import { Territory } from '../types'
import { territoryTypes, TerritoryManagerType, TerritoryActions, TerritoryRepairItem, FetchTerritoryRepairItemsPayload } from './types'

const actions = {
  getTerritory: (
    payload: string,
    callback: (
      succ: boolean) => void
  ): TerritoryActions => ({
    type: territoryTypes.GET_TERRITORY,
    payload,
    callback
  }),
  setTerritory: (payload: Territory): TerritoryActions => ({
    type: territoryTypes.SET_TERRITORY,
    payload
  }),
  fetchTerritoryManagers: (
    payload: string,
    callback: (succ: boolean) => void
  ): TerritoryActions => ({
    type: territoryTypes.FETCH_TERRITORY_MANAGERS,
    payload,
    callback
  }),
  setTerritoryManagers: (payload: TerritoryManagerType[]): TerritoryActions => ({
    type: territoryTypes.SET_TERRITORY_MANAGERS,
    payload
  }),
  setTerritorySuccessManagers: (payload: TerritoryManagerType[]): TerritoryActions => ({
    type: territoryTypes.SET_TERRITORY_SUCCESS_MANAGERS,
    payload
  }),
  fetchTerritoryRepairItems: (
    payload: {
      territoryId?: string,
      params?: FetchTerritoryRepairItemsPayload
    },
    callback?: (succ: boolean) => void): TerritoryActions => ({
      type: territoryTypes.FETCH_TERRITORY_REPAIR_ITEMS,
      payload,
      callback
    }),
  setchTerritoryRepairItems: (payload: TerritoryRepairItem): TerritoryActions => ({
    type: territoryTypes.SET_TERRITORY_REPAIR_ITEMS,
    payload
  }),
  deleteTerritory: (
    payload: string,
    callback: (succ: boolean) => void
  ): TerritoryActions => ({
    type: territoryTypes.DELETE_TERRITORY,
    payload,
    callback
  }),
  updateTerritory: (
    payload: {
      id: string,
      territory: Partial<Territory>
    },
    callback?: (succ: boolean) => void
  ): TerritoryActions => ({
    type: territoryTypes.UPDATE_TERRITORY,
    payload,
    callback
  })
}

export default actions
