import { FC } from 'react'
import { ImagesSelectorProps } from './types'
import useStyles from './styles'
import ImagePicker from '../../atoms/ImagePicker'
import { Box, Grid, Typography, Button } from '@mui/material'
import Slider from '../Slider'
import Icon from '../../atoms/Icon'
import { icons } from 'src/assets'
import { videoMimeTypes } from 'src/helpers'

const ImagesSelector: FC<ImagesSelectorProps> = ({
  submitAction = () => { },
  imageFiles,
  images = [],
  disabled = false,
  multiple = false,
  height,
  columns = 3,
  isSlider = false,
  hasImageLoader,
  hasAllImagesButton,
  allowDelete = false,
  deleteAction = () => { },
  width = 735,
  imageNumber = 3,
  handleAllImage = () => { },
  acceptedFiles
}) => {
  const classes = useStyles()
  const fileExtension = (tmppath: string) => (tmppath ? tmppath.slice((tmppath.lastIndexOf('.') - 2 >>> 0) + 2) : '')
  const handleUploadImage = (newImage: File[]): void => {
    const newImageArr = [...images.filter(i => i !== null), ...newImage]
    if (submitAction) submitAction(newImageArr)
  }

  const handleDeleteImage = (index: number) => {
    if (index && index > images.length) return
    const filteredArray = images.filter((_, i) => i !== index)
    if (submitAction) submitAction(filteredArray)
  }

  const randomNumber = () => {
    return Math.floor(Math.random() * 1000 - 1)
  }

  const handleOpenPDF = (pathFile: string) => {
    const link = document.createElement('a');
    link.href = pathFile;
    link.setAttribute('download', 'image.jpg');
    link.setAttribute('target', '__blank');
    document.body.appendChild(link);
    link.click();
  }

  return (
    <Grid container spacing={2}>
      {(images.length <= 0 && !imageFiles?.length) &&
        <Grid item xs={12} sx={{ marginBottom: 1 }}>
          <Typography variant="h6" color={'var(--gray500)'}>No uploaded Images for now.</Typography>
        </Grid>
      }
      <>

        {isSlider && imageFiles && imageFiles.length > 0
          ?
          <Box maxWidth={`${width < 735 ? width : 735}px`} marginLeft='16px' marginY='16px' position="relative" padding="0 1.5rem">
            <div className="swiper-button-prev-unique slider-arrow"><Icon name="ArrowBackIos" /></div>
            <Slider
              slidesPerView={imageFiles.length > 3 ? 3 : imageFiles.length}
              spaceBetween={20}
              slideToClickedSlide
              className={classes.slider}
              navigation={{
                nextEl: '.swiper-button-next-unique',
                prevEl: '.swiper-button-prev-unique'
              }}
            >
              {imageFiles.map((image, index) => {
                if (image.name?.includes('pdf')) {
                  return (
                    <>
                      <Box display="flex" gap={1} sx={{ cursor: 'pointer' }} onClick={() => handleOpenPDF(image.fileUrl)}>
                        <Icon name="Description" />
                        <Typography
                          variant='body1'
                        >
                          {image?.name}
                        </Typography>
                      </Box>
                      {allowDelete && <Box style={{ position: "absolute", top: 0, right: 0 }} onClick={() => deleteAction(index)}>
                        <icons.DeleteOutline height='9px' width='12px' />
                      </Box>}
                    </>
                  )
                }
                return <>
                  {videoMimeTypes.includes(fileExtension(image.fileUrl)) ? (
                    <video controls style={{ borderRadius: 8, maxWidth: "250px" }}>
                      <source src={image.fileUrl} />
                    </video>
                  ) : (
                    <img width='100%' key={index} src={image.fileUrl} alt="selector" style={{ borderRadius: 8, maxWidth: "250px" }} />
                  )}
                  {allowDelete &&
                    <Box style={{ position: "absolute", top: "5px", right: "5px" }} onClick={() => deleteAction(index)}>
                      <icons.DeleteOutline height='9px' width='12px' style={{ color: "white", background: "red", borderRadius: "4px" }} />
                    </Box>
                  }
                </>
              })}
            </Slider>
            <div className="swiper-button-next-unique slider-arrow"><Icon name="ArrowForwardIos" /></div>
          </Box>
          :
          <Grid item className={`${classes.root}`} sx={{ gridTemplateColumns: `repeat(${columns}, 1fr)` }}>
            {imageFiles && imageFiles.map((image, index) => {
              return (
                <ImagePicker
                  key={index}
                  imageUrl={image.fileUrl}
                  handleUploadFile={handleUploadImage}
                  disabled
                  multiple={multiple}
                  height={height}
                  hasImageLoader={hasImageLoader}
                  acceptedFiles={acceptedFiles}

                />
              )
            })}
          </Grid>
        }
        {isSlider && images.length > 0 ?
          <Box maxWidth={`${width < 735 ? width : 735}px`} marginLeft='16px' marginY='16px' position="relative" padding="0 1.5rem">
            <div className="swiper-button-prev-slider2 slider-arrow"><Icon name="ArrowBackIos" /></div>
            <Slider
              slidesPerView={images.length > imageNumber ? imageNumber : images.length}
              spaceBetween={20}
              slideToClickedSlide
              className={classes.slider}
              navigation={{
                nextEl: '.swiper-button-next-slider2',
                prevEl: '.swiper-button-prev-slider2'
              }}
            >
              {images.map((image: any, index) => {
                return (
                  <ImagePicker
                    imageUrl={URL.createObjectURL(image)}
                    handleUploadFile={handleUploadImage}
                    handleDeleteFile={() => handleDeleteImage(index)}
                    disabled={disabled || !submitAction}
                    key={
                      image
                        ? image.lastModified + randomNumber()
                        : index + randomNumber()
                    }
                    maxWidth="250px"
                    multiple={multiple}
                    height={height}
                    hasImageLoader={hasImageLoader}
                    acceptedFiles={acceptedFiles}
                  />
                )
              })}
            </Slider>
            <div className="swiper-button-next-slider2 slider-arrow"><Icon name="ArrowForwardIos" /></div>
          </Box>
          :
          <Grid item className={`${classes.root}`} sx={{ gridTemplateColumns: `repeat(${columns}, 1fr)` }}>
            {images.map((image: any, index) => {
              return (
                <ImagePicker
                  imageUrl={URL.createObjectURL(image)}
                  handleUploadFile={handleUploadImage}
                  handleDeleteFile={() => handleDeleteImage(index)}
                  disabled={disabled || !submitAction}
                  key={
                    image
                      ? image.lastModified + randomNumber()
                      : index + randomNumber()
                  }
                  multiple={multiple}
                  height={height}
                  hasImageLoader={hasImageLoader}
                  acceptedFiles={acceptedFiles}

                />
              )
            })}
          </Grid>
        }
      </>
      {!disabled && (
        <Grid item container sm={12} spacing={2}>
          <Grid item>
            <ImagePicker
              handleUploadFile={handleUploadImage}
              disabled={disabled || !submitAction}
              multiple={multiple}
              height={height}
              hasImageLoader={hasImageLoader}
              acceptedFiles={acceptedFiles}

            />
          </Grid>
          {hasAllImagesButton && (
            <Grid item>
              <Button
                fullWidth
                variant="outlined"
                startIcon={<Icon name="Image" width=".8rem" />}
                onClick={handleAllImage}
              >
                All Images
              </Button>
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  )
}

export default ImagesSelector
