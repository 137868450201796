import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from '@mui/material'
import Modal from 'src/components/UI/CustomUI/molecules/Modal'
import { TextFieldLabel } from 'src/components/UI'
import { icons } from 'src/assets'
import { getEstimate } from 'src/ducks/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { estimateActions, estimatesActions } from 'src/ducks/actions'
import { ToastContainer, toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import useStyles from '../styles'
import ContactItem from './Contact'
import AddIcon from '@mui/icons-material/Add'
import { Contact } from 'src/ducks/types'
import { CONTACT_ROLE, uniqueRecipients } from 'src/helpers'
import DeleteIcon from '@mui/icons-material/Delete'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { SendTo } from 'src/ducks/estimates/types'

type ConfirmReleaseModalProps = {
  isOpen: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

const ConfirmReleaseModal: FC<ConfirmReleaseModalProps> = ({
  isOpen,
  setOpen,
}) => {
  const styles = useStyles()

  const dispatch = useDispatch()
  const estimate = useSelector(getEstimate())
  const navigate = useNavigate()

  const [subject, setSubject] = useState(
    `Your BOSSCAT Estimate for ${estimate?.properties?.address?.line_1} (#${estimate?.properties.publicId})`
  )
  const [note, setNote] = useState('')
  const [loading, setLoading] = useState(false)
  const [newContacts, setNewContacts] = useState(estimate?.properties.contacts)
  const [showToast, setShowToast] = useState(false)
  const [receivers, setReceivers] = useState('')
  const [toastInfo, setToastInfo] = useState({
    toastType: 'SAVED',
    toastEmail: '',
    toastTimer: 0 as unknown as NodeJS.Timeout,
  })

  const handleSave = () => {
    setLoading(true)
    if (
      estimate?.id &&
      estimate?.properties?.contacts?.length &&
      estimate?.properties?.contacts?.length > 0
    )
      dispatch(
        estimatesActions.releaseEstimate(
          {
            estimateId: estimate?.id,
            releaseEstimateRequest: {
              sendToList: uniqueRecipients(receivers) as SendTo[],
              emailSubject: subject,
              notes: note,
            },
          },
          (succ: boolean) => {
            setLoading(false)
            if (succ) {
              toast.success.call('success', 'Estimate Released')
              navigate(`/estimates/${estimate?.id}`)
              setOpen(false)
            }
          }
        )
      )
    setNote('')
  }

  useEffect(() => {
    if (!isOpen) {
      setNote('')
    }
  }, [isOpen])

  useEffect(() => {
    setNewContacts(estimate?.properties?.contacts)
    setReceivers(estimate?.properties.contacts?.filter(contact => contact.sendEstimate).map((contact) => `${contact.fullName} <${contact.email}>`).join(',') || '')

    setSubject(
      `Your BOSSCAT Estimate for ${estimate?.properties?.address?.line_1} (#${estimate?.properties.publicId})`
    )
  }, [estimate])

  const handleAddReceiver = () => {
    setNewContacts([...(newContacts || []), {} as Contact])
  }

  const handleClose = (index: number) => {
    setNewContacts([
      ...(newContacts || []).filter((contact, idx) => idx !== index),
    ])
  }

  const handleAdd = (str: string, index: number) => {
    // const [name, email] = str.slice(0, -1).split('(')
    // const [firstName, lastName] = name.split(' ')

    const updatedContacts = newContacts?.map((contact, idx) =>
      idx === index
        ? ({
          // firstName,
          // lastName,
          // fullName: name,
          email: str,
          role: CONTACT_ROLE.OTHER,
        } as Contact)
        : contact
    )

    if (updatedContacts) {
      dispatch(
        estimateActions.updateEstimateProperty(
          { contacts: updatedContacts },
          (succ: boolean) => {
            if (succ) {
              const timeout = setTimeout(() => setShowToast(false), 4000)

              // setToastInfo({ toastType: 'SAVED', toastEmail: email, toastTimer: timeout })
              setToastInfo({ toastType: 'SAVED', toastEmail: str, toastTimer: timeout })
              setShowToast(true)
            }
          }
        )
      )
    }
  }

  const handleEdit = (str: string, index: number) => {
    // const [name, email] = str.slice(0, -1).split('(')
    // const [firstName, lastName] = name.split(' ')

    if (newContacts) {
      dispatch(
        estimateActions.updateEstimateContact(
          {
            contact: {
              ...newContacts[index],
              // firstName,
              // lastName,
              // fullName: name,
              email: str,
            },
            idx: index,
          },
          (succ) => {
            if (succ) {
              const timeout = setTimeout(() => setShowToast(false), 4000)

              // setToastInfo({ toastType: 'UPDATED', toastEmail: email, toastTimer: timeout })
              setToastInfo({ toastType: 'UPDATED', toastEmail: str, toastTimer: timeout })
              setShowToast(true)
            }
          }
        )
      )
    }
  }

  const handleDelete = (index: number) => {
    const timeout = setTimeout(() => {
      setShowToast(false)
      dispatch(
        estimateActions.updateEstimateProperty({
          contacts: newContacts?.filter((_, idx) => idx !== index),
        })
      )
    }, 4000)


    setToastInfo({
      toastType: 'DELETED',
      toastEmail: (newContacts || [])[index].email,
      toastTimer: timeout
    })
    setShowToast(true)
  }

  const getToastContent = (type: string, email: string, toastTimer: NodeJS.Timeout) => {
    switch (type) {
      case 'SAVED':
        return (
          <Box className={styles.successToast} mb={2}>
            <Box display="flex" gap="8px" alignItems='center'>
              <CheckCircleIcon sx={{ width: '20px', height: '20px' }} color='inherit' />
              <Typography className={styles.smallFont}>
                <b>{email}</b>&nbsp; added as a receiver.
              </Typography>
            </Box>
          </Box>
        )
      case 'UPDATED':
        return (
          <Box className={styles.successToast} mb={2}>
            <Box display="flex" gap="8px" alignItems='center'>
              <CheckCircleIcon sx={{ width: '20px', height: '20px' }} color='success' />
              <Typography className={styles.smallFont}>
                <b>{email}</b>&nbsp; was updated.
              </Typography>
            </Box>
          </Box>
        )
      case 'DELETED':
        return (
          <Box className={styles.errorToast} mb={2}>
            <Box display="flex" gap="8px">
              <DeleteIcon sx={{ width: '20px', height: '20px' }} />
              <Typography className={styles.smallFont}>
                <b>{email}</b>&nbsp; was updated.
              </Typography>
            </Box>
            <Button
              sx={{ color: '#E01F1F !important', padding: '0 !important' }}
              onClick={(event) => {
                event.preventDefault()
                setShowToast(false)
                clearTimeout(toastTimer)
              }}
            >
              Undo
            </Button>
          </Box>
        )
    }
  }

  return (
    <Modal setOpen={setOpen} open={isOpen} title="Send Estimate">
      <Box px={1}>
        <Box my={2}>
          <Typography className={styles.midFont} fontWeight={600} mb={1}>
            From Territory
          </Typography>
          <Typography className={styles.midFont} color="#3A66FF">
            {estimate?.properties?.territory?.email || '-'}
          </Typography>
        </Box>

        {showToast &&
          getToastContent(toastInfo.toastType, toastInfo.toastEmail, toastInfo.toastTimer)}

        <Box mb={2}>
          <TextFieldLabel
            label='Receivers'
            value={receivers}
            onChange={(e) => setReceivers(e.target.value)}
          />
        </Box>

        {/* <Box mb={2}>
          <Box display="flex" justifyContent="space-between">
            <Typography className={styles.midFont} fontWeight={600} mb={1}>
              Receivers (Contacts)
            </Typography>
            <Button
              startIcon={<AddIcon sx={{ width: '12px', height: '12px' }} />}
              sx={{ color: '#3A66FF', padding: '0px !important' }}
              onClick={handleAddReceiver}
            >
              Add Receiver
            </Button>
          </Box>
          <Stack
            className={styles.receiversContainer}
            divider={<Divider orientation="horizontal" />}
            spacing={0}
          >
            {newContacts?.map((contact, index) => (
              <ContactItem
                key={index}
                index={index}
                contact={contact}
                handleClose={handleClose}
                handleAdd={handleAdd}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
              />
            ))}
          </Stack>
        </Box> */}

        <Box mb={2}>
          <TextFieldLabel
            label="Special Notes"
            placeholder="Additional Information comes here..."
            value={note}
            onChange={(e) => setNote(e.target.value)}
            fullWidth
            multiline
            rows={3}
          />
        </Box>

        <Box mt={2} display="flex" justifyContent="flex-end">
          <Box mr={2}>
            <Button
              disabled={loading}
              variant="outlined"
              onClick={() => setOpen(false)}
              color="primary"
            >
              Cancel
            </Button>
          </Box>

          <Button
            endIcon={loading ? <CircularProgress size={18} /> : <icons.Send />}
            onClick={handleSave}
            variant="contained"
            disabled={!subject}
          >
            Send Estimate
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default ConfirmReleaseModal
