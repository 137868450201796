/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  Divider,
  LabeledText,
} from 'src/components/UI'
import { ScrollWrapper } from 'src/components/templates'
import useStyles from './styles'
import { computer } from 'src/assets'
import { orderActions } from 'src/ducks/actions'
import { getOrder } from 'src/ducks/selectors'
import { OrderHistory } from 'src/ducks/types'
import Tag from 'src/components/UI/CustomUI/atoms/Tag'
import { formatTimestamp, getColorForDispatchStatus, round } from 'src/helpers'

const History: FC<{ timezone?: string }> = ({ timezone }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const { orderId, orderHistory } = useSelector(getOrder())
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (orderId) {
      dispatch(
        orderActions.fetchOrderHistory(orderId, (_succ) => {
          setLoading(false)
        })
      )
    }
  }, [orderId])

  const Loading = () => {
    return (
      <Box className={classes.centerContent}>
        <CircularProgress size="5rem" />
      </Box>
    )
  }

  const HistoryItem = ({ children }: { children: OrderHistory }) => {
    const {
      sendByName,
      vendorName,
      sentOn,
      rejectType,
      vendorComment,
      bidPrice,
      scheduledStart,
      scheduledEnd,
      repliedOn,
      status,
      margin,
    } = children

    const { color, background } = getColorForDispatchStatus(status)

    return (
      <Box display="grid" gap={1.5}>
        <Box display="flex" gap={0.5} justifyContent="space-between">
          <Box display="grid" gap={0.5}>
            <LabeledText
              labelValue="Sent:"
              textValue={`${formatTimestamp(sentOn, 'M/D/YYYY hh:mm a z', timezone)} ${
                sendByName ? 'by ' + sendByName : ''
              }`}
            />
            <LabeledText labelValue="Vendor:" textValue={vendorName} />
          </Box>
          <Tag
            sx={{
              paddingX: 2,
              textTransform: 'uppercase',
              height: '25px',
            }}
            variant="caption"
            width="fit-content"
            color={color}
            background={background}
          >
            {status}
          </Tag>
        </Box>
        <Box display="grid" gap={0.5}>
          <LabeledText labelValue="Reason:" textValue={rejectType} />
          <LabeledText labelValue="Bid:" textValue={`$${round(bidPrice, 2)}`} />
          <LabeledText
            labelValue="Schedule:"
            textValue={`${formatTimestamp(
              scheduledStart,
              'M/D/YYYY hh:mm a',
              timezone,
            )} - ${formatTimestamp(scheduledEnd, 'M/D/YYYY hh:mm a z', timezone)}`}
          />
          <LabeledText
            labelValue="Replied:"
            textValue={formatTimestamp(repliedOn, 'M/D/YYYY hh:mm a z', timezone)}
          />
          <LabeledText labelValue="Comments:" textValue={vendorComment} />
          <LabeledText
            labelValue="Margin:"
            textValue={margin ? margin?.toString() + '%' : '-'}
          />
        </Box>
      </Box>
    )
  }

  const HistoryContent = () => {
    return (
      <Grid
        container
        spacing={2}
        display="grid"
        className={classes.historyContent}
      >
        {orderHistory?.map((history, index) => {
          return (
            <Grid item key={index} style={{ display: 'grid', gap: '14px' }}>
              <HistoryItem>{history}</HistoryItem>
              {index + 1 < orderHistory.length && <Divider />}
            </Grid>
          )
        })}
      </Grid>
    )
  }

  const EmptyHistory = () => {
    return (
      <Box className={classes.centerContent}>
        <img src={computer} alt="computer" />
        <Typography variant="h5">No previous dispatched orders</Typography>
      </Box>
    )
  }

  return (
    <ScrollWrapper relativeStyle={{ minHeight: '30vh' }}>
      {loading ? (
        <Loading />
      ) : orderHistory ? (
        <HistoryContent />
      ) : (
        <EmptyHistory />
      )}
    </ScrollWrapper>
  )
}

export default History
