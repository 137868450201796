import { FC, useState, useContext, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import { icons } from "src/assets"
import { PrivateLoader } from "src/components/templates"
import { Button, Checkbox, FormControlLabel, Grid, TextFieldLabel, Typography } from "src/components/UI"
import UploadDocuments from "src/components/UI/CustomUI/molecules/UploadDocument"
import { companiesActions, filesActions, filtersActions } from "src/ducks/actions"
import { FILE_TYPE, isEmpty, ParseFilesUrl, PHONE_MASK_INPUT, PHONE_MASK_REGEXP, validateEmail } from "src/helpers"
import { CompaniesContext } from "../../context"
import { dispatchTypes } from "../../context/types"
import env from "@beam-australia/react-env"
import moment from "moment"
import { getCompaniesFilter } from "src/ducks/filters/selector"
import { CompaniesFilterType } from "src/ducks/filters/types"

const FILES_URL = env('FILES_URL') ?? ''

const AddCompany: FC = () => {

    const dispatch = useDispatch()
    const { dispatch: dispatchContext } = useContext(CompaniesContext)

    const companiesFilter = useSelector(getCompaniesFilter())

    const selectedAffiliates = useMemo(
        () => companiesFilter.selectedAffiliates || [],
        [companiesFilter]
    )

    const [loading, setLoading] = useState(false)
    const [showWarnings, setShowWarnings] = useState(false)
    const [uploadingFile, setUploadingFile] = useState(false)
    const [file, setFile] = useState<any>(null)

    // FORM
    const [companyName, setCompanyName] = useState('')
    const [companyAdminName, setCompanyAdminName] = useState('')
    const [companyEmail, setCompanyEmail] = useState('')
    const [affiliateId, setAffiliateId] = useState('')
    const [primaryPhone, setPrimaryPhone] = useState('')
    const [landingPage, setLandingPage] = useState('')
    const [institutional, setInstitutional] = useState(false)

    const validate = !isEmpty(companyName) &&
        !isEmpty(companyAdminName) &&
        !isEmpty(landingPage) &&
        (isEmpty(companyEmail) || validateEmail(companyEmail)) &&
        !isEmpty(primaryPhone) && PHONE_MASK_REGEXP.test(primaryPhone) &&
        !isEmpty(landingPage)

    const updateCompaniesFilter = (newFilter: Partial<CompaniesFilterType>) => {
        dispatch(
            filtersActions.setFilters({
                companies: {
                    ...companiesFilter,
                    ...newFilter,
                },
            })
        )
    }

    const handleUploadFile = (file: null | File, callback: () => void) => {
        if (file) {
            setUploadingFile(true)
            dispatch(filesActions.uploadFile(file, (uploadSucc, fileName) => {
                if (uploadSucc && fileName) {
                    const fileToSave = {
                        name: file.name,
                        fileUrl: ParseFilesUrl({
                            baseUrl: FILES_URL,
                            avatar: fileName ?? ''
                        }),
                        description: file.name ?? '',
                        fileType: FILE_TYPE.MATERIAL_INVOICE,
                        uploadedOn: moment().unix(),

                    }
                    callback()
                    setFile(fileToSave)
                }
                setUploadingFile(false)
            }))
        } else {
            setFile(null)
        }
    }

    const handleCancel = () => {
        dispatchContext({ type: dispatchTypes.SET_VALUE, payload: { attr: 'modalOpen', value: false } })
    }

    const handleCreate = () => {
        if (validate) {
            setLoading(true)
            const newCompany = {
                name: companyName,
                affiliateId: affiliateId,
                institutional: institutional,
                landingPageUrl: landingPage,
                phone: parseInt(primaryPhone.replace(/-/g, '')),
                adminName: companyAdminName,
                email: companyEmail,
                logo: file
            }
            dispatch(companiesActions.createCompany({ company: newCompany }, (succ, resCompany) => {
                if (succ) {
                    dispatchContext({ type: dispatchTypes.SET_VALUE, payload: { attr: 'modalOpen', value: false } })
                    if (resCompany) {
                        updateCompaniesFilter({
                            selectedAffiliates: [
                                ...selectedAffiliates, {
                                    key: resCompany.affiliateId,
                                    label: resCompany.name,
                                    id: resCompany.id,
                                    hide: true
                                }
                            ].sort((a, b) => a.label.localeCompare(b.label))
                        })

                        dispatch(companiesActions.fetchAllCompanies({ searchParams: { search: companiesFilter.search } }))
                    }
                    toast.success.call('success', 'Company Created')
                    setLoading(false)
                } else {
                    setLoading(false)
                }
            }))
        } else {
            setShowWarnings(true)
        }

    }

    return (
        <PrivateLoader loading={loading}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <UploadDocuments
                        error={showWarnings && !file}
                        file={file ?? null}
                        dropzoneText='Upload Company Image'
                        preview={true}
                        onChange={!uploadingFile ? handleUploadFile : () => { }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextFieldLabel
                        labelVariant='body1Bold'
                        label='Company Name:'
                        placeholder='Company Name'
                        value={companyName}
                        helperText={showWarnings && isEmpty(companyName) ? 'Should add Company Name' : ''}
                        error={showWarnings && isEmpty(companyName)}
                        onChange={(event) => setCompanyName(event.target.value)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextFieldLabel
                        labelVariant='body1Bold'
                        label='Affiliate ID:'
                        helperText={showWarnings && isEmpty(affiliateId) ? 'Should add Affiliate ID' : ''}
                        error={showWarnings && isEmpty(affiliateId)}
                        placeholder='000000'
                        value={affiliateId}
                        onChange={(event) => setAffiliateId(event.target.value)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextFieldLabel
                        labelVariant='body1Bold'
                        label='Company Admin Name:'
                        placeholder='Company Admin Name'
                        value={companyAdminName}
                        helperText={showWarnings && isEmpty(companyAdminName) ? 'Should add Company Admin Name' : ''}
                        error={showWarnings && isEmpty(companyAdminName)}
                        onChange={(event) => setCompanyAdminName(event.target.value)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextFieldLabel
                        labelVariant='body1Bold'
                        type='email'
                        label='Company Email:'
                        placeholder='name@mail.com'
                        value={companyEmail}
                        helperText={showWarnings && !isEmpty(companyEmail) && !validateEmail(companyEmail) ?
                            'Should add a valid Email' : ''}
                        error={showWarnings && !isEmpty(companyEmail) && !validateEmail(companyEmail)}
                        onChange={(event) => setCompanyEmail(event.target.value)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextFieldLabel
                        labelVariant='body1Bold'
                        label='Primary Phone Number:'
                        placeholder='555-555-5555'
                        value={primaryPhone}
                        helperText={showWarnings && (isEmpty(primaryPhone) || !PHONE_MASK_REGEXP.test(primaryPhone)) ?
                            'Should add a valid Phone' : ''}
                        error={showWarnings && (isEmpty(primaryPhone) || !PHONE_MASK_REGEXP.test(primaryPhone))}
                        onChange={(event) => setPrimaryPhone(event.target.value)}
                        mask={PHONE_MASK_INPUT}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextFieldLabel
                        labelVariant='body1Bold'
                        label='Landing Page:'
                        placeholder='www.company-landing.com'
                        value={landingPage}
                        helperText={showWarnings && isEmpty(landingPage) ? 'Should add Landing Page' : ''}
                        error={showWarnings && isEmpty(landingPage)}
                        onChange={(event) => setLandingPage(event.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={institutional}
                                onChange={(event) => setInstitutional(event.target.checked)}
                                name='payAtClose'
                                color='infoText'
                            />
                        }
                        label={<Typography>Institutional</Typography>}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Button
                        variant='containedLight'
                        fullWidth
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        onClick={handleCreate}
                        variant='contained'
                        fullWidth
                        endIcon={<icons.AccountBalance />}>
                        Create Company
                    </Button>
                </Grid>
            </Grid>
        </PrivateLoader >
    )
}

export default AddCompany