
/** TYPES **/
export const discountsTypes = {
  FETCH_DISCOUNT_BANNER: 'FETCH_DISCOUNT_BANNER',
  CREATE_DISCOUNT_BANNER: 'CREATE_DISCOUNT_BANNER',
  SET_VALUE: 'SET_SUBSCRIPTIONS_VALUE',
}

export interface DiscountsValue {
  attr: keyof DiscountsType
  value: any
}


/** ACTIONS **/
export interface FetchDiscountBannerAction {
  type: typeof discountsTypes.FETCH_DISCOUNT_BANNER
  payload: null
  callback?: (succ: boolean) => void
}

export interface CreateDiscountBannerAction {
  type: typeof discountsTypes.CREATE_DISCOUNT_BANNER
  payload: Partial<DiscountBanner>
  callback?: (succ: boolean) => void
}

export interface SetDiscountValueAction {
  type: typeof discountsTypes.SET_VALUE
  payload: DiscountsValue
}

export type DiscountsActions =
  | FetchDiscountBannerAction
  | CreateDiscountBannerAction
  | SetDiscountValueAction

export interface DiscountBanner {
  id?: string
  discount?: number
  startTime: number
  endTime: number
  minAmount: number
  monthString?: string
  discountCode?: string
  description?: string
}
/** REDUCER **/
export type DiscountsType = {
  discountBanner: DiscountBanner | null
}
