/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo } from '../../helpers'
import { Punchlist } from '../../api'
import { widgetActions } from '../actions'
import { FetchWidgetAction, UpdateWidgetByPathAction, widgetTypes } from './types'
import { UpdateRequest } from '~api/types'
import { Widget } from '../types'
import { getWidget } from './selectors'
import { isEmpty } from 'lodash'

export function* fetchWidget({
  payload,
  callback,
}: FetchWidgetAction): SagaIterator {
  let success = false
  try {
    const widget = yield call(Punchlist.widgets.getWidget, payload)
    if (widget) {
      yield put(widgetActions.setWidget(widget))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the widget'))
    if (callback) yield call(callback, false)
  }
}

export function* updateWidgetByPath({ payload, callback }: UpdateWidgetByPathAction): SagaIterator {
  let success = false
  try {
    const widget = yield select(getWidget())
    const editedWidget = yield call(Punchlist.widgets.updateWidget, widget.id, payload)

    if (!isEmpty(editedWidget)) {
      yield put(widgetActions.setWidget(editedWidget))
      // yield put(jobActions.fetchJob(jobId))
      //yield put(jobActions.fetchHistoryTask(jobId))
      success = true
    }
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('update the job'))
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(widgetTypes.FETCH_WIDGET, fetchWidget)
  yield takeLatest(widgetTypes.UPDATE_WIDGET_BY_PATH, updateWidgetByPath)
}
