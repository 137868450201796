import {
  employeeTypes,
  EmployeeValue,
  EmployeeValues,
  EmployeeActions,
  EmployeeType,
  UpdateEmployeeListPayload
} from './types'

const actions = {
  fetchCurrentEmployee: (
    callback: (succ: boolean) => void
  ): EmployeeActions => ({
    type: employeeTypes.FETCH_CURRENT_EMPLOYEE,
    payload: null,
    callback
  }),
  createEmployee: (
    callback: (succ: boolean) => void
  ): EmployeeActions => ({
    type: employeeTypes.CREATE_EMPLOYEE,
    payload: null,
    callback
  }),
  updateEmployee: (
    payload: Partial<EmployeeType>,
    callback: (succ: boolean) => void
  ): EmployeeActions => ({
    type: employeeTypes.UPDATE_EMPLOYEE,
    payload,
    callback
  }),
  updateEmployeeId: (
    employeeId: string,
    payload: Partial<EmployeeType>,
    callback: (succ: boolean) => void
  ): EmployeeActions => ({
    type: employeeTypes.UPDATE_EMPLOYEE_ID,
    employeeId,
    payload,
    callback
  }),
  replaceEmployeeId: (
    employeeId: string,
    payload: Partial<EmployeeType>,
    op: 'remove' | 'replace' | 'add',
    callback: (succ: boolean) => void
  ): EmployeeActions => ({
    type: employeeTypes.REPLACE_EMPLOYEE_ID,
    employeeId,
    payload,
    op,
    callback
  }),
  deleteEmployeeId: (
    employeeId: string,
    payload: Partial<EmployeeType>,
    callback: (succ: boolean) => void
  ): EmployeeActions => ({
    type: employeeTypes.DELETE_EMPLOYEE_ID,
    employeeId,
    payload,
    callback
  }),
  updateEmployeeList: (
    payload: UpdateEmployeeListPayload,
    callback: (succ: boolean) => void
  ): EmployeeActions => ({
    type: employeeTypes.UPDATE_EMPLOYEE_LIST,
    payload,
    callback
  }),
  setEmployeeValue: (payload: EmployeeValue): EmployeeActions => ({
    type: employeeTypes.SET_VALUE,
    payload
  }),
  setEmployeeValues: (payload: EmployeeValues): EmployeeActions => ({
    type: employeeTypes.SET_VALUES,
    payload
  })
}

export default actions
