import { OverridableStringUnion } from '@mui/types';
import { ButtonPropsVariantOverrides } from "@mui/material";
import React from 'react';

export interface ModalActionType {
    textButton?: string
    onClick: () => void
    variant?: OverridableStringUnion<'text' | 'outlined' | 'contained', ButtonPropsVariantOverrides>
    icon?: string
    iconColor?: string
    disabled?: boolean
    loading?: boolean
}
export interface ModalInvoiceProps {
    open: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    title?: string | JSX.Element
    children?: React.ReactNode
    actions?: ModalActionType[]
    className?: string
    fullWidth?: boolean
    fullScreen?: boolean
    onClose?: () => void
    showClose?: boolean
    center?: boolean
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
    sx?: any
    loading?: boolean
}

export enum MaterialInvoiceModalTypes {
    MATERIAL_INVOICES_LIST = 'MATERIAL_INVOICES_LIST',
    MATERIAL_INVOICES_ADD = 'MATERIAL_INVOICES_ADD',
    MATERIAL_INVOICES_REMOVE = 'MATERIAL_INVOICES_REMOVE'
  } 
  