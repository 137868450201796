import { formatParams } from 'src/helpers'
import axios from '../axios'
import { ApiController } from './types'

const base = '/matches'

const apiController: ApiController = {
  getEstimateMatches: (estimateId: string) => {
    return axios.get(`${base}/${estimateId}`).then((response) => response.data)
  },

  runEstimateAutomatch: (estimateId: string) => {
    return axios.post(`${base}/${estimateId}`).then((response) => response.data)
  },

  getEstimateItemMatch: (estimateId: string, itemId: string) => {
    return axios
      .get(`${base}/${estimateId}/items/${itemId}`)
      .then((response) => response.data)
  },
}

export default apiController
