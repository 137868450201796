import { Theme } from '@mui/material'
import { AvailableCustomColors } from '../themeExtension'

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    containedLight: true;
    containedLightBig: true;
    containedErrorLight: true;
    containedBig: true;
    containedError: true;
    outlinedBig: true;
    toggle: true;
    toggleContained: true;
    toggleContainedDark: true;
    toggleOutlined: true;
    icon: true;
    containedOrange: true;
    containedGreen: true;
  }
}

declare module '@mui/material/Button' {
  export interface ButtonPropsColorOverrides extends AvailableCustomColors { }
}

export const button = {
  MuiButton: {
    variants: [
      {
        props: { variant: 'containedLight' },
        style: ({ theme }: { theme: Theme }) => ({
          backgroundColor: theme.palette.blue[100],
          color: theme.palette.blue[700],
          padding: '0.4rem 1rem',
          textTransform: 'inherit',
          fontFamily: 'NouvelleNormal',
          borderRadius: '32px',
          border: ` 2px solid ${theme.palette.blue[100]}`,
          '&:hover': {
            color: theme.palette.primary.dark
          }
        })
      },
      {
        props: { variant: 'containedLightBig' },
        style: ({ theme }: { theme: Theme }) => ({
          backgroundColor: theme.palette.blue[100],
          color: theme.palette.blue[700],
          padding: '0.4rem 3rem',
          textTransform: 'inherit',
          fontFamily: 'NouvelleNormal',
          fontSize: 'var(--font-S)',
          borderRadius: '32px',
          border: ` 2px solid ${theme.palette.blue[100]}`,
          '&:hover': {
            color: theme.palette.primary.dark
          }
        })
      },
      {
        props: { variant: 'containedBig' },
        style: ({ theme }: { theme: Theme }) => (
          {
            backgroundColor: 'var(--blue700)',
            color: 'var(--white-color)',
            padding: '0.4rem 1.5rem',
            textTransform: 'inherit',
            fontFamily: 'NouvelleNormal',
            borderRadius: '32px',
            border: ` 2px solid ${theme.palette.blue[700]}`,
            fontSize: 'var(--font-S)',
            '&:hover': {
              backgroundColor: theme.palette.primary.dark,
              border: ` 2px solid ${theme.palette.primary.dark}`,
              boxShadow:
                '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'
            },
            "&:disabled": {
              backgroundColor: theme.palette.gray[100],
              border: ` 2px solid ${theme.palette.gray[100]}`,
            }
          })
      },
      {
        props: { variant: 'containedError' },
        style: ({ theme }: { theme: Theme }) => ({
          backgroundColor: theme.palette.error.main,
          color: 'var(--white-color)',
          padding: '0.4rem 1rem',
          textTransform: 'inherit',
          fontFamily: 'NouvelleNormal',
          borderRadius: '32px',
          border: ` 2px solid ${theme.palette.error.main}`,
          '&:hover': {
            backgroundColor: theme.palette.error.dark,
          }
        })
      },
      {
        props: { variant: 'containedGreen' },
        style: ({ theme }: { theme: Theme }) => ({
          backgroundColor: theme.palette.green[700],
          color: 'var(--white-color)',
          padding: '0.4rem 1rem',
          textTransform: 'inherit',
          fontFamily: 'NouvelleNormal',
          borderRadius: '32px',
          border: ` 2px solid ${theme.palette.green[700]}`,
          '&:hover': {
            backgroundColor: theme.palette.green[700],
          }
        })
      },
      {
        props: { variant: 'containedErrorLight' },
        style: ({ theme }: { theme: Theme }) => ({
          backgroundColor: 'var(--red100)',
          color: theme.palette.error.main,
          padding: '0.4rem 1rem',
          textTransform: 'inherit',
          fontFamily: 'NouvelleNormal',
          borderRadius: '32px',
          border: ` 2px solid var(--red100)`,
          '&:hover': {
            border: ` 2px solid var(--red600)`,
          }
        })
      },
      {
        props: { variant: 'outlinedBig' },
        style: ({ theme }: { theme: Theme }) => ({
          color: theme.palette.blue[700],
          border: `2px solid ${theme.palette.blue[700]}`,
          padding: '0.4rem 3rem',
          textTransform: 'inherit',
          fontSize: 'var(--font-S)',
          fontFamily: 'NouvelleNormal',
          borderRadius: '32px',
          '&:hover': {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.blue[700],
            border: ` 2px solid ${theme.palette.blue[700]}`,
            boxShadow:
              '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'
          }
        })
      },
      {
        props: { variant: 'toggle' },
        style: ({ theme }: { theme: Theme }) => ({
          backgroundColor: theme.palette.blue[100],
          color: theme.palette.blue[700]
        })
      },
      {
        props: { variant: 'toggleContained' },
        style: ({ theme }: { theme: Theme }) => ({
          backgroundColor: theme.palette.blue[700],
          color: theme.palette.blue[100],
          '&:hover': {
            backgroundColor: theme.palette.primary.dark
          }
        })
      },
      {
        props: { variant: 'toggleContainedDark' },
        style: ({ theme }: { theme: Theme }) => ({
          backgroundColor: theme.palette.navy[700],
          color: theme.palette.blue[100],
          '&:hover': {
            backgroundColor: theme.palette.navy[600]
          }
        })
      },
      {
        props: { variant: 'toggleOutlined' },
        style: ({ theme }: { theme: Theme }) => ({
          color: theme.palette.blue[700],
          border: `2px solid ${theme.palette.blue[700]}`,
          '&:hover': {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.blue[700],
            border: ` 2px solid ${theme.palette.blue[700]}`,
            boxShadow:
              '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'
          }

        })
      },
      {
        props: { variant: 'icon' },
        style: ({ theme }: { theme: Theme }) => ({
          backgroundColor: '#FAFBFF',
          color: theme.palette.blue[700],
          height: '2.5rem',
          minWidth: '2.5rem'
        })
      },
      {
        props: { variant: 'containedOrange' },
        style: ({ theme }: { theme: Theme }) => ({
          color: 'var(--white-color)',
          backgroundColor: 'var(--orange500)',
          fontFamily: 'NouvelleNormal',
          borderRadius: '32px',
          padding: '0.4rem 1rem',
          textTransform: 'inherit',
          '&:hover': {
            backgroundColor: 'var(--orange500)',
            boxShadow:
              '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'
          },
          '&.Mui-disabled': {
            backgroundColor: 'var(--gray200)',
          }
        })
      },
    ],
    styleOverrides: {
      root: {
        height: 'fit-content',
      },
      contained: ({ theme }: { theme: Theme }) => ({
        backgroundColor: 'var(--blue700)',
        color: 'var(--white-color)',
        padding: '0.4rem 1rem',
        textTransform: 'inherit',
        fontFamily: 'NouvelleNormal',
        borderRadius: '32px',
        border: ` 2px solid ${theme.palette.blue[700]}`,
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
          border: ` 2px solid ${theme.palette.primary.dark}`,
          boxShadow:
            '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'
        }

      }),
      outlined: ({ theme }: { theme: Theme }) => ({
        color: theme.palette.blue[700],
        border: `2px solid ${theme.palette.blue[700]}`,
        padding: '0.4rem 1rem',
        textTransform: 'inherit',
        fontFamily: 'NouvelleNormal',
        borderRadius: '32px',
        '&:hover': {
          color: theme.palette.primary.contrastText,
          backgroundColor: theme.palette.blue[700],
          border: ` 2px solid ${theme.palette.blue[700]}`,
          boxShadow:
            '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'
        }
      }),
      link: {
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: 'transparent',
          cursor: 'pointer'
        }
      },
      text: ({ theme }: { theme: Theme }) => ({
        padding: '0.4rem 1rem',
        textTransform: 'inherit',
        fontFamily: 'NouvelleNormal',
        minWidth: '120px',
        '&:hover': {
          color: theme.palette.primary.dark,
          background: 'transparent'
        }

      }),
    }
  },
  MuiButtonBase: {
    styleOverrides: {
      root: {
        minWidth: 'fit-content !important',
        '&.Mui-disabled': {
          borderColor: 'rgba(0, 0, 0, 0)'
        }
      }
    }
  }
}
