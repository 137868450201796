/* eslint-disable generator-star-spacing */
import { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { Punchlist } from '../../api'
import { insurancesActions, vendorActions } from '../actions'
import { toast } from 'react-toastify'
import { errorTextTryingTo } from 'src/helpers'
import { FetchCSVInsurancesAction, FetchInsurancesAction, insurancesTypes, UpdateInsuranceAction } from './types'
import { isEmpty } from 'lodash'
import { getVendor } from '../selectors'

export function* fetchInsurances({
    payload,
    callback,
}: FetchInsurancesAction): SagaIterator {
    let success = true
    try {
        const page = payload.page ?? 0
        const size = payload.size ?? 50

        const start = page * size
        //const limit = start + size
        const limit = size

        const searchParams = payload.searchParams ?? ''
        /** 
         * Mock api request
        */
        const insurances = yield call(Punchlist.insurances.getInsurances, {
            start,
            limit,
            searchParams,
        })

        if (insurances) {
            yield put(insurancesActions.setInsurances(insurances))
        }

        // yield put(insurancesActions.setInsurances(MOCK_INSURANCES))

        success = true

        if (callback) yield call(callback, success)
    } catch (err) {
        yield call(toast.error, errorTextTryingTo('get the licenses'))

        if (callback) yield call(callback, false)
    }
}

export function* fetchCSVInsurances({
    payload,
    callback,
}: FetchCSVInsurancesAction): SagaIterator {
    let success = true

    try {
        const page = 0
        const size = payload.count ?? 0

        const start = page * size
        //const limit = start + size
        const limit = size

        const searchParams = payload.searchParams ?? ''
        const insurances = yield call(Punchlist.insurances.getInsurances, {
            start,
            limit,
            searchParams,
        })

        if (insurances) {
            yield put(insurancesActions.setCSVInsurances(insurances))
        }

        success = true

        if (callback) yield call(callback, success)
    } catch (err) {
        yield call(toast.error, errorTextTryingTo('get the licenses'))

        if (callback) yield call(callback, false)
    }
}

export function* updateInsurance({
    payload,
    callback,
}: UpdateInsuranceAction): SagaIterator {
    const { request, id, vendorId } = payload
    try {
        const insurance = yield call(
            Punchlist.insurances.updateInsurance,
            id,
            request
        )

        if (!isEmpty(insurance)) {

            const newvendor = yield call(Punchlist.vendors.getVendorById, vendorId)
            yield put(vendorActions.setVendor(newvendor))
            if (callback) yield call(callback, true)
        }
    } catch (error) {
        yield call(toast.error, errorTextTryingTo('update the insurance'))
        if (callback) yield call(callback, false)
    }
}

export default function* saga(): SagaIterator {
    yield takeLatest(insurancesTypes.FETCH_INSURANCES, fetchInsurances)
    yield takeLatest(insurancesTypes.FETCH_CSV_INSURANCES, fetchCSVInsurances)
    yield takeLatest(insurancesTypes.UPDATE_INSURANCE, updateInsurance)
}
