import { formatParams } from 'src/helpers'
import axios from '../axios'
import { ApiController } from './types'

const base = '/licenses'

const apiController: ApiController = {
  getLicenses: async ({ start, limit, searchParams }) => {
    const params = formatParams(searchParams)
    return await axios
      .get(`${base}?${params}`, {
        params: {
          start,
          limit,
        }
      })
      .then(response => response.data)
  },
}

export default apiController
