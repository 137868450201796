import { Typography, Grid, Button } from '@mui/material'
import { TagProps } from './types'
import styles from './styles.module.scss'
import { radio, radioSelected } from 'src/assets'

const ButtonSelector = ({
  children,
  variant = 'body1',
  onClick = () => { },
  value = true
}: TagProps) => {
  const handleOnClick = () => {
    onClick()
  }

  return (
    <Button
      className={styles.Container}
      style={{ width: '100%' }}
      onClick={handleOnClick}
      variant="outlined"
    >
      <Grid container gap={1} >
        <Grid item xs={9} padding='4px' justifySelf='center' alignSelf='start' className={styles.Text}>
          <Typography
            textAlign='left'
            variant={variant}
            color="var(--navy-700)"
          >
            {children}
          </Typography>
        </Grid>

        <Grid item xs={2} justifySelf='center' justifyContent='center' alignSelf='end'>
          <img
            src={value ? radio : radioSelected}
            alt='radioLicense'
            style={{ paddingTop: '4px', height: '14px', width: '14px' }}
          />
        </Grid>
      </Grid>
    </Button>
  )
}
export default ButtonSelector
