/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, SelectInput, TextField, TextFieldLabel, Typography, FieldsFilter } from 'src/components/UI'
import { getTerritoriesForDropdown } from 'src/ducks/territories/selectors'
import { getTerritoryManagersForDropdown, getTerritorySuccessManagersForDropdown } from 'src/ducks/territory/selectors'
import { useDispatch, useSelector } from 'react-redux'

import styles from './styles.module.scss'
import DatePicker from 'src/components/UI/CustomUI/atoms/DatePicker'
import { Range } from 'src/components/UI/CustomUI/atoms/DatePicker/types'
import { getSearchParamValues, getTotalJobs } from 'src/ducks/selectors'
import { jobsActions, searchesActions } from 'src/ducks/actions'
import { getCompaniesForDropdown } from 'src/ducks/companies/selectors'
import moment from 'moment'
import { SelectInputOption } from 'src/components/UI/CustomUI/atoms/SelectInput/types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { InputsProps } from './types'
import TablePagination from 'src/components/UI/CustomUI/organisms/Table/TablePagination'
import ActionButtons from '../ActionButtons'

import { debounce } from 'lodash'
import { ALL_AFFILIATE_TYPE_OPTIONS, ALL_WORK_ORDER_STATUS_OPTIONS, ROWS_PER_PAGE_EXTENDED } from 'src/helpers'


//SearchParams

const STATUS_OPTIONS = [
    { key: 'CLOSED', value: 'Closed' },
    { key: 'SCHEDULING', value: 'Scheduling' },
    { key: 'DEPOSIT_PENDING', value: 'Deposit Pending' },
    { key: 'DISPATCH_PENDING', value: 'Dispatch Pending' },
    { key: 'DISPATCHED', value: 'Dispatched' },
    { key: 'REVIEW', value: 'Review' },
    { key: 'COMPLETE', value: 'Complete' },
    { key: 'RECALLED', value: 'Recalled' }
]

const TASK_SEARCH_OPTIONS: SelectInputOption[] = [
    //{ key: '', label: 'All tasks' },
    { key: 'Job review & setup', label: 'Job review & setup' },
    { key: 'Schedule pre - con inspection', label: 'Schedule pre - con inspection' },
    { key: 'Schedule with client', label: 'Schedule with client' },
    { key: 'Review pre - con inspection', label: 'Review pre - con inspection' },
    { key: 'Submit change order request', label: 'Submit change order request' },
    { key: 'Receive change order approval', label: 'Receive change order approval' },
    { key: 'Review estimated completion date', label: 'Review estimated completion date' },
    { key: ' Dispatch order', label: ' Dispatch order' },
    { key: 'Receive vendor response', label: 'Receive vendor response' },
    { key: 'Confirm work start', label: 'Confirm work start' },
    { key: 'Review dispatch status', label: 'Review dispatch status' },
    { key: 'Vendor completion', label: 'Vendor completion' },
    { key: 'Review work', label: 'Review work' },
    { key: 'Request corrections', label: 'Request corrections' },
    { key: 'Pay vendor', label: 'Pay vendor' },
    { key: 'Client review', label: 'Client review' },
    { key: 'Schedule final client inspection', label: 'Schedule final client inspection' },
    { key: 'Review final client inspection', label: 'Review final client inspection' },
    { key: 'Send final invoice', label: 'Send final invoice' }
]




const Inputs = ({ setTableLoading, tableLoading }: InputsProps) => {
    const dispatch = useDispatch()
    const territoryManagersDropdown = useSelector(getTerritoryManagersForDropdown)
    const territoriesDropdown = useSelector(getTerritoriesForDropdown)
    const territoriesSuccessDropdown = useSelector(getTerritorySuccessManagersForDropdown)
    const affiliatesDropDown = useSelector(getCompaniesForDropdown)


    const searchParams = useSelector(getSearchParamValues)

    //SearchParams

    const [searchParameters, setSearchParameters] = useState<any>(searchParams)


    const totalJobs = useSelector(getTotalJobs)

    //const fetchJobs = (page?: number, size?: number) => {
    const fetchJobs = (page?: number, size?: number) => {

        const params = { ...searchParams, includeArchived: true }

        const paramsWithValues = Object.entries(params).reduce((acc: any, [key, value]) => {
            if (value) {
                acc[key] = value
            }
            return acc
        }, {})

        if (typeof page !== 'number') {
            page = 0
        }
        /*
        if (typeof page === 'number' && typeof size === 'number') {
            paramsWithValues.page = page
            paramsWithValues.size = size
        }*/
        setTableLoading && setTableLoading(true)
        dispatch(
            jobsActions.fetchJobs({ page, size, searchParams: paramsWithValues }, (_succ: boolean) => {
                setTableLoading && setTableLoading(false)
            })
        )
    }

    const changeItemValue = useCallback(
        (newValue, attr) => {
            dispatch(searchesActions.setSearchParamValue({ attr: attr, value: newValue }))
            // dispatchContext({ type: 'SET_ITEM_VALUE', payload: { attr: attr, value: newValue } })
        },
        [dispatch]
    )

    const onItemValueChanged = useMemo(
        () => debounce(changeItemValue, 200),
        [changeItemValue]
    )


    const onChangeDatePicker = (attr: string, rangeSelected: Range) => {
        dispatch(searchesActions.setSearchParamValue({
            attr: `${attr}StartDate`,
            value: rangeSelected.startDate ? moment(rangeSelected.startDate).format('MM/DD/YYYY') : ''
        }))
        dispatch(searchesActions.setSearchParamValue({
            attr: `${attr}EndDate`,
            value: rangeSelected.endDate ? moment(rangeSelected.endDate).format('MM/DD/YYYY') : ''
        }))
    }

    const filterSearch = (newSelected: SelectInputOption) => {
        let res = newSelected
        if (newSelected.key.toLowerCase() === 'all') {
            res = { ...newSelected, key: '' }
        }
        return res
    }

    useEffect(() => {
        setSearchParameters(searchParams)
    }, [searchParams])




    const getTaskSearchForSelect = (): any[] => {
        const data = TASK_SEARCH_OPTIONS.reduce((acc: any, curr) => {
            return [
                ...acc,
                (acc = {
                    id: curr.key,
                    label: curr.label,
                    hide: true,
                }),
            ]
        }, [])

        return data
    }

    const [tasksearchFilter, setTasksearchFilter] = useState(getTaskSearchForSelect || []) //useState(getTaskSearchForSelect || [])




    const handleChangeTaskTypes = (index: number, id: any) => {

        setTasksearchFilter(tasksearchFilter.map((task, i) => {
            if (i === index) {
                task.hide = !task.hide
            }
            return task
        }))
        switch (id) {
            default: {

                const result = tasksearchFilter.filter(item => item.hide === false).map(obj => (obj.id))
                //var result = evens.map(obj => (obj.id));


                setSearchParameters({ ...searchParameters, taskSearch: result })
                onItemValueChanged(result, 'task')
                break
            }
        }
    }


    const getCSNameSearchForSelect = (): any[] => {
        const data = territoriesSuccessDropdown.slice(1).reduce((acc: any, curr) => {
            return [
                ...acc,
                (acc = {
                    id: curr.key,
                    label: curr.label,
                    hide: true,
                }),
            ]
        }, [])

        return data
    }

    const [CSNameSearchFilter, setCSNameSearchFilter] = useState(getCSNameSearchForSelect || [])
    const handleChangeCSNameType = (index: number, id: any) => {

        setCSNameSearchFilter(CSNameSearchFilter.map((csName, i) => {
            if (i === index) {
                csName.hide = !csName.hide
            }
            return csName
        }))

        switch (id) {
            default: {

                const result = CSNameSearchFilter.filter(item => item.hide === false).map(obj => (obj.id))
                setSearchParameters({ ...searchParameters, successManager: result })
                onItemValueChanged(result, 'successManager')
                break
            }
        }

    }

    const getAffiliatesForSelect = (): any[] => {


        const data = affiliatesDropDown.slice(1).reduce((acc: any, curr) => {
            return [
                ...acc,
                (acc = {
                    id: curr.key,
                    label: curr.label,
                    hide: true,
                }),
            ]
        }, [])

        return data
    }

    const [affiliatesNameFilter, setaffiliatesNameFilter] = useState(getAffiliatesForSelect || [])
    const handleChangeAffiliatesNameType = (index: number, id: any) => {

        if (index === - 1) { // unselect all if index is -1
            setaffiliatesNameFilter(affiliatesNameFilter.map((aff, i) => ({ ...aff, hide: true })))
        } else {
            setaffiliatesNameFilter(affiliatesNameFilter.map((affiliate, i) => {
                if (affiliate.id === id) {
                    affiliate.hide = !affiliate.hide
                }
                return affiliate
            }))

            switch (id) {
                default: {
                    const result = affiliatesNameFilter.filter(item => item.hide === false).map(obj => (obj.id))
                    setSearchParameters({ ...searchParameters, affiliate: result })
                    onItemValueChanged(result, 'affiliate')
                    break
                }
            }
        }
    }



    useEffect(() => {
        if (tasksearchFilter.length === 0) {
            setTasksearchFilter(getTaskSearchForSelect())
        }
    }, [tasksearchFilter])

    useEffect(() => {
        if (CSNameSearchFilter.length === 0) {
            setCSNameSearchFilter(getCSNameSearchForSelect())
        }
    }, [CSNameSearchFilter])

    useEffect(() => {
        if (affiliatesNameFilter.length === 0) {
            setaffiliatesNameFilter(getAffiliatesForSelect())
        }
    }, [affiliatesNameFilter])


    return (
        <Grid margin="0px 24px 24px" container>
            <Grid container spacing={1} columnSpacing={2} justifyContent='space-between' className={styles.Inputs}>
                <Grid item lg={3}>
                    <TextFieldLabel
                        autoComplete='off'
                        label='Address'
                        labelVariant='h6'
                        value={searchParameters?.address || ''}
                        onChange={(e) => {
                            onItemValueChanged(e.target.value, 'address')
                            setSearchParameters({ ...searchParameters, address: e.target.value })
                        }}
                        placeholder='Address'
                        size='small'
                    />
                </Grid>
                <Grid item lg={3}>
                    <SelectInput
                        label='Territory Manager'
                        options={territoryManagersDropdown}
                        placeholder='Territory Manager'
                        onChange={(newSelected: SelectInputOption) => {
                            onItemValueChanged(filterSearch(newSelected).key, 'territoryManager')
                            setSearchParameters({ ...searchParameters, territoryManager: filterSearch(newSelected) })
                        }}
                        value={territoryManagersDropdown[territoryManagersDropdown.findIndex(territory => territory.key === searchParameters?.territoryManager)] || undefined}
                        className={styles.Inputs__Select}
                        size='small'
                    />
                </Grid>
                <Grid item lg={3}>
                    <TextFieldLabel
                        autoComplete='off'
                        labelVariant='h6'
                        label='Vendor Search'
                        placeholder='Write Vendor Name or email...'
                        value={searchParameters?.vendor || ''}
                        onChange={(e) => {
                            onItemValueChanged(e.target.value, 'vendor')
                            setSearchParameters({ ...searchParameters, vendor: e.target.value })
                        }}
                        size='small'
                    />
                </Grid>
                <Grid item lg={3}>
                    <SelectInput
                        label='Territory'
                        options={territoriesDropdown}
                        placeholder='All territories'
                        onChange={(newSelected: SelectInputOption) => {
                            onItemValueChanged(filterSearch(newSelected).key, 'territory')
                            setSearchParameters({ ...searchParameters, territory: filterSearch(newSelected) })
                        }}
                        value={territoriesDropdown[territoriesDropdown.findIndex(territory => territory.key === searchParameters?.territory)] || undefined}
                        className={styles.Inputs__Select}
                        size='small'
                    />
                </Grid>
                <Grid item container spacing={2} lg={3}>
                    <Grid item lg={4}>
                        <TextField
                            autoComplete='off'
                            value={searchParameters?.city || ''}
                            placeholder='City'
                            onChange={(e) => {
                                onItemValueChanged(e.target.value, 'city')
                                setSearchParameters({ ...searchParameters, city: e.target.value })
                            }}
                            size='small'
                            fullWidth
                        />
                    </Grid>
                    <Grid item lg={4}>
                        <TextField
                            autoComplete='off'
                            value={searchParameters?.state || ''}
                            placeholder='State'
                            onChange={(e) => {
                                onItemValueChanged(e.target.value, 'state')
                                setSearchParameters({ ...searchParameters, state: e.target.value })
                            }}
                            size='small'
                            fullWidth
                        />
                    </Grid>
                    <Grid item lg={4}>
                        <TextField
                            autoComplete='off'
                            value={searchParameters?.zipCode || ''}
                            placeholder='Zip Code'
                            onChange={(e) => {
                                onItemValueChanged(e.target.value, 'zipCode')
                                setSearchParameters({ ...searchParameters, zipCode: e.target.value })
                            }}
                            size='small'
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Grid item lg={3}>
                    {/*
<SelectInput
                        label='CSM Name'
                        options={territoriesSuccessDropdown}
                        placeholder='All CSM Names'
                        onChange={(newSelected: SelectInputOption) => {
                            onItemValueChanged(filterSearch(newSelected).key, 'successManager')
                            setSearchParameters({ ...searchParameters, successManager: filterSearch(newSelected) })
                        }}
                        value={territoriesSuccessDropdown[territoriesSuccessDropdown.findIndex(successManager => successManager.key === searchParameters?.successManager)] || undefined}
                        className={styles.Inputs__Select}
                        size='small'
                    />
                    */
                    }


                    <FieldsFilter
                        flexColumn
                        handleChange={handleChangeCSNameType}
                        inputText='All CSM Name:'
                        //options={territoriesSuccessDropdown}
                        options={CSNameSearchFilter}
                        renderAsInput
                        label='CSM Name:'
                        popupLabel='Select CSM Name'
                        variant='body1'
                    />


                </Grid>
                <Grid item lg={3}>
                    <TextFieldLabel
                        autoComplete='off'
                        labelVariant='h6'
                        label='Client Search'
                        placeholder='Write Client Name or email...'
                        value={searchParameters?.client || ''}
                        onChange={(e) => {
                            onItemValueChanged(e.target.value, 'client')
                            setSearchParameters({ ...searchParameters, client: e.target.value })
                        }}
                        size='small'
                    />
                </Grid>
                <Grid item lg={3}>
                    <TextFieldLabel
                        autoComplete='off'
                        labelVariant='h6'
                        label='Job ID'
                        placeholder='Write job ID here...'
                        value={searchParameters?.search || ''}
                        onChange={(e) => {
                            onItemValueChanged(e.target.value, 'search')
                            setSearchParameters({ ...searchParameters, search: e.target.value })
                        }}
                        size='small'
                    />
                </Grid>
                <Grid item lg={3} className={styles.Inputs__statusSearch}>
                    <SelectInput
                        label='Status Search'
                        onChange={(newSelected: any) => {
                            onItemValueChanged(newSelected, 'statusSearch')
                            setSearchParameters({ ...searchParameters, statusSearch: newSelected })
                        }}
                        value={searchParameters?.statusSearch || []}
                        multiple
                        options={STATUS_OPTIONS.map((option) => option.value)}
                        freeSolo
                        size='small'
                    />
                </Grid>
                <Grid item lg={1.5}>
                    <SelectInput
                        label='Channel'
                        options={ALL_AFFILIATE_TYPE_OPTIONS}
                        placeholder='Channel'
                        onChange={(newSelected: SelectInputOption) => {
                            onItemValueChanged(filterSearch(newSelected).key, 'affiliateType')
                            setSearchParameters({ ...searchParameters, affiliateType: (newSelected) })
                        }}
                        value={ALL_AFFILIATE_TYPE_OPTIONS[ALL_AFFILIATE_TYPE_OPTIONS.findIndex(affiliateType => affiliateType.key === searchParameters?.affiliateType)] || undefined}
                        className={styles.Inputs__Select}
                        size='small'
                    />
                </Grid>
                <Grid item lg={1.5}>
                    <SelectInput
                        label='Work Order Status'
                        options={ALL_WORK_ORDER_STATUS_OPTIONS}
                        placeholder='Work Order Status'
                        onChange={(newSelected: SelectInputOption) => {
                            onItemValueChanged(filterSearch(newSelected).key, 'orderType')
                            setSearchParameters({ ...searchParameters, orderType: (newSelected) })
                        }}
                        value={ALL_WORK_ORDER_STATUS_OPTIONS[ALL_WORK_ORDER_STATUS_OPTIONS.findIndex(orderType => orderType.key === searchParameters?.orderType)] || undefined}
                        className={styles.Inputs__Select}
                        size='small'
                    />
                </Grid>
                <Grid item lg={3}>
                    <Typography variant='h6'>ETA:</Typography>
                    <DatePicker
                        onChange={(rangeSelected: Range) => {
                            onChangeDatePicker('expectedCompletedOn', rangeSelected)
                        }}
                        value={searchParams.expectedCompletedOnStartDate
                            ? {
                                startDate: new Date(searchParams.expectedCompletedOnStartDate as unknown as string),
                                endDate: new Date(searchParams.expectedCompletedOnEndDate as unknown as string)
                            }
                            : {}}
                        allowRange
                        className={styles.Inputs__Select}
                        size='small'
                    />
                </Grid>
                <Grid item lg={3}>
                    <Typography variant='h6'> Client Deadline Date: </Typography>
                    <DatePicker
                        onChange={(rangeSelected: Range) => {
                            onChangeDatePicker('deadline', rangeSelected)
                        }}
                        value={searchParams.deadlineStartDate
                            ? {
                                startDate: new Date(searchParams.deadlineStartDate as unknown as string),
                                endDate: new Date(searchParams.deadlineEndDate as unknown as string)
                            }
                            : {}}
                        allowRange
                        className={styles.Inputs__Select}
                        size='small'
                    />
                </Grid>
                <Grid item lg={3}>
                    {/* <TextFieldLabel
                        autoComplete="off"
                        labelVariant="h6"
                        label="Task Search:"
                        placeholder="Write status here..."
                        value={searchParameters?.taskSearch || ''}
                        onChange={(e) => {
                            onItemValueChanged(e.target.value, "taskSearch");
                            setSearchParameters({ ...searchParameters, taskSearch: e.target.value })
                        }}
                        size="small"
                    /> */}
                    <FieldsFilter
                        flexColumn
                        handleChange={handleChangeTaskTypes}
                        inputText='All Task Search:'
                        options={tasksearchFilter}
                        renderAsInput
                        label='Task Search:'
                        popupLabel='Select Task to view'
                        variant='body1'
                    />

                </Grid>
                <Grid item lg={3}>
                    <FieldsFilter
                        flexColumn
                        handleChange={handleChangeAffiliatesNameType}
                        inputText='All Affiliate Name:'
                        options={affiliatesNameFilter}
                        renderAsInput
                        label='Affiliate Name:'
                        popupLabel=''
                        variant='body1'
                        contentAlign='left'
                        searchable={true}
                        searchFieldPlaceholder='Enter Affiliate name'
                        allSelection={false}
                    />
                </Grid>
                <Grid item lg={3}>
                    <Typography variant='h6'> Actual Completion Date:</Typography>
                    <DatePicker
                        onChange={(rangeSelected: Range) => {
                            onChangeDatePicker('completion', rangeSelected)
                        }}
                        value={searchParams.completionStartDate
                            ? {
                                startDate: new Date(searchParams.completionStartDate as unknown as string),
                                endDate: new Date(searchParams.completionEndDate as unknown as string)
                            }
                            : {}}
                        allowRange
                        className={styles.Inputs__Select}
                        size='small'
                    />
                </Grid>
                <Grid item lg={3}>
                    <Typography variant='h6'> Approved Date:</Typography>
                    <DatePicker
                        onChange={(rangeSelected: Range) => {
                            onChangeDatePicker('approval', rangeSelected)
                        }}
                        value={searchParams.approvalStartDate
                            ? {
                                startDate: new Date(searchParams.approvalStartDate as unknown as string),
                                endDate: new Date(searchParams.approvalEndDate as unknown as string)
                            }
                            : {}}
                        allowRange
                        className={styles.Inputs__Select}
                        size='small'
                    />
                </Grid>
            </Grid>
            <Grid container justifyContent='flex-end' alignItems='center' marginY={1.5}>
                <ActionButtons
                    setPageLoading={setTableLoading}
                    pageLoading={tableLoading}
                    handleJobSearch={fetchJobs}
                    resetFilters={() => {
                        dispatch(searchesActions.cleanSearchParams())
                        setSearchParameters({})

                        tasksearchFilter.map((item) => {
                            return item.hide = true
                        })

                        CSNameSearchFilter.map((item) => {
                            return item.hide = true
                        })
                        affiliatesNameFilter.map((item) => {
                            return item.hide = true
                        })
                    }}
                    searchParams={searchParameters}
                />
                <TablePagination
                    callToApi={(start, limit) => { fetchJobs(start, limit) }}
                    totalItems={totalJobs}
                    rowsPerPageOptions={ROWS_PER_PAGE_EXTENDED}
                />
            </Grid>
        </Grid>
    )
}

export default Inputs
