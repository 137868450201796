import React, { ReactElement } from 'react'
import { Typography, Grid } from "@mui/material"

const Toast = ({ contactNum }: { contactNum: number }): ReactElement => (
  <Grid container flexDirection="column" spacing={0.5}>
    <Typography
      sx={{
        fontFamily: 'Moranga',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '18px',
        lineHeight: '30px',
        color: '#1A2A55',
      }}
    >
      Request sent
    </Typography>
    <Typography
      sx={{
        fontFamily: 'NN Nouvelle Grotesk',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#1A2A55',
      }}
    >
      {`Change Order Approval Request sent successfully to ${contactNum} contacts.`}
    </Typography>
  </Grid>
)

export default Toast