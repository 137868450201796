import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, NavigationMenu } from '../../../UI'
import useStyles from './styles'
import { TemplateProps } from '../types'
import { OverlayLoader, ScrollWrapper } from '../..'
import { getConfigValue } from '../../../../ducks/selectors'
import { configActions } from '../../../../ducks/actions'

const Template: FC<TemplateProps> = ({ children, hasPadding }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const overlayLoading = useSelector(getConfigValue('overlayLoading'))
  useEffect(() => {
    dispatch(configActions.setConfigValue({ type: 'overlayLoading', value: false }))
    dispatch(configActions.setConfigValue({ type: 'redirectPath', value: '/home' }))
  }, [dispatch])

  return (
    <OverlayLoader loading={overlayLoading}>
      <Box className={classes.root}>
        <NavigationMenu />
        <ScrollWrapper>
          <Box className={classes.content} padding={hasPadding ? '24px' : '0'}>
            {children}
          </Box>
        </ScrollWrapper>
      </Box>
    </OverlayLoader>
  )
}

export default Template
