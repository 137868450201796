import { Button, CircularProgress, Grid, Typography } from '@mui/material'
import React, { FC, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import DropDown from 'src/components/UI/CustomUI/atoms/DropDown'
import Modal from 'src/components/UI/CustomUI/molecules/Modal'
import { searchesActions } from 'src/ducks/actions'
import { getSearches } from 'src/ducks/searches/selectors'
import Folder from '../Folder'
import { isEmpty, RESOURCE_TYPE } from 'src/helpers'

import styles from './styles.module.scss'
import { FoldersProps } from './types'
import { TextFieldLabel } from 'src/components/UI'

const Folders: FC<FoldersProps> = ({ setPageLoading }) => {
  const searches = useSelector(getSearches(), shallowEqual)
  const [buttonLoading, setButtonLoading] = useState(false)
  const [addFolderModal, setAddFolderModal] = useState(false)
  const [folderName, setFolderName] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [folders, setFolders] = useState(searches.folders)

  const dispatch = useDispatch()

  const handleDelete = () => {
    dispatch(
      searchesActions.removeSearch({ folders }, RESOURCE_TYPE.PRO, (success) => {
        setOpenModal(false)
        if (success) {
          setButtonLoading(false)
          dispatch(searchesActions.fetchSearches(RESOURCE_TYPE.PRO))
        }
      })
    )
  }

  const handleDeleteSearch = (searchId: number, folderId: number) => {
    const newArray = [...folders]
    newArray[folderId].searches[searchId] = {
      ...newArray[folderId].searches[searchId],
      toDelete: true,
    }
    setFolders(newArray)
  }

  const handleDeleteFolder = (folderId: number) => {
    const newArray = [...folders]
    newArray[folderId].toDelete = true
    setFolders(newArray)
  }

  const handleCreateFolder = () => {
    if (!folderName) return
    setButtonLoading(true)
    const request = {
      name: folderName,
      searches: [],
    }
    dispatch(
      searchesActions.createFolder(request, RESOURCE_TYPE.PRO, (success) => {
        setFolderName('')
        setAddFolderModal(false)
        setButtonLoading(false)
        if (success) {
          dispatch(searchesActions.fetchSearches(RESOURCE_TYPE.PRO))
        }
      })
    )
  }

  const handleCleanModal = () => {
    setOpenModal(false)
    setFolders(searches.folders)
  }

  useEffect(() => {
    setFolders(searches.folders)
  }, [searches])

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      className={styles.Folders}
    >
      <Modal
        title="Let’s create your folder"
        open={addFolderModal}
        setOpen={setAddFolderModal}
        size="xs"
      >
        <Grid display="flex" direction="column" gap={3}>
          <Grid item xs={12}>
            <TextFieldLabel
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setFolderName(event.target.value)
              }
              value={folderName}
              autoFocus
              label="Name your folder"
              placeholder="Folder Name"
              labelVariant="h6"
            />
          </Grid>
          <Grid container item xs={12} spacing={1} direction="column">
            <Grid item>
              <Button
                variant="containedBig"
                sx={{ width: '100%' }}
                onClick={handleCreateFolder}
                endIcon={buttonLoading ? '' : <Icon name="Save" />}
                disabled={!folderName}
              >
                {buttonLoading ? (
                  <CircularProgress color="info" size="1.4rem" />
                ) : (
                  'Save Folder'
                )}
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={() => {
                  setAddFolderModal(false)
                  setFolderName('')
                }}
                variant="outlinedBig"
                sx={{ width: '100%' }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
      {searches.folders.length ? (
        <Grid item display="flex" className={styles.Folders__Container}>
          {searches.folders.map((folder, index) => (
            <Folder
              key={index}
              folder={folder}
              setPageLoading={setPageLoading}
            />
          ))}
        </Grid>
      ) : (
        <Grid item>
          <Typography variant="h5" color={(theme) => theme.palette.gray[700]}>
            No saved search. Let’s create your first folder.
          </Typography>
        </Grid>
      )}
      <Grid item>
        <Button
          sx={{ fontSize: 16, height: '36px' }}
          variant="outlined"
          onClick={() => setAddFolderModal(true)}
        >
          Add a folder
          <Icon name="Add" sx={{ margin: '5px' }} />
        </Button>
        {!isEmpty(folders) && (
          <Button
            sx={{ fontSize: 16, height: '36px' }}
            color="infoText"
            variant="text"
            onClick={() => setOpenModal(true)}
          >
            Settings
            <Icon name="Settings" sx={{ margin: '5px' }} />
          </Button>
        )}
      </Grid>
      {openModal && (
        <Modal
          setOpen={setOpenModal}
          open={openModal}
          title="Folder Settings"
          className={styles.Modal}
        >
          <Grid container spacing={2}>
            <Grid item container direction="column">
              {folders.map(
                (folder, index) =>
                  !folder.toDelete && (
                    <DropDown
                      key={index}
                      title={
                        <Grid
                          container
                          alignItems="flex-start"
                          justifyContent="space-between"
                          sx={{ alignItems: 'center' }}
                        >
                          <Folder
                            key={index}
                            folder={folder}
                            showSearches={false}
                          />
                          <Icon
                            onClick={() => {
                              handleDeleteFolder(folder.id)
                            }}
                            name="Delete"
                            color="var(--error-color)"
                          />
                        </Grid>
                      }
                      className={styles.Folders__DropDown}
                    >
                      <ul style={{ paddingLeft: '1rem' }}>
                        {folder.searches.map((search, index) => {
                          return !search.toDelete ? (
                            <Grid
                              container
                              alignItems="flex-start"
                              justifyContent="space-between"
                              key={index}
                              style={{ marginBottom: '.5rem' }}
                              flexWrap="nowrap"
                            >
                              <Typography color="var(--gray500)">
                                {search.name}
                              </Typography>
                              <Icon
                                onClick={() => {
                                  handleDeleteSearch(search.id, folder.id)
                                }}
                                name="DeleteOutlined"
                                color="var(--error-color)"
                              />
                            </Grid>
                          ) : (
                            <Grid key={index} />
                          )
                        })}
                      </ul>
                    </DropDown>
                  )
              )}
            </Grid>
            <Grid container item spacing={1} direction="column">
              <Grid item>
                <Button
                  variant="contained"
                  sx={{ width: '100%' }}
                  onClick={handleDelete}
                >
                  {buttonLoading ? (
                    <CircularProgress color="info" size="1.4rem" />
                  ) : (
                    'Save Changes'
                  )}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={handleCleanModal}
                  variant="outlined"
                  sx={{ width: '100%' }}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Modal>
      )}
    </Grid>
  )
}

export default Folders
