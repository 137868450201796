import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Table from 'src/components/UI/CustomUI/organisms/Table'
import { historyActions } from 'src/ducks/actions'
import { getJobHistoryStatus } from 'src/ducks/history/selectors'
import { getEstimate } from 'src/ducks/selectors'
import headers from './headers'

const Status = ({ id }: { id?: string }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const jobHistoryStatus = useSelector(getJobHistoryStatus)
  const estimate = useSelector(getEstimate())

  const fetchEstimateHistoryStatus = useCallback(() => {
    if (id) {
      dispatch(
        historyActions.fetchHistoryStatus(id, (_succ: boolean) => {
          setLoading(false)
        })
      )
    }
  }, [dispatch, id])

  useEffect(() => {
    fetchEstimateHistoryStatus()
  }, [fetchEstimateHistoryStatus, estimate])

  return (
    <Table
      headers={headers}
      title="STATUS HISTORY"
      loading={loading}
      small
    >
      {jobHistoryStatus}
    </Table>
  )
}

export default Status;