import { JobHistory, OrderHistory, RemovedItem } from '../types'

/** TYPES **/
export const historyTypes = {
  FETCH_HISTORY_JOB_TASKS: 'FETCH_HISTORY_JOB_TASKS',
  FETCH_HISTORY_JOB: 'FETCH_HISTORY_JOB',
  FETCH_HISTORY_STATUS: 'FETCH_HISTORY_STATUS',
  SET_HISTORY_VALUE: 'SET_HISTORY_VALUE',
  FETCH_JOB_ORDERS_HISTORY: 'FETCH_JOB_ORDERS_HISTORY',
  FETCH_REMOVED_ITEMS_HISTORY: 'FETCH_REMOVED_ITEMS_HISTORY',
  FETCH_HISTORY_ESTIMATES: 'FETCH_HISTORY_ESTIMATES'
}

/** ACTIONS **/
export interface FetchHistoryJobTasksAction {
  type: typeof historyTypes.FETCH_HISTORY_JOB_TASKS
  payload: string
  callback?: (succ: boolean) => void
}

export interface FetchHistoryJobAction {
  type: typeof historyTypes.FETCH_HISTORY_JOB
  payload: string
  callback?: (succ: boolean) => void
}
export interface FetchJobHistoryStatusAction {
  type: typeof historyTypes.FETCH_HISTORY_STATUS
  payload: string
  callback?: (succ: boolean) => void
}

export interface SetHistoryValueAction {
  type: typeof historyTypes.SET_HISTORY_VALUE
  payload: HistoryValue
}

export interface FetchEstimateHistoryAction {
  type: typeof historyTypes.FETCH_HISTORY_ESTIMATES
  payload: {
    estimateId: string
    itemTitle: string
    event: string
  }
  callback?: (succ: boolean) => void
}

export type HistoryActions =
  | FetchHistoryJobTasksAction
  | FetchHistoryJobAction
  | FetchJobHistoryStatusAction
  | SetHistoryValueAction
  | FetchEstimateHistoryAction

export interface HistoryValue {
  attr: keyof HistoryType
  value: any
}

/** REDUCER **/
export interface HistoryType {
  historyJobTasks: HistoryJobTask[]
  jobHistory: JobHistory[],
  jobHistoryStatus: OrderHistory[],
  orderHistory: JobHistory[],
  historyRemovedItems: RemovedItem[]
}

export interface HistoryJobTask {
  completedBy: string
  completedByName: string
  completedByUserType: string
  completedOn: number
  completionAction: string
  id: string
  jobId: string
  orderId: string
  orderName: string
  startedOn: number
  taskName: string
  triggerDate: number
  type: string
}

