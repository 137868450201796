import { authTypes } from '../auth/types'
import { WidgetsActions, WidgetsType, widgetsTypes } from './types'

const initialState: WidgetsType = []

const reducer = (state = initialState, action: WidgetsActions): WidgetsType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case widgetsTypes.SET_WIDGETS: {
      return payload as WidgetsType
    }

    default:
      return state
  }
}

export default reducer
