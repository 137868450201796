import { InvoiceFileRequest, InvoiceType } from '~api/types'

/** TYPES **/
export const invoiceTypes = {
  GET_INVOICE_PREVIEW: 'GET_INVOICE_PREVIEW',
  SET_INVOICE: 'SET_INVOICE',
  SET_INVOICE_PREVIEW: 'SET_INVOICE_PREVIEW',
  CREATE_INVOICE: 'CREATE_INVOICE',
  RESET_INVOICE: 'RESET_INVOICE',
  SET_INVOICE_PREVIEW_PATH_FILE: 'SET_INVOICE_PREVIEW_PATH_FILE',
  GET_INVOICE_PREVIEW_PATH_FILE: 'GET_INVOICE_PREVIEW_PATH_FILE',
}

/** ACTIONS **/
export interface GetInvoicePreviewAction {
  type: typeof invoiceTypes.GET_INVOICE_PREVIEW
  //payload: string
  payload: {
    params?: Partial<GetInvoicePreviewParams>
  }
  callback?: (succ: boolean,amountTotal:number,tax:number,paid:number,outstandingBalance:number,subTotal:number) => void
}

export interface SetInvoicePreviewAction {
  type: typeof invoiceTypes.SET_INVOICE_PREVIEW
  payload: Invoice
}

export interface SetInvoiceAction {
  type: typeof invoiceTypes.SET_INVOICE
  payload: Invoice
}

export interface CreateInvoiceAction {
  type: typeof invoiceTypes.CREATE_INVOICE
  payload: {
    params: CreateInvoiceParams
    request: Partial<InvoiceType>
  }
  callback: (succ: boolean) => void
}

export interface ResetStateAction {
  type: typeof invoiceTypes.RESET_INVOICE,
  payload: null
}

export interface SetInvoicePreviewPathFileAction {
  type: typeof invoiceTypes.SET_INVOICE_PREVIEW_PATH_FILE
  payload: string
}

export interface GetInvoicePreviewPathFileAction {
  type: typeof invoiceTypes.GET_INVOICE_PREVIEW_PATH_FILE
  payload: InvoiceFileRequest
  callback?: (succ: boolean,pathFile:string) => void
}



export type InvoiceActions =
  | GetInvoicePreviewAction
  | SetInvoicePreviewAction
  | SetInvoiceAction
  | SetInvoicePreviewAction
  | CreateInvoiceAction
  | ResetStateAction
  | SetInvoicePreviewPathFileAction
  | GetInvoicePreviewPathFileAction

/** REDUCER **/

export interface CreateInvoiceParams {
  recipients: string,
  subject: string,
}

export interface Invoice {
  invoicePreview: InvoiceType,
  invoicePath: string
}

export interface InvoiceValue {
  attr: keyof InvoiceType
  value: any
}

export interface GetInvoicePreviewParams {
  jobId: string,
  depositAmount?:number
}
