import { authTypes } from '../auth/types'
import { OrderType } from '../orders/types'
import { orderTypes, Order, OrderActions, OrderValue } from './types'

const initialState: Order = null

const reducer = (state = initialState, action: OrderActions): Order => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case orderTypes.SET_ORDER: {
      const rest = state || {}
      return {
        ...rest as OrderType,
        ...payload as Order
      }
    }

    case orderTypes.SET_ORDER_VALUE: {
      const { attr, value } = payload as OrderValue
      const rest = state || {}
      return {
        ...rest as OrderType,
        [attr]: value
      }
    }

    default:
      return state
  }
}

export default reducer
