/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useState } from 'react'
import { Button, Grid, LabeledText, Typography, CircularProgress } from 'src/components/UI'
import { WorkOrdersContext } from '../../context'
import theme from 'src/components/UI/MaterialUI/theme'
import { useSelector, useDispatch } from 'react-redux'
import { getOrderById, getJob } from 'src/ducks/selectors'
import moment from 'moment'
import { formatDateTimeZone, isEmpty, round } from 'src/helpers'
import { itemsActions, ordersActions } from 'src/ducks/actions'
import { useParams } from 'react-router-dom'
import { ORDER_STATUS, formatTimestampDate } from 'src/helpers'

const Line: FC<{
  bg: string, color: string, success?: boolean, showButton?: boolean, buttonText?: string,
  upRight?: string | JSX.Element, upLeft?: string | JSX.Element,
  downRight?: string | JSX.Element, downLeft?: string | JSX.Element,
  buttonClick?: () => void, buttonLoading?: boolean, label?: string
}> = ({
  bg, color, success = false, showButton = false, buttonText = '', label = '',
  upRight, upLeft, downRight, downLeft, buttonClick, buttonLoading = false
}) => {
    return (
      <Grid
        container
        sx={{
          borderTop: '2px solid var(--border-color)',
          padding: '16px',
          backgroundColor: bg,
          display: 'grid',
          gridAutoFlow: 'column',
          gridTemplateColumns: ' min-content min-content auto',
          color: color
        }}>
        <Grid container item spacing={1} width='325px'>
          <Grid item xs={12} sx={{ width: 'max-content' }}>{upLeft}</Grid>
          <Grid item xs={12} sx={{ width: 'max-content' }}>{downLeft}</Grid>
        </Grid>
        <Grid container item spacing={1} sx={{ paddingLeft: 6 }}>
          <Grid item xs={12} sx={{ width: 'max-content' }}>{upRight}</Grid>
          <Grid item xs={12} sx={{ width: 'max-content' }}>{downRight}</Grid>
        </Grid>
        {!isEmpty(label) && (
          <Grid item sx={{
            display: 'flex', width: '100%', paddingRight: '42px',
            justifyContent: 'end', alignItems: 'center'
          }}>
            <Typography variant='h4' color='primary'>{label}</Typography>
          </Grid>)
        }
        {showButton && (
          <Grid item sx={{ display: 'flex', width: '100%', justifyContent: 'end', alignItems: 'center' }}>
            <Button
              sx={{
                width: '150px', ...(success ? {
                  backgroundColor: theme.palette.green[700],
                  borderColor: theme.palette.green[700],
                  '&:hover': {
                    backgroundColor: theme.palette.green[800],
                    borderColor: theme.palette.green[800],
                  }
                } : {})
              }}
              variant='contained'
              onClick={buttonClick}
            >
              {buttonLoading
                ? <CircularProgress sx={{ color: 'white' }} size={24} />
                : buttonText
              }
            </Button>
          </Grid>
        )}
      </Grid>
    )
  }

const Status: FC = () => {
  const dispatch = useDispatch()
  const [buttonLoading, setButtonLoading] = useState(false)

  const {
    dispatch: dispatchContext,
    state: { selectedTab, selectedOrderId }
  } = useContext(WorkOrdersContext)

  const { id } = useParams()
  const order = useSelector(getOrderById(selectedOrderId as string))

  const {
    dispatchedTimestamp, checkedInTimestamp, completedTimestamp, paymentAmount,
    scheduledStartTimestamp, scheduledEndTimestamp, bidPrice, vendorPaymentTimestamp,
    companyContact, companyName, statusType, csmAcceptanceTimestamp,
    completedByName, acceptedByName, vendorPaidByName, paymentType
  } = order


  const job = useSelector(getJob())
  const timeZone = job?.properties?.address?.timeZone ? job.properties.address.timeZone : ""

  const showPaid = statusType && (ORDER_STATUS.PAID === statusType || vendorPaymentTimestamp != null)
  const showAccepted = statusType && (ORDER_STATUS.COMPLETE === statusType || showPaid || csmAcceptanceTimestamp != null)
  const showComplete = statusType && (ORDER_STATUS.REVIEW === statusType || showAccepted || completedTimestamp != null)
  const showCheckedin = statusType && ([ORDER_STATUS.DISPATCHED, ORDER_STATUS.IN_PROGRESS, ORDER_STATUS.PENDING, ORDER_STATUS.PARENT_REVIEW].includes(statusType) || showComplete)

  const showCreateBillButton = !showPaid
  const showAcceptButton = !showAccepted
  const showMarkCompleteButton = checkedInTimestamp !== null && !showComplete
  const showCheckedinButton = !checkedInTimestamp && statusType !== ORDER_STATUS.PENDING && !showComplete
  const showBidSentLabel = statusType === ORDER_STATUS.PENDING && !showCheckedinButton

  if (!selectedOrderId || !selectedTab) return null

  return (
    <>
      {showPaid && (
        <Line
          color='var(--orange700)'
          bg='var(--orange100)'
          upRight={<Typography variant='body1Bold'>{`Amount: $${round(paymentAmount, 2)} - ${paymentType && paymentType === "CREDITCARD" ? 'Check/Cash/Credit Card' : 'QuickBooks Bill Pay'}`}</Typography>}
          upLeft={<Typography variant='body1Bold'>Payment Approved</Typography>}
          downRight={`Payment Approved by ${vendorPaidByName ?? '...'}`}
          downLeft={vendorPaymentTimestamp ? formatDateTimeZone(vendorPaymentTimestamp, timeZone, "EEE LLL do y hh:mm aa zzz") : ''}
        />
      )}
      {showAccepted && (
        <Line
          color='var(--green800)'
          bg='var(--green100)'
          success
          showButton={showCreateBillButton}
          buttonText='Create Bill'
          buttonClick={() => {
            dispatchContext({ type: 'SET_MODAL_OPEN', payload: true })
            dispatchContext({ type: 'SET_MODAL_TYPE', payload: "CREATE_BILL" })
          }}
          upRight={
            <Typography variant='body1Bold'>
              {`Days to accept: ${moment.unix(csmAcceptanceTimestamp ?? 0).diff(moment.unix(completedTimestamp ?? 0), 'days') + 1}`}
            </Typography>
          }
          upLeft={<Typography variant='body1Bold'>Work Order Accepted</Typography>}
          downRight={`Marked approved by ${acceptedByName ?? '...'}`}
          downLeft={csmAcceptanceTimestamp ? formatDateTimeZone(csmAcceptanceTimestamp, timeZone, "EEE LLL do y hh:mm aa zzz") : ''}
        />
      )}
      {showComplete && (
        <Line
          color='var(--lightBlue800)'
          bg='var(--lightBlue100)'
          showButton={showAcceptButton}
          buttonText='Accept'
          buttonLoading={buttonLoading}
          buttonClick={() => {
            setButtonLoading(true)
            dispatch(ordersActions.updateOrderState(
              { orderId: selectedOrderId ?? '', orderState: 'accepted' },
              (success) => {
                if (success) {
                  setButtonLoading(false)
               /*    dispatch(ordersActions.fetchOrders(
                    { params: { jobId: id, page: 0, size: 9999 } },
                    (_suc) => { setButtonLoading(false) }
                  )) */
                } else setButtonLoading(false)
              }
            ))
          }}
          upRight={
            <Typography variant='body1Bold'>
              {completedTimestamp && `Days to complete: ${moment.unix(completedTimestamp).diff(moment.unix(dispatchedTimestamp ?? 0), 'days') + 1}`}
            </Typography>
          }
          upLeft={<Typography variant='body1Bold'>Work Order Complete</Typography>}
          downRight={`Marked complete by ${completedByName ?? '...'}`}
          downLeft={completedTimestamp ? formatDateTimeZone(completedTimestamp, timeZone, "EEE LLL do y hh:mm aa zzz") : ''}
        />
      )}
      {showCheckedin && (
        <Line
          color='var(--navy700)'
          bg='var(--gray100)'
          showButton={showCheckedinButton || showMarkCompleteButton}
          buttonText={showMarkCompleteButton ? 'Mark Complete' : 'Checkin Vendor'}
          buttonLoading={buttonLoading}
          label={showBidSentLabel ? 'Bid Sent' : ''}
          buttonClick={() => {
            setButtonLoading(true)
            dispatch(ordersActions.updateOrderState(
              {
                orderId: selectedOrderId ?? '',
                orderState: showMarkCompleteButton ? 'complete' : 'checkin'
              },
              (success) => {
                if (success) {
                  setButtonLoading(false)
                  /* dispatch(ordersActions.fetchOrders(
                    { params: { jobId: id, page: 0, size: 9999 } },
                    (_suc) => {
                      dispatch(itemsActions.fetchItemsByCategory({
                        params: { jobId: id, orderId: selectedOrderId || '' }
                      }, (_succ) => {
                        setButtonLoading(false)
                      }))
                    }
                  )) */
                } else setButtonLoading(false)
              }
            ))
          }}
          upRight={checkedInTimestamp && checkedInTimestamp > 0
            ? <LabeledText labelValue='Checked In:' textValue={formatDateTimeZone(checkedInTimestamp, timeZone, "EEE LLL do y hh:mm aa zzz")} />
            : <Typography variant='body1Bold' fontStyle='italic'>Not Checked In</Typography>}
          upLeft={`${companyContact} (${companyName})`}
          downRight={<LabeledText labelValue='Bid Price:' textValue={`$${round(bidPrice, 2)}`} />}
          downLeft={`
            ${scheduledStartTimestamp ? formatDateTimeZone(scheduledStartTimestamp, timeZone, "EEE LLL do y hh:mm aa zzz") : ''}
            - ${scheduledEndTimestamp ? formatDateTimeZone(scheduledEndTimestamp, timeZone, "EEE LLL do y hh:mm aa zzz") : ''}    
          `}

        />
      )}
    </>
  )
}

export default Status