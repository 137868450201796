import { HeaderType } from 'src/components/UI/CustomUI/organisms/Table/types'
import { AlignType } from "src/components/UI/CustomUI/organisms/Table/types"
import { Button, Typography, Box, ExpandButton } from 'src/components/UI'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import { formatTimestamp, round } from 'src/helpers'

const headers: HeaderType[] = [
    {
        id: 'amount',
        label: 'Amount',
        custom: true,
        align: 'center' as AlignType,
        Element: (row: any): JSX.Element => {
            return (<Typography sx={{ fontSize: '14px' }}>
                {row?.amount ? `$${round(row?.amount, 2)}` : '-'}
            </Typography>)
        },
        hide: false
    },
    {
        id: 'status',
        label: 'Status',
        custom: true,
        align: 'center' as AlignType,
        Element: (row: any): JSX.Element => {
            const back = row && row?.status === "SUCCEEDED" ? '#EDFDF6' : 'var(--red100)'
            const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
                <Tooltip {...props} classes={{ popper: className }} />
            ))(({ theme }) => ({
                [`& .${tooltipClasses.tooltip}`]: {
                    backgroundColor: theme.palette.common.white,
                    color: 'rgba(0, 0, 0, 0.87)',
                    boxShadow: theme.shadows[1],
                    fontSize: 11,
                    maxWidth: 153
                },
                [`& .${tooltipClasses.arrow}`]: {
                    color: theme.palette.common.white
                }
            }))
            const customTitle = (
                <Box display='flex'>
                    <Icon
                        name='CheckCircle'
                        color={row?.status === "SUCCEEDED" ? 'var(--green900)' : 'var(--error-color)'}
                        sx={{ margin: '4px' }}
                    />
                    <Typography> {`Payment ${row?.status && row?.status?.toLowerCase()} at ${row?.createdOn && formatTimestamp(row?.createdOn, 'MM/DD/YYYY HH:mm')}`}</Typography>
                </Box >
            )

            return (<LightTooltip arrow
                title={customTitle}>
                <Box display='flex' justifyContent='space-between' minWidth='84px' sx={{ backgroundColor: back, padding: '2px' }}>
                    <Typography color={row?.status === "SUCCEEDED" ? 'var(--green900)' : 'var(--red600)'}
                        sx={{ fontSize: '14px' }}>
                        {row?.status === "SUCCEEDED" ? 'Succeeded' : 'Failed'}
                    </Typography>
                    {row?.status === "SUCCEEDED" ?
                        <Icon name='CheckCircle' color="var(--green900)" /> :
                        <Icon name='Cancel' color="var(--error-color)" />}
                </Box>
            </LightTooltip>
            )
        },
        hide: false
    },
    {
        id: 'address',
        label: 'Description'
    },
    {
        id: 'createdByName',
        label: 'Customer'
    },
    {
        id: 'createdOn',
        label: 'Date',
        type: 'datetime',
    },
    {
        id: 'actions',
        label: 'Actions',
        custom: true,
        align: 'center' as AlignType,
        maxWidth: 70,
        Element: (row: any): JSX.Element => {
            return <ExpandButton hideIcon text='Actions' >
                {/* added hideIcon by request of Paul: https://trello.com/c/PWNF9mrR/111-csm-payments */}
                <Button variant='text' size='small' style={{ padding: '4px', width: '140px' }} >
                    <Typography fontSize={12} sx={{ width: 1, textAlign: 'left' }}> All History </Typography>
                </Button>
                <Button variant='text' size='small' style={{ padding: '4px', width: '140px' }} >
                    <Typography fontSize={12} sx={{ width: 1, textAlign: 'left' }}> Last Month </Typography>
                </Button>
            </ExpandButton>
        },
        hide: false
    }
]

export default headers
