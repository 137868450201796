import { SearchParams } from '../searches/types'
import { EstimatesByCategoryAction, SetEstimatesByStageParam, estimatesByCategoryTypes } from './types'

const actions = {
  fetchEstimatesByStage: (
    payload: {
      stage: string,
      start: number,
      limit: number,
      searchParams: Partial<SearchParams>
    },
    callback?: (succ: boolean, limit?: number) => void
  ): EstimatesByCategoryAction => ({
    type: estimatesByCategoryTypes.FETCH_ESTIMATES_BY_STAGE,
    payload,
    callback,
  }),

  setEstimatesByStage: (payload: SetEstimatesByStageParam): EstimatesByCategoryAction => ({
    type: estimatesByCategoryTypes.SET_ESTIAMTES_BY_STAGE,
    payload,
  }),

  resetEstimatesByStage: (payload: { stage: string }): EstimatesByCategoryAction => ({
    type: estimatesByCategoryTypes.RESET_ESTIMATES_BY_STAGE,
    payload
  })
}

export default actions
