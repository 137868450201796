import { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import { Punchlist } from '../../api'
import { FetchMatchAction, matchTypes } from './types'
import { matchActions } from '../actions'
import { toast } from 'react-toastify'
import { errorTextTryingTo } from 'src/helpers'

export function *fetchMatch({
  payload,
  callback,
}: FetchMatchAction): SagaIterator {
  let success = true

  try {
    const { estimateId, itemId } = payload

    const match = yield call(Punchlist.matches.getEstimateItemMatch, estimateId, itemId)

    if (match) {
      yield put(matchActions.setMatch(match))
    }

    success = true

    if (callback) yield call(callback, success)
  } catch (err) {
    yield call(toast.error, errorTextTryingTo('get the match of estimate item'))

    if (callback) yield call(callback, false)
  }
}


export default function *saga(): SagaIterator {
  yield takeLatest(matchTypes.FETCH_MATCH, fetchMatch)
}
