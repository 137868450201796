import { Grid, Typography } from '@mui/material'
import { AlignType, HeaderType } from 'src/components/UI/CustomUI/organisms/Table/types'
import { formatDate } from 'src/helpers'

const headers: HeaderType[] = [
    {
        id: 'recipientEmail',
        label: 'Email',
        custom: true,
        align: 'center' as AlignType,
        Element: (row: any): JSX.Element => {
            return (
                <Grid>
                    <Typography fontSize="var(--font-XS)">
                        {row.recipientEmail}
                    </Typography>
                </Grid>
            )
        }
    },
    {
        id: 'status',
        label: 'Status',
        custom: true,
        Element: (row: any): JSX.Element => {
            return <Grid>
                <Typography fontSize="var(--font-XS)">
                    {row.status}
                </Typography>
            </Grid>
        }
    },
    {
        id: 'sentOn',
        label: 'Invite Date',
        custom: true,
        Element: (row: any): JSX.Element => {
            return <Grid>
                <Typography fontSize="var(--font-XS)">
                    {row.sentOn ? formatDate(row.sentOn * 1000, 'MM/DD/YYYY') : '-'}
                </Typography>
            </Grid>
        }
    },

]

export default headers