import { OrderType } from 'src/ducks/orders/types'
import useStyles from '../styles'
import { Box, Grid, Typography } from '@mui/material'
import { dateFormatString } from 'src/helpers'

const WorkOrderCard = ({
  order,
  showPercent,
}: {
  order: OrderType
  showPercent: boolean
}) => {
  const styles = useStyles()

  return (
    <Box display="flex" flexDirection="column" gap={1} width="100%">
      <Typography className={styles.location}>
        <b>WO NAME:</b> {order.orderName}
      </Typography>
      <Typography className={styles.location}>
        <b>DATE: </b>
        {order.dispatchedTimestamp
          ? dateFormatString(
            new Date(order.dispatchedTimestamp * 1000),
            'MM/DD/YYYY - hh:mmA'
          )
          : '-'}
      </Typography>
      <Typography className={styles.location}>
        <b>Pro NAME:</b> {order.companyContact ? order.companyContact : '-'}
      </Typography>
      {showPercent && (
        <Grid item container spacing={1}>
          <Grid item>
            <Typography>{`${order.percentItemsComplete}%`}</Typography>
          </Grid>
          <Grid item xs={9} alignItems="center" display="flex">
            <Box width="100%" height="3px" bgcolor="lightgrey">
              <Box
                height="100%"
                width={`${order.percentItemsComplete}%`}
                bgcolor="black"
              ></Box>
            </Box>
          </Grid>
          <Grid item>
            <Typography>{`(${order.numberOfCompletedItems}/${order.numberOfItems})`}</Typography>
          </Grid>
        </Grid>
      )}
    </Box>
  )
}

export default WorkOrderCard
