import TabNavigation from 'src/components/UI/CustomUI/organisms/TabNavigation'
import Customer from './Tabs/Customer'
import Internal from './Tabs/Internal'
import { Box, Typography } from 'src/components/UI'
import { icons } from 'src/assets'

const Notifications = () => {

  const UploadAction = () => {
    return (
      <Box display='flex'>
        <icons.NotificationsActive color='infoText' height='13px' width='13px' sx={{ margin: '0px 4px' }} />
        <Typography variant='h5'>Notifications</Typography>
      </Box>
    )
  }

  const Tabs = [
    {
      label: 'Customer',
      content: <Customer />,
      actionButton: UploadAction()
    },
    {
      label: 'Internal',
      content: <Internal />,
      actionButton: UploadAction()
    },
  ]

  return (
    <>
      <TabNavigation
        tabs={Tabs}
        variant='contained'
        tabWidth="fit-content"
        tabAlign="flex-end"
        defaultActive={0}
      />
    </>
  )
}

export default Notifications
