import { Box, Typography } from "@mui/material"
import { AlignType, HeaderType } from "src/components/UI/CustomUI/organisms/Table/types"

import styles from './styles.module.scss'
import { dateFormatString } from "src/helpers"

const headers: HeaderType[] = [
    {
      id: 'insertedOn',
      label: 'Date',
      custom: true,
      align: 'left' as AlignType,
      Element: (row: any): JSX.Element => {
        return (
          <Box padding="2px 10px">
            <Typography className={styles.Communications__createdOn}>
              {dateFormatString(new Date(row.insertedOn * 1000), 'MM-DD-YYYY hh:mm:ss a')}
            </Typography>
          </Box>
        )
      },
    },
    {
      id: 'sender',
      label: 'From',
      custom: true,
      align: 'left' as AlignType,
      Element: (row: any): JSX.Element => {
        return (
          <Box padding="2px 10px">
            <Typography className={styles.Communications__description}>
              {row.sender.email}
            </Typography>
          </Box>
        )
      },
    },
    {
      id: 'recipient',
      label: 'To',
      custom: true,
      align: 'left' as AlignType,
      Element: (row: any): JSX.Element => {
        return (
          <Box padding="2px 10px">
            <Typography className={styles.Communications__description}>
              {row.recipient.email}
            </Typography>
          </Box>
        )
      },
    },
    {
      id: 'subject',
      label: 'Subject',
      custom: true,
      align: 'left' as AlignType,
      Element: (row: any): JSX.Element => {
        return (
          <Box padding="2px 10px">
            <Typography className={styles.Communications__description}>
              {row.subject}
            </Typography>
          </Box>
        )
      },
    },
    {
      id: 'event',
      label: 'Event',
      custom: true,
      align: 'left' as AlignType,
      Element: (row: any): JSX.Element => {
        return (
          <Box padding="2px 10px">
            <Typography className={styles.Communications__description}>
              {row.event}
            </Typography>
          </Box>
        )
      },
    },
  ]
  
  export default headers
  