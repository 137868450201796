import { Theme } from '../../../UI'
import { makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => ({
  header: {
    justifyContent: 'space-between'
  },
  title: {
    fontSize: '18px !important',
    fontWeight: 500,
    lineHeight: '28px !important',
  },
  submitBtn: {
    backgroundColor: 'var(--green700) !important',
    color: 'white !important',
    borderRadius: '32px !important',
    boxShadow: '0px 0px 1px 0px rgba(26, 42, 85, 0.31), 0px 3px 5px 0px rgba(26, 42, 85, 0.20)',
    width: '127px',
    height: '40px !important',
    fontSize: '14px !important',
    lineHeight: '22px !important'
  },
  addBtn: {
    backgroundColor: 'var(--blue700) !important',
    color: 'white !important',
    borderRadius: '32px !important',
    boxShadow: '0px 0px 1px 0px rgba(26, 42, 85, 0.31), 0px 3px 5px 0px rgba(26, 42, 85, 0.20)',
    width: '127px',
    height: '40px !important',
    fontSize: '14px !important',
    lineHeight: '22px !important'
  },
  statsPanelToggleSelected: {
    width: '40px',
    height: '40px !important',
    backgroundColor: 'var(--blue700) !important',
    color: 'white !important',
    borderRadius: '8px !important',
    border: 'white solid 1px !important',
  },
  statsPanelToggleUnselected: {
    width: '40px',
    height: '40px !important',
    backgroundColor: 'white !important',
    color: 'var(--blue700) !important',
    border: 'var(--blue700) solid 1px !important',
    borderRadius: '8px !important'
  },
}))
