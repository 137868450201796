import React, { useReducer } from 'react'
import { WorkOrdersReducer } from './reducer'
import { WorkOrdersContextType } from './types'

const orderOptions = [{ key: 'recent', label: 'Most recent to last updated' },
{ key: 'updated', label: 'Last updated to most recent' }]

const initialState = {
  // GENERAL
  refresh: true,
  uploadingFiles: false,
  // MODAL
  modalType: null,
  modalOpen: false,
  tabSelected: 0,
  // ORDERS
  selectedOrderId: null,
  selectedTab: 0,
  // ITEMS
  selectedItemId: null,
  selectedItemsIds: [],
  selectedWorkItem: null,
  checkAll: false,
  expandAll: true,
  workItems: [],
  searchFilter: '',
  order: orderOptions[0]
}

export const WorkOrdersContext = React.createContext<{
  state: WorkOrdersContextType;
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null
})

export const ReviewProvider: React.FunctionComponent = ({ children }: any) => {
  const [state, dispatch] = useReducer(WorkOrdersReducer, initialState)

  return (
    <WorkOrdersContext.Provider value={{ state, dispatch }}>
      {children}
    </WorkOrdersContext.Provider>
  )
}
