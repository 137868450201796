import storeInfo from '../store'
import { NavBar } from './types'
import { JobItem } from '../types'

import { SelectInputOption } from 'src/components/UI/CustomUI/atoms/SelectInput/types'
import { getOptionsFromArray } from 'src/helpers'

export const getNavbarJobs = () => (): NavBar => {
  const { navbar } = storeInfo.store.getState()
  return navbar
}

export const getNavbarJobsSelector = (): SelectInputOption[] => {
  const { navbar } = storeInfo.store.getState()
  if (!navbar) return []

  const jobsItems = navbar.items.map((item: JobItem) => {

    const homeownerCt = item.properties?.contacts.find(c => c.role === 'HOMEOWNER')
    const homeowner = homeownerCt && homeownerCt !== undefined ? ' for ' + homeownerCt?.fullName : ''
    return {
      ...item,
      number: '#' + item.properties?.publicId,
      line1: ' - ' + item.properties?.address.fullAddress,
      line2: item.properties?.contacts.length > 0 ? item.properties?.contacts[0].fullName + homeowner : '',
      status: item.status,
      line3: ' - $' + item.properties.totalValue
    }
  })
  const flattenedData = getOptionsFromArray(jobsItems as unknown as JobItem[] || [], { key: 'id', label: ['number', 'line1', 'line2', 'status', 'line3'] }, undefined, '/')
  return flattenedData
}
