/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'

import { errorTextTryingTo } from '../../helpers'
import { Punchlist } from '../../api'
import { widgetActions, widgetsActions } from '../actions'

import { CreateWidgetAction, FetchWidetsAction, widgetsTypes } from './types'
import { RequestPayload } from '../companies/types'
import { isEmpty } from 'lodash'
import { Widget } from '../types'

export function* fetchWidgets({ payload, callback }: FetchWidetsAction): SagaIterator {
  let success = false
  try {
    const items = yield call(Punchlist.widgets.getWidgets)

    if (items) {
      yield put(widgetsActions.setWidgets(items))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the items'))
    if (callback) yield call(callback, false)
  }
}

export function* createWidget({
  payload,
  callback,
}: CreateWidgetAction): SagaIterator {
  try {
    const request: Partial<Widget> = payload

    const widget = yield call(
      Punchlist.widgets.createWidget,
      request
    )

    if (!isEmpty(widget)) {
      const widgets = yield call(Punchlist.widgets.getWidgets)

      if (widgets) {
        yield put(widgetActions.setWidget(widget))
        yield put(widgetsActions.setWidgets(widgets))
        if (callback) yield call(callback, true)
      }
    }
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('create the company'))
    if (callback) yield call(callback, false)
  }
}


export default function* saga(): SagaIterator {
  yield takeLatest(widgetsTypes.FETCH_WIDGETS, fetchWidgets)
  yield takeLatest(widgetsTypes.CREATE_WIDGET, createWidget)
}
