import TabNavigation from "src/components/UI/CustomUI/organisms/TabNavigation"
import TerritoryPricing from "./Tabs/TerritoryPricing"
import EstimateUsage from "./Tabs/EstimateUsage"
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import DeveloperData from "./Tabs/DeveloperData";
import ItemHistory from "./Tabs/ItemHistory";

const ItemTabs = () => {

  const tabs = [
    {
      label: 'Territory Pricing',
      content: <TerritoryPricing />
    },
    {
      label: 'Usage',
      content: <EstimateUsage />
    },
    {
      label: 'Aliases',
      content: <>Aliases</>
    },
    {
      label: 'History',
      content: <ItemHistory />
    },
    {
      label: 'Developer Data',
      icon: <DeveloperModeIcon style={{ width: '14px', height: '14px' }} />,
      content: <DeveloperData />
    },
  ]

  return (
    <TabNavigation tabs={tabs} variant="contained" tabAlign="center" margin='0 0 16px 0' />
  )
}

export default ItemTabs