import { Theme } from '../../../UI'
import { makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => ({
  hideScrollbar: {
    /* Hide scrollbar for Chrome, Safari and Opera */
    '&::-webkit-scrollbar': {
      display: 'none'
    },

    /* Hide scrollbar for IE, Edge and Firefox */
    '-ms-overflow-style': 'none', /* IE and Edge */
    'scrollbar-width': 'none' /* Firefox */
  },
  relative: {
    position: 'relative',
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'auto'
  },
  absolute: {
    position: 'absolute',
    width: '100%',
    height: '100%'
  }
}))
