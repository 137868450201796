import {
  Box, Button, CircularProgress, Dialog, DialogActions,
  DialogContent, DialogTitle, Grid, IconButton, Typography
} from '@mui/material'
import { ModalProps } from './types'

import useStyles from './styles'
import Icon from '../../atoms/Icon'
import { useClassName } from 'src/hooks'

const Modal = ({
  open,
  setOpen,
  title,
  actions,
  className = "",
  children,
  size = "sm",
  fullWidth = false,
  fullScreen = false,
  onClose = () => { },
  showClose = false,
  center = false,
  sx = {},
  loading = false,
  dialogContentSx,
  onKeyDown = () => { },
  disableCloseOnClickOutside,
  onPaste = () => {},
}: ModalProps) => {
  const classes = useStyles()
  const classNameHook = useClassName()

  const handleClose = (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => {
    if (disableCloseOnClickOutside && reason && reason === "backdropClick")
      return
    if (onClose) onClose()
    setOpen(false)
  }

  return (
    <Dialog
      onPaste = {onPaste}
      onKeyDown={onKeyDown}
      classes={{
        container: classNameHook(
          fullWidth ? classes.fullWidth : '',
          fullScreen ? classes.fullScreen : ''
        ),
        paper: classNameHook(
          fullWidth ? classes.fullWidth : '',
          fullScreen ? classes.fullScreen : ''
        ),
      }}
      sx={[...(Array.isArray(sx) ? sx : [sx]), { textAlign: center ? 'center' : 'left' }]}
      onClose={handleClose} open={open}
      className={`${classes.container} ${className}  ${classes[size]}`}
    >
      <DialogTitle>
        <Box>
          {typeof title === "string" ?
            <Typography variant='h5' className={classes.Modal__title}>{title}</Typography>
            : title
          }
          {showClose ? (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
                "z-index": '9999'
              }}
            >
              <Icon name="Close" background='transparent' />
            </IconButton>
          ) : null}
        </Box>
      </DialogTitle>
      <DialogContent sx={{ display: 'grid', ...dialogContentSx }}>
        {!loading ? children : (
          <Grid container justifyContent="center" alignContent="center">
            <CircularProgress />
          </Grid>
        )}
      </DialogContent>
      {actions &&
        <DialogActions className={classes.container}>
          <Grid container spacing={1}>
            {actions.map((action, index) => {
              return (
                <Grid key={index} item xs={6}>
                  <Button
                    key={index}
                    variant={action.variant}
                    className={classes.button}
                    onClick={action.onClick}
                    disabled={action.disabled}
                  >

                    {action.loading ?
                      <CircularProgress color='info' size={'1.4rem'} /> :
                      <>
                        {action.textButton}
                        {action.icon &&
                          <Icon
                            name={action.icon}
                            color={action?.iconColor}
                            height='1rem'
                            sx={{ margin: '3px' }}
                          />}
                      </>
                    }

                  </Button>
                </Grid>
              )
            })}
          </Grid>
        </DialogActions>}
    </Dialog>
  )
}

export default Modal
