import { createStyles, makeStyles } from '@mui/styles'
import { Theme as ThemeMUI } from '@mui/system'

export default makeStyles((theme: ThemeMUI) =>
  createStyles({
    root: {
      // padding: '8px 0'
      gap: "16px !important"
    },
    text: {
      padding: '0 !important',
      fontFamily: 'NextArtHeavy !important',
      textAlign: 'left',
      [theme.breakpoints.down('sm')]: {
        fontSize: '18px !important',
        textAlign: 'center'
      }
    },
    header: {
      width: '75% !important',
      margin: 'auto !important',
      textAlign: 'center'
    },

    radioButton: {
      position: 'absolute',
      right: '8px !important',
      padding: '0 !important',
      width: 'fit-content !important',
      height: 'fit-content !important',
      minWidth: 'fit-content !important',
      '@global': {
        '.MuiButton-label': {
          width: 'fit-content !important'
        }
      }
    },
    title: {
      color: '#0B060F !important',
      fontSize: '18px !important',
    },
    phone: {
      color: 'var(--bosscat-blue-600) !important',
      fontWeight: '700 !important',
      display: 'flex !important',
      alignItems: 'center',
      gap: '.5rem !important',
      textDecoration: 'none'
    },

    button: {
      width: 'fit-content !important',
      height: '45px !important',
      textTransform: 'none',
      color: 'var(--bosscat-blue-600) !important',
      backgroundColor: 'var(--background-color-secondary) !important',
      boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
      borderRadius: 8,
      border: '0px solid !important',
      padding: '0 1rem !important',
      [theme.breakpoints.down('sm')]: {
        width: '165px !important',
        minHeight: '50px !important',
        fontFamily: 'LatoNormal !important',
        fontWeight: 'normal !important',
        fontSize: '16px'
      }
    },
    spinner: {
      '& .MuiCircularProgress-circle': {
        color: 'var(--white-color)'
      }
    },
    img: {
      minHeight: '40px'
    },
    buttonNext: {
      width: 'fit-content !important',
      height: '45px !important',
      textTransform: 'none',
      color: 'white !important',
      backgroundColor: 'var(--bosscat-blue-600) !important',
      boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
      borderRadius: 8,
      border: '0px solid !important',
      padding: '0 1rem !important',
      transition: '300ms all !important',
      [theme.breakpoints.down('sm')]: {
        width: '165px !important',
        minHeight: '50px !important',
        fontFamily: 'LatoNormal !important',
        fontWeight: 'normal !important',
        fontSize: '16px !important'
      },
      '&.Mui-disabled': {
        opacity: .3,
        transition: '300ms all'
      },
      '&:hover': {
        backgroundColor: 'var(--bosscat-blue-700) !important',
      },
      '@global': {
        '.Mui-disabled': {
          opacity: '.5 !important'
        }
      }
    },
    boxes: {
      display: 'flex !important',
      flexDirection: 'column',
      gap: '1rem !important',
      padding: '1rem 0.2rem !important',
    },
    box: {
      display: 'flex !important',
      justifyContent: 'flex-start !important',
      alignItems: 'center !important',
      gap: '1rem !important',
      // flexDirection: 'column !important',
      textAlign: 'left',
      border: '2px solid #F5F6F7 !important',
      borderRadius: 8,
      boxShadow: '0px 3px 5px rgba(26, 42, 85, 0.2), 0px 0px 1px rgba(26, 42, 85, 0.31) !important',
      padding: '8px !important',
      position: 'relative',
      cursor: 'pointer !important',
      '&:hover': {
        border: '2px solid var(--bosscat-blue-600) !important',
        transition: '300ms all !important',
        '@global': {
          '.MuiSvgIcon-root': {
            fill: 'var(--bosscat-blue-600) !important',
          }
        }
      }
    },
    boxSelected: {
      border: '2px solid var(--bosscat-blue-600) !important',
    }
  })
)
