import { HeaderType } from 'src/components/UI/CustomUI/organisms/Table/types';
const headers: HeaderType[] = [
    {
        id: 'zipCode',
        label: 'ZIP',
        align: 'left',
    },
    {

        id: 'city',
        label: 'City',
        align: 'left',
    },
    {
        id: 'state',
        label: 'State',
        align: 'left',
    },
    {
        id: 'county',
        label: 'County',
        align: 'left',
    },
    {
        id: 'population',
        label: 'Population',
        align: 'left',
    },
    {
        id: 'territory',
        label: 'Territory',
        align: 'left',
    },
    {
        id: 'timeZone',
        label: 'Time Zone',
        align: 'left',
    },
    {
        id: 'adjust',
        label: 'Adjust %',
        align: 'left',
    },
]

export default headers;