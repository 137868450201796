import { Grid } from '@mui/material'
import { FC } from 'react'
import { StatusBox } from '../../..'
import { StatusBoxesProps } from './types'
import useStyles from './styles'
import { useIsXlScreen } from 'src/hooks'

const LayerStatusBoxes: FC<StatusBoxesProps> = ({ data, sx, loading }) => {
  const classes = useStyles(data.length)()
  const [xlScreen] = useIsXlScreen()

  if (data.length === 0) return null

  return (
    <Grid container spacing={xlScreen ? 2 : 1} flexWrap='nowrap' className={classes.container} marginTop={1} marginBottom={1}>
      {data.map(({ statName, statValue, borderColor, minWidth, firstColumn, sndColumn, thirdColumn }, index) => (
        <Grid item key={index * Math.floor(Math.random() * 10000)} className={classes.item} display='flex'>
          <StatusBox
            borderColor={borderColor}
            key={index * Math.floor(Math.random() * 10000)}
            statName={statName}
            statValue={statValue}
            firstColumn={firstColumn}
            sndColumn={sndColumn}
            thirdColumn={thirdColumn}
            minWidth={minWidth}
            sx={[{ flex: '1' }, ...(Array.isArray(sx) ? sx : [sx]),]}
            loading={loading}
          />
        </Grid>
      ))}
    </Grid>
  )
}

export default LayerStatusBoxes;
