import { makeStyles } from '@mui/styles'
import { Theme } from '../../../../UI'

export default makeStyles((theme: Theme) => ({
  divider: {
    background: 'gray',
    borderColor: 'gray',
    width: '100%',
  },
}))
