import { useState } from 'react'
import { Button, Checkbox, Divider, Grid, Typography } from '@mui/material'
import BackButton from 'src/components/UI/CustomUI/atoms/BackButton'
import useStyles from '../styles'
import { useDispatch, useSelector } from 'react-redux'
import { getRepairlistFilter } from 'src/ducks/filters/selector'
import Alert from '../Alert'
import { SelectInput, TextFieldLabel } from 'src/components/UI'
import CustomRadio from 'src/components/UI/CustomUI/atoms/CustomCheckbox'
import AddIcon from '@mui/icons-material/Add'
import MaterialTable from '../MaterialTable'
import TerritoryPricing from '../ItemTabs/Tabs/TerritoryPricing'
import MaterialModal from '../Modals/MaterialModal'
import { MaterialItem, PriceItemType } from 'src/ducks/types'
import { DISCLAIMER_OPTIONS } from '../../EstimatesView/Tabs/Items/OrderItems/Modals/AddItem/Tabs/EstimateInfo'
import { ITEM_CATEGORY, ITEM_CATEGORY_OPTIONS, SEVERITY_OPTIONS, isEmpty } from 'src/helpers'
import { getTerritoryRepairItemsForSelect } from 'src/ducks/selectors'
import { repairItemActions } from 'src/ducks/actions'

const NewPriceItem = () => {
  const dispatch = useDispatch()

  const styles = useStyles()

  const [open, setOpen] = useState<boolean>(false)

  const [newPriceItem, setNewPriceItem] = useState<any>({})
  const [materials, setMaterials] = useState<MaterialItem[]>([])

  const repairListFilter = useSelector(getRepairlistFilter())
  const territoryRepairItems = useSelector(getTerritoryRepairItemsForSelect)

  const {
    title,
    disclaimer,
    category,
    laborRate,
    severity,
    unitMaterialCost,
    unitLaborHours,
    fixedPrice,
    hideFromClient,
    unitPrice,
    margin,
    unitCost,
    profit,
  } = newPriceItem

  const disclaimerArray =
    (disclaimer && typeof disclaimer === 'string'
      ? disclaimer
          .split('.')
          .filter((element) => element)
          .map((item) => item.trim())
      : disclaimer) ?? []

  const handleAddMaterial = () => {
    setOpen(true)
  }

  const handleUpdatePriceItem = (item: any) => {
    setNewPriceItem({ ...newPriceItem, ...item })
  }

  const handleCreateMaterial = (newMaterial: MaterialItem) => {
    setMaterials([...materials, newMaterial])
  }

  const _handleBlur = (event: any): void => {
    const disclaimer = event?.target?.value
    if (disclaimer?.length > 0) {
      const newValue: any = [...(disclaimerArray as string[]), disclaimer]
      handleUpdatePriceItem({ disclaimer: newValue })
    }
  }

  const handleSave = () => {
    dispatch(
      repairItemActions.createRepairItem(
        {
          title,
          category: category || 'UNCATEGORIZED',
          disclaimer: disclaimer?.join('.') || '',
          severity,
          hideFromClient,
          fixedPrice,
          laborHours: Number(unitLaborHours),
        },
        (succ) => {
          if (repairListFilter.territory?.key && succ) {
            dispatch(
              repairItemActions.patchPriceItem({
                request: {
                  title,
                  margin: Number(margin),
                  unitCost: Number(unitCost),
                  unitPrice: Number(unitPrice),
                  unitLaborHours: Number(unitLaborHours),
                  unitMaterialCost: Number(unitMaterialCost),
                  category: category || 'UNCATEGORIZED',
                  severity,
                  disclaimer: disclaimer?.join('.') || '',
                  materials: materials.map((mat) => ({
                    id: mat.id,
                    quantity: 1,
                  })),
                },
                territoryId: repairListFilter.territory?.key || '',
              })
            )
          }
        }
      )
    )
  }

  return (
    <>
      <BackButton float top="25px" />
      <Grid container spacing={2} flexDirection="column">
        <Grid item container className={styles.header} flexDirection="column">
          <Typography className={styles.title}>
            {title || 'Item Title'}
          </Typography>
          <Typography className={styles.subtitle}>{`Estimate Item For ${
            repairListFilter.territory?.label || 'All Territories'
          }`}</Typography>
        </Grid>
        <Grid item>
          <Alert text="New Price Items will be available for all territories. The price specified at creation will be the default for all territories. You can override the price for each territory. Title, labor hours, and material cost will apply to all territories." />
        </Grid>
        <Grid item container spacing={2}>
          <Grid item container spacing={2} flexDirection="column" xs={6}>
            <Grid item>
              <Typography className={styles.contentTitle}>
                ITEM INFORMATION
              </Typography>
            </Grid>
            <Grid item>
              <TextFieldLabel
                label="Display Title"
                value={title}
                onChange={(e: any) =>
                  handleUpdatePriceItem({ title: e.target.value })
                }
              />
            </Grid>
            <Grid item>
              <SelectInput
                label="Display Disclaimer"
                placeholder="Disclaimer Text"
                value={disclaimerArray || []}
                options={DISCLAIMER_OPTIONS.map((option) => option.value)}
                size="small"
                onChange={(newSelected) =>
                  handleUpdatePriceItem({ disclaimer: newSelected })
                }
                onBlur={_handleBlur}
                clearOnBlur
                multiple
                freeSolo
                labelVariant="h6"
                maxCharacters={40}
                // disabled={vendorIsPaid}
              />
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={6}>
                <SelectInput
                  label="Category"
                  // disabled
                  value={category}
                  options={ITEM_CATEGORY_OPTIONS.map((option) => option)}
                  onChange={(newValue) => handleUpdatePriceItem({ category: newValue.label })}
                  size="small"
                  labelVariant="h6"
                />
              </Grid>
              <Grid item xs={6}>
                <SelectInput
                  label="Severity: "
                  // disabled
                  value={severity || ''}
                  onChange={(newValue) =>
                    handleUpdatePriceItem({ severity: newValue.label })
                  }
                  options={SEVERITY_OPTIONS}
                  size="small"
                  labelVariant="h6"
                />
              </Grid>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={6}>
                <TextFieldLabel
                  label="Material Cost"
                  value={unitMaterialCost?.toString()}
                  onChange={(e: any) => {
                    handleUpdatePriceItem({ unitMaterialCost: e.target.value })
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldLabel
                  label="Labor Hours"
                  value={unitLaborHours?.toString()}
                  onChange={(e: any) =>
                    handleUpdatePriceItem({ unitLaborHours: e.target.value })
                  }
                />
              </Grid>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item container alignItems="center" width="fit-content">
                <Typography>Fixed Price</Typography>
                <Checkbox
                  checked={!!fixedPrice}
                  onChange={() =>
                    handleUpdatePriceItem({ fixedPrice: !fixedPrice })
                  }
                />
              </Grid>
              <Grid item container alignItems="center" width="fit-content">
                <Typography>Hide from Client</Typography>
                <Checkbox
                  checked={!!hideFromClient}
                  onChange={() =>
                    handleUpdatePriceItem({ hideFromClient: !hideFromClient })
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item container spacing={2} flexDirection="column" xs={6}>
            <Grid item>
              <Typography
                className={styles.contentTitle}
                textTransform="uppercase"
              >
                territory specific:{' '}
                {repairListFilter.territory?.label || 'All Territories'}
              </Typography>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={3}>
                <TextFieldLabel
                  label="Price"
                  value={unitPrice}
                  onChange={(e) =>
                    handleUpdatePriceItem({ unitPrice: e.target.value })
                  }
                  prefix="$"
                />
              </Grid>
              <Grid item xs={3}>
                <TextFieldLabel
                  label="Margin %"
                  value={margin}
                  onChange={(e) =>
                    handleUpdatePriceItem({ margin: e.target.value })
                  }
                  prefix="$"
                />
              </Grid>
              <Grid item xs={3}>
                <TextFieldLabel
                  label="Cost"
                  value={unitCost}
                  onChange={(e) =>
                    handleUpdatePriceItem({ unitCost: e.target.value })
                  }
                  prefix="$"
                />
              </Grid>
              <Grid item xs={3}>
                <TextFieldLabel
                  label="Profit"
                  value={profit}
                  onChange={(e) =>
                    handleUpdatePriceItem({ profit: e.target.value })
                  }
                  prefix="$"
                />
              </Grid>
            </Grid>
            <Grid item>
              <Button className={styles.submitBtn} onClick={handleSave}>
                Save Price Item
              </Button>
            </Grid>
            <Grid item>
              <Divider orientation="horizontal" />
            </Grid>
            <Grid
              item
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography className={styles.contentTitle}>
                Territory Materials:{' '}
                {repairListFilter.territory?.label || 'All Territories'}
              </Typography>
              <Button
                className={styles.addBtn}
                endIcon={<AddIcon style={{ width: '14px', height: '14px' }} />}
                onClick={handleAddMaterial}
              >
                Add Material
              </Button>
            </Grid>
            <Grid item>
              <MaterialTable materials={materials} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <MaterialModal
        open={open}
        setOpen={setOpen}
        isNewPriceItem={true}
        onCreateMaterial={handleCreateMaterial}
      />
    </>
  )
}

export default NewPriceItem
