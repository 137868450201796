import { FC, useContext } from 'react'
import { VendorsFilter, JobsFilter } from './FilterTab'
import TabNavigation from 'src/components/UI/CustomUI/organisms/TabNavigation'
import { getVendorsTabPL } from 'src/ducks/selectors'
import { getVendorsTabPRO } from 'src/ducks/selectors'
import { useSelector } from 'react-redux'
const FiltersBar: FC = () => {

  const vendorsPro = useSelector(getVendorsTabPRO())
  const vendorsPL = useSelector(getVendorsTabPL())

  const TABS = [
    {
      label: 'PL TECH',
      content: <VendorsFilter vendorsParam={vendorsPL} />,
    },
    {
      label: 'PROS',
      content: <VendorsFilter vendorsParam={vendorsPro} />,
    },
    {
      label: 'JOBS',
      content: <JobsFilter />,
    },
  ]

  return (
    <TabNavigation
      tabs={TABS}
      variant="contained"
      tabWidth="333px"
      tabAlign="flex-start"
      defaultActive={0}
      style={{ left: 0, height: '40px' }}
      margin="0px"
      containerSx={{ margin: 0 }}
    />
  )
}

export default FiltersBar
