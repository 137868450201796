import { HeaderType } from 'src/components/UI/CustomUI/organisms/Table/types'

const headers: HeaderType[] = [
    { id: 'id', label: '#' },
    { id: 'dateSent', label: 'Date Sent' },
    { id: 'datePaid', label: 'Date Paid' },
    { id: 'amount', label: 'Amount' },
    { id: 'status', label: 'Status' },
    { id: 'notes', label: 'Notes' },
    { id: 'billingType', label: 'Billing Type' }
]

export default headers