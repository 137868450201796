import { FC, useContext, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Button, CircularProgress, Grid, TextFieldLabel } from 'src/components/UI'
import { ordersActions } from 'src/ducks/actions'
import { isEmpty, ORDER_STATUS } from 'src/helpers'
import { WorkOrdersContext } from '../../../context'

const NewOrder: FC = () => {
	const { id } = useParams()
	const { dispatch: dispatchContext, state: { modalAfterCreate, modalActionAfterCreate } } = useContext(WorkOrdersContext)
	const dispatch = useDispatch()

	const [newOrderName, setNewOrderName] = useState('')
	const [showWarnings, setShowWarnings] = useState(false)
	const [buttonLoading, setButtonLoading] = useState(false)
	const validate = !isEmpty(newOrderName)

	const handleClose = () => {
		dispatchContext({ type: 'SET_MODAL_OPEN', payload: false })
		setShowWarnings(false)
		setNewOrderName('')
	}

	const handleKeypress = (e: React.KeyboardEvent<HTMLInputElement>) => {
		//it triggers by pressing the enter key
		if (e.code === "Enter")
			handleCreate();
		else if (e.code === "Esc")
			handleClose()
	};

	const handleCreate = () => {
		if (validate) {
			setButtonLoading(true)
			setShowWarnings(false)
			const request = {
				orderName: newOrderName,
				statusType: ORDER_STATUS.APPROVED,
				estimateId: id
			}
			dispatch(
				ordersActions.createOrder(request,
					(success, orderId) => {
						setButtonLoading(false)
						dispatchContext({ type: 'SET_PAGE_LOADING', payload: false })
						if (success) {
							handleClose()
							//dispatchContext({ type: 'SET_SELECTED_ORDER_ID', payload: orderId })
							if (orderId) {
								if (modalActionAfterCreate) {
									modalAfterCreate(orderId)
									dispatchContext({ type: 'SET_ACTION_AFTER_CREATE', payload: false })
								}

							}
						}

					}

				)
			)
		}
		else setShowWarnings(true)
	}

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<TextFieldLabel
					onKeyPress={handleKeypress}
					labelVariant='h6'
					label='New Name'
					value={newOrderName}
					error={showWarnings && isEmpty(newOrderName)}
					onChange={(e) => { setNewOrderName(e.target.value) }}
				/>
			</Grid>
			<Grid item container xs={12} spacing={2}>
				<Grid item xs={6}>
					<Button variant='outlined' onClick={handleClose} fullWidth>
						Cancel
					</Button>
				</Grid>
				<Grid item xs={6}>
					<Button variant='contained' onClick={handleCreate} fullWidth>
						{buttonLoading ? <CircularProgress color='info' size={'1.4rem'} /> : 'Create Order'}
					</Button>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default NewOrder