/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'

import { Punchlist } from '../../api'
import { errorTextTryingTo } from '../../helpers'
import { invoiceActions, orderActions } from '../actions'
import {
  CreateInvoiceAction,
  GetInvoicePreviewAction,
  invoiceTypes,
  GetInvoicePreviewPathFileAction,
} from './types'
import { isEmpty } from 'lodash'

export function* getInvoicePreview({
  payload,
  callback,
}: GetInvoicePreviewAction): SagaIterator {
  let success = false
  let amountTotal = 0
  let outstandingBalance = 0
  let tax = 0
  let paid = 0
  let subTotal = 0

  yield put(invoiceActions.resetState())
  try {
    /*
     const { params } = payload || {}
    const messages = yield call(Punchlist.communications.getMessages, { params })
    */
    const { params } = payload || {}
    const invoice = yield call(Punchlist.invoices.getInvoicePreview, { params })
    if (invoice) {
      yield put(invoiceActions.setInvoicePreview(invoice))
      success = true
      amountTotal = invoice.totalWithTax
      tax = invoice.tax
      paid = invoice?.amountPaid
      outstandingBalance = invoice?.outstandingBalance
      subTotal = invoice.preTaxTotal
    }

    if (callback)
      yield call(
        callback,
        success,
        amountTotal,
        tax,
        paid,
        outstandingBalance,
        subTotal
      )
  } catch (error) {
    yield put(orderActions.setOrder(null))
    //yield call(toast.error, errorTextTryingTo('get the invoice preview'))
    if (callback) yield call(callback, false, 0, 0, 0, 0, 0)
  }
}

export function* createInvoice({
  payload,
  callback,
}: CreateInvoiceAction): SagaIterator {
  try {
    const { request, params } = payload
    const invoice = yield call(
      Punchlist.invoices.createInvoice,
      request,
      params
    )

    if (!isEmpty(invoice)) {
      yield call(callback, true)
      //yield call(toast.success, 'The Invoice was created successfully')
    }
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('create the invoice'))
    yield call(callback, false)
  }
}

export function* getInvoicePreviewPathFile({
  payload,
  callback,
}: GetInvoicePreviewPathFileAction): SagaIterator {
  let success = false
  let pathFile = ''
  // yield put(invoiceActions.resetState())
  try {
    const invoice = yield call(
      Punchlist.invoices.getInvoicePreviewPathFile,
      payload
    )

    if (invoice) {
      yield put(invoiceActions.setInvoicePreviewPathFile(invoice))
      success = true
      pathFile = invoice
    }
    /*
        const { fileName, uploadUrl } = yield call(Punchlist.files.uploadFile, { extension })

    const result = yield call(Files.uploadFile, uploadUrl, payload)
    success = result !== null
    yield call(callback, success, fileName)
    */
    if (callback) yield call(callback, success, pathFile)
  } catch (error) {
    yield put(orderActions.setOrder(null))
    //yield call(toast.error, errorTextTryingTo('get the invoice preview'))
    if (callback) yield call(callback, false, '')
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(invoiceTypes.GET_INVOICE_PREVIEW, getInvoicePreview)
  yield takeLatest(invoiceTypes.CREATE_INVOICE, createInvoice)
  yield takeLatest(
    invoiceTypes.GET_INVOICE_PREVIEW_PATH_FILE,
    getInvoicePreviewPathFile
  )
}
