/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { Punchlist } from '../../api'
import { errorTextTryingTo } from '../../helpers'
import { itemsActions, siteVisitActions } from '../actions'
import { CreateSiteVisitAction, GetSiteVisitAction, SiteVisitType, siteVisitTypes, UpdateSiteVisitAction } from './types'
import { UpdateBatchRequest, UpdateRequest } from '~api/types'

const COMPANY_FIELD_MANAGER = 'PunchlistUSA'

export function* getSiteVisit({ payload, callback }: GetSiteVisitAction): SagaIterator {
  let success = false
  try {
    const siteVisit = yield call(Punchlist.siteVisits.getSiteVisitById, payload)
    if (siteVisit) {
      if (siteVisit.vendorId && siteVisit.companyName !== COMPANY_FIELD_MANAGER) {
        const selectedVendor = yield call(Punchlist.vendors.getVendorById, siteVisit.vendorId)
        siteVisit.selectedVendor = selectedVendor
        try {
          const files = yield call(Punchlist.siteVisits.getSiteVisitAttachments, payload)
          siteVisit.files = files
        } catch (error) { yield call(toast.error, errorTextTryingTo('get the site visit files')) }
      }
      yield put(siteVisitActions.setSiteVisit(siteVisit))
      success = true
    }
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the site visit'))
    if (callback) yield call(callback, false)
  }
}

export function* createSiteVisit({ payload, callback }: CreateSiteVisitAction): SagaIterator {
  let success = false
  try {
    const { siteVisit, jobFiles, itemsIds, jobId, sendNotification } = payload
    const data = yield call(Punchlist.siteVisits.createSiteVisit, sendNotification, siteVisit)

    if (data && data.orderId) {
      yield put(siteVisitActions.setSiteVisit(data))
      yield call(Punchlist.siteVisits.setSiteVisitAttachments, data.orderId, jobFiles)

      const request: UpdateBatchRequest = { ids: itemsIds, patch: [] }
      request.patch.push({ op: 'add', path: '/siteVisitId', value: data.orderId })

      yield call(Punchlist.items.updateBatch, request)
      yield put(itemsActions.fetchItems({ params: { jobId } }))

      success = true
    } else {
      yield call(toast.error, errorTextTryingTo('create the site visit'))
    }
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('create the site visit'))
    if (callback) yield call(callback, false)
  }
}

export function* updateSiteVisit({ payload, callback }: UpdateSiteVisitAction): SagaIterator {
  let success = false
  try {
    const { siteVisitId, siteVisit, jobFiles } = payload
    const request: UpdateRequest = []
    for (const attr in siteVisit) {
      request.push({
        op: 'add',
        path: '/' + attr,
        value: siteVisit[attr as keyof SiteVisitType]
      })
    }
    yield call(Punchlist.siteVisits.updateSiteVisit, siteVisitId, request)
    yield call(Punchlist.siteVisits.setSiteVisitAttachments, siteVisitId, jobFiles)
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('update the site visit'))
    yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(siteVisitTypes.GET_SITE_VISIT, getSiteVisit)
  yield takeLatest(siteVisitTypes.CREATE_SITE_VISIT, createSiteVisit)
  yield takeLatest(siteVisitTypes.UPDATE_SITE_VISIT, updateSiteVisit)
}
