/* eslint-disable */

import { useNavigate, useParams } from "react-router-dom"

type ParamType = {
  page: string;
  id: string;
  subId: string;
  subPage: string;
  section: string;
};

export function getParamValues(): ParamType {
  const params = useParams<Partial<ParamType>>();
  // Allow params to be partial
  // Provide default values or handle the undefined case for each property
  const page = params.page !== undefined ? params.page : '';
  const id = params.id !== undefined ? params.id : '';
  const subId = params.subId !== undefined ? params.subId : '';
  const subPage = params.subPage !== undefined ? params.subPage : '';
  const section = params.section !== undefined ? params.section : '';

  return { page, id, subId, subPage, section };
}

export function usePush(): (path: string) => void {
  const navigate = useNavigate()
  return (path: string) => {
    navigate(path)
  }
}


export function useGoBack(): () => void {
  const navigate = useNavigate()
  return () => navigate(-1)
}

