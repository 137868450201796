import RefreshIcon from '@mui/icons-material/Refresh'
import { FC, useCallback, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  SelectInput,
  Typography,
} from 'src/components/UI'
import { SelectInputOption } from 'src/components/UI/CustomUI/atoms/SelectInput/types'
import TextDivider from 'src/components/UI/CustomUI/atoms/TextDivider'
import {
  estimateActions,
  estimatesActions,
  itemsActions,
  materialInvoicesActions,
} from 'src/ducks/actions'
import { employeeIsAdmin, getEstimate } from 'src/ducks/selectors'
import { ESTIMATE_STATUS } from 'src/helpers'
import Modals from './Modals'
import { JobModalTypes } from './Modals/types'
import styles from './styles.module.scss'
import { AcOption, ControlButtonsProp } from './types'

import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import EmojiFlagsIcon from '@mui/icons-material/EmojiFlags'
import FastRewindIcon from '@mui/icons-material/FastRewind'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import FlagIcon from '@mui/icons-material/Flag'
import FlashOnIcon from '@mui/icons-material/FlashOn'
import LensIcon from '@mui/icons-material/Lens'
import LoopIcon from '@mui/icons-material/Loop'
import PauseIcon from '@mui/icons-material/Pause'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import RestoreIcon from '@mui/icons-material/Restore'
import SendIcon from '@mui/icons-material/Send'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import UploadIcon from '@mui/icons-material/Upload'
import DownloadIcon from '@mui/icons-material/Download'

import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import { EstimateItem, EstimateProperty } from 'src/ducks/types'
import HoldModal from '../../Estimates/modals/HoldModal'
import ImportFromCsvModal from './Modals/ImportFormCsvModal'
import { CSVLink } from 'react-csv'
import ExportCSV from 'src/components/UI/CustomUI/molecules/ExportCSV'

const BASE_URL =
  'https://punchlistusa.atlassian.net/servicedesk/customer/portal'

const ControlButtons: FC<ControlButtonsProp> = ({ refresh }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const estimate = useSelector(getEstimate())
  const isAdmin = useSelector(employeeIsAdmin)

  const { currentlyInUse, estimatorName } = estimate as EstimateItem

  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [modalType, setModalType] = useState<JobModalTypes>()
  const [markInUseLoading, setMarkInUseLoading] = useState(false)
  const [importCsvOpen, setImportCsvOpen] = useState(false)
  const [holdModalOpen, setHoldModalOpen] = useState(false)
  const downloadRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null)

  const [itemsCSV, setItemsCSV] = useState<string>()

  const OPTION_ACTIONS = [
    {
      key: 'Estimate-Divider',
      label: 'Estimate',
      isDivider: true,
    },
    {
      key: 'Put On Hold',
      label: estimate?.onHold ? 'Set Active' : 'Put On Hold',
      color: '#3C2CA4',
      icon: estimate?.onHold ? (
        <PlayArrowIcon sx={{ mr: 0.5, fontSize: 'inherit' }} />
      ) : (
        <PauseIcon sx={{ mr: 0.5, fontSize: 'inherit' }} />
      ),
    },
    {
      key: 'Follow Up',
      label: !estimate?.awaitingFollowUp ? 'Follow Up' : 'Remove Follow Up',
      color: '#3C2CA4',
      icon: !estimate?.awaitingFollowUp ? (
        <FlagIcon sx={{ mr: 0.5, fontSize: 'inherit' }} />
      ) : (
        <EmojiFlagsIcon sx={{ mr: 0.5, fontSize: 'inherit' }} />
      ),
    },
    {
      key: estimate?.properties.payAtClose ? 'Change to Pay at Completion' : 'Change to Pay at Close',
      label: estimate?.properties.payAtClose ? 'Change to Pay at Completion' : 'Change to Pay at Close',
      icon: <RestoreIcon sx={{ mr: 0.5, fontSize: 'inherit' }} />,
    },
    {
      key: 'Clone Empty Estimate',
      label: 'Clone Empty Estimate',
      icon: <ContentCopyIcon sx={{ mr: 0.5, fontSize: 'inherit' }} />,
    },
    {
      key: 'Clone Full Estimate',
      label: 'Clone Full Estimate',
      icon: <FileCopyIcon sx={{ mr: 0.5, fontSize: 'inherit' }} />,
    },
    {
      key: 'Back to Builder',
      label: 'Back to New',
      icon: <FastRewindIcon sx={{ mr: 0.5, fontSize: 'inherit' }} />,
      disabled: estimate?.status === ESTIMATE_STATUS.APPROVED,
    },
    {
      key: 'Back to Builder - Reset',
      label: 'Back to New - reset',
      icon: <RestoreIcon sx={{ mr: 0.5, fontSize: 'inherit' }} />,
      disabled: estimate?.status === ESTIMATE_STATUS.APPROVED,
    },
    // {
    //   key: 'Release Estimate',
    //   label: 'Release Estimate',
    //   icon: <MailIcon sx={{ mr: 0.5, fontSize: 'inherit' }} />,
    //   color: '#0E905A',
    // },
    {
      key: 'Automation-Divider',
      label: 'Automation',
      isDivider: true,
    },
    {
      key: 'Auto Estimate Items (Beta)',
      label: 'Auto Estimate Items (Beta)',
      icon: <FlashOnIcon sx={{ mr: 0.5, fontSize: 'inherit' }} />,
    },
    {
      key: 'Review-Divider',
      label: 'Review',
      isDivider: true,
    },
    {
      key: 'Assign to Me',
      label: 'Assign to Me',
      icon: <TextSnippetIcon sx={{ mr: 0.5, fontSize: 'inherit' }} />,
    },
    {
      key: 'Submit for Review',
      label: 'Submit for Review',
      color: '#3A66FF',
      icon: <TextSnippetIcon sx={{ mr: 0.5, fontSize: 'inherit' }} />,
    },
    {
      key: 'Submit for Release',
      label: 'Submit for Release',
      color: '#AD7D00',
      icon: <SendIcon sx={{ mr: 0.5, fontSize: 'inherit' }} />,
    },
    {
      key: 'Options-Divider',
      label: 'Options',
      isDivider: true,
    },
    {
      key: 'OCR Reprocess',
      label: 'OCR Reprocess',
      disabled: estimate?.status === ESTIMATE_STATUS.NEW,
      icon: <LoopIcon sx={{ mr: 0.5, fontSize: 'inherit' }} />,
    },
    {
      key: 'Items-Divider',
      label: 'Items',
      isDivider: true,
    },
    {
      key: 'Import from CSV',
      label: 'Import from CSV',
      icon: <UploadIcon sx={{ mr: 0.5, fontSize: 'inherit' }} />,
    },
    {
      key: 'Export to CSV',
      label: 'Export to CSV',
      icon: <DownloadIcon sx={{ mr: 0.5, fontSize: 'inherit' }} />,
    },
  ]

  const ADMIN_ACTIONS = [
    {
      key: 'AR',
      label: estimate?.properties.archived ? 'Unarchive' : 'Archive',
    },
    // {
    //   key: 'CWO',
    //   label: 'Clear Approval',
    // },
    {
      key: 'Exclude from Reports',
      label: estimate?.properties.excludedFromReports
        ? 'Include in Report'
        : 'Exclude from Reports',
    },
  ]

  if (
    estimate?.status === ESTIMATE_STATUS.COMPLETE ||
    estimate?.status === ESTIMATE_STATUS.CLOSED
  ) {
    ADMIN_ACTIONS.push({
      key: 'reopen_job',
      label: 'Reopen the job',
    })
  }

  const fetchMaterialInvoices = useCallback(() => {
    if (estimate?.id) {
      setLoading(true)
      dispatch(
        materialInvoicesActions.fetchMaterialInvoices(
          estimate?.id,
          (_succ: boolean) => {
            setLoading(false)
            setOpen(true)
            setModalType(JobModalTypes.MATERIAL_INVOICES)
          }
        )
      )
    }
  }, [estimate?.id])

  const onRefresh = () => {
    if (estimate) {
      /* setLoading(true)
      dispatch(
        estimateActions.fetchEstimate(estimate.id, () => {
          setLoading(false)
        })
      ) */
      if (refresh) refresh()
    }
  }

  const updateEstimate = (request: Partial<EstimateItem>) => {
    setLoading(true)
    dispatch(
      estimatesActions.updateEstimate(
        { ...request, id: estimate?.id },
        (succ) => {
          if (succ) {
            setLoading(false)
            // onRefresh()
          }
        }
      )
    )
  }

  const handleOptions = (newValue: SelectInputOption): void => {
    if (newValue.key === 'Put On Hold') {
      if (estimate?.onHold) updateEstimate({ onHold: false })
      else setHoldModalOpen(true)
    }
    if (newValue.key === 'Follow Up') {
      updateEstimate({ awaitingFollowUp: !estimate?.awaitingFollowUp })
    }
    if (newValue.key === 'SI' || newValue.key === 'VI') {
      navigate(`/invoice/jobId/${estimate?.id}/preview`)
    }
    if (newValue.key === 'Submit for Review') {
      setOpen(true)
      setModalType(JobModalTypes.SUBMIT_FOR_REVIEW)
    }
    if (newValue.key === 'Submit for Release') {
      setOpen(true)
      setModalType(JobModalTypes.SUBMIT_FOR_RELEASE)
    }
    if (newValue.key === 'Release Estimate') {
      navigate(`/estimates/${estimate?.id}/release-estimate`)
    }
    if (newValue.key === 'Clone Full Estimate') {
      dispatch(
        estimateActions.cloneEstimate(
          { id: estimate?.id || '', shallow: false },
          (succ: boolean, newEstimate: Partial<EstimateItem>) => {
            if (succ) {
              window.open(`/estimates/${newEstimate?.id}`)
            }
          }
        )
      )
    }
    if (newValue.key === 'Clone Empty Estimate') {
      dispatch(
        estimateActions.cloneEstimate(
          { id: estimate?.id || '', shallow: true },
          (succ: boolean, newEstimate: Partial<EstimateItem>) => {
            if (succ) {
              window.open(`/estimates/${newEstimate?.id}`)
            }
          }
        )
      )
    }
    if (newValue.key === 'Back to Builder') {
      setOpen(true)
      setModalType(JobModalTypes.BACK_TO_BUILDER)
    }
    if (newValue.key === 'Back to Builder - Reset') {
      setOpen(true)
      setModalType(JobModalTypes.BACK_TO_BUILDER_RESET)
    }
    if (newValue.key === 'Assign to Me') {
      setOpen(true)
      setModalType(JobModalTypes.ASSIGN_TO_ME)
    }
    if (newValue.key === 'Exclude from Reports') {
      setOpen(true)
      setModalType(JobModalTypes.EXCLUDE_FROM_REPORT)
    }
    if (newValue.key === 'Import from CSV') {
      setOpen(true)
      setModalType(JobModalTypes.IMPORT_FROM_CSV)
    }
    if (newValue.key === 'Export to CSV') {
      dispatch(
        itemsActions.downloadItemsCSV(
          { id: estimate?.id || '', type: 'estimates' },
          (succ: boolean, items: any) => {
            setItemsCSV(items)
            downloadRef.current?.link.click()
          }
        )
      )
    }
    if (newValue.key === 'AR') {
      setOpen(true)
      setModalType(JobModalTypes.ARCHIVE)
    }
    if (newValue.key === 'CWO') {
      // setOpen(true)
      // setModalType(JobModalTypes.CLEAR_JOB)
    }
    if (newValue.key === 'OCR Reprocess') {
      setOpen(true)
      setModalType(JobModalTypes.OCR_REPROCESS)
    }
    if (newValue.key === 'Auto Estimate Items (Beta)') {
      setOpen(true)
      setModalType(JobModalTypes.AUTOMATCH_ESTIMATE)
    }
    if (newValue.key === 'Change to Pay at Close') {
      dispatch(
        estimateActions.updateEstimateByPath([{ op: "add", path: "/properties/payAtClose", value: true }], () => {
          onRefresh()
        })
      )
    }
    if (newValue.key === 'Change to Pay at Completion') {
      dispatch(
        estimateActions.updateEstimateByPath([{ op: "add", path: "/properties/payAtClose", value: false }], () => {
          onRefresh()
        })
      )
    }
  }

  const handleMarkInUse = () => {
    const updateEstimate = { currentlyInUse: !currentlyInUse }

    setMarkInUseLoading(true)

    dispatch(
      estimatesActions.updateEstimate(updateEstimate, (succ) => {
        setMarkInUseLoading(false)
      })
    )
  }

  return (
    <Box>
      <Grid container justifyContent="flex-end" spacing={2} flexWrap="nowrap">
        <Grid item>
          {loading ? (
            <CircularProgress />
          ) : (
            <IconButton onClick={onRefresh}>
              <RefreshIcon />
            </IconButton>
          )}
        </Grid>

        {itemsCSV && (
          <ExportCSV
            headers={itemsCSV
              ?.slice(0, itemsCSV?.indexOf('\n'))
              ?.split(',')
              ?.map((h: any) => h)}
            data={itemsCSV?.slice(itemsCSV?.indexOf('\n') + 1)}
            innerRef={downloadRef}
            fileName="items.csv"
            label=""
          />
        )}

        {/* {job?.readyForCompletion && <Grid item>
          <Button
            fullWidth
            variant="contained"
            sx={{ minWidth: '130px !important' }}
            onClick={() => {
              setOpen(true)
              setModalType(JobModalTypes.COMPLETE_JOB)
            }}
          >Mark Complete</Button>
        </Grid>} */}
        <Grid item>
          <Button
            endIcon={<Icon name="RemoveRedEye" />}
            className={styles.controlButton}
            variant="outlined"
            style={{ backgroundColor: '#FAFBFF', color: '#3A66FF' }}
            href={`${estimate?.id}/view-estimate`}
            target="_blank"
          >
            View Estimate
          </Button>
        </Grid>
        <Grid item>
          <Button
            className={styles.controlButton}
            onClick={() => setImportCsvOpen(true)}
            variant="outlined"
            style={{ backgroundColor: '#FAFBFF', color: '#3A66FF' }}
          >
            <UploadIcon className={styles.icon} />
          </Button>
        </Grid>
        {currentlyInUse && (
          <Grid item>
            <Button
              className={styles.controlButton}
              variant="outlined"
              style={{ backgroundColor: '#FFF4F4', color: '#E01F1F' }}
              endIcon={<LensIcon className={styles.icon} />}
              onClick={handleMarkInUse}
            >
              {markInUseLoading && <CircularProgress size={18} />}
              IN USE BY{' '}
              {estimatorName ? estimatorName.toUpperCase() : 'ESTIMATOR'}
            </Button>
          </Grid>
        )}
        {!currentlyInUse && (
          <Grid item>
            <Button
              className={styles.controlButton}
              variant="outlined"
              style={{ backgroundColor: '#EDFDF6', color: '#17B975' }}
              onClick={handleMarkInUse}
            >
              {markInUseLoading && <CircularProgress size={18} />}
              Mark in Use
            </Button>
          </Grid>
        )}
        <Grid
          item
          hidden={
            !(
              estimate?.properties.estimateDeliveredOn &&
              estimate.properties.territory?.serviceable &&
              estimate.status !== 'APPROVED'
            )
          }
        >
          <Button
            className={styles.controlButton}
            variant="outlined"
            style={{ backgroundColor: '#FFFBF0', color: '#3A66FF' }}
            onClick={() => {
              setOpen(true)
              setModalType(JobModalTypes.OVERRIDE_APPROVAL)
            }}
          >
            Override
          </Button>
        </Grid>
        <Grid item>
          <Button
            className={styles.controlButton}
            variant="outlined"
            style={{ backgroundColor: '#FFFBF0', color: '#3A66FF' }}
            onClick={() => {
              navigate(`/estimates/${estimate?.id}/release-estimate`)
            }}
          >
            Release Estimate
          </Button>
        </Grid>
        <Grid item>
          <SelectInput
            value="Options"
            disableClearable
            disabled={loading}
            onChange={handleOptions}
            options={OPTION_ACTIONS}
            size="medium"
            sx={{ width: '90px' }}
            paperWidth="220px"
            renderOption={(
              props: any,
              { isDivider, label, color, icon }: AcOption
            ) => (
              <li {...props}>
                {isDivider === true ? (
                  <TextDivider label={label} />
                ) : (
                  <Typography color={color} fontSize="12px">
                    {icon || ''}
                    {label}
                  </Typography>
                )}
              </li>
            )}
            getOptionDisabled={({ isDivider, disabled }: AcOption) =>
              (isDivider ?? false) || (disabled ?? false)
            }
            paperMarginLeft="-130px"
          />
        </Grid>
        {isAdmin && (
          <Grid item>
            <SelectInput
              disableClearable
              disabled={loading}
              value="Admin"
              onChange={handleOptions}
              options={ADMIN_ACTIONS}
              sx={{ width: '78px' }}
              paperWidth="150px"
              paperMarginLeft="-70px"
            />
          </Grid>
        )}
      </Grid>
      <div>
        <ImportFromCsvModal isOpen={importCsvOpen} setOpen={setImportCsvOpen} />
        {open && (
          <Modals
            setOpen={setOpen}
            open={open}
            modalType={modalType}
            setLoading={setLoading}
          >
            <></>
          </Modals>
        )}
        {holdModalOpen && (
          <HoldModal
            estimate={estimate as EstimateItem}
            open={holdModalOpen}
            setOpen={setHoldModalOpen}
            handleClose={() => setHoldModalOpen(false)}
          />
        )}
      </div>
    </Box>
  )
}

export default ControlButtons
