import { Theme } from '../../../UI'
import { makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => ({
    root: {
        minHeight: '60vh',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px'
    },
    title: {
        fontFamily: 'var(--font-medium) !important',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 'var(--font-L) !important',
        lineHeight: '30px !important',
        color: '#1A2A55',
    },
    bannersContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gap: '8px'
    },
    addBanner: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    banner: {
        border: '2px solid #EBECEF',
        cursor: 'pointer',
        padding: '8px',
        boxShadow: '0px 1px 1px rgba(26, 42, 85, 0.24), 0px 0px 1px rgba(26, 42, 85, 0.31)',
        borderRadius: '8px',
        transition: '300ms all',
        width: 'fit-content',
        minWidth: '250px',
        minHeight: '130px',
        // minHeight: '200px',
        '&:hover': {
            border: '2px solid var(--blue700)',
            transition: '300ms all'
        }

    },
    bannerTitle: {
        fontFamily: 'NouvelleNormal',
        fontWeight: 400,
        fontSize: '16px !important'
    },
    bannerSubtitleContainer: {
        marginTop: '.5rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '.3rem'
    },
    bannerSubtitle: {

    },
    blue: {
        color: '#4661A8'
    },
    bold: {
        fontWeight: '800 !important'
    },
    discountCode: {
        background: '#E3FAFD',
        padding: '0rem .5rem',
        borderRadius: '4px'
    },
    modal: {
        '@global': {
            '.MuiPaper-root': {
                minHeight: '50vh'
            }
        }
    }
}))
