import { authTypes } from '../auth/types'
import { Job } from '../types'
import { JobsActions, jobsTypes, NavBar } from './types'

const initialState: NavBar = {
  start: '',
  limit: '',
  total: '',
  items: []
}

const reducer = (state = initialState, action: JobsActions): NavBar => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case jobsTypes.SET_JOBS_NAVBAR: {
      return {
        ...state,
        start: (payload as Job).start,
        limit: (payload as Job).limit,
        items: (payload as Job).items,
        total: (payload as Job).total
      }
    }

    default:
      return state
  }
}

export default reducer
