import { FormControlLabel, Radio, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { FC } from "react"
import { useSelector } from "react-redux"
import Icon from "src/components/UI/CustomUI/atoms/Icon"
import { getItems, getItemsSortedByCategory } from "src/ducks/selectors"
import { renderWorkOrdersTabs } from "src/helpers"
import styles from './styles.module.scss'
import { OrderItemDetailProps } from "./types"

const OrderItem: FC<OrderItemDetailProps> = ({ order, orderSelected, setOrderSelected }) => {
  const items = useSelector(getItemsSortedByCategory())
  const { title, color, icon } = renderWorkOrdersTabs(order, items)
  return (
    <FormControlLabel
      key={order.orderId}
      value={order.orderId}
      control={<Radio sx={{ '&.Mui-checked': { color: 'var(--blue700)' } }} />}
      label={<Box display='flex'>
        <Typography variant="h6Bold" color={color}> {title} </Typography>
        {icon && <Icon sx={{ marginLeft: '.2rem' }} width='.8rem' name={icon} color={color} />}
      </Box>}
      sx={{
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'space-between'
      }}
      className={`${styles.OrderItem} ${order.orderId === orderSelected ? styles.OrderItem__selected : ''}`}
      onChange={() => setOrderSelected(order.orderId as string)}
    />
  )
}

export default OrderItem