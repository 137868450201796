import { authTypes } from '../auth/types'
import { MaterialsActions, materialsTypes, MaterialsType } from './types'

const initialState: MaterialsType = []

const reducer = (
  state = initialState,
  action: MaterialsActions
): MaterialsType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: {
      return initialState
    }
    case authTypes.LOGOUT_REQUEST: {
      return initialState
    }

    case materialsTypes.SET_MATERIALS: {
      return payload as MaterialsType
    }

    default:
      return state
  }
}

export default reducer
