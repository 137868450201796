/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Loader, Typography } from "src/components/UI";
import { jobActions } from "src/ducks/actions";
import { getJob, getOrderHistoryByJob } from "src/ducks/job/selectors";
import { JobItem } from "src/ducks/types";
import { formatDate } from "src/helpers";
import { WorkOrdersContext } from "../../../context";

const CheckInHistory: FC = () => {
    const { state: { selectedOrderId } } = useContext(WorkOrdersContext)
    const dispatch = useDispatch()
    const orderHistory = useSelector(getOrderHistoryByJob)
    const [loading, setLoading] = useState(false)
    const job = useSelector(getJob())
    const { id } = job as JobItem
    useEffect(() => {
        setLoading(true)

        if (job?.id && selectedOrderId)
            dispatch(jobActions.fetchOrderCheckInHistory({ id: id, orderId: selectedOrderId }, (_succ: boolean) => {
                setLoading(false)
            }))

    }, [])
    return (
        <Grid container >
            {loading && (
                <Loader></Loader>

            )}
            {!loading && orderHistory && orderHistory.length > 0 && (
                <Grid item xs={12} container>
                    <Grid item xs={4}>
                        <Typography>Date</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography>Created By</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography>Description</Typography>
                    </Grid>
                </Grid>
            )}
            {!loading && orderHistory && orderHistory.length === 0 && (
                <Typography>No check ins currently</Typography>
            )}
            {!loading && orderHistory && orderHistory.map((checkin) => {
                return (
                    <Grid item xs={12} container>
                        <Grid item xs={4}>
                            {formatDate(Number(checkin.createdOn) * 1000, 'MM/DD/YYY')}
                        </Grid>
                        <Grid item xs={4}>
                            {checkin.createdByName}
                        </Grid>
                        <Grid item xs={4}>
                            {checkin.text}
                        </Grid>
                    </Grid>
                )
            })}

        </Grid>
    )
}

export default CheckInHistory