import React, { FC } from 'react'
import { Checkbox, Grid, Typography } from '@mui/material'
import { InvoiceOptionProps } from './types'

const InvoiceOption: FC<InvoiceOptionProps> = ({
  option,
  index,
  handleClickByIndex,
}) => {
  return (
    <Grid container spacing={1} alignItems="center">
      <Checkbox
        color="infoText"
        style={{ padding: '4px' }}
        checked={!option.checked}
        onChange={() => handleClickByIndex(index)}
      />
      <Typography
        sx={{
          fontFamily: 'Nouvelle',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '22px',
        }}
      >
        {option.description}
      </Typography>
    </Grid>
  )
}

export default InvoiceOption
