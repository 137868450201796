import { SearchParams } from '../searches/types'
import { GroupedOrdersAction, SetGroupedOrdersParam, groupedOrdersTypes } from './types'

const actions = {
  fetchGroupedOrders: (
    payload: {
      stage: string
      searchParams: Partial<SearchParams>
    },
    callback?: (succ: boolean, limit?: number) => void
  ): GroupedOrdersAction => ({
    type: groupedOrdersTypes.FETCH_GROUPED_ORDERS,
    payload,
    callback,
  }),

  setGroupedOrders: (payload: SetGroupedOrdersParam): GroupedOrdersAction => ({
    type: groupedOrdersTypes.SET_GROUPED_ORDERS,
    payload,
  }),

  resetGroupedOrders: (payload: { stage: string }): GroupedOrdersAction => ({
    type: groupedOrdersTypes.RESET_GROUPED_ORDERS,
    payload
  })
}

export default actions
