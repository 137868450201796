import { FC } from 'react'
import ColumnLane from './ColumnLane'
import { ESTIMATE_STATUS, FORM_TYPE } from 'src/helpers'
import { Box, Grid } from 'src/components/UI'
import { ColumnLaneProps } from './ColumnLane/types'

import useStyles from './styles'

const ColumnBoard: FC = () => {
  const styles = useStyles()

  const searchParamArray: ColumnLaneProps[] = [
    {
      stage: 'New',
      searchParams: {
        onHold: false,
        status: ESTIMATE_STATUS.NEW,
        sortBy: 'dueOn',
        estimateFormType: [
          FORM_TYPE.CLIENT_INSPECTION_REPORT,
          FORM_TYPE.INTERNAL,
        ],
        sortOrder: 'ASC',
      },
      headerBackground: '#E5F7FF',
      laneBackground: '#FAFDFF',
    },
    {
      stage: 'OCR Review',
      searchParams: {
        onHold: false,
        status: ESTIMATE_STATUS.OCR_REVIEW,
        sortBy: 'dueOn',
        sortOrder: 'ASC',
      },
      headerBackground: '#EBECEF',
      laneBackground: '#F9FAFA',
    },
    {
      stage: 'API',
      searchParams: {
        onHold: false,
        status: ESTIMATE_STATUS.NEW,
        sortBy: 'dueOn',
        estimateFormType: [FORM_TYPE.PUNCHLIST_API],
        sortOrder: 'ASC',
      },
      headerBackground: '#D6DFFF',
      laneBackground: '#FAFBFF',
    },
    {
      stage: 'Repair List',
      searchParams: {
        onHold: false,
        status: ESTIMATE_STATUS.NEW,
        sortBy: 'dueOn',
        estimateFormType: [FORM_TYPE.CLIENT_REPAIR_LIST],
        sortOrder: 'ASC',
      },
      headerBackground: '#F6FDE2',
      laneBackground: '#FCFEF5',
    },
    {
      stage: 'Composing',
      searchParams: {
        onHold: false,
        status: ESTIMATE_STATUS.COMPOSING,
        sortBy: 'dueOn',
        sortOrder: 'ASC',
      },
      headerBackground: '#D6DFFF',
      laneBackground: '#FAFBFF',
    },
    {
      stage: 'Review',
      searchParams: {
        onHold: false,
        status: ESTIMATE_STATUS.IN_REVIEW,
        sortBy: 'dueOn',
        sortOrder: 'ASC',
      },
      headerBackground: '#D9E0F2',
      laneBackground: '#FAFDFF',
    },
    {
      stage: 'Final Review',
      searchParams: {
        onHold: false,
        status: ESTIMATE_STATUS.READY_FOR_RELEASE,
        sortBy: 'dueOn',
        sortOrder: 'ASC',
      },
      headerBackground: '#FFE5A3',
      laneBackground: '#FFFBF0',
    },
    {
      stage: 'On Hold',
      searchParams: {
        onHold: true,
        sortBy: 'dueOn',
        sortOrder: 'ASC',
      },
      headerBackground: '#E3E0FF',
      laneBackground: '#F1F0FF',
    },
    {
      stage: 'Pending Approvals',
      searchParams: {
        onHold: false,
        status: ESTIMATE_STATUS.PENDING_APPROVALS,
        sortBy: 'dueOn',
        sortOrder: 'ASC',
      },
      headerBackground: '#D2F9E9',
      laneBackground: '#EDFDF6',
    },
  ]

  return (
    <Grid container className={styles.container}>
      <Box className={styles.row}>
        {searchParamArray.map((prop, index) => (
          <ColumnLane key={index} {...prop} />
        ))}
      </Box>
    </Grid>
  )
}

export default ColumnBoard
