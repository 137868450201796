import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid !important',
      // margin: '30px 0px 10px 0px'
    },
    column: {
      display: 'flex !important',
      flexDirection: 'column'
    },
    rows: {
      display: 'flex !important',
      flexDirection: 'row'
    },
    rowRight: {
      display: 'flex !important',
      flexDirection: 'row',
      alignContent: 'flex-end !important',
      justifyContent: 'flex-end !important',
      [theme.breakpoints.down('md')]: {
        alignContent: 'center !important',
        justifyContent: 'center !important'
      }
    },
    container: {
      flexWrap: 'nowrap',
      display: 'flex !important',
      justifyContent: 'space-between !important',
      padding: '.5rem .5rem 0 !important',
      gap: '1rem !important',
      [theme.breakpoints.down('md')]: {
        gap: '0.2rem !important'
      }
    },
    autocompleteContainer: {
      display: 'flex !important',
      alignItems: 'flex-start !important'
    },
    autocomplete: {
      width: '90px !important',
      height: '40px !important',
      margin: '0px !important',
      minWidth: '160px !important',
      padding: '0 0.5rem !important',
      boxShadow: '0px 0px 1px 0px rgba(9, 25, 49, 0.31), 0px 1px 1px 0px rgba(9, 25, 49, 0.24) !important',
      '@global': {
        '.MuiInputBase-root': {
          height: 'fit-content !important'
        },
        '.MuiButtonBase-root': {
          height: 'fit-content !important'
        },
        '.MuiAutocomplete-endAdornment': {
          top: '0.2rem !important'
        }
      }
    },
    title: {
      fontFamily: 'LatoNormal !important',
      fontWeight: 500,
      lineHeight: '24px',
      fontSize: '16px',
      margin: '0px 20px 0px 0px',
      color: 'var(--better-bold-color) !important',
      minWidth: '60px',
      [theme.breakpoints.down('md')]: {
        minWidth: '20px',
        margin: '0px'
      }
    },
    subtitle: {
      fontFamily: 'LatoNormal !important',
      fontWeight: 400,
      lineHeight: '24px',
      fontSize: '16px',
      margin: '0px 0px 0px 0px',
      color: 'var(--better-bold-color) !important'
    },
    subtitleSelected: {
      fontFamily: 'LatoNormal !important',
      fontWeight: 400,
      lineHeight: '24px',
      fontSize: '16px',
      margin: '0px 0px 0px 0px',
      color: 'var(--white-color) !important'
    },
    text: {
      minWidth: '106px',
      fontFamily: 'LatoNormal !important',
      fontWeight: 400,
      lineHeight: '24px',
      fontSize: '16px',
      margin: '5px 16px 0px 2px',
      color: 'var(--better-bold-color)'
    },
    detail: {
      fontFamily: 'LatoNormal !important',
      fontWeight: 400,
      lineHeight: '24px',
      fontSize: '16px',
      margin: '0px 0px 0px 0px',
      color: 'var(--better-color)'
    },
    total: {
      fontFamily: 'LatoNormal !important',
      fontWeight: 700,
      lineHeight: '58px',
      fontSize: '24px',
      margin: '0px 0px 0px 0px',
      color: 'var(--better-bold-color)'
    },
    totalValue: {
      fontFamily: 'LatoNormal !important',
      fontWeight: 500,
      lineHeight: '58px',
      fontSize: '24px',
      margin: '0px 4px',
      color: 'var(--better-bold-color)'
    },
    icon: {
      color: 'var(--better-color) !important'
    },
    logo: {
      height: '66px !important',
      margin: '0px 9px 0px 0px !important'
    },
    pdf: {
      height: '64px !important',
      width: '64px !important',
      [theme.breakpoints.down('md')]: {
        height: '44px !important',
        width: '44px !important'
      }
    },
    button: {
      height: '33px !important',
      width: '136px !important',
      minWidth: '136px !important',
      margin: '0px 2px !important',
      textTransform: 'none',
      border: '1px solid var(--better-color) !important',
      borderRadius: '8px !important',
      backgroundColor: 'var(--better-light-color) !important',
      '&:hover': {
        backgroundColor: 'var(--better-light-color) !important'
      },
      [theme.breakpoints.down('md')]: {
        width: '100px !important'
      }
    },
    combo: {
      height: '33px !important',
      width: '104px !important',
      minWidth: '100px !important',
      margin: '0px 6px !important',
      textTransform: 'none',
      borderRadius: '8px !important',
      backgroundColor: 'var(--row-background) !important',
      '&:hover': {
        backgroundColor: 'var(--row-background) !important'
      }
    },
    comboSelected: {
      height: '33px !important',
      width: '104px !important',
      minWidth: '100px !important',
      margin: '0px 6px !important',
      textTransform: 'none',
      borderRadius: '8px !important',
      backgroundColor: 'var(--better-color) !important',
      '&:hover': {
        backgroundColor: 'var(--better-color) !important'
      }
    },
    iconCombo: {
      color: 'var(--better-bold-color) !important'
    },
    iconSelected: {
      color: 'var(--white-color) !important',
      fill: 'var(--white-color) !important',

    },
    switch: {
      width: '28px !important',
      height: '22px !important',
      padding: '2px 0px !important',
      // margin: '6px 5px',
      alignSelf: 'center',
      display: 'flex !important',
      '&:active': {
        '& .MuiSwitch-thumb': {
          width: "15px !important"
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
          transform: 'translateX(9px) !important'
        }
      },
      '& .MuiSwitch-switchBase': {
        padding: "2px !important",
        top: "-16px !important",
        '&.Mui-checked': {
          transform: 'translateX(12px) !important',
          color: '#fff !important',
          '& + .MuiSwitch-track': {
            opacity: "1 !important",
            backgroundColor: 'var(--better-color) !important'
          }
        }
      },
      '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%) !important',
        width: "12px !important",
        height: "12px !important",
        borderRadius: "6px !important",
        transition: theme.transitions.create(['width'], {
          duration: 200
        })
      },
      '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: 'rgba(0,0,0,.25) !important',
        boxSizing: 'border-box'
      },
    },
    unSelected: {
      borderRadius: '8px !important',
      border: '2px solid var(--bosscat-grey-400) !important',
      padding: '8px !important',
      boxShadow: '0px 0px 1px 0px rgba(9, 25, 49, 0.31), 0px 1px 1px 0px rgba(9, 25, 49, 0.24) !important',
      textTransform: 'none',
      backgroundColor: 'white !important',
      height: '40px !important',
      minWidth: 'fit-content !important',
    },
    selected: {
      padding: '8px !important',
      borderRadius: '8px !important',
      border: '2px solid var(--hardware-haze-700) !important',
      backgroundColor: 'var(--hardware-haze-600) !important',
      textTransform: 'none',
      height: '40px !important',
      minWidth: 'fit-content !important',
      '@global': {
        '.MuiTypography-root': {
          fontWeight: 900,
          color: 'white !important'
        }
      }
    },
    midFont: {
      fontFamily: 'LatoNormal',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '150%',
      textTransform: 'capitalize',
    },
    Tabs: {
      display: 'flex !important',
      gap: '.5rem !important',
      // minHeight: 40,
      overflow: 'hidden !important',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '85vw !important'
      },

      '@global': {
        '.MuiTabScrollButton-root.Mui-disabled': {
          display: 'none !important'
        },
        '.MuiButtonBase-root': {
          minHeight: 'fit-content !important',
          padding: '0.2rem 0.5rem !important',
          // opacity: '1 !important'
        },
        '.MuiTabs-scrollButtons': {
          padding: '0 !important',
          width: "40px !important",
          height: "40px !important",
          backgroundColor: '#F5F6F7 !important',
          borderRadius: "8px !important",
          boxShadow: '0px 0px 1px 0px rgba(9, 25, 49, 0.31), 0px 1px 1px 0px rgba(9, 25, 49, 0.24) !important'
        },
        '.MuiTabs-scroller': {
          background: 'white !important',
          //display: 'flex',
          justifyContent: 'center !important',
          alignItems: 'center !important',
          [theme.breakpoints.down('sm')]: {
            display: 'block !important'
          }
        }
      },

    },
    filterButton: {
      width: "40px !important",
      height: "40px !important",
      boxShadow: '3px 3px 14px 0px rgb(0 0 0 / 11%) !important',
      '@global': {
        '.MuiSvgIcon-root': {
          width: '1rem !important',
          fill: 'var(--bosscat-blue-600) !important'
        }
      }
    },
    filtersModal: {
      '@global': {
        '.MuiDialog-container': {
          alignItems: 'flex-end !important'
        },
        '.MuiDialogContent-root': {
          gap: '1rem !important'
        },
        '.MuiPaper-root': {
          maxWidth: '100% !important',
          margin: "0 !important",
          width: '100% !important'
          // height: '50vh'
        }
      }
    },
    filtersModalTitle: {
      fontFamily: 'NextArtHeavy !important',
      textAlign: 'center',
      width: '100% !important'
    }
  })
)
