import { Grid } from '@mui/material'
import { Typography } from 'src/components/UI'
import { HeaderType } from 'src/components/UI/CustomUI/organisms/Table/types'
import { formatTimestamp } from 'src/helpers'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'


const headers: HeaderType[] = [
  {
    id: 'fullName',
    label: 'Name',
    custom: true,
    Element: (row: any, dispatch): JSX.Element => {
      return (

        <a href={`customers/${row.id}`} target='_blank' rel='noreferrer'>
          <Typography variant='h6'> {row?.firstName} {row?.lastName} </Typography>
        </a>

      )
    }
  },
  {
    id: 'phone',
    label: 'Phone',
    custom: true,
    Element: (row: any, dispatch): JSX.Element => {
      return (

        <a href={`customers/${row.id}`} target='_blank' rel='noreferrer'>
          <Typography variant='h6'> {row?.phone}  </Typography>
        </a>

      )
    }
  },
  {
    id: 'email',
    label: 'Email',
    custom: true,
    Element: (row: any, dispatch): JSX.Element => {
      return (

        <a href={`customers/${row.id}`} target='_blank' rel='noreferrer'>
          <Typography variant='h6'> {row?.email}  </Typography>
        </a>

      )
    }
  },
  {
    id: 'city',
    label: 'City',
    custom: true,
    Element: (row: any, dispatch): JSX.Element => {
      return (
        <a href={`customers/${row.id}`} target='_blank' rel='noreferrer'>
          <Typography variant='h6'>{row?.address?.city}</Typography>
        </a>
      )
    }
  },
  {
    id: 'region',
    label: 'State',
    custom: true,
    Element: (row: any, dispatch): JSX.Element => {
      return (
        <a href={`customers/${row.id}`} target='_blank' rel='noreferrer'>
          <Typography variant='h6'>{row?.address?.state}</Typography>
        </a>
      )
    }
  },
  {
    id: 'zip',
    label: 'Zipcode',
    custom: true,
    Element: (row: any, dispatch): JSX.Element => {
      return (
        <a href={`customers/${row.id}`} target='_blank' rel='noreferrer'>
          <Typography variant='h6'>{row?.address?.zipCode}</Typography>
        </a>
      )
    }
  },
  {
    id: 'territory',
    label: 'Territory',
    custom: true,
    Element: (row: any, dispatch): JSX.Element => {
      return (
        <Typography variant='h6'>{row?.territory === null ? '-' : row?.territory?.title}</Typography>
      )
    }
  },
  {
    id: 'type',
    label: 'Type',
    custom: true,
    Element: (row: any, dispatch): JSX.Element => {
      return (
        <a href={`customers/${row.id}`} target='_blank' rel='noreferrer'>
          <Typography variant='h6'>{row?.clientType}</Typography>
        </a>
      )
    }
  },
  {
    id: 'insertedOn',
    label: 'Created',
    custom: true,
    Element: (row: any): JSX.Element => {
      return (
        <Grid>
          <a href={`customers/${row.id}`} target='_blank' rel='noreferrer'>
            <Typography>
              {formatTimestamp(row?.insertedOn, 'M/D/YYYY HH:mm')}
            </Typography>

          </a>
        </Grid>
      )
    }
  },
  {
    id: 'affiliate',
    label: 'Affiliate',
    custom: true,
    Element: (row: any, dispatch): JSX.Element => {
      return (
        <a href={`customers/${row.id}`} target='_blank' rel='noreferrer'>
          <Typography variant='h6'> {row?.affiliateName}</Typography>
        </a>
      )
    }
  },
  {
    id: 'nar',
    label: 'NAR',
    custom: true,
    Element: (row: any): JSX.Element => {
      return row?.nar
        ? (
          <Grid>
            <a href={`customers/${row.id}`} target='_blank' rel='noreferrer'>
              <Icon name='CheckCircle' color='var(--success-color)' />
            </a>
          </Grid>

        )

        : (
          <Grid>
            <a href={`customers/${row.id}`} target='_blank' rel='noreferrer'>
              <Icon color='var(--error-color)' name='Cancel' />
            </a>
          </Grid>

        )
    }
  },
  {
    id: 'isAdmin',
    label: 'ADMIN',
    custom: true,
    Element: (row: any): JSX.Element => {
      // return row?.nar ? <Icon name='CheckCircle' color='var(--success-color)' /> : <Icon color='var(--error-color)' name='Cancel' />
      return row?.isAdmin
        ? (
          <Grid>
            <a href={`customers/${row.id}`} target='_blank' rel='noreferrer'>
              <Icon name='CheckCircle' color='var(--success-color)' />
            </a>
          </Grid>

        )

        : (
          <Grid>
            <a href={`customers/${row.id}`} target='_blank' rel='noreferrer'>
              <Icon color='var(--error-color)' name='Cancel' />
            </a>
          </Grid>

        )
    }
  },
  {
    id: 'clientPriceModelId',
    label: 'Discount',
    custom: true,
    Element: (row: any): JSX.Element => {
      return <a href={`customers/${row.id}`} target='_blank' rel='noreferrer'>
        <Typography variant='h6'>{row?.discount} </Typography> </a>
    }
  },
  { id: 'revSharePriority', label: 'Rev Share' }
]

export default headers
