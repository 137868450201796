import React, { FC } from 'react'
import useStyles from './styles'
import { Button, Box, Grid, Typography } from '../../../../../UI'
import { BannerProps } from './types'
import { history } from 'src/helpers'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'

const BORDER_PROCESS = '#17B975'
const BORDER_ATTENTION = '#E01F1F'
const BUTTON_PROCESS = '#1CCD83'
const BUTTON_ATTENTION = '#3A66FF'

const Banner: FC<BannerProps> = ({ bannerProp, jobId }) => {
  const classes = useStyles()
  const { iconSrc, title, description, status } = bannerProp
  const push = history.usePush()

  const handleGoToJob = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()

    push(`/jobs/${jobId}`)
  }

  return (
    <Grid
      container
      className={classes.banner}
      justifyContent="space-between"
      alignItems="center"
      style={{
        borderColor: status ? BORDER_PROCESS : BORDER_ATTENTION,
      }}
    >
      <Grid
        container
        item
        spacing={2}
        direction="row"
        className={classes.content}
      >
        <img src={iconSrc} alt="In progress icon" />
        <Box alignItems="center" display="flex" justifyContent="center">
          <Box padding={0}>
            <Typography className={classes.title}>{title}</Typography>
            <Typography className={classes.description}>
              {description}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Button
        variant="contained"
        style={{
          backgroundColor: status ? BUTTON_PROCESS : BUTTON_ATTENTION,
          width: '220px',
          height: '56px',
          borderRadius: '32px',
          textTransform: 'none',
          color: 'white',
          alignItems: 'center',
        }}
        className={`${classes.description} ${status && classes.button}`}
        endIcon={<Icon name="ArrowForward" />}
        onClick={handleGoToJob}
      >
        Go to Job
      </Button>
    </Grid>
  )
}

export default Banner
