import { Box } from 'src/components/UI'

const ContentBox = ({
  children,
  gap = '2px',
  gridAutoFlow = 'row',
  gridTemplateColumns,
  gridTemplateRows
}: {
  children: JSX.Element | JSX.Element[],
  gap?: string,
  gridAutoFlow?: 'column' | 'row',
  gridTemplateColumns?: string,
  gridTemplateRows?: string
}) => {

  return (
    <Box
      display='grid'
      gap={gap}
      gridAutoFlow={gridAutoFlow}
      gridTemplateColumns={gridTemplateColumns}
      gridTemplateRows={gridTemplateRows}
    >
      {children}
    </Box>
  )
}

export default ContentBox
