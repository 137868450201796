import { Box, Typography } from "@mui/material"
import { FC, } from "react"
import { useSelector } from "react-redux"
import Price from "src/components/UI/CustomUI/atoms/Price"
import { getEstimate } from "src/ducks/selectors"
import { round } from "src/helpers/index"
import useStyles from './styles'

export interface TotalPriceProps {
  serviceable?: boolean
  price?: string
  showRegularPrice?: boolean
  ptp?: boolean
  style?: any
}

const TotalPrice: FC<TotalPriceProps> = ({ serviceable, price, showRegularPrice = false, ptp, style }) => {
  const classes = useStyles()

  const estimate = useSelector(getEstimate())

  const totalWithoutDiscount = estimate?.properties?.discount && estimate?.properties?.totalWithTax ? (100 * estimate?.properties?.totalWithTax) / (100 - (estimate?.properties?.discount * -1)) : null

  // const isRanged = price?.includes("-")

  return (
    <Box className={classes.root} style={style}>
      <Box className={classes.column} style={style}>
        {/* {!isRanged && */}
        <Box className={classes.rowTitle}>
          <Typography className={classes.total}> Total:</Typography>
          {serviceable && showRegularPrice ?
            <>
              {totalWithoutDiscount ?
                <Price price={round(totalWithoutDiscount, 2)} totalClassName={classes.total} decimalsClassName={classes.decimals} />
                : <></>
              }

              <Typography className={classes.total}>
                ${round(estimate?.properties?.totalWithTax || 0, 2)}
              </Typography>
            </>
            :
            <Price price={price} totalClassName={classes.total} decimalsClassName={classes.decimals} />
          }
        </Box>
        {/* } */}
        {serviceable && !ptp &&
          <>
            <Box className={classes.rowTitle}>
              <Typography variant="caption">
                Includes one-year labor warranty
              </Typography>
            </Box>
            <Box className={classes.rowTitle}>
              <Typography variant="caption">
                Estimate valid for 30 days
              </Typography>
            </Box>
          </>
        }
      </Box>
    </Box >
  )
}

export default TotalPrice