/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from 'src/components/UI'
import Table from 'src/components/UI/CustomUI/organisms/Table'
import { licensesAction, territoriesActions } from 'src/ducks/actions'
import { getLicenses, getLicenseTotalCount } from 'src/ducks/selectors'
import headers from './headers'
import FilterHeader from './FilterHeader'
import { getProsFilter } from 'src/ducks/filters/selector'
import TablePagination from 'src/components/UI/CustomUI/organisms/Table/TablePagination'
import { ROWS_PER_PAGE_EXTENDED } from 'src/helpers'

import classes from './styles.module.scss'
import { HeaderType } from 'src/components/UI/CustomUI/organisms/Table/types'
import { PrivateLoader } from 'src/components/templates'
import { LicenseType } from 'src/ducks/types'
import Modals from '../Modals'

const Dashboard: FC = () => {
  const dispatch = useDispatch()

  const licenses = useSelector(getLicenses())
  const totalCount = useSelector(getLicenseTotalCount())
  const prosFilter = useSelector(getProsFilter())

  const [pageLoading, setPageLoading] = useState<boolean>(false)
  const [territoriesLoading, setTerritoriesLoading] = useState<boolean>(false)
  const [tableLoading, setTableLoading] = useState<boolean>(false)
  const [tableHeaders, setTableHeaders] = useState<HeaderType[]>(headers)
  const [searchParams, setSearchParams] = useState({})
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(50)
  const [loadingCsv, setLoadingCsv] = useState(false)
  const [open, setOpen] = useState(false)
  const [selectedLicense, setSelectedLicense] = useState(licenses[0])

  const {
    search,
    licenseType,
    reviewStatus,
    uploadedOnStart,
    uploadedOnEnd,
    downloadCSVCount,
  } = prosFilter

  const licenseContactList = [
    {
      key: 'Company',
      value: selectedLicense?.companyName,
    },
    {
      key: 'Contact',
      value: selectedLicense?.contactName,
    },
    {
      key: 'Email',
      value: selectedLicense?.email,
    },
    {
      key: 'Phone',
      value: selectedLicense?.phone,
    },
  ]

  /**
   * Update search params whenever pros filter changes
   */
  useEffect(() => {
    setSearchParams({
      search: search || null,
      licenseType: getArrayForParam(licenseType || []),
      reviewStatus: getArrayForParam(reviewStatus || []),
      uploadedOnStart: uploadedOnStart
        ? new Date(uploadedOnStart).getTime() / 1000
        : null,
      uploadedOnEnd: uploadedOnEnd
        ? new Date(uploadedOnEnd).getTime() / 1000
        : null,
    })
  }, [search, licenseType, reviewStatus, uploadedOnEnd, uploadedOnStart])

  useEffect(() => {
    if (Object.keys(searchParams).length !== 0) {
      fetchLicenses(searchParams)
    }
  }, [page, size, searchParams])

  useEffect(() => {
    fetchTerritories()
  }, [])

  /**
   *
   * @param arr Array of items
   * @returns array filtered by null
   */
  const getArrayForParam = (arr: any[]) => {
    const result = arr
      .filter((item) => item.hide === false)
      .map((item) => item.id)

    return result.length === arr.length ? [] : result
  }

  /**
   *
   * @param page page number
   * @param size page size
   */
  const fetchLicenses = (searchParams?: any) => {
    setTableLoading(true)
    dispatch(
      licensesAction.fetchLicenses({ page, size, searchParams }, (succ) => {
        setTableLoading(false)
      })
    )
  }

  const fetchCsvLicenses = (count: number, callback: () => void): void => {
    setLoadingCsv(true)
    dispatch(
      licensesAction.fetchCsvLicenses({ count, searchParams }, (succ) => {
        setLoadingCsv(false)
        callback()
      })
    )
  }

  const fetchTerritories = (): void => {
    setTerritoriesLoading(true)
    dispatch(
      territoriesActions.fetchTerritories((succ) => {
        setTerritoriesLoading(false)
      })
    )
  }

  const handleRowClick = (license: LicenseType) => {
    setSelectedLicense(license)
    setOpen(true)
  }

  return (
    <PrivateLoader loading={pageLoading || territoriesLoading}>
      <Box className={classes.Licenses__container}>
        <FilterHeader
          headers={tableHeaders}
          setHeaders={setTableHeaders}
          fetchLicenses={() => {
            fetchLicenses(searchParams)
          }}
          loadingCsv={loadingCsv}
          fetchCsvLicenses={(callback) => {
            fetchCsvLicenses(downloadCSVCount || 400, callback)
          }}
        />
        <Box overflow="overlay" flex={1}>
          <Table
            loading={tableLoading}
            headers={tableHeaders}
            defaultOrder="desc"
            hasPagination={false}
            style={{ height: '100%' }}
            rowAction={handleRowClick}
          >
            {licenses ?? []}
          </Table>
        </Box>
        <TablePagination
          callToApi={(start, limit) => {
            setPage(start)
            setSize(limit)
          }}
          totalItems={totalCount}
          rowsPerPageOptions={ROWS_PER_PAGE_EXTENDED}
        />

        {open && (
          <Modals
            item={selectedLicense.license}
            contact
            onClose={() => {
              setOpen(false)
            }}
            onSave={() => {
              dispatch(
                licensesAction.fetchLicenses(
                  { page, size, searchParams },
                  (succ) => {
                    setTableLoading(false)
                  }
                )
              )
            }}
            licenseContactList={licenseContactList}
            vendorId={selectedLicense.vendorId}
          />
          )
        }
      </Box>
    </PrivateLoader>
  )
}

export default Dashboard
