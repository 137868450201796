export const getDisplayForTradeValue = (val: number) => {
  switch (val) {
    case 0:
      return 'Never'
    case 1:
      return 'Light'
    case 2:
      return 'Competent'
    case 3:
      return 'Skilled'
    case 4:
      return 'Licensed'
  }
}
