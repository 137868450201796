import { Options } from 'src/components/UI/CustomUI/atoms/FieldsFilter/types'
import storeInfo from '../../ducks/store'
import { CompaniesFilterType, CustomersFilterType, EstimatesFilterType, JobsFilterType, OptionSelect, RepairListFilterType, ProsFilterType, SubscriptionsFilterType } from './types'

export const getJobsFilter = () => (): JobsFilterType => {
    const { filters: { jobs } } = storeInfo.store.getState()
    return jobs || {}
}

export const getProjectCoordinatorFilter = () => (): JobsFilterType => {
    const { filters: { projectCoordinator } } = storeInfo.store.getState()
    return projectCoordinator || {}
}

export const getProsFilter = () => (): ProsFilterType => {
    const { filters: { pros } } = storeInfo.store.getState()
    return pros || {}
}

/* const options = [
    { id: 'approved', label: 'Approved', hide: false },
    { id: 'unapproved', label: 'Unapproved', hide: false },
    { isSeparator: true },
    { id: 'CERTIFICATE_OF_INSURANCE', label: 'GL', hide: false },
    { id: 'WORKERS_COMPENSATION', label: 'WC', hide: false },
    { id: 'hasACH', label: 'ACH', hide: false },
    { id: 'hasW9', label: 'W-9', hide: false },
    { id: 'hasTaxID', label: 'Tax ID', hide: false }
] */

export const getLicensingOptions = () => (): OptionSelect[] => {
    const { filters: { pros: {
        approved,
        unapproved,
        hasACH,
        hasW9,
        hasTaxID,
        insuranceTypes
    } } } = storeInfo.store.getState()
    return [
        { id: 'approved', label: 'Approved', hide: !approved },
        { id: 'unapproved', label: 'Unapproved', hide: !unapproved },
        { isSeparator: true },
        { id: 'CERTIFICATE_OF_INSURANCE', label: 'GL', hide: !insuranceTypes?.find(i => i === "CERTIFICATE_OF_INSURANCE") },
        { id: 'WORKERS_COMPENSATION', label: 'WC', hide: !insuranceTypes?.find(i => i === "WORKERS_COMPENSATION") },
        { id: 'hasACH', label: 'ACH', hide: !hasACH },
        { id: 'hasW9', label: 'W-9', hide: !hasW9 },
        { id: 'hasTaxID', label: 'Tax ID', hide: !hasTaxID }
    ];
}

export const getInsurancesOptions = () => (): Options[] => {
    const { filters: { pros: {
        approved,
        draft,
        denied,
        insuranceTypes
    } } } = storeInfo.store.getState()
    return [
        { id: 'draft', label: 'Draft', hide: !draft },
        { id: 'approved', label: 'Approved', hide: !approved },
        { id: 'denied', label: 'Denied', hide: !denied },
        { isSeparator: true },
        { id: 'CERTIFICATE_OF_INSURANCE', label: 'COI', hide: !insuranceTypes?.some(i => i === "CERTIFICATE_OF_INSURANCE") },
        { id: 'WORKERS_COMPENSATION', label: 'WC', hide: !insuranceTypes?.some(i => i === "WORKERS_COMPENSATION") },
        { id: 'other', label: 'Other', hide: !insuranceTypes?.some(i => i === "OTHER") }
    ];
}

export const getSubscriptionsFilter = () => (): SubscriptionsFilterType => {
    const { filters: { subscriptions } } = storeInfo.store.getState()
    return subscriptions || {}
}

export const getCompaniesFilter = () => (): CompaniesFilterType => {
    const { filters: { companies } } = storeInfo.store.getState()
    return companies || {}
}

export const getEstimatesFilter = () => (): EstimatesFilterType => {
    const { filters: { estimates } } = storeInfo.store.getState()
    return estimates || {}
}

export const getCustomersFilter = () => (): CustomersFilterType => {
    const { filters: { customers } } = storeInfo.store.getState()
    return customers || {}
}

export const getRepairlistFilter = () => (): RepairListFilterType => {
    const { filters: { repairList } } = storeInfo.store.getState()
    return repairList
}
