import { Theme } from '@mui/material'

export const calendarPicker = {
  MuiCalendarPicker: {
    styleOverrides: {
      root: {
        width: 'auto',
        paddingRight: '12px',
        '& > div:first-child': {
          marginTop: '0px',
          padding: '0 4px 0 12px'
        }
      }
    }
  },
  MuiPickersDay: {
    styleOverrides: {
      root: ({ theme }: { theme: Theme }) => ({
        '&.Mui-selected': {
          backgroundColor: theme.palette.primary.light,
        },
        '&.Mui-selected:hover': {
          backgroundColor: theme.palette.primary.light,
        },
        '&.Mui-selected:focus': {
          backgroundColor: theme.palette.primary.light,
        }
      })
    }
  },
}
