import { useEffect, useState } from "react"

import { Button, Checkbox, CircularProgress, FormControlLabel, Grid, Typography } from "@mui/material"
import { SelectInput, TextFieldLabel } from "src/components/UI"
import RadioGroup from "src/components/UI/CustomUI/atoms/RadioGroup"
import { SelectInputOption } from "src/components/UI/CustomUI/atoms/SelectInputMultiline/types"
import UploadDocuments from "src/components/UI/CustomUI/molecules/UploadDocument"
import { useDispatch } from "react-redux"
import { filesActions, vendorActions } from "src/ducks/actions"
import Icon from "src/components/UI/CustomUI/atoms/Icon"
import { Vendor } from "src/ducks/types"
import { toast } from "react-toastify"
import { AMOUNT_MASK, FILE_TYPE, INSURANCE_TYPE, LICENSE_TYPE, ParseFilesUrl, unMaskedInput } from "src/helpers"
import DatePicker from "src/components/UI/CustomUI/atoms/DatePicker"
import moment from "moment"
import { createNumberMask } from "text-mask-addons"
import { isEmpty } from "lodash"
import env from "@beam-australia/react-env"

const insuranceOptions = [{ key: INSURANCE_TYPE.WORKERS_COMPENSATION, label: "WC" }, { key: INSURANCE_TYPE.CERTIFICATE_OF_INSURANCE, label: "Col" }, { key: INSURANCE_TYPE.OTHER, label: "Other" }]
const licenseOptions = [{ key: LICENSE_TYPE.FORM_W9, label: "W9" }, { key: LICENSE_TYPE.IDENTIFICATION, label: "ID" }, { key: LICENSE_TYPE.TRADE_LICENSE, label: "Trade License" }, { key: LICENSE_TYPE.TRADE, label: "Trade" }, { key: LICENSE_TYPE.OTHER, label: "Other" }, { key: LICENSE_TYPE.BACKGROUND_CHECK, label: "Background Check" }]
const FILES_URL = env('FILES_URL') ?? ''

const UploadDocumentsModal = ({ setOpen, open }: { setOpen: (open: boolean) => void, open: boolean }) => {
  const dispatch = useDispatch()
  const [listType, setListType] = useState("")
  const [input, setInput] = useState<SelectInputOption>()
  // const [file, setFile] = useState<any>()
  const [uploadingFile, setUploadingFile] = useState(false)
  const [loading, setLoading] = useState(false)
  const [validFromDate, setValidFromDate] = useState<Date>()
  const [validToDate, setValidToDate] = useState<Date>()
  const [insuranceCompanyName, setInsuranceCompanyName] = useState('')
  const [amountOfCoverage, setAmountOfCoverage] = useState('')
  const [additionalInsured, setAdditionalInsured] = useState(false)
  const [file, setFile] = useState<any>()
  const [fileToSend, setFileToSend] = useState<any>()

  const currencyMask = createNumberMask(AMOUNT_MASK(1))

  const validate = !file || !input?.key || !listType || !validToDate
  /* || (listType === "insuranceList" && (isEmpty(insuranceCompanyName))) */

  const inputOptions = listType === 'insuranceList' ? insuranceOptions : licenseOptions

  const handleUploadFile = (file: null | File, callback: () => void) => {
    setUploadingFile(true)
    setFile(file)
    if (file) {
      dispatch(filesActions.uploadFile(file, (uploadSucc, fileName) => {
        if (uploadSucc && fileName) {
          setFileToSend({
            name: fileName,
            fileUrl: ParseFilesUrl({
              baseUrl: FILES_URL,
              avatar: fileName ?? ''
            }),
            description: fileName ?? '',
            fileType: FILE_TYPE.PRO_IMAGE
          })
        }
      }))
    }
    setUploadingFile(false)
    callback()
  }

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    setInput(inputOptions[0])
  }, [listType])


  const uploadDocument = () => {
    setLoading(true)
    const vendorListUpdate: any = {
      fileName: fileToSend?.name,
      [listType === "licenseList" ? 'licenseType' : 'insuranceType']: input?.key,
      reviewStatus: 'DRAFT',
      validFromDate: validFromDate && Number(moment(validFromDate).format("X")),
      validToDate: validToDate && Number(moment(validToDate).format("X")),
      additionalInsured: additionalInsured || undefined,
      amountOfCoverage: amountOfCoverage ? unMaskedInput(amountOfCoverage) : undefined,
      insuranceCompanyName: !isEmpty(insuranceCompanyName) ? insuranceCompanyName : undefined
    }

    dispatch(
      vendorActions.addVendorList(vendorListUpdate, listType as keyof Vendor, () => {
        setOpen(false)
        setLoading(false)
        toast.success.call('success', 'The documents have been added successfully.')
      })
    )
  }

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <Typography variant='h6Bold' color='var(--gray700)'>File to upload:</Typography>
      </Grid>
      <Grid item>
        <UploadDocuments
          error={false}
          file={file ?? null}
          dropzoneText='Click here to upload your file'
          onChange={!uploadingFile ? handleUploadFile : () => { }}
        />
      </Grid>

      <Grid item container spacing={2}>
        <Grid item xs={12}>
          <Typography variant='h6Bold' color='var(--gray700)'>File Type:</Typography>
          <RadioGroup
            onChange={(e) => {
              setListType(e.target.value)
            }}
            value={listType}
            options={[
              { value: "insuranceList", label: "Insurance" },
              { value: "licenseList", label: "License" },
            ]}
            variant="outlined"
            spacing={1}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectInput
            onChange={(newSelected) => { setInput(newSelected) }}
            options={inputOptions}
            placeholder="Choose a listType"
            renderOption={(props: any, option: SelectInputOption) => <Typography {...props} sx={{ textTransform: 'capitalize' }} variant='body1'>{option.label}</Typography>}
            value={input}
          />
        </Grid>
        {listType === "insuranceList" &&
          <>
            <Grid container item spacing={1}>
              <Grid item xs={12}>
                <TextFieldLabel
                  label="Insurance Company:"
                  labelVariant="h6Bold"
                  labelColor="var(--gray700)"
                  value={insuranceCompanyName}
                  onChange={e => setInsuranceCompanyName(e.target.value)}
                  placeholder="Insurance Company Name"
                  name="insurance-company-upload"
                  type='text'
                  id='insurance-company-upload'
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldLabel
                  label="Amount of Coverage:"
                  labelVariant="h6Bold"
                  labelColor="var(--gray700)"
                  value={amountOfCoverage}
                  onChange={e => setAmountOfCoverage(e.target.value)}
                  placeholder="20,000"
                  mask={currencyMask}
                  name="amount-of-coverage-upload"
                  type='text'
                  id="amount-of-coverage-upload"
                />
              </Grid>
            </Grid>
            <Grid container item>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={additionalInsured}
                      onChange={() => setAdditionalInsured(!additionalInsured)}
                      name='remember'
                      color='infoText'
                      sx={{
                        padding: '0 !important',
                        marginRight: '.5rem'
                      }}
                    />
                  }
                  label={<Typography variant='body2'>Additional Insured</Typography>}
                />
              </Grid>
            </Grid>
          </>
        }
        <Grid container item spacing={1}>
          <Grid item xs={6}>
            <Typography variant='h6Bold' color='var(--gray700)'>Valid from Date:</Typography>
            <DatePicker
              placeholder='mm/dd/yyyy'
              onChange={(dateSelected: Date) => setValidFromDate(dateSelected)}
              value={validFromDate || null}
              size="small"
              centerOnScreen
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant='h6Bold' color='var(--gray700)'>Valid to Date:</Typography>
            <DatePicker
              placeholder='mm/dd/yyyy'
              onChange={(dateSelected: Date) => setValidToDate(dateSelected)}
              value={validToDate || null}
              size="small"
              centerOnScreen
            />
          </Grid>
        </Grid>

        <Grid item container spacing={1}>
          <Grid item xs={6}>
            <Button
              onClick={handleClose}
              variant="containedLightBig"
              fullWidth
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={uploadDocument}
              variant="containedBig"
              endIcon={!loading && <Icon name="CloudUpload" />}
              fullWidth
              disabled={validate}
            >
              {loading ? <CircularProgress color='info' size='1.4rem' /> : 'Upload'}
            </Button>
          </Grid>
        </Grid>

      </Grid>
    </Grid >
  )
}

export default UploadDocumentsModal