import { FC, useEffect, useState, useRef, useCallback } from 'react'
import { ColumnLaneProps } from './types'
import { useDispatch, useSelector } from 'react-redux'
import { estimatesByCategoryActions } from 'src/ducks/actions'
import { EstimateItem } from 'src/ducks/types'
import { getEstimatesByStage } from 'src/ducks/selectors'
import { Box, CircularProgress, Grid } from 'src/components/UI'
import ColumnHeader from '../ColumnHeader'
import ColumnCard from '../ColumnCard'

import useStyles from './styles'
import { getEstimatesFilter } from 'src/ducks/filters/selector'
import { SearchParams } from 'src/ducks/searches/types'
import { debounce } from 'lodash'

const SECTION_COUNT = 20

const ColumnLane: FC<ColumnLaneProps> = ({ stage, searchParams, headerBackground, laneBackground }) => {
  const dispatch = useDispatch()

  const styles = useStyles()

  const estimates = useSelector(getEstimatesByStage(stage))
  const estimatesFilters = useSelector(getEstimatesFilter())

  const estimatesBoxRef = useRef<HTMLDivElement>(null);

  const [loading, setLoading] = useState<boolean>(false)
  const [initialEstimateCount, setInitialEstimateCount] = useState(SECTION_COUNT)

  const { search, assignedOption, dueDateOption, estimateNumber, estimator, inUseOption, selectedAffiliates, territory } = estimatesFilters

  const handleScroll = () => {
    const element = estimatesBoxRef.current;
    if (element) {
      const { scrollTop, scrollHeight, clientHeight } = element;
      if (scrollTop + clientHeight >= scrollHeight - 2) {
        setInitialEstimateCount((count) => count + SECTION_COUNT);
      }
    }
  };

  const getParams = () => {
    const searchParams = {} as Partial<SearchParams>

    if (search) { searchParams.search = search }

    if (estimateNumber) { searchParams.publicId = estimateNumber }

    if (territory && territory?.label !== 'All Territories') { searchParams.territory = territory.key }

    if (estimator && estimator.label !== 'All Estimators') { searchParams.estimatorId = estimator.id }

    const resAffiliates = selectedAffiliates?.filter(aff => !aff.hide).reduce((acc: any, curr: any) => acc.concat(curr.key), [])
    const queryAffiliates = resAffiliates?.join(',')
    if (resAffiliates?.length !== 0 && resAffiliates && !resAffiliates?.includes('all')) { searchParams.affiliate = queryAffiliates }

    if (inUseOption?.length && inUseOption !== 'all') searchParams.currentlyInUse = inUseOption
    
    if (assignedOption?.length && assignedOption !== 'all') searchParams.hasEstimatorId = assignedOption
    
    if (dueDateOption?.length && dueDateOption !== 'all') searchParams.dueDate = `${Math.floor(new Date().getTime() / 1000)}`


    return searchParams
  }

  const clearState = () => {
    dispatch(
      estimatesByCategoryActions.resetEstimatesByStage({ stage })
    )
  }

  const fetchEstimatesByStage = (params: Partial<SearchParams>, count: number) => {
    dispatch(
      estimatesByCategoryActions.fetchEstimatesByStage(
        {
          stage,
          start: 0,
          limit: count,
          searchParams: {
            ...searchParams,
            ...params,
          }
        },
        (succ: boolean, limit?: number) => {
          setLoading(false)
          setInitialEstimateCount(limit || count)
        }
      )
    )
  }

  const debouncedFetchEstimatesByStage = useCallback(
    debounce(fetchEstimatesByStage, 500),
    []
  )

  useEffect(() => {
    if (loading || initialEstimateCount === SECTION_COUNT || (estimates?.items.length || 0) >= (Number(estimates?.total || 0))) return ;

    setLoading(true)

    debouncedFetchEstimatesByStage(getParams(), initialEstimateCount)

  }, [initialEstimateCount])

  useEffect(() => {
    clearState()

    setInitialEstimateCount(SECTION_COUNT)
    setLoading(true)

    debouncedFetchEstimatesByStage(getParams(), initialEstimateCount)
  }, [estimatesFilters])

  useEffect(() => {
    estimatesBoxRef.current?.addEventListener('scroll', handleScroll);

    return () => {
      clearState()
      estimatesBoxRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, [])

  return (
    <Box className={styles.container} bgcolor={laneBackground || '#FAFDFF'}>
      <ColumnHeader
        count={Number(estimates?.total || 0)}
        status={stage}
        backgroundColor={headerBackground || "#E6F7FF"}
        price={Number(estimates?.totalValue || 0) || undefined}
        dueToday={Number(estimates?.dueToday || 0) || undefined}
      />
      <Box ref={estimatesBoxRef} className={styles.column}>
        {estimates?.items
          ?.slice(0, initialEstimateCount)
          ?.map((estimate: EstimateItem) => (
            <ColumnCard estimate={estimate} key={estimate.id} />
          ))}

        {loading && (
          <Grid container justifyContent='center'>
            <CircularProgress />
          </Grid>
        )}          
      </Box>
    </Box>
  )
}

export default ColumnLane
