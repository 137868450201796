import { FILE_TYPE, MAX_FILES, ParseFilesUrl, imageDocExcelTypes } from 'src/helpers'
import All from './Tabs/All'
import { Box, Button, Grid } from 'src/components/UI'
import { toast } from 'react-toastify'
import { useRef, useState } from 'react'
import { estimateActions, filesActions, jobActions } from 'src/ducks/actions'
import { UpdateRequest } from '~api/types'
import { useDispatch, useSelector } from 'react-redux'
import env from '@beam-australia/react-env'
import moment from 'moment'
import { getEmployeeId } from 'src/ducks/selectors'
import { PunchlistFile } from 'src/ducks/types'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import { PrivateLoader } from 'src/components/templates'
import UploadDocumentsModal from './UploadDocumentsModal'

const Attachments = () => {
  const inputRef = useRef<any>(null)
  const dispatch = useDispatch()
  const userId = useSelector(getEmployeeId)
  const FILES_URL = env('FILES_URL') ?? ''

  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const handleUpload = (event: any) => {
    if (event.target.files.length > MAX_FILES) {
      toast.error(`Maximum allowed number of files exceeded. Only ${MAX_FILES} allowed`)
    }
    if (event.target.files && event.target.files.length > 0 && event.target.files.length <= MAX_FILES) {
      setLoading(true)
      const filesToUpload = event.target.files
      dispatch(
        filesActions.uploadFiles(filesToUpload, (succ, fileNameList) => {
          // Expecting BE returns files in the same order
          if (succ && fileNameList?.length === event.target.files.length) {
            const filesAdded: UpdateRequest = []
            fileNameList?.forEach((name, idx) => {
              const userFileName = filesToUpload[idx]?.name ?? ''
              const fileType = {
                name: userFileName ?? '',
                description: userFileName ?? '',
                fileType: FILE_TYPE.INSPECTION_REPORT,
                fileUrl: ParseFilesUrl({
                  baseUrl: FILES_URL,
                  avatar: name ?? ''
                }),
                uploadedOn: moment().unix(),
                uploadedBy: userId
              } as PunchlistFile
              filesAdded.push({ op: 'add', path: '/properties/files/-', value: fileType })
            })
            dispatch(
              estimateActions.updateEstimateByPath(filesAdded,
                (succ: boolean) => {
                  setLoading(false)
                  if (succ) toast.success('Files uploaded successfully')
                }
              )
            )
          }
        }
        )
      )
    }
  }
  return (
    <Grid marginTop={'50px'}>
      <PrivateLoader loading={loading}>
        <Box style={{ marginBottom: '1rem' }}>
          <input
            type='file'
            accept={imageDocExcelTypes}
            onChange={handleUpload}
            ref={inputRef}
            style={{ display: 'none' }}
            // className={styles.hiddenInput}
            multiple={true}
          />
          <Button variant='containedLight' onClick={() => setShowModal(true)}>
            Upload Documents <Icon name='CloudUpload' sx={{ marginLeft: '5px' }} />
          </Button>
        </Box>
        <All />
        {/*  <TabNavigation
        tabs={Tabs}
        variant='contained'
        tabWidth='fit-content'
        tabAlign='flex-end'
        defaultActive={0}
        margin={xlScreen ? '1rem 0' : '120px 0 0.5rem 0'}
      /> */}
      </PrivateLoader>
      <UploadDocumentsModal
        open={showModal}
        setOpen={setShowModal}
        handleClose={() => setShowModal(false)}
      />
    </Grid>
  )
}

export default Attachments
