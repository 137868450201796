import { useEffect, useMemo, useState } from 'react'
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  SelectInput,
  TextFieldLabel,
  Typography,
} from 'src/components/UI'
import useStyles from './styles'
import { useDispatch, useSelector } from 'react-redux'
import { repairItemActions } from 'src/ducks/actions'
import { useParams } from 'react-router-dom'
import { getMaterials, getPriceList, getRepairItem } from 'src/ducks/selectors'
import { getRepairlistFilter } from 'src/ducks/filters/selector'
import Alert from './Alert'
import CustomCheckbox from 'src/components/UI/CustomUI/atoms/CustomCheckbox'
import AddIcon from '@mui/icons-material/Add'
import SaveIcon from '@mui/icons-material/Save'
import MaterialTable from './MaterialTable'
import ItemTabs from './ItemTabs'
import BackButton from 'src/components/UI/CustomUI/atoms/BackButton'
import MaterialModal from './Modals/MaterialModal'
import { StateProps } from './types'
import { ITEM_CATEGORY_OPTIONS, SEVERITY_OPTIONS, isEmpty } from 'src/helpers'
import { RepairListItem } from 'src/ducks/types'
import { DISCLAIMER_OPTIONS } from '../EstimatesView/Tabs/Items/OrderItems/Modals/AddItem/Tabs/EstimateInfo'

const RepairItemView = () => {
  const dispatch = useDispatch()

  const { id } = useParams()

  const styles = useStyles()

  const repairItem = useSelector(getRepairItem)
  const priceList = useSelector(getPriceList)
  const repairListFilter = useSelector(getRepairlistFilter())
  const materials = useSelector(getMaterials)

  const priceItem =
    priceList[repairListFilter.selectedTerritoryIndexes?.at(0) || 0]

  const [open, setOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [repairState, setRepairState] = useState<StateProps>({
    ...repairItem,
  })
  const [priceState, setPriceState] = useState<StateProps>({ ...priceItem })

  const disclaimerArray =
    (repairState.disclaimer && typeof repairState.disclaimer === 'string'
      ? repairState.disclaimer
          .split('.')
          .filter((element) => element)
          .map((item) => item.trim())
      : repairState.disclaimer) ?? []

  useEffect(() => {
    dispatch(repairItemActions.fetchRepairItem(id || '', (succ: boolean) => {}))
  }, [])

  useEffect(() => {
    setPriceState({ ...priceItem })
  }, [priceItem])

  useEffect(() => {
    setRepairState({ ...repairItem })
  }, [repairItem])

  const getChangedProps = (obj1: StateProps, obj2: StateProps) => {
    let result: StateProps = {}

    Object.keys(obj1).forEach((key) => {
      if (obj1[key] !== obj2[key]) result[key] = obj2[key]
    })

    if (result.disclaimer) result.disclaimer = result.disclaimer.join('.')

    return result
  }

  const isRepairChanged = useMemo(() => {
    return !isEmpty(getChangedProps(repairItem, repairState))
  }, [repairState, repairItem])

  const isPriceChanged = useMemo(() => {
    return !isEmpty(getChangedProps(priceItem || {}, priceState))
  }, [priceState, priceItem])

  const handleRepairStateUpdate = (key: string, value: any) => {
    setRepairState({
      ...repairState,
      [key]: value,
    })
  }

  const handlePriceStateUpdate = (key: string, value: any) => {
    setPriceState({
      ...priceState,
      [key]: value,
    })
  }

  const handleSave = () => {
    if (isRepairChanged) {
      setLoading(true)
      dispatch(
        repairItemActions.patchRepairItem(
          getChangedProps(repairItem, repairState),
          (succ) => {
            setLoading(false)
          }
        )
      )
    }
    if (isPriceChanged) {
      setLoading(true)
      if (
        repairListFilter.selectedTerritoryIndexes?.at(0) === 0 ||
        repairListFilter.selectedTerritoryIndexes?.length === 0
      ) {
        dispatch(
          repairItemActions.patchAllPriceItems(
            getChangedProps(priceItem || {}, priceState),
            (succ) => {
              setLoading(false)
            }
          )
        )
      } else {
        dispatch(
          repairItemActions.patchPriceItems(
            {
              ids: priceList
                .filter((_, index) =>
                  repairListFilter.selectedTerritoryIndexes?.includes(index)
                )
                .map((item) => item.territoryId),
              request: getChangedProps(priceItem || {}, priceState),
            },
            (succ) => {
              setLoading(false)
            }
          )
        )
      }
    }
  }

  const _handleBlur = (event: any): void => {
    const disclaimer = event?.target?.value
    if (disclaimer?.length > 0) {
      const newValue: any = [...(disclaimerArray as string[]), disclaimer]
      handleRepairStateUpdate('disclaimer', newValue)
    }
  }

  return (
    <>
      <BackButton float top="25px" />
      <Grid container spacing={2}>
        <Grid item container className={styles.header} flexDirection="column">
          <Typography className={styles.title}>{repairItem.title}</Typography>
          <Typography className={styles.subtitle}>{`Estimate Item For ${
            repairListFilter.territory?.label || 'All Territories'
          }`}</Typography>
        </Grid>
        <Grid item container spacing={2} flexDirection="column" xs={6}>
          <Grid item>
            <Alert text="Price Items are available for all territories. You can override the price for each territory. Title, labor hours, and material cost changes will apply to all territories." />
          </Grid>
          <Grid item>
            <TextFieldLabel
              label="Display Title"
              value={repairState.title}
              onChange={(e) => handleRepairStateUpdate('title', e.target.value)}
            />
          </Grid>
          <Grid item>
            <SelectInput
              label="Display Disclaimer"
              labelVariant='body1'
              placeholder="Disclaimer Text on Estimate"
              value={disclaimerArray || []}
              options={DISCLAIMER_OPTIONS.map((option) => option.value)}
              size="small"
              onChange={(newSelected: any) => handleRepairStateUpdate('disclaimer', newSelected)}
              onBlur={_handleBlur}
              clearOnBlur
              multiple
              freeSolo
              maxCharacters={40}
              // disabled={vendorIsPaid}
            />
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={4}>
              <SelectInput
                labelVariant="body1"
                label="Category"
                // disabled
                value={repairState.category || ''}
                options={ITEM_CATEGORY_OPTIONS.map((option) => option)}
                onChange={(newValue) => {
                  handleRepairStateUpdate('category', newValue.label)
                }}
                size="small"
              />
            </Grid>
            <Grid item xs={4}>
              <SelectInput
                label="Severity"
                labelVariant="body1"
                options={SEVERITY_OPTIONS}
                value={
                  SEVERITY_OPTIONS.find(
                    (option) => option.key === repairState.severity
                  ) || ''
                }
                onChange={(newValue) =>
                  handleRepairStateUpdate('severity', newValue.label)
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextFieldLabel
                label="Labor Hours"
                value={`${repairState.laborHours || ''}`}
                onChange={(e) =>
                  handleRepairStateUpdate('laborHours', Number(e.target.value))
                }
              />
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={4}>
              <CustomCheckbox
                bgcolor="var(--gray100)"
                label="Fixed Price"
                checked={!!repairState.fixedPrice}
                onClick={() =>
                  handleRepairStateUpdate('fixedPrice', !repairState.fixedPrice)
                }
              />
            </Grid>
            <Grid item xs={4}>
              <CustomCheckbox
                bgcolor="var(--gray100)"
                label="Hide from Client"
                checked={!!repairState.hideFromClient}
                onClick={() =>
                  handleRepairStateUpdate(
                    'hideFromClient',
                    !repairState.hideFromClient
                  )
                }
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            spacing={2}
            display={
              repairListFilter.selectedTerritoryIndexes?.at(0) === 0
                ? 'none'
                : 'flex'
            }
          >
            <Grid item xs={4}>
              <TextFieldLabel
                label="Price"
                value={`${priceState?.unitPrice || ''}`}
                prefix="$"
                onChange={(e) =>
                  handlePriceStateUpdate('unitPrice', e.target.value)
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextFieldLabel
                label="Material Cost"
                value={`${priceState?.unitMaterialCost || ''}`}
                onChange={(e) =>
                  handlePriceStateUpdate('unitMaterialCost', e.target.value)
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextFieldLabel
                label="Margin %"
                value={`${priceState?.margin || ''}`}
                prefix="$"
                onChange={(e) =>
                  handlePriceStateUpdate('margin', e.target.value)
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextFieldLabel
                label="Cost"
                value={`${Number(priceState?.unitCost).toFixed(2) || ''}`}
                prefix="$"
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <TextFieldLabel
                label="Profit"
                value={`${Number(priceState?.profit).toFixed(2) || ''}`}
                prefix="$"
                disabled
              />
            </Grid>
          </Grid>
          <Grid item>
            <Button
              variant="containedBig"
              disabled={!isRepairChanged && !isPriceChanged}
              onClick={handleSave}
              endIcon={
                loading ? (
                  <CircularProgress color="inherit" size={18} />
                ) : (
                  <SaveIcon style={{ height: '16px', width: '16px' }} />
                )
              }
            >
              Save
            </Button>
          </Grid>
          <Grid item>
            <Divider orientation="horizontal" />
          </Grid>
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography className={styles.title}>
              Territory Materials: {repairListFilter.territory?.label}
            </Typography>
            <Button
              className={styles.addBtn}
              endIcon={<AddIcon style={{ width: '14px', height: '14px' }} />}
              onClick={() => setOpen(true)}
            >
              Add Material
            </Button>
          </Grid>
          <Grid item>
            <MaterialTable materials={materials} />
          </Grid>
        </Grid>
        <Grid item container xs={6}>
          <ItemTabs />
        </Grid>
      </Grid>
      <MaterialModal open={open} setOpen={setOpen} />
    </>
  )
}

export default RepairItemView
