import {
  HeaderType,
} from 'src/components/UI/CustomUI/organisms/Table/types'

const headers: HeaderType[] = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'email',
    label: 'Email',
  },
  { id: 'phone', label: 'Phone' },
]

export default headers
