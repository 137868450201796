import Table from "src/components/UI/CustomUI/organisms/Table"
import headers from './headers'

const EstimateUsage = () => {
  return (
    <Table headers={headers} hasPagination={false}>
      {[]}
    </Table>
  )
}

export default EstimateUsage
