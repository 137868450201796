import { MaterialItem } from '../types'
import { materialTypes, MaterialActions } from './types'

const actions = {
  fetchMaterialBySku: (
    payload: string,
    callback?: (succ: boolean, item?: MaterialItem) => void
  ): MaterialActions => ({
    type: materialTypes.FETCH_MATERIAL_BY_SKU,
    payload,
    callback,
  }),

  createMaterial: (
    payload: { url: string, sku: string },
    callback?: (succ: boolean, item?: MaterialItem) => void
  ): MaterialActions => ({
    type: materialTypes.CREATE_MATERIAL,
    payload,
    callback
  }),

  setMaterial: (payload: MaterialItem): MaterialActions => ({
    type: materialTypes.SET_MATERIAL,
    payload,
  }),
}

export default actions
