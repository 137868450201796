import { UpdateRequest } from '~api/types'
import { InsurancesActions, InsurancesFetch, insurancesTypes, } from './types'

const actions = {
    FetchInsurances: (
        payload: {
            page?: number
            size?: number
            searchParams?: any
        },
        callback?: (succ: boolean) => void
    ): InsurancesActions => ({
        type: insurancesTypes.FETCH_INSURANCES,
        payload,
        callback,
    }),

    FetchCSVInsurances: (
        payload: {
            count?: number,
            searchParams?: any
        },
        callback?: (succ: boolean) => void
    ): InsurancesActions => ({
        type: insurancesTypes.FETCH_CSV_INSURANCES,
        payload,
        callback
    }),

    setInsurances: (payload: InsurancesFetch): InsurancesActions => ({
        type: insurancesTypes.SET_INSURANCES,
        payload,
    }),

    setCSVInsurances: (payload: InsurancesFetch): InsurancesActions => ({
        type: insurancesTypes.SET_CSV_INSURANCES,
        payload,
    }),
    updateInsurance: (
        payload: {
            id: string
            request: UpdateRequest
            vendorId: string
        },
        callback: (succ: boolean) => void
    ): InsurancesActions => ({
        type: insurancesTypes.UPDATE_INSURANCE,
        payload,
        callback,
    }),
}

export default actions