import { UpdateRequest } from '~api/types'
import { WorkOrdersActions, workOrdersTypes, WorkOrdersType } from './types'

const actions = {
  updateWorkOrderItem: (
    payload: {
      orderId: string,
      itemId: string,
      userId: string,
      request: UpdateRequest
    },
    callback?: (succ: boolean) => void
  ): WorkOrdersActions => ({
    type: workOrdersTypes.UPDATE_WORK_ORDER_ITEM,
    payload: payload,
    callback
  }),
  fetchGeneralSection: (
    payload: {
      id: string
    },
    callback?: (succ: boolean) => void
  ): WorkOrdersActions => ({
    type: workOrdersTypes.FETCH_GENERAL_SECTION,
    payload,
    callback,
  }),

  fetchFinancialSection: (
    payload: {
      id: string
    },
    callback?: (succ: boolean) => void
  ): WorkOrdersActions => ({
    type: workOrdersTypes.FETCH_FINANCIAL_SECTION,
    payload,
    callback,
  }),

  fetchContractDetails: (
    payload: {
      id: string
    },
    callback?: (succ: boolean) => void
  ): WorkOrdersActions => ({
    type: workOrdersTypes.FETCH_CONTRACT_DETAILS,
    payload,
    callback,
  }),

  fetchContractMilestones: (
    payload: {
      id: string
    },
    callback?: (succ: boolean) => void
  ): WorkOrdersActions => ({
    type: workOrdersTypes.FETCH_CONTRACT_MILESTONES,
    payload,
    callback,
  }),

  fetchWorkOrderItems: (
    payload: {
      id: string
      itemState?: string
    },
    callback?: (succ: boolean) => void
  ): WorkOrdersActions => ({
    type: workOrdersTypes.FETCH_WORK_ORDER_ITEMS,
    payload,
    callback,
  }),

  setWorkOrders: (payload: WorkOrdersType): WorkOrdersActions => ({
    type: workOrdersTypes.SET_WORK_ORDERS,
    payload,
  }),
}

export default actions
