import { Client } from '../types'

/** TYPES **/
export const clientsTypes = {
  FETCH_CLIENTS: 'FETCH_CLIENTS',
  SET_CLIENTS: 'SET_CLIENTS',
  SET_CLIENTS_VALUE: 'SET_CLIENTS_VALUE',
  FETCH_ALL_CLIENTS: 'FETCH_ALL_CLIENTS',
  DOWNLOAD_CSV: 'DOWNLOAD_CSV_CUSTOMERS',
  FETCH_STATS: 'FETCH_STATS'
}

/** ACTIONS **/
export interface FetchClientsAction {
  type: typeof clientsTypes.FETCH_CLIENTS
  // payload: { page?: number, size?: number, state?: string, search?: string, type?:string , createdOnStart?:string , createdOnEnd?:string }
  payload: {
    page?: number, size?: number,
    searchParams?: Partial<SearchClientParams>
  }
  callback?: (succ: boolean) => void
}

export interface FetchStatsAction {
  type: typeof clientsTypes.FETCH_CLIENTS
  payload: {
    searchParams?: Partial<SearchClientParams>
  }
  callback?: (succ: boolean) => void
}

export interface SetClientsAction {
  type: typeof clientsTypes.SET_CLIENTS
  payload: ClientsType
}

export interface SetClientsValueAction {
  type: typeof clientsTypes.SET_CLIENTS_VALUE
  payload: ClienstValue
}

export interface FetchAllClientsAction {
  type: typeof clientsTypes.FETCH_ALL_CLIENTS
  payload?: Partial<SearchClientParams>,
  callback: (succ: boolean, clients: ClientsType) => void
}

export interface DownloadCSVClientsAction {
  type: typeof clientsTypes.FETCH_ALL_CLIENTS
  payload?: Partial<SearchClientParams>,
  callback: (succ: boolean, clients: string) => void
}

export type ClientsActions =
  | FetchClientsAction
  | SetClientsAction
  | SetClientsValueAction
  | FetchAllClientsAction
  | DownloadCSVClientsAction
  | FetchStatsAction

/** REDUCER **/
export interface ClientsType {
  clients: {
    start: number
    limit: number
    total: number
    items: Client[],
  },
  statistics?: ClientsStatistics[]
}


export interface SearchClientParams {
  page?: number,
  size?: number,
  state?: string,
  search?: string,
  type?: string,
  createdOnStart?: string,
  createdOnEnd?: string,
  territory?: string
  affiliate?: string
}


export interface ClientsStatistics {
  status: string,
  count: number
}

export interface ClienstValue {
  attr: keyof ClientsType
  value: Partial<ClientsType>
}

