import { FC } from 'react'
import { Box } from '../../../UI'
import TabNavigation from 'src/components/UI/CustomUI/organisms/TabNavigation'
import All from './Tabs/All'
import Succeeded from './Tabs/Succeeded'
import Failed from './Tabs/Failed'

const Payments: FC = () => {
  const Tabs = [
    {
      label: 'All',
      content: <All />
    },
    {
      label: 'Succeeded',
      content: <Succeeded />
    },
    {
      label: 'Failed',
      content: <Failed />
    }
  ]

  return (
    <Box>
      <TabNavigation
        tabs={Tabs}
        variant='contained'
        tabWidth='fit-content'
        tabAlign='flex-start'
        defaultActive={0}
        style={{ left: 0, height: '34px' }}
      />
    </Box>
  )
}

export default Payments
