/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo } from '../../helpers'
import { Punchlist } from '../../api'
import { clientsTypes, FetchAllClientsAction, FetchClientsAction } from './types'
import { clientsActions } from '../actions'

export function* fetchClients({ payload, callback }: FetchClientsAction): SagaIterator {
  let success = false
  try {

    const page = payload.page ?? 0
    const size = payload.size ?? 50

    const start = page * size
    const limit = size

    const searchParams = payload.searchParams ?? ''
    const clients = yield call(Punchlist.clients.getClients, { start, limit, searchParams })

    if (clients) {
      yield put(clientsActions.setClientsValue({ attr: "clients", value: clients}))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the clients'))
    if (callback) yield call(callback, false)
  }
}

export function* fetchStats({ payload, callback }: FetchClientsAction): SagaIterator {
  let success = false
  try {

    const searchParams = payload.searchParams ?? ''
    const statistics = yield call(Punchlist.clients.getClientsTotalStatics, searchParams)

    if (statistics) {
      yield put(clientsActions.setClientsValue({ attr: "statistics", value: statistics }))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the clients'))
    if (callback) yield call(callback, false)
  }
}

export function* fetchAllClients({ payload, callback }: FetchAllClientsAction): SagaIterator {
  const searchParams = payload ?? ''
  try {

    const start = 0
    const limit = 9999
    const clients = yield call(Punchlist.clients.getClients, { start, limit, searchParams })

    yield call(callback, true, clients)

  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get all clients'))
  }
}

export function* downloadCSV({ payload, callback }: FetchAllClientsAction): SagaIterator {
  const searchParams = payload ?? ''
  try {

    const clients = yield call(Punchlist.clients.downloadCSV, { searchParams })

    yield call(callback, true, clients)

  } catch (error) {
    yield call(toast.error, errorTextTryingTo('download csv'))
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(clientsTypes.FETCH_CLIENTS, fetchClients)
  yield takeLatest(clientsTypes.FETCH_ALL_CLIENTS, fetchAllClients)
  yield takeLatest(clientsTypes.DOWNLOAD_CSV, downloadCSV)
  yield takeLatest(clientsTypes.FETCH_STATS, fetchStats)
}
