import { InvoiceType } from '~api/types'
import { authTypes } from '../auth/types'
import { Invoice, InvoiceActions, invoiceTypes } from './types'

const initialState: Invoice = {
  invoicePreview: {
    id: '',
    total: 0,
    subtotal: 0,
    tax: undefined,
    taxRate: undefined,
    amountDue: undefined,
    amountPaid: undefined,
    discount: undefined,
    discountRate: undefined,
    subtotalCompleted: undefined,
    subtotalPending: undefined,
    currency: '',
    outstandingBalance: undefined,
    createdOn: undefined,
    dueDate: undefined,
    jobId: '',
    resourceId: '',
    resourceType: undefined,
    address: {
      city: '',
      state: '',
      line_1: '',
      line_2: '',
      zipCode: '',
      latitude: undefined,
      longitude: undefined,
      country: '',
      fullAddress: ''
    },
    preparedFor: {
      id: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      fullName: ''
    },
    stripeId: '',
    isDeposit: undefined,
    preTaxTotal: undefined,
    deposit: undefined,
  },
  invoicePath: ''
}

const reducer = (state = initialState, action: InvoiceActions): Invoice => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case invoiceTypes.SET_INVOICE_PREVIEW: {

      return {
        ...state,
        invoicePreview: payload as unknown as InvoiceType
      }
    }

    case invoiceTypes.RESET_INVOICE: {
      return initialState
    }

    case invoiceTypes.SET_INVOICE_PREVIEW_PATH_FILE: {
      return {
        ...state,
        invoicePath: payload as string || ''
      }
    }

    default:
      return state
  }
}

export default reducer
