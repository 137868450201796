import { useParams } from 'react-router-dom'
import TabNavigation from 'src/components/UI/CustomUI/organisms/TabNavigation'
import Dispatch from './Tabs/Dispatch'
import Overall from './Tabs/Overall'
import Status from './Tabs/Status'
import Notes from './Tabs/Notes'
import { useIsXlScreen } from 'src/hooks'

const History = () => {
  const { id } = useParams()
  const [xlScreen] = useIsXlScreen()

  const Tabs = [
    { label: 'Overall', content: <Overall id={id} /> },
    { label: 'Status', content: <Status id={id} /> },
    { label: 'Dispatch', content: <Dispatch id={id} /> },
    { label: 'Notes', content:<Notes id={id} /> }
  ]

  return (
    <TabNavigation
      tabs={Tabs}
      variant='contained'
      tabWidth='fit-content'
      tabAlign='flex-end'
      margin={xlScreen ? '1rem 0' : '50px 0 0.5rem 0'}
    />
  )
}

export default History
