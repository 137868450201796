/* eslint-disable react-hooks/exhaustive-deps */

import { Button, Grid, Typography } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { icons } from 'src/assets'
import Table from 'src/components/UI/CustomUI/organisms/Table'
import { getInspectionCompanies } from 'src/ducks/selectors'
import headers from './headers'
import CompanyModal from './components/CompanyModal'
import { inspectionCompaniesActions } from 'src/ducks/actions'

import styles from './styles.module.scss'
import { InspectionCompany } from 'src/ducks/types'
import { TextFieldLabel } from 'src/components/UI'
import { debounce } from 'lodash'
import { history } from 'src/helpers'

const InspectionCompanies = () => {
  const push = history.usePush()

  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [search, setSearch] = useState('')
  const { items: inspectionCompanies, total } = useSelector(
    getInspectionCompanies
  )
  const [pagination, setPagination] = useState({ start: 0, limit: 10 })

  const [modalOpen, setModalOpen] = useState(false)
  const [companySelected, setCompanySelected] = useState<InspectionCompany>()

  const fetchInspectionCompanies = (
    start: number,
    limit: number,
    search: string
  ) => {
    setLoading(true)
    dispatch(
      inspectionCompaniesActions.fetchInspectionCompanies(
        { searchParams: { search }, page: start, size: limit },
        (succ) => {
          setLoading(false)
        }
      )
    )
  }

  const debouncedFetchCompanies = useCallback(
    debounce(fetchInspectionCompanies, 500),
    []
  )

  useEffect(() => {
    debouncedFetchCompanies(pagination.start, pagination.limit, search)
  }, [search])

  return (
    <Grid container direction="column" spacing={2}>
      {modalOpen && (
        <CompanyModal
          open={modalOpen}
          setOpen={setModalOpen}
          companySelected={companySelected}
          fetchCompanies={() =>
            fetchInspectionCompanies(pagination.start, pagination.limit, search)
          }
          onClose={() => {
            setModalOpen(false)
            setCompanySelected(undefined)
          }}
        />
      )}
      <Grid item container spacing={4} direction="column">
        <Grid item>
          <Typography variant="h3" style={{ fontFamily: 'Nouvelle' }}>
            Inspection Companies
          </Typography>
        </Grid>
        <Grid item xs={6} container spacing={2}>
          <Grid item xs={4}>
            <TextFieldLabel
              labelVariant="h6"
              label=""
              placeholder="Search"
              value={search}
              onChange={(event) => {
                setLoading(true)
                setSearch(event.target.value)
              }}
              className={styles.searchInput}
            />
          </Grid>
          <Grid item>
            <Button
              variant="containedBig"
              style={{ width: 'fit-content' }}
              startIcon={<icons.Add style={{ width: '16px' }} />}
              onClick={() => {
                setModalOpen(true)
              }}
            >
              Add Inspection Company
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Table
          headers={headers}
          defaultOrder="desc"
          loading={loading}
          hasPagination={true}
          callToApi={(start, limit) => {
            setPagination({ start, limit })
            fetchInspectionCompanies(start, limit, search)
          }}
          actionTitle="Actions"
          totalItems={total}
          rowsPerPageDefault={10}
          actions={[
            {
              id: 'edit',
              textButton: 'Edit',
              className: styles.editButton,
              iconStart: <icons.Edit style={{ width: '16px' }} />,
              onClick: (row) => {
                setModalOpen(true)
                setCompanySelected(row)
              },
              preventDefault: true,
            },
          ]}
          rowAction={(row) => {
            push(`/inspection-companies/${row.id}`)
          }}
        >
          {inspectionCompanies}
        </Table>
      </Grid>
    </Grid>
  )
}

export default InspectionCompanies
