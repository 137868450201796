import { GetPaymentsParams } from '~api/punchlist/payments/types'
import { Payment, PaymentType } from '../types'

/** TYPES **/
export const paymentsTypes = {
  GET_PAYMENTS: 'GET_PAYMENTS',
  SET_PAYMENTS: 'SET_PAYMENTS',
  CREATE_PAYMENT: 'CREATE_PAYMENT',
}

/** ACTIONS **/
export interface GetPaymentsAction {
  type: typeof paymentsTypes.GET_PAYMENTS
  payload: {
    params?: GetPaymentsParams
  }
  callback?: (succ: boolean) => void
}

export interface SetPaymentsAction {
  type: typeof paymentsTypes.SET_PAYMENTS
  payload: Payment[]
}

export interface CreatePaymentAction {
  type: typeof paymentsTypes.CREATE_PAYMENT
  payload: Partial<Payment>
  callback?: (succ: boolean) => void
}

export type PaymentsActions =
  | GetPaymentsAction
  | SetPaymentsAction
  | CreatePaymentAction

/** REDUCER **/

export type PaymentsType = PaymentType