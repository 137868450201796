import { Box, Checkbox, FormControlLabel, Grid, Typography } from "@mui/material"
import { FC, useEffect, useState } from "react"
import Icon from "../../../../../../UI/CustomUI/atoms/Icon"
import { DropDownSelectProps } from "./types"
import styles from './styles.module.scss'
import DropDown from "src/components/UI/CustomUI/atoms/DropDown"
import { GroupedItem } from "src/ducks/types"
import { round } from "src/helpers"

const DropDownSelect: FC<DropDownSelectProps> = ({
    workItem, checkAll, index, initiallyExpanded, initiallyChecked, children
}) => {

    const [checked, setChecked] = useState(false)

    useEffect(() => {
        setChecked(initiallyChecked || false)
    }, [initiallyChecked])

    const totalWorktem = (workItem: GroupedItem) => {
        let total = 0
        workItem.items.forEach(item => {
            total += item.totalPrice
        })
        return total
    }

    const costWorktem = (workItem: GroupedItem) => {
        let total = 0
        workItem.items.forEach(item => {
            total += item.totalCost
        })
        return total
    }

    return (
        <DropDown
            title={
                <Grid container sx={{ alignItems: 'center' }} justifyContent="space-between">
                    <FormControlLabel
                        aria-label="Enable"
                        onClick={(event: React.SyntheticEvent<EventTarget>) => event.stopPropagation()}
                        onFocus={(event: React.SyntheticEvent<EventTarget>) => event.stopPropagation()}
                        control={
                            <Checkbox
                                color='checkbox'
                                onChange={() => {
                                    setChecked(!checked)
                                    checkAll && checkAll(index, checked)
                                }}
                            />}
                        checked={checked}
                        label={workItem.category}
                        style={{ color: 'white', marginLeft: '0px' }}
                    />
                    <Box display="flex" gap={3} px={2}>
                        <Typography color="white">Cost: ${round(costWorktem(workItem), 2)}</Typography>
                        <Typography color="white">Total: ${round(totalWorktem(workItem), 2)}</Typography>
                    </Box>
                </Grid>
            }
            iconOpen={<Icon name="AddBox" color="var(--blue700)" height="100%" />}
            iconClose={<Icon name="IndeterminateCheckBox" color="var(--blue700)" height="100%" />}
            initallyExpanded={initiallyExpanded}
            className={styles.DropDown}
        >
            {children}
        </DropDown>
    )
}

export default DropDownSelect