/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react'
import { Box, Checkbox, FormControlLabel, Typography } from 'src/components/UI'
import TabNavigation from 'src/components/UI/CustomUI/organisms/TabNavigation'
import OrderItems from './OrderItems'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { estimateActions, ordersActions } from 'src/ducks/actions'
import styles from './styles.module.scss'
import { PrivateLoader } from 'src/components/templates'
import OrderItemsModal from './OrderItems/Modals'
import { getItems, getOrders } from 'src/ducks/selectors'
import { WorkOrdersContext } from './context'
import AntSwitch from 'src/components/UI/CustomUI/atoms/AntSwitch'
import { workOdersTypes } from './context/types'
import OCRReview from './OCRReview'
import EditView from './EditView'

const WorkOrder = ({ children }: { children: JSX.Element }) => {
  return (
    <Box sx={{
      border: '2px solid var(--border-color)',
      borderTop: 0,
      borderRadius: '0 0 0.75rem 0.75rem',
      paddingTop: '1rem',
      height: '100%',
      minHeight: '30vh'
    }}
    >
      {children}
    </Box>
  )
}

const WorkOrders = () => {
  const dispatch = useDispatch()
  const { dispatch: dispatchContext, state: { pageLoading, selectedTab } } = useContext(WorkOrdersContext)
  const items = useSelector(getItems())
  const orders = useSelector(getOrders())
  const { id } = useParams()

  const [mode, setMode] = useState({
    ocrMode: false,
    editMode: false
  })

  const TABS: any = [
    {
      label: '',
      content: <WorkOrder><OrderItems /></WorkOrder>
    },
    {
      label: '',
      content: <WorkOrder><OCRReview /></WorkOrder>
    },
    {
      label: '',
      content: <WorkOrder><EditView /></WorkOrder>
    }
  ]
  /* 
    const fetchServices = () => {
      dispatchContext({ type: 'SET_PAGE_LOADING', payload: true })
      dispatch(ordersActions.fetchOrders({
        params: { jobId: id }
      }, (succ) => {
        if (succ) {
          dispatchContext({ type: 'SET_PAGE_LOADING', payload: false })
        }
      }))
    } */

  /*   useEffect(() => {
      fetchServices()
    }, [id]) */

  const getTab = () => {
    if (mode.ocrMode) return 1
    if (mode.editMode) return 2
    return 0
  }

  const handleOcrMode = () => {
    setMode({ editMode: false, ocrMode: !mode.ocrMode })
  }

  const handleEditMode = () => {
    setMode({ ocrMode: false, editMode: !mode.editMode })
  }

  const handleUpdateEstimate = (checked: boolean) => {
    dispatch(estimateActions.updateEstimateByPath([{ op: 'replace', path: '/properties/priceMatched', value: checked }], (succ) => {
      if (succ) {
        dispatchContext({ type: workOdersTypes.REFRESH, payload: true })
      }
    }
    ))
  }

  return (
    <>
      <OrderItemsModal />
      <TabNavigation
        tabs={TABS}
        variant='outlined'
        margin={'0'}
        showExpand
        className={styles.Tabs}
        scrollable
        tabWidth='0'
        customExpanded={mode.ocrMode || mode.editMode}
        selectedTab={getTab()}
        additionalContent={
          <Box width='100%' display='flex' justifyContent="space-between" gap={2}>
            <Box display='flex' gap={2}>
              <FormControlLabel
                label={<Typography marginRight={1} fontWeight={600}>OCR Mode</Typography>}
                labelPlacement='start'
                control={
                  <AntSwitch checked={mode.ocrMode} onChange={handleOcrMode} />
                }
              />
              <FormControlLabel
                label={<Typography marginRight={1} fontWeight={600}>Edit Mode</Typography>}
                labelPlacement='start'
                control={
                  <AntSwitch checked={mode.editMode} onChange={handleEditMode} />
                }
              />
            </Box>
            {/* <Box display="flex" flexDirection="row" alignItems="center">
              <Typography color="navy">Price Match</Typography>
              <Checkbox onChange={(e, checked) => handleUpdateEstimate(checked)} />
            </Box> */}
          </Box>
        }
        containerSx={{
          width: '96vw',
          marginTop: '3rem',
          gap: "1rem",
        }}
      />
    </>
  )
}

export default WorkOrders
