/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from 'src/components/UI'
import Table from 'src/components/UI/CustomUI/organisms/Table'
import headers from './headers'
import FilterHeader from './FilterHeader'
import { getSubscriptionsFilter } from 'src/ducks/filters/selector'
import TablePagination from 'src/components/UI/CustomUI/organisms/Table/TablePagination'
import {
  ROWS_PER_PAGE_EXTENDED,
} from 'src/helpers'

import classes from './styles.module.scss'
import { HeaderType } from 'src/components/UI/CustomUI/organisms/Table/types'
import { PrivateLoader } from 'src/components/templates'
import { getSubscriptions, getSubscriptionsTotalCount } from 'src/ducks/subscriptions/selectors'
import { subscriptionsActions } from 'src/ducks/actions'
import { useNavigate } from 'react-router-dom'

const Subscriptions: FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const subscriptionsFilter = useSelector(getSubscriptionsFilter())
  const subscriptions = useSelector(getSubscriptions)
  const totalCount = useSelector(getSubscriptionsTotalCount)

  const [tableLoading, setTableLoading] = useState<boolean>(false)
  const [tableHeaders, setTableHeaders] = useState<HeaderType[]>(headers)
  const [searchParams, setSearchParams] = useState({})
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(50)
  const [loadingCsv, setLoadingCsv] = useState<boolean>(false)


  const { dateStart, dateEnd, gifted, search, status, downloadCSVCount } = subscriptionsFilter

  /**
   * Update search params whenever pros filter changes
   */
  useEffect(() => {
    setSearchParams({
      search: search || null,
      status: status?.filter((st) => !st.hide).map((st) => st.label) || null,
      giftStatus: gifted?.filter((st) => !st.hide).map((st) => st.param) || null,
      startDate: dateStart ? new Date(dateStart).getTime() / 1000 : null,
      endDate: dateEnd ? new Date(dateEnd).getTime() / 1000 : null
    })
  }, [dateStart, dateEnd, gifted, search, status])

  useEffect(() => {
    if (Object.keys(searchParams).length !== 0) {
      fetchSubscriptions(page, size, searchParams)
    }
  }, [page, size, searchParams])

  /**
   *
   * @param page page number
   * @param size page size
   */
  const fetchSubscriptions = (page?: number, size?: number, searchParams?: any) => {
    const paramPage = page ?? 0
    const paramSize = size ?? 50

    setTableLoading(true)
    dispatch(
      subscriptionsActions.fetchSubscriptions(
        { page: paramPage, size: paramSize, searchParams },
        (succ) => {
          setTableLoading(false)
        }
      )
    )
  }

  const fetchCsvSubscriptions = (count: number, callback: () => void): void => {
    setLoadingCsv(true)
    dispatch(
      subscriptionsActions.fetchCsvSubscriptions({ count, searchParams }, (succ) => {
        setLoadingCsv(false)
        callback()
      })
    )
  }

  const handleClickRow = (row: any, e: React.MouseEvent) => {
    if (e.type !== 'contextmenu') {
      navigate(`/subscriptions/${row.id}`)
      e.preventDefault()
    }
  }

  return (
    <Box className={classes.Licenses__container}>
      <FilterHeader
        headers={tableHeaders}
        setHeaders={setTableHeaders}
        fetchData={() => fetchSubscriptions(page, size, searchParams)}
        loadingCsv={loadingCsv}
        fetchCsv={(callback) => fetchCsvSubscriptions(downloadCSVCount || 400, callback)}
      />
      <TablePagination
        callToApi={(start, limit) => {
          setPage(start)
          setSize(limit)
        }}
        totalItems={totalCount}
        rowsPerPageOptions={ROWS_PER_PAGE_EXTENDED}
      />
      <Box overflow="overlay" flex={1}>
        <Table
          loading={tableLoading}
          headers={tableHeaders}
          defaultOrder="desc"
          hasPagination={false}
          style={{ height: '100%' }}
          onRightClickRow={handleClickRow}
          rowAction={handleClickRow}
        >
          {subscriptions || []}
        </Table>
      </Box>
    </Box>
  )
}

export default Subscriptions
