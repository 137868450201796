import { useEffect, useState } from 'react'
import QRCode from 'qrcode'

const useQRCodesGenerator = (url) => {
  const [dataUrl, setDataUrl] = useState('')

  useEffect(() => {
    QRCode.toDataURL(url, { width: 100 }, (err, dataUrl) => {
      if (err) console.error(err)

      // set dataUrl state to dataUrl
      setDataUrl(dataUrl)
      // return dataUrl
    })
  }, [])
  return [dataUrl]
}

export default useQRCodesGenerator
