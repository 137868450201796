import { FC } from 'react'
import { ModalConfirmProps } from './types'
import Modal from 'src/components/UI/CustomUI/molecules/Modal'
import { Button, Grid, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { getEstimate } from 'src/ducks/selectors'
import { ESTIMATE_STATUS } from 'src/helpers'
import { estimateActions, itemsActions } from 'src/ducks/actions'
import { UpdateRequest } from '~api/types'

const ModalBackToBuilder: FC<ModalConfirmProps> = ({
  open,
  reset,
  setOpen,
}) => {
  const dispatch = useDispatch()

  const estimate = useSelector(getEstimate())

  const handleConfirm = (): void => {
    const request: UpdateRequest = [
      { op: 'add', path: '/status', value: ESTIMATE_STATUS.NEW },
      { op: 'add', path: '/estimatorId', value: null },
      { op: 'add', path: '/onHold', value: false },
      { op: 'add', path: '/holdNotes', value: null },
      { op: 'add', path: '/properties/archived', value: false },
      { op: 'add', path: '/properties/excludedFromReports', value: false },
    ]

    dispatch(
      estimateActions.updateEstimateByPath(request, (succ) => {
        if (succ) {
          dispatch(estimateActions.fetchEstimate(estimate?.id || ''))
        }
      })
    )

    if (reset) {
      dispatch(
        itemsActions.deleteEstimateItems(estimate?.id || '', (succ) => {
          if (succ) {
            dispatch(
              itemsActions.fetchItemsByCategory({
                params: {
                  jobId: estimate?.id,
                },
              })
            )
          }
        })
      )
    }

    setOpen(false)
  }

  return (
    <Modal open={open} setOpen={setOpen} title="Are you sure?">
      <Grid container flexDirection="column" style={{ gap: '12px' }}>
        <Typography variant="h5">
          {reset
            ? 'Are you sure you want to erase all items and go back to builder?  You will lose all estimate items and have to start over in the builder.'
            : 'Are you sure you want to send this estimate back to the estimate builder?'}
        </Typography>

        <Grid container justifyContent="flex-end" style={{ gap: '8px' }}>
          <Button
            variant="containedBig"
            onClick={handleConfirm}
            sx={{
              backgroundColor: '#00c851 !important',
              border: '0 !important',
            }}
          >
            Confirm
          </Button>
          <Button
            variant="containedBig"
            onClick={() => setOpen(false)}
            sx={{
              backgroundColor: '#ff3547 !important',
              border: '0 !important',
            }}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Modal>
  )
}

export default ModalBackToBuilder
