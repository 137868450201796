import { formatParams } from 'src/helpers'
import axios from '../axios'
import { ApiController } from './types'
import { Territory } from 'src/ducks/types'

const base = '/territories'

const apiController: ApiController = {
  getTerritories: async () =>
    await axios
      .get(`${base}`)
      .then(response => response.data),
  getTerritoryRepairItems: async ({ params, territoryId }) => {
    const parameters = formatParams(params)
    return await axios
      .get(`${base}/${territoryId}/repairItems?${parameters}`)
      .then(response => response.data)
  },
  getTerritoryById: async (id: string) =>
    await axios
      .get(`${base}/${id}`)
      .then(response => response.data),
  createTerritory: async (payload: Partial<Territory>) =>
    await axios
      .post(`${base}`, payload)
      .then(response => response.data),
  deleteTerritory: async (id: string) =>
    await axios
      .delete(`${base}/${id}`)
      .then(response => response.data),
  updateTerritory: async (id: string, request) =>
    await axios
      .patch(`${base}/${id}`, request)
      .then(response => response.data),


}

export default apiController
