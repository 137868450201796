import {
  Document,
  Font,
  Image,
  Page,
  pdf,
  Text,
  View,
} from '@react-pdf/renderer'
import { formatTimestamp, ITEM_CATEGORY, round } from '../../../../../../../../helpers'
import moment from 'moment'

import Moranga from '../../../../../../../../assets/fonts/Moranga-Medium.otf'
import MorangaR from '../../../../../../../../assets/fonts/Moranga-Regular.otf'
import NouvelleBold from '../../../../../../../../assets/fonts/Nouvelle-Bold.otf'
import NouvelleNormal from '../../../../../../../../assets/fonts/Nouvelle-Normal.otf'
import BetterSansRegular from '../../../../../../../../assets/fonts/BetterSans-Bold.otf'
import BetterSansBold from '../../../../../../../../assets/fonts/BetterSans-Regular.otf'

import style from './style'
import styleBetter from './styleBetter'
import { logo } from 'src/assets'

// Font.register({ family: 'Moranga', src: Moranga })
// Font.register({ family: 'MorangaR', src: MorangaR })
// Font.register({ family: 'NouvelleBold', src: NouvelleBold })
// Font.register({ family: 'NouvelleNormal', src: NouvelleNormal })
// Font.register({ family: 'BetterSansRegular', src: BetterSansRegular })
// Font.register({ family: 'BetterSansBold', src: BetterSansBold })

const FooterView = ({ showBetterStyle }) => {
  const styles = showBetterStyle ? styleBetter : style
  return (
    <>
      <Text
        fixed
        render={() => formatTimestamp(moment().unix(), 'MM/DD/YYYY HH:mm')}
        style={styles.printDate}
      />
      <Text
        fixed
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
        style={styles.pageNumber}
      />
    </>
  )
}

const total = (item) => {
  return '$'.concat(round(item.totalCost, 2))
}

const ItemView = ({ item }) => {
  const styles = style
  return (
    <View style={styles.bodyItem}>
      <View
        style={[
          styles.containerHorizontal,
          styles.content,
          styles.justifyBetween,
        ]}
      >
        <Text
          style={[styles.itemText, styles.textBold, styles.paddingRight]}
          render={() => `${item.title} • QTY: ${item.quantity}`}
        />
        <Text
          style={[styles.itemText, styles.textBold]}
          //render={() => `$ ${round(item.totalPrice, 2)}`}
          render={() => total(item)}
        />
      </View>
      {item?.disclaimer ? (
        <View style={[styles.containerHorizontal, styles.content]}>
          <Text
            style={[styles.itemText, styles.textBold]}
            render={() => 'Disclaimer: '}
          />
          <Text style={[styles.itemText, styles.leftSeparation]}>
            {item.disclaimer}
          </Text>
        </View>
      ) : null}
      <View style={[styles.containerHorizontal, styles.content]}>
        <Text
          style={[styles.itemText, styles.textBold]}
          render={() => 'Notes: '}
        />
        <Text style={[styles.itemText, styles.leftSeparation]}>
          {`${!item.inspectionReportNote || item.inspectionReportNote === ''
            ? 'No notes in this item'
            : item.inspectionReportNote
            }`}
        </Text>
      </View>
      {item?.requestedRepairNote ? (
        <View style={[styles.containerHorizontal, styles.content]}>
          <Text
            style={[styles.itemText, styles.textBold]}
            render={() => 'Requested Repair: '}
          />
          <Text style={[styles.itemText, styles.leftSeparation]}>
            {item.requestedRepairNote}
          </Text>
        </View>
      ) : null}
      <View style={[styles.containerHorizontal, styles.content]}>
        {item.status === 'REJECTED' && (
          <Text style={[styles.itemText, styles.textBold, styles.removed]}>
            {' '}
            REMOVED{' '}
          </Text>
        )}
      </View>
      {item.imageFiles?.length > 0 ? (
        <View style={[styles.content, styles.itemImages, styles.topSeparation]}>
          {item.imageFiles?.map((imgFile, index) => {
            if (!imgFile.fileUrl) return null
            return (
              <Image key={index} src={imgFile.fileUrl} style={styles.image} />
            )
          })}
        </View>
      ) : null}
    </View>
  )
}

const ItemSectionView = ({
  items,
  groupKey,
  index,
  price,
  // showBetterStyle,
}) => {
  const styles = style

  let total = '$'.concat(round(price, 2))
  let finalTotal = '$'.concat(round(price, 2))
  const rangePriceMin = items.reduce((acc, obj) => {
    return (
      acc +
      (obj?.status !== 'REJECTED'
        ? obj.minPriceRange
          ? obj.minPriceRange
          : 0
        : 0)
    )
  }, 0)

  const rangePriceMax = items.reduce((acc, obj) => {
    return (
      acc +
      (obj?.status !== 'REJECTED'
        ? obj.maxPriceRange
          ? obj.maxPriceRange
          : 0
        : 0)
    )
  }, 0)

  if (rangePriceMin && rangePriceMax) {
    if (rangePriceMin === null || rangePriceMax === null)
      total = '$'.concat(round(price, 2))

    if (rangePriceMin === rangePriceMax) total = '$'.concat(round(price, 2))

    if (rangePriceMin !== rangePriceMax)
      total = '$'
        .concat(round(rangePriceMin + (price || 0), 2))
        .concat(' - $')
        .concat(round(rangePriceMax + (price || 0), 2))
  }

  return (
    <View
      key={index + Math.floor(Math.random() * 1000 + 1)}
      style={[styles.container, styles.topSeparation]}
    >
      <View style={[styles.containerHorizontal, styles.sectionTitle]}>
        <Text style={styles.title} render={() => `${groupKey}: `} />
        <Text style={[styles.price, styles.textBold]}>{groupKey === ITEM_CATEGORY.EVALUATE ? total : finalTotal}</Text>
      </View>
      {items.map((item, indx) => (
        <View key={indx}>
          <ItemView item={item} />
          {indx < items.length - 1 && (
            <View
              style={[
                styles.separator,
                item.imageFiles?.length > 0
                  ? styles.bigSpacing
                  : styles.littleSpacing,
              ]}
            />
          )}
        </View>
      ))}
    </View>
  )
}

const UserInfoView = ({ userInfo, imageUris, showBetterStyle }) => {
  const styles = showBetterStyle ? styleBetter : style
  return (
    <View
      style={[
        styles.containerHorizontal,
        styles.verticalSeparator,
        styles.justifyBetween,
      ]}
    >
      <View style={[styles.containerHorizontal]}>
        <View style={styles.itemContainer}>
          {imageUris?.punchlistLogoUri && (
            <Image
              src={imageUris.punchlistLogoUri}
              style={styles.userInfoImage}
            />
          )}
        </View>
        {!showBetterStyle && (
          <View style={[styles.itemContainer, styles.alignBetween]}>
            <Text style={styles.userInfoText}>{userInfo.name}</Text>
            <Text style={styles.userInfoText}>{userInfo.city}</Text>
            <Text style={styles.userInfoText}>{userInfo.address}</Text>
          </View>
        )}
      </View>
      <View style={styles.itemContainer}>
        {imageUris?.mailIconUri ? (
          <Image
            src={imageUris.mailIconUri}
            style={styles.userInfoLabelImage}
          />
        ) : null}
        <Text style={styles.userInfoText}>{userInfo.mail}</Text>
      </View>
      <View style={styles.itemContainer}>
        {imageUris?.sentIconUri && (
          <Image
            src={imageUris.sentIconUri}
            style={styles.userInfoLabelImage}
          />
        )}
        <Text style={styles.userInfoText}>{userInfo.sent}</Text>
      </View>
      <View style={styles.itemContainer}>
        {imageUris?.approvedOnIconUri && (
          <Image
            src={imageUris.approvedOnIconUri}
            style={styles.userInfoLabelImage}
          />
        )}
        <Text style={styles.userInfoText}>{userInfo.approvedOn}</Text>
      </View>
      <View style={[styles.itemContainer, styles.justifyCenter]}>
        <View style={styles.containerHorizontal}>
          <Text style={[styles.itemText, styles.textBold]}>Total:</Text>
          <Text style={styles.itemText}>{` ${userInfo.total}`}</Text>
        </View>
      </View>
    </View>
  )
}

const CompleteBodyView = ({
  groupedItems,
  workOrderInfo,
  // imageUris,
  // showBetterStyle,
}) => {
  const styles = style
  return (
    <View style={styles.bodyItems}>
      {/* <UserInfoView
        imageUris={imageUris}
        userInfo={userInfo}
        showBetterStyle={showBetterStyle}
      />
      {imageUris?.chartUri && (
        <Image src={imageUris.chartUri} style={styles.chartImage} />
      )} */}
      {groupedItems.map((group, index) => {
        const items = group.items
        const price = items.reduce((acc, obj) => {
          return acc + (obj?.status !== 'REJECTED' ? obj.totalCost : 0)
        }, 0)
        return (
          <ItemSectionView
            key={index}
            groupKey={group.category}
            index={index}
            items={items}
            price={price}
          // showBetterStyle={showBetterStyle}
          />
        )
      })}

      <View
        style={[
          styles.containerHorizontal,
          styles.sectionTitle,
          styles.justifyBetween,
        ]}
      >
        <Text style={[styles.title, styles.titleBold]}>Total:</Text>
        <Text
          style={[styles.price, styles.textBold]}
        >{`${workOrderInfo.total}`}</Text>
      </View>
    </View>
  )
}

const ItemsPdf = ({
  groupedItems,
  workOrderInfo,
  imageUris,
  showBetterStyle,
}) => {
  const styles = showBetterStyle ? styleBetter : style
  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <View style={styles.logoView}>
          <Image style={styles.logo} src={logo} alt="punchlist" />
        </View>
        <CompleteBodyView
          groupedItems={groupedItems}
          // imageUris={imageUris}
          workOrderInfo={workOrderInfo}
        // showBetterStyle={showBetterStyle}
        />
        <FooterView showBetterStyle={showBetterStyle} />
      </Page>
    </Document>
  )
}

const download = (blob, name) => {
  const url = window.URL.createObjectURL(new Blob([blob]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', name + '.pdf')
  if (document.body) document.body.appendChild(link)
  link.click()
}

export const generatePdf = async ({
  groupedItems,
  workOrderInfo,
  // imageUris,
  // showBetterStyle,
}) => {
  download(
    await pdf(
      <ItemsPdf
        groupedItems={groupedItems}
        workOrderInfo={workOrderInfo}
      // imageUris={imageUris}
      // showBetterStyle={showBetterStyle}
      />
    ).toBlob(),
    `WorkOrder_${new Date().getTime()}`
  )
}
