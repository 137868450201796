import { Box, Grid } from "@mui/material"
import { FC } from "react"
import FileBox from "src/components/UI/CustomUI/atoms/FileBox"
import { AttachmentsProps, FileAttachment } from "./types"

const Attachments: FC<AttachmentsProps> = ({ files }) => {
  return (
    <Box overflow="overlay" height="190px" width="100%">
      <Grid container spacing={1}>
        {files?.map((file: FileAttachment) => (
          <Grid item xs={12} key={file.name}>
            <FileBox {...file} />
          </Grid>
        )) || 'No Attachments'}
      </Grid>
    </Box>
  )
}

export default Attachments