import { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Table from 'src/components/UI/CustomUI/organisms/Table'
import { materialInvoicesActions, ordersActions } from 'src/ducks/actions'
import { getJob, getMaterialInvoices } from 'src/ducks/selectors'
import { InvoiceMaterialProvider } from '../../../../ControlButtons/Modals/ModalMaterialInvoices/context'
import headers from './headers'
import { MaterialInvoicesTabProps } from './types'

const MaterialInvoicesTab: FC<MaterialInvoicesTabProps> = ({ title, hasPagination = false }) => {
  const dispatch = useDispatch()
  const materialInvoices = useSelector(getMaterialInvoices())
  const job = useSelector(getJob())

  const [loading, setLoading] = useState(false)

  const fetchService = useCallback(() => {
    if (job?.id) {
      dispatch(materialInvoicesActions.fetchMaterialInvoices(job?.id,
        (_succ: boolean) => {
          setLoading(false)
        }))
      dispatch(ordersActions.fetchOrders({
        params: { jobId: job.id }
      }, (_succ) => {
        setLoading(false)
      }))
    }
  }, [job?.id])

  useEffect(() => {
    fetchService()
  }, [job?.id])

  return (
    <InvoiceMaterialProvider>
      <Table
        headers={headers}
        title={title}
        loading={loading}
        hasPagination={hasPagination}
      >
        {materialInvoices ?? []}
      </Table>
    </InvoiceMaterialProvider>
  )
}

export default MaterialInvoicesTab
