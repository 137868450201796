import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { icons } from 'src/assets'
import { Box, Button, SelectInput } from 'src/components/UI'
import Table from 'src/components/UI/CustomUI/organisms/Table'
import { estimateItemsActions, historyActions, itemsActions } from 'src/ducks/actions'
import { getJobHistory } from 'src/ducks/history/selectors'
import { getEstimate, getItems, getItemsSortedByCategory } from 'src/ducks/selectors'
import { useParams } from 'react-router-dom'
import headers from './headers'
import { getEstimateItems } from 'src/ducks/estimateItems/selectors'
import { SelectInputOption } from 'src/components/UI/CustomUI/atoms/SelectInput/types'
import { ROWS_PER_PAGE_EXTENDED } from 'src/helpers'

const History = () => {
  const { id } = useParams()
  const OPTIONS = [{ key: "all", label: "All History" },
  { key: "ESTIMATE_RELEASE", label: "Estimate Released" },
  { key: "ESTIMATE_RESEND", label: "Estimate Resent" },
  { key: "item", label: "Item Changed" }]

  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const estimateHistory = useSelector(getJobHistory)
  const estimate = useSelector(getEstimate())
  const items = useSelector(getEstimateItems(false))

  const [filter, setFilter] = useState(OPTIONS[0]);
  const [filterOptions, setFilterOptions] = useState<SelectInputOption | undefined>();
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  useEffect(() => {
    if (estimate) {
      dispatch(estimateItemsActions.fetchEstimateItems({ params: { jobId: estimate.id } }))
    }
  }, [dispatch, estimate])

  const fetchEstimateHIstory = useCallback(() => {
    if (id) {
      dispatch(
        historyActions.fetchHistoryJob(id, (_succ: boolean) => {
          setLoading(false)
        })
      )
    }
  }, [dispatch, id])

  useEffect(() => {
    fetchEstimateHIstory()
  }, [fetchEstimateHIstory, estimate])

  const handleFilterChange = (newValue: any) => {
    setFilter(newValue);
  };

  const handleFilterOptionsChange = (newValue: any) => {
    setFilterOptions(newValue);
  };

  const handleClearFilter = () => {
    setFilter(OPTIONS[0])
    setFilterOptions(undefined)
    // handle applying filters here
  };

  useEffect(() => {
    dispatch(historyActions.fetchEstimateHistory({
      estimateId: id ?? '',
      event: filter.key === 'all' || filter.key === "item" ? '' : filter.key,
      itemTitle: filter.key === "item" ? filterOptions?.key ?? '' : ''
    }))
  }, [filter, filterOptions])

  return (
    <Box width='100%'>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2, width: '100%', mb: '8px' }}>
        <SelectInput
          sx={{ width: '100%' }}
          label='Filter:'
          onChange={handleFilterChange}
          options={OPTIONS}
          value={filter}
        />
        {filter.key === 'item' && (
          <SelectInput
            sx={{ width: '100%' }}
            label='Filter Options:'
            onChange={handleFilterOptionsChange}
            options={items.map((item) => ({ key: item.itemId, label: item.title }))}
            value={filterOptions}
          />
        )}

        <Button variant="outlined" onClick={handleClearFilter} endIcon={<icons.Clear />} sx={{ mt: 2.5 }}>Clear Filter</Button>
      </Box>
      <Box height='fit-content'>
        <Table
          headers={headers}
          loading={loading}
          totalItems={estimateHistory.length}
          hasTopPagination
          hasPagination={false}
          rowsPerPageDefault={size}
          rowsPerPageOptions={ROWS_PER_PAGE_EXTENDED}
          callToApi={(start, limit) => {
            setPage(start)
            setSize(limit)
          }}
        >
          {estimateHistory.slice(page * size, (page * size + size > estimateHistory.length) ? estimateHistory.length : (page * size + size))}
        </Table>
      </Box>
    </Box>
  )
}

export default History
