import { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Table from 'src/components/UI/CustomUI/organisms/Table'
import { getWorkflowsbyStatus } from 'src/ducks/selectors'
import { Grid, Typography } from '@mui/material'
import { isTodayColor } from 'src/helpers'
import styles from './styles.module.scss'
import { getHistoryJobTask } from 'src/ducks/history/selectors'
import { useParams } from 'react-router-dom'
import { historyActions, workflowsActions } from 'src/ducks/actions'
import useHeaders from './headers'

const JobsTasks: FC = () => {
  const { id } = useParams()
  const dispatch = useDispatch()

  const { headers } = useHeaders();
  const historytask = useSelector(getHistoryJobTask) ?? []
  const jobTask = useSelector(getWorkflowsbyStatus) ?? []

  const [tasksLoading, setTasksLoading] = useState(true)

  const fetchTasks = useCallback(() => {
    dispatch(
      workflowsActions.fetchWorkflowsInstance({ params: { page: 0, size: 99999, jobId: id, includeUpcoming: true } }
      ))
    dispatch(
      historyActions.fetchHistoryJobTask(id as string, (_succ: boolean) => {
        setTasksLoading(false)
      })
    )
  }, [dispatch, id])

  useEffect(() => {
    fetchTasks()
  }, [fetchTasks, id])

  return (
    <Grid container item direction='column' spacing={0.5}>
      <Grid item>
        <Typography variant='h6'>Jobs Tasks</Typography>
      </Grid>
      <Grid item>
        <Table
          headers={headers}
          rowsPerPageDefault={25}
          hasPagination={false}
          rowColor={(row: any) => row?.name ? isTodayColor(row?.triggerDate, row?.status) : 'transparent'}
          small
          className={styles.JobsTasks__Table}
          loading={tasksLoading}
        >
          {[...jobTask, ...historytask] ?? []}
        </Table>
      </Grid>
    </Grid>
  )
}

export default JobsTasks
