import { SearchParams } from '../searches/types'
import { EstimateItem } from '../types'
import { EstimatesActions, EstimatesType, ReleaseEstimateRequest, estimatesTypes } from './types'

const actions = {
  fetchEstimates: (
    payload: {
      page?: number,
      size?: number,
      searchParams?: Partial<SearchParams>,
    },
    callback?: (succ: boolean) => void): EstimatesActions => ({
      type: estimatesTypes.FETCH_ESTIMATES,
      payload,
      callback
    }),
  fetchRelatedEstimates: (payload: string, callback?: (succ: boolean) => void): EstimatesActions => ({
    type: estimatesTypes.FETCH_RELATED_ESTIMATES,
    payload,
    callback
  }),
  updateEstimate: (payload: Partial<EstimateItem>, callback?: (succ: boolean) => void): EstimatesActions => ({
    type: estimatesTypes.UPDATE_ESTIMATE,
    payload,
    callback
  }),
  setEstimates: (payload: EstimatesType): EstimatesActions => ({
    type: estimatesTypes.SET_ESTIMATES,
    payload
  }),
  setRelatedEstimates: (payload: EstimateItem[]): EstimatesActions => ({
    type: estimatesTypes.SET_RELATED_ESTIMATES,
    payload
  }),
  fetchEstimatesCounts: (
    payload: {
      page?: number,
      size?: number,
      searchParams?: Partial<SearchParams>,
    },
    callback?: (succ: boolean) => void): EstimatesActions => ({
      type: estimatesTypes.FETCH_ESTIMATE_COUNTS,
      payload,
      callback
    }),
  setEstimatesCounts: (payload: EstimatesType): EstimatesActions => ({
    type: estimatesTypes.SET_ESTIMATES_COUNTS,
    payload
  }),

  releaseEstimate: (payload: {
    estimateId: string,
    releaseEstimateRequest: ReleaseEstimateRequest
  }, callback?: (succ: boolean) => void): EstimatesActions => ({
    type: estimatesTypes.RELEASE_ESTIMATE,
    payload,
    callback
  })
}

export default actions
