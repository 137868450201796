import { formatParams } from 'src/helpers';
import axios from '../axios'
import { ApiController } from './types'

const base = '/workflows'

const apiController: ApiController = {
  getWorkflows: async ({
    params
  }) => {
    const parameters = formatParams(params);

    return await axios
      .get(`${base}/instances/tasks?${parameters}`)
      .then(response => response.data)
  },
  triggerWorkflowAction: async (payload) => {
    const { request, params } = payload
    const parameters = formatParams(params);
    return await axios
      .post(`${base}/instances/job/action?${parameters}`, request, {
        headers: { 'Content-Type': 'text/plain' }
      })
      .then(response => response.data)
  },
  skipJobWorkflowAction: async (jobId) => {
    return await axios
      .post(`${base}/instances/job/step?jobId=${jobId}`, {
        headers: { 'Content-Type': 'text/plain' }
      })
      .then(response => response.data)
  },
  skipOrderWorkflowAction: async (orderId) => {
    return await axios
      .post(`${base}/instances/order/step?orderId=${orderId}`, {
        headers: { 'Content-Type': 'text/plain' }
      })
      .then(response => response.data)
  }
}

export default apiController
