import { Theme } from '../../../../../UI'
import { makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => ({
  container: {
    height: '300px',
    overflow: 'overlay',
  },
  searchBox: {
    marginBottom: '1rem !important',
  }
}))
