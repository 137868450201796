import storeInfo from '../store'
import { Payment } from '../types'

export const getPayments = () => (): Payment[] => {
  const { payments } = storeInfo.store.getState()
  return payments?.items || []
}

export const getTotalPayments = () => {
  const { payments } = storeInfo.store.getState()
  return payments ? Number(payments.total) : 0
}