import { authTypes } from '../auth/types'
import {
  InspectionCompaniesActions,
  InspectionCompaniesType,
  inspectionCompaniesTypes,
} from './types'

const initialState: InspectionCompaniesType = {
  items: [],
  start: 0,
  limit: 50,
  total: 0,
}
const reducer = (
  state = initialState,
  action: InspectionCompaniesActions
): InspectionCompaniesType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: {
      return initialState
    }
    case authTypes.LOGOUT_REQUEST: {
      return initialState
    }

    case inspectionCompaniesTypes.SET_INSPECTION_COMPANIES: {
      return {
        ...state,
        start: (payload as InspectionCompaniesType).start,
        limit: (payload as InspectionCompaniesType).limit,
        items: (payload as InspectionCompaniesType).items,
        total: (payload as InspectionCompaniesType).total,
      }
    }

    default:
      return state
  }
}

export default reducer
