import React, { ReactNode } from 'react'
import { Box, Typography } from '@mui/material'
import { getEventHeight, getEventTextColor } from './helper'
import { Vendor } from 'src/ducks/types'
import { VendorOptionProp, ViewType } from './types'
import Tooltip from '@mui/material/Tooltip'
import moment from 'moment'

const ViewEvent = (
  index: number,
  order: any,
  vendors: Vendor[],
  vendorOptions: VendorOptionProp[],
  view: ViewType
): React.ReactNode => {
  const {
    vendorId,
    companyContact,
    address: { fullAddress },
    scheduledStartTimestamp
  } = order

  // get target vendor with vendor id
  const targetVendor =
    vendors[index]?.id === vendorId
      ? vendors[index]
      : vendors.find((vendor) => vendor.id === vendorId)

  const targetVendorOption =
    vendorOptions[index]?.id === vendorId
      ? vendorOptions[index]
      : vendorOptions.find((option) => option.id === vendorId)

  if (!targetVendor || !targetVendor!.color) return null

  if (!targetVendorOption?.checked) return null

  const color = targetVendor?.color
  const textColor = getEventTextColor(color)
  const height = getEventHeight(view)

  const startDate = moment.unix(scheduledStartTimestamp).format('hh:mm A')

  const eventWrapper = (
    ui: ReactNode,
    bgColor: string,
    textColor: string,
    preapproval: boolean
  ): React.ReactElement => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        padding="4px"
        height={height}
        fontFamily="Nouvelle !important"
        fontSize="12px"
        lineHeight="18px"
        borderRadius="4px"
        bgcolor={bgColor}
        color={textColor}
        // borderColor={textColor}
        style={{ cursor: preapproval ? 'default' : 'pointer' }}
      >
        {ui}
      </Box>
    )
  }

  const innerUI = (
    <Box className="event-container">
      <Typography fontWeight={600} style={{ whiteSpace: 'nowrap' }} className="event-title">
        {startDate} {companyContact}
      </Typography>
      <Typography fontWeight={400} style={{ whiteSpace: 'nowrap' }} className="event-subtitle">
        {fullAddress}
      </Typography>
    </Box>
  )

  return (
    <Tooltip arrow title={eventWrapper(innerUI, 'transparent', 'white', order.preapproval)}>
      {eventWrapper(innerUI, color, textColor, order.preapproval)}
    </Tooltip>
  )
}

export default ViewEvent
