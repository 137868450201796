import env from '@beam-australia/react-env'
import { Button, CircularProgress, Grid, Typography } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { ActionsButton } from 'src/components/UI'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import TabNavigation from 'src/components/UI/CustomUI/organisms/TabNavigation'
import { filesActions, itemsActions } from 'src/ducks/actions'
import { getItemById, getOrderById } from 'src/ducks/selectors'
import { FILE_TYPE, formatTimestamp, isEmpty, ITEM_CATEGORY, ITEM_STATUS, ORDER_STATUS, ParseFilesUrl } from 'src/helpers'
import { WorkOrdersContext } from '../../../context'

import styles from './styles.module.scss'
import EstimateInfo from './Tabs/EstimateInfo'
import ImageGallery from './Tabs/ImageGallery'
import Notes from './Tabs/Notes'
import Modal from 'src/components/UI/CustomUI/molecules/Modal'

const FILES_URL = env('FILES_URL') ?? ''

const AddItemModal = () => {
  const dispatch = useDispatch()
  const { dispatch: dispatchContext, state: { item, editItem, selectedOrderId, selectedItemId, totalsLoading } } = useContext(WorkOrdersContext)
  const isEditModal = !isEmpty(item.itemId)
  const { id } = useParams()
  const [buttonLoading, setButtonLoading] = useState(false)
  const order = useSelector(getOrderById(selectedOrderId as string))
  const itemToEdit = useSelector(getItemById(selectedItemId as string))

  const {
    completedTimestamp,
    vendorPaymentTimestamp,
    statusType, csmAcceptanceTimestamp,
  } = order

  const showPaid = statusType && (ORDER_STATUS.PAID === statusType || vendorPaymentTimestamp != null)
  const showAccepted = statusType && (ORDER_STATUS.COMPLETE === statusType || showPaid || csmAcceptanceTimestamp != null)
  const showComplete = statusType && (ORDER_STATUS.REVIEW === statusType || showAccepted || completedTimestamp != null)

  const vendorIsPaid = order.vendorPaymentTimestamp !== null ? true : (showAccepted && showComplete) ? true : false
  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  useEffect(() => {
    if (itemToEdit?.itemId) {
      dispatchContext({ type: 'SET_ITEM', payload: itemToEdit })
      dispatchContext({ type: 'SET_ITEM_VALUE', payload: { attr: 'displayTitle', value: itemToEdit.title } })
    }
  }, [dispatchContext, itemToEdit])

  const TABS = [
    {
      label: 'Estimate Info',
      content: <EstimateInfo />
    },
    {
      label: 'Notes',
      content: <Notes />
    },
    {
      label: 'Image Gallery',
      content: <ImageGallery />
    }
  ]

  const handleClose = () => {
    dispatchContext({ type: 'SET_MODAL_OPEN', payload: false })
    dispatchContext({ type: 'RESET_ITEM_VALUE' })
    dispatchContext({ type: 'RESET_EDIT_ITEM_VALUE' })
  }

  const uploadImages = (auxAction?: 'CREATE_NEW' | 'CLONE') => {
    setButtonLoading(true)
    if (item?.images || editItem?.images) {
      const images = isEditModal ? editItem.images : item.images
      dispatch(
        filesActions.uploadFiles(images as File[], (uploadSucc, fileNameList) => {
          if (
            uploadSucc &&
            fileNameList &&
            fileNameList.length === (images as File[]).length
          ) {
            const imageFiles = (images as File[]).map((_image, index) => {
              const fileName = fileNameList[index]
              return {
                name: fileName,
                fileUrl: ParseFilesUrl({
                  baseUrl: FILES_URL,
                  avatar: fileName ?? ''
                }),
                description: '',
                fileType: FILE_TYPE.INSPECTION_IMAGE
              }
            })
            if (!isEditModal) {
              handleCreate({ imageFiles, auxAction })
            } else {
              handleEditItem({ imageFiles, auxAction })
            }
          } else {
            setButtonLoading(false)
          }
        })
      )
    } else {
      if (!isEditModal) {
        handleCreate({ auxAction })
      } else {
        handleEditItem({ auxAction })
      }
    }
  }

  const handleCreate = ({ imageFiles, auxAction }: { imageFiles?: any, auxAction?: 'CREATE_NEW' | 'CLONE' }) => {
    const request = {
      ...item,
      // unitLaborHours: item.totalLaborHours,
      orderId: selectedOrderId,
      estimateId: order.estimateId,
      imageFiles
    }
    dispatch(
      itemsActions.createItem(request,
        (success) => {
          setButtonLoading(false)
          dispatchContext({ type: 'SET_PAGE_LOADING', payload: false })
          dispatchContext({ type: 'SET_ITEM_VALUE', payload: { attr: 'images', value: [] } })
          if (success) {
            switch (auxAction) {
              case 'CREATE_NEW':
                dispatchContext({ type: 'RESET_ITEM_VALUE' })
                break
              case 'CLONE':
                break
              default:
                handleClose()
            }
          }
        })
    )
  }

  const handleRemoveItem = () => {

    setOpenDeleteModal(true)
    /*
        if (item.itemId) {
    
    
          dispatch(itemsActions.deleteItem({ itemId: item.itemId, selectedOrderId: selectedOrderId || undefined }, () => {
            handleClose()
          }))
    
    
        }
        */
  }

  const handleDeleteItemPermanently = () => {
    if (item.itemId) {

      dispatch(itemsActions.deleteItem({ itemId: item.itemId, selectedOrderId: selectedOrderId || undefined, jobId: id || '' }, () => {
        handleClose()
      }))

    }
  }

  const handleEditItem = ({ imageFiles, auxAction }: { imageFiles?: any, auxAction?: 'CREATE_NEW' | 'CLONE' }) => {
    if (!item.itemId) return

    const request = {
      ...editItem
    }

    if ('totalMaterialCost' in request) request.totalMaterialCost = Number(request.totalMaterialCost)
    if ('totalLaborCost' in request) request.totalLaborCost = Number(request.totalLaborCost)
    if ('totalPrice' in request) request.totalPrice = Number(request.totalPrice)
    if ('evaluateReason' in request && request.evaluateReason) request.evaluateReason = String(request.evaluateReason)
    if ('minPriceRange' in request && request.minPriceRange) request.minPriceRange = request.category !== ITEM_CATEGORY.EVALUATE ? undefined : Number(request.minPriceRange)
    if ('maxPriceRange' in request && request.maxPriceRange) request.maxPriceRange = request.category !== ITEM_CATEGORY.EVALUATE ? undefined : Number(request.maxPriceRange)
    if ('quantity' in request && !request.quantity) request.quantity = 1

    if (imageFiles) {
      const images: any = [...item.imageFiles || [], ...imageFiles || []]
      request.imageFiles = images
    }
    dispatch(
      itemsActions.updateItem({ itemId: item.itemId, partialItem: request, selectedOrderId: selectedOrderId || undefined },
        (success) => {
          setButtonLoading(false)
          dispatchContext({ type: 'SET_PAGE_LOADING', payload: false })
          if (success) {
            dispatch(itemsActions.fetchItemsByCategory({ params: { jobId: id, orderId: item.orderId as string } }, (succ) => { }))
            switch (auxAction) {
              case 'CREATE_NEW':
                dispatchContext({ type: 'RESET_ITEM_VALUE' })
                dispatchContext({ type: 'RESET_EDIT_ITEM_VALUE' })
                break
              case 'CLONE':
                dispatchContext({ type: 'RESET_ITEM_VALUE' })
                dispatchContext({ type: 'RESET_EDIT_ITEM_VALUE' })
                break
              default:
                handleClose()
            }
          }
        })
    )
  }

  return (
    <>
      <Grid item container spacing={2} justifyContent='flex-end'>
        <Grid item xs={12} style={{ minHeight: '85vh', paddingBottom: '6rem' }}>
          <TabNavigation tabs={TABS} variant='text' className={styles.TabNavigation} />
        </Grid>
        <Grid
          container
          item
          justifyContent='space-between'
          xs={12}
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            background: 'white',
            paddingBottom: '1rem',
            padding: '0 !important',
            zIndex: '1'
          }}
        >
          <Grid item xs={3}>
            {isEditModal && (
              <>
                <Typography>Added by: <Typography variant='body1Bold'>{item.createdByName}</Typography></Typography>
                <Typography>{formatTimestamp(item.createdOn as number, 'MM/DD/YYYY HH:mm')}</Typography>
              </>
            )}
          </Grid>

          <Grid item container xs={7} spacing={2} justifyContent='flex-end' marginRight={3}>
            {itemToEdit.status === ITEM_STATUS.NEW ? <Grid item xs={5}>
              <Button
                variant={isEditModal ? 'containedErrorLight' : 'containedLight'}
                onClick={isEditModal ? handleRemoveItem : handleClose}
                fullWidth
                disabled={vendorIsPaid}
              >
                {isEditModal ? 'Remove Item' : 'Cancel'}
              </Button>
            </Grid> : <></>}

            <Grid item xs={6}>
              <ActionsButton
                icon={<Icon name='ArrowDropDown' />}
                text='Save'
                onClick={uploadImages}
                iconPosition='end'
                loading={buttonLoading}
                disabled={!item.title || (isEditModal && isEmpty(editItem)) || (!isEditModal && totalsLoading)}
                actions={[{
                  label: 'Save & Create New',
                  onClick: () => {
                    uploadImages('CREATE_NEW')
                  },
                  disabled: vendorIsPaid
                },
                {
                  label: 'Save & Clone',
                  onClick: () => {
                    uploadImages('CLONE')
                  }
                }]}
                variant='contained'
                sx={{
                  minWidth: '100% !important',
                  display: 'flex',
                  justifyContent: 'space-between',
                  backgroundColor: 'var(--blue700)',
                  borderColor: 'var(--blue700)',
                  '&:hover': {
                    backgroundColor: 'var(--blue800)',
                    borderColor: 'var(--blue800)'
                  }
                }}
              />
            </Grid>
          </Grid>

        </Grid>
      </Grid>

      {openDeleteModal &&
        <Modal
          setOpen={setOpenDeleteModal}
          open={openDeleteModal}
          title={
            <Grid container
              spacing={1}
              flexWrap="nowrap"
              alignItems="center"
              justifyContent="center">
              <Grid item>
                This cannot be undone!
              </Grid>
            </Grid>
          }
        >
          <Grid container spacing={2}>
            <Grid item container xs={12} alignItems="center" justifyContent="center" flexWrap="nowrap">
              <Grid item>
                <Typography>Permanently remove the item?</Typography>
              </Grid>

            </Grid>
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={6}>
                <Button variant='outlined' onClick={() => { setOpenDeleteModal(false) }} fullWidth>
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button variant='containedError' fullWidth onClick={handleDeleteItemPermanently}
                  endIcon={!buttonLoading && <Icon name='DeleteOutline' />}
                >
                  {buttonLoading ? <CircularProgress color='info' size={'1.4rem'} /> : 'Permanently Delete'}
                </Button>
              </Grid>
            </Grid>
          </Grid >
        </Modal>
      }
    </>
  )
}

export default AddItemModal
