import { Box, Button, Grid, Typography } from '@mui/material'
import { useContext, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import { invoiceActions, jobActions } from 'src/ducks/actions'
import {
  getInvoicePreview,
  getInvoicePDFPath,
} from 'src/ducks/invoice/selectors'
import { round } from 'src/helpers'
import { PreviewInvoiceContext } from '../context'
import { previewInvoiceTypes } from '../context/types'
import { ScrollWrapper } from 'src/components/templates'
import styles from './styles.module.scss'
import { getItemsSortedByCategory } from 'src/ducks/selectors'
import { icons } from '../../../../../assets'
import { toast } from 'react-toastify'
import ListAltIcon from '@mui/icons-material/ListAlt'
import InvoiceOptionModal from './InvoiceOptionModal'
import { CheckListProps } from './InvoiceOptionModal/types'
import { optionInfo } from './InvoiceOptionModal/constants'

const InvoicePrice = () => {
  const invoicePreview = useSelector(getInvoicePreview())
  const invoicePDFPath = useSelector(getInvoicePDFPath())
  const dispatch = useDispatch()
  const { jobId } = useParams()
  const navigate = useNavigate()
  const itemsByCategoryNew = useSelector(getItemsSortedByCategory())
  const { dispatch: dispatchContext } = useContext(PreviewInvoiceContext)

  const {
    state: {
      isValid,
      ccEmails,
      subject,
      total,
      payerEmail,
      optionSelectedList,
    },
  } = useContext(PreviewInvoiceContext)

  const [open, setOpen] = useState<boolean>(false)
  const [selectedList, setSelectedList] = useState<boolean[]>(
    optionSelectedList ?? []
  )

  useEffect(() => {
    dispatchContext({
      type: previewInvoiceTypes.SET_VALUE,
      payload: { attr: 'optionSelectedList', value: selectedList },
    })
  }, [selectedList, dispatchContext])

  const getInvoiceOptionPayload = () => {
    return optionInfo.reduce(
      (prev, { optionCheck }, index) => ({
        ...prev,
        [optionCheck]: selectedList[index] ?? false,
      }),
      {}
    )
  }

  const fetchInvoicePathFile = (
    optionCheckList: CheckListProps = getInvoiceOptionPayload()
  ) => {
    if (jobId) {
      dispatchContext({
        type: previewInvoiceTypes.SET_VALUE,
        payload: { attr: 'pageLoading', value: true },
      })

      dispatch(
        invoiceActions.getInvoicePreviewPathFile(
          { jobId, ...optionCheckList },
          (_succ: boolean, pathFile: string) => {
            if (pathFile) {
              window.open(pathFile, '_blank')
            }

            dispatchContext({
              type: previewInvoiceTypes.SET_VALUE,
              payload: { attr: 'pageLoading', value: false },
            })
          }
        )
      )
    }
  }

  const openPdfInNewTab = () => {
    if (!invoicePDFPath) {
      fetchInvoicePathFile()
    } else {
      window.open(invoicePDFPath, '_blank')
    }
  }

  const fetchRefreshInvoicePathFile = (
    optionCheckList: CheckListProps = getInvoiceOptionPayload()
  ) => {
    if (jobId) {
      dispatchContext({
        type: previewInvoiceTypes.SET_VALUE,
        payload: { attr: 'pageLoading', value: true },
      })

      dispatch(
        invoiceActions.getInvoicePreviewPathFile(
          { jobId, ...optionCheckList },
          (_succ: boolean, pathFile: string) => {
            dispatchContext({
              type: previewInvoiceTypes.SET_VALUE,
              payload: { attr: 'pageLoading', value: false },
            })
            toast.success.call(
              'success',
              'Successful Refreshed . Invoice has refreshed successfully!'
            )
          }
        )
      )
    }
  }

  const refreshInvoicePDF = () => {
    fetchRefreshInvoicePathFile()
  }

  const handleSendInvoice = () => {
    navigate(`/jobs/${jobId}`)
    if (isValid) {
      const invoiceBody = Object.entries(invoicePreview).reduce(
        (acc: any, [key, val]) => {
          if (val) acc[key] = val
          return acc
        },
        {}
      )

      let recipients = ccEmails
      dispatch(
        invoiceActions.createInvoice(
          {
            request: {
              jobId,
              total,
              ...invoiceBody,
            },
            params: {
              recipients,
              subject,
            },
          },
          (_success) => {
            if (_success) {
              toast.success.call(
                'success',
                'The Invoice was created successfully'
              )
            }
            dispatch(jobActions.fetchJob(jobId || '', (succ) => {}))
          }
        )
      )
    }
  }

  return (
    <Grid
      item
      display="block"
      direction="column"
      xs={7}
      className={styles.PreviewInvoice__right}
    >
      <Grid item flex={1} sx={{ paddingBottom: '12px' }}>
        <Grid item container direction="column">
          <Grid item container direction="row" justifyContent="space-between">
            <Typography variant="h5Bold">Invoice Preview</Typography>
            <Button
              variant="outlined"
              className={styles.PreviewInvoice__optionButton}
              startIcon={<ListAltIcon />}
              onClick={() => setOpen(true)}
            >
              Options
            </Button>
          </Grid>
          <ScrollWrapper relativeStyle={{ minHeight: '60vh' }}>
            {itemsByCategoryNew?.map((category, index) => {
              return (
                <Grid className={styles.PreviewInvoice__Category} key={index}>
                  <Box className={styles.PreviewInvoice__Category__container}>
                    <Typography
                      className={styles.PreviewInvoice__Category__title}
                      variant="body1Bold"
                    >
                      {category?.category?.charAt(0).toUpperCase() +
                        category?.category?.slice(1).toLowerCase()}
                    </Typography>
                    <Typography>${round(category.totalPrice, 2)}</Typography>
                  </Box>
                  {category?.items?.map((item, index) => (
                    <Grid
                      container
                      key={index}
                      borderBottom={
                        index !== category.items.length - 1
                          ? '1px solid #EBECEF'
                          : 'inherit'
                      }
                      padding="1rem 0"
                    >
                      <Grid
                        container
                        item
                        className={styles.PreviewInvoice__Category__items}
                      >
                        <Typography variant="body1Bold" minWidth="1rem">
                          {item?.inspectionReportReference}
                        </Typography>
                        <Typography variant="body1Bold" marginLeft=".5rem">
                          {item?.title}
                        </Typography>
                      </Grid>
                      {item?.inspectionReportNote && (
                        <Grid
                          marginLeft="1.5rem"
                          item
                          container
                          className={styles.PreviewInvoice__Category__items}
                        >
                          <Typography variant="body1Bold">
                            Inspection Notes:
                          </Typography>
                          <Typography marginLeft=".4rem">
                            {item?.inspectionReportNote}
                          </Typography>
                        </Grid>
                      )}
                      {item?.publicRepairNote && (
                        <Grid
                          marginLeft="1.5rem"
                          item
                          container
                          className={styles.PreviewInvoice__Category__items}
                        >
                          <Typography variant="body1Bold">
                            Repair Notes:
                          </Typography>
                          <Typography marginLeft=".4rem">
                            {item?.publicRepairNote}
                          </Typography>
                        </Grid>
                      )}
                      {item?.disclaimer && (
                        <Grid
                          marginLeft="1.5rem"
                          item
                          container
                          className={styles.PreviewInvoice__Category__items}
                        >
                          <Typography variant="body1Bold">
                            Disclaimer:
                          </Typography>
                          <Typography marginLeft=".4rem">
                            {item?.disclaimer}
                          </Typography>
                        </Grid>
                      )}
                      {item?.fieldNote && (
                        <Grid
                          marginLeft="1.5rem"
                          item
                          container
                          className={styles.PreviewInvoice__Category__items}
                        >
                          <Typography variant="body1Bold">
                            Field Tech Note:
                          </Typography>
                          <Typography marginLeft=".4rem">
                            {item?.fieldNote}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  ))}
                </Grid>
              )
            })}
          </ScrollWrapper>
        </Grid>
      </Grid>
      <Grid item flex={1} maxWidth="50vw" sx={{ paddingBottom: '12px' }}>
        <Grid
          item
          container
          justifyContent="flex-end"
          paddingRight="20px"
          spacing={1}
          borderTop="2px solid #EBECEF"
          marginTop={1}
        >
          <Grid item container justifyContent="flex-end">
            <Typography variant="body1Bold">Subtotal:</Typography>
            <Typography marginLeft=".4rem">
              ${round(invoicePreview?.subtotal || 0, 2)}
            </Typography>
          </Grid>
          {invoicePreview?.discount ? (
            <Grid item container justifyContent="flex-end">
              <Typography variant="body1Bold">Discount:</Typography>
              <Typography marginLeft=".4rem">
                ${round(invoicePreview?.discount || 0, 2)}
              </Typography>
            </Grid>
          ) : (
            <></>
          )}
          <Grid item container justifyContent="flex-end">
            <Typography variant="body1Bold">Tax:</Typography>
            <Typography marginLeft=".4rem">
              ${round(invoicePreview?.tax || 0, 2)}
            </Typography>
          </Grid>
          <Grid item container justifyContent="flex-end">
            <Typography variant="body1Bold">Total:</Typography>
            <Typography marginLeft=".4rem">
              ${round(invoicePreview?.total || 0, 2)}
            </Typography>
          </Grid>
          <Grid item container justifyContent="flex-end">
            <Typography variant="body1Bold">Paid:</Typography>
            <Typography marginLeft=".4rem">
              ${round(invoicePreview?.amountPaid || 0, 2)}
            </Typography>
          </Grid>
          <Grid item container justifyContent="flex-end">
            <Typography variant="body1Bold">Outstanding Balance:</Typography>
            <Typography marginLeft=".4rem">
              ${round(invoicePreview?.outstandingBalance || 0, 2)}
            </Typography>
          </Grid>
          <Grid item container justifyContent="center" marginTop={2}>
            <Typography width="90%" textAlign="justify">
              As part of the repair process with BossCat, this property will
              carry a 1 year workmanship guarantee for all repairs addressed by
              BossCat, unless noted otherwise on invoice. This guarantee is
              transferable based upon the property, rather than ownership. This
              invoice serves as a transference of guarantee through closing.
            </Typography>
          </Grid>
          <Grid
            item
            container
            wrap="nowrap"
            justifyContent="space-between"
            marginTop={2}
          >
            <Button
              onClick={refreshInvoicePDF}
              variant="containedLight"
              endIcon={
                <icons.Loop
                  style={{
                    width: '12px',
                    height: '12px',
                    padding: '0px !important',
                  }}
                />
              }
            >
              Refresh PDF
            </Button>

            <Button
              onClick={openPdfInNewTab}
              variant="containedLight"
              endIcon={
                <icons.Download
                  style={{
                    width: '12px',
                    height: '12px',
                    padding: '0px !important',
                  }}
                />
              }
            >
              Download PDF
            </Button>

            <Grid container item justifyContent="flex-end" gap="1rem">
              <Button
                variant="containedLight"
                onClick={() => navigate(`/jobs/${jobId}`)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                endIcon={<Icon name="Email" />}
                disabled={!isValid || invoicePreview?.subtotal <= 0}
                onClick={handleSendInvoice}
              >
                Send Invoice
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {open && (
        <InvoiceOptionModal
          open={open}
          setOpen={setOpen}
          refreshInvoicePDF={fetchRefreshInvoicePathFile}
          selectedList={selectedList}
          setSelectedList={setSelectedList}
        />
      )}
    </Grid>
  )
}

export default InvoicePrice
