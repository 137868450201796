import { Theme } from '../../..'
import { makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => ({
  text: {
    display: 'flex',
    gap: theme.spacing(1),
    justifyContent: 'center',
    alignItems: 'baseline',
    color: theme.palette.primary.dark,
  },
  title: {
    fontFamily: 'MorangaRg',
    fontWeight: 'normal',
    // lineHeight: '58px',
    fontSize: '30px',
    [theme.breakpoints.down('md')]: {
      fontSize: '22px',
    },
  },
  titleBlack: {
    fontFamily: 'NouvelleBold',
    fontSize: '32px',
    [theme.breakpoints.down('md')]: {
      fontSize: '24px',
    },
  },
}))
