import storeInfo from "../store";
import { Insurance } from "../types";
import { TableInsurance } from "./types";

export const getInsurances = () => (): TableInsurance[] => {
    const { insurances: { items } } = storeInfo.store.getState()
    if (!items) return []
    return items
}

export const getInsurancesTotalCount = () => (): number => {
    const { insurances: { total } } = storeInfo.store.getState()
    return total || 0
}

export const getCsvInsurances = () => (): TableInsurance[] => {
    const { insurances: { csvItems } } = storeInfo.store.getState()

    if (!csvItems) return []

    return csvItems
}
