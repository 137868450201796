import { SUBSCRIPTION_STATUS } from "src/helpers";

export const subscriptionStatus = [
  {
    id: 'pending',
    label: SUBSCRIPTION_STATUS.PENDING,
    hide: true
  },
  {
    id: 'active',
    label: SUBSCRIPTION_STATUS.ACTIVE,
    hide: true
  },
  {
    id: 'canceled',
    label: SUBSCRIPTION_STATUS.CANCELED,
    hide: true
  },
  {
    id: 'expired',
    label: SUBSCRIPTION_STATUS.EXPIRED,
    hide: true
  },
  {
    id: 'incomplete',
    label: SUBSCRIPTION_STATUS.INCOMPLETE,
    hide: true
  },
]

export const giftedStatus = [
  {
    id: 'none',
    label: 'None',
    param: 'NOT_AVAILABLE',
    hide: true
  },
  {
    id: 'not_sent',
    label: 'Not Sent',
    param: 'NOT_SENT',
    hide: true
  },
  {
    id: 'sent',
    label: 'Sent',
    param: 'SENT',
    hide: true
  },
]