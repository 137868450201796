/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Typography } from '@mui/material'
import { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PrivateLoader } from 'src/components/templates'
import useStyles from './styles'
import Banner from './Banner'
import {
  formatDate,
  formatDateTimestamp,
  formatTimestamp,
  formatUTCTimestampDate,
  isEmpty,
  round,
} from 'src/helpers'
import Modal from 'src/components/UI/CustomUI/molecules/Modal'
import { TextFieldLabel } from 'src/components/UI'
import EstimateDiscountModal from './Modals/EstimateDiscount'
import { discountsActions } from 'src/ducks/actions'
import { getDiscountsValue } from 'src/ducks/selectors'
import { Button } from '../JobsView/JobsTopInfo/Templates'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import { icons } from 'src/assets'

enum MODAL_TYPES {
  ESTIMATE_DISCOUNT = 'Estimate Discount Banner',
}

interface ModalProp {
  open: boolean
  type: MODAL_TYPES | null
}

const Banners: FC = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [loading, setLoading] = useState(true)
  const [modal, setModal] = useState<ModalProp>({
    open: false,
    type: null,
  })
  const [isEdit, setIsEdit] = useState(false)
  const discountBanner = useSelector(getDiscountsValue('discountBanner'))

  const fetchEstimateDiscount = () => {
    dispatch(
      discountsActions.fetchDiscountBanner((succ: boolean) => {
        setLoading(false)
      })
    )
  }

  useEffect(() => {
    fetchEstimateDiscount()
  }, [])

  return (
    <PrivateLoader loading={loading}>
      <Box className={classes.root}>
        <Typography className={classes.title}>Banners</Typography>
        <Box className={classes.bannersContainer}>
          {!isEmpty(discountBanner) && (
            <Banner
              onClick={() => {
                setIsEdit(true)
                setModal({
                  open: true,
                  type: MODAL_TYPES.ESTIMATE_DISCOUNT,
                })
              }}
              title="Estimate Discount Banner"
              subtitle={
                <Box className={classes.bannerSubtitleContainer}>
                  <Grid container justifyContent="space-between">
                    <Typography className={`${classes.bold} ${classes.blue}`}>
                      Discount: {discountBanner?.discount}%
                    </Typography>
                    <Typography className={`${classes.discountCode}`}>
                      {discountBanner?.discountCode}
                    </Typography>
                  </Grid>
                  <Typography>
                    Min Amount: ${round(discountBanner?.minAmount, 2)}
                  </Typography>
                  <Typography>
                    Start Date:
                    {formatTimestamp(discountBanner?.startTime, 'M/D/YYYY')}
                  </Typography>
                  <Typography>
                    End Date:
                    {formatTimestamp(discountBanner?.endTime, 'M/D/YYYY')}
                  </Typography>
                </Box>
              }
            />
          )}
          {isEmpty(discountBanner) && (
            <Banner
              onClick={() => {
                setModal({
                  open: true,
                  type: MODAL_TYPES.ESTIMATE_DISCOUNT,
                })
              }}
              className={classes.addBanner}
              subtitle={
                <Box style={{ display: 'flex', justifyContent: 'center' }}>
                  <icons.Add style={{ fontSize: '2rem' }} />
                </Box>
              }
            />
          )}
        </Box>
      </Box>
      <Modal
        open={modal.open}
        onClose={() => setModal({ open: false, type: null })}
        setOpen={() => setModal({ open: false, type: null })}
        title={modal.type ? modal.type : undefined}
        className={classes.modal}
        size="md"
      >
        <EstimateDiscountModal
          onClose={() => setModal({ open: false, type: null })}
          isEdit={isEdit}
        />
      </Modal>
    </PrivateLoader>
  )
}

export default Banners
