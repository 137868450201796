import { depositInvoiceTypes, DepositInvoiceValue, DepositInvoiceContextType } from "./types";

export const PreviewInvoiceReducer = (state: DepositInvoiceContextType, action: any) => {
  const { type, payload } = action

  switch (type) {
    case depositInvoiceTypes.SET_VALUE: {
      const { attr, value } = payload as DepositInvoiceValue
      return {
        ...state as unknown as DepositInvoiceContextType,
        [attr]: value
      }
    }
    default:
      return state;
  }
}
