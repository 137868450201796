import { MaterialItem } from '../types'
import { materialsTypes, MaterialsActions } from './types'

const actions = {
  fetchMaterials: (
    payload: string[],
    callback?: (succ: boolean, item?: MaterialItem) => void
  ): MaterialsActions => ({
    type: materialsTypes.FETCH_MATERIALS,
    payload,
    callback,
  }),

  setMaterials: (payload: MaterialItem[]): MaterialsActions => ({
    type: materialsTypes.SET_MATERIALS,
    payload,
  }),
}

export default actions
