import { useState, useEffect, FC } from 'react'
import { useDispatch } from 'react-redux'
import { Typography, BottomLink, Button, Box, TextFieldLabel, PasswordLabel, TitleFirst, Avatar } from '../../../UI'
import useStyles from './styles'
import { validateEmail, isEmpty, AUTH0_CODES } from '../../../../helpers'
import { authActions } from '../../../../ducks/actions'
import { PunchlistLoader } from '../../../templates'
import { Auth0Error } from '../../../../ducks/auth/types'
import { arrow } from '../../../../assets'

const Register: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const [emailTaken, setEmailTaken] = useState('')
  const [emailWarning, setEmailWarning] = useState('')
  const [passwordWarning, setPasswordWarning] = useState('')
  const [errorCode, setErrorCode] = useState('')
  const [passwordPolicy, setPasswordPolicy] = useState('')

  const [loading, setLoading] = useState(false)
  const [submit, setSubmit] = useState(false)

  const validate =
    !isEmpty(email) &&
    validateEmail(email) &&
    !isEmpty(password)

  useEffect(() => {
    if (submit) {
      if (isEmpty(email) || !validateEmail(email)) {
        setEmailWarning('Enter your email to create an account')
      } else if (errorCode === AUTH0_CODES.INVALID_SIGNUP && emailTaken === email) {
        setEmailWarning('An account already exists for this email address.')
      } else {
        setEmailWarning('')
      }
    }
  }, [submit, email, emailTaken, errorCode])

  useEffect(() => {
    if (submit) {
      if (isEmpty(password)) {
        setPasswordWarning('Enter a password to create an account')
      } else if (errorCode === AUTH0_CODES.INVALID_PASSWORD) {
        setPasswordWarning('Password is too weak, please choose a stronger password')
      } else {
        setPasswordWarning('')
      }
    }
  }, [submit, password, errorCode, passwordPolicy])

  const noHandledErrorCode = (): boolean => {
    return !isEmpty(errorCode) &&
      errorCode !== AUTH0_CODES.INVALID_SIGNUP &&
      errorCode !== AUTH0_CODES.INVALID_PASSWORD
  }

  const handleSubmit = (event: React.MouseEvent<EventTarget>): void => {
    event.preventDefault()
    setSubmit(true)
    if (validate) {
      setLoading(true)
      dispatch(authActions.signupRequest({
        email,
        password,
        callback: (success: boolean, error?: Auth0Error) => {
          if (success) {
            dispatch(authActions.loginRequest({
              email,
              password,
              remember: true,
              callback: success => {
                setLoading(false)
              }
            }))
          } else {
            setErrorCode(error?.code ?? '')
            setEmailTaken(email)
            setPasswordPolicy(error?.policy ?? '')
            setLoading(false)
          }
        }
      }))
    }
  }

  return (
    <PunchlistLoader loading={loading}>
      <TitleFirst value='Welcome' black='to BOSSCAT!' />
      <Typography variant='h6' className={classes.text}>
        Let’s sign up!
      </Typography>
      <Box className={classes.center}>
        <form className={classes.form} autoComplete='on'>
          <TextFieldLabel
            label='Email:'
            type='email'
            value={email}
            error={submit && !isEmpty(emailWarning)}
            helperText={submit && emailWarning}
            onChange={(event) => setEmail(event.target.value)}
            placeholder='Enter your email here'
            autoComplete='off'
            sx={{ marginBottom: '34px', height: { sm: '54px' } }}
            className={classes.email}
          />
          <PasswordLabel
            label='Password:'
            value={password}
            error={submit && !isEmpty(passwordWarning)}
            helperText={submit && passwordWarning}
            onChange={(event) => setPassword(event.target.value)}
            placeholder='Create a secure password'
            className={classes.pass}
          />
          {!isEmpty(passwordPolicy) && (
            <Typography
              variant='body2'
              color='error'
              style={{ whiteSpace: 'pre-wrap', padding: '0 16px' }}
            >
              {passwordPolicy}
            </Typography>
          )}
          {submit && noHandledErrorCode() &&
            <Typography
              variant='body2'
              color='error'
              style={{ padding: '0 16px' }}
            >
              An error occurred while trying to sign up, please try again.
            </Typography>}
          <Button
            type='submit'
            variant='contained'
            color='primary'
            size='large'
            className={classes.submit}
            onClick={handleSubmit}
            sx={{ marginBottom: '36px', height: '56px' }}
            endIcon={
              <Avatar
                alt=''
                src={arrow}
                sx={{ height: '0.75rem', width: '0.75rem' }}
              />
            }
            fullWidth
          >
            Sign up
          </Button>
          <BottomLink
            question='Already have an account?'
            linkText='Sign in here'
            linkTo='/login'
          />
        </form>
      </Box>
    </PunchlistLoader>
  )
}

export default Register
