import { authTypes } from '../auth/types'
import { HistoryActions, HistoryType, historyTypes, HistoryValue } from './types'

const initialState: HistoryType = {
  historyJobTasks: [],
  jobHistory: [],
  jobHistoryStatus: [],
  orderHistory: [],
  historyRemovedItems: []
}

const reducer = (state = initialState, action: HistoryActions): HistoryType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case historyTypes.SET_HISTORY_VALUE: {
      const { attr, value } = payload as HistoryValue
      return {
        ...state as unknown as HistoryType,
        [attr]: value
      }
    }

    default:
      return state
  }
}

export default reducer
