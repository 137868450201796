/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import { toast } from 'react-toastify'
import type { SagaIterator } from 'redux-saga'
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import { Punchlist } from '../../api'
import { EMPLOYEE_ROLE_TYPE, errorTextTryingTo, isEmpty } from '../../helpers'
import { territoryActions } from '../actions'
import { DeleteTerritoryAction, FetchTerritoryManagerAction, FetchTerritoryRepairItems, GetTerritoryAction, UpdateTerritoryAction, territoryTypes } from './types'
import { UpdateRequest } from '~api/types'
import { Territory } from '../types'


export function* fetchTerritoryManagers({ payload: role, callback }: FetchTerritoryManagerAction): SagaIterator {
  let success = false
  try {
    // deprecated enpoint
    // const data = yield call(Punchlist.users.getUsersStaff, role)

    const searchParams = { role, approved: true }
    const data = yield call(Punchlist.employees.getEmployees, { searchParams, limit: 150 })

    if (!isEmpty(data)) {
      if (role === EMPLOYEE_ROLE_TYPE.TERRITORY_MANAGER) {
        yield put(territoryActions.setTerritoryManagers(data.items))
      }
      if (role === EMPLOYEE_ROLE_TYPE.TERRITORY_SUCCESS_MANAGER) {
        yield put(territoryActions.setTerritorySuccessManagers(data.items))
      }
      success = true
    }
    yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the territory managers'))
    yield call(callback, false)
  }
}

export function* fetchTerritoryRepairItems({ payload, callback }: FetchTerritoryRepairItems): SagaIterator {
  let success = false
  try {
    const territoryRepairItems = yield call(Punchlist.territories.getTerritoryRepairItems, payload)
    if (territoryRepairItems) {
      yield put(territoryActions.setchTerritoryRepairItems(territoryRepairItems))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the territory repair items'))
    if (callback) yield call(callback, false)
  }
}

export function* getTerritory({ payload, callback }: GetTerritoryAction): SagaIterator {
  let success = false
  try {
    const territory = yield call(Punchlist.territories.getTerritoryById, payload)
    if (territory) {
      yield put(territoryActions.setTerritory(territory))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the territory repair items'))
    if (callback) yield call(callback, false)
  }
}

export function* deleteTerritory({ payload, callback }: DeleteTerritoryAction): SagaIterator {
  try {
    yield call(Punchlist.territories.deleteTerritory, payload)
    if (callback) yield call(callback, true)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('delete Territory'))
    if (callback) yield call(callback, false)
  }
}

export function* updateTerritory({ payload, callback }: UpdateTerritoryAction): SagaIterator {
  const {
    id,
    territory
  } = payload

  const patchRequest: UpdateRequest = []
  
  Object.entries(territory).forEach(([key, value]) => {
    patchRequest.push({
      op: 'replace',
      path: `/${key}`,
      value: value as keyof Territory
    });
  });

  try {
    const updatedTerritory = yield call(Punchlist.territories.updateTerritory, id, patchRequest)
    if (updatedTerritory) {
      yield put(territoryActions.setTerritory(updatedTerritory))
    }
    if (callback) yield call(callback, true)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('update Territory'))
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeEvery(territoryTypes.FETCH_TERRITORY_MANAGERS, fetchTerritoryManagers)
  yield takeLatest(territoryTypes.FETCH_TERRITORY_REPAIR_ITEMS, fetchTerritoryRepairItems)
  yield takeLatest(territoryTypes.GET_TERRITORY, getTerritory)
  yield takeLatest(territoryTypes.DELETE_TERRITORY, deleteTerritory)
  yield takeLatest(territoryTypes.UPDATE_TERRITORY, updateTerritory)
}
