import { Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { HeaderType } from 'src/components/UI/CustomUI/organisms/Table/types'
import { getEstimate, getJob } from 'src/ducks/selectors'
import { formatTimestampTimeZone, getColorByOrderItemStatus } from 'src/helpers'

const headers: HeaderType[] = [
    {
        id: 'sentOn',
        label: 'Dispatched',
        custom: true,
        Element: (row: any, dispatch): JSX.Element => {
            const estimate = useSelector(getEstimate())
            const timeZone = estimate?.properties?.address?.timeZone ? estimate.properties.address.timeZone : ""

            return (
                <Typography variant="body1">{formatTimestampTimeZone(row.createdOn, 'MM/DD/YYYY HH:mm', timeZone)}</Typography>
            )
        }
    },
    {
        id: 'sendByName',
        label: 'By'
    },
    {
        id: 'vendorName',
        label: 'Vendor'
    },
    {
        id: 'status',
        label: 'Response',
        custom: true,
        Element: (row: any): JSX.Element => {
            return <Typography variant='body1Bold' textTransform='uppercase' color={getColorByOrderItemStatus(row.status).color}>{row.status} </Typography>
        }
    },
    {
        id: 'responseTime',
        label: 'Time to Respond'
    },
    {
        id: 'rejectType',
        label: 'Reject Reason'
    },
    {
        id: 'vendorComment',
        label: 'Notes'
    }
]

export default headers
