import { FC } from 'react'
import { Box, Typography, BoxContainer, CircularProgress } from '../../..'
import { StatusBoxProps } from './types'
import useStyles from './styles'
import { useIsXlScreen } from 'src/hooks'

const StatusBox: FC<StatusBoxProps> = ({
  borderColor,
  statName,
  statValue,
  minWidth = '100%',
  sx = { textAlign: 'center' },
  firstColumn,
  sndColumn,
  thirdColumn,
  loading
}) => {
  const classes = useStyles()
  const [xlScreen] = useIsXlScreen()

  return (
    <BoxContainer
      hideTitle
      borderColor={borderColor}
      sx={[...(Array.isArray(sx) ? sx : [sx]), { minWidth: minWidth, minHeight: '79px', padding: '0px !important' }]}
      className={classes.container}
    >
      <Box display='flex'>
        <Box sx={[
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}>
          {!loading ?
            <Typography
              fontFamily='NouvelleBold'
              fontSize='24px'
              lineHeight='28.8px'
              sx={xlScreen ? { padding: '8px' } : { padding: '4px' }}
            >
              {statValue}
            </Typography> : <CircularProgress className={classes.loading} size={24} />
          }

          <Typography
            variant={xlScreen ? 'h4' : 'h5'}
            sx={xlScreen ? { padding: '12px 8px 8px 8px' } : { padding: '4px' }}
          >
            {statName}
          </Typography>
        </Box>
        <Box sx={{ paddingTop: '8px' }}>
          {!loading && firstColumn && firstColumn.map((item, indx) => {
            const colorInd = indx === 0 ? 'var(--navy-700)' : 'var(--gray500)'
            return (
              <Typography variant='body1' color={colorInd} sx={xlScreen ? { padding: '0px 12px' } : { padding: '0px 8px' }} key={Math.floor(Math.random() * 10000)}>
                {item}
              </Typography>
            )
          })}
        </Box>
        <Box sx={{ paddingTop: '8px' }}>
          {!loading && sndColumn && sndColumn.map((item, indx) => {
            const colorInd = indx === 0 ? 'var(--navy-700)' : 'var(--gray500)'
            return (
              <Typography variant='body1' color={colorInd} sx={xlScreen ? { padding: '0px 12px' } : { padding: '0px 8px' }} key={Math.floor(Math.random() * 10000)}>
                {item}
              </Typography>
            )
          })}
        </Box>
        <Box sx={{ paddingTop: '8px' }}>
          {!loading && thirdColumn && thirdColumn.map((item, indx) => {
            const colorInd = indx === 0 ? 'var(--navy-700)' : 'var(--gray500)'
            return (
              <Typography variant='body1' color={colorInd} sx={xlScreen ? { padding: '0px 12px' } : { padding: '0px 8px' }} key={Math.floor(Math.random() * 10000)}>
                {item}
              </Typography>
            )
          })}
        </Box>
      </Box>
    </BoxContainer >
  )
}

export default StatusBox
