export const formControlLabel = {
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        marginLeft: 0,
        marginRight: 2,
        /* '&:before': {
          display: 'none'
        },
        '&.Mui-expanded': {
          margin: 0
        } */
      },
    }
  },
}