import { useSelector } from 'react-redux'
import { LabeledText } from 'src/components/UI'
import { ContentBox, Divider, TabBox } from '../../Templates'
import { getEstimate } from 'src/ducks/estimate/selectors'
import { round } from 'src/helpers'

const Items = () => {
  const estimate = useSelector(getEstimate())

  const itemCount = estimate?.itemCount || 0
  const totalHours = estimate?.totalLaborHours || 0
  const unadjustedTotal = estimate?.unadjustedTotal || 0
  const totalCost = estimate?.totalCost || 0
  const totalWithDiscountApplied = estimate?.totalWithDiscountApplied || 0
  const adjustment = estimate?.discountAmount || 0
  const profit = estimate?.profit || 0
  const margin = estimate?.margin || 0

  return (
    <TabBox>
      <ContentBox gridTemplateColumns="1fr 1fr">
        <LabeledText
          labelValue="Total Items (Qty):"
          textValue={`${itemCount}`}
        />
        <LabeledText
          labelValue="Item Hours:"
          textValue={`${round(totalHours, 2)}`}
        />
      </ContentBox>
      <Divider />
      <ContentBox gridTemplateColumns="1fr 1fr">
        <LabeledText
          labelValue="Unadjusted Total:"
          textValue={unadjustedTotal ? `$${round(unadjustedTotal, 2)}` : '-'}
        />
        <LabeledText
          labelValue="Total Cost:"
          textValue={totalWithDiscountApplied ? `$${round(totalCost, 2)}` : '-'}
        />
        <LabeledText
          labelValue="Total With Discount Applied:"
          textValue={
            totalWithDiscountApplied
              ? `$${round(totalWithDiscountApplied, 2)}`
              : '-'
          }
        />
        <LabeledText
          labelValue="Adjustment:"
          textValue={adjustment ? `$${round(adjustment, 2)}` : '-'}
        />
        <LabeledText
          labelValue="Profit:"
          textValue={profit ? `$${round(profit, 2)}` : '-'}
        />
        <LabeledText
          labelValue="Margin:"
          textValue={margin ? `${round(margin, 2)}%` : '-'}
        />
      </ContentBox>
    </TabBox>
  )
}

export default Items
