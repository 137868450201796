import { FC, useContext } from 'react'
import { Typography } from 'src/components/UI'
import Modal from 'src/components/UI/CustomUI/molecules/Modal'
import { WidgetsContext } from '../context'
import { dispatchTypes, MODAL_TYPE } from '../context/types'
import AddWidget from './AddWidget'
import ConfirmDeleteCompany from './ConfirmDeleteCompany'
import EditWidget from './EditWidget'
const WidgetsModal = () => {
    const { state: { modalType, modalOpen }, dispatch: dispatchContext } = useContext(WidgetsContext)
    const handleClose = () => {
        dispatchContext({ type: dispatchTypes.CLOSE_MODAL, payload: null })
    }

    const getTitle = () => {
        switch (modalType) {
            case MODAL_TYPE.CREATE:
                return "Add New Partner"
            case MODAL_TYPE.EDIT:
                return "Edit Partner"
            case MODAL_TYPE.DELETE:
                return "You are going to delete the Partner:"
            default:
                return 'Partner'
        }
    }

    const getContent = () => {
        switch (modalType) {
            case MODAL_TYPE.CREATE:
                return <AddWidget />
            case MODAL_TYPE.EDIT:
                return <EditWidget />
            case MODAL_TYPE.DELETE:
                return <ConfirmDeleteCompany />
            default:
                return 'Company'
        }
    }

    const getSize = () => {
        switch (modalType) {
            case MODAL_TYPE.CREATE:
                return 'md'
            case MODAL_TYPE.EDIT:
                return 'md'
            case MODAL_TYPE.DELETE:
                return 'sm'
            default:
                return 'md'
        }
    }

    return (
        <Modal
            open={modalOpen}
            setOpen={() => { }}
            onClose={handleClose}
            showClose={true}
            size={getSize()}
            title={getTitle()}
        >
            {getContent()}
        </Modal>
    )
}

export default WidgetsModal