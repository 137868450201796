import { Range } from "src/components/UI/CustomUI/atoms/DatePicker/types"
import { FORM_TYPE, RESOURCE_TYPE } from "src/helpers"

/** TYPES **/
export const searchesTypes = {
  FETCH_SEARCHES: 'FETCH_SEARCHES',
  SET_VALUES: 'SET_SEARCHES_VALUES',
  CREATE_FOLDER: 'CREATE_FOLDER',
  SET_SEARCH_PARAM_VALUE: 'SET_SEARCH_PARAM_VALUE',
  SET_SEARCH_PARAM_VALUES: 'SET_SEARCH_PARAM_VALUES',
  CREATE_SEARCH: 'CREATE_SEARCH',
  CLEAN_PARAMS: 'CLEAN_PARAMS',
  REMOVE_FOLDER: 'REMOVE_FOLDER',
  REMOVE_SEARCH: 'REMOVE_SEARCH',
  SET_SEARCH_SELECTED: 'SET_SEARCH_SELECTED',
}
export interface FolderSearch {
  name: string
  searches: Search[],
  id: number,
  toDelete: boolean
}

export interface Search {
  name: string,
  id: number,
  properties: SearchParams
  toDelete: boolean
}

export interface Searches {
  id: string | number,
  userId: string,
  type: RESOURCE_TYPE | null,
  folders: FolderSearch[]
  searchParams: SearchParams
}

export interface SearchParams {
  address?: string
  city?: string
  state?: string
  territoryManager?: string
  customerSuccess?: string
  territory?: string
  search?: string
  vendor?: string
  client?: string
  hdNumber?: string
  status?: string
  statusComp?: string
  statusSearch?: string
  task?: string[]
  deadlineStartDate?: Date | null | string
  deadlineEndDate?: Date | null | string
  plEtaRange?: Range | null | string
  completionEndDate?: Date | null | string
  completionStartDate?: Date | null | string
  expectedCompletedOnStartDate?: Date | null | string
  expectedCompletedOnEndDate?: Date | null | string
  approved?: boolean
  affiliate?: string | string[]
  affiliateType?: string
  startDate?: number | string
  endDate?: number | string
  includeArchived?: boolean
  approvalStartDate?: string
  approvalEndDate?: string
  successManager?: string | string[]
  trades?: string
  workStartDate?: string
  workEndDate?: string
  zipCode?: string
  institutional?: boolean | null
  estimateFormType?: FORM_TYPE[]
  onHold?: boolean
  estimatorId?: string
  currentlyInUse?: boolean | string
  hasEstimatorId?: boolean | string
  dueDate?: string
  isChild?: boolean | null
  publicId?: string
  category?: string | null
  sortBy?: string
  sortOrder?: string
  statuses?: string[]

  createdStartDate?: string
  createdEndDate?: string
}


export interface SearchesValues {
  attrs: Partial<Searches>
}
export interface SearchParamValue {
  attr: keyof SearchParams | string
  value: any
}


/** ACTIONS **/
export interface FetchSearchesAction {
  type: typeof searchesTypes.FETCH_SEARCHES
  payload: RESOURCE_TYPE
  callback?: (succ: boolean) => void
}


export interface SearchesSetValuesAction {
  type: typeof searchesTypes.SET_VALUES
  payload: SearchesValues
}

export interface CreateFolderAction {
  type: typeof searchesTypes.CREATE_FOLDER
  payload: Partial<FolderSearch>
  resourceType: RESOURCE_TYPE
  callback: (succ: boolean) => void
}

export interface RemoveFolderAction {
  type: typeof searchesTypes.REMOVE_FOLDER
  payload: {
    folderIndex: number[],
  }
  callback: (succ: boolean) => void
}

export interface RemoveSearchAction {
  type: typeof searchesTypes.REMOVE_SEARCH
  payload: {
    folders: FolderSearch[]
  }
  resourceType: RESOURCE_TYPE,
  callback: (succ: boolean) => void
}


export interface CreateSearchAction {
  type: typeof searchesTypes.CREATE_FOLDER
  payload: FolderOptions
  resourceType: RESOURCE_TYPE
  searchInfo: any
  callback: (succ: boolean) => void
}

export interface FolderOptions {
  folderSelected: string | number
  searchName: string
}

export interface SetSearchParamValueAction {
  type: typeof searchesTypes.SET_SEARCH_PARAM_VALUE
  payload: SearchParamValue
}

export interface SetSearchParamValuesAction {
  type: typeof searchesTypes.SET_SEARCH_PARAM_VALUES
  payload: SearchParams
}

export interface CleanSearchParams {
  type: typeof searchesTypes.CLEAN_PARAMS
  payload: null
}

export interface RemoveFolderAction {
  type: typeof searchesTypes.REMOVE_FOLDER
  payload: { folderIndex: number[] }
  resourceType: RESOURCE_TYPE
  callback: (succ: boolean) => void
}

export type SearchesActions =
  | SearchesSetValuesAction
  | CreateFolderAction
  | SetSearchParamValueAction
  | SetSearchParamValuesAction
  | CleanSearchParams
  | RemoveFolderAction
  | RemoveSearchAction
  | CreateSearchAction


export type SearchesType = Searches | null