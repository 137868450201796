import { invoiceMaterialModalTypes, invoiceMaterialModalContextType } from './types'

const initialState: invoiceMaterialModalContextType = {
  invoiceNum: "",
  amount: 0,
  orderName: "",
  insertedOn: 0
}

export const invoiceMaterialModalReducer = (state: invoiceMaterialModalContextType, action: any) => {
  const { type, payload } = action

  switch (type) {
    case invoiceMaterialModalTypes.MATERIAL_INVOICE_DELETE: {
      state = payload.value
      return {
        ...state,
        // [state.amount = payload.amount ]
      }
    }
    case invoiceMaterialModalTypes.RESET: {

      state = initialState
      return {
        //initialState
        ...state,
        //invoice: initialState
        //initialState
      }
    }
    default:
      return state
  }
}
