/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from "@mui/material"
import { Box } from "@mui/system"
import { useCallback, useContext, useEffect,useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { PrivateLoader } from "src/components/templates"
import BackButton from "src/components/UI/CustomUI/atoms/BackButton"
import { invoiceActions, itemsActions, jobActions, territoryActions } from "src/ducks/actions"
import { getJob } from "src/ducks/selectors"
import { getInvoicePreview } from 'src/ducks/invoice/selectors'

import { ITEM_STATUS } from "src/helpers"
import { usePaddingWrapper } from "src/hooks"
import { DepositInvoiceContext } from "./context"
import { depositInvoiceTypes } from "./context/types"
import InvoicePrice from "./InvoicePrice"
import DepositInfo from "./DepositInfo"

import styles from './styles.module.scss'
import AddPayerContact from './AddPayerContact'

const DepositInvoice = () => {
  const { setHasPadding } = usePaddingWrapper()
  const { dispatch: dispatchContext, state: { pageLoading } } = useContext(DepositInvoiceContext)

  const { jobId } = useParams()
  const dispatch = useDispatch()
  const job = useSelector(getJob())
  const invoicePreview = useSelector(getInvoicePreview())
  const [reload, setReload] = useState(false)
  const contactPayer = job?.properties?.contacts?.filter((contact) => (contact as any).isPayer)
  const [outbalance, setOutbalance] = useState(0)
  const payer = invoicePreview && invoicePreview?.preparedFor?.payer ? true 
  : contactPayer? contactPayer.length > 0 ? true : false : false 


  const fetchJob = useCallback(() => {
    if (jobId) {
      dispatchContext({ type: depositInvoiceTypes.SET_VALUE, payload: { attr: 'pageLoading', value: true } })

      dispatch(jobActions.fetchJob(jobId,
        (_succ: boolean) => {
          dispatchContext({ type: depositInvoiceTypes.SET_VALUE, payload: { attr: 'pageLoading', value: false } })
        }))
    }
  }, [])

  const fetchTerritory = useCallback(() => {
    if (job) {
      dispatchContext({ type: depositInvoiceTypes.SET_VALUE, payload: { attr: 'pageLoading', value: true } })

      dispatch(territoryActions.getTerritory(job?.properties?.territory.id,
        (_succ: boolean) => {
          dispatchContext({ type: depositInvoiceTypes.SET_VALUE, payload: { attr: 'pageLoading', value: false } })
        }))
    }
  }, [])

  const fetchItems = () => {
    dispatch(itemsActions.fetchItemsByCategory({
      params: { jobId, status: ITEM_STATUS.ACCEPTED }
    }, (_succ) => {

    }))
  }


  const fetchInvoicePreview = useCallback(() => {
    if (jobId) {
      dispatchContext({ type: depositInvoiceTypes.SET_VALUE, payload: { attr: 'pageLoading', value: true } })

      dispatch(invoiceActions.getInvoicePreview({ params: { jobId: jobId }},
        (_succ: boolean,_ammountTotal:number,tax:number,paid:number,outstandingBalance:number,subtotal:number) => {
         
          dispatchContext({ type: 'SET_VALUE', payload: { attr: 'depositTotal', value: _ammountTotal } })
          dispatchContext({ type: 'SET_VALUE', payload: { attr: 'depositSubTotal', value: subtotal } })
          dispatchContext({ type: 'SET_VALUE', payload: { attr: 'tax', value: tax } })
          dispatchContext({ type: 'SET_VALUE', payload: { attr: 'paid', value: paid } })
          dispatchContext({ type: 'SET_VALUE', payload: { attr: 'depositOutstandingBalance', value: outstandingBalance } })

          dispatchContext({ type: depositInvoiceTypes.SET_VALUE, payload: { attr: 'pageLoading', value: false } })
        }))
    }
  }, [])



 const onReloadHandle = () => {
  setReload(true)
}

useEffect(() => {
  if(reload){
    fetchJob()
    fetchInvoicePreview()
    setHasPadding(false)
  }
}, [reload])


  useEffect(() => {
    fetchJob()
    fetchItems()
    fetchTerritory()
    fetchInvoicePreview()
    setHasPadding(false)
  }, [])

  return (
    <PrivateLoader loading={pageLoading}>
      <Box>
        <Grid container className={styles.PreviewInvoice__container}>
          <Grid item container direction="column" spacing={3} xs={5} className={styles.PreviewInvoice__left}>
            <Grid item marginBottom={2}>
              <BackButton to={`/jobs/${jobId}`} />
            </Grid>
            {(payer)? <DepositInfo /> : <AddPayerContact onReload={onReloadHandle} /> }
            
          </Grid>
          <InvoicePrice />
        </Grid>
      </Box>
    </PrivateLoader >

  )
}

export default DepositInvoice