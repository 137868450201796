/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo } from '../../helpers'
import { Punchlist } from '../../api'
import { materialsActions } from '../actions'
import {
  materialsTypes,
  FetchMaterials,
} from './types'

export function* fetchMaterials({
  payload,
  callback,
}: FetchMaterials): SagaIterator {
  let success = false
  try {
    const ids = payload

    const items = yield call(Punchlist.materials.getMaterials, { ids })

    if (items) {
      yield put(materialsActions.setMaterials(items))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the materials'))
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(materialsTypes.FETCH_MATERIALS, fetchMaterials)
}
