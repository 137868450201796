import { Box, Button, Grid, Typography } from "@mui/material"
import { FC } from "react"
import { icons } from 'src/assets'
import { SUB_OPTIONS } from "../.."
import useStyles from './styles'
import MultilineText from "src/components/UI/CustomUI/atoms/MultilineText"


interface GeneralProps {
  setSubActiveOption: (option: SUB_OPTIONS) => void
  setQuestion: (question: string) => void
  question: string
}


const General: FC<GeneralProps> = ({ setSubActiveOption, setQuestion, question }) => {
  const styles = useStyles()
  const handleClick = (subOption: SUB_OPTIONS) => {
    setSubActiveOption(subOption)
  }

  return (
    <Box width={"100%"} display="flex" flexDirection="column" className={styles.root}>
      <Box>
        <Typography variant="h5" className={styles.text}>
          ask us a question
        </Typography>
        <Typography className={styles.subtitle}>Submit your questions and your preferred method for a reply.</Typography>
      </Box>

      <Box className={styles.questionContainer}>
        <Typography className={styles.questionTitle}>Your question</Typography>
        <MultilineText
          value={question}
          onChange={(event: any) => {
            setQuestion(event.target.value)
          }}
        // placeholder='Add repair items/notes here for our team to review'
        />
      </Box>

      <Grid container justifyContent="space-between">
        <Button
          onClick={() => setSubActiveOption(SUB_OPTIONS.initial)}
          className={styles.button}
          startIcon={<icons.ArrowBack />}
        >
          Back
        </Button>
        <Button
          onClick={() => setSubActiveOption(SUB_OPTIONS.contactInformation)}
          className={styles.buttonNext}
          endIcon={<icons.ArrowForward />}
          disabled={!question}
        >
          Next
        </Button>
      </Grid>
    </Box>
  )
}

export default General