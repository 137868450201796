/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState, useEffect, useContext } from 'react'
import { Box, Checkbox, FormControlLabel, Typography, TextField } from '../../../../../UI'
import { getTerritories } from 'src/ducks/selectors'
import { Table, TableBody, TableHead, TableCell, TableRow } from '@mui/material'
import { VendorTerritory } from 'src/ducks/types'
import { useDispatch, useSelector } from 'react-redux'
import { VendorContext } from '../../context'
import { REVIEW_STATUS } from 'src/helpers'
import { territoriesActions } from 'src/ducks/actions'

const TableColumn: FC<{ data: VendorTerritory[] }> = ({ data }) => {
  const { editedVendor, setEditedVendor } = useContext(VendorContext)
  const lay = { '&:last-child td, &:last-child th': { border: 0 } }
  const min = 0
  const max = 20

  return (
    <Table sx={{ border: 0 }}>
      <TableHead>
        <TableRow key={'head'} sx={lay}>
          <TableCell>Territory</TableCell>
          <TableCell>Capacity (# of crews)</TableCell>
        </TableRow>
      </TableHead>
      <TableBody sx={lay}>
        {data.map((row: VendorTerritory, index: number) => (
          <TableRow key={index + row.id} sx={lay}>
            {row.id === "OTHER" && <TableCell sx={lay}>
              <FormControlLabel
                label={<TextField
                  placeholder='Other'
                  disabled={!editedVendor?.territoryList?.some(t => t.id === row.id)}
                  value={editedVendor?.territoryList?.find(t => t.id === "OTHER")?.title}
                  onChange={(event) => {
                    setEditedVendor({
                      ...editedVendor,
                      territoryList: editedVendor?.territoryList?.map(t => {
                        if (t.id === "OTHER") {
                          return {
                            ...t,
                            id: "OTHER",
                            title: event.target.value
                          }
                        }
                        return t
                      })
                    })
                  }}
                />}
                control={
                  <Checkbox
                    checked={editedVendor?.territoryList?.some(t => t.id === row.id)}
                    onChange={(event) => {
                      if (!event.target.checked) {
                        setEditedVendor({
                          ...editedVendor,
                          territoryList: editedVendor?.territoryList?.filter(t => t.id !== row.id)
                        })
                      } else {
                        const newTerritories = editedVendor?.territoryList
                        newTerritories?.push(row)
                        setEditedVendor({
                          ...editedVendor,
                          territoryList: newTerritories
                        })
                      }
                    }}
                    color='infoText'
                  />
                }
              />
            </TableCell>}
            {row.id !== "OTHER" && <TableCell sx={lay}>
              <FormControlLabel
                label={<Typography variant='body2'>{row.title ?? ''}</Typography>}
                control={
                  <Checkbox
                    checked={editedVendor?.territoryList?.some(t => t.id === row.id)}
                    onChange={(event) => {
                      if (!event.target.checked) {
                        setEditedVendor({
                          ...editedVendor,
                          territoryList: editedVendor?.territoryList?.filter(t => t.id !== row.id)
                        })
                      } else {
                        const newTerritories = editedVendor?.territoryList
                        newTerritories?.push(row)
                        setEditedVendor({
                          ...editedVendor,
                          territoryList: newTerritories
                        })
                      }
                    }}
                    color='infoText'
                  />
                }
              />
            </TableCell>}
            {row.id !== "OTHER" &&
              <TableCell sx={lay}>
                <TextField
                  type='number'
                  size='small'
                  disabled={!editedVendor?.territoryList?.some(t => t.id === row.id)}
                  value={editedVendor?.territoryList?.find(t => t.id === row.id) ? editedVendor?.territoryList?.find(t => t.id === row.id)?.capacity : ''}
                  variant='outlined'
                  placeholder={`From ${min} to ${max}`}
                  inputProps={{ min, max }}
                  style={{ minWidth: '212px' }}
                  onChange={(e) => {
                    var value = parseInt(e.target.value, 10)
                    if (value > max) value = max
                    if (value < min) value = min
                    const newTerritoryList = editedVendor?.territoryList?.map(t => {
                      if (t.id === row.id) {
                        return {
                          ...t,
                          capacity: value
                        }
                      }
                      return t
                    })
                    setEditedVendor({
                      ...editedVendor,
                      territoryList: newTerritoryList

                    })
                  }}
                />
              </TableCell>
            }

          </TableRow>
        ))}
      </TableBody>
    </Table >
  )
}

const Territories: FC = () => {
  const dispatch = useDispatch()

  const { editedVendor } = useContext(VendorContext)
  const territoryList = useSelector(getTerritories)
  const [first, setFirst] = useState<VendorTerritory[] | undefined>([])
  const [second, setSecond] = useState<VendorTerritory[] | undefined>([])

  useEffect(() => {
    dispatch(
      territoriesActions.fetchTerritories(() => { })
    )
  }, [dispatch])

  useEffect(() => {
    const middleIndex = territoryList ? Math.ceil(territoryList?.length / 2) : 0
    const middle = territoryList && territoryList.length > 1 ? middleIndex : 1
    const firstHalf = territoryList.splice(0, middle).map(t => {
      const vendorTerritory = editedVendor?.territoryList?.find(territory => territory.id === t.id)

      return {
        id: t.id,
        title: t.title,
        status: REVIEW_STATUS.APPROVED,
        capacity: null,
        validGL: vendorTerritory?.validGL || false,
        validWC: vendorTerritory?.validWC || false
      }
    })
    const secondHalf = () => {
      const sf = territoryList.splice(-middle).map(t => {
        const vendorTerritory = editedVendor?.territoryList?.find(territory => territory.id === t.id)
        const territory: {
          id: string,
          title: string,
          status: REVIEW_STATUS,
          capacity: null | number,
          validGL: boolean,
          validWC: boolean
        } = {
          id: t.id,
          title: t.title,
          status: REVIEW_STATUS.APPROVED,
          capacity: null,
          validGL: vendorTerritory?.validGL || false,
          validWC: vendorTerritory?.validWC || false
        }
        return territory
      })
      const other = editedVendor?.territoryList?.find(territory => territory.id === "OTHER")
      if (other) {
        sf.push({
          id: other.id,
          title: other.title,
          status: REVIEW_STATUS.DRAFT,
          capacity: 0,
          validGL: false,
          validWC: false
        })
      } else {
        sf.push({
          id: "OTHER",
          title: "",
          status: REVIEW_STATUS.DRAFT,
          capacity: 0,
          validGL: false,
          validWC: false
        })
      }
      return sf
    }
    setFirst(firstHalf)
    setSecond(secondHalf())

  }, [territoryList])

  return (
    <Box display='flex' gap={1}>
      <Box display='flex' flexDirection='column' gap={1} style={{ flex: 1 }}>
        <TableColumn data={first ?? []} />
      </Box>

      <Box display='flex' flexDirection='column' gap={1} style={{ flex: 1 }}>
        <TableColumn data={second ?? []} />
      </Box>
    </Box >
  )
}

export default Territories
