import { Client } from '../types'

/** TYPES **/
export const clientTypes = {
  FETCH_CLIENT: 'FETCH_CLIENT',
  CREATE_CLIENT: 'CREATE_CLIENT',
  SET_CLIENT: 'SET_CLIENT',
  SET_CLIENT_VALUE: 'SET_CLIENT_VALUE',
  UPDATE_CLIENT_ID: 'UPDATE_CLIENT_ID'
}

/** ACTIONS **/
export interface FetchClientAction {
  type: typeof clientTypes.FETCH_CLIENT
  payload: string
  callback?: (succ: boolean) => void
}

export interface CreateClientAction {
  type: typeof clientTypes.CREATE_CLIENT
  payload: Partial<Client>
  callback?: (succ: boolean) => void
}

export interface SetClientAction {
  type: typeof clientTypes.SET_CLIENT
  payload: Client
}

export interface SetClientValueAction {
  type: typeof clientTypes.SET_CLIENT_VALUE
  payload: ClientValue
}

export interface UpdateClientIdAction {
  type: typeof clientTypes.UPDATE_CLIENT_ID
  clientId: string
  payload: Partial<Client>
  callback?: (succ: boolean) => void
}

export type ClientActions =
  | FetchClientAction
  | CreateClientAction
  | SetClientAction
  | SetClientValueAction
  | UpdateClientIdAction

export interface ClientValue {
  attr: keyof Client
  value: Partial<Client>
}

/** REDUCER **/
export type ClientType = Client
