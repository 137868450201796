import { HeaderType } from 'src/components/UI/CustomUI/organisms/Table/types';
const headers: HeaderType[] = [
    {
        id: 'name',
        label: 'Name',
        align: 'left',
    },
    {

        id: 'email',
        label: 'Email',
        align: 'left',
    },
    {
        id: 'estimates',
        label: 'Estimates',
        align: 'left',
    },
    {
        id: 'approvals',
        label: 'Approvals',
        align: 'left',
    },
    {
        id: 'conversionRate',
        label: 'Conversion Rate',
        align: 'left',
    },
    {
        id: 'revenue',
        label: 'Revenue',
        align: 'left',
    }
]

export default headers;