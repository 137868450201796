import { makeStyles } from '@mui/styles'

export default makeStyles(() => ({
  root: {
    display: 'grid',
    height: '100vh',
    margin: '0 auto',
    gridTemplateRows: 'min-content auto'
  },
  content: {
    display: 'grid',
    minHeight: '100%',
    //padding: '24px'
  }
}))
