import { authTypes } from '../auth/types'
import { Widget } from '../types'
import { WidgetActions, widgetTypes } from './types'

const initialState: Widget | null = null

const reducer = (state = initialState, action: WidgetActions): Widget | null => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case widgetTypes.SET_WIDGET: {
      return payload as Widget
    }

    default:
      return state
  }
}

export default reducer
