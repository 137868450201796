/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useCallback, useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { PrivateLoader } from 'src/components/templates'
import { clientsActions, filtersActions, priceModelActions, territoriesActions } from 'src/ducks/actions'
import { getClientsForTable, getTotalClients, getClientsBoxes, getTerritoriesSelector, getPriceModelAffiliate, getPriceModelNar } from 'src/ducks/selectors'
import Table from 'src/components/UI/CustomUI/organisms/Table'
import { getStateForSelect } from 'src/helpers/states'
import headers from './headers'
import { Box, InputSearch, FieldsFilter, Grid, Button, IconButton, StatusBoxes, ActionsButton, SelectInput } from 'src/components/UI'
import DatePicker from 'src/components/UI/CustomUI/atoms/DatePicker'
import { ROWS_PER_PAGE_EXTENDED } from 'src/helpers'
import { useNavigate } from 'react-router-dom';
import TablePagination from 'src/components/UI/CustomUI/organisms/Table/TablePagination'
import useStyles from './styles'
import { useIsXlScreen } from 'src/hooks'
import RefreshIcon from '@mui/icons-material/Refresh'
import { Range } from "./types"
import ExportCSV from 'src/components/UI/CustomUI/molecules/ExportCSV'
import { CSVLink } from 'react-csv'
import { icons } from 'src/assets'
import { SelectInputOption } from 'src/components/UI/CustomUI/atoms/SelectInput/types'
import { debounce } from 'lodash'
import { getCustomersFilter } from 'src/ducks/filters/selector'

const narAffiliates = [
  { key: "all", label: "All" },
  { key: "nar", label: "NAR" }
];

const Customers: FC = () => {
  const dispatch = useDispatch()
  const narPrice = useSelector(getPriceModelNar)
  const affiliatePrice = useSelector(getPriceModelAffiliate)
  const clients = useSelector(getClientsForTable(narPrice, affiliatePrice))
  const filters = useSelector(getCustomersFilter())
  const {
    search,
    states,
    userTypes,
    affiliate,
    dateRange,
    territory
  } = filters
  const classes = useStyles()
  const [params, setParams] = useState<any>({
    state: states ?? [],
    type: userTypes?.filter(type => !type.hide).map((type: SelectInputOption) => type.key) ?? [],
    search: search ?? "",
    territory: territory?.key && territory.key !== "all" ? territory?.key : "",
    nar: affiliate?.key === "nar" ? true : "",
    createdOnStart: dateRange?.startDate ? Math.floor(new Date(dateRange.startDate).getTime() / 1000) : null,
    createdOnEnd: dateRange?.endDate ? Math.floor(new Date(dateRange.endDate).getTime() / 1000) : null
  })
  const stateTypes = useSelector(getStateForSelect)
  const [statesTerritory, setStatesTerritory] = useState(stateTypes.map(state => (
    {
      key: state.key,
      label: state.label,
      value: state.value,
      hide: !states?.includes(state.key)
    }
  )) || [])
  const resetDates: () => Range = () => {
    return {
      startDate: undefined,
      endDate: undefined
    }
  }

  const [tableLoading, setTableLoading] = useState(false)
  const [pageLoading, setPageLoading] = useState(true)
  const [countsLoading, setCountsLoading] = useState(false)
  const [tableHeaders, setTableHeaders] = useState(headers)
  const totalClients = useSelector(getTotalClients)
  const data = useSelector(getClientsBoxes())
  const [xlScreen] = useIsXlScreen()
  const minWidthSel = xlScreen ? '156px' : '120px'
  const [refresh, setRefresh] = useState(true)
  const [loadingCSV, setLoadingCSV] = useState(false)
  const [allClients, setAllClients] = useState<string>()
  const downloadRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
  const territoriesOtions = useSelector(getTerritoriesSelector)



  let navigate = useNavigate()

  const fetchClients = useCallback(
    debounce((params, page?: number, size?: number) => {
      setTableLoading(true)
      dispatch(
        clientsActions.fetchClients({ page, size, searchParams: params }, (_succ: boolean) => {
          setTableLoading(false)
        })
      )
    }, 300),
    [])

  const fetchStats = useCallback(
    debounce((params) => {
      setCountsLoading(true)
      dispatch(
        clientsActions.fetchStats({ searchParams: params }, (_succ: boolean) => {
          setCountsLoading(false)
        })
      )
    }, 300),
    [])

  useEffect(() => {
    if (territory === undefined) {
      dispatch(filtersActions.setFilters({ customers: { ...filters, territory: territoriesOtions[0] } }))
    }
  }, [territoriesOtions])

  useEffect(() => {
    dispatch(territoriesActions.fetchTerritories((succ: boolean) => {
      if (!succ)
        console.log('Error fetching territories')
      setPageLoading(false)
    }))
    dispatch(
      priceModelActions.fetchClientPriceModels((_succ: boolean) => { })
    )
  }, [])

  useEffect(() => {
    fetchClients(params)
    fetchStats(params)
  }, [params, search])

  useEffect(() => {
    if (refresh) {
      fetchClients(params)
      fetchStats(params)
    }
    setRefresh(false)
  }, [refresh])

  const onTableFilterChange = (index: number): void => {
    setTableHeaders(tableHeaders.map((item, indx) => {
      if (indx === index) {
        return { ...item, hide: !item.hide }
      }
      return item
    }))
  }

  const handleClickRow = (row: any, e: React.MouseEvent) => {

    if (e.type !== 'contextmenu') {
      navigate(`${row.id}`)
      e.preventDefault()
    }
  }

  const handleSearch = (search: string) => {
    dispatch(filtersActions.setFilters({
      customers: {
        ...filters,
        search: search
      }
    }))
    setParams({ ...params, search: search })
  }

  const handleChangeStatesTerritory = (index: number) => {
    let id = statesTerritory[index].key
    dispatch(filtersActions.setFilters({
      customers: {
        ...filters,
        states: states?.includes(id) ? states.filter((i: any) => i !== id) : states ? [...states, id] : [id]
      }
    }))

    setStatesTerritory(statesTerritory.map((stTerritory, i) => {
      if (i === index) {
        stTerritory.hide = !stTerritory.hide
      }
      return stTerritory
    }))

    switch (id) {
      default: {

        setParams({
          ...params,
          state: params.state.includes(id) ? params.state.filter((i: any) => i !== id) : [...params.state, id]
        })
        break
      }
    }
  }

  const handleChangeUserTypes = (index: number) => {
    const userTypeId = userTypes[index].key
    dispatch(filtersActions.setFilters({
      customers: {
        ...filters,
        userTypes: filters?.userTypes?.map((user, i) => {
          if (i === index) {
            user.hide = !user.hide
          }
          return user
        }
        )
      }
    }))
    switch (userTypeId) {
      default: {
        setParams({ ...params, type: params.type?.includes(userTypeId) ? params.type?.filter((i: any) => i !== userTypeId) : [...params.type, userTypeId] })
        break
      }
    }
  }

  const handleAffiliateChange = (newSelected: SelectInputOption) => {
    const nar = newSelected?.key === "nar" ? true : null;
    dispatch(filtersActions.setFilters({ customers: { ...filters, affiliate: newSelected } }));
    setParams({
      ...params,
      nar
    });
  };

  const handleATerritoryChange = (newSelected: SelectInputOption) => {
    if (newSelected === null) {
      dispatch(filtersActions.setFilters({ customers: { ...filters, territory: territoriesOtions[0] } }))

    } else {
      dispatch(filtersActions.setFilters({ customers: { ...filters, territory: newSelected } }))
    }
    setParams((params: any) => {
      const newParams = { ...params }
      if (newSelected && newSelected?.label !== 'All Territories') {
        newParams.territory = newSelected.key
      } else {
        delete newParams.territory
      }

      return newParams
    })
  }

  const handleSelect = (rangeSelected: Range) => {
    dispatch(filtersActions.setFilters({ customers: { ...filters, dateRange: rangeSelected } }))
  }

  const getAllClients = () => {
    setLoadingCSV(true)
    dispatch(clientsActions.downloadCSV(params, (succ: boolean, clients: string) => {
      setAllClients(clients)
      setLoadingCSV(false)
      downloadRef.current?.link.click()
    }))
  }

  const handleClearFilters = () => {
    dispatch(filtersActions.setFilters({
      customers: {
        ...filters,
        states: undefined,
        affiliate: narAffiliates[0],
        userTypes: filters?.userTypes?.map((user) => ({ ...user, hide: true })),
        search: "", territory: territoriesOtions[0],
      }
    }))
    setStatesTerritory(statesTerritory.map((stTerritory) => ({ ...stTerritory, hide: true })))
    setParams({ search: "", territory: "", states: "" })
  }

  return (
    <PrivateLoader loading={pageLoading}>
      <Box display='flex' flexDirection='column' gap={2} className={classes.container}>

        <Grid container spacing={2} sx={{ flexWrap: '!important' }} alignItems='flex-end'>
          <Grid item lg={1.5}>
            <InputSearch
              label='Search for Customers:'
              value={search}
              onChange={handleSearch}
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item lg={1.5}>
            <FieldsFilter
              flexColumn
              handleChange={handleChangeStatesTerritory}
              inputText='All States'
              options={statesTerritory}
              renderAsInput
              label='States:'
              popupLabel='Select States to view'
              variant='body1'
              sx={{ minWidth: minWidthSel, flex: '1' }}
            />
          </Grid>
          <Grid item lg={1.5}>
            <FieldsFilter
              flexColumn
              handleChange={handleChangeUserTypes}
              inputText='All User Types'
              options={userTypes}
              renderAsInput
              label='User Type:'
              popupLabel='Select States to view'
              variant='body1'
              sx={{ minWidth: minWidthSel, flex: '1' }}
            />
          </Grid>

          <Grid item lg={1.5}>
            <SelectInput
              label='Territory:'
              onChange={handleATerritoryChange}
              options={territoriesOtions}
              value={territory}
              sx={{ minWidth: minWidthSel, flex: '1' }} />
          </Grid>
          <Grid item lg={1.5}>
            <SelectInput
              label='Affiliate:'
              onChange={handleAffiliateChange}
              options={narAffiliates}
              value={affiliate}
              sx={{ minWidth: minWidthSel, flex: '1' }} />
          </Grid>
          <Grid item lg={1}>
            <Button variant='outlined' onClick={handleClearFilters}>Clear Filters</Button>
          </Grid>

          <Grid item container lg={3.5} justifyContent='flex-end' spacing={2} alignItems='flex-end' width='fit-content'>

            <Grid item>
              <FieldsFilter options={tableHeaders} handleChange={onTableFilterChange} maxWidth='250px' sx={{ height: '56px', minWidth: '56px !important' }} />
            </Grid>
            <Grid item>
              <ActionsButton
                loading={loadingCSV}
                icon={<icons.CloudDownload color='disabled' />}
                iconPosition='end'
                onClick={getAllClients}
                sx={{
                  minWidth: '56px!important',
                  minHeight: '56px!important',
                  '& .MuiBox-root': {
                    height: '56px!important'
                  },
                }}
              />
              {allClients && <ExportCSV
                headers={allClients?.slice(0, allClients?.indexOf('\n'))?.split(',')?.map((h: any) => h)}
                data={allClients?.slice(allClients?.indexOf('\n') + 1)}
                innerRef={downloadRef}
                fileName='clients.csv'
                label=''//{<ActionsButton icon={<Icon name='CloudDownload' />} sx={{ height: '56px', width: '56px' }} />}
              />}

            </Grid>
          </Grid>

        </Grid>

        <StatusBoxes data={data} sx={{ textAlign: 'left', alignItems: 'flex-start', padding: '.5rem 1rem !important' }} loading={countsLoading} />

        <Grid container justifyContent='space-between' alignItems='flex-end'>
          <Grid container item xs={8} spacing={2}>
            <Grid item xs={12} gap={2} display='flex' alignItems='flex-end'>

              <Grid item lg={4} >
                <DatePicker
                  onChange={handleSelect}
                  value={{
                    startDate: dateRange?.startDate ? new Date(dateRange?.startDate) : undefined,
                    endDate: dateRange?.endDate ? new Date(dateRange?.endDate) : undefined
                  }}
                  style={{ height: '38px', padding: '0px', width: '100%' }}
                  size='small'
                  label='Range Created date to:'
                  allowRange
                />
              </Grid>

              <Grid container item lg={6} gap={2}>

                <Grid item>
                  <Button
                    variant='contained'
                    sx={{ minWidth: '100px !important' }}
                    onClick={() => {
                      if (dateRange.startDate && dateRange.endDate) {
                        const dateEnd = new Date(dateRange.endDate.toString())
                        dateEnd.setDate(dateEnd.getDate() + 1);

                        // only on same day searches: date + 1
                        const finalDate = dateRange.startDate.getTime() === dateRange.endDate.getTime() ?
                          dateEnd.getTime() : dateRange.endDate.getTime()

                        setParams({
                          ...params, createdOnStart: Math.floor(dateRange.startDate.getTime() / 1000),
                          createdOnEnd: Math.floor(finalDate / 1000)
                        })
                      } else {
                        setParams({ ...params, createdOnStart: null, createdOnEnd: null })
                      }
                    }}
                  >
                    Apply
                  </Button>
                </Grid>

                <Grid item>
                  <Button
                    variant='outlined'
                    sx={{ minWidth: '100px !important' }}
                    onClick={() => {
                      setParams({ ...params, createdOnStart: null, createdOnEnd: null })
                      dispatch(filtersActions.setFilters({ customers: { ...filters, dateRange: resetDates() } }))
                    }
                    }
                  >
                    Reset dates
                  </Button>
                </Grid>
                <Grid item>
                  <IconButton
                    onClick={() => {
                      setRefresh(true)
                    }
                    }
                  >
                    <RefreshIcon />
                  </IconButton>
                </Grid>
              </Grid>

            </Grid>
          </Grid>
          <Grid item container xs={4} spacing={1} justifyContent='flex-end' alignItems='flex-end'>
            <Grid item>
              <TablePagination
                callToApi={(start, limit) => { fetchClients(params, start, limit) }}
                totalItems={totalClients}
                rowsPerPageOptions={ROWS_PER_PAGE_EXTENDED}
                sx={{
                  '.MuiTablePagination-toolbar': {
                    minHeight: 'fit-content !important'
                  },
                  '.MuiButtonBase-root': {
                    padding: '0px inherit !important'
                  }
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Table
            headers={tableHeaders}
            defaultOrder='desc'
            rowAction={handleClickRow}
            totalItems={totalClients}
            callToApi={(start, limit) => { fetchClients(params, start, limit) }}
            loading={tableLoading}
            hasPagination={false}
            hasScroll
            onRightClickRow={handleClickRow}
            className={classes.table}
          >
            {clients ?? []}
          </Table>
        </Grid>
      </Box>
    </PrivateLoader >
  )
}

export default Customers
