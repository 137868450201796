/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Table from 'src/components/UI/CustomUI/organisms/Table'
import { paymentsActions } from 'src/ducks/actions'
import { getPayments } from 'src/ducks/selectors'
import headers from './headers'
import Modal from 'src/components/UI/CustomUI/molecules/Modal'
import AddCheckModal from '../AddCheckModal'

const Payment = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const payments = useSelector(getPayments())
    const [tableLoading, setTableLoading] = useState(false)

    const fetchPayments = (start: number = 0, limit: number = 25) => {
        setTableLoading(true)
        dispatch(
            paymentsActions.fetchPayments({ params: { start, limit, jobId: id } }, (_succ: boolean) => {
                setTableLoading(false)
            })
        )
    }

    useEffect(() => {
        fetchPayments()
    }, [])

    return (
        <>
            <Table
                headers={headers}
                title="PAYMENT HISTORY"
                loading={tableLoading}
                small
            >
                {payments}
            </Table>
        </>
    )
}

export default Payment;