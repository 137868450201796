import { DiscountBanner } from 'src/ducks/discounts/types'
import axios from '../axios'
import { ApiController } from './types'

const base = '/discount-banner'

const apiController: ApiController = {
  getDiscountBanner: async () =>
    await axios.get(`${base}/current`).then((response) => response.data),

  createDiscountBanner: async (request: Partial<DiscountBanner>) =>
    await axios.post(`${base}`, request).then((response) => response.data),
}

export default apiController
