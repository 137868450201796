import {
  orderTypes,
  OrderActions,
  Order,
  UpdateOrderPayload,
  DispatchOrderPayload,
  RevokeDispatchOrderPayload,
  AddDrawForPayNowPayload,
  GetPricingPayload,
  DeleteOrderPayload,
  OrderValue
} from './types'

const actions = {
  getOrder: (
    payload: string,
    callback?: (
      succ: boolean) => void
  ): OrderActions => ({
    type: orderTypes.GET_ORDER,
    payload,
    callback
  }),
  setOrder: (payload: Order): OrderActions => ({
    type: orderTypes.SET_ORDER,
    payload
  }),
  updateOrder: (
    payload: UpdateOrderPayload,
    callback: (succ: boolean) => void
  ): OrderActions => ({
    type: orderTypes.UPDATE_ORDER,
    payload,
    callback
  }),
  deleteOrder: (
    payload: DeleteOrderPayload,
    callback?: (
      succ: boolean) => void
  ): OrderActions => ({
    type: orderTypes.DELETE_ORDER,
    payload,
    callback
  }),
  dispatchOrder: (
    payload: DispatchOrderPayload,
    callback?: (
      succ: boolean) => void
  ): OrderActions => ({
    type: orderTypes.DISPATCH_ORDER,
    payload,
    callback
  }),
  revokeDispatchOrder: (
    payload: RevokeDispatchOrderPayload,
    callback?: (
      succ: boolean) => void
  ): OrderActions => ({
    type: orderTypes.REVOKE_DISPATCH_ORDER,
    payload,
    callback
  }),
  addDrawForPayNowAction: (
    payload: AddDrawForPayNowPayload,
    callback?: (item: any) => void
  ): OrderActions => ({
    type: orderTypes.ADD_DRAW_FOR_PAYNOW,
    payload,
    callback
  }),
  getOrderBids: (
    payload: string,
    callback?: (bids: any) => void
  ): OrderActions => ({
    type: orderTypes.GET_ORDER_BIDS,
    payload,
    callback
  }),
  getPricing: (
    payload: GetPricingPayload,
    callback?: (pricing: any) => void
  ): OrderActions => ({
    type: orderTypes.GET_PRICING,
    payload,
    callback
  }),
  fetchOrderHistory: (
    payload: string,
    callback: (succ: boolean) => void
  ): OrderActions => ({
    type: orderTypes.FETCH_ORDER_HISTORY,
    payload,
    callback
  }),
  setOrderValue: (payload: OrderValue): OrderActions => ({
    type: orderTypes.SET_ORDER_VALUE,
    payload
  })
}

export default actions
