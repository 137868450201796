import { Partner } from '../types'
import { homemartTypes, HomemartActions, HomemartType } from './types'

const actions = {
  fetchPartners: (payload: { active: boolean }, callback?: (succ: boolean) => void): HomemartActions => ({
    type: homemartTypes.FETCH_PARTNERS,
    payload,
    callback
  }),

  createPartner: (payload: Partial<Partner>, callback?: (succ: boolean) => void): HomemartActions => ({
    type: homemartTypes.CREATE_HOMEMART,
    payload,
    callback
  }),

  updatePartner: (payload: Partial<Partner>, callback?: (succ: boolean) => void): HomemartActions => ({
    type: homemartTypes.UPDATE_HOMEMART,
    payload,
    callback
  }),

  deletePartner: (payload: string, callback?: (succ: boolean) => void): HomemartActions => ({
    type: homemartTypes.DELETE_HOMEMART,
    payload,
    callback
  }),

  fetchPartnerCategories: (payload: null, callback?: (succ: boolean) => void): HomemartActions => ({
    type: homemartTypes.FETCH_PARTNER_CATEGORIES,
    payload,
    callback
  }),

  fetchPartnerTags: (payload: null, callback?: (succ: boolean) => void): HomemartActions => ({
    type: homemartTypes.FETCH_PARTNER_TAGS,
    payload,
    callback
  }),

  setHomemart: (payload: Partial<HomemartType>): HomemartActions => ({
    type: homemartTypes.SET_HOMEMART,
    payload
  })
}

export default actions
