/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo, isEmpty } from '../../helpers'
import { Punchlist } from '../../api'
import { estimatesTypes, FetchEstimatesActions, FetchRelatedEstimatesAction, ReleaseEstimateAction, UpdateEstimateActions } from './types'
import { estimateActions, estimatesActions } from '../actions'
import { getEstimateId } from '../selectors'
import { UpdateRequest } from '~api/types'
import { EstimateItem } from '../types'

export function* fetchEstimates({ payload, callback }: FetchEstimatesActions): SagaIterator {
  let success = false
  try {
    const page = payload.page ?? 0
    const size = payload.size ?? 50

    const start = page * size
    const limit = size;

    const searchParams = payload.searchParams ?? ''
    const estimates = yield call(Punchlist.estimates.getEstimates, { start, limit, searchParams })

    if (estimates) {
      yield put(estimatesActions.setEstimates(estimates))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the estimates'))
    if (callback) yield call(callback, false)
  }
}

export function* fetchRelatedEstimates({ payload, callback }: FetchRelatedEstimatesAction): SagaIterator {
  let success = false
  try {
    const estimates = yield call(Punchlist.estimates.getRelatedEstimates, { id: payload })

    if (estimates) {
      yield put(estimatesActions.setRelatedEstimates(estimates))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the related estimates'))
    if (callback) yield call(callback, false)
  }
}

export function* updateEstimate({ payload, callback }: UpdateEstimateActions): SagaIterator {
  let success = false

  try {
    const request: UpdateRequest = []
    const estimateId = yield select(getEstimateId())
    const { id, ...payloadWithoutId } = payload

    for (const attr in payloadWithoutId) {
      request.push({
        op: 'add',
        path: '/' + attr,
        value: payload[attr as keyof EstimateItem],
      })
    }

    const estimate = yield call(
      Punchlist.estimates.patchEstimate,
      payload.id || estimateId,
      request
    )

    if (!isEmpty(estimate)) {
      // yield put(jobActions.fetchJob(jobId))
      yield put(estimateActions.fetchEstimate(estimate.id))
      //yield put(jobActions.fetchHistoryTask(jobId))
      success = true
    }
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('update the estimate'))
    if (callback) yield call(callback, false)
  }
}

export function* fetchEstimatesCounts({ payload, callback }: FetchEstimatesActions): SagaIterator {
  let success = false
  try {
    const searchParams = payload.searchParams ?? ''
    const counts = yield call(Punchlist.estimates.getEstimatesCounts, { searchParams })

    if (counts) {
      yield put(estimatesActions.setEstimatesCounts(counts))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the estimates counts'))
    if (callback) yield call(callback, false)
  }
}

export function* releaseEstimate({ payload, callback }: ReleaseEstimateAction): SagaIterator {
  let success = false
  try {
    const {
      estimateId,
      releaseEstimateRequest
    } = payload
    const estimate = yield call(Punchlist.estimates.releaseEstimate, estimateId, releaseEstimateRequest)

    if (!isEmpty(estimate)) {
      yield put(estimateActions.setEstimate(estimate))
      success = true
    }
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('release the estimate'))
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(estimatesTypes.FETCH_ESTIMATES, fetchEstimates)
  yield takeLatest(estimatesTypes.FETCH_RELATED_ESTIMATES, fetchRelatedEstimates)
  yield takeLatest(estimatesTypes.UPDATE_ESTIMATE, updateEstimate)
  yield takeLatest(estimatesTypes.FETCH_ESTIMATE_COUNTS, fetchEstimatesCounts)
  yield takeLatest(estimatesTypes.RELEASE_ESTIMATE, releaseEstimate)
}
