import { useState, FC } from 'react'
import {
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import { TextFieldLabel } from 'src/components/UI'
import Folder from '../ProsDashboard/Folder'
import Modal from 'src/components/UI/CustomUI/molecules/Modal'
import { SaveSearchModalProps } from './types'
import { useDispatch, useSelector } from 'react-redux'
import { searchesActions } from 'src/ducks/actions'
import { RESOURCE_TYPE } from 'src/helpers'
import { getProsFilter } from 'src/ducks/filters/selector'
import { getSearches } from 'src/ducks/selectors'

const SaveSearchModal: FC<SaveSearchModalProps> = ({
  setOpenModal,
  openModal,
}) => {
  const dispatch = useDispatch()

  const prosFilter = useSelector(getProsFilter())

  const [searchName, setSearchName] = useState('')
  const [folderSelected, setFolderSelected] = useState(0)
  const [buttonLoading, setButtonLoading] = useState(false)

  const searches = useSelector(getSearches())

  const handleCreateSearch = () => {
    setButtonLoading(true)
    if (!searchName) return
    const request = {
      folderSelected,
      searchName,
    }
    dispatch(
      searchesActions.createSearch(
        request,
        prosFilter,
        RESOURCE_TYPE.PRO,
        (success: boolean) => {
          dispatch(searchesActions.cleanSearchParams())
          dispatch(searchesActions.fetchSearches(RESOURCE_TYPE.PRO))
          if (success) {
            setOpenModal(false)
            setButtonLoading(false)
            setSearchName('')
          }
        }
      )
    )
  }

  return (
    <Modal
      setOpen={setOpenModal}
      open={openModal}
      title="Let's save your search!"
    >
      <Grid container spacing={2} width="fit-content">
        <Grid item flex={1}>
          <TextFieldLabel
            labelVariant="h6"
            label="Name your search"
            placeholder="Saved Search name"
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
          />
        </Grid>
        {searches.folders.length ? (
          <Grid item container direction="column">
            <Typography variant="h6">
              Select a folder to save your Search
            </Typography>
            <RadioGroup
              value={folderSelected}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setFolderSelected(parseInt(event.target.value))
              }
            >
              {searches.folders.map((folder, index) => (
                <FormControlLabel
                  value={index}
                  control={<Radio />}
                  label={
                    <Folder key={index} folder={folder} showSearches={false} />
                  }
                />
              ))}
            </RadioGroup>
          </Grid>
        ) : (
          <Grid item>
            <Typography variant="h5" color={(theme) => theme.palette.gray[700]}>
              No folder created.
            </Typography>
          </Grid>
        )}
        <Grid container item spacing={1} direction="column">
          <Grid item>
            <Button
              variant="containedBig"
              sx={{ width: '100%' }}
              onClick={handleCreateSearch}
              disabled={!searchName}
            >
              {buttonLoading ? (
                <CircularProgress color="info" size="1.4rem" />
              ) : (
                'Save Changes'
              )}
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => setOpenModal(false)}
              variant="outlinedBig"
              sx={{ width: '100%' }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  )
}

export default SaveSearchModal
