/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo } from '../../helpers'
import { Punchlist } from '../../api'
import { companyTypes, FetchCompanyAction } from './types'
import { companyActions } from '../actions'

export function* fetchJob({
  payload,
  callback,
}: FetchCompanyAction): SagaIterator {
  let success = false
  try {
    const company = yield call(Punchlist.companies.getCompanyById, payload)
    if (company) {
      yield put(companyActions.setCompany(company))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the company'))
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(companyTypes.FETCH_COMPANY, fetchJob)
}
