import { Box, Typography } from "@mui/material"
import useStyles from './styles'

interface BannerProps {
  title?: string,
  subtitle?: string | JSX.Element
  onClick?: () => void
  className?: string
}

const Banner = ({ title, subtitle, onClick, className="" }: BannerProps) => {
  const classes = useStyles()

  return (
    <Box className={`${className} ${classes.banner}`} onClick={onClick}>
      {title && <Typography className={classes.bannerTitle}>{title}</Typography>}
      <Typography className={classes.bannerSubtitle}>{subtitle}</Typography>
    </Box>
  )
}

export default Banner