import { FC, useContext } from 'react'
import { Box, Typography, TextField, SelectInput } from '../../../../../UI'
import { verificationStages } from 'src/helpers'
import { VendorContext } from '../../context'
import AdminSection from './AdminSection'

const Admin: FC = () => {
  const { editedVendor, setEditedVendor } = useContext(VendorContext)

  const handleChange = (newSelected: any) => {
    setEditedVendor({
      ...editedVendor,
      verificationStage: newSelected.key,
    })
  }

  const onCheckboxChanaged = (property: string) => {
    const vendor: any = editedVendor

    setEditedVendor({
      ...editedVendor,
      [property]: vendor?.[property] !== null ? !vendor?.[property] : true,
    })
  }

  return (
    <Box display="flex" gap={2}>
      <Box display="flex" flexDirection="column" gap={1} flex="1">
        <Typography
          variant="h4"
          fontFamily="Nouvelle"
          style={{ marginTop: '16px' }}
        >
          Verification and Notes
        </Typography>

        <Typography
          variant="h5"
          fontFamily="Nouvelle"
          style={{ marginTop: '8px' }}
        >
          Verification Stage:
        </Typography>
        <SelectInput
          options={verificationStages.slice(1)}
          value={editedVendor?.verificationStage}
          sx={{ minWidth: '200px' }}
          onChange={handleChange}
        />

        <Typography variant="h5" fontFamily="Nouvelle">
          Referal:
        </Typography>
        <TextField
          size="small"
          value={editedVendor?.referral}
          onChange={(event) => {
            setEditedVendor({
              ...editedVendor,
              referral: event.target.value,
            })
          }}
          variant="outlined"
        />

        <Typography variant="h5" fontFamily="Nouvelle">
          Notes:
        </Typography>
        <TextField
          size="small"
          value={editedVendor?.notes}
          onChange={(event) => {
            setEditedVendor({
              ...editedVendor,
              notes: event.target.value,
            })
          }}
          variant="outlined"
          style={{ marginBottom: '8px' }}
          multiline
          rows={8}
        />
      </Box>

      <Box gap={1} flex="1">
        <AdminSection
          label="Background"
          checkBoxes={[
            {
              checked: editedVendor?.bgChecked || false,
              label: 'Background checked',
              property: 'bgChecked',
              onChange: onCheckboxChanaged,
            },
            {
              checked: editedVendor?.bgPassed || false,
              label: 'Background passed',
              property: 'bgPassed',
              onChange: onCheckboxChanaged,
            },
          ]}
        />

        <AdminSection
          label="LMS"
          checkBoxes={[
            {
              checked: editedVendor?.lmsEnabled || false,
              label: 'LMS Enabled',
              property: 'lmsEnabled',
              onChange: onCheckboxChanaged,
            },
          ]}
        />
      </Box>
    </Box>
  )
}

export default Admin
