import React, { useContext, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Box, Grid } from 'src/components/UI'
import Table from 'src/components/UI/CustomUI/organisms/Table'
import { getOrdersFilteredByEstimatedId } from 'src/ducks/selectors'
import { CalendarContext } from '../../context.provider'
import headers from './headers'
import useStyles from './styles'
import { useIsXlScreen } from 'src/hooks'


const JobsFilter = () => {
  const classes = useStyles()
  const [tableHeaders, setTableHeaders] = useState(headers)
  const context = useContext(CalendarContext)
  const orders = useSelector(getOrdersFilteredByEstimatedId())
  const [tableLoading, setTableLoading] = useState(false)
  const dispatch = useDispatch()
  const [xlScreen] = useIsXlScreen()

  const {
    selected,
    view,
  } = context


  useEffect(() => {

  }, [selected, orders])

  const handleClickRow = (row: any, e: React.MouseEvent) => {
    if (e.type !== 'contextmenu') {
      //navigate(`jobs/${row.id}`, { replace: true })
      e.preventDefault()
    }
  }

  return (
    <Box
      sx={{
        overflow: 'scroll',
        minHeight: '500px',
        maxHeight: 'calc(100vh - 250px)',
        height: '100%',
      }}
    >
      <Grid container direction="column">

        <Grid item xs={12}>
          <Table
            headers={tableHeaders}
            defaultOrder='desc'
            //rowAction={handleClickRow}
            //callToApi={(start, limit) => { fetchJobs(start, limit) }}
            loading={tableLoading}
            hasPagination={false}
            onRightClickRow={handleClickRow}
            className={!xlScreen ? classes.table : ''}
          >
            {orders ?? []}
          </Table>
        </Grid>

      </Grid>
    </Box>
  )
}

export default JobsFilter