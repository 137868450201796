import Table from "src/components/UI/CustomUI/organisms/Table"
import headers from './headers'
import { Button, Grid, TextField } from "@mui/material"
import useStyles from '../../../styles'

const DeveloperData = () => {
  const styles = useStyles()

  return (
    <Grid container flexDirection='column' spacing={2}>
      <Grid item container justifyContent='flex-end'>
        <Button className={styles.submitBtn}>
          Save
        </Button>
      </Grid>
      <Grid item container>
        <TextField multiline minRows={30} fullWidth />
      </Grid>
    </Grid>
  )
}

export default DeveloperData
