/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, takeEvery } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo } from '../../helpers'
import { Punchlist } from '../../api'
import { FetchGroupedOrdersAction, groupedOrdersTypes } from './types'
import { groupedOrdersActions } from '../actions'

export function* fetchGroupedOrders({ payload, callback }: FetchGroupedOrdersAction): SagaIterator {
  let success = false

  const { stage, searchParams } = payload

  try {
    const groupedOrders = yield call(Punchlist.orders.getGroupedOrders, searchParams)

    if (groupedOrders) {
      yield put(groupedOrdersActions.setGroupedOrders({ stage, data: groupedOrders || [] }))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the grouped orders'))
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeEvery(groupedOrdersTypes.FETCH_GROUPED_ORDERS, fetchGroupedOrders)
}
