import { SearchParams } from '../notifications/types'
import { NotifActions, notifTypes, NotificationType } from './types'
import { NotificationItem } from '../types'

const actions = {
  fetchNotifications: (
    payload: {
      page?: number,
      size?: number,
      searchParams?: Partial<SearchParams>,
    },
    callback?: (succ: boolean) => void): NotifActions => ({
      type: notifTypes.FETCH_NOTIFICATIONS,
      payload,
      callback
    }),
  setNotifications: (payload: NotificationType): NotifActions => ({
    type: notifTypes.SET_NOTIFICATIONS,
    payload
  }),
  updateNotificationAction: (
    notificationId: string,
    payload: Partial<NotificationItem>,
    callback?: (succ: boolean) => void
  ): NotifActions => ({
    type: notifTypes.UPDATE_NOTIFICATION,
    notificationId,
    payload,
    callback
  }),

}

export default actions
