import { useCallback, useState } from 'react'
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material'
import { SelectInput, TextFieldLabel, Box } from 'src/components/UI'
import Modal from 'src/components/UI/CustomUI/molecules/Modal'
import {
  APPROVAL_TYPE,
  CONTACT_ROLE,
  CONTACT_ROLE_OPTIONS,
  CONTACT_ROLE_TEXT,
  ESTIMATE_STATUS,
  PHONE_MASK_INPUT,
  PHONE_MASK_REGEXP,
  validateEmail,
} from 'src/helpers'
import { ModalOverrideApprovalProps } from './types'
import { useDispatch, useSelector } from 'react-redux'
import { getEstimate } from 'src/ducks/selectors'
import {
  Contact,
  EstimateItem,
  EstimateProperty,
  JobProperties,
} from 'src/ducks/types'
import {
  estimateActions,
  estimatesActions,
  jobActions,
  jobsActions,
} from 'src/ducks/actions'
import ModalConfirm from '../ModalConfirm'
import { isEmpty } from 'lodash'

const ModalOverrideApproval = ({
  open,
  setOpen,
}: ModalOverrideApprovalProps) => {
  const roleOptions = CONTACT_ROLE_OPTIONS

  const dispatch = useDispatch()

  const estimate = useSelector(getEstimate())

  const {
    properties: { contacts },
  } = estimate as EstimateItem

  const getInitApprover = useCallback(
    (newContacts?: Contact[] | undefined) =>
      (newContacts || contacts)?.find(
        ({ approvalType, isInitialAuthorizer }) =>
          approvalType === APPROVAL_TYPE.APPROVED &&
          isInitialAuthorizer === true
      ),
    [contacts]
  )
  const getHomeowner = useCallback(
    (newContacts?: Contact[] | undefined) =>
      (newContacts || contacts)?.find(
        ({ role, approvalType }) =>
          role === CONTACT_ROLE.HOMEOWNER &&
          (approvalType === APPROVAL_TYPE.REQUIRED ||
            approvalType === APPROVAL_TYPE.APPROVED)
      ),
    [contacts]
  )
  const getPayer = useCallback(
    (newContacts?: Contact[] | undefined) =>
      (newContacts || contacts)?.find(
        ({ isPayer, approvalType }) =>
          isPayer === true &&
          (approvalType === APPROVAL_TYPE.REQUIRED ||
            approvalType === APPROVAL_TYPE.APPROVED ||
            approvalType === APPROVAL_TYPE.PAY_AT_CLOSE)
      ),
    [contacts]
  )

  const [approvalOpen, setApprovalOpen] = useState(false)
  const [saveLoading, setSaveLoading] = useState(false)
  const [saveApproveLoading, setSaveApproveLoading] = useState(false)
  const [initialApprover, setInitialApprover] = useState(getInitApprover())
  const [homeOwner, setHomeOwner] = useState(
    getHomeowner()?.email !== getInitApprover()?.email
      ? getHomeowner()
      : undefined
  )
  const [payer, setPayer] = useState(getPayer())
  const [approverPayAtClose, setApproverPayAtClose] = useState(
    getInitApprover()?.email === getPayer()?.email &&
    !!getInitApprover()?.email &&
    !!getPayer()?.email
  )
  const [ownerPayAtClose, setOwnerPayAtClose] = useState(
    getHomeowner()?.email === getPayer()?.email &&
    !!getHomeowner()?.email &&
    !!getPayer()?.email
  )
  const [payerAtClose, setPayerAtClose] = useState(
    !!estimate?.properties.payAtClose
  )

  const initialApproverIsHomeowner = !isEmpty(initialApprover) && initialApprover.role === CONTACT_ROLE.HOMEOWNER

  const isValidContact = (contact: any, withRole: boolean = false) => {
    return !isEmpty(contact?.fullName) &&
      !isEmpty(contact?.email) &&
      validateEmail(contact?.email || '') &&
      ((PHONE_MASK_REGEXP.test(contact?.phone || '') && !isEmpty(contact?.phone))) &&
      (withRole ? !isEmpty(contact?.role) : true)
  }

  const isValid =
    isValidContact(initialApprover, true) &&
    (!initialApproverIsHomeowner ? isValidContact(homeOwner) : true) &&
    isValidContact(payer)


  const getUpdatedApprovealDetails = () => {
    let newApprovalDetails: any = {
      payer: undefined,
      approver: initialApprover,
      authorized: undefined,
    }

    if (newApprovalDetails.approver?.role === CONTACT_ROLE.HOMEOWNER) {
      newApprovalDetails.authorized = newApprovalDetails.approver
    } else {
      newApprovalDetails.authorized = homeOwner
    }

    if (approverPayAtClose) {
      newApprovalDetails.payer = newApprovalDetails.approver
    } else if (ownerPayAtClose) {
      newApprovalDetails.payer = newApprovalDetails.authorized
      newApprovalDetails.payer.role = CONTACT_ROLE.HOMEOWNER
    } else {
      newApprovalDetails.payer = payer
    }

    return newApprovalDetails
  }

  const handleSave = ({ directApprove }: { directApprove: boolean }) => {
    let newContacts = contacts
    let tempContact: Contact

    const { payer, approver, authorized } = getUpdatedApprovealDetails()

    if (approver) {
      tempContact = newContacts?.find(
        (contact) => contact.email === approver.email
      ) as any
      newContacts = newContacts?.map((contact) =>
        contact.isInitialAuthorizer
          ? { ...contact, approvalType: APPROVAL_TYPE.NOT_NEEDED }
          : contact
      )

      const nameSplit = approver.fullName.split(' ')

      if (!tempContact) {
        tempContact = {
          ...approver,
          firstName: nameSplit[0],
          lastName: nameSplit.length > 1 ? nameSplit[nameSplit.length - 1] : '',
          isInitialAuthorizer: true,
          approvalType: APPROVAL_TYPE.APPROVED,
        }
        tempContact.isPayer = approverPayAtClose
        newContacts?.push(tempContact)
      } else {
        tempContact = {
          ...tempContact,
          isInitialAuthorizer: true,
          fullName: approver?.fullName || '',
          firstName: nameSplit[0],
          lastName: nameSplit.length > 1 ? nameSplit[nameSplit.length - 1] : '',
          phone: approver?.phone || '',
          role: approver?.role || CONTACT_ROLE.OTHER,
          approvalType: APPROVAL_TYPE.APPROVED,
        }
        tempContact.isPayer = approverPayAtClose
        newContacts = newContacts?.map((contact: Contact) =>
          contact.email === tempContact?.email ? tempContact : contact
        )
      }
    }

    if (authorized) {
      tempContact = newContacts?.find(
        (contact) => contact.email === authorized.email
      ) as any
      newContacts = newContacts?.map((contact) =>
        contact.role === CONTACT_ROLE.HOMEOWNER
          ? { ...contact, approvalType: APPROVAL_TYPE.NOT_NEEDED }
          : contact
      )

      const nameSplit = authorized.fullName.split(' ')

      if (!tempContact) {
        tempContact = {
          ...authorized,
          firstName: nameSplit[0],
          lastName: nameSplit.length > 1 ? nameSplit[nameSplit.length - 1] : '',
          role: CONTACT_ROLE.HOMEOWNER,
          approvalType: directApprove
            ? APPROVAL_TYPE.APPROVED
            : APPROVAL_TYPE.REQUIRED,
        }
        tempContact.isPayer = ownerPayAtClose
        newContacts?.push(tempContact)
      } else {
        tempContact = {
          ...tempContact,
          fullName: authorized?.fullName,
          firstName: nameSplit[0],
          lastName: nameSplit.length > 1 ? nameSplit[nameSplit.length - 1] : '',
          phone: authorized?.phone,
          role: CONTACT_ROLE.HOMEOWNER,
          approvalType:
            directApprove ||
              tempContact?.approvalType === APPROVAL_TYPE.APPROVED
              ? APPROVAL_TYPE.APPROVED
              : APPROVAL_TYPE.REQUIRED,
        }
        tempContact.isPayer = ownerPayAtClose
        newContacts = newContacts?.map((contact: Contact) =>
          contact.email === tempContact?.email ? tempContact : contact
        )
      }
    }

    if (payer) {
      tempContact = newContacts?.find(
        (contact) => contact.email === payer.email
      ) as any
      newContacts = newContacts?.map((contact) => ({
        ...contact,
        isPayer: false,
      }))

      const nameSplit = payer.fullName.split(' ')

      if (!tempContact) {
        tempContact = {
          ...payer,
          firstName: nameSplit[0],
          lastName: nameSplit.length > 1 ? nameSplit[nameSplit.length - 1] : '',
          isPayer: true,
          approvalType: payerAtClose
            ? APPROVAL_TYPE.PAY_AT_CLOSE
            : directApprove
              ? APPROVAL_TYPE.APPROVED
              : APPROVAL_TYPE.REQUIRED,
          // role: payer.approvalType === APPROVAL_TYPE.REQUIRED ?
          //         (payer.role === CONTACT_ROLE.HOMEOWNER ? CONTACT_ROLE.OTHER : payer.role) :
          //         payer.role
        }
        newContacts?.push(tempContact)
      } else {
        tempContact = {
          ...tempContact,
          isPayer: true,
          fullName: payer?.fullName,
          firstName: nameSplit[0],
          lastName: nameSplit.length > 1 ? nameSplit[nameSplit.length - 1] : '',
          phone: payer?.phone,
          approvalType: payerAtClose
            ? APPROVAL_TYPE.PAY_AT_CLOSE
            : directApprove ||
              tempContact?.approvalType === APPROVAL_TYPE.APPROVED
              ? APPROVAL_TYPE.APPROVED
              : APPROVAL_TYPE.REQUIRED,
          // role: payer.approvalType === APPROVAL_TYPE.REQUIRED ?
          //         (payer.role === CONTACT_ROLE.HOMEOWNER ? CONTACT_ROLE.OTHER : payer.role) :
          //         payer.role
        }
        newContacts = newContacts?.map((contact: Contact) =>
          contact.email === tempContact?.email ? tempContact : contact
        )
      }
    }

    dispatch(
      estimateActions.updateEstimateProperty(
        { contacts: newContacts, payAtClose: payerAtClose },
        (succ: boolean, newProperties?: Partial<EstimateProperty>) => {
          if (directApprove) setSaveApproveLoading(false)
          else setSaveLoading(false)

          if (succ && directApprove) {
            dispatch(
              jobActions.createJob({
                estimateId: estimate?.id || '',
                properties: newProperties as JobProperties,
              })
            )
          }

          setOpen(false)
        }
      )
    )
  }

  return (
    <Modal setOpen={setOpen} open={open} title="Override Approval" size="xl" disableCloseOnClickOutside>
      <Grid container sx={{ padding: '12px 0' }} spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h5">Initial Approver</Typography>
        </Grid>
        <Grid item xs={6}>
          <TextFieldLabel
            label="Name"
            labelVariant="h6"
            value={initialApprover?.fullName || ''}
            required
            onChange={(e) =>
              setInitialApprover({
                ...initialApprover,
                fullName: e.target.value,
              } as Contact)
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldLabel
            label="Email"
            labelVariant="h6"
            value={initialApprover?.email || ''}
            required
            onChange={(e) =>
              setInitialApprover({
                ...initialApprover,
                email: e.target.value,
              } as Contact)
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldLabel
            label="Phone"
            labelVariant="h6"
            value={initialApprover?.phone || ''}
            required
            mask={PHONE_MASK_INPUT}
            placeholder="876-543-1223"
            onChange={(e) =>
              setInitialApprover({
                ...initialApprover,
                phone: e.target.value,
              } as Contact)
            }
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            label="Role"
            labelVariant="h6"
            onChange={(newValue) => {
              if (newValue.key === CONTACT_ROLE.HOMEOWNER)
                setHomeOwner(undefined)
              setInitialApprover({
                ...initialApprover,
                role: newValue.key,
              } as Contact)
            }}
            options={roleOptions}
            value={
              CONTACT_ROLE_OPTIONS.find(
                (option) => option.key === initialApprover?.role
              )?.label || ''
            }
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            label="Paying Party"
            control={
              <Checkbox
                checked={approverPayAtClose}
                // disabled={!initialApprover?.email}
                onChange={() => {
                  setApproverPayAtClose(!approverPayAtClose)
                  if (initialApprover?.role === CONTACT_ROLE.HOMEOWNER)
                    setOwnerPayAtClose(!approverPayAtClose)

                  if (initialApprover?.email !== homeOwner?.email)
                    setOwnerPayAtClose(false)

                  if (!approverPayAtClose) {
                    setPayer(initialApprover)
                    setPayerAtClose(false)
                  }
                }}
              />
            }
          />
        </Grid>
      </Grid>
      <Box hidden={initialApprover?.role === CONTACT_ROLE.HOMEOWNER}>
        <Grid container sx={{ padding: '12px 0' }} spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h5">Homeowner</Typography>
          </Grid>
          <Grid item xs={6}>
            <TextFieldLabel
              label="Name"
              labelVariant="h6"
              value={homeOwner?.fullName || ''}
              onChange={(e) =>
                setHomeOwner({
                  ...homeOwner,
                  fullName: e.target.value,
                } as Contact)
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldLabel
              label="Email"
              labelVariant="h6"
              value={homeOwner?.email || ''}
              onChange={(e) =>
                setHomeOwner({
                  ...homeOwner,
                  email: e.target.value,
                } as Contact)
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldLabel
              label="Phone"
              labelVariant="h6"
              value={homeOwner?.phone || ''}
              mask={PHONE_MASK_INPUT}
              placeholder="876-543-1223"
              onChange={(e) =>
                setHomeOwner({
                  ...homeOwner,
                  phone: e.target.value,
                } as Contact)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              label="Paying Party"
              control={
                <Checkbox
                  checked={ownerPayAtClose}
                  // disabled={!homeOwner?.email}
                  onChange={() => {
                    setOwnerPayAtClose(!ownerPayAtClose)

                    if (initialApprover?.email !== homeOwner?.email)
                      setApproverPayAtClose(false)
                    if (!ownerPayAtClose) {
                      setPayer(homeOwner)
                      setPayerAtClose(false)
                    }
                  }}
                />
              }
            />
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container sx={{ padding: '12px 0' }} spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h5">Payer</Typography>
          </Grid>
          <Grid item xs={6}>
            <TextFieldLabel
              label="Name"
              labelVariant="h6"
              value={payer?.fullName || ''}
              onChange={(e) =>
                setPayer({
                  ...payer,
                  fullName: e.target.value,
                } as Contact)
              }
              disabled={approverPayAtClose || ownerPayAtClose}
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldLabel
              label="Email"
              labelVariant="h6"
              value={payer?.email || ''}
              onChange={(e) =>
                setPayer({
                  ...payer,
                  email: e.target.value,
                } as Contact)
              }
              disabled={approverPayAtClose || ownerPayAtClose}
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldLabel
              label="Phone"
              labelVariant="h6"
              value={payer?.phone || ''}
              mask={PHONE_MASK_INPUT}
              placeholder="876-543-1223"
              onChange={(e) =>
                setPayer({
                  ...payer,
                  phone: e.target.value,
                } as Contact)
              }
              disabled={approverPayAtClose || ownerPayAtClose}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              label="Pay at Close"
              control={
                <Checkbox
                  checked={payerAtClose}
                  // disabled={!homeOwner?.email}
                  onChange={() => {
                    setPayerAtClose(!payerAtClose)

                    if (!payerAtClose) {
                      setOwnerPayAtClose(false)
                      setApproverPayAtClose(false)
                    }
                  }}
                />
              }
            />
          </Grid>
        </Grid>
      </Box>
      <Grid container sx={{ padding: '12px 0' }} spacing={1}>
        <Grid item xs={3.5}>
          <Button
            variant="containedError"
            fullWidth
            color="error"
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item xs={3.5}>
          <Button
            variant="containedGreen"
            fullWidth
            color="primary"
            endIcon={
              saveLoading ? (
                <CircularProgress color="inherit" size={18} />
              ) : null
            }
            onClick={() => {
              setSaveLoading(true)
              handleSave({ directApprove: false })
            }}
            disabled={!isValid}
            className={isValid ? '' : 'Mui-disabled'}
            style={!isValid ? {
              backgroundColor: 'rgba(0, 0, 0, 0.12)'
            } : {}}
          >
            Save
          </Button>
        </Grid>
        <Grid item xs={5}>
          <Button
            variant="containedGreen"
            fullWidth
            color="success"
            endIcon={
              saveApproveLoading ? (
                <CircularProgress color="inherit" size={18} />
              ) : null
            }
            onClick={() => {
              setSaveApproveLoading(true)
              setApprovalOpen(true)
            }}
            disabled={!isValid}
            style={!isValid ? {
              backgroundColor: 'rgba(0, 0, 0, 0.12)'
            } : {}}
          >
            Save & Approve
          </Button>
        </Grid>
      </Grid>
      <ModalConfirm
        open={approvalOpen}
        setOpen={setApprovalOpen}
        contentText="Do you want to approve this estimate?"
        action={() => {
          handleSave({ directApprove: true })
        }}
      />
    </Modal>
  )
}

export default ModalOverrideApproval
