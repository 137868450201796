import axios from '../axios'
import { ApiController } from './types'

const base = '/users'

const apiController: ApiController = {
  getUsersStaff: async (role) =>
    await axios
      .get(`${base}/staff?role=${role}`)
      .then(response => response.data),
}

export default apiController
