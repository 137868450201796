// NoContactsBox.js
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styles from './styles.module.scss';

const NoContactsBox = () => {
    return (
        <Box className={styles.boxContainer}>
            <Typography className={styles.text}>
                No contacts added to this Estimate, please add at least one contact
            </Typography>
        </Box>
    );
};

export default NoContactsBox;

