import { FC, useEffect, useRef, useState } from 'react'
import { icons, noProfileImage, upload2, uploadPhoto } from '../../../../../assets'
import { Box, Typography, Avatar, CircularProgress } from '../../..'
import { ProfilePictureProps } from './types'
import useStyles from './styles'

import env from '@beam-australia/react-env'
import { ParseFilesUrl } from '../../../../../helpers'
const FILES_URL = env('FILES_URL') ?? ''

const ProfilePicture: FC<ProfilePictureProps> = ({
  avatar,
  onUpload,
  uploading = false
}) => {
  const classes = useStyles()

  const inputRef = useRef<HTMLInputElement | null>(null)

  const hasAvatar: Boolean = avatar !== null

  const [loading, setLoading] = useState(true)
  const [attempts, setAttempts] = useState(5)
  const [error, setError] = useState(false)

  useEffect(() => {
    setLoading(false)
  }, [])

  const selectFile = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.click()
    }
  }

  const handleUpload = (event: any): void => {
    if (onUpload) {
      setAttempts(5)
      setError(false)
      onUpload(event.target.files[0])
    }
  }

  const handleError = () => {
    if (attempts > 0 && avatar) {
      setAttempts(attempts - 1)
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
      }, 1000)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const getPicture = (): JSX.Element => {
    if (uploading || loading) {
      return (
        <Box className={classes.picture}>
          <CircularProgress color='inherit' className={classes.loading} />
        </Box>
      )
    }
    if (hasAvatar && !error) {
      return (
        <img
          onError={handleError}
          alt=''
          src={ParseFilesUrl({ baseUrl: FILES_URL, avatar })}
          className={classes.picture}
        />
      )
    }
    return (
      <img src={noProfileImage} />
    )
  }

  return (
    <div className={classes.root}>
      {getPicture()}
      {!uploading && !loading && (
        <Box className={classes.actionButton} onClick={selectFile}>
          {hasAvatar ? <icons.CameraAlt /> : <img src={upload2} alt='' />}
          <Typography>{hasAvatar ? 'Change Photo' : 'Upload Photo'}</Typography>
        </Box>
      )}
      {!uploading && !loading && hasAvatar && (
        <Box className={classes.actionButton} onClick={selectFile} sx={{ width: '100%', height: '100%' }}>
        </Box>
      )}
      <input
        accept='image/*'
        style={{ display: 'none' }}
        ref={ref => (ref ? (inputRef.current = ref) : null)}
        type='file'
        onChange={handleUpload}
      />
    </div>
  )
}

export default ProfilePicture
