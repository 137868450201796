import { FC } from 'react'
import ColumnLane from './ColumnLane'
import { ORDER_STATUS } from 'src/helpers'
import { Box, Grid, Typography } from 'src/components/UI'
import { ColumnLaneProps } from './ColumnLane/types'

import useStyles from './styles'
import { useSelector } from 'react-redux'
import { getJobsFilter } from 'src/ducks/filters/selector'

const ColumnBoard: FC = () => {
  const styles = useStyles()

  const jobsFilter = useSelector(getJobsFilter())

  const searchParamArray: ColumnLaneProps[] = [
    {
      stage: 'Scheduling',
      searchParams: {
        statuses: [ORDER_STATUS.APPROVED],
      },
      headerBackground: '#E5F7FF',
      laneBackground: '#FAFDFF'
    },
    {
      stage: 'Pending',
      searchParams: {
        statuses: [ORDER_STATUS.PENDING],
      },
      headerBackground: '#F6FDE2',
      laneBackground: '#FCFEF5'
    },
    {
      stage: 'Dispatched',
      searchParams: {
        statuses: [ORDER_STATUS.DISPATCHED],
      },
      headerBackground: '#D6DFFF',
      laneBackground: '#FAFBFF'
    },
    {
      stage: 'In Progress',
      searchParams: {
        statuses: [ORDER_STATUS.IN_PROGRESS],
      },
      headerBackground: '#D6DFFF',
      laneBackground: '#FAFBFF'
    },
    {
      stage: 'Review',
      searchParams: {
        statuses: [ORDER_STATUS.REVIEW],
      },
      headerBackground: '#EBECEF',
      laneBackground: '#F9FAFA'
    },
    {
      stage: 'Complete',
      searchParams: {
        statuses: [ORDER_STATUS.COMPLETE],
      },
      headerBackground: '#D9E0F2',
      laneBackground: '#FAFDFF'
    },
    {
      stage: 'Paid',
      searchParams: {
        statuses: [ORDER_STATUS.PAID],
      },
      headerBackground: '#FFE5A3',
      laneBackground: '#FFFBF0'
    },
    {
      stage: 'On Hold',
      searchParams: {
        statuses: [ORDER_STATUS.ON_HOLD],
      },
      headerBackground: '#E3E0FF',
      laneBackground: '#F1F0FF',
    },
    {
      stage: 'Parent Review',
      searchParams: {
        statuses: [ORDER_STATUS.PARENT_REVIEW],
      },
      headerBackground: '#D2F9E9',
      laneBackground: '#EDFDF6'
    },
  ]

  return (
    <Grid container className={styles.container}>
      {(!jobsFilter.territory || jobsFilter.territory.label === 'All Territories') && (
        <Box className={styles.banner}>
          Please select a Territory to see status.
        </Box>
      )}
      <Box className={styles.row}>
        {searchParamArray.map((prop, index) => (
          <ColumnLane key={index} {...prop} />
        ))}
      </Box>
    </Grid>
  )
}

export default ColumnBoard
