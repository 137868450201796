import type { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo } from '../../helpers'
import { Punchlist } from '../../api'

import { inspectionCompanyActions } from '../actions'
import { FetchInspectionCompanyAction, inspectionCompanyTypes } from './types'

export function* fetchInspectionCompany({
  payload,
  callback,
}: FetchInspectionCompanyAction): SagaIterator {
  let success = false
  try {
    const inspectionCompany = yield call(
      Punchlist.inspectionCompanies.getInspectionCompanyById,
      payload
    )
    const inspectors = yield call(
      Punchlist.inspectionCompanies.getInspectionCompanyInspectors,
      payload
    )

    if (inspectionCompany) {
      const data: any = { ...inspectionCompany }
      if (inspectors) {
        data.inspectors = inspectors
      }
      yield put(inspectionCompanyActions.setInspectionCompany(data))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    console.log({ error })
    yield call(toast.error, errorTextTryingTo('get the inspection companies'))
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(
    inspectionCompanyTypes.FETCH_INSPECTION_COMPANY,
    fetchInspectionCompany
  )
}
