import { RequestPayload } from "../companies/types"
import { Widget } from "../types"

/** TYPES **/
export const widgetsTypes = {
  FETCH_WIDGETS: 'FETCH_WIDGETS',
  SET_WIDGETS: 'SET_WIDGETS',
  CREATE_WIDGET: 'CREATE_WIDGET',
}

/** ACTIONS **/
export interface FetchWidetsAction {
  type: typeof widgetsTypes.FETCH_WIDGETS
  payload: null,
  callback?: (succ: boolean) => void
}

export interface SetWidgetsAction {
  type: typeof widgetsTypes.SET_WIDGETS
  payload: WidgetsType
}

export interface CreateWidgetAction {
  type: typeof widgetsTypes.CREATE_WIDGET
  payload: Partial<Widget>
  callback?: (succ: boolean) => void
}

export type WidgetsActions =
  | FetchWidetsAction
  | SetWidgetsAction
  | CreateWidgetAction

/** REDUCER **/

/* export type WidgetsType = {
  start: number
  limit: number
  total: number
  items: Widget[]
} */

export type WidgetsType = Widget[]

/* export interface ItemsType {
  items: Item[]
  itemsByCategory: ItemByCategory[]
}
 */