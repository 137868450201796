/* eslint-disable @typescript-eslint/no-unused-vars */
import storeInfo from '../store'
import { OrderType } from '../orders/types'
import { Invoice } from './types'
import { InvoiceType } from '~api/types'

export const getInvoicePreview = () => (): InvoiceType => {
  const { invoice: { invoicePreview } } = storeInfo.store.getState()
  return invoicePreview
}

export const getInvoicePDFPath = () => (): string => {
  const { invoice: { invoicePath } } = storeInfo.store.getState()
  return invoicePath
}