import { UpdateRequest } from '~api/types'
import { InspectorCompany } from '../types'

/** TYPES **/
export const inspectorsTypes = {
  FETCH_INSPECTOR: 'FETCH_INSPECTOR',
  SET_INSPECTORS: 'SET_INSPECTORS',
  CREATE_INSPECTOR: 'CREATE_INSPECTOR',
  UPDATE_INSPECTOR: 'UPDATE_INSPECTOR',
}

/** ACTIONS **/

export interface FetchInspectorsAction {
  type: typeof inspectorsTypes.FETCH_INSPECTOR
  payload?: null
  callback?: (succ: boolean) => void
}
export interface CreateInspectorAction {
  type: typeof inspectorsTypes.CREATE_INSPECTOR
  payload: Partial<InspectorCompany>
  callback?: (succ: boolean) => void
}

export interface SetInspectorsAction {
  type: typeof inspectorsTypes.SET_INSPECTORS
  payload: InspectorsType
}

export interface UpdateInspectorAction {
  type: typeof inspectorsTypes.UPDATE_INSPECTOR
  payload: {
    id: string
    request: UpdateRequest
  }
  callback?: (succ: boolean) => void
}

export type InspectorsActions =
  | CreateInspectorAction
  | UpdateInspectorAction
  | FetchInspectorsAction
  | SetInspectorsAction

/** REDUCER **/
export type InspectorsType = InspectorCompany[] | null
