import { Box, Button, Typography } from '@mui/material'
import { Link } from 'src/components/UI'
import {
  HeaderType,
  AlignType,
} from 'src/components/UI/CustomUI/organisms/Table/types'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'

const headers: HeaderType[] = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'address',
    label: 'Office Address',
    custom: true,
    align: 'left' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Box padding="2px 10px">
          <Typography>{row?.address?.fullAddress}</Typography>
        </Box>
      )
    },
  },
]

export default headers
