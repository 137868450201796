import { Button as MaterialButton } from 'src/components/UI'
import { ButtonProps } from '@mui/material'

const Button = ({
  children = '',
  isActive = false,
  onToggle = () => { },
  onLoad = undefined,
  sx
}: {
  children?: string,
  isActive?: boolean,
  onToggle?: () => void,
  onLoad?: React.ReactEventHandler<HTMLButtonElement> | undefined,
  sx?: ButtonProps['sx'];
}) => {
  return (
    <MaterialButton
      variant={isActive ? 'toggleContainedDark' : 'toggle'}
      onClick={onToggle}
      sx={[
        {
          minWidth: '0px',
          height: '28px',
        },
        ...(Array.isArray(sx) ? sx : [sx]),]}
      onLoad={onLoad}
    >
      {children}
    </MaterialButton>
  )
}

export default Button
