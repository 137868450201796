import { ModalProps } from 'src/components/UI/CustomUI/molecules/Modal/types'

export interface ModalsProps extends ModalProps {
  modalType?: JobModalTypes
  setLoading?: (loading: boolean) => void
}

export enum JobModalTypes {
  CLEAR_JOB = 'CLEAR_JOB',
  COMPLETE_JOB = 'COMPLETE_JOB',
  REOPEN_JOB = 'REOPEN_JOB',
  MATERIAL_INVOICES = 'MATERIAL_INVOICES',
  REQUEST_CHANGE_ORDER_APPROVAL = 'REQUEST_CHANGE_ORDER_APPROVAL',
  IMPORT_FROM_CSV = 'IMPORT_FROM_CSV',
  OVERRIDE_APPROVAL = 'OVERRIDE_APPROVAL',
  SUBMIT_FOR_REVIEW = 'SUBMIT_FOR_REVIEW',
  SUBMIT_FOR_RELEASE = 'SUBMIT_FOR_RELEASE',
  BACK_TO_BUILDER = 'BACK_TO_BUILDER',
  BACK_TO_BUILDER_RESET = 'BACK_TO_BUILDER_RESET',
  ASSIGN_TO_ME = 'ASSIGN_TO_ME',
  ARCHIVE = 'ARCHIVE',
  EXCLUDE_FROM_REPORT = 'EXCLUDE_FROM_REPORT',
  OCR_REPROCESS = 'OCR_REPROCESS',
  AUTOMATCH_ESTIMATE = 'AUTOMATCH_ESTIMATE',
}
