import { Vendor, Insurance } from '../types'
import { VendorActions, vendorTypes, VendorValue } from './types'

const actions = {
  fetchVendor: (payload: string, callback?: (succ: boolean) => void): VendorActions => ({
    type: vendorTypes.FETCH_VENDOR,
    payload,
    callback
  }),
  createQuickbooksId: (payload: string, callback?: (succ: boolean) => void): VendorActions => ({
    type: vendorTypes.CREATE_QBID,
    payload,
    callback
  }),
  setVendor: (payload: Vendor | null): VendorActions => ({
    type: vendorTypes.SET_VENDOR,
    payload
  }),
  setVendorValue: (payload: VendorValue): VendorActions => ({
    type: vendorTypes.SET_VENDOR_VALUE,
    payload
  }),
  updateVendor: (
    payload: Partial<Vendor>,
    callback?: (succ: boolean) => void
  ): VendorActions => ({
    type: vendorTypes.UPDATE_VENDOR,
    payload,
    callback
  }),
  addVendorList: (
    payload: Partial<Vendor>,
    list: keyof Vendor,
    callback?: (succ: boolean) => void
  ): VendorActions => ({
    type: vendorTypes.ADD_VENDOR_LIST,
    payload,
    list,
    callback
  }),
  updateVendorList: (
    payload: Partial<Insurance>,
    id: number,
    list: string,
    callback?: (succ: boolean) => void
  ): VendorActions => ({
    type: vendorTypes.UPDATE_VENDOR_INSURANCE,
    payload,
    id,
    list,
    callback
  }),
  deleteVendorInsurance: (
    payload: Partial<any>,
    id: number,
    isInsurance: boolean,
    callback?: (succ: boolean) => void
  ): VendorActions => ({
    type: vendorTypes.DELETE_VENDOR_INSURANCE,
    payload,
    id,
    isInsurance,
    callback
  }),
}

export default actions
