import { Theme } from '../../../UI'
import { makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => ({
    container: {
        minWidth: 'calc(100vw - 48px)',
        [theme.breakpoints.down('lg')]: {
            maxWidth: 'calc(100vw - 48px) !important'
        }
    },
    filters: {
        flexWrap: 'wrap',
        [theme.breakpoints.down('lg')]: {
            minWidth: 'calc(100vw - 48px)',
        },
        '@global': {
            '> .MuiBox-root': {
                [theme.breakpoints.down('lg')]: {
                    width: '20%',
                },
            }
        }
    },
    filters_actions: {
        /* [theme.breakpoints.down('lg')]: {
            justifyContent: 'flex-end !important',
            width: '100%',
            gap: '8px'
        }*/

    },
    table: {
        maxWidth: '100% !important',
        overflowX: 'scroll'
    }
}))
