import { AlignType } from 'src/components/UI/CustomUI/organisms/Table/types'
import { Box, Button, Typography } from '@mui/material'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'

// HeaderType[]
const headers = (dispatchContext: React.Dispatch<any>) => {
  return [
    {
      id: 'fieldManager',
      label: 'Field Managers',
      custom: true,
      Element: (row: any): JSX.Element => {
        return (
          <Typography> {`${row.firstName} ${row.lastName}`} </Typography>
        )
      }
    },
    {
      id: 'action',
      label: 'Action',
      custom: true,
      maxWidth: 50,
      align: 'center' as AlignType,
      Element: (row: any): JSX.Element => {
        if (row.selected) {
          return (
            <Box
              display='flex' justifyContent='center'
              onClick={() => { dispatchContext({ type: 'SET_SELECTED_FIELD_MANAGER', payload: null }) }}
              sx={{ cursor: 'pointer' }}
            >
              <Typography color='var(--blue700)'> Selected </Typography>
              <Icon sx={{ marginLeft: '.2rem' }} width=".8rem" name='CheckCircle' color='var(--blue700)' />
            </Box>)
        }
        return (
          <Button
            variant='contained'
            size='small'
            sx={{ height: '32px' }}
            onClick={() => { dispatchContext({ type: 'SET_SELECTED_FIELD_MANAGER', payload: row }) }}
          >
            Select
          </Button>
        )
      }
    },
  ]
}

export default headers
