import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Table from 'src/components/UI/CustomUI/organisms/Table'
import { historyActions } from 'src/ducks/actions'
import { getJobHistory } from 'src/ducks/history/selectors'
import { getJob } from 'src/ducks/selectors'
import { ROWS_PER_PAGE_SIMPLE } from 'src/helpers'
import headers from './headers'

const Overall = ({ id }: { id?: string }) => {
  const dispatch = useDispatch()
  
  const jobHistory = useSelector(getJobHistory)
  const job = useSelector(getJob())
  
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(50)
  const [historyRecords, setHistoryRecords] = useState(jobHistory)

  useEffect(() => {
    updatePage()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, size, jobHistory])

  const updatePage = () => {
    const start = page * size
    const end = start + size > jobHistory.length ? jobHistory.length : start + size

    setHistoryRecords(jobHistory.slice(start, end))
  }

  const fetchJobHIstory = useCallback(() => {
    if (id) {
      dispatch(
        historyActions.fetchHistoryJob(id, (_succ: boolean) => {
          setLoading(false)
        })
      )
    }
  }, [dispatch, id])

  useEffect(() => {
    fetchJobHIstory()
  }, [fetchJobHIstory, job])

  return (
    <Table
      headers={headers}
      title="OVERALL"
      loading={loading}
      totalItems={jobHistory.length}
      rowsPerPageDefault={50}
      rowsPerPageOptions={ROWS_PER_PAGE_SIMPLE}
      callToApi={(start, limit) => {
        setPage(start)
        setSize(limit)
      }}
      hasTopPagination
      hasPagination={false}
    >
      {historyRecords}
    </Table>
  )
}

export default Overall;