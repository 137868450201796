import { Theme } from '@mui/material'
import { AvailableCustomColors } from '../themeExtension'

declare module '@mui/material/ButtonGroup' {
  export interface ButtonGroupPropsColorOverrides extends AvailableCustomColors { }
}

export const buttonGroup = {
  MuiButtonGroup: {
    styleOverrides: {
      root: ({ theme }: { theme: Theme }) => ({
        '> button': {
          width: '72px',
          heigth: '42px',
          boxShadow: 'none',
          marginRight: '-1px',
          padding: '0.5rem 0',
          borderRadius: '8px',
          '&:hover': {
            boxShadow: 'none'
          }
        },
        '& .MuiButton-contained': {
          border: `2px solid ${theme.palette.navy[700]}`,
          backgroundColor: theme.palette.navy[700]
        },
        '& .MuiButton-outlined': {
          color: theme.palette.navy[700],
          border: `2px solid ${theme.palette.navy[700]}`,
          '&:hover': {
            border: `2px solid ${theme.palette.navy[700]}`,
            backgroundColor: theme.palette.navy[700]
          }
        }
      })
    }
  }
}
