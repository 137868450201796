import { Grid, Typography } from '@mui/material'
import { Comment } from 'src/ducks/comments/types'
import { dateFormatString, formatTimestampDate } from 'src/helpers'

import styles from './styles.module.scss'

const CommentItem = ({ comment }: { comment: Comment }) => {
  // hidding resolve function for now, it's not on the api yet
  // const resolve = false

  return (
    <Grid className={styles.Comment}>
      <Grid item container xs={12} className={styles.Comment__box /* resolve ? styles.Comment__box : styles.Comment__boxGreen */}>
        <Grid item container xs={12} justifyContent='space-between'>
          <Typography marginLeft='.5rem' justifyContent='flex-start' width='auto'>{comment?.message}</Typography>
          {/* <Grid item container xs={2} sx={{ justifyContent: 'flex-end' }}>
            <Typography marginLeft='.5rem' color={resolve ? 'var(--blue700)' : 'var(--green900)'}>{resolve ? 'Resolve' : 'Resolved'}</Typography>
            {!resolve && <CheckCircleIcon color='success' sx={{ margin: '3px 5px', height: '10px', width: '10px', justifyContent: 'flex-end' }} />}
          </Grid> */}
        </Grid>
      </Grid>
      <Grid container item direction='column' marginLeft='.5rem'>
        <Typography color='var(--gray700)' lineHeight='1.3' variant='caption'>{comment?.sender?.name} - {dateFormatString(formatTimestampDate(comment?.insertedOn), 'MMM D, YYYY LT')}</Typography>
        {/* !resolve && <Typography color='var(--gray700)' lineHeight='1.3' variant='caption'>{'Resolved by ' + comment?.sender?.name} - {dateFormatString(formatTimestampDate(comment?.insertedOn), 'MMM D, YYYY LT')}</Typography> */}
      </Grid>
    </Grid>
  )
}

export default CommentItem
