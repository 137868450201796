import { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import { Punchlist } from '../../api'
import { FetchMatchesAction, matchesTypes } from './types'
import { matchesActions } from '../actions'
import { toast } from 'react-toastify'
import { errorTextTryingTo } from 'src/helpers'

export function *fetchMatches({
  payload,
  callback,
}: FetchMatchesAction): SagaIterator {
  let success = true

  try {
    const estimateId = payload

    const matches = yield call(Punchlist.matches.getEstimateMatches, estimateId)

    if (matches) {
      yield put(matchesActions.setMatches(matches))
    }

    success = true

    if (callback) yield call(callback, success)
  } catch (err) {
    yield call(toast.error, errorTextTryingTo('get the matches'))

    if (callback) yield call(callback, false)
  }
}

export function *runAutomatch({
  payload,
  callback,
}: FetchMatchesAction): SagaIterator {
  let success = true

  try {
    const estimateId = payload

    yield call(Punchlist.matches.runEstimateAutomatch, estimateId)

    const matches = yield call(Punchlist.matches.getEstimateMatches, estimateId)

    if (matches) {
      yield put(matchesActions.setMatches(matches))
    }

    success = true

    if (callback) yield call(callback, success)
  } catch (err) {
    yield call(toast.error, errorTextTryingTo('run the automatch'))

    if (callback) yield call(callback, false)
  }
}

export default function *saga(): SagaIterator {
  yield takeLatest(matchesTypes.FETCH_MATCHES, fetchMatches)
  yield takeLatest(matchesTypes.RUN_AUTOMATCH, runAutomatch)
}
