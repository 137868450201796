import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { icons, narLogo } from 'src/assets'
import TabNavigation from 'src/components/UI/CustomUI/organisms/TabNavigation'
import { getJob } from 'src/ducks/selectors'
import { STATUS } from 'src/helpers'
import { Box, BoxContainer, Grid, LabeledText, TitleFirst } from '../../../../UI'
import {
  Approval, HouseDetails, JobContact, JobInfo,
  PLContact, PropertyAccess, Totals
} from './Tabs'
import HouseImages from '../../EstimatesView/EstimateTopInfo/Tabs/HouseImages'
import StatusLabel from 'src/components/UI/CustomUI/atoms/StatusLabel/Status'

const JobTopInfo: FC<{ loading: boolean }> = ({ loading }) => {
  const job = useSelector(getJob())
  const isNar = job?.properties?.nar
  const [status, setStatus] = useState(job?.status)
  useEffect(() => {
    setStatus(job?.status)
  }, [job])

  const General = [
    { label: 'Job Timeline', content: <JobInfo /> },
    { label: 'Information & Access', content: <PropertyAccess /> },
    { label: 'House Images', content: <HouseImages /> },
  ]

  const Contacts = [
    { label: 'Contact', content: <PLContact /> },
    { label: 'Job Contact', content: <JobContact /> },
    { label: 'Approval', content: <Approval /> }
  ]

  const Financials = [
    { label: 'Totals', content: <Totals /> },
    { label: 'Timelines', content: <HouseDetails /> }
  ]

  return (
    <Box display='flex' flexDirection='row' gap={2} justifyContent='space-between'>
      <BoxContainer
        sx={{ width: '100%', height: '250px', justifyContent: 'flex-start' }}
        title={
          <TitleFirst
            alignItems='center' black='General' blackVariant='h5' lineHeight='24px'
            rightIcon={status === STATUS.SCHEDULING
              ? <icons.AccessTime color='infoText' height='0.813rem' width='0.813rem' />
              : <icons.CheckCircle color='success' height='0.813rem' width='0.813rem' />}
          />
        }
        secondTitle={
          <Grid container style={{ flexWrap: 'nowrap' }}>
            <LabeledText textVariant='h5' labelValue='#' sx={{ paddingTop: '5px' }} textValue={job?.properties.publicId} showCopyButton={true} />
            <StatusLabel status={job?.properties?.archived ? 'ARCHIVED' : ''} />
          </Grid>
        }
        loading={loading}
      >
        <TabNavigation tabs={General} variant='contained' margin='0 0 0.5rem' />
      </BoxContainer>
      <BoxContainer
        sx={{ width: '100%', height: '250px', justifyContent: 'flex-start' }}
        title={<TitleFirst alignItems='center' black='Contacts' blackVariant='h5' lineHeight='24px' />}
        loading={loading}
      >
        <TabNavigation tabs={Contacts} variant='contained' margin='0 0 0.5rem' />
      </BoxContainer>
      <BoxContainer
        sx={{ width: '100%', height: '250px', justifyContent: 'flex-start' }}
        title={
          <TitleFirst
            alignItems='center'
            black='Financials'
            blackVariant='h5'
            rightIcon={isNar ? <img src={narLogo} /> : ''}
            lineHeight='24px' />}
        loading={loading}
      >
        <TabNavigation tabs={Financials} variant='contained' margin='0 0 0.5rem' />
      </BoxContainer>
    </Box >
  )
}

export default JobTopInfo
