import { Theme } from '../../../../../UI'
import { makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => ({
  content: {
    padding: '12px',
  },
  title: {
    fontFamily: 'Nouvelle !important',
    fontStyle: 'normal !important',
    fontWeight: '600 !important',
    fontSize: '16px !important',
    lineHeight: '24px !important',
    color: '#1A2A55 !important',
    marginBottom: '4px !important',
  },
  saveButton: {
    width: '120px',
    height: '40px',
    fontStyle: 'normal !important',
    fontWeight: '400 !important',
    fontSize: '16px !important',
    lineHeight: '24px !important',
    padding: '8px 16px !important',
    borderWidth: '0px !important',
  },
  closeButton: {
    width: '120px',
    height: '40px',
    borderWidth: 0,
    borderRadius: '32px !important',
    backgroundColor: '#FAFBFF !important',
    fontStyle: 'normal !important',
    fontWeight: '400 !important',
    fontSize: '16px !important',
    lineHeight: '24px !important',
    '&:hover': {
      color: '494A5A',
    },
  },
}))
