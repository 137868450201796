import { FC } from 'react'
import { Box, Typography } from '@mui/material'
import { StatsProps } from './types'

const Stats: FC<StatsProps> = ({ data }) => {
  return (
    <Box>
      <Typography
        fontFamily='NouvelleBold'
        fontSize='1.125rem'
        paddingTop='0.50rem'
      >
        Stats:
      </Typography>
      {
        Object.keys(data).map((key, index) => (
          <Box key={index} display='flex' flexDirection='row' justifyContent='space-between'>
            <Typography color='GrayText' fontSize='1rem' paddingTop='0.5rem'>
              {`${data[key].label}:`}
            </Typography>
            <Typography fontSize='1rem' paddingTop='0.5rem'>
              {`${data[key].value}`}
            </Typography>
          </Box>
        ))
      }
    </Box>
  )
}

export default Stats
