/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo, isEmpty } from '../../helpers'
import { Punchlist } from '../../api'
import { homemartTypes, FetchPartnersAction, CreatePartnerAction, UpdatePartnerAction, DeletePartnerAction } from './types'
import { homemartActions } from '../actions'
import { UpdateRequest } from '~api/types'
import { Partner } from '../types'

export function* fetchPartners({ payload, callback }: FetchPartnersAction): SagaIterator {
  let success = false
  try {
    const homemart = yield call(Punchlist.homemart.getPartners, payload)

    if (homemart) {
      yield put(homemartActions.setHomemart({ partners: homemart }))
    }

    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the partners'))
    if (callback) yield call(callback, false)
  }
}

export function* fetchPartnerTags({ payload, callback }: FetchPartnersAction): SagaIterator {
  let success = false
  try {
    const tags = yield call(Punchlist.homemart.getPartnerTags)

    if (tags) {
      yield put(homemartActions.setHomemart({ tags }))
    }

    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the partner tags'))
    if (callback) yield call(callback, false)
  }
}

export function* fetchPartnerCategories({ payload, callback }: FetchPartnersAction): SagaIterator {
  let success = false
  try {
    const categories = yield call(Punchlist.homemart.getPartnerCategories)

    if (categories) {
      yield put(homemartActions.setHomemart({ categories }))
    }

    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the partner categories'))
    if (callback) yield call(callback, false)
  }
}

export function* createPartner({ payload, callback }: CreatePartnerAction): SagaIterator {
  let success = false
  try {
    yield call(Punchlist.homemart.addPartner, payload)
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo(error.response.data.message))
    if (callback) yield call(callback, false)
  }
}

export function* updatePartner({ payload, callback }: UpdatePartnerAction): SagaIterator {
  let success = false

  const request: UpdateRequest = []
  for (const attr in (payload as Partial<Partner>)) {
    if (attr !== 'id') {
      request.push({
        op: 'replace',
        path: '/' + attr,
        value: payload[attr as keyof Partner]
      })
    }
  }

  try {
    yield call(Punchlist.homemart.editPartner, payload.id || '', request)
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo(error.response.data.message))
    if (callback) yield call(callback, false)
  }
}

export function* deletePartner({ payload, callback }: DeletePartnerAction): SagaIterator {
  let success = false

  const request: UpdateRequest = []
    request.push({
      op: 'replace',
      path: '/active',
      value: false
    })

  try {
    yield call(Punchlist.homemart.editPartner, payload, request)
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('delete a partner'))
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(homemartTypes.FETCH_PARTNERS, fetchPartners)
  yield takeLatest(homemartTypes.CREATE_HOMEMART, createPartner)
  yield takeLatest(homemartTypes.UPDATE_HOMEMART, updatePartner)
  yield takeLatest(homemartTypes.DELETE_HOMEMART, deletePartner)
  yield takeLatest(homemartTypes.FETCH_PARTNER_CATEGORIES, fetchPartnerCategories)
  yield takeLatest(homemartTypes.FETCH_PARTNER_TAGS, fetchPartnerTags)
}
