import { Theme } from '../../../UI'
import { makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => ({
    container: {
        minWidth: 'calc(100vw - 48px)'
    },
    filters: {
        flexWrap: 'wrap',
        [theme.breakpoints.down('lg')]: {
            minWidth: 'calc(100vw - 48px)'
        },
        '@global': {
            '> .MuiBox-root': {
                [theme.breakpoints.down('lg')]: {
                    width: '49%'
                }
            }
        }
    },
    filters_actions: {
        [theme.breakpoints.down('lg')]: {
            justifyContent: 'flex-end !important',
            width: '100%',
            gap: '8px'
        }

    },
    switch: {
        width: '28px !important',
        height: '22px !important',
        padding: '2px 0px !important',
        // margin: '6px 5px',
        alignSelf: 'center',
        display: 'flex !important',
        '&:active': {
            '& .MuiSwitch-thumb': {
                width: 15
            },
            '& .MuiSwitch-switchBase.Mui-checked': {
                transform: 'translateX(9px)'
            }
        },
        '& .MuiSwitch-switchBase': {
            padding: 2,
            top: -16,
            '&.Mui-checked': {
                transform: 'translateX(12px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    opacity: 1,
                    backgroundColor: 'var(--better-color)'
                }
            }
        },
        '& .MuiSwitch-thumb': {
            boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
            width: 12,
            height: 12,
            borderRadius: 6,
            transition: theme.transitions.create(['width'], {
                duration: 200
            })
        },
        '& .MuiSwitch-track': {
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: 'rgba(0,0,0,.25)',
            boxSizing: 'border-box'
        }
    }
}))
