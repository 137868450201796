import storeInfo from '../store'
import { orderBy as SortBy } from 'lodash'
import { Client, Email } from '../types'
import { formatPhone } from 'src/helpers'
import { StatusBoxProps } from 'src/components/UI/CustomUI/molecules/StatusBox/types'

const getClientFullName = (client: Client) => {
  return `${client.firstName} ${client.lastName}`
}

const getClientFullAddress = (client: Client) => {
  if (!client.address) return ''
  return `${client.address.line_1}, ${client.address.city}, ${client.address.state}`
}

export const getClientPrimaryEmail = (client: Client) => {
  if (client.email.length > 0) {
    return (
      (client.email as Email[]).find(
        (email: Email) => email.emailType === 'PRIMARY'
      )?.email ?? ''
    )
  } else {
    return ''
  }
}

export const getClients = () => (): Client[] => {
  const {
    clients
  } = storeInfo.store.getState()
  const { items } = clients?.clients
  return items
}

export const getClientsForTable =
  (narPrice: string, affiliatePrice: string, orderBy?: string, order?: 'asc' | 'desc') => (): Client[] => {
    const { clients } = storeInfo.store.getState()
    const { items } = clients?.clients
    if (!items || !items.length) return []
    if (orderBy && order) return SortBy(items, orderBy, order) as Client[]

    return items.map((client: Client) => {
      const isNar = client?.clientPriceModelId === narPrice
      const isAffiliate = client?.clientPriceModelId === affiliatePrice
      return {
        ...client,
        fullName: getClientFullName(client),
        fullAddress: getClientFullAddress(client),
        phone: formatPhone(client.phone),
        email: getClientPrimaryEmail(client),
        notes: client.notes || '-',
        discount: isNar ? 'NAR' : isAffiliate ? 'AFFILIATE' : ''
      }
    })
  }

export const getTotalClients = () => {
  const { clients } = storeInfo.store.getState()
  const { total } = clients?.clients
  return total || 0
}

export const getClientById = (clientId: string) => (): Client | null => {
  const {
    clients,
  } = storeInfo.store.getState()
  const { items } = clients?.clients
  return items?.find((client) => client.id === clientId) || null
}

export const getClientsByState =
  (clients: Client[], state: string) => (): Client[] => {
    if (state === 'all' || state === '') return clients
    return clients.filter((client) => client.address.state === state)
  }

export const getClientsSearch =
  (clients: Client[], search: string) => (): Client[] => {
    if (search === '') return clients
    return clients.filter(
      (client) =>
        client.fullAddress?.includes(search) ||
        client.firstName?.toLowerCase().includes(search) ||
        client.lastName?.toLowerCase().includes(search) ||
        client.fullAddress?.toLowerCase().includes(search) ||
        client.phone?.toString().toLowerCase().includes(search) ||
        client.email?.toString().toLowerCase().includes(search) ||
        client.region?.values.join(' ').toLowerCase().includes(search)
      // || client.notes?.toLowerCase().includes(search)
    )
  }

export const getClientsBoxes = () => (): StatusBoxProps[] => {

  const { clients: { statistics } } = storeInfo.store.getState()

  if (statistics) {
    const data: StatusBoxProps[] = [

      { statName: 'Total Users', statValue: statistics[0]?.count ? statistics[0].count : 0, borderColor: 'var(--lightBlue700)' },
      { statName: statistics[1]?.status ? statistics[1].status : 'Created Last Month', statValue: statistics[1]?.count ? statistics[1].count : 0, borderColor: 'var(--lightBlue700)' },
      { statName: statistics[2]?.status ? statistics[2].status : 'Created This Month', statValue: statistics[2]?.count ? statistics[2].count : 0, borderColor: 'var(--lightBlue700)' },
      { statName: 'Total NAR Agents', statValue: statistics[3]?.count ? statistics[3].count : 0, borderColor: 'var(--lightBlue700)' },

    ]
    return data
  }
  const data: StatusBoxProps[] = []
  return data
}


