import { Theme } from '../../../UI'
import { makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => ({
  root: {
    display: 'grid',
    height: '100%',
    gap: theme.spacing(4),
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    paddingTop: '30px'
  },
  center: {
    display: 'flex',
    justifyContent: 'center'
  },
  loading: {
    width: '140px',
    height: '173px',
    [theme.breakpoints.down('md')]: {
      width: '90px',
      height: '123px'
    }
  }
})
)
