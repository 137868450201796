/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo, getMessageContacts, getUniqueMessages } from '../../helpers'
import { Punchlist } from '../../api'
import { communicationsActions } from '../actions'
import { BUCKET_TYPE, communicationsTypes, GetEmailEventsAction, GetMessagesAction, SendMessageAction } from './types'
import { Message } from '../types'

export function* getMessages({ payload, callback }: GetMessagesAction): SagaIterator {
  try {
    const { params } = payload || {}
    const messages = yield call(Punchlist.communications.getMessages, { params })
    if (messages) {
      if (params?.threadId) {
        yield put(communicationsActions.setMessagesByThread(messages.items))
      } else {
        const messagesByThread = getUniqueMessages(messages.items)
        yield put(communicationsActions.setMessages(messagesByThread))
        if (params?.bucket) {
          yield put(communicationsActions.setAmountsValue({ attr: params?.bucket?.toLowerCase(), value: messagesByThread.length as number }))
        } else {
          yield put(communicationsActions.setAmountsValue({ attr: BUCKET_TYPE.ALL.toLowerCase(), value: messagesByThread.length as number }))
        }

      }
      if (callback) yield call(callback, true, messages)
    }
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the messages'))
    if (callback) yield call(callback, false, null)
  }
}

export function* getAmounts({ payload, callback }: GetMessagesAction): SagaIterator {
  try {
    const { params } = payload || {}
    if (params) params.limit = 9999
    const messages = yield call(Punchlist.communications.getMessages, { params })
    if (messages) {
      const messagesByThread = getUniqueMessages(messages.items)
      if (params?.bucket) {
        yield put(communicationsActions.setAmountsValue({ attr: params?.bucket?.toLowerCase(), value: messagesByThread.length as number }))
      } else {
        yield put(communicationsActions.setAmountsValue({ attr: BUCKET_TYPE.ALL.toLowerCase(), value: messagesByThread.length as number }))
      }
      yield call(callback, true, messages)
    }
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the messages'))
    yield call(callback, false, null)
  }
}

export function* getEmailEvents({ payload, callback }: GetEmailEventsAction): SagaIterator {
  try {
    const { id, limit, start } = payload || {}
    const events = yield call(Punchlist.communications.getEmailEvents, { estimateId: id, start: start || 0, limit: limit || 10 })

    if (events) {
      yield put(communicationsActions.setEmailEvents(events))
    }
    
    yield call(callback, true)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the email events'))
    if (callback) yield call(callback, false)
  }
}

export function* sendMessage({ payload, callback }: SendMessageAction): SagaIterator {
  try {
    const message: Message = yield call(Punchlist.communications.createMessage, payload)
    if (message) {
      yield put(communicationsActions.setMessage(message))
      yield put(communicationsActions.setMessagesByThread([message]))
      if (callback) yield call(callback, true, message)
    }
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the messages'))
    if (callback) yield call(callback, false, null)
  }
}

export function* replyThread({ payload, callback }: any): SagaIterator {
  try {
    const { jobId, threadId, contacts, ...rest } = payload || {}
    const contactsArray = payload.contacts ? getMessageContacts(payload.contacts) : []
    const request = { ...rest, jobId, threadId, ...contactsArray }
    const message = yield call(Punchlist.communications.replyThread, jobId, request)
    if (message) {
      yield put(communicationsActions.getMessages({ params: { jobId, threadId } }, () => { }))
      if (callback) yield call(callback, true, message)
    }
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('reply the message'))
    if (callback) yield call(callback, false, null)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(communicationsTypes.GET_MESSAGES, getMessages)
  yield takeLatest(communicationsTypes.GET_EMAIL_EVENTS, getEmailEvents)
  yield takeEvery(communicationsTypes.GET_AMOUNTS, getAmounts)
  yield takeLatest(communicationsTypes.SEND_MESSAGE, sendMessage)
  yield takeLatest(communicationsTypes.REPLY_THREAD, replyThread)
}
