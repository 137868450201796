import { Box, Button, Chip, CircularProgress, Grid } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uploadPhoto } from "src/assets";
import { TextFieldLabel } from "src/components/UI";
import ProfilePicture from "src/components/UI/CustomUI/molecules/ProfilePicture";
import { employeeActions, filesActions } from "src/ducks/actions";
import { EmployeeType } from "src/ducks/employee/types";
import { getEmployee } from "src/ducks/selectors";
import { isEmpty } from "src/helpers";

export default function Profile() {
    const dispatch = useDispatch()
    const employee = useSelector(getEmployee)
    const [loading, setLoading] = useState(false)
    const [upLoading, setUploading] = useState(false)


    //employee data
    const [firstName, setFirstName] = useState(employee.firstName)
    const [lastName, setLastName] = useState(employee.lastName)
    const [phone, setPhone] = useState(employee.phone ? employee.phone : "")

    //address data
    const [fullAddress, setFullAddress] = useState(employee.address.fullAddress)
    const [city, setCity] = useState(employee.address.city)
    const [state, setState] = useState(employee.address.state)
    const [line_1, setLine_1] = useState(employee.address.line_1)
    const [line_2, setLine_2] = useState(employee.address.line_2)
    const [zipCode, setZipCode] = useState(employee.address.zipCode)

    //picture

    const handleUpload = (image: File | null): void => {
        if (image) {
            setUploading(true)
            // setPicture('')
            dispatch(
                filesActions.uploadFile(image, (uploadSucc, fileName) => {
                    if (uploadSucc && !isEmpty(fileName)) {
                        dispatch(
                            employeeActions.updateEmployee(
                                {
                                    picture: fileName
                                },
                                success => {
                                    const newPicture = success
                                        ? (fileName as string)
                                        : employee.picture
                                    setTimeout(() => {
                                        setUploading(false)
                                        const customEvent = new CustomEvent(
                                            'profilePictureChange',
                                            {
                                                detail: { newPicture }
                                            }
                                        )
                                        document.dispatchEvent(customEvent)
                                    }, 5000)
                                }
                            )
                        )
                    }
                })
            )
        }
    }


    const hasChanged = useMemo(() => firstName !== employee.firstName ||
        lastName !== employee.lastName ||
        ('' + phone !== '' + employee.phone) ||
        fullAddress !== employee.address.fullAddress ||
        city !== employee.address.city ||
        state !== employee.address.state ||
        line_1 !== employee.address.line_1 ||
        line_2 !== employee.address.line_2 ||
        zipCode !== employee.address.zipCode
        , [firstName, lastName, phone, fullAddress, city, state, line_1, line_2, zipCode, employee])

    const onSaveChanges = () => {
        setLoading(true)
        let request: any = {}
        if (firstName !== employee.firstName) {
            request = {
                ...request,
                firstName: firstName

            }
        }

        if (lastName !== employee.lastName) {
            request = {
                ...request,
                lastName: lastName

            }
        }

        if ('' + phone !== '' + employee.phone) {
            request = {
                ...request,
                phone: Number(phone)

            }
        }


        if (city !== employee.address.city) {
            request = {
                ...request,
                address: {
                    ...request.address,
                    city: city,
                    line_1: line_1
                }

            }
        }

        if (state !== employee.address.state) {
            request = {
                ...request,
                address: {
                    ...request.address,
                    state: state,
                    line_1: line_1
                }

            }
        }

        if (line_2 !== employee.address.line_2) {
            request = {
                ...request,
                address: {
                    ...request.address,
                    line_2: line_2,
                    line_1: line_1
                }

            }
        }

        if (zipCode !== employee.address.zipCode) {
            request = {
                ...request,
                address: {
                    ...request.address,
                    zipCode: zipCode,
                    line_1: line_1
                }

            }
        }

        if (line_1 !== employee.address.line_1) {
            request = {
                ...request,
                address: {
                    ...request.address,
                    line_1: line_1,

                }
            }
        }


        dispatch(employeeActions.updateEmployee(request, (succ) => {
            setLoading(false)
        }))
    }

    useEffect(() => {
        if (employee.address)
            setFullAddress(employee.address.fullAddress)
    }, [employee])
    return (
        <Box>
            <Grid container spacing={2} justifyContent='center' >
                <Grid item container spacing={2} justifyContent='center' xs={12}>
                    <Grid item justifyContent='flex-start'>
                        <ProfilePicture avatar={employee.picture ? employee.picture : uploadPhoto} onUpload={handleUpload} />
                        {upLoading && <Box sx={{
                            zIndex: 10,
                            position: 'absolute',
                            borderRadius: '32px',
                            top: '25px',
                            width: '200px',
                            height: '200px',
                            backgroundColor: 'rgba(192, 192, 192, 0.3)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'

                        }}>
                            <CircularProgress />
                        </Box>}
                    </Grid>
                    <Grid item xs={6} container spacing={2} alignContent='flex-start'>
                        <Grid item xs={12} md={6}>
                            <TextFieldLabel
                                label='First Name:'
                                //error={showWarnings && isEmpty(lastName.trim())}
                                onChange={ev => {
                                    setFirstName(ev.target.value)
                                }}
                                value={firstName}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextFieldLabel
                                label='Last Name:'
                                //error={showWarnings && isEmpty(lastName.trim())}
                                onChange={ev => {
                                    setLastName(ev.target.value)
                                }}
                                value={lastName}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextFieldLabel
                                label='Phone:'
                                //error={showWarnings && isEmpty(lastName.trim())}
                                onChange={ev => {
                                    setPhone(ev.target.value)
                                }}
                                value={phone ? '' + phone : ''}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            {employee.roles.map(r => {
                                return (
                                    <Chip label={r.roleName} sx={{ marginRight: '4px' }} />
                                )
                            })}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={8} container spacing={1} justifyContent='center'>
                    <Grid item xs={12}>
                        <TextFieldLabel
                            label='Address:'
                            //error={showWarnings && isEmpty(lastName.trim())}
                            onChange={ev => {
                                setFullAddress(ev.target.value)
                            }}
                            disabled
                            value={fullAddress}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextFieldLabel
                            label='City:'
                            //error={showWarnings && isEmpty(lastName.trim())}
                            onChange={ev => {
                                setCity(ev.target.value)
                            }}
                            value={city}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextFieldLabel
                            label='State:'
                            //error={showWarnings && isEmpty(lastName.trim())}
                            onChange={ev => {
                                setState(ev.target.value)
                            }}
                            value={state}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextFieldLabel
                            label='Line 1:'
                            //error={showWarnings && isEmpty(lastName.trim())}
                            onChange={ev => {
                                setLine_1(ev.target.value)
                            }}
                            value={line_1}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextFieldLabel
                            label='Line 2:'
                            //error={showWarnings && isEmpty(lastName.trim())}
                            onChange={ev => {
                                setLine_2(ev.target.value)
                            }}
                            value={line_2}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextFieldLabel
                            label='Zip code:'
                            //error={showWarnings && isEmpty(lastName.trim())}
                            onChange={ev => {
                                setZipCode(ev.target.value)
                            }}
                            value={zipCode}
                        />
                    </Grid>
                    <Grid item xs={6} container justifyContent="flex-end" alignItems='flex-end'>
                        <Button
                            sx={{
                                position: 'relative',
                                bottom: '-15px',
                                right: '15px'
                            }}
                            disabled={!hasChanged || loading}
                            onClick={onSaveChanges}
                            variant='contained'>
                            Save Changes
                        </Button>
                    </Grid>

                </Grid>
            </Grid>
        </Box>
    )
}
