import { Workflow } from '../types'

/** TYPES **/
export const workflowsTypes = {
  FETCH_WORKFLOWS_INSTANCE: 'FETCH_WORKFLOWS_INSTANCE',
  SET_WORKFLOWS_INSTANCE: 'SET_WORKFLOWS_INSTANCE',
  TRIGGER_WORKFLOW_ACTION: 'TRIGGER_WORKFLOW_ACTION',
  SKIP_WORKFLOW_ACTION: 'SKIP_WORKFLOW_ACTION'
}



/** ACTIONS **/
export interface FetchWorkflowsAction {
  type: typeof workflowsTypes.FETCH_WORKFLOWS_INSTANCE
  payload: {
    params?: {
      page?: number,
      size?: number,
      status?: string,
      jobId?: string
    }
  }
  callback?: (succ: boolean) => void
}

export interface TriggerWorkflowActionParams {
  id?: string,
  jobId?: string,
  orderId?: string,
}

export interface SetWorkflowsAction {
  type: typeof workflowsTypes.SET_WORKFLOWS_INSTANCE
  payload: Workflow
}

export interface TriggerWorkflowAction {
  type: typeof workflowsTypes.TRIGGER_WORKFLOW_ACTION
  //TODO: replace any
  payload: any
  callback?: (succ: boolean) => void
}

export type WorkflowsActions =
  | FetchWorkflowsAction
  | SetWorkflowsAction
  | TriggerWorkflowAction

/** REDUCER **/
export type WorkflowsType = Workflow
