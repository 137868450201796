import { useState, useEffect, useContext } from 'react'
import { Button, Grid } from '@mui/material'
import useStyles from './styles'
import attachmentHeaders from './AttachmentHeaders'
import itemsHeaders from './ItemsHeaders'
import Table from 'src/components/UI/CustomUI/organisms/Table'
import { useDispatch, useSelector } from 'react-redux'
import { getEstimate, getItems } from 'src/ducks/selectors'
import { estimatesActions, itemsActions } from 'src/ducks/actions'
import { PrivateLoader } from 'src/components/templates'
import { WorkOrdersContext } from '../../context'
import { MODAL_TYPE, workOdersTypes } from '../../context/types'
import { Item } from 'src/ducks/types'
import { ESTIMATE_STATUS } from 'src/helpers'

const OCRItems = () => {
  const styles = useStyles()

  const dispatch = useDispatch()
  const { dispatch: dispatchContext, state: { selectedItemId } } = useContext(WorkOrdersContext)

  const estimate = useSelector(getEstimate())
  const items = useSelector(getItems())

  const [loading, setLoading] = useState(false)
  const [selectedRow, setSelectedRow] = useState(-1)

  useEffect(() => {
    setLoading(true)

    dispatchContext({ type: workOdersTypes.RESET_ITEM_VALUE, payload: true })

    dispatch(
      itemsActions.fetchItems({ params: { jobId: estimate?.id } }, (succ) => {
        setLoading(false)
      })
    )
  }, [])

  const handleRowClick = (row: Item) => {
    setSelectedRow(items.findIndex((item) => item.itemId === row.itemId))
    dispatchContext({ type: workOdersTypes.SET_ITEM, payload: { ...row, displayTitle: row.title } })
    dispatchContext({ type: workOdersTypes.RESET_EDIT_ITEM_VALUE, payload: { ...row, displayTitle: row.title } })
    dispatchContext({ type: workOdersTypes.SET_SELECTED_ITEM_ID, payload: row.itemId })
  }

  const handleAddItem = () => {
    dispatchContext({ type: 'SET_MODAL_OPEN', payload: true })
    dispatchContext({ type: 'SET_MODAL_TYPE', payload: MODAL_TYPE.ADD_OCR_ITEM })
  }

  const handleCompleteOCRReview = () => {
    setLoading(true)

    dispatch(
      estimatesActions.updateEstimate({ status: ESTIMATE_STATUS.COMPOSING }, (succ) => {
        if (succ) {
          setLoading(false)
        }
      })
    )
  }

  return (
    <PrivateLoader loading={loading}>
      <Grid container className={styles.container}>
        <Grid item container className={styles.buttonGroup}>
          <Button
            variant="contained"
            className={`${styles.ocrReview} ${styles.midFont}`}
            onClick={handleCompleteOCRReview}
          >
            Complete OCR Review
          </Button>
          <Button
            variant="outlined"
            className={`${styles.addEstimate} ${styles.midFont}`}
            onClick={handleAddItem}
            disabled={estimate?.status === 'APPROVED'}
          >
            Add New Item
          </Button>
        </Grid>
        <Grid item container className={styles.attachmentTable}>
          <Table
            headers={attachmentHeaders}
            sx={{ width: '100%' }}
            fullWidth
            hasPagination={false}
          >
            {estimate?.fileDetails || []}
          </Table>
        </Grid>
        <Grid item container className={styles.itemsTable}>
          <Table
            headers={itemsHeaders}
            sx={{ width: '100%' }}
            fullWidth
            hasPagination={false}
            rowAction={handleRowClick}
            selectedRow={selectedRow}
          >
            {items || []}
          </Table>
        </Grid>
      </Grid>
    </PrivateLoader>
  )
}

export default OCRItems
