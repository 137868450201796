import { Theme } from '../../../UI'
import { makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => ({
  text: {
    display: 'flex',
    gap: theme.spacing(1),
    justifyContent: 'center',
    alignItems: 'baseline',
    color: theme.palette.primary.dark
  },
  title: {
    fontFamily: 'MorangaRg',
    fontSize: '30px',
    fontWeight: 'normal',
    lineHeight: '58px'
  },
  titleBlack: {
    fontFamily: 'NouvelleBold',
    fontSize: '32px'
  },
  passTitle: {
    fontFamily: 'Nouvelle',
    paddingTop: '20px'
  },
  form: {
    width: '100%',
    maxWidth: '360px',
    marginTop: theme.spacing(4)
  },
  grid: {
    alignItems: 'center'
  },
  center: {
    display: 'flex',
    justifyContent: 'center'
  },
  actionLink: {
    color: theme.palette.infoText.main,
    textDecoration: 'underline'
  },
  email: {
    '@global': {
      '.MuiOutlinedInput-input': {
        padding: '16px 14px !important',
      }
    }
  },
  pass: {
    marginBottom: '34px',
    '@global': {
      '.MuiOutlinedInput-input': {
        padding: '16px 14px !important',
      }
    }
  },
}))
