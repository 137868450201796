import { Options } from 'src/components/UI/CustomUI/atoms/FieldsFilter/types'
import { COLORS } from './constants'
import { ViewType } from './types'

export const getTerritoryParam = (territoryOptions: Options[]) => {
  return territoryOptions
    .filter((option, idx) => idx >= 2 && option.hide === false)
    .map((option) => option.id)
    .join(',')
}

export const rainbow = (step: number): string => {
  return COLORS[step % COLORS.length]
}

export const getEventTextColor = (bgColor: string): string => {
  var color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor
  var r = parseInt(color.substring(0, 2), 16) // hexToR
  var g = parseInt(color.substring(2, 4), 16) // hexToG
  var b = parseInt(color.substring(4, 6), 16) // hexToB
  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#494A5A' : '#fff'
}

export const getEventHeight = (view: ViewType): string => {
  switch (view) {
    case 'month':
      return '44px'
    case 'week':
      return '100%'
    case 'day':
      return '100%'
    default:
      return '44px'
  }
}
