import { RESOURCE_TYPE } from 'src/helpers'
import {
  FetchSearchesAction,
  FolderOptions,
  FolderSearch,
  SearchesActions,
  searchesTypes,
  SearchesValues,
  SearchParams,
  SearchParamValue,
} from './types'

const actions = {
  fetchSearches: (
    payload: RESOURCE_TYPE,
    callback?: (succ: boolean) => void
  ): FetchSearchesAction => ({
    type: searchesTypes.FETCH_SEARCHES,
    payload,
    callback
  }),
  setSearchesValues: (payload: SearchesValues): SearchesActions => ({
    type: searchesTypes.SET_VALUES,
    payload
  }),
  createFolder: (
    payload: Partial<FolderSearch>,
    resourceType: RESOURCE_TYPE,
    callback: (succ: boolean) => void
  ): SearchesActions => ({
    type: searchesTypes.CREATE_FOLDER,
    payload,
    resourceType,
    callback
  }),
  setSearchParamValue: (payload: SearchParamValue): SearchesActions => {
    return {
      type: searchesTypes.SET_SEARCH_PARAM_VALUE,
      payload
    }
  },
  setSearchParamValues: (payload: SearchParams): SearchesActions => ({
    type: searchesTypes.SET_SEARCH_PARAM_VALUES,
    payload
  }),
  createSearch: (
    payload: FolderOptions,
    searchInfo: any,
    resourceType: RESOURCE_TYPE,
    callback: (succ: boolean) => void
  ): SearchesActions => ({
    type: searchesTypes.CREATE_SEARCH,
    payload,
    resourceType,
    searchInfo,
    callback
  }),
  cleanSearchParams: (): SearchesActions => ({
    type: searchesTypes.CLEAN_PARAMS,
    payload: null
  }),
  removeFolder: (
    payload: { folderIndex: number[] },
    resourceType: RESOURCE_TYPE,
    callback: (succ: boolean) => void
  ): SearchesActions => ({
    type: searchesTypes.REMOVE_FOLDER,
    payload,
    resourceType,
    callback
  }),
  removeSearch: (
    payload: { folders: FolderSearch[] },
    resourceType: RESOURCE_TYPE,
    callback: (succ: boolean) => void
  ): SearchesActions => ({
    type: searchesTypes.REMOVE_SEARCH,
    payload,
    resourceType,
    callback
  }),
}

export default actions
