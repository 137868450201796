/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from 'src/components/UI'
import Table from 'src/components/UI/CustomUI/organisms/Table'
import { insurancesActions } from 'src/ducks/actions'
import { getInsurances, getLicenseTotalCount } from 'src/ducks/selectors'
import headers from './headers'
import FilterHeader from './FilterHeader'
import { getProsFilter } from 'src/ducks/filters/selector'
import TablePagination from 'src/components/UI/CustomUI/organisms/Table/TablePagination'
import {
    REVIEW_STATUS,
    ROWS_PER_PAGE_EXTENDED,
} from 'src/helpers'

import classes from './styles.module.scss'
import { HeaderType } from 'src/components/UI/CustomUI/organisms/Table/types'
import { PrivateLoader } from 'src/components/templates'
import _ from 'lodash'
import InsuranceModal from './Modals/InsuranceModal'
import { Insurance } from 'src/ducks/types'
import { TableInsurance } from 'src/ducks/insurances/types'

const InsuranceDashboard: FC = () => {
    const dispatch = useDispatch()

    const insurances = useSelector(getInsurances())
    const totalCount = useSelector(getLicenseTotalCount())
    const prosFilter = useSelector(getProsFilter())

    const [pageLoading] = useState<boolean>(false)
    const [tableLoading, setTableLoading] = useState<boolean>(false)
    const [tableHeaders, setTableHeaders] = useState<HeaderType[]>(headers)
    const [searchParams, setSearchParams] = useState({})
    const [page, setPage] = useState(0)
    const [size, setSize] = useState(50)
    const [loadingCsv, setLoadingCsv] = useState<boolean>(false)
    const [open, setOpen] = useState(false)
    const [insurance, setInsurance] = useState<TableInsurance>()

    const { search, approved, draft, denied, uploadedOnStart, uploadedOnEnd, downloadCSVCount, insuranceTypes } = prosFilter



    /**
     * Update search params whenever pros filter changes
     */
    useEffect(() => {
        setSearchParams({
            search: search || null,
            insuranceType: !insuranceTypes ? [] : insuranceTypes,
            status: getArrayForParam([
                approved ? REVIEW_STATUS.APPROVED : null,
                draft ? REVIEW_STATUS.DRAFT : null,
                denied ? REVIEW_STATUS.DENIED : null,
            ]),
            startDate: uploadedOnStart
                ? Math.floor(new Date(uploadedOnStart).getTime() / 1000)
                : null,
            endDate: uploadedOnEnd
                ? Math.floor(new Date(uploadedOnEnd).getTime() / 1000)
                : null,
        })
    }, [search, approved, draft, denied, uploadedOnEnd, uploadedOnStart, insuranceTypes])

    useEffect(() => {
        if (!_.isEqual(searchParams, {})) {
            fetchInsurances(page, size, searchParams)
        }
    }, [page, size, searchParams])


    /**
     *
     * @param arr Array of items
     * @returns array filtered by null
     */
    const getArrayForParam = (arr: any[]) => {
        const result = arr.filter((item) => item !== null)

        return result.length === arr.length ? [] : result
    }

    /**
     *
     * @param page page number
     * @param size page size
     */
    const fetchInsurances = (page?: number, size?: number, searchParams?: any) => {
        const paramPage = page ?? 0
        const paramSize = size ?? 50

        setTableLoading(true)
        dispatch(
            insurancesActions.FetchInsurances(
                { page: paramPage, size: paramSize, searchParams },
                (succ: boolean) => {
                    setTableLoading(false)
                }
            )
        )
    }

    const fetchCsvInsurances = (count: number, callback: () => void): void => {
        setLoadingCsv(true)
        dispatch(
            insurancesActions.FetchCSVInsurances({ count, searchParams }, (succ) => {
                setLoadingCsv(false)
                callback()
            })
        )
    }

    return (
        <PrivateLoader loading={pageLoading}>

            <Box className={classes.Licenses__container}>
                {insurance && <InsuranceModal
                    open={open}
                    setOpen={setOpen}
                    tableInsurance={insurance}
                    onSubmit={() => { fetchInsurances(page, size, searchParams) }}
                />}
                <FilterHeader
                    headers={tableHeaders}
                    setHeaders={setTableHeaders}
                    fetchLicenses={() => {
                        fetchInsurances(page, size, searchParams)

                    }}
                    loadingCsv={loadingCsv}
                    fetchCsvInsurances={(callback: () => void) => {
                        fetchCsvInsurances(downloadCSVCount || 400, callback)
                    }}
                />
                <Box overflow="overlay" flex={1}>
                    <Table
                        loading={tableLoading}
                        headers={tableHeaders}
                        defaultOrder="desc"
                        hasPagination={false}
                        style={{ height: '100%' }}
                        rowAction={(row) => {
                            setOpen(true)
                            setInsurance(row)
                        }}
                    >
                        {insurances ?? []}
                    </Table>
                </Box>
                <TablePagination
                    callToApi={(start, limit) => {
                        setPage(start)
                        setSize(limit)
                    }}
                    totalItems={totalCount}
                    rowsPerPageOptions={ROWS_PER_PAGE_EXTENDED}
                />
            </Box>
        </PrivateLoader>
    )
}

export default InsuranceDashboard
