import { FC, useEffect, useState } from 'react'
import { Grid, IconButton } from 'src/components/UI/MaterialUI'
import { Box, Typography, TextField, Level, TextFieldLabel } from '../../..'
import Icon from '../../atoms/Icon'
import { getDisplayForTradeValue } from './helper'
import { Props } from './types'
import DatePicker from '../../atoms/DatePicker'

const TradeBox: FC<Props> = ({
  title,
  level,
  notes,
  licenseID,
  expDate,
  onDelete,
  setNotes,
  setLevel,
  setLicenseID,
  setExpDate
}) => {
  const [value, setValue] = useState(notes)
  const [_level, _setLevel] = useState(level)
  const [_licenseId, _setLicenseID] = useState(licenseID)
  const [_expDate, _setExpDate] = useState(expDate)

  useEffect(()=>{
    setValue(notes)
    _setLevel(level)
    _setLicenseID(licenseID)
    _setExpDate(expDate)
  },[title])

  const handleNotes = (val: any) => {
    setValue(val)
    setNotes(val)
  }

  const handleLevel = (val: number) => {
    setLevel(val)
    _setLevel(val)
  }

  const handleLicenseID = (val: string) => {
    setLicenseID(val)
    _setLicenseID(val)
  }

  const handleLicenseExpDate = (val: string) => {
    setExpDate(val)
    _setExpDate(val)

  }

  return (
    <Box
      border='2px solid'
      borderRadius='0.5rem'
      display='flex'
      flexDirection='column'
      position='relative'
      sx={{ width: '100%', borderColor: '#EBECEF' }}
    >

      <Box style={{ backgroundColor: '#F9FAFA' }} paddingX='16px' paddingY='8px' display='flex' justifyContent='space-between'>
        <Typography
          fontFamily='Nouvelle'
          fontSize='14px'
          lineHeight='22px'
          fontWeight={500}
        >
          {`${title}: ${getDisplayForTradeValue(level)}`}
        </Typography>

        <IconButton sx={{ width: '16px', height: '16px' }} onClick={onDelete} >
          <Icon name='Cancel' color='red' />
        </IconButton>
      </Box>

      <Typography
        fontFamily='Nouvelle'
        fontSize='14px'
        lineHeight='22px'
        fontWeight={500}
        paddingX='16px'
        paddingY='8px'
      >
        Level:
      </Typography>

      <Box display='flex' justifyContent='center'>
        <Level
          value={_level}
          onChange={handleLevel} />
      </Box>

      <TextField
        placeholder='Write some notes here...'
        size='small'
        value={value}
        variant='outlined'
        style={{ margin: '16px 8px' }}
        rows={4}
        multiline
        InputProps={{
          onChange: value => handleNotes(value.target?.value),
          placeholder: 'Write some notes here...',
          size: 'small'
        }}
      />
      {_level === 4 && (
        <Grid container spacing={1} mx={0.5} alignItems='flex-end' mb={1}>
          <Grid item xs={5}>
            <TextFieldLabel
              label='License Number'
              placeholder='License Number'
              size='small'
              value={_licenseId}
              onChange={value => handleLicenseID(value.target?.value)}
              variant='outlined'
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              label='Expiration Date'
              value={_expDate ? new Date(_expDate) : null}
              onChange={value => handleLicenseExpDate(value)}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  )
}

export default TradeBox
