import { FC, useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, CircularProgress, Grid, Typography } from 'src/components/UI'
import { itemsActions } from 'src/ducks/actions'
import { WorkOrdersContext } from '../../../context'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import { getEstimate } from 'src/ducks/selectors'
import { workOdersTypes } from '../../../context/types'

const Delete: FC<{ many?: boolean, category?: boolean, refreshJobItems?: boolean }> = ({ many = false, category = false, refreshJobItems = false }) => {
	const { dispatch: dispatchContext, state: { selectedItemId, selectedItemsIds, selectedWorkItem, selectedOrderId } } = useContext(WorkOrdersContext)
	const dispatch = useDispatch()

	const estimate = useSelector(getEstimate())

	const itemIds: string[] = category ? selectedWorkItem?.items.map(item => item.itemId) || [] : selectedItemsIds
	const countItems = itemIds?.length

	let removeText = 'Are you sure to delete '
	if (category) {
		removeText = removeText.concat(`the ${selectedWorkItem?.category} category and all associated items from the estimate?`)
	} else if (many) {
		removeText = removeText.concat(`${countItems} item${countItems === 1 ? '' : 's'} from the estimate?`)
	} else {
		removeText = removeText.concat('the item from the estimate?')
	}

	const [buttonLoading, setButtonLoading] = useState(false)

	const handleClose = () => {
		dispatchContext({ type: 'SET_MODAL_OPEN', payload: false })
	}

	const handleDelete = () => {
		if (many) {
			setButtonLoading(true)
			dispatch(
				itemsActions.deleteBatch({ itemsIds: itemIds, selectedOrderId: selectedOrderId || undefined, jobId: estimate?.id || '' },
					(success) => {
						setButtonLoading(false)
						if (success) { handleClose() }
					})
			)
		} else if (selectedItemId) {
			setButtonLoading(true)
			dispatch(
				itemsActions.deleteItem({ itemId: selectedItemId, selectedOrderId: selectedOrderId || undefined, jobId: estimate?.id || '' },
					(success) => {
						setButtonLoading(false)
						if (success) {
							dispatchContext({ type: workOdersTypes.RESET_EDIT_ITEM_VALUE })
							dispatchContext({ type: workOdersTypes.SET_ITEM, payload: {} })
							if (refreshJobItems) {
								dispatch(
									itemsActions.fetchItems({ params: { jobId: estimate?.id } }, (succ) => {
										setButtonLoading(false)
									})
								)
							}
							handleClose()
						}
					})
			)
		}
	}

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Box sx={{ padding: '0 3vw' }}>
					<Typography>{removeText}</Typography>
				</Box>
			</Grid>
			<Grid item container xs={12} spacing={2}>
				<Grid item xs={6}>
					<Button variant='outlined' onClick={handleClose} fullWidth>
						Cancel
					</Button>
				</Grid>
				<Grid item xs={6}>
					<Button variant='containedError' onClick={handleDelete} fullWidth
						endIcon={!buttonLoading && <Icon name='DeleteOutline' />}
					>
						{buttonLoading ? <CircularProgress color='info' size={'1.4rem'} /> : 'Yes, Delete'}
					</Button>
				</Grid>
			</Grid>
		</Grid >
	)
}

export default Delete