/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'

import {
  CreateEstimateAction,
  UpdateEstimateAction,
  NewEstimateType,
  newEstimateTypes,
  CreateEstimateV2Action,
  UpdateEstimatePaymentOtionAction,
  ReplaceValueInEstimateAction,
  DeleteValueInEstimateAction,
  UpdateEstimateAddress
} from './types'
import { getNewEstimateValues } from './selectors'
import { isEmpty } from 'lodash'
import * as Sentry from '@sentry/react'
import { Address, Contact, EstimateContact, EstimateItem, PunchlistFile } from '../types'
import { getEmployee } from '../selectors'
import { ESTIMATE_STATUS, FORM_TYPE, REPAIR_TIMELINE, errorTextTryingTo } from 'src/helpers'
import { EstimateType } from '../estimate/types'
import { newEstimateActions } from '../actions'
import { UpdateRequest } from '~api/types'
import { Punchlist } from '../../api'

export function* createEstimate({
  payload,
  callback
}: CreateEstimateAction): SagaIterator {
  try {
    const { id: userId } = yield select(getEmployee)
    const values: NewEstimateType = yield select(getNewEstimateValues())

    const files: PunchlistFile[] = []
    if (values.inspectionFiles) { values.inspectionFiles.forEach((file) => files.push(file)) }
    if (values.repairListFiles) { values.repairListFiles.forEach((file) => files.push(file)) }

    const contacts = !isEmpty(values.mainContact) ? [values.mainContact] : []
    if (!isEmpty(values.additionalEmails)) {
      values.additionalEmails.split(',').forEach((email) => {
        contacts.push({ email, sendEstimate: true } as Contact)
      })
    }

    const { price, date } = payload ?? {}

    const newEstimate: Partial<EstimateItem> = {
      status: ESTIMATE_STATUS.NEW,
      formType: FORM_TYPE.CLIENT_INSPECTION_REPORT,
      repairTimeline: values.repairTime as REPAIR_TIMELINE,
      deliveryServiceId: values.paymentOption?.id,
      dueOn: date,
      properties: {
        userId: userId,
        address: values.address as Address,
        files: files,
        closingDate: values.closingDate,
        contacts: contacts as Contact[],
        clientNotes: values.clientNotes,
        totalValue: price
      }
    }

    const estimate = yield call(Punchlist.estimates.createEstimate, newEstimate)

    if (estimate && !isEmpty(estimate.id)) {
      yield put(
        newEstimateActions.setNewEstimateValue({
          attr: 'estimate',
          value: estimate
        })
      )
      if (callback) yield call(callback, true, estimate.id)
    } else {
      yield call(toast.error, errorTextTryingTo('create an estimate'))
      if (callback) yield call(callback, false)
    }
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('create an estimate'))
    if (callback) yield call(callback, false)
  }
}

export function* createEstimateV2({
  callback
}: CreateEstimateV2Action): SagaIterator {
  try {
    const { id: userId } = yield select(getEmployee)

    const values: NewEstimateType = yield select(getNewEstimateValues())
    if (!isEmpty(values.mainContact) && !isEmpty(values?.clientType)) values.mainContact = { ...values.mainContact, role: values?.clientType as any }
    const contacts = !isEmpty(values.mainContact) ? [values.mainContact] : []

    const newEstimate: Partial<EstimateType> = {
      status: ESTIMATE_STATUS.NOT_SUBMITTED,
      properties: {
        userId: userId,
        address: values.address as Address,
        contacts: contacts as Contact[]
      }
    }

    const estimate = yield call(Punchlist.estimates.createEstimate, newEstimate)
    estimate.serviceable = values.serviceable
    if (estimate && !isEmpty(estimate.id)) {
      yield put(
        newEstimateActions.setNewEstimateValue({
          attr: 'estimate',
          value: estimate
        })
      )
      if (callback) yield call(callback, true, estimate.id)
    } else {
      yield call(toast.error, errorTextTryingTo('create an estimate'))
      if (callback) yield call(callback, false)
    }
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('create an estimate'))
    if (callback) yield call(callback, false)
  }
}

export function* updateEstimatePaymentOption({
  payload,
  callback
}: UpdateEstimatePaymentOtionAction): SagaIterator {
  try {
    const values: NewEstimateType = yield select(getNewEstimateValues())
    const estimateId = values.estimate.id

    if (estimateId) {
      const request: UpdateRequest = []
      request.push({
        op: 'add',
        path: '/deliveryServiceId',
        value: payload?.id
      })
      request.push({ op: 'add', path: '/dueOn', value: payload?.date })
      request.push({ op: 'add', path: '/expedited', value: true })

      const estimate = yield call(
        Punchlist.estimates.updateEstimate,
        estimateId,
        request
      )

      estimate.serviceable = values.serviceable

      if (estimate && !isEmpty(estimate.id)) {
        yield put(
          newEstimateActions.setNewEstimateValue({
            attr: 'estimate',
            value: estimate
          })
        )
        yield put(
          newEstimateActions.setNewEstimateValue({
            attr: 'paymentOption',
            value: payload
          })
        )
        if (callback) yield call(callback, true, estimate.id)
      }
    }

    if (callback) yield call(callback, false)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('update the estimate'))
    if (callback) yield call(callback, false)
  }
}

export function* updateEstimate({
  payload,
  callback
}: UpdateEstimateAction): SagaIterator {
  try {
    const values: NewEstimateType = yield select(getNewEstimateValues())
    const estimateId = values.estimate.id

    if (estimateId) {
      const request: UpdateRequest = []

      for (const attr in payload) {
        const value = payload[attr as keyof typeof payload] as any
        if (typeof value === 'object') {
          for (const subAttr in value) {
            request.push({
              op: 'add',
              path: `/${attr}/${subAttr}`,
              value: value[subAttr as keyof EstimateItem]
            })
          }
        } else {
          request.push({
            op: 'add',
            path: `/${attr}`,
            value: payload[attr as keyof EstimateItem]
          })
        }
      }

      const estimate = yield call(
        Punchlist.estimates.updateEstimate,
        estimateId,
        request
      )
      estimate.serviceable = values.serviceable

      if (estimate && !isEmpty(estimate.id)) {
        yield put(
          newEstimateActions.setNewEstimateValue({
            attr: 'estimate',
            value: estimate
          })
        )
        if (callback) yield call(callback, true, estimate.id)
      }
    }
    if (callback) yield call(callback, false)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('update the estimate'))
    if (callback) yield call(callback, false)
  }
}

export function* updateEstimateV3({
  payload,
  callback
}: UpdateEstimateAction): SagaIterator {
  try {
    const values: NewEstimateType = yield select(getNewEstimateValues())
    const estimateId = values.estimate.id

    if (estimateId) {
      const request: UpdateRequest = []

      for (const attr in payload) {
        request.push({
          op: 'add',
          path: '/properties/contacts/-',
          value: payload[attr as keyof EstimateItem]
        })
      }

      const estimate = yield call(
        Punchlist.estimates.updateEstimate,
        estimateId,
        request
      )
      estimate.serviceable = values.serviceable

      if (estimate && !isEmpty(estimate.id)) {
        yield put(
          newEstimateActions.setNewEstimateValue({
            attr: 'estimate',
            value: estimate
          })
        )
        if (callback) yield call(callback, true, estimate.id)
      }
    }
    if (callback) yield call(callback, false)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('update the estimate'))
    if (callback) yield call(callback, false)
  }
}

export function* updateEstimateAddress({
  payload,
  callback
}: UpdateEstimateAddress): SagaIterator {
  try {
    const values: NewEstimateType = yield select(getNewEstimateValues())
    const estimateId = values.estimate.id

    if (estimateId) {
      const request: UpdateRequest = [{
        op: 'replace',
        path: '/properties/address',
        value: payload
      }]

      const estimate = yield call(
        Punchlist.estimates.updateEstimate,
        estimateId,
        request
      )
      estimate.serviceable = values.serviceable

      if (estimate && !isEmpty(estimate.id)) {
        yield put(
          newEstimateActions.setNewEstimateValue({
            attr: 'estimate',
            value: estimate
          })
        )
        if (callback) yield call(callback, true, estimate.id)
      }
    }
    if (callback) yield call(callback, false)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('update the estimate'))
    if (callback) yield call(callback, false)
  }
}

export function* replaceValueInEstimate({
  payload,
  callback
}: ReplaceValueInEstimateAction): SagaIterator {
  try {
    const values: NewEstimateType = yield select(getNewEstimateValues())
    const estimateId = values.estimate.id
    const request: UpdateRequest = []
    request.push({
      op: 'replace',
      path: payload.path,
      value: payload.value
    })
    if (estimateId) {
      const estimate = yield call(
        Punchlist.estimates.updateEstimate,
        estimateId,
        request
      )

      estimate.serviceable = values.serviceable

      if (estimate && !isEmpty(estimate.id)) {
        yield put(
          newEstimateActions.setNewEstimateValue({
            attr: 'estimate',
            value: estimate
          })
        )

        if (callback) yield call(callback, true, estimate.id)
      }
    }
    if (callback) yield call(callback, false)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('update the estimate'))
    if (callback) yield call(callback, false)
  }
}

export function* deleteValueInEstimate({
  payload,
  callback
}: DeleteValueInEstimateAction): SagaIterator {
  try {
    const values: NewEstimateType = yield select(getNewEstimateValues())
    const estimateId = values.estimate.id
    const request: UpdateRequest = []
    request.push({
      op: 'remove',
      path: payload
    })
    if (estimateId) {
      const estimate = yield call(
        Punchlist.estimates.updateEstimate,
        estimateId,
        request
      )

      estimate.serviceable = values.serviceable

      if (estimate && !isEmpty(estimate.id)) {
        yield put(
          newEstimateActions.setNewEstimateValue({
            attr: 'estimate',
            value: estimate
          })
        )

        if (callback) yield call(callback, true, estimate.id)
      }
    }
    if (callback) yield call(callback, false)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('update the estimate'))
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(newEstimateTypes.CREATE_ESTIMATE, createEstimate)
  yield takeLatest(newEstimateTypes.CREATE_ESTIMATE_V2, createEstimateV2)
  yield takeLatest(newEstimateTypes.UPDATE_ESTIMATE_PAYMENT_OPTION, updateEstimatePaymentOption)
  yield takeLatest(newEstimateTypes.UPDATE_ESTIMATE, updateEstimate)
  yield takeLatest(newEstimateTypes.UPDATE_ESTIMATE_V3, updateEstimateV3)
  yield takeLatest(newEstimateTypes.REPLACE_CONTACT_IN_ESTIMATE, replaceValueInEstimate)
  yield takeLatest(newEstimateTypes.UPDATE_ESTIMATE_ADDRESS, updateEstimateAddress)
  yield takeLatest(newEstimateTypes.DELETE_VALUE_IN_ESTIMATE, deleteValueInEstimate)
}
