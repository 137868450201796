/* eslint-disable @typescript-eslint/no-unused-vars */
import storeInfo from '../../ducks/store'
import { OrderType, OrdersConflict } from './types'
import { Event } from 'react-big-calendar'
import { formatTimestampDate, getOptionsFromArray } from 'src/helpers'
import { SelectInputOption } from 'src/components/UI/CustomUI/atoms/SelectInput/types'

export const getOrders = () => (): OrderType[] => {
  const { orders: { items } } = storeInfo.store.getState()
  if (!items || !items.length) return []
  return items
}

export const getOrderById = (id: string) => (): OrderType => {
  const { orders: { items } } = storeInfo.store.getState()
  if (!items || !items.length) return {} as OrderType
  return items.find(item => item.orderId === id) || {} as OrderType
}

export const getOrdersEvents = () => (): Event[] => {
  const { orders: { items } } = storeInfo.store.getState()
  if (!items || !items.length) return []

  return items.map(order => ({
    title: `Order #${order.orderPublicId ?? ''}`,
    start: formatTimestampDate(order.scheduledStartTimestamp ?? 0),
    end: formatTimestampDate(order.scheduledEndTimestamp ?? 0)
  }))
}



export const getOrdersSelector = (): SelectInputOption[] => {
  const { orders: { items } } = storeInfo.store.getState()
  const flattenedData = getOptionsFromArray(items as unknown as OrderType[] || [], { key: 'orderId', label: 'orderName' })
  return flattenedData
}

export const getOrdersFilteredByEstimatedId = () => (): OrderType[] => {
  const { orders: { items } } = storeInfo.store.getState()
  if (!items || !items.length) return []

  return items.filter((value, index, self) =>
    self.findIndex(v => v.estimateId === value.estimateId &&
      v.estimatePublicId === value.estimatePublicId) === index
  );
}

export const getOrdersConflict = (): OrdersConflict => {
  const { orders: { ordersConflict } } = storeInfo.store.getState()
  return ordersConflict || {} as OrdersConflict
}