import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 600,
      flexDirection: 'column',
      justifyContent: 'center',
      gap: '1rem',
      [theme.breakpoints.down('sm')]: {
        minWidth: '100%'
      }
    },
    infoRow: {
      display: "flex",
      alignItems: "center",
      gap: "4px",
      // marginTop: "1rem",
      width: "100%"
    },
    infoLabel: {
      fontFamily: "LatoBlack",
      fontWeight: 900,
      fontSize: "16px",
      color: "var(--bosscat-black-600)"
    },
    infoValue: {
      fontFamily: "Lato",
      fontSize: "16px",
      color: "var(--bosscat-black-600)"
    },
    row: {
      justifyContent: 'center',
      display: 'flex'
    },
    datePickerContainer: {
      border: '2px solid #F5F6F7',
      borderRadius: 8,
      width: '100%',
    },
    button: {
      background: '#2F7BF7',
      color: 'white',
      textTransform: 'none',
      fontWeight: 900,
      borderRadius: 8,
      height: 60,
      padding: '0 1.5rem',
      '&:hover': {
        backgroundColor: 'var(--bosscat-blue-700)'
      }
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      paddingTop: '1rem'
    },
    shoppingBox: {
      display: 'flex',
      backgroundColor: '#E6F7EE',
      padding: '.5rem',
      borderRadius: 8,
      gap: '.5rem',
      '@global': {
        '.MuiTypography-root': {
          fontWeight: 700,
          color: '#004424',
          textAlign: 'center'
        }
      }
    },
    successContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      gap: '.5rem',
      maxWidth: 450
    },
    titleSuccess: {
      color: '#01AA59',
      fontFamily: 'NextArtHeavy',
    },
    subtitleSuccess: {},
    estimateInfoTitle: {
      fontFamily: 'NextArtHeavy',
      fontSize: 20,
      textAlign: 'center'
    },
    estimateInfoSubtitle: {
      textAlign: 'center',
      color: '#6D6A6F',
      fontSize: 12,
      fontWeight: 700
    }
  })
)
