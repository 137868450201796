import { HeaderType } from 'src/components/UI/CustomUI/organisms/Table/types'

const headers: HeaderType[] = [
    {
        id: 'title',
        label: 'Title'
    },
    {
        id: 'message',
        label: 'Description'
    },
    {
        id: 'insertedOn',
        label: 'Date',
        type: 'datetime'
    }
]

export default headers
