import { FC, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Grid, Loader, Typography } from 'src/components/UI'
import { itemsActions, jobActions } from 'src/ducks/actions'
import { getJob, getOrderHistoryByJob } from 'src/ducks/job/selectors'
import { JobItem } from 'src/ducks/types'
import { formatDate } from 'src/helpers'
import { WorkOrdersContext } from '../../../context'
import { NewItem } from '../../../context/types'

const CombineItems: FC = () => {
  const {
    dispatch: dispatchContext,
    state: { selectedItemsIds, firstItem },
  } = useContext(WorkOrdersContext)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    dispatchContext({ type: 'SET_MODAL_OPEN', payload: false })
  }

  const handleCombine = () => {
    const baseItemId = firstItem?.id

    setLoading(true)
    dispatch(
      itemsActions.combineItems(
        {
          id: baseItemId || '',
          request: selectedItemsIds.filter((id) => id !== baseItemId),
          isEstimate: true,
        },
        (succ) => {
          setLoading(false)
          handleClose()
        }
      )
    )
  }

  return (
    <>
      {loading && <Loader></Loader>}
      {!loading && (
        <Grid container flexDirection="column" spacing={2}>
          <Grid item>
            {!loading && (
              <Typography variant="h6">Combine all selected items?</Typography>
            )}
          </Grid>
          <Grid container item justifyContent="flex-end" spacing={2}>
            <Grid item xs={6}>
              <Button variant="outlined" onClick={handleClose} fullWidth>
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button variant="contained" onClick={handleCombine} fullWidth>
                Combine Items
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default CombineItems
