import { groupByWithTitle, ITEM_CATEGORY } from 'src/helpers'
import storeInfo from '../store'
import { GroupedItem, ImageFile, Item, ItemByCategory } from '../types'

export const getItems = () => (): Item[] => {
  const { items: { items } } = storeInfo.store.getState()
  return items
}

export const getInspectionItems = () => (): Item[] => {
  const { items: { inspectionItems } } = storeInfo.store.getState()
  return inspectionItems
}

export const getItemById = (id: string) => (): Item => {
  const { items: { itemsByCategory } } = storeInfo.store.getState()
  if (!itemsByCategory || !itemsByCategory.length) return {} as Item
  return Object.values(itemsByCategory).reduce((acc, curr) => {
    const item = curr.items.find(item => item.itemId === id)
    if (item) return item
    return acc
  }, {} as Item)
}

export const getItemImages = (imageId: string) => (): ImageFile[] => {
  const { items: { itemsByCategory } } = storeInfo.store.getState()
  if (!itemsByCategory || !itemsByCategory.length) return []
  const itemsList = itemsByCategory.reduce((acc: any, curr) => {
    acc = [...acc, ...curr.items]
    return acc
  }, [])
  return itemsList.find((item: any) => item.itemId === imageId)?.imageFiles || []
}

export const getItemsImages = () => (): ImageFile[] => {
  const { items: { itemsByCategory } } = storeInfo.store.getState()
  if (!itemsByCategory || !itemsByCategory.length) return []
  const itemsList = itemsByCategory.reduce((acc: any, curr) => {
    acc = [...acc, ...curr.items]
    return acc
  }, [])
  const imageFilesList = itemsList.map((item: any) => { return item.imageFiles }) || []
  if (!imageFilesList || !imageFilesList.length) return []
  return [].concat.apply([], imageFilesList as any)
}

export const getOrderItemsImages = (orderId: string) => (): ImageFile[] => {
  const { items: { itemsByCategory } } = storeInfo.store.getState()
  if (!itemsByCategory || !itemsByCategory.length) return []
  const itemsList = itemsByCategory.reduce((acc: any, curr) => {
    acc = [...acc, ...curr.items]
    return acc
  }, [])
  const imageFilesList = itemsList.filter((item: any) => item.orderId === orderId)?.map((item: any) => { return item.imageFiles }) || []
  if (!imageFilesList || !imageFilesList.length) return []
  return [].concat.apply([], imageFilesList as any)
}

export const getItemsByCategory = (id?: string | null) => (): GroupedItem[] => {
  //this selector should be replaced with getItemsSortedByCategory
  const { items: { items } } = storeInfo.store.getState()
  const itemsByOrderId = id ? items.filter(item => item.orderId === id) : items
  const itemsWithTitle = groupByWithTitle(itemsByOrderId, 'category')
  const categoriesSorted = Object.values(ITEM_CATEGORY)
  const aux = itemsWithTitle.sort(
    (a: any, b: any) => categoriesSorted.indexOf(a.title) - categoriesSorted.indexOf(b.title)
  );
  return aux
}

export const getItemsSortedByCategory = (id?: string | null) => (): ItemByCategory[] => {
  const { items: { itemsByCategory } } = storeInfo.store.getState()
  return itemsByCategory
}

export const getItemsByCategoryByJobId = () => (): GroupedItem[] => {
  const { items: { items } } = storeInfo.store.getState()
  return groupByWithTitle(items, 'category')
}

export const getItemsByCategoryList = (items: Item[], id?: string | null) => (): GroupedItem[] => {
  const itemsByOrderId = id ? items.filter(item => item.orderId === id) : items
  return groupByWithTitle(itemsByOrderId, 'category')
}

export const getItemsSearch = (search: string) => (): Item[] => {
  const { items: { items } } = storeInfo.store.getState()
  if (search === '') return items
  return items.filter(
    item => item.title?.toLowerCase().includes(search?.toLowerCase())
  )
}