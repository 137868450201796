import { FC } from 'react'
import { Button, Tooltip } from 'src/components/UI/MaterialUI'
import Icon from '../Icon'
import { toast } from 'react-toastify'
import { ClipboardButtonProps } from './types'

const ClipboardButton: FC<ClipboardButtonProps> = ({
  valueToCopy
}) => {

  const closedTime = 1000

  const handleCopy = () => {
    if (valueToCopy) {
      navigator.clipboard.writeText(valueToCopy)
      toast.success.call('success', 'Copied to clipboard', { autoClose: closedTime })
    }
  }

  return (
    <Tooltip title='Copy to clipboard'>
      <Button size='small' onClick={handleCopy}
        sx={{
          position: 'absolute',
          top: '.3rem',
          right: '.3rem',
          padding: '2px'
        }} >
        <Icon name='ContentCopy' height='12px' />
      </Button>
    </Tooltip>
  )
}

export default ClipboardButton
