import { Button, Grid } from '@mui/material'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { TextFieldLabel } from 'src/components/UI'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import { notesActions } from 'src/ducks/actions'
import { NOTE_TYPE, RESOURCE_TYPE } from 'src/helpers'

const AddNoteModal = ({ onClose }: { onClose: () => void }) => {
  const dispatch = useDispatch()
  const { id } = useParams()

  const [text, setText] = useState('')

  const handleCreateNote = () => {
    const request = {
      text,
      noteType: NOTE_TYPE.ACCOUNTING,
      resourceType: RESOURCE_TYPE.JOB,
      resourceId: id
    }
    dispatch(notesActions.createNote(request,
      (success) => {
        onClose()
      }
    ))
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextFieldLabel
          label='Note:'
          value={text}
          multiline
          rows={4}
          labelVariant='h6'
          placeholder='Write some notes here...'
          size='small'
          onChange={(e) => setText(e.target.value)}
        />
      </Grid>
      <Grid container item lg={12} spacing={1} marginTop={1}>
        <Grid item xs={6}>
          <Button
            onClick={onClose}
            variant='outlinedBig'
            fullWidth
          >
            Cancel
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            onClick={handleCreateNote}
            variant='containedBig'
            fullWidth
            disabled={!text}
          >
            Save Note
            <Icon name='Save' color='white' />
          </Button>
        </Grid>
      </Grid>

    </Grid>
  )
}

export default AddNoteModal
