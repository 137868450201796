import { WorkflowsActions, workflowsTypes, WorkflowsType, TriggerWorkflowActionParams } from './types'

const actions = {
  fetchWorkflowsInstance: (
    payload: {
      params?: {
        page?: number,
        size?: number,
        status?: string,
        jobId?: string,
        includeUpcoming?: boolean
      }
    },
    callback?: (succ: boolean) => void): WorkflowsActions => ({
      type: workflowsTypes.FETCH_WORKFLOWS_INSTANCE,
      payload,
      callback
    }),
  setWorkflows: (payload: WorkflowsType): WorkflowsActions => ({
    type: workflowsTypes.SET_WORKFLOWS_INSTANCE,
    payload
  }),
  triggerWorkflowAction: (
    payload: {
      request: string,
      params?: TriggerWorkflowActionParams
    },
    callback: (succ: boolean) => void
  ): WorkflowsActions => ({
    type: workflowsTypes.TRIGGER_WORKFLOW_ACTION,
    payload,
    callback
  }),
  skipWorkflowAction: (
    payload: {
      orderId?: string,
      jobId?: string
    },
    callback: (succ: boolean) => void
  ): WorkflowsActions => ({
    type: workflowsTypes.SKIP_WORKFLOW_ACTION,
    payload,
    callback
  })
}

export default actions
