import { useState } from 'react';
import { Grid } from '@mui/material';
import { TextFieldLabel } from 'src/components/UI';
import territory from '../../territoryMock';

const EditNumberInputsGrid = () => {

    const [inputValues, setInputValues] = useState({
        electrical: territory?.tradeRates?.find(t => t.id === 'electrical')?.rate ?? '',
        plumbing: territory?.tradeRates?.find(t => t.id === 'plumbing')?.rate ?? '',
        hvac: territory?.tradeRates?.find(t => t.id === 'hvac')?.rate ?? '',
        windows: territory?.tradeRates?.find(t => t.id === 'windowsAndDoors')?.rate ?? '',
        miscellaneous: territory?.tradeRates?.find(t => t.id === 'miscellaneous')?.rate ?? '',
        cl100: territory?.tradeRates?.find(t => t.id === 'cl100')?.rate ?? '',
        crawlspace: territory?.tradeRates?.find(t => t.id === 'crawlspace')?.rate ?? '',
        exterior: territory?.tradeRates?.find(t => t.id === 'exterior')?.rate ?? '',
        interior: territory?.tradeRates?.find(t => t.id === 'interior')?.rate ?? '',
        attic: territory?.tradeRates?.find(t => t.id === 'attic')?.rate ?? '',
        roof: territory?.tradeRates?.find(t => t.id === 'roof')?.rate ?? '',
        uncategorized: territory?.tradeRates?.find(t => t.id === 'uncategorized')?.rate ?? '',
        evaluate: territory?.tradeRates?.find(t => t.id === 'evaluate')?.rate ?? '',
        excluded: territory?.tradeRates?.find(t => t.id === 'excluded')?.rate ?? '',
    });

    const handleChange = (event: any) => {
        const { name, value } = event.target;
        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [name]: value,
        }));
    };

    return (
        <Grid item xs={8} container spacing={2}>

            <Grid item xs={4}>
                <TextFieldLabel
                    name="electrical"
                    label="Electrical"
                    type="number"
                    value={'' + inputValues.electrical}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={4}>
                <TextFieldLabel
                    name="plumbing"
                    label="Plumbing"
                    type="number"
                    value={'' + inputValues.plumbing}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={4}>
                <TextFieldLabel
                    name="hvac"
                    label="HVAC"
                    type="number"
                    value={'' + inputValues.hvac}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={4}>
                <TextFieldLabel
                    name="windows"
                    label="Windows & Doors"
                    type="number"
                    value={'' + inputValues.windows}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={4}>
                <TextFieldLabel
                    name="miscellaneous"
                    label="Miscellaneous"
                    type="number"
                    value={'' + inputValues.miscellaneous}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={4}>
                <TextFieldLabel
                    name="cl100"
                    label="CL100"
                    type="number"
                    value={'' + inputValues.cl100}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={4}>
                <TextFieldLabel
                    name="crawlspace"
                    label="Crawlspace"
                    type="number"
                    value={'' + inputValues.crawlspace}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={4}>
                <TextFieldLabel
                    name="exterior"
                    label="Exterior"
                    type="number"
                    value={'' + inputValues.exterior}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={4}>
                <TextFieldLabel
                    name="interior"
                    label="Interior"
                    type="number"
                    value={'' + inputValues.interior}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={4}>
                <TextFieldLabel
                    name="attic"
                    label="Attic"
                    type="number"
                    value={'' + inputValues.attic}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={4}>
                <TextFieldLabel
                    name="roof"
                    label="Roof"
                    type="number"
                    value={'' + inputValues.roof}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={4}>
                <TextFieldLabel
                    name="uncategorized"
                    label="Uncategorized"
                    type="number"
                    value={'' + inputValues.uncategorized}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={4}>
                <TextFieldLabel
                    name="evaluate"
                    label="Evaluate"
                    type="number"
                    value={'' + inputValues.evaluate}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={4}>
                <TextFieldLabel
                    name="excluded"
                    label="Excluded"
                    type="number"
                    value={'' + inputValues.excluded}
                    onChange={handleChange}
                />
            </Grid>
        </Grid>
    );
};

export default EditNumberInputsGrid;

