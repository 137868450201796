import { LicenseType } from '../types'

export const licensesTypes = {
  FETCH_LICENSES: 'FETCH_LICENSES',
  FETCH_CSV_LICENSES: 'FETCH_CSV_LICENSES',
  SET_LICENSES: 'SET_LICENSES',
  SET_CSV_LICENSES: 'SET_CSV_LICENSES',
}

export interface FetchLicensesAction {
  type: typeof licensesTypes.FETCH_LICENSES
  payload: {
    page?: number
    size?: number
    searchParams?: any
  }
  callback?: (succ: boolean) => void
}

export interface FetchCsvLicensesAction {
  type: typeof licensesTypes.FETCH_CSV_LICENSES
  payload: {
    count?: number
    searchParams?: any
  }
  callback?: (succ: boolean) => void
}

export interface SetLicensesAction {
  type: typeof licensesTypes.SET_LICENSES
  payload: LicensesType
}

export interface SetCsvLicensesAction {
  type: typeof licensesTypes.SET_CSV_LICENSES
  payload: LicensesType
}

export type LicensesActions =
  | FetchLicensesAction
  | FetchCsvLicensesAction
  | SetLicensesAction
  | SetCsvLicensesAction

export interface LicensesType {
  start: number
  limit: number
  total: number
  items: LicenseType[]
  csvItems: LicenseType[]
}
