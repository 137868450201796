import { SelectInputOption } from 'src/components/UI/CustomUI/atoms/SelectInput/types'

export const initReviewStatus: SelectInputOption[] = [
  {
    key: 'draft',
    label: 'Draft',
    value: 'DRAFT',
    id: 'DRAFT',
    hide: true,
  },
  {
    key: 'approved',
    label: 'Approved',
    value: 'APPROVED',
    id: 'APPROVED',
    hide: true,
  },
  {
    key: 'denied',
    label: 'Denied',
    value: 'DENIED',
    id: 'DENIED',
    hide: true,
  },
]

export const initLicenseType: SelectInputOption[] = [
  {
    key: 'w9',
    label: 'W9',
    value: 'FORM_W9',
    id: 'FORM_W9',
    hide: true,
  },
  {
    key: 'id',
    label: 'ID',
    value: 'IDENTIFICATION',
    id: 'IDENTIFICATION',
    hide: true,
  },
  {
    key: 'trade',
    label: 'Trade',
    value: 'TRADE',
    id: 'TRADE',
    hide: true,
  },
  {
    key: 'other',
    label: 'Other',
    value: 'OTHER',
    id: 'OTHER',
    hide: true,
  },
]
