import { Button, CircularProgress, Grid, Typography } from '@mui/material'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { TextFieldLabel } from 'src/components/UI'
import { paymentsActions } from 'src/ducks/actions'
import { CURRENCY_MASK, PAYMENT_TYPE, RESOURCE_TYPE } from 'src/helpers'
import useStyles from './styles'
import { getEmployee, getJob } from 'src/ducks/selectors'
import { JobItem } from 'src/ducks/types'
import { createNumberMask } from 'text-mask-addons'
import CurrencyTextField from 'src/components/UI/CustomUI/molecules/CurrencyTextField'
import { isEmpty } from 'lodash'

const AddCheckModal = ({ onClose }: { onClose: () => void }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const currencyMask = createNumberMask(CURRENCY_MASK)
  const job = useSelector(getJob())
  const user = useSelector(getEmployee)
  const [loading, setLoading] = useState(false)

  const { customerInvoicedTotal, customerPaid } = job || {} as JobItem

  const [amountVal, setAmountVal] = useState("")
  const [checkNumber, setCheckNumber] = useState("")
  const [text, setText] = useState('')

  const isValid = !isEmpty(amountVal) && !isEmpty(checkNumber)

  const handleAddPayment = () => {
    setLoading(true)
    const request = {
      type: PAYMENT_TYPE.CHECK,
      resourceId: job?.id,
      resourceType: RESOURCE_TYPE.JOB,
      amount: Number(amountVal),
      memo: text,
      referenceNum: checkNumber,
      createdBy: user.id
    }
    dispatch(paymentsActions.createPayment(request,
      (success) => {
        setLoading(false)
        onClose()
      }
    ))
  }

  return (
    <Grid container spacing={1}>
      <Grid item>
        <Typography className={classes.bold}>Total Amount Due: <Typography className={classes.amount}> ${customerInvoicedTotal - customerPaid}</Typography></Typography>
      </Grid>
      <Grid item xs={12}>
        <CurrencyTextField
          autoComplete='off'
          label='Check Total Amount'
          labelVariant='h6Bold'
          placeholder='$1,000'
          value={amountVal}
          size='small'
          onChange={(e) => {
            setAmountVal(e)
            // onItemValueChanged(e ?? 0)
          }}
          mask={currencyMask}
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldLabel
          label='Check Number'
          value={checkNumber}
          labelVariant='h6Bold'
          placeholder='000-000-0000'
          size='small'
          onChange={(e) => setCheckNumber(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldLabel
          label='Note'
          value={text}
          multiline
          rows={4}
          labelVariant='h6Bold'
          placeholder='Write some notes here...'
          size='small'
          onChange={(e) => setText(e.target.value)}
        />
      </Grid>
      <Grid container item lg={12} spacing={1} marginTop={1} justifyContent="flex-end">
        <Grid item>
          <Button
            onClick={onClose}
            variant='outlinedBig'
            fullWidth
          >
            Cancel
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            onClick={handleAddPayment}
            variant='containedBig'
            fullWidth
            disabled={!isValid || loading}
          >
            {loading ? <CircularProgress size="1.6rem" /> : 'Add Paid Check'}
          </Button>
        </Grid>
      </Grid>

    </Grid>
  )
}

export default AddCheckModal
