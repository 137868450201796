import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Grid, ProfileInfo, Typography } from 'src/components/UI'
import Table from 'src/components/UI/CustomUI/organisms/Table'
import { getClientData, getClientStatics, getClient, getClientInfo, getPriceModelAffiliate, getPriceModelNar } from 'src/ducks/selectors'
import headers from './headers'

export interface ProfileProps {
    openModal: () => void
}

const Profile: FC<ProfileProps> = ({
    openModal
}) => {
    const userData = useSelector(getClientData())
    const userStatistics = useSelector(getClientStatics())
    const narPrice = useSelector(getPriceModelNar)
    const affiliatePrice = useSelector(getPriceModelAffiliate)
    const narData = useSelector(getClientInfo(narPrice, affiliatePrice))
    const client = useSelector(getClient())

    const dataToDisplay = {
        ...userData,
        createdOn: userData?.insertedOn
    }
    return (
        <Grid container>
            <ProfileInfo userData={dataToDisplay} userStats={userStatistics} narData={narData} openModal={openModal} />
            <Grid item flex={1} marginLeft={"2rem"}>
                <Typography variant='h4' fontFamily="var(--font-medium)" marginBottom={"2rem"}>Properties</Typography>
                <Table headers={headers}>
                    {[client]}
                </Table>
            </Grid>
        </Grid>
    )
}

export default Profile