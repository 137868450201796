import { FC, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Typography } from '../../../../UI'
import Table from 'src/components/UI/CustomUI/organisms/Table'
import { getWorkflows } from 'src/ducks/selectors'
import { workflowsActions } from 'src/ducks/actions'
import { icons } from 'src/assets'
import headers from './headers'

const Activity: FC = () => {
  const dispatch = useDispatch()
  const [tableLoading, setTableLoading] = useState(false)
  const activities = useSelector(getWorkflows())

  const fetchWorkflows = (page: number = 0, size: number = 25) => {
    setTableLoading(true)
    dispatch(
      workflowsActions.fetchWorkflowsInstance({ params: { page, size, status: 'NEEDS_ATTENTION' } }, (_succ: boolean) => {
        setTableLoading(false)
      })
    )
  }

  useEffect(() => {
    fetchWorkflows()
  }, [])


  return (
    <Box>
      <Box display='flex' sx={{ margin: '16px 0px' }}>
        <icons.AccessTime color='infoText' height='13px' width='13px' sx={{ margin: '0px 4px' }} />
        <Typography variant='h5'>Activity</Typography>
      </Box>
      <Table
        headers={headers}
        loading={tableLoading}
        rowsPerPageDefault={25}
        callToApi={(start, limit) => { fetchWorkflows(start, limit) }}
      >
        {activities.items ?? []}
      </Table>
    </Box>
  )
}

export default Activity
