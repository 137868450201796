import { SelectInputOption } from 'src/components/UI/CustomUI/atoms/SelectInput/types'
import { EMPLOYEE_ROLE_TYPE, getOptionsFromArray } from 'src/helpers'
import storeInfo from '../store'
import { Territory } from '../types'
import { TerritoryManagerType, TerritoryRepairItem } from './types'

export const getTerritory = (): Territory | null => {
  const { territory: { territory } } = storeInfo.store.getState()
  return territory
}

export const getTerritoryManagers = (): TerritoryManagerType[] => {
  const { territory: { territoryManagers } } = storeInfo.store.getState()
  return territoryManagers
}

export const getTerritoryManagersFiltered = (territoryId: string) => (): TerritoryManagerType[] => {
  const { territory: { territoryManagers } } = storeInfo.store.getState()
  return territoryManagers.filter(manager => manager.roles
    .some(role => role.roleType === EMPLOYEE_ROLE_TYPE.TERRITORY_MANAGER && role.territoryId === territoryId)
  )
}

export const getTerritoryManagersUsers = (): SelectInputOption[] => {
  const { territory: { territoryManagers } } = storeInfo.store.getState()
  const flattenedData = getOptionsFromArray(territoryManagers, { key: 'id', label: ['firstName', 'lastName'] }, { key: '', label: 'All Managers' })
  return flattenedData
}


export const getTerritoryManagersForDropdown = (): SelectInputOption[] => {
  const { territory: { territoryManagers } } = storeInfo.store.getState()
  const flattenedData = getOptionsFromArray(territoryManagers, { key: 'id', label: ['firstName', 'lastName'] }, { key: 'all', label: 'All Managers' })
  return flattenedData
}

export const getTerritorySuccessManagers = (): TerritoryManagerType[] => {
  const { territory: { territorySuccessManagers } } = storeInfo.store.getState()
  return territorySuccessManagers
}

export const getTerritorySuccessManagersForDropdown = (): SelectInputOption[] => {
  const { territory: { territorySuccessManagers } } = storeInfo.store.getState()
  const flattenedData = getOptionsFromArray(territorySuccessManagers, { key: 'id', label: ['firstName', 'lastName'] }, { key: 'all', label: 'All Managers' })
  return flattenedData
}

export const getTerritoryIdSuccessManagers = (id: string): string => {
  const { territory: { territorySuccessManagers } } = storeInfo.store.getState()
  const result = territorySuccessManagers.find(t => t.id === id)
  return result ? result.firstName + ' ' + result.lastName : ''
}

export const getTerritorySuccessManagersUsers = (): SelectInputOption[] => {
  const { territory: { territorySuccessManagers } } = storeInfo.store.getState()
  const flattenedData = getOptionsFromArray(territorySuccessManagers, { key: 'id', label: ['firstName', 'lastName'] }, { key: '', label: 'All users' })
  return flattenedData
}

export const getTerritorySuccessManagersUsersWithoutAll = (): SelectInputOption[] => {
  const { territory: { territorySuccessManagers } } = storeInfo.store.getState()
  const flattenedData = getOptionsFromArray(territorySuccessManagers, { key: 'id', label: ['firstName', 'lastName'] })
  return flattenedData
}

export const getTerritorySuccessManagersUsersId = (): SelectInputOption[] => {
  const { territory: { territorySuccessManagers } } = storeInfo.store.getState()
  const flattenedData = getOptionsFromArray(territorySuccessManagers, { key: 'id', label: ['firstName', 'lastName'] }, { key: '', label: 'All users' })
  return flattenedData
}

export const getTerritoryRepairItems = (): TerritoryRepairItem[] => {
  const { territory: { territoryRepairItems } } = storeInfo.store.getState()
  return territoryRepairItems
}

export const getTerritoryRepairItemsForSelect = (): SelectInputOption[] => {
  const { territory: { territoryRepairItems } } = storeInfo.store.getState()
  const flattenedData = territoryRepairItems?.reduce((acc: any, curr: TerritoryRepairItem) => {
    return [...acc, (acc = {
      key: curr,
      label: curr.publicTitle
    })]
  }, [])
  return flattenedData
}