import { getVendorPrimaryEmail } from '../selectors'
import storeInfo from '../store'
import { Vendor, VendorTerritory } from '../types'
import { VendorType } from './types'

export const getVendor = () => (): VendorType => {
  const { vendor } = storeInfo.store.getState()
  return vendor
}

export const getVendorTerritories = () => (): VendorTerritory[] => {
  const { vendor }: { vendor: Vendor } = storeInfo.store.getState()
  return vendor.territoryList
}

export const getVendorData = () => (): any => {
  const { vendor }: { vendor: Vendor } = storeInfo.store.getState()
  const userData = {
    firstName: vendor?.firstName,
    lastName: vendor?.lastName,
    email: getVendorPrimaryEmail(vendor),
    phone: vendor?.phone,
    approvedOn: vendor?.approvedOn,
    approvedBy: vendor?.approvedBy,
    approved: vendor?.approved,
    id: vendor?.id,
    tosAcceptedOn: vendor?.tosAcceptedOn
  }
  return userData
}

export const getVendorStatics = () => (): any => {
  const { vendor }: { vendor: Vendor } = storeInfo.store.getState()
  return {
    jobs: {
      label: 'Jobs',
      value: vendor?.statistics?.jobs
    },
    activeJobs: {
      label: 'Active Jobs',
      value: vendor?.statistics?.activeJobs
    },
    mtd: {
      label: 'Completed MTD',
      value: vendor?.statistics?.completedMTD
    },
    ytd: {
      label: 'Completed YTD',
      value: vendor?.statistics?.completedYTD
    }
  }
}


export const getVendorJobs = () => (): any => {
  const { vendor }: { vendor: Vendor } = storeInfo.store.getState()
  return vendor?.jobs
}