import { formatParams } from 'src/helpers'
import axios from '../axios'
import { MOCK_INSURANCES } from './constants'
import { ApiController } from './types'
import { UpdateRequest } from '~api/types'

const base = '/vendors/insurance'

const apiController: ApiController = {
    getInsurances: async ({ start, limit, searchParams }) => {
        const params = formatParams(searchParams)
        return await axios
            .get(`${base}?${params}`, {
                params: {
                    start,
                    limit,
                    sortBy: 'createdOn',
                    sortOrder: 'DESC'
                }
            })
            .then(response => response.data)
            .catch(e => {
                return MOCK_INSURANCES
            })
    },
    updateInsurance: async (id: string, request: UpdateRequest) =>
        await axios
            .patch(`insurances/${id}`, request)
            .then((response) => response.data),
}

export default apiController