import { createStyles, makeStyles } from "@mui/styles";
import { Theme as ThemeMUI } from "@mui/system"


export default makeStyles((theme: ThemeMUI) =>
    createStyles({
        tab: {
            width: '100% !important',
            '@global': {
                '.MuiButtonBase-root ': {
                    width: '100%'
                }
            }
        }
    }))
