import { SearchParams } from '../searches/types'
import { Job, Count } from '../types'

/** TYPES **/
export const reportsTypes = {
  REQUEST_PIPELINE: 'REQUEST_PIPELINE',
  GET_PIPELINE: 'GET_PIPELINE',
}

/** ACTIONS **/
export interface RequestReportsPipelineAction {
  type: typeof reportsTypes.GET_PIPELINE,
  payload: { searchParams?: Partial<SearchParams> },
  callback: (succ: boolean, csv: string) => void
}

export interface GetReportsPipelineAction {
  type: typeof reportsTypes.REQUEST_PIPELINE,
  payload: string,
  callback: (succ: boolean, csv: string) => void
}

export type ReportsActions =
  | GetReportsPipelineAction
  | RequestReportsPipelineAction

/** REDUCER **/
export type ReportsType = any
