import {
  InspectionCompaniesActions,
  InspectionCompaniesType,
  inspectionCompaniesTypes,
} from './types'
import { CompanyItem, InspectionCompany } from '../types'
import { SearchParams } from '../searches/types'
import { UpdateRequest } from '~api/types'

const actions = {
  fetchInspectionCompanies: (
    payload?: {
      page?: number
      size?: number
      searchParams?: Partial<SearchParams>
    },
    callback?: (succ: boolean) => void
  ): InspectionCompaniesActions => ({
    type: inspectionCompaniesTypes.FETCH_INSPECTION_COMPANIES,
    payload,
    callback,
  }),

  createInspectionCompany: (
    payload: Partial<InspectionCompany>,
    callback: (succ: boolean, company?: CompanyItem) => void
  ): InspectionCompaniesActions => ({
    type: inspectionCompaniesTypes.CREATE_INSPECTION_COMPANY,
    payload,
    callback,
  }),

  updateInspectionCompany: (
    payload: {
      id: string
      request: UpdateRequest
    },
    callback: (succ: boolean) => void
  ): InspectionCompaniesActions => ({
    type: inspectionCompaniesTypes.UPDATE_INSPECTION_COMPANY,
    payload,
    callback,
  }),

  deleteInspectionCompany: (
    payload: string,
    callback: (succ: boolean) => void
  ): InspectionCompaniesActions => ({
    type: inspectionCompaniesTypes.DELETE_INSPECTION_COMPANY,
    payload,
    callback,
  }),

  setInspectionCompanies: (payload: InspectionCompaniesType): InspectionCompaniesActions => ({
    type: inspectionCompaniesTypes.SET_INSPECTION_COMPANIES,
    payload,
  }),
}

export default actions
