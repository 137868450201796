/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ErrorBoundaryInner from 'src/components/templates/ErrorBoundaryInner'

const ErrorBoundary = ({ children }: any) => {
  const [hasError, setHasError] = useState(false)
  const location = useLocation()

  useEffect(() => {
    if (hasError) {
      setHasError(false)
    }
  }, [location.key])

  return (
    <ErrorBoundaryInner
      hasError={hasError}
      setHasError={setHasError}
    >
      {children}
    </ErrorBoundaryInner>
  );
}

export default ErrorBoundary