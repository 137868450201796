import React, { FC, useContext } from 'react'
import Modal from 'src/components/UI/CustomUI/molecules/Modal'
import { WorkOrdersContext } from '../context'

import Images from './Images'
import Delete from './Delete'
import { MODAL_TYPE } from '../context/types'

const OrderItemsModal: FC = () => {
    const { state: { modalType, modalOpen, tabSelected }, dispatch: dispatchContext } = useContext(WorkOrdersContext)

    const handleClose = () => {
        dispatchContext({ type: 'SET_MODAL_OPEN', payload: false })
        dispatchContext({ type: 'RESET_ITEM_VALUE' })
        dispatchContext({ type: 'SET_SELECTED_ITEM_ID', payload: null })
    }

    const withFullScreen = [MODAL_TYPE.ALL_IMAGES]
    const showClose = [MODAL_TYPE.ALL_IMAGES]
    const center = [MODAL_TYPE.DELETE_ONE, MODAL_TYPE.DELETE_MANY]

    const title = () => {
        switch (modalType) {
            case MODAL_TYPE.ALL_IMAGES: return 'Image Galery'
            case MODAL_TYPE.DELETE_ONE: return 'Why this item will be rejected?'
            case MODAL_TYPE.DELETE_MANY: return 'You’re rejecting the following items:'
            default: return undefined
        }
    }

    const content = () => {
        switch (modalType) {
            case MODAL_TYPE.ALL_IMAGES: return <Images downloadable hasTabs initialTab={tabSelected} />
            case MODAL_TYPE.DELETE_ONE: return <Delete />
            case MODAL_TYPE.DELETE_MANY: return <Delete many />
            default: return <>No Content</>
        }
    }

    const size = () => {
        switch (modalType) {
            case MODAL_TYPE.DELETE_ONE:
                return 'sm'
            default: return 'sm'
        }
    }

    return (
        <Modal
            setOpen={() => dispatchContext({ type: 'SET_MODAL_OPEN', payload: false })}
            open={modalOpen}
            onClose={() => { handleClose() }}
            title={title()}
            size={size() as 'sm' | 'md' | 'lg' | 'xl'}
            fullScreen={modalType !== null && withFullScreen.includes(modalType)}
            showClose={modalType !== null && showClose.includes(modalType)}
            center={modalType !== null && center.includes(modalType)}
        >
            {content()}
        </Modal>
    )
}

export default OrderItemsModal
