
import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => ({
    deleteButton: {
        width: '100% !important',
        marginTop: '1rem !important',
        marginBottom: '1rem !important',
        backgroundColor: `${theme.palette.error.main} !important`,
        color: "#fff !important"
    },
}))
