import { FC, useContext, useState } from "react"
import { ActionsButton } from "src/components/UI"
import PauseIcon from '@mui/icons-material/Pause';
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import { WorkOrdersContext } from "../../context";
import { useDispatch, useSelector } from "react-redux";
import { getItemsSortedByCategory, getOrderById } from "src/ducks/selectors";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Download from '@mui/icons-material/Download';
import { jobActions, ordersActions } from "src/ducks/actions";
import { Action } from "src/components/UI/CustomUI/molecules/ActionsButton/types";
import HistoryIcon from '@mui/icons-material/History';
import { ORDER_STATUS, ORDER_TYPE } from "src/helpers";
import { useParams } from "react-router-dom"

const WorkOrderAction = ({ downloadPdf }: { downloadPdf: () => void }) => {
  const dispatch = useDispatch()
  const { dispatch: dispatchContext, state: { selectedOrderId } } = useContext(WorkOrdersContext)
  const order = useSelector(getOrderById(selectedOrderId as string))
  const items = useSelector(getItemsSortedByCategory())

  const [loading, setLoading] = useState(false)
  const actions: Action[] = []
  const { id } = useParams()

  const handleResume = () => {
    setLoading(true)
    if (order && order?.orderId)
      dispatch(
        ordersActions.updateOrder(
          { orderId: selectedOrderId, statusType: null },
          (success) => {
            if (!success)
              setLoading(false)
            if (success) {

              if (id) {
                dispatch(
                  jobActions.fetchJob(id, (_succ: boolean) => {

                    setLoading(false)
                    actions.push({
                      label: 'On Hold',
                      icon: <PauseIcon />,
                      onClick: () => {
                        dispatchContext({ type: 'SET_MODAL_TYPE', payload: 'ON_HOLD' })
                        dispatchContext({ type: 'SET_MODAL_OPEN', payload: true })
                      }
                    })

                  })
                )
              }

            }
          })
      )
  }

  const handleRecall = () => {
    setLoading(true)
    dispatch(ordersActions.updateOrder({
      orderId: order.orderId,
      type: ORDER_TYPE.RECALL
    }, (_succ) => setLoading(false)))

  }

  const handleWarranty = () => {
    setLoading(true)
    dispatch(ordersActions.updateOrder({
      orderId: order.orderId,
      type: ORDER_TYPE.WARRANTY
    }, (_succ) => setLoading(false)))
  }

  const handleStandard = () => {
    setLoading(true)
    dispatch(ordersActions.updateOrder({
      orderId: order.orderId,
      type: ORDER_TYPE.ORDER
    }, (_succ) => setLoading(false)))
  }

  const handleUnaccept = () => {
    setLoading(true)

    dispatch(
      ordersActions.updateOrder({
        orderId: order.orderId,
        statusType: ORDER_STATUS.REVIEW,
      }, (_succ) => setLoading(false))
    )
  }

  const handleNotComplete = () => {
    setLoading(true)

    dispatch(
      ordersActions.updateOrder({
        orderId: order.orderId,
        statusType: ORDER_STATUS.IN_PROGRESS,
      }, (_succ) => setLoading(false))
    )
  }

  actions.push({
    label: 'Download WorkOrder PDF',
    icon: <Download />,
    onClick: downloadPdf
  })

  if (order?.statusType === 'ON_HOLD') {
    actions.push({
      label: 'Resume',
      icon: <PlayArrowIcon />,
      onClick: handleResume
    })
  } else {
    actions.push({
      label: 'Check-in History',
      icon: <HistoryIcon />,
      onClick: () => {
        dispatchContext({ type: 'SET_MODAL_TYPE', payload: 'CHECK_IN_HISTORY' })
        dispatchContext({ type: 'SET_MODAL_OPEN', payload: true })
      }
    })
    actions.push({
      label: 'On Hold',
      icon: <PauseIcon />,
      onClick: () => {
        dispatchContext({ type: 'SET_MODAL_TYPE', payload: 'ON_HOLD' })
        dispatchContext({ type: 'SET_MODAL_OPEN', payload: true })
      }
    })
  }

  if (items.length <= 0 && order.orderName !== "Default") {
    actions.push({
      label: 'Delete',
      icon: <Icon name="Delete" />,
      onClick: () => {
        dispatchContext({ type: 'SET_MODAL_TYPE', payload: 'DELETE_WORK_ORDER' })
        dispatchContext({ type: 'SET_MODAL_OPEN', payload: true })
      }
    })
  }


  if (order.type === ORDER_TYPE.ORDER) {
    actions.push({
      label: 'Order Type',
      icon: <Icon name='Grading' />,
      subActions: [
        {
          label: 'Warranty',
          onClick: handleWarranty
        },
        {
          label: 'Recall',
          onClick: handleRecall
        }
      ]
    })
  } else if (order.type === ORDER_TYPE.WARRANTY) {
    actions.push({
      label: 'Order Type',
      icon: <Icon name='Grading' />,
      subActions: [
        {
          label: 'Standard',
          onClick: handleStandard
        },
        {
          label: 'Recall',
          onClick: handleRecall
        }
      ]
    })
  } else {
    actions.push({
      label: 'Order Type',
      icon: <Icon name='Grading' />,
      subActions: [
        {
          label: 'Standard',
          onClick: handleStandard
        },
        {
          label: 'Warranty',
          onClick: handleWarranty
        }
      ]
    })
  }

  actions.push({
    isDivider: true
  })
  
  //Unaccept work, enabled if statusType = COMPLETE
  actions.push({
    label: 'Unaccept Work',
    disabled: order.statusType !== ORDER_STATUS.COMPLETE,
    onClick: handleUnaccept
  })

  //Mark as not complete, enabled if statusType = REVIEW
  actions.push({
    label: 'Mark as Not Complete',
    disabled: order.statusType !== ORDER_STATUS.REVIEW,
    onClick: handleNotComplete
  })

  return (
    <ActionsButton
      actions={actions}
      variant='contained'
      loading={loading}
      iconPosition='end'
      text={"Actions"}
      icon={<Icon name='ArrowDropDown' />} />)
}

export default WorkOrderAction;