import { FC } from 'react'
import { ProgressBar } from '../../..'
import { ProgressBarWithLabelProps } from './types'
import { Box, Typography } from "@mui/material"


const ProgressBarWithLabel: FC<ProgressBarWithLabelProps> = ({ value }) => {

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <ProgressBar value={value} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          value
        )}%`}</Typography>
      </Box>
    </Box>

  )
}

export default ProgressBarWithLabel