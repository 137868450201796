/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo } from '../../helpers'
import { Punchlist } from '../../api'
import { CreateFolderAction, CreateSearchAction, FetchSearchesAction, FolderSearch, RemoveFolderAction, RemoveSearchAction, Search, searchesTypes } from './types'
import { searchesActions } from '../actions'
import { UpdateRequest } from '~api/types'
import { isEmpty } from 'lodash'
import { getSearchParamValues } from './selectors'

export function* fetchSearches({ payload, callback }: FetchSearchesAction): SagaIterator {
  let success = false
  try {
    const searches = yield call(Punchlist.searches.getSearches, payload)
    if (searches) {
      searches.folders.forEach((folder: FolderSearch, index: number) => {
        folder.id = index
        folder.searches.forEach((search: Search, index: number) => {
          search.id = index
        })
      })

      yield put(searchesActions.setSearchesValues(searches))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the client'))
    if (callback) yield call(callback, false)
  }
}

export function* createFolder({ payload, resourceType, callback }: CreateFolderAction): SagaIterator {
  let success = false
  try {
    const request: UpdateRequest = []
    request.push({ op: 'add', path: '/folders/-', value: payload })
    const searches = yield call(Punchlist.searches.editSearch, request, resourceType)
    if (!isEmpty(searches)) {
      success = true
      yield put(searchesActions.setSearchesValues(searches))
    }
    yield call(callback, success)
  } catch (error) {
    yield call(callback, false)
  }
}

export function* createSearch({ payload, resourceType, searchInfo, callback }: CreateSearchAction): SagaIterator {
  let success = false
  const { folderSelected, searchName } = payload
  try {
    const request: UpdateRequest = []
    const values = yield select(getSearchParamValues)

    request.push({
      op: 'add', path: `/folders/${folderSelected}/searches/-`, value: {
        name: searchName,
        properties: searchInfo || values
      }
    })
    const searches = yield call(Punchlist.searches.editSearch, request, resourceType)
    if (!isEmpty(searches)) {
      success = true
      yield put(searchesActions.setSearchesValues(searches))
    }
    yield call(callback, success)
  } catch (error) {
    yield call(callback, false)
  }
}

export function* removeFolder({ payload, resourceType, callback }: RemoveFolderAction): SagaIterator {
  let success = false
  const { folderIndex } = payload
  try {
    const request: UpdateRequest = []
    folderIndex.forEach(index => request.push({ op: 'remove', path: `/folders/${index}` }))
    const searches = yield call(Punchlist.searches.editSearch, request, resourceType)
    if (searches) success = true
    yield call(callback, success)
  } catch (error) {
    yield call(callback, false)
  }
}

export function* removeSearch({ payload, resourceType, callback }: RemoveSearchAction): SagaIterator {
  let success = false
  const { folders } = payload

  const searchesToDelete = folders.reduce((acc: any, curr) => {
    return [...acc, (curr.searches.filter(search => search.toDelete))];
  }, []);
  const foldersToDelete = folders.reduce((acc: any, curr) => {
    return [...acc, (curr.toDelete ? curr : [])];
  }, []);
  try {
    const request: UpdateRequest = []
    searchesToDelete.map((search: Search[]) => search.sort((a: Search, b: Search) => b.id - a.id)
    )

    searchesToDelete.forEach((searches: Search[], index: number) => {
      if (searches.length > 0) {
        searches.forEach((search: Search) => {
          request.push({ op: 'remove', path: `/folders/${index}/searches/${search.id}` })
        })
      }
    })

    foldersToDelete.sort((a: FolderSearch, b: FolderSearch) => b.id - a.id)

    foldersToDelete.forEach((folder: FolderSearch) => {
      if (folder.toDelete) {
        request.push({ op: 'remove', path: `/folders/${folder.id}` })
      }
    })
    const searches = yield call(Punchlist.searches.editSearch, request, resourceType)
    if (searches) success = true
    yield call(callback, success)
  } catch (error) {
    yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(searchesTypes.FETCH_SEARCHES, fetchSearches)
  yield takeLatest(searchesTypes.CREATE_FOLDER, createFolder)
  yield takeLatest(searchesTypes.CREATE_SEARCH, createSearch)
  yield takeLatest(searchesTypes.REMOVE_FOLDER, removeFolder)
  yield takeLatest(searchesTypes.REMOVE_SEARCH, removeSearch)
}
