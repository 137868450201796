import { FC, useContext, useMemo } from 'react'
import useStyles from './styles'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Typography,
} from '@mui/material'
import { FieldsFilter, InputSearch, Grid } from 'src/components/UI'
import { ToolbarProps } from './types'
import AddIcon from '@mui/icons-material/Add'
import { CompaniesContext } from '../context'
import { MODAL_TYPE } from '../context/types'
import RefreshIcon from '@mui/icons-material/Refresh'
import { getCompaniesFilter } from 'src/ducks/filters/selector'
import { useDispatch, useSelector } from 'react-redux'
import { filtersActions } from 'src/ducks/actions'
import { CompaniesFilterType } from 'src/ducks/filters/types'

const Toolbar: FC<ToolbarProps> = ({
  selectedAffiliates,
  setSelectedAffiliates,
  fetchData,
}) => {
  const styles = useStyles()

  const dispatch = useDispatch()

  const companiesFilter = useSelector(getCompaniesFilter())

  const { dispatch: dispatchContext } = useContext(CompaniesContext)

  const search = useMemo(() => companiesFilter.search || '', [companiesFilter])

  const handleNewCompany = () => {
    dispatchContext({
      type: 'SET_VALUE',
      payload: { attr: 'modalType', value: MODAL_TYPE.CREATE },
    })
    dispatchContext({
      type: 'SET_VALUE',
      payload: { attr: 'modalOpen', value: true },
    })
  }

  const handleAffiliatesChange = (index: number, id: string): void => {
    if (index === -1) {
      // unselect all if index is - 1
      setSelectedAffiliates(
        selectedAffiliates.map((aff) => ({ ...aff, hide: true }))
      )
    } else {
      const realIndex = selectedAffiliates.findIndex((aff) => aff.id === id)
      setSelectedAffiliates(
        selectedAffiliates.map((aff, i) => {
          if (i === realIndex) aff.hide = !aff.hide
          return aff
        })
      )
    }
  }

  const updateCompaniesFilter = (newFilter: Partial<CompaniesFilterType>) => {
    dispatch(
      filtersActions.setFilters({
        companies: {
          ...companiesFilter,
          ...newFilter,
        },
      })
    )
  }

  return (
    <>
      <Typography className={styles.title} width="fit-content">
        Company Management
      </Typography>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-end"
        className={styles.headerContainer}
        marginTop={1}
        gap={2}
      >
        <Grid
          container
          alignItems="flex-end"
          justifyContent="center"
          spacing={2}
        >
          <Grid item flex={1}>
            <InputSearch
              // label="Search for Companies:"
              placeholder="Search Companies"
              onChange={(value) => updateCompaniesFilter({ search: value })}
              value={search}
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              label="Institutional"
              control={
                <Checkbox
                  onChange={() =>
                    updateCompaniesFilter({
                      institutional: !companiesFilter.institutional,
                    })
                  }
                  checked={!!companiesFilter.institutional}
                />
              }
            />
          </Grid>
          <Grid item>
            <IconButton
              style={{
                marginLeft: '10px',
                marginTop: '5px',
              }}
              onClick={fetchData}
            >
              <RefreshIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Box display="flex" gap="12px">
          <FieldsFilter
            flexColumn
            handleChange={handleAffiliatesChange}
            inputText="All Affiliates:"
            popupLabel=""
            options={selectedAffiliates}
            renderAsInput
            contentAlign="left"
            variant="body1"
            sx={{ width: '358px', height: '37px', flex: '1' }}
            searchable={true}
            popoverSx={{ width: '378px !important' }}
            searchFieldPlaceholder="Company Name"
            allSelection={false}
          />

          <Button
            onClick={handleNewCompany}
            variant="contained"
            color="primary"
            startIcon={
              <AddIcon
                color="inherit"
                fontSize="inherit"
                style={{ height: '12px' }}
              />
            }
          >
            New Company
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default Toolbar
