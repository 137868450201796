import { ModalProps } from 'src/components/UI/CustomUI/molecules/Modal/types'

export interface ModalsProps extends ModalProps {
  modalType?: JobModalTypes
  setLoading?: (loading: boolean) => void
}

export enum JobModalTypes {
  CLEAR_JOB = 'CLEAR_JOB',
  COMPLETE_JOB = 'COMPLETE_JOB',
  REOPEN_JOB = 'REOPEN_JOB',
  MATERIAL_INVOICES = 'MATERIAL_INVOICES',
  REQUEST_CHANGE_ORDER_APPROVAL = 'REQUEST_CHANGE_ORDER_APPROVAL',
  ARCHIVE_JOB = 'ARCHIVE_JOB',
  EXCLUDE_FROM_REPORTS = 'EXCLUDE_FROM_REPORTS'
}
