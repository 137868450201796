/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo } from '../../helpers'
import { Punchlist } from '../../api'
import { subscriptionsTypes, FetchSubscriptionsAction, FetchCsvSubscriptionsAction } from './types'
import { subscriptionsActions } from '../actions'

export function* fetchSubscriptions({ payload, callback }: FetchSubscriptionsAction): SagaIterator {
  let success = false
  try {
    const page = payload.page ?? 0
    const size = payload.size ?? 50

    const start = page * size
    const limit = size

    const searchParams = payload.searchParams ?? ''
    yield put(subscriptionsActions.setSubscriptions({ start: 0, limit: 0, total: 0, items: [] }))
    const subscriptions = yield call(Punchlist.subscriptions.getSubscriptions, { start, limit, searchParams })

    if (subscriptions) {
      yield put(subscriptionsActions.setSubscriptions(subscriptions))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the subscriptions'))
    if (callback) yield call(callback, false)
  }
}

export function *fetchCsvSubscriptions({
  payload,
  callback,
}: FetchCsvSubscriptionsAction): SagaIterator {
  let success = true

  try {
    const page = 0
    const size = payload.count ?? 0

    const start = page * size
    //const limit = start + size
    const limit = size

    const searchParams = payload.searchParams ?? ''
    const subscriptions = yield call(Punchlist.subscriptions.getSubscriptions, {
      start,
      limit,
      searchParams,
    })

    if (subscriptions) {
      yield put(subscriptionsActions.setCsvSubscriptions(subscriptions))
    }

    success = true

    if (callback) yield call(callback, success)
  } catch (err) {
    yield call(toast.error, errorTextTryingTo('get the csv subscriptions'))

    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(subscriptionsTypes.FETCH_SUBSCRIPTIONS, fetchSubscriptions)
  yield takeLatest(subscriptionsTypes.FETCH_CSV_SUBSCRIPTIONS, fetchCsvSubscriptions)
}
