import { formatParams } from 'src/helpers'
import axios from '../axios'
import { ApiController } from './types'

const base = '/reports'

const apiController: ApiController = {
  requestPipeline: async (searchParams) => {
    const params = formatParams(searchParams)
    return await axios
      .post(`${base}/pipeline?${params}`)
      .then(response => {
        return response.data
      })
  },
  getPipeline: async (pipelineId) => {
    return await axios
      .get(`${base}/pipeline/${pipelineId}`)
      .then(response => {
        return response
      })
  },
}

export default apiController
