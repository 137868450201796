export const ALL_TERRITORY_ID = 'all_territories'
export const ALL_AFFILIATE_ID = 'all_affiliate'

export const preTerritoryOptions = [
  {
    id: ALL_TERRITORY_ID,
    label: 'All Territories',
    hide: false,
  },
  {
    id: 'separator',
    isSeparator: true,
  },
]

export const preAffiliateOptions = [
 
  {
    id: ALL_AFFILIATE_ID,
    label: 'All Affiliates',
    hide: false,
  },
  {
    id: 'separator',
    isSeparator: true,
  },
]

export const COLORS = [
  '#5643D4',
  '#17B975',
  '#EBA900',
  '#0CA2B5',
  '#494A5A',
  '#1A2A55',
  '#F9FAFA',
  '#FAFDFF',
]
