import { ItemsParams } from '../items/types'
import { Item } from '../types'
import {
  EstimateItemValues,
  UpdateEstimateItemPayload,
  EstimateItemsActions,
  estimateItemsTypes,
} from './types'

const actions = {
  fetchEstimateItems: (
    payload: {
      params?: Partial<ItemsParams>,
    },
    callback?: (succ: boolean) => void
  ): EstimateItemsActions => ({
    type: estimateItemsTypes.FETCH_ESTIMATE_ITEMS,
    payload,
    callback
  }),
  setEstimateItems: (payload: Item[]): EstimateItemsActions => ({
    type: estimateItemsTypes.SET_ESTIMATE_ITEMS,
    payload
  }),
  setEstimateItemValues: (payload: EstimateItemValues): EstimateItemsActions => ({
    type: estimateItemsTypes.SET_ESTIMATE_ITEM_VALUES,
    payload
  }),
  updateEstimateItem: (
    payload: UpdateEstimateItemPayload,
    callback?: (succ: boolean) => void
  ): EstimateItemsActions => ({
    type: estimateItemsTypes.UPDATE_ESTIMATE_ITEM,
    payload,
    callback
  }),
  updateEstimateItems: (
    payload: Item[],
    callback?: (succ: boolean) => void
  ): EstimateItemsActions => ({
    type: estimateItemsTypes.UPDATE_ESTIMATE_ITEMS,
    payload,
    callback
  }),
  createEstimateItems: (
    payload: Item[],
    callback: (succ: boolean) => void
  ): EstimateItemsActions => ({
    type: estimateItemsTypes.CREATE_ESTIMATE_ITEMS,
    payload,
    callback
  })

}

export default actions
