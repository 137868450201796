/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import DatePicker from 'src/components/UI/CustomUI/atoms/DatePicker'
import { useDispatch, useSelector } from 'react-redux'
import {
  ActionsButton,
  Box,
  Button,
  FieldsFilter,
  Grid,
  IconButton,
  InputSearch,
  MenuItem,
  Select,
} from 'src/components/UI'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import { icons } from 'src/assets'
import { filtersActions } from 'src/ducks/actions'
import { getProsFilter } from 'src/ducks/filters/selector'
import { ProsFilterType } from 'src/ducks/filters/types'
import { FilterHeaderProps } from './types'
import {
  dateFormatString,
  formatTimestamp,
  headerCSVParser,
  PROS_PAGE_STATUS,
} from 'src/helpers'
import { initLicenseType, initReviewStatus } from './constants'
import ExportCSV from 'src/components/UI/CustomUI/molecules/ExportCSV'
import { getCsvLicenses } from 'src/ducks/selectors'
import { LicenseType } from 'src/ducks/types'

import RefreshIcon from '@mui/icons-material/Refresh'
import { CSVLink } from 'react-csv'
import { debounce } from 'lodash'

const FilterHeader: FC<FilterHeaderProps> = ({
  headers,
  setHeaders,
  fetchLicenses,
  fetchCsvLicenses,
  loadingCsv
}) => {
  const dispatch = useDispatch()

  const prosFilter = useSelector(getProsFilter())
  const csvLicenses = useSelector(getCsvLicenses())
  const downloadRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null)
  const { search, downloadCSVCount, reviewStatus, licenseType, uploadedOnStart, uploadedOnEnd } = prosFilter

  const [searchValue, setSearchValue] = useState<string | null>(null)
  const [refresh, setRefresh] = useState<boolean>(false)
  const [dates, setDates] = useState<{
    uploadedOnStart: string | null
    uploadedOnEnd: string | null
  }>({
    uploadedOnStart: uploadedOnStart || null,
    uploadedOnEnd: uploadedOnEnd || null,
  })

  const headersCSV: any = headerCSVParser(headers)
  const dataCSV = csvLicenses.reduce((acc: any, curr: LicenseType) => {
    const vendor = {
      ...curr,
      companyName: curr.companyName || '',
      pro: curr.contactName || '',
      phone: curr.phone || '',
      email: curr.email || '',
      territories: curr?.license?.territories
        .filter((territory: any) => territory.title)
        .reduce(
          (acc: any, curr: any) =>
            acc.concat(`${curr.title} : ${curr.capacity || '0'}`),
          []
        )
        .join(' - ')
        .replace(/,/g, ' '),
      uploadedOn: curr?.license?.uploadedOn
        ? formatTimestamp(curr?.license?.uploadedOn, 'MM/DD/YYYY hh:mm a')
        : '',
      licenseType: curr.license?.licenseType || '',
      validFromDate: curr?.license?.validFromDate
        ? formatTimestamp(curr?.license?.validFromDate, 'MM/DD/YYYY hh:mm a')
        : '',
      validToDate: curr?.license?.validToDate
        ? formatTimestamp(curr?.license?.validToDate, 'MM/DD/YYYY hh:mm a')
        : '',
      reviewStatus: curr.license?.reviewStatus || '',
    }
    acc = [...acc, vendor]
    return acc
  }, [])

  useEffect(() => {
    if (refresh === true) {
      fetchLicenses()
    }
    setRefresh(false)
  }, [refresh])

  useEffect(() => {
    if (dates.uploadedOnEnd !== uploadedOnEnd && dates.uploadedOnStart !== uploadedOnStart)
      setDates({
        uploadedOnStart: uploadedOnStart || null,
        uploadedOnEnd: uploadedOnEnd || null,
      })
  }, [uploadedOnStart, uploadedOnEnd])

  const updateProsFilter = (newFilter: Partial<ProsFilterType>) => {
    dispatch(
      filtersActions.setFilters({
        pros: {
          ...prosFilter,
          ...newFilter,
        },
      })
    )
  }

  const changeSearchPro = useCallback(
    (newValue) => {
      dispatch(filtersActions.setFilters({
        pros: {
          ...prosFilter,
          search: newValue
        }
      }))
    },
    []
  )

  const debouncedSearch = useCallback(
    debounce(changeSearchPro, 500),
    []
  )

  const onTableFilterChange = (index: number): void => {
    setHeaders(
      headers.map((item, indx) => {
        if (indx === index) {
          return { ...item, hide: !item.hide }
        }
        return item
      })
    )
  }

  const handleReviewStatusChange = (index: number, id: any): void => {
    updateProsFilter({
      reviewStatus: (reviewStatus || initReviewStatus).map((status) =>
        status.id === id ? { ...status, hide: !status.hide } : status
      ),
    })
  }

  const handleLicenseTypeChange = (index: number, id: any): void => {
    updateProsFilter({
      licenseType: (licenseType || initLicenseType).map((type) =>
        type.id === id ? { ...type, hide: !type.hide } : type
      ),
    })
  }

  function handleChangeSearch(value: string) {
    updateProsFilter({ search: value })
  }

  return (
    <Box>
      <Grid
        container
        spacing={2}
        sx={{ flexWrap: '!important' }}
        justifyContent="space-between"
      >
        <Grid item flex={1}>
          <InputSearch
            label="Search for Pro:"
            placeholder="Search for Pro's email"
            onChange={(value) => {
              debouncedSearch(value)
              setSearchValue(value)
            }}
            value={searchValue ?? ''}
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={2}>
          <FieldsFilter
            flexColumn
            handleChange={handleReviewStatusChange}
            inputText="All Review Statuses"
            options={reviewStatus || initReviewStatus}
            renderAsInput
            label="Review Status:"
            popupLabel="Select Review Statuses"
            variant="body1"
          />
        </Grid>
        <Grid item xs={2}>
          <FieldsFilter
            flexColumn
            handleChange={handleLicenseTypeChange}
            inputText="All License Types"
            options={licenseType || initLicenseType}
            renderAsInput
            label="License Type:"
            popupLabel="Select License Types"
            variant="body1"
          />
        </Grid>
        <Grid
          item
          container
          justifyContent="space-between"
          alignItems="flex-end"
          width="fit-content"
          spacing={1}
          paddingRight={2}
        >
          <Grid item>
            <Button
              variant="containedBig"
              color="primary"
              sx={{
                height: '36px',
                alignSelf: 'flex-end',
              }}
              onClick={() => {
                updateProsFilter({ pageStatus: PROS_PAGE_STATUS.PROS })
              }}
            >
              Pros
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="containedBig"
              color="primary"
              sx={{
                height: '36px',
                alignSelf: 'flex-end',
              }}
              onClick={() => {
                updateProsFilter({ pageStatus: PROS_PAGE_STATUS.INSURANCE })
              }}
            >
              Insurance
            </Button>
          </Grid>
          <Grid item>
            <FieldsFilter
              maxWidth="400px"
              // options={tableHeaders}
              options={headers}
              handleChange={onTableFilterChange}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="space-between"
        alignItems="flex-end"
        marginTop={2}
      >
        <Grid container item xs={7} spacing={2}>
          <Grid item gap={2} display="flex" alignItems="flex-end">
            <DatePicker
              placeholder="mm/dd/yyyy"
              onChange={(dateSelected: Date) => {
                setDates({
                  ...dates,
                  uploadedOnStart: dateFormatString(dateSelected),
                })
              }}
              value={
                dates?.uploadedOnStart ? new Date(dates?.uploadedOnStart) : null
              }
              style={{ height: '38px', padding: '0px', width: '100%' }}
              size="small"
              label="Created date from:"
            />
            <DatePicker
              placeholder="mm/dd/yyyy"
              onChange={(dateSelected: Date) => {
                setDates({
                  ...dates,
                  uploadedOnEnd: dateFormatString(dateSelected),
                })
              }}
              value={
                dates?.uploadedOnEnd ? new Date(dates?.uploadedOnEnd) : null
              }
              style={{ height: '38px', padding: '0px', width: '100%' }}
              size="small"
              label="Created date to:"
            />
            <Button
              variant="contained"
              sx={{ minWidth: '100px !important' }}
              onClick={() => {
                updateProsFilter({
                  uploadedOnEnd: dates.uploadedOnEnd,
                  uploadedOnStart: dates.uploadedOnStart,
                })
              }}
            >
              Apply
            </Button>
            <Button
              variant="outlined"
              sx={{ minWidth: '100px !important' }}
              onClick={() => {
                setDates({
                  uploadedOnStart: null,
                  uploadedOnEnd: null,
                })
              }}
            >
              Reset dates
            </Button>
            <IconButton
              style={{
                marginLeft: '10px',
                marginTop: '5px',
              }}
              onClick={() => setRefresh(true)}
            >
              <RefreshIcon />
            </IconButton>
            <Button
              endIcon={<Icon name='Clear' />}
              variant='outlined' onClick={() => dispatch(filtersActions.clearFilters())}>
              Clear Filters
            </Button>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={5}
          spacing={1}
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          {/* <Grid item>
            <TablePagination
              callToApi={(start, limit) => {
                fetchVendors(start, limit)
              }}
              totalItems={totalVendors}
              // totalItems={filteredVendors.length}
              sx={{
                '.MuiTablePagination-toolbar': {
                  minHeight: 'fit-content !important',
                },
                '.MuiButtonBase-root': {
                  padding: '0px inherit !important',
                },
              }}
            />
          </Grid> */}
          <Grid container item spacing={2} justifyContent="flex-end">
            <Grid item>
              <ActionsButton
                loading={loadingCsv}
                icon={<icons.CloudDownload color='disabled' />}
                iconPosition='end'
                onClick={() => fetchCsvLicenses(() => downloadRef?.current?.link?.click())}
                sx={{
                  minWidth: '56px!important',
                }}
              />
              <ExportCSV
                innerRef={downloadRef}
                headers={headersCSV}
                data={dataCSV}
                fileName={'license.csv'}
                label=''
              />

            </Grid>
            <Grid item>
              <Select
                value={downloadCSVCount || 400}
                label={`${downloadCSVCount || 400}`}
                style={{ width: '105px' }}
                onChange={(e) =>
                  updateProsFilter({ downloadCSVCount: Number(e.target.value) })
                }
              >
                <MenuItem value={400}> 400 </MenuItem>
                <MenuItem value={200}> 200 </MenuItem>
                <MenuItem value={100}> 100 </MenuItem>
                <MenuItem value={50}> 50 </MenuItem>
              </Select>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default FilterHeader
