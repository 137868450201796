import { Box, Typography } from '@mui/material'
import {
  HeaderType,
  AlignType,
} from 'src/components/UI/CustomUI/organisms/Table/types'
import classes from '../../styles.module.scss'
import { EMAIL_TYPE } from 'src/helpers'
import moment from 'moment'

// import MailIcon from '@mui/icons-material/Mail'
// import EditIcon from '@mui/icons-material/Edit'
// import DeleteIcon from '@mui/icons-material/Delete'

const applyRegExp = (phone: string): string => {
  if (!phone) return ''

  return String(phone).replace(/^(\d{3})(\d{3})(\d{4})$/g, '+1 $1-$2-$3')
}

const headers: HeaderType[] = [
  {
    id: 'user',
    label: 'User',
    custom: true,
    align: 'left' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Box padding="2px 10px" className={classes.Company__label}>
          {`${row.firstName} ${row.lastName}`}
        </Box>
      )
    },
  },
  {
    id: 'email',
    label: 'Email',
    custom: true,
    align: 'left' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Box padding="2px 10px" className={classes.Company__label}>
          {
            row.email.find((addr: any) => addr.emailType === EMAIL_TYPE.PRIMARY)
              .email
          }
        </Box>
      )
    },
  },
  {
    id: 'phone',
    label: 'Phone',
    custom: true,
    align: 'left' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Box padding="2px 10px" className={classes.Company__label}>
          {applyRegExp(row.phone)}
        </Box>
      )
    },
  },
  {
    id: 'totalJobs',
    label: 'Total Jobs',
    custom: true,
    align: 'left' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Box padding="2px 10px" className={classes.Company__label}>
          {row.completedJobCount + row.activeJobCount}
        </Box>
      )
    },
  },
  {
    id: 'createdOn',
    label: 'Created On',
    custom: true,
    align: 'left' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Box padding="2px 10px" className={classes.Company__label}>
          {moment(row.insertedOn * 1000).format('MM/DD/YYYY - hh:mm A')}
        </Box>
      )
    },
  },
  {
    id: 'approved',
    label: 'Approved',
    custom: true,
    align: 'left' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          className={classes.Company__label}
        >
          {row.disabled ? 'NO' : 'YES'}
        </Box>
      )
    },
  },
  // {
  //   id: 'action',
  //   label: 'Action',
  //   custom: true,
  //   align: 'left' as AlignType,
  //   maxWidth: 132,
  //   width: 132,
  //   Element: (row: any): JSX.Element => {
  //     return (
  //       <Grid
  //         container
  //         justifyContent="space-between"
  //         alignItems="center"
  //         padding="2px 10px"
  //         width="120px"
  //       >
  //         <Button className={classes.Company__Users__actionButton}>
  //           <MailIcon fontSize="small" color="inherit" className={classes.Company__Users__actionIcon} />
  //         </Button>
  //         <Button className={classes.Company__Users__actionButton}>
  //           <EditIcon fontSize="small" color="primary" className={classes.Company__Users__actionIcon} />
  //         </Button>
  //         <Button className={classes.Company__Users__actionButton}>
  //           <DeleteIcon fontSize="small" color="error" className={classes.Company__Users__actionIcon} />
  //         </Button>
  //       </Grid>
  //     )
  //   },
  // },
]

export default headers
