import { createStyles, makeStyles } from "@mui/styles";

export default makeStyles(() =>
  createStyles({
    promoCode: {
      flex: 1,
      marginTop: '1rem !important',
      '@global': {
        '.MuiInputBase-root': {
          height: '60px !important'
        }
      }
    },
    promoCodeTitle: {
      fontSize: '18px !important',
      fontWeight: '900 !important'
    },
    input: {
      '@global': {
        '.MuiFormControl-root': {
          position: 'relative'
        }
      }
    },
    promoCodeContainer: {
      alignItems: 'flex-end !important',
    },
    button: {
      position: 'absolute',
      background: 'var(--bosscat-blue-600) !important',
      color: 'white !important',
      textTransform: 'inherit',
      borderRadius: '8px !important',
      height: '48px !important',
      right: 0,
      bottom: 0,
      margin: '.3rem !important',
      fontWeight: '700 !important',
      '@global': {
        '.MuiButton-label': {
          paddingTop: '0 !important'
        }
      }
    },
    showPromoCode: {
      color: "#2F7BF7 !important",
      fontSize: "16px !important",
      fontFamily: "LatoBlack !important",
      fontWeight: '900 !important',
      lineHeight: "24px !important",
      wordWrap: "break-word",
      cursor: "pointer",
    },
    promoSuccessIcon: {
      fill: '#17B975 !important',
      marginRight: '.5rem !important',
      width: '1rem !important'
    },
    promoSuccessText: {
      color: '#0E905A !important'
    },
    spinner: {
      '& .MuiCircularProgress-circle': {
        color: 'var(--white-color) !important'
      }
    },
    errorMessage: {
      color: 'red !important'
    }
  })
)
