import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

const baseStyle = {
  fontFamily: 'NN Nouvelle Grotesk',
  fontStyle: 'normal !important',
}

export default makeStyles((theme: Theme) => ({
  role: {
    ...baseStyle,
    fontWeight: '400 !important',
    fontSize: '12px !important',
    lineHeight: '18px !important',
    color: '#494A5A',
  },
  fullName: {
    ...baseStyle,
    fontWeight: '500 !important',
    fontSize: '14px !important',
    lineHeight: '22px !important',
    color: '#494A5A',
  }
}))
