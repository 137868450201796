import { CompanyItem } from 'src/ducks/types'
import { formatParams } from 'src/helpers'
import { UpdateRequest } from '~api/types'
import axios from '../axios'
import { ApiController } from './types'

const base = '/materials'

const apiController: ApiController = {
  getMaterialBySku: async ({ sku }) => {
    return await axios
      .get(`${base}/sku/${sku}`)
      .then((response) => response.data)
  },

  getMaterials: async ({ ids }) => {
    const params = formatParams({ ids })
    return await axios
      .get(`${base}?${params}`)
      .then((response) => response.data)
  },

  createMateral: async ({ sku, url }) =>
    await axios
      .post(`${base}`, { sku, url })
      .then((response => response.data))
}

export default apiController
