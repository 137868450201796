import { FC, useState } from 'react'

import { Box, Button, Grid, List, Typography } from '@mui/material'
import { icons } from 'src/assets'
import { isEmpty } from 'lodash'
import { isMobile } from 'react-device-detect'
import useStyles from './styles'


export interface EstimateItemsContainerProps {
  groupedItems: any
  title?: string | JSX.Element
  children?: JSX.Element | JSX.Element[]
  action?: JSX.Element
  className?: string
  showCollapse?: boolean
  canBeEmpty?: boolean
  actionsAtBottom?: boolean
}

const EstimateItemsContainer: FC<EstimateItemsContainerProps> = ({
  groupedItems,
  title,
  action,
  children,
  className = "",
  showCollapse = false,
  canBeEmpty = false,
  actionsAtBottom = false
}) => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(true)

  if (isEmpty(groupedItems) && !canBeEmpty) return <></>

  return (
    <List className={`${classes.list} ${className}`}>
      <Grid container className={classes.titleContainer}>
        <Grid item>
          <Typography className={classes.title}>{title}</Typography>
        </Grid>
        {!actionsAtBottom &&
          <Grid item>
            {action}
          </Grid>
        }
      </Grid>
      {expanded && children}
      {actionsAtBottom &&
        <Grid item>
          {action}
        </Grid>
      }
      {showCollapse &&
        <Box className={classes.expandButtonContainer}>
          <Button
            onClick={() => setExpanded(!expanded)}
            className={classes.expandButton}
          >
            <Typography>
              {isMobile ?
                (expanded ?
                  <icons.Remove className={classes.iconMobile} />
                  :
                  <icons.Add className={classes.iconMobile} />
                )
                : <icons.ExpandLess className={expanded ? classes.iconOpened : classes.iconClosed} />
              }
              {!isMobile && (expanded ? 'Collapse' : 'Expand')}
            </Typography>
          </Button>
        </Box>
      }
    </List>
  )
}

export default EstimateItemsContainer
