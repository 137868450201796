/** TYPES**/
export const configTypes = {
  SET_CONFIG_VALUE: 'SET_CONFIG_VALUE'
}

/** ACTIONS**/
export interface ConfigValue {
  type: string
  value: any
}

export interface ConfigSetValueAction {
  type: typeof configTypes.SET_CONFIG_VALUE
  payload: ConfigValue
}

/** CONFIG**/
export interface ConfigType {
  redirectPath: string
}

export type ConfigActionTypes =
  | ConfigSetValueAction
