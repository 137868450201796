import { UpdateRequest } from './../../api/types';
import { CompanyItem } from '../types'
import { SearchParams } from '../searches/types';

/** TYPES **/
export const companiesTypes = {
  FETCH_COMPANIES: 'FETCH_COMPANIES',
  FETCH_ALL_COMPANIES: 'FETCH_ALL_COMPANIES',
  CREATE_COMPANY: 'CREATE_COMPANY',
  UPDATE_COMPANY: 'UPDATE_COMPANY',
  DELETE_COMPANY: 'DELETE_COMPANY',
  SET_COMPANIES: 'SET_COMPANIES',
  SET_ALL_COMPANIES: 'SET_ALL_COMPANIES',
  ADD_COMPANY: 'ADD_COMPANY',
  PATCH_COMPANY: 'ADD_COMPANY',
}

/** ACTIONS **/
export interface FetchCompaniesAction {
  type: typeof companiesTypes.FETCH_COMPANIES
  payload?: {
    page?: number
    size?: number
    searchParams?: Partial<SearchParams>
    institutional?: boolean | null
  }
  callback?: (succ: boolean) => void
}

export interface FetchAllCompaniesAction {
  type: typeof companiesTypes.FETCH_ALL_COMPANIES
  payload?: {
    page?: number
    size?: number
    searchParams?: Partial<SearchParams>
    institutional?: boolean | null
  }
  callback?: (succ: boolean) => void
}

export interface CreateCompanyAction {
  type: typeof companiesTypes.CREATE_COMPANY
  payload: RequestPayload
  callback?: (succ: boolean, company?: CompanyItem) => void
}

export interface UpdateCompanyAction {
  type: typeof companiesTypes.UPDATE_COMPANY
  payload: RequestPayload
  callback?: (succ: boolean) => void
}

export interface DeleteCompanyAction {
  type: typeof companiesTypes.DELETE_COMPANY
  payload: RequestPayload
  callback?: (succ: boolean) => void
}

export interface SetCompaniesAction {
  type: typeof companiesTypes.SET_COMPANIES
  payload: CompaniesType
}

export interface SetAllCompaniesAction {
  type: typeof companiesTypes.SET_ALL_COMPANIES
  payload: CompanyItem[]
}

export interface AddCompaniesAction {
  type: typeof companiesTypes.ADD_COMPANY
  payload: CompanyItem
}

export type CompaniesActions =
  | FetchCompaniesAction
  | FetchAllCompaniesAction
  | CreateCompanyAction
  | UpdateCompanyAction
  | DeleteCompanyAction
  | SetCompaniesAction
  | AddCompaniesAction

/** REDUCER **/

export interface RequestPayload {
  company: Partial<CompanyItem>
  page?: number
  size?: number
  institutional?: boolean
  updateRequest?: UpdateRequest
}

export interface CompaniesType {
  allItems: CompanyItem[]
  items: CompanyItem[]
  limit: number
  start: number
  total: number
}

// export interface CompanyType {
//   allItems: CompanyItem[]
//   items: CompanyItem[]
//   limit?: string
//   start?: string
//   total: string
// }

export interface RequestPayload {
  company: Partial<CompanyItem>
  page?: number
  size?: number
  institutional?: boolean
}
