import { FC } from 'react'
import { Typography } from 'src/components/UI'

const ViewScheduler: FC = () => {
  return (
    <Typography variant='h4'> (Pending) </Typography>
  )
}

export default ViewScheduler
