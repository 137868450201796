import { SearchParams } from '../searches/types'
import { Job, Count } from '../types'

/** TYPES **/
export const jobsTypes = {
  FETCH_JOBS: 'FETCH_JOBS',
  FETCH_JOBS_COUNTS: 'FETCH_JOBS_COUNTS',
  SET_JOBS: 'SET_JOBS',
  SET_JOBS_COUNTS: 'SET_JOBS_COUNTS',
  DOWNLOAD_CSV: 'DOWNLOAD_CSV',
  GET_CSV: 'GET_CSV',
  SET_JOBS_CSV: 'SET_JOBS_CSV'
}

/** ACTIONS **/
export interface FetchJobsAction {
  type: typeof jobsTypes.FETCH_JOBS
  payload: {
    page?: number,
    size?: number,
    searchParams?: Partial<SearchParams>
  }
  callback?: (succ: boolean) => void
}

export interface SetJObsAction {
  type: typeof jobsTypes.SET_JOBS
  payload: Job
}

export interface SetJobsCountsAction {
  type: typeof jobsTypes.SET_JOBS_COUNTS
  payload: Count[]
}

export interface VendorValue {
  attr: keyof Job
  value: any
}

export interface DownloadCSVJobAction {
  type: typeof jobsTypes.DOWNLOAD_CSV,
  payload: { searchParams?: Partial<SearchParams> },
  callback: (succ: boolean) => void
}

export interface GetCSVJobAction {
  type: typeof jobsTypes.DOWNLOAD_CSV,
  payload: string,
  callback: (succ: boolean) => void
}

export type JobsActions =
  | FetchJobsAction
  | SetJObsAction
  | SetJobsCountsAction
  | GetCSVJobAction
  | DownloadCSVJobAction

/** REDUCER **/
export type JobsType = Job
