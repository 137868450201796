import storeInfo from '../store'
import { HomemartType } from './types'

export const getHomemart = () => (): HomemartType => {
  const { homemart } = storeInfo.store.getState()
  return homemart
}

export const getPartnerCategories = () => (): { id: string, name: string }[] => {
  const { homemart: { categories } } = storeInfo.store.getState()
  return categories
}

export const getPartnerTags = () => (): { id: string, name: string }[] => {
  const { homemart: { tags } } = storeInfo.store.getState()
  return tags
}
