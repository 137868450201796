import { Box, Button, Grid, Link } from 'src/components/UI'
import styles from './styles.module.scss'
import { getEstimate } from 'src/ducks/selectors'
import { useSelector } from 'react-redux'
import JobNotes from './JobNotes'

const BASE_URL =
  'https://punchlistusa.atlassian.net/servicedesk/customer/portal'

const Notes = () => {
  const estimate = useSelector(getEstimate())

  return (
    <Box className={styles.Overview}>
      <Grid
        direction="column"
        container
        item
        xs={12}
        lg={7}
        className={styles.Overview__left}
      >
        <JobNotes />
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={6}>
            <Link
              href={`${BASE_URL}/3`}
              target="_blank"
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <Button
                variant="contained"
                color="error"
                fullWidth
                className={styles.Overview__ticketLink}
              >
                OPEN TECH SUPPORT TICKET
              </Button>
            </Link>
          </Grid>
          <Grid item xs={6}>
            <Link
              href={`${BASE_URL}/9/group/20/create/136?customfield_10116=${estimate?.properties?.territory?.title}&customfield_10053=${estimate?.properties.fullAddress}&customfield_10128=${estimate?.properties?.address?.timeZone}&customfield_10061=${estimate?.id}`}
              target="_blank"
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <Button
                variant="contained"
                color="success"
                fullWidth
                className={styles.Overview__requestLink}
              >
                REQUEST SITE VISIT
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Notes
