/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PrivateLoader } from "src/components/templates";
import { ActionsButton, Box, Button, Checkbox, debounce, DialogActions, DialogContent, Grid, List, ListItem, SelectInput, Typography } from "src/components/UI";
import Modal from "src/components/UI/CustomUI/molecules/Modal";
import Table from "src/components/UI/CustomUI/organisms/Table";
import { InvitationActions, vendorActions, vendorsActions } from "src/ducks/actions";
import { getInvitations, getVendor, getVendorsForTable } from "src/ducks/selectors";
import headers from "./headers";

export default function Organization() {
    const dispatch = useDispatch()
    const vendor = useSelector(getVendor())
    const [open, setOpen] = useState(false)
    const [searchFilter, setSearchFilter] = useState('')
    const vendors = useSelector(getVendorsForTable())
    const [params, setParams] = useState<any>()
    const [loadingVendors, setLoadingVendors] = useState(false)
    const invitations = useSelector(getInvitations())
    const [loadingInvitations, setLoadingInvitations] = useState(false)

    useEffect(() => {
        fetchVendors()
    }, [params])

    useEffect(() => {
        if (vendor) {
            setLoadingInvitations(true)
            dispatch(InvitationActions.getInvitations(
                { networkIds: vendor.networkIds ?? [] },
                (succ: boolean) => setLoadingInvitations(false)
            ))
        }

    }, [dispatch, vendor])

    const fetchVendors = useCallback((page?: number, size?: number) => {
        setLoadingVendors(true)
        dispatch(
            vendorsActions.fetchVendors({ page, size, searchParams: params }, (_succ: boolean) => {
                setLoadingVendors(false)
            })
        )
    }, [dispatch, params])

    const changeItemValue = useCallback(
        (newValue, attr) => {
            setParams({ ...params, [attr]: newValue })
        },
        []
    )

    const onItemValueChanged = useMemo(
        () => debounce(changeItemValue, 700),
        [changeItemValue]
    )

    const handleChangeAdmin = () => {
        setLoadingInvitations(true)
        dispatch(vendorActions.updateVendor({ isAdmin: true }, (succ: boolean) => {
            if (!succ) {
                console.log("Error trying to make vendor admin")
            }

            dispatch(vendorActions.fetchVendor(vendor?.id ?? '', (succ: boolean) => {
                if (!succ) {
                    console.log("Error trying to update vendor")
                }
                setLoadingInvitations(false)
            }))

        }))
    }

    const handleSendInvitation = () => {
        if (vendor?.networkIds && vendor?.networkIds?.length > 0) {
            dispatch(InvitationActions.sendInvitation({
                networkId: vendor?.networkIds[0] ?? '',
                recipientEmail: searchFilter
            }, (succ: boolean) => {
                setOpen(false)
                if (succ && vendor) {
                    setLoadingInvitations(true)
                    dispatch(InvitationActions.getInvitations(
                        { networkIds: vendor.networkIds ?? [] },
                        (succ: boolean) => setLoadingInvitations(false)
                    ))
                }
            }))
        }
    }

    const AddMemberModal = () => {
        return (
            <Modal
                title={
                    <Grid container justifyContent='left' padding='1rem'>
                        <Typography variant='h5Bold'>
                            Add Member
                        </Typography>
                    </Grid>
                }
                open={open}
                setOpen={setOpen}>
                <DialogContent>
                    <SelectInput
                        loading={loadingVendors}
                        label=''
                        placeholder='Pros name here'
                        onInputChange={(value) => {
                            const val = value.key === '' ? '' : value
                            setSearchFilter('' + val)
                            onItemValueChanged(val, 'search')
                        }}
                        onChange={(value) => {
                            const val = value.key === '' ? '' : value
                            setSearchFilter('' + val)
                            onItemValueChanged(val, 'search')
                        }}
                        options={vendors.map(vendor => vendor?.email?.length > 0 ? vendor?.email[0].email : '')}
                        value={searchFilter}
                        sx={{ width: '100%' }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button fullWidth variant='outlined' onClick={() => setOpen(false)}>
                        Cancel
                    </Button>
                    <Button fullWidth variant='contained' onClick={handleSendInvitation}>
                        Add
                    </Button>
                </DialogActions>
            </Modal>
        )
    }

    return (
        <Grid container>
            {AddMemberModal()}
            <Grid item xs={12}>
                <Checkbox disabled={vendor?.isAdmin} checked={vendor?.isAdmin} onClick={handleChangeAdmin} /> Admin
                {!vendor?.isAdmin && !vendor?.parents && (
                    <Typography>Vendor does not belong to any networks</Typography>
                )}
                {!vendor?.isAdmin && vendor?.parents && (
                    <List>
                        {vendor.parents.map((parent: any) => (
                            <ListItem>{`Parent: ${parent.firstName} ${parent.lastName}`}<br />{`Organization: ${parent.companyName}`}</ListItem>
                        ))}
                    </List>
                )}
            </Grid>
            {vendor?.isAdmin &&
                <Grid item xs={12} sx={{ width: '100%' }}>
                    <PrivateLoader loading={loadingInvitations}>
                        <Box display='flex' justifyContent='flex-end' sx={{ mb: '6px' }}>
                            <Button disabled={loadingInvitations} variant='outlined' onClick={() => setOpen(true)}>
                                Add Member
                            </Button>
                        </Box>
                        <Table
                            headers={headers}
                            hasPagination={false}
                        >
                            {invitations}
                        </Table>
                    </PrivateLoader>
                </Grid>
            }

        </Grid >
    )
}