import { makeStyles } from '@mui/styles'
import { Theme } from 'src/components/UI'

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        width: '100%',
        [theme.breakpoints.down('lg')]: {
            flexWrap: 'wrap !important',
        }
    },
}))

export default useStyles;