
import {
  ORDER_STATUS,
  ORDER_TYPE,
  ITEM_MARGIN_TYPE,
  PRICING_ITEM_TYPE
} from '../../helpers'
import { PunchlistFile, OrderHistory, Address } from '../types'

/** TYPES **/
export const ordersTypes = {
  FETCH_ORDERS: 'FETCH_ORDERS',
  SET_ORDERS: 'SET_ORDERS',
  SET_ORDER: 'SET_ORDER',
  CREATE_ORDER: 'CREATE_ORDER',
  UPDATE_ORDER: 'UPDATE_ORDERS',
  UPDATE_ORDER_STATE: 'UPDATE_ORDER_STATE',
  CREATE_BILL: 'CREATE_BILL',
  GET_ORDERS_CONFLICT: 'GET_ORDERS_CONFLICT',
  SET_ORDERS_CONFLICT: 'SET_ORDERS_CONFLICT',
}

export interface CreateOrderRequest {
  orderName: string
  statusType?: ORDER_STATUS
  estimateId?: string
}

/** ACTIONS **/
export interface FetchOrdersAction {
  type: typeof ordersTypes.FETCH_ORDERS
  payload: { startDate?: string, endDate?: string, params?: any }
  callback?: (succ: boolean) => void
}

export interface CreateOrderAction {
  type: typeof ordersTypes.CREATE_ORDER
  payload: CreateOrderRequest
  callback: (succ: boolean, orderId: string | null) => void
}

export interface SetOrdersAction {
  type: typeof ordersTypes.SET_ORDERS
  payload: OrdersType
}

export interface UpdateOrderAction {
  type: typeof ordersTypes.UPDATE_ORDER
  payload: Partial<OrderType>
  callback: (succ: boolean) => void
}

export interface UpdateStatePayload {
  orderId: string
  orderState: OrderState
}

export interface UpdateOrderStateAction {
  type: typeof ordersTypes.UPDATE_ORDER_STATE
  payload: UpdateStatePayload
  callback?: (succ: boolean) => void
}

export interface GetOrderConflictAction {
  type: typeof ordersTypes.FETCH_ORDERS
  payload: GetOrderConflictParams
  callback?: (succ: boolean, orderConflict: OrdersConflict | null) => void
}

export interface CreateBillAction {
  type: typeof ordersTypes.CREATE_BILL
  payload: CreateBillPayload
  callback?: (succ: boolean) => void
}

export interface SetOrdersConflict {
  type: typeof ordersTypes.SET_ORDERS_CONFLICT
  payload: OrdersConflict
}

export type OrdersActions =
  | FetchOrdersAction
  | SetOrdersAction
  | CreateOrderAction
  | UpdateOrderAction
  | UpdateOrderStateAction
  | CreateBillAction
  | GetOrderConflictAction
  | SetOrdersConflict

export interface CreateBillPayload {
  jobId: string,
  orderId: string,
  orderType: string,
  pricingItemId?: string
  paymentType?: string
  amount?: number
  rating?: number
  feedback?: string
  vendorId?: string
  type?: PRICING_ITEM_TYPE
}

export type OrderState = 'none' | 'checkin' | 'complete' | 'accepted' | 'unaccepted' | 'reopen'


/** REDUCER **/
export interface DispatchPriceItem {
  id: string
  name: string | undefined
  amount: number
  type: PRICING_ITEM_TYPE
  description: string
  deadline: number | null
  prepayment: boolean
  paid: boolean
  notes: string
}

export interface DispatchPricingModel {
  itemMarginType: ITEM_MARGIN_TYPE
  itemMarginValue: number | string
  itemMarginTotal: number
  autoUpdate: boolean
  items: DispatchPriceItem[]
}

export interface GetOrderConflictParams {
  vendorId: string
  territory: string
  startDate: number
  endDate: number
}

export interface OrderType {
  orderId: string | null
  orderName: string
  orderPublicId: string
  statusType: ORDER_STATUS | null,
  type: ORDER_TYPE
  files: PunchlistFile[] | undefined

  estimateId: string
  estimatePublicId: string
  bidPrice: number
  totalValue: number
  totalCost: number
  materialCost: number
  prepaymentTotal: number
  totalOwed: number
  paymentAmount: number
  paymentType?: 'QUICKBOOKS' | 'CREDITCARD'

  completedByName: string
  acceptedByName: string
  vendorPaidByName: string

  vendorId: string
  companyName: string
  companyContact: string
  address: Address

  scheduledStartTimestamp: number | null
  scheduledEndTimestamp: number | null
  checkedInTimestamp: number | null
  completedTimestamp: number | null
  csmAcceptanceTimestamp: number | null
  vendorPaymentTimestamp: number | null
  dispatchedTimestamp: number | null
  dispatchedBy: string
  dispatchNotes: string

  adminFlags: string[]
  pricingModel: DispatchPricingModel
  incentives: DispatchPriceItem[] | null
  incentiveStartDeadline: number | null
  incentiveTotalAmount: number | null
  holdNotes: string | null

  orderHistory?: OrderHistory[]
  preapproval?: boolean

  numberOfItems: number
  percentItemsComplete: number
  numberOfCompletedItems: number
}

export interface OrdersType {
  start: number
  limit: number
  total: number
  items: OrderType[],
  ordersConflict: OrdersConflict | null
}

export interface OrdersConflict {
  orders: OrderType[],
  capacity: number,
  conflict: boolean
}