/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import * as Sentry from '@sentry/react'
import { FetchDiscountBannerAction, CreateDiscountBannerAction, discountsTypes } from './types'
import { discountsActions } from '../actions'
import { Punchlist } from '../../api'
import { errorTextTryingTo } from 'src/helpers'
import { toast } from 'react-toastify'

export function* fetchDiscountBanner({ payload, callback }: FetchDiscountBannerAction): SagaIterator {
  let success = false
  try {
    const discountBanner = yield call(Punchlist.discounts.getDiscountBanner)
    yield put(discountsActions.setValue({ attr: 'discountBanner', value: discountBanner }))
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield put(discountsActions.setValue({ attr: 'discountBanner', value: null }))
    Sentry.captureException(error);
    // yield call(toast.error, errorTextTryingTo('get the discount banner'))
    if (callback) yield call(callback, false)
  }
}

export function* createDiscountBanner({ payload, callback }: CreateDiscountBannerAction): SagaIterator {
  let success = false
  try {
    const discountBanner = yield call(Punchlist.discounts.createDiscountBanner, payload)
    if (discountBanner) {
      yield put(discountsActions.setValue({ attr: 'discountBanner', value: discountBanner }))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, error?.response?.data?.message ? error.response.data.message : 'create the discount banner')
    if (callback) yield call(callback, false)
  }
}


export default function* saga(): SagaIterator {
  yield takeLatest(discountsTypes.FETCH_DISCOUNT_BANNER, fetchDiscountBanner)
  yield takeLatest(discountsTypes.CREATE_DISCOUNT_BANNER, createDiscountBanner)
}
