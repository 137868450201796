import { FC, useState, useContext } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import { PrivateLoader } from "src/components/templates"
import { filesActions, widgetsActions } from "src/ducks/actions"
import { FILE_TYPE, isEmpty, ParseFilesUrl } from "src/helpers"
import { WidgetsContext } from "../../context"
import { dispatchTypes } from "../../context/types"
import env from "@beam-australia/react-env"
import moment from "moment"
import { Button, Grid, Typography } from "@mui/material"
import { SelectInput, TextFieldLabel } from "src/components/UI"
import UploadDocuments from "src/components/UI/CustomUI/molecules/UploadDocument"
import FontPicker from "font-picker-react";
import { Widget } from "src/ducks/types"
import Icon from "src/components/UI/CustomUI/atoms/Icon"
import { SelectInputOption } from "src/components/UI/CustomUI/atoms/SelectInput/types"
import { getCompaniesForDropdownWithoutAll } from "src/ducks/selectors"


const FILES_URL = env('FILES_URL') ?? ''
// const REACT_APP_GOOGLE_FONTS_API_KEY = env('REACT_APP_GOOGLE_FONTS_API_KEY') ?? ''
const fontSizeOptions = [
    { label: '6', key: '6' },
    { label: '7', key: '7' },
    { label: '8', key: '8' },
    { label: '9', key: '9' },
    { label: '10', key: '10' },
    { label: '11', key: '11' },
    { label: '12', key: '12' },
    { label: '14', key: '14' },
    { label: '16', key: '16' },
    { label: '18', key: '18' },
    { label: '20', key: '20' },
    { label: '24', key: '24' },
    { label: '26', key: '26' },
    { label: '28', key: '28' },
    { label: '32', key: '32' },
    { label: '36', key: '36' },
    { label: '38', key: '36' },
]
const AddWidget: FC = () => {

    const dispatch = useDispatch()
    const { dispatch: dispatchContext } = useContext(WidgetsContext)

    const companies = useSelector(getCompaniesForDropdownWithoutAll)

    const [loading, setLoading] = useState(false)
    const [showWarnings, setShowWarnings] = useState(false)
    const [uploadingFile, setUploadingFile] = useState(false)
    const [file, setFile] = useState<any>(null)

    // FORM

    const [newWidget, setNewWidget] = useState<Partial<Widget>>({
        fontFamily: 'Open Sans',
        buttonFontFamily: 'Open Sans',
    })

    const isValid =
        !isEmpty(newWidget?.name) &&
        !isEmpty(newWidget?.affiliate) &&
        !isEmpty(newWidget?.fontFamily) &&
        !isEmpty(newWidget?.fontColor) &&
        !isEmpty(newWidget?.headerFontSize?.toString()) &&
        !isEmpty(newWidget?.contentFontSize?.toString()) &&
        !isEmpty(newWidget?.backgroundColor) &&
        !isEmpty(newWidget?.imageUrl) &&
        !isEmpty(newWidget?.buttonFontFamily) &&
        !isEmpty(newWidget?.buttonFontSize?.toString()) &&
        !isEmpty(newWidget?.buttonFontColor) &&
        !isEmpty(newWidget?.buttonColor)



    const handleUploadFile = (file: null | File, callback: () => void) => {
        if (file) {
            setUploadingFile(true)
            dispatch(filesActions.uploadFile(file, (uploadSucc, fileName) => {
                if (uploadSucc && fileName) {
                    const fileToSave = {
                        name: file.name,
                        fileUrl: ParseFilesUrl({
                            baseUrl: FILES_URL,
                            avatar: fileName ?? ''
                        }),
                        description: file.name ?? '',
                        fileType: FILE_TYPE.MATERIAL_INVOICE,
                        uploadedOn: moment().unix(),

                    }
                    callback()
                    setFile(fileToSave)
                    setNewWidget({
                        ...newWidget,
                        imageUrl: fileToSave.fileUrl
                    })
                }
                setUploadingFile(false)
            }))
        } else {
            setFile(null)
        }
    }

    const handleCancel = () => {
        dispatchContext({ type: dispatchTypes.SET_VALUE, payload: { attr: 'modalOpen', value: false } })
    }

    const handleCreate = () => {
        if (isValid && newWidget) {
            setLoading(true)
            dispatch(widgetsActions.createWidget(newWidget, (succ) => {
                if (succ) {
                    dispatchContext({ type: dispatchTypes.SET_VALUE, payload: { attr: 'modalOpen', value: false } })
                    toast.success.call('success', 'Partner Created')
                    setLoading(false)
                } else {
                    setLoading(false)
                }
            }))
        } else {
            setShowWarnings(true)
        }

    }

    return (
        <PrivateLoader loading={loading}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextFieldLabel
                        label="Name"
                        labelVariant="h6"
                        onChange={(event) => setNewWidget({
                            ...newWidget,
                            name: event.target.value
                        })}
                        value={newWidget?.name || ''}
                        error={showWarnings && !newWidget?.name}
                    />
                </Grid>
                <Grid item xs={6}>
                    <SelectInput
                        label="Company"
                        options={companies}
                        onChange={(newSelected: SelectInputOption) => setNewWidget({
                            ...newWidget,
                            affiliate: newSelected.key
                        })}
                        value={newWidget.headerFontSize}
                        error={showWarnings && !newWidget?.headerFontSize}
                    />
                </Grid>
            </Grid>
            <Grid marginY={2}>
                <Typography variant='h5Bold'>Widget Content</Typography>
                <Grid container spacing={2} marginY={.5}>
                    <Grid item xs={6}>
                        <Typography variant="h6">
                            Font Family
                        </Typography>
                        <FontPicker
                            apiKey={'AIzaSyAog_b3tICviSL843KIwDdMIEoDzUMlQEs'}
                            activeFontFamily={newWidget?.fontFamily}
                            onChange={(nextFont) => setNewWidget({
                                ...newWidget,
                                fontFamily: nextFont.family
                            })}
                            key="font-picker1"
                            pickerId="fontpicker1"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextFieldLabel
                            label="Font Color"
                            labelVariant="h6"
                            onChange={(event) => setNewWidget({
                                ...newWidget,
                                fontColor: event.target.value
                            })}
                            value={newWidget?.fontColor || ''}
                            error={showWarnings && !newWidget?.fontColor}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} marginY={.5}>
                    <Grid item xs={6}>
                        <SelectInput
                            label="Font Size Header"
                            options={fontSizeOptions}
                            onChange={(newSelected: SelectInputOption) => setNewWidget({
                                ...newWidget,
                                headerFontSize: Number(newSelected.key)
                            })}
                            value={newWidget.headerFontSize}
                            error={showWarnings && !newWidget?.headerFontSize}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectInput
                            label="Font Size Content"
                            options={fontSizeOptions}
                            onChange={(newSelected: SelectInputOption) => setNewWidget({
                                ...newWidget,
                                contentFontSize: Number(newSelected.key)
                            })}
                            value={newWidget.contentFontSize}
                            error={showWarnings && !newWidget?.contentFontSize}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextFieldLabel
                            label="Background Color"
                            labelVariant="h6"
                            onChange={(event) => setNewWidget({
                                ...newWidget,
                                backgroundColor: event.target.value
                            })}
                            value={newWidget?.backgroundColor || ''}
                            error={showWarnings && !newWidget?.backgroundColor}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} marginY={.5}>
                    <Grid item xs={12}>
                        <Typography variant='h6' color='var(--gray700)'>Image:</Typography>
                        <UploadDocuments
                            // error={showWarnings && !file}
                            file={file ?? null}
                            dropzoneText='Image'
                            preview={true}
                            onChange={!uploadingFile ? handleUploadFile : () => { }}
                            error={showWarnings && !uploadingFile && !newWidget?.imageUrl}
                            onRemoveFile={() => setNewWidget({ ...newWidget, imageUrl: '' })}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid marginY={2}>
                <Typography variant='h5Bold'>Buttons</Typography>
                <Grid container spacing={2} marginY={.5}>
                    <Grid item xs={6}>
                        <Typography variant="h6">
                            Font Family
                        </Typography>
                        <FontPicker
                            apiKey={'AIzaSyAog_b3tICviSL843KIwDdMIEoDzUMlQEs'}
                            activeFontFamily={newWidget?.buttonFontFamily}
                            onChange={(nextFont) => setNewWidget({
                                ...newWidget,
                                buttonFontFamily: nextFont.family
                            })}
                            key="font-picker2"
                            pickerId="fontpicker2"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectInput
                            label="Font Size"
                            options={fontSizeOptions}
                            onChange={(newSelected: SelectInputOption) => setNewWidget({
                                ...newWidget,
                                buttonFontSize: Number(newSelected.key)
                            })}
                            value={newWidget.buttonFontSize}
                            error={showWarnings && !newWidget?.buttonFontSize}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} marginY={.5}>
                    <Grid item xs={6}>
                        <TextFieldLabel
                            label="Font Color"
                            labelVariant="h6"
                            onChange={(event) => setNewWidget({
                                ...newWidget,
                                buttonFontColor: event.target.value
                            })}
                            value={newWidget?.buttonFontColor || ''}
                            error={showWarnings && !newWidget?.buttonFontColor}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextFieldLabel
                            label="Button Color"
                            labelVariant="h6"
                            onChange={(event) => setNewWidget({
                                ...newWidget,
                                buttonColor: event.target.value
                            })}
                            value={newWidget?.buttonColor || ''}
                            error={showWarnings && !newWidget?.buttonColor}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid xs={12} item container spacing={2}>
                <Grid item xs={6}>
                    <Button
                        variant='containedLight'
                        fullWidth
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        onClick={handleCreate}
                        variant='contained'
                        fullWidth
                    >
                        Create Partner
                    </Button>
                </Grid>
            </Grid>
        </PrivateLoader >
    )
}

export default AddWidget