import React, { FC, useState } from 'react'
import styles from './styles.module.scss'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ContactItem from '../ContactItem'
import { Box, Divider } from '../../../../../../../UI'
import { ContactListProps } from './types'

const ContactList: FC<ContactListProps> = ({ contacts, selectedList, setSelectedList }) => {


  const handleSelect = (id: string): void => {
    const newSelectedList = selectedList.map((item, index) =>
      contacts[index].id === id ? !item : item
    )

    const selectedNum = newSelectedList.filter((item) => item === true).length

    setSelectedList(newSelectedList)
  }

  return (
    <List className={styles.List}>
      {contacts?.map((contact, index) => (
        <Box key={contact.id} flexDirection={'column'}>
          <ListItem className={styles.List__item}>
            <ContactItem
              contact={contact}
              selected={selectedList[index]}
              setSelected={handleSelect}
            />
          </ListItem>
          {index !== contacts.length - 1 && <Divider />}
        </Box>
      ))}
    </List>
  )
}

export default ContactList
