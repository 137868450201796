import { UpdateRequest } from "~api/types"
import { Contact, EstimateProperty, Item } from "../types"
import { EstimateItem } from "../types"
import { ESTIMATE_STATUS } from "src/helpers"

/** TYPES **/
export const estimateTypes = {
    FETCH_ESTIMATE: 'FETCH_ESTIMATE',
    CREATE_ESTIMATE_CONTACT: 'CREATE_ESTIMATE_CONTACT',
    UPDATE_ESTIMATE_PROPERTY: 'UPDATE_ESTIMATE_PROPERTY',
    SET_ESTIMATE: 'SET_ESTIMATE',
    CREATE_ESTIMATE: 'CREATE_NEW_ESTIMATE',
    UPDATE_ESTIMATE_BY_PATH: 'UPDATE_ESTIMATE_BY_PATH',
    SHARE_ESTIMATE: 'SHARE_ESTIMATE',
    SUBMIT_ESTIMATE: 'SUBMIT_ESTIMATE',
    CLONE_ESTIMATE: 'CLONE_ESTIMATE',
    UPDATE_ESTIMATE_CONTACT: 'UPDATE_ESTIMATE_CONTACT',
    OCR_REPROCESS: 'OCR_REPROCESS',
    ADD_ITEMS: 'ADD_ITEMS',
    REMOVE_ITEMS: 'REMOVE_ITEMS',
}

export interface ShareEstimateRequest {
    email: string
    role: string
    firstName: string
    lastName: string
}


/** ACTIONS **/
export interface FetchEstimateAction {
    type: typeof estimateTypes.FETCH_ESTIMATE
    payload: string
    callback?: (succ: boolean) => void
}


export interface CreateEstimateContactAction {
    type: typeof estimateTypes.CREATE_ESTIMATE_CONTACT
    payload: Contact
    callback?: (succ: boolean) => void
}

export interface CreateEstimate {
    type: typeof estimateTypes.CREATE_ESTIMATE
    payload: Partial<EstimateItem>
    callback?: (succ: boolean, estimateId: string | null) => void
}

export interface SubmitEstimateAction {
    type: typeof estimateTypes.SUBMIT_ESTIMATE
    payload: {
        estimatorId: string
        additionalInfo?: string
        newStatus: ESTIMATE_STATUS
    }
    callback?: (succ: boolean) => void
}

export interface CloneEstimateAction {
    type: typeof estimateTypes.CLONE_ESTIMATE
    payload: {
        id: string,
        shallow?: boolean,
    }
    callback?: (succ: boolean, newEstimate: Partial<EstimateItem>) => void
}

export interface UpdateEstimatePropertyAction {
    type: typeof estimateTypes.UPDATE_ESTIMATE_PROPERTY
    payload: Partial<EstimateProperty>
    callback?: (succ: boolean, newProperties?: Partial<EstimateProperty>) => void
}

export interface SetEstimateAction {
    type: typeof estimateTypes.SET_ESTIMATE
    payload: EstimateItem | null
}

export interface UpdateEstimateByPathAction {
    type: typeof estimateTypes.UPDATE_ESTIMATE_BY_PATH
    payload: UpdateRequest
    callback: (succ: boolean, estimateId?: string) => void
}

export interface ShareEstimateAction {
    type: typeof estimateTypes.SHARE_ESTIMATE
    payload: Partial<ShareEstimateRequest>
    callback?: (succ: boolean) => void
}

export interface UpdateEstimateContact {
    type: typeof estimateTypes.UPDATE_ESTIMATE_CONTACT
    payload: {
        contact: Partial<Contact>,
        idx: number
    }
    callback?: (succ: boolean) => void
}

export interface OcrEstimateAction {
    type: typeof estimateTypes.OCR_REPROCESS
    payload: string
    callback?: (succ: boolean) => void
}

export interface AddItemsToEstimateAction {
    type: typeof estimateTypes.ADD_ITEMS
    payload: Item[]
    callback?: (succ: boolean) => void
}

export interface RemoveItemsFromEstimateAction {
    type: typeof estimateTypes.REMOVE_ITEMS
    payload: Item[]
    callback?: (succ: boolean) => void
}


export type EstimateActions =
    | FetchEstimateAction
    | CreateEstimateContactAction
    | UpdateEstimatePropertyAction
    | SubmitEstimateAction
    | SetEstimateAction
    | CreateEstimate
    | UpdateEstimateByPathAction
    | ShareEstimateAction
    | CloneEstimateAction
    | UpdateEstimateByPathAction
    | UpdateEstimateContact
    | OcrEstimateAction
    | AddItemsToEstimateAction
    | RemoveItemsFromEstimateAction



/** REDUCER **/
export type EstimateType = EstimateItem | null