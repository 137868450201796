import { ScrollWrapper } from 'src/components/templates'
import { Box } from 'src/components/UI'
import { useIsXlScreen } from 'src/hooks'

const TabBoxMargin = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
    const [xlScreen] = useIsXlScreen()

    return (
      <Box display='grid' marginBottom='40px'>
          <ScrollWrapper relativeStyle={{ height: xlScreen ? '135px' : '130px', paddingBottom: '60px' }}>
              <Box display='grid' gap='6px'>
                  {children}
                </Box>
            </ScrollWrapper>
        </Box>
    )
}

export default TabBoxMargin
