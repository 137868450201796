import { Theme } from '../../../../../UI'
import { makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => ({
  container: {
    padding: '8px',
    flexFlow: 'column',
    border: '2px solid #EBECEF',
    width: '300px !important',
    gap: '8px',
    backgroundColor: 'white',
    boxShadow:
      '0px 1px 1px rgba(26, 42, 85, 0.24), 0px 0px 1px rgba(26, 42, 85, 0.31)',
    borderRadius: '8px',
    cursor: 'pointer',
    '&:hover': {
      border: '2px solid #3A66FF'
    }
  },
  street: {
    fontSize: '16px !important',
    fontWeight: 500,
    lineHeight: '24px !important',
    color: '#1A2A55',
  },
  location: {
    fontSize: '12px !important',
    fontWeight: 400,
    lineHeight: '18px !important',
    color: '#494A5A',
    width: 'fit-content',
  },
  date: {
    fontSize: '14px !important',
    fontWeight: 400,
    // lineHeight: '22px !important',
  },
  dateContainer: {
    height: '22px',
    alignItems: 'center',
    gap: '5px',
  },
  due: {
    lineHeight: '18px !important',
    color: '#494A5A',
  },
  buttonsContainer: {
    gap: '8px',
    height: '32px',
    width: 'fit-content !important',
  },
  assignButton: {
    height: '32px !important',
    borderRadius: '50%',
  },
  inUse: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'var(--navy-700)',
    borderRadius: '4px',
    height: '18px',
    color: 'var(--white-color)',
    border: 0,
    padding: '0 8px',
    gap: '4px',
  },
  affiliate: {
    backgroundColor: 'var(--blue700)',
    borderRadius: '4px',
    height: '18px',
    color: 'var(--white-color)',
    border: 0,
    padding: '0 8px',
  }
}))
