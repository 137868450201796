import { Box, Button, Grid, Typography } from "@mui/material"
import { FC, useState } from "react"
import { TextFieldLabel } from "src/components/UI"
import { isEmpty } from "src/helpers/index"
import useStyles from './styles'


export interface PromotionalCodeProps {
  // setPromoSuccess: (value: boolean) => void
}

const PromotionalCode: FC<PromotionalCodeProps> = () => {
  const classes = useStyles()

  const [promoCode, setPromoCode] = useState('')


  return (
    <Box>
      <Grid item container className={`${classes.promoCodeContainer}`} spacing={2}>

        <Grid item className={classes.promoCode}>
          <Typography className={classes.promoCodeTitle}>Promo Code</Typography>
          <Box position="relative">
            <TextFieldLabel
              label=''
              type='text'
              value={promoCode}
              onChange={(event: any) => {
                setPromoCode(event.target.value)
                // saveNewValue('promoCode', event.target.value)
              }}
              placeholder='Enter your Promo Code'
              autoComplete='off'
              className={classes.input}
            /*  onBlur={e => {
               isEmpty(promo) && dispatch(orderMaintenanceActions.setOrderMaintenanceValue({ attr: 'promoCode', value: null }))
             }} */
            />
            <Button className={classes.button} variant="contained" onClick={() => { }} disabled={isEmpty(promoCode)}>
              {'Apply Code'}
            </Button>
          </Box>

        </Grid>
      </Grid>
    </Box>
  )
}

export default PromotionalCode