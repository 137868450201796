/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo } from '../../helpers'
import { Punchlist } from '../../api'
import { notifTypes, FetchAction, UpdateNotificationAction } from './types'
import { notificationsActions } from '../actions'
import { UpdateRequest } from '~api/types'
import { NotificationItem } from '../types'

export function* fetchNotification({ payload, callback }: FetchAction): SagaIterator {
  let success = false
  try {
    const page = payload.page ?? 0
    const size = payload.size ?? 50

    const start = page * size
    const limit = start + size

    const searchParams = payload.searchParams ?? ''

    const notifications = yield call(Punchlist.notifications.getNotifications, { start, limit, searchParams })

    if (notifications) {
      yield put(notificationsActions.setNotifications(notifications))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the notifications'))
    if (callback) yield call(callback, false)
  }
}

export function* updateNotification({ notificationId, payload, callback }: UpdateNotificationAction): SagaIterator {
  let success = false

  try {
    const request: UpdateRequest = []

    for (const attr in payload) {
      request.push({
        op: 'replace',
        path: '/' + attr,
        value: payload[attr as keyof NotificationItem]
      })
    }

    yield call(Punchlist.notifications.updateNotification, notificationId, request)
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the order history'))
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(notifTypes.FETCH_NOTIFICATIONS, fetchNotification)
  yield takeLatest(notifTypes.UPDATE_NOTIFICATION, updateNotification)
}
