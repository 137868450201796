import { CSVLink } from "react-csv";
import Icon from "../../atoms/Icon";
import ActionsButton from "../ActionsButton";
import { ExportCSVProps } from "./types";

const ExportCSV = ({ headers, innerRef, fileName, data, label = <ActionsButton icon={<Icon name='CloudDownload' />} /> }: ExportCSVProps) => {
  return (
    <>
        <CSVLink
          headers={headers}
          data={data}
          filename={fileName}
          target="_blank"
          ref={innerRef}
        >
          {label}
        </CSVLink>
    </>
  )
}

export default ExportCSV