import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Table from 'src/components/UI/CustomUI/organisms/Table'
import { notesActions } from 'src/ducks/actions'
import { getJob, getNotes } from 'src/ducks/selectors'
import headers from './headers'
import { NOTE_TYPE, ROWS_PER_PAGE_SIMPLE } from 'src/helpers'

const Notes = ({ id }: { id?: string }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  //const jobNotes = useSelector(getNotes)
  //const dispatch = useDispatch()
  const notes = useSelector(getNotes())

  const job = useSelector(getJob())

  const [page, setPage] = useState(0)
  const [size, setSize] = useState(50)
  const [historyRecords, setHistoryRecords] = useState(notes)

  useEffect(() => {
    updatePage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, size, notes])

  const updatePage = () => {
    const start = page * size
    const end = start + size > notes.length ? notes.length : start + size

    setHistoryRecords(notes.slice(start, end))
  }


  const fetchJobNotes = useCallback(() => {
    if (id) {
      dispatch(
        notesActions.getNotes({ params: { noteType: NOTE_TYPE.JOB_GENERAL, resourceId: id as string } }, (_succ: boolean) => {
          setLoading(false)
        })
      )
    }
  }, [dispatch, id])


  useEffect(() => {
    fetchJobNotes()
  }, [fetchJobNotes, job])

  return (
    <Table
      headers={headers}
      title="OVERALL"
      loading={loading}
      totalItems={notes.length}
      rowsPerPageDefault={50}
      rowsPerPageOptions={ROWS_PER_PAGE_SIMPLE}
      callToApi={(start, limit) => {
        setPage(start)
        setSize(limit)
      }}
      hasTopPagination
      hasPagination={false}
    >
      {historyRecords}
    </Table>
  )
}

export default Notes;