import { RepairListItem } from 'src/ducks/types'
import { formatParams } from 'src/helpers'
import axios from '../axios'
import { ApiController } from './types'

const base =
  '/repairItems'

const apiController: ApiController = {
  getRepairList: async ({ start, limit, searchParams }) => {
    const params = formatParams(searchParams)

    return await axios
      .get(`${base}?${params}`, {
        params: {
          start,
          limit,
        },
      })
      .then((response) => response.data)
  },

  getRepairItem: async ({ id }) => {
    return await axios.get(`${base}/${id}`).then((response) => response.data)
  },

  getPriceList: async ({ id }) => {
    return await axios
      .get(`${base}/${id}/priceItems`)
      .then((response) => response.data)
  },

  getTerritoryPriceItem: async ({ repairItemId, territoryId }) => {
    return await axios
      .get(`${base}/${repairItemId}/territories/${territoryId}`)
      .then((response) => response.data)
  },

  createRepairItem: async (request: Partial<RepairListItem>) => {
    return await axios
      .post(`${base}`, request)
      .then((response) => response.data)
  },

  updateRepairItem: async ({ id, request }) => {
    return await axios
      .patch(`${base}/${id}`, request)
      .then((response) => response.data)
  },

  updatePriceItem: async ({ repairItemId, territoryId, request }) => {
    return await axios
      .patch(`${base}/${repairItemId}/territories/${territoryId}`, request)
      .then((response) => response.data)
  },

  deleteRepairItem: async ({ id }) => {
    return await axios.delete(`${base}/${id}`).then((response) => response.data)
  },

  updateAllPriceItems: async ({ repairItemId, request }) => {
    return await axios
      .patch(`${base}/${repairItemId}/territories`, request)
      .then((response) => response.data)
  },

  updatedSelectedPriceItems: async ({ repairItemId, ids, request }) => {
    return await axios
      .patch(`${base}/${repairItemId}/priceItems`, { ids, patch: request })
      .then((response) => response.data)
  },
}

export default apiController
