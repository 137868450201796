import { FC, useState } from "react";
import { Grid, IconButton, Popover, Typography } from "src/components/UI";
import { ShowNotesProps } from "./types";
import CommentIcon from '@mui/icons-material/Comment';

const ShowNotes: FC<ShowNotesProps> = ({ notes }) => {

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <div>
            <Grid container onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose} sx={{ marginTop: '3px' }}>
                <Grid item xs={6}>
                    <IconButton disableTouchRipple disableFocusRipple disableRipple  >
                        <CommentIcon style={{ color: 'red' }} />
                    </IconButton>
                </Grid>
                <Grid item xs={6}>
                    <Typography
                        sx={{ marginTop: '10px', marginLeft: '5px' }}
                        aria-owns={open ? 'mouse-over-popover' : undefined}
                        aria-haspopup="true"
                    >
                        Notes!
                    </Typography>
                </Grid>

            </Grid>


            <Popover
                id="mouse-over-popover"
                sx={{
                    pointerEvents: 'none',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Typography sx={{ p: 1 }}>{`Note: ${notes}`}</Typography>
            </Popover>
        </div>
    );
}

export default ShowNotes;