import {
  AlignType,
  HeaderType,
} from 'src/components/UI/CustomUI/organisms/Table/types'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import { Grid, Typography } from '@mui/material'

const headers: HeaderType[] = [
  {
    id: 'inspectionReportReference',
    label: 'Ref.',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Typography fontSize="14px">
          {row.inspectionReportReference || '-'}
        </Typography>
      )
    }
  },
  {
    id: 'inspectionReportNoteHeader',
    label: 'Heading',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Typography fontSize="14px">
          {row.inspectionReportNoteHeader || '-'}
        </Typography>
      )
    }
  },
  {
    id: 'inspectionReportNote',
    label: 'Notes',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Typography fontSize="14px">
          {row.inspectionReportNote || '-'}
        </Typography>
      )
    }
  },
  {
    id: 'images',
    label: 'Images',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Typography fontSize="14px">
          {row.imageFiles?.length}
        </Typography>
      )
    }
  },
]

export default headers
