import { FC } from 'react'
import { unMaskedInput } from 'src/helpers'
import { TextFieldLabel } from '../../..'
import { CurrencyTextFieldProps } from './types'

const CurrencyTextField: FC<CurrencyTextFieldProps> = ({
  label = '',
  labelVariant = "body1",
  labelColor = 'inherit',
  type = '',
  value,
  error = false,
  helperText,
  placeholder = "$0.00",
  className,
  onChange = () => { },
  onBlur = () => { },
  autoComplete = 'off',
  mask = undefined,
  size = "medium",
  textFieldStyle = {},
  disabled,
  name,
  id
}) => {

  return (
    <TextFieldLabel
      autoComplete={autoComplete}
      label={label}
      value={value ? value?.toString() : undefined}
      size={size}
      onChange={(e) => {
        onChange(unMaskedInput(e.target.value))
      }}
      onBlur={(e) => {
        onBlur(unMaskedInput(e.target.value))
      }}
      labelVariant={labelVariant}
      mask={mask}
      disabled={disabled}
      name={name}
      id={id}
      error={error}
      labelColor={labelColor}
      type={type}
      helperText={helperText}
      placeholder={placeholder}
      className={className}
      textFieldStyle={textFieldStyle}
    />
  )
}

export default CurrencyTextField
