import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Table from 'src/components/UI/CustomUI/organisms/Table'
import { getJob } from 'src/ducks/selectors'
import { PunchlistFile } from 'src/ducks/types'
import headers from './headers'

const Customers = () => {
  const DEFAULT_PAGE_SIZE = 10
  const [loading] = useState(false)
  const job = useSelector(getJob())

  const jobFiltered = job?.fileDetails?.filter((file) =>
    file.uploadedByUserType && file.uploadedByUserType.toLowerCase() === 'client')
  /* const jobFiltered = job?.properties.files.filter((file) =>
      file.uploadedByUserType && file.uploadedByUserType.toLowerCase() === 'client') */

  const [filteredFiles, setFilteredFiles] = useState<PunchlistFile[] | undefined>(jobFiltered ? jobFiltered?.slice(0, DEFAULT_PAGE_SIZE) : [])
  const filterFiles = (page: number, pageSize: number) => {
    if (!jobFiltered) {
      setFilteredFiles([])
    }

    setFilteredFiles(jobFiltered?.filter((file, idx) => {
      if (idx >= page * pageSize && idx <= page * pageSize + pageSize - 1) {
        return true
      }
      return false
    }))

  }

  useEffect(() => {
    filterFiles(0, 10)
  }, [job])

  return (
    <Table
      headers={headers}
      title='CUSTOMERS'
      loading={loading}
      callToApi={filterFiles}
      totalItems={jobFiltered ? jobFiltered.length : 0}
      hasTopPagination
      hasPagination={false}
      rowsPerPageDefault={DEFAULT_PAGE_SIZE}
    >
      {filteredFiles}
    </Table>
  )
}

export default Customers
