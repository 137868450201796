import { FC, useState } from 'react'
import { email } from 'src/assets/index'
import { Box } from 'src/components/UI'
import SuccessModal from 'src/components/UI/CustomUI/molecules/SuccessModal'
import { OPTIONS } from '../..'
import ContactInformation from './options/ContactInformation'
import Initial from './options/Initial'
import useStyles from './styles'

export enum COMMUNICATION_METHODS {
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
  TEXT = 'TEXT',
}

interface AskAQuestionProps {
  setActiveOption: (option: OPTIONS) => void
  setOpen: (open: boolean) => void
}

export enum SUB_OPTIONS {
  initial = 'initial',
  contactInformation = 'contactInformation',
  success = 'success'
}

const AskAQuestion: FC<AskAQuestionProps> = ({ setActiveOption, setOpen }) => {
  const styles = useStyles()
  const [activeSubOption, setActiveSubOption] = useState<SUB_OPTIONS>(SUB_OPTIONS.initial)
  const [question, setQuestion] = useState('')
  const [selectedContactMethod, setSelectedContactMethod] = useState<COMMUNICATION_METHODS>()

  const handleClick = (subOption: SUB_OPTIONS) => {
    if (subOption === SUB_OPTIONS.initial) setActiveOption(OPTIONS.initial)
    setActiveSubOption(subOption)
  }

  const options = [
    {
      content: <Initial setSubActiveOption={handleClick} setQuestion={setQuestion} question={question} />,
      id: 'initial'
    },
    {
      content: <ContactInformation setSelectedContactMethod={setSelectedContactMethod} selectedContactMethod={selectedContactMethod} setSubActiveOption={(option: SUB_OPTIONS) => setActiveSubOption(option)} onSubmit={() => { }} />,
      id: 'contactInformation'
    },
    {
      content:
        <SuccessModal
          img={email}
          title="your question has been submitted!"
          subtitle="Our team will contact you soon!"
          onClose={() => {
            setOpen(false)
            setActiveOption(OPTIONS.initial)
          }}
        />,
      id: 'success'
    },
  ]
  const activeOptionIndex = options.findIndex(option => option.id === activeSubOption)

  const content = options[activeOptionIndex]?.content ?? 'Nothing here..'

  return (
    <Box width={"100%"} display="flex" flexDirection="column" className={styles.root}>
      {content}
    </Box>
  )
}
export default AskAQuestion
