import { authTypes } from '../auth/types'
import { PaymentType } from '../types'
import { PaymentsActions, PaymentsType, paymentsTypes } from './types'

const initialState: PaymentsType = {
  start: '',
  limit: '',
  total: '',
  items: [],
}

const reducer = (state = initialState, action: PaymentsActions): PaymentsType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case paymentsTypes.SET_PAYMENTS: {
      return {
        ...state,
        start: (payload as PaymentType).start,
        limit: (payload as PaymentType).limit,
        items: (payload as PaymentType).items,
        total: (payload as PaymentType).total
      }
    }

    default:
      return state
  }
}

export default reducer
