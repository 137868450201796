import storeInfo from '../store'
import { orderBy as SortBy } from 'lodash'
import { Email, Vendor } from '../types'
import { formatPhone } from 'src/helpers'
import { StatusBoxProps } from 'src/components/UI/CustomUI/molecules/StatusBox/types'

export const getVendorPrimaryEmail = (vendor: Vendor) => {
  return (
    (vendor?.email as Email[])?.find(
      (email: Email) => email?.emailType === 'PRIMARY'
    )?.email ?? ''
  )
}

const getContactName = (vendor: Vendor) => {
  return `${vendor.firstName} ${vendor.lastName} ${getVendorPrimaryEmail(
    vendor
  )}`
}

export const getVendorsForTable =
  (orderBy?: string, order?: 'asc' | 'desc') => (): Vendor[] => {
    const {
      vendors: { items },
    } = storeInfo.store.getState()
    if (!items || !items.length) return []
    if (orderBy && order) return SortBy(items, orderBy, order) as Vendor[]
    return items.map((vendor: Vendor) => {
      return {
        ...vendor,
        companyName: vendor.companyName || '/',
        contact: getContactName(vendor),
        // phone: formatPhone(vendor.phone),
        territories: vendor.territoryList
          .flatMap((territory) => territory.title)
          .join(', '),
      }
    })
  }

export const getTotalVendors = () => {
  const { vendors } = storeInfo.store.getState()
  return vendors.total || 0
}

export const getVendorById = (vendorId: string) => (): Vendor | null => {
  const {
    vendors: { items },
  } = storeInfo.store.getState()
  return items.find((vendor) => vendor.id === vendorId) || null
}

const getVendorByTerr = (vendors: Vendor[], territory: string) => {
  if (territory === undefined || territory === 'All Territories') return vendors
  return vendors.filter((vendor) =>
    vendor.territoryList
      .reduce((acc: any, curr: any) => acc.concat(curr.title), [])
      .includes(territory)
  )
}

const getVendorByStage = (vendors: Vendor[], stage: string) => {
  if (stage === undefined || stage === 'All Verification Stages') return vendors
  return vendors.filter((vendor) => vendor.verificationStage.includes(stage))
}

const findVendorTrade = (vendor: Vendor, trades: any[]) => {
  let find = false
  vendor.tradeList.forEach((trade) => {
    if (trades.includes(trade.tradeType?.displayName)) find = true
  })
  return find
}

const getVendorByTrade = (vendors: Vendor[], trades: any[]) => {
  const res = trades
    .filter((trade) => !trade.hide)
    .reduce((acc: any, curr: any) => curr.label, [])
  if (res.length === 0) return vendors

  return vendors.filter((vendor) => findVendorTrade(vendor, res))
}

const findVendorLicense = (vendor: Vendor, licensings: any[]) => {
  let find = false

  licensings.forEach((license) => {
    if (!license.isSeparator) {
      switch (license.id) {
        case 'approved':
          if (!find) find = vendor.approved
          break
        case 'unapproved':
          if (!find) find = vendor.approved === null || !vendor.approved
          break
        case 'gl':
          const territoriesGL = vendor.territoryList.filter(
            (territory: any) => territory.validGL
          )
          if (!find) find = territoriesGL.length > 0
          break
        case 'wc':
          const territoriesWC = vendor.territoryList.filter(
            (territory: any) => territory.validWC
          )
          if (!find) find = territoriesWC.length > 0
          break
        case 'ach':
          if (!find) find = vendor.hasACH
          break
        case 'w_9':
          if (!find) find = vendor.hasW9
          break
        case 'tax_id':
          if (!find) find = vendor.hasTaxID
          break
        default:
          break
      }
    }
  })

  return find
}

const getVendorByLicense = (vendors: Vendor[], licensings: any[]) => {
  const res = licensings.filter((trade) => !trade.hide)
  if (res === undefined || res.length === 0) return vendors

  return vendors.filter((vendor) => findVendorLicense(vendor, res))
}

export const getVendorSearch = (vendors: Vendor[], search: string) => {
  if (search === '') return vendors
  return vendors.filter(
    (vendor) =>
      vendor.firstName?.toLowerCase().includes(search) ||
      vendor.lastName?.toLowerCase().includes(search)
  )
}

export const getVendorByFilter =
  (
    vendors: Vendor[],
    territory: string,
    stage: string,
    trades: any[],
    licensings: any[],
    search: string
  ) =>
    (): Vendor[] => {
      const terr = getVendorByTerr(vendors, territory)
      const sta = getVendorByStage(terr, stage)
      const trade = getVendorByTrade(sta, trades)
      const lic = getVendorByLicense(trade, licensings)
      return getVendorSearch(lic, search)
    }

export const getVendorsForDispatch = () => (): Vendor[] => {
  const {
    vendors: { items },
  } = storeInfo.store.getState()
  if (!items || !items.length) return []
  const result = items.map((vendor: Vendor) => {
    return {
      ...vendor,
      companyName: vendor.companyName || '/',
      contact: getContactName(vendor),
      phone: formatPhone(vendor.phone),
    }
  })
  return result
}

export const getVendorBoxes = () => (): StatusBoxProps[] => {
  const {
    vendors: { counts, total },
  } = storeInfo.store.getState()

  // TODO: replace with constants when they are defined
  // const total = counts?.find((count: { status: string }) =>
  //   count.status?.includes('Verified')
  // )

  const compliant = counts.find((count) => count.status === 'Compliant')?.count || 0
  const notInterested = counts.find((count) => count.status?.includes('Not Interested'))?.count || 0
  const connectedNotApproved = counts.find((count) => count.status?.includes('Pending Approval'))?.count || 0
  const pendingLicensing = counts.find((count) => count.status?.includes('Pending Background Check'))?.count || 0
  const approved = counts.find(count => count.status === 'Approved')?.count || 0

  const data: StatusBoxProps[] = [
    {
      statName: 'Total',
      statValue: total || 0,
      borderColor: 'info',
    },
    { statName: 'Approved', statValue: approved, borderColor: 'success' },
    { statName: 'Connected not approved', statValue: connectedNotApproved },
    { statName: 'Pending Licensing', statValue: pendingLicensing },
    { statName: 'Complaint', statValue: compliant },
    { statName: 'Not Interested', statValue: notInterested, borderColor: 'secondary' },
  ]
  return data
}

export const getVendors = () => (): Vendor[] => {
  const {
    vendors: { items },
  } = storeInfo.store.getState()

  return items
}

export const getVendorsTabPL = () => (): Vendor[] => {

  const {
    vendors: { items },
  } = storeInfo.store.getState()

  const resultPL = items.filter(
    (items) =>
      items.email.find(e => e.email.split('@')[1] === "punchlistsc.com")
  )

  if (!resultPL || !resultPL.length) return []
  const result = resultPL.map((vendor: Vendor) => {
    return {
      ...vendor,
      companyName: vendor.companyName || '/',
      contact: getContactName(vendor),
      phone: formatPhone(vendor.phone),
    }
  })
  return result
}

export const getVendorsTabPRO = () => (): Vendor[] => {

  const {
    vendors: { items },
  } = storeInfo.store.getState()

  const resultPro = items.filter(
    (items) =>
      items.email.find(e => e.email.split('@')[1] !== "punchlistsc.com")
  )

  if (!resultPro || !resultPro.length) return []
  const result = resultPro.map((vendor: Vendor) => {
    return {
      ...vendor,
      companyName: vendor.companyName || '/',
      contact: getContactName(vendor),
      phone: formatPhone(vendor.phone),
    }
  })
  return result
}


export const getPunchlistVendors = () => (): Vendor[] => {
  const {
    vendors: { punchlistVendors },
  } = storeInfo.store.getState()

  return punchlistVendors || []
}