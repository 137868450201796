import { SelectInputOption } from 'src/components/UI/CustomUI/atoms/SelectInput/types'
import storeInfo from '../store'
import { InspectorCompany } from '../types'

export const getInspectors = (): InspectorCompany => {
  const { inspectors } = storeInfo.store.getState()
  return inspectors
}

export const getInspectorsForSelect = (): SelectInputOption[] => {
  const { inspectors }: { inspectors: InspectorCompany[] } =
    storeInfo.store.getState()
  const flattenedData = inspectors?.reduce(
    (acc: any, curr: InspectorCompany) => {
      return [
        ...acc,
        (acc = {
          key: curr,
          label: curr.name,
        }),
      ]
    },
    []
  )
  return flattenedData
}
