import { FC } from 'react'
import { ImagesSelectorProps } from './types'
import useStyles from './styles'
import ImagePicker from '../../atoms/ImagePicker'
import { Box, Grid, Typography, Button } from '@mui/material'
import Slider from '../Slider'
import Icon from '../../atoms/Icon'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

const GeneralImagesSelector: FC<ImagesSelectorProps> = ({
  submitAction = () => { },
  imageFiles,
  images = [],
  disabled = false,
  multiple = false,
  height,
  columns = 3,
  isSlider = false,
  hasImageLoader,
  hasAllImagesButton,
  width = 735,
  imageNumber = 3,
  minHeight,
  minWidth,
  handleAllImage = () => {},
}) => {
  const classes = useStyles()
  const handleUploadImage = (newImage: File[]): void => {
    const newImageArr = [...images.filter(i => i !== null), ...newImage]
    if (submitAction) submitAction(newImageArr)
  }

  const handleDeleteImageFile = (index: number): void => {
    if (index && index > (imageFiles?.length || 0)) return
    const filteredArray = imageFiles?.filter((_, i) => i !== index)
    if (submitAction) submitAction(filteredArray, true)
  }

  const handleDeleteImage = (index: number) => {
    if (index && index > images.length) return
    const filteredArray = images.filter((_, i) => i !== index)
    if (submitAction) submitAction(filteredArray)
  }

  const randomNumber = () => {
    return Math.floor(Math.random() * 1000 - 1)
  }

  return (
    <Grid container spacing={2} maxHeight="300px" overflow="scroll">
      <>

        {/* {isSlider && imageFiles && imageFiles.length > 0
          ?
          <Box maxWidth={`100%`} marginLeft='16px' marginY='16px' position="relative" padding="0 1.5rem">
            <div className="swiper-button-prev-unique slider-arrow"><Icon name="ArrowBackIos" /></div>
            <Slider
              slidesPerView={imageFiles.length > 3 ? 3 : imageFiles.length}
              spaceBetween={20}
              slideToClickedSlide
              className={classes.slider}
              navigation={{
                nextEl: '.swiper-button-next-unique',
                prevEl: '.swiper-button-prev-unique'
              }}
            >
              {imageFiles.map((image, index) => {
                return <img width='100%' key={index} src={image.fileUrl} alt="selector" style={{ borderRadius: 8, maxWidth: "250px" }} />
              })}
            </Slider>
            <div className="swiper-button-next-unique slider-arrow"><Icon name="ArrowForwardIos" /></div>
          </Box>
          :
          <Grid item className={`${classes.root}`} sx={{ gridTemplateColumns: `repeat(${columns}, 1fr)` }}>
            {imageFiles && imageFiles.map((image, index) => {
              return (
                <ImagePicker
                  key={index}
                  imageUrl={image.fileUrl}
                  handleUploadFile={handleUploadImage}
                  disabled
                  multiple={multiple}
                  height={height}
                  hasImageLoader={hasImageLoader}
                />
              )
            })}
          </Grid>
        } */}
        {isSlider && images.length > 0 ?
          <Box maxWidth={`${width < 735 ? width : 735}px`} marginLeft='16px' marginY='16px' position="relative" padding="0 1.5rem">
            <div className="swiper-button-prev-slider2 slider-arrow"><Icon name="ArrowBackIos" /></div>
            <Slider
              slidesPerView={images.length > imageNumber ? imageNumber : images.length}
              spaceBetween={20}
              slideToClickedSlide
              className={classes.slider}
              navigation={{
                nextEl: '.swiper-button-next-slider2',
                prevEl: '.swiper-button-prev-slider2'
              }}
            >
              {images.map((image: any, index) => {
                return (
                  <ImagePicker
                    imageUrl={URL.createObjectURL(image)}
                    handleUploadFile={handleUploadImage}
                    handleDeleteFile={() => handleDeleteImage(index)}
                    disabled={disabled || !submitAction}
                    key={
                      image
                        ? image.lastModified + randomNumber()
                        : index + randomNumber()
                    }
                    maxWidth="250px"
                    multiple={multiple}
                    height={height}
                    hasImageLoader={hasImageLoader}
                  />
                )
              })}
            </Slider>
            <div className="swiper-button-next-slider2 slider-arrow"><Icon name="ArrowForwardIos" /></div>
          </Box>
          :
          <Grid item className={`${classes.root}`} sx={{ gridTemplateColumns: `repeat(${columns}, 1fr)` }}>
            <ImagePicker
              handleUploadFile={handleUploadImage}
              disabled={disabled || !submitAction}
              multiple={multiple}
              height={height}
              hasImageLoader={hasImageLoader}
              component={
                <Box className={classes.addImage} minWidth={minWidth || '140px'} minHeight={minHeight || '140px'}>
                  <AddPhotoAlternateIcon style={{ width: '38px', height: '38px' }}/>
                  <Typography>Add Image</Typography>
                </Box>
              }
            />
            {imageFiles && imageFiles.map((image, index) => {
              return (
                <ImagePicker
                  key={index}
                  imageUrl={image.fileUrl}
                  handleUploadFile={handleUploadImage}
                  handleDeleteFile={() => handleDeleteImageFile(index)}
                  disabled={disabled || !submitAction}
                  multiple={multiple}
                  height={height}
                  hasImageLoader={hasImageLoader}
                />
              )
            })}
            {images.map((image: any, index) => {
              return (
                <ImagePicker
                  imageUrl={URL.createObjectURL(image)}
                  handleUploadFile={handleUploadImage}
                  handleDeleteFile={() => handleDeleteImage(index)}
                  disabled={disabled || !submitAction}
                  key={
                    image
                      ? image.lastModified + randomNumber()
                      : index + randomNumber()
                  }
                  multiple={multiple}
                  height={height}
                  hasImageLoader={hasImageLoader}
                />
              )
            })}
          </Grid>
        }
      </>
    </Grid>
  )
}

export default GeneralImagesSelector
