import { MaterialItem } from '../types'

/** TYPES **/
export const materialsTypes = {
  FETCH_MATERIALS: 'FETCH_MATERIALS',
  SET_MATERIALS: 'SET_MATERIALS',
}

/** ACTIONS **/
export interface FetchMaterials {
  type: typeof materialsTypes.FETCH_MATERIALS
  payload: string[]
  callback?: (succ: boolean) => void
}

export interface SetMaterials {
  type: typeof materialsTypes.SET_MATERIALS
  payload: MaterialsType
}

export type MaterialsActions =
  | FetchMaterials
  | SetMaterials

/** REDUCER **/
export type MaterialsType = MaterialItem[]
