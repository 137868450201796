import { CompanyItem } from "../types"

/** TYPES **/
export const companyTypes = {
  FETCH_COMPANY: 'FETCH_COMPANY',
  SET_COMPANY: 'SET_COMPANY',
}

/** ACTIONS **/
export interface FetchCompanyAction {
  type: typeof companyTypes.FETCH_COMPANY
  payload: string
  callback?: (succ: boolean) => void
}

export interface SetCompanyAction {
  type: typeof companyTypes.SET_COMPANY
  payload: CompanyType
  callback?: (succ: boolean) => void
}

export type CompanyActions = FetchCompanyAction | SetCompanyAction

/** REDUCER **/
export type CompanyType = CompanyItem | null
