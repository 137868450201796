import { Theme } from '../../../UI'
import { makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => ({
  root: {
    display: 'grid',
    height: '100vh',
    gap: theme.spacing(4),
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center'
  },
  animation: {
    display: 'flex',
    justifyContent: 'center'
  },
  loader: {
    width: '100%',
    margin: 'auto',
    borderRadius: '16px',
    maxWidth: '280px',
    height: '8px',
    color: 'var(--blue700)',
    [theme.breakpoints.up('md')]: {
      maxWidth: '240px'
    }
  }
}))
