import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow } from 'src/components/UI'
import { getSubscription } from 'src/ducks/selectors'
import { SubscriptionItem } from 'src/ducks/types'
import { formatTimestamp } from 'src/helpers'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'

import styles from './styles.module.scss'

const Files: FC = () => {
  const subscription = useSelector(getSubscription())

  const { files } = subscription as SubscriptionItem

  return (
    <Box>
      <Table sx={{ width: '100%' }}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>View</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {files.map((file) => (
            <TableRow key={file?.id}>
              <TableCell component='th' scope='row'>
                {file?.name || ''}
              </TableCell>
              <TableCell align='left'>
                {file?.uploadedOn ? formatTimestamp(file?.uploadedOn, "MM/DD/YYYY") : ''}
              </TableCell>
              <TableCell align='left'>
                {file?.fileType || ''}
              </TableCell>
              <TableCell align='left'>
                <Button
                  variant="contained"
                  className={styles.Files__viewButton}
                  onClick={() => window.open(file.fileUrl, '_blank')}
                  endIcon={
                    <Icon
                      color="inherit"
                      name="NorthEast"
                      width="1rem"
                      height="12px"
                    />
                  }
                >
                  View
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  )
}

export default Files
