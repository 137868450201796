import { FC, useState, useRef } from 'react'
import { FormControl, IconButton, FormHelperText } from '../../..'
import { icons } from '../../../../../assets'
import { PasswordProps } from './types'
import theme from '../../../MaterialUI/theme'
import { InputAdornment, TextField } from 'src/components/UI/MaterialUI'

const Password: FC<PasswordProps> = ({
  value,
  onChange,
  error,
  helperText,
  placeholder,
  fullWidth,
}) => {

  const [showPassword, setShowPassword] = useState(false)
  const [focused, setFocused] = useState(false)

  const handleClickShowPassword = (): void => {
    setShowPassword(!showPassword)
  }

  const passField = useRef<any>(null)

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    event.preventDefault()
  }

  const handleFocusChange = () => {
    setFocused(!focused)
  }

  return (
    <FormControl variant='outlined' size='small' fullWidth={fullWidth} error={error} >
      <TextField
        id="current-password"
        ref={passField}
        autoComplete='current-password'
        type={showPassword ? 'text' : 'password'}
        value={value}
        onChange={onChange}
        onFocus={handleFocusChange}
        placeholder={placeholder}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                <icons.Visibility
                  style={{
                    color: showPassword
                      ? 'var(--teal-700)'
                      : theme.palette.grey[400]
                  }}
                />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>
  )
}

export default Password
