import { FC } from 'react'
import { FormControlLabel, Typography, Checkbox } from '@mui/material'
import { AdminCheckboxProps } from './types'

const AdminCheckbox: FC<AdminCheckboxProps> = ({
  label,
  checked,
  onChange,
  property,
}) => {
  return (
    <FormControlLabel
      label={<Typography variant="body2">{label}</Typography>}
      control={
        <Checkbox
          checked={checked}
          onChange={() => onChange(property)}
          color="infoText"
        />
      }
    />
  )
}

export default AdminCheckbox
