import storeInfo from '../store'
import { PriceItemType, RepairListItem } from '../types'

export const getRepairItem = (): RepairListItem => {
  const {
    repairItem: { item },
  } = storeInfo.store.getState()
  return item
}

export const getPriceList = (): PriceItemType[] => {
  const {
    repairItem: { priceList },
  } = storeInfo.store.getState()
  return priceList
}

export const getPriceItemById = (id: string): PriceItemType => {
  const {
    repairItem: { priceList },
  } = storeInfo.store.getState()
  return priceList.find((item) => item.id === id) as PriceItemType
}

export const getPriceItem = (): PriceItemType => {
  const {
    repairItem: { priceItem },
  } = storeInfo.store.getState()

  return priceItem
}

export const getRepairItemId = (): string => {
  const {
    repairItem: { item },
  } = storeInfo.store.getState()

  return item.id
}
