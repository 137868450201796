import { Partner } from '../types'

/** TYPES **/
export const homemartTypes = {
  FETCH_PARTNERS: 'FETCH_PARTNERS',
  CREATE_HOMEMART: 'CREATE_HOMEMART',
  UPDATE_HOMEMART: 'UPDATE_HOMEMART',
  DELETE_HOMEMART: 'DELETE_HOMEMART',
  SET_HOMEMART: 'SET_HOMEMART',
  FETCH_PARTNER_CATEGORIES: 'FETCH_PARTNER_CATEGORIES',
  FETCH_PARTNER_TAGS: 'FETCH_PARTNER_TAGS',
}

/** ACTIONS **/
export interface FetchPartnersAction {
  type: typeof homemartTypes.FETCH_PARTNERS
  payload: { active: boolean }
  callback?: (succ: boolean) => void
}

export interface CreatePartnerAction {
  type: typeof homemartTypes.CREATE_HOMEMART
  payload: Partial<Partner>
  callback?: (succ: boolean) => void
}

export interface UpdatePartnerAction {
  type: typeof homemartTypes.UPDATE_HOMEMART
  payload: Partial<Partner>
  callback?: (succ: boolean) => void
}

export interface DeletePartnerAction {
  type: typeof homemartTypes.DELETE_HOMEMART
  payload: string
  callback?: (succ: boolean) => void
}

export interface FetchPartnerCategories {
  type: typeof homemartTypes.FETCH_PARTNER_CATEGORIES
  payload: null
  callback?: (succ: boolean) => void
}

export interface FetchPartnerTags {
  type: typeof homemartTypes.FETCH_PARTNER_TAGS
  payload: null
  callback?: (succ: boolean) => void
}

export interface SetHomemartAction {
  type: typeof homemartTypes.SET_HOMEMART
  payload: HomemartType
}

export type HomemartActions =
  | FetchPartnersAction
  | CreatePartnerAction
  | UpdatePartnerAction
  | DeletePartnerAction
  | FetchPartnerCategories
  | FetchPartnerTags
  | SetHomemartAction

/** REDUCER **/
export type HomemartType = {
  partners: Partner[]
  categories: { id: string, name: string }[]
  tags: { id: string, name: string }[]
}
