import { authTypes } from '../auth/types'
import { MaterialInvoice } from '../types'
import { MaterialInvoices, MaterialInvoiceActions, materialInvoiceTypes } from './types'


const initialState: MaterialInvoices = []

const reducer = (state = initialState, action: MaterialInvoiceActions): MaterialInvoices => {
  const { type, payload } = action

  switch (type) {

    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case materialInvoiceTypes.SET_MATERIAL_INVOICES: {
      return payload as MaterialInvoices
    }

    case materialInvoiceTypes.SET_MATERIAL_INVOICE: {

      return [...state, payload as MaterialInvoice]

    }

    case materialInvoiceTypes.RESET_MATERIAL_INVOICES: {
      return initialState
    }

    default:
      return state

  }
}


export default reducer