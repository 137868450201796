/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Table from 'src/components/UI/CustomUI/organisms/Table'
import { notesActions } from 'src/ducks/actions'
import { getNotes } from 'src/ducks/selectors'
import { NOTE_TYPE } from 'src/helpers'
import headers from './headers'


const Accounting = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const notes = useSelector(getNotes())

    const [tableLoading, setTableLoading] = useState(false)

    const fetchNotes = (start: number = 0, limit: number = 25) => {
        setTableLoading(true)
        dispatch(
            notesActions.getNotes({ params: { noteType: NOTE_TYPE.ACCOUNTING, resourceId: id as string } }, (_succ: boolean) => {
                setTableLoading(false)
            })
        )
    }

    useEffect(() => {
        fetchNotes()
    }, [])

    return (
        <Table
            headers={headers}
            title="Accounting"
            loading={tableLoading}
            small
        >
            {notes}
        </Table>
    )
}

export default Accounting;