import { Box, Grid, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import Tag from 'src/components/UI/CustomUI/atoms/Tag'
import { getClientIsHomeowner, getEstimate } from 'src/ducks/selectors'
import styles from './styles.module.scss'
import {
  APPROVAL_TYPE,
  companyRoles,
  CONTACT_ROLE,
  ESTIMATE_APPROVAL_STATUS,
  FILE_TYPE,
  homeownerRoles,
} from 'src/helpers'
import { icons } from 'src/assets'
import ApprovalFlowTrackerStepper from './Stepper'
import { isEmpty } from 'lodash'
import { useEffect } from 'react'
import { clientActions } from 'src/ducks/actions'
import { display } from 'html2canvas/dist/types/css/property-descriptors/display'

const ApprovalFlowTracker = () => {
  const dispatch = useDispatch()
  const estimate = useSelector(getEstimate())
  const contacts = estimate?.properties?.contacts || []
  const isPayAtClose = estimate?.properties?.payAtClose
  const propertyUnderContract = estimate?.properties?.propertyUnderContract
  const userApproving = contacts.find((c) => c.approvalFlowInProgress)
  const clientIsHomeowner = useSelector(getClientIsHomeowner)

  const agentContact = contacts.find((c) =>
    companyRoles.includes(c.role as CONTACT_ROLE)
  )
  const agentContacts = contacts.filter((c) =>
    companyRoles.includes(c.role as CONTACT_ROLE)
  )

  const homeownerContact =
    contacts.find(
      (c) =>
        homeownerRoles.includes(c.role as CONTACT_ROLE) &&
        [APPROVAL_TYPE.REQUIRED, APPROVAL_TYPE.APPROVED].includes(
          c.approvalType as APPROVAL_TYPE
        )
    ) || contacts.find((c) => homeownerRoles.includes(c.role as CONTACT_ROLE))

  const isHomewardCXSupport = homeownerContact?.email
    ? ['cxsupport@test.com', 'cxsupport@homeward.com'].includes(
      homeownerContact?.email
    )
    : false

  const agentIsRequired = agentContact && estimate?.properties.payAtClose

  const agentIsHomeowner =
    !clientIsHomeowner &&
    homeownerContact?.contactId &&
    homeownerContact?.role &&
    [
      CONTACT_ROLE.HOMEOWNER,
      CONTACT_ROLE.HOME_BUYER,
      CONTACT_ROLE.HOME_SELLER,
      CONTACT_ROLE.AGENT_HOMEOWNER,
    ].includes(homeownerContact?.role as CONTACT_ROLE)

  const listingAgreement = estimate?.properties?.files?.find(
    (file) => file.fileType === FILE_TYPE.LISTING_AGREEMENT
  )
  const comparableMarketAnalysis = estimate?.properties?.files?.find(
    (file) => file.fileType === FILE_TYPE.COMPARABLE_MARKET_ANALYSIS
  )

  const approvalFlowStarted =
    !isEmpty(userApproving) ||
    isPayAtClose ||
    [
      ESTIMATE_APPROVAL_STATUS.APPROVED,
      ESTIMATE_APPROVAL_STATUS.PROCESSING_ACH_PAYMENT,
    ].includes(estimate?.approvalStatus as ESTIMATE_APPROVAL_STATUS)

  useEffect(() => {
    //fetch client data for Agent as Homeowner flow validations
    homeownerContact?.contactId &&
      dispatch(clientActions.fetchClient(homeownerContact?.contactId))
  }, [dispatch, homeownerContact])

  const steps = [
    {
      label: `Estimate\nReleased`,
      completed: Boolean(estimate?.properties.estimateDeliveredOn),
      completedOn: estimate?.properties.estimateDeliveredOn,
    },
    {
      label: `Approval Flow\nStarted`,
      completed: approvalFlowStarted ?? true,
      completedOn: estimate?.approvalFlowStartedOn
    },
  ] as any

  const paymentTag = {
    background: isPayAtClose
      ? 'var(--bosscat-blue-600)'
      : isPayAtClose === null
        ? 'var(--gray200)'
        : 'var(--gray700)',
    label: isPayAtClose
      ? 'Pay at Close'
      : isPayAtClose === null
        ? 'Awaiting Payment Method'
        : 'Standard Payment',
  }

  if (agentIsRequired) {
    const listingAgreementDateSigned = estimate?.properties?.files && estimate?.properties?.files.find(file => file.fileType === FILE_TYPE.LISTING_AGREEMENT)?.uploadedOn;
    steps.push({
      label: 'Listing\nAgreement',
      completed: !isEmpty(listingAgreement),
      completedOn: listingAgreementDateSigned
    })

    if (!propertyUnderContract) {
      const cmaDateSigned = estimate?.properties?.files && estimate?.properties?.files.find(file => file.fileType === FILE_TYPE.COMPARABLE_MARKET_ANALYSIS)?.uploadedOn;
      steps.push({
        label: 'Agent\nCMA',
        completed: !isEmpty(comparableMarketAnalysis),
        completedOn: cmaDateSigned
      })
    }

    steps.push({
      label: 'Agent\nAcknowledgement',
      completed:
        (agentContacts.filter(ac => ac?.agentAcknowledgementSignedOn)?.[0]?.agentAcknowledgementSignedOn as number) > 0
          ? true
          : false,
      completedOn: agentContacts.filter(ac => ac?.agentAcknowledgementSignedOn)?.[0]?.agentAcknowledgementSignedOn,
    })
  }

  if (agentIsHomeowner && isPayAtClose) {
    const listingAgreementDateSigned = estimate?.properties?.files && estimate?.properties?.files.find(file => file.fileType === FILE_TYPE.LISTING_AGREEMENT)?.uploadedOn;
    steps.push({
      label: 'Homeowner/Agent\nListing Agreement',
      completed: !isEmpty(listingAgreement),
      completedOn: listingAgreementDateSigned
    })
    if (!propertyUnderContract) {
      const cmaDateSigned = estimate?.properties?.files && estimate?.properties?.files.find(file => file.fileType === FILE_TYPE.COMPARABLE_MARKET_ANALYSIS)?.uploadedOn;
      steps.push({
        label: 'Homeowner/Agent\nCMA',
        completed: !isEmpty(comparableMarketAnalysis),
        completedOn: cmaDateSigned
      })
    }
  }

  if (!isHomewardCXSupport) {
    steps.push(
      {
        label: agentIsHomeowner
          ? 'Homeowner/Agent\nService Agreement'
          : `${homeownerContact?.role === CONTACT_ROLE.HOMEOWNER
            ? 'Homeowner'
            : 'Homebuyer'
          }\nService\n Agreement`,
        completed:
          (homeownerContact?.contractSignedOn as number) > 0 ? true : false,
        completedOn: homeownerContact?.contractSignedOn,
      },
      {
        label: `Deposit\nPaid`,
        completed: homeownerContact?.approvalType === APPROVAL_TYPE.APPROVED,
        completedOn: estimate?.depositPaidOn
      }
    )
  }

  steps.push({
    label: 'Estimate\nApproved',
    completed: estimate?.approvalStatus === ESTIMATE_APPROVAL_STATUS.APPROVED,
    completedOn: estimate?.approvedOn,
  })

  return (
    <Grid
      item
      container
      direction={'column'}
      gap="1rem"
      className={styles.ApprovalFlowTracker}
    >
      <Typography variant="h6Bold">Approval Flow Tracker</Typography>
      <Box style={{ display: 'flex', gap: '.5rem' }}>
        <Tag
          width="fit-content"
          variant="body1ExtraBold"
          sx={{
            border: 'none !important',
            borderRadius: '24px',
            padding: '.3rem .8rem',
          }}
          background={paymentTag.background}
          color={isPayAtClose === null ? 'var(--black)' : 'white'}
        >
          {paymentTag.label}
        </Tag>
        {estimate?.approvalStatus ===
          ESTIMATE_APPROVAL_STATUS.PROCESSING_ACH_PAYMENT && (
            <Tag
              width="fit-content"
              variant="body1ExtraBold"
              sx={{
                border: 'none !important',
                borderRadius: '24px',
                padding: '.3rem .8rem',
              }}
              background={'#3C2CA4'}
              color={isPayAtClose === null ? 'var(--black)' : 'white'}
            >
              Processing ACH Payment
            </Tag>
          )}
      </Box>
      <ApprovalFlowTrackerStepper
        steps={steps}
        sx={{
          transform:
            agentIsRequired || agentIsHomeowner
              ? 'translateX(-20px)'
              : 'translateX(-50px)',
        }}
      />
      {agentContact && (
        <Grid style={{ display: 'flex', gap: '.5rem' }}>
          <icons.CheckCircle className={styles.ApprovalFlowTracker__icon} />
          <Typography variant="body1">
            Estimate Released to
            <Typography variant="body1Bold"> Agent</Typography>.
          </Typography>
        </Grid>
      )}
      <Grid style={{ display: 'flex', gap: '.5rem' }}>
        <icons.CheckCircle className={styles.ApprovalFlowTracker__icon} />
        <Typography variant="body1">
          Estimate Released to
          <Typography variant="body1Bold">
            {agentIsHomeowner
              ? ' Agent'
              : homeownerContact?.role === CONTACT_ROLE.HOMEOWNER
                ? ' Homeowner'
                : ' Homebuyer'}
            .
          </Typography>
          {agentIsHomeowner && (
            <Typography
              variant="body1"
              style={{ display: 'inline', marginLeft: '.1rem' }}
            >
              Agent is also the
              <Typography variant="body1Bold"> Homeowner</Typography>
            </Typography>
          )}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default ApprovalFlowTracker
