import { FC, useState, useContext, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import { icons } from "src/assets"
import { PrivateLoader } from "src/components/templates"
import { Button, Checkbox, FormControlLabel, Grid, TextFieldLabel, Typography } from "src/components/UI"
import UploadDocuments from "src/components/UI/CustomUI/molecules/UploadDocument"
import { companiesActions, filesActions } from "src/ducks/actions"
import { FILE_TYPE, getDifferenceBetweenObjects, isEmpty, ParseFilesUrl, PHONE_MASK_INPUT, PHONE_MASK_REGEXP, validateEmail } from "src/helpers"
import { CompaniesContext } from "../../context"
import { dispatchTypes, MODAL_TYPE } from "../../context/types"
import env from "@beam-australia/react-env"
import moment from "moment"
import { getCompany } from "src/ducks/company/selectors"
import { CompanyType } from "src/ducks/company/types"
import { UpdateRequest } from "~api/types"
import { isEqual } from "lodash"
import { PunchlistFile } from "src/ducks/types"

const FILES_URL = env('FILES_URL') ?? ''

const EditCompany: FC = () => {

    const dispatch = useDispatch()
    const { dispatch: dispatchContext } = useContext(CompaniesContext)
    const company = useSelector(getCompany())

    const [loading, setLoading] = useState(false)
    const [showWarnings, setShowWarnings] = useState(false)
    const [uploadingFile, setUploadingFile] = useState(false)
    const [hasUpdatedData, setHasUpdatedData] = useState(false)

    // FORM
    const [editCompanyForm, setEditCompanyForm] = useState<Partial<CompanyType>>(company)

    useEffect(() => {
        setHasUpdatedData(!isEqual(editCompanyForm, company))
    }, [editCompanyForm, company])

    const validate = !isEmpty(editCompanyForm?.name) &&
        !isEmpty(editCompanyForm?.adminName) &&
        !isEmpty(editCompanyForm?.landingPageUrl) &&
        (isEmpty(editCompanyForm?.email) || validateEmail(editCompanyForm?.email ? editCompanyForm?.email : '')) &&
        !isEmpty(editCompanyForm?.phone?.toString()) &&
        PHONE_MASK_REGEXP.test(editCompanyForm?.phone?.toString() ? '' + editCompanyForm.phone?.toString() : '')



    const handleUploadFile = (file: null | File, callback: () => void) => {
        if (file) {
            setUploadingFile(true)
            dispatch(filesActions.uploadFile(file, (uploadSucc, fileName) => {
                if (uploadSucc && fileName) {
                    const fileToSave = {
                        name: file.name,
                        fileUrl: ParseFilesUrl({
                            baseUrl: FILES_URL,
                            avatar: fileName ?? ''
                        }),
                        description: file.name ?? '',
                        fileType: FILE_TYPE.MATERIAL_INVOICE,
                        uploadedOn: moment().unix(),

                    }
                    callback()
                    setEditCompanyForm({
                        ...editCompanyForm,
                        logo: {
                            ...fileToSave,
                            id: '',
                            uploadedBy: '',
                            uploadedAfterJob: false,
                            uploadedByUserType: '',
                            uploadedByUserName: '',
                            fileName: file.name
                        }
                    })
                }
                setUploadingFile(false)
            }))
        } else {
            setEditCompanyForm({
                ...editCompanyForm,
                logo: undefined
            })
        }
    }

    const handleCancel = () => {
        dispatchContext({ type: dispatchTypes.SET_VALUE, payload: { attr: 'modalOpen', value: false } })
    }

    const handleSave = () => {
        if (validate && company?.id) {
            setLoading(true)
            const updateRequest: UpdateRequest = getDifferenceBetweenObjects(
                {
                    ...editCompanyForm,
                    phone: Number(editCompanyForm?.phone?.toString().replaceAll('-', '')),
                    logo: undefined
                },
                {
                    ...company,
                    logo: undefined
                },
                'replace'
            )
            if (!isEqual(company.logo, editCompanyForm?.logo)) {
                updateRequest.push({
                    op: "add", 
                    path: "/logo",
                    value: editCompanyForm?.logo || null
                })
            }
            dispatch(companiesActions.patchCompany({
                company: {
                    ...company
                },
                updateRequest: updateRequest
            }, (succ) => {
                if (succ) {
                    dispatchContext({ type: dispatchTypes.SET_VALUE, payload: { attr: 'modalOpen', value: false } })
                    toast.success.call('success', 'Company Updated')
                    setLoading(false)
                } else {
                    setLoading(false)
                }
            }))
        } else {
            setShowWarnings(true)
        }

    }

    const handleDelete = () => {
        dispatchContext({
            type: dispatchTypes.SET_VALUE, payload: {
                attr: 'modalType',
                value: MODAL_TYPE.DELETE_COMPANY
            }
        })
    }

    return (
        <PrivateLoader loading={loading}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <UploadDocuments
                        error={showWarnings && !editCompanyForm?.logo}
                        file={editCompanyForm?.logo as unknown as PunchlistFile ?? null}
                        dropzoneText='Upload Company Image'
                        preview={true}
                        onChange={!uploadingFile ? handleUploadFile : () => { }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextFieldLabel
                        labelVariant='body1Bold'
                        label='Company Name:'
                        placeholder='Company Name'
                        value={editCompanyForm?.name}
                        helperText={showWarnings && isEmpty(editCompanyForm?.name) ? 'Should add Company Name' : ''}
                        error={showWarnings && isEmpty(editCompanyForm?.name)}
                        onChange={(event) => setEditCompanyForm({
                            ...editCompanyForm,
                            name: event.target.value
                        })}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextFieldLabel
                        labelVariant='body1Bold'
                        label='Affiliate ID:'
                        helperText={showWarnings && isEmpty(editCompanyForm?.affiliateId) ? 'Should add Affiliate ID' : ''}
                        error={showWarnings && isEmpty(editCompanyForm?.affiliateId)}
                        placeholder='000000'
                        value={editCompanyForm?.affiliateId}
                        onChange={(event) => setEditCompanyForm({
                            ...editCompanyForm,
                            affiliateId: event.target.value
                        })}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextFieldLabel
                        labelVariant='body1Bold'
                        label='Company Admin Name:'
                        placeholder='Company Admin Name'
                        value={editCompanyForm?.adminName}
                        helperText={showWarnings && isEmpty(editCompanyForm?.adminName) ? 'Should add Company Admin Name' : ''}
                        error={showWarnings && isEmpty(editCompanyForm?.adminName)}
                        onChange={(event) => setEditCompanyForm({
                            ...editCompanyForm,
                            adminName: event.target.value
                        })}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextFieldLabel
                        labelVariant='body1Bold'
                        type='email'
                        label='Company Email:'
                        placeholder='name@mail.com'
                        value={editCompanyForm?.email}
                        helperText={showWarnings && (!isEmpty(editCompanyForm?.email) && !validateEmail(editCompanyForm?.email ? editCompanyForm?.email : '')) ?
                            'Should add a valid Email' : ''}
                        error={showWarnings && (!isEmpty(editCompanyForm?.email) && !validateEmail(editCompanyForm?.email ? editCompanyForm?.email : ''))}
                        onChange={(event) => setEditCompanyForm({
                            ...editCompanyForm,
                            email: event.target.value
                        })}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextFieldLabel
                        labelVariant='body1Bold'
                        label='Primary Phone Number:'
                        placeholder='555-555-5555'
                        value={'' + editCompanyForm?.phone}
                        helperText={showWarnings && (isEmpty(editCompanyForm?.phone) || !PHONE_MASK_REGEXP.test(editCompanyForm?.phone ? '' + editCompanyForm?.phone : '')) ?
                            'Should add a valid Phone' : ''}
                        error={showWarnings && (isEmpty(editCompanyForm?.phone) || !PHONE_MASK_REGEXP.test(editCompanyForm?.phone ? '' + editCompanyForm?.phone : ''))}
                        onChange={(event) => setEditCompanyForm({
                            ...editCompanyForm,
                            phone: event.target.value
                        })}
                        mask={PHONE_MASK_INPUT}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextFieldLabel
                        labelVariant='body1Bold'
                        label='Landing Page:'
                        placeholder='www.company-landing.com'
                        value={editCompanyForm?.landingPageUrl}
                        helperText={showWarnings && isEmpty(editCompanyForm?.landingPageUrl) ? 'Should add Landing Page' : ''}
                        error={showWarnings && isEmpty(editCompanyForm?.landingPageUrl)}
                        onChange={(event) => setEditCompanyForm({
                            ...editCompanyForm,
                            landingPageUrl: event.target.value
                        })}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={editCompanyForm?.institutional}
                                onChange={(event) => setEditCompanyForm({
                                    ...editCompanyForm,
                                    institutional: event.target.checked
                                })}
                                name='payAtClose'
                                color='infoText'
                            />
                        }
                        label={<Typography>Institutional</Typography>}
                    />
                </Grid>
                <Grid item xs={12} sx={{
                    backgroundColor: '#FFF4F4!important',
                    borderRadius: '8px',
                    marginLeft: '13px'
                }}>
                    <Typography variant='body1Bold' sx={{ color: 'red!important' }}>Delete Company</Typography>
                    <Typography>You will no longer have access to all its information.</Typography>
                    <Button
                        onClick={handleDelete}
                        endIcon={<icons.Delete />}
                        variant='containedError'
                        sx={{
                            marginTop: '8px',
                            marginBottom: '8px'
                        }}
                    >
                        Delete Company
                    </Button>
                </Grid>
                <Grid item xs={6}>

                    <Button
                        variant='containedLight'
                        fullWidth
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>

                </Grid>
                <Grid item xs={6}>
                    <Button
                        onClick={handleSave}
                        variant='contained'
                        fullWidth
                        disabled={!hasUpdatedData}
                        endIcon={<icons.Save />}>
                        Save Changes
                    </Button>
                </Grid>
            </Grid>
        </PrivateLoader >
    )
}

export default EditCompany