import { FC, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, CircularProgress, Grid, TextFieldLabel, Typography } from 'src/components/UI'
import { itemsActions, orderActions, ordersActions, vendorActions } from 'src/ducks/actions'
import { WorkOrdersContext } from '../../../context'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import { getEstimate, getItemById, getOrderById, getVendor, getVendorById } from 'src/ducks/selectors'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { PrivateLoader } from 'src/components/templates'
import Table from 'src/components/UI/CustomUI/organisms/Table'
import { round } from 'src/helpers'

const RejectItem: FC = () => {
	const dispatch = useDispatch()
	const { dispatch: dispatchContext, state: { selectedOrderId, pageLoading, selectedItemId } } = useContext(WorkOrdersContext)
	const { id, orderId } = useParams()
	const order = useSelector(getOrderById(selectedOrderId as string))
	const [followupNotes, setFollowupNotescial] = useState('')
	const [buttonLoading, setButtonLoading] = useState(false)
	const item = useSelector(getItemById(selectedItemId as string))
	const vendor = useSelector(getVendor())
	const estimate = useSelector(getEstimate())

	useEffect(() => {
		order.vendorId && dispatch(
			vendorActions.fetchVendor(order.vendorId, (_succ: boolean) => {
			})
		)
	}, [])

	const handleDelete = () => {
		setButtonLoading(true)
		if (!selectedItemId) {
			setButtonLoading(false)
			return
		}
		dispatch(itemsActions.rejectItem({
			itemId: selectedItemId as string,
			partialItem: { followupNotes },
			id: estimate?.id || ''
		}, (succ) => {
			handleClose()
			if (succ) {
				dispatch(itemsActions.fetchItemsByCategory({
					params: { jobId: id, orderId: orderId || '' }
				}, (_succ) => {
					setButtonLoading(false)
				}))
			}
		}))
	}

	const handleClose = () => {
		dispatchContext({ type: 'SET_MODAL_OPEN', payload: false })
	}

	const sendTo = `${vendor?.firstName} ${vendor?.lastName} <${vendor?.email.find(email => email.emailType === 'PRIMARY')?.email}>`

	return (
		<Grid>
			<Box sx={{ background: 'var(--blue700)' }} >
				<Typography align='center' padding={.7} variant="h5" color='white'>Reject Item</Typography>
			</Box>

			<Grid container>
				<Grid item xs={12} container margin={2}>
					<Grid item>
						<Typography variant="h6Bold">From Territory:</Typography>
					</Grid>
					<Grid item marginLeft=".1rem">
						<Typography variant="h6">{`${estimate?.properties?.territory?.emailName} <${estimate?.properties?.territory?.email}>`}</Typography>
					</Grid>
				</Grid>
				<Grid container item xs={12} gap={2} marginX={2} flexWrap="nowrap">
					<Grid item xs={6}>
						<TextFieldLabel
							label="Send to: "
							labelVariant='h6'
							value={sendTo}
							disabled
						/>
					</Grid>
					<Grid item xs={6}>
						<TextFieldLabel
							label="Subject: "
							labelVariant='h6'
							value={`Items need to be addressed - ${estimate?.properties?.address?.line_1}`}
							disabled
						/>
					</Grid>
				</Grid>

				<Grid item container marginY={2} xs={12} paddingX={3} paddingY={2} columnSpacing={1} sx={{ background: 'var(--gray100)' }}>
					<Grid item xs={.5} overflow="hidden">
						<Typography variant="body1Bold">{item?.inspectionReportReference}</Typography>
					</Grid>
					<Grid item xs={4.5}>
						<Typography variant="body1Bold">{item?.title}</Typography>
					</Grid>
					<Grid item xs={1} display="flex" justifyContent="center">
						<Typography variant="body1Bold">Quantity</Typography>
					</Grid>
					<Grid item xs={1.5} display="flex" justifyContent="flex-end">
						<Typography variant="body1Bold">Unit Cost</Typography>
					</Grid>
					<Grid item xs={1.5} display="flex" justifyContent="flex-end">
						<Typography variant="body1Bold">Total Cost</Typography>
					</Grid>
					<Grid item xs={1.5} display="flex" justifyContent="flex-end">
						<Typography variant="body1Bold">Unit Price</Typography>
					</Grid>
					<Grid item xs={1.5} display="flex" justifyContent="flex-end">
						<Typography variant="body1Bold">Total Price</Typography>
					</Grid>

					<Grid item xs={.5}>
					</Grid>
					<Grid item xs={4.5}>
						<Typography>{item?.disclaimer}</Typography>
					</Grid>
					<Grid item xs={1}>
						<Typography align="center">{item?.quantity}</Typography>
					</Grid>
					<Grid item xs={1.5} display="flex" justifyContent="flex-end">
						<Typography>${round(item?.unitCost, 2)}</Typography>
					</Grid>
					<Grid item xs={1.5} display="flex" justifyContent="flex-end">
						<Typography>${round(item?.totalCost, 2)}</Typography>
					</Grid>
					<Grid item xs={1.5} display="flex" justifyContent="flex-end">
						<Typography>${round(item?.unitPrice, 2)}</Typography>
					</Grid>
					<Grid item xs={1.5} display="flex" justifyContent="flex-end">
						<Typography>${round(item?.totalPrice, 2)}</Typography>
					</Grid>
				</Grid>

				{item?.inspectionReportNote && <Grid container item xs={12} paddingX={3}>
					<Typography variant="body1Bold">Inspection Notes:</Typography>
					<Typography marginLeft=".2rem">{item?.inspectionReportNote}</Typography>
				</Grid>}

				<Grid item xs={12} paddingX={3} marginY={2}>
					<TextFieldLabel
						label="Special Notes: "
						labelVariant='h6'
						value={followupNotes}
						multiline
						rows={3}
						onChange={(e) => setFollowupNotescial(e.target.value)}
						placeholder="Add notes for the contractor"
					/>
				</Grid>
				<Grid item container xs={12} spacing={2} padding={3} justifyContent="flex-end">
					<Grid item xs={3}>
						<Button
							variant='containedLightBig'
							onClick={handleClose}
							fullWidth
						>
							Cancel
						</Button>
					</Grid>
					<Grid item xs={3}>
						<Button
							variant='containedBig'
							onClick={handleDelete}
							fullWidth
						>
							{buttonLoading ? <CircularProgress color='info' size={'1.4rem'} /> : 'Send'}
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Grid>

	)
}

export default RejectItem