import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

const baseStyle = {
  fontFamily: 'NN Nouvelle Grotesk',
  fontStyle: 'normal !important',
}

export default makeStyles((theme: Theme) => ({
  title: {
    ...baseStyle,
    fontWeight: '500 !important',
    fontSize: '16px !important',
    lineHeight: '24px !important',
    color: '#1A2A55',
  },
  subtitle: {
    ...baseStyle,
    fontWeight: '400 !important',
    fontSize: '12px !important',
    lineHeight: '18px !important',
    color: '#494A5A',
  },
  contact: {
    ...baseStyle,
    fontWeight: '500 !important',
    fontSize: '14px !important',
    lineHeight: '22px !important',
    color: '#494A5A',
  },
  cancelButton: {
    width: '184px',
    height: '40px',
    borderWidth: 0,
    borderRadius: '32px !important',
    backgroundColor: '#FAFBFF !important',
    '&:hover': {
      color: '494A5A',
    },
  },
  sendButton: {
    width: '184px',
    height: '40px',
  },
  toast: {
    alignItems: 'flex-start !important',
  },
  toastTitle: {
    fontFamily: 'Moranga !important',
    fontStyle: 'normal !important',
    fontWeight: '500 !important',
    fontSize: '18px !important',
    lineHeight: '30px !important',
    color: '#1A2A55 !important',
  },
  toastContent: {
    fontFamily: 'NN Nouvelle Grotesk',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px !important',
    lineHeight: '24px',
    color: '#1A2A55',
  }
}))
