import { FC, useState } from 'react'
import { Dialog, Button, DialogContent, Typography, Box } from '../../../../../UI'
import useStyles from './styles'
import { faq, icons, contact, questions } from '../../../../../../assets'
import { ContactUsModalProps } from './types'
import FAQ from './Options/FAQ'
import Initial from './Options/Initial'
import SpeakWithExpert from './Options/SpeakWithExpert'
import AskAQuestion from './Options/AskAQuestion'

export enum OPTIONS {
  initial = 'initial',
  faq = 'faq',
  expert = 'expert',
  questions = 'questions',
}

const ContactUsModal: FC<ContactUsModalProps> = ({
  open,
  setOpen,
  showFAQ = true,
}) => {
  const styles = useStyles()
  const [activeOption, setActiveOption] = useState<OPTIONS>(OPTIONS.initial)

  const handleClick = (option: OPTIONS) => {
    setActiveOption(option)
  }

  const options = [
    {
      content: <Initial showFAQ={showFAQ} handleClick={handleClick} />,
      id: 'initial'
    },
  ]

  if (showFAQ) {
    options.push({
      content: <FAQ setActiveOption={(option: OPTIONS) => setActiveOption(option)} />,
      id: 'faq'
    },)
  }

  options.push(
    {
      content: <SpeakWithExpert setActiveOption={(option: OPTIONS) => setActiveOption(option)} setOpen={setOpen} />,
      id: 'expert'
    },
    {
      content: <AskAQuestion setActiveOption={(option: OPTIONS) => setActiveOption(option)} setOpen={setOpen} />,
      id: 'questions'
    }
  )

  const handleClose = () => {
    setActiveOption(OPTIONS.initial)
    setOpen(false)
  }

  const activeOptionIndex = options.findIndex(option => option.id === activeOption)

  const content = options[activeOptionIndex]?.content ?? 'Nothing here..'


  return (
    <Dialog onClose={handleClose} open={open} classes={{ container: styles.container, paper: styles.root }} >
      <DialogContent className={styles.header}>
        <Button onClick={handleClose} className={styles.closeButton}>
          <icons.Close />
        </Button>
      </DialogContent>
      <DialogContent className={styles.body}>
        {content}
      </DialogContent>
    </Dialog>
  )
}

export default ContactUsModal
