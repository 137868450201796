import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    // BASE BUTTON'S STYLES
    root: {
      display: 'flex',
      padding: '8px',
      width: '100%',
      gap: '4px',
      justifyContent: 'flex-start',
      alignItems: 'center'
    },
    optionDesc: {
      fontSize: '14px !important',
      lineHeight: '22px !important',
    }
  })
)
