import { FC, useState } from 'react'

import { Box, Button, Grid, Typography } from '@mui/material'
import { isMobile } from 'react-device-detect'
import { useSelector } from 'react-redux'
import { icons } from 'src/assets'
import MultilineText from 'src/components/UI/CustomUI/atoms/MultilineText'
import { getEstimate } from 'src/ducks/selectors'
import { Item } from 'src/ducks/types'
import useStyles from './styles'


export interface AdditionalItemsRequestedProps {
  punchlistPlanner?: boolean
  serviceable?: boolean
  handleShowAlert?: (item: Item) => void
  handleRemove?: (estimateId: string, itemId: string, removed: boolean) => void
  estimateNotApproved?: boolean
}

const AdditionalItemsRequested: FC<AdditionalItemsRequestedProps> = ({ serviceable, punchlistPlanner, handleShowAlert, handleRemove, estimateNotApproved }) => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(true)
  const estimate = useSelector(getEstimate())
  const [newItem, setNewItem] = useState(estimate?.properties?.clientRequestNotes || '')

  return (
    <Box className={`${classes.root} ${expanded ? classes.rootExpanded : ''}`}>
      <Grid item className={classes.header}>
        <Typography className={classes.title}>Additional items requested</Typography>
        <Typography className={classes.subtitle}>Our team will review your request and respond within one business day.</Typography>
      </Grid>
      {estimateNotApproved &&
        <Grid container className={classes.buttonsContainer}>
          <Grid item xs={6}>
            <Button
              className={classes.buttonEdit}
              startIcon={(expanded ? <icons.Save /> : <icons.Edit />)}
            >
              {expanded ? 'Save' : 'Edit'
              }
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={() => {
              }}
              className={classes.buttonDelete}
              startIcon={<icons.Delete />}
            >
              {'Delete'
              }
            </Button>
          </Grid>
        </Grid>
      }

      <Box hidden={!expanded} className={classes.container}>
        <MultilineText
          value={newItem}
          onChange={(event: any) => {
            setNewItem(event.target.value)
          }}
          className={classes.itemsInput}
          disabled={!estimateNotApproved}
        />
      </Box>
      {!isMobile && <Box className={classes.expandButtonContainer}>
        <Button
          onClick={() => setExpanded(!expanded)}
          className={classes.expandButton}
        >
          <Typography>
            <icons.ExpandLess className={expanded ? classes.iconOpened : classes.iconClosed} />
            {expanded ? 'Collapse' : 'Expand'}
          </Typography>
        </Button>
      </Box>}
    </Box>
  )
}

export default AdditionalItemsRequested
