import { formatParams } from 'src/helpers'
import axios from '../axios'
import { ApiController } from './types'

const base = '/invitations'

const apiController: ApiController = {
    getInvitations: async ({ params, networkIds }) => {
        const parameters = formatParams(networkIds)
        return await axios
            .get(`${base}?networkIds=${networkIds}`)
            .then((response) => response.data)
    },
    createInvitation: async (request) => {
        return await axios
            .post(`${base}`, request)
            .then((response) => response.data)
    },
}

export default apiController