import { useEffect, useState } from 'react'
import Table from 'src/components/UI/CustomUI/organisms/Table'
import headers from './headers'
import { useDispatch, useSelector } from 'react-redux'
import { getPriceList } from 'src/ducks/selectors'
import { filtersActions, repairItemActions } from 'src/ducks/actions'
import { useParams } from 'react-router-dom'
import { getRepairlistFilter } from 'src/ducks/filters/selector'
import useStyles from '../../../styles'
import { Box } from '@mui/material'

const TerritoryPricing = () => {
  const dispatch = useDispatch()

  const styles = useStyles()

  const { id } = useParams()

  const priceList = useSelector(getPriceList)
  const repairListFilter = useSelector(getRepairlistFilter())

  const [loading, setLoading] = useState<boolean>(false)

  const selectedTerritoryIndex = priceList.findIndex(
    (item) => item.territoryId === repairListFilter.territory?.key
  )

  const [selectedIndex, setSelectedIndex] = useState<number>(selectedTerritoryIndex === -1 ? 0 : selectedTerritoryIndex)

  useEffect(() => {
    setLoading(true)

    dispatch(
      repairItemActions.fetchPriceList(id || '', (succ: boolean) => {
        setLoading(false)
      })
    )

    dispatch(
      filtersActions.setFilters({
        repairList: {
          ...repairListFilter,
          selectedTerritoryIndexes: [selectedIndex],
        },
      })
    )
  }, [])

  const handleTerritoryCheck = (index: number) => {
    const newIndexes = repairListFilter.selectedTerritoryIndexes?.includes(
      index
    )
      ? repairListFilter.selectedTerritoryIndexes?.filter(
          (idx) => idx !== index
        )
      : [...(repairListFilter.selectedTerritoryIndexes || []), index]

    dispatch(
      filtersActions.setFilters({
        repairList: {
          ...repairListFilter,
          selectedTerritoryIndexes: newIndexes,
        },
      })
    )

    setSelectedIndex(newIndexes.at(0) || 0)

    dispatch(
      repairItemActions.setPriceItem(priceList[newIndexes.at(0) || 0])
    )
  }

  return (
    <Box className={styles.tabContainer}>
      <Table
        headers={headers}
        hasPagination={false}
        loading={loading}
        selectedRow={selectedIndex}
      >
        {priceList.map((_item, index) =>
          repairListFilter.selectedTerritoryIndexes?.includes(index)
            ? { ..._item, checked: true, onClick: handleTerritoryCheck, index }
            : { ..._item, checked: false, onClick: handleTerritoryCheck, index }
        ) || []}
      </Table>
    </Box>
  )
}

export default TerritoryPricing
