/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Typography } from '@mui/material'
import { debounce } from 'lodash'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { TextFieldLabel } from 'src/components/UI'
import { getJob, getTerritory } from 'src/ducks/selectors'
import { getInvoicePreview } from 'src/ducks/invoice/selectors'
import { isEmpty, validateEmail } from 'src/helpers'
import { PreviewInvoiceContext } from '../context'
import { previewInvoiceTypes } from '../context/types'

const SendingInfo = () => {
  const territory = useSelector(getTerritory)
  const { dispatch: dispatchContext, state } = useContext(PreviewInvoiceContext)
  const job = useSelector(getJob())
  const invoicePreview = useSelector(getInvoicePreview())

  const contactsDefault = job?.properties?.contacts?.filter((contact) => (contact as any).isPayer);

  const [values, setValues] = useState({
    from: `${territory?.emailName} ${territory?.email}`,
    ccEmails: state?.ccEmails,
    payer: invoicePreview && invoicePreview?.preparedFor?.payer
      ? invoicePreview?.preparedFor?.fullName + ' <' + invoicePreview?.preparedFor?.email + '>'
      : contactsDefault && contactsDefault?.length > 0 ? contactsDefault[0]?.fullName + ' <' + contactsDefault[0]?.email + '>' : '',//contactsDefault? contactsDefault[0]?.email : '',
    subject: job?.properties?.address?.fullAddress ? `BOSSCAT Invoice for ${job?.properties?.address?.fullAddress}` : 'BOSSCAT Invoice'
  })

  const changeItemValue = useCallback(
    (newValue, attr) => dispatchContext({ type: 'SET_VALUE', payload: { attr: attr, value: newValue } }),
    [dispatchContext]
  )

  const onItemValueChanged = useMemo(
    () => debounce(changeItemValue, 500),
    [changeItemValue]
  )

  const validateAdditionalEmails = () => {
    let isValid = true
    const emails = values?.ccEmails?.split(',')
    emails.forEach(adEmail => {
      if (!validateEmail(adEmail?.replace(/\s/g, ''))) isValid = false
    })
    return isValid
  }

  const validate: boolean =
    !isEmpty(values?.subject) &&
    (isEmpty(values?.ccEmails) || validateAdditionalEmails())

  useEffect(() => {
    dispatchContext({ type: previewInvoiceTypes.SET_VALUE, payload: { attr: 'isValid', value: validate } })
  }, [values])

  useEffect(() => {
    dispatchContext({ type: 'SET_VALUE', payload: { attr: 'subject', value: job?.properties?.address?.fullAddress ? `BOSSCAT Invoice for ${job?.properties?.address?.fullAddress}` : 'BOSSCAT Invoice' } })
    dispatchContext({ type: 'SET_VALUE', payload: { attr: 'payerEmail', value: values.payer } })
    dispatchContext({ type: 'SET_VALUE', payload: { attr: 'ccEmails', value: '' } })
    //dispatchContext({ type: 'SET_VALUE', payload: { attr: 'recipients', value: values.payer }})
  }, [])



  return (
    <Grid item container direction='column' spacing={2}>
      <Grid item>
        <Typography variant='h5Bold'>Sending Info</Typography>
      </Grid>
      <Grid item container direction='column' spacing={2}>
        <Grid item>
          <TextFieldLabel
            label='From Territory:'
            value={values.from}
            disabled
          />
        </Grid>
        <Grid item>
          <TextFieldLabel
            label='Recipient (Payer): '
            value={values.payer}
            placeholder='Joe Smith <joe.smith@fake.com>'
            disabled
          />
        </Grid>
        <Grid item>
          <TextFieldLabel
            label='CC Emails: '
            type='email'
            value={values.ccEmails || ''}
            onChange={(e) => {
              setValues({ ...values, ccEmails: e.target.value })
              onItemValueChanged(e.target.value, 'ccEmails')
            }}
            placeholder='Separate email addresses by comma'
          />
        </Grid>
        <Grid item>
          <TextFieldLabel
            label='Subject:'
            value={values.subject}
            onChange={(e) => {
              setValues({ ...values, subject: e.target.value })
              onItemValueChanged(e.target.value, 'subject')
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SendingInfo