import { workOdersTypes, WorkOrdersContextType } from './types'

export const WorkOrdersReducer = (state: WorkOrdersContextType, action: any) => {
  const { type, payload } = action

  switch (type) {
    case workOdersTypes.SET_MODAL_TYPE: {
      return {
        ...state,
        modalType: payload
      }
    }
    case workOdersTypes.SET_MODAL_OPEN: {
      return {
        ...state,
        modalOpen: payload
      }
    }
    case workOdersTypes.SET_MODAL_ACTION_AFTER_CREATE: {
      return {
        ...state,
        modalActionAfterCreate: payload
      }
    }
    case workOdersTypes.SET_MODAL_AFTER_CREATE: {
      return {
        ...state,
        modalAfterCreate: payload
      }
    }
    case workOdersTypes.SET_PAGE_LOADING: {
      return {
        ...state,
        pageLoading: payload
      }
    }
    case workOdersTypes.SET_TOTALS_LOADING: {
      return {
        ...state,
        totalsLoading: payload
      }
    }
    case workOdersTypes.REFRESH: {
      return {
        ...state,
        refresh: payload
      }
    }
    case workOdersTypes.SET_WORK_ITEMS: {
      return {
        ...state,
        workItems: payload
      }
    }
    case workOdersTypes.TOGGLE_CHECK_ALL: {
      return {
        ...state,
        checkAll: !state.checkAll
      }
    }
    case workOdersTypes.TOGGLE_EXPAND_ALL: {
      return {
        ...state,
        expandAll: !state.expandAll
      }
    }
    case workOdersTypes.SET_SELECTED_ITEM_ID: {
      return {
        ...state,
        selectedItemId: payload
      }
    }
    case workOdersTypes.SET_SELECTED_ITEMS_IDS: {
      return {
        ...state,
        selectedItemsIds: payload
      }
    }
    case workOdersTypes.SET_SELECTED_WORK_ITEM: {
      return {
        ...state,
        selectedWorkItem: payload
      }
    }
    case workOdersTypes.SET_SELECTED_ORDER_ID: {
      return {
        ...state,
        selectedOrderId: payload
      }
    }
    case workOdersTypes.SET_ITEM_VALUES_LOADING: {
      return {
        ...state,
        itemsLoading: payload
      }
    }
    case workOdersTypes.SET_ITEM: {
      return {
        ...state,
        item: payload
      }
    }
    case workOdersTypes.SET_ITEM_VALUE: {
      const { attr, value } = payload
      return {
        ...state,
        item: {
          ...state.item,
          [attr]: value
        }
      }
    }
    case workOdersTypes.SET_EDIT_ITEM_VALUE: {
      const { attr, value } = payload
      return {
        ...state,
        editItem: {
          ...state.editItem,
          [attr]: value
        }
      }
    }
    case workOdersTypes.RESET_ITEM_VALUE: {
      return {
        ...state,
        item: {},
        selectedItemId: null
      }
    }
    case workOdersTypes.RESET_EDIT_ITEM_VALUE: {
      return {
        ...state,
        editItem: {},
        selectedItemId: null
      }
    }
    case workOdersTypes.SET_SELECTED_TAB: {
      return {
        ...state,
        selectedTab: payload
      }
    }
    case workOdersTypes.SET_SELECTED_VENDOR: {
      return {
        ...state,
        selectedVendor: payload
      }
    }
    case workOdersTypes.SET_SELECTED_SITE_VISIT_ID: {
      return {
        ...state,
        selectedSiteVisitId: payload
      }
    }
    case workOdersTypes.SET_SELECTED_FIELD_MANAGER: {
      return {
        ...state,
        selectedFieldManager: payload
      }
    }
    case workOdersTypes.REFRESH_SITE: {
      return {
        ...state,
        refreshSite: payload
      }
    }
    default:
      return state
  }
}
