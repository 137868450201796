import { authTypes } from '../auth/types'
import { ClientPriceModel } from '../types'
import { clientTypes, PriceModelActions, PriceModelType, SetPriceModelAction } from './types'

const initialState: ClientPriceModel = {
  priceModels: []
}

const reducer = (state = initialState as any, action: PriceModelActions): PriceModelType => {
  const { type } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case clientTypes.SET_CLIENT_PRICE_MODEL: {
      return {
        ...state,
        priceModels: (action as SetPriceModelAction).payload as unknown as ClientPriceModel
      }
    }

    default:
      return state
  }
}

export default reducer
