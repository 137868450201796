/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { PrivateLoader } from 'src/components/templates'
import Expand from 'src/components/UI/CustomUI/atoms/Expand'
import { employeesActions, itemsActions, ordersActions } from 'src/ducks/actions'
import { getOrders } from 'src/ducks/selectors'
import { ORDER_STATUS } from 'src/helpers'
import Comments from './Comments'
import JobsTasks from './JobsTasks'
import Notes from './Notes'

import styles from './styles.module.scss'

const OPEN_ORDER_STATUS = [ORDER_STATUS.APPROVED, ORDER_STATUS.DISPATCHED, ORDER_STATUS.REVIEW, ORDER_STATUS.IN_PROGRESS, ORDER_STATUS.PENDING, ORDER_STATUS.ON_HOLD, null]

const COMPLETED_ORDER_STATUS = [ORDER_STATUS.COMPLETE, ORDER_STATUS.INVOICED, ORDER_STATUS.PAID, null]

const Overview = () => {
  const { id } = useParams()
  const dispatch = useDispatch()

  const orders = useSelector(getOrders())

  const [pageLoading, setPageLoading] = useState(true)

  const openWorkOrders = orders.filter(order => OPEN_ORDER_STATUS.includes(order.statusType))
  const workOrdersInReview = orders.filter(order => order.statusType === ORDER_STATUS.REVIEW)
  const completedWorkOrders = orders.filter(order => COMPLETED_ORDER_STATUS.includes(order.statusType))

  const fetchServices = () => {
    // TODO: fetch services asynchronously
    dispatch(itemsActions.fetchItemsByCategory({
      params: { jobId: id }
    }, (succ) => {
      if (succ) {
        dispatch(ordersActions.fetchOrders({
          params: { jobId: id, page: 0, size: 9999 }
        }, (succ) => {
          if (succ) {
            dispatch(
              employeesActions.fetchEmployees({ page: 0, size: 150, searchParams: { approved: true } }, (_succ: boolean) => {
                setPageLoading(false)
              })
            )
          } else {
            setPageLoading(false)
          }
        }))
      }
    }))
    // if (job?.properties.territory.id) {
    //   dispatch(
    //     territoryActions.fetchTerritoryRepairItems({ territoryId: job?.properties.territory.id, params: { limit: 9999 } })
    //   )
    // }
  }

  useEffect(() => {
    fetchServices()
  }, [id])

  return (
    <Box
      className={styles.Overview}
    >
      <Expand>
        <Grid
          item
          sx={{
            border: '2px solid var(--border-color)',
            borderRadius: '0.75rem 0 0.75rem 0.75rem',
            minHeight: '30vh'
          }}
          spacing={3}
        >
          <PrivateLoader loading={pageLoading}>
            <Grid
              item
              container
              className={styles.Overview__container}
              spacing={2}
            >
              <Grid direction='column' container item xs={12} lg={7} className={styles.Overview__left}>
                <Grid item container className={styles.WorkOrders__stats} gap={15}>
                  <Grid item>
                    <Grid container item>
                      <Typography variant='h6Bold' display='flex'>
                        Total Work Orders:
                        <Typography variant='h6' marginLeft='.5rem'>
                          {orders.length}
                        </Typography>
                      </Typography>

                    </Grid>
                    <Grid container item>
                      <Typography variant='h6Bold' display='flex'>
                        Open Work Orders:
                        <Typography variant='h6' marginLeft='.5rem'>
                          {openWorkOrders.length}
                        </Typography>
                      </Typography>

                      <Typography variant='h6Bold' display='flex' marginLeft='.6rem'>
                        Work Orders In Review:
                        <Typography variant='h6' marginLeft='.5rem'>
                          {workOrdersInReview.length}
                        </Typography>
                      </Typography>

                      <Typography variant='h6Bold' display='flex' marginLeft='.6rem'>
                        Completed Work Orders:
                        <Typography variant='h6' marginLeft='.5rem'>
                          {completedWorkOrders.length}
                        </Typography>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item container direction='column' spacing={2}>
                  <Grid item>
                    <Typography variant='h6Bold'>Tasks</Typography>
                  </Grid>
                  <JobsTasks />
                </Grid>
              </Grid>
              <Grid container item xs={12} lg={4.5} sx={{ height: 'fit-content' }}>
                <Notes />
                <Comments />
              </Grid>
            </Grid>
          </PrivateLoader>
        </Grid>
      </Expand>
    </Box>
  )
}
export default Overview
