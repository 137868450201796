import { SearchParams } from '../searches/types'
import { subscriptionsTypes, SubscriptionsActions, SubscriptionsType } from './types'

const actions = {
  fetchSubscriptions: (
    payload: {
      page?: number
      size?: number
      searchParams?: Partial<SearchParams>
    },
    callback?: (succ: boolean) => void
  ): SubscriptionsActions => ({
    type: subscriptionsTypes.FETCH_SUBSCRIPTIONS,
    payload,
    callback,
  }),

  fetchCsvSubscriptions: (
    payload: {
      count?: number,
      searchParams?: any
    },
    callback?: (succ: boolean) => void
  ): SubscriptionsActions => ({
    type: subscriptionsTypes.FETCH_CSV_SUBSCRIPTIONS,
    payload,
    callback
  }),

  setSubscriptions: (payload: Partial<SubscriptionsType>): SubscriptionsActions => ({
    type: subscriptionsTypes.SET_SUBSCRIPTIONS,
    payload,
  }),

  setCsvSubscriptions: (payload: Partial<SubscriptionsType>): SubscriptionsActions => ({
    type: subscriptionsTypes.SET_CSV_SUBSCRIPTIONS,
    payload,
  }),
}

export default actions
