import {
  companiesTypes,
  CompaniesActions,
  RequestPayload,
  CompaniesType,
} from './types'
import { CompanyItem } from '../types'
import { SearchParams } from '../searches/types'

const actions = {
  fetchCompanies: (
    payload?: {
      page?: number
      size?: number
      searchParams?: Partial<SearchParams>
      institutional?: boolean | null
    },
    callback?: (succ: boolean) => void
  ): CompaniesActions => ({
    type: companiesTypes.FETCH_COMPANIES,
    payload,
    callback,
  }),

  fetchAllCompanies: (
    payload?: {
      page?: number
      size?: number
      searchParams?: Partial<SearchParams>
      institutional?: boolean | null
    },
    callback?: (succ: boolean) => void
  ): CompaniesActions => ({
    type: companiesTypes.FETCH_ALL_COMPANIES,
    payload,
    callback,
  }),

  createCompany: (
    payload: RequestPayload,
    callback: (succ: boolean, company?: CompanyItem) => void
  ): CompaniesActions => ({
    type: companiesTypes.CREATE_COMPANY,
    payload,
    callback,
  }),

  updateCompany: (
    payload: RequestPayload,
    callback: (succ: boolean) => void
  ): CompaniesActions => ({
    type: companiesTypes.UPDATE_COMPANY,
    payload,
    callback,
  }),

  patchCompany: (
    payload: RequestPayload,
    callback: (succ: boolean) => void
  ): CompaniesActions => ({
    type: companiesTypes.PATCH_COMPANY,
    payload,
    callback,
  }),

  deleteCompany: (
    payload: RequestPayload,
    callback: (succ: boolean) => void
  ): CompaniesActions => ({
    type: companiesTypes.DELETE_COMPANY,
    payload,
    callback,
  }),

  setCompanies: (payload: CompaniesType): CompaniesActions => ({
    type: companiesTypes.SET_COMPANIES,
    payload,
  }),

  setAllCompanies: (payload: CompaniesType): CompaniesActions => ({
    type: companiesTypes.SET_ALL_COMPANIES,
    payload,
  }),
}

export default actions
