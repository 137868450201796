/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo, isEmpty } from '../../helpers'
import { Punchlist } from '../../api'
import {
  jobTypes,
  FetchJobAction,
  UpdateJobAction,
  UpdateJobPropertyAction,
  DeleteJobAction,
  FetchOrderCheckInHistory,
  UpdateJobContact,
  CloneJobAction,
  UpdateJobByPathAction,
  ChangeOrderRequestApprovalAction,
  CreateJobAction
} from './types'
import { jobActions } from '../actions'
import { UpdateRequest } from '~api/types'
import { getJobId } from './selectors'
import { Contact, JobItem, JobProperties } from '../types'

import * as Sentry from '@sentry/react'

export function* fetchJob({ payload, callback }: FetchJobAction): SagaIterator {
  let success = false
  try {
    const job = yield call(Punchlist.jobs.getJobById, payload)
    if (job) {
      yield put(jobActions.setJob(job))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);

    yield call(toast.error, errorTextTryingTo('get the job'))
    if (callback) yield call(callback, false)
  }
}

export function* createJob({ payload, callback }: CreateJobAction): SagaIterator {
  let success = false
  try {
    const job = yield call(Punchlist.jobs.createJob, payload)

    if (job) {
      yield put(jobActions.setJob(job))
    }

    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);

    yield call(toast.error, errorTextTryingTo('create the job'))
    if (callback) yield call(callback, false)
  }
}

export function* deleteJob({ payload, callback }: DeleteJobAction): SagaIterator {
  let success = false
  try {
    yield call(Punchlist.jobs.deleteJobById, payload)
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);

    yield call(toast.error, errorTextTryingTo('delete the job'))
    if (callback) yield call(callback, false)
  }
}

export function* updateJob({ payload, callback }: UpdateJobAction): SagaIterator {
  let success = false

  try {
    const request: UpdateRequest = []
    const jobId = yield select(getJobId)

    for (const attr in payload) {
      request.push({
        op: 'add',
        path: '/' + attr,
        value: payload[attr as keyof JobItem]
      })
    }

    const job = yield call(Punchlist.jobs.updateJob, jobId, request)

    if (!isEmpty(job)) {
      // yield put(jobActions.fetchJob(jobId))
      yield put(jobActions.setJob(job))
      //yield put(jobActions.fetchHistoryTask(jobId))
      success = true
    }
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);

    yield call(toast.error, errorTextTryingTo('update the job'))
    if (callback) yield call(callback, false)
  }
}

export function* updateJobByPath({ payload, callback }: UpdateJobByPathAction): SagaIterator {
  let success = false
  try {
    const jobId = yield select(getJobId)
    const job = yield call(Punchlist.jobs.updateJob, jobId, payload)

    if (!isEmpty(job)) {
      yield put(jobActions.setJob(job))
      // yield put(jobActions.fetchJob(jobId))
      //yield put(jobActions.fetchHistoryTask(jobId))
      success = true
    }
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);

    yield call(toast.error, errorTextTryingTo('update the job'))
    if (callback) yield call(callback, false)
  }
}

export function* updateJobProperty({ payload, callback }: UpdateJobPropertyAction): SagaIterator {
  let success = false
  try {
    const request: UpdateRequest = []
    const jobId = yield select(getJobId)

    for (const attr in payload) {
      request.push({
        op: 'add',
        path: '/properties/' + attr,
        value: payload[attr as keyof JobProperties]
      })
    }

    const job = yield call(Punchlist.jobs.updateJob, jobId, request)

    if (!isEmpty(job)) {
      yield put(jobActions.setJob(job))

      // yield put(jobActions.fetchJob(jobId))
      success = true
    }
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);

    yield call(toast.error, errorTextTryingTo('update the job'))
    if (callback) yield call(callback, false)
  }
}

export function* fetchOrderCheckInHistory({ payload, callback }: FetchOrderCheckInHistory): SagaIterator {
  let success = false
  const { id, orderId } = payload
  try {
    const orderHistory = yield call(Punchlist.history.getCheckInHistory, id, orderId)
    if (orderHistory) {
      yield put(jobActions.setJobValue({ attr: 'orderHistory', value: orderHistory }))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the order history'))
    if (callback) yield call(callback, false)
  }
}

export function* updateJobContact({ payload, callback }: UpdateJobContact): SagaIterator {
  const { contact, idx } = payload
  let success = false

  try {
    const request: UpdateRequest = []

    for (const attr in contact) {
      request.push({
        op: 'replace',
        path: `/properties/contacts/${idx}/${attr}`,
        value: contact[attr as keyof Contact]
      })
    }
    const jobId = yield select(getJobId)

    const resp = yield call(Punchlist.jobs.updateJobContact, jobId, request)
    yield put(jobActions.setJob(resp))

    // yield put(jobActions.fetchJob(jobId))
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);

    yield call(toast.error, errorTextTryingTo('get the order history'))
    if (callback) yield call(callback, false)
  }
}

export function* createJobContact({ payload, callback }: UpdateJobContact): SagaIterator {
  let success = false
  try {
    const request: UpdateRequest = []
    request.push({
      op: 'add',
      path: '/properties/contacts/-',
      value: payload
    })

    const jobId = yield select(getJobId)

    const job = yield call(Punchlist.jobs.updateJobContact, jobId, request)
    yield put(jobActions.setJob(job))

    // yield put(jobActions.fetchJob(jobId))
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);

    yield call(toast.error, errorTextTryingTo('get the order history'))
    if (callback) yield call(callback, false)
  }
}

export function* cloneJob({ payload, callback }: CloneJobAction): SagaIterator {
  let success = false
  try {
    const job = yield call(Punchlist.jobs.cloneJob, payload)
    success = true
    if (callback) yield call(callback, success, job.id)
  } catch (error) {
    Sentry.captureException(error);

    yield call(toast.error, errorTextTryingTo('clone the job'))
    if (callback) yield call(callback, false, null)
  }
}

export function* removeJobContact({ payload, callback }: UpdateJobContact): SagaIterator {
  const { idx } = payload
  let success = false

  try {
    const request: UpdateRequest = []

    request.push({
      op: 'remove',
      path: `/properties/contacts/${idx}`,
    })

    const jobId = yield select(getJobId)

    const job = yield call(Punchlist.jobs.removeJobContact, jobId, request)
    yield put(jobActions.setJob(job))

    // yield put(jobActions.fetchJob(jobId))
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);

    yield call(toast.error, errorTextTryingTo('remove the job contact'))
    if (callback) yield call(callback, false)
  }
}

export function* changeOrderRequestApproval({ payload, callback }: ChangeOrderRequestApprovalAction): SagaIterator {
  let success = false
  try {
    const jobId = yield select(getJobId)
    yield call(Punchlist.jobs.changeOrderApproval, jobId, payload)
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);

    yield call(toast.error, errorTextTryingTo('request order change approval'))
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(jobTypes.FETCH_JOB, fetchJob)
  yield takeLatest(jobTypes.UPDATE_JOB, updateJob)
  yield takeLatest(jobTypes.DELETE_JOB, deleteJob)
  yield takeLatest(jobTypes.UPDATE_JOB_BY_PATH, updateJobByPath)
  yield takeLatest(jobTypes.UPDATE_JOB_PROPERTY, updateJobProperty)
  yield takeLatest(jobTypes.FETCH_CHECK_IN_HISTORY, fetchOrderCheckInHistory)
  yield takeLatest(jobTypes.UPDATE_JOB_CONTACT, updateJobContact)
  yield takeLatest(jobTypes.CREATE_JOB_CONTACT, createJobContact)
  yield takeLatest(jobTypes.CLONE_JOB, cloneJob)
  yield takeLatest(jobTypes.REMOVE_JOB_CONTACT, removeJobContact)
  yield takeLatest(jobTypes.CHANGE_ORDER_REQUEST_APPROVAL, changeOrderRequestApproval)
  yield takeLatest(jobTypes.CREATE_JOB, createJob)
}
