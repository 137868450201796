import { Button, CircularProgress, Grid, TextFieldLabel, Typography } from 'src/components/UI'
import { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { notesActions } from 'src/ducks/actions'
import { formatTimestamp, NOTE_TYPE, RESOURCE_TYPE } from 'src/helpers'
import { getNotes, getEmployee } from 'src/ducks/selectors'
import styles from './styles.module.scss'
import { toast } from 'react-toastify'

const JobNotes: FC = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const notes = useSelector(getNotes())
  const currentUser = useSelector(getEmployee)
  /* 
    const {
      startedOn, createdOn, firstDispatchDate, lastSiteVisitDate,
      expectedCompletedOn, dueOn, percentItemsComplete, lastDispatchDate
    } = estimate as EstimateItem */

  const [loading, setLoading] = useState(false)
  const [note, setNote] = useState(notes[0]?.text || '')
  const showInput = notes.length === 0

  const fetchNote = useCallback(() => {
    if (id) {
      dispatch(
        notesActions.getNotes({ params: { noteType: NOTE_TYPE.JOB_GENERAL, resourceId: id as string, resourceType: RESOURCE_TYPE.ESTIMATE, limit: 1 } })
      )
    }
  }, [dispatch, id])

  useEffect(() => {
    fetchNote()
  }, [fetchNote, id])

  useEffect(() => {
    setNote(notes[0]?.text || '')
  }, [notes])

  const postSuccess = (success: boolean) => {
    if (success) {
      fetchNote()
      setLoading(false)
    }
  }

  const createNote = () => {
    setLoading(true)
    const request = {
      resourceId: id,
      resourceType: RESOURCE_TYPE.ESTIMATE,
      noteType: NOTE_TYPE.JOB_GENERAL,
      createdBy: currentUser.id,
      createdByName: `${currentUser.firstName} ${currentUser.lastName} `,
      text: note
    }
    return dispatch(
      notesActions.createNote(request,
        (success) => {
          toast.success.call('success', `Note ${showInput ? 'created' : 'updated'} successfully`)
          postSuccess(success)
        }
      )
    )
  }
  /* 
    const dataCSV = [
      {
        note: notes[0]?.text?.replace(/[\r\n]/gm, ' ') || '',
        approval: formatTimestamp(createdOn, 'MM/DD/YYYY'),
        dueOn: formatTimestamp(dueOn, 'MM/DD/YYYY'),
        firstDispatchDate: formatTimestamp(firstDispatchDate, 'MM/DD/YYYY'),
        lastSiteVisitDate: formatTimestamp(lastSiteVisitDate, 'MM/DD/YYYY'),
        startedOn: formatTimestamp(startedOn, 'MM/DD/YYYY'),
        expectedCompletedOn: formatTimestamp(expectedCompletedOn, 'MM/DD/YYYY'),
        lastDispatchDate: formatTimestamp(lastDispatchDate, 'MM/DD/YYYY'),
        percentItemsComplete: `${percentItemsComplete}%`
      }
    ] */

  const headerCSV = [
    { label: 'Note', key: 'note' },
    { label: 'Approval', key: 'approval' },
    { label: 'Completion ETC', key: 'dueOn' },
    { label: 'First Dispatched', key: 'firstDispatchDate' },
    { label: 'Site Visit', key: 'lastSiteVisitDate' },
    { label: 'Job Start', key: 'startedOn' },
    { label: 'Customer Deadline', key: 'expectedCompletedOn' },
    { label: 'Last Dispatched', key: 'lastDispatchDate' },
    { label: 'Job Progress', key: 'percentItemsComplete' }
  ]

  return (
    <Grid container item spacing={1} direction='column' marginBottom={4} paddingLeft={1}>
      <Grid container item justifyContent='space-between' alignItems='center' padding='0 !important'>
        <Grid item padding={0}>
          <Typography variant='h6Bold'>Job Note:</Typography>
        </Grid>
        <Grid item container width='fit-content' gap={1.5} marginBottom={1.5}>
          {/*  <ExportCSV
            headers={headerCSV}
            data={dataCSV}
            fileName='notes.csv'
            label={<Button sx={{ padding: '8px !important' }} variant='containedLight'><Icon name='CloudDownload' /></Button>}
          /> */}
          <Button
            variant={showInput ? 'contained' : 'containedOrange'}
            onClick={createNote}
            sx={{ minWidth: '130px !important' }}
            disabled={loading || !note || note === notes[0]?.text}
          >
            {loading ? <CircularProgress color='info' size='1rem' /> : <Typography>{showInput ? 'Create Note' : 'Update Note'}</Typography>}
          </Button>
        </Grid>
      </Grid>
      <TextFieldLabel
        label=''
        placeholder='Note...'
        value={note ?? ''}
        size='small'
        onChange={event => setNote(event.target.value)}
        multiline
        rows={6}
        labelVariant='h6'
        className={`${showInput ? '' : styles.Notes__Input_edit}`}
      />
      {!showInput && <Typography display-='block' variant='body1Bold' color='var(--orange700)' className={styles.Notes__noteBy}>{notes[0].createdByName} - {formatTimestamp(notes[0]?.createdOn, 'MM/DD/YYYY HH:mm')}</Typography>}
    </Grid>
  )
}

export default JobNotes
