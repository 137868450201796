import { clientsTypes, ClientsActions, ClientsType, SearchClientParams, ClienstValue } from './types'

const actions = {
  fetchClients: (
    //payload: { page?: number, size?: number, state?: string, search?: string,type?:string,createdOnStart?:string, createdOnEnd?:string },
    payload: {
      page?: number,
      size?: number,
      searchParams?: Partial<SearchClientParams>,
    },
    callback?: (succ: boolean) => void,
  ): ClientsActions => ({
    type: clientsTypes.FETCH_CLIENTS,
    payload,
    callback
  }),

  fetchStats: (
    payload: {
      searchParams?: Partial<SearchClientParams>,
    },
    callback?: (succ: boolean) => void,
  ): ClientsActions => ({
    type: clientsTypes.FETCH_STATS,
    payload,
    callback
  }),
  
  setClients: (payload: ClientsType): ClientsActions => ({
    type: clientsTypes.SET_CLIENTS,
    payload
  }),

  setClientsValue: (payload: ClienstValue): ClientsActions => ({
    type: clientsTypes.SET_CLIENTS_VALUE,
    payload
  }),

  fetchAllClients: (
    payload: Partial<SearchClientParams>,
    callback: (succ: boolean, clients: ClientsType) => void,
  ): ClientsActions => ({
    type: clientsTypes.FETCH_ALL_CLIENTS,
    payload,
    callback
  }),

  downloadCSV: (
    payload: Partial<SearchClientParams>,
    callback: (succ: boolean, clients: string) => void,
  ): ClientsActions => ({
    type: clientsTypes.DOWNLOAD_CSV,
    payload,
    callback
  }),

}

export default actions
