import { InspectorsActions, inspectorsTypes } from './types'
import { InspectorCompany } from '../types'
import { UpdateRequest } from '~api/types'

const actions = {
  fetchInspectors: (callback: (succ: boolean) => void): InspectorsActions => ({
    type: inspectorsTypes.FETCH_INSPECTOR,
    callback,
  }),
  setInspectors: (payload: InspectorCompany[]): InspectorsActions => ({
    type: inspectorsTypes.SET_INSPECTORS,
    payload,
  }),
  createInspector: (
    payload: Partial<InspectorCompany>,
    callback: (succ: boolean) => void
  ): InspectorsActions => ({
    type: inspectorsTypes.CREATE_INSPECTOR,
    payload,
    callback,
  }),
  updateInspector: (
    payload: {
      id: string
      request: UpdateRequest
    },
    callback: (succ: boolean) => void
  ): InspectorsActions => ({
    type: inspectorsTypes.UPDATE_INSPECTOR,
    payload,
    callback,
  }),
}

export default actions
