import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { DropDownProps } from './types';

import styles from './styles.module.scss';
import Icon from '../Icon';
import { FC, useEffect, useState } from 'react';

const DropDown: FC<DropDownProps> = ({ title, children, className = "", iconOpen, iconClose, initallyExpanded = false, iconColor = "initial" }) => {
    const [expanded, setExpanded] = useState(initallyExpanded);
    const IconOpen = iconOpen || <Icon name="ExpandMore" color={iconColor} />
    const IconClose = iconClose || <Icon name="ExpandLess" color={iconColor} />

    useEffect(() => {
        setExpanded(initallyExpanded)
    }, [initallyExpanded])
    return (
        <>
            <Accordion expanded={expanded} variant='outlined' className={`${styles.Accordion} ${className}`} onChange={() => setExpanded(!expanded)}>
                <AccordionSummary
                    expandIcon={expanded ? IconClose : IconOpen}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={styles.Accordion__Summary}
                >
                    {title}
                </AccordionSummary >
                <AccordionDetails className={styles.Accordion__Details}>
                    {children}
                </AccordionDetails>
            </Accordion>
        </>
    )
}

export default DropDown