import { FC, useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Radio,
  Typography,
} from 'src/components/UI'
import { repairItemActions, itemsActions } from 'src/ducks/actions'
import { ITEM_CATEGORY } from 'src/helpers'
import { WorkOrdersContext } from '../../../context'
import { getEstimate, getItemById, getMatch } from 'src/ducks/selectors'
import SaveIcon from '@mui/icons-material/Save'
import styles from './styles.module.scss'
import { workOdersTypes } from '../../../context/types'

const MultiMatches: FC = () => {
  const dispatch = useDispatch()
  const { dispatch: dispatchContext } = useContext(WorkOrdersContext)

  const itemMatch = useSelector(getMatch())
  const estimate = useSelector(getEstimate())
  const item = useSelector(getItemById(itemMatch.itemId))

  const [loading, setLoading] = useState<boolean>(false)
  const [priceItemId, setPriceItemId] = useState(
    itemMatch.matches.find((mt) => mt.priceItemTitle === item.title)
      ?.priceItemId || itemMatch.matches[0].priceItemId
  )

  const handleCancel = () => {
    dispatchContext({ type: 'SET_MODAL_OPEN', payload: false })
  }

  const handleSubmit = () => {
    const match = itemMatch.matches.find((mt) => mt.priceItemId === priceItemId)

    setLoading(true)

    dispatch(
      repairItemActions.fetchPriceItem(
        {
          repairItemId: priceItemId,
          territoryId: estimate?.properties?.territory?.id || '',
        },
        (succ, priceItem) => {
          if (succ && priceItem) {
            const request = {
              title: match?.priceItemTitle,
              quantity: 1,
              unitCost: priceItem.unitCost,
              unitLaborHours: priceItem.unitLaborHours,
              unitMaterialCost: priceItem.unitMaterialCost,
              unitPrice: priceItem.unitPrice,
              margin: priceItem.margin,
              profit: priceItem.profit,
              disclaimer: priceItem.disclaimer,
              category: priceItem.category as ITEM_CATEGORY,
              severity: priceItem.severity,
            }

            dispatch(
              itemsActions.updateItem(
                {
                  itemId: item.id || '',
                  partialItem: request,
                  isEstimate: true,
                },
                (succ, newItem) => {
                  dispatchContext({
                    type: workOdersTypes.SET_ITEM,
                    payload: { ...newItem, displayTitle: newItem?.title },
                  })
                  dispatch(
                    itemsActions.fetchItems(
                      { params: { jobId: estimate?.id } },
                      (succ) => {}
                    )
                  )
                  dispatch(
                    itemsActions.fetchItemsByCategory(
                      { params: { jobId: estimate?.id } },
                      (succ) => {}
                    )
                  )
                  handleCancel()
                }
              )
            )
          }
        }
      )
    )
  }

  return (
    <>
      {loading && (
        <Grid container justifyContent="center" alignItems='center' height='200px'>
          <CircularProgress />
        </Grid>
      )}
      {!loading && (
        <Grid
          container
          spacing={'12px'}
          flexDirection="column"
          padding="12px"
          mt={0}
        >
          <Grid item>
            <Typography variant="h5">Multiple Results</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6">{item?.title}</Typography>
            {itemMatch.matches.map((match) => (
              <Box
                key={match.priceItemId}
                className={
                  priceItemId === match.priceItemId
                    ? styles.selected
                    : styles.unselected
                }
                mt="12px"
                onClick={() => setPriceItemId(match.priceItemId)}
                style={{ cursor: 'pointer' }}
              >
                <Typography variant="h6">{match.displayTitle}</Typography>
                <Radio
                  sx={{ padding: 0 }}
                  checked={priceItemId === match.priceItemId}
                />
              </Box>
            ))}
          </Grid>
          <Grid container item justifyContent="flex-end">
            <Button variant="containedLight" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              variant="contained"
              endIcon={<SaveIcon style={{ width: '14px', height: '14px' }} />}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default MultiMatches
