import { Invitation, InvitationActions, InvitationReducer, invitationTypes } from './types';

import { authTypes } from '../auth/types'

const initialState: InvitationReducer = {
    invitations: []
}

const reducer = (state = initialState, action: InvitationActions): InvitationReducer => {
    const { type, payload } = action

    switch (type) {
        case authTypes.LOGIN_REQUEST: { return initialState }
        case authTypes.LOGOUT_REQUEST: { return initialState }

        case invitationTypes.SET_INVITATIONS: {

            return {
                ...state,
                invitations: payload as unknown as Invitation[]
            }
        }

        default:
            return state
    }
}

export default reducer
