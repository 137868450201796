import { formatParams } from 'src/helpers';
import axios from '../axios'
import { ApiController } from './types'

const base = '/notes'

const apiController: ApiController = {
  createNote: async (request) =>
    await axios
      .post(`${base}`, request)
      .then(response => response.data),
  getNotes: async ({ params }) => {
    const parameters = formatParams(params);
    return await axios
      .get(`${base}?${parameters}`)
      .then(response => response.data)
  },
}

export default apiController
