import { InspectionCompany } from '../types'

/** TYPES **/
export const inspectionCompanyTypes = {
  FETCH_INSPECTION_COMPANY: 'FETCH_INSPECTION_COMPANY',
  SET_INSPECTION_COMPANY: 'SET_INSPECTION_COMPANY',
}

/** ACTIONS **/
export interface FetchInspectionCompanyAction {
  type: typeof inspectionCompanyTypes.FETCH_INSPECTION_COMPANY
  payload: string
  callback?: (succ: boolean) => void
}

export interface SetInspectionCompanyAction {
  type: typeof inspectionCompanyTypes.SET_INSPECTION_COMPANY
  payload: InspectionCompany | null
}

export type InspectionCompanyActions =
  | FetchInspectionCompanyAction
  | SetInspectionCompanyAction

/** REDUCER **/
export type InspectionCompanyType = InspectionCompany | null
