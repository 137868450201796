import { LicensesActions, LicensesType } from './types'
import { licensesTypes } from './types'

const actions = {
  fetchLicenses: (
    payload: {
      page?: number
      size?: number
      searchParams?: any
    },
    callback?: (succ: boolean) => void
  ): LicensesActions => ({
    type: licensesTypes.FETCH_LICENSES,
    payload,
    callback,
  }),

  fetchCsvLicenses: (
    payload: {
      count?: number,
      searchParams?: any
    },
    callback?: (succ: boolean) => void
  ): LicensesActions => ({
    type: licensesTypes.FETCH_CSV_LICENSES,
    payload,
    callback
  }),

  setLicenses: (payload: LicensesType): LicensesActions => ({
    type: licensesTypes.SET_LICENSES,
    payload,
  }),

  setCsvLicenses: (payload: LicensesType): LicensesActions => ({
    type: licensesTypes.SET_CSV_LICENSES,
    payload,
  })
}

export default actions