import storeInfo from '../store'
import { JobHistory, OrderHistory, RemovedItem } from '../types'

export const getHistoryJobTask = (): any[] => {
  const { history: { historyJobTasks } } = storeInfo.store.getState()
  return historyJobTasks
}

export const getJobHistory = (): JobHistory[] => {
  const { history: { jobHistory } } = storeInfo.store.getState()
  return jobHistory
}

export const getJobHistoryStatus = (): OrderHistory[] => {
  const { history: { jobHistoryStatus } } = storeInfo.store.getState()
  return jobHistoryStatus
}


export const getOrderHistoryByJob = (): JobHistory[] => {
  const { history: { orderHistory } } = storeInfo.store.getState()
  return orderHistory
}

export const getRemovedItemsHistory = (): RemovedItem[] => {
  const { history: { historyRemovedItems } } = storeInfo.store.getState()
  return historyRemovedItems
}