import { Box, Button, Grid, Typography } from '@mui/material'
import styles from './styles.module.scss'

import { icons } from 'src/assets'
import { useState } from 'react'
import InspectionDataModal from './components/InspectionDataModal'
import { useSelector } from 'react-redux'
import { getEstimate } from 'src/ducks/selectors'
import { ContentBox } from '../../../EstimateTopInfo/Templates'
import { LabeledText } from 'src/components/UI'

const InspectionData = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const estimate = useSelector(getEstimate())
  const estimateInspection = estimate?.properties?.estimateInspection
  const { inspector } = estimateInspection || {}

  return (
    <Grid
      item
      container
      direction={'column'}
      gap="1rem"
      className={styles.InspectionData}
    >
      {modalOpen && (
        <InspectionDataModal
          open={modalOpen}
          setOpen={setModalOpen}
          onClose={() => {
            setModalOpen(false)
          }}
        />
      )}
      <Typography variant="h6Bold">Inspection Data</Typography>
      {!estimateInspection ? (
        <Button
          variant="containedBig"
          style={{ width: 'fit-content' }}
          startIcon={<icons.Add style={{ width: '16px' }} />}
          onClick={() => {
            setModalOpen(true)
          }}
        >
          Add Inspection Data
        </Button>
      ) : (
        <ContentBox className={styles.InspectionData__box}>
          <Box>
            <LabeledText
              labelValue="Inspection Company:"
              textValue={inspector?.inspectionCompany?.name}
            />
            <LabeledText
              labelValue="Inspector Name:"
              textValue={inspector?.name}
            />
            <LabeledText
              labelValue="Address:"
              textValue={inspector?.inspectionCompany?.address?.fullAddress}
            />
            <LabeledText labelValue="Phone:" textValue={inspector?.phone} />
          </Box>
          <Button
            className={styles.InspectionData__button}
            variant="icon"
            endIcon={<icons.Edit />}
            onClick={() => setModalOpen(true)}
          />
        </ContentBox>
      )}
    </Grid>
  )
}

export default InspectionData
