import { StyleSheet } from '@react-pdf/renderer'

export default StyleSheet.create({
  body: {
    position: 'relative',
    padding: '48px 24px',
  },
  bodyItem: {
    padding: '8px 2px',
  },
  bodyItems: {
    padding: '12px 4px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  containerHorizontal: {
    display: 'flex',
    flexDirection: 'row',
  },
  logoView: {
    position: 'absolute',
    top: 12,
    right: 12,
  },
  logo: {
    display: 'flex',
    width: '175px',
  },
  block: {
    paddingTop: '32px',
  },
  topSeparation: {
    paddingTop: '16px',
  },
  leftSeparation: {
    padding: '0px 8px',
  },
  verticalSeparator: {
    margin: '8px 0px',
  },
  sectionTitle: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#FAFBFF',
    padding: '8px 16px',
  },
  separator: {
    width: '100%',
    height: '1px',
    backgroundColor: '#1A2A55',
  },
  littleSpacing: {
    margin: '8px 0px',
  },
  bigSpacing: {
    margin: '48px 0px 4px',
  },
  textRight: {
    display: 'flex',
    width: '100%',
    textAlign: 'right',
  },
  title: {
    fontFamily: 'BetterSansRegular',
    fontSize: '18px',
    color: '#1A2A55',
  },
  subtitle: {
    fontFamily: 'BetterSansRegular',
    fontSize: '12px',
    color: '#1A2A55',
  },
  titleBold: {
    fontFamily: 'BetterSansRegular',
    fontSize: '18px',
    color: '#1A2A55',
  },
  price: {
    fontFamily: 'BetterSansRegular',
    fontSize: '20px',
    color: '#1A2A55',
  },
  content: {
    position: 'relative',
    padding: '4px 16px',
  },
  itemText: {
    fontFamily: 'BetterSansRegular',
    flexWrap: 'wrap',
    fontSize: '10px',
    color: '#1A2A55',
  },
  userInfoText: {
    fontFamily: 'BetterSansRegular',
    fontSize: '8px',
    color: '#494A5A',
  },
  textBold: {
    fontFamily: 'BetterSansBold',
  },
  printDate: {
    position: 'absolute',
    fontSize: 12,
    bottom: 15,
    left: 30,
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 15,
    right: 30,
    color: 'grey',
  },
  itemImages: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    // justifyContent: 'space-evenly',
    margin: '8px 0px',
  },
  image: {
    display: 'flex',
    height: '72px',
    borderRadius: '8px',
    marginRight: '16px',
  },
  userInfoImage: {
    display: 'flex',
    height: '32px',
    marginRight: '4px',
  },
  chartImage: {
    display: 'flex',
    width: '100%',
  },
  userInfoLabelImage: {
    display: 'flex',
    width: '96px',
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  alignBetween: {
    alignContent: 'space-between',
  },
  justifyBetween: {
    justifyContent: 'space-between',
  },
  justifyCenter: {
    justifyContent: 'center',
  },
  removed: {
    backgroundColor: '#FFF4F4',
    color: 'red',
  },
  justifyEnd: {
    justifyContent: 'flex-end',
  },
})
