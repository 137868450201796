/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react'
import { Grid, Typography, Box, LabeledText } from 'src/components/UI'
import { getTradeTypes, getVendorPrimaryEmail } from 'src/ducks/selectors'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import { Button, Divider, IconButton, Tooltip } from "@mui/material"
import { INSURANCE_TYPE } from 'src/helpers'
import moment from 'moment'
import { TradesAndVendorProps } from './types'
import { TradeType } from 'src/ducks/types'
import { useSelector } from 'react-redux'

const TradesAndVendor: FC<TradesAndVendorProps> = (
  { selectedVendor, unselectVendor, selectTrades, selectedTrades, dispatched }
) => {
  const [option, setOption] = useState<'trades' | 'vendor'>('trades')
  const tradeTypes = useSelector(getTradeTypes) as TradeType[]

  useEffect(() => {
    if (selectedVendor) setOption('vendor')
  }, [selectedVendor])

  const VendorIcon = (
    { name, enable, tooltipTitle, validToDate }: {
      name: string,
      enable: boolean,
      tooltipTitle: string,
      validToDate?: number
    }) => {

    const expired = !validToDate || validToDate < moment().unix()
    let title = tooltipTitle

    if (!enable || expired) {
      title = 'No '.concat(tooltipTitle)
      if (validToDate) title = title.concat(`. Expired on ${moment.unix(validToDate).format('MM/DD/YYYY')}`)
    } else {
      title = tooltipTitle.concat(' on file')
      if (validToDate) title = title.concat(`. Expires on ${moment.unix(validToDate).format('MM/DD/YYYY')}`)
    }

    return (
      <Grid item>
        <Tooltip title={title}>
          <div>
            <Icon name={name} sx={{ paddingTop: '4px' }} />
            {(!enable || expired) && (
              <Icon name='Block' color='red' sx={{
                position: 'absolute',
                marginTop: '-16px',
              }} />
            )}
          </div>
        </Tooltip>
      </Grid>
    )
  }

  const VendorDocs = () => {
    if (!selectedVendor) return null

    return (
      <>
        <VendorIcon name='ContactMail' enable={selectedVendor?.hasTaxID ?? false} tooltipTitle='Tax ID' />
        <VendorIcon name='Description' enable={selectedVendor?.hasW9 ?? false} tooltipTitle='W9' />
        <VendorIcon name='AccountBalance' enable={selectedVendor?.hasACH ?? false} tooltipTitle='ACH' />
        <VendorIcon
          name='Person'
          enable={selectedVendor?.insuranceList?.some(ins => ins.insuranceType === INSURANCE_TYPE.WORKERS_COMPENSATION) ?? false}
          tooltipTitle="Worker's Compensation"
          validToDate={selectedVendor?.insuranceList?.find(ins => ins.insuranceType === INSURANCE_TYPE.WORKERS_COMPENSATION)?.validToDate}
        />
        <VendorIcon
          name='Assignment'
          enable={selectedVendor?.insuranceList?.some(ins => ins.insuranceType === INSURANCE_TYPE.CERTIFICATE_OF_INSURANCE) ?? false}
          tooltipTitle='General Liability'
          validToDate={selectedVendor?.insuranceList?.find(ins => ins.insuranceType === INSURANCE_TYPE.CERTIFICATE_OF_INSURANCE)?.validToDate}
        />
        {!dispatched && (
          <Grid item sx={{ padding: '4px 4px 0 12px !important' }}>
            <IconButton onClick={unselectVendor}>
              <Icon name='Close' color='var(--gray100)' background='var(--red600)' sx={{ borderRadius: 25 }} />
            </IconButton>
          </Grid>
        )}
      </>
    )
  }

  const Vendor = () => {
    if (!selectedVendor) return (
      <Typography sx={{ padding: '8px' }}>
        Select a Vendor from the table below to display the info.
      </Typography>
    )

    return (
      <>
        <Grid item xs={7}>
          <LabeledText labelValue='Company:' textValue={selectedVendor?.companyName} />
        </Grid>
        <Grid item xs={5}>
          <LabeledText labelValue='Contact:' textValue={`${selectedVendor?.firstName} ${selectedVendor?.lastName}`} />
        </Grid>
        <Grid item xs={7}>
          <LabeledText labelValue='Email:' textValue={selectedVendor ? getVendorPrimaryEmail(selectedVendor) : ''} />
        </Grid>
        <Grid item xs={5}>
          <LabeledText labelValue='Phone:' textValue={selectedVendor?.phone?.toString() ?? '-'} />
        </Grid>
      </>
    )
  }

  const Trades = () => {
    return (
      <Grid container spacing={1} sx={{ padding: '4px 0 0 8px' }}>
        <Grid item xs={8} sx={{ display: 'flex', flexWrap: 'wrap' }}>
          {selectedTrades && selectedTrades.length
            ? (
              tradeTypes?.filter(trade => selectedTrades?.includes(trade.id)).map(trade => (
                <Box key={trade.id} sx={{ padding: '4px 4px 0' }}>
                  <Typography>· {trade.displayName}</Typography>
                </Box>
              ))
            ) : (
              <Typography sx={{ paddingTop: '4px' }}>
                {/* Select trades to get recommended vendors. */}
              </Typography>
            )
          }
        </Grid>
        <Grid item xs={4} sx={{ display: 'flex', alignItems: 'end', justifyContent: 'flex-end' }}>
          <Button
            variant='outlined'
            size='small'
            sx={{ width: '110px', lineHeight: 1 }}
            onClick={selectTrades}
          >
            Select Trades
          </Button>
        </Grid>
      </Grid>
    )
  }

  return (
    <Box sx={{
      backgroundColor: 'var(--gray100)',
      borderRadius: '8px'
    }}>
      <Grid container spacing={1} sx={{ minHeight: '36px' }}>
        <Grid item xs={6} sx={{ display: 'flex', gap: '24px' }} paddingTop={'4px !important'}>
          <Box
            onClick={() => setOption('trades')}
            sx={{
              display: 'flex', gap: '4px', cursor: 'pointer', alignItems: 'center', paddingLeft: '6px',
              color: option === 'trades' ? 'var(--blue700)' : 'var(--gray500)'
            }}
          >
            <Icon name='Build' height='0.8rem' />
            <Typography variant='h6' paddingTop='2px'>Trades</Typography>
          </Box>
          <Box
            onClick={() => setOption('vendor')}
            sx={{
              display: 'flex', gap: '4px', cursor: 'pointer', alignItems: 'center',
              color: option === 'vendor' ? 'var(--blue700)' : 'var(--gray500)'
            }}
          >
            <Icon name='Person' height='0.8rem' />
            <Typography variant='h6' paddingTop='2px'>
              {selectedVendor ? `${selectedVendor?.firstName} ${selectedVendor?.lastName}` : 'Vendor'}
            </Typography>
          </Box>
        </Grid >
        <Grid container item xs={6} spacing={1} justifyContent='flex-end' paddingRight={1}>
          {option === 'vendor' && <VendorDocs />}
        </Grid>
      </Grid >
      <Divider style={{ margin: '0 8px', borderWidth: '1px' }} />
      <Grid container spacing={1} sx={{ minHeight: '68px', height: 'auto', padding: '8px' }}>
        {option === 'trades' ? <Trades /> : <Vendor />}
      </Grid>
    </Box >
  )
}

export default TradesAndVendor