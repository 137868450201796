import { authTypes } from '../auth/types'
import { HomemartActions, HomemartType, homemartTypes } from './types'

const initialState: HomemartType = {
  partners: [],
  categories: [],
  tags: [],
}

const reducer = (state = initialState, action: HomemartActions): HomemartType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case homemartTypes.SET_HOMEMART: {
      return { ...state, ...(payload as Partial<HomemartType>) }
    }

    default:
      return state
  }
}

export default reducer
