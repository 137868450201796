import { FiltersAction, FiltersType, filtersTypes } from './types'

const actions = {
  setFilters: (payload: Partial<FiltersType>): FiltersAction => ({
    type: filtersTypes.SET_FILTERS,
    payload,
  }),
  clearFilters: (): FiltersAction => ({ type: filtersTypes.CLEAR_FILTERS, payload: null })
}

export default actions
