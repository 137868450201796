import { InsertLink } from '@mui/icons-material'
import { Box, Grid, Typography } from '@mui/material'
import Table from 'src/components/UI/CustomUI/organisms/Table'

import headers from './headers'
import { useSelector } from 'react-redux'
import { getPCDetails, getWorkOrderContractMilestones } from 'src/ducks/selectors'
import { LabeledText } from 'src/components/UI'
import styles from './styles.module.scss'


const Details = () => {
  const pcDetails = useSelector(getPCDetails())

  const mileStones = [
    {
      totals: 'Total Open',
      count: pcDetails?.totalOpenTasks || 0,
      color: '#3A66FF',
    },
    {
      totals: 'Total Past Due',
      count: pcDetails?.totalPastDue || 0,
      color: '#E01F1F',
    },
    {
      totals: 'Total Due Today',
      count: pcDetails?.totalDueToday || 0,
      color: '#EBA900',
    },
    // {
    //   totals: 'Total Due Future',
    //   count: pcDetails?.totalDueFuture || 0,
    //   color: '#0E905A',
    // },
  ]

  return (
    <Grid container item spacing={1} style={{ overflow: 'overlay', flexWrap: 'nowrap' }} height='250px'>
      <Grid container item direction='column' xs={6} height="max-content">
        <Grid item>
          <Box border="2px var(--gray200) solid" display='flex' flexDirection='column' padding='8px' gap='8px' borderRadius='8px' bgcolor='var(--gray100)'>
            <LabeledText alignItems="center" labelValue='Name:' textValue={pcDetails?.projectCoordinatorName || '-'} justifyContent='space-between' />
            <LabeledText alignItems="center" labelValue='Date:' textValue={pcDetails?.date || '-'} justifyContent='space-between' />
            <LabeledText alignItems="center" labelValue='AVG days to complete WO:' textValue={pcDetails?.avgDaysComplete ? `${pcDetails?.avgDaysComplete} days` : '-'} justifyContent='space-between' />
            <LabeledText alignItems="center" labelValue='Business Days:' textValue={pcDetails?.businessDays ? `${pcDetails?.businessDays} days` : '-'} justifyContent='space-between' />
          </Box>
        </Grid>
      </Grid>
      <Box border="2px var(--gray200) solid" display='flex' height="fit-content" width="50%" flexDirection='column' marginLeft="8px" marginTop="8px" borderRadius='8px'>
        <div className={styles.TaskHeader}>
          <Typography fontFamily="var(--font-bold)" fontSize="12px">Work Order Tasks</Typography>
        </div>
        {mileStones.map((mileStone, index) => (
          <div className={styles.TaskRow} style={index === (mileStones.length - 1) ? {} : { borderBottom: "2px var(--gray200) solid" }} key={`row-${index}`}>
            <div className={styles.TaskTitleContainer}>
              <Typography color={mileStone.color} className={styles.TaskTitle}>{mileStone.totals}</Typography>
            </div>
            <div className={styles.TaskValueContainer}>
              <Typography align='center'>{mileStone.count}</Typography>
            </div>
          </div>
        ))}
      </Box>
      {/* <Grid item xs={6}>
        <Table
          headers={headers}
          defaultOrder="desc"
          // loading={false}
          hasPagination={false}
        >
          {mileStones ?? []}
        </Table>
      </Grid> */}
    </Grid>
  )
}

export default Details
