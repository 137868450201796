import JsZip from 'jszip'
import FileSaver from 'file-saver'
import { ImageFile } from 'src/ducks/types'

export const imageMimeTypes = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.webp']
export const videoMimeTypes = ['.webm', '.ogg', '.mp4', '.mov', '.quicktime']
export const applicationMimeTypes = ['.pdf']
export const imageAndDocTypes = 'image/*,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
export const imageDocExcelTypes = 'image/*,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.csv,.xlsx,.xlsm,.xlsb,.xltx,.xls'
export const MAX_FILES = 99

export const getFileExtension = (file: File): string => {
  try {
    return file.type.split('/')[1]
  } catch (_error) {
    return ''
  }
}

export const dataURItoFile = (dataUri: string, name: string): File => {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataUri.split(',')[1])

  // separate out the mime component
  const mimeString = dataUri.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  return new File([new Blob([ab], { type: mimeString })], name, { type: mimeString })
}

export const downloadURI = (uri: string, name: string): void => {
  const link = document.createElement('a')
  link.download = name
  link.href = uri
  link.target = '_blank'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const downloadZip = async (zipName: string, images: ImageFile[], callback: () => void): Promise<void> => {
  const zip = JsZip()
  for (let i = 0; i < images.length; i++) {
    const blob = await fetch(images[i].fileUrl).then(r => r.blob())
    const type = '.'.concat(blob.type.split('/')[1])
    zip.file(zipName.concat('.', i.toString(), type), blob)
  }
  zip.generateAsync({ type: 'blob' }).then((zipFile: any) => {
    return FileSaver.saveAs(zipFile, zipName)
  })
  callback()
}
