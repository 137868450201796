import { authTypes } from '../auth/types'
import { SiteVisitsActions, SiteVisitsType, siteVisitsTypes } from './types'

const initialState: SiteVisitsType = {
  start: 0,
  limit: 0,
  total: 0,
  items: []
}

const reducer = (state = initialState, action: SiteVisitsActions): SiteVisitsType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case siteVisitsTypes.SET_SITE_VISITS: {
      return payload as SiteVisitsType
    }

    default:
      return state
  }
}

export default reducer
