import TabNavigation from 'src/components/UI/CustomUI/organisms/TabNavigation'
import Week from './Tabs/Week'
import Month from './Tabs/Month'
import { Box, Typography } from 'src/components/UI'
import { icons } from 'src/assets'

const Statistics = () => {

  const UploadAction = () => {
    return (
      <Box display='flex'>
        <icons.BarChart height='9px' width='9px'
          sx={{ color: 'var(--black-color)', margin: '0px 4px' }} />
        <Typography variant='h5'>Statistics</Typography>
      </Box>
    )
  }

  const Tabs = [
    {
      label: 'Week',
      content: <Week />,
      actionButton: UploadAction()
    },
    {
      label: 'Month',
      content: <Month />,
      actionButton: UploadAction()
    },
  ]

  return (
    <>
      <TabNavigation
        tabs={Tabs}
        variant='contained'
        tabWidth="fit-content"
        tabAlign="flex-end"
        defaultActive={0}
      />
    </>
  )
}

export default Statistics
