import { FC } from 'react'
import { CircularProgress, LinearProgress } from '../../../'
import { LoaderProps, LOADER_TYPES } from './types'

const Loader: FC<LoaderProps> = ({ type = LOADER_TYPES.CIRCULAR, margin, size = 40 }) => {
  switch (type) {
    case LOADER_TYPES.LINEAR:
      return <LinearProgress />
    default:
      return (
        <CircularProgress
          size={size}
          style={{
            display: 'block',
            margin: margin ? margin : '30px auto',
          }}
        />
      )
  }
}

export default Loader
