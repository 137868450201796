import { UpdateRequest } from '~api/types'
import { MaintenanceService, SubscriptionItem } from '../types'
import { subscriptionTypes, SubscriptionActions } from './types'

const actions = {
  fetchSubscription: (payload: string, callback?: (succ: boolean) => void): SubscriptionActions => ({
    type: subscriptionTypes.FETCH_SUBSCRIPTION,
    payload,
    callback
  }),
  setSubscription: (payload: SubscriptionItem | null): SubscriptionActions => ({
    type: subscriptionTypes.SET_SUBSCRIPTION,
    payload
  }),
  updateSubscriptionByPath: (
    payload: UpdateRequest,
    callback: (succ: boolean) => void
  ): SubscriptionActions => ({
    type: subscriptionTypes.UPDATE_SUBSCRIPTION,
    payload,
    callback
  }),
  createJobFromSubscription: (
    payload: { subscriptionId: string, title: string },
    callback?: (succ: boolean, service: MaintenanceService | null) => void): SubscriptionActions => ({
      type: subscriptionTypes.CREATE_JOB_FROM_SUBSCRIPTION,
      payload,
      callback
    }),
  deleteSubscription: (
    payload: { subscriptionId: string },
    callback?: (succ: boolean) => void): SubscriptionActions => ({
      type: subscriptionTypes.DELETE_SUBSCRIPTION,
      payload,
      callback
    }),
}

export default actions
