import { FC, useState, useContext, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import { icons } from "src/assets"
import { PrivateLoader } from "src/components/templates"
import { Button, Checkbox, FormControlLabel, Grid, SelectInput, TextFieldLabel, Typography } from "src/components/UI"
import UploadDocuments from "src/components/UI/CustomUI/molecules/UploadDocument"
import { companiesActions, filesActions, widgetActions, widgetsActions } from "src/ducks/actions"
import { FILE_TYPE, getDifferenceBetweenObjects, isEmpty, ParseFilesUrl, PHONE_MASK_INPUT, PHONE_MASK_REGEXP, validateEmail } from "src/helpers"
import { WidgetsContext } from "../../context"
import { dispatchTypes, MODAL_TYPE } from "../../context/types"
import env from "@beam-australia/react-env"
import moment from "moment"
import { getCompany } from "src/ducks/company/selectors"
import { CompanyType } from "src/ducks/company/types"
import { UpdateRequest } from "~api/types"
import { isEqual } from "lodash"
import { PunchlistFile, Widget } from "src/ducks/types"
import { getCompaniesForDropdownWithoutAll, getWidget } from "src/ducks/selectors"
import FontPicker from "font-picker-react"
import { SelectInputOption } from "src/components/UI/CustomUI/atoms/SelectInput/types"
import Icon from "src/components/UI/CustomUI/atoms/Icon"

const FILES_URL = env('FILES_URL') ?? ''

const fontSizeOptions = [
    { label: '6', key: '6' },
    { label: '7', key: '7' },
    { label: '8', key: '8' },
    { label: '9', key: '9' },
    { label: '10', key: '10' },
    { label: '11', key: '11' },
    { label: '12', key: '12' },
    { label: '14', key: '14' },
    { label: '16', key: '16' },
    { label: '18', key: '18' },
    { label: '20', key: '20' },
    { label: '24', key: '24' },
    { label: '26', key: '26' },
    { label: '28', key: '28' },
    { label: '32', key: '32' },
    { label: '36', key: '36' },
    { label: '38', key: '36' },
]

const EditWidget: FC = () => {

    const dispatch = useDispatch()
    const { dispatch: dispatchContext } = useContext(WidgetsContext)
    const widget = useSelector(getWidget())

    const [loading, setLoading] = useState(false)
    const [showWarnings, setShowWarnings] = useState(false)
    const [hasUpdatedData, setHasUpdatedData] = useState(false)
    const [file, setFile] = useState<any>({ fileUrl: widget.imageUrl })
    const companies = useSelector(getCompaniesForDropdownWithoutAll)
    const [uploadingFile, setUploadingFile] = useState(false)


    // FORM
    const [editWidgetForm, setEditWidgetForm] = useState<Partial<Widget>>(widget)

    useEffect(() => {
        setHasUpdatedData(!isEqual(editWidgetForm, widget))
    }, [editWidgetForm, widget])

    const handleCancel = () => {
        dispatchContext({ type: dispatchTypes.SET_VALUE, payload: { attr: 'modalOpen', value: false } })
    }

    const handleSave = () => {
        if (widget?.id) {
            setLoading(true)
            const updateRequest: UpdateRequest = getDifferenceBetweenObjects(
                {
                    ...editWidgetForm,
                },
                {
                    ...widget,
                },
                'replace'
            )
            dispatch(
                widgetActions.updateWidgetByPath(updateRequest, (succ: boolean) => {
                    dispatch(widgetsActions.fetchWidgets(null, () => {
                        dispatchContext({
                            type: dispatchTypes.SET_VALUE, payload: {
                                attr: 'modalOpen',
                                value: false
                            }
                        })
                        setLoading(false)
                    }))
                })
            )
        } else {
            setShowWarnings(true)
        }

    }

    /*  const handleDelete = () => {
         dispatchContext({
             type: dispatchTypes.SET_VALUE, payload: {
                 attr: 'modalType',
                 value: MODAL_TYPE.DELETE
             }
         })
     } */

    const handleUploadFile = (file: null | File, callback: () => void) => {
        if (file) {
            setUploadingFile(true)
            dispatch(filesActions.uploadFile(file, (uploadSucc, fileName) => {
                if (uploadSucc && fileName) {
                    const fileToSave = {
                        name: file.name,
                        fileUrl: ParseFilesUrl({
                            baseUrl: FILES_URL,
                            avatar: fileName ?? ''
                        }),
                        description: file.name ?? '',
                        fileType: FILE_TYPE.MATERIAL_INVOICE,
                        uploadedOn: moment().unix(),

                    }
                    callback()
                    setFile(fileToSave)
                    setEditWidgetForm({
                        ...editWidgetForm,
                        imageUrl: fileToSave.fileUrl
                    })
                }
                setUploadingFile(false)
            }))
        } else {
            setFile(null)
        }
    }

    return (
        <PrivateLoader loading={loading}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextFieldLabel
                        label="Name"
                        labelVariant="h6"
                        onChange={(event) => setEditWidgetForm({
                            ...editWidgetForm,
                            name: event.target.value
                        })}
                        value={editWidgetForm?.name || ''}
                    />
                </Grid>
                <Grid item xs={6}>
                    <SelectInput
                        label="Company"
                        options={companies}
                        onChange={(newSelected: SelectInputOption) => setEditWidgetForm({
                            ...editWidgetForm,
                            affiliate: newSelected.key
                        })}
                        value={companies.find(company => {
                            return company.key === editWidgetForm.affiliate
                        })}
                    />
                </Grid>
            </Grid>
            <Grid marginY={2}>
                <Typography variant='h5Bold'>Widget Content</Typography>
                <Grid container spacing={2} marginY={.5}>
                    <Grid item xs={6}>
                        <Typography variant="h6">
                            Font Family
                        </Typography>
                        <FontPicker
                            apiKey={'AIzaSyAog_b3tICviSL843KIwDdMIEoDzUMlQEs'}
                            activeFontFamily={editWidgetForm?.fontFamily}
                            onChange={(nextFont) => setEditWidgetForm({
                                ...editWidgetForm,
                                fontFamily: nextFont.family
                            })}
                            key="font-picker1"
                            pickerId="fontpicker1"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextFieldLabel
                            label="Font Color"
                            labelVariant="h6"
                            onChange={(event) => setEditWidgetForm({
                                ...editWidgetForm,
                                fontColor: event.target.value
                            })}
                            value={editWidgetForm?.fontColor || ''}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} marginY={.5}>
                    <Grid item xs={6}>
                        <SelectInput
                            label="Font Size Header"
                            options={fontSizeOptions}
                            onChange={(newSelected: SelectInputOption) => setEditWidgetForm({
                                ...editWidgetForm,
                                headerFontSize: Number(newSelected.key)
                            })}
                            value={editWidgetForm.headerFontSize}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectInput
                            label="Font Size Content"
                            options={fontSizeOptions}
                            onChange={(newSelected: SelectInputOption) => setEditWidgetForm({
                                ...editWidgetForm,
                                contentFontSize: Number(newSelected.key)
                            })}
                            value={editWidgetForm.contentFontSize}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextFieldLabel
                            label="Background Color"
                            labelVariant="h6"
                            onChange={(event) => setEditWidgetForm({
                                ...editWidgetForm,
                                backgroundColor: event.target.value
                            })}
                            value={editWidgetForm?.backgroundColor || ''}
                        />
                    </Grid>

                </Grid>
                <Grid container spacing={2} marginY={.5}>
                    <Grid item xs={12}>
                        <Typography variant='h6' color='var(--gray700)'>Image:</Typography>
                        <UploadDocuments
                            error={showWarnings && !file}
                            file={file ?? null}
                            dropzoneText='Image'
                            preview={true}
                            onChange={!uploadingFile ? handleUploadFile : () => { }}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid marginY={2}>
                <Typography variant='h5Bold'>Buttons</Typography>
                <Grid container spacing={2} marginY={.5}>
                    <Grid item xs={6}>
                        <Typography variant="h6">
                            Font Family
                        </Typography>
                        <FontPicker
                            apiKey={'AIzaSyAog_b3tICviSL843KIwDdMIEoDzUMlQEs'}
                            activeFontFamily={editWidgetForm?.buttonFontFamily}
                            onChange={(nextFont) => setEditWidgetForm({
                                ...editWidgetForm,
                                buttonFontFamily: nextFont.family
                            })}
                            key="font-picker2"
                            pickerId="fontpicker2"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectInput
                            label="Font Size"
                            options={fontSizeOptions}
                            onChange={(newSelected: SelectInputOption) => setEditWidgetForm({
                                ...editWidgetForm,
                                buttonFontSize: Number(newSelected.key)
                            })}
                            value={editWidgetForm.buttonFontSize}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} marginY={.5}>
                    <Grid item xs={6}>
                        <TextFieldLabel
                            label="Font Color"
                            labelVariant="h6"
                            onChange={(event) => setEditWidgetForm({
                                ...editWidgetForm,
                                buttonFontColor: event.target.value
                            })}
                            value={editWidgetForm?.buttonFontColor || ''}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextFieldLabel
                            label="Button Color"
                            labelVariant="h6"
                            onChange={(event) => setEditWidgetForm({
                                ...editWidgetForm,
                                buttonColor: event.target.value
                            })}
                            value={editWidgetForm?.buttonColor || ''}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid xs={12} item container spacing={2}>
                <Grid item xs={6}>
                    <Button
                        variant='containedLight'
                        fullWidth
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        onClick={handleSave}
                        variant='contained'
                        fullWidth
                        disabled={uploadingFile || !hasUpdatedData}
                    >
                        Save
                    </Button>
                </Grid>
            </Grid>
        </PrivateLoader >
    )
}

export default EditWidget