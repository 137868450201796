import { Box, Typography } from '@mui/material'
import {
  AlignType,
  HeaderType,
} from 'src/components/UI/CustomUI/organisms/Table/types'

import styles from './styles.module.scss'
import CircleIconButton from 'src/components/UI/CustomUI/molecules/CircleIconButton'
import { Delete, Edit, Image } from '@mui/icons-material'

const headers: HeaderType[] = [
  {
    id: 'offeredBy',
    label: 'Offered By',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Box padding="2px 10px">
          <Typography className={styles.Homemart__xsFont}>
            {row?.readableCompanyName}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'category',
    label: 'Category',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Box padding="2px 10px">
          <Typography className={styles.Homemart__xsFont}>
            {row?.tags?.at(0).name}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'description',
    label: 'Description',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Box padding="2px 10px">
          <Typography className={styles.Homemart__xsFont}>
            {row?.description}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'link',
    label: 'Link',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Box padding="2px 10px">
          <Typography className={styles.Homemart__xsFont}>
            {row?.actionUrl}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'cta',
    label: 'CTA',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Box padding="2px 10px">
          <Typography className={styles.Homemart__xsFont}>
            {row?.actionVerbiage}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'promo',
    label: 'Promo',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Box padding="2px 10px">
          <Typography className={styles.Homemart__xsFont}>
            {row.discountVerbiage}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'image',
    label: 'Image',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      const list = row?.titleImage?.split('/')
      return row?.titleImage ? (
        <Box
          padding="2px 10px"
          display="flex"
          gap="4px"
          color={'var(--blue700)'}
        >
          <Image style={{ width: '16px', height: '16px' }} />
          <Typography className={styles.Homemart__xsFont}>
            {list[list.length - 1]}
          </Typography>
        </Box>
      ) : (
        <></>
      )
    },
  },
  {
    id: 'logo',
    label: 'Logo',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      const list = row?.logo?.split('/')
      return row?.logo ? (
        <Box
          padding="2px 10px"
          display="flex"
          gap="4px"
          color={'var(--blue700)'}
        >
          <Image style={{ width: '16px', height: '16px' }} />
          <Typography className={styles.Homemart__xsFont}>
            {list[list.length - 1]}
          </Typography>
        </Box>
      ) : (
        <></>
      )
    },
  },
  {
    id: 'action',
    label: 'Action',
    custom: true,
    align: 'center' as AlignType,
    width: 120,
    Element: (row: any): JSX.Element => {
      return (
        <Box padding="2px 10px" display="flex" gap="10px" width="fit-content">
          <CircleIconButton
            bgcolor="var(--blue100)"
            height="40px"
            width="40px"
            icon={<Edit style={{ width: '16px', height: '16px' }} />}
            iconColor="var(--blue700)"
            onClick={() => row.onEdit()}
          />
          <CircleIconButton
            bgcolor="var(--red100)"
            height="40px"
            width="40px"
            icon={<Delete style={{ width: '16px', height: '16px' }} />}
            iconColor="var(--red700)"
            onClick={() => row.onDelete()}
          />
        </Box>
      )
    },
  },
]

export default headers
