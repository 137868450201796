import { Theme } from '../../../../UI'
import { makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => ({
  optionsContianer: {
    // maxWidth: '264px',
    padding: theme.spacing(1),
    paddingBottom: 0,
    borderColor: `${theme.palette.grey[200]} !important`,
    borderWidth: '2px !important',
    borderRadius: `${theme.spacing(1)} !important`,
    borderStyle: 'solid !important',
  },
  verticalRender: {
    width: '210px !important',
  },
  searchWidth: {
    width: '389px !important'
  },
  searchRender: {
    maxHeight: '440px !important',
    overflow: 'scroll'
  },
  button: {
    width: '34px !important',
    minWidth: '34px !important',
    borderRadius: '8px !important',
    backgroundColor: theme.palette.grey[100],
  },
  input: {
    border: '2px solid var(--border-color)',
    borderRadius: '8px',
    paddingRight: '14px',
    height: 'fit-content',
    whiteSpace: 'nowrap',
  },
  inputText: {
    padding: '6.5px 14px',
    paddingRight: '65px',
    overflowX: 'clip',
    textOverflow: 'ellipsis',
  },
}))
