import { authTypes } from '../auth/types'
import { CompaniesActions, companiesTypes, CompaniesType } from './types'

const initialState: CompaniesType = {
  allItems: [],
  items: [],
  start: 0,
  limit: 50,
  total: 0,
}
const reducer = (
  state = initialState,
  action: CompaniesActions
): CompaniesType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: {
      return initialState
    }
    case authTypes.LOGOUT_REQUEST: {
      return initialState
    }

    case companiesTypes.SET_COMPANIES: {
      return {
        ...state,
        ...payload
      } as CompaniesType
    }

    case companiesTypes.SET_ALL_COMPANIES: {
      return {
        ...state,
        allItems: (payload as CompaniesType).items,
        total: (payload as CompaniesType).total
      }
    }

    default:
      return state
  }
}

export default reducer
