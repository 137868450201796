import { State } from '../reducers'
import { TradeType } from '../types'

export const getTradeTypes = (state: State): TradeType[] => {
  return state.tradeTypes?.items || []
}

export const getTradeForSelect = (state: State): any[] => {
  const data = state.tradeTypes?.items?.reduce((acc: any, curr) => {
    return [...acc, (acc = {
      id: curr.id,
      label: curr.displayName,
      hide: true
    })]
  }, [])
  return data
}
