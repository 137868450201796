import { Box, Grid, Typography } from 'src/components/UI'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import ShowMore from 'src/components/UI/CustomUI/atoms/ShowMore'
import Tag from 'src/components/UI/CustomUI/atoms/Tag'
import {
  AlignType,
  HeaderType,
} from 'src/components/UI/CustomUI/organisms/Table/types'
import { formatTimestamp, round } from 'src/helpers'
import { licenseCheckFilters } from './FilterHeader/constants'
import { reviewStatusColors } from './constants'

const applyRegExp = (phone: string): string => {
  if (!phone) return ''

  return String(phone).replace(/^(\d{3})(\d{3})(\d{4})$/g, '+1 $1-$2-$3')
}

const licenceHeaders: HeaderType[] = [
  {
    id: 'companyName',
    label: 'Company',
    custom: true,
    hide: false,
    align: 'left' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Box>
          <Typography fontWeight={600}>{row.companyName}</Typography>
        </Box>
      )
    },
  },
  {
    id: 'pro',
    label: 'Pro',
    hide: false,
    custom: true,
    align: 'left' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Box>
          <Typography fontWeight={600}>{row.vendorName}</Typography>
        </Box>
      )
    },
  },
  {
    id: 'phone',
    label: 'Phone',
    hide: false,
    custom: true,
    align: 'left' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Box>
          <Typography>{applyRegExp(row.phone)}</Typography>
        </Box>
      )
    },
  },
  { id: 'email', label: 'Email' },
  {
    id: 'territoryList',
    label: 'Territories',
    hide: false,
    custom: true,
    maxWidth: 140,
    Element: (row: any): JSX.Element => {
      const territories = row?.territoryList
        ?.filter((territory: any) => territory?.title)
        ?.reduce(
          (acc: any, curr: any) =>
            acc.concat(`${curr.title}: ${curr.capacity || '0'}`),
          []
        )
      return (
        <Box display="flex" flexDirection="column" gap={1}>
          <ShowMore
            limit={3}
            buttonSx={{ width: '100%' }}
            tooltipContent={
              <Box gap={1}>
                {territories?.map((territory: any) => (
                  <Typography key={territory}>{territory}</Typography>
                ))}
              </Box>
            }
          >
            {territories?.map((territory: any) => (
              <Tag width="100%" center>
                {territory}
              </Tag>
            ))}
          </ShowMore>
        </Box>
      )
    },
  },
  {
    id: 'validFromDate',
    label: 'Valid From Date',
    hide: false,
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Box>
          <Typography>
            {formatTimestamp(row?.insurance?.validFromDate, 'MM/DD/YYYY')}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'insuranceType',
    label: 'Insurance Type',
    hide: false,
    custom: true,
    maxWidth: 120,
    Element: (row: any): JSX.Element => {
      return (
        <Box display="flex" flexDirection="column" gap={1} padding="15px 20px">
          <Tag
            width="100%"
            center
            sx={{ lineHeight: '18px', minWidth: '80px !important' }}
            fontWeight={600}
          >
            {
              licenseCheckFilters?.find(
                (filter) => filter.value === row?.insurance?.insuranceType
              )?.label
            }
          </Tag>
        </Box>
      )
    },
  },
  {
    id: 'validToDate',
    label: 'Valid To Date',
    hide: false,
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Grid>
          <Typography>
            {formatTimestamp(row?.insurance?.validToDate, 'MM/DD/YYYY')}
          </Typography>
        </Grid>
      )
    },
  },
  {
    id: 'validToDate',
    label: 'Amount of Coverage',
    hide: false,
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Grid>
          <Typography>
            {`$${round(row?.insurance?.amountOfCoverage, 2)}`}
          </Typography>
        </Grid>
      )
    },
  },
  {
    id: 'additionalInsured',
    label: 'Additional Insured',
    hide: false,
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Grid>
          <Typography>
            {row?.insurance?.additionalInsured ? <Icon name='CheckCircle' color="var(--success-color)" /> : <Icon color="var(--error-color)" name='Cancel' />}
          </Typography>
        </Grid>
      )
    },
  },
  {
    id: 'reviewStatus',
    label: 'Review Status',
    hide: false,
    custom: true,
    maxWidth: 120,
    Element: (row: any): JSX.Element => {
      const { status, ...customStyle } =
        reviewStatusColors.find((item) => item.status === row?.insurance?.reviewStatus) ||
        {}

      return (
        <Box display="flex" flexDirection="column" gap={1} padding="15px 20px">
          <Tag
            width="100%"
            center
            {...customStyle}
            hasShadow={true}
            sx={{ lineHeight: '18px', minWidth: '80px !important' }}
            fontWeight={600}
          >
            {row?.insurance?.reviewStatus}
          </Tag>
        </Box>
      )
    },
  },
]

export default licenceHeaders
