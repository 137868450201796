import { workOdersTypes, WorkOrdersContextType } from './types'

export const WorkOrdersReducer = (state: WorkOrdersContextType, action: any) => {
  const { type, payload } = action

  switch (type) {
    case workOdersTypes.SET_MODAL_TYPE: {
      return {
        ...state,
        modalType: payload
      }
    }
    case workOdersTypes.SET_MODAL_OPEN: {
      return {
        ...state,
        modalOpen: payload
      }
    }
    case workOdersTypes.REFRESH: {
      return {
        ...state,
        refresh: payload
      }
    }
    case workOdersTypes.SET_REVIEW_ITEMS: {
      const newCopy = payload
      return {
        ...state,
        workItems: payload,
        workItemsFilter: newCopy
      }
    }
    case workOdersTypes.TOGGLE_CHECK_ALL: {
      return {
        ...state,
        checkAll: !state.checkAll
      }
    }
    case workOdersTypes.TOGGLE_EXPAND_ALL: {
      return {
        ...state,
        expandAll: !state.expandAll
      }
    }
    case workOdersTypes.SET_SELECTED_ITEM_ID: {
      return {
        ...state,
        selectedItemId: payload
      }
    }
    case workOdersTypes.SET_SELECTED_ITEMS_IDS: {
      return {
        ...state,
        selectedItemsIds: payload
      }
    }
    case workOdersTypes.SET_SELECTED_WORK_ITEM: {
      return {
        ...state,
        selectedWorkItem: payload
      }
    }
    case workOdersTypes.RESET_ITEM: {
      return {
        ...state,
        newItem: {}
      }
    }
    case workOdersTypes.SET_SEARCH: {
      return {
        ...state,
        searchFilter: payload
      }
    }
    case workOdersTypes.SET_ORDER: {
      return {
        ...state,
        order: payload
      }
    }
    case workOdersTypes.SET_TAB_SELECTED: {
      return {
        ...state,
        tabSelected: payload
      }
    }
    default:
      return state
  }
}
