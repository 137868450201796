import { Territory } from '../types'

/** TYPES **/
export const territoriesTypes = {
  FETCH_TERRITORY_MANAGERS: 'FETCH_TERRITORY_MANAGERS',
  FETCH_TERRITORIES: 'FETCH_TERRITORIES',
  SET_TERRITORIES: 'SET_TERRITORIES',
  CREATE_TERRITORY: 'CREATE_TERRITORY',
}

/** ACTIONS **/

export interface FetchTerritoriesAction {
  type: typeof territoriesTypes.FETCH_TERRITORIES
  payload: null
  callback: (succ: boolean) => void
}

export interface CreateTerritoryAction {
  type: typeof territoriesTypes.CREATE_TERRITORY
  payload: Partial<Territory>
  callback: (succ: boolean) => void
}

export interface SetTerritories {
  type: typeof territoriesTypes.SET_TERRITORIES
  payload: TerritoryType[]
}

export type TerritoriesActions =
  | FetchTerritoriesAction
  | SetTerritories
  | CreateTerritoryAction

/** REDUCER **/

export interface TerritoryType {
  start: number
  limit: number
  total: number
  items: Territory[]
}

export interface TerritoriesType {
  territories: TerritoryType | null
}