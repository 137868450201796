/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid } from '@mui/material'
import { useSelector } from 'react-redux'
import { FC, useContext, useEffect, useState } from 'react'
import { SelectInput, InputSearch } from 'src/components/UI'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import { getItemsByCategory } from 'src/ducks/selectors'
import BatchActions from '../BatchActions'
import { WorkOrdersContext } from './../context'
import { Item } from 'src/ducks/types'

const Header: FC<{ hasItems?: boolean, fullHeader?: boolean }> = ({ hasItems = false, fullHeader = false }) => {
  const {
    dispatch: dispatchContext,
    state: { workItems, checkAll, expandAll, order, searchFilter }
  } = useContext(WorkOrdersContext)
  const itemsByCategory = useSelector(getItemsByCategory(null))

  const orderOptions = [{ key: 'recent', label: 'Most recent to last updated' },
  { key: 'updated', label: 'Last updated to most recent' }]
  const [selectedItems, setSelectedItems] = useState<Item[]>([])

  useEffect(() => {
    setSelectedItems(workItems.reduce((acc: any, curr: any) => {
      return (acc = [
        ...acc,
        ...curr.items.filter((item: { checked: any }) => item.checked)
      ])
    }, []))
  }, [workItems])

  const handleSelectAll = () => {
    dispatchContext({ type: 'TOGGLE_CHECK_ALL', payload: !checkAll })
    dispatchContext({
      type: 'SET_REVIEW_ITEMS',
      payload: workItems.map((item: any) => {
        item.items.forEach((subitem: any) => {
          subitem.checked = !checkAll
        })
        return item
      })
    })
  }

  const handleSearch = (searchFilter: string) => {
    if (searchFilter !== '') {
      itemsByCategory.map((item: any) => {
        const newItems = item.items.filter((subitem: any) => {
          return subitem.title?.toLowerCase().includes(searchFilter?.toLowerCase())
        })
        item.items = newItems
        return item
      })
    }
    dispatchContext({ type: 'SET_SEARCH', payload: searchFilter })
    dispatchContext({ type: 'SET_REVIEW_ITEMS', payload: itemsByCategory })
  }

  const handleOrder = (newSelected: any) => {
    dispatchContext({ type: 'SET_ORDER', payload: newSelected })
    dispatchContext({
      type: 'SET_REVIEW_ITEMS',
      payload: workItems.map((item: any) => {
        if (newSelected === orderOptions[0]) {
          item.items = item.items.sort((a: { completedOn: number }, b: { completedOn: number }) => a.completedOn > b.completedOn ? 1 : -1)
        } else {
          item.items = item.items.sort((a: { completedOn: number }, b: { completedOn: number }) => a.completedOn < b.completedOn ? 1 : -1)
        }
        return item
      })
    })
  }

  return (
    <Grid container spacing={1} paddingX={1}>
      <Grid item container spacing={1} lg={12} display='flex' flexDirection='row' justifyContent='space-between'>
        <Grid item container spacing={1} xs={8} lg={10}>
          {hasItems &&
            <>
              <Grid item>
                <Button
                  onClick={() => dispatchContext({ type: 'TOGGLE_EXPAND_ALL', payload: !expandAll })}
                  variant='outlined'
                  sx={{ padding: 0, width: 41, height: 41 }}
                >
                  <Icon name={expandAll ? 'Remove' : 'Add'} width='1rem' height='1rem' />
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={handleSelectAll}
                  variant='outlined'
                  sx={{ padding: 0, width: 41, height: 41 }}
                >
                  <Icon name={checkAll ? 'CheckBox' : 'CheckBoxOutlined'} />
                </Button>
              </Grid>
            </>}
          <Grid item>
            {selectedItems && selectedItems.length > 0 && <BatchActions items={selectedItems} />}
          </Grid>
          <Grid item lg={4}>
            <InputSearch
              key='InputSearchReviewItem'
              placeholder='Search item...'
              onChange={(event) => handleSearch(event)}
              value={searchFilter}
              sx={{ width: '100%' }}
              autoFocus
            />
          </Grid>
        </Grid>
        <Grid item xs={4} lg={2} justifySelf='end'>
          <SelectInput
            key='SelectInputReviewItem'
            value={order}
            onChange={handleOrder}
            options={orderOptions}
            size='small'
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Header
