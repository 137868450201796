import React, { useReducer } from 'react'
import { invoiceMaterialModalReducer } from './reducer'
import { invoiceMaterialModalContextType } from './types'

const initialState: invoiceMaterialModalContextType = {
  invoiceNum: "",
  amount: 0,
  orderName: "",
  insertedOn: 0
}

export const InvoiceMaterialContext = React.createContext<{
  state: invoiceMaterialModalContextType;
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null
})

export const InvoiceMaterialProvider: React.FunctionComponent = ({ children }: any) => {
  const [state, dispatch] = useReducer(invoiceMaterialModalReducer, initialState)

  return (
    <InvoiceMaterialContext.Provider value={{ state, dispatch }}>
      {children}
    </InvoiceMaterialContext.Provider>
  )
}
