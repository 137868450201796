import { formatParams } from 'src/helpers'
import axios from '../axios'
import { ApiController } from './types'

const base = '/vendors'

const apiController: ApiController = {
  getVendors: async ({ start, limit, searchParams }) => {
    const params = formatParams(searchParams, { allowFalsies: true })
    return await axios
      .get(`${base}?${params}`, { params: { start, limit } })
      .then(response => response.data)
  },

  requestCSV: async ({ start, limit, searchParams }) => {
    const params = formatParams(searchParams)
    return await axios
      .post(`${base}/csv?${params}`, { params: { start, limit } })
      .then(response => response.data)
  },

  getCSV: async (csvId) => {
    return await axios
      .get(`${base}/csv/${csvId}`)
      .then(response => {
        return response
      })
  },

  createQbId: async (vendorId) => {
    return await axios
      .post(`${base}/${vendorId}/quickBooks`)
      .then(response => response.data)
  },

  getRecommendedVendors: async ({ searchParams }) => {
    const params = formatParams(searchParams)
    return await axios
      .get(`${base}/recommended?${params}`)
      .then(response => response.data)
  },
  getVendorById: async (vendorId: string) =>
    await axios
      .get(`${base}/${vendorId}`)
      .then(response => response.data),

  getVendorStatics: async (vendorId: string) =>
    await axios
      .get(`${base}/${vendorId}/statistics`)
      .then(response => response.data),

  getVendorJobs: async (vendorId: string) =>
    await axios
      .get(`${base}/${vendorId}/jobs`)
      .then(response => response.data),

  updateVendor: async (vendorId: string, request) => {
    return await axios
      .patch(`${base}/${vendorId}`, request)
      .then(response => response.data)
  },

  getCounts: async ({ searchParams }) => {
    const params = formatParams(searchParams, { allowFalsies: true })
    return await axios
      .get(`${base}/counts?${params}`)
      .then(response => response.data)
  },
  getPunchlistVendors: async ({ start, limit, searchParams }) => {
    const params = formatParams(searchParams)
    return await axios
      .get(`${base}/punchlist?${params}`, { params: { start, limit } })
      .then(response => response.data)
  },
}

export default apiController
