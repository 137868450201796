import { FC } from 'react'
import {
  Typography,
  Popper,
  PopperProps,
  TextField
} from '../../..'
import { icons } from 'src/assets'
import { useClassName } from 'src/hooks'
import useStyles from './styles'
import { AutocompleteProps } from './types'
import { Autocomplete as AutocompleteMui } from '@mui/material'

const Autocomplete: FC<AutocompleteProps> = ({
  className,
  label,
  options,
  value,
  placeholder = '',
  onChange,
  error = false,
  classes: classesProp,
  titleClassName = '',
  multiple = false,
  disabled,
  labelClassName = '',
  inputProps,
  popperWidth = '300px',
  popperStyles
}) => {
  const classes = useStyles()
  const classNames = useClassName()

  const PopperMy = (props: JSX.IntrinsicAttributes & PopperProps) => {
    return (
      <Popper
        {...props}
        style={{ width: popperWidth, ...popperStyles, }}
        className={classes.popper}
        placement='bottom-start'
      />
    )
  }

  return (
    <>
      {label && <Typography variant="body1" className={classNames(classes.title, titleClassName, labelClassName)}>
        {label}
      </Typography>}
      <AutocompleteMui
        options={options}
        getOptionLabel={option => option.value}
        renderInput={params => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              ...inputProps,
              disableUnderline: true,
            }}
            variant='standard'
            label=''
            // error={error}
            placeholder={placeholder}
            className={classNames(
              classes.textfield,
              className || '',
              error ? classes.inputError : ''
            )}

          />
        )}
        classes={{
          root: classes.root,
          endAdornment: classes.endAdornment,
          // clearIndicatorDirty: classes.clearIndicator,
          popupIndicator: classes.popupIndicator,
          popupIndicatorOpen: classes.popupIndicator,
          listbox: classes.listBox,
          option: classes.option,
          ...classesProp
        }}
        // getOptionSelected={(option: any, value: any) => {
        //   if (value.key === '') return true;
        //   return option.key === value.key
        // }}
        value={value}
        onChange={(_event, value) => {
          onChange(value)
        }}
        PopperComponent={PopperMy}
        popupIcon={<icons.KeyboardArrowDown />}
        multiple={multiple}
        disabled={disabled}
      />
    </>
  )
}

export default Autocomplete
