import { FC, Suspense, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { isAuthorized, isApproved, isRequiredData, getEmployee } from '../ducks/selectors'
import { PrivatePage, PunchlistLoader } from '../components/templates'
import { authActions, configActions } from '../ducks/actions'
import { PrivateRoutesProps } from './types'

export type ContextType = { hasPadding: boolean | null, setHasPadding: (hasPadding: boolean | null) => void };


const PrivateRoute: FC<PrivateRoutesProps> = ({ allowedRoles }) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const [hasPadding, setHasPadding] = useState<boolean>(true)

  const authorized = useSelector(isAuthorized)
  const pathname = location.pathname
  const approved = useSelector(isApproved)
  const isUnauthorized = pathname === '/unauthorized'
  const requiredData = useSelector(isRequiredData)
  const currentUser = useSelector(getEmployee)
  const userRoleIsAllowed = allowedRoles && currentUser?.roles?.some((r) => allowedRoles.includes(r.roleType))

  useEffect(() => {
    return () => {
      setHasPadding(true)
    }
  }, [dispatch, location.pathname])

  if (!authorized) {
    dispatch(authActions.logoutRequest())
    location?.pathname && dispatch(configActions.setConfigValue({ type: 'redirectPath', value: location?.pathname }))
    return <Navigate to='/login' state={{ from: location }} replace />
  }

  if ((authorized && !approved && !isUnauthorized)) {
    return <Navigate to='/unauthorized' state={{ from: location }} replace />
  }

  if (authorized && approved && !isUnauthorized && !requiredData && pathname !== "/account") {
    return <Navigate to='/account' state={{ from: location }} replace />
  }

  if (allowedRoles && !userRoleIsAllowed) {
    return <Navigate to='/home' state={{ from: location }} replace />
  }

  return (
    <Suspense fallback={<PunchlistLoader />}>
      <PrivatePage hasPadding={hasPadding}>
        <Outlet context={{ hasPadding, setHasPadding }} />
      </PrivatePage>
    </Suspense>
  )
}

export default PrivateRoute
