import { authTypes } from '../auth/types'
import {
  subscriptionsTypes,
  SubscriptionsType,
  SubscriptionsActions,
} from './types'

const initialState: SubscriptionsType = {
  start: 0,
  limit: 0,
  total: 0,
  items: [],
  csvItems: [],
}

const reducer = (
  state = initialState,
  action: SubscriptionsActions
): SubscriptionsType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: {
      return initialState
    }
    case authTypes.LOGOUT_REQUEST: {
      return initialState
    }

    case subscriptionsTypes.SET_SUBSCRIPTIONS: {
      return {
        ...state,
        start: (payload as SubscriptionsType).start,
        limit: (payload as SubscriptionsType).limit,
        total: (payload as SubscriptionsType).total,
        items: (payload as SubscriptionsType).items,
      }
    }

    case subscriptionsTypes.SET_CSV_SUBSCRIPTIONS: {
      return {
        ...state,
        csvItems: (payload as SubscriptionsType).items
      }
    }

    default:
      return state
  }
}

export default reducer
