/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo } from '../../helpers'
import { Punchlist } from '../../api'
import { historyActions } from '../actions'
import { FetchEstimateHistoryAction, FetchHistoryJobAction, FetchHistoryJobTasksAction, FetchJobHistoryStatusAction, historyTypes } from './types'

export function* fetchHistoryJobTask({ payload, callback }: FetchHistoryJobTasksAction): SagaIterator {
  let success = false
  try {
    const jobHistoryTask = yield call(Punchlist.history.getHistoryJobTask, payload)
    if (jobHistoryTask) {
      yield put(historyActions.setHistoryValue({ attr: 'historyJobTasks', value: jobHistoryTask }))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the job history task'))
    if (callback) yield call(callback, false)
  }
}

export function* fetchJobHistory({ payload, callback }: FetchHistoryJobAction): SagaIterator {
  let success = false
  try {
    const jobHistory = yield call(Punchlist.history.getHistoryJob, payload)
    if (jobHistory) {
      yield put(historyActions.setHistoryValue({ attr: 'jobHistory', value: jobHistory }))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the job history'))
    if (callback) yield call(callback, false)
  }
}
export function* fetchJobHistoryStatus({ payload, callback }: FetchJobHistoryStatusAction): SagaIterator {
  let success = false
  try {
    const jobHistoryStatus = yield call(Punchlist.history.getHistoryJobStatus, payload)
    if (jobHistoryStatus) {
      yield put(historyActions.setHistoryValue({ attr: 'jobHistoryStatus', value: jobHistoryStatus }))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the job history status'))
    if (callback) yield call(callback, false)
  }
}

export function* fetchOrderHistoryByJob({ payload, callback }: FetchJobHistoryStatusAction): SagaIterator {
  let success = false
  try {
    const orderHistory = yield call(Punchlist.history.getHistoryJobOrders, payload)
    if (orderHistory) {
      yield put(historyActions.setHistoryValue({ attr: 'orderHistory', value: orderHistory }))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the order history'))
    if (callback) yield call(callback, false)
  }
}

export function* fetchHistoryRemovedItems({ payload, callback }: FetchJobHistoryStatusAction): SagaIterator {
  let success = false
  try {
    const removedItems = yield call(Punchlist.history.getHistoryRemovedItems, payload)
    if (removedItems) {
      yield put(historyActions.setHistoryValue({ attr: 'historyRemovedItems', value: removedItems }))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the removed items'))
    if (callback) yield call(callback, false)
  }
}

export function* fetchHistoryEstimates({ payload, callback }: FetchEstimateHistoryAction): SagaIterator {
  let success = false
  const { estimateId, event, itemTitle } = payload
  try {
    const history = yield call(Punchlist.history.getHistoryEstimates, estimateId, itemTitle, event)
    if (history) {
      yield put(historyActions.setHistoryValue({ attr: 'jobHistory', value: history }))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the estimates'))
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(historyTypes.FETCH_HISTORY_JOB_TASKS, fetchHistoryJobTask)
  yield takeLatest(historyTypes.FETCH_HISTORY_JOB, fetchJobHistory)
  yield takeLatest(historyTypes.FETCH_HISTORY_STATUS, fetchJobHistoryStatus)
  yield takeLatest(historyTypes.FETCH_JOB_ORDERS_HISTORY, fetchOrderHistoryByJob)
  yield takeLatest(historyTypes.FETCH_REMOVED_ITEMS_HISTORY, fetchHistoryRemovedItems)
  yield takeLatest(historyTypes.FETCH_HISTORY_ESTIMATES, fetchHistoryEstimates)
}
