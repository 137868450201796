import { Box, Grid, Typography, Checkbox } from '@mui/material'
import { FC } from 'react'
import { AttachmentsProps } from './types'
import { LabeledText } from 'src/components/UI'
import { Divider } from '../../Templates'
import { useSelector } from 'react-redux'
import { getWorkOrderContractDetails } from 'src/ducks/selectors'
import { formatTimestamp } from 'src/helpers'

const Details: FC<AttachmentsProps> = () => {
  const contractDetails = useSelector(getWorkOrderContractDetails)

  return (
    <Grid
      container
      direction="column"
      spacing={1}
      height={'100%'}
      style={{ flexWrap: 'nowrap', overflow: 'overlay', marginTop: 0 }}
      bgcolor={"red"}
    >
      <Grid container item>
        <Grid item xs={6}>
          <LabeledText
            labelValue="Pay at Close"
            textValue={`${contractDetails?.payAtClose
              ? 'YES ✅'
              : contractDetails?.payAtClose === false
                ? 'NO ❌'
                : '-'
              }`}
          />
        </Grid>
        <Grid item xs={6}>
          <LabeledText
            labelValue="Date Signed:"
            textValue={
              contractDetails?.dateSigned
                ? formatTimestamp(contractDetails?.dateSigned, 'MM/DD/YYYY')
                : '-'
            }
          />
        </Grid>
      </Grid>
      <Grid item>
        <Divider />
      </Grid>
      <Box display="flex">
        <Grid container item style={{ gap: '4px' }} direction="column">
          <LabeledText labelValue="Customer Information" />
          <LabeledText
            labelValue="Customer:"
            textValue={contractDetails?.customerFullName || '-'}
          />
          <Box display="flex" gap="16px">
            <LabeledText
              labelValue="Phone:"
              textValue={contractDetails?.customerPhone || '-'}
            />
            <LabeledText
              labelValue="Email:"
              textValue={contractDetails?.customerEmail || '-'}
            />
          </Box>
        </Grid>
        <Box>
          <LabeledText labelValue="Communication Preference" />
          <Box display="flex" alignItems="center" sx={{ marginLeft: "-12px" }}>
            <Box display="flex" alignItems="center">
              <Checkbox checked={Boolean(contractDetails?.phoneCommunication)} contentEditable={false} />
              <Typography>Phone</Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Checkbox checked={Boolean(contractDetails?.smsCommunication)} contentEditable={false} />
              <Typography>SMS</Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Checkbox checked={Boolean(contractDetails?.emailCommunication)} contentEditable={false} />
              <Typography>Email</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Grid item>
        <Divider />
      </Grid>
      <Grid container item style={{ gap: '4px' }} direction="column">
        <LabeledText labelValue="Agent Details" />
        <Box display="flex" gap="16px">
          <LabeledText
            labelValue="Agent:"
            textValue={contractDetails?.agentFullName || '-'}
          />
          <LabeledText
            labelValue="Brokerage:"
            textValue={contractDetails?.agentBrokerage || '-'}
          />
        </Box>
        <Box display="flex" gap="16px">
          <LabeledText
            labelValue="Phone:"
            textValue={contractDetails?.agentPhone || '-'}
          />
          <LabeledText
            labelValue="Email:"
            textValue={contractDetails?.agentEmail || '-'}
          />
        </Box>
      </Grid>
    </Grid>
  )
}

export default Details
