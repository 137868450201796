/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'

import { Punchlist } from '../../api'
import { errorTextTryingTo, isEmpty } from '../../helpers'
import { orderActions } from '../actions'
import {
  orderTypes, GetOrderAction, GetOrderBidsAction,
  UpdateOrderAction, Order, AddDrawForPayNowAction, GetPricingAction, FetchOrderHistoryAction
} from './types'
import { UpdateRequest } from '~api/types'

export function* getOrder({ payload, callback }: GetOrderAction): SagaIterator {
  let success = false
  try {
    const { order, files } = yield all({
      order: call(Punchlist.orders.getOrderById, payload),
      files: call(Punchlist.orders.getOrderAttachments, payload),
    });
    if (order) {
      order.files = files
    }
    yield put(orderActions.setOrder(order))
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield put(orderActions.setOrder(null))
    yield call(toast.error, errorTextTryingTo('get the order'))
    if (callback) yield call(callback, false)
  }
}

export function* updateOrder({ payload, callback }: UpdateOrderAction): SagaIterator {
  try {
    const { orderId, order, jobFiles } = payload
    const request: UpdateRequest = []
    for (const attr in order) {
      if (attr !== 'orderId') {
        request.push({
          op: 'add',
          path: '/' + attr,
          value: order[attr as keyof Order]
        })
      }
    }
    const newOrder = yield call(Punchlist.orders.updateOrder, orderId, request)
    if (newOrder) {
      yield call(Punchlist.orders.setOrderAttachments, orderId, jobFiles)
      yield put(orderActions.setOrder(newOrder))
      yield call(callback, true)
    }
    yield call(callback, false)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('update the order'))
    yield call(callback, false)
  }
}

export function* deleteOrder({ payload, callback }: any): SagaIterator {
  try {
    const { orderId } = payload
    yield call(Punchlist.orders.deleteOrder, orderId)
    if (callback) yield call(callback, true)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('revoke the order'))
    if (callback) yield call(callback, false)
  }
}

export function* dispatchOrder({ payload, callback }: any): SagaIterator {
  try {
    const { orderId, order, jobFiles, sendNotification } = payload

    const newOrder = yield call(Punchlist.orders.dispatchOrder, orderId, sendNotification, order)
    if (newOrder) {
      if (jobFiles) yield call(Punchlist.orders.setOrderAttachments, orderId, jobFiles)
      yield put(orderActions.setOrder(newOrder))
      if (callback) yield call(callback, true)

    } else if (callback) yield call(callback, false)
  } catch ({ response }) {
    const { message } = response?.data || {}

    if (message) {
      yield call(toast.error, message)
    } else {
      yield call(toast.error, errorTextTryingTo('dispatch the order'))
    }

    if (callback) yield call(callback, false)
  }
}

export function* revokeDispatchOrder({ payload, callback }: any): SagaIterator {
  try {
    const { orderId, sendNotification } = payload

    const newOrder = yield call(Punchlist.orders.revokeDispatchOrder, orderId, sendNotification)
    if (newOrder) {
      yield put(orderActions.setOrder(newOrder))
      if (callback) yield call(callback, true)

    } else if (callback) yield call(callback, false)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('revoke the order'))
    if (callback) yield call(callback, false)
  }
}

export function* addOrderDrawForPayNow({ payload, callback }: AddDrawForPayNowAction): SagaIterator {
  try {
    const { orderId, item } = payload
    const requestAdd: UpdateRequest = []

    requestAdd.push({
      op: 'add',
      path: '/pricingModel/items/-',
      value: item
    })

    const newOrder = yield call(Punchlist.orders.updateOrder, orderId, requestAdd)
    if (newOrder) {
      // order is not updated until all operations are completed
      // yield put(orderActions.setOrder(newOrder))
      if (callback) yield call(callback, newOrder.pricingModel.items[newOrder.pricingModel.items.length - 1])

    } else if (callback) yield call(callback, null)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('update the order'))
    if (callback) yield call(callback, null)
  }
}

export function* getOrderBids({ payload, callback }: GetOrderBidsAction): SagaIterator {
  try {
    const data = yield call(Punchlist.orders.getOrderBids, payload)
    if (callback) yield call(callback, !isEmpty(data) ? data : [])
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get order bids'))
    if (callback) yield call(callback, null)
  }
}

export function* getPricing({ payload, callback }: GetPricingAction): SagaIterator {
  try {
    const { orderId, pricingModel } = payload
    const newOrder = yield call(Punchlist.orders.getPricing, orderId, pricingModel)
    if (callback) yield call(callback, true)
    if (newOrder) {
      yield put(orderActions.setOrder(newOrder))
    }
  } catch (error) {
    if (callback) yield call(callback, false)
    yield call(toast.error, errorTextTryingTo('get the pricing'))
  }
}

export function* fetchOrderHistory({ payload, callback }: FetchOrderHistoryAction): SagaIterator {
  let success = false
  try {
    const orderHistory = yield call(Punchlist.history.getHistoryOrder, payload)

    if (orderHistory) {
      yield put(orderActions.setOrderValue({ attr: 'orderHistory', value: orderHistory }))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the order history'))
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(orderTypes.GET_ORDER, getOrder)
  yield takeLatest(orderTypes.UPDATE_ORDER, updateOrder)
  yield takeLatest(orderTypes.DELETE_ORDER, deleteOrder)
  yield takeLatest(orderTypes.DISPATCH_ORDER, dispatchOrder)
  yield takeLatest(orderTypes.REVOKE_DISPATCH_ORDER, revokeDispatchOrder)
  yield takeLatest(orderTypes.GET_ORDER_BIDS, getOrderBids)
  yield takeLatest(orderTypes.GET_PRICING, getPricing)
  yield takeLatest(orderTypes.FETCH_ORDER_HISTORY, fetchOrderHistory)
  yield takeLatest(orderTypes.ADD_DRAW_FOR_PAYNOW, addOrderDrawForPayNow)
}
