import { Grid, Typography } from '@mui/material'
import { FC } from 'react'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import Tag from 'src/components/UI/CustomUI/atoms/Tag'
import theme from 'src/components/UI/MaterialUI/theme'
import { formatTimestamp, getColorBySeverity } from 'src/helpers'
import { RemovedItemDetailProps } from './types'
import styles from './styles.module.scss'

const RemovedItemDetail: FC<RemovedItemDetailProps> = ({
  item,
  itemSelected,
  setItemSelected,
}) => {
  const { background } = item?.data?.severity
    ? getColorBySeverity(item?.data?.severity)
    : { background: null }
    
  return (
    <Grid
      container
      flexDirection="column"
      gap={0.5}
      className={`${styles.RemovedItemDetail} ${
        itemSelected?.text === item.text
          ? styles.RemovedItemDetail__selected
          : ''
      }`}
      onClick={() => setItemSelected(item)}
    >
      <Grid item container>
        <Typography variant="h5Bold" marginRight={1}>
          {item.data?.title}
        </Typography>
        {background && (
          <Tag
            sx={{ paddingX: 2, textTransform: 'uppercase', height: '25px' }}
            variant="caption"
            width="fit-content"
            background={background}
            color="var(--black-color)"
          >
            {item?.data?.severity}
          </Tag>
        )}
      </Grid>
      <Grid item container alignItems="center">
        <Icon
          color={theme.palette.grey[700]}
          name="CalendarToday"
          sx={{ marginRight: '.5rem' }}
        />
        <Typography variant="h6Bold" color={theme.palette.grey[700]}>
          Deleted Date:
        </Typography>
        <Typography color={theme.palette.grey[700]} marginLeft=".3rem">
          {formatTimestamp(item.createdOn, 'MM/DD/YYYY')}
        </Typography>
      </Grid>
      <Grid item container alignItems="center">
        <Icon
          color={theme.palette.grey[700]}
          name="Person"
          sx={{ marginRight: '.5rem' }}
        />
        <Typography variant="h6Bold" color={theme.palette.grey[700]}>
          Deleted By:
        </Typography>
        <Typography color={theme.palette.grey[700]} marginLeft=".3rem">
          {item.createdBy}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default RemovedItemDetail
