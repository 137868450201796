import storeInfo from '../store'
import { RepairListItem } from '../types'

export const getRepairList = (): RepairListItem[] => {
  const { repairList } = storeInfo.store.getState()
  return repairList.items
}

export const getCsvRepairList = (): RepairListItem[] => {
  const { repairList } = storeInfo.store.getState()
  return repairList.csvItems
}

export const getRepairListTotal = (): number => {
  const { repairList } = storeInfo.store.getState()
  return repairList.total
}
