import { makeStyles } from '@mui/styles'

export default makeStyles(() => ({
  arrow: {
    backgroundColor: 'rgba(26, 42, 85, 0.5) !important',
    // opacity: '50%',
    borderRadius: '100%',
    padding: '.2rem',
    '@global': {
      '.MuiSvgIcon-root': {
        // paddingLeft: '.3rem',
      }
    }
  },
  sliderSelector: {
    borderRadius: 8,
    width: '100%',
    // maxWidth: "240px",
    position: 'relative',
    height: '100% !important'
  },
  slider: {
    borderRadius: '8px',
    '@global': {
      '.swiper-slide': {
        // height: 'auto',
        height: '135px !important',
        '@global': {
          'img': {
            height: '100%',
            objectFit: 'cover',
          }
        }
      }
    }
  },
  radioButton: {
    position: 'absolute',
    bottom: '.5rem',
    right: '.5rem',
    backgroundColor: 'white',
    borderRadius: 20,
    '@global': {
      '.MuiButtonBase-root': {
        paddingTop: '0.2rem !important',
        paddingBottom: '0.2rem !important',
        paddingRight: '0.2rem !important'
      },
      '.MuiTypography-root': {
        paddingRight: '.5rem'
      }
    }
  },
  formContainer: {
    display: 'block !important',
    '@global': {
      '.MuiFormGroup-root': {
        display: 'block !important'
      }
    }
  },
  selectNone: {
    '@global': {
      '.MuiButtonBase-root': {
        padding: '0 0.3rem .4rem !important '
      }
    }
  }
}))
