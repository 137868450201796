/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react'
import { Typography, Box } from 'src/components/UI'
import TabNavigation from 'src/components/UI/CustomUI/organisms/TabNavigation'
import OrderItems from './OrderItems'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useSearchParams } from 'react-router-dom'
import { getItems, getItemsByCategory, getItemsSortedByCategory, getJob, getOrders } from 'src/ducks/selectors'
import { ordersActions } from 'src/ducks/actions'
import styles from './styles.module.scss'
import { PrivateLoader } from 'src/components/templates'
import OrderItemsModal from './OrderItems/Modals'
import { WorkOrdersContext } from './context'
import { isEmpty, STATUS, renderWorkOrdersTabs } from 'src/helpers'

const WorkOrder = ({ children }: { children: JSX.Element }) => {
  return (
    <Box sx={{
      border: '2px solid var(--border-color)',
      borderTop: 0,
      borderRadius: '0 0 0.75rem 0.75rem',
      paddingTop: '1rem',
      height: '100%',
      minHeight: '30vh'
    }}
    >
      {children}
    </Box>
  )
}

const WorkOrders = () => {
  const dispatch = useDispatch()
  const { dispatch: dispatchContext, state: { pageLoading, selectedTab } } = useContext(WorkOrdersContext)
  const itemsByCategory = useSelector(getItemsSortedByCategory())
  const orders = useSelector(getOrders())
  const { id } = useParams()
  const [tabs, setTabs] = useState<any>()
  const [searchParams, setSearchParams] = useSearchParams()
  const [defaultActive, setDefaultActive] = useState(0)
  const job = useSelector(getJob())

  const TABS: any = [
    {
      label: 'All Orders',
      content: <WorkOrder><OrderItems tabName='All Orders' /></WorkOrder>
    },
  ]

  const fetchServices = () => {
    dispatchContext({ type: 'SET_PAGE_LOADING', payload: true })
    dispatch(ordersActions.fetchOrders({
      params: { jobId: id }
    }, (succ) => {
      if (succ) {
        dispatchContext({ type: 'SET_PAGE_LOADING', payload: false })
      }
    }))
  }

  useEffect(() => {
    const tab = searchParams?.get('tab') ?? '0'
    fetchServices()
    dispatchContext({ type: 'SET_SELECTED_TAB', payload: tab })
    setTabs(TABS)
  }, [id])


  useEffect(() => {
    if (orders && orders?.length > 0) {
      const newTabs = [
        ...TABS,
        ...orders.map(order => {

          const { title, color, icon, fullHeader } = renderWorkOrdersTabs(order, itemsByCategory)
          return {
            label: order.orderName,
            id: order.orderId,
            element:
              (<Box display='flex'>
                <Typography color={color}> {title} </Typography>
                {icon && <Icon sx={{ marginLeft: '.2rem' }} width='.8rem' name={icon} color={color} />}
              </Box>),
            content: (
              <WorkOrder>
                <OrderItems orderId={order.orderId} fullHeader={fullHeader} />
              </WorkOrder>
            ),
            action: (newValue: any) => {
              dispatchContext({ type: 'SET_SELECTED_ORDER_ID', payload: newValue })
              dispatchContext({ type: 'REFRESH', payload: true })
            }
          }
        }),
      ]
      if (job?.status !== STATUS.COMPLETE && job?.status !== STATUS.CLOSED) {
        newTabs.push({
          label: '+ Add New',
          action: () => {
            dispatchContext({ type: 'SET_MODAL_OPEN', payload: true })
            dispatchContext({ type: 'SET_MODAL_TYPE', payload: 'ADD' })
          },
          preventOpen: true,
          className: styles.AddNew,
          content: <></>
        })
      }
      setTabs(newTabs)

      const orderId = searchParams?.get('order') ?? ''
      if (!isEmpty(orderId)) {
        setDefaultActive(orders.findIndex(order => order.orderId === orderId) + 1)
      }
    }
  }, [orders, selectedTab])

  return (
    <>
      <OrderItemsModal />
      <PrivateLoader loading={pageLoading}>
        <TabNavigation
          tabs={tabs}
          variant='outlined'
          margin={'0'}
          showExpand
          className={styles.Tabs}
          defaultActive={defaultActive}
          scrollable
          containerSx={{
            width: '96vw',
            marginTop: '3rem'
          }}
          getSelectedTab={(selected) => {
            dispatchContext({ type: 'SET_SELECTED_TAB', payload: selected })

            if (selected) {
              setSearchParams({
                ...searchParams,
                tab: "1",
                order: orders[selected - 1]?.orderId as string
              }, { replace: true })
            } else {
              setSearchParams({
                ...searchParams,
                tab: "1"
              }, { replace: true })
            }
          }}
        />
      </PrivateLoader>
    </>
  )
}

export default WorkOrders
