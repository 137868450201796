import { authTypes } from '../auth/types'
import { Item, ItemByCategory } from '../types'
import { ItemsActions, ItemsType, itemsTypes } from './types'

const initialState: ItemsType = {
  items: [],
  itemsByCategory: [],
  inspectionItems: []
}

const reducer = (state = initialState, action: ItemsActions): ItemsType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case itemsTypes.SET_ITEMS: {
      return {
        ...state,
        items: payload as Item[]
      }
    }
    case itemsTypes.SET_INSPECTION_ITEMS: {
      return {
        ...state,
        inspectionItems: payload as Item[]
      }
    }
    case itemsTypes.SET_ITEMS_BY_CATEGORY: {
      Object.assign(state?.itemsByCategory)

      return {
        ...state,
        itemsByCategory: payload as ItemByCategory[]
      }
    }

    default:
      return state
  }
}

export default reducer
