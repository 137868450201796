/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, delay, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo } from '../../helpers'
import { Punchlist } from '../../api'
import { jobsActions, reportsActions } from '../actions'
import { GetReportsPipelineAction, RequestReportsPipelineAction, reportsTypes } from './types'

export function* requestReportsPipeline({ payload, callback }: RequestReportsPipelineAction): SagaIterator {
  const { searchParams } = payload
  try {
    const csvId = yield call(Punchlist.reports.requestPipeline, searchParams)
    if (csvId) {
      yield put(reportsActions.getReportsPipeline(csvId, callback))
    } else {
      yield call(toast.error, errorTextTryingTo('download csv'))
      yield call(callback, false, '')
    }
  } catch {
    yield call(toast.error, errorTextTryingTo('download csv'))
    yield call(callback, false, '')
  }
}

export function* getReportsPipeline({ payload, callback }: GetReportsPipelineAction): SagaIterator {
  try {
    const csv = yield call(Punchlist.reports.getPipeline, payload)
    if (csv.status === 200) {
      yield call(callback, true, csv.data)
    } else {
      yield delay(1500);
      yield put(reportsActions.getReportsPipeline(payload, callback ))
    }
  } catch {
    yield call(toast.error, errorTextTryingTo('download csv file'))
    yield call(callback, false, '')
  }
}
export default function* saga(): SagaIterator {
  yield takeLatest(reportsTypes.REQUEST_PIPELINE, requestReportsPipeline)
  yield takeLatest(reportsTypes.GET_PIPELINE, getReportsPipeline)
}
