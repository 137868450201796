import { Box, Button, SvgIcon, Typography } from '@mui/material'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { greenPoint, icons, redPoint } from 'src/assets'
import {
  OverlayLoader,
  PrivateLoader,
  ScrollWrapper,
} from 'src/components/templates'
import { getCampaigns, getEstimate } from 'src/ducks/selectors'
import {
  ESTIMATE_PUBLIC_STATUS,
  ESTIMATE_VIEW,
  ITEM_CATEGORY,
  ITEM_CATEGORY_FOR_FILTERS,
  ITEM_PRIORITY,
  ITEM_STATUS,
  formatTimestamp,
  history,
  isEmpty,
  round,
  sortItemsByCategories,
} from 'src/helpers'
import {
  campaignsActions,
  discountsActions,
  estimateActions,
  estimateItemsActions,
  itemsActions,
} from '../../../../../ducks/actions'
import { Item } from '../../../../../ducks/types'
import EstimateAlert from './EstimateAlert'
import { generatePdf } from './ItemsPdf'
import { generatePdf as generatePdfCsm } from './ItemsPdf_csm'
import JobLinkBanner from './JobLinkBanner'
import { BANNER_STATUS } from './JobLinkBanner/constants'
import PriceDetail from './PriceDetail'
import DesktopShareModal from './ShareModal/DesktopShareModal'
import MobileShareModal from './ShareModal/MobileShareModal'
import useStyles from './styles'
import useStylesBetter from './stylesBetter'
// import DoughnutChart from 'src/components/UI/CustomUI/atoms/DoughnutChart'
import moment from 'moment'
import { isMobile } from 'react-device-detect'
import { useParams } from 'react-router-dom'
import { CategoryIcon } from 'src/components/UI/CustomUI/molecules/CategoryIcon'
import Modal from 'src/components/UI/CustomUI/molecules/Modal'
import TypeViewsV2 from 'src/components/UI/CustomUI/molecules/TypeViewsV2'
import {
  getEstimateItems,
  getEstimateItemsGroupBy,
} from 'src/ducks/estimateItems/selectors'
import useQRCodesGenerator from 'src/hooks/useQRCodeGenerator'
import AdditionalItemsRequested from './AdditionalItemsRequested'
import BottomBarEstimate from './BottomBarEstimate'
import Cart from './Cart'
import ContactUsModal from './ContactUsModal'
import EstimateLeft from './EstimateLeft'
import EstimatedItems from './EstimatedItems'
import NeedMoreInformation from './NeedMoreInformation'
import NewItemsEstimated from './NewItemsEstimated'
import RequestMoreItemsModal from './RequestMoreItemsModal'
import SharedEstimateIndicator from './SharedEstimateIndicator'
import NewEstimateModal from './NewEstimateModal'
import ReleaseHeader from './ReleaseHeader'
import html2canvas from 'html2canvas'
import EstimateHeaderAlert from './EstimateHeaderAlert'

const getApprovedColor = (item: string) => {
  switch (item) {
    case 'Approved':
      return greenPoint
    case 'Rejected':
      return redPoint
  }
}

const PortalEstimate = ({ isRelease = false }: { isRelease?: boolean }) => {
  const mounted = useRef(false)

  const { id: estimateId } = useParams()
  const estimate = useSelector(getEstimate())
  const styles = useStyles()
  const stylesBetter = useStylesBetter()
  const dispatch = useDispatch()
  const push = history.usePush()

  const [qrCode] = useQRCodesGenerator(
    `${window.location.origin}/estimates/${estimate?.id}`
  )
  const [tutorialVideoQrCode] = useQRCodesGenerator(
    `https://www.youtube.com/watch?v=5237HyFN44M`
  )

  const {
    properties,
    sharedByEmail,
    sharedByName,
    deliveredOn,
    approvedOn,
    promotionId,
    approvalStatus,
  } = estimate ?? {}

  const {
    territory,
    contacts,
    minimum = 0,
    affiliate,
    discount,
    nar,
    taxAmount,
    totalWithTax = 0,
    taxRate,
    address,
  } = properties ?? {}
  /*   const {
    } = address ?? {} */
  const { serviceable } = territory ?? {}

  const {
    firstName = '',
    lastName = '',
    email = '',
  } = contacts && contacts.length > 0 ? contacts[0] : {}

  const {
    line_1: street,
    line_2: street2,
    city,
    state,
    zipCode,
  } = address ?? {}

  const line1 = (street ? `${street} ` : '') + (street2 ? `#${street2}` : '')
  const line2 = `${city}, ${state} ${zipCode}`
  const isFromCanada = address?.country === 'Canada'

  const showBetterStyle = !isEmpty(affiliate) && affiliate === 'better'
  const classes = showBetterStyle ? stylesBetter : styles
  const isPillarToPost =
    estimate?.properties.affiliate === 'pillartopost' ||
    estimate?.properties.affiliate === 'pillar to post'

  const [pageLoading, setPageLoading] = useState(true)
  const [itemsLoading, setItemsLoading] = useState(false)
  const [selectedType, setSelectedType] = useState<ESTIMATE_VIEW>(
    ESTIMATE_VIEW.TYPE
  )

  const [hideRemoved, setHideRemoved] = useState(false)
  const [downloadingPdf, setDownloadingPdf] = useState(false)
  const [openRequestItemModal, setOpenRequestItemModal] = useState(false)
  const [openShareModal, setOpenShareModal] = useState(false)
  const [openContactUsModal, setOpenContactUsModal] = useState(false)
  const [showFAQ, setShowFAQ] = useState(true)
  const [openCart, setOpenCart] = useState(false)
  const campaign = useSelector(getCampaigns())

  const estimateItems = useSelector(getEstimateItems(false)).filter(
    (item: Item) => {
      return (
        !item.addedPostDelivery ||
        (item.addedPostDelivery && item.status !== ITEM_STATUS.NEW)
      )
    }
  )

  const groupedItems = sortItemsByCategories(
    useSelector(
      getEstimateItemsGroupBy(
        selectedType,
        hideRemoved,
        undefined,
        undefined,
        estimateItems
      )
    )
  )

  const groupedItemsPDF = sortItemsByCategories(
    useSelector(getEstimateItemsGroupBy(selectedType, hideRemoved))
  )

  const evaluateItems = sortItemsByCategories(
    useSelector(getEstimateItemsGroupBy(ESTIMATE_VIEW.TYPE, false, undefined))
  )[ITEM_CATEGORY.EVALUATE]

  delete groupedItems.EVALUATE

  const [selectedFilt, setSelectedFilt] = useState<
    ITEM_CATEGORY_FOR_FILTERS | ITEM_PRIORITY
  >(ITEM_CATEGORY_FOR_FILTERS.ALL)

  const items = useSelector(getEstimateItems(false))

  const categoryHash = (items as Item[]).reduce((result: any, item: Item) => {
    const key =
      selectedType === ESTIMATE_VIEW.PRIORITY ? item.severity : item.category
    if (result[key] === undefined) result[key] = 1
    else result[key]++

    return result
  }, {})

  const sortedPriorities = Object.fromEntries(
    Object.entries(categoryHash).sort(
      (a: any, b: any) =>
        Object.values(ITEM_PRIORITY).indexOf(a[0]) -
        Object.values(ITEM_PRIORITY).indexOf(b[0])
    )
  )

  const categoryTabs = Object.keys(sortedPriorities).map((key) => ({
    title: key,
    count: categoryHash[key],
  }))

  const shared = estimate?.sharedByEmail != null
  const punchlistPlanner = serviceable !== undefined && !serviceable && !shared

  const bannerStatus = useMemo(() => {
    if (estimate?.jobNeedsAttention === true)
      return BANNER_STATUS.NEEDS_ATTENTION
    if (estimate?.publicStatus === ESTIMATE_PUBLIC_STATUS.APPROVED)
      return BANNER_STATUS.IN_PROGRESS
    return BANNER_STATUS.OTHER
  }, [estimate])

  const showApproveButton =
    (estimate?.approvalStatus === 'NOT_APPROVED' ||
      estimate?.approvalStatus === 'MISSING_DETAILS') &&
    estimate.publicStatus !== ESTIMATE_PUBLIC_STATUS.EXPIRED &&
    (totalWithTax ? Number(totalWithTax) > Number(minimum) : false)

  const fetchEstimateItems = useCallback(
    (loadings = true) => {
      if (loadings) setItemsLoading(true)
      dispatch(
        estimateItemsActions.fetchEstimateItems(
          { params: { jobId: estimateId } },
          (_succ: boolean) => {
            if (mounted.current) {
              setItemsLoading(false)
            } else {
              return null
            }
          }
        )
      )
    },
    [dispatch, estimateId]
  )

  const fetchDiscountBanner = useCallback(() => {
    const date = moment().format('MMM YYYY')

    dispatch(discountsActions.fetchDiscountBanner())
  }, [dispatch])

  const fetchCampaigns = useCallback(() => {
    dispatch(
      campaignsActions.fetchCampaigns({
        estimateType: !serviceable
          ? 'OUT_OF_AREA'
          : estimate?.formType === 'CLIENT_REPAIR_LIST'
          ? 'IN_AREA_REPAIR_LIST'
          : 'IN_AREA_ESTIMATE',
      })
    )
  }, [dispatch])

  const fetchEstimateView = useCallback(
    (loadings = true) => {
      if (loadings && mounted.current) setPageLoading(true)
      dispatch(
        estimateActions.fetchEstimate(estimateId || '', (_succ: boolean) => {
          if (mounted.current) setPageLoading(false)
          if (!_succ) {
            push(`p/dashboard`)
            // goBack()
          } else {
            if (mounted.current) {
              fetchEstimateItems(loadings)
            }
          }
        })
      )
      territory?.id &&
        dispatch(
          itemsActions.fetchRecommendedItems(territory?.id, (succ) => {})
        )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, estimateId, fetchEstimateItems]
  )

  useEffect(() => {
    // fetchDiscountBanner()
    // fetchCampaigns()
  }, [])

  useEffect(() => {
    mounted.current = true
    fetchEstimateView()
    return () => {
      mounted.current = false
    }
  }, [fetchEstimateView])

  const handleSubmit = (type: ESTIMATE_VIEW): void => {
    setSelectedType(type)
  }

  const getIcon = (item: any, color?: string) => {
    switch (selectedType) {
      case ESTIMATE_VIEW.TYPE:
        return (
          <Box className={classes.icon}>
            <CategoryIcon title={item} color={color} />
          </Box>
        )
      case ESTIMATE_VIEW.PRIORITY:
        return (
          <Box className={classes.icon}>
            <CategoryIcon filteredByPriority title={item} color={color} />
          </Box>
        )
      case ESTIMATE_VIEW.APPROVED:
        return (
          <img src={getApprovedColor(item)} alt="" className={classes.icon} />
        )
    }
  }

  const handleApprove = (): void => {
    push(`/estimates/${estimate?.id}/approve`)
  }

  const handleRemove = (
    estimateId: string,
    itemId: string,
    removed: boolean
  ): void => {
    dispatch(
      estimateItemsActions.updateEstimateItem(
        {
          estimateId,
          itemId,
          partialItem: {
            status: removed ? ITEM_STATUS.APPROVED : ITEM_STATUS.REJECTED,
          },
        },
        () => {
          fetchEstimateView(false)
        }
      )
    )
  }

  const handleShare = (): void => {
    setOpenShareModal(true)
  }

  const onOpenCart = (): void => {
    setOpenCart(true)
  }

  const filterApproved = (groupedItems: any) => {
    const filteredItems = { ...groupedItems }
    Object.keys(filteredItems).forEach((key) => {
      const approvedItems = filteredItems[key].filter(
        (item: Item) => item.status === ITEM_STATUS.APPROVED
      )
      if (approvedItems.length) {
        filteredItems[key] = approvedItems
      } else {
        delete filteredItems[key]
      }
    })
    return filteredItems
  }

  const onlyApprovedItems = filterApproved(groupedItems)

  const groupedItemsCsm = sortItemsByCategories(
    useSelector(getEstimateItemsGroupBy(selectedType, hideRemoved))
  )

  const handlePdf = async (onlyApproved?: boolean, oldPdf?: boolean) => {
    if (!downloadingPdf) {
      setDownloadingPdf(true)
      if (oldPdf) {
        await generatePdfCsm({
          groupedItems: groupedItemsCsm,
          evaluateItems: evaluateItems,
          userInfo: {
            name: `${firstName} ${lastName}`,
            city: city ? `${city},` : '',
            address: street ?? '',
            sent: deliveredOn
              ? formatTimestamp(deliveredOn, 'MMMM Do, YYYY')
              : '',
            approvedOn: approvedOn
              ? formatTimestamp(approvedOn, 'MMMM Do, YYYY')
              : '',
            mail: email ?? '',
            taxRate: taxRate,
            total: total,
          },
          imageUris: {},
          showBetterStyle,
          isFromCanada: isFromCanada,
        })
      } else {
        await generatePdf({
          groupedItems: onlyApproved ? onlyApprovedItems : groupedItemsPDF,
          evaluateItems: evaluateItems,
          userInfo: {
            name: `${firstName} ${lastName}`,
            city: city ? `${city},` : '',
            address: address,
            sent: deliveredOn
              ? formatTimestamp(deliveredOn, 'MMMM Do, YYYY')
              : '',
            approvedOn: approvedOn
              ? formatTimestamp(approvedOn, 'MMMM Do, YYYY')
              : '',
            mail: email ?? '',
            taxRate: taxRate,
            total: total,
            serviceable,
            nar: nar,
          },
          imageUris: {
            qrCode,
            // punchlistLogoUri,
          },
          marketingImg: campaign?.imageUrl,
          estimate: estimate,
        })
      }

      setDownloadingPdf(false)
    }
  }

  const [alert, setAlert] = useState(false)
  const [alertItem, setAlertItem] = useState<Item | null>(null)

  const handleShowAlert = (item: Item): void => {
    setAlertItem(item)
    setAlert(true)
  }

  const estimateNotApproved =
    !shared && estimate?.publicStatus !== ESTIMATE_PUBLIC_STATUS.APPROVED

  const minPriceRange = estimate?.minPriceRange
  const maxPriceRange = estimate?.maxPriceRange

  const total = useMemo(() => {
    if (minPriceRange && maxPriceRange) {
      if (minPriceRange === null || maxPriceRange === null) {
        return '$'
          .concat(round(totalWithTax ?? 0, 2))
          .concat(isFromCanada ? ' CAD' : '')
      }

      if (minPriceRange === maxPriceRange) {
        return '$'
          .concat(round(totalWithTax ?? 0, 2))
          .concat(isFromCanada ? ' CAD' : '')
      }

      if (minPriceRange !== maxPriceRange) {
        return '$'
          .concat(round(minPriceRange, 0).slice(0, -3))
          .concat(isFromCanada ? ' CAD' : '')
          .concat(' - $')
          .concat(round(maxPriceRange, 0).slice(0, -3))
          .concat(isFromCanada ? ' CAD' : '')
      }
    }
    return '$'
      .concat(round(totalWithTax ?? 0, 2))
      .concat(isFromCanada ? ' CAD' : '')
  }, [minPriceRange, maxPriceRange, totalWithTax, isFromCanada])

  const handleOpenContactUsModal = (showFAQ: boolean) => {
    setOpenContactUsModal(!openContactUsModal)
    setShowFAQ(showFAQ)
  }

  return (
    <PrivateLoader loading={pageLoading}>
      {/* HIDED BOX FOR ESTIMATE TOP INFO */}
      <OverlayLoader loading={downloadingPdf}>
        <div>
          {isRelease ? <ReleaseHeader onDownload={handlePdf} /> : <></>}
          <div className={classes.container}>
            <div className={classes.containerLeft}>
              <EstimateLeft
                estimate={estimate}
                onDownloadPDF={handlePdf}
                onShare={handleShare}
                serviceable={serviceable || false}
                ptp={isPillarToPost}
              />
            </div>
            <div className={classes.containerRight}>
              <Modal
                open={openCart}
                setOpen={() => setOpenCart(!openCart)}
                size="lg"
                className={classes.cartModal}
                title={
                  <Box className={classes.cartHeader}>
                    <Box className={classes.cartTitleContainer}>
                      {isMobile && (
                        <icons.ArrowBack
                          className={classes.arrowBack}
                          onClick={() => setOpenCart(false)}
                        />
                      )}
                      <Box className={classes.row}>
                        <icons.ShoppingCart className={classes.cartIcon} />
                        <Typography className={classes.cartTitle}>
                          {serviceable
                            ? 'Cart Summary'
                            : 'Total for selected Items'}
                        </Typography>
                      </Box>
                      {/* <Typography className={classes.cartTitle}>${round(estimate?.properties?.totalWithTax || 0, 2)}</Typography> */}
                    </Box>
                    {isMobile && (
                      <Typography
                        className={classes.estimateValid}
                        variant="caption"
                      >
                        Estimate valid for 30 days
                      </Typography>
                    )}
                  </Box>
                }
                showClose={!isMobile}
              >
                <Cart
                  handleOpenContactUsModal={handleOpenContactUsModal}
                  fetchEstimateView={fetchEstimateView}
                  serviceable={serviceable || false} /* price={total} */
                  setOpenCart={() => setOpenCart(!openCart)}
                  onApprove={() => {}}
                  showApproveButton={showApproveButton}
                  price={total}
                  estimateNotApproved={estimateNotApproved}
                />
              </Modal>
              {!serviceable &&
                (estimate?.properties?.affiliate === 'pillartopost' ||
                  estimate?.properties?.affiliate === 'pillar to post') &&
                !shared && <NewEstimateModal />}

              <PrivateLoader loading={itemsLoading} building="fragment">
                <>
                  <TypeViewsV2
                    currentTab={0}
                    tabs={categoryTabs}
                    selectedType={selectedType}
                    handleSubmit={handleSubmit}
                    totalCount={items.length}
                    getIcon={getIcon}
                    // use the selectedFilt for filtering the items with selected category or priority
                    selectedFilt={selectedFilt}
                    setSelectedFilt={setSelectedFilt}
                  />
                  {shared && (
                    <SharedEstimateIndicator
                      sharedByEmail={sharedByEmail || ''}
                      sharedByName={sharedByName || ''}
                    />
                  )}
                  <ScrollWrapper relativeClass={classes.relativeContainer}>
                    <>
                      <JobLinkBanner
                        jobStatus={bannerStatus}
                        jobId={estimate?.id}
                      />

                      <NeedMoreInformation
                        estimateNotApproved={estimateNotApproved}
                        showBetterStyle={showBetterStyle}
                        punchlistPlanner={punchlistPlanner}
                        handleShowAlert={handleShowAlert}
                        handleRemove={handleRemove}
                        serviceable={serviceable || false}
                        fetchEstimateView={fetchEstimateView}
                        selectedFilt={selectedFilt}
                      />

                      {estimate && estimate?.properties?.clientRequestNotes && (
                        <AdditionalItemsRequested
                          punchlistPlanner={punchlistPlanner}
                          handleShowAlert={handleShowAlert}
                          handleRemove={handleRemove}
                          serviceable={serviceable || false}
                          estimateNotApproved={estimateNotApproved}
                        />
                      )}

                      {/* {selectedFilt === ITEM_CATEGORY_FOR_FILTERS.ALL && */}
                      <NewItemsEstimated
                        selectedType={selectedType}
                        hideRemoved={hideRemoved}
                        serviceable={serviceable || false}
                        selectedFilt={selectedFilt}
                        fetchEstimateView={fetchEstimateView}
                        handleShowAlert={handleShowAlert}
                        punchlistPlanner={punchlistPlanner}
                        estimateNotApproved={estimateNotApproved}
                        getIcon={getIcon}
                      />
                      {/* } */}

                      <EstimatedItems
                        selectedType={selectedType}
                        hideRemoved={hideRemoved}
                        serviceable={serviceable || false}
                        selectedFilt={selectedFilt}
                        fetchEstimateView={fetchEstimateView}
                        handleShowAlert={handleShowAlert}
                        punchlistPlanner={punchlistPlanner}
                        estimateNotApproved={estimateNotApproved}
                        getIcon={getIcon}
                      />
                      {isMobile &&
                        !estimate?.properties?.clientRequestNotes &&
                        estimateNotApproved && (
                          <Box
                            style={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <Button
                              type="submit"
                              variant="contained"
                              size="small"
                              className={classes.requestMoreButton}
                              fullWidth
                              onClick={() => setOpenRequestItemModal(true)}
                              startIcon={<SvgIcon component={icons.ListAlt} />}
                            >
                              <Typography> Request More Items</Typography>
                            </Button>
                          </Box>
                        )}
                    </>
                  </ScrollWrapper>

                  {alert && alertItem && (
                    <Modal
                      open={alert}
                      setOpen={() => setAlert(!alert)}
                      className={classes.itemDetailModal}
                    >
                      <Box className={classes.back}>
                        <Box className={classes.alert}>
                          <EstimateAlert
                            title={alertItem.title}
                            quantity={Number(alertItem.quantity)}
                            inspectionReportNote={
                              alertItem.inspectionReportNote
                            }
                            requestedRepairNote={alertItem.requestedRepairNote}
                            price={alertItem.totalPrice}
                            imageFiles={alertItem.imageFiles}
                            disclaimer={alertItem.disclaimer}
                            removed={alertItem.status === ITEM_STATUS.REJECTED}
                            showButtons={estimateNotApproved}
                            onRemove={() =>
                              handleRemove(
                                alertItem.estimateId,
                                alertItem.itemId,
                                alertItem.status === ITEM_STATUS.REJECTED
                              )
                            }
                            showAlert={(value) => setAlert(value)}
                          />
                        </Box>
                      </Box>
                    </Modal>
                  )}

                  {!isMobile ? (
                    <DesktopShareModal
                      open={openShareModal}
                      setOpen={setOpenShareModal}
                      showBetterStyle={showBetterStyle}
                    />
                  ) : (
                    <MobileShareModal
                      open={openShareModal}
                      setOpen={setOpenShareModal}
                      showBetterStyle={showBetterStyle}
                    />
                  )}

                  <ContactUsModal
                    open={openContactUsModal}
                    showFAQ={showFAQ}
                    setOpen={setOpenContactUsModal}
                    showBetterStyle={showBetterStyle}
                  />

                  <RequestMoreItemsModal
                    open={openRequestItemModal}
                    setOpen={setOpenRequestItemModal}
                  />

                  {!isMobile && (
                    <PriceDetail
                      discount={discount}
                      isNar={nar}
                      taxAmount={taxAmount}
                      totalValue={totalWithTax}
                      taxRate={taxRate}
                      priceRange={total}
                      serviceable={serviceable || false}
                      totalPrice={total}
                      ptp={isPillarToPost}
                      discountText={promotionId || ''}
                    />
                  )}

                  <BottomBarEstimate
                    disabledForward={!totalWithTax || totalWithTax < minimum}
                    onRequestMoreItems={() => setOpenRequestItemModal(true)}
                    onOpenCart={onOpenCart}
                    rmForward={false}
                    price={total}
                    showButtons={estimateNotApproved}
                    showBetterStyle={showBetterStyle}
                    showApproveButton={showApproveButton}
                    isPunchlistPlanner={punchlistPlanner}
                    shared={shared}
                    serviceable={serviceable || false}
                    ptp={isPillarToPost}
                  />
                </>
              </PrivateLoader>
            </div>
          </div>
        </div>
      </OverlayLoader>
    </PrivateLoader>
  )
}

export default PortalEstimate
