import { FC } from 'react'
import { Box, Typography } from '../../../UI'

const Reports: FC = () => {
  return (
    <Box>
      <Typography variant='h1'>Reports</Typography>
      <Typography variant='h6'>(Pending)</Typography>
    </Box>
  )
}

export default Reports
