import exp from 'constants'
import { SearchParams } from '../searches/types'
import { Estimate, Count, EstimateItem } from '../types'

/** TYPES **/
export const estimatesTypes = {
  FETCH_ESTIMATES: 'FETCH_ESTIMATES',
  FETCH_RELATED_ESTIMATES: 'FETCH_RELATED_ESTIMATES',
  SET_ESTIMATES: 'SET_ESTIMATES',
  SET_RELATED_ESTIMATES: 'SET_RELATED_ESTIMATES',
  FETCH_ESTIMATE_COUNTS: 'FETCH_ESTIMATE_COUNTS',
  SET_ESTIMATES_COUNTS: 'SET_ESTIMATES_COUNTS',
  UPDATE_ESTIMATE: 'UPDATE_ESTIMATE',
  CREATE_ESTIMATE: 'CREATE_ESTIMATE',
  RELEASE_ESTIMATE: 'RELEASE_ESTIMATE',
}

/** ACTIONS **/
export interface FetchEstimatesActions {
  type: typeof estimatesTypes.FETCH_ESTIMATES
  payload: {
    page?: number,
    size?: number,
    searchParams?: Partial<SearchParams>
  }
  callback?: (succ: boolean) => void
}
export interface FetchRelatedEstimatesAction {
  type: typeof estimatesTypes.FETCH_RELATED_ESTIMATES
  payload: string
  callback?: (succ: boolean) => void
}

export interface CreateEstimateActions {
  type: typeof estimatesTypes.CREATE_ESTIMATE
  payload: Partial<Partial<EstimateItem>>
  callback?: (succ: boolean, estimateId: string | null) => void
}

export interface UpdateEstimateActions {
  type: typeof estimatesTypes.UPDATE_ESTIMATE
  payload: Partial<EstimateItem>
  callback?: (succ: boolean) => void
}

export interface SetEstimatesAction {
  type: typeof estimatesTypes.SET_ESTIMATES
  payload: Estimate
}

export interface SetRelatedEstimatesAction {
  type: typeof estimatesTypes.SET_RELATED_ESTIMATES
  payload: EstimateItem[]
}

export interface SetJobsCountsAction {
  type: typeof estimatesTypes.SET_ESTIMATES_COUNTS
  payload: Count[]
}

export interface ReleaseEstimateAction {
  type: typeof estimatesTypes.RELEASE_ESTIMATE
  payload: {
    estimateId: string,
    releaseEstimateRequest: ReleaseEstimateRequest
  }
  callback?: (succ: boolean) => void
}

export interface SendTo {
  name: string,
  email: string,
}

export interface ReleaseEstimateRequest {
  sendToList: SendTo[],
  emailSubject: string,
  notes: string,
}

export type EstimatesActions =
  | FetchEstimatesActions
  | FetchRelatedEstimatesAction
  | UpdateEstimateActions
  | SetEstimatesAction
  | SetRelatedEstimatesAction
  | SetJobsCountsAction
  | ReleaseEstimateAction

export type EstimatesType = Estimate
