import React, { FC, useState } from 'react'
import { Box, Button, Popover, Typography } from '@mui/material'
import { ColorPickerProp } from './types'
import { SketchPicker } from 'react-color'

import styles from './styles.module.scss'


const ColorPicker: FC<ColorPickerProp> = ({
  picker,
  color,
  onChange,
  onClose,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    onClose()
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <Box>
      <Button aria-describedby={id} onClick={handleClick}>
        {picker}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        className={styles.ColorPicker__popover}
      >
        <Box
          className={styles.ColorPicker}
        >
          <Typography display="block" marginBottom="12px" variant="h5Bold">Pick a color for this technician</Typography>
          <SketchPicker width='350px' color={color} onChange={onChange} className={styles.ColorPicker__container} />
        </Box>
      </Popover>
    </Box>
  )
}

export default ColorPicker
