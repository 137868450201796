import { HeaderType } from 'src/components/UI/CustomUI/organisms/Table/types'

const headers: HeaderType[] = [
    {
        id: 'jobs',
        label: 'Jobs'
    },
    {
        id: 'jobNumber',
        label: ''
    },
    {
        id: 'date',
        label: 'Date'
    },
    {
        id: 'dateNumber',
        label: ''
    },
]

export default headers
