import { Button, Grid, Typography } from '@mui/material'
import { isEmpty, set } from 'lodash'
import { FC, useState } from 'react'
import { PrivateLoader } from 'src/components/templates'
import { TextFieldLabel } from 'src/components/UI'
import Modal from 'src/components/UI/CustomUI/molecules/Modal'
import styles from './styles.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { inspectorsActions } from 'src/ducks/actions'
import { InspectorCompany } from 'src/ducks/types'
import { PHONE_MASK_INPUT, PHONE_MASK_REGEXP, validateEmail } from 'src/helpers'
import { getInspectionCompany } from 'src/ducks/selectors'

interface InspectorCompanyModalProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  inspectorSelected?: InspectorCompany
  fetchInspectors: () => void
  onClose: () => void
}

export type ModalTypes = 'ADD' | 'EDIT'

const InspectorCompanyModal: FC<InspectorCompanyModalProps> = ({
  open,
  setOpen,
  inspectorSelected,
  fetchInspectors,
  onClose,
}) => {
  const dispatch = useDispatch()
  const [inspectorName, setInspectorName] = useState(inspectorSelected?.name)
  const [inspectorEmail, setInspectorEmail] = useState(inspectorSelected?.email)
  const [inspectorPhone, setInspectorPhone] = useState(inspectorSelected?.phone)
  const inspectionCompany = useSelector(getInspectionCompany)
  const [showWarnings, setShowWarnings] = useState(false)
  const [loading, setLoading] = useState(false)

  const isValid =
    !isEmpty(inspectorEmail) &&
    !isEmpty(inspectorPhone) &&
    !isEmpty(inspectorName)

  const handleAddInspection = () => {
    if (isEmpty(inspectorEmail) || isEmpty(inspectorPhone)) {
      setShowWarnings(true)
      return
    }
    setShowWarnings(false)
    setLoading(true)
    dispatch(
      inspectorsActions.createInspector(
        {
          name: inspectorName,
          email: inspectorEmail,
          phone: inspectorPhone,
          inspectionCompany: {
            name: inspectionCompany.name,
          },
        },
        (succ) => {
          setLoading(false)
          if (succ) {
            //fetch companies
            fetchInspectors()
            setOpen(false)
          }
        }
      )
    )
  }

  const handleEditInspection = () => {
    if (!isValid) {
      setShowWarnings(true)
      return
    }
    setShowWarnings(false)
    setLoading(true)
    dispatch(
      inspectorsActions.updateInspector(
        {
          id: inspectorSelected?.id as string,
          request: [
            {
              op: 'replace',
              path: '/name',
              value: inspectorName,
            },
            {
              op: 'replace',
              path: '/email',
              value: inspectorEmail,
            },
            {
              op: 'replace',
              path: '/phone',
              value: inspectorPhone,
            },
          ],
        },
        (succ) => {
          setLoading(false)
          if (succ) {
            fetchInspectors()
            setOpen(false)
          }
        }
      )
    )
  }
  return (
    <Modal open={open} setOpen={setOpen} onClose={onClose}>
      <PrivateLoader loading={loading}>
        <Grid
          container
          direction="column"
          spacing={2}
          style={{ padding: '.8rem 0' }}
        >
          <Grid item>
            <Typography variant="h5" style={{ fontFamily: 'Nouvelle' }}>
              Inspector Data
            </Typography>
          </Grid>
          <Grid item>
            <TextFieldLabel
              labelVariant="h6"
              label="Name"
              placeholder="Name"
              value={inspectorName}
              helperText={
                showWarnings && isEmpty(inspectorName)
                  ? 'Should add Company Name'
                  : ''
              }
              error={showWarnings && isEmpty(inspectorName)}
              onChange={(event) => setInspectorName(event.target.value)}
            />
          </Grid>
          <Grid item>
            <TextFieldLabel
              label="Email:"
              type="email"
              name="login-email"
              // autoComplete='email username'
              value={inspectorEmail}
              error={
                showWarnings &&
                (isEmpty(inspectorEmail) ||
                  !validateEmail(inspectorEmail || ''))
              }
              helperText={
                showWarnings &&
                (isEmpty(inspectorEmail) ||
                  !validateEmail(inspectorEmail || '')) &&
                'Write a valid email'
              }
              color="inputColors"
              onChange={(event) => setInspectorEmail(event.target.value)}
              placeholder="Enter your email here"
              sx={{
                marginBottom: '2.125rem',
                height: { sm: '54px' },
              }}
              className={styles.email}
            />
          </Grid>
          <Grid item>
            <TextFieldLabel
              name="add-job-contact-modal-ph"
              id="add-job-contact-modal-ph"
              labelVariant="h6"
              label="Phone:"
              value={inspectorPhone}
              placeholder="876-543-1223"
              mask={PHONE_MASK_INPUT}
              error={
                showWarnings &&
                (!isEmpty(inspectorPhone) ||
                  !PHONE_MASK_REGEXP.test(inspectorPhone || ''))
              }
              className={styles.TextFieldLabel}
              onChange={(event) => {
                setInspectorPhone(event.target.value)
              }}
            />
          </Grid>
          <Grid item container spacing={1}>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="containedBig"
                style={{
                  color: 'var(--blue700)',
                  height: '100%',
                  fontSize: 'var(--font-S)',
                  background: '#FAFBFF',
                  border: 'none',
                }}
                onClick={onClose}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="containedBig"
                onClick={
                  inspectorSelected ? handleEditInspection : handleAddInspection
                }
              >
                {!inspectorSelected ? 'Add Inspector' : 'Edit Inspector'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </PrivateLoader>
    </Modal>
  )
}

export default InspectorCompanyModal
