import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { Box, Divider } from '../../../UI'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import CalendarToolbar from './ToolBar'
import CalendarSidebar from './SideBar'
import { CalendarContext } from './context.provider'
import ViewScheduler from './ViewScheduler'
import ViewCalendar from './ViewCalendar'
import {
  companiesActions,
  ordersActions,
  siteVisitsActions,
  territoriesActions,
  vendorsActions,
} from 'src/ducks/actions'
import { getEmployeePrimaryEmail, getOrders, getSiteVisits, getTerritories, getVendors } from 'src/ducks/selectors'
import { Event } from 'react-big-calendar'
import { PrivateLoader } from 'src/components/templates'
import { ORDER_STATUS } from 'src/helpers'
import { Options } from 'src/components/UI/CustomUI/atoms/FieldsFilter/types'
import { formatTimestampDate } from 'src/helpers'
import ViewEvent from './ViewEvent'
import { getCompanies } from 'src/ducks/companies/selectors'
import { preTerritoryOptions, preAffiliateOptions } from './constants'
import { getTerritoryParam, rainbow } from './helper'
import { VendorOptionProp, ViewType } from './types'
import { OrderType } from 'src/ducks/orders/types'

const defaultSelected = moment()

const CalendarPage: FC = () => {
  const dispatch = useDispatch()

  const [selected, setSelected] = useState<moment.Moment | null>(
    defaultSelected
  )

  const orders = useSelector(getOrders())
  const siteVisits = useSelector(getSiteVisits())
  const territories = useSelector(getTerritories)
  const affiliatesStages = useSelector(getCompanies)
  const vendors = useSelector(getVendors())

  const [view, setView] = useState<ViewType>('month')
  const [showDispatch, setShowDispatch] = useState<boolean>(false)
  const [ordersEvents, setOrdersEvents] = useState<Event[]>([])
  const [siteVisitsEvents, setSiteVisitsEvents] = useState<Event[]>([])

  const [territoryOptions, setTerritoryOptions] =
    useState<Options[]>(preTerritoryOptions)

  const [territoryParam, setTerritoryParam] = useState<string>(
    getTerritoryParam(territoryOptions)
  )

  const [affiliatesOptions, setAffiliatesOptions] =
    useState<Options[]>(preAffiliateOptions)

  const [affiliateParam, setAffiliateParam] = useState<string>(
    getTerritoryParam(affiliatesOptions))

  const [vendorOptions, setVendorOptions] = useState<VendorOptionProp[]>([])

  const [showOrders, setShowOrders] = useState(true)
  const [showSiteVisits, setShowSiteVisits] = useState(true)

  const [showAssignedToMe, setShowAssignedToMe] = useState(false)

  const [loadingOrders, setLoadingOrders] = useState<boolean>(true)
  const [loadingVendors, setLoadingVendors] = useState<boolean>(true)

  const loggedUserEmail = useSelector(getEmployeePrimaryEmail)
  const vendorUserIds = vendors.filter((vendor) =>
    vendor?.email[0]?.email?.includes(loggedUserEmail?.split('@')[0])
  ).reduce((acc: any, curr: any) => (acc = [...acc, curr.id]), []);

  const reduceOrdersForEvents = (orders: OrderType[]): Event[] => {
    const ordersReduced = orders.reduce((prev: Event[], order: OrderType, index: number) => {
      const result = prev
      const newEvent = {
        title: ViewEvent(index, order, vendors, vendorOptions, view),
        start: formatTimestampDate(order.scheduledStartTimestamp ?? 0),
        end: formatTimestampDate(order.scheduledEndTimestamp ?? 0),
        resource: !order.preapproval ? `${order.estimateId}?tab=1&order=${order?.orderId}` : null,
      }
      if (newEvent.title !== null) result.push(newEvent)
      return result
    }, [])
    return ordersReduced
  }

  useEffect(() => {
    if (vendors.length > 0) {
      if (showOrders && orders.length) {
        setOrdersEvents(reduceOrdersForEvents(orders))
      }

      if (showSiteVisits && siteVisits.length) {
        setSiteVisitsEvents(reduceOrdersForEvents(siteVisits))
      }
    }
  }, [orders, siteVisits, vendors, vendorOptions, showSiteVisits, showOrders])

  useEffect(() => {
    setTerritoryOptions([
      ...preTerritoryOptions,
      ...territories
        .filter(({ serviceable }) => serviceable === true)
        .map(({ id, title }) => ({
          id,
          label: title,
          hide: false,
        })),
    ])
  }, [territories])

  useEffect(() => {

    setAffiliatesOptions([
      ...preAffiliateOptions,
      ...affiliatesStages
        .map(({ id, name }) => ({
          id,
          label: name,
          hide: false,
        })),
    ])

  }, [affiliatesStages])

  // needs attention
  useEffect(() => {
    setVendorOptions(
      vendors.map((vendor) => ({
        id: vendor.id,
        checked: vendorUserIds.includes(vendor.id) ? true : !showAssignedToMe
      }))
    )
    // update vendor colors
    const nullColorVendor = vendors.findIndex((vendor) => vendor.color === null) // vendor index with null color

    if (nullColorVendor > -1) {
      dispatch(
        vendorsActions.setVendorsList(
          vendors.map((vendor, index) => ({
            ...vendor,
            color: vendor.color ? vendor.color : rainbow(index),
          }))
        )
      )
    }
  }, [vendors, dispatch, showAssignedToMe])

  useEffect(() => {
    setLoadingOrders(true)
    setLoadingVendors(true)
    setVendorOptions([])
    setSiteVisitsEvents([])
    setOrdersEvents([])
    showOrders && dispatch(
      ordersActions.fetchOrders(
        {
          params: {
            startDate: selected!.clone().startOf(view).format('MM/DD/YYYY'),
            endDate: selected!.clone().endOf(view).add(1, 'days').format('MM/DD/YYYY'),
            statuses: [
              ORDER_STATUS.DISPATCHED,
              ORDER_STATUS.REVIEW,
              ORDER_STATUS.COMPLETE,
              ORDER_STATUS.IN_PROGRESS,
            ].join(','),
            territory: territoryParam,
            affiliate: affiliateParam,
          },
        },
        (_succ) => {
          setLoadingOrders(false)
        }
      )
    )

    showSiteVisits && dispatch(
      siteVisitsActions.getSiteVisits({
        startDate: selected!.clone().startOf(view).format('MM/DD/YYYY'),
        endDate: selected!.clone().endOf(view).add(1, 'days').format('MM/DD/YYYY'),
        status: [
          ORDER_STATUS.DISPATCHED,
          ORDER_STATUS.REVIEW,
          ORDER_STATUS.COMPLETE,
          ORDER_STATUS.IN_PROGRESS,
        ].join(','),
        territory: territoryParam,
        affiliate: affiliateParam,
      }, undefined, undefined,
        (_succ) => {
          setLoadingOrders(false)
        })
    )

    dispatch(
      vendorsActions.fetchVendors({
        page: 0,
        size: 1000,
        searchParams: {
          approved: true,
          territory: territoryParam,
        },
      }, (_succ) => {
        setLoadingVendors(false)
      })
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, view, dispatch, territoryParam, affiliateParam, showOrders, showSiteVisits])

  useEffect(() => {
    dispatch(territoriesActions.fetchTerritories((succ) => { }))
    dispatch(companiesActions.fetchCompanies())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CalendarContext.Provider
      value={{
        ordersEvents,
        siteVisitsEvents,
        selected,
        setSelected,
        view,
        setView,
        showDispatch,
        setShowDispatch,
        territoryOptions,
        setTerritoryOptions,
        territoryParam,
        setTerritoryParam,
        affiliatesOptions,
        setAffiliatesOptions,
        affiliateParam,
        setAffiliateParam,
        vendorOptions,
        setVendorOptions,
        showOrders,
        setShowOrders,
        showSiteVisits,
        setShowSiteVisits,
        showAssignedToMe,
        setShowAssignedToMe
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridAutoFlow: 'row',
          gridAutoRows: 'min-content min-content auto',
        }}
      >
        <CalendarToolbar />
        <Divider sx={{ margin: '16px -24px' }} />
        <Box
          sx={{
            display: 'grid',
            gridAutoFlow: 'column',
            gridAutoColumns: 'auto min-content',
            gap: '20px',
          }}
        >
          <PrivateLoader loading={loadingOrders || loadingVendors}>
            {showDispatch ? <ViewScheduler /> : <ViewCalendar />}
          </PrivateLoader>
          <CalendarSidebar />
        </Box>
      </Box>
    </CalendarContext.Provider>
  )
}

export default CalendarPage
