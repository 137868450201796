import { SelectInputOption } from 'src/components/UI/CustomUI/atoms/SelectInput/types'
import { getOptionsFromArray, PRICE_MODEL_TYPE } from 'src/helpers'
import storeInfo from '../store'
import { PriceModelType } from './types'

export const getClientPriceModel = () => (): PriceModelType => {
  const { priceModel } = storeInfo.store.getState()
  return priceModel
}

export const getClientPriceModelFlatten = (): SelectInputOption[] => {
  const { priceModel } = storeInfo.store.getState()
  if (priceModel) {
    return getOptionsFromArray(priceModel.priceModels as unknown as any[], { key: 'id', label: 'name' })
  } else {
    return getOptionsFromArray([] as unknown as any[], { key: 'id', label: 'name' })
  }
}

export const getPriceModelNar = (): string => {
  const { priceModel } = storeInfo.store.getState()
  if (priceModel) {
    const price = priceModel.priceModels?.find(e => e.name === PRICE_MODEL_TYPE.PRICE_MODEL_NAR)
    return priceModel && price ? price.id : ''
  }
  return ''
}

export const getPriceModelAffiliate = (): string => {
  const { priceModel } = storeInfo.store.getState()
  if (priceModel) {
    const price = priceModel.priceModels?.find(e => e.name === PRICE_MODEL_TYPE.PRICE_MODEL_AFFILIATE)
    return priceModel && price ? price.id : ''
  }
  return ''
}

/* export const getPriceModelByName = (name: string): PriceModel | null => {
  const { priceModel } = storeInfo.store.getState()
  if (priceModel) {
    const priceFound = priceModel.priceModels.find(e => e.name === name)
    return priceFound ?? null
  }
  return null
} */
