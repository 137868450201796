import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) =>
  createStyles({
    label: {
      color: '#1a2a55',
      fontFamily: 'NouvelleNormal',
      fontWeight: 500,
      lineHeight: '24px',
      margin: '16px 9px 0px 0px'
    },
    comboUnit: {
      width: '100%',
      minHeight: '110px',
      marginTop: '2px',
      padding: '0px 20px 0px 20px',
      borderRadius: '8px',
      border: '2px solid var(--border-color)',
      color: 'var(--text-color)',
      fontFamily: 'NouvelleNormal',
      fontWeight: 400,
      fontSize: 16,
      '&:hover': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8
      },
      '&.Mui-focused': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8
      }
    },
    submit: {
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--blue700)',
      borderRadius: 32,
      endIcon: {
        width: '5px',
        height: '5px'
      }
    },
    icon: {
      width: '14px',
      height: '14px'
    },
    addressSearcherIcon: {
      color: theme.palette.grey[400],
      cursor: 'default'
    },
    optionContainer: {
      display: 'flex',
      alignItems: 'center',
      marign: '4px 0px !important',
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        width: '80%'
      },
      '&:hover': {
        backgroundColor: '#CEF0FF',
      },

    },
    pointerIcon: {
      width: '48px',
      borderRadius: '100%',
      paddingRight: '8px'
    },
    mainDirection: {
      fontSize: '16px',
      fontFamily: 'Nouvelle',
      paddingRight: '4px',
      width: '90%'
    },
    addressSearch: {
      display: 'grid',
      gridAutoColumns: 'auto min-content',
      gridAutoFlow: 'column',
      gap: '16px',
      alignItems: 'flex-end'
    },
    googleMapsButton: {
      textTransform: 'none',
      color: 'var(--blue700)',
      backgroundColor: 'var(--gray100)',
      borderRadius: 32,
      border: '0px solid',
      minWidth: '150px'
    },
    iconMap: {
      width: '24px',
      [theme.breakpoints.down('sm')]: {
        width: '14px',
      }
    }
  })
)
