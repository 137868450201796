import { Contact } from 'src/ducks/types'

export interface DepositInvoiceContextType {
  ccEmails: string
  subject: string
  jobId: string
  total: number
  isValid: boolean
  pageLoading: boolean
  payerEmail: string
  depositAmount: number
  depositPercentaje: number
  depositTotal: number
  depositSubTotal: number
  typeAmount: string
  file?: File
  depositOutstandingBalance : number
  tax:number,
  paid:number,
}

export interface DepositInvoiceValue {
  attr: keyof DepositInvoiceContextType
  value: any
}

export const depositInvoiceTypes = {
  SET_VALUE: 'SET_VALUE',
}
