
import { Button, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useNavigate } from 'react-router-dom'
import { logo } from 'src/assets'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import ErrorRandomImage from './RandomImage'

import styles from './styles.module.scss'

const ErrorPage = ({ error }: { error?: Error }) => {
  const navigate = useNavigate()
  return (
    <>
      <header className={styles.header}>
        <img src={logo} alt='Logo' />
      </header>
      <Box className={`${styles.root}`}>
        <Typography className={styles.title}>Oops!</Typography>
        {/* <img className={styles.img} src={logo} alt='Error' /> */}
        <ErrorRandomImage />
        <Typography className={styles.subtitle}>Couldn't find the roof <br /> you're looking for </Typography>
        <Button
          className={styles.button}
          onClick={() => navigate('/home')}
          startIcon={<Icon name="ArrowBack" />}
          variant='contained'
        >
          Return
        </Button>
      </Box>
    </>
  )
}

export default ErrorPage
