import React, { FC } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { FilterTogglerProp } from './types'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import classes from './styles.module.scss'

const FilterToggler: FC<FilterTogglerProp> = ({ label, hide, toggle }) => {
    return (
        <Grid
            container
            className={classes.FilterToggler__container}
            onClick={() => {
                toggle()
            }}
        >
            <Grid item container width='fit-content' className={classes.FilterToggler__item}>
                {hide ? <KeyboardArrowDownIcon height={2} color='inherit' /> : <KeyboardArrowUpIcon height={2} color='primary' />}
                <FilterAltIcon height={2} color='inherit' />
                <Typography className={classes.FilterToggler__label} width='fit-content' >{label}</Typography>
            </Grid>
        </Grid>
    )
}

export default FilterToggler