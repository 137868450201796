import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getInspectionItems,
  getItems,
  getItemsByCategory,
  getItemsSortedByCategory,
} from 'src/ducks/selectors'
import styles from './styles.module.scss'
import { itemsActions } from 'src/ducks/actions'
import { Item } from 'src/ducks/types'
import { toast } from 'react-toastify'
import { Box, CircularProgress, Typography } from '@mui/material'
import { Image } from '@mui/icons-material'

type Props = {}

const InspectionItems = (props: Props) => {
  const [loading, setLoading] = useState('')
  const dispatch = useDispatch()
  const inspectionItems = useSelector(getInspectionItems())

  const items = useSelector(getItemsSortedByCategory())

  const getCategoryItems = (category: string) => {
    return items.find((item) => item.category === category)
  }

  const handleCreateInspectionItem = (itemToCreate: Item) => {
    setLoading(itemToCreate.id?.toString() || '')
    const newItem = {
      hideFromClient: itemToCreate.hideFromClient,
      totalLaborCost: itemToCreate.totalLaborCost,
      totalMaterialCost: itemToCreate.totalMaterialCost,
      unitMaterialCost: itemToCreate.unitMaterialCost,
      unitLaborHours: itemToCreate.unitLaborHours,
      laborRate: itemToCreate.laborRate,
      category: itemToCreate.category,
      severity: itemToCreate.severity,
      title: itemToCreate.title,
      totalCost: itemToCreate.totalCost,
      profit: itemToCreate.profit,
      measurement: itemToCreate.measurement,
      inspectionReportNote: itemToCreate.inspectionReportNote,
      requestedRepairNote: itemToCreate.requestedRepairNote,
      orderId: itemToCreate.orderId,
      estimateId: itemToCreate.estimateId,
      quantity: itemToCreate.quantity,
      status: itemToCreate.status,
      totalPrice: itemToCreate.totalPrice,
      margin: itemToCreate.margin,
      disclaimer: itemToCreate.disclaimer,
      imageFiles: itemToCreate.imageFiles,
      isEstimate: true,
    }
    dispatch(
      itemsActions.createItem(newItem, (succ) => {
        setLoading('')
      })
    )
  }

  const isExising = (item: Item) => {
    const categoryItems = getCategoryItems(item.category)?.items
    return (
      categoryItems?.findIndex(
        (it) => it.inspectionReportNote === item.inspectionReportNote
      ) !== -1
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerItemRef}>Reference</div>
        <div className={styles.headerItemNotes}>Item</div>
        <div className={styles.headerItem}></div>
      </div>
      <div className={styles.body}>
        {inspectionItems.map((item, index) => {
          return (
            <div
              className={`${styles.bodyItem} ${
                isExising(item) ? styles.existingBodyItem : ''
              }`}
              key={index}
            >
              <div className={styles.bodyItemCellRef}>{item.id}</div>
              <div className={styles.bodyItemCellNotes}>
                <Typography component="span" style={{ position: 'relative' }}>
                  {`${item.inspectionReportNote} ${isExising(item) ? '(Summary)' : ''}`}
                  {item.imageFiles.length ? (
                    <div style={{ display: 'inline-flex', alignItems: 'center', height: '16px', marginLeft: '4px', position: 'absolute' }}>
                        <Image style={{ width: '14px', height: '14px' }} />
                    </div>
                  ) : (
                    <></>
                  )}
                </Typography>
              </div>
              <div className={styles.bodyItemCellButton}>
                <div
                  onClick={() => handleCreateInspectionItem(item)}
                  className={styles.addButton}
                >
                  {loading === item.id ? (
                    <CircularProgress color="info" size="1rem" />
                  ) : (
                    'Add'
                  )}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default InspectionItems
