import { JobActions } from '../job/types'
import { SearchParams } from '../searches/types'
import { JobsActions, jobsTypes, JobsType } from './types'

const actions = {
  fetchJobs: (
    payload: {
      page?: number,
      size?: number,
      searchParams?: Partial<SearchParams>,
    },
    callback?: (succ: boolean) => void): JobsActions => ({
      type: jobsTypes.FETCH_JOBS,
      payload,
      callback
    }),
  setJobs: (payload: JobsType): JobsActions => ({
    type: jobsTypes.SET_JOBS,
    payload
  }),
  fetchJobsCounts: (
    payload: {
      page?: number,
      size?: number,
      searchParams?: Partial<SearchParams>,
    },
    callback?: (succ: boolean) => void): JobsActions => ({
      type: jobsTypes.FETCH_JOBS_COUNTS,
      payload,
      callback
    }),
  setJobsCounts: (payload: JobsType): JobsActions => ({
    type: jobsTypes.SET_JOBS_COUNTS,
    payload
  }),
  downloadCSV: (
    payload: {
      searchParams?: Partial<SearchParams>
    }, callback: (succ: boolean) => void): JobsActions => ({
      type: jobsTypes.DOWNLOAD_CSV,
      payload,
      callback,
    }),
  getCSV: (
    payload: string,
    callback: (succ: boolean) => void): JobsActions => ({
      type: jobsTypes.GET_CSV,
      payload,
      callback,
    }),
  setCSVJobs: (payload: string): JobActions => ({
    type: jobsTypes.SET_JOBS_CSV,
    payload
  })
}

export default actions
