import { Box, Button, Typography } from "@mui/material"
import { FC } from "react"
import useStyles from './styles'

import { icons } from 'src/assets'
import { emptyCart } from "src/assets/index"

export interface EmptyCartProps {
  // price: string
  serviceable?: boolean
  onClick?: () => void
}

const EmptyCart: FC<EmptyCartProps> = ({ serviceable, onClick }) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <img className={classes.image} src={emptyCart} alt="Empty Cart" />
      <Box className={classes.container}>
        <Typography className={classes.title}>Hey!</Typography>
        <Typography className={classes.subtitle}>Let’s add some items to your cart to proceed.</Typography>
        <Button
          className={classes.button}
          onClick={() => onClick && onClick()}
          endIcon={<icons.ArrowForward />}
        >
          Select Items
        </Button>
      </Box>
    </Box>
  )
}

export default EmptyCart