import { Box } from '@mui/system'
import { FC, useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, CircularProgress, Grid, Typography, TextFieldLabel, Rating, SelectInput } from 'src/components/UI'
import { ordersActions, jobActions } from 'src/ducks/actions'
import { isEmpty, round } from 'src/helpers'
import { WorkOrdersContext } from '../../../context'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import theme from 'src/components/UI/MaterialUI/theme'
import { getOrderById } from 'src/ducks/selectors'
import { useParams } from 'react-router-dom'
import { SelectInputOption } from 'src/components/UI/CustomUI/atoms/SelectInput/types'

const paymentOptions = [{ label: 'QuickBooks Bill Pay', key: 'QUICKBOOKS' }, { label: 'Credit Card', key: 'CREDITCARD' }]

const CreateBill: FC = () => {
	const { dispatch: dispatchContext, state: { selectedOrderId } } = useContext(WorkOrdersContext)
	const dispatch = useDispatch()

	const [rating, setRating] = useState(0)
	const [feedback, setFeedback] = useState('')
	const [paymentMethod, setPaymentMethod] = useState<SelectInputOption>(paymentOptions[0])
	const [showWarnings, setShowWarnings] = useState(false)
	const [buttonLoading, setButtonLoading] = useState(false)

	const validate = rating > 0 && !isEmpty(feedback)

	const { id } = useParams()
	const order = useSelector(getOrderById(selectedOrderId as string))
	const {
		orderName, companyContact, companyName,
		bidPrice, prepaymentTotal, materialCost, vendorId
	} = order
	const paymentAmount = (bidPrice ?? 0) - (prepaymentTotal ?? 0) - (materialCost ?? 0)

	const handleClose = () => {
		dispatchContext({ type: 'SET_MODAL_OPEN', payload: false })
		setShowWarnings(false)
	}

	const handleEdit = () => {
		if (validate) {
			setButtonLoading(true)
			setShowWarnings(false)
			dispatch(ordersActions.createBill({
				jobId: id as string,
				orderId: selectedOrderId as string,
				orderType: 'ORDER',
				paymentType: paymentMethod.key,
				rating, feedback, vendorId
			}, (succ1) => {
				if (succ1 && id) {
					dispatch(
						jobActions.fetchJob(id, (succ2: boolean) => {
							if (succ2) {
								dispatch(ordersActions.fetchOrders(
									{ params: { jobId: id, page: 0, size: 9999 } },
									(succ3) => {
										if (succ3) handleClose()
										setButtonLoading(false)
									}
								))
							}
						})
					)



				} else setButtonLoading(false)
			}))
		} else setShowWarnings(true)
	}

	return (
		<Box sx={{}}>
			<Box sx={{ display: 'flex', gap: '8px', position: 'absolute', top: '12px', alignItems: 'baseline' }}>
				<Typography
					variant='body2'
					fontSize='14px'
					sx={{ color: 'var(--blue700)' }}
				>
					{`Pay BOSSCAT Pro (${orderName})`}
				</Typography>
				<Typography
					variant='caption'
					sx={{
						padding: '4px 8px',
						color: theme.palette.green[900],
						backgroundColor: theme.palette.green[100]
					}}
				>
					{paymentMethod?.label?.toUpperCase()}
				</Typography>
			</Box>
			<Grid container spacing={2} sx={{ padding: '52px 0 16px' }}>
				<Grid item xs={6}>
					<Typography fontSize='14px' variant='body1Bold'>{companyContact}</Typography>
					<Typography fontSize='14px'>{companyName}</Typography>
				</Grid>
				<Grid item display='flex' justifyContent='end' alignItems='flex-start' xs={6}>
					<Rating
						name='rating'
						value={rating}
						sx={{ fontSize: '1.5rem' }}
						onChange={(_e, newValue) => {
							if (newValue) { setRating(newValue) }
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextFieldLabel
						label='Techinician Feedback:'
						placeholder='Write your feedback for technician here...'
						value={feedback}
						size="small"
						onChange={event => setFeedback(event.target.value)}
						multiline
						rows={4}
						error={showWarnings && isEmpty(feedback)}
						labelVariant='h6'
					/>
				</Grid>
				<Grid container item xs={12} spacing={1}>
					<Grid item xs={6}><Typography fontSize='14px'>Bid Price:</Typography></Grid>
					<Grid item xs={6} display='flex' justifyContent='end'>
						<Typography fontSize='14px'>{`$${round(bidPrice, 2)}`}</Typography>
					</Grid>
					<Grid item xs={6}><Typography fontSize='14px'>Prepayments:</Typography></Grid>
					<Grid item xs={6} display='flex' justifyContent='end'>
						<Typography fontSize='14px'>{`$${round(prepaymentTotal, 2)}`}</Typography>
					</Grid>
					<Grid item xs={6}><Typography fontSize='14px'>Material Cost:</Typography></Grid>
					<Grid item xs={6} display='flex' justifyContent='end'>
						<Typography fontSize='14px'>{`$${round(materialCost, 2)}`}</Typography>
					</Grid>
					<Grid item xs={6}><Typography fontSize='14px'><b>Payment Amount:</b></Typography></Grid>
					<Grid item xs={6} display='flex' justifyContent='end'>
						<Typography fontSize='14px'>{`$${round(paymentAmount, 2)}`}</Typography>
					</Grid>
					<Grid item xs={6} display='flex' justifyContent='start'>
						<SelectInput
							label='Choose payment type:'
							onChange={(payment) => setPaymentMethod(payment)}
							options={paymentOptions}
							value={paymentMethod?.label}
							sx={{ width: '100%' }}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Box display='flex' justifyContent='end' gap={2}>
				<Button variant='containedLight' onClick={handleClose} sx={{ width: '100px' }}>
					Cancel
				</Button>
				<Button
					disabled={!rating}
					variant='contained'
					onClick={handleEdit}
					startIcon={!buttonLoading ? <Icon name='AttachMoney' /> : null}
					sx={{
						width: '150px',
						backgroundColor: theme.palette.green[700],
						borderColor: theme.palette.green[700],
						'&:hover': {
							backgroundColor: theme.palette.green[800],
							borderColor: theme.palette.green[800],
						}
					}}
				>
					{buttonLoading ? <CircularProgress color='info' size={'1.4rem'} /> : 'Pay BOSSCAT Pro'}
				</Button>
			</Box>
		</Box >
	)
}

export default CreateBill