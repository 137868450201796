/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { debounce } from 'lodash'
import { employeesActions, territoriesActions, employeeActions } from 'src/ducks/actions'
import { Box, Typography, SelectInput, Button, Grid, InputSearch } from 'src/components/UI'
import { HeaderType, AlignType } from 'src/components/UI/CustomUI/organisms/Table/types'
import { getEmployees, getTotalEmployees, getTerritoriesSelector, getEmployeeRoles, getEmployee } from 'src/ducks/selectors'
import { SelectInputOption } from 'src/components/UI/CustomUI/atoms/SelectInput/types'
import Modal from 'src/components/UI/CustomUI/molecules/Modal'
import { ModalActionType } from 'src/components/UI/CustomUI/molecules/Modal/types'
import Table from 'src/components/UI/CustomUI/organisms/Table'
import { EMPLOYEE_ROLE_TYPE, EMAIL_TYPE } from 'src/helpers'
import { SearchParams } from 'src/ducks/employees/types'
import { EmployeeRole, EmployeeType } from 'src/ducks/employee/types'
import Tag from 'src/components/UI/CustomUI/atoms/Tag'
import ShowMore from 'src/components/UI/CustomUI/atoms/ShowMore'
import useStyles from './styles'
import { icons, computerPointer } from 'src/assets'
import { Checkbox, FormControlLabel, Switch } from '@mui/material'
import UpdateUserModal from './UpdateUserModal'
import { IconButton } from '@mui/material'

const Admin: FC = () => {
  const classes = useStyles()
  const employee = useSelector(getEmployee)
  const dispatch = useDispatch()
  const [tableLoading, setTableLoading] = useState(false)
  const employees = useSelector(getEmployees)
  const totalEmployees = useSelector(getTotalEmployees)
  const roles = useSelector(getEmployeeRoles)
  const [open, setOpen] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [openUpdate, setOpenUpdate] = useState(false)
  const [selEmployee, setSelEmployee] = useState<EmployeeType | null>(null)
  const [selTerritories, setSelTerritories] = useState<string[]>([])
  const [selTerrValues, setSelTerrValues] = useState<boolean[]>([])
  const [administratorName, setAdministratorNameToSearch] = useState<string>("");
  const PAGE_SIZE = 50
  const [openChangeRoleModal, setOpenChangeRoleModal] = useState(false)
  const [employeeToEdit, setEmployeeToEdit] = useState<EmployeeType>()
  const [employeeNewRoles, setEmployeeNewRoles] = useState<EmployeeRole[]>()

  const territoriesOtions = useSelector(getTerritoriesSelector)
  const [territory, setTerritory] = useState(territoriesOtions[0])

  const onEditEmployee = (id: string, params: Partial<EmployeeType>): void => {
    setTableLoading(true)
    dispatch(employeeActions.updateEmployeeId(id, params, () => {
      setTableLoading(false)
      fetchAllEmployees(0, PAGE_SIZE)
    }))
  }

  const onReplaceEmployee = (id: string, params: Partial<EmployeeType>): void => {
    setTableLoading(true)
    const op = params && params?.roles && params?.roles[0]?.roleName === '' ? 'remove' : 'replace'

    dispatch(employeeActions.replaceEmployeeId(id, params, op, () => {
      setTableLoading(false)
      fetchAllEmployees(0, PAGE_SIZE)
      //setOpenChangeRoleModal(false)
    }))
  }

  const onDeleteEmployee = (id: string, params: Partial<EmployeeType>): void => {
    setTableLoading(true)
    dispatch(employeeActions.deleteEmployeeId(id, params, () => {
      setTableLoading(false)
      fetchAllEmployees(0, PAGE_SIZE)
    }))
  }

  const statusOtions = [
    { key: 'all', label: 'All Status' },
    { key: 'enabled', label: 'Enabled' },
    { key: 'disabled', label: 'Disabled' }
  ] as SelectInputOption[]
  const [status, setStatus] = useState(statusOtions[0])

  const handleTitle = (title: string) => {
    return title.substring(0, 1) + title.substring(1, title.length).toLowerCase().replaceAll('_', ' ')
  }

  const getPrimaryEmail = () => {
    if (selEmployee !== null) {
      return selEmployee?.email?.find(e => e.emailType === EMAIL_TYPE.PRIMARY)?.email
    }
    return ''
  }

  const handleOpenEdit = (employee: EmployeeType) => {
    const options = territoriesOtions.slice(1)
    const terr = options.map(t => { return t.label })
    const terrValues = options.map(t => { return employee?.territories?.find(et => et === t.key) !== undefined || false })
    setSelEmployee(employee)
    setSelTerritories(terr)
    setSelTerrValues(terrValues)
    setOpen(true)
  }

  const handleOpenDelete = (employee: EmployeeType) => {
    setSelEmployee(employee)
    setOpenDelete(true)
  }

  const headers: HeaderType[] = [
    {
      id: 'name',
      label: 'Name',
      custom: true,
      align: 'center' as AlignType,
      Element: (row: any): JSX.Element => {
        return (
          <Box display='flex' flexDirection='column'>
            <Typography sx={{ fontSize: '14px' }}>
              {row?.firstName + ' ' + row?.lastName}
            </Typography>
            <Typography sx={{ fontSize: '12px' }}>
              {row?.email ? row?.email[0]?.email : ' - '}
            </Typography>
          </Box>
        )
      },
      width: 350,
      hide: false
    },
    {
      id: 'zip',
      label: 'ZIP Code',
      custom: true,
      align: 'center' as AlignType,
      Element: (row: any): JSX.Element => {
        return (
          <Typography sx={{ fontSize: '14px' }}>
            {row?.address ? row?.address.zipCode : ' - '}
          </Typography>
        )
      },
      width: 80,
      hide: false
    },
    {
      id: 'territories',
      label: 'Territories',
      custom: true,
      align: 'center' as AlignType,
      Element: (row: any): JSX.Element => {
        const names = row?.territories?.map((curr: any) => territoriesOtions?.find(et => et.key === curr)?.label || '')
        return (
          <Box display='flex' flexDirection='column' gap={1}>
            <ShowMore
              limit={4}
              buttonSx={{ width: '100%' }}
              tooltipContent={
                <Box gap={1}>
                  {names?.map((terr: any) => <Typography key={terr}>{terr}</Typography>)}
                </Box>
              }
            >
              {!names?.length ? ["-"] : names.map((terr: any) => { return <Tag width='100%' center> {terr} </Tag> })}
            </ShowMore>
          </Box>
        )
      },
      width: 150,
      hide: false
    },

    {
      id: 'roles',
      label: 'Role',
      custom: true,
      align: 'center' as AlignType,
      Element: (row: any): JSX.Element => {
        const roles = row?.roles?.reduce((acc: any, curr: any) => acc.concat(curr?.roleName), []) || []
   
        return (
          <SelectInput
            options={rolesOpt.filter(role => !roles.includes(role.label)) ?? []}
            value={roles}
            sx={{ minWidth: '150px' }}
            multiple
            canRemove={false}
            onChange={(newValue) => {
              const newRoles = newValue.reduce((acc: any, curr: any) => {
                if (curr.key) {
                  return [...acc, {
                    roleName: curr.label,
                    roleType: curr.key,
                    primary: false
                  }]
                } else {
                  const optionSelected = rolesOpt.find(role => role.label === curr as unknown as string)
                  return [...acc, {
                    roleName: optionSelected?.label,
                    roleType: optionSelected?.key,
                    primary: false
                  }]
                }
              }, [])
              employee?.id && onReplaceEmployee(row?.id, { roles: [...newRoles] })
            }}
          />
        )
      },
      width: 150,
      hide: false
    },
    {
      id: 'approved',
      label: 'Enabled',
      custom: true,
      align: 'center' as AlignType,
      Element: (row: any): JSX.Element => {
        return (
          <Switch
            key={row.id}
            checked={row?.approved}
            onChange={() => { onEditEmployee(row.id, { approved: !row?.approved }) }}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        )
      },
      width: 60,
      hide: false
    },
    {
      id: 'status',
      label: 'Status',
      custom: true,
      align: 'center' as AlignType,
      Element: (row: any): JSX.Element => {
        const color = row?.approved ? '#17B975' : '#CA0000'
        const bgColor = row?.approved ? '#EDFDF6' : '#FFF4F4'
        return (
          <Box
            justifyContent='center' alignItems='center' borderRadius='8px'
            sx={{ width: '130px', borderColor: 'primary', backgroundColor: bgColor }}
          >
            <Typography color={color} margin='6px' sx={{ fontSize: '14px', backgroundColor: 'red' }}>
              {row?.approved ? 'ENABLED' : 'DISABLED'}
            </Typography>
          </Box>
        )
      },
      width: 130,
      hide: false
    },
    {
      id: 'view',
      label: 'Actions',
      custom: true,
      align: 'center' as AlignType,
      Element: (row: any): JSX.Element => {
        return (
          <Box display='flex' justifyContent='center' alignItems='center'>
            <Button
              variant='outlined'
              color='primary'
              startIcon={<icons.Edit height='9px' width='12px' />}
              sx={{ width: '80px', height: '40px', margin: '0px 0px' }}
              onClick={() => handleOpenEdit(row)}
            >
              Territories
            </Button>
            {
              employee.roles.some(role => role.roleType === EMPLOYEE_ROLE_TYPE.SUPER_ADMIN) &&
              <IconButton
                sx={{ margin: '0px 10px' }}
                onClick={() => {
                  const employee = employees?.find(employe => employe.id === row.id)
                  if (employee)
                    setSelEmployee(employee)
                  setOpenUpdate(true)
                }}
              >
                <icons.Edit height='9px' width='12px' />
              </IconButton>
            }
            <IconButton
              sx={{
                margin: '0px 0px'
              }}
              onClick={() => handleOpenDelete(row)}
            >
              <icons.Delete color='error' height='9px' width='12px' />
            </IconButton>

          </Box>
        )
      },
      width: 350,
      hide: false
    }
  ]

  const rolesOtions = [
    { key: 'all', label: 'All roles' },
    ...roles.find((role) => role.roleType === EMPLOYEE_ROLE_TYPE.SUPER_ADMIN) ?
      [
        { key: EMPLOYEE_ROLE_TYPE.ADMIN, label: handleTitle(EMPLOYEE_ROLE_TYPE.ADMIN) },
        { key: EMPLOYEE_ROLE_TYPE.SUPER_ADMIN, label: handleTitle(EMPLOYEE_ROLE_TYPE.SUPER_ADMIN) }
      ] : [],
    //{ key: EMPLOYEE_ROLE_TYPE.ADMIN, label: handleTitle(EMPLOYEE_ROLE_TYPE.ADMIN) },
    { key: EMPLOYEE_ROLE_TYPE.TERRITORY_BUSINESS_DEV_MANAGER, label: 'Business dev manager' },
    { key: EMPLOYEE_ROLE_TYPE.TERRITORY_CUSTOMER_CARE, label: 'Customer care' },
    { key: EMPLOYEE_ROLE_TYPE.TERRITORY_SUCCESS_MANAGER, label: 'Customer success manager' },
    { key: EMPLOYEE_ROLE_TYPE.TERRITORY_ESTIMATOR, label: 'Estimator' },
    { key: EMPLOYEE_ROLE_TYPE.TERRITORY_ESTIMATOR_MANAGER, label: 'Estimator manager' },
    //{ key: EMPLOYEE_ROLE_TYPE.FIELD_MANAGER, label: handleTitle(EMPLOYEE_ROLE_TYPE.FIELD_MANAGER) },
    { key: EMPLOYEE_ROLE_TYPE.MANAGER, label: handleTitle(EMPLOYEE_ROLE_TYPE.MANAGER) },
    //{ key: EMPLOYEE_ROLE_TYPE.SALES, label: handleTitle(EMPLOYEE_ROLE_TYPE.SALES) },
    //{ key: EMPLOYEE_ROLE_TYPE.SUCCESS_MANAGER, label: handleTitle(EMPLOYEE_ROLE_TYPE.SUCCESS_MANAGER) },
    //{ key: EMPLOYEE_ROLE_TYPE.SUPER_ADMIN, label: handleTitle(EMPLOYEE_ROLE_TYPE.SUPER_ADMIN) },
    { key: EMPLOYEE_ROLE_TYPE.TERRITORY_SUPERINTENDENT, label: 'Superintendent' },
    //{ key: EMPLOYEE_ROLE_TYPE.TERRITORY_AGENT, label: handleTitle(EMPLOYEE_ROLE_TYPE.TERRITORY_AGENT) },
    //{ key: EMPLOYEE_ROLE_TYPE.TERRITORY_LICENSE_PARTNER, label: handleTitle(EMPLOYEE_ROLE_TYPE.TERRITORY_LICENSE_PARTNER) },
    { key: EMPLOYEE_ROLE_TYPE.TERRITORY_MANAGER, label: handleTitle(EMPLOYEE_ROLE_TYPE.TERRITORY_MANAGER) },
    { key: EMPLOYEE_ROLE_TYPE.TERRITORY_SUPERVISOR, label: handleTitle(EMPLOYEE_ROLE_TYPE.TERRITORY_SUPERVISOR) },
    { key: EMPLOYEE_ROLE_TYPE.TERRITORY_VENDOR_MANAGER, label: 'Vendor manager' },
    //{ key: EMPLOYEE_ROLE_TYPE.TERRITORY_VENDOR, label: handleTitle(EMPLOYEE_ROLE_TYPE.TERRITORY_VENDOR) },
    { key: EMPLOYEE_ROLE_TYPE.CLOUDFACTORY, label: 'Cloudfactory' },
  ]
  const [role, setRole] = useState(rolesOtions[0])
  const rolesOpt = rolesOtions.slice(1)

  const getParams = () => {
    const searchParams = {} as Partial<SearchParams>
    if (territory && territory?.key !== 'all') { searchParams.territories = [territory.key] }
    if (status && status?.key !== 'all') { searchParams.approved = status.key === 'enabled' }
    if (role && role?.key !== 'all') { searchParams.role = role.key }
    if (administratorName) { searchParams.search = administratorName }
    return searchParams
  }

  const fetchServices = () => {
    setTableLoading(true)
    dispatch(territoriesActions.fetchTerritories((_succ: boolean) => {
      setTableLoading(false)
    }))
  }

  const fetchAllEmployees = (page?: number, size?: number, params?: SearchParams) => {
    setTableLoading(true)
    const searchParams = params ?? getParams()
    dispatch(territoriesActions.fetchTerritories((_succ: boolean) => { }))
    dispatch(
      employeesActions.fetchEmployees({ page, size, searchParams }, (_succ: boolean) => {
        setTableLoading(false)
      })
    )
  }

  const handleATerritoryChange = (newSelected: SelectInputOption) => {
    if (newSelected === null) {
      setTerritory(territoriesOtions[0])
    } else {
      setTerritory(newSelected)
    }
  }

  const handlStatusChange = (newSelected: SelectInputOption) => {
    if (newSelected === null) {
      setStatus(statusOtions[0])
    } else {
      setStatus(newSelected)
    }
  }

  const handleRoleChange = (newSelected: any) => {
    if (newSelected === null) {
      setRole(rolesOtions[0])
    } else {
      setRole(newSelected)
    }
  }

  useEffect(() => {
    fetchAllEmployees(0, PAGE_SIZE)
  }, [territory, role, status])

  useEffect(() => {
    fetchServices()
  }, [dispatch])

  const handleClose = () => {
    setOpen(false)
    setOpenDelete(false)
  }

  const handleCloseDelete = () => {
    setOpenDelete(false)
    if (selEmployee) {
      onDeleteEmployee(selEmployee.id, {})
    }
  }

  const handleCloseEdit = () => {
    setOpen(false)
    const options = territoriesOtions.slice(1)
    const newValues = [] as string[]
    selTerrValues.forEach((item: boolean, index: number) => {
      if (item) {
        newValues.push(options[index].key)
      }
    })
    if (selEmployee) {
      onReplaceEmployee(selEmployee.id, { territories: newValues })
    }
  }

  const debouncedSearch = debounce((params) => {
    fetchAllEmployees(0, PAGE_SIZE, params)
  }, 900);

  const handleChangeSearch = (search: string): void => {
    const params = { ...getParams, search };
    debouncedSearch(params);
  };

  const handleSearchAdministrator = (administratorName: string): void => {
    setAdministratorNameToSearch(administratorName);
    handleChangeSearch(administratorName);
  };

  const modalAction: ModalActionType[] = [
    {
      textButton: 'Cancel',
      variant: 'contained',
      onClick: () => { handleClose() }
    },
    {
      textButton: 'Update Territories',
      variant: 'contained',
      onClick: () => { handleCloseEdit() }
    }
  ]

  const modalActionDelete: ModalActionType[] = [
    {
      textButton: 'No, keep member',
      variant: 'outlined',
      onClick: () => { handleClose() }
    },
    {
      textButton: 'Yes, delete member',
      variant: 'contained',
      onClick: () => { handleCloseDelete() }
    }
  ]

  const handleOpenUpdate = (open: boolean) => {
    setOpenUpdate(open)
  }


  return (
    <Box>
      {/*    {openChangeRoleModal && <Modal
        open={openChangeRoleModal}
        setOpen={setOpenChangeRoleModal}
        onClose={() => setOpenChangeRoleModal(false)}
        showClose={true}
        title={""}
      >
        <Box gap={1} display='flex' flexDirection='column' justifyContent='center' alignItems='center' style={{ margin: '2rem 0' }}>
          <Typography variant='h3' margin='0px 0px 10px'>You’re going to change <Typography style={{ display: 'inline' }} variant='h3' fontWeight={700}> {employeeToEdit?.firstName} {employeeToEdit?.lastName} </Typography>'s role.</Typography>
          <Typography variant='h5'>Are you sure you want to change it to <Typography variant='h5' style={{ display: 'inline' }} fontWeight={700}>{employeeNewRole?.roleName}</Typography>? </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid
            item
            xs
            flexDirection="row"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <Button
              variant="contained"
              sx={{
                width: '100%',
                height: '40px',
                backgroundColor: '#FAFBFF',
                color: '#3A66FF',
                border: 0,
                '&:hover': {
                  color: 'white',
                },
              }}
              onClick={() => setOpenChangeRoleModal(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                width: '100%',
                height: '40px',
                backgroundColor: '#EB5151',
                border: 0,
                '&:hover': {
                  border: '0',
                  backgroundColor: '#EB5151',
                  color: 'white',
                },
              }}
              // disabled={true}
              //onClick={() => employeeNewRole && employeeToEdit?.id && onReplaceEmployee(employeeToEdit?.id, { roles: [...employeeToEdit.roles, employeeNewRole] })}
            >
              Confirm Change Role
            </Button>
          </Grid>
        </Grid>
      </Modal>} */}
      {selEmployee &&
        <UpdateUserModal open={openUpdate} setOpen={setOpenUpdate} employee={selEmployee} />
      }
      <Box display='flex' flexDirection='column' gap={2}>
        <Typography variant='h3' fontWeight='700'>Admin Panel</Typography>

        <Box display='flex' justifyContent='flex-end' gap={2}>
          <InputSearch
            label='Search for administrators:'
            value={administratorName}
            onChange={handleSearchAdministrator}
            sx={{ width: '100%' }}
            size="medium"
          />
          <SelectInput
            label='Role:'
            onChange={handleRoleChange}
            options={rolesOtions}
            renderOption={(props: any, option: SelectInputOption) => <Typography {...props} sx={{ textTransform: 'capitalize' }} variant='body1'>{option.label}</Typography>}
            value={role}
            sx={{ maxWidth: '240px', minWidth: '156px', flex: '1' }}
          />
          <SelectInput
            label='Status:'
            onChange={handlStatusChange}
            options={statusOtions}
            value={status}
            sx={{ maxWidth: '240px', minWidth: '156px', flex: '1' }}
          />
          <SelectInput
            label='Territory:'
            onChange={handleATerritoryChange}
            options={territoriesOtions}
            value={territory}
            sx={{ maxWidth: '240px', minWidth: '156px', flex: '1' }}
          />
        </Box>

        <Table
          headers={headers}
          loading={tableLoading}
          rowsPerPageDefault={PAGE_SIZE}
          rowsPerPageOptions={[PAGE_SIZE]}
          totalItems={totalEmployees}
          callToApi={(start, limit) => { fetchAllEmployees(start, limit) }}
          hasTopPagination
        >
          {employees ?? []}
        </Table>
      </Box>

      <Box
        sx={{
          position: 'absolute',
          bottom: '12px',
          right: '12px'
        }}
      >
        {open &&
          <Modal
            setOpen={setOpen}
            open={open}
            title=''
            actions={modalAction}
          >
            <Grid container spacing={1} justifyContent='center' alignItems='center'>
              <Grid item>
                <Typography variant='h4'>Edit Territories for: </Typography>
              </Grid>
              <Grid item marginBottom={2}>
                <Typography variant='h4' fontWeight='700'>{getPrimaryEmail()}</Typography>
              </Grid>
              <Grid container item spacing={1}>
                {selTerritories.map((terr: string, index: number) => {
                  return (
                    <Grid item xs={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selTerrValues[index]}
                            onChange={() => setSelTerrValues(selTerrValues.map((val, i) => i === index ? !val : val))}
                            name='remember'
                            color='infoText'
                          />
                        }
                        label={<Typography variant='body2'>{'' + terr}</Typography>}
                      />
                    </Grid>
                  )
                })}
              </Grid>
            </Grid>
          </Modal>}

        {openDelete &&
          <Modal
            setOpen={setOpenDelete}
            open={openDelete}
            title=''
            actions={modalActionDelete}
          >
            <Box gap={1} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
              <img src={computerPointer} alt='computer' style={{ height: '86px', width: '86px' }} />
              <Typography variant='h3' fontWeight='700' margin='0px 0px 10px'>You’re going to delete a member</Typography>
              <Typography variant='h4'>Are you sure you want to delete your member </Typography>
              <Typography variant='h4' fontWeight='700'>{`${getPrimaryEmail()} ?`}</Typography>
            </Box>
          </Modal>}
      </Box>
    </Box>
  )
}

export default Admin
