import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textBase: {
    color: theme.palette.background.default,
    fontSize: 32,
    [theme.breakpoints.down('md')]: {
      fontSize: 26
    },
    fontWeight: 400,
    margin: '12px 32px'
  },
  nouvelle: {
    fontFamily: 'nouvelle'
  },
  moranga: {
    fontWeight: 400,
    fontFamily: 'moranga'
  },
  bottomMarks: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0
  },
  image: {
    display: 'flex',
    height: '350px',
    width: '350px',
    objectFit: 'cover',
  }
}))
