import { FC, useEffect, useState } from 'react'
import { IconSwitchProps } from './types'
import { Button, Grid } from '@mui/material'

import useStyles from './styles'

const IconSwitch: FC<IconSwitchProps> = ({
  firstIcon,
  secondIcon,
  status,
  handleToggle,
}) => {
  const styles = useStyles()

  const [switchState, setSwitchState] = useState<boolean>(false)

  useEffect(() => {
    if (status) {
      setSwitchState(status)
    }
  }, [status])

  const handleSwitch = (value: boolean): void => {
    setSwitchState(value)
    if (handleToggle) handleToggle(value)
  }

  return (
    <Grid container item className={styles.container}>
      <Grid item>
        <Button
          className={switchState ? styles.selected : styles.unSelected}
          onClick={() => handleSwitch(true)}
        >
          {firstIcon}
        </Button>
      </Grid>
      <Grid item>
        <Button
          className={!switchState ? styles.selected : styles.unSelected}
          onClick={() => handleSwitch(false)}
        >
          {secondIcon}
        </Button>
      </Grid>
    </Grid>
  )
}

export default IconSwitch
