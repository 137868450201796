import React, { useReducer } from "react"
import { FC } from "react"
import { CompaniesReducer } from "./reducer"
import { CompaniesContextType } from "./types"

const initialState: CompaniesContextType = {
    // MODAL
    modalType: null,
    modalOpen: false,
}

export const CompaniesContext = React.createContext<{
    state: CompaniesContextType,
    dispatch: React.Dispatch<any>
}>({
    state: initialState,
    dispatch: () => null
})

export const CompaniesProvider: FC = ({ children }: any) => {
    const [state, dispatch] = useReducer(CompaniesReducer, initialState)
    return (
        <CompaniesContext.Provider value={{ state, dispatch }}>
            {children}
        </CompaniesContext.Provider>
    )
}