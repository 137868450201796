import { Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { HeaderType } from 'src/components/UI/CustomUI/organisms/Table/types'
import { getJob } from 'src/ducks/selectors'
import { formatTimestampTimeZone, getColorByJobStatus } from 'src/helpers'

const headers: HeaderType[] = [
    {
        id: 'createdOn',
        label: 'Date',
        custom: true,
        Element: (row: any, dispatch): JSX.Element => {
            const job = useSelector(getJob())
            const timeZone = job?.properties?.address?.timeZone ? job.properties.address.timeZone : ""

            return (
                <Typography variant="body1">{formatTimestampTimeZone(row.createdOn, 'MM/DD/YYYY HH:mm', timeZone)}</Typography>
            )
        }
    },
    {
        id: 'createdBy',
        label: 'Updated',
    },
    {
        id: 'description',
        label: 'Description',
    },
    {
        id: 'newStatus',
        label: 'New Status',
        custom: true,
        Element: (row: any): JSX.Element => {
            return <Typography variant='body1Bold' textTransform="uppercase" color={getColorByJobStatus(row.newStatus).color}>{row.newStatus} </Typography>
        },
    },
    {
        id: 'ageInDays',
        label: 'Age',
        custom: true,
        Element: (row: any): JSX.Element => {
            return <Typography>{row.ageInDays} days </Typography>
        },
    },
]

export default headers
