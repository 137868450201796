import storeInfo from '../store'
import { Searches, SearchParams } from './types'

export const getSearches = () => (): Searches => {
  const { searches } = storeInfo.store.getState()
  return searches
}

export const getSearchParamValues = (): SearchParams => {
  const { searches: { searchParams } } = storeInfo.store.getState()
  return searchParams
}