import { RepairListItem } from '../types'
import { SearchParams } from '../searches/types';

/** TYPES **/
export const repairlistTypes = {
  FETCH_REPAIRLIST: 'FETCH_REPAIRLIST',
  FETCH_CSV_REPAIRLIST: 'ETCH_CSV_REPAIRLIST',
  SET_REPAIRLIST: 'SET_REPAIRLIST',
  SET_CSV_REPAIRLIST: 'SET_CSV_REPAIRLIST',
}

/** ACTIONS **/
export interface FetchRepairlistAction {
  type: typeof repairlistTypes.FETCH_REPAIRLIST
  payload?: {
    page?: number
    size?: number
    searchParams?: Partial<SearchParams>
  }
  callback?: (succ: boolean) => void
}

export interface FetchCsvRepairlistAction {
  type: typeof repairlistTypes.FETCH_CSV_REPAIRLIST
  payload?: {
    searchParams?: Partial<SearchParams>
  }
  callback?: (succ: boolean) => void
}

export interface SetRepairlistAction {
  type: typeof repairlistTypes.SET_REPAIRLIST
  payload: RepairListType
}

export interface SetCsvRepairlistAction {
  type: typeof repairlistTypes.SET_CSV_REPAIRLIST
  payload: RepairListItem[]
}

export type RepairListActions =
  | FetchRepairlistAction
  | FetchCsvRepairlistAction
  | SetRepairlistAction
  | SetCsvRepairlistAction

/** REDUCER **/
export interface RepairListType {
  items: RepairListItem[]
  csvItems: RepairListItem[]
  limit: number
  start: number
  total: number
}