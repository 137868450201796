import { FC, useState, useContext } from 'react'
import { Grid, Typography, Button } from '@mui/material'
import { TextFieldLabel } from 'src/components/UI'
import { icons } from '../../../../../assets'
import { isEmpty } from 'lodash'
import { validateEmail, CONTACT_ROLE, PHONE_MASK_INPUT, PHONE_MASK_REGEXP } from 'src/helpers'
import { jobActions } from 'src/ducks/actions'
import { Contact } from 'src/ducks/types'
import { PreviewInvoiceContext } from '../context'
import { previewInvoiceTypes } from "../context/types"
import { useDispatch,useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { getJob,getTerritory } from 'src/ducks/selectors'

export interface AddPayerContactParam {
    onReload: () => void
}

const newContactDefault: Contact = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    otherRole: '',
    role: CONTACT_ROLE.OTHER,
    approvalType: null,
    approvedOn: null,
    isScheduler: false,
    isRequester: false,
    fullName: '',
    sendEstimate: false,
    isPayer: true
}


const AddPayerContact: FC<AddPayerContactParam> = ({ onReload }) => {
    const [newContact, setNewContact] = useState(newContactDefault)
    const { firstName, lastName, email, phone } = newContact
    const [showWarnings, setShowWarnings] = useState(false)
    const { dispatch: dispatchContext } = useContext(PreviewInvoiceContext)
    const dispatch = useDispatch()
    const territory = useSelector(getTerritory)
    const job = useSelector(getJob())

    const from = `${territory?.emailName} ${territory?.email}`
    const subject = job?.properties?.address?.fullAddress ? `BOSSCAT Invoice for ${job?.properties?.address?.fullAddress}` : 'BOSSCAT Invoice'
    
    const validate =
        !isEmpty(firstName) &&
        !isEmpty(lastName) &&
        !isEmpty(email) &&
        validateEmail(email) &&
        ((PHONE_MASK_REGEXP.test(phone) && !isEmpty(phone)) || isEmpty(phone))

    const handleAdd = () => {

        dispatchContext({ type: previewInvoiceTypes.SET_VALUE, payload: { attr: 'pageLoading', value: true } })
        dispatch(
            jobActions.createJobContact(
                newContact,
                (succ: boolean) => {
                    dispatchContext({ type: previewInvoiceTypes.SET_VALUE, payload: { attr: 'pageLoading', value: false } })
                    if (succ) {
                        toast.success.call('success', 'Job Payer Contact created successfully')
                        onReload()
                    }
                })
        )
    }

    return (
        <>
            <Grid container item spacing={1} direction='column' marginBottom={4} paddingLeft={1}>

                <Grid item padding={0}>
                    <Typography variant='h5Bold'>Sending Info</Typography>
                </Grid>

            </Grid>

            <Grid container item direction='column' paddingLeft={1} marginBottom={4} spacing={2}>

                <Grid item container spacing={2}  >
                    <Grid item container p={"8px 16px 20px 24px"} direction='column' spacing={2} sx={{ backgroundColor: "#FFFBF0" }}>
                        <Grid item padding={0} spacing={2} >
                            <icons.Warning style={{ padding: 0, width: '12px', height: '12px', color: "#AD7D00" }} />
                            <Typography variant='h5Bold' sx={{ color: "#AD7D00", fontFamily: "nouvelle" }}>Add Payer’s Info</Typography>
                        </Grid>
                        <Grid item padding={0}>
                            <Typography variant='h5Bold' sx={{ fontFamily: "nouvelle" }}>        Add Payer info to send the invoice
                            </Typography>
                        </Grid>

                        <Grid item container direction='row' spacing={4} display={"flex"}>
                            <Grid item xs={6}>
                                <TextFieldLabel
                                    label='Payer First Name:'
                                    placeholder='Enter payer’s first name'
                                    value={firstName}
                                    onChange={(e) => { setNewContact({ ...newContact, firstName: e.target.value }) }}
                                    error={showWarnings && isEmpty(firstName)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldLabel
                                    label='Payer Last Name:'
                                    placeholder='Enter payer’s last name'
                                    value={lastName}
                                    onChange={(e) => { setNewContact({ ...newContact, lastName: e.target.value }) }}
                                    error={showWarnings && isEmpty(lastName)}
                                />
                            </Grid>
                        </Grid>

                        <Grid item>
                            <TextFieldLabel
                                label='Payer Email: '
                                type='email'
                                placeholder='Enter payer’s email'
                                value={email}
                                onChange={(e) => { setNewContact({ ...newContact, email: e.target.value }) }}
                                error={showWarnings && (isEmpty(email) || !validateEmail(email))}
                            />
                        </Grid>
                        <Grid item>
                            <TextFieldLabel
                                label='Payer Mobile Phone Number: '
                                type='phone'
                                placeholder='555-555-5555'
                                value={phone}
                                mask={PHONE_MASK_INPUT}
                                error={showWarnings && !isEmpty(phone) && !PHONE_MASK_REGEXP.test(phone)}
                                onChange={(e) => { setNewContact({ ...newContact, phone: e.target.value }) }}
                            />
                        </Grid>

                        <Grid item>
                            <Button
                                variant='contained'
                                sx={{
                                    backgroundColor: "#AD7D00", border: 2,borderColor:"#AD7D00",
                                    '&:hover': {
                                        color: '#AD7D00',
                                        backgroundColor: "white",
                                        border: 2,
                                        borderColor: '#AD7D00'
                                    }
                                }}
                                
                                onClick={() => {
                                    if (validate) {
                                        handleAdd()
                                    } else {
                                        setShowWarnings(true)
                                    }
                                }}

                                disabled={!validate}
                            >
                                Add Payer Contact
                            </Button>
                        </Grid>

                    </Grid>

                    <Grid item container direction='column' spacing={2}>
                        <Grid item>
                            <TextFieldLabel
                                label='From Territory:'
                                value={from}
                                disabled
                            />
                        </Grid>
                        <Grid item>
                            <TextFieldLabel
                                label='Recipient (Payer): '
                                placeholder='Joe Smith <joe.smith@fake.com>'
                                disabled
                            />
                        </Grid>
                        <Grid item>
                            <TextFieldLabel
                                label='CC Emails: '
                                type='email'
                                disabled
                                placeholder='Separate email addresses by comma'
                            />
                        </Grid>
                        <Grid item>
                            <TextFieldLabel
                                label='Subject:'
                                value={subject}
                                disabled
                            />
                        </Grid>
                    </Grid>

                </Grid>


            </Grid>
        </>

    )
}

export default AddPayerContact