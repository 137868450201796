import { FC, useState } from 'react'
import {
  Box,
  Button,
  Popover,
  FormControlLabel,
  Checkbox,
  Grid,
  Typography,
  TitleFirst,
} from 'src/components/UI'
import useStyles from './styles'
import { icons } from 'src/assets'
import { FilterOptions, FieldsFilterProps } from './types'
import { colors } from 'src/components/UI/MaterialUI/themeExtension'
import theme from 'src/components/UI/MaterialUI/theme'
import { useClassName } from 'src/helpers'
import {
  InputAdornment,
  SxProps,
  TextField,
  Theme,
} from 'src/components/UI/MaterialUI'
import SearchIcon from '@mui/icons-material/Search'

const OptionsFieldsFilter: FC<FilterOptions> = ({
  handleClose,
  handleChange,
  options,
  flexColumn,
  popupLabel = 'Select columns to view',
  maxWidth,
  singleSelect,
  popoverItemSx,
  searchable
}) => {
  return (
    <Grid container columns={4} maxWidth={maxWidth}>
      <Grid item xs={4}>
        <TitleFirst
          display={singleSelect ? 'none' : 'flex'}
          justifyContent="flex-start"
          black={popupLabel}
          blackVariant="h6"
          sx={{ marginBottom: '8px' }}
        />
      </Grid>
      {options.map(({ id, label, hide, isSeparator }, index) =>
        isSeparator ? (
          <Box
            key={`${id} ${index}`}
            width="100%"
            marginY="2px"
            height="2px"
            sx={{ backgroundColor: theme.palette.grey[200] }}
          />
        ) : (
          <Grid item xs={flexColumn ? 4 : 2} key={`${id} ${index}`}>
            {!singleSelect ? (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!hide}
                    color="infoText"
                    onChange={() => handleChange(index, id)}
                    sx={{
                      padding: '4px 9px',
                      paddingLeft: `${searchable ? '0' : '9px'}`
                    }}
                    
                  />
                }
                label={label || id || `${index}`}
              />
            ) : (
              <Typography
                onClick={() => {
                  handleChange(index, id)
                  handleClose()
                }}
                sx={{
                  ...popoverItemSx,
                  fontWeight: `${index === 0 ? 600 : 400}`,
                }}
              >
                {label}
              </Typography>
            )}
          </Grid>
        )
      )}
      {!singleSelect && (
        <Grid container justifyContent="flex-end">
          <Button onClick={handleClose} color="infoText">
            <Typography
              fontFamily="var(--font-medium)"
              color="var(--blue700)"
              fontWeight="500"
            >
              DONE
            </Typography>
          </Button>
        </Grid>
      )}
    </Grid>
  )
}

const FieldsFilter: FC<FieldsFilterProps> = ({
  flexColumn,
  handleChange,
  handleSelectionDone,
  inputText,
  label,
  options,
  renderAsInput,
  popupLabel,
  popoverSx,
  sx,
  maxWidth,
  variant = 'h5',
  icon,
  contentAlign = 'right',
  singleSelect = false,
  popoverItemSx,
  searchable = false,
  searchFieldPlaceholder = '',
  allSelection = false
}) => {
  const classes = useStyles()
  const className = useClassName()
  const [anchorEl, setAnchorEl] = useState<
    HTMLButtonElement | HTMLDivElement | null
  >(null)

  const [searchStr, setSearchStr] = useState<string>('')

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>
  ) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    if (handleSelectionDone) handleSelectionDone()
    setSearchStr('')
  }

  const selectedOptionLabels = () => {
    var selected = options.filter((opt) => !opt.hide && opt.label)

    if (!options[0]?.hide && allSelection) {
      return options[0]?.label
    }

    if (selected.length > 0) {
      return selected.map((opt) => opt.label).join(', ')
    }

    return null
  }

  const open = Boolean(anchorEl)
  const id = open ? 'table-fields-showed-filter' : undefined

  const boxStyles = [{
    cursor: 'pointer',
    '&:hover': { borderColor: colors.infoColor.main },
  },
  ...(Array.isArray(sx) ? sx : [sx]),
  ]
  const popoverStyles = { ...popoverSx, marginTop: '1rem' }
  const padding = variant === 'body1' ? '8px' : '4px'

  return (
    <Box>
      {label && label !== '' && (
        <TitleFirst
          black={label}
          blackVariant="h6"
          lineHeight="22px"
          justifyContent="flex-start"
        />
      )}
      {renderAsInput ? (
        <Box
          aria-describedby={id}
          alignItems="center"
          display="flex"
          boxShadow="0px 1px 1px rgba(26, 42, 85, 0.24), 0px 0px 1px rgba(26, 42, 85, 0.31)"
          justifyContent="space-between"
          className={classes.input}
          onClick={handleClick}
          sx={boxStyles as any}
        >
          <Typography
            variant={variant}
            padding={padding}
            sx={{ color: 'currentColor', width: '100%' }}
            className={classes.inputText}
          >
            {singleSelect ? inputText : selectedOptionLabels() || inputText}
          </Typography>
          {!icon ? (
            <icons.FilterAlt sx={{ color: theme.palette.grey[400] }} />
          ) : (
            icon
          )}
        </Box>
      ) : (
        <Button
          aria-describedby={id}
          className={classes.button}
          onClick={handleClick}
          /*  sx={{ ...sx }} */
          sx={[...(Array.isArray(sx) ? sx : [sx]),]}
          style={{
            backgroundColor: '#F9FAFA',
            color: open ? colors.blue[700] : '#72748D',
          }}
        >
          {!icon ? <icons.FilterAlt fontSize="medium" /> : icon}
        </Button>
      )}
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: contentAlign,
        }}
        classes={{
          paper: className(
            classes.optionsContianer,
            flexColumn && !singleSelect ? classes.verticalRender : '',
            searchable ? classes.searchWidth : ''
          ),
        }}
        id={id}
        onClose={handleClose}
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: contentAlign,
        }}
        sx={popoverStyles as SxProps<Theme>}
      >
        {searchable && (
          <Box display="flex" flexDirection="column" gap={1}>
            <TextField
              className={classes.input}
              value={searchStr}
              onChange={(e) => setSearchStr(e.target.value)}
              placeholder={searchFieldPlaceholder}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon style={{ width: '16px', height: '16px' }} />
                  </InputAdornment>
                ),
              }}
            />
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="containedLight"
                style={{
                  padding: '0',
                  width: 'fit-content',
                  backgroundColor: 'white',
                  border: 0,
                }}
                onClick={() => handleChange(-1, '')} // unselect all if index is -1
              >
                Unselect All
              </Button>
            </Box>
            <Box
              width="100%"
              height="2px"
              sx={{ backgroundColor: '#EBECEF' }}
            />
          </Box>
        )}
        <Box className={searchable ? classes.searchRender : ''}>
          {OptionsFieldsFilter({
            flexColumn,
            handleChange,
            handleClose,
            options: searchable
              ? options.filter((option) =>
                  (option.label as string)
                    .toLowerCase()
                    .includes(searchStr.toLowerCase())
                )
              : options,
            popupLabel,
            maxWidth,
            singleSelect,
            popoverItemSx,
            searchable
          })}
        </Box>
      </Popover>
    </Box>
  )
}

export default FieldsFilter
