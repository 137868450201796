import { useSelector } from 'react-redux'
import { LabeledText } from 'src/components/UI'
import { getJob } from 'src/ducks/selectors'
import { formatTimestamp, formatTimestampUTC } from 'src/helpers'
import { ContentBox, Divider, TabBox } from '../../Templates'
import { useIsLgScreen, useIsXlScreen } from 'src/hooks'

const Approval = () => {
  const job = useSelector(getJob())
  const homeOwner = job?.properties?.contacts?.find(contact => contact?.role === 'HOMEOWNER')
  const payer = job?.properties?.contacts?.find(contact => contact?.isPayer)
  const name = job?.properties.contacts.find(c => c.isInitialAuthorizer)
  const [xlScreen] = useIsXlScreen()
  const [lgScreen] = useIsLgScreen()

  return (
    <TabBox>
      <ContentBox gridTemplateColumns={xlScreen || lgScreen ? '1fr 1fr' : '1fr'}>
        <LabeledText labelValue={homeOwner?.fullName || ''} textValue={homeOwner ? '(Homeowner)' : ''} />
        <LabeledText labelValue={payer?.fullName || ''} textValue={payer ? '(Payer)' : ''} />
        <LabeledText labelValue='' textValue={homeOwner?.email || ''} />
        <LabeledText labelValue='' textValue={payer?.email || ''} />
        <LabeledText labelValue='' textValue={homeOwner?.phone} />
        <LabeledText labelValue='' textValue={payer?.phone} />
      </ContentBox>
      <Divider />
      <ContentBox gridTemplateColumns='1fr 1fr'>
        <LabeledText labelValue='Closing Date:' textValue={formatTimestampUTC(job?.properties?.closingDate, 'MM/DD/YYYY')} />
        <LabeledText labelValue='Preferred Date:' textValue={formatTimestamp(job?.properties?.preferredCompletedOn, 'MM/DD/YYYY')} />
      </ContentBox>
      <Divider />
      <ContentBox>
        <LabeledText labelValue='Approval Signature:' textValue={job?.properties.contacts.find(c => c.isInitialAuthorizer)?.firstName + ' ' + job?.properties.contacts.find(c => c.isInitialAuthorizer)?.lastName} />
        <LabeledText labelValue='Access Code:' textValue={job?.properties?.accessDetails} />
        <LabeledText labelValue='Notes:' textValue={job?.properties?.clientNotes} />
      </ContentBox>
    </TabBox>
  )
}

export default Approval
