import { makeStyles } from '@mui/styles'
import { Theme } from 'src/components/UI'

export default makeStyles((theme: Theme) => ({
    container: {
        [theme.breakpoints.down('lg')]: {
            minWidth: '100% !important',
        }
    },
    loading: {
        position: 'absolute',
        right: '5px',
        top: '5px'
    }
}))
