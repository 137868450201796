import { authTypes } from "../auth/types"
import { EstimateItem } from "../types"
import { EstimateActions, EstimateType, estimateTypes } from "./types"

const initialState: EstimateType = null

const reducer = (state = initialState, action: EstimateActions): EstimateType => {
    const { type, payload } = action

    switch (type) {
        case authTypes.LOGIN_REQUEST: { return initialState }
        case authTypes.LOGOUT_REQUEST: { return initialState }

        case estimateTypes.SET_ESTIMATE: {
            return payload as EstimateItem
        }

        default:
            return state
    }
}

export default reducer