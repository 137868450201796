import { authTypes } from '../auth/types'
import { SiteVisitActions, SiteVisitType, siteVisitTypes } from './types'

const initialState: SiteVisitType = null

const reducer = (state = initialState, action: SiteVisitActions): SiteVisitType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case siteVisitTypes.SET_SITE_VISIT: {
      return payload as SiteVisitType
    }

    default:
      return state
  }
}

export default reducer
