import { authTypes } from '../auth/types'
import { MaterialActions, materialTypes, MaterialType } from './types'

const initialState: MaterialType = {} as MaterialType

const reducer = (
  state = initialState,
  action: MaterialActions
): MaterialType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: {
      return initialState
    }
    case authTypes.LOGOUT_REQUEST: {
      return initialState
    }

    case materialTypes.SET_MATERIAL: {
      return {
        ...state,
        ...payload as MaterialType
      }
    }

    default:
      return state
  }
}

export default reducer
