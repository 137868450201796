import { Box, Paper, Typography, IconButton, Tooltip } from '@mui/material';
import { Delete, Visibility, TextSnippet } from '@mui/icons-material';
import FileBoxProps from './types';
import styles from './styles.module.scss'
const FileBox = ({ name, onDeleteClick, onViewClick }: FileBoxProps) => {
    const isLongText = name?.length > 40;
    return (
        <Box className={styles.container}>
            <Paper className={styles.paper}>
                <Box className={styles.info}>
                    <Box className={styles.infoLeft}>
                        <TextSnippet className={styles.icon} />
                        <Tooltip title={isLongText ? name : ''}>
                            <Typography variant="body1">
                                {isLongText ? `${name?.slice(0, 40)}...` : name || "File"}
                            </Typography>
                        </Tooltip>
                    </Box>
                    <Box display="flex" alignItems="center">
                        <IconButton onClick={onViewClick}>
                            <Visibility />
                        </IconButton>
                        <Box mx={1} />
                        <IconButton onClick={onDeleteClick} className={styles.deleteBtn}>
                            <Delete color='error' />
                        </IconButton>
                    </Box>
                </Box>
            </Paper>
        </Box>

    );
};

export default FileBox;
