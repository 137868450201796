import { authTypes } from '../auth/types'
import { Count, Estimate, EstimateItem } from '../types'
import { EstimatesActions, EstimatesType, estimatesTypes } from './types'

const initialState: Estimate = {
  start: '',
  limit: '',
  total: '',
  totalValue: '',
  dueToday: '',
  items: [],
  relatedItems: [],
  counts: [],
  //jobsCSV: '',
}

const reducer = (state = initialState, action: EstimatesActions): EstimatesType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case estimatesTypes.SET_ESTIMATES: {
      return {
        ...state,
        start: (payload as Estimate).start,
        limit: (payload as Estimate).limit,
        items: (payload as Estimate).items,
        total: (payload as Estimate).total,
        totalValue: (payload as Estimate).totalValue,
        dueToday: (payload as Estimate).dueToday,
      }
    }

    case estimatesTypes.SET_RELATED_ESTIMATES: {
      return {
        ...state,
        relatedItems: payload as EstimateItem[]
      }
    }

    case estimatesTypes.SET_ESTIMATES_COUNTS: {
      return {
        ...state,
        counts: payload as Count[]
      }
    }
    /* 
       case jobsTypes.SET_JOBS_CSV: {
         return {
           ...state,
           jobsCSV: payload as string
         }
       } */

    default:
      return state
  }
}

export default reducer
