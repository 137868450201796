/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Typography, Box, OutlinedInput, Button } from 'src/components/UI'
import { Checkbox, CircularProgress, FormControlLabel } from '@mui/material'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import theme from 'src/components/UI/MaterialUI/theme'
import { DispatchPriceItem, DispatchPricingModel, OrderType } from 'src/ducks/orders/types'
import { round, isEmpty, PRICING_ITEM_TYPE } from 'src/helpers'
import { icons } from 'src/assets'
import { orderActions, ordersActions } from 'src/ducks/actions'
import { getOrder } from 'src/ducks/selectors'

const Draw: FC<{ position: number, item: DispatchPriceItem }> = ({ position, item }) => {
  const dispatch = useDispatch()
  const jobOrder = useSelector(getOrder())

  const { pricingModel, orderId, estimateId, dispatchedTimestamp } = jobOrder || {} as OrderType
  const { items } = pricingModel
  const { name, amount, description, paid, id } = item
  const orderAlreadySaved = !!id
  const orderDispatched = dispatchedTimestamp !== null
  const enableDraw = orderDispatched && !paid
  const buttonDraw = orderDispatched && !orderAlreadySaved ? 'Save and Pay Now' : 'Pay Now'
  const buttonWidth = orderDispatched && !orderAlreadySaved ? '140px' : '100px'

  const [open, setOpen] = useState(false)
  const [paying, setPaying] = useState(false)
  const [showWarnings, setShowWarnings] = useState(false)

  const validate = !isEmpty(name) && amount

  const calculatePricing = (attrs: Partial<DispatchPricingModel>) => {
    if (orderId) {
      dispatch(orderActions.getPricing({ orderId, pricingModel: { ...pricingModel, ...attrs } }))
    }
  }

  const setPricing = (attr: string, value: number) => {
    const newValue = value || 0
    setValue(attr, newValue)
    calculatePricing({
      ...pricingModel,
      items: items.map((item, index) => {
        if (index === position) {
          return { ...item, [attr]: newValue }
        }
        return item
      })
    })
  }

  const setValue = (attr: string, value: any) => {
    dispatch(orderActions.setOrder({
      ...jobOrder,
      pricingModel: {
        ...pricingModel,
        items: items.map((item, index) => {
          if (index === position) {
            return { ...item, [attr]: value }
          }
          return item
        })
      }
    }))
  }

  useEffect(() => {
    if (name === undefined) {
      setOpen(true)
      setValue('name', 'Draw')
    }
  }, [name])

  const deleteItem = (event: React.SyntheticEvent<EventTarget>) => {
    event.stopPropagation()
    calculatePricing({ ...pricingModel, items: items.filter((_item, index) => index !== position) })
  }

  const createBill = (newId = null) => {
    if (validate) {
      setPaying(true)
      setShowWarnings(false)
      dispatch(ordersActions.createBill({
        jobId: estimateId,
        orderId: orderId as string,
        orderType: 'ORDER',
        pricingItemId: newId ?? id,
        amount
      }, (succ) => {
        if (succ) {
          dispatch(orderActions.getOrder(orderId as string,
            (succ) => { setPaying(false) }))
        } else setPaying(false)
      }))
    } else {
      setOpen(true)
      setShowWarnings(true)
    }
  }

  const addOrderDrawForPayNow = () => {
    if (validate) {
      setPaying(true)
      setShowWarnings(false)
      dispatch(orderActions.addDrawForPayNowAction({
        orderId: orderId as string,
        item: { name, amount, description, type: PRICING_ITEM_TYPE.DRAW }
      }, (newItem) => {
        if (newItem) {
          createBill(newItem.id)
        } else setPaying(false)
      }))
    } else {
      setOpen(true)
      setShowWarnings(true)
    }
  }

  const payNow = () => {
    if (id) createBill()
    else addOrderDrawForPayNow()
  }

  return (
    <>
      <Grid item xs={8}>
        <FormControlLabel
          control={
            <Checkbox
              checkedIcon={<icons.IndeterminateCheckBoxSharp />}
              icon={<icons.AddBoxSharp />}
              sx={{ color: 'var(--blue700)', '&.Mui-checked': { color: 'var(--blue700)' } }}
              onChange={() => setOpen(!open)}
            />
          }
          checked={open}
          label={(
            <Box display='flex' gap={2}>
              <Typography variant='body2'>{name}</Typography>
              {!paid
                ? <Box onClick={deleteItem}><Icon name='DeleteOutlined' width={16} color='var(--error-color)' background='var(--gray100)' /></Box>
                : <Typography variant='body2' sx={{ color: theme.palette.green[700] }}>PAID</Typography>}
            </Box>
          )}
        />
        <Grid container spacing={1} sx={{ paddingLeft: '24px' }}>
          {open && (
            !paid
              ? (
                <>
                  <Grid item xs={8}>
                    <OutlinedInput
                      style={showWarnings && isEmpty(name) ? { borderColor: theme.palette.error.main } : {}}
                      size='small' fullWidth placeholder='Provide a label'
                      value={name} onChange={(event) => setValue('name', event.target.value)}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <OutlinedInput
                      style={showWarnings && !amount ? { borderColor: theme.palette.error.main } : {}}
                      size='small' fullWidth placeholder='$0.00'
                      value={amount || ''} onChange={(event) => setPricing('amount', parseInt(event.target.value, 10))}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <OutlinedInput
                      size='small' fullWidth placeholder='Provide Description'
                      multiline rows={2}
                      value={description} onChange={(event) => setValue('description', event.target.value)}
                    />
                  </Grid>
                </>
              )
              : (
                <Typography
                  style={{ color: 'var(--gray700)' }}
                  paddingTop={1} paddingLeft={1.5} variant='body2'
                >
                  {description}
                </Typography>
              )
          )}
          {!paid && (
            <Grid item xs={5}>
              <Button
                onClick={() => payNow()}
                variant='contained'
                size='small'
                sx={{
                  width: buttonWidth,
                  lineHeight: 1,
                  backgroundColor: theme.palette.green[700],
                  borderColor: theme.palette.green[700],
                  '&:hover': {
                    backgroundColor: theme.palette.green[700],
                    borderColor: theme.palette.green[700]
                  }
                }}
                disabled={!enableDraw}
              >
                {paying ? <CircularProgress color='info' size='0.8rem' /> : buttonDraw}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={4} textAlign='end'>
        <Typography variant='body1' paddingTop='10px'>
          - ${round(amount, 2)}
        </Typography>
      </Grid>
    </>
  )
}

export default Draw
