import React, { useMemo, useState } from 'react'
import Modal from 'src/components/UI/CustomUI/molecules/Modal'
import useStyles from './styles'
import ContactList from './ContactList'
import Toast from './Toast'
import SendIcon from '@mui/icons-material/Send'
import { ModalRequestChangeOrderApprovalProps } from './types'
import { Grid } from '../../../../../../UI'
import { useDispatch, useSelector } from 'react-redux'
import { getJob } from 'src/ducks/selectors'
import { Button, Typography, Box } from '@mui/material'
import { toast } from 'react-toastify'
import { jobActions } from 'src/ducks/actions'
import { ChangeOrderAttribute } from '~api/types'

const ModalRequestChangeOrderApproval = ({
  open,
  setOpen,
  onClose = () => {},
}: ModalRequestChangeOrderApprovalProps) => {
  const classes = useStyles()

  const dispatch = useDispatch()

  const job = useSelector(getJob())

  const contacts = useMemo(() => job?.properties.contacts ?? [], [job])

  const [selectedList, setSelectedList] = useState<boolean[]>(
    new Array(contacts.length).fill(false)
  )

  const selectedNum = useMemo(
    (): number =>
      selectedList.reduce(
        (prev, selected) => (selected === true ? prev + 1 : prev),
        0
      ),
    [selectedList]
  )

  const handleSend = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault()

    dispatch(
      jobActions.changeOrderRequestApproval(
        contacts
          .filter((contact, index) => selectedList[index])
          .map(
            ({ id, firstName, lastName, email, phone, fullName }) =>
              ({
                id,
                firstName,
                lastName,
                email,
                phone,
                fullName,
              } as ChangeOrderAttribute)
          )
          ,
        (succ: boolean) => {
          if (succ) {
            toast.success.call('success', <Toast contactNum={selectedNum} />)
          }
        }
      )
    )

    setOpen(false)
  }

  return (
    <Modal setOpen={setOpen} open={open}>
      <Grid
        container
        sx={{ padding: '12px 0' }}
        flexDirection={'column'}
        spacing={1}
      >
        <Grid item>
          <Typography className={classes.title}>
            Request Change Order Approval
          </Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.subtitle}>
            Selected contacts will receive an email with a link to the Estimate.
          </Typography>
        </Grid>
        <Grid item container flexDirection="column" columnSpacing={0}>
          <Typography className={classes.contact}>Contacts</Typography>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            className={classes.contact}
          >
            <Typography>{`${selectedNum} contact(s) selected`}</Typography>
            <Button
              onClick={() =>
                setSelectedList(new Array(selectedList.length).fill(true))
              }
              sx={{ color: '#3A66FF', padding: 0 }}
            >
              Select All
            </Button>
          </Box>
        </Grid>
        <Grid item padding={0}>
          <ContactList
            contacts={contacts}
            selectedList={selectedList}
            setSelectedList={setSelectedList}
          />
        </Grid>
      </Grid>
      <Grid container marginTop={1} justifyContent="space-between">
        <Button
          className={classes.cancelButton}
          onClick={() => setOpen(false)}
          sx={{ color: '#3A66FF' }}
        >
          Cancel
        </Button>
        <Button
          className={classes.sendButton}
          variant="contained"
          disabled={selectedNum === 0}
          endIcon={<SendIcon />}
          onClick={handleSend}
        >
          Send Request
        </Button>
      </Grid>
    </Modal>
  )
}

export default ModalRequestChangeOrderApproval
