import React, { useEffect, useState } from 'react'

import MaterialTable from '@mui/material/Table'

import { Order, TableProps } from './types'
import TableHead from './TableHead'
import TableBody from './TableBody'
import { Box, CircularProgress, Grid, Typography } from '@mui/material'

import styles from './styles.module.scss'
import TablePagination from './TablePagination'
import { ROWS_PER_PAGE_SIMPLE } from 'src/helpers'

const Table = <T, U>({
  id = 'table',
  headers,
  actions,
  small = false,
  actionTitle,
  title,
  defaultOrder = 'asc',
  defaultOrderBy,
  defaultPage = 0,
  className,
  sortable,
  rowAction,
  loading,
  totalItems,
  rowsPerPageDefault,
  rowsPerPageOptions = ROWS_PER_PAGE_SIMPLE,
  callToApi,
  hasPagination = true,
  hasTopPagination = false,
  hasBorders = true,
  children,
  onRightClickRow,
  rowColor,
  hasScroll = false,
  emptyState,
  fullWidth,
  selectedRow,
  stickyHeader = false,
  stickyPagination = false,
  highlightedRows,
}: TableProps<T, U>) => {
  const [order, setOrder] = useState<Order>(defaultOrder)
  const [orderBy, setOrderBy] = useState<keyof T | undefined>(children?.length ? defaultOrderBy ??
    Object.keys(children ? children[0] : 'id' as any)[0] as keyof T
    : undefined
  )
  const [childrenOrder, setChildrenOrder] = useState<T[] | undefined>(children)

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof T
  ) => {
    sortChildren(property)
    setOrder(prevOrder => prevOrder === "asc" ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const sortChildren = (sortBy: keyof T) => {
    if (sortBy && children && children.length) {
      if (order === "asc") {
        const newOrder = ([] as T[]).concat(children).sort((a, b) => {
          let aVal = a[sortBy];
          let bVal = b[sortBy];
          if (sortBy === "approvedOn") {
            aVal = a["createdOn" as keyof T]
            bVal = b["createdOn" as keyof T]
          }
          if (sortBy === "flags") {
            aVal = (a["flags" as keyof T] as any)?.[0].flag
            bVal = (b["flags" as keyof T] as any)?.[0].flag
          }
          if (sortBy === "closingDate") {
            aVal = (a["properties" as keyof T] as any).closingDate
            bVal = (b["properties" as keyof T] as any).closingDate
          }
          if (sortBy === "totalContract" || sortBy === "totalOriginalContractAmount" || sortBy === "totalChangeOrderAmount" || sortBy === "amountComplete" || sortBy === "contractAmountPending" || sortBy === "changeOrderPendingApproval" || sortBy === "proPrice" || sortBy === "customerPrice") {
            aVal = aVal && (aVal as any).includes("$") ? parseFloat((aVal as any).replace(/[^0-9\.]+/g, "")) as any : aVal;
            bVal = bVal && (bVal as any).includes("$") ? parseFloat((bVal as any).replace(/[^0-9\.]+/g, "")) as any : bVal;
          }

          if (aVal < bVal) return -1;
          if (aVal > bVal) return 1;
          return 0;
        })
        setChildrenOrder(newOrder)
      }
      if (order === "desc") {
        const newOrder = ([] as T[]).concat(children).sort((a, b) => {
          let aVal = a[sortBy];
          let bVal = b[sortBy];
          if (sortBy === "approvedOn") {
            aVal = a["createdOn" as keyof T]
            bVal = b["createdOn" as keyof T]
          }
          if (sortBy === "flags") {
            aVal = (a["flags" as keyof T] as any)?.[0].flag
            bVal = (b["flags" as keyof T] as any)?.[0].flag
          }
          if (sortBy === "closingDate") {
            aVal = (a["properties" as keyof T] as any).closingDate
            bVal = (b["properties" as keyof T] as any).closingDate
          }
          if (sortBy === "totalContract" || sortBy === "totalOriginalContractAmount" || sortBy === "totalChangeOrderAmount" || sortBy === "amountComplete" || sortBy === "contractAmountPending" || sortBy === "changeOrderPendingApproval" || sortBy === "proPrice" || sortBy === "customerPrice") {
            aVal = aVal && (aVal as any).includes("$") ? parseFloat((aVal as any).replace(/[^0-9\.]+/g, "")) as any : aVal;
            bVal = bVal && (bVal as any).includes("$") ? parseFloat((bVal as any).replace(/[^0-9\.]+/g, "")) as any : bVal;
          }
          if (aVal > bVal) return -1;
          if (aVal < bVal) return 1;
          return 0;
        })
        setChildrenOrder(newOrder)
      }
    }
  }

  useEffect(() => {
    setChildrenOrder(children)
    setOrderBy("id" as keyof T)
  }, [children])

  return (
    <Grid style={{ width: `${fullWidth ? '100%' : 'auto'}` }} className={`${styles.Table} ${!title && styles.Table__TopBorders} ${className || ''} ${hasScroll ? styles.Table__hasScroll : ''}`}>
      {title &&
        <Box className={styles.Table__Header}>
          <Typography variant='h6' color='var(--white-color)'>{title}</Typography>
        </Box>}
      {!!totalItems && hasTopPagination && (
        <TablePagination
          id={id}
          callToApi={callToApi}
          defaultPage={defaultPage}
          rowsPerPageDefault={rowsPerPageDefault}
          rowsPerPageOptions={rowsPerPageOptions}
          totalItems={totalItems}
          isSticky={stickyPagination}
        />
      )}
      <MaterialTable stickyHeader={stickyHeader} id={id} className={`${!title && styles.Table__TopBorders}`}>
        <TableHead
          headers={headers}
          sortable={sortable}
          haveActions={actions && actions.length > 0}
          actionTitle={actionTitle}
          actionTitleAlign="center"
          onRequestSort={handleRequestSort}
          order={order}
          orderBy={orderBy}
          small={small}
        />
        {!loading ?

          childrenOrder && childrenOrder.length > 0 ?

            <TableBody<T, U>
              headers={headers}
              actions={actions}
              small={small}
              rowAction={rowAction}
              hasBorders={hasBorders}
              rowColor={rowColor}
              onRightClickRow={onRightClickRow}
              selectedRow={selectedRow}
              highlightedRows={highlightedRows}
            >
              {childrenOrder}
            </TableBody>

            :
            <tbody><tr><td className={styles.emptyState}>{emptyState}</td></tr></tbody>

          : <tbody><tr><td><Box className={styles.loadingState}><CircularProgress className={styles.loading} /></Box></td></tr></tbody>
        }
      </MaterialTable>
      {totalItems !== 0 && hasPagination && (
        <TablePagination
          id={id}
          callToApi={callToApi}
          defaultPage={defaultPage}
          rowsPerPageDefault={rowsPerPageDefault}
          rowsPerPageOptions={rowsPerPageOptions}
          totalItems={totalItems}
          isSticky={stickyPagination}
        />
      )}
    </Grid>
  )
}

export default Table
