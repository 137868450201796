/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useMemo, useState, useContext, useLayoutEffect } from 'react'
import Table from 'src/components/UI/CustomUI/organisms/Table'
import Toolbar from './Toolbar'
import TablePagination from 'src/components/UI/CustomUI/organisms/Table/TablePagination'
import { formatTimestamp, getOptionsFromArrayMultiple, headerCSVParser, ROWS_PER_PAGE_EXTENDED } from 'src/helpers'
import { useDispatch, useSelector } from 'react-redux'
import {
  companiesActions,
  companyActions,
  filtersActions,
} from 'src/ducks/actions'
import {
  getAllCompanies,
  getCompanies,
  getTotalCompaniesCount,
} from 'src/ducks/companies/selectors'
import { Box } from '@mui/material'
import CompaniesModal from './modals'
import { Grid, Button } from 'src/components/UI'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import styles from './styles.module.scss'
import { CompanyItem } from 'src/ducks/types'
import { CompaniesContext } from './context'
import { SelectInputOption } from 'src/components/UI/CustomUI/atoms/SelectInput/types'
import { dispatchTypes, MODAL_TYPE } from './context/types'
import { getCompaniesFilter } from 'src/ducks/filters/selector'
import { CompaniesFilterType } from 'src/ducks/filters/types'
import ExportCSV from 'src/components/UI/CustomUI/molecules/ExportCSV'

import headers from './headers'

const Companies: FC = () => {
  const dispatch = useDispatch()
  const { dispatch: dispatchContext } = useContext(CompaniesContext)

  const allCompanies = useSelector(getAllCompanies)
  const totalCount = useSelector(getTotalCompaniesCount)
  const companiesFilter = useSelector(getCompaniesFilter())

  const [allCompaniesLoading, setAllCompaniesLoading] = useState<boolean>(false)
  const [tableLoading, setTableLoading] = useState<boolean>(false)
  const [selectedTableCompanies, setSelectedTableCompanies] = useState<any>([])
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(50)

  const search = companiesFilter.search || ''
  const institutional = companiesFilter.institutional || null

  const affiliatesStages = useMemo(
    () => {
      return getOptionsFromArrayMultiple(allCompanies, { key: 'id', label: 'name' })
    },
    [allCompanies]
  )
  const selectedAffiliates = useMemo(
    () => {
      return companiesFilter.selectedAffiliates || []
    },
    [companiesFilter]
  )
  const selectedCount = useMemo(
    () => selectedAffiliates.filter((aff) => !aff.hide).length,
    [selectedAffiliates]
  )

  // CSV
  const headersCSV: any = headerCSVParser(headers)
  const dataCSV = selectedTableCompanies.reduce((acc: any, curr: any) => {
    const vendor = {
      ...curr,
      affiliateId: curr?.affiliateId || '',
      companyName: curr?.name || '',
      createdOn: curr?.createdOn ? formatTimestamp(curr?.createdOn, 'MM/DD/YYYY hh:mm a') : '',
      link: curr?.landingPageUrl || '',
      phone: curr?.phone || '',
      email: curr?.email || '',
      institutional: curr?.institutional ? 'Yes' : 'No',
    }
    acc = [...acc, vendor]
    return acc
  }, [])


  const fetchAllCompanies = () => {

    setAllCompaniesLoading(true)
    dispatch(
      companiesActions.fetchAllCompanies(
        { searchParams: { search, institutional: !!institutional } },
        (succ) => {
          setAllCompaniesLoading(false)
        }
      )
    )
  }

  const updateCompaniesFilter = (newFilter: Partial<CompaniesFilterType>) => {
    dispatch(
      filtersActions.setFilters({
        companies: {
          ...companiesFilter,
          ...newFilter,
        },
      })
    )
  }

  const attachSettingHandler = (arr: CompanyItem[]) => {
    return arr.map((company) => ({ ...company, handleSettings }))
  }

  // set table list with selected companies
  const setSelectedCompaniesForTable = () => {
    const length = selectedCount || allCompanies.length
    const start = page * size
    const end = start + size > length ? length : start + size

    setSelectedTableCompanies(
      allCompanies
        .filter((company, index) => {
          const targetAff = selectedAffiliates.find(
            (aff) => aff.id === company.id
          )
          return !targetAff?.hide || !selectedCount
        })
        .slice(start, end)
    )
  }

  const setSelectedAffiliates = (newAffiliates: SelectInputOption[]) => {
    updateCompaniesFilter({
      selectedAffiliates: newAffiliates,
    })
  }

  useEffect(() => {
    fetchAllCompanies()
  }, [search, institutional])

  useEffect(() => {
    setSelectedCompaniesForTable()
  }, [selectedCount, page, size, allCompanies])

  useEffect(() => {
    setSelectedAffiliates(affiliatesStages)
  }, [affiliatesStages])

  const handleSettings = (row: any) => {
    dispatch(companyActions.setCompany(row))
    dispatchContext({
      type: dispatchTypes.SET_VALUE,
      payload: {
        attr: 'modalType',
        value: MODAL_TYPE.EDIT,
      },
    })
    dispatchContext({
      type: dispatchTypes.SET_VALUE,
      payload: {
        attr: 'modalOpen',
        value: true,
      },
    })
  }

  return (
    <Box>
      <CompaniesModal />
      <Box display="flex" flexDirection="column" gap={1}>
        <Toolbar
          selectedAffiliates={selectedAffiliates}
          setSelectedAffiliates={setSelectedAffiliates}
          fetchData={fetchAllCompanies}
        />
        <Grid container justifyContent='flex-end' alignItems='center'>
          <TablePagination
            callToApi={(start, limit) => {
              fetchAllCompanies()
              setPage(start)
              setSize(limit)
            }}
            totalItems={selectedCount ? selectedCount : totalCount}
            rowsPerPageOptions={[...ROWS_PER_PAGE_EXTENDED, 1000]}
          />
          <ExportCSV
            headers={headersCSV}
            data={dataCSV}
            fileName='companies.csv'
            label={<Button sx={{ padding: '8px !important' }} variant='containedLight'><Icon name='CloudDownload' /></Button>}
          />
        </Grid>
        <Box className={styles.Companies__tableContainer}>
          <Table
            headers={headers}
            defaultOrder="desc"
            loading={allCompaniesLoading || tableLoading}
            hasPagination={false}
          >
            {attachSettingHandler(selectedTableCompanies)}
          </Table>
        </Box>
      </Box>
    </Box>
  )
}

export default Companies
