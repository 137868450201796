import { FC } from 'react'
import { TitleProps } from './types'
import useStyles from './styles'

import { Typography, Box } from '../../..'

const TitleFirst: FC<TitleProps> = ({
  alignItems,
  black,
  justifyContent,
  leftIcon,
  lineHeight,
  rightIcon,
  value,
  valueVariant,
  blackVariant,
  ...rest
}) => {
  const classes = useStyles()

  return (
    <Box
      alignItems={alignItems}
      className={classes.text}
      justifyContent={justifyContent} 
      {...rest as any}
    >
      {leftIcon && (
        <Box display="flex" alignItems="center">
          {leftIcon}
        </Box>
      )}
      {value && (
        <Typography
          fontFamily="MorangaRg"
          fontSize={valueVariant ? undefined : '30px'}
          fontWeight="normal"
          lineHeight={lineHeight}
          variant={valueVariant}
        >
          {value}
        </Typography>
      )}
      {black && (
        <Typography
          fontFamily="NouvelleBold !important"
          fontSize={blackVariant ? undefined : '32px'}
          lineHeight={lineHeight}
          variant={blackVariant}
        >
          {black}
        </Typography>
      )}
      {rightIcon && (
        <Box display="flex" alignItems="center">
          {rightIcon}
        </Box>
      )}
    </Box>
  )
}

export default TitleFirst
