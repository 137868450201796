import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/system'

export default makeStyles((theme: Theme) =>
  createStyles({
    row: {
      display: 'flex !important',
      flexDirection: 'row',
      alignSelf: 'center !important',
      width: '100% !important',
      height: '88px !important',
      justifyContent: 'flex-end !important',
      alignItems: 'center !important',
      backgroundColor: 'white !important',
      position: 'sticky',
      bottom: 0,
      zIndex: 4,
      [theme.breakpoints.down('sm')]: {
        height: '112px !important',
        bottom: 'env(safe-area-inset-bottom) !important',
        position: 'fixed !important',
      }
    },
    column: {
      display: 'flex !important',
      flexDirection: 'column',
      alignItems: 'flex-start'
    },
    line1: {},
    line2: {},
    rowTitle: {
      display: 'flex !important',
      flexDirection: 'row',
      margin: '0px 20px !important',
      [theme.breakpoints.down('sm')]: {
        margin: '0px 12px'
      }
    },
    rowButtons: {
      display: 'flex !important',
      flexDirection: 'row',
      margin: '0 !important',
      flex: 0.7,
      justifyContent: 'flex-end !important',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column !important',
        margin: '0px 10px !important',
        width: 'auto !important',
        alignItems: 'center !important',
        flex: 1
      }
    },
    forward: {
      width: '220px !important',
      height: '56px !important',
      textTransform: 'none',
      color: 'var(--white-color) !important',
      backgroundColor: 'var(--bosscat-blue-600) !important',
      borderRadius: 8,
      margin: '5px !important',
      endIcon: {
        width: '5px !important',
        height: '5px'
      },
      [theme.breakpoints.down('sm')]: {
        width: '100% !important',
        height: '40px'
      }
    },
    selectedItems: {
      // width: '220px !important',
      height: '54px !important',
      padding: '0 .5rem !important',
      color: 'var(--white-color) !important',
      // backgroundColor: 'var(--repairs-yellow-500) !important',
      backgroundColor: '#01AA59 !important',
      borderColor: '#01AA59 !important',
      borderRadius: 8,
      textTransform: 'inherit',
      margin: '5px !important',
      width: 'fit-content !important',
      '&:hover': {
        backgroundColor: 'var(--bosscat-green-700) !important',
      },
      '@global': {
        '.MuiTypography-root': {
          fontWeight: 900,
        }
      },
      endIcon: {
        width: '5px !important',
        height: '5px'
      },
      [theme.breakpoints.down('sm')]: {
        width: '100% !important',
        height: '60px !important',
        margin: 0,
        padding: '0 0.5rem'
      }
    },
    requestMoreButton: {
      height: '54px !important',
      padding: '0 .5rem !important',
      color: 'var(--bosscat-blue-600) !important',
      backgroundColor: 'white !important',
      borderRadius: 8,
      textTransform: 'inherit',
      margin: '5px !important',
      transition: '300ms all !important',
      maxWidth: 'fit-content !important',
      '&:hover': {
        color: '#2662C6 !important',
        backgroundColor: 'white !important',
        transition: '300ms all !important',
      },
      '@global': {
        '.MuiTypography-root': {
          fontWeight: 900,
        }
      },

      [theme.breakpoints.down('sm')]: {
        width: 'fit-content !important',
        height: '48px !important',
        borderRadius: 32,
        color: '#0B060F'
      }
    },
    speak: {
      width: '220px !important',
      height: '56px !important',
      textTransform: 'none',
      color: 'var(--white-color) !important',
      backgroundColor: 'var(--bosscat-green-600) !important',
      borderColor: 'var(--bosscat-green-600) !important',
      borderRadius: 8,
      margin: '5px !important',
      endIcon: {
        width: '5px !important',
        height: '5px'
      },
      [theme.breakpoints.down('sm')]: {
        width: '100% !important',
        height: '40px'
      },
      '&:hover': {
        backgroundColor: 'var(--bosscat-green-700) !important'
      }
    },
    icon: {
      width: '15px !important',
      height: '15px'
    },
    total: {
      fontFamily: 'NextArtBold !important',
      fontSize: '24px !important',
      [theme.breakpoints.down('sm')]: {
        fontSize: '18px !important',
      }
    },
    price: {
      fontFamily: 'Lato !important',
      fontWeight: 500,
      fontSize: '24px !important',
      marginLeft: '4px !important',
      minWidth: '80px'
    }
  })
)
