import { createContext, FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { vendorActions } from 'src/ducks/actions'
import { getVendor } from 'src/ducks/selectors'
import { VendorType } from 'src/ducks/vendor/types'
import { VendorContextType } from './types'

const initialState: VendorContextType = {
  editedVendor: null,
  setEditedVendor: () => null,
  hasChanges: false,
  loading: false,
  handleSave: () => { },
}

export const VendorContext = createContext<VendorContextType>(initialState)

export const VendorProvider: FC = ({ children }: any) => {
  const vendor = useSelector(getVendor())
  const dispatch = useDispatch()
  const [editedVendor, setEditedVendor] = useState<Partial<VendorType>>(vendor)
  const [hasChanges, setHasChanges] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const editedVendorOrderList = {
      ...editedVendor,
      territoryList: editedVendor?.territoryList?.sort((a, b) =>
        a.id.localeCompare(b.id)
      ),
    }
    const vendorOrderList = {
      ...vendor,
      territoryList: vendor?.territoryList?.sort((a, b) =>
        a.id.localeCompare(b.id)
      ),
    }
    const edited =
      JSON.stringify(editedVendorOrderList) !== JSON.stringify(vendorOrderList)
    setHasChanges(edited)
  }, [editedVendor, vendor])

  const handleSave = () => {
    const edited: any = editedVendor || {}
    const origin: any = vendor || {}
    const propertyList = [
      'tradeList',
      'firstName',
      'lastName',
      'companyName',
      'email',
      'address',
      'notes',
      'referral',
      'bgChecked',
      'bgPassed',
      'verificationStage',
      'territoryList',
      'lmsEnabled',
      'phone',
      'wcExemptTerritories'
    ]

    setLoading(true)
    setHasChanges(false)
    let request: Partial<VendorType> = {}

    propertyList.forEach((property) => {
      request =
        edited[property] !== origin[property]
          ? {
            ...request,
            [property]: edited[property],
          }
          : request
    })

    if (JSON.stringify(request) !== JSON.stringify({})) {
      dispatch(
        vendorActions.updateVendor(request, (_succ) => {
          setLoading(false)
          setHasChanges(false)
        })
      )
    }
  }

  return (
    <VendorContext.Provider
      value={{
        editedVendor,
        setEditedVendor,
        hasChanges,
        loading,
        handleSave,
      }}
    >
      {children}
    </VendorContext.Provider>
  )
}
