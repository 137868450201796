import { SearchParams } from '../searches/types'
import { Estimate, Count, EstimateItem } from '../types'

/** TYPES **/
export const estimatesByCategoryTypes = {
  FETCH_ESTIMATES_BY_STAGE: ' FETCH_ESTIMATES_BY_STAGE',
  SET_ESTIAMTES_BY_STAGE: 'SET_ESTIAMTES_BY_STAGE',
  RESET_ESTIMATES_BY_STAGE: 'RESET_ESTIMATES_BY_STAGE',
}

/** ACTIONS **/
export interface FetchNewEstimatesAction {
  type: typeof estimatesByCategoryTypes.FETCH_ESTIMATES_BY_STAGE
  payload: {
    stage: string,
    start: number,
    limit: number,
    searchParams: Partial<SearchParams>
  }
  callback?: (succ: boolean, limit?: number) => void
}

export interface ResetEstimatesByStageAction {
  type: typeof estimatesByCategoryTypes.RESET_ESTIMATES_BY_STAGE
  payload: {
    stage: string,
  }
  callback?: (succ: boolean) => void
}

export interface SetNewEstimatesAction {
  type: typeof estimatesByCategoryTypes.SET_ESTIAMTES_BY_STAGE
  payload: SetEstimatesByStageParam
}

export interface SetEstimatesByStageParam {
  data: Estimate
  stage: string
}

export type EstimatesByCategoryAction =
  | FetchNewEstimatesAction
  | SetNewEstimatesAction
  | ResetEstimatesByStageAction

export type EstimatesByCategoryType = any
