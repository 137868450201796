import { createStyles, makeStyles } from '@mui/styles'
import { Theme as ThemeMUI } from '@mui/system'


export default makeStyles((theme: ThemeMUI) =>
  createStyles({
    text: {
      padding: '0 !important',
      fontFamily: 'NextArtHeavy !important',
      textAlign: 'left',
      [theme.breakpoints.down('sm')]: {
        fontSize: '18px !important',
        textAlign: 'center !important',
        marginBottom: '.5rem !important'
      }
    },
    root: {
      // padding: '8px 0'
    },
    box: {
      display: 'flex !important',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: '330px !important',
      textAlign: 'left',
      border: '2px solid #F5F6F7 !important',
      borderRadius: '8px !important',
      boxShadow: '0px 3px 5px rgba(26, 42, 85, 0.2), 0px 0px 1px rgba(26, 42, 85, 0.31) !important',
      padding: '8px !important',
      position: 'relative',
      cursor: 'pointer',
      '@global': {
        'img': {
          width: '120px !important',
          height: '120px !important',
          paddingBottom: '1rem !important'
        }
      },
      '&:hover': {
        border: '2px solid var(--bosscat-blue-600) !important',
        transition: '300ms all',
        '@global': {
          '.MuiSvgIcon-root': {
            fill: 'var(--bosscat-blue-600) !important',
          }
        }
      }
    },
    radioButton: {
      position: 'absolute',
      right: '8px !important',
      padding: '0 !important',
      width: 'fit-content',
      height: 'fit-content',
      minWidth: 'fit-content',
      '@global': {
        '.MuiButton-label': {
          width: 'fit-content'
        }
      }
    },
    title: {
      color: '#0B060 !important',
      fontSize: '18px !important',
      marginBottom: '.5rem !important'
    },
    subtitle: {
      color: '#6D6A6F !important'
    },
    question: {
      fontSize: '16px !important',
      fontFamily: 'NextArtBold !important',
      lineHeight: '30px !important',
      wordBreak: 'break-word',
      textAlign: 'justify',
      [theme.breakpoints.down('md')]: {
        textAlign: "left"
      },
    },
    accordion: {
      boxShadow: '0px 1px 1px rgba(26, 42, 85, 0.24), 0px 0px 1px rgba(26, 42, 85, 0.31) !important',
      borderRadius: '8px !important',
      border: '2px solid #F5F6F7 !important',
      '&:before': {
        content: 'none !important'
      }
    },
    button: {
      width: 'fit-content !important',
      height: '45px !important',
      textTransform: 'none',
      color: 'var(--bosscat-blue-600) !important',
      backgroundColor: 'var(--background-color-secondary) !important',
      boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important",
      borderRadius: '8px !important',
      border: '0px solid !important',
      padding: '0 1rem !important',
      [theme.breakpoints.down('sm')]: {
        width: '165px !important',
        minHeight: '50px !important',
        fontFamily: 'LatoNormal !important',
        fontWeight: 'normal',
        fontSize: '16px !important'
      }
    }
  })
)
