import React, { FC } from 'react'
import useStyles from './styles'
import { TextDividerProps } from './types'
import { Divider as MaterialDivider, Grid } from '@mui/material'

const TextDivider: FC<TextDividerProps> = ({
  label,
  align = 'left',
  spacing = '10px',
}) => {
  const classes = useStyles()

  return (
    <Grid
      container
      spacing={spacing}
      justifyContent="center"
      alignItems="center"
    >
      <Grid item>{label}</Grid>
      <Grid item flex={1}>
        <MaterialDivider classes={{ root: classes.divider }} />
      </Grid>
    </Grid>
  )
}

export default TextDivider
