import React, { FC, useMemo } from 'react'
import useStyles from './styles'
import InvoiceOption from './InvoiceOption'
import { Button, Dialog, Grid, Typography } from '@mui/material'
import { CheckListProps, InvoiceOptionModalProps } from './types'
import Save from '@mui/icons-material/Save'
import { optionInfo } from './constants'

const InvoiceOptionModal: FC<InvoiceOptionModalProps> = ({
  open,
  setOpen,
  refreshInvoicePDF,
  selectedList,
  setSelectedList,
}) => {
  const styles = useStyles()

  const invoiceOptions = useMemo(() => {
    return optionInfo.map((option, index) => ({
      ...option,
      checked: selectedList[index] ?? false,
    }))
  }, [selectedList])

  const handleClickByIndex = (index: number): void => {
    setSelectedList(
      selectedList.map((checked, idx) => (idx === index ? !checked : checked))
    )
  }

  const handleSave = (): void => {
    const newOptions: CheckListProps = invoiceOptions.reduce(
      (prev, { optionCheck, checked }) =>
      ({
        ...prev,
        [optionCheck]: checked,
      } as CheckListProps),
      {}
    )

    refreshInvoicePDF(newOptions)
  }

  const handleClose = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault()

    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      classes={{
        paper: styles.content,
      }}
      onClose={handleClose}
    >
      <Grid
        container
        direction="column"
        margin={0}
        padding={0}
        style={{
          gap: '20px',
        }}
      >
        <Grid
          item
          container
          direction="column"
          margin={0}
          style={{
            padding: 0,
            gap: '8px',
          }}
        >
          <Typography className={styles.title}>Invoice Options</Typography>
          {invoiceOptions.map((option, index) => (
            <InvoiceOption
              key={index}
              index={index}
              option={option}
              handleClickByIndex={handleClickByIndex}
            />
          ))}
        </Grid>
        <Grid container spacing={1} justifyContent="flex-end">
          <Button
            className={styles.closeButton}
            sx={{ color: '#3A66FF' }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className={styles.saveButton}
            endIcon={<Save />}
            onClick={handleSave}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default InvoiceOptionModal
