import { authTypes } from '../auth/types'
import { GroupedOrdersAction, GroupedOrdersType, SetGroupedOrdersParam, groupedOrdersTypes } from './types'

const initialState: GroupedOrdersType = {}

const reducer = (state = initialState, action: GroupedOrdersAction): GroupedOrdersType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case groupedOrdersTypes.SET_GROUPED_ORDERS: {
      return {
        ...state,
        [(payload as SetGroupedOrdersParam).stage]: (payload as SetGroupedOrdersParam).data
      }
    }

    case groupedOrdersTypes.RESET_GROUPED_ORDERS: {
      delete state[(payload as SetGroupedOrdersParam).stage]
      
      return {
        ...state,
        [(payload as SetGroupedOrdersParam).stage]: []
      }
    }

    default:
      return state
  }
}

export default reducer
