import { Component, ErrorInfo, ReactNode } from "react";
import ErrorPage from "src/components/pages/Error";



interface Props {
  children?: ReactNode;
  hasError?: boolean;
  setHasError?: any
}
interface State {
  hasError: boolean;
  error?: Error | undefined
}

class ErrorBoundaryInner extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      error: undefined
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidUpdate(prevProps: Props) {
    if (!this.props.hasError && prevProps.hasError) {
      this.setState({ hasError: false });
    }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.props.setHasError(true);
    this.setState({
      error: error
    })
  }


  render() {
    return this.state.hasError
      ? <ErrorPage error={this.state.error} />
      : this.props.children;
  }
}

export default ErrorBoundaryInner;