import { Territory } from '../types'
import { TerritoriesActions, territoriesTypes, TerritoryType } from './types'

const actions = {
  fetchTerritories: (
    callback: (succ: boolean) => void
  ): TerritoriesActions => ({
    type: territoriesTypes.FETCH_TERRITORIES,
    payload: null,
    callback
  }),
  setTerritories: (payload: TerritoryType[]): TerritoriesActions => ({
    type: territoriesTypes.SET_TERRITORIES,
    payload
  }),
  fetchTerritorySuccessManagers: (
    callback: (succ: boolean) => void
  ): TerritoriesActions => ({
    type: territoriesTypes.FETCH_TERRITORY_MANAGERS,
    payload: null,
    callback
  }),
  setTerritorySuccessManagers: (payload: TerritoryType[]): TerritoriesActions => ({
    type: territoriesTypes.SET_TERRITORIES,
    payload
  }),
  createTerritory: (
    payload: Partial<Territory>,
    callback: (succ: boolean) => void
  ): TerritoriesActions => ({
    type: territoriesTypes.CREATE_TERRITORY,
    payload,
    callback
  }),
}

export default actions
