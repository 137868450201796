import { useSelector } from 'react-redux'
import { LabeledText } from 'src/components/UI'
import { EstimateItem } from 'src/ducks/types'
import { formatTimestamp } from 'src/helpers'
import { ContentBox, Divider, TabBox } from '../../Templates'
import { useIsLgScreen, useIsXlScreen } from 'src/hooks'
import SitevisitsModal from './SiteVisitsModal'
import Modal from 'src/components/UI/CustomUI/molecules/Modal'
import { useState } from 'react'
import { getEstimate } from 'src/ducks/estimate/selectors'

const EstimateInfo = () => {
  const estimate = useSelector(getEstimate())
  const [xlScreen] = useIsXlScreen()
  const [lgScreen] = useIsLgScreen()
  const [modalOpen, setModalOpen] = useState(false)

  const repairTimeLineMapper = {
    PRELISTING: 'Prelisting',
    ASAP: 'ASAP',
    BEFORE_CLOSE: 'Before Close',
    AFTER_CLOSE: 'After Close',
    NEVER: 'Never',
    ONE_WEEK: 'One Week',
    THIRTY_DAYS: 'Thirty Days',
    SIXTY_DAYS: 'Sixty Days',
    OVER_SIXTY_DAYS: 'Over Sixty Days'
  }

  const {
    properties,
    createdOn,
    deliveryService,
    repairTimeline,
  } = estimate || {} as EstimateItem

  const { address, territory, estimateDeliveredOn } = properties || {}

  const estimateSent = estimateDeliveredOn ? formatTimestamp(estimateDeliveredOn, 'MM/DD/YYYY') : '-'
  const deliveryRequested = deliveryService?.title || '-'
  const deliveryDays = deliveryService?.days || '-'
  const repairTimelineValue = repairTimeLineMapper[repairTimeline] || '-'

  return (
    <TabBox>
      <Modal
        setOpen={setModalOpen}
        open={modalOpen}
        title='Site Visit History'
        showClose
        size='xs'
      >
        <SitevisitsModal />
      </Modal>
      <ContentBox gridTemplateColumns={'1fr'}>
        <LabeledText labelValue='Address:' textValue={estimate?.properties.address?.line_1} />
        <ContentBox gridTemplateColumns={xlScreen || lgScreen ? '1fr 1fr' : '1fr'}>
          <LabeledText labelValue='City:' textValue={address?.city} />
          <LabeledText labelValue='State:' textValue={address?.state} />
          <LabeledText labelValue='Territory:' textValue={territory?.title} />
          <LabeledText labelValue='ZIP Code:' textValue={address?.zipCode} />
        </ContentBox>
      </ContentBox>
      <Divider />
      <ContentBox gridTemplateColumns={xlScreen || lgScreen ? '1fr 1fr' : '1fr'}>
        <LabeledText
          labelValue='Created On:'
          textValue={formatTimestamp(createdOn, 'MM/DD/YYYY')}
          flexDirection={'column'}
        />
        <LabeledText
          labelValue='Estimate Sent:'
          textValue={estimateSent}
          flexDirection={'column'}
        />
      </ContentBox>
      <ContentBox gridTemplateColumns={xlScreen || lgScreen ? '1fr 1fr 1fr' : '1fr'}>
        <LabeledText
          labelValue='Delivery Requested:'
          textValue={deliveryRequested}
          flexDirection={'column'}
        />
        <LabeledText
          labelValue='Repairs Requested:'
          textValue={repairTimelineValue}
          flexDirection={'column'}
        />
        <LabeledText
          labelValue='Delivery Days:'
          textValue={`${deliveryDays}`}
          flexDirection={'column'}
        />
      </ContentBox>
    </TabBox>
  )
}

export default EstimateInfo
