// ContactInfoBox.tsx
import { useState } from 'react';
import { Typography, Box, Collapse, Button, FormControlLabel, Checkbox } from '@mui/material'
import styles from './styles.module.scss'
import { icons } from 'src/assets';
import { Contact } from 'src/ducks/types';


const CollapseContact = ({ contact, onDelete, onChangeRequester }: {
    contact: Contact,
    onDelete: () => void,
    onChangeRequester: () => void
}) => {
    const [collapsed, setCollapsed] = useState(false)
    const { firstName, lastName, email, phone, role } = contact

    const handleCollapse = () => {
        setCollapsed(!collapsed)
    };

    return (
        <Box className={styles.contactInfoBox}>
            <Box className={styles.titleContainer}>
                <Typography variant="h6" className={styles.title} onClick={handleCollapse}>
                    <icons.ExpandMore className={`${styles.expandIcon} ${collapsed ? styles.expanded : ''}`} />
                    {`${firstName} ${lastName} - ${role}`}
                </Typography>
                <Box display={'flex'} alignItems={'center'} justifyContent='flex-end'>
                    <FormControlLabel
                        style={{ marginRight: '1rem' }}
                        value={contact.isRequester}
                        control={<Checkbox checked={contact.isRequester} onChange={onChangeRequester} />}
                        label="Requester"
                        labelPlacement="end"

                    />
                    <Button variant='containedErrorLight' endIcon={<icons.Delete />} onClick={onDelete}>
                        Delete
                    </Button>
                </Box>
                
            </Box>

            <Collapse in={collapsed}>
                <Box marginTop={2}>
                    <Box className={styles.info}>
                        <icons.Person className={styles.icon} />
                        <Typography>{`Name: ${firstName} ${lastName}`}</Typography>
                    </Box>
                    <Box className={styles.info}>
                        <icons.Email className={styles.icon} />
                        <Typography>{`Email: ${email}`}</Typography>
                    </Box>
                    <Box className={styles.info}>
                        <icons.Phone className={styles.icon} />
                        <Typography>{`Phone: ${phone}`}</Typography>
                    </Box>
                </Box>
            </Collapse>
        </Box>
    );
};

export default CollapseContact;
