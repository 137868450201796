import { createStyles, makeStyles } from '@mui/styles'


export default makeStyles(() =>
  createStyles({
    comboUnit: {
      minHeight: '110px',
      padding: '8px 20px 8px 20px',
      borderRadius: '8px',
      border: '2px solid var(--border-color)',
      color: 'var(--text-color)',
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: 16,
      '&:hover': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8
      },
      '&.Mui-focused': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8
      },
    },
    input: {
      borderWidth: '0px',
      height: '100% !important',
    },
    inputMultiline: {
      borderWidth: '0px',
      height: '86px !important',
      overflow: 'auto !important'
    },
    inputMultilineResizable: {
      borderWidth: '0px',
      minHeight: '86px !important',
      maxHeight: '190px',
      overflow: 'auto !important',
      resize: 'vertical',
    },
    inputError: {
      borderColor: 'var(--expired-color) !important',
    },
  })
)
