import React, { FC, useState } from 'react'
import { Box, Button, Divider, Grid, Stack, Typography } from '@mui/material'
import { ColumnCardProps } from './types'
import { ESTIMATE_STATUS, dateFormatString } from 'src/helpers'
import CircleIconButton from 'src/components/UI/CustomUI/molecules/CircleIconButton'

import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder'
import EmojiFlagsIcon from '@mui/icons-material/EmojiFlags'
import PauseIcon from '@mui/icons-material/Pause'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow'
import CircleIcon from '@mui/icons-material/Circle'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import FlagIcon from '@mui/icons-material/Flag'

import { EstimateItem } from 'src/ducks/types'
// import AssignModal from '../../modals/AssignModal'
import { useDispatch, useSelector } from 'react-redux'
import { getEmployees } from 'src/ducks/selectors'
// import HoldModal from '../../modals/HoldModal'

import useStyles from './styles'
import { getEstimatesFilter } from 'src/ducks/filters/selector'
import { Link } from 'react-router-dom'
import ModalSubmitForReview from '../../../EstimatesView/ControlButtons/Modals/ModalSubmitForReview'
import WorkOrderCard from './WorkOrderCard'

const ColumnCard: FC<ColumnCardProps> = React.memo(
  ({ groupedOrder, stage }) => {
    const styles = useStyles()

    const dispatch = useDispatch()

    const {
      displayAddress: address,
      territoryTitle,
      affiliateId,
      publicId,
    } = groupedOrder

    const employees = useSelector(getEmployees)
    const estimatesFilters = useSelector(getEstimatesFilter())

    const [assignModalOpen, setAssignModalOpen] = useState<boolean>(false)
    const [holdModalOpen, setHoldModalOpen] = useState<boolean>(false)
    const [submitReviewModalOpen, setSubmitReviewModalOpen] =
      useState<boolean>(false)

    const isComposingStatus = (estimate: EstimateItem) => {
      return (
        estimate &&
        estimate.status === ESTIMATE_STATUS.COMPOSING &&
        !estimate.onHold
      )
    }

    const isReviewStatus = (estimate: EstimateItem) => {
      return estimate && estimate.status === ESTIMATE_STATUS.IN_REVIEW
    }

    // const handleNavigate = () => {
    //   window.open(`/estimates/${estimate.id}`)
    // }

    // const handleAssign = (
    //   event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    // ) => {
    //   // event.stopPropagation()
    //   event.preventDefault()
    //   dispatch(estimateActions.setEstimate(estimate))

    //   setAssignModalOpen(true)
    // }

    // const updateEstimate = (request: Partial<EstimateItem>) => {
    //   dispatch(
    //     estimatesActions.updateEstimate(
    //       { ...request, id: estimate.id },
    //       (succ) => {
    //         if (succ) {
    //           dispatch(
    //             filtersActions.setFilters({ estimates: { ...estimatesFilters } })
    //           )
    //         }
    //       }
    //     )
    //   )
    // }

    // const handleHold = (
    //   event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    // ) => {
    //   // event.stopPropagation()
    //   event.preventDefault()
    //   dispatch(estimateActions.setEstimate(estimate))

    //   if (estimate.onHold) {
    //     updateEstimate({ onHold: false })
    //   } else {
    //     setHoldModalOpen(true)
    //   }
    // }

    // const handleFollowUp = (
    //   event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    // ) => {
    //   // event.stopPropagation()
    //   event.preventDefault()
    //   dispatch(estimateActions.setEstimate(estimate))

    //   if (estimate.awaitingFollowUp) {
    //     updateEstimate({ awaitingFollowUp: false })
    //   } else {
    //     updateEstimate({ awaitingFollowUp: true })
    //   }
    // }

    // const handleSubmitReview = (
    //   event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    // ) => {
    //   // event.stopPropagation()
    //   event.preventDefault()
    //   dispatch(estimateActions.setEstimate(estimate))

    //   setSubmitReviewModalOpen(true)
    //   // update the status as IN_REVIEW
    //   // updateEstimate({ status: ESTIMATE_STATUS.IN_REVIEW })
    // }

    // const handleSubmitRelease = (
    //   event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    // ) => {
    //   // event.stopPropagation()
    //   event.preventDefault()
    //   dispatch(estimateActions.setEstimate(estimate))

    //   // update the status as RELEASE
    //   updateEstimate({ status: ESTIMATE_STATUS.READY_FOR_RELEASE })
    // }

    // const handleCloseModal = () => {
    //   setAssignModalOpen(false)
    //   setHoldModalOpen(false)
    // }

    return (
      <Link to={`/jobs/${groupedOrder.jobId}`} target="_blank">
        <Grid container className={styles.container}>
          <Grid container item flexDirection="column">
            <Grid container item justifyContent="space-between">
              <Typography className={styles.street}>
                {address || '-'}
              </Typography>
              {publicId && (
                <Box className={styles.inUse}>
                  <Typography lineHeight="18px">{publicId}</Typography>
                </Box>
              )}
            </Grid>
            <Grid container item justifyContent="space-between">
              <Typography className={styles.location}>
                {territoryTitle || '-'}
              </Typography>
              {affiliateId && (
                <Box className={styles.affiliate}>
                  <Typography lineHeight="18px">{affiliateId}</Typography>
                </Box>
              )}
            </Grid>
          </Grid>
          <Grid item container className={styles.dateContainer} color="#4661A8">
            {/* <Grid item>
            <CalendarTodayIcon style={{ width: '14px', height: '14px' }} />
          </Grid> */}
            <Grid item>
              <Typography className={styles.date}>
                {`To Be Completed: ${
                  groupedOrder.expectedCompletedOn
                    ? dateFormatString(
                        new Date(groupedOrder.expectedCompletedOn * 1000),
                        'MM/DD/YYYY - hh:mmA'
                      )
                    : '-'
                }`}
              </Typography>
            </Grid>
          </Grid>
          {/* <Grid item container className={styles.dateContainer} color="#1A2A55">
          <Grid item>
            <QueryBuilderIcon style={{ width: '14px', height: '14px' }} />
          </Grid>
          <Grid item>
            <Typography className={styles.date}>
              {`Due: ${dateFormatString(
                new Date(estimate.dueOn * 1000),
                'MM/DD/YYYY - hh:mmA'
              )}`}
            </Typography>
          </Grid>
        </Grid> */}

          {/* {estimate.onHold && estimate.holdNotes && (
          <Grid item container>
            <Typography variant="h6">{estimate.holdNotes}</Typography>
          </Grid>
        )} */}

          <Grid item container justifyContent="space-between">
            {/* <Grid item>
            {isComposingStatus(estimate) && (
              <CircleIconButton
                width="36px"
                height="36px"
                bgcolor="#F9FAFA"
                icon={
                  <DoubleArrowIcon style={{ width: '16px', height: '16px' }} />
                }
                iconColor="black"
                onClick={handleSubmitReview}
                popover="Submit for review"
              />
            )}
            {isReviewStatus(estimate) && (
              <CircleIconButton
                width="36px"
                height="36px"
                bgcolor="#F9FAFA"
                icon={
                  <DoubleArrowIcon style={{ width: '16px', height: '16px' }} />
                }
                iconColor="black"
                onClick={handleSubmitRelease}
                popover="Submit for release"
              />
            )}
          </Grid> */}
            {/* <Grid item container className={styles.buttonsContainer}>
            <Grid item>
              <CircleIconButton
                width="36px"
                height="36px"
                bgcolor="#F9FAFA"
                icon={
                  estimate.awaitingFollowUp ? (
                    <FlagIcon style={{ width: '16px', height: '16px' }} />
                  ) : (
                    <EmojiFlagsIcon style={{ width: '16px', height: '16px' }} />
                  )
                }
                iconColor="red"
                onClick={handleFollowUp}
                popover={
                  estimate.awaitingFollowUp ? 'Resume Follow Up' : 'Follow Up'
                }
              />
            </Grid>
            <Grid item>
              <CircleIconButton
                width="36px"
                height="36px"
                bgcolor="#F9FAFA"
                icon={
                  estimate.onHold ? (
                    <PlayArrowIcon style={{ width: '16px', height: '16px' }} />
                  ) : (
                    <PauseIcon style={{ width: '16px', height: '16px' }} />
                  )
                }
                iconColor="#3A66FF"
                onClick={handleHold}
                popover={estimate.onHold ? 'Resume' : 'Put on Hold'}
              />
            </Grid>
            <Grid item>
              {estimate.estimatorName ? (
                <CircleIconButton
                  width="36px"
                  height="36px"
                  bgcolor="#71B085"
                  icon={
                    <>
                      {estimate.estimatorName
                        ?.split(' ')
                        .map((name) => name[0]?.toUpperCase())}
                    </>
                  }
                  iconColor="white"
                  onClick={handleAssign}
                  popover={estimate.estimatorName}
                />
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  className={styles.assignButton}
                  onClick={handleAssign}
                >
                  Assign
                </Button>
              )}
            </Grid>
          </Grid> */}
          </Grid>
          <Box width="100%">
            <Stack
              direction="column"
              spacing={1}
              divider={<Divider orientation="horizontal" />}
            >
              {groupedOrder.orders.map((order) => (
                <WorkOrderCard
                  order={order}
                  showPercent={stage === 'In Progress'}
                />
              ))}
            </Stack>
          </Box>
        </Grid>
        {/* {assignModalOpen && (
        <AssignModal
          open={assignModalOpen}
          setOpen={setAssignModalOpen}
          assigners={employees || []}
          handleClose={handleCloseModal}
          estimate={estimate}
        />
      )}
      {holdModalOpen && (
        <HoldModal
          open={holdModalOpen}
          setOpen={setHoldModalOpen}
          estimate={estimate}
          handleClose={handleCloseModal}
        />
      )}
      {submitReviewModalOpen && (
        <ModalSubmitForReview
          open={submitReviewModalOpen}
          setOpen={setSubmitReviewModalOpen}
          estimate={estimate}
        />
      )} */}
      </Link>
    )
  }
)

export default ColumnCard
