/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useContext, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Box, Button, Grid, TextField, Typography } from 'src/components/UI'

import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import ClipboardButton from 'src/components/UI/CustomUI/atoms/ClipboardButton'
import { employeeIsAdmin, employeeIsCustomerCare, employeeIsManager, getEmployeeRoles, getSubscription } from 'src/ducks/selectors'
import { Address as GiftAddress, SubscriptionItem } from 'src/ducks/types'
import { EMPLOYEE_ROLE_TYPE, formatTimestamp, getDifferenceBetweenObjects, isEmpty, updateObjectByPath } from 'src/helpers'
import { SubscriptionContext } from '../../context'
import SelectAddress from 'src/components/UI/CustomUI/organisms/SelectAddress'

import { ModalActionType } from '../../../JobsView/ControlButtons/Modals/ModalMaterialInvoices/types'
import Modal from 'src/components/UI/CustomUI/molecules/Modal'
import { Address } from 'src/components/UI/CustomUI/organisms/SelectAddress/types'
import { subscriptionActions } from 'src/ducks/actions'
import { UpdateRequest } from '~api/types'
import { toast } from 'react-toastify'

const Gift: FC = () => {
  const { editedSub, setEditedSub } = useContext(SubscriptionContext)
  const subscription = useSelector(getSubscription(), shallowEqual)

  const { giftBox } = editedSub as SubscriptionItem || {}

  const dispatch = useDispatch()
  const isAdmin = useSelector(employeeIsAdmin)
  const isCustomerCare = useSelector(employeeIsCustomerCare)
  const isManager = useSelector(employeeIsManager)
  const [address, setAddress] = useState<any>('')
  const [openAddress, setOpenAddress] = useState(false)

  const isValid: boolean =
    !isEmpty(address?.line_1) &&
    address?.line_1 !== ' ' &&
    !!address?.zipCode &&
    address?.latitude &&
    address?.longitude

  const handleChange = (newAddress: Address) => {

    setAddress(newAddress)
  }

  const handleCloseAddress = () => {
    setOpenAddress(false)
    setAddress('')
  }

  const handleSaveEditAddress = () => {
    if (isValid) {
      var editAdress: GiftAddress = {
        city: address.city,
        state: address.state,
        line_1: address.line_1,
        line_2: address.line_2,
        zipCode: address.zipCode,
        latitude: address.latitude,
        longitude: address.longitude,
        fullAddress: '',
      }
      const subscriptionDifferences: UpdateRequest = [{ op: 'add', path: '/giftBox/shippingAddress', value: editAdress }]
      dispatch(
        subscriptionActions.updateSubscriptionByPath(subscriptionDifferences, (succ: boolean) => {
          if (succ) {
            toast.success('Address updated successfully')
          } else {
            toast.error('Error updating address')
          }
        })
      )

      setOpenAddress(false)
      setAddress('')
    }
  }

  const modalActionAddress: ModalActionType[] = [
    {
      textButton: 'Cancel',
      variant: 'outlined',
      onClick: () => {
        handleCloseAddress()
      },
    },
    {
      textButton: 'Update Address',
      variant: 'contained',
      onClick: () => {
        handleSaveEditAddress()
      },
      icon: 'Save',
      iconColor: 'white',
      disabled: !isValid,
    },
  ]

  const handleOnChangeValue = (path: string, newValue: string | number) => {
    setEditedSub(updateObjectByPath({ obj: editedSub, path, value: newValue }))
  }

  useEffect(() => {
    setEditedSub(subscription)
  }, [subscription])

  return (
    <Box gap={2} display='flex' flexDirection='column'>
      <Grid container alignItems="center">
        <Grid item xs={3}>
          <Typography variant="h5">Mailing Address:</Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={giftBox?.shippingAddress?.fullAddress || ''}
            fullWidth
            disabled
          />
          {((isAdmin || isCustomerCare || isManager) && giftBox) && <Button
            variant='containedLight'
            color='primary'
            sx={{ height: '30px', marginTop: '5px' }}
            onClick={() => {
              setOpenAddress(true)
            }}
            disabled={!isAdmin && !isCustomerCare && !isManager}
          >
            Edit Address
            <Icon name="Edit" sx={{ marginLeft: '.5rem' }} />
          </Button>}
        </Grid>
      </Grid>

      <Grid container alignItems="center">
        <Grid item xs={3}>
          <Typography variant="h5">Delivery Date:</Typography>
        </Grid>
        <Grid item xs={3}>
          <TextField
            value={formatTimestamp(giftBox?.deliveryDate, 'MM/DD/YYYY')}
            fullWidth
            disabled={(!isAdmin && !isCustomerCare && !isManager) || isEmpty(giftBox)}
          />
        </Grid>
      </Grid>

      <Grid container alignItems="center" marginTop={4}>
        <Grid item xs={3}>
          <Typography variant="h5">Tracking ID:</Typography>
        </Grid>
        <Grid item xs={3}>
          <TextField
            value={giftBox?.trackingId}
            fullWidth
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleOnChangeValue(
                'giftBox/trackingId',
                event.target.value
              )
            }
            disabled={(!isAdmin && !isCustomerCare && !isManager) || isEmpty(giftBox)}
          />
        </Grid>
      </Grid>

      <Grid container alignItems="center">
        <Grid item xs={3}>
          <Typography variant="h5">Tracking URL:</Typography>
        </Grid>
        <Grid item xs={3}>
          <TextField
            value={giftBox?.trackingUrl}
            fullWidth
            disabled={(!isAdmin && !isCustomerCare && !isManager) || isEmpty(giftBox)}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleOnChangeValue(
                'giftBox/trackingUrl',
                event.target.value
              )
            }
          />
        </Grid>
      </Grid>

      <Grid container marginTop={4}>
        <Grid item xs={3}>
          <Typography variant="h5">Gift Message:</Typography>
        </Grid>
        <Grid item xs={3} sx={{ position: 'relative' }}>
          <TextField
            multiline
            rows={4}
            value={giftBox?.message || ''}
            fullWidth
            disabled={(!isAdmin && !isCustomerCare && !isManager) || isEmpty(giftBox)}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleOnChangeValue(
                'giftBox/message',
                event.target.value
              )
            }
          />
          <ClipboardButton valueToCopy={giftBox?.message} />
        </Grid>
      </Grid>

      <Box
        sx={{
          position: 'absolute',
          bottom: '12px',
          right: '12px',
        }}
      >
        {openAddress && (
          <Modal
            setOpen={setOpenAddress}
            open={openAddress}
            title={'Edit Address Information'}
            actions={modalActionAddress}
            onClose={() => {
              handleCloseAddress()
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SelectAddress
                  placeholder="Address"
                  showWarnings={false}
                  savedAddress={address}
                  onChange={handleChange}
                />
              </Grid>

            </Grid>
          </Modal>
        )}
      </Box>

    </Box>
  )
}

export default Gift
