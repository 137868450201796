import { Image, Link, Page, Path, Svg, Text, View } from '@react-pdf/renderer'
import styles from './style'
import {
  bellhopLogo,
  bellhop2 as bellhopQr,
  adtLogo,
  pdfCouple2,
  arwLogo,
  s24Qr,
  simplisafeLogo,
  simplisafeQr,
  trustpilot,
  vhtLogo,
  vhtQr,
  arrowPDFLink,
  companyLogo,
  pypLogo,
  adtQR,
  arwQR,
  spikedQR,
  pypQR
} from 'src/assets/index'

const Page6 = ({ userInfo }) => {
  const partners = [
    {
      logo: adtLogo,
      text: (
        <Text
          style={{
            ...styles.blue900,
            ...styles.latoNormal,
            ...styles.fontSmall,
          }}
        >
          {`Enjoy the peace of mind that comes with an ADT-monitored\nsmart home system, complete with professional installation.`}
        </Text>
      ),
      link: 'https://s24alarms.com/bosscat',
      linkText: 'Learn More',
      qr: adtQR,
    },
    {
      logo: bellhopLogo,
      text: (
        <Text
          style={{
            ...styles.blue900,
            ...styles.latoNormal,
            ...styles.fontSmall,
          }}
        >
          {`A better moving experience starts here. Count on our trusted\nteam to handle the burdens of moving- so you can focus on\nfeeling settled.`}
        </Text>
      ),
      link: 'https://www.getbellhops.com/bosscat/',
      linkText: 'Learn More',
      qr: bellhopQr,
    },
    {
      logo: arwLogo,
      text: (
        <Text
          style={{
            ...styles.blue900,
            ...styles.latoNormal,
            ...styles.fontSmall,
          }}
        >
          {`Get important coverage for your home systems and appliances.\nSave up to $120 off our best plan.`}
        </Text>
      ),
      link: 'https://lp.arwhome.com/bosscat',
      linkText: 'Learn More',
      qr: arwQR,
    },
    {
      logo: companyLogo,
      text: (
        <Text
          style={{
            ...styles.blue900,
            ...styles.latoNormal,
            ...styles.fontSmall,
          }}
        >
          {`Stay connected- Internet, TV, Voice and Mobile are at\nyour fingertips. We have you covered. Check out our spectacular\noffers just for BOSSCAT customers!`}
        </Text>
      ),
      link: 'https://form.jotform.com/FieldForms/bosscat',
      linkText: 'Learn More',
      qr: spikedQR,
    },
    {
      logo: pypLogo,
      text: (
        <Text
          style={{
            ...styles.blue900,
            ...styles.latoNormal,
            ...styles.fontSmall,
          }}
        >
          {`One call will handle it all, from Internet to Trash & more. Your\ndedicated account manager will handle everything for you.`}
        </Text>
      ),
      link: 'https://www.pickyourprovider.com/bosscat/',
      linkText: 'Learn More',
      qr: pypQR,
    },
  ]
  return (
    <Page size="A4" wrap>
      <View style={{ ...styles.page6 }}>
        {userInfo.serviceable ? (
          <View style={{ ...styles.page6Header }}>
            <Image src={pdfCouple2} style={{ width: '100%' }} />
            <View
              style={{
                ...styles.backgroundBlue600,
                ...styles.page6HeaderText,
              }}
            >
              <Text
                style={{
                  ...styles.white,
                  ...styles.font25,
                  ...styles.latoBold,
                }}
              >
                Exclusive Offer for BOSSCAT Customers:
              </Text>
              <Text
                style={{
                  ...styles.white,
                  ...styles.font25,
                  ...styles.latoBold,
                }}
              >
                Your Passport to Savings from our Partners
              </Text>
            </View>
          </View>
        ) : (
          <View
            style={{
              padding: '32px 32px 0px',
            }}
          >
            <Text
              style={{
                ...styles.blue600,
                ...styles.fontMedium,
                ...styles.latoBold,
              }}
            >
              Exclusive Offer for BOSSCAT Customers: Your Passport to Savings
              from our Partners
            </Text>
          </View>
        )}
        <View style={{ ...styles.page6Content }}>
          <View style={{ ...styles.partnersQr, marginTop: "72px" }}>
            {partners.map((partner) => {
              return (
                <View>
                  <View
                    style={{
                      ...styles.row,
                      ...styles.alignCenter,
                      ...styles.gap4,
                      ...styles.spaceBetween,
                    }}
                  >
                    <Image
                      src={partner.logo}
                      style={{
                        ...styles.partnerLogo,
                      }}
                    />
                    <View style={{ ...styles.w80 }}>
                      <Text>{partner.text}</Text>
                      <View style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: "8px"
                      }}>
                        <Link
                          style={{
                            ...styles.latoNormal,
                            ...styles.fontSmall,
                            color: "#2F7BF7",

                          }}
                          src={partner.link}
                        >
                          {partner.linkText ?? partner.link}
                        </Link>
                        <Image
                          src={arrowPDFLink}
                          style={{
                            width: "10px",
                            height: "10px",
                            marginLeft: "5px",
                          }}
                        />
                      </View>
                    </View>
                    <Image
                      src={partner.qr}
                      alt="estimate qr code"
                      style={{
                        ...styles.qrCodeWOBorder,
                        // ...styles.qrCodeBorder,
                      }}
                    />
                  </View>
                  <View style={{ ...styles.separator }}></View>
                </View>
              )
            })}
          </View>
        </View>
      </View>

      <View
        style={{
          ...styles.page6Footer,
        }}
      >
        {!userInfo.serviceable && (
          <View
            style={{
              ...styles.backgroundBlue600,
              ...styles.p5,
              ...styles.flexColumn,
              ...styles.alignCenter,
              ...styles.justifyCenter,
            }}
          >
            <Text
              style={{
                ...styles.white,
                ...styles.nextArtNormal,
                ...styles.fontLarge,
                ...styles.textCenter,
              }}
            >
              GET GREEN REBATES & TAX INCENTIVES with BOSSCAT RENOVATIONS
            </Text>
            <Text
              style={{
                ...styles.white,
                ...styles.latoNormal,
                ...styles.fontSmall,
              }}
            >
              Contact us to learn more: 1-877-4-BOSSCAT
            </Text>
          </View>
        )}
        <View
          style={{
            ...styles.backgroundBlue800,
            padding: '20px',
            width: "100%"
          }}
        >
          <View
            style={{
              ...styles.flexColumn,
              ...styles.alignCenter,
              ...styles.fontMedium,
              width: "100%"
            }}
          >
            <Text
              style={{
                ...styles.white,
                ...styles.latoBold,
                ...styles.textCenter,
                ...styles.font20
              }}
            >
              CONTACT US
            </Text>
            <Text
              style={{
                ...styles.white,
                ...styles.textCenter,
                ...styles.latoNormal,
                ...styles.font20,
              }}
            >
              CustomerCare@BosscatHome.com
            </Text>
            <Text
              style={{
                ...styles.white,
                ...styles.textCenter,
                ...styles.latoNormal,
                ...styles.font20,
              }}
            >
              1-877-4-BOSSCAT
            </Text>
          </View>
        </View>
      </View>
    </Page>
  )
}

export default Page6
