import React, { FC } from 'react'
import { LinkEstimateProps } from './types'
import { Typography, Grid } from '@mui/material'
import { Link } from 'react-router-dom'

import NorthEastIcon from '@mui/icons-material/NorthEast'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import ContactsIcon from '@mui/icons-material/Contacts'

import styles from './styles.module.scss'
import { dateFormatString } from 'src/helpers'
import StatusLabel from '../StatusLabel/Status'

const LinkEstimate: FC<LinkEstimateProps> = ({ estimate }) => {
  return (
    <Grid
      container
      flexDirection="column"
      spacing={0.5}
      className={styles.LinkEstimate__container}
    >
      <Grid
        item
        container
        justifyContent="space-between"
        style={{ flexFlow: 'row' }}
      >
        <Grid item container className={styles.LinkEstimate__publicId}>
          <Link
            to={`/estimates/${estimate.id}`}
            target='blank'
            style={{ textDecoration: 'underline' }}
          >
            {estimate.properties.publicId || '-'}
          </Link>
          <NorthEastIcon style={{ width: '18px', height: '18px' }} />
        </Grid>
        <Grid item minWidth='fit-content'>
          <StatusLabel status={estimate.status} />
        </Grid>
      </Grid>
      <Grid item container spacing={0.5} alignItems="center">
        <CalendarTodayIcon className={styles.LinkEstimate__icon} />
        <Grid item>
          <Typography fontSize={'14px'}>
            <b>Request Date: </b>
            {dateFormatString(
              new Date(estimate.createdOn * 1000),
              'ddd, MMM D, YYYY h:mm A'
            )}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container spacing={0.5} alignItems="center">
        <ContactsIcon className={styles.LinkEstimate__icon} />
        <Grid item>
          <Typography fontSize={'14px'}>
            <b>Contacts: </b>
            {estimate.properties.contacts
              ?.map((contact) => contact.fullName)
              .join(', ')}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default LinkEstimate
