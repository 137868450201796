import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Table from 'src/components/UI/CustomUI/organisms/Table'
import { communicationsActions } from 'src/ducks/actions'
import {
  getEmailEventList,
  getEmailEventsTotalCount,
  getEstimate,
} from 'src/ducks/selectors'
import headers from './headers'
import { Box } from '@mui/material'
import TablePagination from 'src/components/UI/CustomUI/organisms/Table/TablePagination'
import { ROWS_PER_PAGE_SIMPLE } from 'src/helpers'

const Communications: FC<{}> = () => {
  const dispatch = useDispatch()

  const estimate = useSelector(getEstimate())
  const emailEvents = useSelector(getEmailEventList())
  const emailEventsTotalCount = useSelector(getEmailEventsTotalCount())

  const [loading, setLoading] = useState(false)
  const [paging, setPaging] = useState({ start: 0, limit: 10 })

  useEffect(() => {
    fetchEmailEvents()
  }, [paging])

  const fetchEmailEvents = () => {
    setLoading(true)

    dispatch(
      communicationsActions.getEmailEvents(
        { id: estimate?.id || '', start: paging.start, limit: paging.limit },
        (succ: boolean) => {
          setLoading(false)
        }
      )
    )
  }

  return (
    <Box margin="1rem 0">
      <TablePagination
        totalItems={emailEventsTotalCount}
        rowsPerPageDefault={10}
        rowsPerPageOptions={ROWS_PER_PAGE_SIMPLE}
        callToApi={(start, limit) => {
          setPaging({ start, limit })
        }}
      />
      <Table headers={headers} loading={loading} hasPagination={false}>
        {emailEvents}
      </Table>
    </Box>
  )
}

export default Communications
