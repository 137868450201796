import Tag from 'src/components/UI/CustomUI/atoms/Tag'
import { AlignType, HeaderType } from 'src/components/UI/CustomUI/organisms/Table/types'
import { expiracyStatusTagColor, expiracyStatusText, formatTimestamp, getExpiracyStatus } from 'src/helpers'
import { Grid, Typography } from '../../../../UI'


export const headersLicenses: HeaderType[] = [
    {
        id: 'licenseType',
        label: 'License Type',
        custom: true,
        align: 'center' as AlignType,
        Element: (row: any): JSX.Element => {
            return (
                <Typography sx={{ fontSize: '12px', fontWeight: 400 }}> {row?.licenseType?.replaceAll('_', ' ')} </Typography>
            )
        }
    },
    {
        id: 'validFromDate',
        label: 'Valid from Date',
        custom: true,
        align: 'center' as AlignType,
        Element: (row: any): JSX.Element => {
            return (
                <Typography sx={{ fontSize: '12px', fontWeight: 400 }}>
                    {row?.validFromDate ? formatTimestamp(row?.validFromDate, 'MM/DD/YYYY') : '-'}
                </Typography>
            )
        }
    },
    {
        id: 'validToDate',
        label: 'Valid to Date',
        custom: true,
        align: 'center' as AlignType,
        Element: (row: any): JSX.Element => {
            return (
                <Typography sx={{ fontSize: '12px', fontWeight: 400 }}>
                    {row?.validToDate ? formatTimestamp(row?.validToDate, 'MM/DD/YYYY') : '-'}
                </Typography>
            )
        }
    },
    {
        id: 'expiracyStatus',
        label: 'Expiracy Status',
        custom: true,
        align: 'center' as AlignType,
        Element: (row: any): JSX.Element => {
            const rowStatus = getExpiracyStatus(row?.validToDate)
            const { color } = expiracyStatusTagColor(rowStatus)
            return (
                <Grid container alignItems='center' spacing={1} direction='column'>
                    <Grid item width='100%'>
                        <Tag center color={color} background='#FFF' >{expiracyStatusText(rowStatus)} </Tag>
                    </Grid>
                </Grid>
            )
        },
        maxWidth: 80
    },
    
]


