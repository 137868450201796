import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'inline-flex !important',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '4px',
      gap: '4px',
      height: 'fit-content !important',
      width: 'fit-content !important',
      border: '2px solid #EBECEF',
      borderRadius: '80px'
    },
    selected: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '5px !important',
      border: 0,
      background: '#1A2A55 !important',
      borderRadius: '80px !important',
      color: 'white !important'
    },
    unSelected: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '5px !important',
      border: 0,
      background: 'white !important',
      borderRadius: '80px !important'
    }
  })
)
