import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => ({
    selectAddress: {
        border: '2px solid var(--border-color) !important',
        height: 'inherit !important',
        "@global": {
            '.MuiInputBase-root:before': {
                borderBottom: 'none !important'
            }
        }
    }
}))
