import { authTypes } from '../auth/types'
import { Note, Notes, NotesActions, notesTypes } from './types'

const initialState: Notes = []

const reducer = (state = initialState, action: NotesActions): Notes => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case notesTypes.SET_NOTES: {
      return payload as Note[]
    }
    case notesTypes.SET_NOTE: {
      return [...state, payload as Note]
    }
    default:
      return state
  }
}

export default reducer
