import { previewInvoiceTypes, PreviewInvoiceValue, PreviewInvoiceContextType } from "./types";

export const PreviewInvoiceReducer = (state: PreviewInvoiceContextType, action: any) => {
  const { type, payload } = action

  switch (type) {
    case previewInvoiceTypes.SET_VALUE: {
      const { attr, value } = payload as PreviewInvoiceValue
      return {
        ...state as unknown as PreviewInvoiceContextType,
        [attr]: value
      }
    }
    default:
      return state;
  }
}
