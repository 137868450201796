import { FC, useState, SyntheticEvent, useEffect } from 'react'
import { Button, Grid, Tab, Tabs } from '@mui/material'
// import { tabsClasses } from '@mui/material/Tabs';

import { TabNavigationProps } from './types'
import styles from './styles.module.scss'
import { styled } from '@mui/system'
import { icons } from 'src/assets'

const TabNavigation: FC<TabNavigationProps> = ({
  tabs,
  defaultActive = 0,
  variant = 'text',
  margin,
  className = '',
  style = {},
  tabWidth = "100%",
  tabAlign = "flex-end",
  showExpand = false,
  getSelectedTab,
  containerSx = {},
  additionalContent,
  tabsStyles = {},
  scrollable = false,
  selectedTab,
  id = "",
  customExpanded = false,
}) => {
  const [selected, setSelected] = useState<number>(defaultActive)
  const [expanded, setExpanded] = useState(customExpanded)

  useEffect(() => {
    (selectedTab !== undefined) && setSelected(selectedTab)
  }, [selectedTab])

  useEffect(() => {
    setExpanded(customExpanded)
  }, [customExpanded])

  useEffect(() => {
    getSelectedTab && getSelectedTab(selected)
  }, [selected])

  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    if (!tabs?.[newValue].content) return;
    const action = tabs[newValue].action
    const preventOpen = tabs[newValue].preventOpen
    if (!preventOpen) setSelected(newValue)
    if (action) action(tabs[newValue]?.id)
  }

  if (!tabs.length) return null

  const TabsStyled = styled(Tabs)({
    width: tabWidth,
    '& .MuiTabs-flexContainer': {
      gridTemplateColumns: variant === 'contained' || variant === 'containedCircular' ? `repeat(${tabs.length}, 1fr)` : 'inherit',
    }
  })

  return (
    <Grid
      container
      display='grid'
      className={`${expanded ? styles.Expanded : ''}`}
      gridTemplateRows='min-content auto'
      height='100%'
      width='100%'
      alignItems={tabAlign}
    >
      <Grid
        sx={containerSx}
        container
        item
        alignItems="center"
        justifyContent={tabs[selected]?.actionButton ? "space-between" : tabAlign}
        className={`${styles[`${variant}--container`]} ${scrollable ? styles[`${variant}--container--scrollable`] : ''} ${styles.Column}`}
      >
        {tabs[selected]?.actionButton && tabs[selected]?.actionButton}
        <TabsStyled
          value={selected}
          onChange={handleChange}
          className={`${styles.Tabs} ${styles[variant]} ${styles[`${variant}--${tabs.length}`]} ${className} `}
          style={{ margin: margin, ...tabsStyles }}
          variant={scrollable ? "scrollable" : undefined}
          scrollButtons={scrollable}
          id={id}
          sx={{
            '.Mui-disabled': { opacity: '0 !important', display: 'none' },
            // '.Mui-disabled': { opacity: '1 !important' },
          }}
        >
          {tabs.filter((tab) => tab.label).map((tab, index) =>
            <Tab
              className={`${tab.className || ''} ${tab.error ? styles.Error : ''} ${styles.Tab} ${styles[variant]} ${styles.Tab} ${tab.action ? styles.Tab__action : ''}`}
              key={index}
              label={tab.element ? tab.element : tab.label}
              style={{ color: tab.color, ...style }}
              icon={tab?.icon ? tab.icon : undefined}
              iconPosition={'start'}
            />
          )}
        </TabsStyled>
        {additionalContent && additionalContent}
        {showExpand && (
          <Button
            className={`${styles.Tab} ${styles.Expand} ${styles[variant]} `}
            key='flow'
            style={{
              ...style,
            }}
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? <icons.KeyboardArrowDown /> : <icons.KeyboardArrowUp />}
          </Button>
        )}
      </Grid>
      <Grid item height="100%" width="100%" display="grid">
        {tabs[selected]?.content}
      </Grid>
    </Grid>
  )
}

export default TabNavigation
