import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";


export default makeStyles((theme: Theme) =>
  createStyles({
    banner: {
      padding: '24px 32px',
      flexFlow: 'row',
      borderWidth: '2px',
      boxSizing: 'border-box',
      gap: '16px',
      borderRadius: '16px',
      borderStyle: 'solid',
      flexWrap: 'nowrap !important' as any
    },
    title: {
      fontFamily: 'NouvelleBold !important',
      fontSize: '16px !important',
      lineHeight: '24px !important',
    },
    content: {
      marginLeft: 0,
      gap: '16px',
    },
    description: {
      fontFamily: 'Nouvelle !important',
      fontSize: '16px !important',
      lineHeight: '24px !important',
    },
    button: {
      backgroundColor: 'var(--green800) !important',
      borderColor: 'var(--green800) !important',

      '&:hover': {
        backgroundColor: 'var(--green900) !important',
        borderColor: 'var(--green900) !important',
      },
    },
  })
)
