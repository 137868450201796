import { RESOURCE_TYPE } from 'src/helpers'
import axios from '../axios'
import { ApiController } from './types'

const base = '/searches'

const apiController: ApiController = {
  getSearches: async (type: RESOURCE_TYPE) =>
    await axios
      .get(`${base}/${type}`)
      .then(response => response.data),

  editSearch: async (request, type) =>
    await axios
      .patch(`${base}/${type || 'JOB'}`, request)
      .then(response => response.data),
}

export default apiController
