import { authTypes } from '../auth/types'
import { InspectorsActions, InspectorsType, inspectorsTypes } from './types'

const initialState: InspectorsType = null
const reducer = (
  state = initialState,
  action: InspectorsActions
): InspectorsType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: {
      return initialState
    }
    case authTypes.LOGOUT_REQUEST: {
      return initialState
    }
    case inspectorsTypes.SET_INSPECTORS: {
      return payload as InspectorsType
    }
    default:
      return state
  }
}

export default reducer
