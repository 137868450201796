import { MatchItem } from '../types'

export const matchTypes = {
  FETCH_MATCH: 'FETCH_MATCH',
  SET_MATCH: 'SET_MATCH',
}

export interface FetchMatchAction {
  type: typeof matchTypes.FETCH_MATCH
  payload: {
    estimateId: string
    itemId: string
  }
  callback?: (succ: boolean) => void
}

export interface SetMatchAction {
  type: typeof matchTypes.SET_MATCH
  payload: MatchItem
}

export type MatchActions =
  | FetchMatchAction
  | SetMatchAction

export type MatchType = MatchItem