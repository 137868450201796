import {
  FC, useContext, useEffect, /* , useState */
  useState
} from 'react'
import {
  Box,
  Button,
  IconButton,
  ButtonGroup,
  Typography,
  FieldsFilter,
  Grid,
  Checkbox,
  FormControlLabel,
} from '../../../UI'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { icons } from 'src/assets'
import { CalendarContext } from './context.provider'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Options } from 'src/components/UI/CustomUI/atoms/FieldsFilter/types'
import { getTerritoryParam } from './helper'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { getEmployeePrimaryEmail, getVendors } from 'src/ducks/selectors'

const ALL_TERRITORY_ID = 'all_territories'
const ALL_AFFILIATE_ID = 'all_affiliate'

const CalendarToolbar: FC = () => {
  const context = useContext(CalendarContext)
  const {
    selected,
    setSelected,
    view,
    setView,
    showDispatch,
    territoryOptions,
    setTerritoryOptions,
    setTerritoryParam,
    affiliatesOptions,
    setAffiliatesOptions,
    setAffiliateParam,
    showOrders,
    setShowOrders,
    showSiteVisits,
    setShowSiteVisits,
    showAssignedToMe,
    setShowAssignedToMe
  } = context

  const loggedUserEmail = useSelector(getEmployeePrimaryEmail)

  const vendors = useSelector(getVendors())
  const vendorUserIds = vendors.filter((vendor) =>
    vendor?.email[0]?.email?.includes(loggedUserEmail?.split('@')[0])
  )

  const handlePrev = () => {
    setSelected(selected!.clone().subtract(1, view))
  }

  const handleNext = () => {
    setSelected(selected!.clone().add(1, view))
  }

  const getSelectedTitle = () => {
    switch (view) {
      case 'day':
        return selected!.format('MMMM DD, YYYY')
      case 'week':
        const firstDay = selected!.clone().startOf('week')
        const lastDay = selected!.clone().endOf('week')

        if (firstDay.format('MMMM') !== lastDay.format('MMMM')) {
          return `${firstDay!.format('MMMM')} - ${lastDay!.format('MMMM YYYY')}`
        }
        return selected!.format('MMMM YYYY')
      case 'month':
        return selected!.format('MMMM YYYY')
      default:
        return ''
    }
  }

  const handleTerritoriesChange = (index: number, id: string) => {
    let isAllSelected: boolean

    // click all territories option
    if (id === ALL_TERRITORY_ID) {
      isAllSelected = territoryOptions[index].hide ?? false
      setTerritoryOptions(
        territoryOptions.map((option, idx) =>
          !isAllSelected
            ? { ...option, hide: true }
            : { ...option, hide: false }
        )
      )
    } else {
      isAllSelected = territoryOptions.reduce(
        (prev: boolean, option: Options, idx: number) => {
          const val = index === idx ? option.hide : !option.hide
          return prev && (idx < 2 ? true : val ?? false)
        },
        true
      )

      setTerritoryOptions(
        territoryOptions.map((option, idx) => ({
          ...option,
          hide:
            idx === 0
              ? !isAllSelected
              : idx === index
                ? !option.hide
                : option.hide,
        }))
      )
    }
  }

  const handleTerritoryClose = () => {
    if (territoryOptions[0].hide === false) {
      setTerritoryParam('')
    } else {
      const newTerritoryParam = getTerritoryParam(territoryOptions)
      setTerritoryParam(newTerritoryParam)
    }
  }

  const handleAffiliatesChange = (index: number, id: string) => {
    let isAllSelected: boolean

    // click all territories option
    if (id === ALL_AFFILIATE_ID) {
      isAllSelected = affiliatesOptions[index].hide ?? false
      setAffiliatesOptions(
        affiliatesOptions.map((option, idx) =>
          !isAllSelected
            ? { ...option, hide: true }
            : { ...option, hide: false }
        )
      )
    } else {
      isAllSelected = affiliatesOptions.reduce(
        (prev: boolean, option: Options, idx: number) => {
          const val = index === idx ? option.hide : !option.hide
          return prev && (idx < 2 ? true : val ?? false)
        },
        true
      )

      setAffiliatesOptions(
        affiliatesOptions.map((option, idx) => ({
          ...option,
          hide:
            idx === 0
              ? !isAllSelected
              : idx === index
                ? !option.hide
                : option.hide,
        }))
      )
    }
  }

  const handleAffiliatesClose = () => {
    if (affiliatesOptions[0].hide === false) {
      setAffiliateParam('')
    } else {

      const affiliatesSelected = affiliatesOptions.filter(affiliate => !affiliate.hide).length
      if (affiliatesSelected <= 15) {
        const newAffiliateParam = getTerritoryParam(affiliatesOptions)
        setAffiliateParam(newAffiliateParam)
      } else {
        setAffiliatesOptions(
          affiliatesOptions.map((option, idx) => ({
            ...option,
            hide: false
          })))
        setAffiliateParam('')
        toast.info.call('info', 'Please select all affiliates or 15 affiliates maximum')
      }

    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 1,
        height: '42px',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        <Typography variant="h5" sx={{ fontFamily: 'var(--font-moranga)' }}>
          {getSelectedTitle()}
        </Typography>
        <FieldsFilter
          options={territoryOptions}
          handleChange={handleTerritoriesChange}
          handleSelectionDone={handleTerritoryClose}
          renderAsInput={true}
          inputText={'All Territories'}
          maxWidth="329px"
          contentAlign="left"
          popupLabel="Select territories to view"
          icon={<ExpandMoreIcon sx={{ color: '#AAACBB' }} />}
          sx={{
            height: '40px',
            width: '180px !important',
            padding: '8px 16px',
          }}
        />
        <Grid>
          <FormControlLabel control={
            <Checkbox
              defaultChecked
              onChange={() => setShowOrders(!showOrders)}
              checked={showOrders}
            />
          } label="Orders" />
          <FormControlLabel control={
            <Checkbox
              defaultChecked
              onChange={() => setShowSiteVisits(!showSiteVisits)}
              checked={showSiteVisits}
            />
          } label="Site Visits" />
        </Grid>
        {vendorUserIds.length > 0 && <Grid>
          <FormControlLabel control={
            <Checkbox
              onChange={() => setShowAssignedToMe(!showAssignedToMe)}
              checked={showAssignedToMe}
            />
          } label="Assigned to me" />
        </Grid>}
      </Box>
      <Box display="flex" gap="20px">
        {!showDispatch && (
          <ButtonGroup>
            <Button
              onClick={() => setView('day')}
              variant={(view === 'day' && 'contained') || 'outlined'}
            >
              <Typography variant="h6">Day</Typography>
            </Button>
            <Button
              onClick={() => setView('week')}
              variant={(view === 'week' && 'contained') || 'outlined'}
            >
              <Typography variant="h6">Week</Typography>
            </Button>
            <Button
              onClick={() => setView('month')}
              variant={(view === 'month' && 'contained') || 'outlined'}
            >
              <Typography variant="h6">Month</Typography>
            </Button>
          </ButtonGroup>
        )}
        <Box sx={{ display: 'flex', gap: 1 }}>
          <IconButton size="large" onClick={handlePrev}>
            <icons.ArrowBackIosNew sx={{ height: '12px', width: '12px' }} />
          </IconButton>
          <IconButton size="large" onClick={handleNext}>
            <icons.ArrowForwardIos sx={{ height: '12px', width: '12px' }} />
          </IconButton>
        </Box>

        <FieldsFilter
          options={affiliatesOptions}
          handleChange={handleAffiliatesChange}
          handleSelectionDone={handleAffiliatesClose}
          renderAsInput={true}
          inputText={'All Affiliates'}
          maxWidth="329px"
          contentAlign="left"
          popupLabel="Select affiliates to view"
          icon={<ExpandMoreIcon sx={{ color: '#AAACBB' }} />}
          sx={{
            height: '40px',
            width: '180px !important',
            padding: '8px 16px',
          }}
          allSelection={true}
        />

      </Box>
    </Box>
  )
}

export default CalendarToolbar
