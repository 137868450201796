import { authTypes } from '../auth/types'
import { CompanyActions, CompanyType, companyTypes } from "./types"

const initialState: CompanyType = null

const reducer = (state = initialState, action: CompanyActions): CompanyType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case companyTypes.SET_COMPANY: {
      return payload as CompanyType
    }

    default:
      return state
  }
}

export default reducer
