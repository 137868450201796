/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'

import { Punchlist } from '../../api'
import { errorTextTryingTo, isEmpty } from '../../helpers'
import { GetNotesAction, notesTypes } from './types'
import { notesActions } from '../actions'


export function* createNote({ payload, callback }: any): SagaIterator {
  let success = false
  try {
    delete payload.files
    const note = yield call(Punchlist.notes.createNote, payload)
    if (!isEmpty(note)) {
      yield put(notesActions.setNote(note))
      success = true
    }
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('create the note'))
    if (callback) yield call(callback, false)
  }
}

export function* getNotes({ payload, callback }: GetNotesAction): SagaIterator {
  let success = false
  try {
    const params = payload.params ?? ''

    const notes = yield call(Punchlist.notes.getNotes, { params })
    if (notes) {
      yield put(notesActions.setNotes(notes))
      success = true
    }
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the invoice preview'))
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(notesTypes.CREATE_NOTE, createNote)
  yield takeLatest(notesTypes.GET_NOTES, getNotes)
}
