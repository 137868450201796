import { Fragment, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Divider, Typography, Button, CircularProgress, Box } from 'src/components/UI'
import CostsTable from '../CostsTable'
import { PRICING_ITEM_TYPE, round } from 'src/helpers'
import ItemMargin from './ItemMargin'
import Incentive from './Incentive'
import Draw from './Draw'
import { ScrollWrapper } from 'src/components/templates'
import { DispatchPriceItem, OrderType } from 'src/ducks/orders/types'
import { orderActions } from 'src/ducks/actions'
import { getOrder } from 'src/ducks/selectors'
import { useIsXlScreen } from 'src/hooks'
import { DispatchContext } from '../../context'

const Pricing = ({ editable = true }: { editable?: boolean }) => {
  const dispatch = useDispatch()
  const { state: { loadingPricing } } = useContext(DispatchContext)

  const jobOrder = useSelector(getOrder())
  const { pricingModel, bidPrice, materialCost, prepaymentTotal, totalOwed } = jobOrder || {} as OrderType
  const { items } = pricingModel ?? {}
  const [xlScreen] = useIsXlScreen()

  const addItem = (type: PRICING_ITEM_TYPE) => {
    dispatch(orderActions.setOrder({
      ...jobOrder,
      pricingModel: {
        ...pricingModel,
        items: [
          {
            type, name: undefined, amount: 0, description: '',
            paid: false, deadline: null
          } as DispatchPriceItem
          , ...items
        ]
      }
    }))
  }

  return (
    <Box>
      <Grid
        container spacing={1}
        display='grid' height='100%'
        gridTemplateRows='min-content min-content auto min-content min-content'
      >
        <Grid item xs={12}>
          <CostsTable />
        </Grid>
        {editable && <Grid item xs={12} sx={{ display: 'flex', gap: 1 }}>
          <Button
            variant='contained'
            size='small'
            sx={{ lineHeight: 1 }}
            onClick={() => addItem(PRICING_ITEM_TYPE.INCENTIVE)}
          >
            Add Incentive
          </Button>
          <Button
            variant='contained'
            size='small'
            sx={{ lineHeight: 1 }}
            onClick={() => addItem(PRICING_ITEM_TYPE.DRAW)}
          >
            Add Draw
          </Button>
        </Grid>
        }
        {editable && <Grid item xs={12}>
          {xlScreen && <ScrollWrapper absoluteStyle={{ padding: '0 8px 0 12px' }}>
            <Grid container paddingBottom={1}>
              <Grid item xs={8}><Typography variant='body1Bold'>Name</Typography></Grid>
              <Grid item xs={4} textAlign='end'><Typography variant='body1Bold'>Amount</Typography></Grid>
              <ItemMargin />
              {pricingModel && pricingModel?.items?.map((item: DispatchPriceItem, index: number) => (
                <Fragment key={index}>
                  {item.type === PRICING_ITEM_TYPE.INCENTIVE && <Incentive position={index} item={item} />}
                  {item.type === PRICING_ITEM_TYPE.DRAW && <Draw position={index} item={item} />}
                </Fragment>
              ))}
            </Grid>
          </ScrollWrapper>}
          {!xlScreen && <Grid container>
            <Grid item xs={8}><Typography variant='body1Bold'>Name</Typography></Grid>
            <Grid item xs={4} textAlign='end'><Typography variant='body1Bold'>Amount</Typography></Grid>
            <ItemMargin />
            {pricingModel && pricingModel?.items?.map((item: DispatchPriceItem, index: number) => (
              <Fragment key={index}>
                {item.type === PRICING_ITEM_TYPE.INCENTIVE && <Incentive position={index} item={item} />}
                {item.type === PRICING_ITEM_TYPE.DRAW && <Draw position={index} item={item} />}
              </Fragment>
            ))}
          </Grid>}
        </Grid>}
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid container item xs={12} spacing={0.5} height="fit-content">
          <Grid item xs={8}><Typography variant='body1'>Total</Typography></Grid>
          <Grid item xs={4} textAlign='end'><Typography variant='body1'>{loadingPricing ? <CircularProgress color='info' size='.8rem' /> : `$${round(bidPrice, 2)}`}</Typography></Grid>
          <Grid item xs={8}><Typography variant='body1'>Paid</Typography></Grid>
          <Grid item xs={4} textAlign='end'><Typography variant='body1'>{loadingPricing ? <CircularProgress color='info' size='.8rem' /> : `$${round(prepaymentTotal, 2)}`}</Typography></Grid>
          <Grid item xs={8}><Typography variant='body1'>Materials</Typography></Grid>
          <Grid item xs={4} textAlign='end'><Typography variant='body1'>{loadingPricing ? <CircularProgress color='info' size='.8rem' /> : `$${round(materialCost, 2)}`}</Typography></Grid>
          <Grid item xs={8}><Typography variant='body1Bold'>Total Owed</Typography></Grid>
          <Grid item xs={4} textAlign='end'><Typography variant='body1Bold'>{loadingPricing ? <CircularProgress color='info' size='.8rem' /> : `$${round(totalOwed, 2)}`}</Typography></Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Pricing
