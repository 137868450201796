import { authTypes } from '../auth/types'
import { TerritoriesType, TerritoriesActions, territoriesTypes, TerritoryType } from './types'


const initialState: TerritoriesType = {
  territories: null,
}

const reducer = (state = initialState, action: TerritoriesActions): TerritoriesType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case territoriesTypes.SET_TERRITORIES: {
      return {
        ...state,
        territories: payload as unknown as TerritoryType
      }
    }
    default:
      return state
  }
}

export default reducer
