import { Box, Button, Typography } from '@mui/material'
import { Link, TextField } from 'src/components/UI'
import {
  HeaderType,
  AlignType,
} from 'src/components/UI/CustomUI/organisms/Table/types'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import SettingsIcon from '@mui/icons-material/Settings'
import DeleteIcon from '@mui/icons-material/Delete'
import CircleIconButton from 'src/components/UI/CustomUI/molecules/CircleIconButton'
import moment from 'moment'

import styles from './styles.module.scss'

const headers: HeaderType[] = [
  {
    id: 'sku',
    label: 'SKU',
    custom: true,
    align: 'left' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Box padding="2px 10px">
          <Typography className={styles.Companies__companyName}>
            {row?.sku}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'price',
    label: 'Price',
    custom: true,
    align: 'left' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Box padding="2px 10px">
          <Typography className={styles.Companies__companyName}>
            {row?.price || 0}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'quantity',
    label: 'Quantity',
    custom: true,
    align: 'left' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Box padding="2px 10px">
          <TextField value={row.quantity || 0} />
        </Box>
      )
    },
  },
  {
    id: 'action',
    label: 'Action',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Box padding="2px 10px">
          <CircleIconButton
            bgcolor="var(--red100)"
            width="40px"
            height="40px"
            icon={<DeleteIcon style={{ width: '16px', height: '16px' }} />}
            iconColor="var(--red500)"
          />
        </Box>
      )
    },
  },
]

export default headers
