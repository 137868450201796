/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, cps, takeLatest } from 'redux-saga/effects'
import env from '@beam-australia/react-env'

import { Auth } from '../../api'
import { isEmpty } from '../../helpers'
import {
  authTypes, AuthActions, ParseHashPayload, LoginPayload,
  LogoutPayload, SignupPayload, ChangePasswordPayload
} from './types'

const AUTH0_CONNECTION = env('AUTH0_CONNECTION') ?? ''

export function* loginRequest({ payload }: AuthActions): SagaIterator {
  const { email, password, remember, callback } = payload as LoginPayload

  try {
    const webAuth = Auth.getWebAuth()
    Auth.set(String(remember), 'punchlist-remember', remember)
    const result = yield cps([webAuth, webAuth.login], {
      realm: AUTH0_CONNECTION,
      email: email,
      password: password
    })
    // If the result is success, redirect before raising this callback..
    yield call(callback, !isEmpty(result))
  } catch (_error) {
    yield call(callback, false)
  }
}

export function* parseHashRequest({ payload }: AuthActions): SagaIterator {
  const { hash, callback: parseCB } = payload as ParseHashPayload
  let success = false

  try {
    const webAuth = Auth.getWebAuth()

    // TODO: use this method where applicable
    // webAuth.crossOriginVerification()

    const result = yield cps([webAuth, webAuth.parseHash], {
      hash: hash as string
    })

    if (!isEmpty(result) && !isEmpty(result.idToken)) {
      const userInfo = yield cps([webAuth.client, webAuth.client.userInfo],
        result.accessToken ?? ''
      )

      if (!isEmpty(userInfo)) {
        success = true
        const remember = Auth.get('punchlist-remember') === 'true'
        Auth.clear('punchlist-remember')
        Auth.setToken(result.idToken, remember)
        Auth.setUserInfo(JSON.stringify(userInfo), remember)
      }
    }
    yield call(parseCB, success)
  } catch (_error) {
    yield call(parseCB, false)
  }
}

export function* logoutRequest({ payload }: AuthActions): SagaIterator {
  if (payload !== null) {
    const { callback } = payload as LogoutPayload

    try {
      yield call(Auth.clearAppStorage)
      yield call(callback, true)
    } catch (_error) {
      yield call(callback, false)
    }
  }
}

export function* signupRequest({ payload }: AuthActions): SagaIterator {
  let success = false
  const { email, password, callback } = payload as SignupPayload

  try {
    const webAuth = Auth.getWebAuth()
    const signupResult = yield cps([webAuth, webAuth.signup], {
      connection: AUTH0_CONNECTION,
      email: email,
      password: password
    })

    success =
      !isEmpty(signupResult) &&
      !isEmpty(signupResult.Id) &&
      !isEmpty(signupResult.email)

    yield call(callback, success)
  } catch (error: any) {
    yield call(callback, success, {
      code: error?.code ?? '',
      policy: error?.policy ?? ''
    })
  }
}

export function* changePasswordRequest({ payload }: AuthActions): SagaIterator {
  let success = false
  const { email, callback } = payload as ChangePasswordPayload

  try {
    const webAuth = Auth.getWebAuth()
    const changePasswordResult = yield cps([webAuth, webAuth.changePassword], {
      connection: AUTH0_CONNECTION,
      email: email
    })

    success = !isEmpty(changePasswordResult)
    yield call(callback, success)
  } catch (error: any) {
    yield call(callback, success, {
      code: error?.code ?? '',
      policy: error?.policy ?? ''
    })
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(authTypes.LOGIN_REQUEST, loginRequest)
  yield takeLatest(authTypes.PARSE_HASH_REQUEST, parseHashRequest)
  yield takeLatest(authTypes.LOGOUT_REQUEST, logoutRequest)
  yield takeLatest(authTypes.SIGNUP_REQUEST, signupRequest)
  yield takeLatest(authTypes.CHANGE_PASSWORD_REQUEST, changePasswordRequest)
}
