import { Email } from '~api/punchlist/employees/types'
import { EMPLOYEE_ROLE_TYPE } from '../../helpers'
import { Address } from '../types'

/** TYPES **/
export const employeeTypes = {
  FETCH_CURRENT_EMPLOYEE: 'FETCH_CURRENT_EMPLOYEE',
  CREATE_EMPLOYEE: 'CREATE_EMPLOYEE',
  UPDATE_EMPLOYEE: 'UPDATE_EMPLOYEE',
  UPDATE_EMPLOYEE_ID: 'UPDATE_EMPLOYEE_ID',
  DELETE_EMPLOYEE_ID: 'DELETE_EMPLOYEE_ID',
  REPLACE_EMPLOYEE_ID: 'REPLACE_EMPLOYEE_ID',
  SET_VALUE: 'SET_EMPLOYEE_VALUE',
  SET_VALUES: 'SET_EMPLOYEE_VALUES',
  UPDATE_EMPLOYEE_LIST: 'UPDATE_EMPLOYEE_LIST',
  FETCH_USERS_STAFF: 'FETCH_USERS_STAFF'
}

/** ACTIONS **/
export interface FetchCurrentEmployeeAction {
  type: typeof employeeTypes.FETCH_CURRENT_EMPLOYEE
  payload: null
  callback: (succ: boolean) => void
}

export interface CreateEmployeeAction {
  type: typeof employeeTypes.CREATE_EMPLOYEE
  payload: null
  callback: (succ: boolean) => void
}

export interface UpdateEmployeeAction {
  type: typeof employeeTypes.UPDATE_EMPLOYEE
  payload: Partial<EmployeeType>
  callback: (succ: boolean) => void
}

export interface UpdateEmployeeIdAction {
  type: typeof employeeTypes.UPDATE_EMPLOYEE
  employeeId: string
  payload: Partial<EmployeeType>
  op: 'remove' | 'replace' | 'add'
  callback: (succ: boolean) => void
}

export interface UpdateEmployeeListPayload {
  attr: keyof EmployeeType
  opp?: 'add' | 'removeAll' | 'replace'
  list?: any[]
}

export interface UpdateEmployeeListAction {
  type: typeof employeeTypes.UPDATE_EMPLOYEE_LIST
  payload: UpdateEmployeeListPayload
  callback: (succ: boolean) => void
}

export interface EmployeeValue {
  attr: keyof EmployeeType
  value: any
}

export interface EmployeeSetValueAction {
  type: typeof employeeTypes.SET_VALUE
  payload: EmployeeValue
}

export interface EmployeeValues {
  attrs: Partial<EmployeeType>
}

export interface EmployeeSetValuesAction {
  type: typeof employeeTypes.SET_VALUES
  payload: EmployeeValues
}

export type EmployeeActions =
  | FetchCurrentEmployeeAction
  | CreateEmployeeAction
  | UpdateEmployeeAction
  | UpdateEmployeeIdAction
  | UpdateEmployeeListAction
  | EmployeeSetValueAction
  | EmployeeSetValuesAction

/** REDUCER **/
export interface EmployeeRole {
  roleName: string
  roleType: EMPLOYEE_ROLE_TYPE
  territoryId?: string
}

export interface EmployeeType {
  id: string
  email: Email[]

  firstName: string
  middleName: string
  lastName: string
  phone: number | null

  address: Address
  picture: string
  territories?: string[]
  registrationComplete: boolean
  type: string
  roles: EmployeeRole[]
  approved: boolean
  admin: boolean
}
