import { FC, useEffect, useRef, useState } from 'react'
import DatePicker from 'src/components/UI/CustomUI/atoms/DatePicker'
import { useDispatch, useSelector } from 'react-redux'
import {
  ActionsButton,
  Box,
  Button,
  FieldsFilter,
  Grid,
  IconButton,
  InputSearch,
  MenuItem,
  Select,
} from 'src/components/UI'
import { filtersActions } from 'src/ducks/actions'
import { getSubscriptionsFilter } from 'src/ducks/filters/selector'
import { SubscriptionsFilterType } from 'src/ducks/filters/types'
import { FilterHeaderProps } from './types'
import { dateFormatString, formatTimestamp, headerCSVParser } from 'src/helpers'
import { giftedStatus, subscriptionStatus } from './constants'
import ExportCSV from 'src/components/UI/CustomUI/molecules/ExportCSV'

import RefreshIcon from '@mui/icons-material/Refresh'
import { getCsvSubscriptions } from 'src/ducks/subscriptions/selectors'
import { SubscriptionItem } from 'src/ducks/types'
import { Email } from '~api/punchlist/employees/types'
import { getLastService, getNextService } from '../headers'
import { CSVLink } from 'react-csv'
import { icons } from 'src/assets'

const FilterHeader: FC<FilterHeaderProps> = ({
  headers,
  setHeaders,
  fetchData,
  loadingCsv,
  fetchCsv
}) => {
  const dispatch = useDispatch()

  const subscriptionsFilter = useSelector(getSubscriptionsFilter())
  const csvSubscriptions = useSelector(getCsvSubscriptions)
  const downloadRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null)

  const { search, dateStart, dateEnd, status, gifted, downloadCSVCount } =
    subscriptionsFilter

  const [refresh, setRefresh] = useState<boolean>(false)
  const [dates, setDates] = useState<{
    dateStart: string | null
    dateEnd: string | null
  }>({
    dateStart: dateStart || null,
    dateEnd: dateEnd || null,
  })

  const headersCSV: any = headerCSVParser(headers)
  const dataCSV = csvSubscriptions.reduce(
    (acc: any, curr: SubscriptionItem) => {

      const subscription = {
        ...curr,
        name: `${curr.homeowner?.firstName} ${curr.homeowner?.lastName}` || '',
        email: (curr.homeowner?.email[0] as Email).email || '',
        phone: curr.homeowner?.phone || '',
        address: curr.propertyAddress?.fullAddress || '',
        territory: curr.territory?.title || '',
        subStart: formatTimestamp(curr?.contractSignedOn, 'MM/DD/YYYY'),
        subEnd: formatTimestamp(curr?.expiresOn, 'MM/DD/YYYY'),
        purchasedBy: `${curr?.purchaser.firstName} ${curr?.purchaser.lastName}` || '',
        lastService: getLastService(curr)?.title || '',
        nextService: getNextService(curr)?.title || '',
        status: curr.status,
        giftStatus:
          curr.gift === false
            ? 'None'
            : curr.giftBox?.trackingId
              ? 'Sent'
              : 'Not Sent',
      }
      acc = [...acc, subscription]
      return acc
    },
    []
  )

  useEffect(() => {
    if (refresh === true) {
      fetchData()
    }
    setRefresh(false)
  }, [fetchData, refresh])

  const updateSubscriptionsFilter = (
    newFilter: Partial<SubscriptionsFilterType>
  ) => {
    dispatch(
      filtersActions.setFilters({
        subscriptions: {
          ...subscriptionsFilter,
          ...newFilter,
        },
      })
    )
  }

  const onTableFilterChange = (index: number): void => {
    setHeaders(
      headers.map((item, indx) => {
        if (indx === index) {
          return { ...item, hide: !item.hide }
        }
        return item
      })
    )
  }

  const handleStatusChange = (index: number, id: any): void => {
    const curStatus = status || subscriptionStatus
    updateSubscriptionsFilter({
      ...subscriptionStatus,
      status: curStatus.map((st) =>
        st.id === id ? { ...st, hide: !st.hide } : st
      ),
    })
  }

  const handleGiftStatusChange = (index: number, id: any): void => {
    const curGiftStatus = gifted || giftedStatus
    updateSubscriptionsFilter({
      ...subscriptionStatus,
      gifted: curGiftStatus.map((st) =>
        st.id === id ? { ...st, hide: !st.hide } : st
      ),
    })
  }

  return (
    <Box>
      <Grid
        container
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Grid container item spacing={2} flex={1}>
          <Grid item gap={2} display="flex" alignItems="flex-end">
            <InputSearch
              label="Search for Subscription:"
              placeholder="Search for Subscription"
              onChange={(value) => {
                updateSubscriptionsFilter({ search: value })
              }}
              value={search}
              sx={{ width: '100%' }}
            />
            <DatePicker
              placeholder="mm/dd/yyyy"
              onChange={(dateSelected: Date) => {
                setDates({
                  ...dates,
                  dateStart: dateFormatString(dateSelected),
                })
              }}
              value={dates?.dateStart ? new Date(dates?.dateStart) : null}
              style={{ height: '38px', padding: '0px', width: '100%' }}
              size="small"
              label="Date Start:"
            />
            <DatePicker
              placeholder="mm/dd/yyyy"
              onChange={(dateSelected: Date) => {
                setDates({
                  ...dates,
                  dateEnd: dateFormatString(dateSelected),
                })
              }}
              value={dates?.dateEnd ? new Date(dates?.dateEnd) : null}
              style={{ height: '38px', padding: '0px', width: '100%' }}
              size="small"
              label="Date End:"
            />
            <Button
              variant="contained"
              sx={{ minWidth: '100px !important' }}
              onClick={() => {
                updateSubscriptionsFilter({
                  dateEnd: dates.dateEnd,
                  dateStart: dates.dateStart,
                })
              }}
            >
              Apply
            </Button>
            <Button
              variant="outlined"
              sx={{ minWidth: '100px !important' }}
              onClick={() => {
                setDates({
                  dateStart: null,
                  dateEnd: null,
                })
              }}
            >
              Reset dates
            </Button>
            <IconButton
              style={{
                marginLeft: '10px',
                marginTop: '5px',
              }}
              onClick={() => setRefresh(true)}
            >
              <RefreshIcon />
            </IconButton>
            <FieldsFilter
              flexColumn
              handleChange={handleStatusChange}
              inputText="All Statuses"
              options={status || subscriptionStatus}
              renderAsInput
              label="Status:"
              popupLabel="Select Status to view"
              variant="body1"
            />
            <FieldsFilter
              flexColumn
              handleChange={handleGiftStatusChange}
              inputText="Gift Status"
              options={gifted || giftedStatus}
              renderAsInput
              label="Gift:"
              popupLabel="Select Gift Status to view"
              variant="body1"
            />
            <FieldsFilter
              maxWidth="400px"
              // options={tableHeaders}
              options={headers}
              handleChange={onTableFilterChange}
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={1.5}
          spacing={1}
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <Grid container item spacing={2} justifyContent="flex-end">
            <Grid item>
              <ActionsButton
                loading={loadingCsv}
                icon={<icons.CloudDownload color='disabled' />}
                iconPosition='end'
                onClick={() => fetchCsv(() => downloadRef?.current?.link?.click())}
                sx={{
                  minWidth: '56px!important',
                }}
              />
              <ExportCSV
                innerRef={downloadRef}
                headers={headersCSV}
                data={dataCSV}
                fileName={'subscriptions.csv'}
                label=''
              />
            </Grid>
            <Grid item>
              <Select
                value={downloadCSVCount || 400}
                label={`${downloadCSVCount || 400}`}
                style={{ width: '105px' }}
                onChange={(e) =>
                  updateSubscriptionsFilter({
                    downloadCSVCount: Number(e.target.value),
                  })
                }
              >
                <MenuItem value={400}> 400 </MenuItem>
                <MenuItem value={200}> 200 </MenuItem>
                <MenuItem value={100}> 100 </MenuItem>
                <MenuItem value={50}> 50 </MenuItem>
              </Select>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default FilterHeader
