import { Box, Grid, Typography } from '@mui/material'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import Tag from 'src/components/UI/CustomUI/atoms/Tag'
import ShowMore from 'src/components/UI/CustomUI/atoms/ShowMore'
import { AlignType, HeaderType } from 'src/components/UI/CustomUI/organisms/Table/types'
import { INSURANCE_TYPE, formatTimestamp, vstagesTagColor } from 'src/helpers'
import { getTradeColor } from 'src/helpers/trades'
import TradesHeader from '../TradesHeader'
import { Insurance } from 'src/ducks/types'

const headers: HeaderType[] = [
  {
    id: 'pro',
    label: 'Pro',
    custom: true,
    Element: (row: any): JSX.Element => {
      return <Grid>
        <Typography variant='body1Bold'>
          {row?.firstName} {row?.lastName}
        </Typography>
        <Typography>
          {row?.companyName}
        </Typography>
      </Grid>
    }
  },
  {
    id: 'email',
    label: 'Email',
    custom: true,
    Element: (row: any): JSX.Element => {
      return <Grid>
        <Typography fontSize="var(--font-XXXS)">
          {row?.email[0]?.email}
        </Typography>
      </Grid>
    }
  },
  { id: 'phone', label: 'Phone' },
  {
    id: 'insertedOn',
    label: 'Created',
    custom: true,
    Element: (row: any): JSX.Element => {
      return <Grid>
        <Typography variant='body1Bold'>
          Created:
        </Typography>
        <Typography>
          {formatTimestamp(Math.floor(row?.insertedOn), 'M/D/YYYY hh:mm a')}
        </Typography>
      </Grid>
    }
  },
  {
    id: 'territoryList',
    label: <Grid display="flex" flexDirection="column"><Typography variant="body1Bold">Territories</Typography><Typography variant="body1Bold">& Capacities</Typography> </Grid>,
    labelCSV: 'Territories & Capacities',
    custom: true,
    maxWidth: 140,
    Element: (row: any): JSX.Element => {
      const territories = row?.territoryList.filter((territory: any) => territory.title).reduce((acc: any, curr: any) => acc.concat(`${curr.title}: ${curr.capacity || '0'}`), [])
      return (
        <Box display='flex' flexDirection='column' gap={1}>
          <ShowMore
            limit={3}
            buttonSx={{ width: '100%' }}
            tooltipContent={
              <Box gap={1}>
                {territories.map((territory: any) => <Typography key={territory}>{territory}</Typography>)}
              </Box>
            }
          >
            {territories.map((territory: any) =>
              <Tag width='100%' center>{territory}</Tag>
            )}
          </ShowMore>
        </Box>
      )
    },
  },
  {
    id: 'registrationComplete',
    label: <Grid display="flex" flexDirection="column"><Typography variant="body1Bold">Registration</Typography><Typography variant="body1Bold">Complete</Typography> </Grid>,
    labelCSV: 'Registration Complete',
    custom: true,
    maxWidth: 80,
    Element: (row: any): JSX.Element => {
      return row?.registrationComplete ? <Icon name='CheckCircle' color="var(--success-color)" /> : <Icon color="var(--error-color)" name='Cancel' />
    }
  },
  {
    id: 'documentsComplete',
    label: <Grid display="flex" flexDirection="column"><Typography variant="body1Bold">Documents</Typography><Typography variant="body1Bold">Complete</Typography> </Grid>,
    labelCSV: 'Documents Complete',
    custom: true,
    maxWidth: 80,
    Element: (row: any): JSX.Element => {
      return row?.documentsComplete ? <Icon name='CheckCircle' color="var(--success-color)" /> : <Icon color="var(--error-color)" name='Cancel' />
    }
  },
  {
    id: 'approved',
    label: 'Approved',
    custom: true,
    maxWidth: 80,
    Element: (row: any): JSX.Element => {
      return row?.approved ? <Icon name='CheckCircle' color="var(--success-color)" /> : <Icon color="var(--error-color)" name='Cancel' />
    }
  },
  {
    id: 'tradeList',
    label: <TradesHeader />,
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      const trades = row?.tradeList.reduce((acc: any, curr: any) => acc.concat({
        label: curr?.tradeType?.displayName,
        level: curr?.level
      }), [])
      return (
        <Box display="flex" flexDirection="column" gap={1}>
          <ShowMore
            limit={4}
            buttonSx={{ width: "100%" }}
            tooltipContent={
              <Box gap={1}>
                {trades.map((trade: any) => <Typography key={trade.label}>{`L${trade.level}: ${trade.label}`}</Typography>)}
              </Box>
            }
          >
            {trades.map((trade: any) =>
              <Tag width="100%" center background={getTradeColor(trade.level)}>{` L${trade.level}: ${trade.label}`}</Tag>
            )}
          </ShowMore>
        </Box>
      )
    }
  },
  {
    id: 'gl',
    label: 'GL',
    custom: true,
    Element: (row: any): JSX.Element => {
      const territoriesGL = row?.territoryList.filter((territory: any) => territory.validGL && territory.title).reduce((acc: any, curr: any) => {
        return acc.concat(curr.title)
      }, [])
      return territoriesGL.map((item: any) => <Typography>x {item}</Typography>)
    }
  },
  {
    id: 'wc',
    label: 'WC',
    custom: true,
    Element: (row: any): JSX.Element => {
      const territoriesWC = row?.territoryList.filter((territory: any) => territory.validWC && territory.title).reduce((acc: any, curr: any) => {
        return acc.concat(curr.title)
      }, [])
      return territoriesWC.map((item: any) => <Typography>x {item}</Typography>)
    }
  },
  {
    id: 'bgPassed',
    label: 'BG Passed',
    custom: true,
    Element: (row: any): JSX.Element => {
      if (!row?.bgPassed) return <></>
      return row?.bgPassed ? <Icon name='CheckCircle' color="var(--success-color)" /> : <Icon color="var(--error-color)" name='Cancel' />
    },
  },
  {
    id: 'verificationStage',
    label: 'V. Stage',
    custom: true,
    Element: (row: any): JSX.Element => {
      const { color, background } = vstagesTagColor(row?.verificationStage)
      return (
        <Grid container alignItems="center" spacing={1} direction="column">
          <Grid item width="100%" display="flex" justifyContent="center">
            <Tag center color={color} width="fit-content" background={background}>{row?.verificationStage} </Tag>
          </Grid>
        </Grid>
      )
    },
  },
  {
    id: 'orders',
    label: 'Orders',
    custom: true,
    Element: (row: any): JSX.Element => {
      return (
        <Grid display="flex" flexDirection="column" gap={.5}>
          <Typography sx={{ display: 'flex', flexWrap: 'nowrap', gap: '.3rem' }} variant="body1Bold" color="var(--blue700)"> Active: <Typography color="var(--navy700)"> {row?.activeOrders?.length}</Typography></Typography>
          <Typography sx={{ display: 'flex', flexWrap: 'nowrap', gap: '.3rem' }} variant="body1Bold" color="var(--blue800)"> In Review: <Typography color="var(--navy700)"> {row?.workOrderCountReview}</Typography></Typography>
          <Typography sx={{ display: 'flex', flexWrap: 'nowrap', gap: '.3rem' }} variant="body1Bold" color="var(--red700)"> Rejected: <Typography color="var(--navy700)"> {row?.rejectedWorkOrderCount}</Typography></Typography>
        </Grid>
      )

    },
  },

  {
    id: 'completedMTD',
    label: <Grid display="flex" flexDirection="column"><Typography variant="body1Bold">Completed</Typography><Typography variant="body1Bold">MTD & YTD</Typography> </Grid>,
    labelCSV: 'Completed MTD & YTD',
    align: 'center' as AlignType,
    custom: true,
    Element: (row: any): JSX.Element => {
      return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <Typography sx={{ display: 'flex', flexWrap: 'nowrap', gap: '.3rem' }} variant="body1Bold" color="var(--blue700)"> MTD: <Typography color="var(--navy700)"> {row?.completedMTD}</Typography></Typography>
          <Typography sx={{ display: 'flex', flexWrap: 'nowrap', gap: '.3rem' }} variant="body1Bold" color="var(--blue800)"> YTD: <Typography color="var(--navy700)"> {row?.completedYTD}</Typography></Typography>
        </Box>
      )
    },
  },
  {
    id: 'workOrderCountCompleted',
    label: <Grid display="flex" flexDirection="column"><Typography variant="body1Bold">Total</Typography><Typography variant="body1Bold">Jobs</Typography> </Grid>,
    labelCSV: 'Total Jobs',
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Typography sx={{ display: 'flex', flexWrap: 'nowrap', gap: '.3rem' }} variant="body1Bold" color="var(--blue700)"> MTD: <Typography color="var(--navy700)"> {row?.workOrderCount}</Typography></Typography>
      )
    },
  },
  {
    id: 'referral',
    label: 'Referral',
    custom: true,
    Element: (row: any): JSX.Element => {
      return <Grid>
        <Typography fontSize="var(--font-XXXS)">
          {row?.referral}
        </Typography>
      </Grid>
    }
  },
  {
    id: 'certificate',
    label: 'Certificate of Insurance',
    custom: true,
    minWidth: 100,
    Element: (row: any): JSX.Element => {
      const certificate = row.insuranceList?.filter((insurance: Insurance) => insurance.insuranceType === INSURANCE_TYPE.CERTIFICATE_OF_INSURANCE).sort((a: Insurance, b: Insurance) => b.validToDate - a.validToDate)[0]
      return <Grid>
        <Typography>
          {certificate?.validToDate && formatTimestamp(Math.floor(certificate?.validToDate), 'M/D/YYYY')}
        </Typography>
      </Grid>
    }
  },
  {
    id: 'Workers Compensation Insurance',
    label: 'Workers Compensation Insurance',
    custom: true,
    minWidth: 100,
    Element: (row: any): JSX.Element => {
      const certificate = row.insuranceList?.filter((insurance: Insurance) => insurance.insuranceType === INSURANCE_TYPE.WORKERS_COMPENSATION).sort((a: Insurance, b: Insurance) => b.validToDate - a.validToDate)[0]
      return <Grid>
        <Typography>
          {certificate?.validToDate && formatTimestamp(Math.floor(certificate?.validToDate), 'M/D/YYYY')}
        </Typography>
      </Grid>
    }
  },
  {
    id: 'Other Insurance',
    label: 'Other Insurance',
    custom: true,
    minWidth: 100,
    Element: (row: any): JSX.Element => {
      const certificate = row.insuranceList?.filter((insurance: Insurance) => insurance.insuranceType === INSURANCE_TYPE.OTHER).sort((a: Insurance, b: Insurance) => b.validToDate - a.validToDate)[0]
      return <Grid>
        <Typography>
          {certificate?.validToDate && formatTimestamp(Math.floor(certificate?.validToDate), 'M/D/YYYY')}
        </Typography>
      </Grid>
    }
  },
]

export default headers
