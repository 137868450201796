import { ClientPriceModel } from '../types'

/** TYPES **/
export const clientTypes = {
  FETCH_CLIENT_PRICE_MODEL: 'FETCH_CLIENT_PRICE_MODEL',
  SET_CLIENT_PRICE_MODEL: 'SET_CLIENT_PRICE_MODEL',
}

/** ACTIONS **/
export interface FetchClientAction {
  type: typeof clientTypes.FETCH_CLIENT_PRICE_MODEL
  callback?: (succ: boolean) => void
}

export interface SetPriceModelAction {
  type: typeof clientTypes.SET_CLIENT_PRICE_MODEL
  payload: ClientPriceModel
}

export type PriceModelActions =
  | FetchClientAction
  | SetPriceModelAction


export interface ClientValue {
  attr: keyof ClientPriceModel
  value: Partial<ClientPriceModel>
}

/** REDUCER **/
export type PriceModelType = ClientPriceModel
