
import { useSelector } from 'react-redux'
import { StatusBoxProps } from 'src/components/UI/CustomUI/molecules/StatusBox/types'
import { ESTIMATE_VIEW, ESTIMATE_VIEW_BETTER_COLORS_TYPES, ESTIMATE_VIEW_COLORS_APPROVED, ESTIMATE_VIEW_COLORS_PRIORITY, ESTIMATE_VIEW_COLORS_TYPES, ITEM_STATUS, STATUS, capitalize, isPercentageOf, isRoundPercentageOf, round } from 'src/helpers'
import { getCompanies } from '../companies/selectors'
import { getTerritorySuccessManagers } from '../selectors'
import storeInfo from '../store'
import { EstimateItem, Item } from '../types'

import { EstimatesType } from './types'
import { getEstimateItemsGroupBy } from '../estimateItems/selectors'

export const getEstimates = () => (): EstimatesType => {
  const { estimates } = storeInfo.store.getState()
  return estimates
}

export const getTotalEstimates = () => {
  const { estimates } = storeInfo.store.getState()
  return estimates ? Number(estimates.total) : 0
}

export const getEstimatesBoxes = () => (): StatusBoxProps[] => {
  const { estimates: { counts } } = storeInfo.store.getState()
  const depositPending = counts?.find((count: { status: string }) => count.status?.includes(STATUS.DEPOSIT_PENDING))
  const recalled = counts?.find((count: { status: string }) => count.status?.includes(STATUS.RECALLED))
  const scheduling = counts?.find((count: { status: string }) => count.status?.includes(STATUS.SCHEDULING))
  const pending = counts?.find((count: { status: string }) => count.status?.includes(STATUS.DISPATCH_PENDING))
  const dispatched = counts?.find((count: { status: string }) => count.status?.includes(STATUS.DISPATCHED))
  const review = counts?.find((count: { status: string }) => count.status?.includes(STATUS.REVIEW))
  const complete = counts?.find((count: { status: string }) => count.status?.includes(STATUS.COMPLETE))
  const closed = counts?.find((count: { status: string }) => count.status?.includes(STATUS.CLOSED))
  const notInvoiced = counts?.find((count: { status: string }) => count.status?.includes(STATUS.NOT_INVOICED))
  const invoicedSent = counts?.find((count: { status: string }) => count.status?.includes(STATUS.INVOICE_SENT))
  const partialPay = counts?.find((count: { status: string }) => count.status?.includes(STATUS.PARTIAL_PAYMENT))
  const collection = counts?.find((count: { status: string }) => count.status?.includes(STATUS.IN_COLLECTION))

  const data: StatusBoxProps[] = [
    {
      statName: 'Active',
      statValue: depositPending && recalled && scheduling && pending && dispatched
        ? depositPending.count + recalled.count + scheduling.count + pending.count + dispatched.count
        : 0,
      borderColor: 'var(--orange700)',
      minWidth: '310px',
      firstColumn: [depositPending ? depositPending.count.toString() : '-', 'Deposit Pending',
      recalled ? recalled.count.toString() : '-', 'Recalled'],
      sndColumn: [dispatched ? dispatched.count.toString() : '-', 'Dispatched',
      scheduling ? scheduling.count.toString() : '-', 'Scheduling'],
      layer: 0,
      thirdColumn: [pending ? pending.count.toString() : '-', 'Dispatch Pending',
        '', '']
    },
    { statName: 'Scheduling', statValue: scheduling ? scheduling.count : 0, borderColor: 'var(--lightBlue700)', layer: 1 },
    { statName: 'Dispatch Pending', statValue: pending ? pending.count : 0, borderColor: 'var(--lightBlue700)', layer: 1 },
    { statName: 'Dispatched', statValue: dispatched ? dispatched.count : 0, borderColor: 'var(--lightBlue700)', layer: 1 },
    { statName: 'Review', statValue: review ? review.count : 0, borderColor: 'info', layer: 1 },
    {
      statName: 'Complete',
      statValue: complete ? complete.count : 0,
      borderColor: 'success',
      minWidth: '310px',
      firstColumn: [notInvoiced ? notInvoiced.count.toString() : '-', 'Not invoiced',
      invoicedSent ? invoicedSent.count.toString() : '-', 'Invoice Sent'],
      sndColumn: [partialPay ? partialPay.count.toString() : '-', 'Partial Payments',
      collection ? collection.count.toString() : '-', 'Collections'],
      layer: 0,
    },
    { statName: 'Closed', statValue: closed ? closed.count : 0, layer: 1 }
  ]
  return data
}

export const getEstimatesForTable = () => (): EstimateItem[] => {
  const { estimates } = storeInfo.store.getState()
  if (!estimates) return []

  const territorySuccessManagers = useSelector(getTerritorySuccessManagers)
  const affiliatesStages = useSelector(getCompanies)

  return estimates.items.map((item: EstimateItem) => {
    const csm = territorySuccessManagers.find(t => t.id === item.properties?.territory?.successManager)
    const affiliate = item.properties.affiliate
      ? affiliatesStages.find(a => a.affiliateId?.toLowerCase() === item.properties.affiliate?.toLowerCase())
      : null

    return {
      ...item,
      totalValue: item.properties.totalValue,
      fullAddress: item.properties.fullAddress,
      city: item.properties?.address?.city,
      territory: item.properties?.territory?.title,
      affiliate: affiliate ? affiliate.name : '-',
      affiliateType: item.properties.affiliateType,
      territoryManager: item.properties?.territory?.territoryManager,
      client: item.properties?.contacts && item.properties?.contacts?.length > 0 ? item.properties?.contacts[0]?.fullName : '',
      publicId: item.properties.publicId,
      csm: csm ? csm.firstName + ' ' + csm.lastName : ''
    }
  })
}

export const getRelatedEstimates = () => (): EstimateItem[] => {
  const { estimates: { relatedItems } } = storeInfo.store.getState()

  return relatedItems
}

export const getEstimateChartData = (
  selectedType: ESTIMATE_VIEW,
  hideRemoved: boolean,
  showBetterStyle?: boolean
): any => {
  const estimates = getEstimateItemsGroupBy(selectedType, hideRemoved)()

  const data = {
    labels: [''],
    datasets: [
      {
        label: '',
        backgroundColor: [''],
        labelColor: [''],
        hoverOffset: 1,
        data: [0]
      }
    ]
  }

  let colors: string[]
  let colorLabels: string[]
  let labels: string[]
  let totalData: number[]
  let totalAmount: number

  switch (selectedType) {
    case ESTIMATE_VIEW.APPROVED:
      colors = []
      colorLabels = []
      labels = []
      totalData = []
      Object.keys(estimates).forEach((item: string) => {
        let total = 0
        colors.push(
          item === 'Approved'
            ? (showBetterStyle
              ? ESTIMATE_VIEW_COLORS_APPROVED.ApprovedBetter
              : ESTIMATE_VIEW_COLORS_APPROVED.Approved
            )
            : ESTIMATE_VIEW_COLORS_APPROVED.Rejected
        )
        colorLabels.push(item === 'Approved' && !showBetterStyle ? '#1A2A55' : '#FFF')
        estimates[item].forEach((element: Item) => {
          total += element.totalPrice
        })
        totalData.push(total)
        labels.push(`${capitalize(item)}:`)
      })
      data.datasets[0].backgroundColor = colors
      totalAmount = 0
      totalData.forEach(amount => (totalAmount += amount))
      data.datasets[0].data = totalData.map((item, index) => {
        labels[index] += ` $${round(item, 2)} (${isPercentageOf(item, totalAmount)}%)`
        return isPercentageOf(item, totalAmount)
      })
      data.labels = labels
      data.datasets[0].labelColor = colorLabels
      return data

    case ESTIMATE_VIEW.PRIORITY:
      colors = []
      colorLabels = []
      labels = []
      totalData = []
      Object.keys(estimates).forEach(item => {
        let total = 0
        switch (item) {
          case 'Urgent':
            colors.push(ESTIMATE_VIEW_COLORS_PRIORITY.Urgent)
            colorLabels.push('#FFF')
            break
          case 'High':
            colors.push(ESTIMATE_VIEW_COLORS_PRIORITY.High)
            colorLabels.push('#FFF')
            break
          case 'Medium':
            colors.push(ESTIMATE_VIEW_COLORS_PRIORITY.Medium)
            colorLabels.push('#1A2A55')
            break
          case 'Low':
            colors.push(ESTIMATE_VIEW_COLORS_PRIORITY.Low)
            colorLabels.push('#1A2A55')
            break
          case 'Cosmetic':
            colors.push(ESTIMATE_VIEW_COLORS_PRIORITY.Cosmetic)
            colorLabels.push('#FFF')
            break
          default:
            colors.push(ESTIMATE_VIEW_COLORS_PRIORITY.Cosmetic)
            colorLabels.push('#FFF')
            break
        }
        estimates[item].forEach((element: Item) => {
          if (element.status !== ITEM_STATUS.REJECTED) {
            total += element.totalPrice
          }
        })
        totalData.push(total)
        labels.push(`${capitalize(item)}:`)
      })
      data.datasets[0].backgroundColor = colors
      totalAmount = 0
      totalData.forEach(amount => (totalAmount += amount))
      data.datasets[0].data = totalData.map((item, index) => {
        labels[index] += ` $${round(item, 2)} (${isPercentageOf(item, totalAmount)}%)`
        return isPercentageOf(item, totalAmount)
      })
      data.labels = labels
      data.datasets[0].labelColor = colorLabels
      return data

    case ESTIMATE_VIEW.TYPE:
      colors = []
      colorLabels = []
      labels = []
      totalData = []
      Object.keys(estimates).forEach((item, index) => {
        let total = 0
        colors.push(showBetterStyle
          ? ESTIMATE_VIEW_BETTER_COLORS_TYPES[index]
          : ESTIMATE_VIEW_COLORS_TYPES[index])
        estimates[item].forEach((element: Item) => {
          if (element.status !== ITEM_STATUS.REJECTED) {
            total += element.totalPrice
          }
        })
        colorLabels.push('#FFF')
        totalData.push(total)
        labels.push(`${capitalize(item)}:`)
      })
      data.datasets[0].backgroundColor = colors
      totalAmount = 0
      totalData.forEach(amount => (totalAmount += amount))
      data.datasets[0].data = totalData.map((item, index) => {
        labels[index] += ` $${round(item, 2)} (${isRoundPercentageOf(item, totalAmount)}%)`
        return isRoundPercentageOf(item, totalAmount)
      })
      data.labels = labels
      data.datasets[0].labelColor = colorLabels
      return data

    default:
      return data
  }
}
