import { HeaderType } from 'src/components/UI/CustomUI/organisms/Table/types'

const headers: HeaderType[] = [
    { id: 'id', label: '#' },
    { id: 'customer', label: 'Customer' },
    { id: 'address', label: 'Address' },
    { id: 'amount', label: 'Amount' },
    { id: 'status', label: 'Status' },
    { id: 'notes', label: 'Notes' },
    { id: 'payment', label: 'Payment' },
    { id: 'pros', label: 'Pros' },
    { id: 'flags', label: 'Flags' },
]

export default headers
