import { useSelector } from 'react-redux'
import { getJob } from 'src/ducks/selectors'
import { JobItem } from 'src/ducks/types'
import { LabeledText } from 'src/components/UI'
import { ContentBox, Divider, TabBox } from '../../Templates'
import { useIsLgScreen, useIsXlScreen } from 'src/hooks'

const PLContact = () => {
  const job = useSelector(getJob())
  const {
    territoryManagerName,
    successManagerName,
    supervisorName,
    businessDevelopmentManagerName,
    superintendentName
  } = job || {} as JobItem
  const [xlScreen] = useIsXlScreen()
  const [lgScreen] = useIsLgScreen()

  return (
    <TabBox>
      <ContentBox>
        <LabeledText labelValue='Estimator:' textValue="" />
      </ContentBox>
      <Divider />
      <ContentBox gridTemplateColumns={xlScreen || lgScreen ? '1fr 1fr' : '1fr'}>
        <LabeledText labelValue='Territory Manager:' textValue={territoryManagerName} />
        <LabeledText labelValue='Success Manager:' textValue={successManagerName} />
        <LabeledText labelValue='Supervisor:' textValue={supervisorName} />
        <LabeledText labelValue='Client person:' textValue="" />
        <LabeledText labelValue='Superintendent:' textValue={superintendentName} />
        <LabeledText labelValue='BD Manager:' textValue={businessDevelopmentManagerName} />
      </ContentBox>
      <Divider />
      <ContentBox>
        <LabeledText labelValue='Client Project Manager:' textValue="" />
      </ContentBox>
    </TabBox>
  )
}

export default PLContact
