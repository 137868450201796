import { authTypes } from '../auth/types'
import { InspectionCompany } from '../types'
import {
  InspectionCompanyActions,
  InspectionCompanyType,
  inspectionCompanyTypes,
} from './types'

const initialState: InspectionCompanyType = null
const reducer = (
  state = initialState,
  action: InspectionCompanyActions
): InspectionCompanyType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case inspectionCompanyTypes.SET_INSPECTION_COMPANY: {
      return payload as InspectionCompany
    }

    default:
      return state
  }
}

export default reducer
