import { Button, CircularProgress, Grid, Typography } from 'src/components/UI'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { PrivateLoader } from 'src/components/templates'
import { EmployeeType } from 'src/ducks/employee/types'
import { commentsActions } from 'src/ducks/actions'
import { getComments } from 'src/ducks/comments/selectors'
import { getEmployees, getEmployeesForDropdown, getTotalComments } from 'src/ducks/selectors'
import { isEmpty, ROWS_PER_PAGE_EXTENDED } from 'src/helpers'
import CommentItem from './Comment'
import TablePagination from 'src/components/UI/CustomUI/organisms/Table/TablePagination'

import { MentionsInput, Mention } from 'react-mentions'

const Comments: FC = () => {

  const dispatch = useDispatch()
  const { id } = useParams()

  const comments = useSelector(getComments())
  const employeesForDropDown = useSelector(getEmployeesForDropdown)
  const employees = useSelector(getEmployees)

  const [comment, setComment] = useState('')
  const [buttonLoading, setButtonLoading] = useState(false)
  const [tableLoading, setTableLoading] = useState(false)
  const [addCommentLoading, setAddCommentLoading] = useState(false)
  const isValid = !isEmpty(comment)

  const totalComments = useSelector(getTotalComments)

  const fetchComments = (page?: number, size?: number) => {
    setTableLoading(true)
    dispatch(
      commentsActions.fetchComments({ page, size, params: { jobId: id } }, (_succ: boolean) => {
        setTableLoading(false)
      })
    )
  }

  const handleCreateComment = () => {
    setButtonLoading(true)
    setAddCommentLoading(true)
    const data: { recipientsList: any[], comment: string } = {
      recipientsList: [],
      comment: ''
    }
    comment?.split(/[{}]+/).forEach((item) => {
      if (item.includes('ID')) {
        const userId = item?.split('ID:')[1]
        const user = employees?.find((employee: EmployeeType) => employee?.id === userId)
        if (user?.id || userId) {
          data?.recipientsList?.push({
            id: user?.id || userId,
            userId: user?.id || userId,
            name: `${user?.firstName} ${user?.lastName}`
          })
        }
      } else {
        data.comment += item
      }
    })
    if (isValid) {
      const request = {
        message: data?.comment,
        recipientsList: data?.recipientsList,
        estimateId: id,
        jobId: id
      }
      // if (data?.recipientsList.length > 0) {
        dispatch(
          commentsActions.createComment(request,
            () => {
              setComment('')
              setButtonLoading(false)
              setAddCommentLoading(false)
              fetchComments()
            })
        )
      // }
    }
  }

  useEffect(() => {
    fetchComments()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <>
      <PrivateLoader loading={addCommentLoading}>
        <Grid container direction='column' spacing={2} >
          <Grid container item spacing={1} direction='column'>
            <Grid item>
              <Typography variant='h6Bold'>Add comment</Typography>
              <MentionsInput
                placeholder="Add comment. Use '@' to tag a user."
                value={comment}
                onChange={(e: any, newValue) => setComment(e.target.value)}
                className='Comments__textarea'
                cols={4}
                rows={3}
                style={{
                  input: {
                    border: '2px solid #EBECEF',
                    borderRadius: '8px',
                    height: 'auto',
                    position: 'relative',
                    padding: '.5rem'
                  },
                  suggestions: {
                    border: '2px solid #EBECEF',
                    borderRadius: '8px',
                    height: '30vh',
                    overflowY: 'scroll',
                    minWidth: '15vw',
                    padding: '0'
                  },
                  item: {
                    padding: '0 !important'
                  },
                  highlighter: {
                  },
                  control: {
                  }

                }}
              >
                <Mention
                  markup='{{@__display__}} {{ID:__id__}}'
                  trigger='@'
                  data={employeesForDropDown}
                  renderSuggestion={(suggestion: any) => {
                    return (
                      <Grid padding={1}>
                        <Typography variant='body1Bold'>{suggestion?.display}</Typography>
                        <Typography variant='body1'>{suggestion?.email}</Typography>
                      </Grid>
                    )
                  }}
                />
              </MentionsInput>
            </Grid>
            <Grid item>
              <Button
                variant='contained'
                onClick={handleCreateComment}
                disabled={!isValid}
                sx={{ padding: '0.3rem 1.5rem !important' }}
              >
                {buttonLoading ? <CircularProgress color='info' size='1.4rem' /> : 'Save Comment'}
              </Button>
            </Grid>
          </Grid>


          <Grid item >
            <TablePagination
              callToApi={(start, limit) => { fetchComments(start, limit) }}
              totalItems={totalComments}
              rowsPerPageDefault={25}
              rowsPerPageOptions={ROWS_PER_PAGE_EXTENDED}
            />
          </Grid>
          <Grid container direction='column' spacing={2} >
            <PrivateLoader loading={tableLoading}>

              <Grid item>
                {
                  comments?.length > 0 && comments?.sort((a, b) => a.insertedOn < b.insertedOn ? 1 : -1).map((comment, index) => {
                    return (
                      <CommentItem key={comment.id} comment={comment} />
                    )
                  })
                }
              </Grid>

            </PrivateLoader>
          </Grid>
        </Grid>
      </PrivateLoader>
    </>
  )
}

export default Comments
