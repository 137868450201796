import { INSURANCE_TYPE, REVIEW_STATUS } from 'src/helpers';
export const MOCK_INSURANCES = {
    "start": 0,
    "limit": 50,
    "total": 3336,
    "items": [
        {
            "companyName": "C&B Custom Designs LLC",
            "vendorName": " ",
            "phone": "1234567890",
            "insurance": {
                "id": "f9fec0a8efb49a4f0b2c6c26",
                "insuranceType": INSURANCE_TYPE.CERTIFICATE_OF_INSURANCE,
                "reviewStatus": REVIEW_STATUS.APPROVED,
                "insuranceId": "testID",
                "insuranceCompanyName": "ACF Insurance",
                "amountOfCoverage": 200,
                "additionalInsured": true,
                "validFromDate": 1664251200,
                "validToDate": 1695787200,
                "fileName": "f9fec0a8efb49a4f0b2c6c26/Punchlist COI 9.2022.pdf",
                "reviewedOn": 1663681970,
                "reviewedBy": "vycsu9dNnGMkDebPo",
                "fileLocation": "CLOUDINARY",
                "uploadedBy": "vycsu9dNnGMkDebPo",
                "uploadedOn": 1663681927,
                "territories": [
                    "ybGDvc4Ez2mWWeynW"
                ]
            }
        },
    ]
}