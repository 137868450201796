import moment from 'moment'
import { FC, useState } from 'react'
import Tag from 'src/components/UI/CustomUI/atoms/Tag'
import { Box, Button, Grid, SelectInput, Typography } from '../../../../UI'

import SelectAddress from 'src/components/UI/CustomUI/organisms/SelectAddress'
import { Address as GiftAddress } from 'src/ducks/types'
import { Email } from '~api/punchlist/employees/types'
import Icon from '../../../../UI/CustomUI/atoms/Icon'
import { InfoProps } from './types'

import { isEmpty } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import Modal from 'src/components/UI/CustomUI/molecules/Modal'
import { Address } from 'src/components/UI/CustomUI/organisms/SelectAddress/types'
import { subscriptionActions } from 'src/ducks/actions'
import { employeeIsAdmin, getEmployeeRoles } from 'src/ducks/selectors'
import { EMPLOYEE_ROLE_TYPE, SUBSCRIPTION_STATUS, history } from 'src/helpers'
import { UpdateRequest } from '~api/types'
import { ModalActionType } from '../../JobsView/ControlButtons/Modals/ModalMaterialInvoices/types'
import useStyles from './styles'


const ContactInfo: FC<InfoProps> = ({
  userData,
}) => {

  const classes = useStyles()
  const dispatch = useDispatch()
  const isAdmin = useSelector(employeeIsAdmin)
  const employeeRoles = useSelector(getEmployeeRoles)
  const employeeIsManager = employeeRoles.some((role) => role.roleType === EMPLOYEE_ROLE_TYPE.MANAGER)
  const [address, setAddress] = useState<any>('')
  const [openAddress, setOpenAddress] = useState(false)
  const [openStatus, setOpenStatus] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [newStatus, setNewStatus] = useState(userData?.status || SUBSCRIPTION_STATUS.PENDING)
  const goBack = history.useGoBack()
  const isValid: boolean =
    !isEmpty(address?.line_1) &&
    address?.line_1 !== ' ' &&
    !!address?.zipCode &&
    address?.latitude &&
    address?.longitude

  const isStatusValid: boolean =
    !isEmpty(newStatus)

  const handleChange = (newAddress: Address) => {

    setAddress(newAddress)
  }

  const handleCloseAddress = () => {
    setOpenAddress(false)
    setAddress('')
  }

  const handleCloseStatus = () => {
    setOpenStatus(false)
    setNewStatus(userData?.status || SUBSCRIPTION_STATUS.PENDING)
  }

  const handleDeleteSub = () => {
    if (!openDelete) return setOpenDelete(true)

    setIsLoading(true)
    dispatch(
      subscriptionActions.deleteSubscription({ subscriptionId: userData?.id || "" }, (succ: boolean) => {
        if (succ) {
          goBack()
          toast.success('Subscription deleted successfully')
        }
        setIsLoading(false)
        setOpenDelete(false)
      })
    )
  }

  const handleSaveEditStatus = () => {
    if (isStatusValid) {
      var editStatus: string = newStatus
      const subscriptionDifferences: UpdateRequest = [{ op: 'add', path: '/status', value: editStatus }]
      dispatch(
        subscriptionActions.updateSubscriptionByPath(subscriptionDifferences, (succ: boolean) => {
          if (succ) {
            toast.success('Status updated successfully')
          } else {
            toast.error('Error updating status')
          }
        })
      )

      setOpenStatus(false)
      // setNewStatus(userData?.status || SUBSCRIPTION_STATUS.PENDING)
    }
  }


  const handleSaveEditAddress = () => {
    if (isValid) {
      var editAdress: GiftAddress = {
        city: address.city,
        state: address.state,
        line_1: address.line_1,
        line_2: address.line_2,
        zipCode: address.zipCode,
        latitude: address.latitude,
        longitude: address.longitude,
        fullAddress: '',
      }
      const subscriptionDifferences: UpdateRequest = [{ op: 'add', path: '/propertyAddress', value: editAdress }]
      dispatch(
        subscriptionActions.updateSubscriptionByPath(subscriptionDifferences, (succ: boolean) => {
          if (succ) {
            toast.success('Address updated successfully')
          } else {
            toast.error('Error updating address')
          }
        })
      )

      setOpenAddress(false)
      setAddress('')
    }
  }

  const modalActionDelete: ModalActionType[] = [
    {
      textButton: 'Cancel',
      variant: 'outlined',
      onClick: () => {
        setOpenDelete(false)
      },
    },
    {
      textButton: 'Delete',
      variant: 'containedError',
      onClick: () => {
        handleDeleteSub()
      },
      icon: 'Delete',
      iconColor: 'white',
      disabled: isLoading,
    },
  ]
  const modalActionAddress: ModalActionType[] = [
    {
      textButton: 'Cancel',
      variant: 'outlined',
      onClick: () => {
        handleCloseAddress()
      },
    },
    {
      textButton: 'Update Address',
      variant: 'contained',
      onClick: () => {
        handleSaveEditAddress()
      },
      icon: 'Save',
      iconColor: 'white',
      disabled: !isValid,
    },
  ]

  const modalActionStatus: ModalActionType[] = [
    {
      textButton: 'Cancel',
      variant: 'outlined',
      onClick: () => {
        handleCloseStatus()
      },
    },
    {
      textButton: 'Update Status',
      variant: 'contained',
      onClick: () => {
        handleSaveEditStatus()
      },
      icon: 'Save',
      iconColor: 'white',
      disabled: !isStatusValid,
    },
  ]

  const statusOptions = [
    {
      key: SUBSCRIPTION_STATUS.PENDING,
      value: SUBSCRIPTION_STATUS.PENDING,
      label: "Pending"
    },
    {
      key: SUBSCRIPTION_STATUS.ACTIVE,
      value: SUBSCRIPTION_STATUS.ACTIVE,
      label: "Active"
    },
    {
      key: SUBSCRIPTION_STATUS.CANCELED,
      value: SUBSCRIPTION_STATUS.CANCELED,
      label: "Cancelled"
    },
    {
      key: SUBSCRIPTION_STATUS.INCOMPLETE,
      value: SUBSCRIPTION_STATUS.INCOMPLETE,
      label: "Incomplete"
    },
    {
      key: SUBSCRIPTION_STATUS.EXPIRED,
      value: SUBSCRIPTION_STATUS.EXPIRED,
      label: "Expired"
    }
  ]

  const colors = {
    [SUBSCRIPTION_STATUS.PENDING]: '#FFC107',
    [SUBSCRIPTION_STATUS.ACTIVE]: '#17B975',
    [SUBSCRIPTION_STATUS.CANCELED]: '#DC3545',
    [SUBSCRIPTION_STATUS.INCOMPLETE]: '#FFC107',
    [SUBSCRIPTION_STATUS.EXPIRED]: '#DC3545'
  }

  const backColors = {
    [SUBSCRIPTION_STATUS.PENDING]: '#FFF3CD',
    [SUBSCRIPTION_STATUS.ACTIVE]: '#EDFDF6',
    [SUBSCRIPTION_STATUS.CANCELED]: '#F8D7DA',
    [SUBSCRIPTION_STATUS.INCOMPLETE]: '#FFF3CD',
    [SUBSCRIPTION_STATUS.EXPIRED]: '#F8D7DA'
  }

  return (
    <Box display='flex' height='100%'>
      <Box display='flex' flexDirection='column' width='100%' >
        <Box display='flex' flexDirection='column' gap={1}>
          <Typography fontSize='1.25rem' fontFamily="var(--font-medium)">
            {`${userData?.homeowner?.firstName} ${userData?.homeowner?.lastName}` || '  -'}
          </Typography>

          <Typography fontSize='1.25rem' fontFamily="var(--font-medium)">
            <Typography color='var(--gray700)' variant="h5Bold" paddingTop='0.5rem'>
              Address:
            </Typography>
            {userData?.propertyAddress?.fullAddress || '  -'}
            {(isAdmin || employeeIsManager) && <Button
              variant='containedLight'
              color='primary'
              sx={{ height: '30px', marginTop: '5px' }}
              onClick={() => {
                setOpenAddress(true)
              }}
              disabled={!isAdmin && !employeeIsManager}
            >
              Edit Address
              <Icon name="Edit" sx={{ marginLeft: '.5rem' }} />
            </Button>}
          </Typography>


          <Box display='flex' marginBottom={.5} alignItems='center'>
            <Icon name='Email' width='1rem' sx={{ marginRight: '.4rem' }} />
            <Typography style={{ maxWidth: "50%", wordBreak: "break-word" }} variant="h5">
              {(userData?.homeowner?.email[0] as Email).email}
            </Typography>
          </Box>

          <Tag background={backColors[`${userData?.status as SUBSCRIPTION_STATUS}`]} center color={colors[`${userData?.status as SUBSCRIPTION_STATUS}`]}> {userData?.status} </Tag>
          {(isAdmin) && <Button
            variant='containedLight'
            color='primary'
            sx={{ height: '30px', marginTop: '5px' }}
            onClick={() => {
              setOpenStatus(true)
            }}
            disabled={!isAdmin}
          >
            Edit Status
            <Icon name="Edit" sx={{ marginLeft: '.5rem' }} />
          </Button>}
          {/* edit status if admin */}


          <Box display='flex' justifyContent='space-between'>
            <Typography color='var(--gray700)' variant="h5Bold" paddingTop='0.5rem'>
              Contract Signed On:
            </Typography>
            <Typography color='var(--gray700)' variant="h5" paddingTop='0.5rem'>
              {userData?.contractSignedOn ? moment(userData.contractSignedOn * 1000).format('MM/DD/YYYY') : '  -'}
            </Typography>
          </Box>
          <Box display='flex' flexDirection='column' justifyContent='space-between'>
            <Typography color='var(--gray700)' variant="h5Bold" paddingTop='0.5rem'>
              Subscription ID:
            </Typography>
            <Typography color='var(--gray700)' variant="h5" paddingTop='0.5rem'>
              {userData?.id || ''}
            </Typography>
          </Box>
        </Box>
        {isAdmin && <Button onClick={handleDeleteSub} className={classes.deleteButton}>Delete Subscription</Button>}
      </Box>

      <Box
        sx={{
          position: 'absolute',
          bottom: '12px',
          right: '12px',
        }}
      >
        {openDelete && (
          <Modal
            setOpen={setOpenDelete}
            open={openDelete}
            title={'Are you sure you want to delete this subscription?'}
            actions={modalActionDelete}
            onClose={() => {
              setOpenDelete(false)
            }}
          >
            <Grid container spacing={2}>


            </Grid>
          </Modal>
        )}
        {openAddress && (
          <Modal
            setOpen={setOpenAddress}
            open={openAddress}
            title={'Edit Address Information'}
            actions={modalActionAddress}
            onClose={() => {
              handleCloseAddress()
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SelectAddress
                  placeholder="Address"
                  showWarnings={false}
                  savedAddress={address}
                  onChange={handleChange}
                />
              </Grid>

            </Grid>
          </Modal>
        )}

        {openStatus && (
          <Modal
            setOpen={setOpenStatus}
            open={openStatus}
            title={'Edit Subscription Status'}
            actions={modalActionStatus}
            onClose={() => {
              handleCloseStatus()
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SelectInput
                  label='Status:'
                  onChange={(value: any) => {
                    setNewStatus(value.value)
                  }}
                  options={statusOptions}
                  value={newStatus}
                />

              </Grid>

            </Grid>
          </Modal>
        )}
      </Box>

    </Box >
  )
}

export default ContactInfo
