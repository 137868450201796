/* eslint-disable generator-star-spacing */
import type { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo } from '../../helpers'
import { Punchlist } from '../../api'
import {
  materialInvoiceTypes, FetchMaterialInvoicesAction, UploadMaterialInvoiceAction, UpdateMaterialInvoiceByPathAction
} from './types'
import { materialInvoicesActions } from '../actions'
import { getJobId } from '../selectors'
import { UpdateRequest } from '~api/types'

export function* fetchMaterialInvoices({ payload, callback }: FetchMaterialInvoicesAction): SagaIterator {
  let success = false
  try {
    const materialInvoices = yield call(Punchlist.materialInvoices.getMaterialInvoice, payload)
    if (materialInvoices) {
      yield put(materialInvoicesActions.setMaterialInvoices(materialInvoices))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the material invoices'))
    if (callback) yield call(callback, false)
  }
}

export function* uploadMaterialInvoices({ payload, callback }: UploadMaterialInvoiceAction): SagaIterator {
  try {
    const { materialInvoices, jobId } = payload
    const uploadMaterialInvoices = yield call(Punchlist.materialInvoices.uploadMaterialInvoice, jobId, materialInvoices)
    if (uploadMaterialInvoices) {
      yield put(materialInvoicesActions.setMaterialInvoices(uploadMaterialInvoices))
      if (callback) yield call(callback, true)
    }
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('upload the material invoices'))
    if (callback) yield call(callback, false)
  }
}

export function* addMaterialInvoice({ payload, callback }: UploadMaterialInvoiceAction): SagaIterator {
  try {
    const request: UpdateRequest = [{
      op: 'add',
      path: '/-',
      value: payload
    }]

    const jobId = yield select(getJobId)

    const updatedMaterialInvoices = yield call(Punchlist.materialInvoices.updateMaterialInvoice, jobId, request)
    if (updatedMaterialInvoices) {
      yield put(materialInvoicesActions.setMaterialInvoices(updatedMaterialInvoices))
      if (callback) yield call(callback, true)
    }
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('upload the material invoices'))
    if (callback) yield call(callback, false)
  }
}

export function* updateMaterialInvoiceByPath({ payload, callback }: UpdateMaterialInvoiceByPathAction): SagaIterator {
  let success = false

  try {

    const jobId = yield select(getJobId)

    const materialInvoices = yield call(Punchlist.materialInvoices.updateMaterialInvoice, jobId, payload)

    if (materialInvoices) {
      yield put(materialInvoicesActions.setMaterialInvoices(materialInvoices))
      if (callback) yield call(callback, true, materialInvoices)
    }
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('update the job'))
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(materialInvoiceTypes.FETCH_MATERIAL_INVOICE, fetchMaterialInvoices)
  yield takeLatest(materialInvoiceTypes.UPLOAD_MATERIAL_INVOICES, uploadMaterialInvoices)
  yield takeLatest(materialInvoiceTypes.UPDATE_MATERIAL_INVOICES_BY_PATH, updateMaterialInvoiceByPath)
  yield takeLatest(materialInvoiceTypes.ADD_MATERIAL_INVOICES, addMaterialInvoice)
}
