/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState, useCallback, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  colors,
  Box,
  Button,
  StatusBoxes,
  SelectInput,
  ActionsButton,
  FieldsFilter,
  Typography,
  Grid,
  TextField,
  IconButton,
} from '../../../UI'
import DatePicker from 'src/components/UI/CustomUI/atoms/DatePicker'
import { PrivateLoader } from 'src/components/templates'
import {
  getJobsForTable,
  getTotalJobs,
  getTerritoriesSelector,
  getJobsBoxes,
  getEmployee,
  getJobsCSV,
} from 'src/ducks/selectors'
import { getAllCompaniesForMultipleFieldWithoutAllOption } from 'src/ducks/companies/selectors'
import {
  getTerritoryManagers,
  getTerritoryManagersUsers,
  getTerritorySuccessManagers,
  getTerritorySuccessManagersUsers,
} from 'src/ducks/territory/selectors'
import {
  jobsActions,
  territoriesActions,
  companiesActions,
  territoryActions,
  filtersActions,
} from 'src/ducks/actions'
import Table from 'src/components/UI/CustomUI/organisms/Table'
import { Range } from 'src/components/UI/CustomUI/atoms/DatePicker/types'
import { useNavigate } from 'react-router-dom'
import {
  EMPLOYEE_ROLE_TYPE,
  JOB_STATUS_FILTERS,
  STATUS,
  ROWS_PER_PAGE_EXTENDED,
  isEmpty,
} from 'src/helpers'
import headers from './headers'
import { icons } from '../../../../assets'
import useStyles from './styles'
import { SearchParams } from 'src/ducks/searches/types'
import { SelectInputOption } from 'src/components/UI/CustomUI/atoms/SelectInput/types'
import TablePagination from 'src/components/UI/CustomUI/organisms/Table/TablePagination'
import moment from 'moment'
import RefreshIcon from '@mui/icons-material/Refresh'
import { useIsXlScreen } from 'src/hooks'
import { CSVLink } from 'react-csv'
import ExportCSV from 'src/components/UI/CustomUI/molecules/ExportCSV'
import { getJobsFilter } from 'src/ducks/filters/selector'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import IconSwitch from 'src/components/UI/CustomUI/molecules/IconSwitch'
import TableChartIcon from '@mui/icons-material/TableChart'
import ListAltIcon from '@mui/icons-material/ListAlt'
import ColumnBoard from './ColumnBoard'

const ordersOtions = [
  { key: 'all', label: 'All Orders' },
  { key: 'broker', label: 'Broker Orders' },
  { key: 'institutional', label: 'Institutional Orders' },
  { key: 'inspector', label: 'Inspector Orders' },
  { key: 'consumer', label: 'Consumer Orders' },
]

const states = [
  JOB_STATUS_FILTERS.ACTIVE,
  JOB_STATUS_FILTERS.SCHEDULING,
  JOB_STATUS_FILTERS.DEPOSIT_PENDING,
  JOB_STATUS_FILTERS.DISPATCH_PENDING,
  JOB_STATUS_FILTERS.DISPATCHED,
  JOB_STATUS_FILTERS.NEEDS_REVIEW,
  JOB_STATUS_FILTERS.COMPLETE_NOT_INVOICED,
  JOB_STATUS_FILTERS.COMPLETE_INVOICED,
  JOB_STATUS_FILTERS.COMPLETE_PARTIALLY_PAID,
  JOB_STATUS_FILTERS.COMPLETE_IN_COLLECTION,
  JOB_STATUS_FILTERS.CLOSED,
  JOB_STATUS_FILTERS.ALL,
]

const Jobs: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [xlScreen] = useIsXlScreen()

  const minWidthSel = xlScreen ? '156px' : '120px'
  const affiliateSelectorWidth = xlScreen ? '156px' : 'auto'

  interface SearchInputs {
    range: Range
  }

  const jobsFilter = useSelector(getJobsFilter())

  const [search, setSearch] = useState<SearchInputs>({
    range: !isEmpty(jobsFilter.approvalDate)
      ? {
        startDate: new Date(jobsFilter.approvalDate.startDate),
        endDate: new Date(jobsFilter.approvalDate.endDate),
      }
      : {},
  })

  const territorySuccessManagersForDropdown = useSelector(
    getTerritorySuccessManagersUsers
  )
  const territoryManagersForDropdown = useSelector(getTerritoryManagersUsers)
  const territorySuccessManagers = useSelector(getTerritorySuccessManagers)
  const territoryManagers = useSelector(getTerritoryManagers)
  const currentUser = useSelector(getEmployee)
  const isManager = currentUser.roles.find(
    (role) => role.roleType === EMPLOYEE_ROLE_TYPE.TERRITORY_SUCCESS_MANAGER
  )

  const indexOfCSM = territorySuccessManagersForDropdown.findIndex(
    (user) => user.key === currentUser.id
  )
  const [cmsUsers, setCmsUsers] = useState(
    jobsFilter.csmUser ??
    (isManager
      ? territorySuccessManagersForDropdown[indexOfCSM]
      : territorySuccessManagersForDropdown[0])
  )
  const [tmUsers, setTmUsers] = useState(
    jobsFilter.tmUser ?? territoryManagersForDropdown[0]
  )

  const [tableLoading, setTableLoading] = useState(true)
  const [pageLoading, setPageLoading] = useState(false)
  const [countsLoading, setCountsLoading] = useState(false)
  const [tableHeaders, setTableHeaders] = useState(headers)
  const [orders, setOrders] = useState(jobsFilter.orders ?? ordersOtions[0])
  const affiliatesStages = useSelector(
    getAllCompaniesForMultipleFieldWithoutAllOption
  )
  const [selectedAffiliates, setSelectedAffiliates] = useState(
    jobsFilter.affiliates ?? affiliatesStages
  )

  const [isKanban, setIsKanban] = useState(!!jobsFilter.isKanbanView)
  const territoriesOtions = useSelector(getTerritoriesSelector)
  const jobsCSV = useSelector(getJobsCSV)

  const jobs = useSelector(getJobsForTable())
  const totalJobs = useSelector(getTotalJobs)
  const data = useSelector(getJobsBoxes())
  const [territory, setTerritory] = useState(
    jobsFilter.territory || territoriesOtions[0]
  )
  const [statusSelected, setStatusSelected] = useState(
    jobsFilter.statusSelected ?? 0
  )
  const [jobNumber, setJobNumber] = useState(jobsFilter.jobNumber || '')
  const [reset, setReset] = useState(false)
  const [refresh, setRefresh] = useState(true)
  const [filters, setFilters] = useState(false)
  const [loadingCSV, setLoadingCSV] = useState(false)
  const downloadRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null)

  useEffect(() => {
    if (isEmpty(jobsFilter.approvalDate) && !isEmpty(search.range))
      setSearch({ range: {} })

    if (jobsFilter.orders !== orders)
      setOrders(jobsFilter.orders ?? ordersOtions[0])

    if (jobsFilter.affiliates !== selectedAffiliates)
      setSelectedAffiliates(jobsFilter.affiliates ?? affiliatesStages)

    if (jobsFilter.territory !== territory)
      setTerritory(jobsFilter.territory || territoriesOtions[0])

    if (jobsFilter.statusSelected !== statusSelected)
      setStatusSelected(jobsFilter.statusSelected ?? 0)

    if (jobsFilter.jobNumber !== jobNumber)
      setJobNumber(jobsFilter.jobNumber || '')

    if (jobsFilter.csmUser !== cmsUsers)
      setCmsUsers(
        jobsFilter.csmUser ??
        (isManager
          ? territorySuccessManagersForDropdown[indexOfCSM]
          : territorySuccessManagersForDropdown[0])
      )

    if (jobsFilter.tmUser !== tmUsers)
      setTmUsers(jobsFilter.tmUser ?? territoryManagersForDropdown[0])

    if (jobsFilter.isKanbanView !== isKanban)
      setIsKanban(!!jobsFilter.isKanbanView)
  }, [jobsFilter])

  useEffect(() => {
    setCmsUsers(
      jobsFilter.csmUser ??
      (isManager
        ? territorySuccessManagersForDropdown[indexOfCSM]
        : territorySuccessManagersForDropdown[0])
    )
  }, [isManager, territorySuccessManagers])

  useEffect(() => {
    setTmUsers(jobsFilter.tmUser ?? territoryManagersForDropdown[0])
  }, [territoryManagers])

  useEffect(() => {
    if (affiliatesStages.length > 0 && !filters) {
      const affiliates = jobsFilter.affiliates && jobsFilter?.affiliates.length > 0 ? jobsFilter.affiliates : affiliatesStages
      setSelectedAffiliates(affiliates)
    }
  }, [affiliatesStages, jobsFilter.affiliates])

  const getParams = () => {
    const searchParams = {} as Partial<SearchParams>

    if (jobNumber !== '') {
      searchParams.search = jobNumber
    }

    if (territory && territory?.label !== 'All Territories') {
      searchParams.territory = territory.key
    }

    if (statusSelected !== states.length - 1) {
      // searchParams.status = states[statusSelected].toLocaleUpperCase()
      switch (states[statusSelected]) {
        case JOB_STATUS_FILTERS.ACTIVE:
          searchParams.status = [
            STATUS.SCHEDULING,
            STATUS.DEPOSIT_PENDING,
            STATUS.DISPATCH_PENDING,
            STATUS.DISPATCHED,
            STATUS.REVIEW,
          ].join(',')
          break
        case JOB_STATUS_FILTERS.SCHEDULING:
          searchParams.status = STATUS.SCHEDULING
          break
        case JOB_STATUS_FILTERS.DEPOSIT_PENDING:
          searchParams.status = STATUS.DEPOSIT_PENDING
          break
        case JOB_STATUS_FILTERS.DISPATCH_PENDING:
          searchParams.status = STATUS.DISPATCH_PENDING
          break
        case JOB_STATUS_FILTERS.DISPATCHED:
          searchParams.status = STATUS.DISPATCHED
          break
        case JOB_STATUS_FILTERS.NEEDS_REVIEW:
          searchParams.status = STATUS.REVIEW
          break
        case JOB_STATUS_FILTERS.COMPLETE_NOT_INVOICED:
          searchParams.status = STATUS.COMPLETE
          searchParams.statusComp = STATUS.NOT_INVOICED
          break
        case JOB_STATUS_FILTERS.COMPLETE_INVOICED:
          searchParams.status = STATUS.COMPLETE
          searchParams.statusComp = STATUS.INVOICE_SENT
          break
        case JOB_STATUS_FILTERS.COMPLETE_PARTIALLY_PAID:
          searchParams.status = STATUS.COMPLETE
          searchParams.statusComp = STATUS.PARTIAL_PAYMENT
          break
        case JOB_STATUS_FILTERS.COMPLETE_IN_COLLECTION:
          searchParams.status = STATUS.COMPLETE
          searchParams.statusComp = STATUS.IN_COLLECTION
          break
        case JOB_STATUS_FILTERS.CLOSED:
          searchParams.status = STATUS.CLOSED
          break
      }
    }

    if (cmsUsers?.key) {
      searchParams.successManager = cmsUsers?.key || ''
    }
    if (tmUsers?.key) {
      searchParams.territoryManager = tmUsers?.key || ''
    }

    if (orders?.key !== 'all') {
      searchParams.affiliateType = orders?.key.toUpperCase()
    }

    const resAffiliates = selectedAffiliates
      .filter((aff) => !aff.hide)
      .reduce((acc: any, curr: any) => acc.concat(curr.key), [])
    const queryAffiliates = resAffiliates.join(',')
    if (resAffiliates.length !== 0 && !resAffiliates.includes('all')) {
      searchParams.affiliate = queryAffiliates
    }

    if ('startDate' in search.range && 'endDate' in search.range) {
      searchParams.approvalStartDate = moment(search.range.startDate).format(
        'MM/DD/YYYY'
      )
      searchParams.approvalEndDate = moment(search.range.endDate).format(
        'MM/DD/YYYY'
      )
    }

    return searchParams
  }

  const fetchServices = useCallback(() => {
    setTableLoading(true)
    dispatch(
      territoriesActions.fetchTerritories((_succ: boolean) => {
        setPageLoading(false)
      })
    )
    dispatch(
      territoryActions.fetchTerritoryManagers(
        `${EMPLOYEE_ROLE_TYPE.TERRITORY_SUCCESS_MANAGER}`,
        (_succ: boolean) => {
          setPageLoading(false)
        }
      )
    )
    dispatch(
      territoryActions.fetchTerritoryManagers(
        `${EMPLOYEE_ROLE_TYPE.TERRITORY_MANAGER}`,
        (_succ: boolean) => {
          setPageLoading(false)
        }
      )
    )
    dispatch(
      companiesActions.fetchCompanies({}, (_succ: boolean) => {
        setPageLoading(false)
      })
    )
    // dispatch(jobsActions.fetchJobsCounts({ searchParams: {} }, (_succ: boolean) => { setPageLoading(false) }))
  }, [])

  const fetchJobs = (page?: number, size?: number) => {
    setTableLoading(true)
    const searchParams = getParams()
    dispatch(
      jobsActions.fetchJobs({ page, size, searchParams }, (_succ: boolean) => {
        setTableLoading(false)
      })
    )

    setCountsLoading(true)
    dispatch(
      jobsActions.fetchJobsCounts({ searchParams }, (_succ: boolean) => {
        setCountsLoading(false)
      })
    )
  }

  useEffect(() => {
    dispatch(
      filtersActions.setFilters({
        jobs: {
          territory: territory,
          affiliates: selectedAffiliates,
          approvalDate: search.range,
          csmUser: cmsUsers,
          tmUser: tmUsers,
          jobNumber: jobNumber,
          orders: orders,
          statusSelected: statusSelected,
          isKanbanView: isKanban,
        },
      })
    )
    fetchJobs()

  }, [
    territory,
    statusSelected,
    orders,
    selectedAffiliates,
    search,
    cmsUsers,
    tmUsers,
    isKanban,
  ])

  useEffect(() => {
    if (reset && jobNumber === '') {
      fetchJobs(0, 50)
    }
    setReset(false)
  }, [jobNumber])

  useEffect(() => {
    if (refresh) {
      if (isKanban) {
        dispatch(
          filtersActions.setFilters({
            jobs: { ...jobsFilter }
          })
        )
      } else {
        fetchServices()
        fetchJobs()
      }
    }
    setRefresh(false)
  }, [refresh])

  const handleChangeOptions = (newSelected: SelectInputOption) => {
    if (newSelected === null) {
      setOrders(ordersOtions[0])
    } else {
      setOrders(newSelected)
    }
    setFilters(true)
  }

  const handleChangeDate = (rangeSelected: Range) => {
    setSearch({ ...search, range: rangeSelected })
    setFilters(true)
  }

  const handleCsmUsers = (newSelected: SelectInputOption) => {
    if (newSelected === null) {
      setCmsUsers(territorySuccessManagersForDropdown[0])
    } else {
      setCmsUsers(newSelected)
    }
    setFilters(true)
  }

  const handleTMUsers = (newSelected: SelectInputOption) => {
    if (newSelected === null) {
      setTmUsers(territoryManagersForDropdown[0])
    } else {
      setTmUsers(newSelected)
    }
    setFilters(true)
  }

  const handleATerritoryChange = (newSelected: SelectInputOption) => {
    if (newSelected === null) {
      setTerritory(territoriesOtions[0])
    } else {
      setTerritory(newSelected)
    }
    setFilters(true)
  }

  const handleAffiliatesChange = (index: number, id: string): void => {
    setFilters(true)

    if (index === -1) {
      // unselect all if index is - 1
      setSelectedAffiliates(
        selectedAffiliates.map((aff) => ({ ...aff, hide: true }))
      )
    } else {
      const realIndex = selectedAffiliates.findIndex((aff) => aff.id === id)
      setSelectedAffiliates(
        selectedAffiliates.map((aff, i) => {
          if (i === realIndex) aff.hide = !aff.hide
          return aff
        })
      )
    }
  }

  const handleChangeStatus = (newOption: { key: string | number }) => {
    setStatusSelected(+newOption.key)
    setFilters(true)
  }

  const handleChangeSearchInput = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setJobNumber(e.target.value)
  }

  const onResetSearchInput = (): void => {
    setReset(true)
    setJobNumber('')
  }

  const onSearch = (): void => {
    dispatch(
      filtersActions.setFilters({
        jobs: {
          ...jobsFilter,
          jobNumber: jobNumber,
        },
      })
    )
    fetchJobs(0, 50)
  }

  const handleClickRow = (row: any, e: React.MouseEvent) => {
    if (e.type !== 'contextmenu') {
      navigate(`${row.id}`)
      e.preventDefault()
    }
  }

  const onTableFilterChange = (index: number): void => {
    setTableHeaders(
      tableHeaders.map((item, indx) => {
        if (indx === index) {
          return { ...item, hide: !item.hide }
        }
        return item
      })
    )
  }

  const handleKeyDown = (event: { key: string }) => {
    if (event.key === 'Enter') {
      onSearch()
    }
  }

  const downloadCSV = (event: any) => {
    const searchParams = getParams()
    setLoadingCSV(true)
    dispatch(
      jobsActions.downloadCSV(
        {
          searchParams,
        },
        (succ: boolean) => {
          setLoadingCSV(false)
          succ && downloadRef?.current?.link?.click()
        }
      )
    )
  }

  return (
    <div onKeyDown={handleKeyDown}>
      <PrivateLoader loading={pageLoading}>
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          className={classes.container}
        >
          <Box
            display="flex"
            gap={1}
            justifyContent="space-between"
            className={classes.filters}
          >
            <SelectInput
              label="Territory:"
              onChange={handleATerritoryChange}
              options={territoriesOtions}
              value={territory}
              sx={{ minWidth: minWidthSel, flex: '1' }}
            />

            {!isKanban && (
              <SelectInput
                label="Status:"
                onChange={handleChangeStatus}
                options={states.map((state, idx) => {
                  return {
                    key: '' + idx,
                    label: state,
                  }
                })}
                value={states[statusSelected]}
                sx={{ minWidth: minWidthSel, flex: '1' }}
              />
            )}

            <FieldsFilter
              flexColumn
              handleChange={handleAffiliatesChange}
              inputText="All Affiliates:"
              popupLabel=""
              options={selectedAffiliates}
              renderAsInput
              contentAlign="left"
              label="Affiliate:"
              variant="body1"
              sx={{ width: affiliateSelectorWidth, flex: '1' }}
              searchable={true}
              popoverSx={{ width: '378px !important' }}
              searchFieldPlaceholder="Enter Affiliate name"
              allSelection={false}
            />

            <SelectInput
              label="TM:"
              onChange={handleTMUsers}
              options={territoryManagersForDropdown}
              value={
                tmUsers === undefined
                  ? territoryManagersForDropdown.length > 0
                    ? `${territoryManagersForDropdown[1].firstName} ${territoryManagersForDropdown[1].lastName}`
                    : 'Fetching territory managers...'
                  : tmUsers
              }
              sx={{ minWidth: minWidthSel, flex: '1' }}
              freeSolo={false}
            />

            <SelectInput
              label="CSM User:"
              onChange={handleCsmUsers}
              options={territorySuccessManagersForDropdown}
              value={
                cmsUsers === undefined
                  ? territorySuccessManagers.length > 0
                    ? `${territorySuccessManagers[1].firstName} ${territorySuccessManagers[1].lastName}`
                    : 'Fetching success managers...'
                  : cmsUsers
              }
              sx={{ minWidth: minWidthSel, flex: '1' }}
              freeSolo={false}
            />
            <SelectInput
              label="Orders:"
              onChange={handleChangeOptions}
              options={ordersOtions}
              value={orders}
              sx={{ minWidth: minWidthSel, flex: '1' }}
            />

            {!isKanban && (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                minWidth={xlScreen ? '230px' : '170px'}
                sx={{ flex: '1' }}
                maxWidth={xlScreen ? '250px' : '200px'}
              >
                <Typography variant="h6"> Approval Date: </Typography>
                <DatePicker
                  onChange={handleChangeDate}
                  value={search.range}
                  allowRange
                  style={{ height: '38px', padding: '0px' }}
                  styleAlert={{ right: 0 }}
                  size="small"
                />
              </Box>
            )}

            {!isKanban && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                maxWidth={xlScreen ? 'inherit' : '128px'}
                minWidth="128px"
                className={classes.filters_actions}
              >
                <FieldsFilter
                  options={tableHeaders}
                  handleChange={onTableFilterChange}
                  maxWidth="250px"
                  sx={{ height: '56px', minWidth: '56px !important' }}
                />
                <ActionsButton
                  loading={loadingCSV}
                  icon={<icons.CloudDownload color="disabled" />}
                  iconPosition="end"
                  onClick={downloadCSV}
                  sx={{
                    minWidth: '56px!important',
                  }}
                />
                <ExportCSV
                  innerRef={downloadRef}
                  headers={jobsCSV
                    ?.slice(0, jobsCSV?.search('\n'))
                    ?.split(',')
                    ?.map((h: any) => h)}
                  data={jobsCSV?.slice(jobsCSV?.search('\n') + 1)}
                  fileName={'jobs.csv'}
                  label=""
                />
              </Box>
            )}
          </Box>

          {!isKanban && (
            <StatusBoxes
              data={data}
              sx={{ textAlign: 'left', alignItems: 'left' }}
              loading={countsLoading}
            />
          )}
          <Grid container justifyContent="space-between">
            <Grid item container xs={12} lg={6} alignItems="center" spacing={1}>
              {!isKanban && (
                <Grid item>
                  <TextField
                    size="small"
                    // style={
                    //   xlScreen
                    //     ? { height: '30px' }
                    //     : { height: '30px', minWidth: '50%' }
                    // }
                    placeholder="Search Job Number"
                    value={jobNumber}
                    onChange={handleChangeSearchInput}
                  />
                </Grid>
              )}
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    width: '80px',
                    height: '30px',
                    marginLeft: '10px',
                    // marginTop: '5px',
                  }}
                  endIcon={
                    <icons.Search
                      style={{
                        width: '12px',
                        height: '12px',
                        padding: '0px !important',
                      }}
                    />
                  }
                  onClick={onSearch}
                >
                  Search
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  sx={{
                    width: '80px',
                    height: '30px',
                    marginLeft: '10px',
                    // marginTop: '5px',
                    backgroundColor: '#FAFBFF',
                    color: colors.blue[700],
                    border: 0,
                    '&:hover': {
                      color: 'white',
                    },
                  }}
                  onClick={onResetSearchInput}
                >
                  Reset
                </Button>
              </Grid>
              <Grid item>
                <IconButton
                  style={{
                    marginLeft: '10px',
                    marginTop: '5px',
                  }}
                  onClick={() => setRefresh(true)}
                >
                  <RefreshIcon />
                </IconButton>
              </Grid>
              <Grid item>
                <Button
                  sx={{
                    width: '120px',
                    height: '30px',
                    marginLeft: '10px',
                    // marginTop: '5px',
                    marginRight: '10px',
                  }}
                  endIcon={<Icon name="Clear" />}
                  variant="outlined"
                  onClick={() => dispatch(filtersActions.clearFilters())}
                >
                  Clear Filters
                </Button>
              </Grid>
              <Grid item>
                <IconSwitch
                  firstIcon={
                    <TableChartIcon style={{ width: '10px', height: '10px' }} />
                  }
                  secondIcon={
                    <ListAltIcon style={{ width: '10px', height: '10px' }} />
                  }
                  status={isKanban}
                  handleToggle={(toggleState: boolean) =>
                    setIsKanban(toggleState)
                  }
                />
              </Grid>
            </Grid>
            {!isKanban && (
              <Grid item xs={12} lg={6}>
                <TablePagination
                  callToApi={(start, limit) => {
                    fetchJobs(start, limit)
                  }}
                  totalItems={totalJobs}
                  rowsPerPageOptions={ROWS_PER_PAGE_EXTENDED}
                />
              </Grid>
            )}
          </Grid>
          {!isKanban ? (
            <Table
              headers={tableHeaders}
              defaultOrder="desc"
              rowAction={handleClickRow}
              callToApi={(start, limit) => {
                fetchJobs(start, limit)
              }}
              loading={tableLoading}
              hasPagination={false}
              onRightClickRow={handleClickRow}
              className={!xlScreen ? classes.table : ''}
              sortable
              stickyHeader
            >
              {jobs ?? []}
            </Table>
          ) : (
            <ColumnBoard />
          )}
        </Box>
      </PrivateLoader>
    </div>
  )
}
export default Jobs
