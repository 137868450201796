import React, { FC, useState, useEffect } from 'react'
import { HoldModalProps } from './types'
import { Button, CircularProgress, Grid, TextFieldLabel } from 'src/components/UI'
import Modal from 'src/components/UI/CustomUI/molecules/Modal'
import { useDispatch, useSelector } from 'react-redux'
import { estimateActions, estimatesActions, filtersActions } from 'src/ducks/actions'

import useStyles from './styles'
import { getEstimatesFilter } from 'src/ducks/filters/selector'

const HoldModal: FC<HoldModalProps> = ({
  open,
  setOpen,
  handleClose,
  estimate,
}) => {
  const styles = useStyles()

  const dispatch = useDispatch()

  const estimatesFilters = useSelector(getEstimatesFilter())

  const [notes, setNotes] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    setLoading(true)

    dispatch(
      estimateActions.fetchEstimate(estimate.id, (succ) => {
        setLoading(false)
      })
    )
  }, [])

  const holdEstimate = () => {
    setLoading(true)

    dispatch(estimatesActions.updateEstimate({ onHold: true, holdNotes: notes }, (succ) => {
      if (succ) {
        // trigger refreshing
        dispatch(filtersActions.setFilters({ estimates: { ...estimatesFilters } }))
      }

      setLoading(false)
      handleClose()
    }))
  }

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Put estimate on hold"
      onClose={handleClose}
    >
      {loading ? (
        <Grid container justifyContent="center">
          <CircularProgress />
        </Grid>
      ) : (
        <>
          <TextFieldLabel
            label="Special Notes:"
            labelVariant='h6'
            multiline
            rows={4}
            placeholder="Add notes for why the estimate is on hold"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            className={styles.notes}
          />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button variant="outlined" color="primary" onClick={handleClose} fullWidth>
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button variant="contained" color="primary" onClick={holdEstimate} fullWidth>
                Hold Estimate
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </Modal>
  )
}

export default HoldModal
