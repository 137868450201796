const territory = {
    "_id": "9XFYd8oJ6jTvpea6A",
    "title": "Tampa",
    "standardPricing": [
        {
            "_id": "1",
            "title": "1 Business Day ($99.00)",
            "days": 1,
            "amount": 99,
            "requires_serviceable": false,
            "selected": true
        },
        {
            "_id": "2",
            "title": "2 Business Days ($49.00)",
            "days": 2,
            "amount": 49,
            "requires_serviceable": false,
            "selected": false
        },
        {
            "_id": "3",
            "title": "3 Business Days (Free)",
            "days": 3,
            "amount": 0,
            "requires_serviceable": true,
            "selected": false
        }
    ],
    "vipPricing": [
        {
            "_id": "4",
            "title": "1 Business Day ($49.00)",
            "days": 1,
            "amount": 49,
            "requires_serviceable": false,
            "selected": true
        },
        {
            "_id": "5",
            "title": "2 Business Days (Free)",
            "days": 2,
            "amount": 0,
            "requires_serviceable": true,
            "selected": false
        }
    ],
    "ccEmails": "BOSSCAT Tampa <test94532290@fake.com>",
    "zipCodes": [
        "33508",
        "33509",
        "33510",
        "33511",
        "33523",
        "33525",
        "33526",
        "33527",
        "33534",
        "33540",
        "33541",
        "33542",
        "33543",
        "33544",
        "33545",
        "33548",
        "33549",
        "33550",
        "33556",
        "33558",
        "33559",
        "33563",
        "33564",
        "33565",
        "33566",
        "33567",
        "33568",
        "33569",
        "33574",
        "33576",
        "33578",
        "33583",
        "33584",
        "33587",
        "33592",
        "33594",
        "33595",
        "33596",
        "33601",
        "33602",
        "33603",
        "33604",
        "33605",
        "33606",
        "33607",
        "33608",
        "33609",
        "33610",
        "33611",
        "33612",
        "33613",
        "33614",
        "33615",
        "33616",
        "33617",
        "33618",
        "33619",
        "33620",
        "33621",
        "33622",
        "33623",
        "33624",
        "33625",
        "33626",
        "33629",
        "33630",
        "33631",
        "33633",
        "33634",
        "33635",
        "33637",
        "33646",
        "33647",
        "33650",
        "33655",
        "33660",
        "33661",
        "33662",
        "33663",
        "33664",
        "33672",
        "33673",
        "33674",
        "33675",
        "33677",
        "33679",
        "33680",
        "33681",
        "33682",
        "33684",
        "33685",
        "33686",
        "33687",
        "33688",
        "33689",
        "33694",
        "33701",
        "33702",
        "33703",
        "33704",
        "33705",
        "33706",
        "33707",
        "33708",
        "33709",
        "33710",
        "33711",
        "33712",
        "33713",
        "33714",
        "33715",
        "33716",
        "33729",
        "33730",
        "33731",
        "33732",
        "33733",
        "33734",
        "33736",
        "33737",
        "33738",
        "33740",
        "33741",
        "33742",
        "33743",
        "33744",
        "33747",
        "33755",
        "33756",
        "33757",
        "33758",
        "33759",
        "33760",
        "33761",
        "33762",
        "33763",
        "33764",
        "33765",
        "33766",
        "33767",
        "33769",
        "33770",
        "33771",
        "33772",
        "33773",
        "33774",
        "33775",
        "33776",
        "33777",
        "33778",
        "33779",
        "33780",
        "33781",
        "33782",
        "33784",
        "33785",
        "33786",
        "34606",
        "34608",
        "34610",
        "34637",
        "34638",
        "34639",
        "34652",
        "34653",
        "34654",
        "34655",
        "34656",
        "34660",
        "34667",
        "34668",
        "34669",
        "34673",
        "34674",
        "34677",
        "34680",
        "34681",
        "34682",
        "34683",
        "34684",
        "34685",
        "34688",
        "34689",
        "34690",
        "34691",
        "34692",
        "34695",
        "34697",
        "34698"
    ],
    "insertedOn": 1528814134,
    "insertedBy": "PBa8uMcjXTcQJawp2",
    "updatedOn": 1660763159,
    "phone": "123-456-7890",
    "managerUserId": "poTxgeQsDGyqmPjad",
    "vipEmails": [
        "test37537116@fake.com",
        "test98190728@fake.com",
        "test12018134@fake.com",
        "test40002998@fake.com",
        "test59227761@fake.com",
        "test29994035@fake.com",
        "test95225278@fake.com",
        "test82827093@fake.com",
        "test3404620@fake.com",
        "test68577113@fake.com",
        "test13499166@fake.com",
        "test52262633@fake.com"
    ],
    "tradeRates": [
        {
            "id": "electrical",
            "category": "Electrical",
            "rate": 88
        },
        {
            "id": "plumbing",
            "category": "Plumbing",
            "rate": 88
        },
        {
            "id": "hvac",
            "category": "HVAC",
            "rate": 88
        },
        {
            "id": "windows",
            "category": "Windows/Doors",
            "rate": 66
        },
        {
            "id": "misc",
            "category": "Miscellaneous",
            "rate": 66
        },
        {
            "id": "cl100",
            "category": "CL100",
            "rate": 55
        },
        {
            "id": "crawlspace",
            "category": "Crawlspace",
            "rate": 66
        },
        {
            "id": "exterior",
            "category": "Exterior",
            "rate": 66
        },
        {
            "id": "interior",
            "category": "Interior",
            "rate": 66
        },
        {
            "id": "attic",
            "category": "Attic",
            "rate": 66
        },
        {
            "id": "roof",
            "category": "Roof",
            "rate": 77
        },
        {
            "id": "uncategorized",
            "category": "Uncategorized",
            "rate": 55
        },
        {
            "id": "evaluate",
            "category": "Evaluate",
            "rate": 33
        },
        {
            "id": "excluded",
            "category": "Excluded",
            "rate": 33
        }
    ],
    "default": false,
    "isServiceable": true,
    "jobMinimum": 500,
    "estimateAdjustment": 0,
    "isInspection": true,
    "updatedBy": "ghtJbbPunwL7mc3mb",
    "latitude": 27.9944116,
    "longitude": -82.5943666
}

export default territory;