/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EMPLOYEE_ROLE_TYPE, RESOURCE_TYPE } from 'src/helpers'
import { companiesActions, jobsActions, searchesActions, territoriesActions, territoryActions } from '../../../../ducks/actions'
import { Grid } from '../../../UI'
import Folders from './Folders'
import Inputs from './Inputs'
import { getJobsForTable, getSearchParamValues } from 'src/ducks/selectors'
import Table from 'src/components/UI/CustomUI/organisms/Table'
import headers from './headers'
import { usePaddingWrapper } from 'src/hooks'
import { useNavigate } from 'react-router-dom'

const Search: FC = () => {
  const [tableLoading, setTableLoading] = useState(true)
  const { setHasPadding } = usePaddingWrapper()
  const dispatch = useDispatch()
  const jobs = useSelector(getJobsForTable())
  const searchParams = useSelector(getSearchParamValues)
  const navigate = useNavigate()

  const fetchServices = useCallback(() => {
    dispatch(
      searchesActions.fetchSearches(RESOURCE_TYPE.JOB, (_succ: boolean) => {
        setTableLoading(false)
      })
    )
    dispatch(territoryActions.fetchTerritoryManagers(`${EMPLOYEE_ROLE_TYPE.TERRITORY_MANAGER}`, (_succ: boolean) => {
      setTableLoading(false)
    }))
    dispatch(territoryActions.fetchTerritoryManagers(`${EMPLOYEE_ROLE_TYPE.TERRITORY_SUCCESS_MANAGER}`, (_succ: boolean) => {
      setTableLoading(false)
    }))
    dispatch(territoriesActions.fetchTerritories((_succ: boolean) => {
      setTableLoading(false)
    }))
    dispatch(companiesActions.fetchCompanies({ institutional: searchParams.affiliateType === 'INSTITUTIONAL' || null }, (_succ: boolean) => {
      setTableLoading(false)
    }))
    const params = { ...searchParams, includeArchived: true }
    dispatch(
      jobsActions.fetchJobs({ searchParams: params }, (_succ: boolean) => {
        setTableLoading(false)
      })
    )
  }, [])

  useEffect(() => {
    if (searchParams.affiliateType) {
      dispatch(companiesActions.fetchCompanies({ institutional: searchParams.affiliateType === 'INSTITUTIONAL' }, (_succ: boolean) => {
        setTableLoading(false)
      }))
    }
  }, [searchParams])

  useEffect(() => {
    fetchServices()
    setHasPadding(false)
    //dispatch(searchesActions.cleanSearchParams())
  }, [])

  const handleClickRow = (row: any, e: React.MouseEvent) => {
    if (e.type !== 'contextmenu') {
      navigate(`/jobs/${row.id}`)
      e.preventDefault()
    }
  }

  return (
    <Grid container alignContent='flex-start' spacing={1}>
      <Grid item lg={12}>
        <Folders setPageLoading={setTableLoading} />
      </Grid>
      <Inputs tableLoading={tableLoading} setTableLoading={setTableLoading} />
      <Grid item lg={12} marginLeft={2}>
        <Table
          headers={headers}
          loading={tableLoading}
          hasPagination={false}
          rowAction={handleClickRow}
          onRightClickRow={handleClickRow}
        >
          {jobs || []}
        </Table>
      </Grid>
    </Grid>
  )
}

export default Search
