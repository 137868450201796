/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useCallback, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DispatchContext } from 'src/components/pages/Private/Dispatch/context'
import { dispatchTypes } from 'src/components/pages/Private/Dispatch/context/types'
import History from 'src/components/pages/Private/Dispatch/Tabs/History'
import Pricing from 'src/components/pages/Private/Dispatch/Tabs/Pricing'
import Schedule from 'src/components/pages/Private/Dispatch/Tabs/Schedule'
import { CircularProgress, Grid } from 'src/components/UI'
import TabNavigation from 'src/components/UI/CustomUI/organisms/TabNavigation'
import { orderActions, vendorActions } from 'src/ducks/actions'
import { getJob, getOrderById } from 'src/ducks/selectors'
import { JobItem } from 'src/ducks/types'
import { WorkOrdersContext } from '../../../context'

const ViewDispatch: FC = () => {
	const dispatch = useDispatch()
	const { state: { selectedOrderId } } = useContext(WorkOrdersContext)
	const { dispatch: dispatchContext } = useContext(DispatchContext)

	const [fetchingVendor, setFetchingVendor] = useState(true)
	const [fetchingOrder, setFetchingOrder] = useState(true)


	const job = useSelector(getJob())
	const order = useSelector(getOrderById(selectedOrderId as string))

	const { properties } = job || ({} as JobItem)

	const { lockBoxDetails, accessDetails } = properties || {}

	const fetchOrder = useCallback(() => {
		if (order.orderId) {
			dispatchContext({
				type: dispatchTypes.SET_VALUE,
				payload: { attr: 'order', value: null }
			})
			dispatch(orderActions.setOrder(null))
			dispatch(
				orderActions.getOrder(order.orderId, (_succ: boolean) => {
					setFetchingOrder(false)
				})
			)
		}
	}, [])

	useEffect(() => {
		fetchOrder()
	}, [])

	useEffect(() => {
		if (order.orderId) {
			dispatchContext({
				type: dispatchTypes.SET_VALUE,
				payload: {
					attr: 'scheduledStartTimestamp',
					value: order?.scheduledStartTimestamp
				}
			})
			dispatchContext({
				type: dispatchTypes.SET_VALUE,
				payload: {
					attr: 'scheduledEndTimestamp',
					value: order?.scheduledEndTimestamp
				}
			})
			const access = accessDetails ? 'Access Details: ' + accessDetails : ''
			const lockbox = lockBoxDetails ? '\nLockbox: ' + lockBoxDetails : ''

			dispatchContext({
				type: dispatchTypes.SET_VALUE,
				payload: {
					attr: 'dispatchNotes', value: order?.dispatchedTimestamp ?
						order?.dispatchNotes : access + ' ' + lockbox
				}
			})

			if (selectedOrderId) {
				dispatch(
					vendorActions.fetchVendor(order.vendorId, (_succ: boolean) => {
						setFetchingVendor(false)
					})
				)
			} else dispatch(vendorActions.setVendor(null))
		}
	}, [order])


	const TABS = [
		{ label: 'Schedule', content: <Schedule editable={false} />, },
		{ label: 'Pricing', content: <Pricing editable={false} />, },
		{ label: 'Dispatch History', content: <History timezone={job?.properties.address.timeZone} /> }
	]


	return (
		fetchingOrder || fetchingVendor ?
			<Grid container justifyContent="center" alignItems="center" minHeight="30vh">
				<CircularProgress />
			</Grid>
			:
			<Grid container spacing={2}>
				<Grid item xs={12} lg={12}>
					<TabNavigation
						tabs={TABS}
						defaultActive={0}
						variant='contained'
						margin='0 0 0.5rem'
					/>
				</Grid>
			</Grid>
	)
}

export default ViewDispatch