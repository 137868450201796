import { Widget } from 'src/ducks/types'
import axios from '../axios'
import { ApiController } from './types'

const base = '/widgets'

const apiController: ApiController = {
  getWidgets: async () =>
    await axios
      .get(`${base}`)
      .then(response => response.data),

  getWidget: async (widgetId: string) =>
    await axios
      .get(`${base}/${widgetId}`)
      .then(response => response.data),

  updateWidget: async (widgetId: string, request) => {
    return await axios
      .patch(`${base}/${widgetId}`, request)
      .then(response => response.data)
  },

  createWidget: async (request: Partial<Widget>) =>
    await axios.post(`${base}`, request).then((response) => response.data),

}

export default apiController
