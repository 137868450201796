import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { TagProps } from './types'
import styles from './styles.module.scss'

const Tag = ({
  children,
  background,
  color,
  center,
  width = '100%',
  sx,
  variant = 'body1',
  hasShadow = false,
  fontWeight = 400,
  onClick = (event?: React.SyntheticEvent<EventTarget>) => {},
}: TagProps) => {
  return (
    <Box
      sx={[...(Array.isArray(sx) ? sx : [sx])]}
      className={styles.Tag}
      style={{
        background: background || 'var(--gray700)',
        border: '2px solid #EBECEF',
        boxShadow: `${
          hasShadow
            ? '0px 1px 1px rgba(26, 42, 85, 0.24), 0px 0px 1px rgba(26, 42, 85, 0.31)'
            : '0'
        }`,
        width: width,
      }}
      onClick={onClick}
    >
      <Typography
        color={color ?? 'white'}
        textAlign={center ? 'center' : 'left'}
        variant={variant}
        fontWeight={fontWeight}
      >
        {children}
      </Typography>
    </Box>
  )
}
export default Tag
