import { useState, useEffect, useCallback, FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'
import { isEmpty } from '../../../../helpers'
import { PunchlistLoader } from '../..'
import { authActions, configActions, employeeActions } from '../../../../ducks/actions'
import { TemplateProps } from './types'
import { getConfigValue } from 'src/ducks/selectors'

const Template: FC<TemplateProps> = ({ children, error, setError }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const redirectPath = useSelector(getConfigValue('redirectPath'))
  const unauthorizedPath = '/unauthorized'

  const [hash, setHash] = useState<string | null>(null)
  const [hashParsed, setHashParsed] = useState(false)

  const [loading, setLoading] = useState(true)

  const setPublicLoading = useCallback((value: boolean) => {
    dispatch(configActions.setConfigValue({
      type: 'publicLoading', value
    }))
  }, [dispatch])

  const hashFlags = useCallback((start: boolean): void => {
    setPublicLoading(start)
    setLoading(start)
    setHashParsed(true)
    setError(!start)
  }, [setPublicLoading, setError])

  useEffect(() => {
    if (!isEmpty(hash) && !hashParsed) {
      hashFlags(true)
      dispatch(authActions.parseHashRequest({
        hash: hash as string,
        callback: (hashSuccess) => {
          if (hashSuccess) {
            dispatch(employeeActions.fetchCurrentEmployee((fetchSuccess: any) => {
              if (fetchSuccess) {
                setPublicLoading(false)
                return <Navigate to={redirectPath || '/home'} state={{ from: location }} replace />
              } else {
                dispatch(employeeActions.createEmployee((createSuccess: any) => {
                  if (createSuccess) {
                    setPublicLoading(false)
                    return <Navigate to={unauthorizedPath} state={{ from: location }} replace />
                  } else hashFlags(false)
                }))
              }
            }))
          } else hashFlags(false)
        }
      }))
    }
  }, [hash, hashParsed, hashFlags, dispatch, setPublicLoading, location, redirectPath])

  useEffect(() => {
    if (hash === null) setHash(window.location.hash)
    else setLoading(false)
  }, [hash])

  return (
    <PunchlistLoader loading={loading || (!isEmpty(hash) && !error)}>
      {children}
    </PunchlistLoader>
  )
}

export default Template
