import { Theme } from '../../../UI'
import { makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => ({
  root: {
    height: '100vh',
    position: 'relative'
  },
  logoAltItem: {
    background: theme.palette.text.primary,
    position: 'relative',
    alignItems: 'center'
  },
  logoAlt: {
    width: '50%',
    margin: '0 auto'
  },
  center: {
    display: 'grid',
    alignItems: 'center',
    alignContent: 'center'
  },
  childrenBox: {
    padding: '0 24px'
  },
  loginTexture: {
    position: 'fixed',
    maxWidth: '150px',
    width: '20vw',
    zIndex: -1,
    bottom: 0,
    right: 0,
    [theme.breakpoints.down('md')]: {
      width: '15vw'
    },
    [theme.breakpoints.down('xs')]: {
      width: '20vw'
    }
  }
}))
