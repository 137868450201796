import { authTypes } from '../auth/types'
import { JobItem } from '../types'
import { JobActions, JobType, jobTypes, JobValue } from './types'

const initialState: JobType = null

const reducer = (state = initialState, action: JobActions): JobType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: {
      return initialState
    }
    case authTypes.LOGOUT_REQUEST: {
      return initialState
    }

    case jobTypes.SET_JOB: {
      return payload as JobItem
    }

    case jobTypes.CLEAN_JOB: {
      return initialState
    }

    case jobTypes.SET_JOB_VALUE: {
      const { attr, value } = payload as JobValue
      return {
        ...(state as unknown as JobItem),
        [attr]: value,
      }
    }

    default:
      return state
  }
}

export default reducer
