import { SelectInputOption } from "src/components/UI/CustomUI/atoms/SelectInput/types";
import { PROS_PAGE_STATUS } from "src/helpers";

export const filtersTypes = {
  SET_FILTERS: 'SET_FILTER',
  CLEAR_FILTERS: 'CLEAR_FILTERS'
}

export interface SetFiltersAction {
  type: typeof filtersTypes.SET_FILTERS
  payload: Partial<FiltersType>
}

export interface ClearFiltersAction {
  type: typeof filtersTypes.SET_FILTERS
  payload: null
}

export interface OrdersOptionType {
  key: string
  label: string
}

export interface MultiSelectorOptionType {
  id: string
  label: string
  hide: boolean
  param?: string
}

export type FiltersAction = SetFiltersAction | ClearFiltersAction

export interface FiltersType {
  jobs: JobsFilterType
  projectCoordinator: JobsFilterType
  pros: ProsFilterType
  subscriptions: SubscriptionsFilterType
  companies: CompaniesFilterType
  estimates: EstimatesFilterType
  customers: CustomersFilterType
  repairList: RepairListFilterType
}

export interface JobsFilterType {
  territory?: SelectInputOption
  statusSelected?: number
  affiliates?: SelectInputOption[]
  csmUser?: SelectInputOption
  tmUser?: SelectInputOption
  orders?: OrdersOptionType
  approvalDate?: any
  jobNumber?: string
  isKanbanView?: boolean
}

export interface ProsFilterType {
  pageStatus?: PROS_PAGE_STATUS
  search?: string
  approved?: boolean
  denied?: boolean
  w9?: boolean
  id?: boolean
  trade?: boolean
  coi?: boolean
  wc?: boolean
  draft?: boolean
  other?: boolean
  reviewStatus?: SelectInputOption[]
  licenseType?: SelectInputOption[]
  insertedOnStart?: string | null
  insertedOnEnd?: string | null
  uploadedOnStart?: string | null
  uploadedOnEnd?: string | null
  downloadCSVCount?: number | null
  regLms?: string
  territory?: string
  insuranceTypes?: string[]
  hasACH?: boolean
  hasW9?: boolean
  hasTaxID?: boolean
  unapproved?: boolean
  vStages?: string[]
  regLmsArray?: string[]
  tradesFilter?: string[]
  page?: number
  pageSize?: number
  isChild?: boolean | null
}

export interface OptionSelect {
  id?: string,
  label?: string,
  hide?: boolean,
  isSeparator?: boolean
}

export interface SubscriptionsFilterType {
  status?: MultiSelectorOptionType[]
  gifted?: MultiSelectorOptionType[]
  search?: string
  dateStart?: string | null
  dateEnd?: string | null
  downloadCSVCount?: number
}

export interface CompaniesFilterType {
  selectedAffiliates?: SelectInputOption[]
  search?: string
  institutional?: boolean
}

export interface EstimatesFilterType {
  territory?: SelectInputOption
  selectedAffiliates?: SelectInputOption[]
  estimator?: SelectInputOption
  estimateNumber?: string
  isColumnView?: boolean
  inUseOption?: string
  assignedOption?: string
  dueDateOption?: string
  search?: string
  status?: string
}

export interface Range {
  startDate?: Date
  endDate?: Date
}

export interface CustomersFilterType {
  search?: string,
  states?: string[]
  territory?: SelectInputOption
  affiliate?: SelectInputOption
  userTypes: SelectInputOption[]
  dateRange: Range
}

export interface RepairListFilterType {
  category?: SelectInputOption
  territory?: SelectInputOption
  selectedTerritoryIndexes?: number[]
}
