/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo, isEmpty } from '../../helpers'
import { Punchlist } from '../../api'
import { workOrdersTypes, FetchGeneralSectionAction, FetchFinancialSectionAction, FetchContractDetailsAction, FetchContractMilestonesAction, UpdateWorkOrderItemAction } from './types'
import { workOrdersActions } from '../actions'
import * as Sentry from '@sentry/react'


export function* fetchGeneralSection({ payload, callback }: FetchGeneralSectionAction): SagaIterator {
  let success = false
  try {
    const generalSection = yield call(Punchlist.workOrders.getGeneralSection, payload)

    if (generalSection) {
      yield put(workOrdersActions.setWorkOrders({ generalSection }))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the general section of work order'))
    if (callback) yield call(callback, false)
  }
}

export function* fetchFinancialSection({ payload, callback }: FetchFinancialSectionAction): SagaIterator {
  let success = false
  try {
    const financialSection = yield call(Punchlist.workOrders.getFinancialSection, payload)

    if (financialSection) {
      yield put(workOrdersActions.setWorkOrders({ financialSection }))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the financial section of work order'))
    if (callback) yield call(callback, false)
  }
}

export function* fetchContractDetails({ payload, callback }: FetchContractDetailsAction): SagaIterator {
  let success = false
  try {
    const contractDetails = yield call(Punchlist.workOrders.getContractDetails, payload)

    if (contractDetails) {
      yield put(workOrdersActions.setWorkOrders({ contractDetails }))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the contract details of work order'))
    if (callback) yield call(callback, false)
  }
}

export function* fetchContractMilestones({ payload, callback }: FetchContractMilestonesAction): SagaIterator {
  let success = false
  try {
    const contractMilestones = yield call(Punchlist.workOrders.getContractMilestones, payload)

    if (contractMilestones) {
      yield put(workOrdersActions.setWorkOrders({ contractMilestones }))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the contract milestones of work order'))
    if (callback) yield call(callback, false)
  }
}

export function* fetchWorkOrderItems({ payload, callback }: FetchContractMilestonesAction): SagaIterator {
  let success = false
  try {
    const items = yield call(Punchlist.workOrders.getWorkOrderItems, payload)

    if (items) {
      yield put(workOrdersActions.setWorkOrders({ items }))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the items of work order'))
    if (callback) yield call(callback, false)
  }
}

export function* updateWorkOrderItem({ payload, callback }: UpdateWorkOrderItemAction): SagaIterator {
  let success = false
  try {
    const orderId = payload.orderId;
    const itemId = payload.itemId;
    const userId = payload.userId;
    const request = payload.request;
    const job = yield call(Punchlist.workOrders.updateWorkOrderItem, orderId, itemId, userId, request)

    if (!isEmpty(job)) {
      // yield put(jobActions.setJob(job))
      // yield put(jobActions.fetchJob(jobId))
      //yield put(jobActions.fetchHistoryTask(jobId))
      success = true
    }
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);

    yield call(toast.error, errorTextTryingTo('update the job'))
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(workOrdersTypes.FETCH_GENERAL_SECTION, fetchGeneralSection)
  yield takeLatest(workOrdersTypes.FETCH_FINANCIAL_SECTION, fetchFinancialSection)
  yield takeLatest(workOrdersTypes.FETCH_CONTRACT_DETAILS, fetchContractDetails)
  yield takeLatest(workOrdersTypes.FETCH_CONTRACT_MILESTONES, fetchContractMilestones)
  yield takeLatest(workOrdersTypes.FETCH_WORK_ORDER_ITEMS, fetchWorkOrderItems)
  yield takeLatest(workOrdersTypes.UPDATE_WORK_ORDER_ITEM, updateWorkOrderItem)
}
