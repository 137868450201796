import { EmployeeType } from '../employee/types'
import { EmployeesActions, employeesTypes, SearchParams } from './types'

const actions = {
  fetchEmployees: (
    payload: {
      page?: number,
      size?: number,
      searchParams?: Partial<SearchParams>
    },
    callback: (succ: boolean) => void
  ): EmployeesActions => ({
    type: employeesTypes.FETCH_EMPLOYEES,
    payload,
    callback
  }),
  setEmployees: (payload: EmployeeType[]): EmployeesActions => {
    return {
      type: employeesTypes.SET_EMPLOYEES,
      payload
    }
  },
  setOldEmployees: (payload: EmployeeType[]): EmployeesActions => {
    return {
      type: employeesTypes.SET_EMPLOYEES,
      payload
    }
  },
  fetchEmployeeById: (
    payload: {
      searchParams?: Partial<SearchParams>
    },
    callback: (succ: boolean) => void
  ): EmployeesActions => ({
    type: employeesTypes.FETCH_EMPLOYEE_ID,
    payload,
    callback
  }),
  updateEmployeeInListById: (
    payload: {
      updatedValues: Partial<EmployeeType>,
      id: string
    }
  ): EmployeesActions => ({
    type: employeesTypes.UPDATE_EMPLOYEE_IN_LIST_BY_ID,
    payload
  })
}

export default actions
