import { Typography } from '@mui/material'
import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Modal from 'src/components/UI/CustomUI/molecules/Modal'
import { ModalActionType } from 'src/components/UI/CustomUI/molecules/Modal/types'
import {
  estimateActions,
  itemsActions,
  jobActions,
  matchesActions,
} from 'src/ducks/actions'
import { STATUS } from 'src/helpers'
import { JobModalTypes, ModalsProps } from './types'
import ModalMaterialInvoices from './ModalMaterialInvoices'
import ModalRequestChangeOrderApproval from './ModalRequestChangeOrderApproval'
import ModalOverrideApproval from './ModalOverrideApproval'
import { getEstimate, getEstimateId, getJob } from 'src/ducks/selectors'
import ConfirmReleaseModal from '../../PortalEstimate/ConfirmReleaseModal'
import ModalSubmitForReview from './ModalSubmitForReview'
import ModalSubmitForRelease from './ModalSubmitForRelease'
import ModalBackToBuilder from './ModalBackToBuilder'
import ModalAssignToMe from './ModalAssignToMe'
import ImportFromCsvModal from './ImportFormCsvModal'
import ModalConfirm from './ModalConfirm'

const Modals: FC<ModalsProps> = ({ open, setOpen, modalType, setLoading }) => {
  const dispatch = useDispatch()
  const job = useSelector(getJob())
  const estimate = useSelector(getEstimate())
  const estimateId = useSelector(getEstimateId())
  const navigate = useNavigate()
  const title = () => {
    switch (modalType) {
      case JobModalTypes.CLEAR_JOB:
        return 'Confirm Clear Approval'
      case JobModalTypes.COMPLETE_JOB:
        return 'Confirm Complete Job'
      case JobModalTypes.REOPEN_JOB:
        return 'Reopen Job'
      case JobModalTypes.IMPORT_FROM_CSV:
        return 'Import from CSV'
      case JobModalTypes.OVERRIDE_APPROVAL:
        return 'Override Approval'
      case JobModalTypes.SUBMIT_FOR_REVIEW:
        return 'Submit For Review'
      case JobModalTypes.SUBMIT_FOR_RELEASE:
        return 'Submit For Release'
      case JobModalTypes.BACK_TO_BUILDER:
        return 'Are you sure?'
      case JobModalTypes.BACK_TO_BUILDER_RESET:
        return 'Are you sure?'
      case JobModalTypes.ARCHIVE:
        return 'Confirm'
      default:
        return undefined
    }
  }

  const content = () => {
    switch (modalType) {
      case JobModalTypes.CLEAR_JOB:
        return (
          <Typography>
            Are you sure you want to clear approval on the job? This cannot be
            undone.
          </Typography>
        )
      case JobModalTypes.COMPLETE_JOB:
        return (
          <Typography>
            Are you sure you want to mark the Job complete. This cannot be
            undone.
          </Typography>
        )
      case JobModalTypes.REOPEN_JOB:
        return <Typography>Are you sure you want to reopen the Job?</Typography>
      default:
        return <>No Content</>
    }
  }

  const onClearWorkOrder = (): void => {
    setOpen(false)
    setLoading && setLoading(true)
    if (estimateId) {
      dispatch(
        jobActions.deleteJob(estimateId, () => {
          toast.success.call('success', 'Job Deleted')
          setOpen(false)
          navigate('/jobs')
        })
      )
    }
    setLoading && setLoading(false)
  }

  const onArchive = () => {
    setOpen(false)
    setLoading && setLoading(true)
    if (estimate?.id) {
      const nextStatus = !estimate?.properties.archived
      dispatch(
        estimateActions.updateEstimateProperty(
          { archived: nextStatus },
          (succ) => {
            setLoading && setLoading(false)
          }
        )
      )
    }
  }

  const onExclude = () => {
    setOpen(false)
    setLoading && setLoading(true)
    if (estimate?.id) {
      const nextStatus = !estimate?.properties.excludedFromReports
      dispatch(
        estimateActions.updateEstimateProperty(
          { excludedFromReports: nextStatus },
          (succ) => {
            setLoading && setLoading(false)
          }
        )
      )
    }
  }

  const onEditJob = (status: STATUS) => {
    let messageText = `Job status updated to In ${status} `

    dispatch(
      jobActions.updateJob(
        {
          status: status,
        },
        () => {
          toast.success.call('success', messageText)
          setOpen(false)
        }
      )
    )
  }

  const onOcrReprocess = () => {
    setLoading && setLoading(true)
    dispatch(
      estimateActions.ocrReprocessEstimate(
        estimateId || '',
        (succ: boolean) => {
          if (succ) {
            dispatch(
              itemsActions.fetchItemsByCategory({
                params: {
                  jobId: estimate?.id,
                },
              })
            )
          }
          setLoading && setLoading(false)
        }
      )
    )
    setOpen(false)
  }

  const onAutomatchEstimate = () => {
    setLoading && setLoading(true)
    dispatch(
      matchesActions.runAutomatch(estimateId || '', (succ: boolean) => {
        setLoading && setLoading(false)
        dispatch(
          itemsActions.fetchItems({ params: { jobId: estimateId } }, () => {})
        )
        dispatch(
          itemsActions.fetchItemsByCategory({ params: { jobId: estimateId } }, () => {})
        )
      })
    )
    setOpen(false)
  }

  const modalActions: ModalActionType[] = [
    {
      textButton: 'Cancel',
      variant: 'outlined',
      onClick: () => {
        setOpen(false)
      },
    },
    {
      textButton: 'Yes',
      variant: 'contained',
      onClick: () => {
        switch (modalType) {
          case JobModalTypes.CLEAR_JOB:
            onClearWorkOrder()
            break
          case JobModalTypes.COMPLETE_JOB:
            onEditJob(STATUS.COMPLETE)
            break
          case JobModalTypes.REOPEN_JOB:
            if (
              job?.status === STATUS.COMPLETE ||
              job?.status === STATUS.CLOSED
            ) {
              onEditJob(STATUS.REVIEW)
            }

            break

          default:
            break
        }
      },
    },
  ]

  const selectModal = () => {
    switch (modalType) {
      case JobModalTypes.MATERIAL_INVOICES:
        return <ModalMaterialInvoices setOpen={setOpen} open={open} />
      case JobModalTypes.REQUEST_CHANGE_ORDER_APPROVAL:
        return <ModalRequestChangeOrderApproval setOpen={setOpen} open={open} />
      case JobModalTypes.OVERRIDE_APPROVAL:
        return <ModalOverrideApproval setOpen={setOpen} open={open} />
      case JobModalTypes.SUBMIT_FOR_REVIEW:
        return <ModalSubmitForReview setOpen={setOpen} open={open} />
      case JobModalTypes.SUBMIT_FOR_RELEASE:
        return <ModalSubmitForRelease setOpen={setOpen} open={open} />
      case JobModalTypes.BACK_TO_BUILDER:
        return <ModalBackToBuilder setOpen={setOpen} open={open} />
      case JobModalTypes.BACK_TO_BUILDER_RESET:
        return <ModalBackToBuilder setOpen={setOpen} open={open} reset={true} />
      case JobModalTypes.ASSIGN_TO_ME:
        return <ModalAssignToMe setOpen={setOpen} open={open} />
      case JobModalTypes.IMPORT_FROM_CSV:
        return <ImportFromCsvModal setOpen={setOpen} isOpen={open} />
      case JobModalTypes.ARCHIVE:
        return (
          <ModalConfirm
            setOpen={setOpen}
            open={open}
            contentText={`Are you sure to ${
              !estimate?.properties?.archived ? 'archive' : 'unarchive'
            } this estimate?`}
            action={onArchive}
          />
        )
      case JobModalTypes.EXCLUDE_FROM_REPORT:
        return (
          <ModalConfirm
            setOpen={setOpen}
            open={open}
            contentText={`Are you sure to ${
              !estimate?.properties?.excludedFromReports
                ? 'exclude from reports'
                : 'include in reports'
            }?`}
            action={onExclude}
          />
        )
      case JobModalTypes.OCR_REPROCESS:
        return (
          <ModalConfirm
            setOpen={setOpen}
            open={open}
            contentText={`This action will reset the estimate to "New" status and clear all items, are you sure?`}
            action={onOcrReprocess}
          />
        )
      case JobModalTypes.AUTOMATCH_ESTIMATE:
        return (
          <ModalConfirm
            setOpen={setOpen}
            open={open}
            contentText="Begin auto estimate?"
            action={onAutomatchEstimate}
          />
        )
      default:
        return (
          <Modal
            setOpen={setOpen}
            open={open}
            title={title()}
            actions={modalActions}
          >
            {content()}
          </Modal>
        )
    }
  }

  return selectModal()
}

export default Modals
