import { FC, useContext, useEffect } from 'react'
import {
  Button, Grid
} from '@mui/material'
import { icons } from '../../../../../../../../assets'
import useStyles from './styles'
import { ModalRemoveInvoice } from './type'
import { useSelector, useDispatch } from "react-redux"
import { jobActions, materialInvoicesActions } from 'src/ducks/actions'
import { getMaterialInvoices } from 'src/ducks/selectors'
import { InvoiceMaterialContext } from "../context"

const Remove: FC<ModalRemoveInvoice> = ({
  onClose,
  index,
}) => {
  const classes = useStyles()

  let materialInvoices = useSelector(getMaterialInvoices())
  const dispatch = useDispatch();
  const { dispatch: dispatchContext, state: { invoiceNum, amount, insertedOn, orderName } } = useContext(InvoiceMaterialContext)

  const handleClose = () => {
    onClose()
  }

  const handleRemove = () => {
    dispatch(materialInvoicesActions.updateMaterialInvoiceByPath([{ op: 'remove', path: `/${index}` }],
      (succ: boolean, materialInvoices) => {
        if (succ) {
          const totalPrice = materialInvoices?.reduce((acc: number, curr: any) => acc + curr?.amount, 0) || 0
          dispatch(jobActions.setJobValue({ attr: 'materialCost', value: totalPrice }))
        }
      }))
    onClose()
  }
  return (
    <>
      <div className={classes.contentRemove}>
        <Grid item xs={12} sm container alignItems='flex-end' >
          <Grid item xs container direction="column" spacing={2} >
            <Grid item xs flexDirection="row" > By deleting this invoice you’ll no longer has
              access to this document.</Grid>
            <Grid item xs flexDirection="row" >Are you sure you want to delete this invoice?</Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm container paddingTop={4} justifyContent={"center"} >

          <Grid item xs container direction="column" spacing={2} justifyContent={"center"} >

            <Grid item xs flexDirection="row" sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center"
            }}>

              {/* 
              <Button
                variant='contained'
                sx={{

                  width: '272px',
                  height: '40px',
                  backgroundColor: '#EB5151',
                  border: '0',
                  '&:hover': {
                    border: '0',
                    backgroundColor: '#EB5151',
                    color: 'white'
                  }
                }}
                onClick={handleRemove}
                endIcon={<icons.Delete style={{ width: '12px', height: '12px', padding: '0px !important' }} />}
              >
                Yes, Delete Invoice
              </Button>*/}

              <Button
                variant='contained'
                sx={{
                  //position: 'absolute',
                  //right: 200,               
                  width: '272px',
                  height: '40px',
                  //marginLeft: '10px',
                  //marginTop: '5px',
                  backgroundColor: '#EB5151',
                  border: 0,
                  '&:hover': {
                    border: '0',
                    backgroundColor: '#EB5151',
                    color: 'white'
                  }
                }}
                endIcon={<icons.Delete style={{ width: '12px', height: '12px', padding: '0px !important' }} />}
                onClick={handleRemove}
              >
                Yes, Delete Invoice
              </Button>

            </Grid>

            <Grid item xs flexDirection="row" sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center"
            }}>

              <Button
                variant='contained'
                sx={{
                  //position: 'absolute',
                  //right: 200,               
                  width: '272px',
                  height: '40px',
                  //marginLeft: '10px',
                  //marginTop: '5px',
                  backgroundColor: '#FAFBFF',
                  color: '#3A66FF',
                  border: 0,
                  '&:hover': {
                    color: 'white'
                  }
                }}
                onClick={handleClose}
              >
                No, keep this invoice
              </Button>
            </Grid>

          </Grid>

        </Grid>

      </div>

    </>

  )
}

export default Remove
