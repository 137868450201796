import { CompanyActions, CompanyType, companyTypes } from './types'

const actions = {
  fetchCompany: (
    payload: string,
    callback?: (succ: boolean) => void
  ): CompanyActions => ({
    type: companyTypes.FETCH_COMPANY,
    payload,
    callback,
  }),

  setCompany: (payload: CompanyType): CompanyActions => ({
    type: companyTypes.SET_COMPANY,
    payload,
  }),
}

export default actions
