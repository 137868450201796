import { GetPaymentsParams } from '~api/punchlist/payments/types'
import { Payment } from '../types'
import { PaymentsActions, paymentsTypes } from './types'

const actions = {
  fetchPayments: (
    payload: {
      params?: Partial<GetPaymentsParams>,
    },
    callback?: (succ: boolean) => void
  ): PaymentsActions => ({
    type: paymentsTypes.GET_PAYMENTS,
    payload,
    callback
  }),
  createPayment: (
    payload: Partial<Payment>,
    callback?: (
      succ: boolean) => void
  ): PaymentsActions => ({
    type: paymentsTypes.CREATE_PAYMENT,
    payload,
    callback
  }),
  setPayments: (payload: Payment[]): PaymentsActions => ({
    type: paymentsTypes.SET_PAYMENTS,
    payload
  }),
}

export default actions
