import { icons } from 'src/assets'
import { getEstimate, getItemsInCart } from 'src/ducks/selectors'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import useStyles from './styles'
import useStylesBetter from './stylesBetter'
import { BottomBarProps } from './types'

import { isMobile } from 'react-device-detect'
import TotalPrice from '../Cart/components/TotalPrice'
import { Button, SvgIcon, Typography } from '@mui/material'

const BottomBarEstimate: FC<BottomBarProps> = ({
  onRequestMoreItems,
  onOpenCart,
  price,
  showButtons,
  showBetterStyle = false,
  ptp = false,
  serviceable
}) => {
  const styles = useStyles()
  const stylesBetter = useStylesBetter()
  const classes = showBetterStyle ? stylesBetter : styles
  const itemsInCart = useSelector(getItemsInCart())
  const estimate = useSelector(getEstimate())


  return (
    <div className={classes.row}>
      {isMobile && <TotalPrice
        serviceable={serviceable}
        price={price}
        showRegularPrice={!isMobile}
        ptp={ptp}
        style={{ alignItems: "flex-start" }}
      />}
      {showButtons && (
        <div className={classes.rowButtons}>
          {!estimate?.properties.clientRequestNotes && !isMobile && <Button
            type="submit"
            variant="contained"
            size="small"
            className={classes.requestMoreButton}
            fullWidth
            onClick={onRequestMoreItems}
            startIcon={<SvgIcon component={icons.ListAlt} />}
          >
            <Typography> Request More Items</Typography>
          </Button>}
          <Button
            type="submit"
            variant="contained"
            size="small"
            className={classes.selectedItems}
            fullWidth
            onClick={onOpenCart}
            startIcon={serviceable ? <SvgIcon component={icons.ShoppingCart} /> : <></>}
            endIcon={isMobile ? <SvgIcon fontSize='small' component={icons.ArrowForward} /> : <></>}
          >
            <Typography> {serviceable ? 'View Cart' : 'See Selected Items'} {!serviceable && <br />} ({itemsInCart.length}{!isMobile ? ' items' : ''})</Typography>
          </Button>
        </div>
      )
      }
    </div >
  )
}

export default BottomBarEstimate
