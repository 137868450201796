import { Box, Button, Typography } from '@mui/material'
import { Link } from 'src/components/UI'
import {
  HeaderType,
  AlignType,
} from 'src/components/UI/CustomUI/organisms/Table/types'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import SettingsIcon from '@mui/icons-material/Settings';
import moment from 'moment'

import styles from './styles.module.scss'

export const removeProtocol = (url: string): string => {
  if (!url) {
    return ''
  }

  return url.replace(/(^\w+:|^)\/\//, '')
}

const applyRegExp = (phone: string): string => {
  if (!phone) return ''

  return String(phone).replace(/^(\d{3})(\d{3})(\d{4})$/g, '+1 $1-$2-$3')
}

const headers: HeaderType[] = [
  {
    id: 'milestone',
    label: 'Milestone',
    custom: true,
    align: 'center' as AlignType,
    padding: '0px 8px !important',
    Element: (row: any): JSX.Element => {
      return (
        <Box padding="1px 4px">
          <Typography className={styles.Companies__companyName}>
            {row?.milestone}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'customer_price',
    label: 'Customer Price',
    custom: true,
    align: 'center' as AlignType,
    padding: '0px 8px !important',
    Element: (row: any): JSX.Element => {
      return (
        <Box padding="1px 4px">
          <Typography className={styles.Companies__companyName}>
            {`$${Number(row?.customerPrice ?? 0).toFixed(2)}`}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'pro_price',
    label: 'Pro Price',
    custom: true,
    align: 'center' as AlignType,
    padding: '0px 8px !important',
    minWidth: 100,
    Element: (row: any): JSX.Element => {
      return (
        <Box padding="1px 4px">
          <Typography className={styles.Companies__companyName}>
            {`$${Number(row?.proPrice ?? 0).toFixed(2)}`}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'pm_approved',
    label: 'PM Approved',
    custom: true,
    align: 'center' as AlignType,
    padding: '0px 8px !important',
    Element: (row: any): JSX.Element => {
      return (
        <Box padding="1px 4px">
          <Typography className={styles.Companies__companyName} style={{ color: row.pmApproved ? '#17B975' : '#E01F1F' }}>
            {row.pmApproved ? 'YES' : 'NO'}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'customer_approved',
    label: 'Customer Approved',
    custom: true,
    align: 'center' as AlignType,
    padding: '0px 8px !important',
    Element: (row: any): JSX.Element => {
      return (
        <Box padding="1px 4px">
          <Typography className={styles.Companies__companyName} style={{ color: row.customerApproved ? '#17B975' : '#E01F1F' }}>
            {row.customerApproved ? 'YES' : 'NO'}
          </Typography>
        </Box>
      )
    },
  },
]

export default headers
