import { CampaignsActions, CampaignsType, FetchCampaignsPayload, campaignsTypes } from './types'

const actions = {
  fetchCampaigns: (
    payload: FetchCampaignsPayload,
    callback?: (succ: boolean) => void
  ): CampaignsActions => ({
    type: campaignsTypes.FETCH_CAMPAIGNS,
    payload,
    callback
  }),
  setCampaigns: (payload: CampaignsType): CampaignsActions => ({
    type: campaignsTypes.SET_CAMPAIGNS,
    payload
  }),
}

export default actions
