import React, { useReducer } from 'react'
import { WorkOrdersReducer } from './reducer'
import { WorkOrdersContextType } from './types'

const initialState = {
  // GENERAL
  pageLoading: true,
  refresh: true,
  uploadingFiles: false,
  // MODAL
  modalType: null,
  modalOpen: false,
  modalActionAfterCreate: false,
  modalAfterCreate: (a: string) => { },
  // ORDERS
  selectedOrderId: null,
  selectedTab: 0,
  // ITEMS
  selectedItemId: null,
  selectedItemsIds: [],
  selectedWorkItem: null,
  checkAll: false,
  expandAll: true,
  workItems: [],
  item: {},
  itemToFetchTotals: {},
  itemsLoading: false,
  editItem: {},
  showPriceMatch: false,
  // SITE-VISIT
  selectedVendor: null,
  selectedSiteVisitId: null,
  selectedFieldManager: null,
  refreshSite: false,
  firstItem: null,
  totalsLoading: false,
  comments: [],
  totalPatchLoading: false
}

export const WorkOrdersContext = React.createContext<{
  state: WorkOrdersContextType;
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null
})

export const WorkOrdersProvider: React.FunctionComponent = ({ children }: any) => {
  const [state, dispatch] = useReducer(WorkOrdersReducer, initialState)

  return (
    <WorkOrdersContext.Provider value={{ state, dispatch }}>
      {children}
    </WorkOrdersContext.Provider>
  )
}
