import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) =>
  createStyles({
    marketingImg: {
      borderRadius: '8px',
      border: '2px solid var(--bosscat-grey-400)',
      boxShadow: '0px 0px 1px 0px rgba(9, 25, 49, 0.31), 0px 1px 1px 0px rgba(9, 25, 49, 0.24)',
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    },

    marketContent: {
      display: 'flex',
      // borderRadius: '8px',
      backgroundColor: 'var(--bosscat-grey-200)',
      justifyContent: 'center',
      alignItems: 'center',
      // border: '2px solid var(--bosscat-grey-400)',
      flexGrow: 1,
      // padding: '86px 21px',
    },
    marketText: {
      color: 'var(--bosscat-black-400)',
      textAlign: 'center',
      fontFamily: 'NextArtNormal',
      fontSize: '28px',
      fontWeight: 900,
      lineHeight: '40px',
    },
  })
)
