import { Vendor, Insurance, Trade } from '../types'

/** TYPES **/
export const vendorTypes = {
  FETCH_VENDOR: 'FETCH_VENDOR',
  SET_VENDOR: 'SET_VENDOR',
  SET_VENDOR_VALUE: 'SET_VENDOR_VALUE',
  UPDATE_VENDOR: 'UPDATE_VENDOR',
  UPDATE_VENDOR_INSURANCE: 'UPDATE_VENDOR_INSURANCE',
  DELETE_VENDOR_INSURANCE: 'DELETE_VENDOR_INSURANCE',
  UPDATE_VENDOR_TRADES: 'UPDATE_VENDOR_TRADES',
  ADD_VENDOR_LIST: 'ADD_VENDOR_LIST',
  CREATE_QBID: 'CREATE_QBID'
}

/** ACTIONS **/
export interface FetchVendorAction {
  type: typeof vendorTypes.FETCH_VENDOR
  payload: string
  callback?: (succ: boolean) => void
}

export interface CreateQuickBooksIdAction {
  type: typeof vendorTypes.CREATE_QBID
  payload: string
  callback?: (succ: boolean) => void
}

export interface SetVendorAction {
  type: typeof vendorTypes.SET_VENDOR
  payload: Vendor | null
}

export interface SetVendorAttb {
  type: typeof vendorTypes.SET_VENDOR
  payload: VendorValue
}

export interface VendorValue {
  attr: keyof Vendor
  value: any
}

export interface UpdateVendorAction {
  type: typeof vendorTypes.UPDATE_VENDOR
  payload: Partial<Vendor>
  callback?: (succ: boolean) => void
}

export interface UpdateVendorTradeAction {
  type: typeof vendorTypes.UPDATE_VENDOR_TRADES,
  payload: Trade[],
  id: number,
  callback: (succ: boolean) => void
}

export interface AddVendorListAction {
  type: typeof vendorTypes.UPDATE_VENDOR
  payload: Partial<Vendor>
  list: keyof Vendor
  callback?: (succ: boolean) => void
}
export interface UpdateVendorInsuranceAction {
  type: typeof vendorTypes.UPDATE_VENDOR_INSURANCE
  payload: Partial<Insurance>
  id: number
  list: string
  callback?: (succ: boolean) => void
}

export interface DeleteVendorInsuranceAction {
  type: typeof vendorTypes.DELETE_VENDOR_INSURANCE
  payload: Partial<Insurance>
  id: number
  isInsurance: boolean
  callback?: (succ: boolean) => void
}

export type VendorActions =
  | FetchVendorAction
  | SetVendorAction
  | SetVendorAttb
  | UpdateVendorAction
  | UpdateVendorInsuranceAction
  | DeleteVendorInsuranceAction
  | CreateQuickBooksIdAction

/** REDUCER **/
export type VendorType = Vendor | null
