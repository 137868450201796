import { FC } from "react"
import { SUB_OPTIONS } from "../.."
import { Box, Button, Typography } from "@mui/material"
import useStyles from './styles'
import { expert, icons } from 'src/assets'
import { Link } from "react-router-dom"


interface GeneralProps {
  setSubActiveOption: (option: SUB_OPTIONS) => void
}


const General: FC<GeneralProps> = ({ setSubActiveOption }) => {
  const styles = useStyles()

  return (
    <Box width={"100%"} display="flex" flexDirection="column" className={styles.root}>
      <Typography variant="h5" className={styles.text}>
        speak with an expert
      </Typography>
      <Box width={"100%"} display="flex" className={styles.container}>
        <img src={expert} alt="FAQ" />
        <Box className={styles.box}>
          <Typography className={styles.title}>Our Customer Care Team is ready to help you at:</Typography>
          <a href="tel:1-877-4-BOSSCAT" className={styles.phone}> <icons.Phone />1-877-4-BOSSCAT </a>
          <Typography className={styles.subtitle}>Available Monday-Friday, 8:00am-6:00pm EST</Typography>
        </Box>
      </Box>
      <Button
        onClick={() => setSubActiveOption(SUB_OPTIONS.initial)}
        className={styles.button}
        startIcon={<icons.ArrowBack />}
      >
        Back
      </Button>
    </Box>
  )
}

export default General