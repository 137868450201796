import { FC, useContext, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, CircularProgress, Grid, TextFieldLabel } from 'src/components/UI'
import { ordersActions } from 'src/ducks/actions'
import { isEmpty } from 'src/helpers'
import { WorkOrdersContext } from '../../../context'

const RenameOrder: FC = () => {
	const { state: { selectedOrderId }, dispatch: dispatchContext } = useContext(WorkOrdersContext)
	const dispatch = useDispatch()
	const [newOrderName, setNewOrderName] = useState('')
	const [showWarnings, setShowWarnings] = useState(false)
	const [buttonLoading, setButtonLoading] = useState(false)
	const validate = !isEmpty(newOrderName)

	const handleClose = () => {
		dispatchContext({ type: 'SET_MODAL_OPEN', payload: false })
		setShowWarnings(false)
		setNewOrderName('')
	}

	const handleEdit = () => {
		if (validate) {
			setButtonLoading(true)
			setShowWarnings(false)

			const request = {
				orderName: newOrderName,
				orderId: selectedOrderId
			}
			dispatch(
				ordersActions.updateOrder(request,
					(success) => {
						setButtonLoading(false)
						dispatchContext({ type: 'SET_PAGE_LOADING', payload: false })
						if (success) {
							handleClose()
						}
					})
			)
		} else setShowWarnings(true)
	}

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<TextFieldLabel
					labelVariant='h6'
					label='Order Name'
					value={newOrderName}
					error={showWarnings && isEmpty(newOrderName)}
					onChange={(e) => { setNewOrderName(e.target.value) }}
				/>
			</Grid>
			<Grid item container xs={12} spacing={2}>
				<Grid item xs={6}>
					<Button variant='outlined' onClick={handleClose} fullWidth>
						Cancel
					</Button>
				</Grid>
				<Grid item xs={6}>
					<Button variant='contained' onClick={handleEdit} fullWidth>
						{buttonLoading ? <CircularProgress color='info' size={'1.4rem'} /> : 'Update Order'}
					</Button>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default RenameOrder