import { makeStyles } from '@mui/styles'
import { Theme } from '../../../../UI'

export default makeStyles((theme: Theme) => ({
  input: {
  },
  inputSmall: {
    padding: '1px !important',
    fontSize: '12px !important',
    fontFamily: 'NouvelleNormal'
  },
  notchedOutline: {
  },
  popupIcon: {
    color: 'gray !important',
    background: 'white !important',
  },
  popupCustomIcon: {
    transform: 'rotate(0deg) !important',
  },
}))
