import { ClientPriceModel } from '../types'
import { PriceModelActions, clientTypes } from './types'

const actions = {
  fetchClientPriceModels: (
    callback?: (succ: boolean) => void
  ): PriceModelActions => ({
    type: clientTypes.FETCH_CLIENT_PRICE_MODEL,
    callback
  }),
  setClientPriceModels: (payload: ClientPriceModel): PriceModelActions => ({
    type: clientTypes.SET_CLIENT_PRICE_MODEL,
    payload
  }),
}

export default actions
