import { WidgetsContextType, dispatchTypes, DispatchValue } from "./types";

export const WidgetsReducer = (state: WidgetsContextType, action: any) => {
    const { type, payload } = action
    switch (type) {
        case dispatchTypes.SET_VALUE: {
            const { attr, value } = payload as DispatchValue
            return {
                ...state as unknown as WidgetsContextType,
                [attr]: value
            }
        }
        case dispatchTypes.CLOSE_MODAL: {
            if (payload) {
                const { attr, value } = payload as DispatchValue
                return {
                    ...state as unknown as WidgetsContextType,
                    modalOpen: false,
                    [attr]: value
                }
            }
            return {
                ...state as unknown as WidgetsContextType,
                modalOpen: false,
            }

        }
        case dispatchTypes.OPEN_MODAL: {
            if (payload) {
                const { attr, value } = payload as DispatchValue
                return {
                    ...state as unknown as WidgetsContextType,
                    modalOpen: true,
                    [attr]: value
                }
            }
            return {
                ...state as unknown as WidgetsContextType,
                modalOpen: true,
            }
        }
        default:
            return state
    }
}