import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontFamily: 'LatoBlack',
      fontWeight: 900,
      paddingBottom: '8px',
      color: "#0B060F",
      fontSize: '18px'
    },
    textfield: {
      width: '100% !important',
      [theme.breakpoints.up('xl')]: {
        minWidth: '220px !important'
      },
      height: '54px',
      padding: '0px 20px 0px 20px',
      borderRadius: '8px !important',
      border: '2px solid var(--border-color) !important',
      shadowBox: '0px 1px 1px rgba(26, 42, 85, 0.24), 0px 0px 1px rgba(26, 42, 85, 0.31) !important',
      color: 'var(--text-color) !important',
      inputColor: '#FFFFFF !important',
      textDecorationColor: '#FFFFFF !important',
      fontFamily: 'LatoNormal !important',
      fontWeight: 400,
      fontSize: "16px !important",
      transition: 'all 200ms',
      '&:hover': {
        borderColor: 'var(--border-color-focus)',
        transition: 'all 200ms',
        borderRadius: 8
      },
      '&.Mui-focused': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8
      },
      '& .MuiInputBase-input': {
        '&::placeholder': {
          color: 'var(--placeholder-color)',
          opacity: 1,
        }
      }
    },
    inputError: {
      borderColor: 'var(--expired-color) !important'
    },
    root: {
      width: '100%',
    },
    clearIndicator: {
      display: 'none'
    },
    endAdornment: {
      top: 0
    },
    popupIndicator: {
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent'
      }
    },
    popper: {
      margin: '12px 0 0 -10px',
      zIndex: 9999
    },
    listBox: {
      padding: 0
    },
    option: {
      padding: '12px 20px',
      flexDirection: 'column',
      alignItems: 'flex-start',
      '&[data-focus="true"]': {
        backgroundColor: '#FCFCFC',
        borderColor: 'transparent'
      },
      '&[aria-selected="true"]': {
        backgroundColor: '#FCFCFC',
        fontWeight: 900,
        borderColor: 'transparent'
      }

    }
  })
)
