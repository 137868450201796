import { Checkbox, FormControlLabel, Grid } from "@mui/material"
import { FC, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import { SelectInput, TextFieldLabel } from "src/components/UI"
import { SelectInputOption } from "src/components/UI/CustomUI/atoms/SelectInput/types"
import Modal from "src/components/UI/CustomUI/molecules/Modal"
import { PrivateLoader } from "src/components/templates"
import { employeesActions, territoriesActions } from "src/ducks/actions"
import { getEmployeesPossibleManagers } from "src/ducks/selectors"
import { EMPLOYEE_ROLE_TYPE, PHONE_MASK_INPUT, isEmpty, validateEmail } from "src/helpers"

interface AddTerritoryModalProps {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const AddTerritoryModal: FC<AddTerritoryModalProps> = ({ open, setOpen }) => {

    const dispatch = useDispatch()
    const managers = useSelector(getEmployeesPossibleManagers())
    const defaultTerritoryInputs = {
        title: '',
        territoryManager: '',
        phone: '',
        email: '',
        serviceable: false,
        inspectionTerritory: false,
    }

    const [showWarnings, setShowWarnings] = useState(false)
    const [territory, setTerritory] = useState(defaultTerritoryInputs)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (open) {
            setLoading(true)
            dispatch(employeesActions.fetchEmployees({ searchParams: { role: EMPLOYEE_ROLE_TYPE.TERRITORY_MANAGER, approved: true } }, () => {
                setLoading(false)
            }))
        }
        setShowWarnings(false)
        setTerritory(defaultTerritoryInputs)
    }, [open])

    const validate = !isEmpty(territory.title) &&
        (!isEmpty(territory.email) || validateEmail(territory.email))


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setTerritory((prevTerritory) => ({
            ...prevTerritory,
            [name]: value,
        }));
    };

    const handleCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const { name, checked } = event.target;
        setTerritory((prevTerritory) => ({
            ...prevTerritory,
            [name]: checked,
        }));
    };

    const handleChangeManager = (newManager: SelectInputOption) => {
        setTerritory((prevTerritory) => ({
            ...prevTerritory,
            territoryManager: newManager.key,
        }));
    }

    const handleSubmit = () => {
        setShowWarnings(true)
        if (validate) {
            setOpen(false)
            setLoading(true)
            dispatch(territoriesActions.createTerritory(territory, (succ: boolean) => {
                setLoading(false)
                if (succ) {
                    toast.success('Territory created successfully')
                }
            }))
        }
    }

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title={'Add Territory'}
            actions={[
                {
                    textButton: 'Cancel',
                    variant: 'outlined',
                    onClick: () => setOpen(false)
                },
                {
                    textButton: 'Save',
                    variant: 'contained',
                    onClick: handleSubmit
                }
            ]}
        >
            <PrivateLoader loading={loading}>
                <Grid container justifyContent='center' alignItems='flex-start' spacing={2}>
                    <Grid item xs={12}>
                        <TextFieldLabel
                            error={showWarnings && isEmpty(territory.title)}
                            label='Territory'
                            name='title'
                            helperText={showWarnings && isEmpty(territory.title) && 'Territory must have a name'}
                            onChange={handleChange} />
                    </Grid>
                    <Grid item xs={12}>
                        <SelectInput
                            options={managers}
                            label='Manager'
                            onChange={handleChangeManager} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextFieldLabel
                            label='Phone'
                            name='phone'
                            onChange={handleChange}
                            mask={PHONE_MASK_INPUT} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextFieldLabel
                            error={showWarnings && !validateEmail(territory.email)}
                            helperText={showWarnings && !validateEmail(territory.email) && 'Invalid email'}
                            label='Email'
                            name='email'
                            onChange={handleChange} />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="serviceable"
                                    checked={territory.serviceable}
                                    onChange={handleCheckboxChange}
                                />
                            }
                            label="Serviceable Territory"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="inspectionTerritory"
                                    checked={territory.inspectionTerritory}
                                    onChange={handleCheckboxChange}
                                />
                            }
                            label="Inspection Territory"
                        />
                    </Grid>
                </Grid>
            </PrivateLoader>
        </Modal>
    )

}

export default AddTerritoryModal