import { makeStyles } from '@mui/styles'
import { Theme } from 'src/components/UI'

const useStyles = (columnCount: number) => makeStyles((theme: Theme) => ({
    container: {
        [theme.breakpoints.down('lg')]: {
            flexWrap: 'wrap !important',
        }
    },
    item: {
        width: '50%',
        [theme.breakpoints.down('lg')]: {
            width: `${100 / columnCount}%`,
            height: '128px'
        }
    }
}))

export default useStyles;