import { applyMiddleware, createStore, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import env from '@beam-australia/react-env'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import rootSaga from './sagas'
import rootReducer from './reducers'

const w: any = window as Window
const devtools = w.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__

const sagaMiddleware = createSagaMiddleware()
const middlewares = [sagaMiddleware]
const composeEnhancers = (
  env('NODE_ENV') !== 'production' &&
  Boolean(devtools) &&
  typeof window === 'object'
)
  ? devtools({})
  : compose

const persistConfig = {
  key: 'punchlist-csm',
  storage,
  migrate: (state: any) => {
    if (!state) {
      state = {}
    }
    const newState = {
      ...state,
      client: state.client,
      clients: state.clients,
      config: state.config,
      employee: { ...state.employee },
      order: state.order,
      orders: state.orders,
      vendor: state.vendor,
      vendors: state.vendors,
      searches: state.searches,
      siteVisit: state.siteVisit,
      job: state.job,
      jobs: state.jobs,
      workflows: state.workflows,
      notifications: state.notifications,
      tradeTypes: state.trades,
      priceModel: state.priceModels,
      estimates: state.estimates,
      estimate: state.estimate,
      invitations: state.invitations
    }
    return Promise.resolve(newState)
  }
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
const store = createStore(
  persistedReducer, // root reducer with router state
  composeEnhancers(applyMiddleware(...middlewares))
)

const persistor = persistStore(store as any)

// Used to clean the storage that uses Redux Persist.
// persistor.purge()

sagaMiddleware.run(rootSaga)

const storeInfo = {
  store,
  persistor
}

export default storeInfo
