import { Typography } from '@mui/material'
import { Box, Grid, Button, Link } from 'src/components/UI'
import { AlignType, HeaderType } from 'src/components/UI/CustomUI/organisms/Table/types'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'


const headers: HeaderType[] = [
  {
    id: 'estimatePublicId',
    label: 'Job ID',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <a href={`jobs/${row.estimateId}`} target='_blank' rel='noreferrer'>
          <Typography sx={{ fontSize: '10px', fontWeight: 400 }}> {row?.estimatePublicId} </Typography>
        </a>
      )
    }
  },
  {
    id: 'Address',
    label: 'Address',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (

        <Grid container justifyContent="space-between" style={{ height: '30px' }}>
          <Grid item xs={10}>
            <Link href={`jobs/${row.estimateId}`} target='_blank' style={{ textDecoration: 'none' }} >
              <Typography sx={{ fontSize: '12px', fontWeight: 400 }}> {row?.address?.fullAddress} </Typography>
            </Link>
          </Grid>
          <Grid item xs={2}>

            <Link href={`jobs/${row.estimateId}`} target='_blank' style={{ textDecoration: 'none' }} >
              <Typography sx={{ color: "#3A66FF", fontSize: '10px', fontWeight: 400 }}>Job</Typography>
              <Icon sx={{ color: "#3A66FF" }} name='NorthEast' width='1rem' height='1rem' />
            </Link>
          </Grid>
        </Grid>
      )
    }
  }


]

export default headers