import type { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo } from '../../helpers'
import { Punchlist } from '../../api'

import { isEmpty } from 'lodash'
import {
  CreateInspectionCompanyAction,
  DeleteInspectionCompanyAction,
  FetchInspectionCompaniesAction,
  inspectionCompaniesTypes,
  UpdateInspectionCompanyAction,
} from './types'
import { inspectionCompaniesActions } from '../actions'

export function* fetchInspectionCompanies({
  payload,
  callback,
}: FetchInspectionCompaniesAction): SagaIterator {
  let success = false
  try {
    const page = payload?.page ?? 0
    const size = payload?.size ?? 1000
    const searchParams = payload?.searchParams
    const start = page * size
    const limit = size

    const inspectionCompanies = yield call(
      Punchlist.inspectionCompanies.getInspectionCompanies,
      {
        start,
        limit,
        searchParams,
      }
    )

    if (inspectionCompanies) {
      yield put(
        inspectionCompaniesActions.setInspectionCompanies(inspectionCompanies)
      )
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    console.log({ error })
    yield call(toast.error, errorTextTryingTo('get the inspection companies'))
    if (callback) yield call(callback, false)
  }
}

export function* createInspectionCompany({
  payload,
  callback,
}: CreateInspectionCompanyAction): SagaIterator {
  try {
    const inspectionCompany = yield call(
      Punchlist.inspectionCompanies.createInspectionCompany,
      payload
    )

    if (!isEmpty(inspectionCompany)) {
      if (callback) yield call(callback, true)
    }
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('create the company'))
    if (callback) yield call(callback, false)
  }
}

export function* updateInspectionCompany({
  payload,
  callback,
}: UpdateInspectionCompanyAction): SagaIterator {
  const { request, id } = payload
  try {
    const inspectionCompany = yield call(
      Punchlist.inspectionCompanies.updateInspectionCompany,
      id,
      request
    )

    if (!isEmpty(inspectionCompany)) {
      if (callback) yield call(callback, true)
    }
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('update the company'))
    if (callback) yield call(callback, false)
  }
}

export function* deleteInspectionCompany({
  payload,
  callback,
}: DeleteInspectionCompanyAction): SagaIterator {
  try {
    yield call(Punchlist.inspectionCompanies.deleteInspectionCompany, payload)

    if (callback) yield call(callback, true)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('delete the company'))
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(
    inspectionCompaniesTypes.FETCH_INSPECTION_COMPANIES,
    fetchInspectionCompanies
  )
  yield takeLatest(
    inspectionCompaniesTypes.CREATE_INSPECTION_COMPANY,
    createInspectionCompany
  )
  yield takeLatest(
    inspectionCompaniesTypes.UPDATE_INSPECTION_COMPANY,
    updateInspectionCompany
  )
  yield takeLatest(
    inspectionCompaniesTypes.DELETE_INSPECTION_COMPANY,
    deleteInspectionCompany
  )
}
