import { HeaderType, AlignType } from 'src/components/UI/CustomUI/organisms/Table/types'

import { Button } from 'src/components/UI'
import { USER_TYPE } from 'src/helpers'
import { Typography } from '@mui/material'
import moment from 'moment'
import { useState } from 'react'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import DeleteAttachmentModal from '../../DeleteAttachmentModal'

const getUserType = (type: string) => {
    const typeLw = type ? type.toLocaleLowerCase() : ''
    switch (typeLw) {
        case 'vendor':
            return USER_TYPE.PRO
        case 'client':
            return USER_TYPE.CUSTOMER
        case 'staff':
            return USER_TYPE.PUNCHLIST
        default:
            return '-'
    }
}

const headers: HeaderType[] = [
    {
        id: 'description',
        label: 'Description',
        minWidth: 300
    },
    {
        id: 'uploadedByName',
        label: 'Uploaded By',
        maxWidth: 100
    },
    {
        id: 'uploadedByUserType',
        label: 'User Type',
        custom: true,
        Element: (row: any): JSX.Element => {
            return (
                <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                    {getUserType(row.uploadedByUserType)}
                </Typography>
            )
        },
        maxWidth: 80
    },
    {
        id: 'uploadedOn',
        label: 'Date',
        custom: true,
        Element: (row: any): JSX.Element => {
            return (
                <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                    {moment.unix(row?.uploadedOn).format('MM/DD/YYYY')}
                </Typography>
            )
        },
        maxWidth: 80
    },
    {
        id: 'fileUrl',
        label: 'View',
        custom: true,
        align: 'center' as AlignType,
        Element: (row: any): JSX.Element => {
            return (
                <Button
                    variant='contained'
                    color='primary'
                    sx={{ width: '75px', height: '26px' }}
                    href={row.fileUrl}
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    View
                </Button>
            )
        },
        maxWidth: 70,
        hide: false
    },
    {
        id: '',
        label: '',
        custom: true,
        align: 'center' as AlignType,
        Element: (row: any): JSX.Element => {
            const [open, setOpen] = useState(false)
            return (
                <>
                    <DeleteAttachmentModal open={open} setOpen={setOpen} attachment={row} />
                    <Button
                        variant='containedErrorLight'
                        style={{
                            padding: '.5rem !important'
                        }}
                        onClick={() => {
                            setOpen(true)
                        }}
                    >
                        <Icon name='Delete' color='var(--red600)' />
                    </Button>
                </>
            )
        },
        maxWidth: 70,
        hide: false
    }
]

export default headers
