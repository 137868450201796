import { MatchItem } from '../types'
import { MatchActions } from './types'
import { matchTypes } from './types'

const actions = {
  fetchMatch: (
    payload: {
      estimateId: string
      itemId: string
    },
    callback?: (succ: boolean) => void
  ): MatchActions => ({
    type: matchTypes.FETCH_MATCH,
    payload,
    callback,
  }),

  setMatch: (payload: MatchItem): MatchActions => ({
    type: matchTypes.SET_MATCH,
    payload,
  }),
}

export default actions
