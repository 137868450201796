import { FC, useEffect, useState } from 'react'
import { CircularProgress, Box } from '../../../UI'
import useStyles from './styles'
import { useIsSmScreen, useIsXsScreen, useIsMdScreen } from '../../../../hooks'
import { LoaderProps } from '../types'

const PrivateLoader: FC<LoaderProps> = ({ children, loading = true, sx }) => {
  const classes = useStyles()

  const [smScreen] = useIsSmScreen()
  const [xsScreen] = useIsXsScreen()
  const [mdScreen] = useIsMdScreen()
  const smallScreen = xsScreen || smScreen || mdScreen
  const [dots, setDots] = useState(0)

  useEffect(() => {
    if (loading) {
      const timer = setInterval(() => {
        setDots((dots + 1) % 4)
      }, 500)
      return () => { clearInterval(timer) }
    }
  }, [dots])

  if (children && !loading) {
    return <> {children} </>
  }

  return (
    <Box className={classes.root} sx={[...(Array.isArray(sx) ? sx : [sx]),]}>
      <Box className={classes.center}>
        <CircularProgress color='primary' size={smallScreen ? 56 : 65} thickness={4} />
      </Box>
    </Box>
  )
}

export default PrivateLoader
