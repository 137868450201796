import { FC } from 'react'
import { ModalConfirmProps } from './types'
import Modal from 'src/components/UI/CustomUI/molecules/Modal'
import { Button, Grid, Typography } from '@mui/material'

const ModalConfirm: FC<ModalConfirmProps> = ({
  open,
  contentText,
  action,
  setOpen,
}) => {
  return (
    <Modal open={open} setOpen={setOpen} title="Confirm">
      <Grid container flexDirection="column" style={{ gap: '12px' }}>
        <Typography variant="h5">{contentText}</Typography>
        <Grid container justifyContent="flex-end" style={{ gap: '8px', flexWrap: 'nowrap' }}>
          <Button variant="containedError" onClick={() => setOpen(false)} sx={{ width: '100%' }}>
            Cancel
          </Button>
          <Button variant="containedGreen" onClick={action} sx={{ width: '100%' }} >
            Confirm
          </Button>
        </Grid>
      </Grid>
    </Modal>
  )
}

export default ModalConfirm
