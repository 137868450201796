
import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => ({
    heardInput: {
        '@global': {
            '.MuiInputBase-input': {
                textTransform: 'capitalize',
            }
        }
    },
}))
