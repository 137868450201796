import { FC, useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { icons } from 'src/assets';
import { Box, Button, CircularProgress, Grid, Icon, Typography } from 'src/components/UI'
import { companiesActions, filtersActions } from 'src/ducks/actions';
import { getCompany } from 'src/ducks/company/selectors';
import { getCompaniesFilter } from 'src/ducks/filters/selector';
import { CompaniesContext } from '../../context';
import { dispatchTypes, MODAL_TYPE } from '../../context/types';
const ConfirmDeleteCompany: FC = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const company = useSelector(getCompany())
    const companiesFilter = useSelector(getCompaniesFilter())

    const { selectedAffiliates } = companiesFilter

    const { dispatch: dispatchContext } = useContext(CompaniesContext)
    
    const [loading, setLoading] = useState(false)

    const handleCancel = () => {
        dispatchContext({
            type: dispatchTypes.SET_VALUE, payload: {
                attr: 'modalType',
                value: MODAL_TYPE.EDIT
            }
        })
    }

    const handleDelete = () => {
        if (company?.id) {
            setLoading(true)
            dispatch(companiesActions.deleteCompany({
                company: {
                    id: company.id,
                    name: company.name,
                    affiliateId: ''
                }
            }, (succ) => {
                if (succ) {
                    dispatchContext({
                        type: dispatchTypes.SET_VALUE, payload: {
                            attr: 'modalOpen',
                            value: false
                        }
                    })
                    dispatch(
                        filtersActions.setFilters({
                            companies: {
                                ...companiesFilter,
                                selectedAffiliates: selectedAffiliates?.filter((aff) => aff.id !== company.id)
                            }
                        })
                    )
                    dispatch(
                        companiesActions.fetchAllCompanies({ searchParams: { search: companiesFilter.search } }, (su) => setLoading(false))
                    )
                    toast.success("Company deleted")
                    navigate('/companies')
                } else {
                    setLoading(false)
                    toast.success("Error trying to delete company")
                }

            }))
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant='body1Bold'>{company?.name}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography >This action can't be undone. <br /> Are you sure you want to delete this company?</Typography>
            </Grid>
            <Grid item xs={6}>
                <Button
                    onClick={handleCancel}
                    fullWidth
                    disabled={loading}
                    variant='containedLight'>
                    No, Cancel
                </Button>
            </Grid>
            <Grid item xs={6}>
                {loading ? <Box display='flex' justifyContent='center'>
                    <CircularProgress size={30} />
                </Box> : (
                    <Button
                        onClick={handleDelete}
                        fullWidth
                        variant='containedError'
                        endIcon={<icons.Delete />}
                    >
                        Yes, Delete
                    </Button>
                )}
            </Grid>

        </Grid>
    )
}

export default ConfirmDeleteCompany;