import { LabeledText } from 'src/components/UI'
import { ContentBox, Divider, TabBox } from '../../Templates'
import { useIsLgScreen, useIsXlScreen } from 'src/hooks'
import { useSelector } from 'react-redux'
import { getJob } from 'src/ducks/selectors'
import { JobItem } from 'src/ducks/types'


const PropertyAccess = () => {
  const [xlScreen] = useIsXlScreen()
  const [lgScreen] = useIsLgScreen()
  const job = useSelector(getJob())
  const {
    status, publicStatus
  } = job || {} as JobItem
  return (
    <TabBox>
      <ContentBox gridTemplateColumns={xlScreen || lgScreen ? '1fr 1fr' : '1fr'}>
        <LabeledText labelValue='Client Name:' textValue={job?.properties?.clientName || ''} />
        <LabeledText labelValue='Channel:' textValue={job?.properties?.affiliateType || ''} />
        <LabeledText labelValue='Job Status:' textValue={publicStatus?.toUpperCase() === "HOLD" ? "HOLD" : status ? status : ''} />
      </ContentBox>
      <Divider />
      <ContentBox gridTemplateColumns={xlScreen || lgScreen ? '1fr 1fr' : '1fr'}>
        <LabeledText labelValue='Access Info:' textValue={job?.properties?.accessDetails || ''} />
        <LabeledText labelValue='Lockbox details:' textValue={job?.properties?.lockBoxDetails || ''} />
      </ContentBox>
      <Divider />
      <ContentBox gridTemplateColumns={xlScreen || lgScreen ? '1fr 1fr' : '1fr'}>
        <LabeledText labelValue='Custom Flag:' textValue={''} />
        <LabeledText labelValue='Property Type:' textValue={''} />
        <LabeledText labelValue='Promo:' textValue={''} />
      </ContentBox>
    </TabBox>
  )
}

export default PropertyAccess
