import {
  ordersTypes,
  OrdersType,
  OrdersActions,
  CreateOrderRequest,
  OrderType,
  UpdateStatePayload,
  CreateBillPayload,
  GetOrderConflictParams,
  OrdersConflict
} from './types'

const actions = {
  fetchOrders: (
    payload: {
      params?: any,
      page?: number,
      size?: number,
    },
    callback?: (
      succ: boolean) => void
  ): OrdersActions => ({
    type: ordersTypes.FETCH_ORDERS,
    payload,
    callback
  }),
  getOrdersConflict: (
    payload: GetOrderConflictParams,
    callback?: (
      succ: boolean,
      orderConflict: OrdersConflict | null
    ) => void
  ): OrdersActions => ({
    type: ordersTypes.GET_ORDERS_CONFLICT,
    payload,
    callback
  }),
  setOrders: (payload: OrdersType): OrdersActions => ({
    type: ordersTypes.SET_ORDERS,
    payload
  }),
  setOrdersConflict: (payload: OrdersConflict): OrdersActions => ({
    type: ordersTypes.SET_ORDERS_CONFLICT,
    payload
  }),
  createOrder: (
    payload: CreateOrderRequest,
    callback: (succ: boolean, orderId: string | null) => void
  ): OrdersActions => ({
    type: ordersTypes.CREATE_ORDER,
    payload,
    callback
  }),
  updateOrder: (
    payload: Partial<OrderType>,
    callback: (succ: boolean) => void
  ): OrdersActions => ({
    type: ordersTypes.UPDATE_ORDER,
    payload,
    callback
  }),
  updateOrderState: (
    payload: UpdateStatePayload,
    callback?: (succ: boolean) => void
  ): OrdersActions => ({
    type: ordersTypes.UPDATE_ORDER_STATE,
    payload,
    callback
  }),
  createBill: (
    payload: CreateBillPayload,
    callback?: (succ: boolean) => void
  ): OrdersActions => ({
    type: ordersTypes.CREATE_BILL,
    payload,
    callback
  })
}

export default actions
