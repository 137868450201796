import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Scrollbar, Mousewheel } from 'swiper';
import { FC } from "react";
import { SliderProps } from "./types";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Box } from "@mui/material";

SwiperCore.use([Navigation, Pagination, Scrollbar, Mousewheel]);

const Slider: FC<SliderProps> = ({ loop, onSlideChange, direction = "horizontal", slidesPerView = 3, initialSlide, className, spaceBetween, pagination = false, navigation = false, children }) => {
    return (
        <Box display="flex" alignItems="center" gap="1rem">
            <Swiper
                loop={loop}
                direction={direction}
                slidesPerView={slidesPerView}
                navigation={navigation}
                className={`${className}`}
                onSlideChange={onSlideChange}
                slideToClickedSlide
                grabCursor
                freeMode
                mousewheel={{ forceToAxis: true, sensitivity: 1 }}
                initialSlide={initialSlide}
                spaceBetween={spaceBetween}
                pagination={pagination}
            >
                {children && children.map((slide: any, index) => <SwiperSlide key={`${index}`}>{slide}</SwiperSlide>)}
            </Swiper>
        </Box>
    )
}

export default Slider;