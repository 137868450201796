import { SelectInputOption } from 'src/components/UI/CustomUI/atoms/SelectInput/types'
import storeInfo from '../store'
import { InspectionCompany } from '../types'

export const getInspectionCompany = (): InspectionCompany => {
  const { inspectionCompany } = storeInfo.store.getState()
  return inspectionCompany
}

export const getInspectorsCompanyForSelect = (): SelectInputOption[] => {
  const { inspectionCompany }: { inspectionCompany: InspectionCompany } =
    storeInfo.store.getState()
  const flattenedData = inspectionCompany?.inspectors?.reduce(
    (acc: any, curr: InspectionCompany) => {
      return [
        ...acc,
        (acc = {
          key: curr,
          label: curr.name,
        }),
      ]
    },
    []
  )
  return flattenedData as SelectInputOption[]
}
