import storeInfo from '../store'
import { Message } from '../types'

export const getMessages = () => (): Message[] => {
  const { communications: { messages } } = storeInfo.store.getState()
  return messages
}

export const getMessagesByThread = () => (): Message[] => {
  const { communications: { messagesByThread } } = storeInfo.store.getState()
  return messagesByThread
}

export const getAmounts = () => (): any => {
  const { communications: { amounts } } = storeInfo.store.getState()
  return amounts
}

export const getEmailEventList = () => (): any => {
  const { communications: { emailEvents } } = storeInfo.store.getState()
  return emailEvents?.items || []
}

export const getEmailEventsTotalCount = () => (): any => {
  const { communications: { emailEvents } } = storeInfo.store.getState()
  return emailEvents?.total || 0
}
