import { authTypes } from '../auth/types'
import { Vendor } from '../types'
import { VendorActions, VendorType, vendorTypes, VendorValue } from './types'

const initialState: VendorType = null

const reducer = (state = initialState, action: VendorActions): VendorType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case vendorTypes.SET_VENDOR: {
      return payload as Vendor
    }

    case vendorTypes.SET_VENDOR_VALUE: {
      const { attr, value } = payload as VendorValue
      if (state === null) return null
      else {
        return {
          ...state as unknown as Vendor,
          [attr]: value
        }
      }
    }

    default:
      return state
  }
}

export default reducer
