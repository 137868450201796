import { Typography } from '@mui/material'
import { AlignType, HeaderType } from 'src/components/UI/CustomUI/organisms/Table/types'
import { formatTimestamp } from '../../../../helpers'
import { ProgressBar } from '../../../UI'

export const csvheaders: HeaderType[] = [
  {
    id: 'firstDispatchDate',
    label: 'First Dispatched'
  },
  {
    id: 'lastDispatchDate',
    label: 'Last Dispatched'
  },
  {
    id: 'lastSiteVisitDate',
    label: 'Site Visit'
  },
  {
    id: 'notes',
    label: 'Note'
  }
]

const headers: HeaderType[] = [
  {
    id: 'publicId',
    label: 'Job ID',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <a href={`jobs/${row.id}`} target='_blank' rel='noreferrer'>
          <Typography sx={{ fontSize: '10px', fontWeight: 400 }}> {row?.publicId} </Typography>
        </a>
      )
    }
  },
  {
    id: 'fullAddress',
    label: 'Address',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <a href={`jobs/${row.id}`} target='_blank' rel='noreferrer'>
          <Typography sx={{ fontSize: '10px', fontWeight: 400 }}> {row?.fullAddress} </Typography>
        </a>
      )
    }
  },
  {
    id: 'city',
    label: 'City',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <a href={`jobs/${row.id}`} target='_blank' rel='noreferrer'>
          <Typography sx={{ fontSize: '10px', fontWeight: 400 }}> {row?.city} </Typography>
        </a>
      )
    }
  },
  {
    id: 'territory',
    label: 'Territory',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <a href={`jobs/${row.id}`} target='_blank' rel='noreferrer'>
          <Typography sx={{ fontSize: '10px', fontWeight: 400 }}> {row?.territory} </Typography>
        </a>
      )
    }
  },
  {
    id: 'totalValue',
    label: 'Price',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <a href={`jobs/${row.id}`} target='_blank' rel='noreferrer'>
          <Typography sx={{ fontSize: '10px', fontWeight: 400 }}> {`$${typeof row?.totalValue === "string" ? row?.totalValue : row?.totalValue?.toFixed(2)}`} </Typography>
        </a>
      )
    }
  },
  {
    id: 'nextTask',
    label: 'Task',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <a href={`jobs/${row.id}`} target='_blank' rel='noreferrer'>

          <Typography sx={{ fontSize: '10px', fontWeight: 400 }}> {row?.nextTask} </Typography>
        </a>
      )
    }
  },
  {
    id: 'status',
    label: 'Status',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <a href={`jobs/${row.id}`} target='_blank' rel='noreferrer'>
          <Typography sx={{ fontSize: '10px', fontWeight: 700 }} color='var(--green900)'> {row?.publicStatus?.toUpperCase() === "HOLD" ? "HOLD" : row?.status ? row?.status : ''} </Typography>
        </a>
      )
    }
  },
  {
    id: 'flags',
    label: 'Flags',
    custom: true,
    align: 'center' as AlignType,
    maxWidth: 150,
    Element: (row: any): JSX.Element => {
      return (
        <a href={`jobs/${row.id}`} target='_blank' rel='noreferrer'>
          <Typography sx={{ fontSize: '10px', fontWeight: 700 }} color='var(--blue700)'> {row?.flags != null && row?.flags.length > 0 ? row?.flags[0].flag : '-'} </Typography>
        </a>
      )
    }
  },
  {
    id: 'approvedOn',
    label: 'Approved on',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <a href={`jobs/${row.id}`} target='_blank' rel='noreferrer'>
          <Typography sx={{ fontSize: '10px', fontWeight: 400 }}>
            {row?.createdOn ? formatTimestamp(row?.createdOn, 'MM/DD/YYYY') : '-'}
          </Typography>
        </a>
      )
    }
  },
  {
    id: 'startedOn',
    label: 'Start Date',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <a href={`jobs/${row.id}`} target='_blank' rel='noreferrer'>
          <Typography sx={{ fontSize: '10px !important', fontWeight: 400 }}>
            {row?.startedOn ? formatTimestamp(row?.startedOn, 'MM/DD/YYYY') : '-'}
          </Typography>
        </a>
      )
    }
  },
  {
    id: 'expectedCompletedOn',
    label: 'Expected Date',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <a href={`jobs/${row.id}`} target='_blank' rel='noreferrer'>
          <Typography sx={{ fontSize: '10px', fontWeight: 400 }}>
            {row?.expectedCompletedOn ? formatTimestamp(row?.expectedCompletedOn, 'MM/DD/YYYY') : '-'}
          </Typography>
        </a>
      )
    }
  },
  {
    id: 'dueOn',
    label: 'Due Date',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <a href={`jobs/${row.id}`} target='_blank' rel='noreferrer'>
          <Typography sx={{ fontSize: '10px', fontWeight: 400 }}>
            {row?.dueOn ? formatTimestamp(row?.dueOn, 'MM/DD/YYYY') : '-'}
          </Typography>
        </a>
      )
    }
  },
  {
    id: 'closingDate',
    label: 'Closing Date',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <a href={`jobs/${row.id}`} target='_blank' rel='noreferrer'>
          <Typography sx={{ fontSize: '10px', fontWeight: 400 }}>
            {row?.properties?.closingDate ? formatTimestamp(row?.properties?.closingDate, 'MM/DD/YYYY') : '-'}
          </Typography>
        </a>
      )
    }
  },
  {
    id: 'percentItemsComplete',
    label: 'Progress',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <a href={`jobs/${row.id}`} target='_blank' rel='noreferrer'>
          <ProgressBar value={row.percentItemsComplete ?? 0} />
        </a>
      )
    }
  },
  {
    id: 'territoryManager',
    label: 'TM',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <a href={`jobs/${row.id}`} target='_blank' rel='noreferrer'>
          <Typography sx={{ fontSize: '10px', fontWeight: 400 }}> {row?.territoryManager} </Typography>
        </a>
      )
    }
  },
  {
    id: 'csm',
    label: 'CSM',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <a href={`jobs/${row.id}`} target='_blank' rel='noreferrer'>
          <Typography sx={{ fontSize: '10px', fontWeight: 400 }}> {row?.csm} </Typography>
        </a>
      )
    }
  },
  {
    id: 'client',
    label: 'Client',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <a href={`jobs/${row.id}`} target='_blank' rel='noreferrer'>
          <Typography sx={{ fontSize: '10px', fontWeight: 400 }}> {row?.client} </Typography>
        </a>
      )
    }
  },
  {
    id: 'affiliate',
    label: 'Affiliate',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <a href={`jobs/${row.id}`} target='_blank' rel='noreferrer'>
          <Typography sx={{ fontSize: '10px', fontWeight: 400 }}> {row?.affiliate} </Typography>
        </a>
      )
    }
  },
  {
    id: 'affiliateType',
    label: 'Type',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <a href={`jobs/${row.id}`} target='_blank' rel='noreferrer'>
          <Typography sx={{ fontSize: '10px', fontWeight: 400 }}> {row?.affiliateType} </Typography>
        </a>
      )
    }
  }
]

export default headers
