import { Grid } from '@mui/material'
import Table from 'src/components/UI/CustomUI/organisms/Table'

import headers from './headers'
import { useSelector } from 'react-redux'
import { getPCFinancials, getPCFinancialsJobs } from 'src/ducks/selectors'
import { round, roundWithoutCents } from 'src/helpers'
import TabNavigation from 'src/components/UI/CustomUI/organisms/TabNavigation'

const Financials = () => {
  const pcFinancials = useSelector(getPCFinancials())
  const pcFinancialsJobs = useSelector(getPCFinancialsJobs())



  const financialsWO = [
    {
      totals: 'Total Active Work Orders',
      count: pcFinancials?.totalActiveWO === undefined ? '-' : pcFinancials?.totalActiveWO,
      isBold: true,
      padding: "4px 8px"
    },
    {
      totals: 'Total $ Active Contract Value',
      count: pcFinancials?.totalActiveContractValue !== undefined ? `$${roundWithoutCents(pcFinancials?.totalActiveContractValue)}` : '-',
      align: "right",
      padding: "4px 8px"
    },
    {
      totals: 'AVG $ Active Contract Value',
      count: pcFinancials?.avgActiveContractValue !== undefined ? `$${roundWithoutCents(pcFinancials?.avgActiveContractValue)}` : '-',
      padding: "4px 8px"
    },
    {
      totals: 'AVG $ Complete per day (past 30 days)',
      count: pcFinancials?.avgCompleteWOValue !== undefined ? `$${roundWithoutCents(pcFinancials?.avgCompleteWOValue)}` : '-',
      padding: "4px 8px"
    },
    {
      totals: 'AVG $ Completed per day (month to date)',
      count: pcFinancials?.avgCompleteWoValueThisMonth
        !== undefined ? `$${roundWithoutCents(pcFinancials?.avgCompleteWoValueThisMonth
        )}` : '-',
      padding: "4px 8px"
    },
  ]
  const financialsJOBS = [
    {
      totals: 'Total Active Jobs',
      count: pcFinancialsJobs?.totalActiveJobs === undefined ? '-' : pcFinancialsJobs?.totalActiveJobs,
      isBold: true,
      padding: "4px 8px"
    },
    {
      totals: 'Total $ Active Contract Value',
      count: pcFinancialsJobs?.totalActiveContractValue !== undefined ? `$${roundWithoutCents(pcFinancialsJobs?.totalActiveContractValue)}` : '-',
      align: "right",
      padding: "4px 8px"
    },
    {
      totals: 'AVG $ Active Contract Value',
      count: pcFinancialsJobs?.avgActiveContractValue !== undefined ? `$${roundWithoutCents(pcFinancialsJobs?.avgActiveContractValue)}` : '-',
      padding: "4px 8px"
    },
    {
      totals: 'AVG $ Complete per day (past 30 days)',
      count: pcFinancialsJobs?.avgCompleteJobValue !== undefined ? `$${roundWithoutCents(pcFinancialsJobs?.avgCompleteJobValue)}` : '-',
      padding: "4px 8px"
    },
    {
      totals: 'AVG $ Completed per day (month to date)',
      count: pcFinancialsJobs?.avgCompleteJobValueThisMonth
        !== undefined ? `$${roundWithoutCents(pcFinancialsJobs?.avgCompleteJobValueThisMonth
        )}` : '-',
      padding: "4px 8px"
    },
  ]

  const FinancialsTabs = [
    {
      label: 'Work Order', content: <Table
        headers={headers}
        defaultOrder="desc"
        // loading={false}
        hasPagination={false}
      >
        {financialsWO ?? []}
      </Table>
    },
    {
      label: 'Jobs', content: <Table
        headers={headers}
        defaultOrder="desc"
        // loading={false}
        hasPagination={false}
      >
        {financialsJOBS ?? []}
      </Table>
    }
  ]

  return (
    <Grid container item spacing={1} direction="column" style={{ overflow: 'overlay', flexWrap: 'nowrap' }} height='250px'>
      <Grid item>
        <TabNavigation tabs={FinancialsTabs} variant='contained' margin='0 0 0.5rem' />
      </Grid>
    </Grid>
  )
}

export default Financials
