import { FC } from 'react'
import { Box, Typography } from '@mui/material'
import { AdminSectionProps } from './types'
import AdminCheckbox from '../AdminCheckbox'

const AdminSection: FC<AdminSectionProps> = ({ label, checkBoxes }) => {
  return (
    <Box display="flex" flexDirection="column" gap={1} flex="1">
      <Typography
        variant="h4"
        fontFamily="Nouvelle"
        style={{ marginTop: '16px' }}
      >
        {label}
      </Typography>

      {checkBoxes.map((box) => (
        <AdminCheckbox
          label={box.label}
          checked={box.checked}
          onChange={box.onChange}
          property={box.property}
        />
      ))}
    </Box>
  )
}

export default AdminSection
