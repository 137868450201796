import { Image, Page, Path, Svg, Text, View } from '@react-pdf/renderer'
import styles from './style'
import {
  appStore,
  blueLogo,
  bosscatAppQr,
  bosscatLogo,
  bosscatWarranty,
  googleStore,
  marketingImg,
  phoneApp,
} from 'src/assets/index'

const Page5 = ({ qrCode }) => {
  return (
    <Page size="A4" wrap>
      <View style={{ ...styles.page5 }}>
        <Text
          style={{
            ...styles.fontXLarger,
            ...styles.nextArtSemiBold,
            ...styles.blue900,
            marginTop: '24px'
          }}
        >
          NEXT STEPS WITH YOUR ESTIMATE
        </Text>
        <Text
          style={{
            ...styles.font18,
            ...styles.latoBold,
            ...styles.blue600,
          }}
        >
          Customize your estimate and book your project online
        </Text>
        <View
          style={[
            styles.row,
            styles.alignCenter,
            styles.justifyCenter,
            styles.w100,
          ]}
        >
          {qrCode && (
            <Image
              src={qrCode}
              alt="estimate qr code"
              style={{
                ...styles.mh3,
                ...styles.qrCode,
              }}
            />
          )}
          <Text
            style={{
              ...styles.fontSmall,
              ...styles.latoBold,
              ...styles.ml1,
            }}
          >
            Your Interactive Online Estimate
          </Text>
        </View>

        <Image
          src={marketingImg}
          alt="marketing"
          style={{ ...styles.w100, marginTop: '46px' }}
        />
        {/* {marketingImg && (
          <Image
            src={marketingImg}
            alt="marketing"
            style={{ ...styles.w100 }}
          />
        )} */}
      </View>
      <View
        style={{
          ...styles.page5Footer,
          ...styles.backgroundBlue800,
          ...styles.row,
          ...styles.justifyBetween,
        }}
      >
        <View
          style={{
            ...styles.w50,
            ...styles.flexColumn,
            marginBottom: '32px',
          }}
        >
          <Text
            style={{
              ...styles.fontLarge,
              ...styles.nextArtBold,
              ...styles.white,
            }}
          >
            DOWNLOAD THE
          </Text>
          <View style={{ ...styles.row, ...styles.alignCenter }}>
            <Image src={blueLogo} style={{ ...styles.bosscatLogo }} />
            <Text
              style={{
                ...styles.fontLarge,
                ...styles.nextArtBold,
                ...styles.white,
                ...styles.ml1,
              }}
            >
              app
            </Text>
          </View>
          <View style={{
                ...styles.mh2,
          }}>
            <Text
              style={{
                ...styles.fontMedium,
                ...styles.latoNormal,
                ...styles.white,
              }}
            >
              View your estimate, add to your shopping cart, order, and schedule
              your
            </Text>
            <Text style={{
              ...styles.fontMedium,
              ...styles.latoNormal,
              ...styles.white,
            }}>
              services.
            </Text>
          </View>
          <View
            style={{
              ...styles.row,
              ...styles.gap2,
              ...styles.alignCenter,
            }}
          >
            {bosscatAppQr && (
              <Image
                src={bosscatAppQr}
                alt="bosscat app qr code"
                style={{
                  // width: '80px',
                  ...styles.qrCode,
                }}
              />
            )}
            <View
              style={{
                ...styles.flexColumn,
                ...styles.alignCenter,
                ...styles.justifyCenter,
              }}
            >
              <Image
                src={googleStore}
                alt="app store qr code"
                style={{
                  width: '80px',
                  marginTop: '8px',
                  borderRadius: '8px',
                  // ...styles.qrCode,
                }}
              />
              <Image
                src={appStore}
                alt="google store qr code"
                style={{
                  ...styles.mh1,
                  width: '80px',
                  borderRadius: '8px',
                  // ...styles.qrCode,
                }}
              />
            </View>
          </View>
        </View>
        <View
          style={{
            ...styles.w50,
            ...styles.row,
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
          }}
        >
          <Image
            src={phoneApp}
            alt="phone app  image"
            style={{ ...styles.w80, ...styles.appImageFooter }}
          />
        </View>
      </View>
    </Page>
  )
}

export default Page5
