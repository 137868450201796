/* eslint-disable @typescript-eslint/no-unused-vars */
import storeInfo from '../../ducks/store'
import { SiteVisitsType } from './types'
import { OrderType } from '../orders/types'

export const getSiteVisits = () => (): OrderType[] => {
  const { siteVisits } = storeInfo.store.getState()
  return siteVisits ? siteVisits.items : []
}

export const getTotalVisits = () => {
  const { siteVisits } = storeInfo.store.getState()
  return siteVisits ? siteVisits.total : 0
}