import { Box, Typography } from 'src/components/UI'
import {
  AlignType,
  HeaderType,
} from 'src/components/UI/CustomUI/organisms/Table/types'
import { formatTimestamp } from 'src/helpers'
import { SubscriptionItem } from 'src/ducks/types'

const applyRegExp = (phone: string): string => {
  if (!phone) return ''

  return String(phone).replace(/^(\d{3})(\d{3})(\d{4})$/g, '+1 $1-$2-$3')
}

export const getLastService = (row: SubscriptionItem) => {
  const now = new Date().getTime() / 1000
  const arr = (row.services || [])
    .filter(
      (service: any) => service.scheduledDate && service.scheduledDate < now
    )
    .sort((a: any, b: any) => b.scheduledDate - a.scheduledDate)

  return arr[0]
}

export const getNextService = (row: SubscriptionItem) => {
  const now = new Date().getTime() / 1000
  const arr = (row.services || [])
    .filter(
      (service: any) => service.scheduledDate && service.scheduledDate > now
    )
    .sort((a: any, b: any) => a.scheduledDate - b.scheduledDate)

  return arr[0]
}

const subscriptionsHeaders: HeaderType[] = [
  {
    id: 'name',
    label: 'Name',
    custom: true,
    align: 'left' as AlignType,
    Element: (row: SubscriptionItem): JSX.Element => {
      const {
        homeowner: { firstName, lastName },
      } = row

      return (
        <a href={`subscriptions/${row.id}`} target="_blank" rel="noreferrer">
          <Box className='Subscriptions__cell'>
            <Typography
              fontWeight={600}
            >{`${firstName} ${lastName}`}</Typography>
          </Box>
        </a>
      )
    },
  },
  {
    id: 'email',
    label: 'Email',
    custom: true,
    align: 'left' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <a href={`subscriptions/${row.id}`} target="_blank" rel="noreferrer">
          <Box className='Subscriptions__cell'>
            <Typography fontWeight={600}>
              {row.homeowner.email[0].email}
            </Typography>
          </Box>
        </a>
      )
    },
  },
  {
    id: 'phone',
    label: 'Phone',
    custom: true,
    align: 'left' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <a href={`subscriptions/${row.id}`} target="_blank" rel="noreferrer">
          <Box className='Subscriptions__cell'>
            <Typography>{applyRegExp(row.homeowner.phone)}</Typography>
          </Box>
        </a>
      )
    },
  },
  {
    id: 'address',
    label: 'Address',
    custom: true,
    align: 'left' as AlignType,
    Element: (row: SubscriptionItem): JSX.Element => {
      return (
        <a href={`subscriptions/${row.id}`} target="_blank" rel="noreferrer">
          <Box className='Subscriptions__cell'>
            <Typography fontWeight={600}>
              {row.propertyAddress?.fullAddress || ''}
            </Typography>
          </Box>
        </a>
      )
    },
  },
  {
    id: 'territory',
    label: 'Territory',
    custom: true,
    align: 'left' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <a href={`subscriptions/${row.id}`} target="_blank" rel="noreferrer">
          <Box className='Subscriptions__cell'>
            <Typography fontWeight={600}>
              {row.territory?.title || ''}
            </Typography>
          </Box>
        </a>
      )
    },
  },
  {
    id: 'subStart',
    label: 'Sub Start',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <a href={`subscriptions/${row.id}`} target="_blank" rel="noreferrer">
          <Box className='Subscriptions__cell'>
            <Typography>
              {formatTimestamp(row?.contractSignedOn, 'MM/DD/YYYY')}
            </Typography>
          </Box>
        </a>
      )
    },
  },
  {
    id: 'subEnd',
    label: 'Sub End',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <a href={`subscriptions/${row.id}`} target="_blank" rel="noreferrer">
          <Box className='Subscriptions__cell'>
            <Typography>
              {formatTimestamp(row?.expiresOn, 'MM/DD/YYYY')}
            </Typography>
          </Box>
        </a>
      )
    },
  },
  {
    id: 'purchasedBy',
    label: 'Purchased By',
    custom: true,
    align: 'left' as AlignType,
    Element: (row: SubscriptionItem): JSX.Element => {
      const {
        purchaser: { firstName, lastName },
      } = row

      return (
        <a href={`subscriptions/${row.id}`} target="_blank" rel="noreferrer">
          <Box className='Subscriptions__cell'>
            <Typography
              fontWeight={600}
            >{`${firstName} ${lastName}`}</Typography>
          </Box>
        </a>
      )
    },
  },
  {
    id: 'shippingName',
    label: 'GiftBox Shipping Name',
    custom: true,
    align: 'left' as AlignType,
    Element: (row: SubscriptionItem): JSX.Element => {
      const {
        giftBox,
      } = row
      const { shippingName } = giftBox || {}

      return (
        <a href={`subscriptions/${row.id}`} target="_blank" rel="noreferrer">
          <Box className='Subscriptions__cell'>
            <Typography
              fontWeight={600}
            >{`${shippingName || ""}`}</Typography>
          </Box>
        </a>
      )
    },
  },
  {
    id: 'lastService',
    label: 'Last Service',
    custom: true,
    align: 'left' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <a href={`subscriptions/${row.id}`} target="_blank" rel="noreferrer">
          <Box className='Subscriptions__cell'>
            <Typography fontWeight={600}>
              {getLastService(row)?.title || ''}
            </Typography>
          </Box>
        </a>
      )
    },
  },
  {
    id: 'nextService',
    label: 'Next Service',
    custom: true,
    align: 'left' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <a href={`subscriptions/${row.id}`} target="_blank" rel="noreferrer">
          <Box className='Subscriptions__cell'>
            <Typography fontWeight={600}>
              {getNextService(row)?.title || ''}
            </Typography>
          </Box>
        </a>
      )
    },
  },
  {
    id: 'status',
    label: 'Status',
    custom: true,
    align: 'left' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <a href={`subscriptions/${row.id}`} target="_blank" rel="noreferrer">
          <Box className='Subscriptions__cell'>
            <Typography fontWeight={600}>{row.status}</Typography>
          </Box>
        </a>
      )
    },
  },
  {
    id: 'giftStatus',
    label: 'Gift Status',
    custom: true,
    align: 'left' as AlignType,
    Element: (row: SubscriptionItem): JSX.Element => {
      const status =
        row.gift === false
          ? 'None'
          : row.giftBox?.trackingId
            ? 'Sent'
            : 'Not Sent'

      return (
        <a href={`subscriptions/${row.id}`} target="_blank" rel="noreferrer">
          <Box className='Subscriptions__cell'>
            <Typography fontWeight={600}>{status}</Typography>
          </Box>
        </a>
      )
    },
  },
  {
    id: 'promoCode',
    label: 'Promo Code',
    custom: true,
    align: 'left' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <a href={`subscriptions/${row.id}`} target="_blank" rel="noreferrer">
          <Box className='Subscriptions__cell'>
            <Typography fontWeight={600}>{row.promoCode}</Typography>
          </Box>
        </a>
      )
    },
  },
]

export default subscriptionsHeaders
