import { Widget } from '../types'
import { WidgetsActions, WidgetsType, widgetsTypes } from './types'

const actions = {
  fetchWidgets: (
    payload: null,
    callback?: (succ: boolean) => void): WidgetsActions => ({
      type: widgetsTypes.FETCH_WIDGETS,
      payload,
      callback
    }),
  setWidgets: (payload: WidgetsType): WidgetsActions => ({
    type: widgetsTypes.SET_WIDGETS,
    payload
  }),
  createWidget: (
    payload: Partial<Widget>,
    callback: (succ: boolean) => void
  ): WidgetsActions => ({
    type: widgetsTypes.CREATE_WIDGET,
    payload,
    callback,
  }),
}

export default actions
