/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo } from '../../helpers'
import { Punchlist } from '../../api'
import { FetchNewEstimatesAction, estimatesByCategoryTypes } from './types'
import { estimatesByCategoryActions } from '../actions'

export function* fetchEstimatesByStage({ payload, callback }: FetchNewEstimatesAction): SagaIterator {
  let success = false

  const { stage, searchParams, start, limit } = payload

  try {
    const estimates = yield call(Punchlist.estimates.getEstimates, { start: start || 0, limit: limit || 50, searchParams: searchParams})

    if (estimates) {
      yield put(estimatesByCategoryActions.setEstimatesByStage({ stage, data: estimates }))
    }
    success = true
    if (callback) yield call(callback, success, estimates.limit)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the estimates'))
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeEvery(estimatesByCategoryTypes.FETCH_ESTIMATES_BY_STAGE, fetchEstimatesByStage)
}
