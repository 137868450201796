import { authTypes } from '../auth/types'
import { EmployeeType } from '../employee/types'
import { Employees, EmployeesActions, EmployeesType, employeesTypes } from './types'

const initialState: Employees = null

const reducer = (state = initialState, action: EmployeesActions): Employees => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case employeesTypes.SET_EMPLOYEES: {
      return payload as Employees
    }

    case employeesTypes.UPDATE_EMPLOYEE_IN_LIST_BY_ID: {
      const {
        updatedValues,
        id
      } = payload as { updatedValues: Partial<EmployeeType>, id: string }

      const { items } = state as unknown as EmployeesType

      if (items?.some(e => e.id === id)) {
        return {
          ...state as Employees,
          items: items.map(employee => {
            if (employee.id === id) {
              return {
                ...employee,
                ...updatedValues
              }
            }
            return employee
          })

        } as Employees
      }
      return state
    }

    default:
      return state
  }
}

export default reducer
