import { makeStyles } from '@mui/styles'
import { Theme } from "@mui/material";

export default makeStyles((theme: Theme) => ({
  bold: {
    fontFamily: 'NouvelleBold !important',
    display: 'flex',
    gap: '.2rem',
    fontSize: '16px !important'
  },
  amount: {
    color: 'var(--green900)',
    fontFamily: 'NouvelleBold !important',
    fontSize: '16px !important'
  },
}))
