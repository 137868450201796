import { EmailEvents, Message } from '../types'
import { AmountsTypes, AmountValues, communicationsTypes, GetMessagesParams, MessagesActions, SendMessageAction } from './types'

const actions = {
  getMessages: (
    payload: { params?: Partial<GetMessagesParams> },
    callback: (succ: boolean, messages?: Message[] | null) => void
  ): MessagesActions => ({
    type: communicationsTypes.GET_MESSAGES,
    payload,
    callback
  }),
  getAmounts: (
    payload: { params?: Partial<GetMessagesParams> },
    callback: (succ: boolean) => void
  ): MessagesActions => ({
    type: communicationsTypes.GET_AMOUNTS,
    payload,
    callback
  }),
  getEmailEvents: (
    payload: {
      id: string,
      start?: number,
      limit?: number,
    },
    callback: (succ: boolean) => void
  ): MessagesActions => ({
    type: communicationsTypes.GET_EMAIL_EVENTS,
    payload,
    callback
  }),
  setMessages: (payload: Message[]): MessagesActions => ({
    type: communicationsTypes.SET_MESSAGES,
    payload
  }),
  setMessage: (payload: Message): MessagesActions => ({
    type: communicationsTypes.SET_MESSAGE,
    payload
  }),
  setMessagesByThread: (payload: Message[]): MessagesActions => ({
    type: communicationsTypes.SET_MESSAGES_BY_THREAD,
    payload
  }),
  setMessageByThread: (payload: Message): MessagesActions => ({
    type: communicationsTypes.SET_MESSAGE_BY_THREAD,
    payload
  }),
  setEmailEvents: (payload: EmailEvents): MessagesActions => ({
    type: communicationsTypes.SET_EMAIL_EVENTS,
    payload
  }),
  sendMessage: (
    payload: Partial<Message>,
    callback?: (succ: boolean, message?: Message | null) => void
  ): SendMessageAction => ({
    type: communicationsTypes.SEND_MESSAGE,
    payload,
    callback
  }),
  replyThread: (
    payload: Partial<Message>,
    callback?: (succ: boolean, messages?: Message[] | null) => void
  ): MessagesActions => ({
    type: communicationsTypes.REPLY_THREAD,
    payload,
    callback
  }),
  resetAmounts: (): MessagesActions => ({
    type: communicationsTypes.RESET_AMOUNTS,
    payload: null
  }),
  setAmountsValue: (payload: AmountValues): MessagesActions => ({
    type: communicationsTypes.SET_AMOUNT_VALUE,
    payload
  })
}

export default actions
