import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material"
import DatePicker from "../../../atoms/DatePicker"
import { useRef, useState } from "react"
import { Range } from "../../../atoms/DatePicker/types"
import { icons } from './../../../../../../assets'
import { useDispatch } from "react-redux"
import { reportsActions } from "src/ducks/actions"
import { CSVLink } from "react-csv"
import ExportCSV from "../../../molecules/ExportCSV"
import { dateFormatString, formatDateTimestamp } from "src/helpers"

const ReportsModal = ({ onClose }: { onClose?: () => void }) => {
    const dispatch = useDispatch()

    const [rangeSelected, setRangeSelected] = useState<Range | null>(null)
    const [loading, setLoading] = useState(false)
    const [csvData, setCsvData] = useState<string>("")

    const downloadRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);


    const handleSelect = (rangeSelected: Range) => {
        setRangeSelected(rangeSelected)
    }

    const onSubmit = () => {
        setLoading(true)
        const searchParams = rangeSelected?.startDate && rangeSelected?.endDate ? { createdStartDate: dateFormatString(rangeSelected.startDate), createdEndDate: dateFormatString(rangeSelected.endDate) } : {};

        dispatch(reportsActions.requestReportsPipeline({ searchParams }, (succ, csvData) => {
            setLoading(false)
            setCsvData(csvData)
            // downloadRef?.current?.link?.click()
            succ && onClose && onClose()
            const link = document.createElement('a');
            link.href = csvData;

            // Set the download attribute to specify the file name
            link.download = 'reports.csv'; // Set your custom file name here

            // Append the anchor element to the body
            document.body.appendChild(link);

            // Trigger a click event on the anchor element
            link.click();

            // Clean up: remove the anchor element from the body
            document.body.removeChild(link);
        }))
    }

    return (
        <Grid container spacing={2}>
            <Grid item>
                <Typography color="#494A5A">You'll get a combined report of estimates and jobs in a given time period. Select the corresponding range you need.</Typography>
            </Grid>
            <Grid item xs={12}>
                <DatePicker
                    onChange={handleSelect}
                    value={{
                        startDate: rangeSelected?.startDate ? new Date(rangeSelected?.startDate) : undefined,
                        endDate: rangeSelected?.endDate ? new Date(rangeSelected?.endDate) : undefined
                    }}
                    //style={{ height: '38px', padding: '0px', width: '100%' }}
                    size='small'
                    label='Date Range'
                    allowRange
                    labelVariant="h6Bold"
                    placeholder="mm/dd/yy - mm/dd/yy"
                    centerOnScreen
                    quickOptions={['This Year', 'Last Month', 'Last Quarter', 'Last Year']}
                />
            </Grid>
            <Grid item xs={6}>
                <Button
                    onClick={onClose}
                    fullWidth
                    disabled={loading}
                    variant='containedLight'>
                    Cancel
                </Button>
            </Grid>
            <Grid item xs={6}>
                {loading ?
                    <Box display='flex' justifyContent='center'>
                        <CircularProgress size={30} />
                    </Box>
                    : (
                        <Button
                            onClick={onSubmit}
                            fullWidth
                            variant='contained'
                            endIcon={<icons.Download />}
                            disabled={!rangeSelected?.startDate || !rangeSelected?.endDate}
                        >
                            Download Report
                        </Button>
                    )}
                {<ExportCSV
                    innerRef={downloadRef}
                    headers={csvData?.slice(0, csvData?.search('\n'))?.split(',')?.map((h: any) => h)}
                    data={csvData ? csvData?.slice(csvData?.indexOf('\n') + 1) : []}
                    fileName={'reports.csv'}
                    label=''
                />}
            </Grid>
        </Grid>

    )
}


export default ReportsModal