import { UpdateRequest } from '~api/types'
import { MaintenanceService, SubscriptionItem } from '../types'

/** TYPES **/
export const subscriptionTypes = {
  FETCH_SUBSCRIPTION: 'FETCH_SUBSCRIPTION',
  UPDATE_SUBSCRIPTION: 'UPDATE_SUBSCRIPTION',
  CREATE_JOB_FROM_SUBSCRIPTION: 'CREATE_JOB_FROM_SUBSCRIPTION',
  SET_SUBSCRIPTION: 'SET_SUBSCRIPTION',
  DELETE_SUBSCRIPTION: 'DELETE_SUBSCRIPTION',
  SET_SUBSCRIPTION_SERVICE: 'SET_SUBSCRIPTION_SERVICE',
}

export interface UpdateSubscriptionPayload {
  subscriptionId: string
  subscription: Partial<SubscriptionItem>
}
/** ACTIONS **/
export interface FetchSubscriptionAction {
  type: typeof subscriptionTypes.FETCH_SUBSCRIPTION
  payload: string
  callback?: (succ: boolean) => void
}

export interface UpdateSubscriptionByPathAction {
  type: typeof subscriptionTypes.UPDATE_SUBSCRIPTION
  payload: UpdateRequest
  callback: (succ: boolean) => void
}

export interface CreateJobFromSubscriptionAction {
  type: typeof subscriptionTypes.CREATE_JOB_FROM_SUBSCRIPTION
  payload: {
    subscriptionId: string,
    title: string
  }
  callback?: (succ: boolean, service: MaintenanceService | null) => void
}
export interface DeleteSubscriptionAction {
  type: typeof subscriptionTypes.DELETE_SUBSCRIPTION
  payload: {
    subscriptionId: string,
  }
  callback?: (succ: boolean) => void
}

export interface SetSubscriptionAction {
  type: typeof subscriptionTypes.SET_SUBSCRIPTION
  payload: SubscriptionItem | null
}

export type SubscriptionActions =
  | FetchSubscriptionAction
  | SetSubscriptionAction
  | UpdateSubscriptionByPathAction
  | CreateJobFromSubscriptionAction
  | DeleteSubscriptionAction

/** REDUCER **/
export type SubscriptionType = SubscriptionItem | null
