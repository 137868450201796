import { CompanyItem, InspectionCompany } from '../types'
import { SearchParams } from '../searches/types'
import { UpdateRequest } from '~api/types'

/** TYPES **/
export const inspectionCompaniesTypes = {
  FETCH_INSPECTION_COMPANIES: 'FETCH_INSPECTION_COMPANIES',
  CREATE_INSPECTION_COMPANY: 'CREATE_INSPECTION_COMPANY',
  UPDATE_INSPECTION_COMPANY: 'UPDATE_INSPECTION_COMPANY',
  DELETE_INSPECTION_COMPANY: 'DELETE_INSPECTION_COMPANY',
  SET_INSPECTION_COMPANIES: 'SET_INSPECTION_COMPANIES',
}

/** ACTIONS **/
export interface FetchInspectionCompaniesAction {
  type: typeof inspectionCompaniesTypes.FETCH_INSPECTION_COMPANIES
  payload?: {
    page?: number
    size?: number
    searchParams?: Partial<SearchParams>
  }
  callback?: (succ: boolean) => void
}

export interface CreateInspectionCompanyAction {
  type: typeof inspectionCompaniesTypes.CREATE_INSPECTION_COMPANY
  payload: Partial<InspectionCompany>
  callback?: (succ: boolean) => void
}

export interface UpdateInspectionCompanyAction {
  type: typeof inspectionCompaniesTypes.UPDATE_INSPECTION_COMPANY
  payload: {
    id: string
    request: UpdateRequest
  }
  callback?: (succ: boolean) => void
}

export interface DeleteInspectionCompanyAction {
  type: typeof inspectionCompaniesTypes.DELETE_INSPECTION_COMPANY
  payload: string
  callback?: (succ: boolean) => void
}

export interface SetInspectionCompaniesAction {
  type: typeof inspectionCompaniesTypes.SET_INSPECTION_COMPANIES
  payload: InspectionCompaniesType
}

export type InspectionCompaniesActions =
  | FetchInspectionCompaniesAction
  | CreateInspectionCompanyAction
  | UpdateInspectionCompanyAction
  | DeleteInspectionCompanyAction
  | SetInspectionCompaniesAction

/** REDUCER **/

export interface InspectionCompaniesType {
  items: InspectionCompany[]
  limit: number
  start: number
  total: number
}