export const radio = {
  MuiRadio: {
    styleOverrides: {
      root: {
        color: 'var(--blue700)',
        '&.Mui-checked': {
          color: 'var(--blue700)',
        }
      },
    },
  },
};
