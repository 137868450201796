import { Box, Typography } from "src/components/UI/MaterialUI";
import Icon from "../../atoms/Icon";

export default function RecallBox() {
    return (
        <Box sx={{ borderColor: '#3C2CA4!important', border: 2, padding: '8px', borderRadius: '5px', display: 'flex' }}>
            <Box sx={{ marginRight: '3px' }}><Icon name='PublishedWithChanges' color='#3C2CA4' /></Box>
            <Box><Typography color='#3C2CA4' variant='body1Bold'>Recall</Typography></Box>
        </Box>
    )
}