import { Theme } from '@mui/material';

export const input = {
  MuiOutlinedInput: {
    styleOverrides: {
      root: ({ theme }: { theme: Theme }) => ({
        border: `2px solid ${theme.palette.borderColor.main}`,
        borderRadius: '8px',
        boxShadow: '0px 1px 1px rgba(26, 42, 85, 0.24), 0px 0px 1px rgba(26, 42, 85, 0.31)',
        '&:hover': {
          borderColor: 'var(--border-hover)'
        },
        '&.Mui-disabled:hover': {
          borderColor: theme.palette.borderColor.main
        },
      }),
      notchedOutline: {
        border: 'none'
      },
      input: {
        // fontSize: 'var(--font-S)',
        height: 'inherit',
        padding: '8px 14px'
      }
    }
  },
  MuiInputBase: {
    styleOverrides: {
      inputSizeSmall: {
        padding: '9px !important',
        fontSize: 'var(--font-XXS) !important'
      },
      inputMultiline: {
        padding: '0 !important'
      }
    }
  },
  MuiFilledInput: {
    styleOverrides: {
      root: ({ theme }: { theme: Theme }) => ({
        background: 'none',
        border: 'none',
        padding: '0',
        margin: '0',
        '&:hover': {
          borderColor: 'none !important'
        },
        '&:before': {
          display: 'none',
          border: 0
        },
        '&:after': {
          display: 'none',
          border: 0
        },
        '&.Mui-error': {
          border: '2px solid var(--error-color)',
          borderRadius: '8px'
        }
      }),
      input: ({ theme }: { theme: Theme }) => ({
        border: `2px solid ${theme.palette.borderColor.main}`,
        borderRadius: '8px',
        padding: '8px 14px !important',
        background: 'white',
        boxShadow: '0px 1px 1px rgba(26, 42, 85, 0.24), 0px 0px 1px rgba(26, 42, 85, 0.31)',
        '&:hover': {
          borderColor: 'var(--border-hover)'
        },
        '&:before': {
          border: 0
        },
        '&:focus': {
          backgroundColor: 'white !important',
          borderRadius: '8px'
        }
      })
    }
  }
}

export const autocomplete = {
  MuiAutocomplete: {
    styleOverrides: {
      endAdornment: ({ theme }: { theme: Theme }) => ({
        top: 'inherit'
      }),
      input: {
        padding: '4px !important'
      },
      inputRoot: {
        padding: '5px !important'
      }
    }
  }
}
