/* import { icons } from 'src/assets'

export const titleIcon = ({ title }: { title: string }) => {
  switch (title) {
    case 'ATTIC': return <icons.House style={{ fill: "var(--bosscat-blue-600)" }} />
    // case 'CL100': return <icons.House style={{ fill: "var(--bosscat-blue-600)" }} />
    //  case 'CRAWLSPACE': return icons.crawlspaceBetter
    //  case 'ELECTRICAL': return icons.electricalBetter
    //  case 'EVALUATE': return icons.evaluateBetter
    //  case 'EXCLUDED': return icons.excludedBetter
    //  case 'EXTERIOR': return icons.exteriorBetter
    //  case 'HVAC': return icons.hvacBetter
    //  case 'INTERIOR': return icons.interiorBetter
    //  case 'MISCELLANEOUS': return icons.miscellaneousBetter
    //  case 'PLUMBING': return icons.plumbingBetter
    //  case 'ROOF': return icons.roofBetter
    //  case 'UNCATEGORIZED': return icons.uncategorizedBetter
    //  case 'WINDOWS_DOORS': return icons.windowsdoorsBetter 
    default: return <icons.Delete style={{ fill: "var(--bosscat-blue-600)" }} />
  }

}
 */
import { ITEM_PRIORITY } from 'src/helpers/constants'
import { ReactComponent as Attic } from './../../../../../assets/icons/categories/attic.svg'
import { ReactComponent as CL100 } from './../../../../../assets/icons/categories/cl100.svg'
import { ReactComponent as Crawlspace } from './../../../../../assets/icons/categories/crawlspace.svg'
import { ReactComponent as Electrical } from './../../../../../assets/icons/categories/electrical.svg'
import { ReactComponent as Evaluate } from './../../../../../assets/icons/categories/evaluate.svg'
import { ReactComponent as Excluded } from './../../../../../assets/icons/categories/excluded.svg'
import { ReactComponent as Exterior } from './../../../../../assets/icons/categories/exterior.svg'
import { ReactComponent as Hvac } from './../../../../../assets/icons/categories/hvac.svg'
import { ReactComponent as Interior } from './../../../../../assets/icons/categories/interior.svg'
import { ReactComponent as Miscellaneous } from './../../../../../assets/icons/categories/miscellaneous.svg'
import { ReactComponent as Plumbing } from './../../../../../assets/icons/categories/plumbing.svg'
import { ReactComponent as Roof } from './../../../../../assets/icons/categories/roof.svg'
import { ReactComponent as Uncategorized } from './../../../../../assets/icons/categories/uncategorized.svg'
import { ReactComponent as Windowsdoors } from './../../../../../assets/icons/categories/windowsdoors.svg'
import { ReactComponent as Dot } from './../../../../../assets/icons/dots/dot.svg'


export const CategoryIcon = ({ title, color = "#0B060F", filteredByPriority = false }: { title: string, color?: string, filteredByPriority?: boolean }) => {
  if (filteredByPriority) {
    switch (title) {
      case ITEM_PRIORITY.Urgent: return <Dot fill="#E01F1F" />
      case ITEM_PRIORITY.High:
        return <Dot fill="orange" />
      case ITEM_PRIORITY.Medium:
        return <Dot fill="#FFC633" />
      case ITEM_PRIORITY.Low:
        return <Dot fill="#1CCD83" />
      case ITEM_PRIORITY.Cosmetic:
        return <Dot fill="#3C2CA4" />
    }
  }
  switch (title) {
    case 'ATTIC': return <Attic fill={color} />
    case 'CL100': return <CL100 fill={color} />
    case 'CRAWLSPACE': return <Crawlspace fill={color} />
    case 'ELECTRICAL': return <Electrical fill={color} />
    case 'EVALUATE': return <Evaluate fill={color} />
    case 'EXCLUDED': return <Excluded fill={color} />
    case 'EXTERIOR': return <Exterior fill={color} />
    case 'HVAC': return <Hvac fill={color} />
    case 'INTERIOR': return <Interior fill={color} />
    case 'MISCELLANEOUS': return <Miscellaneous fill={color} />
    case 'PLUMBING': return <Plumbing fill={color} />
    case 'ROOF': return <Roof fill={color} />
    case 'UNCATEGORIZED': return <Uncategorized fill={color} />
    case 'WINDOWS_DOORS': return <Windowsdoors fill={color} />
    default: return <Electrical fill={color} />
  }

}
