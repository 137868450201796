/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Typography } from '@mui/material'
import { FC, useCallback, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from 'src/components/UI/CustomUI/molecules/Modal'
import { tradeTypesActions } from 'src/ducks/actions'
import { getTradeTypes } from 'src/ducks/selectors'
import { DispatchContext } from '../context'
import { dispatchTypes } from '../context/types'
import styles from './styles.module.scss'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import { TradeType } from 'src/ducks/types'

const TradesModal: FC<{ onClick: () => void }> = ({ onClick }) => {
  const dispatch = useDispatch()
  const { state: { openModal, tradesSelected }, dispatch: dispatchContext } = useContext(DispatchContext)

  const [loading, setLoading] = useState(true)
  const [trades, setTrades] = useState<string[]>(tradesSelected)
  const tradeTypes = useSelector(getTradeTypes) as TradeType[]

  const fetchTradeTypes = useCallback(() => {
    setLoading(true)
    dispatch(tradeTypesActions.fetchTradeTypes(
      (_succ: boolean) => { setLoading(false) }
    ))
  }, [dispatch])

  useEffect(() => {
    if (loading && (!tradeTypes || !tradeTypes.length)) fetchTradeTypes()
    else setLoading(false)
  }, [tradeTypes, fetchTradeTypes])

  const handleClose = () => {
    dispatchContext({
      type: dispatchTypes.SET_VALUE,
      payload: { attr: 'openModal', value: false }
    })
  }

  const handleClick = () => {
    dispatchContext({
      type: dispatchTypes.SET_VALUE,
      payload: { attr: 'tradesSelected', value: trades }
    })
    onClick()
    handleClose()
  }

  const handleTradeClick = (tradeType: TradeType) => {
    if (trades.includes(tradeType.id)) {
      setTrades(trades.filter(tradeId => tradeId !== tradeType.id))
    } else {
      setTrades([...trades, tradeType.id])
    }
  }

  return (
    <Modal
      setOpen={() => { }}
      open={openModal}
      size='xl'
      className={styles.Dispatch__container}
    >
      <>
        <Typography variant='h5' className={styles.Title}>Dispatch</Typography>
        <Typography variant='h6' className={styles.SubTitle}>Select the Trades needed for this Work Order</Typography>
        <Box className={styles.Trades}>
          {tradeTypes.map((tradeType: TradeType) => (
            <Box
              key={tradeType.id}
              onClick={() => handleTradeClick(tradeType)}
              className={`${styles.Trade} ${trades.includes(tradeType.id) && styles.Trade__Selected}`}>
              {tradeType.displayName}
              {trades.includes(tradeType.id) && <Icon color='var(--blue700)' name="CheckCircleRounded" />}
            </Box>
          ))}
        </Box>
        <Box className={styles.Actions}>
          <Button variant='outlined' onClick={handleClose} className={styles.Action}>
            Skip
          </Button>
          <Button
            variant='contained'
            onClick={handleClick}
            endIcon={<Icon name="Search" />}
            className={styles.Action}
            disabled={!trades.length}
          >
            Search
          </Button>
        </Box>
      </>
    </Modal>
  )
}

export default TradesModal