import { ITEM_CATEGORY } from 'src/helpers'
import { Email } from '~api/punchlist/employees/types'
import { EmployeeRole } from '../employee/types'
import { Address, Territory } from '../types'

/** TYPES **/
export const territoryTypes = {
  FETCH_TERRITORY_MANAGERS: 'FETCH_TERRITORY_MANAGERS',
  SET_TERRITORY_MANAGERS: 'SET_TERRITORY_MANAGERS',
  SET_TERRITORY_SUCCESS_MANAGERS: 'SET_TERRITORY_SUCCESS_MANAGERS',
  FETCH_TERRITORY_REPAIR_ITEMS: 'FETCH_TERRITORY_REPAIR_ITEMS',
  SET_TERRITORY_REPAIR_ITEMS: 'SET_TERRITORY_REPAIR_ITEMS',
  GET_TERRITORY: 'GET_TERRITORY',
  SET_TERRITORY: 'SET_TERRITORY',
  DELETE_TERRITORY: 'DELETE_TERRITORY',
  UPDATE_TERRITORY: 'UPDATE_TERRITORY'
}

/** ACTIONS **/
export interface FetchTerritoryManagerAction {
  type: typeof territoryTypes.FETCH_TERRITORY_MANAGERS
  payload: string
  callback: (succ: boolean) => void
}

export interface GetTerritoryAction {
  type: typeof territoryTypes.GET_TERRITORY
  payload: string
  callback: (succ: boolean) => void
}

export interface DeleteTerritoryAction {
  type: typeof territoryTypes.DELETE_TERRITORY
  payload: string
  callback: (succ: boolean) => void
}

export interface SetTerritoryManagers {
  type: typeof territoryTypes.SET_TERRITORY_MANAGERS
  payload: TerritoryManagerType[]
}

export interface FetchTerritoryRepairItems {
  type: typeof territoryTypes.FETCH_TERRITORY_REPAIR_ITEMS
  payload: {
    jobId?: string,
    params?: FetchTerritoryRepairItemsPayload
  }
  callback?: (succ: boolean) => void
}

export interface UpdateTerritoryAction {
  type: typeof territoryTypes.UPDATE_TERRITORY
  payload: {
    id: string,
    territory: Partial<Territory>
  }
  callback?: (succ: boolean) => void
}

export interface FetchTerritoryRepairItemsPayload {
  id?: string,
  search?: string
  start?: number
  limit?: number
}

export interface SetTerritoryRepairItems {
  type: typeof territoryTypes.SET_TERRITORY_REPAIR_ITEMS
  payload: TerritoryRepairItem
}

export interface SetTerritoryAction {
  type: typeof territoryTypes.SET_TERRITORY
  payload: Territory
}

export type TerritoryActions =
  | FetchTerritoryManagerAction
  | SetTerritoryManagers
  | FetchTerritoryRepairItems
  | SetTerritoryRepairItems
  | GetTerritoryAction
  | SetTerritoryAction
  | DeleteTerritoryAction
  | UpdateTerritoryAction


/** REDUCER **/

export interface TerritoryManagerType {
  id: string
  email: Email[]

  firstName: string
  middleName: string
  lastName: string
  phone: number | null

  address: Address
  picture: string

  registrationComplete: boolean
  type: string
  roles: EmployeeRole[]
}

export interface TerritoryRepairItem {
  id: string,
  publicTitle: string,
  margin: number,
  unitCost: number,
  unitPrice: number,
  laborRate: number,
  unitLaborHours: number,
  unitMaterialCost: number,
  title: string,
  category: ITEM_CATEGORY,
  severity: string,
  disclaimer: string
}

export interface TerritoryType {
  territoryManagers: TerritoryManagerType[]
  territorySuccessManagers: TerritoryManagerType[]
  territoryRepairItems: TerritoryRepairItem[]
  territory: Territory | null
}