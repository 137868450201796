import { FC } from 'react'
import { useClassName } from '../../../../../hooks'
import { MultilineTextProps } from './types'
import useStyles from './styles'
import { TextField } from '@mui/material'

/**
 * @param className className to apply styles `string`
 * @param error `boolean` indicating if there is an error
 * @param inputResizable `boolean` to allow resize the input
 * @param maxRows the number mof max rows to show before overflox starts `number`
 * @param onChange event to handle the changes on the input
 * @param placeholder placeholder to show if input's empty `string`
 * @param value value of the input `string`
 * @returns
 */
const MultilineText: FC<MultilineTextProps> = ({
  className = '',
  error,
  inputResizable,
  maxRows = 4,
  onChange,
  placeholder,
  value,
  disabled
}) => {
  const classNames = useClassName()
  const classes = useStyles()

  return (
    <TextField
      value={value}
      onChange={onChange}
      multiline
      maxRows={maxRows}
      fullWidth
      InputProps={{
        className: classes.input,
        classes: {
          inputMultiline: inputResizable
            ? classes.inputMultilineResizable
            : classes.inputMultiline
        }
      }}
      placeholder={placeholder}
      className={classNames(
        className,
        classes.comboUnit,
        error ? classes.inputError : ''
      )}
      disabled={disabled}
    />
  )
}

export default MultilineText
