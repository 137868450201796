import { useState } from 'react'
import { useSelector } from 'react-redux'
import Table from 'src/components/UI/CustomUI/organisms/Table'
import { getEstimate } from 'src/ducks/selectors'
import headers from './headers'

const All = () => {
  const [loading] = useState(false)
  const estimate = useSelector(getEstimate())

  return (
    <Table
      headers={headers}
      title='ALL'
      loading={loading}
    >
      {estimate?.fileDetails ?? []}
    </Table>
  )
}

export default All
