import { Box, Grid } from '@mui/material'
import { FC } from 'react'
import styles from './styles.module.scss'
import { icons } from 'src/assets'
import { formatTimestamp, formatTimestampDate } from 'src/helpers'

export interface ApprovalFlowTrackerStepperProps {
  steps: {
    label: string
    completed: boolean
    completedOn?: number
  }[]
  sx?: any
}

const IconC = ({ completed }: { completed: boolean }) => {
  return (
    <div
      className={styles.ApprovalFlowTracker__icon}
      style={{
        backgroundColor: completed ? 'var(--green-700)' : '#cccdd6',
      }}
    >
      <icons.CheckCircle
        style={completed ? {} : { background: 'white', width: 8, height: 8 }}
      />
    </div>
  )
}

const ApprovalFlowTrackerStepper: FC<ApprovalFlowTrackerStepperProps> = ({
  steps,
  sx,
}) => {
  return (
    <Grid className={styles.ApprovalFlowTracker__stepper} sx={sx}>
      {steps.map((step, index) => {
        return (
          <Grid flex={1} position="relative">
            {index !== 0 && (
              <Box
                className={`${styles.ApprovalFlowTracker__connector} ${
                  step.completed
                    ? styles.ApprovalFlowTracker__connector__completed
                    : ''
                }`}
              ></Box>
            )}

            <Grid className={styles.ApprovalFlowTracker__step}>
              <IconC completed={step.completed} />
              <Grid container direction="column" alignContent="center" alignItems="center" gap=".3rem">
                <span className={styles.ApprovalFlowTracker__label} key={index}>
                  {step.label}
                </span>
                <span
                  className={styles.ApprovalFlowTracker__completedOn}
                  key={index}
                >
                  {formatTimestamp(
                    step.completedOn || null,
                    'MMM D, YYYY h:mm A',
                    'America/New_York'
                  )}
                </span>
              </Grid>
            </Grid>
          </Grid>
        )
      })}
    </Grid>
  )
}

export default ApprovalFlowTrackerStepper
