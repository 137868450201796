import { useContext } from 'react'
import Modal from 'src/components/UI/CustomUI/molecules/Modal'
import { CompaniesContext } from '../context'
import { MODAL_TYPE, dispatchTypes } from '../context/types'
import AddCompany from './AddCompay'
import ConfirmDeleteCompany from './ConfirmDeleteCompany'
import EditCompany from './EditCompany'
const CompaniesModal = () => {
    const { state: { modalType, modalOpen }, dispatch: dispatchContext } = useContext(CompaniesContext)
    const handleClose = () => {
        dispatchContext({ type: dispatchTypes.CLOSE_MODAL, payload: null })
    }

    const getTitle = () => {
        switch (modalType) {
            case MODAL_TYPE.CREATE:
                return "Add New Company"
            case MODAL_TYPE.EDIT:
                return "Edit Company"
            case MODAL_TYPE.DELETE_COMPANY:
                return "You are going to delete the Company:"
            default:
                return 'Company'
        }
    }

    const getContent = () => {
        switch (modalType) {
            case MODAL_TYPE.CREATE:
                return <AddCompany />
            case MODAL_TYPE.EDIT:
                return <EditCompany />
            case MODAL_TYPE.DELETE_COMPANY:
                return <ConfirmDeleteCompany />
            default:
                return 'Company'
        }
    }

    const getSize = () => {
        switch (modalType) {
            case MODAL_TYPE.CREATE:
                return 'md'
            case MODAL_TYPE.EDIT:
                return 'md'
            case MODAL_TYPE.DELETE_COMPANY:
                return 'sm'
            default:
                return 'md'
        }
    }

    return (
        <Modal
            open={modalOpen}
            setOpen={() => { }}
            onClose={handleClose}
            showClose={true}
            size={getSize()}
            title={getTitle()}
        >
            {getContent()}
        </Modal>
    )
}

export default CompaniesModal