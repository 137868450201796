import { StyleSheet } from '@react-pdf/renderer'

export default StyleSheet.create({
  body: {
    position: 'relative',
    padding: '48px',
  },
  page1: {},
  pageBg: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  imgBg: {
    display: 'flex',
    width: '100vw',
    height: '100vh',
  },

  qrCode: {
    width: '80px',
    borderRadius: '8px',
  },


  qrCodeWOBorder: {
    width: '80px',
    padding: '4px',
    marginTop: '14px'
  /*   display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    marginTop: '4px' */
  },

  page1Content: {
    padding: '56px 48px 48px',
    minHeight: '70vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },

  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },

  gap1: {
    gap: '4px',
  },

  gap2: {
    gap: '8px',
  },

  gap3: {
    gap: '12px',
  },

  gap4: {
    gap: '16px',
  },

  flexEnd: {
    alignItems: 'flex-end',
  },
  flexStart: {
    alignItems: 'flex-start',
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },

  contactBanner: {
    backgroundColor: '#2F7BF7',
    width: '100%',
    position: 'absolute',
    bottom: '0'
  },

  backgroundBlue900: {
    backgroundColor: '#091931',
  },

  backgroundBlue800: {
    backgroundColor: '#1d2952',
  },

  backgroundBlue600: {
    backgroundColor: '#2F7BF7',
  },
  blue600: {
    color: '#2F7BF7',
  },
  black600: {
    color: '#0B060F',
  },

  blue700: {
    color: '#1A2A55',
  },
  blue800: {
    color: '#1d2952',
  },
  blue900: {
    color: '#091931',
  },

  alignCenter: {
    alignContent: 'center',
    alignItems: 'center',
  },

  justifyCenter: {
    display: 'flex',
    justifyContent: 'center',
  },

  page1Top: {
    position: 'relative',
    marginBottom: '70px'
  },

  textCenter: {
    textAlign: 'center',
  },
  logoView: {
    /*  position: 'absolute',
    top: 12,
    right: 12, */
  },

  page2: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },

  imgBgPage2: {
    height: '100vh',
    width: '55vw',
    objectFit: 'cover',
    objectPosition: 'right bottom',
  },

  page2Right: {
    // backgroundColor: '#091931',
    width: '100%',
  },

  page2Header: {
    backgroundColor: '#091931',
    padding: '48px 24px 24px',
  },

  /*   pageBg2: {
    position: 'absolute',
    top: 0,
    left: '-60px',
  }, */

  estimateFlag: {
    backgroundColor: '#2A7DE1',
    padding: '8px',
    borderRadius: '50px',
    fontSize: '14px',
  },

  logo: {
    // display: 'flex',
    width: '430px',
    marginLeft: '-8px',
  },
  logoSmall: {
    width: '60px',
    marginBottom: '16px',
  },
  fontXXSmall: {
    fontSize: '8px',
  },
  fontXSmall: {
    fontSize: '10px',
  },
  fontSmall: {
    fontSize: '12px',
  },
  fontSmaller: {
    fontSize: '14px',
  },
  fontMedium: {
    fontSize: '16px',
  },
  font18:{
    fontSize: '18px'
  },
  font20:{
    fontSize: '20px'
  },
  fontLarge: {
    fontSize: '24px',
  },
  font25: {
    fontSize: '25px',
  },
  fontLarger: {
    fontSize: '28px',
  },
  fontXLarger: {
    fontSize: '30px',
  },
  fontXLarge: {
    fontSize: '32px',
  },
  fontXXXLarge: {
    fontSize: '50px',
  },
  qrContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  w40: {
    width: '40%',
  },
  w50: {
    width: '50%',
  },
  w60: {
    width: '60%',
  },
  w70: {
    width: '70%',
  },
  w80: {
    width: '80%',
  },
  w90: {
    width: '90%',
  },
  w100: {
    width: '100%',
  },
  mb1: {
    marginBottom: '4px',
  },
  mb2: {
    marginBottom: '8px',
  },
  mb3: {
    marginBottom: '12px',
  },
  mb4: {
    marginBottom: '16px',
  },
  mb5: {
    marginBottom: '20px',
  },
  mb6: {
    marginBottom: '24px',
  },
  mt1: {
    marginTop: '4px',
  },
  mt2: {
    marginTop: '8px',
  },
  mt3: {
    marginTop: '12px',
  },
  mt4: {
    marginTop: '16px',
  },
  mt5: {
    marginTop: '20px',
  },
  mh1: {
    margin: '4px 0',
  },
  mh2: {
    margin: '8px 0',
  },
  mh3: {
    margin: '12px 0',
  },
  mh4: {
    margin: '16px 0',
  },
  mh5: {
    margin: '20px 0',
  },
  mv1: {
    margin: '0 4px',
  },
  mr1: {
    marginRight: '4px',
  },
  mr2: {
    marginRight: '8px',
  },
  mr3: {
    marginRight: '12px',
  },
  mr4: {
    marginRight: '16px',
  },
  ml1: {
    marginLeft: '4px',
  },

  ml2: {
    marginLeft: '8px',
  },
  estimateTitle: {
    fontFamily: 'LatoBold',
    fontSize: '40px',
  },

  nextArtBold: {
    // fontFamily: 'NextArtBold',
  },
  nextArtSemiBold: {
    // fontFamily: 'NextArtSemiBold',
  },
  nextArtNormal: {
    // fontFamily: 'NextArtNormal',
  },
  latoBold: {
    fontFamily: 'LatoBold',
  },
  latoNormal: {
    fontFamily: 'LatoNormal',
  },

  whiteFont: {
    color: 'white',
  },

  white: {
    color: 'white',
  },

  bodyItem: {
    padding: '8px 4px',
  },

  p3: {
    padding: '12px',
  },
  p4: {
    padding: '16px',
  },
  p5: {
    padding: '20px',
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
  },

  containerHorizontal: {
    display: 'flex',
    flexDirection: 'row',
  },

  block: {
    paddingTop: '32px',
  },
  topSeparation: {
    paddingTop: '16px',
  },
  leftSeparation: {
    padding: '0px 8px',
  },
  verticalSeparator: {
    width: '1px',
    height: '100%',
    backgroundColor: '#1A2A55',
    margin: '0px px',
  },

  verticalSeparatorWhite: {
    width: '1px',
    height: '100%',
    backgroundColor: '#FFF',
    margin: '0px 16px',
  },
  sectionTitle: {
    display: 'flex',
    alignItems: 'space-between',
    padding: '4px',
    width: '100%',
    backgroundColor: '#F9F9F9',
  },
  separator: {
    width: '100%',
    height: '1px',
    // backgroundColor: '#1A2A55',
    opacity: '.2',
  },
  littleSpacing: {
    margin: '8px 0px',
  },
  bigSpacing: {
    margin: '48px 0px 4px',
  },
  textRight: {
    display: 'flex',
    width: '100%',
    textAlign: 'right',
  },
  subtitle: {
    fontFamily: 'LatoNormal',
    fontWeight: 500,
    fontSize: '12px',
    color: '#1A2A55',
  },
  title: {
    fontFamily: 'LatoNormal',
    fontWeight: 500,
    fontSize: '18px',
    color: '#1A2A55',
  },
  titleBold: {
    fontFamily: 'LatoNormal',
    fontWeight: 500,
    fontSize: '18px',
    color: '#1A2A55',
  },

  content: {
    position: 'relative',
    padding: '4px 0px',
  },
  itemText: {
    fontFamily: 'LatoNormal',
    flexWrap: 'wrap',
    fontSize: '12px',
  },

  grayColor: {
    color: '#8F9091',
  },

  green700: {
    color: '#847848',
  },

  userInfoText: {
    fontFamily: 'LatoNormal',
    fontSize: '8px',
    color: '#494A5A',
  },
  textBold: {
    fontFamily: 'LatoBold',
  },
  printDate: {
    position: 'absolute',
    fontSize: 12,
    bottom: 15,
    left: 30,
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 15,
    right: 30,
    color: 'grey',
  },
  itemImages: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    // justifyContent: 'space-evenly',
    margin: '4px 0px',
  },
  image: {
    display: 'flex',
    height: '72px',
    borderRadius: '8px',
    marginRight: '16px',
  },

  userInfoImage: {
    display: 'flex',
    height: '32px',
    marginRight: '4px',
  },
  chartImage: {
    display: 'flex',
    width: '100%',
  },
  userInfoLabelImage: {
    display: 'flex',
    width: '96px',
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  alignBetween: {
    alignContent: 'space-between',
  },
  justifyBetween: {
    justifyContent: 'space-between',
  },
  removed: {
    backgroundColor: '#FFF4F4',
    color: 'red',
  },
  qrVideoContainer: {
    background: '#fff',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '24px 8px',
    paddingLeft: '24px',
  },

  helpBanner: {
    backgroundColor: '#2F7BF7',
    width: '100%',
    padding: '8px 32px',
    paddingLeft: '84px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    position: 'relative',
  },
  bullet: {
    backgroundColor: '#2F7BF7',
    borderRadius: '32px',
    padding: '4px 8px',
    color: 'white',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    justifyItems: 'center',
    marginRight: '4px',
    width: '31px',
    height: '31px'
  },
  listItemBullet: {
    alignContent: 'center',
    alignItems: 'center',
    margin: '18px 0px',
  },
  page3: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    // height: '100%',
  },
  page3Header: {
    padding: '24px 8px',
    // height: '115px',
  },
  bodyItems: {
    padding: '12px 12px 4px',
    backgroundColor: '#FFF',
    margin: '0 24px',
    // maxHeight: '70%',
    // height: '100%',
    marginBottom: '150px',
  },
  item: {
    margin: '0px 4px',
  },
  priceMatchFooter: {
    backgroundColor: '#2A7DE1',
    position: 'absolute',
    bottom: '0px',
    width: '100%',
    padding: '16px 32px',
    height: '140px',
  },
  priceMatchImage: {
    width: '95px',
    height: '95px',
  },
  loanDepotLogo: {
    width: '150px',
  },
  page4: {
    minHeight: '100vh',
  },
  evaluateBodyItems: {
    backgroundColor: '#EAF2FE',
    padding: '8px',
    borderRadius: '8px',
  },
  loanDepotQRContainer: {
    backgroundColor: '#FFF',
    width: '80px',
    padding: '8px',
    borderRadius: '8px',
    position: 'absolute',
    bottom: '-4px',
    right: '0',
  },
  loanDepot: {
    backgroundColor: '#FFF',
    width: '80px',
    padding: '8px',
    borderRadius: '8px',
  },
  evaluateItem: {
    margin: '4px 0',
    borderRadius: '4px',
  },
  bosscatWarranty: {
    width: '90px',
  },
  bosscatWarrantyFooter: {
    backgroundColor: '#2A7DE1',
    position: 'absolute',
    bottom: '0px',
    width: '100%',
    padding: '12px 48px',
  },
  page5: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '32px 0',
  },
  page5Footer: {
    backgroundColor: '#2A7DE1',
    position: 'absolute',
    bottom: '0px',
    width: '100%',
    padding: '32px 32px 0px',
  },
  bosscatLogo: {
    height: '20px',
  },
  appImageFooter: {
    // position: 'absolute',
    // bottom: '-60px',
    // right: '30px',
  },
  latoItalicBold: {
    fontFamily: 'LatoItalicBold',
  },
  page6Footer: {
    position: 'absolute',
    bottom: '0',
    width: '100%',
  },
  page6Content: {
    padding: '0px 32px',
  },
  page6ContentAgent: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    height: '300px',
  },
  page6Header: {
    position: 'relative',
    // marginBottom: '60px',
  },

  page6HeaderText: {
    padding: '16px 32px',
    width: '90%',
    position: 'absolute',
    bottom: '-50px',
  },
  partnersQr: {

  },
  partnerLogo: {
    width: '100px',
  },
  qrCodeBorder: {
    padding: '4px',
    borderRadius: '8px',
    border: '2px solid black',
  },
  priceContainer: {
    /* display: 'flex',
    flexDirection: 'column',
    alignContent: 'flex-start',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    justifyItems: 'flex-start',
    backgroundColor: 'blue',
    position: 'relative', */
    position: 'relative',
  },
  total: {
    fontFamily: 'LatoBold',
    fontSize: '16px',
  },
  decimals: {
    fontSize: '8px',
    fontFamily: 'LatoBold',
    fontWeight: 600,
    position: 'absolute',
    top: 20,
    height: '8px',
  },
})
