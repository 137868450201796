import { authTypes } from '../auth/types'
import { RepairListItem } from '../types'
import { RepairListActions, repairlistTypes, RepairListType } from './types'

const initialState: RepairListType = {
  items: [],
  csvItems: [],
  start: 0,
  limit: 50,
  total: 0,
}
const reducer = (
  state = initialState,
  action: RepairListActions
): RepairListType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: {
      return initialState
    }
    case authTypes.LOGOUT_REQUEST: {
      return initialState
    }

    case repairlistTypes.SET_REPAIRLIST: {
      return {
        ...state,
        ...payload
      } as RepairListType
    }

    case repairlistTypes.SET_CSV_REPAIRLIST: {
      return {
        ...state,
        csvItems: payload as RepairListItem[],
      }
    }

    default:
      return state
  }
}

export default reducer
