import { DownloadCSVJobAction, GetCSVJobAction } from './types';
/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, delay, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo } from '../../helpers'
import { Punchlist } from '../../api'
import { jobsTypes, FetchJobsAction } from './types'
import { jobsActions } from '../actions'

export function* fetchJobs({ payload, callback }: FetchJobsAction): SagaIterator {
  let success = false
  try {
    const page = payload.page ?? 0
    const size = payload.size ?? 50

    const start = page * size
    //const limit = start + size
    const limit = size;

    const searchParams = payload.searchParams ?? ''
    const jobs = yield call(Punchlist.jobs.getJobs, { start, limit, searchParams })

    if (jobs) {
      yield put(jobsActions.setJobs(jobs))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the jobs'))
    if (callback) yield call(callback, false)
  }
}

export function* fetchJobsCounts({ payload, callback }: FetchJobsAction): SagaIterator {
  let success = false
  try {
    const searchParams = payload.searchParams ?? ''
    const counts = yield call(Punchlist.jobs.getJobsCounts, { searchParams })

    if (counts) {
      yield put(jobsActions.setJobsCounts(counts))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the jobs counts'))
    if (callback) yield call(callback, false)
  }
}

export function* downloadCSVJob({ payload, callback }: DownloadCSVJobAction): SagaIterator {
  const { searchParams } = payload
  try {
    const csvId = yield call(Punchlist.jobs.requestCSV, searchParams)
    if (csvId) {
      yield put(jobsActions.getCSV(csvId, callback))
    } else {
      yield call(toast.error, errorTextTryingTo('download csv jobs file'))
      yield call(callback, false)
    }
  } catch {
    yield call(toast.error, errorTextTryingTo('download csv jobs file'))
    yield call(callback, false)
  }
}

export function* getCSV({ payload, callback }: GetCSVJobAction): SagaIterator {
  try {
    const jobs = yield call(Punchlist.jobs.getCSV, payload)
    if (jobs.status === 200) {
      yield put(jobsActions.setCSVJobs(jobs.data))
      yield call(callback, true)
    } else {
      yield delay(1500);
      yield put(jobsActions.getCSV(payload, callback))
    }
  } catch {
    yield call(toast.error, errorTextTryingTo('download csv jobs file'))
    yield call(callback, false)
  }
}
export default function* saga(): SagaIterator {
  yield takeLatest(jobsTypes.FETCH_JOBS, fetchJobs)
  yield takeLatest(jobsTypes.FETCH_JOBS_COUNTS, fetchJobsCounts)
  yield takeLatest(jobsTypes.DOWNLOAD_CSV, downloadCSVJob)
  yield takeLatest(jobsTypes.GET_CSV, getCSV)
}
