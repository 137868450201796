import { InsertLink } from '@mui/icons-material'
import { Box, Grid, Typography } from '@mui/material'
import Table from 'src/components/UI/CustomUI/organisms/Table'

import headers from './headers'
import { useSelector } from 'react-redux'
import { getWorkOrderContractMilestones } from 'src/ducks/selectors'

const Milestones = () => {
  const contractMilestones = useSelector(getWorkOrderContractMilestones)
  const mockMileStones = contractMilestones?.milestones

  return (
    <Grid container item spacing={1} direction="column" style={{ overflow: 'overlay', flexWrap: 'nowrap' }} height='170px'>
      <Grid item>
        <Box display="flex" gap="4px" alignItems="center" color="#3A66FF">
          <Typography>📑 Document Links</Typography>
          <InsertLink style={{ width: '14px', height: '14px' }} />
        </Box>
      </Grid>
      <Grid item style={{paddingTop: "4px"}}>
        <Table
          headers={headers}
          defaultOrder="desc"
          // loading={false}
          hasPagination={false}
        >
          {mockMileStones ?? []}
        </Table>
      </Grid>
    </Grid>
  )
}

export default Milestones
