import { Box, Button, Checkbox, Typography } from '@mui/material'
import { Link, TextField } from 'src/components/UI'
import {
  HeaderType,
  AlignType,
} from 'src/components/UI/CustomUI/organisms/Table/types'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import SettingsIcon from '@mui/icons-material/Settings'
import DeleteIcon from '@mui/icons-material/Delete'
import CircleIconButton from 'src/components/UI/CustomUI/molecules/CircleIconButton'
import moment from 'moment'

import styles from './styles.module.scss'

const headers: HeaderType[] = [
  {
    id: 'address',
    label: 'Address',
    custom: true,
    align: 'left' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Box padding="2px 10px">
          <Typography className={styles.Companies__companyName}>
            {row?.address}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'status',
    label: 'Status',
    custom: true,
    align: 'left' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Box padding="2px 10px">
          <Typography className={styles.Companies__companyName}>
            {row?.status}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'price',
    label: 'Price',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Box padding="2px 10px">
          <Typography className={styles.Companies__companyName}>
            {row?.price}
          </Typography>
        </Box>
      )
    },
  },
]

export default headers
