import { HeaderType } from 'src/components/UI/CustomUI/organisms/Table/types'

const headers: HeaderType[] = [
    { id: 'id', label: '#' },
    { id: 'date_sent', label: 'Date Sent' },
    { id: 'address', label: 'Date Paid' },
    { id: 'amount', label: 'Amount' },
    { id: 'status', label: 'Status' },
    { id: 'notes', label: 'Notes' },
    { id: 'payment', label: 'Billing Type' },
]

export default headers
