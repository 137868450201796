/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useCallback, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { PrivateLoader } from 'src/components/templates'
import BackButton from 'src/components/UI/CustomUI/atoms/BackButton'
import TabNavigation from 'src/components/UI/CustomUI/organisms/TabNavigation'
import { subscriptionActions } from 'src/ducks/actions'
import { getSubscription } from 'src/ducks/selectors'
import { ActionsButton, Box, Grid } from '../../../UI'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import { Details, Files, Gift, Services } from './Tabs'
import ContactInfo from './ContactInfo'
import { SubscriptionContext } from './context'
import { UpdateRequest } from '~api/types'
import { getDifferenceBetweenObjects, isEmpty } from 'src/helpers'

const SubscriptionView: FC = () => {
  const dispatch = useDispatch()
  const { id } = useParams()

  const subscription = useSelector(getSubscription())
  const [hasChanges, setHasChanges] = useState(false)

  const { editedSub, setEditedSub, loading } = useContext(SubscriptionContext)

  const [pageLoading, setPageLoading] = useState(true)

  const fetchSubscription = useCallback(() => {
    if (id) {
      dispatch(
        subscriptionActions.fetchSubscription(id, (_succ: boolean) => {
          setPageLoading(false)
        })
      )
    }
  }, [])

  useEffect(() => {
    fetchSubscription()
  }, [])

  useEffect(() => {
    setEditedSub(subscription)
  }, [subscription])

  useEffect(() => {
    const hasDiff: UpdateRequest = getDifferenceBetweenObjects(
      editedSub,
      subscription,
      'replace'
    )
    setHasChanges(!isEmpty(hasDiff))
  }, [editedSub])

  const handleSave = () => {
    setPageLoading(true)
    const subscriptionDifferences: UpdateRequest = getDifferenceBetweenObjects(
      editedSub,
      subscription,
      'add'
    )
    dispatch(
      subscriptionActions.updateSubscriptionByPath(subscriptionDifferences, (succ: boolean) => {
        setPageLoading(false)
      })
    )
  }

  const TABS = [
    {
      label: 'Details',
      content: <Details />,
    },
    {
      label: 'Gift',
      content: <Gift />,
    },
    {
      label: 'Services',
      content: <Services />,
    },
    {
      label: 'Files',
      content: <Files />,
    },
  ]

  return (
    <PrivateLoader loading={pageLoading}>
      <Box display="flex" flexDirection="column" gap={2}>
        <Box>
          <BackButton />
        </Box>
        <Grid container spacing={2} marginBottom={3}>
          <Grid item xs={3}>
            <ContactInfo userData={subscription} />
          </Grid>
          <Grid item xs={9}>
            <TabNavigation tabs={TABS} />
          </Grid>
        </Grid>
        <ActionsButton
          variant="contained"
          disabled={!hasChanges}
          sx={{
            position: 'fixed',
            height: '40px !important',
            width: '180px !important',
            bottom: '40px',
            right: '40px',
          }}
          onClick={handleSave}
          loading={loading}
          icon={<Icon name="Save" />}
          iconPosition="end"
          text="Save changes"
        />
      </Box>
    </PrivateLoader>
  )
}

export default SubscriptionView
