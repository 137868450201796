import { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import { Punchlist } from '../../api'
import { FetchCsvLicensesAction, FetchLicensesAction, licensesTypes } from './types'
import { licensesAction } from '../actions'
import { toast } from 'react-toastify'
import { errorTextTryingTo } from 'src/helpers'

export function *fetchLicenses({
  payload,
  callback,
}: FetchLicensesAction): SagaIterator {
  let success = true

  try {
    const page = payload.page ?? 0
    const size = payload.size ?? 50

    const start = page * size
    //const limit = start + size
    const limit = size

    const searchParams = payload.searchParams ?? ''
    const licenses = yield call(Punchlist.licenses.getLicenses, {
      start,
      limit,
      searchParams,
    })

    if (licenses) {
      yield put(licensesAction.setLicenses(licenses))
    }

    success = true

    if (callback) yield call(callback, success)
  } catch (err) {
    yield call(toast.error, errorTextTryingTo('get the licenses'))

    if (callback) yield call(callback, false)
  }
}

export function *fetchCsvLicenses({
  payload,
  callback,
}: FetchCsvLicensesAction): SagaIterator {
  let success = true

  try {
    const page = 0
    const size = payload.count ?? 0

    const start = page * size
    //const limit = start + size
    const limit = size

    const searchParams = payload.searchParams ?? ''
    const licenses = yield call(Punchlist.licenses.getLicenses, {
      start,
      limit,
      searchParams,
    })

    if (licenses) {
      yield put(licensesAction.setCsvLicenses(licenses))
    }

    success = true

    if (callback) yield call(callback, success)
  } catch (err) {
    yield call(toast.error, errorTextTryingTo('get the licenses'))

    if (callback) yield call(callback, false)
  }
}


export default function *saga(): SagaIterator {
  yield takeLatest(licensesTypes.FETCH_LICENSES, fetchLicenses)
  yield takeLatest(licensesTypes.FETCH_CSV_LICENSES, fetchCsvLicenses)
}
