import { Grid } from '@mui/material'
import { FC } from 'react'
import { StatusBoxesProps } from './types'
import useStyles from './styles'
import { useIsXlScreen, useIsLgScreen } from 'src/hooks'
import { StatusBoxProps } from '../StatusBox/types'
import LayerStatusBoxes from '../LayerStatusBoxes'

const StatusBoxes: FC<StatusBoxesProps> = ({ data, sx, loading }) => {
  const classes = useStyles();
  const layers = [0, 1];
  const [xlScreen] = useIsXlScreen()
  const [lgScreen] = useIsLgScreen()

  const getLayer = (item: StatusBoxProps): number => {
    const itemLayer = item.layer ?? 0;
    return (!xlScreen && !lgScreen) ? itemLayer : 0;
  }

  return (
    <Grid spacing={xlScreen ? 2 : 1} flexWrap='nowrap' className={classes.container}>
      {layers.map((layer, index) => (
        <LayerStatusBoxes 
          key={index * Math.floor(Math.random() * 10000)}
          data={data.filter((item) => getLayer(item) === layer)}
          sx={[
            ...(Array.isArray(sx) ? sx : [sx]),
          ]}
          loading={loading}
        />
      ))}
    </Grid>
  )
}

export default StatusBoxes
