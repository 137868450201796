/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { PrivateLoader } from 'src/components/templates'
import { siteVisitsActions, territoriesActions } from 'src/ducks/actions'
import { getTotalVisits, getSiteVisits, getTerritoriesMultipleField } from 'src/ducks/selectors'
import Table from 'src/components/UI/CustomUI/organisms/Table'
import { useIsMdScreen } from '../../../../hooks'
import headers from './headers'
import { ORDER_STATUS, getOptionsFromArray, enumToArrayObject, ROWS_PER_PAGE_EXTENDED } from 'src/helpers'
import { Box, FieldsFilter, SelectInput, Grid } from 'src/components/UI'
import { GetSiteVisitsParams } from 'src/ducks/siteVisits/types'
import { useNavigate } from 'react-router-dom'

type StatusType = {
  key: string;
  label: string;
}

function useStateCallback<T>(initialState: T, cb: (state: T) => void): [state: T, setState: (state: T) => void] {
  const [state, setState] = useState(initialState);
  useEffect(() => cb(state), [state]);
  return [state, setState];
};

const SiteVisits: FC = () => {
  const dispatch = useDispatch()
  const visits = useSelector(getSiteVisits())
  const territoriesOtions = useSelector(getTerritoriesMultipleField)

  const enumArray = enumToArrayObject(ORDER_STATUS)
  const statusOtions = getOptionsFromArray(enumArray, { key: 'key', label: 'label' }, { key: 'all', label: 'All Status' })

  const [tableLoading, setTableLoading] = useState(false)
  const [pageLoading, setPageLoading] = useState(true)
  const [tableHeaders] = useState(headers)
  const [isSelectingAnAffiliate, setIsSelectingAnAffiliate] = useState(false);
  const [mdScreen] = useIsMdScreen()
  const total = useSelector(getTotalVisits)
  const navigate = useNavigate()

  const onChangeStatus = () => fetchSiteVisits()
  const onChangeSelectAffiliates = () => {
    if (isSelectingAnAffiliate) {
      fetchSiteVisits()
    }
  }

  const [selectedAffiliates, setSelectedAffiliates] = useStateCallback(territoriesOtions, onChangeSelectAffiliates)
  const [status, setStatus] = useStateCallback<StatusType>(statusOtions[0], onChangeStatus)

  const fetchSiteVisits = (page?: number, size?: number) => {
    setTableLoading(true)
    const filter = {} as Partial<GetSiteVisitsParams>
    if (status.key !== 'all') {
      filter.status = status.key
    }

    const resAffiliates = selectedAffiliates.filter(aff => !aff.hide).reduce((acc: any, curr: any) => acc.concat(curr.key), [])
    const queryAffiliates = resAffiliates.join(',')
    if (resAffiliates.length !== 0 && !resAffiliates.includes('all')) {
      filter.territory = queryAffiliates
    }

    dispatch(territoriesActions.fetchTerritories((_succ: boolean) => { }))
    dispatch(
      siteVisitsActions.getSiteVisits(filter, page, size, (_succ: boolean) => {
        setPageLoading(false)
        setTableLoading(false)
      })
    )
  }

  useEffect(() => {
    fetchSiteVisits()
  }, [])

  useEffect(() => {
    if (territoriesOtions?.length > 0 && !isSelectingAnAffiliate) {
      setSelectedAffiliates(territoriesOtions)
    }
  }, [territoriesOtions, isSelectingAnAffiliate])

  const handleClickRow = (row: any, e: React.MouseEvent) => {
    if (e.type !== 'contextmenu') {
      if (!row.preapproval) {
        navigate(`/jobs/${row.estimateId}?from=sitevisits`)
        e.preventDefault()
      }
    }

  }

  const handleStatusChange = (newSelected: any) => {
    setStatus(newSelected)
  }

  const handleAffiliatesChange = (index: number) => {
    setIsSelectingAnAffiliate(true);
    setSelectedAffiliates(selectedAffiliates.map((aff, i) => {
      if (index === 0) {
        aff.hide = i !== 0
      } else {
        if (i === 0)
          aff.hide = true
        if (i === index)
          aff.hide = !aff.hide
      }
      return aff
    }))
  }

  return (
    <PrivateLoader loading={pageLoading}>
      <Box display='flex' flexDirection='column' gap={mdScreen ? 1 : 2}>
        <Box display='flex' justifyContent='flex-end' gap={mdScreen ? 1 : 2}>
          <FieldsFilter
            flexColumn
            handleChange={handleAffiliatesChange}
            inputText='All Territories:'
            options={selectedAffiliates}
            renderAsInput
            label='Territory:'
            variant='body1'
            sx={{ minWidth: '324px !important', flex: '1' }}
          />
          <SelectInput
            label='Status:'
            placeholder='All Status'
            onChange={handleStatusChange}
            options={statusOtions}
            value={status}
            sx={{ minWidth: '199px' }}
          />
        </Box>
        <Grid item xs={12}>
          <Table
            headers={tableHeaders}
            defaultOrder='desc'
            rowAction={handleClickRow}
            onRightClickRow={handleClickRow}
            totalItems={total}
            callToApi={(start, limit) => { fetchSiteVisits(start, limit) }}
            loading={tableLoading}
            rowsPerPageOptions={ROWS_PER_PAGE_EXTENDED}
          >
            {visits ?? []}
          </Table>
        </Grid>
      </Box>
    </PrivateLoader>
  )
}

export default SiteVisits
