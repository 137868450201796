import { Theme } from '../../../../UI'
import { makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => ({
  error: {
    borderColor: `${theme.palette.error.main} !important`,
  },
  textArea: {
    padding: '10px',
    minHeight: '100px',
    minWidth: '100%',
    maxWidth: '100%',
    '&:hover': {
      borderColor: `var(--border-hover) !important`,
    },
    '&:focus': {
      borderColor: `var(--border-color) !important`,
    },
    '&:focus-visible': {
      borderColor: `var(--border-color) !important`,
    },
    border: `2px solid var(--border-color)`,
    borderRadius: '8px',
  }
}))
