export const accordion = {
  MuiAccordion: {
    styleOverrides: {
      root: {
        border: 'none',
        borderBottom: 'none',
        display: 'flex',
        flexDirection: 'column',
        '&:before': {
          display: 'none'
        },
        '&.Mui-expanded': {
          margin: 0
        }
      },
    }
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        padding: '0 !important',
        minHeight: 'fit-content !important',
        margin: '0',
        justifyContent: 'flex-start !important',
      },
      content: {
        margin: '0 !important',
        flexGrow: 0,
      },
    }
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: {
        padding: 0,
        //paddingLeft: '2rem',
      }
    }
  }
}