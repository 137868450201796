import { authTypes } from '../auth/types'
import { LicensesType } from './types'
import { LicensesActions, licensesTypes } from './types'

const initialState: LicensesType = {
  start: 0,
  limit: 50,
  total: 0,
  items: [],
  csvItems: [],
}

const reducer = (
  state = initialState,
  action: LicensesActions
): LicensesType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: {
      return initialState
    }
    case authTypes.LOGOUT_REQUEST: {
      return initialState
    }

    case licensesTypes.SET_LICENSES: {
      return {
        ...state,
        start: (payload as LicensesType).start,
        limit: (payload as LicensesType).limit,
        items: (payload as LicensesType).items,
        total: (payload as LicensesType).total,
      }
    }

    case licensesTypes.SET_CSV_LICENSES: {
      return {
        ...state,
        csvItems: (payload as LicensesType).items
      }
    }

    default:
      return state
  }
}

export default reducer
