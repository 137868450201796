import { ScrollWrapper } from 'src/components/templates'
import { Box } from 'src/components/UI'

const TabBox = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  return (
    <ScrollWrapper relativeStyle={{ height: '175px' }}>
      <Box display='grid' gap='6px'>
        {children}
      </Box>
    </ScrollWrapper>
  )
}

export default TabBox
