import { Box, Typography } from "@mui/material"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"
import Icon from "src/components/UI/CustomUI/atoms/Icon"
import { getItems, getItemsSortedByCategory, getJob, getOrderById } from "src/ducks/selectors"
import { renderWorkOrdersTabs } from "src/helpers"
import Comments from "../Comments"

import styles from './styles.module.scss'

const CommentsModal = () => {
  const [searchParams] = useSearchParams()
  const items = useSelector(getItemsSortedByCategory())

  const orderId = searchParams?.get('order') ?? ''
  const order = useSelector(getOrderById(orderId))

  const job = useSelector(getJob())

  const { title, color, icon } = renderWorkOrdersTabs(order, items)

  const modalTitle = order?.orderId ? title : job?.properties.fullAddress

  return (
    <>
      <Box className={styles.Comments}>
        <Box display="flex">
          <Typography marginRight=".4rem" variant="h6Bold">{order.orderId ? 'Work Order:' : 'Job:'} </Typography>
          <Typography variant="h6" display="flex" color={color}> {modalTitle}
            {order.orderId && icon &&
              <Icon sx={{ marginLeft: '.2rem' }} width='.8rem' name={icon} color={color} />}
          </Typography>
        </Box>
        <Comments />
      </Box>
    </>
  )
}
export default CommentsModal