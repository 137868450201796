
/** TYPES **/
export const commentsTypes = {
  FETCH_COMMENTS: 'FETCH_COMMENTS',
  SET_COMMENTS: 'SET_COMMENTS',
  SET_COMMENT: 'SET_COMMENT',
  ADD_COMMENTS: 'ADD_COMMENTS',
  CREATE_COMMENT: 'CREATE_COMMENT'
}

export interface CommentsParams {
  estimateId?: string
  orderId?: string
  jobId?: string
  userId?: string
  itemId?: string
  start?: number
  limit?: number
}

export interface SearchParamValue {
  attr: keyof CommentsParams
  value: any
}

export interface Recipient {
  id: string,
  name: string,
  userId: string
}

export interface Comment {
  id: string,
  message: string,
  recipientsList: Recipient[],
  estimateId: string,
  orderId: string,
  jobId: string,
  insertedBy: string,
  insertedOn: number,
  updatedBy: string,
  updatedOn: number,
  insertedByName?: string,
  sender: {
    id: string,
    name: string,
    userId: string
  }
}

/* ACTIONS */

export interface FetchCommentsAction {
  type: typeof commentsTypes.FETCH_COMMENTS
  payload: {
    page?: number,
    size?: number,
    params: Partial<CommentsParams>
  }
  callback?: (succ: boolean, comments?: CommentsType) => void
}

export interface CreateCommentAction {
  type: typeof commentsTypes.CREATE_COMMENT
  //TODO: replace any
  payload: Partial<Comment>
  callback?: (succ: boolean, comment?: Comment) => void
}

export interface SetCommentsAction {
  type: typeof commentsTypes.SET_COMMENTS
  payload: Comment[]
}

export interface SetCommentAction {
  type: typeof commentsTypes.SET_COMMENTS
  payload: Partial<Comment>
}

/** REDUCER **/
export interface CommentsType {
  start: string
  limit: string
  total: string
  items: Comment[],
}

export type CommentsActions =
  | FetchCommentsAction
  | SetCommentsAction
  | SetCommentAction
  | CreateCommentAction


