/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState, useContext, useEffect, useCallback, useMemo } from 'react'
import { Grid, Typography, RadioGroup, Radio, Box, OutlinedInput, SelectInput } from 'src/components/UI'
import { Checkbox, CircularProgress, FormControlLabel } from '@mui/material'
import { DispatchContext } from '../../context'
import { DispatchPricingModel, OrderType } from 'src/ducks/orders/types'
import { ITEM_MARGIN_TYPE, round, CURRENCY_MASK } from 'src/helpers'
import { dispatchTypes } from '../../context/types'
import { icons } from 'src/assets'
import { OrderBid } from 'src/ducks/types'
import { SelectInputOption } from 'src/components/UI/CustomUI/atoms/SelectInput/types'
import { orderActions } from 'src/ducks/actions'
import { useDispatch, useSelector } from 'react-redux'
import { employeeIsSuperAdmin, getOrder } from 'src/ducks/selectors'
import { debounce } from 'lodash'
import { createNumberMask } from 'text-mask-addons'
import CurrencyTextField from 'src/components/UI/CustomUI/molecules/CurrencyTextField'

const ItemMargin: FC = () => {
  const dispatch = useDispatch()
  const { dispatch: dispatchContext, state: { orderBids, loadingPricing } } = useContext(DispatchContext)

  const jobOrder = useSelector(getOrder())
  const { pricingModel, bidPrice, orderId } = jobOrder || {} as OrderType
  const { itemMarginType, itemMarginValue, autoUpdate, itemMarginTotal } = pricingModel ?? {}
  const [marginVal, setMarginVal] = useState("")
  const [open, setOpen] = useState(true)
  const [vendorMargin, setVendorMargin] = useState<SelectInputOption | null>(null)
  const currencyMask = createNumberMask(CURRENCY_MASK)

  const isSuperAdmin = useSelector(employeeIsSuperAdmin)

  const setValues = (attrs: Record<string, unknown>) => {
    dispatch(orderActions.setOrder({ ...jobOrder, pricingModel: { ...pricingModel, ...attrs } }))
  }

  const calculatePricing = (attrs: Partial<DispatchPricingModel>) => {
    const values = {
      ...attrs,
      itemMarginValue: Number(attrs.itemMarginValue)
    }
    if (orderId && itemMarginValue !== "") {
      setValues(attrs)
      dispatchContext({ type: dispatchTypes.SET_VALUE, payload: { attr: 'loadingPricing', value: true } })
      dispatch(orderActions.getPricing({ orderId, pricingModel: { ...pricingModel, ...values } }, (succ) => {
        dispatchContext({ type: dispatchTypes.SET_VALUE, payload: { attr: 'loadingPricing', value: false } })

      }))
    }
  }

  const handlePunchlistMargin = (value: number) => {
    const newValue = value || 0
    const newItemMarginValue = newValue
    calculatePricing({ autoUpdate: autoUpdate || true, itemMarginType: ITEM_MARGIN_TYPE.PL_MARGIN, itemMarginValue: newItemMarginValue })

  }

  const handleVendorMargin = (option: any) => {
    const newMarginAmount = parseInt(option?.key) ?? 0
    const newItemMarginValue = orderBids?.find(bid => bid.bidPrice === newMarginAmount)?.marginPct ?? 0
    setVendorMargin(option)
    dispatchContext({ type: dispatchTypes.SET_VALUE, payload: { attr: 'marginAmount', value: newMarginAmount } })
    calculatePricing({ autoUpdate: autoUpdate || true, itemMarginType: ITEM_MARGIN_TYPE.VENDOR_MARGIN, itemMarginValue: newItemMarginValue })


  }

  const getDefaultVendorMargin = () => {


    if (orderBids && orderBids.length > 0) {
      const bid = itemMarginValue > 0
        ? orderBids.find(orderBid => orderBid.marginPct === itemMarginValue) as OrderBid
        : orderBids[0] as OrderBid
      return {
        key: bid ? bid?.bidPrice.toString() : orderBids[0].bidPrice.toString(),
        label: `Bid Price: $${bid ? bid.bidPrice : orderBids[0].bidPrice} - Vendor Profit $${bid ? bid.vendorProfit : orderBids[0].vendorProfit} (${bid ? bid.marginPct : orderBids[0].marginPct}%)`
      }
    }
    return null
  }

  const handleTypeChange = (type: ITEM_MARGIN_TYPE) => {

    if (type === ITEM_MARGIN_TYPE.PL_MARGIN) {
      const marginValue = Number(itemMarginValue)
      handlePunchlistMargin(marginValue > 100 ? 50 : marginValue)
    }

    if (type === ITEM_MARGIN_TYPE.VENDOR_MARGIN) {
      handleVendorMargin(vendorMargin || getDefaultVendorMargin())
    }

    if (type === ITEM_MARGIN_TYPE.CUSTOM) {

      if (vendorMargin?.key) {
        const newMarginAmount = parseInt(vendorMargin?.key) ?? 0
        handleCustomBid(newMarginAmount)
        //setMarginVal(vendorMargin?.key.toString())
        setMarginVal(round(Number(vendorMargin?.key.toString()) ?? 0, 2))
      } else {
        handleCustomBid(itemMarginValue)
      }
    }
  }

  useEffect(() => {
    if (itemMarginType === ITEM_MARGIN_TYPE.VENDOR_MARGIN) {
      handleVendorMargin(getDefaultVendorMargin())
      const vendorMarginValue = getDefaultVendorMargin()
      if (vendorMarginValue?.key) {
        setMarginVal(round(Number(vendorMarginValue?.key.toString()) ?? 0, 2))
      }

    } else {

      if (itemMarginType === ITEM_MARGIN_TYPE.CUSTOM) {
        setMarginVal(bidPrice ? round(Number(bidPrice?.toString()) ?? 0, 2) : "")
      }

      handleTypeChange(itemMarginType)
    }
  }, [])

  const handleCustomBid = useCallback(
    (value: number | string) => {
      dispatchContext({ type: dispatchTypes.SET_VALUE, payload: { attr: 'marginAmount', value: value } })
      calculatePricing({ autoUpdate, itemMarginType: ITEM_MARGIN_TYPE.CUSTOM, itemMarginValue: value })
    },
    []
  )

  const onItemValueChanged = useMemo(
    () => debounce(handleCustomBid, 600),
    [handleCustomBid]
  )

  return (
    <>
      <Grid item xs={8}>
        <FormControlLabel
          control={
            <Checkbox
              checkedIcon={<icons.IndeterminateCheckBoxSharp />}
              icon={<icons.AddBoxSharp />}
              sx={{ color: 'var(--blue700)', '&.Mui-checked': { color: 'var(--blue700)' } }}
              onChange={() => setOpen(!open)}
            />
          }
          checked={open}
          label='Item Margin'
        />
        {open && (
          <>
            <RadioGroup
              value={itemMarginType}
              onChange={(e) => handleTypeChange(e.target.value as ITEM_MARGIN_TYPE)}
              sx={{ flexDirection: 'column', gap: 1.5, paddingLeft: 4 }}
            >
              <FormControlLabel
                key={ITEM_MARGIN_TYPE.PL_MARGIN}
                value={ITEM_MARGIN_TYPE.PL_MARGIN}
                control={<Radio sx={{ '&.Mui-checked': { color: 'var(--blue700)' } }} />}
                label='Margin'
                sx={{ height: '16px' }}
              />
              {itemMarginType === ITEM_MARGIN_TYPE.PL_MARGIN && (
                <Box sx={{ display: 'flex', flexDirection: 'column', paddingLeft: 4 }}>
                  <OutlinedInput
                    size='small' sx={{ maxWidth: '100px' }} placeholder='0'
                    endAdornment={<Typography variant='body2'>%</Typography>}
                    value={itemMarginValue || ''}
                    onChange={(event) => handlePunchlistMargin(parseInt(event.target.value, 10))}
                  />
                </Box>
              )}
              {orderBids && orderBids?.length > 0 && (
                <>
                  <FormControlLabel
                    key={ITEM_MARGIN_TYPE.VENDOR_MARGIN}
                    value={ITEM_MARGIN_TYPE.VENDOR_MARGIN}
                    control={<Radio sx={{ '&.Mui-checked': { color: 'var(--blue700)' } }} />}
                    label='Vendor margin'
                    sx={{ height: '16px' }}
                    disabled={!isSuperAdmin}
                  />
                  {itemMarginType === ITEM_MARGIN_TYPE.VENDOR_MARGIN && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', paddingLeft: 4 }}>
                      <SelectInput
                        onChange={handleVendorMargin} size='small'
                        value={vendorMargin} sx={{ minWidth: '320px' }} label=''
                        options={orderBids
                          ? orderBids.map(bid => {
                            return {
                              key: bid.bidPrice,
                              label: `Bid Price: $${bid.bidPrice} - Vendor Profit $${bid.vendorProfit} (${bid.marginPct}%)`
                            }
                          })
                          : []}
                        disabled={!isSuperAdmin}
                      />
                    </Box>
                  )}
                </>
              )}
              <FormControlLabel
                key={ITEM_MARGIN_TYPE.CUSTOM}
                value={ITEM_MARGIN_TYPE.CUSTOM}
                control={<Radio sx={{ '&.Mui-checked': { color: 'var(--blue700)' } }} />}
                label='Custom Bid'
                sx={{ height: '16px' }}
              />
              {itemMarginType === ITEM_MARGIN_TYPE.CUSTOM && (
                <Box sx={{ display: 'flex', flexDirection: 'column', paddingLeft: 4 }}>
                  <CurrencyTextField
                    autoComplete='off'
                    label=''
                    placeholder='$0.00'
                    value={marginVal}
                    size='small'
                    onChange={(e) => {
                      setMarginVal(e)
                      onItemValueChanged(e ?? 0)
                    }}
                    labelVariant='h6'
                    mask={currencyMask}
                  />

                </Box>
              )}
            </RadioGroup>
          </>
        )}
      </Grid>
      <Grid item xs={4} textAlign='end'>
        <Typography variant='body1' paddingTop='10px'>
          {loadingPricing ? <CircularProgress color='info' size='.8rem' /> : `$${round(itemMarginTotal ?? 0, 2)}`}
        </Typography>
      </Grid>
    </>
  )
}

export default ItemMargin
