import { Theme } from '../../../UI'
import { makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => ({
  lgFont: {
    fontSize: '20px !important',
    fontWeight: '500 !important',
    lineHeight: '30px !important',
    color: 'var(--navy-700)'
  },
  primaryButton: {
    backgroundColor: 'var(--blue700)',
    borderRadius: '50%',
    height: '32px !important'
  }
}))
