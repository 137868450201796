import { FC, useState } from 'react'

import { Box, Button, CircularProgress, ListSubheader, Typography } from '@mui/material'
import { isEmpty } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { icons } from 'src/assets'
import { estimateActions, estimateItemsActions } from 'src/ducks/actions'
import { Item } from 'src/ducks/types'
import { ESTIMATE_VIEW, ITEM_CATEGORY, ITEM_CATEGORY_FOR_FILTERS, ITEM_PRIORITY, ITEM_STATUS, history, sortItemsByCategories } from 'src/helpers/index'
import EstimateItem from '../EstimateItem'
import EstimateItemsContainer from '../EstimateItemsContainer'
import useStyles from './styles'
import { getEstimateItems, getEstimateItemsGroupBy } from 'src/ducks/estimateItems/selectors'

export interface EstimatedItemsProps {
  selectedType: ESTIMATE_VIEW
  hideRemoved: boolean
  selectedFilt: ITEM_CATEGORY_FOR_FILTERS | ITEM_PRIORITY
  fetchEstimateView: (loadings: boolean) => void
  handleShowAlert: (Item: Item) => void
  punchlistPlanner: boolean
  estimateNotApproved: boolean
  getIcon: (item: string, color?: string) => JSX.Element
  serviceable?: boolean
}

const EstimatedItems: FC<EstimatedItemsProps> = ({ selectedType, hideRemoved, serviceable, selectedFilt, fetchEstimateView, handleShowAlert, punchlistPlanner, estimateNotApproved, getIcon }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { id: estimateId } = history.getParamValues()

  const [expandAll, setExpandAll] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingAll, setLoadingAll] = useState(false)

  const [itemIdLoading, setItemIdLoading] = useState('')

  const estimateItems = useSelector(getEstimateItems(false)).filter((item: Item) => {
    return !item.addedPostDelivery || (item.addedPostDelivery && item.status !== ITEM_STATUS.NEW)
  })

  const groupedItems = sortItemsByCategories(useSelector(
    getEstimateItemsGroupBy(selectedType, hideRemoved, selectedFilt !== ITEM_CATEGORY_FOR_FILTERS.ALL ? selectedFilt : undefined, undefined, estimateItems)
  ))

  delete groupedItems.EVALUATE

  const itemsToBatch = Object.entries(groupedItems).reduce(
    (accumulator: Item[], [, item]: any) => {
      return [...accumulator, ...item.map((item: any) => item).filter((item: any) => item.category !== ITEM_CATEGORY.EXCLUDED.toUpperCase())]
    },
    []
  );

  const showRemoveAll = itemsToBatch.length > 0 && itemsToBatch.every((item: Item) => item.status === ITEM_STATUS.APPROVED)

  const handleUpdateItem = (item: Item) => {
    setLoading(true)
    setItemIdLoading(item.itemId)
    dispatch(
      estimateItemsActions.updateEstimateItem(
        {
          estimateId,
          itemId: item.itemId,
          partialItem: {
            status: item.status === ITEM_STATUS.APPROVED ? ITEM_STATUS.REJECTED : ITEM_STATUS.APPROVED
          }
        },
        () => {
          setLoading(false)
          setItemIdLoading('')
          fetchEstimateView(false)
        }
      )
    )
  }

  const handleAddItems = () => {
    setLoading(true)
    setLoadingAll(true)

    dispatch(estimateActions.addItems(itemsToBatch, (succ) => {
      fetchEstimateView(false)
      setLoading(false)
      setLoadingAll(false)
    }))
  }

  const handleRemoveItems = () => {
    setLoading(true)
    setLoadingAll(true)

    dispatch(estimateActions.removeItems(itemsToBatch, (succ) => {
      fetchEstimateView(false)
      setLoading(false)
      setLoadingAll(false)
    }))
  }

  if (isEmpty(groupedItems)) return <></>

  return (
    <EstimateItemsContainer
      groupedItems={groupedItems}
      title="ESTIMATED ITEMS"
      action={
        <>
          <Button
            onClick={() => setExpandAll(!expandAll)}
            variant='text'
            className={classes.expandButton}
          >
            {expandAll ? 'Collapse' : 'Expand'} All Items
          </Button>
          {/* {estimateNotApproved && <Button
            onClick={() => showRemoveAll ? handleRemoveItems() : handleAddItems()}
            variant='text'
            className={`${showRemoveAll ? classes.removeAllButton : classes.addAllButton}`}
            startIcon={!showRemoveAll ? (<icons.ShoppingCart />) : <></>}
            // disabled={loading || itemsToBatch.length === 0}
            disabled={true}
          >
            {showRemoveAll ? 'Remove All Items' : 'Add All Items'}
          </Button>} */}
        </>

      }
    >
      {Object.keys(groupedItems).map((itemKey) => {
        const items = groupedItems[itemKey]
        const itemCategory =
          selectedType === ESTIMATE_VIEW.TYPE
            ? ITEM_CATEGORY[itemKey as keyof typeof ITEM_CATEGORY]
            : itemKey

        return (
          <Box className={classes.categoryContainer} key={itemKey}>
            <ListSubheader className={classes.subHeader}>
              <Box className={classes.categoryHeader}>
                {getIcon(itemKey, 'var(--bosscat-blue-600)')}
                <Typography className={classes.categoryTitle}>
                  {itemCategory}
                </Typography>
              </Box>
            </ListSubheader>

            {items.map((item: Item, index: number) => {
              const itemIsInCart = item.status === ITEM_STATUS.APPROVED
              return (
                <Box key={item.itemId} /* className={itemCategory === ITEM_CATEGORY.EVALUATE ? classes.evaluateContainer : ''} */>
                  <EstimateItem
                    item={item}
                    // showAlert={() => handleShowAlert && handleShowAlert(item)}
                    isPunchlistPlanner={punchlistPlanner}
                    initialOpen={expandAll}
                    showPrice={item.category !== ITEM_CATEGORY.EXCLUDED.toUpperCase()}
                    showPriceRange={!serviceable}
                    actions={
                      estimateNotApproved ?
                        <>
                          {(loading && item.itemId === itemIdLoading) || loadingAll ?
                            <Box width={86} className={classes.spinnerContainer}>
                              <CircularProgress />
                            </Box>
                            :
                            <></>
                            // (item.category === ITEM_CATEGORY.EXCLUDED.toUpperCase()) ?
                            //   <></> :
                            //   <Button
                            //     startIcon={
                            //       !itemIsInCart && <icons.ShoppingCart />
                            //     }
                            //     onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                            //       event.stopPropagation()
                            //       // handleUpdateItem(item)
                            //       // handleClickConsultation(item)
                            //     }}
                            //     // disabled={loading}
                            //     disabled={true}
                            //     className={itemIsInCart ? classes.buttonRemove : classes.buttonAdd}>
                            //     {itemIsInCart ? 'Remove' : 'Add'}
                            //   </Button>
                          }
                        </>
                        : <></>
                    }
                  />
                </Box>
              )
            })}
          </Box>
        )
      })}
    </EstimateItemsContainer>
  )
}

export default EstimatedItems
