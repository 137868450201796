import { Box, Typography } from '@mui/material'
import {
  HeaderType,
  AlignType,
} from 'src/components/UI/CustomUI/organisms/Table/types'

import styles from './styles.module.scss'

export const removeProtocol = (url: string): string => {
  if (!url) {
    return ''
  }

  return url.replace(/(^\w+:|^)\/\//, '')
}

const headers: HeaderType[] = [
  {
    id: 'totals',
    label: 'Totals',
    custom: true,
    padding: "3.3px 8px",
    align: 'left' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Box>
          <Typography className={styles.Companies__companyName} style={{ fontWeight: row.isBold ? 700 : 400 }}>
            {row?.totals}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'count',
    label: 'Count',
    custom: true,
    padding: "3.3px 8px",
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Box>
          <Typography align='right' className={styles.Companies__companyName}>
            {row.count}
          </Typography>
        </Box>
      )
    },
  },
]

export default headers
