import { useEffect, useState } from 'react'

import { Fab, Typography } from '@mui/material'

import styles from './styles.module.scss'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import Modal from 'src/components/UI/CustomUI/molecules/Modal'
import CommentsModal from './CommentsModal'

const CommentsFab = () => {
  const [isHovered, setIsHovered] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  return (
    <>
      <Modal
        open={modalOpen}
        setOpen={setModalOpen}
        className={styles.Comments__modal}
        showClose
        dialogContentSx={{ display: 'block !important' }}
      >
        <CommentsModal />
      </Modal>
      <Fab onClick={() => setModalOpen(!modalOpen)} className={`${styles.Comments__button}`} color="primary" aria-label="add" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
        <Icon width="20px" height="20px" name="ChatBubbleOutline" />
        <Typography sx={{
          position: isHovered ? 'static' : 'absolute',
          left: isHovered ? '0px' : '-999em',
          transition: 'all 1s ease-out !important'
        }} className={`${styles.Comments__button__text}`}>Comments</Typography>
      </Fab>
    </>
  )
}

export default CommentsFab