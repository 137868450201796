import axios from '../axios'
import { ApiController } from './types'
import { formatParams } from 'src/helpers'
import { Client } from 'src/ducks/types'

const base = '/clients'

const apiController: ApiController = {
  //.get(base, { params: { start, limit,searchParams } })

  getClients: async ({ start, limit, searchParams }) => {
    const params = formatParams(searchParams)
    return await axios
      .get(`${base}?${params}`, { params: { start, limit } })
      .then((response) => response.data)
  },

  getClientById: async (clientId: string) =>
    await axios.get(`${base}/${clientId}`).then((response) => response.data),

  getClientStatics: async (clientId: string) =>
    await axios
      .get(`${base}/${clientId}/statistics`)
      .then((response) => response.data),

  getClientsTotalStatics: async (searchParams) => {
    const params = formatParams(searchParams)
    return await axios
      .get(`${base}/statistics?${params}`)
      .then((response) => response.data)
  },

  getClientPriceModel: async () =>
    await axios.get(`${base}/price-models`).then((response) => response.data),

  addClient: async (payload: Partial<Client>) =>
    await axios.post(`${base}`, payload).then((response) => response.data),

  updateClientId: async (id: string, request) =>
    await axios
      .patch(`${base}/${id}`, request)
      .then((response) => response.data),

  downloadCSV: async ({ start, limit, searchParams }) => {
    const params = formatParams(searchParams)
    return await axios
      .post(`${base}/csv?${params}`, { params: { start, limit } })
      .then((response) => response.data)
  },
}

export default apiController
