import { FC, useState } from 'react'
import { CircleIconButtonProps } from './types'
import { Button, Popover, Typography } from '@mui/material'

const CircleIconButton: FC<CircleIconButtonProps> = ({
  width,
  height,
  icon,
  iconColor,
  bgcolor,
  onClick,
  popover,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <>
      <Button
        style={{
          padding: '0',
          backgroundColor: `${bgcolor}`,
          borderRadius: '50%',
          width: `${width}`,
          height: `${height}`,
          color: `${iconColor}`,
        }}
        onClick={onClick}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {icon}
      </Button>
      {popover && (
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'none',
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography sx={{ p: 1 }}>{popover || ''}</Typography>
        </Popover>
      )}
    </>
  )
}

export default CircleIconButton
