import { HeaderType } from 'src/components/UI/CustomUI/organisms/Table/types'
import { AlignType } from "src/components/UI/CustomUI/organisms/Table/types"
import { Button, Typography } from 'src/components/UI'
import { JOB_PUBLIC_STATUS } from 'src/helpers'

const headers: HeaderType[] = [
    {
        id: 'startedOn',
        label: 'Escalated',
        type: 'datetime'
    },
    {
        id: 'address',
        label: 'Address'
    },
    {
        id: 'name',
        label: 'Task'
    },
    {
        id: 'status',
        label: 'Status',
        custom: true,
        align: 'center' as AlignType,
        Element: (row: any): JSX.Element => {
            return (<Typography color='var(--red600)' sx={{ fontSize: '14px' }}>
                {row?.status ? JOB_PUBLIC_STATUS.NEEDS_ATTENTION : ''}
            </Typography>)
        },
        hide: false
    },
    {
        id: 'affiliate',
        label: 'Type',
        custom: true,
        align: 'center' as AlignType,
        Element: (row: any): JSX.Element => {
            return (<Typography sx={{ fontSize: '14px' }}>
                {row?.affiliate ? row?.affiliate + ' - ' + row?.affiliateType : row?.affiliateType}
            </Typography>)
        },
        hide: false
    },
    {
        id: 'view',
        label: 'View',
        custom: true,
        align: 'center' as AlignType,
        Element: (row: any): JSX.Element => {
            return <Button
                variant='contained'
                color='primary'
                href={`/jobs/${row.jobId}`}
                sx={{ width: '91px', height: '26px' }}>
                View
            </Button>
        },
        hide: false
    }
]

export default headers
