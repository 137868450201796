import { SearchParams } from '../searches/types'
import { GroupedOrder } from '../types'

/** TYPES **/
export const groupedOrdersTypes = {
  FETCH_GROUPED_ORDERS: ' FETCH_GROUPED_ORDERS',
  SET_GROUPED_ORDERS: 'SET_GROUPED_ORDERS',
  RESET_GROUPED_ORDERS: 'RESET_GROUPED_ORDERS',
}

/** ACTIONS **/
export interface FetchGroupedOrdersAction {
  type: typeof groupedOrdersTypes.FETCH_GROUPED_ORDERS
  payload: {
    stage: string
    searchParams: Partial<SearchParams>
  }
  callback?: (succ: boolean, limit?: number) => void
}

export interface ResetGroupedOrdersAction {
  type: typeof groupedOrdersTypes.RESET_GROUPED_ORDERS
  payload: {
    stage: string,
  }
  callback?: (succ: boolean) => void
}

export interface SetGroupedOrdersAction {
  type: typeof groupedOrdersTypes.SET_GROUPED_ORDERS
  payload: SetGroupedOrdersParam
}

export interface SetGroupedOrdersParam {
  data: GroupedOrder[]
  stage: string
}

export type GroupedOrdersAction =
  | FetchGroupedOrdersAction
  | SetGroupedOrdersAction
  | ResetGroupedOrdersAction

export type GroupedOrdersType = {
  [key: string]: GroupedOrder[]
}
