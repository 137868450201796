import { Box, Button, Tooltip, Typography } from 'src/components/UI'
import { AlignType, HeaderType } from 'src/components/UI/CustomUI/organisms/Table/types'
import { formatTimestamp } from '../../../../helpers'

const headers: HeaderType[] = [
  {
    id: 'estimatePublicId',
    label: 'ID',
    custom: true,
    Element: (row: any): JSX.Element => {
      return (
        <Typography sx={{ fontSize: '10px', fontWeight: 400 }}>{row?.estimatePublicId}</Typography>
      )
    }
  },
  {
    id: 'scheduledStartTimestamp',
    label: 'Scheduled Date',
    custom: true,
    Element: (row: any): JSX.Element => {
      return (
        <Typography sx={{ fontSize: '10px', fontWeight: 400 }}>
          {row?.scheduledStartTimestamp ? formatTimestamp(row?.scheduledStartTimestamp, 'MM/DD/YYYY') : '-'}
        </Typography>
      )
    }
  },
  {
    id: 'address',
    label: 'Address',
    custom: true,
    Element: (row: any): JSX.Element => {
      return (
        <Typography sx={{ fontSize: '10px', fontWeight: 400 }}>{row?.address?.fullAddress}</Typography>
      )
    }
  },
  {
    id: 'region',
    label: 'City',
    custom: true,
    Element: (row: any): JSX.Element => {
      return (
        <Typography sx={{ fontSize: '10px', fontWeight: 400 }}> {row?.address?.city}</Typography>
      )
    }
  },
  {
    id: 'vendorPaidByName',
    label: 'Vendor',
    custom: true,
    Element: (row: any): JSX.Element => {
      return (
        <Typography sx={{ fontSize: '10px', fontWeight: 400 }}>{row?.vendorPaidByName ? row?.vendorPaidByName : ' - '}</Typography>
      )
    }
  },
  {
    id: 'items',
    label: '# Items',
    custom: true,
    Element: (row: any): JSX.Element => {
      return (
        <Typography sx={{ fontSize: '10px', fontWeight: 400 }}>{row?.numItems ? row?.numItems : ' - '}</Typography>
      )
    }
  },
  {
    id: 'statusType',
    label: 'Status',
    custom: true,
    Element: (row: any): JSX.Element => {
      const render = () => {
        const status = row?.statusType ?? ' - '
        switch (status) {
          case 'REJECTED': {
            return <Typography sx={{ fontSize: '10px', fontWeight: 700 }} color='#E01F1F'>{status?.replace('_', ' ')}</Typography>
          }
          case 'DISPATCHED': {
            return <Typography sx={{ fontSize: '10px', fontWeight: 700 }} color='#CC9300'>{status?.replace('_', ' ')}</Typography>
          }
          case 'REVIEW': {
            return <Typography sx={{ fontSize: '10px', fontWeight: 700 }} color='#0071A3'>{status?.replace('_', ' ')}</Typography>
          }
          case 'IN_PROGRESS':
          case 'PENDING': {
            return <Typography sx={{ fontSize: '10px', fontWeight: 700 }} color='#3A66FF'>{status?.replace('_', ' ')}</Typography>
          }
          case 'APPROVED':
          case 'COMPLETE': {
            return <Typography sx={{ fontSize: '10px', fontWeight: 700 }} color='#17B975'>{status?.replace('_', ' ')}</Typography>
          }
          default: {
            return (
              <Typography sx={{ fontSize: '10px', fontWeight: 700 }}>{status?.replace('_', ' ')}</Typography>
            )
          }
        }
      }
      return render()
    }
  },
  {
    id: 'view',
    label: 'View',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return <Tooltip title={row.preapproval ? 'This site visit is currently in the estimation stage' : ''} placement="top" >
        <Box>
          <Button
            variant='contained'
            color='primary'
            href={row.preapproval ? `/estimates/${row.estimateId}` : `/jobs/${row.estimateId}?from=sitevisits`}
            sx={{ width: '91px', height: '26px' }}>
            View
          </Button>
        </Box>
      </Tooltip>
    },
    hide: false
  }
]

export default headers
