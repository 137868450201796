import { CLIENT_TYPE } from 'src/helpers'
import { authTypes } from '../auth/types'
import { Client } from '../types'
import { ClientActions, ClientType, clientTypes, ClientValue } from './types'

const initialState: Client = {
  id: '',
  firstName: '',
  middleName: '',
  lastName: '',
  email: '',
  phone: '',
  address: {
    city: '',
    state: '',
    line_1: '',
    line_2: '',
    zipCode: '',
    latitude: 0,
    longitude: 0,
    fullAddress: ''
  },
  type: '',
  clientType: CLIENT_TYPE.HOMEOWNER,
  affiliate: 'string',
  customerId: 'string',
  isAdmin: false,
  registrationCompleteOn: 0
}

const reducer = (state = initialState as any, action: ClientActions): ClientType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case clientTypes.SET_CLIENT: {
      return payload as Client
    }

    case clientTypes.SET_CLIENT_VALUE: {
      const { attr, value } = payload as ClientValue
      return {
        ...state,
        [attr]: value
      }
    }

    default:
      return state
  }
}

export default reducer
