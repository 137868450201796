import { useState } from 'react'
import { LabeledText } from 'src/components/UI'
import { ContentBox, Divider, TabBox } from '../../Templates'
import { useSelector } from 'react-redux'
import { getJob } from 'src/ducks/selectors'
import { JobItem } from 'src/ducks/types'
import { dateFormatString, formatTimestamp, formatUTCTimestampDate } from 'src/helpers'
import { useIsLgScreen, useIsXlScreen } from 'src/hooks'

const HouseDetails = () => {
  const job = useSelector(getJob())
  const {
    schedulingStartDate,
    schedulingEndDate,
    preConInspectionDate,
    finalWalkthroughDate,
    properties
  } = job as JobItem

  const [xlScreen] = useIsXlScreen()
  const [lgScreen] = useIsLgScreen()
  const delivery = properties.estimateDeliveredOn ? formatTimestamp(properties.estimateDeliveredOn, 'MM/DD/YYYY') : ''
  const closingDate = properties.closingDate ? dateFormatString(formatUTCTimestampDate(properties.closingDate), 'MM/DD/YYYY') : ''
  const schStartDate = schedulingStartDate ? dateFormatString(formatUTCTimestampDate(schedulingStartDate), 'MM/DD/YYYY') : ''
  const schEndDate = schedulingEndDate ? dateFormatString(formatUTCTimestampDate(schedulingEndDate), 'MM/DD/YYYY') : ''
  const preConDate = preConInspectionDate ? dateFormatString(formatUTCTimestampDate(preConInspectionDate), 'MM/DD/YYYY') : ''
  const finalWalkDate = finalWalkthroughDate ? dateFormatString(formatUTCTimestampDate(finalWalkthroughDate), 'MM/DD/YYYY') : ''

  return (
    <TabBox>
      <ContentBox gridTemplateColumns={xlScreen || lgScreen ? '1fr 1fr' : '1fr'}>
        <LabeledText labelValue='Delivery Date:' textValue={`${delivery}`} />
        <LabeledText labelValue='Closing Date:' textValue={`${closingDate}`} />
      </ContentBox>
      <Divider />
      <ContentBox gridTemplateColumns={xlScreen || lgScreen ? '1fr 1fr' : '1fr'}>
        <LabeledText labelValue='Scheduling Start:' textValue={`${schStartDate}`} />
        <LabeledText labelValue='Scheduling End:' textValue={`${schEndDate}`} />
      </ContentBox>
      <Divider />
      <ContentBox gridTemplateColumns={xlScreen || lgScreen ? '1fr 1fr' : '1fr'}>
        <LabeledText labelValue='Pre-Con Inspection:' textValue={`${preConDate}`} />
        <LabeledText labelValue='Final Walktrough:' textValue={`${finalWalkDate}`} />
      </ContentBox>
    </TabBox>
  )
}

export default HouseDetails
