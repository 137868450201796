import React, { useReducer } from 'react'
import { DispatchReducer } from './reducer'
import { DispatchContextType } from './types'

const initialState = {
  jobFiles: [],
  marginAmount: 0,
  scheduledStartTimestamp: null,
  scheduledEndTimestamp: null,
  dispatchNotes: '',
  orderBids: [],
  showWarnings: false,
  openModal: false,
  modalType: undefined,
  tradesSelected: [],
  loadingPricing: false,
}

export const DispatchContext = React.createContext<{
  state: DispatchContextType;
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null
})


export const DispatchProvider: React.FunctionComponent = ({ children }: any) => {
  const [state, dispatch] = useReducer(DispatchReducer, initialState)

  return (
    <DispatchContext.Provider value={{ state, dispatch }}>
      {children}
    </DispatchContext.Provider>
  )
}