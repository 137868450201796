import { Grid, Typography } from '@mui/material'
import { debounce } from 'lodash'
import { useCallback, useContext, useEffect, useMemo, useState, FC } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { TextFieldLabel, RadioGroup, FormControlLabel, Radio } from 'src/components/UI'
import { getJob, getTerritory } from 'src/ducks/selectors'
import { getInvoicePreview } from 'src/ducks/invoice/selectors'
import { isEmpty, validateEmail, CURRENCY_MASK, unMaskedInput, unMaskedInputWithReplace, PORCENT_MASK } from 'src/helpers'
import { DepositInvoiceContext } from '../context'
import { depositInvoiceTypes } from '../context/types'
import UploadDocuments from "src/components/UI/CustomUI/molecules/UploadDocument"
import { createNumberMask } from 'text-mask-addons'
import { useParams } from "react-router-dom"
import { invoiceActions } from "src/ducks/actions"

type TABLE_OPTION = 'USD' | '%';

const DepositInfo = () => {
  const { jobId } = useParams()
  const dispatch = useDispatch()
  const currencyMask = createNumberMask(CURRENCY_MASK)
  const porcentMask = createNumberMask(PORCENT_MASK)
  const territory = useSelector(getTerritory)
  const { dispatch: dispatchContext, state } = useContext(DepositInvoiceContext)
  const job = useSelector(getJob())
  const invoicePreview = useSelector(getInvoicePreview())
  const [assignTo, setAssignTo] = useState<TABLE_OPTION>('USD')
  const contactsDefault = job?.properties?.contacts?.filter((contact) => (contact as any).isPayer);

  const [uploadingFile, setUploadingFile] = useState(false)
  const [file, setFile] = useState<any>(null)
  const [amountForm, setAmountForm] = useState('')
  const [porcenttForm, setPorcentForm] = useState('')

  const [values, setValues] = useState({
    from: `${territory?.emailName} ${territory?.email}`,
    ccEmails: state?.ccEmails,
    payer: invoicePreview && invoicePreview?.preparedFor?.payer
      ? invoicePreview?.preparedFor?.fullName + ' <' + invoicePreview?.preparedFor?.email + '>'
      : contactsDefault && contactsDefault?.length > 0 ? contactsDefault[0]?.fullName + ' <' + contactsDefault[0]?.email + '>' : '',//contactsDefault? contactsDefault[0]?.email : '',
    subject: job?.properties?.address?.fullAddress ? `BOSSCAT Invoice for ${job?.properties?.address?.fullAddress}` : 'BOSSCAT Invoice',
  })

  const changeItemValue = useCallback(
    (newValue, attr) => dispatchContext({ type: 'SET_VALUE', payload: { attr: attr, value: newValue } }),
    []
  )

  const onItemValueChanged = useMemo(
    () => debounce(changeItemValue, 500),
    [changeItemValue]
  )

  const changeDepositAmountValue = useCallback(
    (amount) => { fetchInvoicePreview(Number(amount)) }
    , []
  )

  const onAmountValueChanged = useMemo(
    () => debounce(changeDepositAmountValue, 500),
    [changeDepositAmountValue]
  )

  const changeDepositPercentValue = useCallback(
    (amount) => {
      fetchPercentaje(Number(amount))
    }
    , []
  )

  const onPercentValueChanged = useMemo(
    () => debounce(changeDepositPercentValue, 500),
    [changeDepositPercentValue]
  )

  const validateAdditionalEmails = () => {
    let isValid = true
    const emails = values?.ccEmails?.split(',')
    emails.forEach(adEmail => {
      if (!validateEmail(adEmail?.replace(/\s/g, ''))) isValid = false
    })
    return isValid
  }

  const validateAmount = () => {
    let isValid = false

    if (state.depositAmount >= 0 && state.depositAmount <= state.depositOutstandingBalance) {
      isValid = true
    }

    return isValid
  }

  const validate: boolean =
    !isEmpty(values?.subject) && validateAmount() &&
    (isEmpty(values?.ccEmails) || validateAdditionalEmails())

  const setAmountZero = () => {
    setAmountForm('')
    setPorcentForm('')
    //fetchInvoicePreview(0, false)
    //setValues({ ...values, depositAmount: 0 })
    //onItemValueChanged(0, 'depositAmount')
    //dispatchContext({ type: depositInvoiceTypes.SET_VALUE, payload: { attr: 'depositAmount', value: 0 } })

  }

  const fetchPercentaje = (valueAmount: number) => {
    if (jobId) {
      dispatch(invoiceActions.getInvoicePreview({ params: { jobId: jobId, depositAmount: Number(valueAmount) } },
        (_succ: boolean, _ammountTotal: number, tax: number, paid: number, outstandingBalance: number, subtotal: number) => {

          var monto = outstandingBalance * (Number(valueAmount) / 100)
          fetchInvoicePreview(monto)
        }))
    }
  }

  const fetchInvoicePreview = useCallback((valueAmount: number) => {
    if (jobId) {

      dispatch(invoiceActions.getInvoicePreview({ params: { jobId: jobId, depositAmount: valueAmount } },
        (_succ: boolean, _ammountTotal: number, tax: number, paid: number, outstandingBalance: number, subtotal: number) => {

          let total = valueAmount
          dispatchContext({ type: 'SET_VALUE', payload: { attr: 'depositTotal', value: _ammountTotal } })
          dispatchContext({ type: 'SET_VALUE', payload: { attr: 'depositSubTotal', value: subtotal } })
          dispatchContext({ type: 'SET_VALUE', payload: { attr: 'tax', value: tax } })
          dispatchContext({ type: 'SET_VALUE', payload: { attr: 'paid', value: paid } })
          dispatchContext({ type: 'SET_VALUE', payload: { attr: 'depositOutstandingBalance', value: outstandingBalance } })
          onItemValueChanged(total, 'depositAmount')

        }))
    }
  }, [])


  useEffect(() => {
    dispatchContext({ type: depositInvoiceTypes.SET_VALUE, payload: { attr: 'isValid', value: validate } })
  }, [values])

  useEffect(() => {
    dispatchContext({ type: 'SET_VALUE', payload: { attr: 'subject', value: job?.properties?.address?.fullAddress ? `BOSSCAT Invoice for ${job?.properties?.address?.fullAddress}` : 'BOSSCAT Invoice' } })
    dispatchContext({ type: 'SET_VALUE', payload: { attr: 'payerEmail', value: values.payer } })
    dispatchContext({ type: 'SET_VALUE', payload: { attr: 'ccEmails', value: '' } })
  }, [])


  const handleUploadFile = (file: null | File, callback: () => void) => {
    setUploadingFile(true)
    if (file) {
      dispatchContext({ type: 'SET_VALUE', payload: { attr: 'file', value: file } })
      callback()
      setUploadingFile(false)
      setFile(file)
    }else{
      setFile(null)
      setUploadingFile(false)
      dispatchContext({ type: 'SET_VALUE', payload: { attr: 'file', value: null } })
    }
    
  }

  const handleTypeChange = (type: TABLE_OPTION) => {
    setAmountForm('')
    setPorcentForm('')
    fetchInvoicePreview(0)
    setAssignTo(type);
  }

  return (

    <>
      <Grid container item spacing={1} direction='column' marginBottom={4} paddingLeft={1}>

        <Grid item padding={0}>
          <Typography variant='h5Bold'>Sending Info</Typography>
        </Grid>

      </Grid>

      <Grid item container direction='column' spacing={2}>
        <Grid item container direction='row' spacing={2} >

          <Grid item container spacing={2} p={"8px 16px 20px 24px"} sx={{ backgroundColor: "#EDFDF6" }}  >
            <Grid item>
              <Typography>Deposit Amount:</Typography>
            </Grid>

            <Grid item>

              <RadioGroup
                value={assignTo}
                onChange={(e) => handleTypeChange(e.target.value as TABLE_OPTION)}
                sx={{ flexDirection: 'row' }}
              >
                <FormControlLabel
                  key='USD'
                  value='USD'
                  control={<Radio color='primary' />}
                  label='USD'
                  sx={{ height: '16px' }}
                />
                <FormControlLabel
                  key='%'
                  value='%'
                  control={<Radio color='primary' />}
                  label='%'
                  sx={{ height: '16px' }}
                />
              </RadioGroup>

            </Grid>
          </Grid>

        </Grid>

        <Grid item container direction='column' spacing={2}>
          <Grid item>

            {assignTo === "USD" && <TextFieldLabel
              autoComplete='off'
              label=''
              value={amountForm}
              size='small'
              onChange={(e) => {
                setAmountForm(e.target.value)
                unMaskedInput(e.target.value)
                var v = Number(unMaskedInput(e.target.value));
                if (v <= state.depositOutstandingBalance) {
                  onAmountValueChanged(unMaskedInput(e.target.value))
                } else {
                  setPorcentForm("0")
                  unMaskedInput("$0")
                  onPercentValueChanged(0)
                }

              }}
              labelVariant='h6'
              mask={currencyMask}
              name='amount-number-deposit-invoices'
              id='amount-number-deposit-invoices'
              placeholder='$00.00'
            />}

            {assignTo === "%" &&
              <TextFieldLabel
                autoComplete='off'
                label=''
                value={porcenttForm}
                size='small'

                onChange={(e) => {

                  setPorcentForm(e.target.value)
                  unMaskedInputWithReplace(e.target.value, "%")
                  var v = Number(unMaskedInputWithReplace(e.target.value, "%"));
                  if (v >= 0 && v <= 100) {

                    onPercentValueChanged(unMaskedInputWithReplace(e.target.value, "%"))
                  } else {
                    v = v / 10
                    setPorcentForm(v.toString())
                    unMaskedInputWithReplace(v.toString(), "%")
                    onPercentValueChanged(v)
                  }

                }}
                labelVariant='h6'
                mask={porcentMask}
                name='amount-porcent-deposit-invoices'
                id='amount-porcent-deposit-invoices'
                placeholder='%'
              />}

          </Grid>


          <Grid item>
            <TextFieldLabel
              label='From Territory:'
              value={values.from}
              disabled
            />
          </Grid>
          <Grid item>
            <TextFieldLabel
              label='Recipient (Payer): '
              value={values.payer}
              placeholder='Joe Smith <joe.smith@fake.com>'
              disabled
            />
          </Grid>
          <Grid item>
            <TextFieldLabel
              label='CC Emails: '
              type='email'
              value={values.ccEmails || ''}
              onChange={(e) => {
                setValues({ ...values, ccEmails: e.target.value })
                onItemValueChanged(e.target.value, 'ccEmails')
              }}
              placeholder='Separate email addresses by comma'
            />
          </Grid>
          <Grid item>
            <TextFieldLabel
              label='Subject:'
              value={values.subject}
              onChange={(e) => {
                setValues({ ...values, subject: e.target.value })
                onItemValueChanged(e.target.value, 'subject')
              }}
            />
          </Grid>

          <Grid item>
            <Typography variant='h6Bold' color='var(--gray700)'>Attach File:</Typography>
            <UploadDocuments
              error={false}
              file={file ?? null}
              dropzoneText='Click here to upload your file'
              onChange={!uploadingFile ? handleUploadFile : () => { }}
            />
          </Grid>
        </Grid>
      </Grid>

    </>
  )
}

export default DepositInfo