
const RED = '#D9534F'
const YELLOW = '#F0AD4E'
const GREEN = '#5CB85C'
const BLUE = '#053DAC'
const BLACK = '#000000'

export const getTradeColor = (level: number): string => {
    switch (level) {
        case 0:
            return BLACK
        case 1:
            return RED
        case 2:
            return YELLOW
        case 3:
            return GREEN
        case 4:
            return BLUE
        default:
            break;
    }
    return BLACK;
}