import { FC } from 'react'
import { icons } from 'src/assets'
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Typography } from 'src/components/UI'
import { OPTIONS } from '../..'
import useStyles from './styles'
import { QAType } from './types'

const QAs: {
  title: string,
  questions: QAType[]
}[] =
  [
    {
      title: "How to use the Estimate",
      questions: [
        {
          question: 'What can I do with this estimate?',
          answer:
            'Our online shopping cart experience allows you to add items to your shopping cart, check out, and get started with the scheduling of your project. From your estimate, you are also able to request additional items to be estimated, as well as request an in-home consultation. If you  have any questions or would like to speak with a BOSSCAT team member, click on the Contact us” button.',
        },
        {
          question:
            `Some of my items are in the “Need More Information” section. What does this mean?`,
          answer:
            `For certain items, additional information is needed to provide accurate pricing. If you are
      interested in a consultation to receive estimates on these items, click on the “Request In-Home
      Consultation” button.`,
        },
        {
          question: 'Can I share this estimate with others?',
          answer:
            `Our convenient “Share Estimate” feature allows you to provide others access to your online
      estimate. Once you enter the information in the “Share Estimate” screen, we will send an email
      to your contact with instructions on how to view the estimate.`,
        },
      ]
    },
    {
      title: "About the Project",
      questions: [
        {
          question: 'How soon can you get started on my project?',
          answer:
            `While we strive to get started quickly, scheduling lead times vary based on size and scope. For
            projects with a tight deadline, we do our best to accommodate your timing. For market and
            project-specific timelines, please contact our Customer Care team for assistance at 1-877-4-
            BOSSCAT.`,
        },
        {
          question:
            `Who will be responsible for completing my project?`,
          answer:
            `Depending on the size, scope, and nature of your project, either a BOSSCAT team member or a
            vetted, professional BOSSCAT Pro will work on your project. Additionally, your dedicated
            BOSSCAT Project Coordinator will oversee the entire process, and every job will receive on-the-
            ground supervision by a BOSSCAT Project Manager.`,
        },
        {
          question: 'Is there a quality guarantee?',
          answer:
            `Absolutely! We provide a comprehensive one-year warranty on all labor, ensuring your
            satisfaction and peace of mind.`,
        },
      ]
    },
    {
      title: "Pricing and Payment",
      questions: [
        {
          question: 'Do you price match quotes from other contractors?',
          answer:
            `Indeed, we do! In fact, we go a step further and will beat it by up to 10%, to a maximum of
            $2,500 below the competing price.`,
        },
        {
          question:
            `Will a deposit be required?`,
          answer:
            `For projects totaling less than $10,000, we do not require any deposits. You can proceed with
            confidence knowing that there are no upfront costs.`,
        },
        {
          question: 'Can I choose a "pay at close" option?',
          answer:
            `Certainly! We offer a convenient "pay at close" option for projects completed either before or
            during the home sale. This flexibility aims to accommodate your specific needs. If you are
            interested in paying at the time of closing, please contact us at 1-877-4-BOSSCAT.`,
        },
      ]
    }
  ]

interface FAQProps {
  setActiveOption: (option: OPTIONS) => void
}

const FAQ: FC<FAQProps> = ({ setActiveOption }) => {
  const styles = useStyles()

  return (
    <Box width={"100%"} display="flex" flexDirection="column" className={styles.root}>
      <Typography variant="h5" className={styles.text}>
        Check Out our FAQs
      </Typography>
      {QAs.map(({ title, questions }, index) => (
        <>
          <Typography className={styles.faqTitle}>
            {title}
          </Typography>

          {questions.map(({ question, answer, bullets }, index) => (
            <Accordion className={styles.accordion}>
              <AccordionSummary
                expandIcon={<icons.Add style={{ width: '1rem', fill: 'black' }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={styles.question}>{question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  {answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </>
      ))}
      <Button
        onClick={() => setActiveOption(OPTIONS.initial)}
        className={styles.button}
        startIcon={<icons.ArrowBack />}
      >
        Back
      </Button>
    </Box>
  )
}
export default FAQ
