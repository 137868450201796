import { Box, Typography } from '@mui/material'
import { TabElementProps } from './types'

import styles from '../styles.module.scss'

const TabElement = ({ label, icon }: TabElementProps) => {
  return (
    <Box display="flex" gap={0.5} alignItems='center'>
      {icon}
      <Typography className={styles.Company__label}>
        {label}
      </Typography>
    </Box>
  )
}

export default TabElement
