import { FC, useEffect, useState } from "react";
import Table from "src/components/UI/CustomUI/organisms/Table";
import { PrivateLoader } from "src/components/templates";
import headers from "./headers";
import { Box, Button, Typography } from "@mui/material";
import { icons } from "src/assets";
import { useDispatch, useSelector } from "react-redux";
import { getEmployee, getTerritories } from "src/ducks/selectors";
import { territoriesActions } from "src/ducks/actions";
import AddTerritoryModal from "./AddTerritoryModal";
import { useNavigate } from "react-router-dom";
import { EMPLOYEE_ROLE_TYPE } from "src/helpers";

const Territories: FC = () => {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const territories = useSelector(getTerritories)
    const [open, setOpen] = useState(false)
    const navigate = useNavigate()
    const currentUser = useSelector(getEmployee)
    const isSuperAdmin = currentUser.roles.find(role => role.roleType === EMPLOYEE_ROLE_TYPE.SUPER_ADMIN)


    useEffect(() => {
        setIsLoading(true)
        dispatch(territoriesActions.fetchTerritories((succ: boolean) => setIsLoading(false)))
    }, [dispatch])

    const handleNew = () => setOpen(true)

    const handleClickRow = (row: any, e: React.MouseEvent) => {
        navigate(`${row.id}`)
    }

    return (
        <PrivateLoader loading={isLoading}>
            <Box mb={4}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Typography variant="h1">Territory Management</Typography>
                    <Button variant="contained" disabled={!isSuperAdmin} startIcon={<icons.Add />} onClick={handleNew}>New Territory</Button>
                </Box>

                <Table headers={headers} hasPagination={false}>
                    {territories.map((territory) => ({
                        ...territory,
                        disabled: false,
                        action: handleClickRow
                    }))}
                </Table>
            </Box>
            <AddTerritoryModal open={open} setOpen={setOpen} />
        </PrivateLoader>
    )
};

export default Territories;