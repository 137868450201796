import { useEffect, useState } from 'react'
import { useMediaQuery, useTheme } from '../components/UI'

const useCurrentBreakpoint = (): string => {
  const [currentBreakpoint, setCurrentBreakpoint] = useState<string>('')
  const theme = useTheme()
  // ? flags
  const isExtraSmall = useMediaQuery(theme.breakpoints.up('xs'))
  const isSmall = useMediaQuery(theme.breakpoints.up('sm'))
  const isMedium = useMediaQuery(theme.breakpoints.up('md'))
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'))
  const isExtraLarge = useMediaQuery(theme.breakpoints.up('xl'))

  useEffect(() => {
    if (isExtraSmall) setCurrentBreakpoint('xs')
    if (isSmall) setCurrentBreakpoint('sm')
    if (isMedium) setCurrentBreakpoint('md')
    if (isLarge) setCurrentBreakpoint('lg')
    if (isExtraLarge) setCurrentBreakpoint('xl')
  }, [
    isExtraSmall,
    isSmall,
    isMedium,
    isLarge,
    isExtraLarge
  ])

  return currentBreakpoint // ? return current breakpoint
}

export { useCurrentBreakpoint }
