import { createStyles, makeStyles } from '@mui/styles'
import { Theme as ThemeMUI } from '@mui/system'

export default makeStyles((theme: ThemeMUI) =>
  createStyles({
    root: {
      padding: '8px 0 !important'
    },
    text: {
      padding: '0 !important',
      fontFamily: 'NextArtHeavy !important',
      textAlign: 'left'
    },
    faqTitle: {
      textAlign: 'left',
      fontSize: '18px !important',
      fontFamily: 'LatoBold !important',
      fontWeight: '700 !important',
      marginTop: '20px !important'
    },
    question: {
      fontSize: '16px !important',
      fontFamily: 'LatoBold !important',
      lineHeight: '30px !important',
      wordBreak: 'break-word',
      textAlign: 'justify',
      [theme.breakpoints.down('md')]: {
        textAlign: "left"
      },
    },
    accordion: {
      boxShadow: '0px 1px 1px rgba(26, 42, 85, 0.24), 0px 0px 1px rgba(26, 42, 85, 0.31) !important',
      borderRadius: '8px !important',
      border: '2px solid #F5F6F7 !important',
      margin: '8px 0 !important',
      padding: '2px 8px !important',
      '&:before': {
        content: 'none !important'
      },
      '@global': {
        '.MuiAccordionSummary-root': {
          height: '100% !important'
        },
        '.MuiAccordionDetails-root .MuiTypography-root': {
          textAlign: 'left'
        }
      }
    },
    button: {
      width: 'fit-content !important',
      height: '45px !important',
      textTransform: 'none',
      color: 'var(--bosscat-blue-600) !important',
      backgroundColor: 'var(--background-color-secondary) !important',
      boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important",
      borderRadius: '8px !important',
      border: '0px solid !important',
      padding: '0 1rem !important',
      [theme.breakpoints.down('sm')]: {
        width: '165px !important',
        minHeight: '50px !important',
        fontFamily: 'LatoNormal !important',
        fontWeight: 'normal !important',
        fontSize: '16px !important'
      }
    }
  })
)
