import { SearchParams } from '../searches/types'
import { JobsActions, jobsTypes, NavBar } from './types'

const actions = {
  fetchJobs: (
    payload: {
      page?: number,
      size?: number,
      searchParams?: Partial<SearchParams>,
    },
    callback?: (succ: boolean) => void): JobsActions => ({
      type: jobsTypes.FETCH_JOBS_NAVBAR,
      payload,
      callback
    }),
  setJobs: (payload: NavBar): JobsActions => ({
    type: jobsTypes.SET_JOBS_NAVBAR,
    payload
  })
}

export default actions
