import { FC } from 'react'
import { ColumnHeaderProps } from './types'
import { Box, Grid, Typography } from '@mui/material'

import useStyles from './styles'

const ColumnHeader: FC<ColumnHeaderProps> = ({
  count,
  status,
  backgroundColor,
  dueToday,
  price,
}) => {
  const styles = useStyles()

  return (
    <Box className={styles.container} bgcolor={backgroundColor}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography className={styles.count}>{count}</Typography>
        </Grid>
        {price && (
          <Grid item>
            <Box className={styles.headerContainer}>
              <Typography className={styles.price}>{`$${price.toLocaleString()}`}</Typography>
            </Box>
          </Grid>
        )}
      </Grid>
      <Box>
        {dueToday && (
          <Typography
            className={styles.due}
          >{`Due Today (${dueToday})`}</Typography>
        )}
        <Typography className={styles.status}>{status}</Typography>
      </Box>
    </Box>
  )
}

export default ColumnHeader
