import { formatParams } from 'src/helpers'
import axios from '../axios'
import { ApiController } from './types'

const base = '/notifications'

const apiController: ApiController = {
  getNotifications: async ({ start, limit, searchParams }) => {
    const params = formatParams(searchParams)
    return await axios
      .get(`${base}?${params}`, {
        params: {
          start,
          limit
        }
      })
      .then(response => response.data)
  },
  updateNotification: async (notificationId: string, request) => {
    return await axios
      .patch(`${base}/${notificationId}`, request)
      .then(response => response.data)
  },
}

export default apiController
