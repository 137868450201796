import { authTypes } from '../auth/types'
import { Workflow } from '../types'
import { WorkflowsActions, workflowsTypes, WorkflowsType } from './types'

const initialState: WorkflowsType = {
  start: '',
  limit: '',
  total: '',
  items: []
}

const reducer = (state = initialState, action: WorkflowsActions): WorkflowsType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case workflowsTypes.SET_WORKFLOWS_INSTANCE: {
      return payload as Workflow
    }

    default:
      return state
  }
}

export default reducer
