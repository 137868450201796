import { FC } from 'react'
import { ModalConfirmProps } from './types'
import Modal from 'src/components/UI/CustomUI/molecules/Modal'
import { Button, Grid, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { getEmployee, getEstimate } from 'src/ducks/selectors'
import { ESTIMATE_STATUS } from 'src/helpers'
import { estimateActions } from 'src/ducks/actions'
import { UpdateRequest } from '~api/types'

const ModalAssignToMe: FC<ModalConfirmProps> = ({
  open,
  contentText,
  setOpen,
}) => {
  const dispatch = useDispatch()

  const employee = useSelector(getEmployee)
  const estimate = useSelector(getEstimate())

  const handleAssign = (): void => {
    const request: UpdateRequest = [
      { op: 'add', path: '/estimatorId', value: employee.id },
    ]

    dispatch(estimateActions.updateEstimateByPath(request, (succ) => {
      if (succ) {
        dispatch(estimateActions.fetchEstimate(estimate?.id || '', (succ) => {}))
      }
    }))

    setOpen(false)
  }

  return (
    <Modal open={open} setOpen={setOpen} title="Are you sure?">
      <Grid container flexDirection="column" style={{ gap: '12px' }}>
        <Typography variant="h5">Begin composing estimate?</Typography>
        <Grid container justifyContent="flex-end" style={{ gap: '8px' }}>
          <Button variant="containedBig" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button variant="containedBig" onClick={handleAssign}>
            Confirm
          </Button>
        </Grid>
      </Grid>
    </Modal>
  )
}

export default ModalAssignToMe
