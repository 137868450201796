import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) =>
    createStyles({
        root: {
            background: 'linear-gradient(90deg, rgba(19,49,99,1) 0%, rgba(9,25,49,1) 100%) !important',
            padding: '1rem !important',
            borderRadius: '8px !important',
            // marginTop: '1rem',
            position: 'relative',
            overflow: 'hidden',
            [theme.breakpoints.down('sm')]: {
                display: 'flex !important',
                justifyContent: 'space-between'
            }
        },
        title: {
            fontFamily: "NextArtHeavy !important",
            color: 'white !important',
            [theme.breakpoints.down('sm')]: {
                fontFamily: 'inherit',
                fontWeight: '700 !important',
                textTransform: 'capitalize',
                display: 'flex',
                alignItems: 'center',
                maxWidth: '70% !important'
            }
        },
        vipMedal: {
            position: 'absolute',
            opacity: '.2',
            right: '-1.5rem',
            top: '-0.8rem',
            width: 80
        },
        link: {
            // color: '#FFC531',
            fontWeight: '700 !important',
            textDecoration: 'underline',
            display: 'flex',
            gap: '.3rem',
            alignItems: 'center',
            textTransform: 'inherit',
            height: 'fit-content !important',
            padding: '0.5rem 0rem !important',
        },
        linkIcon: {
            fontSize: '1rem !important'
        }
    })
)
