/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid, Typography } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { PrivateLoader } from 'src/components/templates'
import { SelectInput, TextFieldLabel } from 'src/components/UI'
import Modal from 'src/components/UI/CustomUI/molecules/Modal'
import { useDispatch, useSelector } from 'react-redux'
import {
  estimateActions,
  // filesActions,
  inspectionCompaniesActions,
  inspectionCompanyActions,
  inspectorsActions,
} from 'src/ducks/actions'
import {
  // FILE_TYPE,
  isEmpty,
  // ParseFilesUrl,
  PHONE_MASK_INPUT,
  validateEmail,
} from 'src/helpers'
import {
  getEstimate,
  getInspectionCompaniesForSelect,
  getInspectorsCompanyForSelect,
  getInspectorsForSelect,
} from 'src/ducks/selectors'
// import UploadDocuments from 'src/components/UI/CustomUI/molecules/UploadDocument'
// import env from '@beam-australia/react-env'
// import moment from 'moment'
import { InspectionCompany, InspectorCompany } from 'src/ducks/types'
import SelectAddress from 'src/components/UI/CustomUI/organisms/SelectAddress'
import { Address } from 'src/components/UI/CustomUI/organisms/SelectAddress/types'
import styles from './styles.module.scss'
import { UpdateRequest } from '~api/types'

interface InspectionDataModalProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  onClose: () => void
}

// const FILES_URL = env('FILES_URL') ?? ''

const InspectionDataModal: FC<InspectionDataModalProps> = ({
  open,
  setOpen,
  onClose,
}) => {
  const dispatch = useDispatch()
  const estimate = useSelector(getEstimate())
  /* const inspectionFile = estimate?.properties.files?.find(
    (file) => file.fileType === FILE_TYPE.INSPECTION_PDF
  ) */
  const [companySelected, setCompanySelected] =
    useState<InspectionCompany | null>(
      estimate?.properties.estimateInspection?.inspector?.inspectionCompany ||
        null
    )
  const [inspectorSelected, setInspectorSelected] =
    useState<Partial<InspectorCompany> | null>(
      estimate?.properties.estimateInspection?.inspector || null
    )
  const isEdit = !!estimate?.properties.estimateInspection?.inspector?.id
  // const [file, setFile] = useState<any>(inspectionFile ?? null)
  const [uploadingFile, setUploadingFile] = useState(false)
  const [loading, setLoading] = useState(true)

  const inspectionCompaniesForSelect = useSelector(
    getInspectionCompaniesForSelect
  )
  const allInspectors = useSelector(getInspectorsForSelect)
  const inspectorsFromCompany = useSelector(getInspectorsCompanyForSelect)

  const inspectorsCompaniesForSelect = companySelected?.id
    ? inspectorsFromCompany
    : allInspectors

  const isValid =
    !isEmpty(companySelected?.name) &&
    !isEmpty(companySelected?.address) &&
    !isEmpty(inspectorSelected?.name) &&
    !isEmpty(inspectorSelected?.phone) &&
    !isEmpty(inspectorSelected?.email) &&
    validateEmail(inspectorSelected?.email || '') &&
    !uploadingFile

  const fetchInspectionCompanies = (
    start: number,
    limit: number,
    search: string
  ) => {
    setLoading(true)
    dispatch(
      inspectionCompaniesActions.fetchInspectionCompanies(
        { searchParams: { search }, page: start, size: limit },
        (succ) => {
          setLoading(false)
        }
      )
    )
  }

  const fetchInspectors = () => {
    setLoading(true)
    dispatch(
      inspectorsActions.fetchInspectors((succ) => {
        setLoading(false)
      })
    )
  }

  const fetchInspectionCompany = (id: any) => {
    setLoading(true)
    id &&
      dispatch(
        inspectionCompanyActions.fetchInspectionCompany(id, (succ) => {
          setLoading(false)
        })
      )
  }

  const handleSelectCompany = (newSelected: any) => {
    const { key: newCompany } = newSelected
    inspectorSelected?.id && setInspectorSelected(null)

    if (!newSelected.key) {
      setCompanySelected({ name: '', address: {} })
      fetchInspectors()
      return
    } else {
      setCompanySelected(newCompany)
      newCompany.id && fetchInspectionCompany(newCompany.id)
    }
  }

  const handleSelectInspector = (newSelected: any) => {
    if (!newSelected.key) {
      setInspectorSelected(null)
      // fetchInspectors()
      return
    } else {
      const { key: inspector } = newSelected
      setInspectorSelected(inspector)

      if (inspector.id) {
        fetchInspectionCompany(inspector.inspectionCompany?.id)
        setCompanySelected(inspector.inspectionCompany)
        fetchInspectors()
      }
    }
  }

 /*  const handleUploadFile = (file: null | File, callback: () => void) => {
    setUploadingFile(true)

    if (file) {
      dispatch(
        filesActions.uploadFile(file, (uploadSucc, fileName) => {
          if (uploadSucc && fileName) {
            const fileToSave = {
              name: file.name,
              fileName: file.name,
              fileUrl: ParseFilesUrl({
                baseUrl: FILES_URL,
                avatar: fileName ?? '',
              }),
              description: 'Estimate Inspection Data' ?? '',
              fileType: FILE_TYPE.INSPECTION_PDF,
              uploadedOn: moment().unix(),
            }
            callback()
            setFile(fileToSave)
          }
          setUploadingFile(false)
        })
      )
    } else {
      setFile(null)
    }
  } */

  useEffect(() => {
    fetchInspectionCompanies(0, 10000, '')
    fetchInspectors()
    dispatch(inspectionCompanyActions.setInspectionCompany(null))
    if (isEdit) {
      fetchInspectionCompany(companySelected?.id)
    }
  }, [])

  const patchInspectionDataToEstimate = () => {
    const payload: UpdateRequest = [
      {
        op: isEdit ? 'replace' : 'add',
        path: '/properties/estimateInspection',
        value: {
          inspector: {
            name: inspectorSelected?.name,
            email: inspectorSelected?.email,
            phone: inspectorSelected?.phone,
            inspectionCompany: {
              name: companySelected?.name || '',
              address: companySelected?.address || {},
            },
          },
        },
      },
    ]
/*     if (file) {
      payload.push({
        op: 'add',
        path: '/properties/files/-',
        value: file,
      })
    } */
    dispatch(
      estimateActions.updateEstimateByPath(payload, (succ) => {
        setLoading(false)

        if (succ) {
          // fetchInspectors()
          setOpen(false)
        }
      })
    )
  }

  const handleAddInspection = () => {
    setLoading(true)

    if (inspectorSelected?.id) {
      // If inspector already exists we patch the estimate with the inspection data
      patchInspectionDataToEstimate()
    } else {
      // If inspector does not exist we create the inspector and the inspection company
      dispatch(
        inspectorsActions.createInspector(
          {
            name: inspectorSelected?.name,
            email: inspectorSelected?.email,
            phone: inspectorSelected?.phone,
            inspectionCompany: {
              name: companySelected?.name || '',
              address: companySelected?.address || {},
            },
          },
          (succ) => {
            if (succ) {
              // patch the estimate with the inspection data
              patchInspectionDataToEstimate()
            } else {
              setLoading(false)
            }
          }
        )
      )
    }
  }

  return (
    <Modal open={open} setOpen={setOpen} className={styles.modal}>
      <PrivateLoader loading={loading}>
        <Grid
          container
          direction="column"
          spacing={2}
          style={{ padding: '.8rem 0 0' }}
        >
          <Grid item>
            <Typography variant="h5" style={{ fontFamily: 'Nouvelle' }}>
              Inspector Data
            </Typography>
          </Grid>
         {/*  <Grid item>
            <UploadDocuments
              file={file}
              onChange={handleUploadFile}
              dropzoneText="Click here to upload your file"
            />
          </Grid> */}
          <Grid item>
            <SelectInput
              label="Inspection Company"
              value={companySelected?.name}
              onInputChange={(newSelected, reason) => {
                if (reason === 'input') {
                  inspectorSelected?.id && setInspectorSelected(null)

                  if (companySelected?.id) {
                    setCompanySelected({
                      name: newSelected,
                    })
                  } else {
                    setCompanySelected({
                      ...companySelected,
                      name: newSelected,
                    })
                  }
                }
              }}
              onChange={handleSelectCompany}
              options={inspectionCompaniesForSelect.map((option) => option)}
              onFocus={(event) => {
                event.target.select()
              }}
              placeholder="e.g.: Pillar to Post, 123 Realtors..."
              size="small"
              labelVariant="h6Medium"
              freeSolo
              className={styles.input}
            />
          </Grid>
          <Grid item>
            <SelectInput
              label="Inspector Name"
              value={inspectorSelected?.name || ''}
              onInputChange={(newSelected, reason) => {
                if (reason === 'input') {
                  if (inspectorSelected?.id) {
                    setInspectorSelected({
                      name: newSelected,
                    })
                  } else {
                    setInspectorSelected({
                      ...inspectorSelected,
                      name: newSelected,
                    })
                  }
                }
              }}
              onChange={handleSelectInspector}
              options={inspectorsCompaniesForSelect?.map((option) => option)}
              onFocus={(event) => {
                event.target.select()
              }}
              size="small"
              labelVariant="h6Medium"
              freeSolo
              placeholder="Enter Inspector Name"
              className={styles.input}
            />
          </Grid>
          <Grid item>
            <SelectAddress
              placeholder="Enter Address"
              showWarnings={false}
              savedAddress={companySelected?.address || null}
              onChange={(newAddress: Address) => {
                setCompanySelected({
                  ...(companySelected as InspectionCompany),
                  address: newAddress,
                })
              }}
              initAdd={companySelected?.address?.fullAddress}
              showUnit={false}
              showMap={false}
              inputClassName={styles.selectAddress}
              title={
                <Typography className={styles.input} variant="h6Medium">
                  Address
                </Typography>
              }
              disabled={!isEmpty(companySelected?.id)}
            />
          </Grid>
          <Grid item>
            <TextFieldLabel
              name="add-job-contact-modal-ph"
              id="add-job-contact-modal-ph"
              labelVariant="h6"
              label="Phone:"
              value={inspectorSelected?.phone || ''}
              placeholder="876-543-1223"
              mask={PHONE_MASK_INPUT}
              disabled={!isEmpty(inspectorSelected?.id)}
              onChange={(event) => {
                setInspectorSelected({
                  ...(inspectorSelected as InspectorCompany),
                  phone: event.target.value,
                })
              }}
              className={styles.input}
            />
          </Grid>
          <Grid item>
            <TextFieldLabel
              label="Email:"
              type="email"
              name="login-email"
              disabled={!isEmpty(inspectorSelected?.id)}
              value={inspectorSelected?.email || ''}
              color="inputColors"
              onChange={(event) =>
                setInspectorSelected({
                  ...(inspectorSelected as InspectorCompany),
                  email: event.target.value,
                })
              }
              placeholder="mail@email.com"
              sx={{
                marginBottom: '2.125rem',
                height: { sm: '54px' },
              }}
              className={styles.input}
            />
          </Grid>
          <Grid item container spacing={1}>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="containedBig"
                style={{
                  color: 'var(--blue700)',
                  height: '100%',
                  fontSize: 'var(--font-S)',
                  background: '#FAFBFF',
                  border: 'none',
                }}
                onClick={onClose}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="containedBig"
                onClick={handleAddInspection}
                disabled={!isValid}
              >
                Add Inspector Data
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </PrivateLoader>
    </Modal>
  )
}

export default InspectionDataModal
