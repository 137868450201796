import env from '@beam-australia/react-env'
import { useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TabNavigation from 'src/components/UI/CustomUI/organisms/TabNavigation'
import { FILE_TYPE, ParseFilesUrl, imageDocExcelTypes, MAX_FILES } from 'src/helpers'
import { PrivateLoader } from 'src/components/templates'
import All from './Tabs/All'
import Customers from './Tabs/Customers'
import Pros from './Tabs/Pros'
import { Button, Box, Grid, Typography } from 'src/components/UI'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import { filesActions, jobActions } from 'src/ducks/actions'
import { JobItem, PunchlistFile } from 'src/ducks/types'
import { getJob, getEmployeeId } from 'src/ducks/selectors'
import styles from './styles.module.scss'
import moment from 'moment'
import { useIsXlScreen } from 'src/hooks'
import MaterialInvoicesTab from './Tabs/MaterialInvoices'
import { icons } from '../../../../../../assets'
import Modal from 'src/components/UI/CustomUI/molecules/Modal'
import Add from '../../ControlButtons/Modals/ModalMaterialInvoices/Add'
import { toast } from 'react-toastify'
import { UpdateRequest } from '~api/types'
import UploadDocumentsModal from './UploadDocumentsModal'

const Attachments = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const inputRef = useRef<any>(null)
  const job = useSelector(getJob())
  const userId = useSelector(getEmployeeId)
  const { properties } = job as JobItem
  const FILES_URL = env('FILES_URL') ?? ''
  const [xlScreen] = useIsXlScreen()

  const [showModal, setShowModal] = useState(false)

  const handleUpload = (event: any) => {
    if (event.target.files.length > MAX_FILES) {
      toast.error(`Maximum allowed number of files exceeded. Only ${MAX_FILES} allowed`)
    }
    if (event.target.files && event.target.files.length > 0 && event.target.files.length <= MAX_FILES) {
      setLoading(true)
      const filesToUpload = event.target.files
      dispatch(
        filesActions.uploadFiles(filesToUpload, (succ, fileNameList) => {
          // Expecting BE returns files in the same order
          if (succ && fileNameList?.length === event.target.files.length) {
            const filesAdded: UpdateRequest = []
            fileNameList?.forEach((name, idx) => {
              const userFileName = filesToUpload[idx]?.name ?? ''
              const fileType = {
                name: userFileName ?? '',
                description: userFileName ?? '',
                fileType: FILE_TYPE.INSPECTION_REPORT,
                fileUrl: ParseFilesUrl({
                  baseUrl: FILES_URL,
                  avatar: name ?? ''
                }),
                uploadedOn: moment().unix(),
                uploadedBy: userId
              } as PunchlistFile
              filesAdded.push({ op: 'add', path: '/properties/files/-', value: fileType })
            })
            dispatch(
              jobActions.updateJobByPath(filesAdded,
                (succ: boolean) => {
                  setLoading(false)
                }
              )
            )
          }
        }
        )
      )
    }
  }

  const UploadAction = () => {
    return (
      <Grid display="flex" gap="1rem">
        <Box>
          <input
            type='file'
            accept={imageDocExcelTypes}
            onChange={handleUpload}
            ref={inputRef}
            className={styles.hiddenInput}
            multiple={true}
          />
          <Button variant='containedLight' onClick={() => setShowModal(true)}>
            Upload Documents <Icon name='CloudUpload' sx={{ marginLeft: '5px' }} />
          </Button>
        </Box>
        <Button
          variant='outlined'
          startIcon={<icons.Add style={{ width: '12px', height: '12px', padding: '0px !important' }} />}
          onClick={() => setModalOpen(true)}
        >
          Material Invoice
        </Button>
      </Grid>

    )
  }

  const Tabs = [
    {
      label: 'All',
      content: <All />,
      actionButton: UploadAction()
    },
    {
      label: 'Customers',
      content: <Customers />,
      actionButton: UploadAction()
    },
    {
      label: 'Pros',
      content: <Pros />,
      actionButton: UploadAction()
    },
    {
      label: 'Material Invoices',
      content: <MaterialInvoicesTab hasPagination title="MATERIAL INVOICES" />,
      actionButton: UploadAction()
    }
  ]

  return (
    <>
      <PrivateLoader loading={loading}>
        <Modal
          open={modalOpen}
          setOpen={setModalOpen}
          size="lg"
          title={<Typography
            sx={{ padding: '12px' }} variant='h5'>Add Invoice </Typography>}
        >
          <Add
            onClose={() => setModalOpen(false)}
          // setMaterialInvoicesTemp={setMaterialInvoicesTemp}
          />
        </Modal>
        <TabNavigation
          tabs={Tabs}
          variant='contained'
          tabWidth='fit-content'
          tabAlign='flex-end'
          defaultActive={0}
          margin={xlScreen ? '1rem 0' : '120px 0 0.5rem 0'}
        />
        <UploadDocumentsModal
          open={showModal}
          setOpen={setShowModal}
          handleClose={() => setShowModal(false)}
        />
      </PrivateLoader>
    </>
  )
}

export default Attachments
