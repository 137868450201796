import { formatParams } from 'src/helpers'
import axios from '../axios'
import { ApiController } from './types'

const base = '/jobs'

const apiController: ApiController = {
  getJobs: async ({ start, limit, searchParams }) => {
    const params = formatParams(searchParams)
    return await axios
      .get(`${base}?${params}`, {
        params: {
          start,
          limit,
          sortBy: 'createdOn',
          sortOrder: 'DESC'
        }
      })
      .then(response => response.data)
  },

  getJobById: async (jobId: string) =>
    await axios
      .get(`${base}/${jobId}`)
      .then(response => response.data),

  getJobsCounts: async ({ searchParams }) => {
    const params = formatParams(searchParams)
    return await axios

      .get(`${base}/counts?${params}`)
      .then(response => response.data)
  },

  createJob: async (request) => {
    return await axios
      .post(`${base}`, request)
      .then(response => response.data)
  },

  updateJob: async (jobId: string, request) => {
    return await axios
      .patch(`${base}/${jobId}`, request)
      .then(response => response.data)
  },

  deleteJobById: async (jobId: string) => {
    return await axios
      .delete(`${base}/${jobId}`)
      .then(response => response.data)
  },

  updateJobContact: async (jobId: string, request) => {
    return await axios
      .patch(`${base}/${jobId}`, request)
      .then(response => response.data)
  },

  cloneJob: async (jobId: string) => {
    return await axios
      .post(`${base}/${jobId}/clone`)
      .then(response => response.data)
  },

  removeJobContact: async (jobId: string, request) => {
    return await axios
      .patch(`${base}/${jobId}`, request)
      .then(response => response.data)
  },

  requestCSV: async (searchParams) => {
    const params = formatParams(searchParams)
    return await axios
      .post(`${base}/csv?${params}&sortBy=createdOn&sortOrder=DESC`)
      .then(response => {
        return response.data
      })
  },

  getCSV: async (csvId) => {
    return await axios
      .get(`${base}/csv/${csvId}`)
      .then(response => {
        return response
      })
  },

  changeOrderApproval: async (jobId: string, request) => {
    return await axios
      .post(`${base}/${jobId}/change-order`, request)
      .then(response => response.data)
  }
}

export default apiController
