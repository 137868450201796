import { SelectInputOption } from 'src/components/UI/CustomUI/atoms/SelectInput/types'

export const licenseCheckFilters: SelectInputOption[] = [
  {
    key: 'draft',
    label: 'Draft',
    value: 'DRAFT',
    hide: false,
  },
  {
    key: 'approved',
    label: 'Approved',
    value: 'APPROVED',
    hide: false,
  },
  {
    key: 'denied',
    label: 'Denied',
    value: 'DENIED',
    hide: false,
  },
  {
    key: 'divider',
    label: '',
    isSeparator: true,
  },
  {
    key: 'COI',
    label: 'COI',
    value: 'CERTIFICATE_OF_INSURANCE',
    hide: false,
  },
  {
    key: 'wc',
    label: 'WC',
    value: 'WORKERS_COMPENSATION',
    hide: false,
  },
  {
    key: 'other',
    label: 'Other',
    value: 'OTHER',
    hide: false,
  },
]

export const insuranceOptions: SelectInputOption[] = [
  {
    key: 'draft',
    label: 'Draft',
    value: 'DRAFT',
    hide: false,
  },
  {
    key: 'approved',
    label: 'Approved',
    value: 'APPROVED',
    hide: false,
  },
  {
    key: 'denied',
    label: 'Denied',
    value: 'DENIED',
    hide: false,
  },
  {
    key: 'divider',
    label: '',
    isSeparator: true,
  },
  {
    key: 'coi',
    label: 'COI',
    value: 'CERTIFICATE_OF_INSURANCE',
    hide: false,
  },
  {
    key: 'wc',
    label: 'WC',
    value: 'WORKERS_COMPENSATION',
    hide: false,
  },
  {
    key: 'other',
    label: 'Other',
    value: 'OTHER',
    hide: false,
  },
]
