import { Button } from '@mui/material'
import { FC, useState } from 'react'
import { icons } from 'src/assets'
import useStyles from './styles'
import { CounterProps } from './types'

const Counter: FC<CounterProps> = ({
  initialValue,
  onDecrease = () => { },
  onIncrease = () => { },
  color = 'primary'
}) => {
  const classes = useStyles()
  const [counter, setCounter] = useState<number>(initialValue)

  const increaseCounter = () => {
    setCounter(counter + 1)
    onIncrease(counter + 1)
  }

  const decreaseCounter = () => {
    setCounter(counter - 1)
    onDecrease(counter - 1)
  }

  return (
    <div className={classes.root}>
      {/*   <ActionButton
        backgroundColor={color}
        icon='remove'
        disabled={counter === 1}
        onClick={decreaseCounter}
      /> */}
      <Button
        onClick={decreaseCounter}
        disabled={counter === 1}
        className={classes.button}
      >
        <icons.Remove />
      </Button>
      <div className={classes.counterContainer}>
        <h1 className={classes.counter}>{counter}</h1>
      </div>
      <Button
        onClick={increaseCounter}
        className={classes.button}
      >
        <icons.Add />
      </Button>
      {/*  <ActionButton
        backgroundColor={color}
        icon='add'
        onClick={increaseCounter}
      /> */}
    </div>
  )
}

export default Counter
