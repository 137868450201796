/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo } from '../../helpers'
import { Punchlist } from '../../api'
import { workflowsTypes, FetchWorkflowsAction, TriggerWorkflowAction } from './types'
import { workflowsActions } from '../actions'

export function* fetchWorkflowsInstance({ payload, callback }: FetchWorkflowsAction): SagaIterator {
  let success = false
  try {
    const params = payload.params

    const workflows = yield call(Punchlist.workflows.getWorkflows, { params })

    if (workflows) {
      yield put(workflowsActions.setWorkflows(workflows))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the workflows'))
    if (callback) yield call(callback, false)
  }
}

export function* triggerWorkflowAction({ payload, callback }: TriggerWorkflowAction): SagaIterator {
  try {
    const workflows = yield call(Punchlist.workflows.triggerWorkflowAction, payload)
    if (workflows) {
      yield put(workflowsActions.setWorkflows(workflows))
    }
    if (callback) yield call(callback, true)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('create the order'))
    if (callback) yield call(callback, false)
  }
}

export function* skipWorkflowAction({ payload, callback }: TriggerWorkflowAction): SagaIterator {
  try {

    let workflows
    if (payload.orderId) {
      workflows = yield call(Punchlist.workflows.skipOrderWorkflowAction, payload.orderId)
    } else {
      workflows = yield call(Punchlist.workflows.skipJobWorkflowAction, payload.jobId)
    }

    if (workflows) {
      yield put(workflowsActions.setWorkflows(workflows))
    }

    if (callback) yield call(callback, true)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('create the order'))
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(workflowsTypes.FETCH_WORKFLOWS_INSTANCE, fetchWorkflowsInstance)
  yield takeLatest(workflowsTypes.TRIGGER_WORKFLOW_ACTION, triggerWorkflowAction)
  yield takeLatest(workflowsTypes.SKIP_WORKFLOW_ACTION, skipWorkflowAction)
}
