import { makeStyles } from '@mui/styles'
import { Theme } from '../../../../UI'

export default makeStyles((theme: Theme) => ({
  input: {
    '@global': {
      '.MuiAutocomplete-endAdornment': {
        transform: 'translateY(0px) !important'
      }
    }
  },
  notchedOutline: {
  },
  popupIcon: {
    color: 'gray !important',
    background: 'transparent !important',
  },
  popupCustomIcon: {
    transform: 'rotate(0deg) !important',
  },
  canRemove: {
    "@global": {
      '.MuiAutocomplete-clearIndicator': {
        display: 'none'
      }
    }
  },
  error: {
    "@global": {
      '.MuiInputBase-root': {
        borderColor: theme.palette.error.main,
      }
    }
  }
}))
