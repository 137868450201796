import { authTypes } from '../auth/types'
import { PCViewActions, PCViewType, pcViewTypes } from './types'

const initialState: PCViewType = {
  pcCSV: '',
}

const reducer = (state = initialState, action: PCViewActions): PCViewType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case pcViewTypes.SET_PC_VIEW_ACTION: {
      return { ...state, ...payload as any}
    }
    case pcViewTypes.SET_PC_CSV: {
      return {
        ...state,
        pcCSV: payload as string
      }
    }

    default:
      return state
  }
}

export default reducer
