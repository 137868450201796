import { FC, useState } from 'react'
import clsx from 'clsx'
import { Box, Button, CircularProgress, Grid, Typography } from '../../..'
import useStyles from './styles'
import { UploadDocumentsProps } from './types'
import Icon from '../../atoms/Icon'
import Dropzone from 'react-dropzone'
import { useDropzone } from 'react-dropzone'

const UploadDocuments: FC<UploadDocumentsProps> = ({
  file,
  dropzoneText = 'Upload File',
  error = false,
  className = '',
  onChange,
  allowedFiles,
  preview = false,
  onRemoveFile = () => { }
}) => {
  const classes = useStyles()

  const [uploading, setUploading] = useState(false)

  const handleAdd = (newFile: any) => {
    setUploading(true)
    if (newFile) {
      onChange(newFile[0], () => {
        if (preview) {
          // Live time for the bucket to be ready to give back de picture
          setTimeout(() => setUploading(false), 2000)
        } else {
          setUploading(false)
        }

      })
    }
  }

  const handleDeleteFile = () => {
    setUploading(true)
    onChange(null, () => setUploading(false))
    setUploading(false)
    onRemoveFile()
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: files => handleAdd(files),
    //multiple: false,
    accept: allowedFiles
  });


  return (
    <Box className={`${clsx(classes.upload, error ? classes.errorColor : '')} ${className}`}>
      {uploading
        ? (
          <Box className={classes.loading}>
            <CircularProgress />
          </Box>) :
        file !== null
          ? (
            preview ? (
              <Box display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection='column'
              >
                <Box flex={1}><img width='100%' src={file.fileUrl} alt="preview" style={{ borderRadius: 8, maxWidth: "250px" }} /></Box>
                <Box flex={1}>
                  <Button variant='containedError' onClick={handleDeleteFile}>Delete</Button>
                </Box>
              </Box>

            ) : (
              <Box className={classes.file}>
                <Box display="flex" gap={1}>
                  <Icon name="Description" />
                  <Typography
                    variant='body1'
                  >
                    {file.name}
                  </Typography>
                </Box>
                <Box className={classes.delete}>
                  <Button
                    onClick={handleDeleteFile}
                    sx={{ padding: '0 !important' }}
                  >
                    <Icon name="Delete" color='var(--red600)' />
                  </Button>
                </Box>
              </Box>)
          )
          :
          (
            <Box className={classes.filesBox} {...getRootProps()} >
              {/*  <Dropzone> */}
              <section >
                <Grid display="flex" justifyContent="center" gap={1}>
                  <input {...getInputProps()} />
                  <Typography margin={'12px'} align='center'>{dropzoneText}</Typography>
                  <Icon name='Upload' />
                </Grid>
              </section>
              {/*  </Dropzone> */}
            </Box>
          )
      }
    </Box>
  )
}

export default UploadDocuments
