import {
  filesTypes,
  FilesActions
} from './types'

const actions = {
  uploadFile: (
    payload: File,
    callback: (succ: boolean, fileName?: string, uploadUrl?: string) => void
  ): FilesActions => ({
    type: filesTypes.UPLOAD_FILE,
    payload,
    callback
  }),
  uploadFiles: (
    payload: File[],
    callback: (succ: boolean, fileNameList?: string[]) => void
  ): FilesActions => ({
    type: filesTypes.UPLOAD_FILES,
    payload,
    callback
  })
}

export default actions
