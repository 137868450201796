import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: '.35rem',
      // width: 138,
      // height: 32,
      [theme.breakpoints.down('sm')]: {
        // marginTop: 12
      }
    },
    counterContainer: {
      display: 'flex',
      width: 'fit-content',
      minWidth: 24,
      height: 24,
      justifyContent: 'center',
      alignItems: 'center',
      borderColor: theme.palette.grey[200],
      border: '2px solid #F5F6F7',
      borderRadius: 8,
      boxShadow:
        '0px 1px 1px rgba(26, 42, 85, 0.24), 0px 0px 1px rgba(26, 42, 85, 0.31)'
    },
    counter: {
      fontSize: 16,
      fontWeight: 400,
      color: theme.palette.grey[700],
      padding: '.5rem'
    },
    button: {
      minHeight: 24,
      height: 24,
      minWidth: 24,
      width: 24,
      backgroundColor: '#EFF0F1',
      borderRadius: '100%',
      boxShadow: '0px 1px 1px rgba(26, 42, 85, 0.24), 0px 0px 1px rgba(26, 42, 85, 0.31)',

      '@global': {
        '.MuiSvgIcon-root': {
          fontSize: '1rem',
          fill: '#0B060F'
        }
      }
    }
  })
)
