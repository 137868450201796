/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo } from '../../helpers'
import { Punchlist } from '../../api'
import { clientTypes, FetchClientAction } from './types'
import { priceModelActions } from '../actions'

import * as Sentry from '@sentry/react'

export function* fetchClientPriceModel({ callback }: FetchClientAction): SagaIterator {
  let success = false
  try {
    const priceModels = yield call(Punchlist.clients.getClientPriceModel)
    if (priceModels) {
      yield put(priceModelActions.setClientPriceModels(priceModels))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('get the client'))
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(clientTypes.FETCH_CLIENT_PRICE_MODEL, fetchClientPriceModel)
}
