/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, delay, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo } from '../../helpers'
import { Punchlist } from '../../api'
import { vendorsTypes, FetchVendorsAction, FetchRecommendedVendorsAction, FetchCountsAction, GetCSVVendorAction, RequestCSVVendorsAction } from './types'
import { vendorsActions } from '../actions'

export function* fetchVendors({ payload, callback }: FetchVendorsAction): SagaIterator {
  let success = false
  try {
    const page = payload.page ?? 0
    const size = payload.size ?? 50

    const start = page * size
    const limit = size

    const searchParams = payload.searchParams ?? ''
    yield put(vendorsActions.setVendors({ start: 0, limit: 0, total: 0, items: [] }))
    const vendors = yield call(Punchlist.vendors.getVendors, { start, limit, searchParams })

    if (vendors) {
      yield put(vendorsActions.setVendors(vendors))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the vendors'))
    if (callback) yield call(callback, false)
  }
}

export function* requestCSVVendors({ payload, callback }: RequestCSVVendorsAction): SagaIterator {
  try {
    const page = 0
    const size = payload.count ?? 50

    const start = page * size
    const limit = size

    const searchParams = payload.searchParams ?? ''
    const csvId = yield call(Punchlist.vendors.requestCSV, { start, limit, searchParams })

    if (csvId) {
      yield put(vendorsActions.getCSV(csvId, callback))
    } else {
      yield call(toast.error, errorTextTryingTo('get the vendors'))
    }

  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the vendors'))
  }
}

export function* getCSV({ payload, callback }: GetCSVVendorAction): SagaIterator {
  try {
    const vendors = yield call(Punchlist.vendors.getCSV, payload)
    if (vendors.status === 200) {
      yield call(callback, true, vendors.data)
    } else {
      yield delay(1500);
      yield put(vendorsActions.getCSV(payload, callback))
    }
  } catch {
    yield call(toast.error, errorTextTryingTo('download csv jobs file'))
    yield call(callback, false, '')
  }
}
export function* fetchRecommendedVendors({ payload, callback }: FetchRecommendedVendorsAction): SagaIterator {
  let success = false
  try {
    yield put(vendorsActions.setVendors({ start: 0, limit: 0, total: 0, items: [] }))
    const vendors = yield call(Punchlist.vendors.getRecommendedVendors, { searchParams: payload })

    if (vendors) {
      yield put(vendorsActions.setVendors({
        items: vendors,
        total: vendors.length,
        start: 0,
        limit: 10
      }))
    }
    success = true
    if (callback) yield call(callback, success, (vendors && vendors.length) || 0)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the recommended vendors'))
    if (callback) yield call(callback, false, 0)
  }
}

export function* fetchCounts({ payload, callback }: FetchCountsAction): SagaIterator {
  let success = false
  try {
    const searchParams = payload.searchParams ?? ''
    const counts = yield call(Punchlist.vendors.getCounts, { searchParams })

    if (counts) {
      yield put(vendorsActions.setCounts(counts))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the pros counts'))
    if (callback) yield call(callback, false)
  }
}

export function* fetchPunchlistVendors({ payload, callback }: FetchVendorsAction): SagaIterator {
  let success = false
  try {
    const page = payload.page ?? 0
    const size = payload.size ?? 50

    const start = page * size

    const searchParams = payload.searchParams ?? ''
    const punchlistVendors = yield call(Punchlist.vendors.getPunchlistVendors, { start, limit: 999, searchParams })

    if (punchlistVendors) {
      yield put(vendorsActions.setPunchlistVendors(punchlistVendors))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the vendors'))
    if (callback) yield call(callback, false)
  }
}


export default function* saga(): SagaIterator {
  yield takeLatest(vendorsTypes.FETCH_VENDORS, fetchVendors)
  yield takeLatest(vendorsTypes.REQUEST_CSV_VENDORS, requestCSVVendors)
  yield takeLatest(vendorsTypes.GET_VENDORS_CSV, getCSV)
  yield takeLatest(vendorsTypes.FETCH_PUNCHLIST_VENDORS, fetchPunchlistVendors)
  yield takeLatest(vendorsTypes.FETCH_RECOMMENDED_VENDORS, fetchRecommendedVendors)
  yield takeLatest(vendorsTypes.FETCH_COUNTS, fetchCounts)
}
