import { FC, useEffect, useMemo, useState } from 'react'
import { MaterialModalProps } from './types'
import useStyles from '../../styles'
import {
  Typography,
  Box,
  TextField,
  Button,
  CircularProgress,
} from '@mui/material'
import Modal from 'src/components/UI/CustomUI/molecules/Modal'
import CircleIconButton from 'src/components/UI/CustomUI/molecules/CircleIconButton'
import SearchIcon from '@mui/icons-material/Search'
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred'
import SaveIcon from '@mui/icons-material/Save'
import { useDispatch, useSelector } from 'react-redux'
import { materialActions, repairItemActions } from 'src/ducks/actions'
import { TextFieldLabel } from 'src/components/UI'
import { getMaterial, getPriceItem, getPriceList } from 'src/ducks/selectors'
import { getRepairlistFilter } from 'src/ducks/filters/selector'
import { MaterialItem, PriceItemType } from 'src/ducks/types'

const MaterialModal: FC<MaterialModalProps> = ({
  isNewPriceItem = false,
  open,
  setOpen,
  onCreateMaterial,
}) => {
  const dispatch = useDispatch()

  const styles = useStyles()

  const material = useSelector(getMaterial)
  const priceItem = useSelector(getPriceItem)
  const priceList = useSelector(getPriceList)
  const repairListFilter = useSelector(getRepairlistFilter())

  const [sku, setSku] = useState<string>('')
  const [showWarn, setShowWarn] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [url, setUrl] = useState<string>('')

  const selectedPriceItem = useMemo(
    () => priceList[repairListFilter.selectedTerritoryIndexes?.at(0) || 0],
    [repairListFilter, priceList]
  )

  useEffect(() => {
    setUrl(material.url)
  }, [material])

  const handleSearchBySku = () => {
    if (sku) {
      dispatch(
        materialActions.fetchMaterialBySku(sku, (succ) => {
          if (!succ) {
            setShowWarn(true)
          } else {
            setShowWarn(false)
          }
        })
      )
    }
  }

  const handleUrlChange = (e: any) => {
    setUrl(e.target.value)
  }

  const handleUpdatePriceItemWithMaterial = (
    item: PriceItemType,
    mat: MaterialItem
  ) => {
    setLoading(true)

    dispatch(
      repairItemActions.patchPriceItem(
        {
          request: {
            materials: [...(item.materials || []), { id: mat.id, quantity: 1 }],
          },
          territoryId: item.territoryId,
        },
        (succ) => {
          setLoading(false)
          handleClose()
        }
      )
    )
  }

  const handleSave = () => {
    if (!showWarn) {
      if (isNewPriceItem) {
        onCreateMaterial && onCreateMaterial(material)
      } else {
        handleUpdatePriceItemWithMaterial(selectedPriceItem, material)
      }
    } else {
      dispatch(
        materialActions.createMaterial({ url, sku }, (succ, mat) => {
          if (succ) {
            if (isNewPriceItem) {
              onCreateMaterial && onCreateMaterial(mat as MaterialItem)
            } else {
              handleUpdatePriceItemWithMaterial(
                selectedPriceItem,
                mat as MaterialItem
              )
            }
          }
        })
      )
    }
    handleClose()
  }

  const handleClose = () => {
    setOpen(false)
    dispatch(repairItemActions.setPriceItem({} as PriceItemType))
  }

  return (
    <Modal open={open} setOpen={setOpen} title="Add Material">
      {loading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Typography variant="h4">SKU</Typography>
          <Box display="flex" justifyContent="space-between" gap={2} mb={2}>
            <TextField
              value={sku}
              onChange={(e) => setSku(e.target.value)}
              fullWidth
            />
            <CircleIconButton
              bgcolor="var(--blue700)"
              width="40px"
              height="40px"
              icon={<SearchIcon style={{ width: '16px', height: '16px' }} />}
              iconColor="white"
              onClick={handleSearchBySku}
            />
          </Box>

          <Box
            className={styles.warn}
            mb={2}
            style={{ display: showWarn ? 'flex' : 'none' }}
          >
            <ReportGmailerrorredIcon
              style={{ width: '24px', height: '24px' }}
            />
            <Typography variant="h5" color="var(--navy-700)">
              Material not found, please provide the URL associated with SKU.
            </Typography>
          </Box>

          <TextFieldLabel
            label="URL"
            labelVariant="h4"
            value={url}
            onChange={handleUrlChange}
          />
          <Box display="flex" justifyContent="flex-end" gap={2} mt={2}>
            <Button variant="containedLight" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              endIcon={<SaveIcon style={{ width: '16px', height: '16px' }} />}
              onClick={handleSave}
            >
              Save
            </Button>
          </Box>
        </>
      )}
    </Modal>
  )
}

export default MaterialModal
