import { Grid } from '@mui/material'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { TextFieldLabel } from 'src/components/UI'
import { getSubscription } from 'src/ducks/selectors'
import { SubscriptionItem } from 'src/ducks/types'
import { formatTimestamp } from 'src/helpers'
import { Email } from '~api/punchlist/employees/types'
import { getLastService } from '../../../Subscriptions/headers'
import useStyles from './styles'

const Details: FC = () => {
  const subscription = useSelector(getSubscription())
  const classes = useStyles()

  const { contractSignedOn, purchaser, expiresOn, homeowner } =
    subscription as SubscriptionItem

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextFieldLabel
          size="small"
          value={
            contractSignedOn
              ? formatTimestamp(contractSignedOn, 'MM/DD/YYYY')
              : '-'
          }
          variant="outlined"
          label="Subscription Started:"
          labelVariant="h5"
          disabled={true}
        />
      </Grid>

      <Grid item xs={6}>
        <TextFieldLabel
          size="small"
          value={`${purchaser.firstName} ${purchaser.lastName}`}
          variant="outlined"
          label="Purchaser Name:"
          labelVariant="h5"
          disabled={true}
        />
      </Grid>

      <Grid item xs={6}>
        <TextFieldLabel
          size="small"
          value={expiresOn ? formatTimestamp(expiresOn, 'MM/DD/YYYY') : '-'}
          variant="outlined"
          label="Renewal Date:"
          labelVariant="h5"
          disabled={true}
        />
      </Grid>

      <Grid item xs={6}>
        <TextFieldLabel
          size="small"
          value={(homeowner.email[0] as Email)?.email || '-'}
          variant="outlined"
          label="Email:"
          labelVariant="h5"
          disabled={true}
        />
      </Grid>

      <Grid item xs={6} marginTop={4}>
        <TextFieldLabel
          size="small"
          value={getLastService(subscription as SubscriptionItem)?.title || '-'}
          variant="outlined"
          label="Last Service:"
          labelVariant="h5"
          disabled={true}
        />
      </Grid>

      <Grid item xs={6} marginTop={4}>
        <TextFieldLabel
          size="small"
          value={subscription?.reference?.replaceAll('_', ' ').toLocaleLowerCase()}
          variant="outlined"
          label="How you heard from us:"
          labelVariant="h5"
          disabled={true}
          className={classes.heardInput}
        />
      </Grid>
    </Grid>
  )
}

export default Details
