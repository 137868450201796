import { useState, FC } from 'react'
import { useDispatch } from 'react-redux'
import { Typography, Button, Box, TextFieldLabel, TitleFirst, BottomLink } from '../../../UI'
import { validateEmail, isEmpty } from '../../../../helpers'
import { authActions } from '../../../../ducks/actions'
import { PunchlistLoader } from '../../../templates'
import useStyles from './styles'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'

const Reset: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [emailToSend, setEmailToSend] = useState('')
  const [loading, setLoading] = useState(false)
  const [sendEmailError, setSendEmailError] = useState(false)
  const [sendEmailSuccess, setSendEmailSuccess] = useState(false)
  const [emailSent, setEmailSent] = useState('')
  const [submit, setSubmit] = useState(false)

  const handleAccept = (event: { preventDefault: () => void }): void => {
    event.preventDefault()
    setSubmit(true)
    setSendEmailError(false)
    setSendEmailSuccess(false)
    if (validateEmail(emailToSend)) {
      setLoading(true)
      dispatch(authActions.changePasswordRequest({
        email: emailToSend,
        callback: (success: boolean) => {
          setLoading(false)
          if (success) {
            setEmailSent(emailToSend)
            setSendEmailSuccess(true)
          } else {
            setSendEmailError(true)
            setSubmit(false)
          }
        }
      }))
    }
  }

  return (
    <PunchlistLoader loading={loading}>
      <TitleFirst value='Forgot your password?' />
      <Typography variant='h4' className={classes.text} marginTop=".5rem">
        We'll send you an email with a link to
      </Typography>
      <Typography variant='h4' className={classes.text}>
        reset it.
      </Typography>
      <Box className={classes.center}>
        <form className={classes.form} autoComplete='off'>
          <TextFieldLabel
            label='Email:'
            type='email'
            value={emailToSend}
            error={submit && (isEmpty(emailToSend) || !validateEmail(emailToSend))}
            onChange={(event) => { if (!loading) { setEmailToSend(event.target.value) } }}
            placeholder='Enter your email here'
            className={classes.email}
            autoComplete='off'
            labelVariant='h5'
          />
          <Box>
            {sendEmailError && (
              <Typography variant='body2' color='error'>
                An error occurred, please verify you entered the correct email address.
              </Typography>
            )}
            {sendEmailSuccess && (
              <Typography variant='body1' className={classes.successText}>
                Thank you, If a BOSSCAT account exists for <Typography variant='body1Bold'>{emailSent}</Typography>, an e-mail will be sent with further instructions.
              </Typography>
            )}
          </Box>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            size='large'
            className={classes.submit}
            sx={{ margin: '36px 0', height: '56px' }}
            onClick={(event) => { if (!loading) handleAccept(event) }}
            fullWidth
            endIcon={
              <Icon
                name={"ArrowForward"}
              />
            }
          >
            Reset My Password
          </Button>
          <BottomLink
            question='Back to'
            linkText='Sign in here'
            linkTo='/login'
          />
        </form>
      </Box>
    </PunchlistLoader>
  )
}

export default Reset
