//import { MaterialInvoice } from '~api/types'

import { UpdateRequest } from "~api/types"
import { MaterialInvoice } from "../types"

// APi GET obtengo todas
// API PUT ADD,DELETE,UPDATE , mando array a la api

//A nivel state , add,delete,update,list(get)

/** TYPES **/
export const materialInvoiceTypes = {
  FETCH_MATERIAL_INVOICE: 'FETCH_MATERIAL_INVOICE',
  SET_MATERIAL_INVOICES: 'SET_MATERIAL_INVOICES',
  SET_MATERIAL_INVOICE: 'SET_MATERIAL_INVOICE',
  UPLOAD_MATERIAL_INVOICES: 'UPLOAD_MATERIAL_INVOICES',
  ADD_MATERIAL_INVOICES: 'ADD_MATERIAL_INVOICES',
  REMOVE_MATERIAL_INVOICES: 'REMOVE_MATERIAL_INVOICES',
  UPDATE_MATERIAL_INVOICES_BY_PATH: 'UPDATE_MATERIAL_INVOICES_BY_PATH',
  EDIT_MATERIAL_INVOICES: 'EDIT_MATERIAL_INVOICES',
  RESET_MATERIAL_INVOICES: 'RESET_MATERIAL_INVOICES'
}

/** ACTIONS **/

export interface FetchMaterialInvoicesAction {
  type: typeof materialInvoiceTypes.FETCH_MATERIAL_INVOICE
  payload: string
  callback?: (succ: boolean) => void
}

export interface SetMaterialInvoicesAction {
  type: typeof materialInvoiceTypes.SET_MATERIAL_INVOICES
  payload: Partial<MaterialInvoices>
}

export interface SetMaterialInvoiceAction {
  type: typeof materialInvoiceTypes.SET_MATERIAL_INVOICE
  payload: MaterialInvoice
  callback?: (succ: boolean) => void
}

export interface UploadMaterialInvoiceAction {
  type: typeof materialInvoiceTypes.ADD_MATERIAL_INVOICES
  payload: UpdateMaterialInvoiceParams
  callback?: (succ: boolean) => void
}

export interface UpdateMaterialInvoiceByPathAction {
  type: typeof materialInvoiceTypes.UPDATE_MATERIAL_INVOICES_BY_PATH
  payload: UpdateRequest
  callback?: (succ: boolean, materialInvoices?: MaterialInvoices) => void
}


export interface AddMaterialInvoiceAction {
  type: typeof materialInvoiceTypes.UPLOAD_MATERIAL_INVOICES
  payload: Partial<MaterialInvoice>
  callback?: (succ: boolean) => void
}

export interface ResetStateAction {
  type: typeof materialInvoiceTypes.RESET_MATERIAL_INVOICES,
  payload: null
}


export type MaterialInvoiceActions =
  | FetchMaterialInvoicesAction
  | SetMaterialInvoicesAction
  | SetMaterialInvoiceAction
  | UploadMaterialInvoiceAction
  | UpdateMaterialInvoiceByPathAction
  | ResetStateAction
  | AddMaterialInvoiceAction
/** REDUCER **/


export interface GetMaterialInvoiceParams {
  jobId: string,
}

export interface UpdateMaterialInvoiceParams {
  materialInvoices: MaterialInvoices,
  jobId: string,
}


/** REDUCER **/


export type MaterialInvoices = MaterialInvoice[] 
