/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useCallback, useEffect, useState, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { PrivateLoader } from 'src/components/templates'
import BackButton from 'src/components/UI/CustomUI/atoms/BackButton'
import TabNavigation from 'src/components/UI/CustomUI/organisms/TabNavigation'
import Modal from 'src/components/UI/CustomUI/molecules/Modal'

import {
  clientActions,
  companiesActions,
  priceModelActions,
} from 'src/ducks/actions'
import { Box, Grid, SelectInput, Switch, Typography } from '../../../UI'
import Invoices from './Tabs/Invoices'
import Jobs from './Tabs/Jobs'
import Profile from './Tabs/Profile'
import { ModalActionType } from '../JobsView/ControlButtons/Modals/ModalMaterialInvoices/types'
import {
  getAllCompaniesForMultipleFieldWithoutAllOption,
  getClient,
  getClientInfo,
  getClientPriceModelFlatten,
  getPriceModelAffiliate,
  getPriceModelNar,
} from 'src/ducks/selectors'
import { SelectInputOption } from 'src/components/UI/CustomUI/atoms/SelectInput/types'
import { CLIENT_TYPE, getDifferenceBetweenObjects } from 'src/helpers'

const CustomerView: FC = () => {
  const { id } = useParams()
  const [pageLoading, setPageLoading] = useState(true)
  const dispatch = useDispatch()

  const priceModelOptions = useSelector(getClientPriceModelFlatten)
  const OPTIONS: SelectInputOption[] = [
    { key: 'NAR', label: 'NAR' },
    { key: 'AFFILIATE', label: 'Affiliate' },
  ]
  const CLIENT_OPTIONS: SelectInputOption[] = [
    { key: CLIENT_TYPE.HOMEOWNER, label: 'Home Owner' },
    { label: 'Institutional', key: CLIENT_TYPE.INSTITUTIONAL },
    { label: 'Broker', key: CLIENT_TYPE.BROKER },
    { label: 'Agent', key: CLIENT_TYPE.AGENT },
  ]
  const affiliateOptions: SelectInputOption[] = useSelector(
    getAllCompaniesForMultipleFieldWithoutAllOption
  )
  const [open, setOpen] = useState(false)

  const client = useSelector(getClient())
  const narPrice = useSelector(getPriceModelNar)
  const affiliatePrice = useSelector(getPriceModelAffiliate)
  const narData = useSelector(
    getClientInfo(narPrice, affiliatePrice),
    shallowEqual
  )

  const narInitial = narData.nar.value === 'Yes' ?? false
  const discountInitial = {
    key: narData.discount.value,
    label: narData.discount.value,
  }
  const revInitial = {
    key: narData.revShare.value,
    label: narData.revShare.value,
  }

  const affInitial = !client.affiliate
    ? null
    : affiliateOptions.find((affiliate) => affiliate.key === client.affiliate)

  const [customer, setCustomer] = useState({
    nar: narInitial,
    discount: discountInitial,
    rev: revInitial,
    aff: affInitial,
    type: CLIENT_OPTIONS.find((option) => option.key === client.clientType),
  })

  const { nar, discount, rev, aff, type } = customer

  useEffect(() => {
    resetCustomer()
    // setType(CLIENT_OPTIONS.find((option) => option.key === client.clientType))
  }, [client])

  const hasChange =
    nar !== narInitial ||
    discount !== discountInitial ||
    rev !== revInitial ||
    aff !== affInitial ||
    type?.key !== client.type

  const fetchClientPriceModels = useCallback(() => {
    dispatch(
      priceModelActions.fetchClientPriceModels((_succ: boolean) => {
        setPageLoading(false)
      })
    )

    dispatch(companiesActions.fetchCompanies({}, (succ: boolean) => {}))
  }, [])

  const fetchClient = useCallback(() => {
    id &&
      dispatch(
        clientActions.fetchClient(id, (_succ: boolean) => {
          fetchClientPriceModels()
        })
      )
  }, [])

  useEffect(() => {
    fetchClient()
  }, [])

  const resetCustomer = () => {
    setCustomer({
      nar: narInitial,
      discount: discountInitial,
      rev: revInitial,
      aff: affInitial,
      type: CLIENT_OPTIONS.find((option) => option.key === client.clientType),
    })
  }

  const handleOpenModal = () => {
    resetCustomer()
    setOpen(true)
  }

  const TABS = [
    {
      label: 'Profile',
      content: <Profile openModal={handleOpenModal} />,
    },
    {
      label: 'Job/Estimates',
      content: <Jobs />,
    },
    {
      label: 'Invoices',
      content: <Invoices />,
    },
    {
      label: 'Notes',
      content: <p>Notes</p>,
    },
  ]

  const handleEdit = () => {
    const discountId =
      discount.label.toLowerCase() === priceModelOptions[0].label.toLowerCase()
        ? priceModelOptions[0].key
        : priceModelOptions[1].key
    const params = {
      nar: nar,
      clientPriceModelId: discount.key ? discountId : null,
      revSharePriority: rev.key && rev.key !== '-' ? rev.key : null,
      affiliate:
        type?.key === CLIENT_TYPE.HOMEOWNER ? '' : aff?.key ? aff.key : null,
      clientType: type?.key as unknown as CLIENT_TYPE,
    }
    // setPageLoading(true)

    id &&
      dispatch(
        clientActions.updateClientId(id, params, (_succ: boolean) => {
          setOpen(false)
          fetchClient()
        })
      )
  }

  const modalAction: ModalActionType[] = [
    {
      textButton: 'Cancel',
      variant: 'containedError',
      onClick: () => {
        setOpen(false)
      },
    },
    {
      textButton: 'Save',
      variant: 'contained',
      onClick: () => {
        handleEdit()
      },
      disabled: !hasChange,
    },
  ]

  return (
    <PrivateLoader loading={pageLoading}>
      <Box>
        <Grid container justifyContent="space-between">
          <Grid item width="fit-content">
            <BackButton />
          </Grid>
          <Grid container item width="fit-content">
            <Typography variant="h4" color="var(--gray700)">
              Total Value:{' '}
              <Typography variant="h4Bold" color="var(--navy-700)">
                ${' '}
              </Typography>
            </Typography>
            <Typography marginX={1} variant="h4" color="var(--gray700)">
              {' '}
              /{' '}
            </Typography>
            <Typography variant="h4" color="var(--gray700)">
              Outstanding Balance:{' '}
              <Typography variant="h4Bold" color="var(--navy-700)">
                $
              </Typography>
            </Typography>
          </Grid>
        </Grid>
        <TabNavigation tabs={TABS} variant="text" />

        <Box
          sx={{
            position: 'absolute',
            bottom: '12px',
            right: '12px',
          }}
        >
          {open && (
            <Modal setOpen={setOpen} open={open} title="" actions={modalAction}>
              <Grid
                container
                spacing={1}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <Typography variant="h4">Edit information: </Typography>
                </Grid>

                <Grid container item xs={12} spacing={1}>
                  <Grid item xs={10} alignSelf="center">
                    <Typography variant="h6">
                      {narData.nar.label + ':'}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} alignSelf="end">
                    <Switch
                      checked={nar}
                      onChange={() => {
                        setCustomer({ ...customer, nar: !nar })
                      }}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <SelectInput
                    label="Type:"
                    options={CLIENT_OPTIONS}
                    placeholder=""
                    onChange={(newSelected: SelectInputOption) => {
                      setCustomer({ ...customer, type: newSelected })
                    }}
                    value={type}
                    canRemove={false}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SelectInput
                    label={narData.discount.label + ':'}
                    options={priceModelOptions}
                    placeholder=""
                    onChange={(newSelected: SelectInputOption) => {
                      setCustomer({ ...customer, discount: newSelected })
                    }}
                    value={discount}
                    size="small"
                  />
                </Grid>

                <Grid item xs={12}>
                  <SelectInput
                    label={narData.revShare.label + ':'}
                    options={OPTIONS}
                    placeholder=""
                    onChange={(newSelected: SelectInputOption) => {
                      setCustomer({ ...customer, rev: newSelected })
                    }}
                    value={rev}
                    size="small"
                  />
                </Grid>

                {type?.key !== CLIENT_TYPE.HOMEOWNER && (
                  <Grid item xs={12}>
                    <SelectInput
                      label={narData.affiliate.label + ':'}
                      options={affiliateOptions}
                      placeholder=""
                      onChange={(newSelected: SelectInputOption) => {
                        setCustomer({ ...customer, aff: newSelected })
                      }}
                      value={aff}
                      size="small"
                    />
                  </Grid>
                )}
              </Grid>
            </Modal>
          )}
        </Box>
      </Box>
    </PrivateLoader>
  )
}

export default CustomerView
