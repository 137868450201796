import { MaterialItem } from '../types'

/** TYPES **/
export const materialTypes = {
  FETCH_MATERIAL_BY_SKU: 'FETCH_MATERIAL_BY_SKU',
  CREATE_MATERIAL: 'CREATE_MATERIAL',
  SET_MATERIAL: 'SET_MATERIAL',
}

/** ACTIONS **/
export interface FetchMaterialBySku {
  type: typeof materialTypes.FETCH_MATERIAL_BY_SKU
  payload: string
  callback?: (succ: boolean, item?: MaterialItem) => void
}

export interface CreateMaterial {
  type: typeof materialTypes.CREATE_MATERIAL
  payload: { url: string, sku: string }
  callback?: (succ: boolean, item?: MaterialItem) => void
}

export interface SetMaterial {
  type: typeof materialTypes.SET_MATERIAL
  payload: MaterialItem
}

export type MaterialActions =
  | FetchMaterialBySku
  | CreateMaterial
  | SetMaterial

/** REDUCER **/
export type MaterialType = MaterialItem
