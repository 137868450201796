import { formatParams } from 'src/helpers'
import axios from '../axios'
import { ApiController } from './types'

const base = '/communications'

const apiController: ApiController = {
  getMessages: async ({ params }) => {
    const parameters = formatParams(params)
    return await axios
      .get(`${base}/message?${parameters}`)
      .then(response => response.data)
  },

  getEmailEvents: async ({ estimateId, start, limit }) => {
    const parameters = formatParams({ estimateId, start, limit })
    return await axios
      .get(`${base}/emails/events?${parameters}`)
      .then(response => response.data)
  },

  createMessage: async (request) => {
    return await axios
      .post(`${base}/message`, request)
      .then(response => response.data)
  },

  replyThread: async (jobId: string, request) => {
    return await axios
      .put(`${base}/message?jobId=${jobId}`, request)
      .then(response => response.data)
  },
}

export default apiController
