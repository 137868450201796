import { FC } from 'react'
import { InputSearchProps } from './types'
import { TextField, InputAdornment, TitleFirst, Box } from 'src/components/UI'
import { icons } from 'src/assets'
import useStyles from './styles'

const InputEndIcon = () => {
  return (
    <InputAdornment position='end'>
      <icons.Search fontSize='medium' />
    </InputAdornment>
  )
}

const InputSearch: FC<InputSearchProps> = ({
  label = '',
  onChange,
  placeholder = 'Search...',
  value,
  size = 'medium',
  disabled = false,
  sx,
  error,
  autoFocus = false
}) => {
  const classes = useStyles()

  return (
    <Box>
      {label !== '' && (
        <TitleFirst
          black={label}
          blackVariant='h6'
          fontFamily='NouvelleBold'
          lineHeight='22px'
          justifyContent='flex-start'
        />
      )}
      <TextField
        autoComplete='search'
        disabled={disabled}
        InputProps={{
          endAdornment: InputEndIcon(),
          onChange: value => onChange(value.target?.value),
          placeholder: placeholder,
          size: size,
          value: value,
          classes: { ...classes }
        }}
        error={error}
        sx={{ ...sx }}
        variant='outlined'
        autoFocus={autoFocus}
      />
    </Box>
  )
}

export default InputSearch
