import { Partner } from 'src/ducks/types'
import axios from '../axios'
import { ApiController } from './types'
import { UpdateRequest } from '~api/types'

const base = '/home-mart'

const apiController: ApiController = {
  getPartners: async ({ active }) => {
    return await axios
      .get(`${base}?active=${active}`)
      .then(response => response.data)
  },

  addPartner: async (payload: Partial<Partner>) => {
    return await axios
      .post(`${base}`, payload)
      .then(response => response.data)
  },

  editPartner: async (id: string, payload: UpdateRequest) => {
    return await axios
      .patch(`${base}/${id}`, payload)
      .then(response => response.data)
  },

  getPartnerCategories: async () => {
    return await axios
      .get(`${base}/customer-types`)
      .then(response => response.data)
  },

  getPartnerTags: async () => {
    return await axios
      .get(`${base}/tags`)
      .then(response => response.data)
  }
}

export default apiController
