import { InvoiceFileRequest, InvoiceType } from "~api/types"
import { CreateInvoiceParams, Invoice, InvoiceActions, invoiceTypes, GetInvoicePreviewParams } from "./types"

const actions = {
  getInvoicePreview: (
    //payload: string,
     payload: { params?: Partial<GetInvoicePreviewParams> },
    callback?: (
      succ: boolean,amountTotal:number,tax:number,paid:number,outstandingBalance:number,subTotal:number) => void
  ): InvoiceActions => ({
    type: invoiceTypes.GET_INVOICE_PREVIEW,
    payload,
    callback
  }),
  setInvoicePreview: (payload: Invoice): InvoiceActions => ({
    type: invoiceTypes.SET_INVOICE_PREVIEW,
    payload
  }),
  setInvoice: (payload: Invoice): InvoiceActions => ({
    type: invoiceTypes.SET_INVOICE,
    payload
  }),
  createInvoice: (
    payload: {
      params: CreateInvoiceParams
      request: Partial<InvoiceType>
    },
    callback: (succ: boolean) => void
  ): InvoiceActions => ({
    type: invoiceTypes.CREATE_INVOICE,
    payload,
    callback
  }),
  resetState: (): InvoiceActions => ({
    type: invoiceTypes.RESET_INVOICE,
    payload: null,
  }),
  getInvoicePreviewPathFile: (
    payload: InvoiceFileRequest,
    callback?: (
      succ: boolean, pathFile: string) => void
  ): InvoiceActions => ({
    type: invoiceTypes.GET_INVOICE_PREVIEW_PATH_FILE,
    payload,
    callback
  }),
  setInvoicePreviewPathFile: (payload: string): InvoiceActions => ({
    type: invoiceTypes.SET_INVOICE_PREVIEW_PATH_FILE,
    payload
  }),
}

export default actions
