import { FC, useContext, useEffect, useState } from 'react'
import { Box, Grid, SelectInput, TradeBox } from '../../../../../UI'
import { useDispatch, useSelector } from 'react-redux'
import {
  getTradeTypes,
} from 'src/ducks/selectors'
import { VendorContext } from '../../context'
import { tradeTypesActions } from 'src/ducks/actions'
import { SelectInputOption } from 'src/components/UI/CustomUI/atoms/SelectInput/types'
import { TradeTypeForSelect } from './types'

const Trades: FC = () => {
  const dispatch = useDispatch()

  const tradeTypes = useSelector(getTradeTypes)

  const { editedVendor, setEditedVendor } = useContext(VendorContext)

  const [options, setOptions] = useState<SelectInputOption[]>([])
  const [tradeTypesForSelect, setTradeTypesForSelect] = useState<TradeTypeForSelect[]>([])

  useEffect(() => {
    // Set options
    setOptions(tradeTypes?.reduce((acc: any, curr) => {
      return [...acc, (acc = {
        id: curr.id,
        label: curr.displayName,
        hide: true
      })]
    }, []))

    // Set trade types for select
    setTradeTypesForSelect(tradeTypes.map((t) => ({
      level: 0,
      primaryTrade: false,
      tradeType: t
    })))

  }, [tradeTypes])

  const handleChange = (newSelected: any) => {
    const trades = editedVendor?.tradeList
    const trade = tradeTypesForSelect.find((t) => t.tradeType.id === newSelected.id)
    if (
      trade &&
      !editedVendor?.tradeList?.some(
        (t) => t.tradeType.id === trade.tradeType.id
      )
    ) {
      trades?.push(trade)
      setEditedVendor({
        ...editedVendor,
        tradeList: trades,
      })
    }
  }

  const handleLevel = (newLevel: number, idx: number) => {
    const expiration = newLevel !== 4 
    setEditedVendor({
      ...editedVendor,
      tradeList: editedVendor?.tradeList?.map((trade, _idx) => {
        if (_idx === idx){
          if (newLevel !== 4 && trade.level === 4){
            return {
              ...trade,
              level: newLevel,
              expiration: "",
              license: "",
            }
          }
          return {
            ...trade,
            level: newLevel,
          }
        }
        return trade
      }),
    })
  }

  const handleNote = (newNote: string, idx: number) => {
    setEditedVendor({
      ...editedVendor,
      tradeList: editedVendor?.tradeList?.map((trade, _idx) => {
        if (_idx === idx)
          return {
            ...trade,
            notes: newNote,
          }
        return trade
      }),
    })
  }

  const handleLicenseID = (newLicenseID: string, idx: number) => {
    setEditedVendor({
      ...editedVendor,
      tradeList: editedVendor?.tradeList?.map((trade, _idx) => {
        if (_idx === idx)
          return {
            ...trade,
            license: ""+newLicenseID,
          }
        return trade
      }),
    })
  }

  const handleLicenseExpDate = (newExpDate: string, idx: number) => {
    setEditedVendor({
      ...editedVendor,
      tradeList: editedVendor?.tradeList?.map((trade, _idx) => {
        if (_idx === idx)
          return {
            ...trade,
            expiration: newExpDate,
          }
        return trade
      }),
    })
  }

  const handleDelete = (idx: number) => {
    setEditedVendor({
      ...editedVendor,
      tradeList: editedVendor?.tradeList?.filter((trade, _idx) => {
        return _idx !== idx
      }),
    })
  }

  useEffect(() => {
    if (!options || options.length < 2) {
      dispatch(tradeTypesActions.fetchTradeTypes())
    }
  }, [dispatch, options])

  return (
    <Box display="flex" flexDirection="column" gap={2.5}>
      <Box display="flex" gap={1} width="100%">
        <SelectInput
          options={options}
          value="Add Trade"
          placeholder="Add Trade"
          sx={{ width: '100%' }}
          onChange={handleChange}
        />
      </Box>

      <Grid container spacing={3}>
        {editedVendor?.tradeList &&
          editedVendor?.tradeList.map((trade: any, index: number) => {
            return (
              <Grid item xs={4}>
                <TradeBox
                  key={index}
                  title={trade.tradeType.displayName}
                  level={trade.level}
                  notes={trade.notes}
                  licenseID={trade.license ?? 0}
                  expDate={trade.expiration ?? new Date()}
                  onDelete={() => {
                    handleDelete(index)
                  }}
                  setLevel={(value: number) => {
                    handleLevel(value, index)
                  }}
                  setNotes={(value: string) => {
                    handleNote(value, index)
                  }}
                  setLicenseID={(value: string) => {
                    handleLicenseID(value, index)
                  }}
                  setExpDate = {(value: string) => {
                    handleLicenseExpDate(value, index)
                  }}
                />
              </Grid>
            )
          })}
      </Grid>
    </Box>
  )
}

export default Trades
