import { SelectInputOption } from "src/components/UI/CustomUI/atoms/SelectInput/types"

export interface CompaniesContextType {
    // MODAL
    modalType: MODAL_TYPE | null,
    modalOpen: boolean,
}

export enum MODAL_TYPE {
    CREATE = 'CREATE_COMPANY',
    EDIT = 'EDIT_COMPANY',
    DELETE_COMPANY = 'DELETE_COMPANY'
}

export interface DispatchValue {
    attr: keyof CompaniesContextType
    value: any
}

export const dispatchTypes = {
    SET_VALUE: 'SET_VALUE',
    CLOSE_MODAL: 'CLOSE_MODAL',
    OPEN_MODAL: 'OPEN_MODAL',
    DELETE_COMPANY: 'DELETE_COMPANY'
}