import { authTypes } from '../auth/types'
import { InsurancesActions, InsurancesFetch, insurancesTypes, } from './types'

const initialState: InsurancesFetch = {
  start: 0,
  limit: 50,
  total: 0,
  items: [],
  csvItems: [],
}

const reducer = (
  state = initialState,
  action: InsurancesActions
): InsurancesFetch => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: {
      return initialState
    }
    case authTypes.LOGOUT_REQUEST: {
      return initialState
    }

    case insurancesTypes.SET_INSURANCES: {
      return {
        ...state,
        start: (payload as InsurancesFetch).start,
        limit: (payload as InsurancesFetch).limit,
        items: (payload as InsurancesFetch).items,
        total: (payload as InsurancesFetch).total,
      }
    }

    case insurancesTypes.SET_CSV_INSURANCES: {
      return {
        ...state,
        csvItems: (payload as InsurancesFetch).items
      }
    }

    default:
      return state
  }
}

export default reducer
