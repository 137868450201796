import env from '@beam-australia/react-env'
import moment from 'moment'
import { JobItem, JobProperties } from 'src/ducks/types'
import { Box, Button, Grid, Typography } from '@mui/material';
import { useContext, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from "react-router-dom";
import Icon from "src/components/UI/CustomUI/atoms/Icon";
import { invoiceActions, jobActions, filesActions } from "src/ducks/actions";
import { getInvoicePreview, getInvoicePDFPath } from "src/ducks/invoice/selectors";
import { round, FILE_TYPE, ParseFilesUrl } from "src/helpers";
import { DepositInvoiceContext } from "../context";
import { depositInvoiceTypes } from "../context/types"
import { ScrollWrapper } from 'src/components/templates';
import styles from './styles.module.scss';
import { getEmployeeId, getJob, } from 'src/ducks/selectors';
import { toast } from 'react-toastify'
const FILES_URL = env('FILES_URL') ?? ''

const InvoicePrice = () => {
  const job = useSelector(getJob())
  const properties = job?.properties as JobProperties
  const invoicePreview = useSelector(getInvoicePreview())
  const dispatch = useDispatch()
  const { jobId } = useParams()
  const navigate = useNavigate()
  const { dispatch: dispatchContext } = useContext(DepositInvoiceContext)
  const userId = useSelector(getEmployeeId)
  const {
    state: { isValid, ccEmails, subject, payerEmail, depositAmount, file, depositTotal, depositSubTotal, tax,
      paid, depositOutstandingBalance }
  } = useContext(DepositInvoiceContext)


const createDeposit = () =>{

  const invoiceBody = Object.entries(invoicePreview).reduce(
    (acc: any, [key, val]) => {
      if (val) acc[key] = val
      return acc
    }, {}
  )

  let recipients = payerEmail + "," + ccEmails;
  dispatch(
    invoiceActions.createInvoice(
      {
        request: {
          jobId,
          isDeposit: true,
          ...invoiceBody
        },
        params: {
          recipients,
          subject
        }
      },
      (_success) => {
        if(_success){
          toast.success.call('success', 'Deposit Invoice Sent Successfully')
        }
        dispatchContext({ type: depositInvoiceTypes.SET_VALUE, payload: { attr: 'pageLoading', value: false } })
        navigate(`/jobs/${jobId}`)
      }
    )
  )

}

  const handleSendInvoice = () => {
    
    if (isValid) {

      dispatchContext({ type: depositInvoiceTypes.SET_VALUE, payload: { attr: 'pageLoading', value: true } })

      if (file) {

        dispatch(filesActions.uploadFile(file, (uploadSucc, fileName) => {
          if (uploadSucc && fileName) {

            const fileToSave = {
              name: file.name,
              fileUrl: ParseFilesUrl({
                baseUrl: FILES_URL,
                avatar: fileName ?? ''
              }),
              description: file.name ?? '',
              fileType: FILE_TYPE.DEPOSIT_ATTACHMENT,
              uploadedOn: moment().unix(),
              uploadedBy: userId
            }
            var x: any[]
            x = properties.files
            x = [...x, fileToSave]
           
            dispatch(
              jobActions.updateJobByPath([{ op: 'add', path: `/properties/files/-`, value: file }], (succ: boolean) => {
                createDeposit()
              })
            )
          }
        }))

      }else{
        createDeposit()
      }
    }
  }

  return (
    <Grid
      item
      display='block'
      direction='column'
      xs={7}
      className={styles.PreviewInvoice__right}
    >
      <Grid item flex={1} sx={{ paddingBottom: '12px' }}>
        <Grid item container direction='column'>
          <Typography variant='h5Bold'>Deposit</Typography>
          <ScrollWrapper relativeStyle={{ minHeight: '60vh' }}>

            <Grid className={styles.PreviewInvoice__Category} >
              <Box className={styles.PreviewInvoice__Category__container}>
                <Typography
                  className={styles.PreviewInvoice__Category__title}
                  variant='body1Bold'
                >
                  $ Deposit Total
                </Typography>
                <Typography>${round(depositAmount || 0, 2)}</Typography>
              </Box>

              <Box className={styles.PreviewInvoice__Category__containerItem}>
                <Typography
                  className={styles.PreviewInvoice__Category__title}
                  variant='body1Bold'
                >
                  Deposit Amount
                </Typography>
                <Typography>${round(depositAmount || 0, 2)}</Typography>
              </Box>

            </Grid>



          </ScrollWrapper>
        </Grid>
      </Grid>
      <Grid item flex={1} maxWidth='50vw' sx={{ paddingBottom: '12px' }}>
        <Grid
          item
          container
          justifyContent='flex-end'
          paddingRight='20px'
          spacing={1}
          borderTop='2px solid #EBECEF'
          marginTop={1}
        >
          <Grid item container justifyContent='flex-end'>
            <Typography variant='body1Bold'>Deposit Subtotal:</Typography>
            <Typography marginLeft='.4rem'>
              ${round(depositSubTotal || 0, 2)}
            </Typography>
          </Grid>
          <Grid item container justifyContent='flex-end'>
            <Typography variant='body1Bold'>Tax:</Typography>
            <Typography marginLeft='.4rem'>
              ${round(tax || 0, 2)}
            </Typography>
          </Grid>
          <Grid item container justifyContent='flex-end'>
            <Typography variant='body1Bold'>Total:</Typography>
            <Typography marginLeft='.4rem'>
              ${round(depositTotal || 0, 2)}
            </Typography>
          </Grid>
          <Grid item container justifyContent='flex-end'>
            <Typography variant='body1Bold'>Paid:</Typography>
            <Typography marginLeft='.4rem'>
              ${round(paid || 0, 2)}
            </Typography>
          </Grid>
          <Grid item container justifyContent='flex-end'>
            <Typography variant='body1Bold'>Outstanding Balance:</Typography>
            <Typography marginLeft='.4rem'>
              ${round(depositOutstandingBalance || 0, 2)}
            </Typography>
          </Grid>
          {/* 
          <Grid item container justifyContent='flex-end'>
            <Typography variant='body1Bold'>Tiene File :</Typography>
            <Typography marginLeft='.4rem'>
            {file ? "Tiene" : "Notiene"}
            </Typography>
          </Grid>*/}

          <Grid
            item
            container
            wrap='nowrap'
            justifyContent='space-between'
            marginTop={2}
          >
            <Grid container item justifyContent='flex-end' gap='1rem'>
              <Button variant='containedLight' onClick={() => navigate(`/jobs/${jobId}`)}>Cancel</Button>
              <Button
                variant='contained'
                endIcon={<Icon name='Email' />}
                disabled={!isValid || invoicePreview?.subtotal <= 0 || depositAmount === 0}
                onClick={handleSendInvoice}
              >
                Send Deposit Invoice
              </Button>
              
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
};

export default InvoicePrice

