import { authTypes } from '../auth/types'
import { Estimate } from '../types'
import { EstimatesByCategoryAction, EstimatesByCategoryType, SetEstimatesByStageParam, estimatesByCategoryTypes } from './types'

const initialState: EstimatesByCategoryType = {}

const reducer = (state = initialState, action: EstimatesByCategoryAction): EstimatesByCategoryType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case estimatesByCategoryTypes.SET_ESTIAMTES_BY_STAGE: {
      return {
        ...state,
        [(payload as SetEstimatesByStageParam).stage]: (payload as SetEstimatesByStageParam).data
      }
    }

    case estimatesByCategoryTypes.RESET_ESTIMATES_BY_STAGE: {
      delete state[(payload as SetEstimatesByStageParam).stage]
      
      return {
        ...state,
        [(payload as SetEstimatesByStageParam).stage]: {}
      }
    }

    default:
      return state
  }
}

export default reducer
