import { formatParams } from 'src/helpers'
import axios from '../axios'
import { ApiController } from './types'

const base = '/jobs'

const apiController: ApiController = {
  getMaterialInvoice: async (jobId: string) =>
    await axios
      .get(`${base}/${jobId}/materials`)
      .then(response => response.data),

  uploadMaterialInvoice: async (jobId: string, request) =>
    await axios
      .put(`${base}/${jobId}/materials`, request)
      .then(response => response.data),

  updateMaterialInvoice: async (jobId, request) =>
    await axios
      .patch(`${base}/${jobId}/materials`, request)
      .then(response => response.data),
}

export default apiController
