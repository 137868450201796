export interface PreviewInvoiceContextType {
    ccEmails: string,
    subject: string,
    jobId: string,
    total: number,
    isValid: boolean,
    pageLoading: boolean,
    payerEmail: string,
    optionSelectedList?: boolean[],
}

export interface PreviewInvoiceValue {
    attr: keyof PreviewInvoiceContextType
    value: any
}

export const previewInvoiceTypes = {
    SET_VALUE: 'SET_VALUE'
}