import { authTypes } from '../auth/types'
import { MatchItem } from '../types'
import { MatchActions, matchTypes } from './types'

const initialState: MatchItem = {} as MatchItem

const reducer = (state = initialState, action: MatchActions): MatchItem => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: {
      return initialState
    }
    case authTypes.LOGOUT_REQUEST: {
      return initialState
    }

    case matchTypes.SET_MATCH: {
      return {
        ...state,
        ...(payload as MatchItem),
      }
    }

    default:
      return state
  }
}

export default reducer
