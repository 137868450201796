import { UpdateRequest } from "~api/types"
import { Widget } from "../types"

/** TYPES **/
export const widgetTypes = {
  FETCH_WIDGET: 'FETCH_WIDGET',
  SET_WIDGET: 'SET_WIDGET',
  UPDATE_WIDGET_BY_PATH: 'UPDATE_WIDGET_BY_PATH',
}

/** ACTIONS **/
export interface FetchWidgetAction {
  type: typeof widgetTypes.FETCH_WIDGET
  payload: string
  callback?: (succ: boolean) => void
}

export interface SetWidgetAction {
  type: typeof widgetTypes.SET_WIDGET
  payload: Widget
  callback?: (succ: boolean) => void
}

export interface UpdateWidgetByPathAction {
  type: typeof widgetTypes.UPDATE_WIDGET_BY_PATH
  payload: UpdateRequest
  callback?: (succ: boolean) => void
}

export type WidgetActions =
  | FetchWidgetAction
  | SetWidgetAction
  | UpdateWidgetByPathAction

/** REDUCER **/
export type WidgetType = Widget | null
