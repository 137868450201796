import type { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo } from '../../helpers'
import { Punchlist } from '../../api'

import {
  CreateInspectorAction,
  FetchInspectorsAction,
  inspectorsTypes,
  UpdateInspectorAction,
} from './types'
import { isEmpty } from 'lodash'
import { inspectorsActions } from '../actions'

export function* fetchInspectors({
  payload,
  callback,
}: FetchInspectorsAction): SagaIterator {
  let success = false
  try {
    const inspectors = yield call(Punchlist.inspectors.getInspectors)

    if (inspectors) {
      yield put(inspectorsActions.setInspectors(inspectors))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    console.log({ error })
    yield call(toast.error, errorTextTryingTo('get the inspection companies'))
    if (callback) yield call(callback, false)
  }
}

export function* createInspector({
  payload,
  callback,
}: CreateInspectorAction): SagaIterator {
  try {
    const inspector = yield call(Punchlist.inspectors.createInspector, payload)
    if (!isEmpty(inspector)) {
      if (callback) yield call(callback, true)
    }
  } catch (error) {
    const errorMsg = error?.response?.data?.message || 'create the company'
    yield call(toast.error, errorMsg)
    if (callback) yield call(callback, false)
  }
}
export function* updateInspector({
  payload,
  callback,
}: UpdateInspectorAction): SagaIterator {
  const { request, id } = payload
  try {
    const inspector = yield call(
      Punchlist.inspectors.updateInspector,
      id,
      request
    )

    if (!isEmpty(inspector)) {
      if (callback) yield call(callback, true)
    }
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('update the company'))
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(inspectorsTypes.CREATE_INSPECTOR, createInspector)
  yield takeLatest(inspectorsTypes.UPDATE_INSPECTOR, updateInspector)
  yield takeLatest(inspectorsTypes.FETCH_INSPECTOR, fetchInspectors)
}
