import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Table from 'src/components/UI/CustomUI/organisms/Table'
import { estimateActions,notesActions } from 'src/ducks/actions'
import { getEstimate ,getNotes } from 'src/ducks/selectors'
import headers from './headers'
import { NOTE_TYPE, RESOURCE_TYPE } from 'src/helpers'

const Notes = ({ id }: { id?: string }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  //const jobNotes = useSelector(getNotes)
  //const dispatch = useDispatch()
    const notes = useSelector(getNotes())

  const estimate = useSelector(getEstimate())

  const fetchEstimateNotes= useCallback(() => {
    if (id) {
      dispatch(
        notesActions.getNotes({ params: { noteType: NOTE_TYPE.JOB_GENERAL, resourceId: id as string, resourceType: RESOURCE_TYPE.ESTIMATE } }, (_succ: boolean) => {
          setLoading(false)
        })
      )
    }
  }, [dispatch, id])


  useEffect(() => {
    fetchEstimateNotes()
  }, [fetchEstimateNotes, estimate])

  return (
    <Table
      headers={headers}
      title="OVERALL"
      loading={loading}
    >
      {notes}
    </Table>
  )
}

export default Notes;