import { MatchesActions, MatchesType } from './types'
import { matchesTypes } from './types'

const actions = {
  fetchMatches: (
    payload: string,
    callback?: (succ: boolean) => void
  ): MatchesActions => ({
    type: matchesTypes.FETCH_MATCHES,
    payload,
    callback,
  }),

  runAutomatch: (
    payload: string,
    callback?: (succ: boolean) => void
  ): MatchesActions => ({
    type: matchesTypes.RUN_AUTOMATCH,
    payload,
    callback
  }),

  setMatches: (payload: MatchesType): MatchesActions => ({
    type: matchesTypes.SET_MATCHES,
    payload,
  }),
}

export default actions
