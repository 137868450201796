import { FC } from 'react'
import { Container, LinearProgress } from '../../../UI'
import { logo } from '../../../../assets'
import { LoaderProps } from '../types'
import useStyles from './styles'

const PunchlistLoader: FC<LoaderProps> = ({ children, loading = true }) => {
  const classes = useStyles()

  if (children && !loading) {
    return <> {children} </>
  }

  return (
    <Container className={classes.content}>
      <img src={logo} alt='' className={classes.logo} />
      <LinearProgress className={classes.loader} />
    </Container>
  )
}

export default PunchlistLoader
