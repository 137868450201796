import { FC } from 'react'

import { Box, Grid, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { getEstimate } from 'src/ducks/selectors'
import { ESTIMATE_PUBLIC_STATUS, formatTimestamp } from 'src/helpers/index'
import Badge from '../EstimateLeft/Badge'
import MarketingContent from '../MarketingContent'
import useStyles from './styles'


export interface EstimateDetailModalProps {
  ptp?: boolean
  // setOpenModal: (value: boolean) => void
}

const EstimateDetailModal: FC<EstimateDetailModalProps> = ({ ptp }) => {
  const classes = useStyles()
  const estimate = useSelector(getEstimate())

  return (
    <Grid container className={classes.root}>
      <Grid item>
        <Typography className={classes.estimateInfoTitle}>
          {estimate?.properties.address?.line_1 || ''}
        </Typography>
        <Typography className={classes.estimateInfoSubtitle}>
          {estimate?.properties.address?.city || ''}
        </Typography>
      </Grid>
      <Grid item justifyContent='center' className={classes.row}>
        <Badge
          status={estimate?.publicStatus as ESTIMATE_PUBLIC_STATUS}
          styles={{
            position: 'static',
            width: 'fit-content'
          }}
        />
      </Grid>

      <Grid item>
        <Box>
          <Box className={classes.infoRow}>
            <Typography className={classes.infoLabel}>Requested by:</Typography>
            <Typography className={classes.infoValue}>
              {estimate?.properties?.requester?.fullName}
            </Typography>
          </Box>
          <Box className={classes.infoRow}>
            <Typography className={classes.infoLabel}>
              Estimate created on:
            </Typography>
            <Typography className={classes.infoValue}>
              {formatTimestamp(estimate?.createdOn, 'MM/DD/YYYY')}
            </Typography>
          </Box>
          {estimate?.lastDeliveredOn && <Box className={classes.infoRow}>
            <Typography className={classes.infoLabel}>
              Estimate updated on:
            </Typography>
            <Typography className={classes.infoValue}>
              {formatTimestamp(estimate?.lastDeliveredOn, 'MM/DD/YYYY')}
            </Typography>
          </Box>}
          {estimate?.approvedOn && !ptp && <Box className={classes.infoRow}>
            <Typography className={classes.infoLabel}>
              Estimate approved on:
            </Typography>
            <Typography className={classes.infoValue}>
              {formatTimestamp(estimate?.approvedOn, 'MM/DD/YYYY')}
            </Typography>
          </Box>}
        </Box>
      </Grid>
      <MarketingContent />
    </Grid>
  )
}

export default EstimateDetailModal
