import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '100% !important',
      minWidth: '45vw !important',
      borderRadius: '16px !important',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        margin: 0,
      }
    },
    container: {
      [theme.breakpoints.down('sm')]: {
        alignItems: 'flex-end !important',
      }
    },
    header: {
      display: 'flex !important',
      alignItems: 'center !important',
      paddingBottom: '1rem !important',
      justifyContent: 'flex-end !important',
      position: 'absolute',
      right: 0,
      top: 0,
      padding: '8px 24px !important',
      zIndex: '9999 !important',
      '@global': {
        '.MuiButton-label': {
          width: 'fit-content'
        }
      }
    },
    body: {
      // paddingBottom: '1rem'
    },
    text: {
      padding: '0 !important',
      fontFamily: 'NextArtHeavy !important',
      textAlign: 'left'
    },
    boxes: {
      display: 'flex !important',
      // gridTemplateColumns: 'repeat(3, 1fr) !important',
      gap: '1rem !important',
    },
    box: {
      display: 'flex !important',
      justifyContent: 'flex-start !important',
      flexDirection: 'column',
      alignItems: 'flex-start !important',
      width: '280px !important',
      textAlign: 'left',
      border: '2px solid #F5F6F7 !important',
      borderRadius: 8,
      boxShadow: '0px 3px 5px rgba(26, 42, 85, 0.2), 0px 0px 1px rgba(26, 42, 85, 0.31) !important',
      padding: '8px !important',
      position: 'relative',
      cursor: 'pointer !important',
      '&:hover': {
        border: '2px solid var(--bosscat-blue-600) !important',
        transition: '300ms all !important',
        '@global': {
          '.MuiSvgIcon-root': {
            fill: 'var(--bosscat-blue-600) !important',
          }
        }
      }
    },
    noAbsolute: {
      position: 'relative'
    },
    radioButton: {
      position: 'absolute',
      right: '8px !important',
      padding: '0 !important',
      width: 'fit-content !important',
      height: 'fit-content !important',
      minWidth: 'fit-content !important',
      '@global': {
        '.MuiButton-label': {
          width: 'fit-content'
        }
      }
    },
    title: {
      color: '#0B060F !important',
      fontSize: '18px !important',
      marginBottom: '.5rem !important'
    },
    subtitle: {
      color: '#6D6A6F'
    },
    closeButton: {
      backgroundColor: 'var(--bosscat-grey-400) !important',
      width: '40px !important',
      height: '40px !important',
      display: 'flex !important',
      justifyContent: 'center !important',
      alignItems: 'center !important',
      borderRadius: '8px !important',
      maxWidth: '40px !important',
      boxShadow: '0px 3px 5px rgba(26, 42, 85, 0.2), 0px 0px 1px rgba(26, 42, 85, 0.31) !important',
      minWidth: 'fit-content !important',
      '@global': {
        '.MuiSvgIcon-root': {
          fontSize: '1rem !important'
        }
      }
    },
  })
)
