import { FC, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PrivateLoader } from "src/components/templates";
import { Button, FormControl, Grid, RadioGroup, Typography } from "src/components/UI";
import Icon from "src/components/UI/CustomUI/atoms/Icon";
import { itemsActions } from "src/ducks/actions";
import { getRemovedItemsHistory } from "src/ducks/history/selectors";
import { getJob } from "src/ducks/job/selectors";
import { getOrders } from "src/ducks/selectors";
import OrderItem from "./OrderItem";
import RemovedItemDetail from "./RemovedItemDetail";
import { WorkOrdersContext } from "../../../context";
import styles from './styles.module.scss'
import { RemovedItem } from "src/ducks/types";
import { isEmpty } from "lodash";

const RecoverRemovedItems: FC = () => {
    const { dispatch: dispatchContext } = useContext(WorkOrdersContext)
    const dispatch = useDispatch()
    const job = useSelector(getJob())
    const removedItems = useSelector(getRemovedItemsHistory)
    const [loading, setLoading] = useState(false)
    const orders = useSelector(getOrders())
    const [orderSelectedId, setOrderSelectedId] = useState(orders[0]?.orderId)
    const [itemSelected, setItemSelected] = useState<RemovedItem>()

    const handleClose = () => {
        dispatchContext({ type: 'SET_MODAL_OPEN', payload: false })
    }

    const handleRecoverItem = () => {
        setLoading(true)
        if (itemSelected) {
            itemSelected.data.orderId = orderSelectedId
            dispatch(itemsActions.recoverItem({ item: itemSelected?.data, id: job?.id }, (succ) => {
                succ && dispatch(itemsActions.fetchItemsByCategory({
                    params: { jobId: job?.id, }
                }, (_succ) => {
                    handleClose()
                    setLoading(false)
                }))
            }))
        }
    }

    return (
        <PrivateLoader loading={loading}>
            <Typography marginBottom="1.5rem">Select a deleted item and then assign it to a Work Order.</Typography>
            <Grid container className={styles.RecoverRemovedItems} spacing={2}>
                <Grid item xs={6}>
                    <Grid border="2px solid var(--border-color)" width="100%" borderRadius={4} className={styles.RecoverRemovedItems__container}>
                        {removedItems.length > 0 && removedItems.map(item => <RemovedItemDetail item={item} setItemSelected={setItemSelected} itemSelected={itemSelected} />)}
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <FormControl sx={{ width: '100%' }}>
                        <RadioGroup
                            value={orderSelectedId}
                            onChange={(e) => setOrderSelectedId(e.target.value as string)}
                            name="order-items"
                            className={styles.OrderItems__container}
                        >
                            {orders.length > 0 && orders.map(order => <OrderItem setOrderSelected={setOrderSelectedId} orderSelected={orderSelectedId} order={order} />)}
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid item container justifyContent="flex-end" margin="1rem 0" gap={1}>
                <Button
                    variant="containedLight"
                    onClick={handleClose}
                >
                    <Typography variant="h5">
                        Cancel
                    </Typography>
                </Button>

                <Button
                    variant="contained"
                    endIcon={<Icon name="Undo" width=".8rem" />}
                    onClick={handleRecoverItem}
                    disabled={isEmpty(orderSelectedId) || isEmpty(itemSelected)}
                >
                    <Typography variant="h5">
                        Recover Item
                    </Typography>
                </Button>
            </Grid>
        </PrivateLoader>
    )
}

export default RecoverRemovedItems