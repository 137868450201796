import { Button, Grid, Typography } from "@mui/material"
import { useState } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { PrivateLoader } from "src/components/templates"
import { subscriptionActions } from "src/ducks/actions"
import { getSubscription } from "src/ducks/selectors"
import { MaintenanceService } from "src/ducks/types"
import { formatTimestamp } from "src/helpers"
import styles from './styles.module.scss'

const ServiceDetail = ({ service }: { service: MaintenanceService }) => {
  const dispatch = useDispatch()
  const subscription = useSelector(getSubscription(), shallowEqual)
  const [pageLoading, setPageLoading] = useState(false)

  const buttonText = service.jobId ? 'View' : 'Create'

  const handleClick = () => {
    if (service.jobId) {
      window.open(`/jobs/${service.jobId}?tab=1&order=${service.orderId}`, '_blank', 'noreferrer')
    } else {
      setPageLoading(true)
      dispatch(subscriptionActions.createJobFromSubscription({ subscriptionId: subscription?.id as string, title: service.title }, (succ, data) => {
        if (succ && subscription?.id) {
          setPageLoading(true)
          data && window.open(`/jobs/${data.jobId}?tab=1&order=${data.orderId}`, '_blank', 'noreferrer');
          dispatch(
            subscriptionActions.fetchSubscription(subscription.id, (_succ: boolean) => {
              setPageLoading(false)
            })
          )
          setPageLoading(false)
        }
      }))
    }
  }

  return (
    <PrivateLoader loading={pageLoading}>
      <Grid className={styles.ServiceDetail__container}>
        <Grid container className={styles.ServiceDetail} spacing={2}>
          <Grid xs={4} direction="column" container item gap={3}>
            <Typography variant="h5Bold">
              Job link:
            </Typography>
            <Typography variant="h5Bold">
              Status:
            </Typography>
            <Typography variant="h5Bold">
              Requested on:
            </Typography>
          </Grid>
          <Grid direction="column" container item gap={3}>
            <Grid item>
              <Button
                variant="containedBig"
                style={{
                  background: service.jobId ? 'var(--blue700)' : 'var(--green800)',
                  border: `1px solid ${service.jobId ? 'var(--blue700)' : 'var(--green800)'}`
                }}
                onClick={handleClick}
                className={styles.ServiceDetail__button}
              >
                {buttonText}
              </Button>
            </Grid>
            <Grid item>
              <Typography variant="h6" lineHeight={'1.6rem'} /* color={status === "RECEIVED" ? 'var(--success-color)' : 'var(--orange700)'} */>{service?.status} </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h5">
                {service.requestedDate ? formatTimestamp(service.requestedDate, 'M/D/YYYY') : ' -'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

    </PrivateLoader>
  )
}

export default ServiceDetail