import React from 'react'
import { Grid } from '@mui/material'
import OCRItems from './OCRItems'
import ItemView from '../ItemView'

const OCRReview = () => {
  return (
    <Grid container style={{ height: '100%' }}>
      <Grid item md={5}>
        <OCRItems />
      </Grid>
      <Grid item md={7} flex={1} paddingRight={2}>
        <ItemView view='OCR' />
      </Grid>
    </Grid>
  )
}

export default OCRReview