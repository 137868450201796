import { FC, useState } from "react"
import { SUB_OPTIONS } from "../.."
import { Box, Button, CircularProgress, Typography } from "@mui/material"
import useStyles from './styles'
import { icons } from 'src/assets'
import { useCalendlyEventListener, InlineWidget } from "react-calendly";
import SuccessModal from "src/components/UI/CustomUI/molecules/SuccessModal"
import { getEstimate } from "src/ducks/selectors"
import { useDispatch, useSelector } from "react-redux"
import { isEmpty } from "lodash"
import { estimateActions } from "src/ducks/actions"

interface CalendlyProps {
  setSubActiveOption: (option: SUB_OPTIONS) => void
  setOpen: (open: boolean) => void
}


const Calendly: FC<CalendlyProps> = ({ setSubActiveOption, setOpen }) => {
  const dispatch = useDispatch()
  const styles = useStyles()
  const estimate = useSelector(getEstimate())


  useCalendlyEventListener({
    onEventScheduled: (e: any) => {
      dispatch(estimateActions.updateEstimateByPath([{ op: 'add', path: `/calendlyEventId`, value: e?.data?.payload?.event?.uri }],
        (succ: boolean) => {
        }))
    },
  });

  if (!isEmpty(estimate?.calendlyEventId)) {
    return (
      <SuccessModal onClose={() => setOpen(false)} title="Your phone call has been scheduled. You will receive an email confirmation.​ " subtitle="We will call you at the scheduled time." />
    )
  }

  return (
    <Box width={"100%"} display="flex" flexDirection="column" className={`${styles.root}`}>
      <Typography variant="h5" className={styles.text}>
        QUESTIONS ABOUT YOUR ESTIMATE
      </Typography>
      <Typography className={styles.subtitle}>
        Schedule a time with an expert to discuss line items in your Estimate. <br/> For other questions,
        please contact our <a href="tel:1-877-4-BOSSCAT" className={styles.phone}>Customer Care Team: 1-877-4-BOSSCAT.</a>
      </Typography>
      <Box width={"100%"} display="flex">
        <InlineWidget
          styles={{
            width: '100%', maxWidth: '100%',
          }}
          LoadingSpinner={() => <Box className={styles.spinner}><CircularProgress size={60} /></Box>}
          url="https://calendly.com/bosscat/phone-call-consultation?hide_gdpr_banner=1"
        />
      </Box>
      <Button
        onClick={() => setSubActiveOption(SUB_OPTIONS.initial)}
        className={styles.button}
        startIcon={<icons.ArrowBack />}
      >
        Back
      </Button>
    </Box>
  )
}

export default Calendly