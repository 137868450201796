import { formatParams } from 'src/helpers'
import axios from '../axios'
import { ApiController } from './types'

const base = '/site-visits'

const apiController: ApiController = {
  getSiteVisits: async ({
    start, limit, params
  }) => {
    const parameters = formatParams(params)
    return await axios
      // .get(`${base}?${parameters}`)
      .get(`${base}?${parameters}`, {
        params: {
          start,
          limit
        }
      })
      .then(response => response.data)
  },

  getSiteVisitById: async (siteVisitId: string) =>
    await axios
      .get(`${base}/${siteVisitId}`)
      .then(response => response.data),

  createSiteVisit: async (sendNotification = false, request) => {
    return await axios
      .post(`${base}?sendNotification=${sendNotification}`, request)
      .then(response => response.data)
  },

  updateSiteVisit: async (siteVisitId: string, request) =>
    await axios
      .patch(`${base}/${siteVisitId}`, request)
      .then(response => response.data),

  setSiteVisitAttachments: async (siteVisitId: string, request) =>
    await axios
      .put(`${base}/${siteVisitId}/files`, request)
      .then(response => response.data),

  getSiteVisitAttachments: async (siteVisitId: string) =>
    await axios
      .get(`${base}/${siteVisitId}/files`)
      .then(response => response.data)
}

export default apiController
