export const colors = {
  neutral: { white: '#FFF', black: '#000' },
  webBackground: { 100: '#FCFFFA', 200: '#F5F9F2' },
  lightBlue: {
    100: '#FAFDFF',
    200: 'var(--lightBlue200)',
    300: '#CEF0FF',
    400: '#ADE6FF',
    500: '#7AD6FF',
    600: '#33C1FF',
    700: '#0095D6',
    800: '#0071A3'
  },
  blue: {
    100: '#FAFBFF',
    200: '#D6DFFF',
    300: '#B8C8FF',
    400: '#99B0FF',
    500: '#7090FF',
    600: '#5278FF',
    700: '#3A66FF'
  },
  navy: {
    100: '#F0F3FA',
    200: '#D9E0F2',
    300: '#A4B4DF',
    400: '#738CCE',
    500: '#4661A8',
    600: '#263E7D',
    700: '#1A2A55'
  },
  purple: {
    100: '#F1F0FF',
    200: '#E3E0FF',
    300: '#C1BCFF',
    400: '#978FFF',
    500: '#6E5BE8',
    600: '#5643D4',
    700: '#3C2CA4'
  },
  orange: {
    100: '#FFFBF0',
    200: '#FFE5A3',
    300: '#FFD874',
    400: '#FFC633',
    500: 'var(--orange500)',
    600: '#CC9300',
    700: '#AD7D00'
  },
  red: {
    100: 'var(--red100)',
    200: '#FFCCCC',
    300: '#FF9393',
    400: '#F77A7A',
    500: '#EB5151',
    600: '#E01F1F',
    700: '#CA0000'
  },
  lime: {
    100: '#FCFEF5',
    200: '#F6FDE2',
    300: '#E9FAB7',
    400: '#D6F679',
    500: '#C0EC3F',
    600: '#9FCB1F',
    700: '#90B32A'
  },
  gray: {
    100: '#EBECEF',
    200: '',
    300: '',
    400: '',
    500: '',
    600: '',
    700: 'var(--gray700)'
  },
  green: {
    100: '#EDFDF6',
    200: '#D2F9E9',
    300: '#B7F5DB',
    400: '#93F0C9',
    500: '#66EAB3',
    600: '#3AE49C',
    700: '#1CCD83',
    800: '#17B975',
    900: '#0E905A'
  },
  teal: {
    100: '#E3FAFD',
    200: '#BCF3FA',
    300: '#99EDF8',
    400: '#70E6F5',
    500: '#2DDAF1',
    600: '#0FC4DC',
    700: '#0CA2B5'
  },
  infoColor: {
    main: '#00b0f0',
    dark: '#007ba8',
    light: '#04c8f3'
  },
  text: {
    secondary: '#AAACBB',
    disabled: '#CCCDD6'
  },
  success: {
    dark: '#1f9c24',
    light: '#93da96'
  },
  error: {
    main: '#EB5151',
    dark: '#e31b0c',
    light: '#f88078'
  },
  warning: {
    main: '#fdb100',
    dark: '#ce9200',
    light: '#ffd777'
  },
  navbar: {
    background: '#0f102c'
  },
  inputColors: {
    main: '#99EDF8',
    dark: '#99EDF8',
    light: '#99EDF8',
    contrastText: '#FFF'
  },
  checkbox: {
    main: '#FFF',
    dark: '#99EDF8',
    light: '#99EDF8',
    contrastText: '#FFF'
  },
  borderColor: {
    main: 'var(--border-color)'
  }
}

export interface CustomPalette {
  neutral: { white: string; black: string }
  webBackground: { 100: string; 200: string }
  lightBlue: {
    100: string; 200: string; 300: string; 400: string; 500: string; 600: string; 700: string; 800: string
  }
  blue: {
    100: string; 200: string; 300: string; 400: string; 500: string; 600: string; 700: string
  }
  navy: {
    100: string; 200: string; 300: string; 400: string; 500: string; 600: string; 700: string
  }
  purple: {
    100: string; 200: string; 300: string; 400: string; 500: string; 600: string; 700: string
  }
  orange: {
    100: string; 200: string; 300: string; 400: string; 500: string; 600: string; 700: string
  }
  red: {
    100: string; 200: string; 300: string; 400: string; 500: string; 600: string; 700: string
  }
  lime: {
    100: string; 200: string; 300: string; 400: string; 500: string; 600: string; 700: string
  }
  green: {
    100: string; 200: string; 300: string; 400: string; 500: string; 600: string; 700: string; 800: string; 900: string
  }
  teal: {
    100: string; 200: string; 300: string; 400: string; 500: string; 600: string; 700: string
  },
  borderColor: {
    main: string
  },
  inputColors: ColorPalette,
  gray: {
    100: string; 200: string; 300: string; 400: string; 500: string; 600: string; 700: string
  }
}

interface ColorPalette {
  main: string
  dark: string
  light: string
  contrastText: string
}

export interface AvailableCustomColors {
  infoText: true
  navbar: true
  inputColors: true,
  checkbox: true
}
