import { FC } from 'react'
import { Grid } from '../../../UI'
import Notifications from './Notifications'
import Statistics from './Statistics'
import Activity from './Activity'

const Home: FC = () => {

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Activity />
      </Grid>
      <Grid item xs={8}>
        <Notifications />
      </Grid>
      <Grid item xs={4}>
        <Statistics />
      </Grid>
    </Grid>
  )
}

export default Home
