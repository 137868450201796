import { Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'absolute',
      top: '2rem',
      //right: 0,
      zIndex: 3,
      width: 'fit-content',
      height: 'auto',
      paddingTop: '1rem',
      /*     [theme.breakpoints.down('lg')]: {
            width: '480px'
          },
          [theme.breakpoints.down('md')]: {
            width: '320px'
          }, */
      marginTop: '10px',
      backgroundColor: 'var(--white-color)',
      border: '2px solid var(--border-color)',
      borderRadius: '8px',
      minWidth: '370px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    date: {
      //width: '100% !important'
    },
    day: {
      fontSize: 16
    },
    selectedDay: {
      color: 'var(--white-color)'
    },
    unselectedDay: {
      color: 'var(--black-color)'
    },
    today: {
      borderRadius: 15,
      width: '40%',
      height: 3,
      position: 'absolute',
      top: 20
    },
    todaySelected: {
      //backgroundColor: 'var(--white-color)'
    },
    todayUnselected: {
      //backgroundColor: 'var(--blue700)'
    },
    comboParent: {
      width: '100%',
      marginTop: '8px',
      paddingLeft: '20px',
      borderRadius: '8px',
      color: 'var(--text-color)',
      fontFamily: 'NouvelleNormal',
      fontWeight: 400,
      fontSize: 16,
      '&:hover': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8
      },
      '&.Mui-focused': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8
      },
    },
    bar: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      height: '56px',
      backgroundColor: 'var(--gray100)',
      borderRadius: '8px',
      justifyContent: 'center'
    },
    title: {
      fontFamily: 'NouvelleNormal',
      fontWeight: 400,
      fontSize: 16,
      color: '#5B5D71',
      textTransform: 'none'
    },
    // OVERRIDES CALENDAR
    month: {
      /* padding: 0,
      left: -5,
      width: '100% !important', */
    },
    days: {
      //height: '100%'
    },
    dayToday: {
      // display: 'flex',
      // flexDirection: 'column'
    },
    dayNumber: {
      // display: 'flex',
      // flexDirection: 'column',
      // height: 22
    },
    dateRangePickerWrapper: {
      // display: 'flex',
      // width: '100%'
    },
    calendarWrapper: {
      // width: '100%',
      // height: '100%'
    },
    error: {
      borderColor: `${theme.palette.error.main} !important`,
    },
    timePicker: {
      display: 'flex',
      zIndex: 99999,
      [theme.breakpoints.down('lg')]: {
        width: '560px'
      },
      [theme.breakpoints.down('md')]: {
        width: '400px'
      }
    },
    centerOnScreen: {
      left: '50%',
      transform: 'translate(-50%, -50%)',
      top: '50%',
      position: 'fixed',
    },
    left: {

    },
    right: {
      right: '0'
    },
    center: {
    },
    left1: {
      left: '0'
    }
  })
)
