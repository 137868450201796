import { InputAdornment, TextField } from '../../../../UI'
import { FC } from 'react'
import MaskedInput from 'react-text-mask'
import { CustomMaskedInputProps, TextMaskCustomProps } from './types'

const TextMaskCustom: FC<TextMaskCustomProps> = (props) => {
  const { mask, inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={mask}
      placeholderChar={'\u2000'}
      showMask={false}
    />
  )
}

const CustomMaskedInput: FC<CustomMaskedInputProps> = (props) => {
  const { mask, adornment, ...rest } = props

  return (
    <TextField
      InputProps={{
        inputProps: { mask },
        inputComponent: TextMaskCustom as any,
        startAdornment: (
          <InputAdornment position='start'>
            {adornment}
          </InputAdornment>
        )
      }}
      {...rest}
    />
  )
}

export default CustomMaskedInput
