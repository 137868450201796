import { FC, useState } from 'react'
import moment from 'moment'
import theme from 'src/components/UI/MaterialUI/theme'
import { Box, Stats, Typography, Button, CircularProgress } from '../../../../UI'
import Tag from 'src/components/UI/CustomUI/atoms/Tag'
import { colors } from '../../../../UI/MaterialUI/themeExtension'

import { InfoProps } from './types'
import { useDispatch, useSelector } from 'react-redux'
import { vendorActions } from 'src/ducks/actions'
import Icon from '../../../../UI/CustomUI/atoms/Icon'
import Modal from 'src/components/UI/CustomUI/molecules/Modal'
import UploadDocumentsModal from '../UploadDocumentsModal'
import { EMPLOYEE_ROLE_TYPE, formatDate } from 'src/helpers'
import { getEmployee } from 'src/ducks/selectors'

const ContactInfo: FC<InfoProps> = ({
  userData,
  userStatsPros
}) => {
  const dispatch = useDispatch()
  const employee = useSelector(getEmployee)
  const disabled = !employee.roles.some(role => role.roleType === EMPLOYEE_ROLE_TYPE.TERRITORY_VENDOR_MANAGER || role.roleType === EMPLOYEE_ROLE_TYPE.SUPER_ADMIN || role.roleType === EMPLOYEE_ROLE_TYPE.MANAGER)

  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)

  const handleApprovePro = () => {
    setLoading(true)

    dispatch(
      vendorActions.updateVendor({
        approved: !userData.approved
      }, (succ: boolean) => {
        setLoading(false)
      })
    )
  }


  return (
    <Box display='flex' height='100%'>
      {open &&
        <Modal
          open={open}
          setOpen={setOpen}
          title="Upload Documentation"
        >
          <UploadDocumentsModal setOpen={setOpen} open={open} />
        </Modal>
      }
      <Box display='flex' flexDirection='column' width='100%' >
        <Box display='flex' flexDirection='column' gap={1}>
          <Typography fontSize='1.25rem' fontFamily="var(--font-medium)">
            {`${userData.firstName} ${userData.lastName}`}
          </Typography>

          <Box display='flex' marginBottom={.5} alignItems='center'>
            <Icon name='Email' width='1rem' sx={{ marginRight: '.4rem' }} />
            <Typography variant="h5">
              {userData.email}
            </Typography>
          </Box>

          <Tag background={userData.approved ? '#EDFDF6' : 'var(--red100)'} center color={userData.approved ? '#17B975' : 'var(--red600)'}> {userData.approved ? 'Approved' : 'Unapproved'} </Tag>

          <Box display='flex' justifyContent='space-between'>
            <Typography color='var(--gray700)' variant="h5Bold" paddingTop='0.5rem'>
              Approved by:
            </Typography>
            <Typography color='var(--gray700)' variant="h5" paddingTop='0.5rem'>
              {userData.approvedByName}
            </Typography>
          </Box>

          <Box display='flex' justifyContent='space-between'>
            <Typography color='var(--gray700)' variant="h5Bold" paddingTop='0.5rem'>
              Approved on:
            </Typography>
            <Typography color='var(--gray700)' variant="h5" paddingTop='0.5rem'>
              {userData.approvedOn ? moment(userData.approvedOn * 1000).format('MM/DD/YYYY') : ''}
            </Typography>
          </Box>
        </Box>

        <Button
          variant='containedBig'
          color='primary'
          sx={{
            width: '100%',
            height: '40px',
            alignSelf: 'center',
            marginTop: '30px'
          }}
          disabled={disabled}
          endIcon={<Icon name="CloudUpload" />}
          onClick={() => setOpen(true)}
        >
          Upload Documents
        </Button>

        <Button
          variant='containedBig'
          sx={{
            width: '100%',
            height: '40px',
            alignSelf: 'center',
            marginTop: '16px',
            backgroundColor: userData.approved ? colors.red[500] : colors.green[700],
            border: `2px solid ${userData.approved ? colors.red[500] : colors.green[700]}`,
            '&:hover': {
              backgroundColor: userData.approved ? colors.red[700] : colors.green[700],
              border: `2px solid ${userData.approved ? colors.red[700] : colors.green[700]}`,
            }
          }}
          disabled={disabled}
          onClick={handleApprovePro}
        >

          {loading ?
            <CircularProgress color='info' size='1.4rem' />
            : `${userData.approved ? 'Unapprove' : 'Approve'} Pro`
          }

        </Button>

        <Stats data={userStatsPros} />

        <Box>
          <Typography
            fontFamily='NouvelleBold'
            fontSize='1.125rem'
            paddingTop='0.50rem'
          >
            User Info:
          </Typography>

          <Box display='flex' flexDirection='row' justifyContent='space-between'>
            <Typography color='GrayText' fontSize='1rem' paddingTop='0.5rem'>
              {`User id:`}
            </Typography>
            <Typography fontSize='1rem' paddingTop='0.5rem'>
              {userData.id}
            </Typography>
          </Box>
          <Box display='flex' flexDirection='row' justifyContent='space-between'>
            <Typography color='GrayText' fontSize='1rem' paddingTop='0.5rem'>
              {`Terms accepted:`}
            </Typography>
            <Typography fontSize='1rem' paddingTop='0.5rem'>
              {formatDate(userData?.tosAcceptedOn, 'MM/DD/YYYY')}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box
        borderLeft={`2px solid ${theme.palette.grey[200]}`}
        height='auto'
        marginLeft='1.313rem'
        width='0px'
      />
    </Box >
  )
}

export default ContactInfo
