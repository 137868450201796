import { formatParams, ORDER_STATUS } from 'src/helpers'
import axios from '../axios'
import { ApiController } from './types'
import { SearchParams } from 'src/ducks/searches/types'

const base = '/orders'

const apiController: ApiController = {
  getOrders: async ({ start, limit, params }) => {
    const parameters = formatParams(params);
    return await axios
      .get(`${base}?${parameters}`, {
        params: {
          start,
          limit,
        }
      })
      .then(response => response.data)
  },

  getOrderById: async (orderId: string) =>
    await axios
      .get(`${base}/${orderId}`)
      .then(response => response.data),

  getGroupedOrders: async (params: Partial<SearchParams>) => {
    const parameters = formatParams(params)
    return await axios
      .get(`${base}/grouped?${parameters}`)
      .then((response) => response.data)
  },

  createOrder: async (request) =>
    await axios
      .post(`${base}`, request)
      .then(response => response.data),

  updateOrder: async (id: string, request) =>
    await axios
      .patch(`${base}/${id}`, request)
      .then(response => response.data),

  setOrderAttachments: async (orderId: string, request) =>
    await axios
      .put(`${base}/${orderId}/files`, request)
      .then(response => response.data),

  deleteOrder: async (orderId: string) =>
    await axios
      .delete(`${base}/${orderId}`),

  getOrderAttachments: async (orderId: string) =>
    await axios
      .get(`${base}/${orderId}/files`)
      .then(response => response.data),

  dispatchOrder: async (orderId: string, sendNotification = false, request) =>
    await axios
      .post(`${base}/${orderId}/dispatch${sendNotification ? '?sendNotification=true' : ''}`, request)
      .then(response => response.data),

  revokeDispatchOrder: async (orderId: string, sendNotification = false) =>
    await axios
      .delete(`${base}/${orderId}/dispatch${sendNotification ? '?notify=true' : ''}`)
      .then(response => response.data),

  setOrderCheckIn: async (orderId: string) =>
    await axios
      .post(`${base}/${orderId}/checkin`)
      .then(response => response.data),

  setOrderComplete: async (orderId: string) =>
    await axios
      .post(`${base}/${orderId}/complete`)
      .then(response => response.data),

  setOrderAccepted: async (orderId: string) =>
    await axios
      .post(`${base}/${orderId}/review?accepted=true`)
      .then(response => response.data),

  setOrderUnaccepted: async (orderId: string) =>
    await axios
      .patch(`${base}/${orderId}`, [{ op: 'add', path: '/statusType', value: ORDER_STATUS.REVIEW }])
      .then(response => response.data),

  setOrderReopen: async (orderId: string) =>
    await axios
      .patch(`${base}/${orderId}`, [{ op: 'add', path: '/statusType', value: ORDER_STATUS.IN_PROGRESS }])
      .then(response => response.data),

  getOrderBids: async (orderId: string) =>
    await axios
      .get(`${base}/${orderId}/bids`)
      .then(response => response.data),

  getPricing: async (orderId: string, request) =>
    await axios
      .post(`${base}/${orderId}/pricing`, request)
      .then(response => response.data),

  getOrderConflict: async (request) => {
    const parameters = formatParams(request);

    return await axios
      .get(`${base}/conflict?${parameters}`)
      .then(response => response.data)
  },
  /* const parameters = formatParams(params);
  return await axios
    .get(`${base}?${parameters}`, {
      params: {
        start,
        limit,
      }
    })
    .then(response => response.data) */
}

export default apiController
