import { select } from 'redux-saga/effects';
/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, takeEvery } from 'redux-saga/effects'

import { Punchlist } from '../../api'
import { isEmpty } from '../../helpers'
import { territoriesActions } from '../actions'
import { CreateTerritoryAction, FetchTerritoriesAction, territoriesTypes } from './types'

export function* fetchTerritories({ callback }: FetchTerritoriesAction): SagaIterator {
  let success = false
  try {
    const data = yield call(Punchlist.territories.getTerritories)

    if (!isEmpty(data)) {
      yield put(territoriesActions.setTerritories(data))
      success = true
    }
    yield call(callback, success)
  } catch (error) {
    yield call(callback, false)
  }
}

export function* createTerritory({ payload, callback }: CreateTerritoryAction): SagaIterator {
  let success = false
  try {
    if(payload?.territoryManager === ''){
      delete payload.territoryManager
    }

    // This should be in the BE
    payload.emailName = `BOSSCAT ${payload.title}`

    const data = yield call(Punchlist.territories.createTerritory, payload)
    if (!isEmpty(data)) {
      const territories = yield select((state) => state.territories.territories)
      yield put(territoriesActions.setTerritories({
        ...territories,
        items: [data, ...territories.items]
      }))
      success = true
    }
    yield call(callback, success)
  } catch (error) {
    yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeEvery(territoriesTypes.FETCH_TERRITORIES, fetchTerritories)
  yield takeEvery(territoriesTypes.CREATE_TERRITORY, createTerritory)
}
