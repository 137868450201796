import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  container: {
    justifyContent: 'center !important',

    '> div': {
      minHeight: '0px !important',
    },
  },
  xs: {
    '@global': {
      '.MuiDialog-container': {
        maxWidth: '400px !important'
      }
    }
  },
  sm: {},
  md: {
    '@global': {
      '.MuiDialog-container': {
        maxWidth: '650px !important'
      }
    }
  },
  lg: {
    '@global': {
      '.MuiDialog-container': {
        maxWidth: '850px !important'
      }
    }
  },
  xl: {
    '@global': {
      '.MuiDialog-container': {
        maxWidth: '1200px !important'
      }
    }
  },
  button: {
    width: "100%",
  },
  fullWidth: {
    maxWidth: '720px',
    height: '400px'
  },
  removeContent:{
    maxWidth: '720px',
    height: '280px'
  },
  fullScreen: {
   // maxWidth: '100% !important',
    height: '100% !important'
  },
  listScreen:{
    maxWidth: '720px',
    height: '350px'
    //height: '240px'
  },
  Modal__title:{
    padding: '12px',
  }
}))
