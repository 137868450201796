/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getSubscription } from 'src/ducks/selectors'
import { SubscriptionType } from 'src/ducks/subscription/types'
import { SubscriptionContextType } from './types'

const initialState: SubscriptionContextType = {
  editedSub: null,
  setEditedSub: () => null,
  hasChanges: false,
  loading: false,
}

export const SubscriptionContext = createContext<SubscriptionContextType>(initialState)

export const SubscriptionProvider: FC = ({ children }: any) => {
  const subscription = useSelector(getSubscription())
  const [editedSub, setEditedSub] = useState<Partial<SubscriptionType>>(subscription)
  const [hasChanges, setHasChanges] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const editedSubOrderList = {
      ...editedSub,
      territoryList: editedSub?.services?.sort((a, b) =>
        a?.jobId?.localeCompare(b?.jobId)
      ),
    }
    const subOrderList = {
      ...subscription,
      territoryList: subscription?.services?.sort((a, b) =>
        a?.jobId?.localeCompare(b?.jobId)
      ),
    }
    const edited =
      JSON.stringify(editedSubOrderList) !== JSON.stringify(subOrderList)
    setHasChanges(edited)
  }, [editedSub])


  return (
    <SubscriptionContext.Provider
      value={{
        editedSub,
        setEditedSub,
        hasChanges,
        loading,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  )
}
