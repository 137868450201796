import { Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { FC } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import TabNavigation from 'src/components/UI/CustomUI/organisms/TabNavigation'
import { getSubscription } from 'src/ducks/selectors'
import { MaintenanceService } from 'src/ducks/types'
import { SERVICE_STATUS } from 'src/helpers'
import ServiceDetail from './ServiceDetail'

import styles from './styles.module.scss'

const Services: FC = () => {
    const subscription = useSelector(getSubscription(), shallowEqual)

    const TABS = subscription && subscription?.services?.reduce((acc: any, curr: MaintenanceService) => {
        acc = [...acc, {
            label: <Box display="flex" gap=".2rem">
                <Typography variant="h5Bold">{curr?.title?.replace('Maintenance', '')}</Typography>
                {curr.status === SERVICE_STATUS.COMPLETE && <Icon name="CheckCircle" color="var(--green800)" />}
            </Box>,
            content: <ServiceDetail service={curr} />
        }]
        return acc
    }, [])

    return (
        <Grid className={styles.Services}>
            <TabNavigation tabs={TABS} className={styles.ServicesTab} variant="outlined" />
        </Grid>
    )
}

export default Services