import { formatParams } from 'src/helpers'
import axios from '../axios'
import { ApiController } from './types'

const base = '/work-orders'

const apiController: ApiController = {
  getGeneralSection: async ({
    id
  }) => {
    return await axios
      .get(`${base}/general-sections/${id}`)
      .then(response => response.data)
  },
  updateWorkOrderItem: async (orderId: string, itemId: string, userId: string, request) => {
    return await axios
      .patch(`${base}/items/${orderId}/${itemId}/${userId}`, request)
      .then(response => response.data)
  },

  getFinancialSection: async ({
    id
  }) => {
    return await axios
      .get(`${base}/contract-financials/${id}`)
      .then(response => response.data)
  },

  getContractDetails: async ({
    id
  }) => {
    return await axios
      .get(`${base}/contract-details/${id}`)
      .then(response => response.data)
  },

  getContractMilestones: async ({
    id
  }) => {
    return await axios
      .get(`${base}/contract-milestones/${id}`)
      .then(response => response.data)
  },

  getWorkOrderItems: async ({
    id,
    itemState
  }) => {
    const parameters = formatParams({ itemState });
    return await axios
      .get(`${base}/items/${id}?${parameters}`)
      .then(response => response.data)
  },
}

export default apiController
