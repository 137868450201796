import { UpdateRequest } from '~api/types'
import { WorkOrders } from '../types'

/** TYPES **/
export const workOrdersTypes = {
  FETCH_GENERAL_SECTION: 'FETCH_GENERAL_SECTION',
  FETCH_FINANCIAL_SECTION: 'FETCH_FINANCIAL_SECTION',
  FETCH_CONTRACT_DETAILS: 'FETCH_CONTRACT_DETAILS',
  FETCH_CONTRACT_MILESTONES: 'FETCH_CONTRACT_MILESTONES',
  FETCH_WORK_ORDER_ITEMS: 'FETCH_WORK_ORDER_ITEMS',
  SET_WORK_ORDERS: 'SET_WORK_ORDERS',
  UPDATE_WORK_ORDER_ITEM: 'UPDATE_WORK_ORDER_ITEM',
}

/** ACTIONS **/
export interface FetchGeneralSectionAction {
  type: typeof workOrdersTypes.FETCH_GENERAL_SECTION
  payload: {
    id: string
  }
  callback?: (succ: boolean) => void
}

export interface UpdateWorkOrderItemAction {
  type: typeof workOrdersTypes.UPDATE_WORK_ORDER_ITEM
  payload: {
    orderId: string
    itemId: string
    userId: string
    request: UpdateRequest
  }
  callback?: (succ: boolean) => void
}
export interface FetchFinancialSectionAction {
  type: typeof workOrdersTypes.FETCH_FINANCIAL_SECTION
  payload: {
    id: string
  }
  callback?: (succ: boolean) => void
}

export interface FetchContractDetailsAction {
  type: typeof workOrdersTypes.FETCH_CONTRACT_DETAILS
  payload: {
    id: string
  }
  callback?: (succ: boolean) => void
}

export interface FetchContractMilestonesAction {
  type: typeof workOrdersTypes.FETCH_CONTRACT_MILESTONES
  payload: {
    id: string
  }
  callback?: (succ: boolean) => void
}

export interface FetchWorkerItemsAction {
  type: typeof workOrdersTypes.FETCH_WORK_ORDER_ITEMS
  payload: {
    id: string,
    itemState?: string,
  }
  callback?: (succ: boolean) => void
}

export interface SetWorkOrdersAction {
  type: typeof workOrdersTypes.SET_WORK_ORDERS
  payload: Partial<WorkOrders>
}

export type WorkOrdersActions =
  | FetchGeneralSectionAction
  | FetchFinancialSectionAction
  | FetchContractDetailsAction
  | FetchContractMilestonesAction
  | FetchWorkerItemsAction
  | SetWorkOrdersAction
  | UpdateWorkOrderItemAction

/** REDUCER **/
export type WorkOrdersType = WorkOrders
