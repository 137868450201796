import { AlignType } from 'src/components/UI/CustomUI/organisms/Table/types'
import { Box, Button, Grid, Typography } from '@mui/material'
import ShowMore from 'src/components/UI/CustomUI/atoms/ShowMore'
import { getTradeLevelInfo } from 'src/helpers'
import Tag from 'src/components/UI/CustomUI/atoms/Tag'
import { getComparator, stableSort } from 'src/helpers/sort'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'

// HeaderType[]
const headers = (dispatchContext: React.Dispatch<any>) => {
  return [
    { id: 'companyName', label: 'Company' },
    {
      id: 'contact', label: 'Name & Mail',
      custom: true,
      Element: (row: any): JSX.Element => {
        return <Grid>
          <Typography variant='body1'>
            {row?.firstName} {row?.lastName}
          </Typography>
          <Typography variant='caption'>
            {row?.email[0]?.email}
          </Typography>
        </Grid>
      }
    },
    {
      id: 'tradeList',
      label: 'Trades',
      custom: true,
      Element: (row: any): JSX.Element => {
        const trades = row?.tradeList || []
        return (
          <Box display="flex" flexDirection="row" gap={1}>
            <ShowMore
              limit={1}
              buttonSx={{ width: "30%", padding: '1px', height: 'auto' }}
              tooltipContent={
                <Box gap={1}>
                  {stableSort(trades, getComparator('desc', 'level')).map((trade: any) => {
                    const name = trade.tradeType.displayName
                    const level = trade.level ? `(${getTradeLevelInfo(trade.level).text})` : ''
                    return <Typography key={name}>{`${name} ${level}`}</Typography>
                  })}
                </Box>
              }
            >
              {stableSort(trades, getComparator('desc', 'level')).map((trade: any) => {
                const name = trade.tradeType.displayName
                const level = trade.level ? `(${getTradeLevelInfo(trade.level).text})` : ''
                return <Tag width="65%" center>{`${name} ${level}`}</Tag>
              })}
            </ShowMore>
          </Box>
        )
      }
    },
    { id: 'phone', label: 'Phone' },
    {
      id: 'action',
      label: 'Action',
      custom: true,
      align: 'center' as AlignType,
      Element: (row: any): JSX.Element => {
        if (row.selected) {
          return (
            <Box display='flex' justifyContent='center'>
              <Typography color='var(--blue700)'> Selected </Typography>
              <Icon sx={{ marginLeft: '.2rem' }} width=".8rem" name='CheckCircle' color='var(--blue700)' />
            </Box>)
        }
        return (
          <Button
            variant='contained'
            size='small'
            sx={{ height: '32px' }}
            onClick={() => { dispatchContext({ type: 'SET_SELECTED_VENDOR', payload: row }) }}
          >
            Select
          </Button>
        )
      }
    },
  ]
}

export default headers
