import { FC, useContext, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Box, Button, CircularProgress, Grid, Typography, TextFieldLabel } from 'src/components/UI'
import { itemsActions } from 'src/ducks/actions'
import { WorkOrdersContext } from '../../context'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import { ITEM_STATUS } from 'src/helpers'
import { Item } from 'src/ducks/types'

const Delete: FC<{ many?: boolean, category?: boolean }> = ({ many = false, category = false }) => {
    const { dispatch: dispatchContext, state: { workItems, selectedItemsIds } } = useContext(WorkOrdersContext)
    const dispatch = useDispatch()
    const { id } = useParams()

    const selectedItems = workItems.reduce((acc: any, curr: any) => {
        return (acc = [
            ...acc,
            ...curr.items.filter((item: { itemId: string }) => selectedItemsIds.includes(item.itemId))
        ])
    }, [])

    const itemsTitles = selectedItems.map((item: { title: string }) => { return item.title }) || []
    const countItems = selectedItemsIds?.length
    const removeText = `You’re rejecting all the current items. (${countItems}/${countItems})`
    const [reason, setReason] = useState('')
    const [buttonLoading, setButtonLoading] = useState(false)

    const handleClose = () => {
        dispatchContext({ type: 'SET_MODAL_OPEN', payload: false })
    }

    const handleDelete = () => {
        setButtonLoading(true)
        selectedItems.forEach((item: Partial<Item>) => {
            dispatch(itemsActions.rejectItem({
                id: id || '',
                itemId: item?.itemId as string,
                partialItem: { followupNotes: reason }
                // partialItem: { ...item, status: ITEM_STATUS.REJECTED, requestedRepairNote: reason }
            }, (succ) => {
                succ && dispatch(itemsActions.fetchItemsByCategory({
                    params: { jobId: id, status: ITEM_STATUS.COMPLETE }
                }, (succ, items) => {
                    dispatchContext({ type: 'SET_REVIEW_ITEMS', payload: items })
                    dispatchContext({ type: 'REFRESH', payload: true })
                    setButtonLoading(false)
                    if (succ) { handleClose() }
                }))
            }))
        })


    }

    return (
        <Grid container spacing={2}>
            {many &&
                <Grid container item xs={12}>
                    {itemsTitles.map((title: string) => {
                        return (
                            <Grid item xs={12}>
                                <Typography>{'• ' + title}</Typography>
                            </Grid>
                        )
                    })}
                </Grid>}

            {many && <Grid item xs={12}> <Typography>{removeText}</Typography></Grid>}

            <Grid item xs={12}>
                <Box>
                    <TextFieldLabel
                        label=''
                        value={reason}
                        size='small'
                        labelVariant='h6'
                        placeholder='Rejecting reason'
                        multiline
                        rows={6}
                        onChange={event => setReason(event.target.value)}
                    />
                </Box>
            </Grid>

            <Grid item container xs={12} spacing={2}>
                <Grid item xs={6}>
                    <Button variant='outlined' onClick={handleClose} fullWidth>
                        Cancel
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        variant='containedError' onClick={handleDelete} fullWidth
                        startIcon={!buttonLoading && <Icon name='Save' />}
                    >
                        {buttonLoading ? <CircularProgress color='info' size='1.4rem' /> : 'Save'}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Delete
