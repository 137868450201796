/* eslint-disable react-hooks/exhaustive-deps */

import { Button, Grid, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { icons } from 'src/assets'
import Table from 'src/components/UI/CustomUI/organisms/Table'
import { getInspectionCompany } from 'src/ducks/selectors'
import headers from './headers'
import { inspectionCompanyActions } from 'src/ducks/actions'

import styles from './styles.module.scss'
import { useParams } from 'react-router-dom'
import InspectorCompanyModal from './components/InspectorCompanyModal'
import { history } from 'src/helpers'
import { PrivateLoader } from 'src/components/templates'
import { InspectorCompany } from 'src/ducks/types'

const InspectionCompany = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [inspectorSelected, setInspectorSelected] = useState<InspectorCompany>()
  const inspectionCompany = useSelector(getInspectionCompany)

  const push = history.usePush()
  const { id } = useParams()

  const [modalOpen, setModalOpen] = useState(false)

  const fetchInspectionCompany = () => {
    setLoading(true)
    id &&
      dispatch(
        inspectionCompanyActions.fetchInspectionCompany(id, (succ) => {
          setLoading(false)
        })
      )
  }

  useEffect(() => {
    fetchInspectionCompany()
  }, [])

  return (
    <PrivateLoader loading={loading}>
      <Grid
        container
        direction="column"
        spacing={2}
        style={{ marginTop: '10px' }}
      >
        {modalOpen && (
          <InspectorCompanyModal
            open={modalOpen}
            setOpen={setModalOpen}
            inspectorSelected={inspectorSelected}
            fetchInspectors={() => {
              fetchInspectionCompany()
            }}
            onClose={() => {
              setModalOpen(false)
              setInspectorSelected(undefined)
            }}
          />
        )}
        <Button
          className={styles.buttonBack}
          onClick={() => {
            push(`/inspection-companies`)
          }}
        >
          <icons.ArrowBack className={styles.buttonBack__arrow} />
        </Button>
        <Typography
          variant="h3"
          style={{
            fontFamily: 'Nouvelle',
            marginLeft: 26,
            marginTop: '.1rem',
            marginBottom: '1rem',
          }}
        >
          {inspectionCompany?.name}
        </Typography>
        <Grid item>
          <Button
            variant="containedBig"
            style={{ width: 'fit-content' }}
            startIcon={<icons.Add style={{ width: '16px' }} />}
            onClick={() => {
              setInspectorSelected(undefined)
              setModalOpen(true)
            }}
          >
            Add Inspector
          </Button>
        </Grid>
        <Grid item>
          <Table
            headers={headers}
            defaultOrder="desc"
            loading={loading}
            // hasPagination={true}
            callToApi={(start, limit) => {
              fetchInspectionCompany()
            }}
            actionTitle="Actions"
            totalItems={20}
            rowsPerPageDefault={10}
            actions={[
              {
                id: 'edit',
                textButton: 'Edit',
                className: styles.editButton,
                iconStart: <icons.Edit style={{ width: '16px' }} />,
                onClick: (row) => {
                  setModalOpen(true)
                  setInspectorSelected(row)
                },
              },
            ]}
            hasPagination={false}
          >
            {inspectionCompany?.inspectors}
          </Table>
        </Grid>
      </Grid>
    </PrivateLoader>
  )
}

export default InspectionCompany
