import { Comment } from 'src/ducks/comments/types'
import { TerritoryManagerType } from 'src/ducks/territory/types'
import { GroupedItem, ImageFile, Item, PunchlistFile, Vendor } from 'src/ducks/types'

export interface WorkOrdersContextType {
  // GENERAL
  pageLoading: boolean
  refresh: boolean
  // MODAL
  modalType: MODAL_TYPE | null
  modalOpen: boolean
  modalActionAfterCreate: boolean
  modalAfterCreate: (orderId: string) => void
  // ORDERS
  selectedOrderId: string | null
  selectedTab: number
  // ITEMS
  selectedItemId: string | null
  selectedItemsIds: string[]
  selectedWorkItem: GroupedItem | null
  checkAll: boolean
  expandAll: boolean
  workItems: GroupedItem[]
  item: Partial<NewItem>
  itemToFetchTotals: Partial<NewItem>
  itemsLoading: boolean
  editItem: Partial<NewItem>
  showPriceMatch?: boolean
  // SITE-VISIT
  selectedVendor: Vendor | null
  selectedSiteVisitId: string | null
  selectedFieldManager: TerritoryManagerType | null
  refreshSite: boolean

  firstItem: Partial<NewItem> | null
  comments: any[]
  totalsLoading: boolean,
  totalPatchLoading: boolean,
}

export interface NewItem extends Item {
  displayTitle: string
  comments?: string[]
  // state used in the <Image> component
  images: File[]
  publicRepairNote: string
  fieldNotes: string
  measurement: string
  priceMatchComment?: string
  priceMatchFile?: {
    name?: string
    uploadUrl?: string
  } | null
}

export const workOdersTypes = {
  SET_MODAL_TYPE: 'SET_MODAL_TYPE',
  SET_MODAL_OPEN: 'SET_MODAL_OPEN',
  TOGGLE_SHOW_PRICE_MATCH: 'TOGGLE_SHOW_PRICE_MATCH',
  SET_MODAL_AFTER_CREATE: 'SET_MODAL_AFTER_CREATE',
  SET_MODAL_ACTION_AFTER_CREATE: 'SET_MODAL_ACTION_AFTER_CREATE',
  SET_PAGE_LOADING: 'SET_PAGE_LOADING',
  REFRESH: 'REFRESH',
  SET_WORK_ITEMS: 'SET_WORK_ITEMS',
  TOGGLE_CHECK_ALL: 'TOGGLE_CHECK_ALL',
  TOGGLE_EXPAND_ALL: 'TOGGLE_EXPAND_ALL',
  SET_SELECTED_ORDER_ID: 'SET_SELECTED_ORDER_ID',
  SET_ITEM: 'SET_ITEM',
  SET_ITEM_TO_FETCH_TOTALS: 'SET_ITEM_TO_FETCH_TOTALS',
  SET_TOTAL_PATCH_LOADING: 'SET_TOTAL_PATCH_LOADING',
  SET_ITEM_TO_FETCH_TOTALS_VALUE: 'SET_ITEM_TO_FETCH_TOTALS_VALUE',
  RESET_ITEM_TO_FETCH_TOTALS: 'RESET_ITEM_TO_FETCH_TOTALS',
  SET_EDIT_ITEM: 'SET_EDIT_ITEM',
  SET_ITEM_VALUE: 'SET_ITEM_VALUE',
  RESET_ITEM: 'RESET_ITEM',
  RESET_ITEM_VALUE: 'RESET_ITEM_VALUE',
  SET_SELECTED_ITEM_ID: 'SET_SELECTED_ITEM_ID',
  SET_SELECTED_ITEMS_IDS: 'SET_SELECTED_ITEMS_IDS',
  SET_SELECTED_WORK_ITEM: 'SET_SELECTED_WORK_ITEM',
  SET_SELECTED_TAB: 'SET_SELECTED_TAB',
  SET_ITEM_VALUES_LOADING: 'SET_ITEM_VALUES_LOADING',
  SET_SELECTED_VENDOR: 'SET_SELECTED_VENDOR',
  SET_SELECTED_SITE_VISIT_ID: 'SET_SELECTED_SITE_VISIT_ID',
  SET_SELECTED_FIELD_MANAGER: 'SET_SELECTED_FIELD_MANAGER',
  SET_EDIT_ITEM_VALUE: 'SET_EDIT_ITEM_VALUE',
  RESET_EDIT_ITEM_VALUE: 'RESET_EDIT_ITEM_VALUE',
  REFRESH_SITE: 'REFRESH_SITE',
  SET_FIRST_ITEM: 'SET_FIRST_ITEM',
  SET_COMMENTS: 'SET_COMMENTS',
  SET_TOTALS_LOADING: 'SET_TOTALS_LOADING',
}

export enum MODAL_TYPE {
  ADD = 'ADD',
  EDIT = 'EDIT',
  EDIT_ITEM = 'EDIT_ITEM',
  IMAGES = 'IMAGES',
  ALL_IMAGES = 'ALL_IMAGES',
  DELETE_ONE = 'DELETE_ONE',
  DELETE_MANY = 'DELETE_MANY',
  DELETE_CATEGORY = 'DELETE_CATEGORY',
  SITE_VISIT = 'SITE_VISIT',
  ON_HOLD = 'ON_HOLD',
  CREATE_BILL = 'CREATE_BILL',
  DELETE_WORK_ORDER = 'DELETE_WORK_ORDER',
  REJECT_ITEM = 'REJECT_ITEM',
  CHECK_IN_HISTORY = 'CHECK_IN_HISTORY',
  VIEW_DISPATCH = 'VIEW_DISPATCH',
  RECOVER_ITEMS = 'RECOVER_ITEMS',
  DELETE_ONE_REFRESH = 'DELETE_ONE_REFRESH',
  ADD_OCR_ITEM = 'ADD_OCR_ITEM',
  COMBINE_ITEMS = 'COMBINE_ITEMS',
  MULTI_MATCHES = 'MULTI_MATCHES',
}
