import React, { FC, useState, useEffect } from 'react'
import ProsDashboard from './ProsDashboard'
import Dashboard from './LicenseDashboard'
import { useSelector } from 'react-redux'
import { getProsFilter } from 'src/ducks/filters/selector'
import { Box } from 'src/components/UI'
import { PROS_PAGE_STATUS } from 'src/helpers'
import InsuranceDashboard from './InsuranceDashboard'

const Pros: FC = () => {
  const prosFilter = useSelector(getProsFilter())

  const [pageStatus, setPageStatus] = useState<PROS_PAGE_STATUS>(
    prosFilter.pageStatus ?? PROS_PAGE_STATUS.PROS
  )

  useEffect(() => {
    setPageStatus(prosFilter.pageStatus ?? PROS_PAGE_STATUS.PROS)
  }, [prosFilter])

  return <Box>
    {pageStatus === PROS_PAGE_STATUS.PROS && <ProsDashboard />}
    {pageStatus === PROS_PAGE_STATUS.LICENSES && <Dashboard />}
    {pageStatus === PROS_PAGE_STATUS.INSURANCE && <InsuranceDashboard />}
  </Box>
}

export default Pros
