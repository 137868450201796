import { Link, TableCell as MaterialTableCell, Typography } from '@mui/material'

import { formatTimestamp, formatTimestampTimeZone } from 'src/helpers'
import { TableCellProps } from './types'

const TableCell = ({
    dataType,
    children,
    maxWidth,
    minWidth,
    width,
    showLink,
    hrefLink,
    small,
    isHeader,
    color,
    ...rest
}: TableCellProps) => {
    let content

    switch (dataType) {
        case 'date':
            content = formatTimestamp(children as number, 'MM/DD/YYYY')
            break
        case 'datetime':
            content = formatTimestamp(children as number, 'MM/DD/YYYY HH:mm')
            break
        case 'link':
            content = showLink
                ? <Link href={hrefLink} target='_blank'>{children}</Link>
                : children
            break
        default:
            content = children
            break
    }
    return (
        <MaterialTableCell
            {...rest}
            style={{
                maxWidth: maxWidth ?? 'auto',
                minWidth: minWidth ?? '',
                width: width ?? '',
                padding: small ? '4px' : 'default',
                wordWrap: 'break-word',
                background: color ?? '',
            }}
        >
            <Typography
                color={isHeader ? 'primary' : 'inherit'}
                component='span'
                variant={small ? 'body1' : "h6"}
            >
                {content}
            </Typography>
        </MaterialTableCell>
    )

}

export default TableCell