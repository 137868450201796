import { FC, useContext } from 'react'
import Modal from 'src/components/UI/CustomUI/molecules/Modal'
import { WorkOrdersContext } from '../../context'

import Images from './Images'
import NewOrder from './NewOrder'
import RenameOrder from './RenameOrder'
import AddItemModal from './AddItem'
import Delete from './Delete'
import { MODAL_TYPE } from '../../context/types'
import SiteVisit from './SiteVisit'
import OnHoldModal from './OnHoldModal'
import CreateBill from './CreateBill'
import DeleteWorkOrder from './DeleteWorkOrder'
import RejectItem from './RejectItem'
import CheckInHistory from './CheckInHistory'
import ViewDispatch from './ViewDispatch'
import { DispatchProvider } from 'src/components/pages/Private/Dispatch/context'
import RecoverRemovedItems from 'src/components/pages/Private/JobsView/Tabs/WorkOrders/OrderItems/Modals/RecoverRemovedItems'
import { Box, Typography } from '@mui/material'

const OrderItemsModal: FC = () => {
	const { state: { modalType, modalOpen, selectedTab }, dispatch: dispatchContext } = useContext(WorkOrdersContext)

	const handleClose = () => {
		dispatchContext({ type: 'SET_MODAL_OPEN', payload: false })
		dispatchContext({ type: 'RESET_ITEM_VALUE' })
		dispatchContext({ type: 'RESET_EDIT_ITEM_VALUE' })
		dispatchContext({ type: 'SET_SELECTED_ITEM_ID', payload: null })
	}

	const withFullScreen = [MODAL_TYPE.IMAGES, MODAL_TYPE.ALL_IMAGES]
	const showClose = [MODAL_TYPE.IMAGES, MODAL_TYPE.ALL_IMAGES, MODAL_TYPE.EDIT_ITEM, MODAL_TYPE.CREATE_BILL]
	const center = [MODAL_TYPE.DELETE_ONE, MODAL_TYPE.DELETE_MANY, MODAL_TYPE.DELETE_CATEGORY, MODAL_TYPE.DELETE_WORK_ORDER]
	const noPadding = [MODAL_TYPE.REJECT_ITEM]

	const title = () => {
		switch (modalType) {
			case MODAL_TYPE.ADD: return 'New Order'
			case MODAL_TYPE.EDIT: return 'Rename Order'
			case MODAL_TYPE.IMAGES: return 'Image Galery'
			case MODAL_TYPE.ALL_IMAGES: return 'Image Galery'
			case MODAL_TYPE.DELETE_ONE: return (
				<Box margin="1rem 1rem 0">
					<Typography variant='h5Bold'>
						Remove Item
					</Typography>
				</Box>
			)
			case MODAL_TYPE.DELETE_MANY: return 'Remove Items'
			case MODAL_TYPE.DELETE_CATEGORY: return (
				<Box margin="1rem 1rem 0">
					<Typography variant='h5Bold'>
						Remove Items
					</Typography>
				</Box>
			)
			case MODAL_TYPE.SITE_VISIT: return 'Dispatch Site Visit'
			case MODAL_TYPE.ON_HOLD: return 'Put Estimated on Hold'
			case MODAL_TYPE.DELETE_WORK_ORDER: return 'This cannot be undone!'
			case MODAL_TYPE.CHECK_IN_HISTORY: return 'Check-in History'
			case MODAL_TYPE.VIEW_DISPATCH: return 'Dispatch Details'
			case MODAL_TYPE.RECOVER_ITEMS: return 'Deleted Items'
			default: return undefined
		}
	}

	const content = () => {
		switch (modalType) {
			case MODAL_TYPE.ADD: return <NewOrder />
			case MODAL_TYPE.EDIT: return <RenameOrder />
			case MODAL_TYPE.EDIT_ITEM:
				return <AddItemModal />
			case MODAL_TYPE.IMAGES: return <Images downloadable hasTabs />
			case MODAL_TYPE.ALL_IMAGES: return <Images allImages downloadable hasTabs allOrders={!selectedTab} />
			case MODAL_TYPE.DELETE_ONE: return <Delete />
			case MODAL_TYPE.DELETE_MANY: return <Delete many />
			case MODAL_TYPE.DELETE_CATEGORY: return <Delete many category />
			case MODAL_TYPE.SITE_VISIT: return <SiteVisit />
			case MODAL_TYPE.ON_HOLD: return <OnHoldModal />
			case MODAL_TYPE.CREATE_BILL: return <CreateBill />
			case MODAL_TYPE.DELETE_WORK_ORDER: return <DeleteWorkOrder />
			case MODAL_TYPE.REJECT_ITEM: return <RejectItem />
			case MODAL_TYPE.CHECK_IN_HISTORY: return <CheckInHistory />
			case MODAL_TYPE.VIEW_DISPATCH: return <DispatchProvider><ViewDispatch /></DispatchProvider>
			case MODAL_TYPE.RECOVER_ITEMS: return <RecoverRemovedItems />
			default: return <>No Content</>
		}
	}

	const size = () => {
		switch (modalType) {
			case MODAL_TYPE.EDIT_ITEM: return 'lg'
			case MODAL_TYPE.SITE_VISIT: return 'xl'
			case MODAL_TYPE.CREATE_BILL: return 'md'
			case MODAL_TYPE.REJECT_ITEM: return 'xl'
			case MODAL_TYPE.CHECK_IN_HISTORY: return 'md'
			case MODAL_TYPE.VIEW_DISPATCH: return 'lg'
			case MODAL_TYPE.RECOVER_ITEMS: return 'xl'
			default: return 'sm'
		}
	}

	const height = () => {
		switch (modalType) {
			case MODAL_TYPE.VIEW_DISPATCH: return '80vh'
			default: return 'inherit'
		}
	}


	return (
		<Modal
			setOpen={() => dispatchContext({ type: 'SET_MODAL_OPEN', payload: false })}
			open={modalOpen}
			onClose={() => { handleClose() }}
			title={title()}
			size={size()}
			fullScreen={modalType !== null && withFullScreen.includes(modalType)}
			showClose={modalType !== null && showClose.includes(modalType)}
			center={modalType !== null && center.includes(modalType)}
			sx={{
				'.MuiDialogContent-root': {
					padding: modalType && noPadding.includes(modalType) ? '0 !important' : '16px 12px'
				},
				'.MuiPaper-root': {
					minHeight: height()
				}
			}
			}
		>
			{content()}
		</Modal>
	)
}

export default OrderItemsModal
