/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import TabNavigation from 'src/components/UI/CustomUI/organisms/TabNavigation'
import Assignments from './Tabs/Assignments'
import Details from './Tabs/Details'
import { useIsXlScreen } from 'src/hooks'
import { territoriesActions } from 'src/ducks/actions'
import { useDispatch } from 'react-redux'

const Information = () => {
  // const [updateData, setUpdateData] = useState(false)
  // const [detailsChanged, setDetailsChanged] = useState(false)
  // const [monetaryChanged, setMonetaryChanged] = useState(false)
  const [xlScreen] = useIsXlScreen()

  const dispatch = useDispatch()

  const detailsHaveChanges = (value: boolean) => {
    /*  setDetailsChanged(value)
     setUpdateData(value) */
  }

  const monetaryHaveChanges = (value: boolean) => {
    /*  setMonetaryChanged(value)
     setUpdateData(value) */
  }

  const Tabs = [
    { label: 'Details', content: <Details isChanged={detailsHaveChanges} /> },
    { label: 'Assignments', content: <Assignments isChanged={monetaryHaveChanges} /> },

  ]

  useEffect(() => {
    dispatch(territoriesActions.fetchTerritories(() => { }))
  }, [])

  return (
    <TabNavigation
      tabs={Tabs}
      variant='contained'
      tabWidth='fit-content'
      tabAlign='flex-end'
      defaultActive={1}
      margin={xlScreen ? '1rem 0' : '50px 0 1rem 0'}
    />
  )
}

export default Information
