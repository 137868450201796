import axios from '../axios'
import { formatParams } from 'src/helpers'
import { ApiController } from './types'

const base = '/comments'

const apiController: ApiController = {
  getComments: async ({  start, limit,params }) => {
    const parameters = formatParams(params);
    return await axios
      .get(`${base}?${parameters}`,{
        params: {
          start,
          limit,
          sortBy: 'insertedOn',
          sortOrder: 'DESC'
        }
      })
      .then(response => response.data)
  },

  createComment: async (request) =>
    await axios
      .post(`${base}`, request)
      .then(response => response.data),

}

export default apiController
