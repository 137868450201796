import { Grid, Typography } from "@mui/material";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { FC } from "react";
import Table from "src/components/UI/CustomUI/organisms/Table";
import headers from "./headers";
import territory from "../../territoryMock";

const containerStyle = {
    width: '100%',
    height: '100%',
    minHeight: '175px',
    padding: '12px 0',
    borderRadius: '8px',
    '.gm-style-cc': { display: 'none' }
}

interface VIPsProps {
    disabled?: boolean
}

const VIPs: FC<VIPsProps> = (disabled) => {
    if (disabled) return <Typography variant='body1'>This feature is not available yet</Typography>
    return (
        <Grid container style={{ height: '100%' }} sx={{ paddingLeft: '0.25rem' }} spacing={2}>
            <Grid item xs={4} style={{ height: '100%' }}>
                {true && (
                    <GoogleMap
                        center={{
                            lat: territory.latitude,
                            lng: territory.longitude
                        }}
                        zoom={15}
                        mapContainerStyle={containerStyle}
                        options={{
                            clickableIcons: false,
                            fullscreenControl: false,
                            zoomControl: false
                        }}
                    >
                        <Marker position={{
                            lat: territory.latitude,
                            lng: territory.longitude
                        }} />
                    </GoogleMap>
                )}
            </Grid>
            <Grid item xs={8}>
                <Table headers={headers} hasPagination={false}>
                    {territory.vipPricing.map((vip) => ({ ...vip }))}
                </Table>
            </Grid>


        </Grid>

    )
}

export default VIPs;