import { UpdateRequest } from '~api/types'
import { Widget } from '../types'
import { WidgetActions, widgetTypes } from './types'

const actions = {
  fetchWidget: (
    payload: string,
    callback?: (succ: boolean) => void
  ): WidgetActions => ({
    type: widgetTypes.FETCH_WIDGET,
    payload,
    callback,
  }),

  setWidget: (payload: Widget): WidgetActions => ({
    type: widgetTypes.SET_WIDGET,
    payload,
  }),

  updateWidgetByPath: (
    payload: UpdateRequest,
    callback?: (succ: boolean) => void
  ): WidgetActions => ({
    type: widgetTypes.UPDATE_WIDGET_BY_PATH,
    payload,
    callback
  }),
}

export default actions
