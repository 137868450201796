import { FC, useContext, useMemo } from 'react'
import useStyles from './styles'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Typography,
} from '@mui/material'
import { FieldsFilter, InputSearch, Grid } from 'src/components/UI'
import { ToolbarProps } from './types'
import AddIcon from '@mui/icons-material/Add'
import { WidgetsContext } from '../context'
import { MODAL_TYPE } from '../context/types'
import RefreshIcon from '@mui/icons-material/Refresh'
import { getCompaniesFilter } from 'src/ducks/filters/selector'
import { useDispatch, useSelector } from 'react-redux'
import { filtersActions } from 'src/ducks/actions'
import { CompaniesFilterType } from 'src/ducks/filters/types'

const Toolbar: FC<ToolbarProps> = ({
  fetchData,
  setSearch,
  search
}) => {
  const styles = useStyles()

  const { dispatch: dispatchContext } = useContext(WidgetsContext)

  const handleNewCompany = () => {
    dispatchContext({
      type: 'SET_VALUE',
      payload: { attr: 'modalType', value: MODAL_TYPE.CREATE },
    })
    dispatchContext({
      type: 'SET_VALUE',
      payload: { attr: 'modalOpen', value: true },
    })
  }

  return (
    <>
      <Typography className={styles.title} width="fit-content">
        Widgets Management
      </Typography>
      <Grid
        justifyContent="space-between"
        alignItems="flex-end"
        className={styles.headerContainer}
        marginTop={1}
        gap={2}
        container
      >
        <Grid
          container
          alignItems="flex-end"
          spacing={2}
          xs={4}
        >
          <Grid item flex={1}>
            <InputSearch
              // label="Search for Widgets:"
              placeholder="Search Widgets"
              onChange={(value) => setSearch(value)}
              value={search}
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item>
            <IconButton
              style={{
                marginLeft: '10px',
                marginTop: '5px',
              }}
              onClick={fetchData}
            >
              <RefreshIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container xs={2} justifyContent="flex-end">
          <Grid item>
            <Button
              onClick={handleNewCompany}
              variant="contained"
              color="primary"
              startIcon={
                <AddIcon
                  color="inherit"
                  fontSize="inherit"
                  style={{ height: '12px' }}
                />
              }
            >
              New Partner
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default Toolbar
