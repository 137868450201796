import { Theme } from 'src/components/UI'
import { makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => ({
  container: {
    flexFlow: 'column',
    gap: '1rem',
    padding: '0 1rem',
    height: '100%'
  },
  buttonGroup: {
    justifyContent: 'space-between'
  },
  ocrReview: {
    backgroundColor: '#1CCD83 !important',
    height: '40px',
    padding: 'auto !important',
    borderRadius: '100%',
    border: '0 !important'
  },
  addEstimate: {
    height: '40px',
    padding: 'auto !important',
    borderRadius: '100%',
  },
  midFont: {
    fontStyle: 'normal !important',
    fontWeight: 400,
    fontSize: '1rem !important',
    lineHeight: '24px !important',
  },
  smFont: {
    fontStyle: 'normal !important',
    fontWeight: 400,
    fontSize: '14px !important',
    lineHeight: '22px !important',
  },
  attachmentTable: {
    overflow: 'overlay',
    height: '200px'
  },
  itemsTable: {
    overflow: 'overlay',
    minHeight: '300px',
    flex: 1,
    maxHeight: 'calc(100vh - 270px)',
  },
  hideMatch: {
    padding: '8px',
    gap: '8px',
    display: 'flex',
    borderRadius: '8px',
    backgroundColor: 'var(--gray100)',
    alignItems: 'center',
    boxShadow: '0px 0px 1px 0px rgba(26, 42, 85, 0.31), 0px 1px 1px 0px rgba(26, 42, 85, 0.24)',
  },
  sxFont: {
    fontSize: '12px !important',
    fontWeight: 500,
    lineHeight: '18px !important'
  }
}))
