import { Box, Typography } from '@mui/material'
import {
  HeaderType,
  AlignType,
} from 'src/components/UI/CustomUI/organisms/Table/types'
import DeleteIcon from '@mui/icons-material/Delete'
import CircleIconButton from 'src/components/UI/CustomUI/molecules/CircleIconButton'
import moment from 'moment'

import styles from './styles.module.scss'

const headers: HeaderType[] = [
  {
    id: 'title',
    label: 'Name',
    custom: true,
    align: 'left' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Box padding="2px 10px" display="flex" flexDirection="column">
          <Typography className={styles.Table__companyName}>
            {row?.title}
          </Typography>
          <Typography className={styles.Companies__createdOn}>
            {`Last used: ${moment(row.lastUsedTimestamp * 1000).format(
              'MM/DD/YYYY - hh:mm A'
            )}`}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'lastUsed',
    label: 'Last Used',
    custom: true,
    align: 'right' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Box padding='2px 10px'>
          <Typography className={styles.Table__companyName}>
            {`${row.lastUsedTimestamp ? moment(row.lastUsedTimestamp * 1000).format('MM/DD/YYYY - hh:mm A') : '-'}`}
          </Typography>
        </Box>
      )
    }
  },
  {
    id: 'win',
    label: 'Win',
    custom: true,
    align: 'right' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Box padding="2px 10px">
          <Typography className={styles.Table__companyName} color='var(--green900)'>
            {row?.wins}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'reject',
    label: 'Reject',
    custom: true,
    align: 'right' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Box padding="2px 10px">
          <Typography className={styles.Table__companyName} color='var(--red700)'>
            {row?.losses}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'used',
    label: 'Used',
    custom: true,
    align: 'right' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Box padding="2px 10px">
          <Typography className={styles.Table__companyName} color='var(--blue700)'>
            {row?.usageCount}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'action',
    label: 'Action',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Box padding="2px 10px">
          <CircleIconButton
            bgcolor="var(--red100)"
            width="40px"
            height="40px"
            icon={<DeleteIcon style={{ width: '16px', height: '16px' }} />}
            iconColor="var(--red500)"
            onClick={(event) => {
              event.stopPropagation()
              row.handleDeleteItem(row.id)
            }}
          />
        </Box>
      )
    },
  },
]

export default headers
