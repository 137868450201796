import { Invitation, InvitationActions, invitationTypes, SendInvitationPayload } from "./types"

const actions = {
    sendInvitation: (
        payload: SendInvitationPayload,
        callback: (succ: boolean) => void
    ): InvitationActions => ({
        type: invitationTypes.SEND_INVITATION,
        payload,
        callback
    }),
    getInvitations: (
        payload: {
            networkIds: string[]
        },
        callback: (succ: boolean, fileNameList?: string[]) => void
    ): InvitationActions => ({
        type: invitationTypes.GET_INVITATIONS,
        payload,
        callback
    }),
    setInvitations: (payload: Invitation[]): InvitationActions => ({
        type: invitationTypes.SET_INVITATIONS,
        payload
    }),
}

export default actions