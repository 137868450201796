import { useState } from 'react'
import { Props } from './types'
import { Button, Box } from 'src/components/UI'
import { icons } from 'src/assets'

const ExpandButton = ({ text, children, hideIcon }: Props) => {

  const [show, setShow] = useState(false)

  return (
    <Box display='flex' flexDirection='column'>
      <Button
        variant='outlined'
        color='primary'
        sx={{ width: '91px', height: '26px' }}
        onClick={() => !hideIcon && setShow(!show)}
        endIcon={hideIcon ? null : <icons.ArrowDropDown style={{ marginBottom: '3px' }} />}>
        {text}
      </Button>

      {show && (
        <Box display='flex' flexDirection='column'
          style={{
            backgroundColor: 'white', padding: '0px 12px', position: 'absolute', marginTop: 30, right: 0,
            zIndex: 1, width: 'fit-content', border: '2px solid var(--border-color)', height: 'auto'
          }}>
          {children}
        </Box>
      )}
    </Box>
  )
}
export default ExpandButton