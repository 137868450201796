import { Grid } from '@mui/material'
import { LabeledText } from 'src/components/UI'
import { Divider } from '../../Templates'
import { useSelector } from 'react-redux'
import { getWorkOrderFinancialSection } from 'src/ducks/selectors'

const Financial = () => {
  const financialSection = useSelector(getWorkOrderFinancialSection)

  return (
    <Grid container direction="column" spacing={1}>
      <Grid container item style={{ gap: '4px' }} direction="column">
        <LabeledText
          sx={{ justifyContent: 'space-between' }}
          labelValue="Total Original Contract Amount"
          labelWeight="400"
          textValue={
            financialSection?.originalContractAmount
              ? `$${financialSection?.originalContractAmount.toLocaleString()}`
              : '-'
          }
        />
        <LabeledText
          sx={{ justifyContent: 'space-between' }}
          labelValue="Total Change Order Amount"
          labelWeight="400"
          textValue={
            financialSection?.changeOrderAmount
              ? `$${financialSection?.changeOrderAmount.toLocaleString()}`
              : '-'
          }
        />
        <LabeledText
          sx={{ justifyContent: 'space-between' }}
          labelValue="Total Contract"
          textValue={
            financialSection?.totalContract
              ? `$${financialSection?.totalContract.toLocaleString()}`
              : '-'
          }
        />
        <Divider />
      </Grid>

      <Grid container item style={{ gap: '4px' }} direction="column">
        <LabeledText
          sx={{ justifyContent: 'space-between' }}
          labelValue="Estimated Date of Completion "
          textValue={
            financialSection?.estimatedDateCompletion
              ? new Date(financialSection?.estimatedDateCompletion)
                  .toISOString()
                  .split('T')[0]
              : '-'
          }
        />
        <LabeledText
          sx={{ justifyContent: 'space-between' }}
          labelValue="Amount of Work Order Complete"
          textValue={
            financialSection?.amountWorkOrderComplete
              ? `$${financialSection?.amountWorkOrderComplete.toLocaleString()}`
              : '-'
          }
        />
        <LabeledText
          sx={{ justifyContent: 'space-between' }}
          labelValue="Contract Amount Remaining"
          textValue={
            financialSection?.remainingContractAmount
              ? `$${financialSection?.remainingContractAmount.toLocaleString()}`
              : '-'
          }
        />
        <LabeledText
          sx={{ justifyContent: 'space-between' }}
          labelValue="Discount Applied (Promo Code)"
          textValue={
            financialSection?.discountApplied
              ? `$${financialSection?.discountApplied.toLocaleString()}`
              : '-'
          }
        />
        <Divider />
      </Grid>
    </Grid>
  )
}

export default Financial
