import { Document, Font, pdf } from '@react-pdf/renderer'

import NextArtSemiBold from '../../../../../../assets/fonts/NEXT ART_SemiBold.otf'
import Moranga from '../../../../../../assets/fonts/NEXT ART_Regular.otf'
import MorangaR from '../../../../../../assets/fonts/NEXT ART_Regular.otf'
import LatoBold from '../../../../../../assets/fonts/Lato-Bold.ttf'
import NextArtBold from '../../../../../../assets/fonts/NEXT ART_Bold.otf'
import NextArtNormal from '../../../../../../assets/fonts/NEXT ART_Regular.otf'
import NextArtLight from '../../../../../../assets/fonts/NEXT ART_Light.otf'
import LatoNormal from '../../../../../../assets/fonts/Lato-Regular.ttf'
import LatoItalicBold from '../../../../../../assets/fonts/Lato-BoldItalic.ttf'
import BetterSansRegular from '../../../../../../assets/fonts/BetterSans-Bold.otf'
import BetterSansBold from '../../../../../../assets/fonts/BetterSans-Regular.otf'

import Page1 from './Page1'
import Page2 from './Page2'
import Page3 from './Page3'
import Page4 from './Page4'
import Page5 from './Page5'
import Page6 from './Page6'
import Page6Agent from './Page6Agent'

// Font.register({ family: 'Moranga', src: Moranga })
// Font.register({ family: 'MorangaR', src: MorangaR })
Font.register({ family: 'LatoBold', src: LatoBold })
Font.register({ family: 'LatoNormal', src: LatoNormal })
Font.register({ family: 'LatoItalicBold', src: LatoItalicBold })
// Font.register({ family: 'BetterSansRegular', src: BetterSansRegular })
// Font.register({ family: 'BetterSansBold', src: BetterSansBold })
// Font.register({ family: 'NextArtBold', src: NextArtBold })
// Font.register({ family: 'NextArtSemiBold', src: NextArtSemiBold })
// Font.register({ family: 'NextArtNormal', src: NextArtNormal })
// Font.register({ family: 'NextArtLight', src: NextArtLight })

export const GenerateDocument = ({
  groupedItems,
  evaluateItems,
  userInfo,
  imageUris,
  marketingImg,
  estimate
}) => {
  const isPillarToPost = estimate?.properties.affiliate === 'pillartopost' || estimate?.properties.affiliate === 'pillar to post'
  const isFromCanada = estimate?.properties.address.country === 'Canada';

  return (
    <Document>
      <Page1 userInfo={userInfo} qrCode={imageUris?.qrCode} estimate={estimate} />
      <Page2
        userInfo={userInfo}
      // tutorialVideoQrCode={imageUris?.tutorialVideoQrCode}
      />
      <Page3 groupedItems={groupedItems} userInfo={userInfo} evaluateItems={evaluateItems} isFromCanada={isFromCanada} totalWithTax={estimate.properties.totalWithTax} />
      {userInfo?.serviceable && (
        <Page4 items={evaluateItems} userInfo={userInfo} />
      )}
      {userInfo?.serviceable && (
        <Page5 marketingImg={marketingImg} qrCode={imageUris?.qrCode} />
      )}
      <Page6Agent />
      {!isPillarToPost && <Page6 userInfo={userInfo} />}
    </Document>
  )
}

const download = (blob, name) => {
  const url = window.URL.createObjectURL(new Blob([blob]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', name + '.pdf')
  if (document.body) document.body.appendChild(link)
  link.click()
}

export const generatePdf = async ({
  groupedItems,
  evaluateItems,
  userInfo,
  imageUris,
  marketingImg,
  estimate
}) => {
  download(
    await pdf(
      <GenerateDocument
        groupedItems={groupedItems}
        evaluateItems={evaluateItems}
        userInfo={userInfo}
        imageUris={imageUris}
        marketingImg={marketingImg}
        estimate={estimate}
      />
    ).toBlob(),
    `Order_${new Date().getTime()}`
  )
}
