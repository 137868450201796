import { Box, Typography } from "src/components/UI/MaterialUI";
import Icon from "../../atoms/Icon";

export default function WarrantyBox() {
    return (
        <Box sx={{ borderColor: 'var(--orange500)!important', border: 2, padding: '8px', borderRadius: '5px', display: 'flex' }}>
            <Box sx={{ marginRight: '3px' }}><Icon name='PrivacyTip' color='var(--orange500)' /></Box>
            <Box><Typography color='var(--orange500)' variant='body1Bold'>Warranty</Typography></Box>
        </Box>
    )
}