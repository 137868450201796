import storeInfo from '../store'
import { JobHistory, JobItem } from '../types'
import { JobType } from './types'

export const getJob = () => (): JobType => {
  const { job } = storeInfo.store.getState()
  return job
}

export const getJobId = (): string => {
  const { job } = storeInfo.store.getState()
  return (job as JobItem)?.id || ''
}
export const getOrderHistoryByJob = (): JobHistory[] => {
  const { job: { orderHistory } } = storeInfo.store.getState()
  return orderHistory
}

export const getJobFullAddress = () => (): string => {
  const { job } = storeInfo.store.getState()
  if (!job) return ''
  const { properties: { fullAddress } } = job as JobItem
  return fullAddress || ''
}

