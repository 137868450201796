import { TradeType } from '../types'
import {
  tradeTypes,
  TradeTypesActions
} from './types'

const actions = {
  fetchTradeTypes: (callback?: (succ: boolean) => void): TradeTypesActions => ({
    type: tradeTypes.FETCH_TRADE_TYPES,
    payload: null,
    callback
  }),
  setTradeTypes: (payload: TradeType[]): TradeTypesActions => ({
    type: tradeTypes.SET_TRADE_TYPES,
    payload
  })
}

export default actions
