import { TablePagination as TablePaginationMUI } from "@mui/material";
import { FC, useState } from "react";
import { TablePaginationProps } from "./types";
import styles from './styles.module.scss';
import { ROWS_PER_PAGE_SIMPLE } from 'src/helpers';

const TablePagination: FC<TablePaginationProps> = ({
	id = '',
	totalItems,
	rowsPerPageDefault,
	callToApi,
	defaultPage = 0,
	rowsPerPageOptions = ROWS_PER_PAGE_SIMPLE,
	className = '',
	isSticky = false,
	sx
}) => {
	const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageDefault || 50);
	const [page, setPage] = useState(defaultPage);
	return (
		<TablePaginationMUI
			id={id.concat('-pagination')}
			rowsPerPageOptions={rowsPerPageOptions}
			component="div"
			count={totalItems || 0}
			rowsPerPage={rowsPerPage}
			page={page}
			onPageChange={(event, newPage) => {
				setPage(newPage);
				callToApi && callToApi(newPage, rowsPerPage);
			}}
			showFirstButton
			showLastButton
			onRowsPerPageChange={(event) => {
				setRowsPerPage(parseInt(event.target.value, 10));
				setPage(defaultPage);
				callToApi && callToApi(defaultPage, parseInt(event.target.value, 10));
			}}
			className={`${styles.TablePagination} ${className} ${isSticky ? styles.sticky : ''}`}
			sx={[
				...(Array.isArray(sx) ? sx : [sx]),
			]}
		/>
	)
}

export default TablePagination