/* eslint-disable generator-star-spacing */
import { SagaIterator } from 'redux-saga'
import { Punchlist } from 'src/api'
import {
  CloneEstimateAction,
  CreateEstimateContactAction,
  estimateTypes,
  FetchEstimateAction,
  OcrEstimateAction,
  ShareEstimateAction,
  SubmitEstimateAction,
  UpdateEstimateByPathAction,
  UpdateEstimateContact,
  UpdateEstimatePropertyAction,
} from './types'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo, isEmpty } from 'src/helpers'
import { estimateActions } from '../actions'
import { UpdateRequest } from '~api/types'
import { getEstimate, getEstimateId } from '../selectors'
import { CreateEstimateActions } from '../estimates/types'
import { Contact, EstimateProperty } from '../types'

export function* fetchEstimate({
  payload,
  callback,
}: FetchEstimateAction): SagaIterator {
  let success = false
  try {
    const estimate = yield call(Punchlist.estimates.getEstimateById, payload)
    if (estimate) {
      yield put(estimateActions.setEstimate(estimate))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the estimate'))
    if (callback) yield call(callback, false)
  }
}

export function* createEstimateContact({ payload, callback }: CreateEstimateContactAction): SagaIterator {
  let success = false
  try {
    const request: UpdateRequest = []
    request.push({
      op: 'add',
      path: '/properties/contacts/-',
      value: payload
    })

    const estimateId = yield select(getEstimateId())

    const estimate = yield call(Punchlist.estimates.updateEstimateContact, estimateId, request)
    yield put(estimateActions.setEstimate(estimate))

    // yield put(jobActions.fetchJob(jobId))
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('add the estimate contact'))
    if (callback) yield call(callback, false)
  }
}

export function* createEstimate({ payload, callback }: CreateEstimateActions): SagaIterator {
  let success = false

  try {

    const estimate = yield call(
      Punchlist.estimates.createEstimate,
      payload
    )

    if (!isEmpty(estimate)) {
      yield put(estimateActions.setEstimate(estimate))
      success = true
    }
    if (callback) yield call(callback, success, estimate.id)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('update the estimate'))
    if (callback) yield call(callback, false, null)
  }
}

export function* submitEstimate({ payload, callback }: SubmitEstimateAction): SagaIterator {
  let success = false
  const estimateId = yield select(getEstimateId())

  try {
    const estimate = yield call(
      Punchlist.estimates.submitEstimate,
      estimateId,
      payload
    )

    if (!isEmpty(estimate)) {
      yield put(estimateActions.fetchEstimate(estimate.id))
      success = true
    }
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('submit the estimate'))
    if (callback) yield call(callback, false)
  }
}

export function* cloneEstimate({ payload, callback }: CloneEstimateAction): SagaIterator {
  let success = false
  const estimateId = yield select(getEstimateId())

  try {
    const newEstimate = yield call(
      Punchlist.estimates.cloneEstimate,
      payload.id || estimateId,
      payload.shallow
    )

    if (!isEmpty(newEstimate)) {
      success = true
    }
    if (callback) yield call(callback, success, newEstimate)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('clone the estimate'))
    if (callback) yield call(callback, false, {})
  }
}

export function* updateEstimateProperty({ payload, callback }: UpdateEstimatePropertyAction): SagaIterator {
  let success = false
  try {
    const request: UpdateRequest = []
    const estimateId = yield select(getEstimateId())

    for (const attr in payload) {
      request.push({
        op: 'add',
        path: '/properties/' + attr,
        value: payload[attr as keyof EstimateProperty]
      })
    }

    const estimate = yield call(Punchlist.estimates.updateEstimate, estimateId, request)

    if (!isEmpty(estimate)) {
      // yield put(estimateActions.setEstimate(estimate))

      yield put(estimateActions.fetchEstimate(estimate.id))
      success = true
    }
    if (callback) yield call(callback, success, estimate.properties)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('update the estimate'))
    if (callback) yield call(callback, false)
  }
}

export function* updateEstimateByPath({
  payload,
  callback
}: UpdateEstimateByPathAction): SagaIterator {
  try {
    const { id: estimateId } = yield select(getEstimate())

    if (estimateId) {
      const estimate = yield call(
        Punchlist.estimates.updateEstimate,
        estimateId,
        payload
      )

      if (!isEmpty(estimate)) {
        yield put(estimateActions.setEstimate(estimate))
        if (callback) yield call(callback, true)
      }
    }
    if (callback) yield call(callback, false)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('update the estimate'))
    if (callback) yield call(callback, false)
  }
}

export function* shareEstimate({ payload, callback }: ShareEstimateAction): SagaIterator {
  try {
    const { id } = yield select(getEstimate())
    yield call(Punchlist.estimates.share, id, payload)
    yield call(toast.success, 'The estimate was shared successfully!')
    if (callback) yield call(callback, true)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the estimate'))
    if (callback) yield call(callback, false)
  }
}

export function* updateEstimateContact({ payload, callback }: UpdateEstimateContact): SagaIterator {
    const { contact, idx } = payload
    let success = false

    try {
        const request: UpdateRequest = []

        for (const attr in contact) {
            request.push({
                op: 'replace',
                path: `/properties/contacts/${idx}/${attr}`,
                value: contact[attr as keyof Contact]
            })
        }
        const { id: estimateId } = yield select(getEstimate())

        const resp = yield call(Punchlist.estimates.updateEstimate, estimateId, request)
        yield put(estimateActions.setEstimate(resp))

        // yield put(jobActions.fetchJob(jobId))
        success = true
        if (callback) yield call(callback, success)
    } catch (error) {
        yield call(toast.error, errorTextTryingTo('update the estimate'))
        if (callback) yield call(callback, false)
    }
}

export function* ocrReprocessEstimate({ payload, callback }: OcrEstimateAction): SagaIterator {
  try {
    const { id } = yield select(getEstimate())
    const result = yield call(Punchlist.estimates.ocrEstimate, id)

    yield put(estimateActions.setEstimate(result))
    yield call(toast.success, 'The estimate was ocr reprocessed successfully!')
    if (callback) yield call(callback, true)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the estimate'))
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(estimateTypes.FETCH_ESTIMATE, fetchEstimate)
  yield takeLatest(estimateTypes.CREATE_ESTIMATE_CONTACT, createEstimateContact)
  yield takeLatest(estimateTypes.CREATE_ESTIMATE, createEstimate)
  yield takeLatest(estimateTypes.UPDATE_ESTIMATE_PROPERTY, updateEstimateProperty)
  yield takeLatest(estimateTypes.UPDATE_ESTIMATE_BY_PATH, updateEstimateByPath)
  yield takeLatest(estimateTypes.SHARE_ESTIMATE, shareEstimate)
  yield takeLatest(estimateTypes.SUBMIT_ESTIMATE, submitEstimate)
  yield takeLatest(estimateTypes.CLONE_ESTIMATE, cloneEstimate)
  yield takeLatest(estimateTypes.UPDATE_ESTIMATE_CONTACT, updateEstimateContact)
  yield takeLatest(estimateTypes.OCR_REPROCESS, ocrReprocessEstimate)
}
