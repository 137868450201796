import axios from '../axios'
import { ApiController } from './types'

const base = '/bills'

const apiController: ApiController = {
  createBill: async (request) => {
    return await axios
      .post(base, request)
      .then(response => response.data)
  },
}

export default apiController
