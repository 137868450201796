import { Theme } from '../../../../../UI'
import { makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => ({
  centerContent: {
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '180px',
    margin: '0 auto',
    textAlign: 'center',
    gap: '16px',
  },
  historyContent: {
    paddingTop: '16px',
  }
}))
