import { Box, Button } from "@mui/material"
import { Dispatch, FC, SetStateAction, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import Modal from "src/components/UI/CustomUI/molecules/Modal"
import UploadDocuments from "src/components/UI/CustomUI/molecules/UploadDocument"
import { itemsActions } from "src/ducks/actions"
import { getEstimateId } from "src/ducks/selectors"


interface ImportFromCsvModalProps {
    isOpen: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
}

const ImportFromCsvModal: FC<ImportFromCsvModalProps> = ({ isOpen, setOpen }) => {
    const dispatch = useDispatch()
    const estimateId = useSelector(getEstimateId())
    const [uploadingFile, setUploadingFile] = useState(false)

    const handleUploadFile = (file: null | File, callback: () => void) => {
        if (file) {
            setUploadingFile(true)
            const formData = new FormData();
            formData.append('file', file);
            dispatch(itemsActions.createItemFromCsv({ id: estimateId, file: formData }, (succ: boolean) => {
                if (succ) {
                    toast.success('Items imported successfully')
                }
                setUploadingFile(false)
                setOpen(false)
            }))
        }
    }

    return (
        <Modal open={isOpen} setOpen={setOpen} title='Import from CSV'>
            <UploadDocuments
                preview={false}
                file={null}
                onChange={handleUploadFile}
                dropzoneText="Add your CSV here"
                allowedFiles={{
                    'text/csv': ['.csv'],
                }} />
            <Box display='flex' justifyContent='flex-end' marginTop={2}>
                <Button
                    disabled={uploadingFile}
                    onClick={() => setOpen(false)}
                    variant='outlined'
                    color='primary'
                    sx={{ mr: 2 }}>
                    Cancel
                </Button>
            </Box>
        </Modal>
    )
}

export default ImportFromCsvModal