import React, { FC, useState, useEffect } from 'react'
import { AssignModalProps } from './types'
import { Box, CircularProgress, Grid, TextField } from 'src/components/UI'
import Modal from 'src/components/UI/CustomUI/molecules/Modal'
import Estimator from './Estimator'

import useStyles from './styles'
import { useDispatch, useSelector } from 'react-redux'
import { estimateActions, estimatesActions, filtersActions } from 'src/ducks/actions'
import { ESTIMATE_STATUS } from 'src/helpers'
import { getEstimatesFilter } from 'src/ducks/filters/selector'

const AssignModal: FC<AssignModalProps> = ({
  assigners,
  open,
  setOpen,
  handleClose,
  estimate,
}) => {
  const styles = useStyles()

  const dispatch = useDispatch()

  const estimatesFilters = useSelector(getEstimatesFilter())

  const [search, setSearch] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    setLoading(true)

    dispatch(
      estimateActions.fetchEstimate(estimate.id, (succ) => {
        setLoading(false)
      })
    )
  }, [])

  const assignEstimator = (estimatorId: string) => {
    setLoading(true)

    dispatch(estimatesActions.updateEstimate({ id: estimate.id, estimatorId }, (succ) => {
      if (succ) {  
        // trigger refreshing
        dispatch(filtersActions.setFilters({ estimates: { ...estimatesFilters } }))
      }
      
      setLoading(false)
      handleClose()
    }))
  }

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Assign to Estimator"
      onClose={handleClose}
    >
      {loading ? (
        <Grid container justifyContent='center'><CircularProgress /></Grid>
      ) : (
        <>
          <TextField
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search..."
            className={styles.searchBox}
          />
          <Box className={styles.container}>
            <Grid container spacing={1}>
              {assigners
                .filter((estimator) =>
                  `${estimator.firstName} ${estimator.lastName}`
                    .toLowerCase()
                    .includes(search.toLowerCase())
                )
                .map((estimator) => (
                  <Estimator
                    key={estimator.id}
                    estimator={estimator}
                    assignEstimator={assignEstimator}
                  />
                ))}
            </Grid>
          </Box>
        </>
      )}
    </Modal>
  )
}

export default AssignModal
