import { Theme } from '../../../../UI'
import { makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => ({
  container: {
    overflowX: 'scroll',
    maxWidth: 'calc(100vw - 48px)',
    '&::-webkit-scrollbar': {
      width: '5px',
    },
    '&::-webkit-scrollbar-track': {
      background: theme.palette.grey[300],
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.grey[500],
      borderRadius: '10px',
    },
  },
  row: {
    display: 'flex',
    gap: '16px',
    flexWrap: 'nowrap',
  },
  banner: {
    padding: '8px',
    fontSize: '18px',
    lineHeight: '24px',
    border: '2px solid var(--red600)',
    borderRadius: '8px',
    width: '100%',
    marginLeft: '16px',
    backgroundColor: 'var(--red200)',
  }
}))
