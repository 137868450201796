import { OptionProps } from './types'

export const optionInfo: OptionProps[] = [
  {
    description: 'Hide Images',
    optionCheck: 'showImages',
  },
  {
    description: 'Hide Pricing',
    optionCheck: 'showPricing',
  },
  {
    description: 'Hide Repair Notes',
    optionCheck: 'showRepairNotes',
  },
]
