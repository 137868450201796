import { Theme } from '@mui/material'
import { AvailableCustomColors } from '../themeExtension'

declare module '@mui/material/IconButton' {
  export interface IconButtonPropsColorOverrides extends AvailableCustomColors { }
}

export const iconButton = {
  MuiIconButton: {
    styleOverrides: {
      root: ({ theme }: { theme: Theme }) => ({
        backgroundColor: theme.palette.blue[100],
        color: theme.palette.blue[700]
      })
    }
  }
}
