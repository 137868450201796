import React, { FC } from 'react'
import Icon from '../../../atoms/Icon'
import { NoResultsViewProps } from './types'
import { Typography, Box,Grid } from "@mui/material"
import styles from './styles.module.scss';

const NoResultsView: FC<NoResultsViewProps> = ({
  title = "",
  subtitle = "",
  icon = <Icon name='CheckCircle' color='var(--success-color)' />
}) => {
  return (

<Grid container spacing={1} direction={"row"} >
    
    
     <Grid container item xs={2} justifyContent='flex-end' alignItems='flex-end'>
       <Grid item justifyContent='flex-end' alignItems='flex-end'>{icon}</Grid>
     </Grid>


     <Grid item xs={10}>
      <Grid container direction={"row"} >
        <Grid item>
        <Typography
            className={
              `${styles.title}`
            }
          >
            {title}
          </Typography>
        </Grid>
        <Grid item>
        <Typography
            className={
              `${styles.description}`
            }
          >
            {subtitle}
          </Typography>
        </Grid>
      </Grid>
     </Grid>
</Grid>


  
  )
}


export default NoResultsView