import { FC } from 'react'
import { Box } from '../../../../../UI'
import { MarketingContentProps } from './types'
import useStyles from './styles'
// import { isMobile } from 'react-device-detect'
// import { marketingContentDefault } from 'src/assets/index'
import { useSelector } from 'react-redux'
import { getCampaigns } from 'src/ducks/selectors'

const MarketingContent: FC<MarketingContentProps> = () => {
    const classes = useStyles()
    const campaign = useSelector(getCampaigns())

    if (!campaign?.imageUrl) return null
    return (
        <Box className={classes.marketContent}>
            <img src={campaign.imageUrl} alt="marketing content" className={classes.marketingImg} />
        </Box>
    )
}

export default MarketingContent
