import { OrderType } from '../orders/types'
import { PunchlistFile, Vendor } from '../types'

/** TYPES **/
export const siteVisitTypes = {
  GET_SITE_VISIT: 'FETCH_SITE_VISIT',
  SET_SITE_VISIT: 'SET_SITE_VISIT',
  CREATE_SITE_VISIT: 'CREATE_SITE_VISIT',
  UPDATE_SITE_VISIT: 'UPDATE_SITE_VISIT'
}

/** ACTIONS **/
export interface GetSiteVisitAction {
  type: typeof siteVisitTypes.GET_SITE_VISIT
  payload: string
  callback?: (succ: boolean) => void
}

export interface SetSiteVisitsAction {
  type: typeof siteVisitTypes.SET_SITE_VISIT
  payload: SiteVisitType
}

export interface CreateSiteVisitPayload {
  siteVisit: Partial<SiteVisitType>
  jobFiles: PunchlistFile[]
  itemsIds: string[]
  jobId: string
  sendNotification: boolean
}

export interface CreateSiteVisitAction {
  type: typeof siteVisitTypes.CREATE_SITE_VISIT
  payload: CreateSiteVisitPayload
  callback: (succ: boolean) => void
}

export interface UpdateSiteVisitPayload {
  siteVisitId: string
  siteVisit: Partial<SiteVisitType>
  jobFiles: PunchlistFile[]
}

export interface UpdateSiteVisitAction {
  type: typeof siteVisitTypes.UPDATE_SITE_VISIT
  payload: UpdateSiteVisitPayload
  callback: (succ: boolean) => void
}

export type SiteVisitActions =
  | GetSiteVisitAction
  | SetSiteVisitsAction
  | CreateSiteVisitAction
  | UpdateSiteVisitAction

/** REDUCER **/
export type SiteVisitType = (OrderType & { selectedVendor: Vendor }) | null
