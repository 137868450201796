import { memo, useContext, useState } from 'react'
import { Button, CircularProgress, Grid, TextFieldLabel, Typography } from 'src/components/UI'
import useStyles from './styles'
import { ImageProps } from '../AddItem/Tabs/EstimateInfo/types'
import ImagesSelector from 'src/components/UI/CustomUI/molecules/ImagesSelector'
import GeneralImagesSelector from 'src/components/UI/CustomUI/molecules/GeneralImageSelector'
import { WorkOrdersContext } from '../../../context'
import SaveIcon from '@mui/icons-material/Save'
import { useDispatch, useSelector } from 'react-redux'
import { filesActions, itemsActions } from 'src/ducks/actions'
import { FILE_TYPE, ParseFilesUrl } from 'src/helpers'
import { getEstimateId } from 'src/ducks/selectors'
import env from '@beam-australia/react-env'

const FILES_URL = env('FILES_URL') ?? ''

const propsAreEqual = (prevProps: ImageProps, nextProps: ImageProps) => {
  return (
    JSON.stringify(prevProps.images) === JSON.stringify(nextProps.images) &&
    JSON.stringify(prevProps.imageFiles) ===
      JSON.stringify(nextProps.imageFiles) &&
    prevProps.isSlider === nextProps.isSlider &&
    prevProps.hasImageLoader === nextProps.hasImageLoader &&
    prevProps.submitAction === nextProps.submitAction
  )
}

const MemoImagesSelector = memo((props: ImageProps) => {
  const {
    submitAction,
    images,
    imageFiles,
    isSlider,
    hasImageLoader,
    handleAllImage,
    isNewSelector,
  } = props

  return (
    <GeneralImagesSelector
      multiple
      submitAction={submitAction}
      images={images}
      imageFiles={imageFiles}
      height="80px"
      columns={4}
      // isSlider={isSlider}
      hasImageLoader={hasImageLoader}
      hasAllImagesButton
      width={450}
      imageNumber={2}
      handleAllImage={() => {
        if (handleAllImage) handleAllImage()
      }}
      minHeight={'80px'}
      minWidth={'80px'}
    />
  )
}, propsAreEqual)

const AddOcrItem = () => {
  const styles = useStyles()

  const dispatch = useDispatch()

  const { dispatch: dispatchContext } = useContext(WorkOrdersContext)

  const estimateId = useSelector(getEstimateId())

  const [images, setImages] = useState<File[]>([])
  const [note, setNote] = useState<string>('')
  const [ref, setRef] = useState<string>('')
  const [heading, setHeading] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const submitAction = (newImages: File[]) => {
    setImages(newImages)
  }

  const uploadImages = (auxAction?: 'CREATE_NEW' | 'CLONE') => {
    if (images) {
      dispatch(
        filesActions.uploadFiles(
          images as File[],
          (uploadSucc, fileNameList) => {
            if (
              uploadSucc &&
              fileNameList &&
              fileNameList.length === (images as File[]).length
            ) {
              const imageFiles = (images as File[]).map((_image, index) => {
                const fileName = fileNameList[index]
                return {
                  name: fileName,
                  fileUrl: ParseFilesUrl({
                    baseUrl: FILES_URL,
                    avatar: fileName ?? '',
                  }),
                  description: '',
                  fileType: FILE_TYPE.INSPECTION_IMAGE,
                }
              })
                handleCreate({ imageFiles })
            }
          }
        )
      )
    } else {
        handleCreate({})
    }
  }

  const handleAddOcrItem = () => {
    uploadImages('CREATE_NEW')
  }

  const handleClose = () => {
    dispatchContext({ type: 'SET_MODAL_OPEN', payload: false })
    dispatchContext({ type: 'RESET_ITEM_VALUE' })
    dispatchContext({ type: 'RESET_EDIT_ITEM_VALUE' })
  }

  const handleCreate = ({ imageFiles }: { imageFiles?: any }) => {
    setLoading(true)

    const request = {
      inspectionReportNote: note,
      inspectionReportNoteHeader: heading,
      inspectionReportReference: ref,
      estimateId: estimateId,
      isEstimate: true,
      imageFiles: imageFiles || [],
      title: 'Untitled',
      category: 'UNCATEGORIZED',
      quantity: 1,
    }

    dispatch(
      itemsActions.createItem(request, (success) => {
        dispatchContext({ type: 'SET_PAGE_LOADING', payload: false })
        dispatchContext({
          type: 'SET_ITEM_VALUE',
          payload: { attr: 'images', value: [] },
        })
        if (success) {
          dispatch(
            itemsActions.fetchItems(
              { params: { jobId: estimateId } },
              (succ) => {
                setLoading(false)
                handleClose()
              }
            )
          )
          dispatchContext({ type: 'RESET_ITEM_VALUE' })
        }
      })
    )
  }

  return (
    <Grid container flexDirection="column" spacing={1} padding={2}>
      <Grid item>
        <Typography className={styles.title}>OCR Add New Item</Typography>
      </Grid>
      <Grid item>
        <MemoImagesSelector
          submitAction={submitAction}
          images={images}
          // imageFiles={item.imageFiles || []}
          imageFiles={[]}
          isSlider={false}
          hasImageLoader={true}
          handleAllImage={() => {
            dispatchContext({ type: 'SET_MODAL_OPEN', payload: true })
            dispatchContext({ type: 'SET_MODAL_TYPE', payload: 'ALL_IMAGES' })
          }}
          isNewSelector={true}
        />
      </Grid>
      <Grid item container spacing={1}>
        <Grid item container spacing={1}>
          <Grid item xs={6}>
            <TextFieldLabel
              label="Ref"
              value={ref}
              onChange={(e) => setRef(e.target.value)}
              placeholder="Ref"
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldLabel
              label="Heading"
              value={heading}
              onChange={(e) => setHeading(e.target.value)}
              placeholder="Add Heading"
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TextFieldLabel
            label="Notes"
            value={note}
            onChange={(e) => setNote(e.target.value)}
            multiline
            rows={2}
            fullWidth
            required
          />
        </Grid>
      </Grid>
      <Grid item container justifyContent="flex-end" spacing={1} mt={1}>
        <Button variant="containedLightBig" onClick={handleClose}>Cancel</Button>
        <Button
          variant="containedBig"
          endIcon={loading? <CircularProgress size='1rem' color='info' /> : <SaveIcon style={{ width: '16px', height: '16px' }} />}
          onClick={handleAddOcrItem}
          disabled={!note || loading}
        >
          Save
        </Button>
      </Grid>
    </Grid>
  )
}

export default AddOcrItem
