import { Box, Button, Grid, Tooltip, Typography } from '@mui/material'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import { AlignType } from 'src/components/UI/CustomUI/organisms/Table/types'
import { License, Vendor } from 'src/ducks/types'
import { getNonValidLicenses, hasNonValidLicense } from 'src/helpers'

// HeaderType[]
const headers = (
  setSelected: React.Dispatch<React.SetStateAction<Vendor | null>>,
  dispatched: boolean,
  showRecommended: boolean,
  //function to open modal
  openModalLicenses: (val: boolean | null, vendor: Vendor | null) => void
  //onClick: () => void
  
  //React.Dispatch<React.SetStateAction<boolean | null>>
) => {

  const rank = []

  const handleClick = (row : Vendor) => {
    if (hasNonValidLicense(row?.licenseList)) {
      openModalLicenses(true, row)
    } else {
      setSelected(row)
    }
  }

  if (showRecommended) rank.push({ id: 'rank', label: 'Rank', align: 'center' as AlignType })

  return [
    ...rank,
    {
      id: 'companyName',
      label: 'Company',
      custom: true,
      Element: (row: Vendor): JSX.Element => {
        return (
          <Typography variant='body1' style={{ lineBreak: 'anywhere' }}>{row.companyName}</Typography>
        )
      }
    },
    {
      id: 'contact',
      label: 'Name & Mail',
      custom: true,
      Element: (row: Vendor): JSX.Element => {
        return (
          <Grid>
            <Typography variant='body1'>
              {row?.firstName} {row?.lastName}
            </Typography>
            <Typography variant='caption' style={{ lineBreak: 'anywhere' }}>
              {row?.email[0]?.email}
            </Typography>
          </Grid>
        )
      }
    },
    {
      id: 'location',
      label: 'Location',
      custom: true,
      Element: (row: Vendor): JSX.Element => {
        return (
          <Typography variant='body1'>
            {row?.address?.city}, {row?.address?.state}
          </Typography>
        )
      }
    },
    {
      id: 'trade',
      label: 'Trade',
      custom: true,
      Element: (row: Vendor): JSX.Element => {
        const primaryTrade = row?.tradeList.find(trade => trade.primaryTrade)
        return (
          <Typography variant='body1'>
            {primaryTrade?.tradeType?.displayName}
          </Typography>
        )
      }
    },
    {
      id: 'level',
      label: 'Level',
      custom: true,
      align: 'center' as AlignType,
      maxWidth: 50,
      Element: (row: Vendor): JSX.Element => {
        const primaryTrade = row?.tradeList.find(trade => trade.primaryTrade)
        return (
          <Typography variant='body1'>
            {primaryTrade?.level}
          </Typography>
        )
      }
    },
    {
      id: 'activeJobs',
      label: 'Active Jobs',
      custom: true,
      align: 'center' as AlignType,
      Element: (row: Vendor): JSX.Element => {
        return (
          <Typography variant='body1'>
            {row?.activeOrders?.length || 0}
          </Typography>
        )
      }
    },
    {
      id: 'completedYTD',
      align: 'center' as AlignType,
      label: 'Completed YTD'
    },
    {
      id: 'lastCompletedJob',
      label: 'Last Completed Job',
      custom: true,
      align: 'center' as AlignType,
      Element: (row: Vendor): JSX.Element => {
        return (
          <Typography variant='body1'>
            -
          </Typography>
        )
      }
    },
    {
      id: 'distance',
      label: 'Distance',
      custom: true,
      align: 'center' as AlignType,
      Element: (row: Vendor): JSX.Element => {
        return (
          <Typography variant='body1'>
            -
          </Typography>
        )
      }
    },
    {
      id: 'scorecard',
      label: 'Scorecard',
      custom: true,
      align: 'center' as AlignType,
      Element: (row: Vendor): JSX.Element => {
        return (
          <Typography variant='body1'>
            -
          </Typography>
        )
      }
    },
    {
      id: 'action',
      label: 'Action',
      custom: true,
      align: 'center' as AlignType,
      Element: (row: any): JSX.Element => {
        if (row.selected) {
          return (
            <Box display='flex' justifyContent='center'>
              <Typography color='var(--blue700)'> Selected </Typography>
              <Icon sx={{ marginLeft: '.2rem' }} width='.8rem' name='CheckCircle' color='var(--blue700)' />
            </Box>
          )
        }
        return (
          <Tooltip title={dispatched ? 'Job must be revoked before selecting a new Pro' : ''}>
            <div>
              <Button
                variant='contained'
                size='small'
                disabled={dispatched}
                sx={{ height: '32px' }}
                onClick={() => { handleClick(row) }}
              >
                Select
              </Button>
            </div>
          </Tooltip>
        )
      }
    }
  ]
}

export default headers
