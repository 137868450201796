export const AUTH0_CODES = {
  INVALID_PASSWORD: 'invalid_password',
  INVALID_SIGNUP: 'invalid_signup',
}

export enum EMAIL_TYPE {
  PRIMARY = 'PRIMARY',
  CC = 'CC',
  BCC = 'BCC',
}

export enum CLIENT_TYPE {
  HOMEOWNER = 'HOMEOWNER',
  HOME_BUYER = 'HOME_BUYER',
  AGENT = 'AGENT',
  CLOSING_COORDINATOR = 'CLOSING_COORDINATOR',
  INSTITUTIONAL = 'INSTITUTIONAL',
  BROKER = 'BROKER',
}

export enum EMPLOYEE_ROLE_TYPE {
  TERRITORY_MANAGER = 'TERRITORY_MANAGER',
  TERRITORY_SUCCESS_MANAGER = 'TERRITORY_SUCCESS_MANAGER',
  ESTIMATOR = 'ESTIMATOR',
  TERRITORY_CUSTOMER_CARE = 'TERRITORY_CUSTOMER_CARE',
  MANAGER = 'MANAGER',
  SUCCESS_MANAGER = 'SUCCESS_MANAGER',
  TERRITORY_LICENSE_PARTNER = 'TERRITORY_LICENSE_PARTNER',
  ADMIN = 'ADMIN',
  SUPER_ADMIN = 'SUPER_ADMIN',
  TERRITORY_AGENT = 'TERRITORY_AGENT',
  TERRITORY_ESTIMATOR = 'TERRITORY_ESTIMATOR',
  TERRITORY_ESTIMATOR_MANAGER = 'TERRITORY_ESTIMATOR_MANAGER',
  TERRITORY_VENDOR = 'TERRITORY_VENDOR',
  TERRITORY_VENDOR_MANAGER = 'TERRITORY_VENDOR_MANAGER',
  FIELD_MANAGER = 'FIELD_MANAGER',
  SALES = 'SALES',
  TERRITORY_SUPERVISOR = 'TERRITORY_SUPERVISOR',
  TERRITORY_BUSINESS_DEV_MANAGER = 'TERRITORY_BUSINESS_DEV_MANAGER',
  TERRITORY_SUPERINTENDENT = 'TERRITORY_SUPERINTENDENT',
  CLOUDFACTORY = 'CLOUDFACTORY',
}

export enum ORDER_TYPE {
  ORDER = 'ORDER',
  SITE_VISIT = 'SITE_VISIT',
  RECALL = 'RECALL',
  WARRANTY = 'WARRANTY',
}

export enum ORDER_STATUS {
  APPROVED = 'APPROVED',
  DISPATCHED = 'DISPATCHED',
  REJECTED = 'REJECTED',
  REVIEW = 'REVIEW',
  COMPLETE = 'COMPLETE',
  INVOICED = 'INVOICED',
  PAID = 'PAID',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING = 'PENDING',
  ON_HOLD = 'ON_HOLD',
  PARENT_REVIEW = 'PARENT_REVIEW',
}

export enum DISPATCH_STATUS {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  REVOKED = 'REVOKED',
  ASSIGNED = 'ASSIGNED',
}

export enum AFFILIATE_TYPE {
  BROKER = 'BROKER',
  INSTITUTIONAL = 'INSTITUTIONAL',
  CONSUMER = 'CONSUMER',
  INSPECTOR = 'INSPECTOR',
  NONE = 'NONE',
}

export enum NOTE_TYPE {
  ACCOUNTING = 'ACCOUNTING',
  ORDER_STATUS = 'ORDER_STATUS',
  JOB_GENERAL = 'JOB_GENERAL',
}

export enum ORDER_ITEM_STATUS {
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
  COMPLETE = 'COMPLETE',
  APPROVED = 'APPROVED',
}

export enum ORDER_FILE_TYPE {
  INSPECTION_REPORT = 'INSPECTION_REPORT',
  ADDENDUM = 'ADDENDUM',
  PRO_REPORT = 'PRO_REPORT',
}

export enum ORDER_ITEM_IMAGE_TYPE {
  INSPECTION_IMAGE = 'INSPECTION_IMAGE',
  PRO_IMAGE = 'PRO_IMAGE',
}

export enum ITEM_MARGIN_TYPE {
  VENDOR_MARGIN = 'VENDOR_MARGIN',
  PL_MARGIN = 'PL_MARGIN',
  CUSTOM = 'CUSTOM',
}

export enum PRICING_ITEM_TYPE {
  ITEM_MARGIN = 'ITEM_MARGIN',
  INCENTIVE = 'INCENTIVE',
  DRAW = 'DRAW',
}

export enum RESOURCE_TYPE {
  ESTIMATE = 'ESTIMATE',
  JOB = 'JOB',
  PRO = 'PRO',
}

export enum ESTIMATE_STATUS {
  NEW = 'NEW',
  COMPOSING = 'COMPOSING',
  IN_REVIEW = 'IN_REVIEW',
  RELEASED = 'RELEASED',
  EXPIRED = 'EXPIRED',
  DECLINED = 'DECLINED',
  NOT_SUBMITTED = 'NOT_SUBMITTED',
  PENDING_APPROVALS = 'PENDING_APPROVALS',
  APPROVED = 'APPROVED',
  OCR_REVIEW = 'OCR_REVIEW',
  OCR_PROCESSING = 'OCR_PROCESSING',
  SCHEDULING = 'SCHEDULING',
  COMPLETE = 'COMPLETE',
  CLOSED = 'CLOSED',
  READY_FOR_RELEASE = 'READY_FOR_RELEASE',
}

export enum ESTIMATE_PUBLIC_STATUS {
  IN_PROGRESS = 'In Progress',
  EXPIRED = 'Expired',
  COMPLETED = 'Completed',
  APPROVED = 'Approved',
}

export enum ITEM_STATUS {
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
  COMPLETE = 'COMPLETE',
  APPROVED = 'APPROVED',
  NEW = 'NEW',
}

export enum ITEM_CATEGORY {
  ELECTRICAL = 'ELECTRICAL',
  PLUMBING = 'PLUMBING',
  HVAC = 'HVAC',
  WINDOWS_DOORS = 'WINDOWS_DOORS',
  MISCELLANEOUS = 'MISCELLANEOUS',
  CL100 = 'CL100',
  CRAWLSPACE = 'CRAWLSPACE',
  EXTERIOR = 'EXTERIOR',
  INTERIOR = 'INTERIOR',
  ATTIC = 'ATTIC',
  ROOF = 'ROOF',
  UNCATEGORIZED = 'UNCATEGORIZED',
  EVALUATE = 'EVALUATE',
  EXCLUDED = 'EXCLUDED',
}

export enum ITEM_PRIORITY {
  Urgent = 'Urgent',
  High = 'High',
  Medium = 'Medium',
  Low = 'Low',
  Cosmetic = 'Cosmetic',
}

export enum STATUS {
  SCHEDULING = 'SCHEDULING',
  DEPOSIT_PENDING = 'DEPOSIT_PENDING',
  DISPATCH_PENDING = 'DISPATCH_PENDING',
  DISPATCHED = 'DISPATCHED',
  REVIEW = 'REVIEW',
  COMPLETE = 'COMPLETE',
  RECALLED = 'RECALLED',
  CLOSED = 'CLOSED',
  NOT_INVOICED = 'NOT_INVOICED',
  INVOICE_SENT = 'INVOICE_SENT',
  PARTIAL_PAYMENT = 'PARTIAL_PAYMENT',
  IN_COLLECTION = 'IN_COLLECTION',
  APPROVED = 'APPROVED',
  OVERDUE = 'OVERDUE',
  NEW = 'NEW',
}

export enum JOB_STATUS_FILTERS {
  ALL = 'All',
  ACTIVE = 'Active',
  SCHEDULING = 'Scheduling',
  DEPOSIT_PENDING = 'Deposit Pending',
  DISPATCH_PENDING = 'Dispatch Pending',
  DISPATCHED = 'Dispatched',
  NEEDS_REVIEW = 'Needs Review',
  COMPLETE_NOT_INVOICED = 'Complete - Not Invoiced',
  COMPLETE_INVOICED = 'Complete - Invoiced',
  COMPLETE_PARTIALLY_PAID = 'Complete - Partially Paid',
  COMPLETE_IN_COLLECTION = 'Complete - In Collection',
  CLOSED = 'Closed',
}

export enum ESTIMATE_STATUS_FILTERS {
  ALL = 'All',
  NEW = 'New',
  COMPOSING = 'Composing',
  IN_REVIEW = 'In Review',
  RELEASED = 'Released',
  EXPIRED = 'Expired',
  DECLINED = 'Declined',
  NOT_SUBMITTED = 'Not Submitted',
  PENDING_APPROVALS = 'Pending Approvals',
  APPROVED = 'Approved',
  OCR_REVIEW = 'Ocr Review',
  OCR_PROCESSING = 'Ocr Processing',
  PROCESSING_ACH_PAYMENT = 'Processing ACH Payment',
}

export enum JOB_PUBLIC_STATUS {
  SCHEDULING = 'Scheduling',
  DISPATCHED = 'Dispatched',
  IN_PROGRESS = 'In Progress',
  HOLD = 'Hold',
  COMPLETED = 'Completed',
  NEEDS_ATTENTION = 'Needs Attention',
}

export enum JOB_ITEM_PUBLIC_STATUS {
  SCHEDULED = 'Scheduled',
  DISPATCHED = 'Dispatched',
  IN_PROGRESS = 'In Progress',
  HOLD = 'Hold',
  COMPLETED = 'Completed',
  NEEDS_ATTENTION = 'Needs Attention',
}

export enum FORM_TYPE {
  CLIENT_INSPECTION_REPORT = 'CLIENT_INSPECTION_REPORT',
  CLIENT_REPAIR_LIST = 'CLIENT_REPAIR_LIST',
  INTERNAL = 'INTERNAL',
  LEGACY_FORM = 'LEGACY_FORM',
  PUNCHLIST_API = 'PUNCHLIST_API',
}

export enum REPAIR_TIMELINE {
  PRELISTING = 'PRELISTING',
  ASAP = 'ASAP',
  BEFORE_CLOSE = 'BEFORE_CLOSE',
  AFTER_CLOSE = 'AFTER_CLOSE',
  NEVER = 'NEVER',
  ONE_WEEK = 'ONE_WEEK',
  THIRTY_DAYS = 'THIRTY_DAYS',
  SIXTY_DAYS = 'SIXTY_DAYS',
  OVER_SIXTY_DAYS = 'OVER_SIXTY_DAYS',
}

export enum CONTACT_ROLE {
  LISTING_AGENT = 'LISTING_AGENT',
  BUYERS_AGENT = 'BUYERS_AGENT',
  BUYERS_CLOSING_COORDINATOR = 'BUYERS_CLOSING_COORDINATOR',
  SELLERS_CLOSING_COORDINATOR = 'SELLERS_CLOSING_COORDINATOR',
  HOMEOWNER = 'HOMEOWNER',
  INSPECTOR = 'INSPECTOR',
  OTHER = 'OTHER',
  HOME_BUYER = 'HOME_BUYER',
  HOME_SELLER = 'HOME_SELLER',
  INSTITUTIONAL_INVESTOR = 'INSTITUTIONAL_INVESTOR',
  AGENT_HOMEOWNER = 'AGENT_HOMEOWNER'
}

export enum CONTACT_ROLE_TEXT {
  LISTING_AGENT = 'Selling/Listing Agent',
  BUYERS_AGENT = 'Buyers Agent',
  BUYERS_CLOSING_COORDINATOR = 'Closing Coordinator - Buyer`s Agent',
  SELLERS_CLOSING_COORDINATOR = 'Closing Coordinator - Seller`s Agent',
  HOMEOWNER = 'Homeowner',
  HOME_BUYER = 'Homebuyer',
  INSPECTOR = 'Inspector',
  INSTITUTIONAL_INVESTOR = 'Institutional Investor',
  OTHER = 'Other',
}

export const CONTACT_ROLE_OPTIONS = [
  { key: CONTACT_ROLE.LISTING_AGENT, label: CONTACT_ROLE_TEXT.LISTING_AGENT },
  { key: CONTACT_ROLE.BUYERS_AGENT, label: CONTACT_ROLE_TEXT.BUYERS_AGENT },
  {
    key: CONTACT_ROLE.BUYERS_CLOSING_COORDINATOR,
    label: CONTACT_ROLE_TEXT.BUYERS_CLOSING_COORDINATOR,
  },
  {
    key: CONTACT_ROLE.SELLERS_CLOSING_COORDINATOR,
    label: CONTACT_ROLE_TEXT.SELLERS_CLOSING_COORDINATOR,
  },
  { key: CONTACT_ROLE.HOMEOWNER, label: CONTACT_ROLE_TEXT.HOMEOWNER },
  { key: CONTACT_ROLE.INSPECTOR, label: CONTACT_ROLE_TEXT.INSPECTOR },
  { key: CONTACT_ROLE.OTHER, label: CONTACT_ROLE_TEXT.OTHER },
]

export enum APPROVAL_TYPE {
  NOT_NEEDED = 'NOT_NEEDED',
  REQUIRED = 'REQUIRED',
  APPROVED = 'APPROVED',
  PAY_AT_CLOSE = 'PAY_AT_CLOSE',
}

// Adjustment user emails, hardcoded now
export const ADJUSTMENT_USERS = [
  'paul.dorney@bosscathome.com',
  'paul.dorney@punchlistusa.com',
  'john.doyle@bosscathome.com',
  'john.doyle@punchlistusa.com',
  'alicia.bieringer@bosscathome.com',
  'alicia.bieringer@punchlistusa.com',
  'danielle.alexander@punchlistsc.com',
  'becky@bosscathome.com',
  'becky@punchlistusa.com',
  'brian.glabe@bosscathome.com',
  'brian.glabe@punchlistusa.com',
  'stefan@bosscathome.com',
  'stefan@punchlistusa.com',
  'Curt.Heatley@punchlistUSA.com',
  'eriko.dowd@punchlistusa.com',
]

export enum FILE_TYPE {
  INSPECTION_REPORT = 'INSPECTION_REPORT',
  INSPECTION_IMAGE = 'INSPECTION_IMAGE',
  ADDENDUM = 'ADDENDUM',
  PRO_REPORT = 'PRO_REPORT',
  PRO_IMAGE = 'PRO_IMAGE',
  MATERIAL_INVOICE = 'MATERIAL_INVOICE',
  DEPOSIT_ATTACHMENT = 'DEPOSIT_ATTACHMENT',
  DOCUMENT = 'DOCUMENT',
  REPAIR_LIST = 'REPAIR_LIST',
  LISTING_AGREEMENT = 'LISTING_AGREEMENT',
  COMPARABLE_MARKET_ANALYSIS = 'COMPARABLE_MARKET_ANALYSIS',
  INSPECTION_PDF = 'INSPECTION_PDF',
  PRICE_MATCH = 'PRICE_MATCH',
}

export enum CREDIT_CARD_TYPE {
  VISA = 'VISA',
  MASTERCARD = 'MASTERCARD',
}

export enum ESTIMATE_VIEW {
  TYPE = 'Type',
  PRIORITY = 'Priority',
  APPROVED = 'Approved',
}

export enum JOB_VIEW {
  CATEGORY = 'category',
  STATUS = 'status',
}

export enum FEEDBACK_TYPE {
  ORDER = 'ORDER',
  ESTIMATE = 'ESTIMATE',
  ITEM = 'ITEM',
  SITE_VISIT = 'SITE_VISIT',
  JOB = 'JOB',
  VENDOR = 'VENDOR',
}

export enum PAYMENT_TYPE {
  CHECK = 'CHECK',
  CREDITCARD = 'CREDITCARD',
  ACH = 'ACH',
}

export enum PAYMENT_RESOURCE_TYPE {
  ORDER = 'ORDER',
  ESTIMATE = 'ESTIMATE',
  ITEM = 'ITEM',
  SITE_VISIT = 'SITE_VISIT',
  JOB = 'JOB',
  PAYMENT = 'PAYMENT',
}

export enum PAYMENT_STATUS {
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
}

export enum ESTIMATE_VIEW_COLORS_PRIORITY {
  Urgent = '#E01F1F',
  High = '#E01F1F',
  Medium = '#FFC633',
  Low = '#1CCD83',
  Cosmetic = '#5643D4',
}

export enum ESTIMATE_VIEW_COLORS_APPROVED {
  Approved = '#1CCD83',
  ApprovedBetter = '#1b8860',
  Rejected = '#E01F1F',
}

export const ESTIMATE_VIEW_COLORS_TYPES = [
  '#0E2E41',
  '#2B515D',
  '#4E7778',
  '#779E92',
  '#491A5F',
  '#7A23A3',
  '#BA72DB',
  '#5D55B4',
  '#3228AF',
  '#151050',
  '#EFBA32',
  '#BD8C0F',
  '#84620A',
  '#5F4607',
]

export const ESTIMATE_VIEW_BETTER_COLORS_TYPES = [
  '#073126',
  '#004733',
  '#99D6A9',
  '#CCE9D3',
  '#F0F7F1',
  '#BF271B',
  '#F2ABA6',
  '#F03333',
  '#F9B3B3',
  '#EF8032',
  '#F8C4A0',
  '#FFBC14',
  '#F8EBC9',
]

export enum LICENSE_TYPE {
  FORM_W9 = 'FORM_W9',
  IDENTIFICATION = 'IDENTIFICATION',
  TRADE_LICENSE = 'TRADE_LICENSE',
  TRADE = 'TRADE',
  OTHER = 'OTHER',
  BACKGROUND_CHECK = 'BACKGROUND_CHECK',
}

export enum INSURANCE_TYPE {
  WORKERS_COMPENSATION = 'WORKERS_COMPENSATION',
  CERTIFICATE_OF_INSURANCE = 'CERTIFICATE_OF_INSURANCE',
  OTHER = 'OTHER',
}

export enum REVIEW_STATUS {
  DRAFT = 'DRAFT',
  SUBMITTED = 'SUBMITTED',
  REVIEWED = 'REVIEWED',
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
  EXPIRED = 'EXPIRED',
}

export enum EXPIRACY_STATUS {
  EXPIRED = 'EXPIRED',
  ALMOST_EXPIRED_1MONTH = 'ALMOST_EXPIRED_1MONTH',
  ALMOST_EXPIRED_2WEEKS = 'ALMOST_EXPIRED_2WEEKS',
  ALMOST_EXPIRED_1WEEK = 'ALMOST_EXPIRED_1WEEK',
  VALID = 'VALID',
}

export enum USER_TYPE {
  PRO = 'PRO',
  CUSTOMER = 'CUSTOMER',
  PUNCHLIST = 'PUNCHLIST',
}

export enum MESSAGE_STATUS {
  SENT = 'SENT',
}

export const verificationStages = [
  // { key: 'all', label: 'All Verification Stages' },
  { key: 'Lead: Not Contacted', label: 'Lead: Not Contacted' },
  { key: 'Lead: 1st Attempt', label: 'Lead: 1st Attempt' },
  { key: 'Lead: 2nd Attempt', label: 'Lead: 2nd Attempt' },
  { key: 'Lead: 3rd Attempt', label: 'Lead: 3rd Attempt' },
  { key: 'Lead: Cold', label: 'Lead: Cold' },
  { key: 'Pending Approval', label: 'Pending Approval' },
  { key: 'Pending Background Check', label: 'Pending Background Check' },
  { key: 'Pending LMS Training', label: 'Pending LMS Training' },
  { key: 'Pending Orientation', label: 'Pending Orientation' },
  { key: 'Verified', label: 'Verified' },
  {
    key: 'Not Approved: Background Check',
    label: 'Not Approved: Background Check',
  },
  { key: 'Not Approved: Insurance', label: 'Not Approved: Insurance' },
  { key: 'Not Approved: Other', label: 'Not Approved: Other' },
  { key: 'Inactive: Not Interested', label: 'Inactive: Not Interested' },
  {
    key: 'Inactive: Due to Performance',
    label: 'Inactive: Due to Performance',
  },
  { key: 'Inactive: Insurance Lapse', label: 'Inactive: Insurance Lapse' },
  { key: 'Inactive: Non-Responsive', label: 'Inactive: Non-Responsive' },
  {
    key: 'Inactive: Background Check Fail',
    label: 'Inactive: Background Check Fail',
  },
  { key: 'Inactive: Prevent Use', label: 'Inactive: Prevent Use' },
]

export const ROWS_PER_PAGE_EXTRA_EXTENDED = [25, 50, 100, 200, 300, 400, 500]
export const ROWS_PER_PAGE_EXTENDED = [5, 10, 25, 50, 100, 250]
export const ROWS_PER_PAGE_SIMPLE = [5, 10, 25, 50]

export enum PRICE_MODEL_TYPE {
  PRICE_MODEL_NAR = 'NAR',
  PRICE_MODEL_AFFILIATE = 'Affiliate',
}

export enum PROS_PAGE_STATUS {
  PROS = 'PROS',
  LICENSES = 'LICENSES',
  INSURANCE = 'INSURANCE',
}

export enum PROTECT_PLAN {
  PLAN_A = 'PLAN_A',
  PLAN_B = 'PLAN_B',
  PLAN_C = 'PLAN_C',
  PLAN_D = 'PLAN_D',
}

export enum SERVICE_STATUS {
  UNINITIALIZED = 'UNINITIALIZED',
  REQUESTED = 'REQUESTED',
  SCHEDULED = 'SCHEDULED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETE',
}

export enum SUBSCRIPTION_STATUS {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  CANCELED = 'CANCELED',
  INCOMPLETE = 'INCOMPLETE',
}

export enum CLIENT_CATEGORY {
  NAR = 'NAR',
  INSTITUTIONAL = 'INSTITUTIONAL',
  BROKER = 'BROKER',
  AFFILIATE = 'AFFILIATE',
  OTHER = 'OTHER',
}

export enum ESTIMATE_APPROVAL_STATUS {
  NOT_APPROVED = 'NOT_APPROVED',
  MISSING_DETAILS = 'MISSING_DETAILS',
  PENDING_FURTHER = 'PENDING_FURTHER',
  APPROVED = 'APPROVED',
  PROCESSING_ACH_PAYMENT = 'PROCESSING_ACH_PAYMENT',
}

export const SEVERITY_OPTIONS = [
  {
    key: 'Cosmetic',
    label: 'Cosmetic',
  },
  {
    key: 'Low',
    label: 'Low',
  },
  {
    key: 'Medium',
    label: 'Medium',
  },
  {
    key: 'High',
    label: 'High',
  },
  {
    key: 'Urgent',
    label: 'Urgent',
  },
]

export const ITEM_CATEGORY_OPTIONS = [
  {
    key: ITEM_CATEGORY.ELECTRICAL,
    label: ITEM_CATEGORY.ELECTRICAL,
  },
  {
    key: ITEM_CATEGORY.PLUMBING,
    label: ITEM_CATEGORY.PLUMBING,
  },
  {
    key: ITEM_CATEGORY.HVAC,
    label: ITEM_CATEGORY.HVAC,
  },
  {
    key: ITEM_CATEGORY.WINDOWS_DOORS,
    label: ITEM_CATEGORY.WINDOWS_DOORS,
  },
  {
    key: ITEM_CATEGORY.MISCELLANEOUS,
    label: ITEM_CATEGORY.MISCELLANEOUS,
  },
  {
    key: ITEM_CATEGORY.CL100,
    label: ITEM_CATEGORY.CL100,
  },
  {
    key: ITEM_CATEGORY.CRAWLSPACE,
    label: ITEM_CATEGORY.CRAWLSPACE,
  },
  {
    key: ITEM_CATEGORY.EXTERIOR,
    label: ITEM_CATEGORY.EXTERIOR,
  },
  {
    key: ITEM_CATEGORY.INTERIOR,
    label: ITEM_CATEGORY.INTERIOR,
  },
  {
    key: ITEM_CATEGORY.ATTIC,
    label: ITEM_CATEGORY.ATTIC,
  },
  {
    key: ITEM_CATEGORY.ROOF,
    label: ITEM_CATEGORY.ROOF,
  },
  {
    key: ITEM_CATEGORY.UNCATEGORIZED,
    label: ITEM_CATEGORY.UNCATEGORIZED,
  },
  {
    key: ITEM_CATEGORY.EVALUATE,
    label: ITEM_CATEGORY.EVALUATE,
  },
  {
    key: ITEM_CATEGORY.EXCLUDED,
    label: ITEM_CATEGORY.EXCLUDED,
  },
]

export const PARTNER_CATEGORY_OPTIONS = [
  {
    key: 'MOVING_SERVICES',
    label: 'Moving Services',
  },
  {
    key: 'PHOTOGRAPHY',
    label: 'Photography',
  },
  {
    key: 'HOME_WARRANTY',
    label: 'Home Warranty',
  },
  {
    key: 'INTERIOR_DESIGN',
    label: 'Interior Design',
  },
  {
    key: 'TV_INTERNET_VOICE_AND_MOBILE',
    label: 'TV Internet Voice & Mobile',
  },
  {
    key: 'HOME_CHARGING_SOLUTIONS',
    label: 'Home Changing Solutions',
  },
  {
    key: 'UTILITY_CONCIERGE_SERVICES',
    label: 'Utility Concierge Services',
  },
  {
    key: 'TRASH_REMOVAL',
    label: 'Trash Removal',
  },
  {
    key: 'INSURANCE',
    label: 'Insurance',
  },
  {
    key: 'TITLE',
    label: 'Title',
  },
  {
    key: 'STAGING',
    label: 'Staging',
  },
  {
    key: 'PEST_CONTROL',
    label: 'Pest Control',
  },
  {
    key: 'LANDSCAPING',
    label: 'Landscaping',
  },
  {
    key: 'CLEANING',
    label: 'Cleaning',
  },
  {
    key: 'RESTORATION',
    label: 'Restoration',
  },
  {
    key: 'SOLAR',
    label: 'Solar',
  },
  {
    key: 'HOME_FURNISHING_AND_DECOUR',
    label: 'Home Furnishing & Decor',
  },
  {
    key: 'STORAGE',
    label: 'Storage',
  },
  {
    key: 'FURNITURE_ASSEMBLY',
    label: 'Furniture Assembly',
  },
  {
    key: 'CABINETS',
    label: 'Cabinets',
  },
  {
    key: 'RENTAL_CAR',
    label: 'Rental Car',
  },
  {
    key: 'SPA',
    label: 'Spa',
  },
  {
    key: 'AUTO_WARRANTY',
    label: 'Auto Warranty',
  },
  {
    key: 'ROOFING_GUTTERS_AND_SIDING',
    label: 'Roofing, Gutters & Siding',
  },
  {
    key: 'PAINTING',
    label: 'Painting',
  },
  {
    key: 'HOME_SECURITY',
    label: 'Home Security',
  },
  {
    key: 'SMART_HOME',
    label: 'Smart Home',
  },
  {
    key: 'HOME_INSPECTION',
    label: 'Home Inspection',
  },
  {
    key: 'WINDOWS_AND_DOORS',
    label: 'Windows, Doors',
  },
  {
    key: 'OUTDOOR_LIVING',
    label: 'Outdoor Living',
  },
  {
    key: 'STORAGE_AND_ORGANIZATION',
    label: 'Storage & Organization',
  },
  {
    key: 'BLINDS_AND_WINDOW_TREATMENTS',
    label: 'Blinds and Window Treatments',
  },
  {
    key: 'RECREATION',
    label: 'Recreation',
  },
]

export const PARTNER_ROLE_OPTIONS = [
  {
    key: 'ALL',
    label: 'All',
  },
  {
    key: 'HOMEOWNER',
    label: 'Homeowners',
  },
  {
    key: 'CONTRACTOR',
    label: 'Contractors',
  },
  {
    key: 'REALTOR',
    label: 'Realtors',
  },
  {
    key: 'PROPERTY_MANAGER',
    label: 'Property Managers',
  },
  {
    key: 'INVESTOR',
    label: 'Investors',
  },
]

export const DOCUMENT_TYPE_OPTIONS = [
  {
    key: 'LISTING_AGREEMENT',
    value: 'Listing Agreement',
  },
  {
    key: 'CLIENT_CONTRACT',
    value: 'Client Contract',
  },
  {
    key: 'INVOICE_PDF',
    value: 'Invoice',
  },
  {
    key: 'MATERIAL_INVOICE',
    value: 'Material Invoice',
  },
  {
    key: 'INSPECTION_REPORT',
    value: 'Inspection Report',
  },
  {
    key: 'INSPECTION_IMAGE',
    value: 'Inspection Image',
  },
  {
    key: 'ADDENDUM',
    value: 'Addendum',
  },
  {
    key: 'PRO_REPORT',
    value: 'Pro Report',
  },
  {
    key: 'PRO_IMAGE',
    value: 'Pro Image',
  },
  {
    key: 'REPAIR_LIST',
    value: 'Repair List',
  },
  {
    key: 'DEPOSIT_ATTACHMENT',
    value: 'Deposit Attachment',
  },
  {
    key: 'GOOGLE_SEARCH_IMAGE',
    value: 'Google Search Image',
  },
  {
    key: 'RENOWALK_PDF',
    value: 'Renowalk PDF',
  },
  {
    key: 'PRICE_MATCH',
    value: 'Price Match',
  },
  {
    key: 'PRO_ESTIMATE',
    value: 'Pro Estimate',
  },
  {
    key: 'CUSTOMER_CORRESPONDENCE',
    value: 'Customer Correspondence',
  },
  {
    key: 'CHANGE_ORDER',
    value: 'Change Order',
  },
]

export const workOrderItemFilterOptions = [
  {
    key: 'all',
    label: 'All',
  },
  {
    key: 'open',
    label: 'Open Items',
  },
  {
    key: 'closed',
    label: 'Closed Items',
  },
]

export const pcViewItemFilterOptions = [
  {
    key: 'open_wo',
    label: 'Open Work Orders',
  },
  {
    key: 'closed_wo',
    label: 'Closed Work Orders',
  },
  {
    key: 'divider',
    label: '',
    isDivider: true
  },
  {
    key: 'job_status',
    label: 'Job Status',
  },
  {
    key: 'work_order_status',
    label: 'Work Order Status',
  },
]

export const pcViewJobStatusOptions = [
  {
    key: 'all',
    label: 'All Statuses',
  },
  {
    key: 'scheduled',
    label: 'Scheduled',
  },
  {
    key: 'inProgress',
    label: 'In Progress',
  },
  {
    key: 'complete',
    label: 'Completed',
  },
  {
    key: 'needsAttention',
    label: 'Needs Attention',
  },
]

export const pcViewWOStatusOptions = [
  {
    key: 'all',
    label: 'All Status',
  },
  {
    key: 'notStarted',
    label: 'Not Started',
  },
  {
    key: 'inProgress',
    label: 'In Progress',
  },
  {
    key: 'pendingSelection',
    label: 'Pending Selection',
  },
  {
    key: 'pendingQC',
    label: 'Pending QC',
  },
  {
    key: 'complete',
    label: 'Work Order Complete',
  },
]
