import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Grid, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'

import { WorkflowItem, WORKFLOW_TYPES } from 'src/ducks/types'
import { historyActions, jobActions, notesActions, workflowsActions } from 'src/ducks/actions'

import { TextFieldLabel } from 'src/components/UI'

import Modal from 'src/components/UI/CustomUI/molecules/Modal'
import DatePicker from 'src/components/UI/CustomUI/atoms/DatePicker'

import styles from './styles.module.scss'
import moment from 'moment'
import { formatDateTimestamp, formatTimestampDate, STATUS, NOTE_TYPE, RESOURCE_TYPE, isEmpty } from 'src/helpers'

const ModalActions = ({ item, isZero = false, isSkip = false }: { item: WorkflowItem, isZero?: boolean, isSkip?: boolean }) => {
  const dispatch = useDispatch()
  const { id } = useParams()

  const [values, setValues] = useState<any>()
  const [note, setNote] = useState('')
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [completionDate, setCompletionDate] = useState(new Date().getTime() / 1000)

  const triggerAction = (action?: string) => {
    setLoading(true)
    action && dispatch(
      workflowsActions.triggerWorkflowAction({
        request: action,
        params: { jobId: id }
      },
        (success) => {
          postSuccess(success)
        })
    )
    if (!action) {
      setLoading(false)
    }
  }

  const postSuccess = (success: boolean) => {
    setOpen(!open)
    setValues([])
    setNote('')
    setLoading(false)
    if (success) {
      dispatch(workflowsActions.fetchWorkflowsInstance({ params: { page: 0, size: 99999, jobId: id } }))
      dispatch(historyActions.fetchHistoryJobTask(id as string, (_succ: boolean) => { }))

    }
  }

  const updateJob = () => {

    const request: any = {}
    setLoading(true)

    Object.entries(values).forEach(([key, value]) => {
      request[key] = typeof value === 'object' ? Number(moment(value as unknown as Date).format('X')) : value
    })

    dispatch(
      jobActions.updateJob({
        ...request
      },
        (success) => {
          postSuccess(success)
        }
      )
    )
  }

  const getActionToTrigger = (subTask?: string) => {
    if (isSkip) {
      setLoading(true)
      if (item.orderId)
        return dispatch(workflowsActions.skipWorkflowAction({ orderId: item.orderId },
          (success) => {
            postSuccess(success)
          }))
      return dispatch(workflowsActions.skipWorkflowAction({ jobId: id },
        (success) => {
          postSuccess(success)
        }))
    }
    switch (item.type) {


      case WORKFLOW_TYPES.MARK_JOB_COMPLETE:
        //meter validacion completiondate
        if (!isEmpty(completionDate.toString())) {

          dispatch(jobActions.updateJob({
            completedOn: completionDate,
            status: STATUS.COMPLETE
          }, (suc) => {
            setLoading(false)
          }))
          return triggerAction('JOB_MARKED_COMPLETE')
        } else {
          setLoading(false)
          return ''
        }

      case WORKFLOW_TYPES.SETUP_JOB:
        if (!isEmpty(completionDate.toString())) {
          // dispatch(jobActions.updateJob({
          //   completedOn: completionDate
          // }, (suc) => {
          //   setLoading(false)
          // }))
          return triggerAction('JOB_SETUP_COMPLETE')
        } else {
          setLoading(false)
          return ''
        }

      case WORKFLOW_TYPES.SUBMIT_CHANGE_ORDER:
        return triggerAction('CHANGE_ORDER_REQUEST_SUBMITTED')
      case WORKFLOW_TYPES.CLIENT_FINAL_REVIEW:
      case WORKFLOW_TYPES.REVIEW_FINAL_WALKTHROUGH:
        return triggerAction(subTask)
      case WORKFLOW_TYPES.REVIEW_PRECON_INSPECTION:
        return triggerAction(subTask)
      case WORKFLOW_TYPES.REQUEST_CORRECTIONS:
        return triggerAction('CORRECTIONS_REQUESTED')
      case WORKFLOW_TYPES.RECEIVE_CHANGE_ORDER_APPROVAL:
        return triggerAction('CHANGE_ORDER_APPROVED')
      case WORKFLOW_TYPES.REVIEW_DISPATCH_STATUS:
        const request = {
          resourceId: id,
          resourceType: RESOURCE_TYPE.JOB,
          noteType: NOTE_TYPE.ORDER_STATUS,
          text: note
        }
        setLoading(true)
        return dispatch(
          notesActions.createNote(request,
            (success) => {
              postSuccess(success)
            }
          )
        )
      case WORKFLOW_TYPES.SEND_FINAL_INVOICE:
        return triggerAction('FINAL_INVOICE_SENT')
      default:
        if (validate()) {
          updateJob()
        }
        return ''
    }
  }

  const getButtons = (): { action: any, title?: string, variant?: string }[] => {
    if (isSkip)
      return [{ title: 'Skip', action: () => getActionToTrigger() }]
    switch (item.type) {
      case WORKFLOW_TYPES.CLIENT_FINAL_REVIEW:
      case WORKFLOW_TYPES.REVIEW_FINAL_WALKTHROUGH:
        return [
          { title: 'Client Approved', action: () => { getActionToTrigger('CLIENT_APPROVED') } },
          { title: 'Client Rejected', action: () => { getActionToTrigger('CLIENT_REJECTED') }, variant: 'containedError' }
        ]
      case WORKFLOW_TYPES.REVIEW_PRECON_INSPECTION:
        return [
          { title: 'Changes Required', action: () => { getActionToTrigger('CHANGE_ORDER_REQUIRED') } },
          { title: 'No Changes Required', action: () => { getActionToTrigger('INSPECTION_COMPLETED') }, variant: 'containedError' }
        ]
      //case WORKFLOW_TYPES.MARK_JOB_COMPLETE:
      case WORKFLOW_TYPES.MARK_JOB_COMPLETE:
        return [{ title: 'Mark Job Completed', action: () => getActionToTrigger(WORKFLOW_TYPES.MARK_JOB_COMPLETE) }]//getActionToTrigger(WORKFLOW_TYPES.SETUP_JOB) }]
      case WORKFLOW_TYPES.SEND_FINAL_INVOICE:
        return [{ title: 'Complete', action: () => getActionToTrigger(WORKFLOW_TYPES.SEND_FINAL_INVOICE) }]
      default:
        return [{ action: () => { getActionToTrigger() } }]
    }
  }

  const getContent = () => {
    if (isSkip)
      return (
        <Grid container padding='2.5rem 0'>
          <Grid item xs={12}>
            <Typography> Are you sure you want to skip this task?</Typography>
          </Grid>
        </Grid>
      )
    switch (item.type) {
      // case WORKFLOW_TYPES.SCHEDULE_WORK_WITH_CLIENT:
      case WORKFLOW_TYPES.SCHEDULE_WORK_WITH_CLIENT:
        return (
          <Grid container padding='2.5rem 0'>
            <Grid item xs={12}>
              <Typography variant='h6'>Starts:</Typography>
              <DatePicker
                onChange={(rangeSelected: Range) => {
                  setValues({ ...values, schedulingStartDate: rangeSelected })
                }}
                value={values?.schedulingStartDate || null}
                placeholder='--/--/----'
                size='small'
                allowTimePicker
                centerOnScreen
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h6'>Ends:</Typography>
              <DatePicker
                onChange={(rangeSelected: Range) => {
                  setValues({ ...values, schedulingEndDate: rangeSelected })
                }}
                value={values?.schedulingEndDate || null}
                placeholder='--/--/----'
                size='small'
                allowTimePicker
                centerOnScreen
              />
            </Grid>
          </Grid>
        )
      case WORKFLOW_TYPES.REVIEW_ESTIMATED_COMPLETION_DATE:
        return (
          <Grid container padding='2.5rem 0'>
            <Grid item xs={12}>
              <Typography variant='h6'>Expected Completed On:</Typography>
              <DatePicker
                onChange={(rangeSelected: Range) => {
                  setValues({ ...values, expectedCompletedOn: rangeSelected })
                }}
                value={values?.expectedCompletedOn || null}
                placeholder='--/--/----'
                size='small'
                allowTimePicker
                centerOnScreen
              />
            </Grid>
          </Grid>
        )
      case WORKFLOW_TYPES.SCHEDULE_FINAL_WALKTHROUGH:
        return (
          <Grid container padding='2.5rem 0'>
            <Grid item xs={12}>
              <Typography variant='h6'>Final Walkthrough:</Typography>
              <DatePicker
                onChange={(rangeSelected: Range) => {
                  setValues({ ...values, finalWalkthroughDate: rangeSelected })
                }}
                value={values?.finalWalkthroughDate || null}
                placeholder='--/--/----'
                size='small'
                allowTimePicker
                centerOnScreen
              />
            </Grid>
          </Grid>
        )
      case WORKFLOW_TYPES.SCHEDULE_PRECON_INSPECTION:
        return (
          <Grid container padding='2.5rem 0'>
            <Grid item xs={12}>
              <Typography variant='h6'>Pre Con Inspection Date:</Typography>
              <DatePicker
                onChange={(rangeSelected: Range) => {
                  setValues({ ...values, preConInspectionDate: rangeSelected })
                }}
                value={values?.preConInspectionDate || null}
                placeholder='--/--/----'
                size='small'
                allowTimePicker
                centerOnScreen
              />
            </Grid>
          </Grid>
        )
      case WORKFLOW_TYPES.REVIEW_DISPATCH_STATUS:
        return (
          <Grid container padding='2.5rem 0'>
            <Grid item xs={12}>
              <TextFieldLabel
                label='Note:'
                labelVariant='h6'
                value={note}
                onChange={(e) => {
                  setNote(e.target.value)
                }}
              />
            </Grid>
          </Grid>
        )
      case WORKFLOW_TYPES.MARK_JOB_COMPLETE:
        return (
          <Grid container padding='2.5rem 0' justifyContent='center' >
            <Grid item xs={9}>
              <DatePicker
                placeholder='mm/dd/yyyy --:--'
                label='Completion Date:'
                onChange={
                  (dateSelected: Date) =>
                    setCompletionDate(formatDateTimestamp(dateSelected))}
                value={formatTimestampDate(completionDate)}
                size="small"
                minDate={new Date()}
                centerOnScreen
              //error={showWarnings && !scheduledStartTimestamp}
              />
            </Grid>
          </Grid>
        )
      case WORKFLOW_TYPES.SEND_FINAL_INVOICE:
        return (
          <Grid container padding='2.5rem 0' justifyContent='center'>
            {
              isZero ?
                <Typography>This job has a $0 value, so no invoice is required to complete it.  If the $0 value is correct, select Complete to skip sending the invoice.</Typography> :
                null
            }
          </Grid>
        )
      default:
        return (
          <> </>
        )
    }
  }

  const validate = () => {

    switch (item.type) {
      case WORKFLOW_TYPES.SCHEDULE_WORK_WITH_CLIENT:
        return (
          !isEmpty(values?.schedulingStartDate?.toString()) &&
          !isEmpty(values?.schedulingEndDate?.toString())
        )
      case WORKFLOW_TYPES.REVIEW_ESTIMATED_COMPLETION_DATE:
        return (!isEmpty(values?.expectedCompletedOn?.toString()))
      case WORKFLOW_TYPES.SCHEDULE_FINAL_WALKTHROUGH:
        return (
          !isEmpty(values?.finalWalkthroughDate?.toString())
        )
      case WORKFLOW_TYPES.SCHEDULE_PRECON_INSPECTION:
        return (!isEmpty(values?.preConInspectionDate?.toString()))
      case WORKFLOW_TYPES.MARK_JOB_COMPLETE:
        return (
          !isEmpty(completionDate?.toString())
        )
      default:
        return (true)
    }

  }


  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        variant={isZero || isSkip ? 'contained' : 'outlined'}
        color={isSkip ? 'warning' : 'primary'}
        sx={{ marginRight: '3px' }}
      >
        {isSkip ? 'Skip' : 'Action'}
      </Button>
      <Modal
        setOpen={setOpen}
        open={open}
        title={
          <Grid container sx={{ background: 'var(--gray100)', borderTopRightRadius: 8, borderTopLeftRadius: 8 }} justifyContent='center' padding='1rem'>
            <Typography variant='h5Bold'> Task: </Typography>
            <Typography variant='h5' marginLeft='.4rem'>{item.name}</Typography>
          </Grid>
        }
        className={styles.Modal}
        loading={loading}
      >
        {getContent()}
        <Grid container gap={1} alignItems='flex-end'>
          <Button
            sx={{ flex: 1 }}
            variant='outlined'
            onClick={() => setOpen(!open)}
          >Cancel
          </Button>
          {getButtons().map((button: any) => (
            <Button
              sx={{ flex: 1 }}
              variant={button.variant ?? 'contained'}
              onClick={() => {
                button.action();
              }}
            >
              {button.title ?? 'Complete'}
            </Button>
          ))}
        </Grid>
      </Modal>
    </>
  )
}

export default ModalActions