import React, { FC } from 'react'
import { ClientNotesProps } from './types'
import { Grid, Typography, Box } from '@mui/material'
import { LabeledText } from 'src/components/UI'
import { useSelector } from 'react-redux'
import { getWorkOrderGeneralSection } from 'src/ducks/work-orders/selectors'

const General: FC<ClientNotesProps> = ({ estimate }) => {
  const generalSection = useSelector(getWorkOrderGeneralSection)

  const Contact = ({
    title,
    fullName,
    phone,
    email,
  }: {
    title?: string
    fullName?: string
    phone?: string
    email?: string
  }) => {
    return (
      <Box display="flex" gap="4px" flexDirection={'column'}>
        <Typography style={{ fontSize: '12px', lineHeight: '18px', fontFamily: "var(--font-bold)", color: '#3A66FF' }}>{title || '-'}</Typography>
        <LabeledText labelValue="Name:" textValue={fullName || '-'} />
        <LabeledText labelValue="Phone:" textValue={`${phone || '-'}`} />
        <LabeledText labelValue="Email:" textValue={email || '-'} />
      </Box>
    )
  }

  return (
    <Box display="flex" gap={'8px'} flexDirection={'column'} width={'100%'}>
      <Box display={'flex'} gap="4px" flexDirection={'column'} padding={'8px'} borderRadius="8px" bgcolor={"var(--blue100)"}>
        <LabeledText labelValue="Territory:" textValue={generalSection?.territory || '-'} />
        <LabeledText
          labelValue="Address:"
          textValue={generalSection?.address || '-'}
        />
        <LabeledText labelValue="City:" textValue={generalSection?.city || '-'} />
        <LabeledText labelValue="State:" textValue={generalSection?.state || '-'} />
        <LabeledText labelValue="ZIP Code:" textValue={generalSection?.zipCode || '-'} />
      </Box>
      <Grid container item>
        <Grid item xs={6}>
          {Contact({
            title: 'Project Coordinator',
            ...generalSection?.projectCoordinator || {},
          })}
        </Grid>
        <Grid item xs={6}>
          {Contact({
            title: 'Field Manager',
            ...generalSection?.fieldManager || {},
          })}
        </Grid>
      </Grid>
    </Box>
  )
}

export default General
