import { FC } from 'react'
import { Box, Typography } from '../../../UI'

const Tasks: FC = () => {
  return (
    <Box>
      <Typography variant='h1'>Tasks</Typography>
      <Typography variant='h6'>(Pending)</Typography>
    </Box>
  )
}

export default Tasks
