/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo } from '../../helpers'
import { Punchlist } from '../../api'
import { jobsTypes, FetchJobsAction } from './types'
import { navbarActions } from '../actions'

export function* fetchJobs({ payload, callback }: FetchJobsAction): SagaIterator {
  let success = false
  try {
    const page = payload.page ?? 0
    const size = payload.size ?? 50

    const start = page * size
    const limit = start + size

    const searchParams = payload.searchParams ?? ''
    const jobs = yield call(Punchlist.jobs.getJobs, { start, limit, searchParams })

    if (jobs) {
      yield put(navbarActions.setJobs(jobs))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the navigation search'))
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(jobsTypes.FETCH_JOBS_NAVBAR, fetchJobs)
}
