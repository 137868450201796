/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState, useCallback, useMemo, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Box, Button, StatusBoxes, FieldsFilter, SelectInput, InputSearch, Grid, IconButton, TitleFirst, Typography, ActionsButton, FormControlLabel, Checkbox } from '../../../../UI'
import { PrivateLoader } from 'src/components/templates'
import { getTotalVendors, getVendorsForTable, getTradeForSelect, getVendorBoxes } from 'src/ducks/selectors'
import { filtersActions, searchesActions, territoriesActions, tradeTypesActions, vendorsActions } from 'src/ducks/actions'
import Table from 'src/components/UI/CustomUI/organisms/Table'
import headers from './headers'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import { useNavigate } from 'react-router-dom'
import { getTerritoriesForDropdown } from 'src/ducks/territories/selectors'

import { debounce, set } from 'lodash'
import DatePicker from 'src/components/UI/CustomUI/atoms/DatePicker'
import { dateFormatString, formatTimestamp, headerCSVParser, PROS_PAGE_STATUS, RESOURCE_TYPE, ROWS_PER_PAGE_SIMPLE, verificationStages } from 'src/helpers'
import ExportCSV from 'src/components/UI/CustomUI/molecules/ExportCSV'
import TablePagination from 'src/components/UI/CustomUI/organisms/Table/TablePagination'
import RefreshIcon from '@mui/icons-material/Refresh'

import styles from './styles.module.scss'
import { ProsFilterType } from 'src/ducks/filters/types'
import { getLicensingOptions, getProsFilter } from 'src/ducks/filters/selector'
import Folders from './Folders'
import SaveSearchModal from '../SaveSearchModal'
import FilterToggler from 'src/components/UI/CustomUI/molecules/FilterToggler'
import { Range } from 'src/components/UI/CustomUI/atoms/DatePicker/types'
import { CSVLink } from 'react-csv'
import { icons } from 'src/assets'

const regLmsOptions = [
  { id: 'registrationComplete', label: 'Reg Complete', hide: true },
  { id: 'registrationInComplete', label: 'Reg InComplete', hide: true },
  { id: 'div1', isSeparator: true },
  { id: 'lmsEnabled', label: 'LMS Enabled', hide: true },
  { id: 'lmsDisabled', label: 'LMS Disabled', hide: true },
  { id: 'div1', isSeparator: true },
  { id: 'parent', label: 'Parent', hide: true },
  { id: 'pro', label: 'Pro', hide: true },
]

const Pros: FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const tradeTypes = useSelector(getTradeForSelect)
  const territoriesDropdown = useSelector(getTerritoriesForDropdown)
  const totalVendors = useSelector(getTotalVendors)
  const vendors = useSelector(getVendorsForTable())
  const counts = useSelector(getVendorBoxes())
  const prosFilter = useSelector(getProsFilter())
  const {
    search,
    territory,
    insertedOnStart,
    insertedOnEnd,
    approved,
    insuranceTypes,
    hasACH,
    hasTaxID,
    hasW9,
    unapproved,
    vStages,
    regLmsArray,
    tradesFilter,
    isChild,
    page,
    pageSize
  } = prosFilter

  const licenseOptions = useSelector(getLicensingOptions())

  const [pageLoading, setPageLoading] = useState(true)
  const [tableLoading, setTableLoading] = useState(false)
  const [countsLoading, setCountsLoading] = useState(false)

  const [saveModalOpen, setSaveModalOpen] = useState<boolean>(false)
  const [params, setParams] = useState<any>({})
  const [refresh, setRefresh] = useState<boolean>(false)
  const [searchPro, setSearchPro] = useState(params.search)
  const [trades, setTrades] = useState<any[]>([])
  const [tableHeaders, setTableHeaders] = useState(headers)
  const [stages, setStages] = useState<any>([])
  const [hideFilterStats, setHideFilterStats] = useState<boolean>(false)
  const [loadingCsv, setLoadingCsv] = useState<boolean>(false)
  const [dataCSV, setDataCsv] = useState<string>("")
  const downloadRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);

  // New Filter
  const [regLmsFilter, setRegLmsFilter] = useState<any>([])

  /**
   * UpdateTradesOptions when ready
   */
  useEffect(() => {
    if (trades.length === 0 && tradeTypes) {
      setTrades(tradeTypes.map(to => ({
        ...to,
        hide: Boolean(tradesFilter?.find(t => t !== to.id))
      })))
    }
    if (trades?.length > 0) {
      setTrades(trades.map((trade, i) => {
        if (tradesFilter?.includes(trade.id)) {
          trade.hide = false
        } else {
          trade.hide = true
        }
        return trade
      }))
    }
  }, [tradeTypes, tradesFilter])

  useEffect(() => {
    setStages(verificationStages.map((st => ({
      id: st.key,
      label: st.label,
      hide: Boolean(!vStages?.find(s => s === st.key))
    }))))
  }, [vStages])

  useEffect(() => {
    setRegLmsFilter(regLmsOptions.map(option => ({
      ...option,
      hide: Boolean(!regLmsArray?.find(opt => opt === option.id))
    })))
  }, [regLmsArray])

  /**
   * get filter with no undefined values from redux
   */
  const getParamsFilter = useCallback(() => {
    let filer = {}
    if (approved && !unapproved) {
      filer = {
        ...filer,
        approved
      }
    }
    if (unapproved && !approved) {
      filer = {
        ...filer,
        approved: false
      }
    }
    if (territory?.length && territory?.length > 0) {
      filer = {
        ...filer,
        territory: territory
      }
    }

    if (hasACH) {
      filer = {
        ...filer,
        hasACH: true
      }
    }
    if (hasTaxID) {
      filer = {
        ...filer,
        hasTaxID: true
      }
    }
    if (hasW9) {
      filer = {
        ...filer,
        hasW9: true
      }
    }
    if (search && search !== '') {
      filer = {
        ...filer,
        search: search
      }
    }
    if (tradesFilter && tradesFilter.length > 0) {
      filer = {
        ...filer,
        trades: tradesFilter
      }
    }
    if (regLmsArray && regLmsArray.length > 0) {

      regLmsArray.forEach(reglms => {
        switch (reglms) {
          case 'pro':
            filer = {
              ...filer,
              isAdmin: false
            }
            break
          case 'parent':
            filer = {
              ...filer,
              isAdmin: true
            }
            break
          case 'lmsDisabled':
            filer = {
              ...filer,
              lmsEnabled: false
            }
            break
          case 'registrationInComplete':
            filer = {
              ...filer,
              registrationComplete: false
            }
            break
          default:
            filer = {
              ...filer,
              [reglms]: true
            }
        }
      })
    }
    if (insuranceTypes && insuranceTypes.length > 0) {

      filer = {
        ...filer,
        insuranceTypes: insuranceTypes
      }
    }

    if (vStages && vStages.length > 0) {
      filer = {
        ...filer,
        verificationStage: vStages
      }
    }

    if (insertedOnStart && insertedOnEnd) {
      filer = {
        ...filer,
        insertedOnStart: insertedOnStart,
        insertedOnEnd: insertedOnEnd
      }
    }

    /*     filer = {
          ...filer,
          isChild: isChild ? isChild : null
        } */
    if (isChild) filer = { ...filer, isChild: isChild }

    return filer
  }, [search, territory, approved, insuranceTypes, hasACH, hasTaxID, hasW9,
    unapproved, vStages, regLmsArray, tradesFilter, insertedOnStart, insertedOnEnd, isChild])


  /**
 * Update search params whenever pros filter changes
 */
  useEffect(() => {
    if ((insertedOnStart && insertedOnEnd) || (!insertedOnStart && !insertedOnEnd))
      fetchVendors()
  }, [search, territory, approved, insuranceTypes, hasACH, hasTaxID, hasW9,
    unapproved, vStages, regLmsArray, tradesFilter, insertedOnStart, insertedOnEnd, isChild])

  useEffect(() => {
    if (refresh === true) {
      fetchVendors()
    }
    setRefresh(false)
  }, [refresh])

  useEffect(() => {
    //fetchVendors()
    territoriesDropdown.length <= 1 && dispatch(territoriesActions.fetchTerritories((_succ: boolean) => {
      setPageLoading(false)
    }))

  }, [prosFilter])

  useEffect(() => {
    setPageLoading(true)
    dispatch(
      searchesActions.fetchSearches(RESOURCE_TYPE.PRO, (_succ: boolean) => { })
    )
    dispatch(tradeTypesActions.fetchTradeTypes(
      (_succ: boolean) => setPageLoading(false)
    ))
  }, [])

  useEffect(() => {
    trades.length <= 0 && setTrades(tradeTypes || [])
  }, [tradeTypes])

  const updateProsFilter = (newFilter: Partial<ProsFilterType>) => {
    dispatch(
      filtersActions.setFilters({
        pros: {
          ...prosFilter,
          ...newFilter
        }
      })
    )
  }

  const fetchVendors = useCallback((page?: number, size?: number) => {
    setTableLoading(true)
    setCountsLoading(true)
    dispatch(
      vendorsActions.fetchVendors({ page, size, searchParams: getParamsFilter() }, (_succ: boolean) => {
        setPageLoading(false)
        setTableLoading(false)
      })
    )
    dispatch(vendorsActions.fetchCounts({ searchParams: getParamsFilter() }, (_succ: boolean) => {
      setCountsLoading(false)
    }))
  }, [prosFilter])

  const handleChangeLicensing = (index: number, id: any) => {
    switch (id) {
      case 'WORKERS_COMPENSATION':
      case 'CERTIFICATE_OF_INSURANCE': {
        dispatch(filtersActions.setFilters({
          pros: {
            ...prosFilter,
            insuranceTypes: insuranceTypes?.includes(id) ? insuranceTypes.filter((i: any) => i !== id) : insuranceTypes ? [...insuranceTypes, id] : [id]
          }
        }))
        setParams({ ...params, insuranceTypes: insuranceTypes?.includes(id) ? insuranceTypes.filter((i: any) => i !== id) : insuranceTypes ? [...insuranceTypes, id] : [id] })
        break
      }
      case 'unapproved': {
        dispatch(filtersActions.setFilters({
          pros: {
            ...prosFilter,
            unapproved: !unapproved
          }
        }))
        break
      }
      case 'approved': {
        dispatch(filtersActions.setFilters({
          pros: {
            ...prosFilter,
            approved: !approved
          }
        }))

        break
      }
      default: {
        if (id === 'hasTaxID') {
          dispatch(filtersActions.setFilters({
            pros: {
              ...prosFilter,
              hasTaxID: !prosFilter.hasTaxID
            }
          }))
        }
        if (id === 'hasACH') {
          dispatch(filtersActions.setFilters({
            pros: {
              ...prosFilter,
              hasACH: !prosFilter.hasACH
            }
          }))
        }
        if (id === 'hasW9') {
          dispatch(filtersActions.setFilters({
            pros: {
              ...prosFilter,
              hasW9: !prosFilter.hasW9
            }
          }))
        }
      }
    }
  }

  const handleChangeTrades = (index: number, id: any) => {

    setTrades(trades.map((trade, i) => {
      if (tradesFilter?.includes(id) || id === trade.id) {
        trade.hide = false
      } else {
        trade.hide = true
      }
      return trade
    }))

    dispatch(filtersActions.setFilters({
      pros: {
        ...prosFilter,
        tradesFilter: tradesFilter?.includes(id) ? tradesFilter.filter((i: any) => i !== id) : tradesFilter ? [...tradesFilter, id] : [id]
      }
    }))

  }

  const handleChangeStages = (index: number, id: any) => {
    setStages(stages.map((stage: any, i: number) => {
      if (i === index) {
        stage.hide = !stage.hide
      }
      return stage
    }))

    switch (id) {
      default: {
        dispatch(filtersActions.setFilters({
          pros: {
            ...prosFilter,
            vStages: vStages?.includes(id) ? vStages?.filter((i: any) => i !== id) : vStages ? [...vStages, id] : [id]
          }
        }))
        break
      }
    }
  }
  const handleChangeRegLMS = (index: number, id: any) => {
    let newFilter = [...regLmsFilter]

    if (id === 'registrationComplete' || id === 'registrationInComplete') {
      if (id === 'registrationComplete' && newFilter[index].hide) {
        newFilter[index + 1].hide = true
      }
      else if (id === 'registrationInComplete' && newFilter[index].hide) {
        newFilter[index - 1].hide = true
      }
    }

    else if (id === 'lmsEnabled' || id === 'lmsDisabled') {
      if (id === 'lmsEnabled' && newFilter[index].hide) {
        newFilter[index + 1].hide = true
      }
      else if (id === 'lmsDisabled' && newFilter[index].hide) {
        newFilter[index - 1].hide = true
      }
    }

    else if (id === 'parent' || id === 'pro') {
      if (id === 'parent' && newFilter[index].hide) {
        newFilter[index + 1].hide = true
      }
      else if (id === 'pro' && newFilter[index].hide) {
        newFilter[index - 1].hide = true
      }
    }

    newFilter[index].hide = !newFilter[index].hide
    dispatch(filtersActions.setFilters({
      pros: {
        ...prosFilter,
        regLmsArray: newFilter.filter(rl => !rl.hide).map(rl => rl.id),
        isChild: !newFilter.find(filter => filter.id === 'pro').hide,
      }
    }))
    setRegLmsFilter(newFilter) // Update filter options
  }

  const handleChangeDate = (rangeSelected: Range) => {
    dispatch(filtersActions.setFilters({
      pros: {
        ...prosFilter,
        insertedOnStart: dateFormatString(rangeSelected.startDate || ''),
        insertedOnEnd: dateFormatString(rangeSelected.endDate || '')
      }
    }))
  }

  const changeItemValue = useCallback(
    (params, newValue, attr) => {
      setParams({ ...params, [attr]: newValue })
    },
    []
  )

  const changeSearchPro = useCallback(
    (newValue) => {
      dispatch(filtersActions.setFilters({
        pros: {
          ...prosFilter,
          search: newValue
        }
      }))
    },
    []
  )

  const debouncedSearch = useCallback(
    debounce(changeSearchPro, 500),
    []
  )


  const onItemValueChanged = useMemo(
    () => debounce(changeItemValue, 700),
    [changeItemValue]
  )



  const onTableFilterChange = (index: number): void => {
    setTableHeaders(tableHeaders.map((item, indx) => {
      if (indx === index) {
        return { ...item, hide: !item.hide }
      }
      return item
    }))
  }

  const fetchCsvVendors = useCallback(() => {
    setLoadingCsv(true)
    dispatch(vendorsActions.requestCSVVendors({ count: totalVendors, searchParams: getParamsFilter() }, (succ, vendorsForCSV) => {
      setLoadingCsv(false)
      if (succ) {
        setDataCsv(vendorsForCSV)
        downloadRef?.current?.link?.click()
      }
    }))
  }, [prosFilter, loadingCsv])


  const handleClearFilters = () => {
    dispatch(filtersActions.clearFilters())
  }

  return (
    <PrivateLoader loading={pageLoading}>
      <Box display='flex' flexDirection='column' gap={2}>
        <FilterToggler
          label='Filters & Stats'
          hide={hideFilterStats}
          toggle={() => {
            setHideFilterStats(!hideFilterStats)
          }}
        />
        <Box hidden={hideFilterStats}>
          <Grid container spacing={2} sx={{ flexWrap: '!important' }} justifyContent='space-between'>
            <Grid item lg={12}>
              <Folders setPageLoading={setTableLoading} />
            </Grid>
            <Grid item lg={1.9}>
              <FieldsFilter
                flexColumn
                handleChange={handleChangeLicensing}
                inputText='Licenses'
                options={licenseOptions}
                renderAsInput
                label='Licensing:'
                popupLabel='Select Licenses to view'
                variant='body1'
              />
            </Grid>
            <Grid item lg={1.9}>
              <SelectInput
                customIcon={
                  <Icon name='FilterAlt' color='var(--gray400)' />
                }
                label='Territories Filter:'
                options={territoriesDropdown}
                onChange={(newSelected) => {
                  if (newSelected.key === 'all') {
                    delete prosFilter.territory;
                    dispatch(filtersActions.setFilters({
                      pros: {
                        ...prosFilter,
                        territory: undefined
                      }
                    }))
                  } else {
                    dispatch(filtersActions.setFilters({
                      pros: {
                        ...prosFilter,
                        territory: newSelected.key
                      }
                    }))
                  }
                }}
                value={territory ? territoriesDropdown.find(_territory => _territory.key === territory) : territoriesDropdown[0]}
              />
            </Grid>
            <Grid item lg={1.9}>
              <FieldsFilter
                flexColumn
                handleChange={handleChangeTrades}
                inputText='All trades'
                options={trades}
                renderAsInput
                label='Trades:'
                popupLabel='Select Trades to view'
                variant='body1'
              />
            </Grid>
            <Grid item lg={1.9}>
              <FieldsFilter
                flexColumn
                handleChange={handleChangeStages}
                inputText='All Verification Stages'
                options={stages}
                renderAsInput
                label='Verification Stages Filter:'
                popupLabel='Select Verification Stages'
                variant='body1'
              />
            </Grid>
            {/* <Grid item lg={1.7}>
              <SelectInput
                label='Verification Stages Filter:'
                options={verificationStages}
                onChange={(newSelected) => {
                  if (newSelected.key === 'all') {
                    delete params.verificationStage;
                    setParams({ ...params })
                  } else {
                    setParams({ ...params, verificationStage: newSelected.key })
                  }
                }}
                value={params.verificationStage ? verificationStages.find(vStage => vStage.key === params.verificationStage) : verificationStages[0]}
              />
            </Grid> */}
            <Grid item lg={1.9}>
              <FieldsFilter
                flexColumn
                handleChange={handleChangeRegLMS}
                inputText='Reg & LMS'
                options={regLmsFilter}
                renderAsInput
                label='Reg & LMS:'
                popupLabel='Select Trades to view'
                variant='body1'
              />
            </Grid>
            <Grid item container lg={2.4} md={3} justifyContent='space-between' alignItems='flex-end' width='fit-content'>
              <Grid item>
                <Button
                  variant='containedBig'
                  color='primary'
                  sx={{
                    width: '100px',
                    height: '36px',
                    alignSelf: 'flex-end'
                  }}
                  onClick={() => updateProsFilter({ pageStatus: PROS_PAGE_STATUS.INSURANCE })}
                >
                  Insurance
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant='containedBig'
                  color='primary'
                  sx={{
                    width: '100px',
                    height: '36px',
                    alignSelf: 'flex-end'
                  }}
                  onClick={() => {
                    updateProsFilter({ pageStatus: PROS_PAGE_STATUS.LICENSES })
                  }}
                >
                  Licenses
                </Button>
              </Grid>
              <Grid item>
                <FieldsFilter maxWidth='400px' options={tableHeaders} handleChange={onTableFilterChange} />
              </Grid>
            </Grid>
          </Grid>
          <StatusBoxes data={counts} minWidth='100%' loading={countsLoading} />
        </Box>
        <Grid container justifyContent='space-between' alignItems='flex-end'>
          <Grid container item xs={7.9} spacing={2}>
            <Grid item gap={2} display='flex' alignItems='flex-end'>
              <InputSearch
                label='Search for Pro:'
                placeholder='Pros name here'
                onChange={(value) => {
                  setSearchPro(value)
                  onItemValueChanged(params, value, 'search')
                  debouncedSearch(value)
                }}
                value={searchPro ? searchPro : ''}
                sx={{ width: '200px' }}
              />
              <Box display='flex' flexDirection='column' justifyContent='flex-start' sx={{ flex: '1' }}>
                <Typography variant='h6'> Created date: </Typography>
                <DatePicker
                  onChange={handleChangeDate}
                  value={{
                    ...insertedOnStart ? { startDate: new Date(insertedOnStart) } : {},
                    ...insertedOnEnd ? { endDate: new Date(insertedOnEnd) } : {}
                  } as Range}
                  allowRange
                  style={{ height: '38px', padding: '0px' }}
                  styleAlert={{ right: 0 }}
                  size='small'
                />
              </Box>
              <Button
                variant='contained'
                sx={{ minWidth: '100px !important' }}
                onClick={() => setRefresh(true)}
              >
                Apply
              </Button>
              <Button
                variant='outlined'
                sx={{ minWidth: '100px !important' }}
                onClick={() => {
                  dispatch(filtersActions.setFilters({
                    pros: {
                      ...prosFilter,
                      insertedOnEnd: null,
                      insertedOnStart: null
                    }
                  }))
                  setRefresh(true)
                }}
              >
                Reset dates
              </Button>
              <IconButton
                style={{
                  marginLeft: '10px',
                  marginTop: '5px'
                }} onClick={() => setRefresh(true)}
              >
                <RefreshIcon />
              </IconButton>
              <Button variant='outlined' onClick={() => setSaveModalOpen(true)}>
                Save Search
                <Icon name='Save' sx={{ margin: '5px' }} />
              </Button>
              <Button variant='outlined' onClick={handleClearFilters}>
                Clear Filters
                <Icon name='Clear' sx={{ margin: '5px' }} />
              </Button>
              {/* Moved to top
              <FormControlLabel
                label='Child Pros'
                control={<Checkbox checked={!!prosFilter.isChild} onChange={() => updateProsFilter({ isChild: !prosFilter.isChild })} />}
              /> */}
            </Grid>
          </Grid>
          <Grid item container xs={4.1} spacing={0} justifyContent='flex-end' alignItems='center'>
            <Grid item>
              <TablePagination
                callToApi={(start, limit) => { fetchVendors(start, limit) }}
                totalItems={totalVendors}
                rowsPerPageOptions={[...ROWS_PER_PAGE_SIMPLE, 200, 350]}
                // totalItems={filteredVendors.length}
                sx={{
                  '.MuiTablePagination-toolbar': {
                    minHeight: 'fit-content !important'
                  },
                  '.MuiButtonBase-root': {
                    padding: '0px inherit !important'
                  }
                }}
              />
            </Grid>
            <Grid item>
              <ActionsButton
                loading={loadingCsv}
                icon={<icons.CloudDownload color='disabled' />}
                iconPosition='end'
                onClick={fetchCsvVendors}
                sx={{
                  minWidth: '56px!important',
                }}
              />
              <ExportCSV
                innerRef={downloadRef}
                headers={dataCSV?.slice(0, dataCSV?.search('\n'))?.split(',')?.map((h: any) => h)}
                data={dataCSV?.slice(dataCSV?.search('\n') + 1)}
                fileName={'pros.csv'}
                label=''
              />
            </Grid>
          </Grid>
        </Grid>
        <Table
          headers={tableHeaders}
          defaultOrder='desc'
          rowAction={(row) => navigate(`${row?.id}`)}
          small
          totalItems={totalVendors}
          callToApi={(start, limit) => { fetchVendors(start, limit) }}
          loading={tableLoading}
          hasPagination={false}
          className={styles.Pros__Table}
          hasScroll
        >
          {vendors ?? []}
        </Table>
        {saveModalOpen && <SaveSearchModal openModal={saveModalOpen} setOpenModal={setSaveModalOpen} />}
      </Box>
    </PrivateLoader>
  )
}

export default Pros
