
import { RESOURCE_TYPE } from 'src/helpers'
import { authTypes } from '../auth/types'
import { Searches, SearchesActions, searchesTypes, SearchParams, SearchParamValue } from './types'


const initialState: Searches = {
  id: "",
  userId: "",
  type: RESOURCE_TYPE.JOB,
  folders: [],
  searchParams: {} as SearchParams,
}

const reducer = (state = initialState, action: SearchesActions): Searches => {
  const { type, payload } = action
  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case searchesTypes.SET_VALUES: {
      return {
        ...state,
        ...payload
      }
    }
    case searchesTypes.SET_SEARCH_PARAM_VALUE: {
      const { attr, value } = payload as SearchParamValue
      return {
        ...state,
        searchParams: {
          ...state.searchParams,
          [attr]: value
        }
      }
    }
    case searchesTypes.SET_SEARCH_PARAM_VALUES: {
      return {
        ...state,
        searchParams: {
          ...payload as SearchParams
        }
      }
    }
    case searchesTypes.CLEAN_PARAMS: {
      return {
        ...state,
        searchParams: {}
      }
    }

    default:
      return state
  }
}

export default reducer
