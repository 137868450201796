import { PriceItemType, RepairListItem } from '../types'

/** TYPES **/
export const repairItemTypes = {
  FETCH_REPAIRITEM: 'FETCH_REPAIRITEM',
  FETCH_PRICELIST: 'FETCH_PRICELIST',
  FETCH_PRICEITEM: 'FETCH_PRICEITEM',
  CREATE_REPAIRITEM: 'CREATE_REPAIRITEM',
  PATCH_REPAIRITEM: 'PATCH_REPAIRITEM',
  PATCH_PRICEITEM: 'PATCH_PRICEITEM',
  PATCH_ALL_PRICEITEMS: 'PATCH_ALL_PRICEITEMS',
  PATCH_PRICEITEMS: 'PATCH_PRICEITEMS',
  DELETE_REPAIRITEM: 'DELETE_REPAIRITEM',
  SET_REPAIRITEM: 'SET_REPAIRITEM',
  SET_PRICEITEM: 'SET_PRICEITEM',
  SET_PRICELIST: 'SET_PRICELIST',
}

/** ACTIONS **/
export interface FetchRepairItemAction {
  type: typeof repairItemTypes.FETCH_REPAIRITEM
  payload: string
  callback?: (succ: boolean) => void
}

export interface FetchPriceListAction {
  type: typeof repairItemTypes.FETCH_REPAIRITEM
  payload: string
  callback?: (succ: boolean) => void
}

export interface FetchPriceItemAction {
  type: typeof repairItemTypes.FETCH_PRICEITEM
  payload: {
    repairItemId: string
    territoryId: string
  }
  callback?: (succ: boolean, priceItem?: PriceItemType) => void
}

export interface CreateRepairItemAction {
  type: typeof repairItemTypes.CREATE_REPAIRITEM
  payload: Partial<RepairListItem>
  callback?: (succ: boolean, newRepairItem?: RepairListItem) => void
}

export interface PatchRepairItemAction {
  type: typeof repairItemTypes.PATCH_REPAIRITEM
  payload: Partial<RepairListItem>
  callback?: (succ: boolean) => void
}

export interface PatchAllPriceItemsAction {
  type: typeof repairItemTypes.PATCH_ALL_PRICEITEMS
  payload: Partial<PriceItemType>
  callback?: (succ: boolean) => void
}

export interface PatchPriceItemAction {
  type: typeof repairItemTypes.PATCH_PRICEITEM
  payload: {
    request: Partial<PriceItemType>
    territoryId: string
  }
  callback?: (succ: boolean) => void
}

export interface PatchPriceItemsAction {
  type: typeof repairItemTypes.PATCH_PRICEITEMS
  payload: {
    ids: string[]
    request: Partial<PriceItemType>
  }
  callback?: (succ: boolean) => void
}

export interface DeleteRepairItemAction {
  type: typeof repairItemTypes.DELETE_REPAIRITEM
  payload: string
  callback?: (succ: boolean) => void
}

export interface SetRepairItemAction {
  type: typeof repairItemTypes.SET_REPAIRITEM
  payload: RepairListItem
}

export interface SetPriceListAction {
  type: typeof repairItemTypes.SET_PRICELIST
  payload: PriceItemType[]
}

export interface SetPriceItemAction {
  type: typeof repairItemTypes.SET_PRICEITEM
  payload: PriceItemType
}

export type RepairItemActions =
  | FetchRepairItemAction
  | FetchPriceListAction
  | FetchPriceItemAction
  | CreateRepairItemAction
  | PatchRepairItemAction
  | PatchPriceItemAction
  | PatchAllPriceItemsAction
  | PatchPriceItemsAction
  | DeleteRepairItemAction
  | SetRepairItemAction
  | SetPriceItemAction
  | SetPriceListAction

/** REDUCER **/
export interface RepairItemType {
  item: RepairListItem
  priceList: PriceItemType[]
  priceItem: PriceItemType
}
