import { authTypes } from '../auth/types'
import { Vendor, VendorCount } from '../types'
import { vendorsTypes, VendorsType, VendorsActions } from './types'

const initialState: VendorsType = {
  start: 0,
  limit: 0,
  total: 0,
  items: [],
  counts: [],
  punchlistVendors: [],
  vendorsCSV: ""
}

const reducer = (state = initialState, action: VendorsActions): VendorsType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: {
      return initialState
    }
    case authTypes.LOGOUT_REQUEST: {
      return initialState
    }

    case vendorsTypes.SET_VENDORS: {
      return {
        ...state,
        start: (payload as VendorsType).start,
        limit: (payload as VendorsType).limit,
        total: (payload as VendorsType).total,
        items: (payload as VendorsType).items,
      }
    }

    case vendorsTypes.SET_PUNCHLIST_VENDORS: {
      return {
        ...state,
        punchlistVendors: (payload as Vendor[])
      }
    }

    case vendorsTypes.SET_VENDORS_LIST: {
      return { ...state, items: payload as Vendor[] }
    }

    case vendorsTypes.SET_VENDORS_CSV: {
      return { ...state, vendorsCSV: payload as string }
    }

    case vendorsTypes.SET_VENDORS_COUNTS: {
      return {
        ...state,
        counts: payload as VendorCount[],
      }
    }

    case vendorsTypes.SET_VENDOR_BY_ID: {
      return {
        ...state,
        items: state.items.map((item) =>
          item.id === (payload as Vendor).id ? (payload as Vendor) : item
        ),
      }
    }

    default:
      return state
  }
}

export default reducer
