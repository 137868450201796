import { SearchParams } from '../searches/types'
import { SubscriptionItem } from '../types'

/** TYPES **/
export const subscriptionsTypes = {
    FETCH_SUBSCRIPTIONS: 'FETCH_SUBSCRIPTIONS',
    FETCH_CSV_SUBSCRIPTIONS: 'FETCH_CSV_SUBSCRIPTIONS',
    SET_SUBSCRIPTIONS: 'SET_SUBSCRIPTIONS',
    SET_CSV_SUBSCRIPTIONS: 'SET_CSV_SUBSCRIPTIONS',
}

/** ACTIONS **/
export interface FetchSubscriptionsAction {
  type: typeof subscriptionsTypes.FETCH_SUBSCRIPTIONS
  payload: {
    page?: number
    size?: number
    searchParams?: Partial<SearchParams>
  }
  callback?: (succ: boolean) => void
}

export interface FetchCsvSubscriptionsAction {
  type: typeof subscriptionsTypes.FETCH_CSV_SUBSCRIPTIONS
  payload: {
    count: number,
    searchParams?: Partial<SearchParams>
  }
  callback?: (succ: boolean) => void
}

export interface SetSubscriptionsAction {
  type: typeof subscriptionsTypes.SET_SUBSCRIPTIONS
  payload: Partial<SubscriptionsType>
}

export interface SetCsvSubscriptionsAction {
  type: typeof subscriptionsTypes.SET_CSV_SUBSCRIPTIONS
  payload: Partial<SubscriptionsType>
}

export type SubscriptionsActions =
  | FetchSubscriptionsAction
  | FetchCsvSubscriptionsAction
  | SetSubscriptionsAction
  | SetCsvSubscriptionsAction

/** REDUCER **/
export interface SubscriptionsType {
  items: SubscriptionItem[]
  total: number
  start: number
  limit: number
  csvItems: SubscriptionItem[]
}
