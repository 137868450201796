import { authTypes } from '../auth/types'
import { PriceItemType, RepairListItem } from '../types'
import { RepairItemActions, repairItemTypes, RepairItemType } from './types'

const initialState: RepairItemType = {
  item: {} as RepairListItem,
  priceList: [],
  priceItem: {} as PriceItemType
}

const reducer = (
  state = initialState,
  action: RepairItemActions
): RepairItemType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: {
      return initialState
    }
    case authTypes.LOGOUT_REQUEST: {
      return initialState
    }

    case repairItemTypes.SET_REPAIRITEM: {
      return {
        ...state,
        item: (payload as RepairListItem)
      }
    }

    case repairItemTypes.SET_PRICELIST: {
      return {
        ...state,
        priceList: (payload as PriceItemType[])
      }
    }

    default:
      return state
  }
}

export default reducer
