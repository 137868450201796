import { Theme } from '../../../UI'
import { makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) =>
({
  text: {
    display: 'flex',
    gap: theme.spacing(1),
    justifyContent: 'center',
    alignItems: 'baseline',
    color: theme.palette.primary.dark,
    [theme.breakpoints.down('md')]: {
      fontSize: 'var(--font-M)!important',
    }
  },
  form: {
    width: '100%',
    maxWidth: '360px',
    marginTop: theme.spacing(4)
  },
  submit: {
    margin: theme.spacing(4, 0, 2),
    textTransform: 'none',
    color: 'var(--white-color)',
    backgroundColor: 'var(--blue700)',
    borderRadius: 32
  },
  center: {
    display: 'flex',
    justifyContent: 'center'
  },
  email: {
    paddingBottom: theme.spacing(1),
    '@global': {
      '.MuiOutlinedInput-root': {
        marginTop: 8
      },
      '.MuiOutlinedInput-input': {
        padding: '16px 14px !important',
      }
    }
  },
  icon: {
    margin: '0 0 4px 0',
    width: '12px',
    height: '12px'
  },
  successText: {
    color: 'var(--blue700)',
    padding: 0
  }
})
)
