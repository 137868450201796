import { useState } from 'react'
import { Button } from 'src/components/UI'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import Modal from 'src/components/UI/CustomUI/molecules/Modal'
import TabNavigation from 'src/components/UI/CustomUI/organisms/TabNavigation'
import Accounting from './Tabs/Accounting'
import AddNoteModal from './Tabs/AddNoteModal'
import Payment from './Tabs/Payment'
import { useIsXlScreen } from 'src/hooks'
import AddCheckModal from './Tabs/AddCheckModal'

const Payments = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const [checkModalOpen, setCheckModalOpen] = useState(false)
  const [xlScreen] = useIsXlScreen()

  const TABS = [
    {
      label: 'Payment', content: <Payment />,
      actionButton:
        <Button variant='containedGreen' onClick={() => setCheckModalOpen(true)}>
          Add Check Payment <Icon name='Add' sx={{ marginLeft: '5px' }} />
        </Button>
    },
    {
      label: 'Accounting',
      content: <Accounting />,
      actionButton:
        <Button variant='containedLight' onClick={() => setCheckModalOpen(true)}>
          Add Note <Icon name='Create' sx={{ marginLeft: '5px' }} />
        </Button>
    }
  ]

  return (
    <>
      {checkModalOpen && <Modal
        open={checkModalOpen}
        setOpen={setCheckModalOpen}
        title='Payment'
      >
        <AddCheckModal onClose={() => setCheckModalOpen(false)} />
      </Modal>}
      {modalOpen &&
        <Modal
          open={modalOpen}
          setOpen={setModalOpen}
          title='Add Note'
        >
          <AddNoteModal onClose={() => setModalOpen(false)} />
        </Modal>}
      <TabNavigation
        tabs={TABS}
        variant='contained'
        tabWidth='fit-content'
        tabAlign='flex-end'
        defaultActive={1}
        margin={xlScreen ? '2rem 0' : '110px 0 0.5rem 0'}
      />
    </>
  )
}

export default Payments
