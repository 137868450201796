import { SearchParams } from '../searches/types'
import { NavBarJob } from '../types'

/** TYPES **/
export const jobsTypes = {
  FETCH_JOBS_NAVBAR: 'FETCH_JOBS_NAVBAR',
  SET_JOBS_NAVBAR: 'SET_JOBS_NAVBAR'
}

/** ACTIONS **/
export interface FetchJobsAction {
  type: typeof jobsTypes.FETCH_JOBS_NAVBAR
  payload: {
    page?: number,
    size?: number,
    searchParams?: Partial<SearchParams>
  }
  callback?: (succ: boolean) => void
}

export interface SetJObsAction {
  type: typeof jobsTypes.SET_JOBS_NAVBAR
  payload: NavBarJob
}

export type JobsActions =
  | FetchJobsAction
  | SetJObsAction

/** REDUCER **/
export type NavBar = NavBarJob
