import { Box, Button, CircularProgress, Grid } from "@mui/material"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import { TextFieldLabel } from "src/components/UI"
import DatePicker from "src/components/UI/CustomUI/atoms/DatePicker"
import CurrencyTextField from "src/components/UI/CustomUI/molecules/CurrencyTextField"
import { discountsActions } from "src/ducks/actions"
import { DiscountBanner } from "src/ducks/discounts/types"
import { getDiscountsValue } from "src/ducks/selectors"
import { CURRENCY_MASK_NEGATIVE, formatDateTimestamp, formatTimestampDate, isEmpty } from 'src/helpers'
import { createNumberMask } from "text-mask-addons"

const bannerDefault: DiscountBanner = {
  startTime: Math.floor(new Date().getTime() / 1000),
  endTime: Math.floor(new Date().getTime() / 1000),
  minAmount: 0,
  monthString: '',
  discountCode: '',
  description: ''
}

const EstimateDiscountModal = ({ onClose, isEdit }: { onClose: () => void, isEdit: boolean }) => {
  const dispatch = useDispatch()
  const discountBanner = useSelector(getDiscountsValue('discountBanner'))
  const [banner, setBanner] = useState<DiscountBanner | null>(isEdit ? { ...discountBanner, discount: discountBanner.discount } : bannerDefault)
  const currencyMask = createNumberMask(CURRENCY_MASK_NEGATIVE)
  // const [submit, setSubmit] = useState(false)
  const [loading, setLoading] = useState(false)

  const isValid = !isEmpty(banner?.discount?.toString())
    && !isEmpty(banner?.discountCode)
    && !isEmpty(banner?.description)
    && !isEmpty(banner?.startTime?.toString())
    && !isEmpty(banner?.endTime?.toString())
    && (banner?.endTime || 0) >= (banner?.startTime || 0)

  const handleSave = () => {
    // setSubmit(true)
    setLoading(true)
    banner?.id && delete banner?.id
    banner && dispatch(discountsActions.createDiscountBanner({ ...banner, discount: (Number(banner?.discount) || 0), minAmount: Number(banner.minAmount) }, (succ => {
      // setSubmit(false)
      setLoading(false)
      if (succ) {
        onClose()
        toast.success("Promo updated successfully")
      }
    }
    )))
  }

  return (
    <Grid container spacing={2} alignContent="flex-start">
      <Grid item container spacing={2}>
        <Grid item xs={6}>
          <TextFieldLabel
            label='Discount:'
            type='number'
            value={banner?.discount?.toString() || ''}
            // error={isEmpty(banner?.discount.toString())}
            onChange={(event) => setBanner({ ...banner as DiscountBanner, discount: event.target.value })}
            placeholder='10 %'
            autoComplete='off'
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldLabel
            label='Discount Promo Code:'
            type='text'
            value={banner?.discountCode}
            // error={isEmpty(banner?.discountCode)}
            onChange={(event) => setBanner({ ...banner as DiscountBanner, discountCode: event.target.value })}
            placeholder='Enter the discount amount'
            autoComplete='off'
          />
        </Grid>
      </Grid>
      <Grid item container spacing={2}>
        <Grid item xs={12}>
          <CurrencyTextField
            autoComplete='off'
            label='Min Amount: '
            value={banner?.minAmount}
            size='small'
            onChange={(e) => {
              setBanner({ ...banner as DiscountBanner, minAmount: e })
            }}
            labelVariant='h6'
            mask={currencyMask}
            name='amount-number-estimate-discount-modal'
            id='amount-number-estimate-discount-modal'
          />
        </Grid>
      </Grid>
      <Grid item container spacing={2}>
        <Grid item xs={12}>
          <TextFieldLabel
            label='Discount Label:'
            type='text'
            value={banner?.description}
            // error={isEmpty(banner?.description)}
            onChange={(event) => setBanner({ ...banner as DiscountBanner, description: event.target.value })}
            placeholder='Enter the banner label that will appear on the Portal App'
            autoComplete='off'
            multiline
            rows={4}
          />
        </Grid>
      </Grid>
      <Grid item container spacing={2}>
        <Grid item xs={6}>
          <DatePicker
            placeholder='mm/dd/yyyy'
            onChange={(dateSelected: Date) => setBanner({ ...banner as DiscountBanner, startTime: formatDateTimestamp(dateSelected) })}
            value={formatTimestampDate(banner?.startTime || null)}
            style={{ height: '38px', padding: '0px', width: '100%' }}
            size="small"
            centerOnScreen
            label="Start Date"
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            placeholder='mm/dd/yyyy'
            onChange={(dateSelected: Date) => setBanner({ ...banner as DiscountBanner, endTime: formatDateTimestamp(dateSelected) })}
            value={formatTimestampDate(banner?.endTime || null)}
            style={{ height: '38px', padding: '0px', width: '100%' }}
            size="small"
            centerOnScreen
            label="End Date"
          />
        </Grid>
      </Grid>
      <Grid item container spacing={2} marginTop={1}>
        <Grid item xs={6}>
          <Button
            // sx={{ width: '200px' }}
            variant='outlined'
            onClick={onClose}
            fullWidth
          >
            Close
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            // sx={{ width: '200px' }}
            variant='contained'
            onClick={handleSave}
            disabled={!isValid}
            fullWidth
          >
            {!loading ? 'Save Changes' : <CircularProgress size={20} />}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default EstimateDiscountModal