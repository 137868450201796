import { Box, Grid, Typography } from '@mui/material'
import Tag from 'src/components/UI/CustomUI/atoms/Tag'
import { AlignType, HeaderType } from 'src/components/UI/CustomUI/organisms/Table/types'
import { getTradeColor } from 'src/helpers/trades'

const headers: HeaderType[] = [
    {
        id: 'level',
        label: 'Tag',
        custom: true,
        align: 'center' as AlignType,
        Element: (row: any): JSX.Element => {
            return (
                <Box display="flex" flexDirection="column" gap={1}>
                    <Tag width="100%" center background={getTradeColor(row.level)}>{` L${row.level}: Trade`}</Tag>
                </Box>
            )
        }
    },
    {
        id: 'description',
        label: 'Level',
        custom: true,
        Element: (row: any): JSX.Element => {
            return <Grid>
                <Typography fontSize="var(--font-XS)">
                    {row.description}
                </Typography>
            </Grid>
        }
    },

]

export default headers
