/* eslint-disable react-hooks/exhaustive-deps */
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { Box, Typography } from 'src/components/UI'
import Table from 'src/components/UI/CustomUI/organisms/Table'
import { AlignType, HeaderType } from 'src/components/UI/CustomUI/organisms/Table/types'
import { round } from 'src/helpers'
import { getJob, getOrder } from 'src/ducks/selectors'
import { JobItem } from 'src/ducks/types'
import { OrderType } from 'src/ducks/orders/types'

const headers: HeaderType[] = [
  {
    id: 'title',
    label: '',
    custom: true,
    align: 'left' as AlignType,
    color: '#EBECEF',
    columnColor: () => '#F9FAFA',
    Element: (row: any): JSX.Element => {
      return <Typography style={{ color: row.italic ? '#72748D' : 'undefined', textAlign: row.italic ? 'center' : 'left' }} variant={row.bold ? 'body1Bold' : 'body1'} fontStyle={row.italic ? 'italic' : 'inherit'}> {row.title} </Typography>
    }
  }, {
    id: 'workOrder',
    label: 'WORK ORDER',
    custom: true,
    align: 'right' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Box>
          <Typography style={{ color: row.italic ? '#72748D' : 'undefined' }} variant={row.bold ? 'body1Bold' : 'body1'} fontStyle={row.italic ? 'italic' : 'inherit'}> {`$${round(row.workOrder, 2)}`} </Typography>
          {row.workOrderPer !== undefined && <Typography display='inline' color='#72748D' fontStyle='italic'> {`(${row.workOrderPer}%)`} </Typography>}
        </Box>
      )
    }
  },
  {
    id: 'job',
    label: 'JOB',
    custom: true,
    align: 'right' as AlignType,
    Element: (row: any): JSX.Element => {
      const totalWithoutDiscount = row?.discount ? (100 * row?.job) / (100 - (row?.discount * -1)) : null
      return (
        <Box>
          <Typography style={{ display: 'inline', color: row.italic ? '#72748D' : 'undefined' }} variant={row.bold ? 'body1Bold' : 'body1'} fontStyle={row.italic ? 'italic' : 'inherit'}> {`$${round(row.job, 2)}`} </Typography>
          {row.jobPer !== undefined && <Typography display='inline' color='#72748D' fontStyle='italic'> {`(${row.jobPer}%)`} </Typography>}
          {totalWithoutDiscount && <Typography style={{ display: 'inline', color: '#72748D' }} variant={'body1'} fontStyle={'italic'}> {`($${round(totalWithoutDiscount, 2)})`} </Typography>}
        </Box>
      )
    }
  }
]

const CostsTable: FC = () => {
  const jobOrder = useSelector(getOrder())
  const job = useSelector(getJob())

  const {
    // totalCost: jobTotalCost,
    vendorPaid: jobVendorPaid,
    margin: jobMargin,
    vendorOutstanding: jobVendorOutstanding,
    totalProfit: jobTotalProfit,
    properties: { totalValue: jobTotalValue, discount },
    materialCost
  } = job as JobItem || {}

  const {
    // totalCost: orderTotalCost,
    totalValue: orderTotalValue,
    bidPrice: orderBidPrice,
    materialCost: orderMaterialCost
  } = jobOrder as OrderType || {}

  const jobVendorSpend = jobVendorPaid + jobVendorOutstanding
  const workOrderProfit = orderTotalValue - orderBidPrice
  const workOrderPer = workOrderProfit ? round((workOrderProfit / orderTotalValue) * 100, 2) : 0
  const jobPer = round(jobMargin, 2)

  return (
    <Table
      headers={headers}
      defaultOrder='desc'
      small
      totalItems={0}
    >
      {[
        // { title: 'COST', workOrder: orderTotalCost, job: jobTotalCost },
        { title: 'CUSTOMER PRICE', workOrder: orderTotalValue, job: jobTotalValue, discount },
        { title: 'VENDOR DISPATCH AMOUNT', workOrder: orderBidPrice, job: jobVendorSpend },
        { title: 'MATERIAL SPEND', workOrder: orderMaterialCost, job: materialCost, italic: true },
        { title: 'PROFIT', workOrder: workOrderProfit, job: jobTotalProfit, bold: true, workOrderPer, jobPer }
      ]}
    </Table>
  )
}

export default CostsTable
