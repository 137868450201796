import { EmployeeType } from '../employee/types'

/** TYPES **/
export const employeesTypes = {
  FETCH_EMPLOYEES: 'FETCH_EMPLOYEES',
  SET_EMPLOYEES: 'SET_EMPLOYEES',
  FETCH_EMPLOYEE_ID: 'FETCH_EMPLOYEE_ID',
  UPDATE_EMPLOYEE_IN_LIST_BY_ID: 'UPDATE_EMPLOYEE_IN_LIST_BY_ID'
}

export interface SearchParams {
  approved?: boolean,
  territories?: string[],
  role?: string | string[],
  oldTable?: boolean,
  employeeId?: string,
  search?: string,
}

/** ACTIONS **/
export interface FetchEmployeesAction {
  type: typeof employeesTypes.FETCH_EMPLOYEES
  payload: {
    page?: number, size?: number,
    searchParams?: Partial<SearchParams>
  }
  callback: (succ: boolean, data?: any) => void
}
export interface SetEmployees {
  type: typeof employeesTypes.SET_EMPLOYEES
  payload: EmployeeType[]
}
export interface SetOldEmployees {
  type: typeof employeesTypes.SET_EMPLOYEES
  payload: EmployeeType[]
}

export interface UpdateEmployeeInListById {
  type: typeof employeesTypes.UPDATE_EMPLOYEE_IN_LIST_BY_ID
  payload: {
    updatedValues: Partial<EmployeeType>
    id: string
  }
}

export type EmployeesActions =
  | FetchEmployeesAction
  | SetEmployees
  | UpdateEmployeeInListById

/** REDUCER **/

export interface EmployeesType {
  start: number
  limit: number
  total: number
  items: EmployeeType[]
}

export type Employees = EmployeesType | null
