import { Button, Tooltip } from '@mui/material';
import { SyntheticEvent } from 'react';
import { ShowMoreProps } from './types';

const ShowMore = ({ children, limit = children?.length, buttonSx, tooltipContent }: ShowMoreProps) => {
  const handleClick = (e: SyntheticEvent<EventTarget>) => {
    e.stopPropagation()
  }

  return (
    <>
      {children?.slice(0, limit)?.map((item: React.ReactNode) => item)}
      {children?.length > limit &&
        <Tooltip sx={{ width: '50%' }} title={tooltipContent}>
          <Button variant='toggleContained' sx={buttonSx}
            onClick={handleClick}>
            + More
          </Button>
        </Tooltip>
      }
    </>
  )
}
export default ShowMore