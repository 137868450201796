/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState, useCallback, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { PrivateLoader } from 'src/components/templates'
import { ActionsButton, Box, SelectInput } from '../../../../../UI'
import { getOrders, getVendor } from 'src/ducks/selectors'
import Table from 'src/components/UI/CustomUI/organisms/Table'
import headers from './headers'
import { SelectInputOption } from 'src/components/UI/CustomUI/atoms/SelectInput/types'
import TablePagination from 'src/components/UI/CustomUI/organisms/Table/TablePagination'
import { ORDER_STATUS, round, ROWS_PER_PAGE_EXTENDED } from 'src/helpers'
import ExportCSV from 'src/components/UI/CustomUI/molecules/ExportCSV'
import { icons } from 'src/assets'
import { CSVLink } from 'react-csv'
import { OrderType } from 'src/ducks/orders/types'
import { ordersActions } from 'src/ducks/actions'
import moment from 'moment'

const status = [
  { key: '0', label: 'All Jobs Status' },
  { key: '1', label: 'In Review' },
  { key: '2', label: 'Active' },
  { key: '3', label: 'Completed' },
]

const Jobs: FC = () => {
  const dispatch = useDispatch()

  const orders = useSelector(getOrders())
  const vendor = useSelector(getVendor())

  const [tableLoading, setTableLoading] = useState(true)
  const [selectedStatus, setSelectedStatus] = useState(status[0])
  const [start, setStart] = useState<number>(0)
  const [end, setEnd] = useState<number>(50)
  const [rowsPerPage, setRowsPerPage] = useState<number>(50)
  const [loadingCSV] = useState(false)

  const downloadRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null)

  const handleChange = (newSelected: SelectInputOption) => {
    setSelectedStatus(newSelected)
    switch (newSelected.label) {
      case 'All Jobs Status':
        fetchOrders()
        break
      case 'In Review':
        fetchOrders([ORDER_STATUS.REVIEW])
        break
      case 'Active':
        fetchOrders([ORDER_STATUS.DISPATCHED, ORDER_STATUS.IN_PROGRESS])
        break
      case 'Completed':
        fetchOrders([ORDER_STATUS.PAID])
        break
    }
  }

  const fetchOrders = useCallback((statuses: string[] = []) => {
    setTableLoading(true)
    dispatch(
      ordersActions.fetchOrders(
        { page: 0, size: 1000, params: { vendorIds: vendor?.id, statuses } },
        (_succ: boolean) => {
          setTableLoading(false)
          // TODO: filter ids when jobs api is ready
        }
      )
    )
  }, [])

  const updatePage = (page: number, size: number) => {
    const jobsStart = page * size
    const jobsEnd =
      jobsStart + size > orders.length ? orders.length : jobsStart + size

    setStart(jobsStart)
    setEnd(jobsEnd)
    setRowsPerPage(size)
  }

  useEffect(() => {
    fetchOrders()
  }, [])

  useEffect(() => {
    setEnd(start + rowsPerPage > orders.length ? orders.length : start + rowsPerPage)
  }, [orders])

  const handleRowClick = (row: OrderType, e: React.MouseEvent) => {
    if (e.type !== 'contextmenu') {
      window.open(`/jobs/${row.estimateId}`)
      e.preventDefault()
    }
  }

  const downloadCSV = (event: any) => {
    //const searchParams = getParams();
    downloadRef?.current?.link?.click()
    //setLoadingCSV(true)
  }

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <PrivateLoader loading={false}>
        <Box
          display="flex"
          gap={1}
          width="100%"
          style={{ justifyContent: 'flex-end' }}
        >
          <SelectInput
            options={status}
            value={selectedStatus}
            placeholder="All Jobs Status"
            sx={{ minWidth: '200px' }}
            onChange={handleChange}
          />
          <ActionsButton
            loading={loadingCSV}
            icon={<icons.CloudDownload color="disabled" />}
            iconPosition="end"
            onClick={downloadCSV}
            sx={{
              minWidth: '56px!important',
            }}
          />
          <ExportCSV
            innerRef={downloadRef}
            headers={
              headers
                ? headers.map((header) => ({
                  key: header.id as string,
                  label: header.label as string,
                }))
                : []
            } //tableJobs?.map((h: any) => h)}
            data={orders.map(order => {
              return {
                address: order.address.fullAddress,
                territory: `${order.address.city}, ${order.address.state}`,
                value: `$${round(order.totalValue, 2)}`,
                items: order.numberOfItems,
                startDate: order.scheduledStartTimestamp
                  ? `${moment(order.scheduledStartTimestamp * 1000).format(
                    'MM/DD/YYYY'
                  )}`
                  : '-',
                endDate: order.scheduledEndTimestamp
                  ? `${moment(order.scheduledEndTimestamp * 1000).format('MM/DD/YYYY')}`
                  : '-',
                completedOn: order.completedTimestamp
                  ? `${moment(order.completedTimestamp * 1000).format('MM/DD/YYYY')}`
                  : '-',
                duration: (order.completedTimestamp || order.scheduledEndTimestamp) && order.scheduledStartTimestamp
                  ? `${moment((order.completedTimestamp || order.scheduledEndTimestamp || 0) * 1000).diff(
                    moment(order.scheduledStartTimestamp * 1000),
                    'days'
                  ) + 1
                  } days`
                  : ''
              }
            })}
            fileName={'jobs.csv'}
            label=""
          />
        </Box>
        <Box display="flex" width="100%" justifyContent="flex-end">
          <TablePagination
            callToApi={(start, limit) => {
              updatePage(start, limit)
            }}
            totalItems={orders.length}
            rowsPerPageOptions={ROWS_PER_PAGE_EXTENDED}
          />
        </Box>
        <Table headers={headers} hasPagination={false} rowAction={handleRowClick} loading={tableLoading}>
          {orders.slice(start, end)}
        </Table>
      </PrivateLoader>
    </Box>
  )
}

export default Jobs
