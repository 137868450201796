import React, { useReducer } from "react"
import { FC } from "react"
import { WidgetsReducer } from "./reducer"
import { WidgetsContextType } from "./types"

const initialState: WidgetsContextType = {
    // MODAL
    modalType: null,
    modalOpen: false,
}

export const WidgetsContext = React.createContext<{
    state: WidgetsContextType,
    dispatch: React.Dispatch<any>
}>({
    state: initialState,
    dispatch: () => null
})

export const WidgetsProvider: FC = ({ children }: any) => {
    const [state, dispatch] = useReducer(WidgetsReducer, initialState)
    return (
        <WidgetsContext.Provider value={{ state, dispatch }}>
            {children}
        </WidgetsContext.Provider>
    )
}