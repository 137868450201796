import { useState } from "react";
import useStyles from './styles'
import { estimateNote } from "src/assets";
import Icon from "src/components/UI/CustomUI/atoms/Icon";
import Counter from "src/components/UI/CustomUI/molecules/Counter";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";

export default function NewEstimateModal() {
    const classes = useStyles()
    const [open, setOpen] = useState(true)
    const closeDialog = () => setOpen(false)
    return (
        <Dialog
            open={open}
            maxWidth='md'
            fullWidth
            onClose={() => setOpen(false)}
            classes={{ paper: classes.root }}>
            <DialogTitle className={classes.dialogTitle}>
                <IconButton onClick={closeDialog} className={classes.closeButton} ><Icon name="Close" /></IconButton></DialogTitle>
            <DialogContent>
                <Box display='flex' alignItems='center' justifyContent='center' marginBottom='16px'>
                    <img src={estimateNote} alt='Notepad' />
                </Box>
                <Typography align='center' variant='h4' className={classes.title}>
                    Your estimate is <span>Ready!</span>
                </Typography>
                <Typography align='center' variant='subtitle2'>
                    Here are some tips on viewing your estimate.
                </Typography>
                <Box display='flex' flexDirection='column' marginY='8px' alignItems='center'>
                    <Box flex='1'>
                        <Typography variant='body2'>
                            1. For best utilization of your estimate, please update quantities for each item as needed. You can change the quantity for each repair item by clicking on these buttons.
                        </Typography>
                    </Box>
                    <Box flex='1' alignItems='flex-end' style={{ zoom: 0.7 }}>
                        <Counter
                            initialValue={3}
                            color={'primary'}
                        />
                    </Box>
                </Box>
                <Box display='flex' flexDirection='row' alignItems='center'>
                    <Box >
                        <Typography variant='body2'>
                            2. To see detailed descriptions and photos, click on the blue button.
                        </Typography>
                    </Box>
                    <Box marginLeft='5px'>
                        <Box flex='0.1' bgcolor='#5278FF' className={classes.expandMore}>
                            <Icon name="ExpandMore" className={classes.expandMoreIcon} />
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    className={classes.button}
                    variant='contained'
                    fullWidth={true}
                    onClick={closeDialog}>
                    Got it! I'll do it!
                </Button>
            </DialogActions>
        </Dialog>
    )
}