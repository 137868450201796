import { useState, useEffect, useRef, FC, Suspense } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Typography, Button, Box, TextFieldLabel, TitleFirst, Avatar } from '../../../UI'
import useStyles from './styles'
import { isEmpty, PHONE_MASK_REGEXP, PHONE_MASK_INPUT } from '../../../../helpers'
import { PunchlistLoader, AuthPage } from '../../../templates'
import { arrow, uploadPhoto } from '../../../../assets'
import { getEmployeeId } from 'src/ducks/selectors'
import { employeeActions, filesActions } from 'src/ducks/actions'
import { usePaddingWrapper } from 'src/hooks'
import { EmployeeType } from 'src/ducks/employee/types'
import { toast } from 'react-toastify'

const Account: FC = () => {
  const classes = useStyles()
  const inputRef = useRef<any>(null)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [name, setName] = useState('')
  const [lastname, setLastname] = useState('')
  const [phone, setPhone] = useState('')
  const [file, setFile] = useState(null)
  const id = useSelector(getEmployeeId)
  const { setHasPadding } = usePaddingWrapper()

  const [nameWarning, setNameWarning] = useState('')
  const [lastnameWarning, setLastnameWarning] = useState('')
  const [phoneWarning, setPhoneWarning] = useState('')
  const [error, setError] = useState(false)

  const [loading, setLoading] = useState(false)
  const [submit, setSubmit] = useState(false)

  const validate =
    !isEmpty(name) &&
    !isEmpty(lastname) &&
    !isEmpty(phone) &&
    PHONE_MASK_REGEXP.test(phone)

  useEffect(() => {
    if (submit) {
      if (isEmpty(name)) {
        setNameWarning('Enter your name to create an account')
      } else {
        setNameWarning('')
      }
    }
  }, [submit, name])

  useEffect(() => {
    if (submit) {
      if (isEmpty(lastname)) {
        setLastnameWarning('Enter your last name to create an account')
      } else {
        setLastnameWarning('')
      }
    }
  }, [submit, lastname])

  useEffect(() => {
    if (submit) {
      if (isEmpty(phone) || !PHONE_MASK_REGEXP.test(phone)) {
        setPhoneWarning('Enter your full phone number to create an account')
      } else {
        setPhoneWarning('')
      }
    }
  }, [submit, phone])

  useEffect(() => {
    setHasPadding(false)
  }, [])

  const handleUpload = (event: any): void => {
    if (event.target.files && event.target.files?.length > 0) {
      setFile(event.target.files[0])
    }
  }

  const updateEmployee = (params: Partial<EmployeeType>) => {
    dispatch(employeeActions.updateEmployeeId(id, params, (success: boolean) => {
      setLoading(false)
      if (success) {
        dispatch(employeeActions.setEmployeeValues({ attrs: params }))
        toast.success.call('success', 'Your account has been successfully created')
        navigate('/home')
      } else setError(true)
    }))
  }

  const handleCreate = (event: any) => {
    event.preventDefault()
    setSubmit(true)
    if (validate) {
      setLoading(true)
      const phoneNumber = phone.replaceAll('-', '')

      // save file to server
      if (file !== null) {
        dispatch(filesActions.uploadFile(file as File, (succ, fileName) => {
          if (succ) {
            const params = { firstName: name, lastName: lastname, phone: parseInt(phoneNumber), picture: fileName }
            updateEmployee(params)
          }
        }))
      } else {
        const params = { firstName: name, lastName: lastname, phone: parseInt(phoneNumber) }
        updateEmployee(params)
      }
    }
  }

  return (
    <Suspense fallback={<PunchlistLoader />}>
      <AuthPage>
        <PunchlistLoader loading={loading}>
          <TitleFirst value='Let’s create' black='your BOSSCAT CSM Account' />
          <Typography variant='h6' className={classes.text}>
            Let’start!
          </Typography>
          <Box className={classes.center}>
            <form className={classes.form} autoComplete='on'>
              <Box className={classes.imagePicker}>
                <input
                  type='file'
                  accept='image/*'
                  onChange={handleUpload}
                  ref={inputRef}
                  className={classes.hiddenInput}
                />
                <Button onClick={() => inputRef.current.click()}>
                  <img src={uploadPhoto} alt='' className={classes.profile} />
                </Button>
              </Box>

              <TextFieldLabel
                label='First name:'
                type='text'
                value={name}
                error={submit && !isEmpty(nameWarning)}
                helperText={submit && nameWarning}
                onChange={(event) => setName(event.target.value)}
                placeholder='Enter your name here'
                autoComplete='off'
                sx={{ marginBottom: '24px', height: { sm: '54px' } }}
                className={classes.name}
              />
              <TextFieldLabel
                label='Last name:'
                type=''
                value={lastname}
                error={submit && !isEmpty(lastnameWarning)}
                helperText={submit && lastnameWarning}
                onChange={(event) => setLastname(event.target.value)}
                placeholder='Enter your last name here'
                autoComplete='off'
                sx={{ marginBottom: '24px', height: { sm: '54px' } }}
                className={classes.name}
              />
              <TextFieldLabel
                label='Phone number:'
                type='phone'
                value={phone}
                error={submit && !isEmpty(phoneWarning)}
                helperText={submit && phoneWarning}
                onChange={(event) => setPhone(event.target.value)}
                placeholder='555-555-0000'
                mask={PHONE_MASK_INPUT}
                sx={{ marginBottom: '24px', height: { sm: '54px' } }}
                className={classes.name}
              />
              {submit && error &&
                <Typography
                  variant='body2'
                  color='error'
                  style={{ padding: '0 16px' }}
                >
                  An error occurred while trying to create your account, please try again.
                </Typography>}
              <Button
                type='submit'
                variant='contained'
                color='primary'
                size='large'
                className={classes.submit}
                onClick={handleCreate}
                sx={{ height: '56px' }}
                endIcon={
                  <Avatar
                    alt=''
                    src={arrow}
                    sx={{ height: '0.75rem', width: '0.75rem' }}
                  />
                }
                fullWidth
              >
                Create my CSM Account
              </Button>
            </form>
          </Box>
        </PunchlistLoader>
      </AuthPage>
    </Suspense>
  )
}

export default Account
