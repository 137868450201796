import { createStyles, makeStyles } from "@mui/styles";

export default makeStyles(() =>
  createStyles({
    price: {
      display: 'flex',
      alignItems: 'flex-end'
      // gap: '.2rem'
    },
    total: {
      fontFamily: 'NextArtBold',
      fontSize: '16px',
      lineHeight: '1',
      /* [theme.breakpoints.down('sm')]: {
        fontSize: 20
      } */
    },
    decimals: {
      fontFamily: 'NextArt',
      fontSize: '11px',
      lineHeight: '1',
      fontWeight: 600,
      transform: 'translateY(-0.5px)'
    },

  })
)
