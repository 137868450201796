import { FC, useEffect, useState } from 'react'
import { ModalSubmitForReleaseProps } from './types'
import Modal from 'src/components/UI/CustomUI/molecules/Modal'
import { Button, Divider, Grid, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { getEmployeesForSelect, getEstimate } from 'src/ducks/selectors'
import { EstimateItem } from 'src/ducks/types'
import { EMPLOYEE_ROLE_TYPE, ESTIMATE_STATUS } from 'src/helpers'
import { employeesActions, estimateActions } from 'src/ducks/actions'
import { SelectInput, TextFieldLabel } from 'src/components/UI'
import { SelectInputOption } from 'src/components/UI/CustomUI/atoms/SelectInput/types'

const ModalSubmitForRelease: FC<ModalSubmitForReleaseProps> = ({
  open,
  setOpen,
}) => {
  const dispatch = useDispatch()

  const estimate = useSelector(getEstimate())
  const reviewers = useSelector(getEmployeesForSelect())

  const {
    properties: { address },
  } = estimate as EstimateItem

  const [additionalNote, setAdditionalNote] = useState<string>('')
  const [estimator, setEstimator] = useState<any>(undefined)

  useEffect(() => {
    dispatch(
      employeesActions.fetchEmployees(
        {
          page: 0,
          size: 150,
          searchParams: { role: [EMPLOYEE_ROLE_TYPE.TERRITORY_ESTIMATOR, EMPLOYEE_ROLE_TYPE.TERRITORY_ESTIMATOR_MANAGER], approved: true },
        },
        (succ) => {}
      )
    )
  }, [])

  const handleChangeEstimator = (newValue: SelectInputOption): void => {
    setEstimator(newValue)
  }

  const handleAssign = (): void => {
    if (!estimator) return

    dispatch(
      estimateActions.submitEstimate(
        {
          newStatus: ESTIMATE_STATUS.READY_FOR_RELEASE,
          estimatorId: estimator.key,
          additionalInfo: additionalNote,
        },
        (succ) => {
          setOpen(false)
        }
      )
    )
  }

  return (
    <Modal open={open} setOpen={setOpen} title="Submit For Release">
      <Grid container flexDirection="column" style={{ gap: '12px' }}>
        <Typography variant="h5">Property</Typography>

        <a
          href={address?.googleUrl || 'https://maps.google.com'}
          target="_blank"
          rel="noreferrer"
        >
          <Typography color="#3A66FF">
            {address?.fullAddress || '-'}{' '}
          </Typography>
        </a>

        <Divider />

        <SelectInput
          options={reviewers.slice(1)}
          label="Reviewers"
          value={estimator}
          placeholder="Select Reviewer"
          onChange={handleChangeEstimator}
        />

        <TextFieldLabel
          multiline
          rows={6}
          value={additionalNote}
          onChange={(e) => setAdditionalNote(e.target.value)}
          label="Additional Information"
          labelVariant="h6"
        />

        <Grid container justifyContent="flex-end" style={{ gap: '8px' }}>
          <Button variant="containedBig" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button variant="containedBig" onClick={handleAssign}>
            Confirm
          </Button>
        </Grid>
      </Grid>
    </Modal>
  )
}

export default ModalSubmitForRelease
