import { Button, Grid } from "@mui/material"
import { FC, useState } from "react"
import Icon from "../Icon"

import styles from './styles.module.scss'
import { ExpandProps } from "./types"

const Expand: FC<ExpandProps> = ({
  style = {},
  children,
  onClick,
  hasPadding = true,
}) => {
  const [expanded, setExpanded] = useState(false)

  return (
    <Grid
      container
      flexDirection='column'
      className={`${expanded ? styles.Expand__expanded : ''} ${hasPadding ? styles.Expand__expanded__noPadding : ''} ${styles.Expand}`}
      gridTemplateRows='min-content auto'
      height='100%'
      width='100%'
    >
      <Grid item height={32} alignItems="flex-end">
        <Button
          className={`${styles.Tab} ${styles.Expand__button}`}
          key='flow'
          style={{
            ...style,
            position: 'absolute',
          }}
          onClick={() => {
            setExpanded(!expanded)
            onClick && onClick()
          }}
        >
          {expanded ? <Icon name="KeyboardArrowDown" /> : <Icon name="KeyboardArrowUp" />}
        </Button>
      </Grid>
      {children}
    </Grid>
  )
}

export default Expand