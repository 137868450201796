import { PriceItemType, RepairListItem } from '../types'
import { repairItemTypes, RepairItemActions } from './types'

const actions = {
  fetchRepairItem: (
    payload: string,
    callback?: (succ: boolean) => void
  ): RepairItemActions => ({
    type: repairItemTypes.FETCH_REPAIRITEM,
    payload,
    callback,
  }),

  patchRepairItem: (
    payload: Partial<RepairListItem>,
    callback?: (succ: boolean) => void
  ): RepairItemActions => ({
    type: repairItemTypes.PATCH_REPAIRITEM,
    payload,
    callback,
  }),

  fetchPriceList: (
    payload: string,
    callback?: (succ: boolean) => void
  ): RepairItemActions => ({
    type: repairItemTypes.FETCH_PRICELIST,
    payload,
    callback,
  }),

  fetchPriceItem: (
    payload: {
      repairItemId: string
      territoryId: string
    },
    callback?: (succ: boolean, priceItem?: PriceItemType) => void
  ): RepairItemActions => ({
    type: repairItemTypes.FETCH_PRICEITEM,
    payload,
    callback,
  }),

  createRepairItem: (
    payload: Partial<RepairListItem>,
    callback?: (succ: boolean, newRepairItem?: RepairListItem) => void
  ): RepairItemActions => ({
    type: repairItemTypes.CREATE_REPAIRITEM,
    payload,
    callback,
  }),

  patchPriceItem: (
    payload: {
      request: Partial<PriceItemType>
      territoryId: string
    },
    callback?: (succ: boolean) => void
  ): RepairItemActions => ({
    type: repairItemTypes.PATCH_PRICEITEM,
    payload,
    callback,
  }),

  patchAllPriceItems: (
    payload: Partial<PriceItemType>,
    callback?: (succ: boolean) => void
  ): RepairItemActions => ({
    type: repairItemTypes.PATCH_ALL_PRICEITEMS,
    payload,
    callback,
  }),

  patchPriceItems: (
    payload: {
      ids: string[]
      request: Partial<PriceItemType>
    },
    callback?: (succ: boolean) => void
  ): RepairItemActions => ({
    type: repairItemTypes.PATCH_PRICEITEMS,
    payload,
    callback,
  }),

  deleteRepairItem: (
    payload: string,
    callback?: (succ: boolean) => void
  ): RepairItemActions => ({
    type: repairItemTypes.DELETE_REPAIRITEM,
    payload,
    callback,
  }),

  setRepairItem: (payload: RepairListItem): RepairItemActions => ({
    type: repairItemTypes.SET_REPAIRITEM,
    payload,
  }),

  setPriceList: (payload: PriceItemType[]): RepairItemActions => ({
    type: repairItemTypes.SET_PRICELIST,
    payload,
  }),

  setPriceItem: (payload: PriceItemType): RepairItemActions => ({
    type: repairItemTypes.SET_PRICEITEM,
    payload,
  }),
}

export default actions
