import React, { useState } from 'react'
import { FieldsFilter, Grid, InputSearch, SelectInput } from 'src/components/UI'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import { SelectInputOption } from 'src/components/UI/CustomUI/atoms/SelectInput/types'
import Table from 'src/components/UI/CustomUI/organisms/Table'
import headers from './headers'

const territories = [
    { key: '1', label: 'Dropdown 1' },
    { key: '2', label: 'Dropdown 2' },
]

const Invoices = () => {
    const [searchFilter, setSearchFilter] = useState('')
    const [selectedTerritory, setSelectedTerritory] = useState(territories[0])
    const [tableHeaders, setTableHeaders] = useState(headers)

    const handleSelectChange = (newSelected: SelectInputOption) => {
        setSelectedTerritory(newSelected)
    }

    const onTableFilterChange = (index: number): void => {
        setTableHeaders(tableHeaders.map((item, indx) => {
            if (indx === index) {
                return { ...item, hide: !item.hide }
            }
            return item
        }))
    }

    return (
        <Grid container direction="column" flex={1} spacing={2} >
            <Grid item container justifyContent="flex-end" spacing={2}>
                <Grid item>
                    <InputSearch
                        placeholder='Search...'
                        onChange={setSearchFilter}
                        value={searchFilter}
                    />
                </Grid>
                <Grid item>
                    <SelectInput
                        customIcon={
                            <Icon name="KeyboardArrowDown" color="var(--gray-400)" />
                        }
                        onChange={handleSelectChange}
                        options={territories}
                        value={selectedTerritory}
                        sx={{ minWidth: '200px' }}
                    />
                </Grid>
                <Grid item>
                    <FieldsFilter options={tableHeaders} handleChange={onTableFilterChange} />
                </Grid>
            </Grid>
            <Grid item>
                <Table headers={tableHeaders}>
                    {[]}
                </Table>
            </Grid>
        </Grid>
    )
}

export default Invoices