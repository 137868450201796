import { authTypes } from '../auth/types'
import { Job, Count } from '../types'
import { JobsActions, jobsTypes, JobsType } from './types'

const initialState: JobsType = {
  start: '',
  limit: '',
  total: '',
  items: [],
  counts: [],
  jobsCSV: '',
}

const reducer = (state = initialState, action: JobsActions): JobsType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case jobsTypes.SET_JOBS: {
      return {
        ...state,
        start: (payload as Job).start,
        limit: (payload as Job).limit,
        items: (payload as Job).items,
        total: (payload as Job).total
      }
    }

    case jobsTypes.SET_JOBS_COUNTS: {
      return {
        ...state,
        counts: payload as Count[]
      }
    }

    case jobsTypes.SET_JOBS_CSV: {
      return {
        ...state,
        jobsCSV: payload as string
      }
    }

    default:
      return state
  }
}

export default reducer
