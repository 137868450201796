/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'

import { errorTextTryingTo } from '../../helpers'
import { Punchlist } from '../../api'
import { paymentsTypes, GetPaymentsAction, CreatePaymentAction } from './types'
import { paymentsActions } from '../actions'
import { isEmpty } from 'lodash'
import * as Sentry from '@sentry/react'

export function* fetchPayments({ payload, callback }: GetPaymentsAction): SagaIterator {
  let success = false
  try {
    const params = payload.params ?? {}
    const page = params?.start ? params.start : 0
    const size = params?.limit ? params.limit : 50

    const start = page * size
    const limit = start + size

    if (start) params.start = start
    if (limit) params.limit = limit

    const payments = yield call(Punchlist.payments.getPayments, { params })

    if (payments) {
      yield put(paymentsActions.setPayments(payments))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('get the payments'))
    if (callback) yield call(callback, false)
  }
}

export function* createPayment({ payload, callback }: CreatePaymentAction): SagaIterator {
  let success = false
  try {
    const payments = yield call(Punchlist.payments.createPayment, payload)
    if (!isEmpty(payments)) {
      yield put(paymentsActions.fetchPayments({ params: { limit: 25, jobId: payload.resourceId, start: 0 } }))
      success = true
    }
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('create the note'))
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(paymentsTypes.GET_PAYMENTS, fetchPayments)
  yield takeLatest(paymentsTypes.CREATE_PAYMENT, createPayment)
}
