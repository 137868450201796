/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo, isEmpty } from '../../helpers'
import { Punchlist } from '../../api'
import { ClientType, clientTypes, CreateClientAction, FetchClientAction, UpdateClientIdAction } from './types'
import { clientActions } from '../actions'
import { UpdateRequest } from '~api/types'

export function* fetchClient({ payload, callback }: FetchClientAction): SagaIterator {
  let success = false
  try {
    const client = yield call(Punchlist.clients.getClientById, payload)
    const statistics = yield call(Punchlist.clients.getClientStatics, payload)
    if (client) {
      yield put(clientActions.setClient(client))
      yield put(clientActions.setClientValue({ attr: "statistics", value: statistics }))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the client'))
    if (callback) yield call(callback, false)
  }
}

export function* createClient({ payload, callback }: CreateClientAction): SagaIterator {
  let success = false
  try {
    const client = yield call(Punchlist.clients.addClient, payload)

    if (client) {
      yield put(clientActions.setClient(client))
    }

    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('create a client'))
    if (callback) yield call(callback, false)
  }
}

export function* updateClientId({ clientId, payload, callback }: UpdateClientIdAction): SagaIterator {
  let success = false

  try {
    const request: UpdateRequest = []

    for (const attr in payload) {
      request.push({
        op: 'add',
        path: '/' + attr,
        value: payload[attr as keyof ClientType]
      })
    }
    const data = yield call(Punchlist.clients.updateClientId, clientId, request)

    if (!isEmpty(data)) {
      success = true
      yield put(clientActions.setClient(data))

    }

    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('update the client'))
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(clientTypes.FETCH_CLIENT, fetchClient)
  yield takeLatest(clientTypes.UPDATE_CLIENT_ID, updateClientId)
}
