import React, { FC, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { clientsActions } from 'src/ducks/actions'
import { getClients, getCompany } from 'src/ducks/selectors'
import Table from 'src/components/UI/CustomUI/organisms/Table'
import headers from './headers'

const Users: FC = () => {
  const dispatch = useDispatch()

  const company = useSelector(getCompany())
  const clients = useSelector(getClients())

  const [tableLoading, setTableLoading] = useState<boolean>(false)

  useEffect(() => {
    setTableLoading(true)

    dispatch(
      clientsActions.fetchClients(
        {
          page: 0,
          size: 1000,
          searchParams: { affiliate: company?.affiliateId },
        },
        (succ) => {
          setTableLoading(false)
        }
      )
    )
  }, [company, dispatch])

  return (
    <Box
      paddingTop={3}
      paddingBottom={3}
      display="flex"
      gap={2.5}
      flexDirection="column"
    >
      <Table
        headers={headers}
        defaultOrder="desc"
        loading={tableLoading}
        hasPagination={false}
      >
        {clients}
      </Table>
    </Box>
  )
}

export default Users
