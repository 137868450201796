import { Theme } from '../../../UI'
import { makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => ({
  content: {
    display: 'flex',
    gap: '20px',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center'
  },
  logo: {
    width: '70%',
    maxWidth: '425px',
    paddingBottom: '25px'
  },
  loader: {
    width: '80%',
    margin: 'auto',
    borderRadius: '8px',
    maxWidth: '40%',
    [theme.breakpoints.up('md')]: {
      maxWidth: '20rem'
    }
  }
})
)
