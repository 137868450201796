import {
  Comment,
  CommentsActions,
  CommentsParams,
  CommentsType,
  commentsTypes,
} from './types'

const actions = {
  fetchComments: (
    payload: {
      page?: number
      size?: number
      params: Partial<CommentsParams>
    },
    callback?: (succ: boolean, comments?: CommentsType) => void
  ): CommentsActions => ({
    type: commentsTypes.FETCH_COMMENTS,
    payload,
    callback,
  }),
  setComments: (payload: Comment[]): CommentsActions => ({
    type: commentsTypes.SET_COMMENTS,
    payload,
  }),
  addComments: (payload: Comment[]): CommentsActions => ({
    type: commentsTypes.ADD_COMMENTS,
    payload,
  }),
  setComment: (payload: Partial<Comment>): CommentsActions => ({
    type: commentsTypes.SET_COMMENT,
    payload,
  }),
  createComment: (
    payload: Partial<Comment>,
    callback?: (succ: boolean, comment?: Comment) => void
  ): CommentsActions => ({
    type: commentsTypes.CREATE_COMMENT,
    payload,
    callback,
  }),
}

export default actions
