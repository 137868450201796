import {
  siteVisitTypes,
  SiteVisitActions,
  SiteVisitType,
  CreateSiteVisitPayload,
  UpdateSiteVisitPayload
} from './types'

const actions = {
  getSiteVisit: (
    payload: string,
    callback?: (succ: boolean) => void
  ): SiteVisitActions => ({
    type: siteVisitTypes.GET_SITE_VISIT,
    payload,
    callback
  }),
  setSiteVisit: (payload: SiteVisitType): SiteVisitActions => ({
    type: siteVisitTypes.SET_SITE_VISIT,
    payload
  }),
  createSiteVisit: (
    payload: CreateSiteVisitPayload,
    callback: (succ: boolean) => void
  ): SiteVisitActions => ({
    type: siteVisitTypes.CREATE_SITE_VISIT,
    payload,
    callback
  }),
  updateSiteVisit: (
    payload: UpdateSiteVisitPayload,
    callback: (succ: boolean) => void
  ): SiteVisitActions => ({
    type: siteVisitTypes.UPDATE_SITE_VISIT,
    payload,
    callback
  }),
}

export default actions
