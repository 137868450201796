import { Button, CircularProgress, Grid, Typography } from 'src/components/UI'
import { FC, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { PrivateLoader } from 'src/components/templates'
import { EmployeeType } from 'src/ducks/employee/types'
import { commentsActions } from 'src/ducks/actions'
import { getComments } from 'src/ducks/comments/selectors'
import {
  getEmployees,
  getEmployeesForDropdown,
  getEstimate,
  getTotalComments,
} from 'src/ducks/selectors'
import { isEmpty, ROWS_PER_PAGE_EXTENDED } from 'src/helpers'
import CommentItem from './Comment'
import TablePagination from 'src/components/UI/CustomUI/organisms/Table/TablePagination'

import { MentionsInput, Mention } from 'react-mentions'
import { CommentsProps } from './types'
import { WorkOrdersContext } from '../../../../../../context'
import { workOdersTypes } from '../../../../../../context/types'

const NewItemComments: FC<CommentsProps> = () => {
  const dispatch = useDispatch()

  const {
    dispatch: dispatchContext,
    state: { item, comments, selectedOrderId, selectedItemId },
  } = useContext(WorkOrdersContext)

  // const comments = useSelector(getComments())
  const employeesForDropDown = useSelector(getEmployeesForDropdown)
  const employees = useSelector(getEmployees)

  const [comment, setComment] = useState('')
  const [buttonLoading, setButtonLoading] = useState(false)
  const [tableLoading, setTableLoading] = useState(false)
  const [addCommentLoading, setAddCommentLoading] = useState(false)
  const isValid = !isEmpty(comment)
  const estimate = useSelector(getEstimate())
  const totalComments = comment.length

  const handleCreateComment = () => {
    // setButtonLoading(true)
    // setAddCommentLoading(true)
    const data: { recipientsList: any[]; comment: string } = {
      recipientsList: [],
      comment: '',
    }
    comment?.split(/[{}]+/).forEach((item) => {
      if (item.includes('ID')) {
        const userId = item?.split('ID:')[1]
        const user = employees?.find(
          (employee: EmployeeType) => employee?.id === userId
        )
        if (user?.id || userId) {
          data?.recipientsList?.push({
            id: user?.id || userId,
            userId: user?.id || userId,
            name: `${user?.firstName} ${user?.lastName}`,
          })
        }
      } else {
        data.comment += item
      }
    })
    if (isValid) {
      // if (data?.recipientsList.length > 0) {

        dispatchContext({
          type: workOdersTypes.SET_COMMENTS,
          payload: [
            ...comments,
            { message: data.comment, recipientsList: data.recipientsList },
          ],
        })
      // }

      setComment('')
    }
  }

  return (
    <>
      <PrivateLoader loading={addCommentLoading}>
        <Grid container direction="column" spacing={2}>
          <Grid container item spacing={1} direction="column">
            <Grid item>
              <Typography variant="h6Bold">Add comment</Typography>
              <MentionsInput
                placeholder="Add comment. Use '@' to tag a user."
                value={comment}
                onChange={(e: any, newValue) => setComment(e.target.value)}
                className="Comments__textarea"
                cols={4}
                rows={3}
                style={{
                  input: {
                    border: '2px solid #EBECEF',
                    borderRadius: '8px',
                    height: 'auto',
                    position: 'relative',
                    padding: '.5rem',
                  },
                  suggestions: {
                    border: '2px solid #EBECEF',
                    borderRadius: '8px',
                    height: '30vh',
                    overflowY: 'scroll',
                    minWidth: '15vw',
                    padding: '0',
                  },
                  item: {
                    padding: '0 !important',
                  },
                  highlighter: {},
                  control: {},
                }}
              >
                <Mention
                  markup="{{@__display__}} {{ID:__id__}}"
                  trigger="@"
                  data={employeesForDropDown}
                  renderSuggestion={(suggestion: any) => {
                    return (
                      <Grid padding={1}>
                        <Typography variant="body1Bold">
                          {suggestion?.display}
                        </Typography>
                        <Typography variant="body1">
                          {suggestion?.email}
                        </Typography>
                      </Grid>
                    )
                  }}
                />
              </MentionsInput>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={handleCreateComment}
                disabled={!isValid}
                sx={{ padding: '0.3rem 1.5rem !important' }}
              >
                {buttonLoading ? (
                  <CircularProgress color="info" size="1.4rem" />
                ) : (
                  'Save Comment'
                )}
              </Button>
            </Grid>
          </Grid>

          <Grid item>
            <TablePagination
              // callToApi={(start, limit) => { fetchComments(start, limit) }}
              totalItems={totalComments}
              rowsPerPageDefault={5}
              rowsPerPageOptions={ROWS_PER_PAGE_EXTENDED}
            />
          </Grid>
          <Grid item container direction="column">
            <PrivateLoader loading={tableLoading}>
              <Grid item>
                {comments?.length > 0 &&
                  comments
                    ?.sort((a, b) => (a.insertedOn < b.insertedOn ? 1 : -1))
                    .map((comment, index) => {
                      return <CommentItem key={comment.id} comment={comment} />
                    })}
              </Grid>
            </PrivateLoader>
          </Grid>
        </Grid>
      </PrivateLoader>
    </>
  )
}

export default NewItemComments
