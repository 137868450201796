/* eslint-disable react-hooks/exhaustive-deps */
import { FC } from 'react'
import { Grid, Typography, Box, LabeledText } from 'src/components/UI'
import { getVendorPrimaryEmail } from 'src/ducks/selectors'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import { Divider, IconButton, Tooltip } from "@mui/material"
import { INSURANCE_TYPE } from 'src/helpers'
import moment from 'moment'
import { SelectedVendorProps } from './types'

const SelectedVendor: FC<SelectedVendorProps> = ({ selectedVendor, unselectVendor }) => {
  const VendorIcon = (
    { name, enable, tooltipTitle, validToDate }: {
      name: string,
      enable: boolean,
      tooltipTitle: string,
      validToDate?: number
    }) => {

    const expired = !validToDate || validToDate < moment().unix()
    let title = tooltipTitle

    if (!enable || expired) {
      title = 'No '.concat(tooltipTitle)
      if (validToDate) title = title.concat(`. Expired on ${moment.unix(validToDate).format('MM/DD/YYYY')}`)
    } else {
      title = tooltipTitle.concat(' on file')
      if (validToDate) title = title.concat(`. Expires on ${moment.unix(validToDate).format('MM/DD/YYYY')}`)
    }

    return (
      <Grid item>
        <Tooltip title={title}>
          <div>
            <Icon name={name} sx={{ paddingTop: '4px' }} />
            {(!enable || expired) && (
              <Icon name='Block' color='red' sx={{
                position: 'absolute',
                marginTop: '-16px',
              }} />
            )}
          </div>
        </Tooltip>
      </Grid>
    )
  }

  if (!selectedVendor) return null

  return (
    <Box sx={{
      backgroundColor: 'var(--gray100)',
      borderRadius: '8px'
    }}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Typography variant='h6' sx={{
            fontWeight: 'bold',
            padding: '0 8px 8px'
          }}>
            {`${selectedVendor.firstName} ${selectedVendor.lastName}`}
          </Typography>
        </Grid>
        <Grid container item xs={6} spacing={1} justifyContent='flex-end' paddingRight={1}>
          <VendorIcon
            name='ContactMail'
            enable={selectedVendor.hasTaxID}
            tooltipTitle='Tax ID'
          />
          <VendorIcon
            name='Description'
            enable={selectedVendor.hasW9}
            tooltipTitle='W9'
          />
          <VendorIcon
            name='AccountBalance'
            enable={selectedVendor.hasACH}
            tooltipTitle='ACH'
          />
          <VendorIcon
            name='Person'
            enable={selectedVendor.insuranceList?.some(ins => ins.insuranceType === INSURANCE_TYPE.WORKERS_COMPENSATION)}
            tooltipTitle="Worker's Compensation"
            validToDate={selectedVendor.insuranceList?.find(ins => ins.insuranceType === INSURANCE_TYPE.WORKERS_COMPENSATION)?.validToDate}
          />
          <VendorIcon
            name='Assignment'
            enable={selectedVendor.insuranceList?.some(ins => ins.insuranceType === INSURANCE_TYPE.CERTIFICATE_OF_INSURANCE)}
            tooltipTitle='General Liability'
            validToDate={selectedVendor.insuranceList?.find(ins => ins.insuranceType === INSURANCE_TYPE.CERTIFICATE_OF_INSURANCE)?.validToDate}
          />
          <Grid item sx={{ padding: '4px 4px 0 12px !important' }}>
            <IconButton onClick={unselectVendor}>
              <Icon name='Close' color='var(--gray100)' background='var(--red600)' sx={{ borderRadius: 25 }} />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={1} sx={{ padding: '8px' }}>
        <Grid item xs={7}>
          <LabeledText labelValue='Company:' textValue={selectedVendor?.companyName} />
        </Grid>
        <Grid item xs={5}>
          <LabeledText labelValue='Contact:' textValue={`${selectedVendor?.firstName} ${selectedVendor?.lastName}`} />
        </Grid>
        <Grid item xs={7}>
          <LabeledText labelValue='Email:' textValue={getVendorPrimaryEmail(selectedVendor)} />
        </Grid>
        <Grid item xs={5}>
          <LabeledText labelValue='Phone:' textValue={selectedVendor?.phone?.toString() ?? '-'} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default SelectedVendor