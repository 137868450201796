import { Theme } from '@mui/material'

export const table = {
  MuiTable: {
    styleOverrides: {
      root: {
        borderCollapse: 'unset',
        border: '2px solid var(--border-color)',
        borderBottomRightRadius: 14,
        borderBottomLeftRadius: 14,
        borderTop: 'none',
        borderSpacing: '0px',
        minHeight: '112px',
        position: 'relative'
      }
    }
  },
  MuiTableHead: {
    styleOverrides: {
      root: ({ theme }: { theme: Theme }) => ({
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.primary.dark
      }),
      body: {
      }
    }
  },
  MuiTableRow: {
    styleOverrides: {
      head: ({ theme }: { theme: Theme }) => ({
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.primary.dark
      })
    }
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        border: 'none',
        borderTop: '2px solid var(--border-color)',
        margin: 0,
        padding: '6px 10px;',
        fontSize: '16px',
        lineHeight: '0px'
      },
      head: {
        height: '38px',
        textAlign: 'left',
        fontFamily: 'NouvelleBold !important'
      },
      body: {
        fontSize: '12px',
        borderRight: '2px solid var(--border-color)',
        '&:last-child': {
          borderRight: 'none'
        }
      }
    }
  },
  MuiTablePagination: {
    styleOverrides: {
      displayedRows: {
      }
    }
  }
}
