import {
  repairlistTypes,
  RepairListActions,
  RepairListType,
} from './types'
import { SearchParams } from '../searches/types'
import { RepairListItem } from '../types'

const actions = {
  fetchRepairlist: (
    payload?: {
      page?: number
      size?: number
      searchParams?: Partial<SearchParams>
    },
    callback?: (succ: boolean) => void
  ): RepairListActions => ({
    type: repairlistTypes.FETCH_REPAIRLIST,
    payload,
    callback,
  }),

  fetchCsvRepairlist: (
    payload?: {
      searchParams?: Partial<SearchParams>
    },
    callback?: (succ: boolean) => void
  ): RepairListActions => ({
    type: repairlistTypes.FETCH_CSV_REPAIRLIST,
    payload,
    callback,
  }),

  setRepairlist: (payload: RepairListType): RepairListActions => ({
    type: repairlistTypes.SET_REPAIRLIST,
    payload,
  }),

  setCsvRepairlist: (payload: RepairListItem[]): RepairListActions => ({
    type: repairlistTypes.SET_CSV_REPAIRLIST,
    payload,
  }),
}

export default actions
