import { Client } from '../types'
import { ClientActions, clientTypes, ClientValue } from './types'

const actions = {
  fetchClient: (
    payload: string,
    callback?: (succ: boolean) => void
  ): ClientActions => ({
    type: clientTypes.FETCH_CLIENT,
    payload,
    callback
  }),

  createClient: (
    payload: Partial<Client>,
    callback?: (succ: boolean) => void
  ): ClientActions => ({
    type: clientTypes.CREATE_CLIENT,
    payload,
    callback
  }),
  
  setClient: (payload: Client): ClientActions => ({
    type: clientTypes.SET_CLIENT,
    payload
  }),

  setClientValue: (payload: ClientValue): ClientActions => ({
    type: clientTypes.SET_CLIENT_VALUE,
    payload
  }),

  updateClientId: (
    clientId: string,
    payload: Partial<Client>,
    callback: (succ: boolean) => void
  ): ClientActions => ({
    type: clientTypes.UPDATE_CLIENT_ID,
    clientId,
    payload,
    callback
  }),
}

export default actions
