import { SelectInputOption } from 'src/components/UI/CustomUI/atoms/SelectInput/types'
import storeInfo from '../store'
import { InspectionCompany } from '../types'
import { InspectionCompaniesType } from './types'

export const getInspectionCompanies = (): InspectionCompaniesType => {
  const { inspectionCompanies } = storeInfo.store.getState()
  return inspectionCompanies
}

export const getInspectionCompaniesForSelect = (): SelectInputOption[] => {
  const { inspectionCompanies } = storeInfo.store.getState()
  const flattenedData = inspectionCompanies?.items?.reduce((acc: any, curr: InspectionCompany) => {
    return [...acc, (acc = {
      key: curr,
      label: curr.name
    })]
  }, [])
  return flattenedData
}