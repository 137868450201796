import { Divider as MaterialDivider } from 'src/components/UI'

const Divider = () => {
  return (
    <MaterialDivider
      sx={{
        border: '1px solid #EBECEF',
        borderRadius: '8px',
        background: '#EBECEF'
      }}
    />
  )
}

export default Divider
