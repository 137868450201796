import { SearchParams } from '../searches/types'
import { Vendor, VendorCount } from '../types'

/** TYPES **/
export const vendorsTypes = {
  FETCH_VENDORS: 'FETCH_VENDORS',
  REQUEST_CSV_VENDORS: 'REQUEST_CSV_VENDORS',
  GET_VENDORS_CSV: 'GET_VENDORS_CSV',
  FETCH_PUNCHLIST_VENDORS: 'FETCH_PUNCHLIST_VENDORS',
  SET_VENDORS: 'SET_VENDORS',
  SET_PUNCHLIST_VENDORS: 'SET_PUNCHLIST_VENDORS',
  FETCH_RECOMMENDED_VENDORS: 'FETCH_RECOMMENDED_VENDORS',
  SET_VENDORS_LIST: 'SET_VENDORS_LIST',
  FETCH_COUNTS: 'FETCH_COUNTS',
  SET_VENDORS_COUNTS: 'SET_VENDORS_COUNTS',
  SET_VENDORS_CSV: 'SET_VENDORS_CSV',
  SET_VENDOR_BY_ID: 'SET_VENDOR_BY_ID',
}

/** ACTIONS **/
export interface FetchVendorsAction {
  type: typeof vendorsTypes.FETCH_VENDORS
  payload: {
    page?: number
    size?: number
    searchParams?: Partial<SearchParams>
  }
  callback?: (succ: boolean) => void
}

export interface RequestCSVVendorsAction {
  type: typeof vendorsTypes.REQUEST_CSV_VENDORS
  payload: {
    count?: number
    searchParams?: Partial<SearchParams>
  }
  callback: (succ: boolean, vendors: string) => void
}

export interface FetchRecommendedVendorsAction {
  type: typeof vendorsTypes.FETCH_RECOMMENDED_VENDORS
  payload: {
    territory: string
    trades: string
    workStartDate: string
    workEndDate: string
  }
  callback?: (succ: boolean, total: number) => void
}

export interface SetVendorsAction {
  type: typeof vendorsTypes.SET_VENDORS
  payload: Partial<VendorsType>
}

export interface SetVendorByIdAction {
  type: typeof vendorsTypes.SET_VENDOR_BY_ID
  payload: Vendor
}
export interface SetVendorsListAction {
  type: typeof vendorsTypes.SET_VENDORS_LIST
  payload: Vendor[]
}

export interface SetCountsAction {
  type: typeof vendorsTypes.SET_VENDORS_COUNTS
  payload: VendorCount[]
}

export interface FetchCountsAction {
  type: typeof vendorsTypes.FETCH_COUNTS
  payload: {
    searchParams?: Partial<SearchParams>
  }
  callback?: (succ: boolean) => void
}

export interface GetCSVVendorAction {
  type: typeof vendorsTypes.GET_VENDORS_CSV,
  payload: string,
  callback: (succ: boolean, vendors: string) => void
}


export type VendorsActions =
  | FetchVendorsAction
  | FetchRecommendedVendorsAction
  | SetVendorsAction
  | SetVendorByIdAction
  | SetVendorsListAction
  | SetCountsAction
  | FetchCountsAction
  | RequestCSVVendorsAction
  | GetCSVVendorAction

/** REDUCER **/
export interface VendorsType {
  items: Vendor[]
  total: number
  start: number
  limit: number
  counts: VendorCount[]
  punchlistVendors: Vendor[]
  vendorsCSV: string
}
