/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Typography } from '@mui/material'
import { FC, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import Modal from 'src/components/UI/CustomUI/molecules/Modal'
import Table from 'src/components/UI/CustomUI/organisms/Table'
import { getVendor } from 'src/ducks/selectors'
import { DispatchContext } from '../context'
import { dispatchTypes } from '../context/types'
import { headersLicenses } from './headers'
import styles from './styles.module.scss'
import { License, Vendor } from 'src/ducks/types'

// export const LicensesView: FC<{ licenses: License[] |  undefined }> = (licenses: License[] | undefined) => {
//   const vendor = useSelector(getVendor())
//   return (
//     <>
//       <Table headers={headersLicenses}>
//         {licenses}
//       </Table>
//     </>
//   )
// }

const LicenseExpireModal: FC<{ onClick: React.Dispatch<React.SetStateAction<Vendor | null>>, licenses: License[] |  undefined, vendor: Vendor | null }> = ({ onClick, licenses, vendor }) => {
  const dispatch = useDispatch()
  const { state: { openModal }, dispatch: dispatchContext } = useContext(DispatchContext)

  const handleClose = () => {
    dispatchContext({
      type: dispatchTypes.SET_VALUE,
      payload: { attr: 'openModal', value: false }
    })
  }

  const handleClick = () => {
    onClick(vendor)
    handleClose()
  }

  

  return (
    <Modal
      setOpen={() => { }}
      open={openModal}
      size='xl'
      className={styles.Dispatch__container}
    >
      <>
        <Typography variant='h5' className={styles.Title}>Warning</Typography>
        <Typography variant='h6' className={styles.SubTitle}>Some insurances of this Pro are expired or near to expiration</Typography>

        <Table headers={headersLicenses}>
          {licenses}
        </Table>
        
        <Box className={styles.Actions}>
          <Button variant='outlined' onClick={handleClose} className={styles.Action}>
            Cancel
          </Button>
          <Button
            variant='contained'
            onClick={handleClick}
            className={styles.Action}
          >
            Accept & Assign
          </Button>
        </Box>
      </>
    </Modal>
  )
}

export default LicenseExpireModal