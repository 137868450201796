import storeInfo from '../store'
import { Comment } from './types'

export const getComments = () => (): Comment[] => {
  const { comments } = storeInfo.store.getState()
  return [...comments?.items] ?? []
}

export const getTotalComments = () => {
  const { comments } = storeInfo.store.getState()
  return comments ? Number(comments.total) : 0
}