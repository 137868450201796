
export interface invoiceMaterialModalContextType {
  invoiceNum: string,
  amount: number,
  orderName: string,
  insertedOn: number
}

export const invoiceMaterialModalTypes = {
  MATERIAL_INVOICE_DELETE: 'MATERIAL_INVOICE_DELETE',
  RESET: 'RESET'
}

