/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import Table from 'src/components/UI/CustomUI/organisms/Table'
import DatePicker from 'src/components/UI/CustomUI/atoms/DatePicker'
import { Range } from 'src/components/UI/CustomUI/atoms/DatePicker/types'
import { Box } from 'src/components/UI'
import headers from '../headers'
import { paymentsActions } from 'src/ducks/actions'
import { useDispatch, useSelector } from 'react-redux'
import { getPayments, getTotalPayments } from 'src/ducks/selectors'
import moment from 'moment'
import { GetPaymentsParams } from '~api/punchlist/payments/types'
import { PAYMENT_STATUS } from 'src/helpers'
import NoResultsView from 'src/components/UI/CustomUI/organisms/Table/NoResultsView'
import {
  empty
} from '../../../../../../assets'

const Failed = () => {
  const dispatch = useDispatch()
  const payments = useSelector(getPayments())
  const [tableLoading, setTableLoading] = useState(false)
  const totalPayments = useSelector(getTotalPayments)

  interface SearchInputs {
    range: Range
  }

  const [search, setSearch] = useState<SearchInputs>({
    range: {}
  })

  const fetchPayments = (start: number = 0, limit: number = 50) => {
    setTableLoading(true)
    const parameters: Partial<GetPaymentsParams> = {
      start,
      limit,
      status: PAYMENT_STATUS.FAILED
    }
    if (search.range.startDate && search.range.endDate) {
      parameters.startDate = moment(search.range.startDate).format('MM/DD/YYYY')
      parameters.endDate = moment(search.range.endDate).format('MM/DD/YYYY')
    }
    dispatch(
      paymentsActions.fetchPayments({ params: parameters }, (_succ: boolean) => {
        setTableLoading(false)
      })
    )
  }

  useEffect(() => {
    fetchPayments()
  }, [search])

  return (
    <Box display='flex' flexDirection='column'>
      <Box display='flex' alignSelf='flex-end' justifyContent='flex-end' >
        <DatePicker
          onChange={(rangeSelected: Range) => {
            setSearch({ ...search, range: rangeSelected })
          }}
          value={search.range}
          allowRange
          style={{ height: '38px', padding: '0px', width: '280px', margin: '-52px 0 20px 0' }}
          styleAlert={{ right: 0 }}
          size="small"
        />
      </Box>
      <Table
        headers={headers}
        loading={tableLoading}
        hasPagination={true}
        totalItems={totalPayments}
        emptyState={<NoResultsView title='No payments for this range of dates'
          subtitle='Try selecting another range of dates of selecting All History'
          icon={

            <img src={empty} alt='empty icon' style={{ padding: 0, width: '24px', height: '24px' }} />

          } />}
      >
        {payments ?? []}
      </Table>
    </Box>
  )
}

export default Failed
