import { InspectionCompanyActions, inspectionCompanyTypes } from './types'
import { InspectionCompany } from '../types'

const actions = {
  fetchInspectionCompany: (
    payload: string,
    callback?: (succ: boolean) => void
  ): InspectionCompanyActions => ({
    type: inspectionCompanyTypes.FETCH_INSPECTION_COMPANY,
    payload,
    callback,
  }),
  setInspectionCompany: (
    payload: InspectionCompany | null
  ): InspectionCompanyActions => ({
    type: inspectionCompanyTypes.SET_INSPECTION_COMPANY,
    payload,
  }),
}

export default actions
