import { FC, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, CircularProgress, Grid, TextFieldLabel } from 'src/components/UI';
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import { ordersActions,jobActions } from 'src/ducks/actions';
import { ORDER_STATUS } from 'src/helpers';
import { WorkOrdersContext } from '../../../context';
import { useParams } from "react-router-dom"

const OnHoldModal: FC = () => {
    const dispatch = useDispatch()
    const { dispatch: dispatchContext, state: { selectedOrderId } } = useContext(WorkOrdersContext)
    const [notes, setNotes] = useState('')
    const [buttonLoading, setButtonLoading] = useState(false)
    const { id } = useParams()

    const handleCancel = () => {
        dispatchContext({ type: 'SET_MODAL_OPEN', payload: false })
    }

    const handleSubmit = () => {
        setButtonLoading(true)
        if (selectedOrderId)
            {dispatch(
                ordersActions.updateOrder({
                    orderId: selectedOrderId,
                    statusType: ORDER_STATUS.ON_HOLD,
                    holdNotes: notes
                },
                    (success) => {
                        if (!success)
                            setButtonLoading(false)
                        if (success) {
                            if (id) {
                                dispatch(
                                  jobActions.fetchJob(id, (_succ: boolean) => {
                                    handleCancel()
                                  })
                                )
                              }
                        }
                    }
                )
            )}
    }

    return (
      <Grid container spacing={2}>
          <Grid item xs={12}>
              <TextFieldLabel
                  label='Note:'
                  value={notes}
                  multiline
                  rows={4}
                  labelVariant='h6'
                  placeholder='Write some notes here...'
                  size='small'
                  onChange={(e) => setNotes(e.target.value)}
                />
            </Grid>
          <Grid container item lg={12} spacing={1} marginTop={1}>
              <Grid item xs={6}>
                  <Button variant='outlinedBig' fullWidth onClick={handleCancel}>Cancel</Button>
                </Grid>
              <Grid item xs={6}>
                  <Button variant='containedBig' fullWidth onClick={handleSubmit} disabled={buttonLoading}>
                      Pause{buttonLoading ? <CircularProgress sx={{ marginLeft: '5px' }} color='info' size="1.4rem" /> : <Icon sx={{ margin: '3px', marginBottom: '4px' }} name='Pause' color='white' />}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default OnHoldModal
