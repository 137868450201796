import { FC, useEffect, useState } from 'react'
import { Button, Grid, Typography } from '@mui/material'
import styles from '../../styles.module.scss'
import { SelectInput, TextFieldLabel } from 'src/components/UI'
import { PartnerModalProps } from './types'
import Modal from 'src/components/UI/CustomUI/molecules/Modal'
import UploadDocuments from 'src/components/UI/CustomUI/molecules/UploadDocument'
import { Partner } from 'src/ducks/types'
import { useDispatch, useSelector } from 'react-redux'
import { filesActions, homemartActions } from 'src/ducks/actions'
import {
  FILE_TYPE,
  // PARTNER_CATEGORY_OPTIONS,
  PARTNER_ROLE_OPTIONS,
  ParseFilesUrl,
} from 'src/helpers'
import moment from 'moment-timezone'
import env from '@beam-australia/react-env'
import {
  getHomemart,
  getPartnerCategories,
  getPartnerTags,
} from 'src/ducks/selectors'

const FILES_URL = env('FILES_URL') ?? ''

const PartnerModal: FC<PartnerModalProps> = ({
  open,
  setOpen,
  addModal = true,
  selectedPartner,
}) => {
  const dispatch = useDispatch()

  const [imageFile, setImageFile] = useState<any>(null)
  const [logoFile, setLogoFile] = useState<any>(null)
  const [partner, setPartner] = useState<any>(
    addModal ? { active: true } : selectedPartner
  )
  const [tags, setTags] = useState<any>([])
  const [customerTypes, setCustomerTypes] = useState<any>([])
  const [imageUploading, setImageUploading] = useState<boolean>(false)

  const categoryOptions = useSelector(getPartnerCategories())
  const tagOptions = useSelector(getPartnerTags())
  const homemarts = useSelector(getHomemart())

  const tagsArray =
    (tags && typeof tags === 'string'
      ? tags
          .split('.')
          .filter((element: any) => element)
          .map((item: any) => item.trim())
      : tags) ?? []

  const customerTypesArray =
    (customerTypes && typeof customerTypes === 'string'
      ? customerTypes
          .split('.')
          .filter((element: any) => element)
          .map((item: any) => item.trim())
      : customerTypes) ?? []

  const tagsPayload = tags.map((tag: string) =>
    tagOptions.find((option) => option.name === tag)
  )
  const customerTypesPayload = customerTypes.map((type: string) =>
    categoryOptions.find((option) => option.name === type)
  )

  useEffect(() => {
    setPartner(addModal ? { active: true } : selectedPartner)

    setTags(addModal ? [] : selectedPartner?.tags.map((tag: any) => tag.name))
    setCustomerTypes(
      addModal ? [] : selectedPartner?.customerTypes.map((tag: any) => tag.name)
    )

    if (!addModal && selectedPartner?.titleImage) {
      const path = selectedPartner?.titleImage.split('/')
      setImageFile({
        name: path[path.length - 1],
        fileUrl: selectedPartner?.titleImage,
      })
    } else {
      setImageFile(null)
    }

    if (!addModal && selectedPartner?.logo) {
      const path = selectedPartner?.logo.split('/')
      setLogoFile({
        name: path[path.length - 1],
        fileUrl: selectedPartner?.logo,
      })
    } else {
      setLogoFile(null)
    }
  }, [addModal, selectedPartner])

  const handleImageFileChange = (file: null | File, callback: () => void) => {
    if (file) {
      setImageUploading(true)
      dispatch(
        filesActions.uploadFile(file, (uploadSucc, fileName) => {
          if (uploadSucc && fileName) {
            const fileToSave = {
              name: file.name,
              fileUrl: ParseFilesUrl({
                baseUrl: FILES_URL,
                avatar: fileName ?? '',
              }),
              description: file.name ?? '',
              fileType: FILE_TYPE.MATERIAL_INVOICE,
              uploadedOn: moment().unix(),
            }
            callback()
            setImageFile(fileToSave)
            partner.titleImage = ParseFilesUrl({
              baseUrl: FILES_URL,
              avatar: fileName ?? '',
            })
          }
          setImageUploading(false)
        })
      )
    } else {
      setImageFile(null)
    }
  }

  const handleLogoFileChange = (file: null | File, callback: () => void) => {
    if (file) {
      setImageUploading(true)
      dispatch(
        filesActions.uploadFile(file, (uploadSucc, fileName) => {
          if (uploadSucc && fileName) {
            const fileToSave = {
              name: file.name,
              fileUrl: ParseFilesUrl({
                baseUrl: FILES_URL,
                avatar: fileName ?? '',
              }),
              description: file.name ?? '',
              fileType: FILE_TYPE.MATERIAL_INVOICE,
              uploadedOn: moment().unix(),
            }
            callback()
            setLogoFile(fileToSave)
            partner.logo = ParseFilesUrl({
              baseUrl: FILES_URL,
              avatar: fileName ?? '',
            })
          }
          setImageUploading(false)
        })
      )
    } else {
      setLogoFile(null)
    }
  }

  const handlePartnerChange = (changedValue: Partial<Partner>) => {
    setPartner({
      ...partner,
      ...changedValue,
    })
  }

  const existingPartner = !!homemarts.partners
        .filter((pt) => pt.id !== partner.id || addModal)
        .find(
          (pt) =>
            pt.readableCompanyName === partner.readableCompanyName &&
            partner.readableCompanyName
        )

  const isInvalid =
    !partner.readableCompanyName ||
    !partner.actionVerbiage ||
    !customerTypes.length ||
    !tags.length ||
    !partner.description ||
    !partner.actionUrl ||
    !partner.titleImage ||
    !partner.logo
    // existingPartner

  const handleAddPartner = () => {
    if (addModal) {
      dispatch(
        homemartActions.createPartner(
          {
            ...partner,
            tags: tagsPayload,
            active: true,
            customerTypes: customerTypesPayload,
          },
          (succ) => {
            dispatch(
              homemartActions.fetchPartners({ active: true }, (succ) => {})
            )
            if (succ) handleClose()
          }
        )
      )
    } else {
      dispatch(
        homemartActions.updatePartner(
          {
            ...partner,
            tags: tagsPayload,
            active: true,
            customerTypes: customerTypesPayload,
          },
          (succ) => {
            dispatch(
              homemartActions.fetchPartners({ active: true }, (succ) => {})
            )
            if (succ) handleClose()
          }
        )
      )
    }
  }

  const handleClose = () => {
    setPartner({})
    setImageFile(null)
    setLogoFile(null)
    setTags([])
    setCustomerTypes([])
    setOpen(false)
  }

  return (
    <Modal open={open} setOpen={setOpen} onClose={handleClose} size="lg">
      <Grid
        container
        spacing={1}
        flexDirection="column"
        style={{ marginTop: '4px' }}
      >
        <Grid item>
          <Typography
            className={styles.Homemart__mFont}
            style={{ color: 'var(--navy-700)' }}
          >
            {addModal
              ? 'Add Partner'
              : `Edit Mode: ${partner.readableCompanyName}`}
          </Typography>
        </Grid>
        <Grid item container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={6}>
            <TextFieldLabel
              label="Offered by *"
              value={partner.readableCompanyName || ''}
              // helperText={existingPartner ? 'Already existing partner' : ''}
              // error={existingPartner}
              onChange={(e) =>
                handlePartnerChange({ readableCompanyName: e.target.value })
              }
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldLabel
              label="CTA *"
              value={partner.actionVerbiage || ''}
              onChange={(e) =>
                handlePartnerChange({ actionVerbiage: e.target.value })
              }
              required
            />
          </Grid>
          <Grid item xs={6}>
            <SelectInput
              label="Category *"
              options={tagOptions.map((option) => option.name)}
              labelVariant="subtitle1"
              value={tagsArray}
              onChange={(e) => {
                setTags(e)
              }}
              multiple
              onBlur={(event) => {
                const tag = event?.target?.value
                if (tag?.length > 0) {
                  const newValue: any = [...(tagsArray as string[]), tag]
                  setTags(newValue)
                }
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldLabel
              label="Promo"
              value={partner.discountVerbiage || ''}
              onChange={(e) => {
                handlePartnerChange({ discountVerbiage: e.target.value })
              }}
              required
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2} style={{ height: 'max-content' }}>
          <Grid item container flexDirection="column" spacing={1} xs={6}>
            <Grid item>
              <SelectInput
                label="For roles *"
                options={categoryOptions.map((option) => option.name)}
                labelVariant="subtitle1"
                value={customerTypesArray}
                onChange={(e) => {
                  setCustomerTypes(e)
                }}
                multiple
                onBlur={(event) => {
                  const customerType = event?.target?.value
                  if (customerType?.length > 0) {
                    const newValue: any = [
                      ...(customerTypesArray as string[]),
                      customerType,
                    ]
                    setCustomerTypes(newValue)
                  }
                }}
              />
            </Grid>
            <Grid item>
              <TextFieldLabel
                label="Link *"
                value={partner.actionUrl || ''}
                onChange={(e) =>
                  handlePartnerChange({ actionUrl: e.target.value })
                }
                required
              />
            </Grid>
            <Grid item>
              <TextFieldLabel
                label="Description *"
                multiline
                rows={2}
                style={{ height: '30px' }}
                value={partner.description || ''}
                onChange={(e) =>
                  handlePartnerChange({
                    description: e.target.value.slice(0, 255),
                  })
                }
                required
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            spacing={1}
            flexDirection="column"
            xs={6}
            justifyContent="space-between"
          >
            <Grid item>
              <Typography>Image *</Typography>
              <UploadDocuments
                dropzoneText="Click here to upload your file"
                file={imageFile}
                onChange={handleImageFileChange}
                onRemoveFile={() => handlePartnerChange({ titleImage: '' })}
              />
            </Grid>
            <Grid item style={{ marginTop: '4px' }}>
              <Typography>Logo *</Typography>
              <UploadDocuments
                dropzoneText="Click here to upload your file"
                file={logoFile}
                onChange={handleLogoFileChange}
                onRemoveFile={() => handlePartnerChange({ logo: '' })}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end" item spacing={2}>
          <Grid item>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={handleAddPartner}
              disabled={isInvalid}
            >
              {addModal ? 'Add Partner' : 'Save Changes'}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  )
}

export default PartnerModal
