import { GetSiteVisitsParams, SiteVisitsActions, SiteVisitsType, siteVisitsTypes } from "./types"

const actions = {
  getSiteVisits: (
    payload: Partial<GetSiteVisitsParams>,
    start?: number,
    limit?: number,
    callback?: (succ: boolean) => void
  ): SiteVisitsActions => ({
    type: siteVisitsTypes.GET_SITE_VISITS,
    payload,
    start,
    limit,
    callback
  }),
  setSiteVisits: (payload: SiteVisitsType): SiteVisitsActions => ({
    type: siteVisitsTypes.SET_SITE_VISITS,
    payload
  }),
}

export default actions
