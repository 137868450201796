import { authTypes } from '../auth/types'
import { ordersTypes, OrdersType, OrdersActions, OrdersConflict } from './types'

const initialState: OrdersType = {
  start: 0,
  limit: 0,
  total: 0,
  items: [],
  ordersConflict: null
}

const reducer = (state = initialState, action: OrdersActions): OrdersType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case ordersTypes.SET_ORDERS: {
      return payload as OrdersType
    }

    case ordersTypes.SET_ORDERS_CONFLICT: {
      return {
        ...state,
        ordersConflict: payload as OrdersConflict
      }
    }

    default:
      return state
  }
}

export default reducer
