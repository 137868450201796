/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { Punchlist } from '../../api'
import { errorTextTryingTo } from '../../helpers'
import { siteVisitsActions } from '../actions'
import { GetSiteVisitsAction, siteVisitsTypes } from './types'


export function* getSiteVisits({ start, limit, payload, callback }: GetSiteVisitsAction): SagaIterator {
  let success = false
  try {
    /*   const page = page ?? 0
     const size = sizes ?? 50
  
      const start = page * size
      const limit = start + size */

    const siteVisits = yield call(Punchlist.siteVisits.getSiteVisits, { start, limit, params: payload })
    if (siteVisits) {
      yield put(siteVisitsActions.setSiteVisits(siteVisits))
      success = true
    }
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the site visits'))
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(siteVisitsTypes.GET_SITE_VISITS, getSiteVisits)
}
