import React, { useContext, FC, useState, useEffect } from 'react'
import { Box, Checkbox, FormControlLabel, Grid } from 'src/components/UI'
import VendorColorPicker from 'src/components/UI/CustomUI/molecules/VendorColorPicker'
import { CalendarContext } from '../../context.provider'
import { VendorFilterType } from './types'

const VendorsFilter: FC<VendorFilterType> = ({
  vendorsParam
}) => {

  const vendors = vendorsParam
  const context = useContext(CalendarContext)
  const { vendorOptions, setVendorOptions } = context
  const [allChecked, setAllchecked] = useState(true)

  useEffect(() => {
    setAllchecked(vendorOptions.find(vendor => !vendor.checked) ? false : true)
  }, [vendorOptions])

  const handleSelection = () => {
    setVendorOptions(
      vendorOptions.map((option) =>
        !allChecked
          ? { ...option, checked: true }
          : { ...option, checked: false }
      )
    )
  }

  const isCheked = () => {
    if (allChecked)
      return true

    return false
  }

  return (
    <Box
      sx={{
        overflow: 'scroll',
        minHeight: '500px',
        maxHeight: 'calc(100vh - 250px)',
        height: '100%',
      }}
    >
      <Grid container direction="column" marginTop=".5rem">
        {vendors.length > 0 ? (
          <FormControlLabel
            control={
              <Checkbox
                checked={isCheked()}
                color="infoText"
                onChange={handleSelection}
                sx={{
                  padding: '0px !important',
                }}
              />
            }
            label={`All`}
            style={{ gap: '8px' }}
          />
        ) :
          <></>
        }
        {vendors.map((vendor, index) => (
          <VendorColorPicker
            key={`${vendor.id} ${index}`}
            vendor={vendor}
            index={index}
            vendorOptions={vendorOptions}
            setVendorOptions={setVendorOptions}
          />
        ))}
      </Grid>
    </Box>
  )
}

export default VendorsFilter
