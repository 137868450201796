import React, { FC } from 'react'
import { RelatedEstimatesProps } from './types'
import styles from './styles.module.scss'
import { Grid, Box } from '@mui/material'
import LinkEstimate from 'src/components/UI/CustomUI/atoms/LinkEstimate'
import { EstimateItem } from "src/ducks/types";

const RelatedEstimates: FC<RelatedEstimatesProps> = ({ estimates }) => {
  const relatedEstimates = typeof estimates === "string" ? [] : estimates
  return (
    <Box overflow='overlay' height='175px'>
      <Grid
        container
        spacing={1}
        flexDirection="column"
        className={styles.RelatedEstimates}
      >
        {relatedEstimates?.map((estimate, index) => (
          <Grid key={index} item>
            <LinkEstimate estimate={estimate} />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default RelatedEstimates
