import { Box, Checkbox, Typography } from '@mui/material'
import {
  HeaderType,
  AlignType,
} from 'src/components/UI/CustomUI/organisms/Table/types'

import styles from './styles.module.scss'
import { round } from 'src/helpers'

const headers: HeaderType[] = [
  {
    id: 'check',
    label: <Checkbox />,
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Box padding="2px 10px">
          <Checkbox checked={!!row.checked} onClick={() => row.onClick(row.index)} />
        </Box>
      )
    },
  },
  {
    id: 'territory',
    label: 'Territory',
    custom: true,
    align: 'left' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Box padding="2px 10px">
          <Typography className={styles.Companies__companyName}>
            {row?.territory?.title || 'Default'}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'laborRate',
    label: 'Labor Rate',
    custom: true,
    align: 'left' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Box padding="2px 10px">
          <Typography className={styles.Companies__companyName}>
            {`${row?.laborRate}`}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'cost',
    label: 'Cost',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Box padding="2px 10px">
          <Typography className={styles.Companies__companyName}>
            {`$${row?.unitCost}`}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'price',
    label: 'Price',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Box padding="2px 10px">
          <Typography className={styles.Companies__companyName}>
            {`$${row?.unitPrice}`}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'profit',
    label: 'Profit',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Box padding="2px 10px">
          <Typography className={styles.Companies__companyName}>
            {round(row?.profit, 2)}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'margin',
    label: 'Margin',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Box padding="2px 10px">
          <Typography className={styles.Companies__companyName} style={{ color: 'var(--blue700)' }}>
            {`${row?.margin}%`}
          </Typography>
        </Box>
      )
    },
  },
]

export default headers
