import OfflineBoltIcon from '@mui/icons-material/OfflineBolt'
import { Box, Typography } from '@mui/material'
import { icons } from 'src/assets'
import {
  AlignType,
  HeaderType,
} from 'src/components/UI/CustomUI/organisms/Table/types'

const headers: HeaderType[] = [
  {
    id: 'inspectionReportReference',
    label: 'Ref.',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Typography fontSize="14px">
          {row.inspectionReportReference || '-'}
        </Typography>
      )
    },
  },
  {
    id: 'title',
    label: 'Item',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return <Box display="flex" alignItems="center">
        <Typography fontSize="14px">{row.title || '-'}</Typography>
        {row?.priceMatch?.id &&
          <Box marginLeft="4px" display="flex" alignItems="center" padding="3px" borderRadius="16px" bgcolor="var(--green900)">
            <icons.MonetizationOn htmlColor='#fff' />
          </Box>}
      </Box>
    },
  },
  {
    id: 'ratings',
    label: 'Match Rating',
    custom: true,
    align: 'right' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Box
          width={Number(row.automatchConfidenceScore) >= 90 ? '100%' : 'auto'}
          display='flex'
          justifyContent={
            Number(row.automatchConfidenceScore) >= 90
              ? 'space-between'
              : 'flex-end'
          }
        >
          {Number(row.automatchConfidenceScore) >= 90 && (
            <OfflineBoltIcon style={{ width: '16px', height: '16px' }} />
          )}
          <Typography fontSize="14px">
            {`${row.automatchConfidenceScore || '0'}%`}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'category',
    label: 'Category',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return <Typography fontSize="14px">{row.category || '-'}</Typography>
    },
  },
]

export default headers
