import { useContext, useCallback } from 'react'
import { Grid, Typography } from '@mui/material'
import { WorkOrdersContext } from '../../../../../context'
import Images from '../../../Images'
import ImagesSelector from 'src/components/UI/CustomUI/molecules/ImagesSelector'
import { ImageFile } from 'src/ducks/types'
import { useSelector } from 'react-redux'
import { getItemsImages } from 'src/ducks/selectors'
import { isEmpty } from 'src/helpers'

const ImageGallery = () => {
  const {
    state: { item, editItem },
    dispatch: dispatchContext,
  } = useContext(WorkOrdersContext)
  const isEditModal = !isEmpty(item.itemId)
  const imageFiles = editItem.imageFiles || item.imageFiles

  const submitAction = useCallback(
    (e: any) => {
      if (isEditModal) {
        dispatchContext({
          type: 'SET_EDIT_ITEM_VALUE',
          payload: { attr: 'imageFiles', value: e },
        })
      } else {
        dispatchContext({
          type: 'SET_ITEM_VALUE',
          payload: { attr: 'imageFiles', value: e },
        })
      }
    },
    [isEditModal]
  )

  return (
    <Grid
      container
      sx={{
        flex: '1 1 auto',
        overflowY: 'auto',
        display: 'grid',
        padding: '0 1rem',
      }}
    >
      <Images
        downloadable
        initialTab={1}
        size="large"
        height="14rem"
        maximizeImage={false}
        deletable
        submitAction={submitAction}
        imageFiles={imageFiles}
        showCopyToItem
      />
    </Grid>
  )
}

export default ImageGallery
