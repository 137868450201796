import { makeStyles } from '@mui/styles'

export default makeStyles(() => ({
  root: {
    display: 'flex',
    position: 'absolute',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 5,
    background: 'rgba(0,0,0,0.2)'
  }
}))
