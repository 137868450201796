import { authTypes } from '../auth/types'
import {
  employeeTypes,
  EmployeeType,
  EmployeeActions,
  EmployeeValue,
  EmployeeValues
} from './types'

const initialState: EmployeeType = {
  id: '',
  email: [],

  firstName: '',
  middleName: '',
  lastName: '',
  phone: null,

  address: {
    city: '',
    state: '',
    line_1: '',
    line_2: '',
    zipCode: '',
    latitude: 0,
    longitude: 0,
    fullAddress: ''
  },
  picture: '',

  registrationComplete: false,
  type: '',
  roles: [],
  approved: false,
  admin: false
}

const reducer = (state = initialState, action: EmployeeActions): EmployeeType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case employeeTypes.SET_VALUE: {
      const { attr, value } = payload as EmployeeValue
      return {
        ...state,
        [attr]: value
      }
    }
    case employeeTypes.SET_VALUES: {
      const { attrs } = payload as EmployeeValues
      return {
        ...state,
        ...attrs as EmployeeType
      }
    }
    default:
      return state
  }
}

export default reducer
