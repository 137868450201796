import { Box, Button, Typography } from "@mui/material"
import { useSelector } from "react-redux"
import { icons, vipMedal } from 'src/assets'
import { getDiscountsValue, getEstimate } from "src/ducks/selectors"
import useStyles from './styles'

const DiscountBanner = () => {
  const classes = useStyles()
  const discountBanner = useSelector(getDiscountsValue('discountBanner'))
  const estimate = useSelector(getEstimate())

  const discountVal = discountBanner?.discount * 100
  if (!discountVal) return null

  const promoApplied = discountBanner?.discountCode === estimate?.promotionId


  return (
    <Box className={classes.root}>
      <img src={vipMedal} alt="Vip Medal" className={classes.vipMedal} />
      {discountBanner?.description && <Typography className={classes.title}>{discountBanner?.description}</Typography>}
      {<Button
        onClick={() => { }} style={{
          color: promoApplied ? 'var(--green-700)' : '#FFC531'
        }} className={classes.link}
        endIcon={
          promoApplied ?
            <icons.CheckCircle className={classes.linkIcon} />
            :
            <icons.ShoppingCart className={classes.linkIcon} />
        }>
        {promoApplied ? 'Code Applied' : 'Apply Code'}
      </Button>}
    </Box>
  )
}

export default DiscountBanner