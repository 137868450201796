import { DispatchPriceItem, DispatchPricingModel, OrderType } from '../orders/types'
import { PunchlistFile, OrderBid } from '../types'

/** TYPES **/
export const orderTypes = {
  GET_ORDER: 'FETCH_ORDER',
  SET_ORDER: 'SET_ORDER',
  UPDATE_ORDER: 'UPDATE_ORDER',
  DELETE_ORDER: 'DELETE_ORDER',
  DISPATCH_ORDER: 'DISPATCH_ORDER',
  GET_ORDER_BIDS: 'GET_ORDER_BIDS',
  ADD_DRAW_FOR_PAYNOW: 'ADD_DRAW_FOR_PAYNOW',
  REVOKE_DISPATCH_ORDER: 'REVOKE_DISPATCH_ORDER',
  GET_PRICING: 'GET_PRICING',
  FETCH_ORDER_HISTORY: 'FETCH_ORDER_HISTORY',
  SET_ORDER_VALUE: 'SET_ORDER_VALUE'
}

/** ACTIONS **/
export interface GetOrderAction {
  type: typeof orderTypes.GET_ORDER
  payload: string
  callback?: (succ: boolean) => void
}

export interface SetOrderAction {
  type: typeof orderTypes.SET_ORDER
  payload: Order
}

export interface UpdateOrderPayload {
  orderId: string
  order: Partial<Order>
  jobFiles: PunchlistFile[]
  sendNotification?: boolean
}

export interface UpdateOrderAction {
  type: typeof orderTypes.UPDATE_ORDER
  payload: UpdateOrderPayload
  callback: (succ: boolean) => void
}

export interface DispatchOrderPayload {
  orderId: string
  order?: Partial<Order>
  jobFiles?: PunchlistFile[]
  sendNotification?: boolean
}

export interface DispatchOrderAction {
  type: typeof orderTypes.DISPATCH_ORDER
  payload: UpdateOrderPayload
  callback?: (succ: boolean) => void
}

export interface RevokeDispatchOrderPayload {
  orderId: string
  sendNotification?: boolean
}

export interface DeleteOrderPayload {
  orderId: string
}

export interface RevokeDispatchOrderAction {
  type: typeof orderTypes.REVOKE_DISPATCH_ORDER
  payload: RevokeDispatchOrderPayload
  callback?: (succ: boolean) => void
}

export interface AddDrawForPayNowPayload {
  orderId: string
  item: Partial<DispatchPriceItem>
}

export interface AddDrawForPayNowAction {
  type: typeof orderTypes.ADD_DRAW_FOR_PAYNOW
  payload: AddDrawForPayNowPayload
  callback?: (item: DispatchPriceItem | null) => void
}

export interface GetOrderBidsAction {
  type: typeof orderTypes.GET_ORDER_BIDS
  payload: string
  callback?: (orderBids: OrderBid[] | null) => void
}

export interface GetPricingPayload {
  orderId: string
  pricingModel: DispatchPricingModel
}

export interface GetPricingAction {
  type: typeof orderTypes.GET_PRICING
  payload: GetPricingPayload
  callback?: (succ: boolean) => void
}
export interface FetchOrderHistoryAction {
  type: typeof orderTypes.FETCH_ORDER_HISTORY
  payload: string
  callback?: (succ: boolean) => void
}

export interface OrderValue {
  attr: keyof OrderType
  value: any
}

export interface SetOrderValueAction {
  type: typeof orderTypes.SET_ORDER_VALUE
  payload: OrderValue
}

export type OrderActions =
  | GetOrderAction
  | SetOrderAction
  | UpdateOrderAction
  | DispatchOrderAction
  | RevokeDispatchOrderAction
  | AddDrawForPayNowAction
  | GetOrderBidsAction
  | GetPricingAction
  | FetchOrderHistoryAction
  | SetOrderValueAction

/** REDUCER **/
export type Order = OrderType | null
