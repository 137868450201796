import Table from 'src/components/UI/CustomUI/organisms/Table'
import headers from './headers'
import { useSelector } from 'react-redux'
import { getRepairlistFilter } from 'src/ducks/filters/selector'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { materialsActions } from 'src/ducks/actions'
import { getPriceList } from 'src/ducks/selectors'
import { MaterialItem } from 'src/ducks/types'

const MaterialTable = ({ materials }: { materials: MaterialItem[] }) => {
  const dispatch = useDispatch()

  const repairListFilter = useSelector(getRepairlistFilter())
  const priceList = useSelector(getPriceList)

  const [loading, setLoading] = useState<boolean>(false)

  const selectedPriceItem =
    priceList[repairListFilter.selectedTerritoryIndexes?.at(0) || 0]

  useEffect(() => {
    setLoading(true)

    if (
      !repairListFilter.selectedTerritoryIndexes?.length ||
      !repairListFilter.selectedTerritoryIndexes?.at(0) ||
      !priceList[repairListFilter.selectedTerritoryIndexes?.at(0) || 0]
        .materials?.length
    ) {
      setLoading(false)
    } else {
      dispatch(
        materialsActions.fetchMaterials(
          priceList[
            repairListFilter.selectedTerritoryIndexes?.at(0) || 0
          ].materials?.map((mat) => mat.id) || [],
          () => {
            setLoading(false)
          }
        )
      )
    }
  }, [repairListFilter.selectedTerritoryIndexes, priceList])

  return (
    <Table headers={headers} hasPagination={false} loading={loading}>
      {!repairListFilter.selectedTerritoryIndexes?.length ||
      !repairListFilter.selectedTerritoryIndexes?.at(0)
        ? []
        : materials}
    </Table>
  )
}

export default MaterialTable
