import { Box, Typography } from "@mui/material"
import { useSelector } from "react-redux"
import { getEstimate } from "src/ducks/estimate/selectors"
import Comments from "../Comments"

import styles from './styles.module.scss'

const CommentsModal = () => {
  const estimate = useSelector(getEstimate())

  return (
    <>
      <Box className={styles.Comments}>
        <Box display="flex">
          <Typography marginRight=".4rem" variant="h6Bold">Estimate: </Typography>
          <Typography variant="h6" display="flex"> {estimate?.properties.fullAddress}
          </Typography>
        </Box>
        <Comments />
      </Box>
    </>
  )
}
export default CommentsModal