import { REVIEW_STATUS } from 'src/helpers'

export const reviewStatusColors = [
  {
    status: REVIEW_STATUS.APPROVED,
    color: '#0E905A',
    background: '#EDFDF6',
  },

  {
    status: REVIEW_STATUS.DENIED,
    color: '#CA0000',
    background: '#FFF4F4',
  },

  {
    status: REVIEW_STATUS.DRAFT,
    color: '#AD7D00',
    background: '#FFFBF0',
  },
]
