/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import BackButton from 'src/components/UI/CustomUI/atoms/BackButton'
import TabNavigation from 'src/components/UI/CustomUI/organisms/TabNavigation'
import { jobActions, itemsActions, siteVisitsActions, materialInvoicesActions, employeeActions, jobsActions } from 'src/ducks/actions'
import { Box } from '../../../UI'
import JobTopInfo from './JobsTopInfo'
import { Attachments, Communications, History, Information, Overview, Payments, ReviewItems, WorkOrders } from './Tabs'
import { WorkOrdersProvider } from './Tabs/WorkOrders/context'
import ControlButtons from './ControlButtons'
import { ReviewProvider } from './Tabs/ReviewItems/context'
import { ITEM_STATUS } from 'src/helpers'
import { getItems, getItemsSortedByCategory } from 'src/ducks/selectors'
import CommentsFab from './CommentsFab'
import { Item } from 'src/ducks/types'

const JobsView: FC = () => {
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const [pageLoading, setPageLoading] = useState(true)
  const itemsByCategory = useSelector(getItemsSortedByCategory())
  const [selectedTab, setSelectedTab] = useState<number>()
  const { id } = useParams()
  const navigate = useNavigate()

  const reviewItems = itemsByCategory?.reduce((acc: Item[], curr: any) => [...acc, ...curr.items.filter((item: Item) => item.status === 'COMPLETE')], []);

  const fetchJob = useCallback(() => {
    setPageLoading(true)

    if (id) {
      dispatch(
        jobActions.fetchJob(id, (succ: boolean) => {
          setPageLoading(false)

          if (!succ) {
            setPageLoading(false)
            navigate('/not-found')
          }
                dispatch(itemsActions.fetchItems({
                  params: { jobId: id }
                }, (_succ: boolean, _count?: number) => {
                }))
        })
      )
    }
  }, [id])

  const fetchSiteVisits = useCallback(() => {
    if (id) {
      dispatch(
        siteVisitsActions.getSiteVisits({ jobId: id })
      )
    }
  }, [id])

  const fetchCurrentEmployeeToResetRole = useCallback(() => {
    dispatch(employeeActions.fetchCurrentEmployee((fetchSuccess: any) => {
    }))
  }, [])

  useEffect(() => {
    dispatch(materialInvoicesActions.setMaterialInvoices([]))
    fetchJob()
    fetchSiteVisits()
    fetchCurrentEmployeeToResetRole()
    return () => {
      dispatch(jobActions.cleanJob(null))
    }
  }, [id])

  const refresh = () => {
    setPageLoading(true)
    fetchJob()
  }

  const TABS = [
    { label: 'Overview', content: <Overview /> },
    { label: 'Work Orders', content: <WorkOrdersProvider><WorkOrders /></WorkOrdersProvider> },
    { label: `Review Items (${reviewItems.length})`, content: <ReviewProvider><ReviewItems /></ReviewProvider> },
    { label: 'Communications', content: <Communications /> },
    { label: 'Payments', content: <Payments /> },
    { label: 'Attachments', content: <Attachments /> },
    { label: 'History', content: <History /> },
    { label: 'Information', content: <Information /> }
  ]

  return (
    <>
      {searchParams?.get('from') ? (<BackButton float top='12px' to={'/' + searchParams?.get('from') ?? undefined} />) : (<BackButton float top='12px' />)}
      <Box display='grid' gridTemplateRows='min-content auto' gap={2}>
        <Box padding={selectedTab === 3 ? '24px 24px 0' : 0}><JobTopInfo loading={pageLoading} /></Box>
        {!pageLoading && <Box>
          <TabNavigation
            tabs={TABS}
            margin='0'
            style={{ fontSize: 'var(--font-XS)' }}
            defaultActive={parseInt(searchParams?.get('tab') ?? '0')}
            getSelectedTab={(selected) => {
              setSelectedTab(selected)
              const orderId = searchParams.get('order')
              const fromParam = searchParams.get('from')
              if (fromParam !== null) {
                setSearchParams({ ...searchParams, tab: selected.toString(), from: fromParam }, { replace: true })
              } else {
                const params: any = {
                  ...searchParams,
                  tab: selected.toString(),
                }
                if (orderId && selected === 1) {
                  params.order = orderId
                }
                setSearchParams(params, { replace: true })
              }
            }}
            containerSx={{ padding: selectedTab === 3 ? '0 24px' : 0, margin: '1rem 0 0', display: 'flex', justifyContent: 'space-between' }}
            additionalContent={<ControlButtons refresh={refresh} />}
            tabsStyles={{ width: 'fit-content' }}
          />
          {/* 
            We show the Comments button only if the tab isn't the Overview one
            https://trello.com/c/u68hEvLF/25-rocky-comments-on-jobview#comment-63c5f03eafe5cb001602d7cc
          */}
          {selectedTab !== 0 && <CommentsFab />}
        </Box>}
      </Box>
    </>
  )
}

export default JobsView
