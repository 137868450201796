import { SelectInputOption } from 'src/components/UI/CustomUI/atoms/SelectInput/types';
import { authTypes } from '../auth/types'
import { FiltersType, FiltersAction, filtersTypes, Range } from './types'

const narAffiliates = [
  { key: "all", label: "All" },
  { key: "nar", label: "NAR" }
];

const userTypes: SelectInputOption[] = [
  { key: 'HOMEOWNER', label: 'Homeowner', hide: true },
  { key: 'INSTITUTIONAL', label: 'Institutional', hide: true },
  { key: 'BROKER', label: 'Broker', hide: true },
]
const defaultRange: Range = {
  startDate: undefined,
  endDate: undefined
}

const initialState: FiltersType = {
  jobs: {},
  pros: {},
  subscriptions: {},
  companies: {},
  estimates: {},
  projectCoordinator: {},
  customers: {
    userTypes: userTypes,
    dateRange: defaultRange
  },
  repairList: {},
}

const reducer = (state = initialState, action: FiltersAction): FiltersType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: {
      return initialState
    }

    case authTypes.LOGOUT_REQUEST: {
      return initialState
    }

    case filtersTypes.SET_FILTERS: {
      return {
        ...state,
        ...payload
      }
    }

    case filtersTypes.CLEAR_FILTERS: {
      return {
        ...initialState,
        jobs: { isKanbanView: state.jobs.isKanbanView },
        projectCoordinator: { statusSelected: 1 },
        pros: { pageStatus: state.pros.pageStatus }
      }
    }

    default:
      return state
  }
}

export default reducer
