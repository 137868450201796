import { FC, useState } from 'react'
import { Dialog, Button, DialogContent, Typography, TextFieldLabel, Grid } from '../../../../../../UI'
import useStyles from './styles'
import useStylesBetter from './stylesBetter'
import { ShareModalProps } from '../types'
import { isEmpty, SHARE_ROLE, validateEmail } from '../../../../../../../helpers'
import { estimateActions } from '../../../../../../../ducks/actions'
import { useDispatch } from 'react-redux'
import Autocomplete from 'src/components/UI/CustomUI/molecules/Autocomplete'

const DesktopShareModal: FC<ShareModalProps> = ({ open, setOpen, showBetterStyle = false }) => {
  const styles = useStyles()
  const stylesBetter = useStylesBetter()
  const classes = showBetterStyle ? stylesBetter : styles
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [showWarnings, setShowWarnings] = useState(false)

  const isValidEmail = !isEmpty(email) && validateEmail(email)

  const roleOptions = SHARE_ROLE.map((role, index) => {
    return {
      key: index + 1,
      value: role.label,
      param: role.role
    }
  })
  const [role, setRole] = useState(null)

  const clear = () => {
    setEmail('')
    setFirstName('')
    setLastName('')
    setRole(null)
    setShowWarnings(false)
  }

  const handleClose = () => {
    clear()
    setOpen(false)
  }

  const handleSubmit = () => {
    setShowWarnings(!isValidEmail)
    if (isValidEmail) {
      setOpen(false)
      clear()
      dispatch(estimateActions.shareEstimate({
        email,
        firstName,
        lastName,
        role: (role as any)?.param
      }))
    }
  }

  return (
    <Dialog onClose={handleClose} open={open} classes={{ paper: classes.root }}>
      <DialogContent>
        <Typography variant='h5' className={classes.text}>
          Share Estimate
        </Typography>
        <Typography variant='body1' className={classes.description}>
          Enter the information and role of the person you want to share the Estimate with.
        </Typography>
      </DialogContent>
      <DialogContent className={classes.inputsContainer}>
        <Grid container spacing={2} justifyContent='space-between'>
          <Grid item className={classes.flexOne}>
            <TextFieldLabel
              label='First Name'
              type='text'
              value={firstName}
              onChange={(event) => { setFirstName(event.target.value) }}
              placeholder='First Name'
            />
          </Grid>
          <Grid item className={classes.flexOne}>
            <TextFieldLabel
              label='Last Name'
              type='text'
              value={lastName}
              onChange={(event) => { setLastName(event.target.value) }}
              placeholder='Last Name'
            />
          </Grid>
        </Grid>
        <TextFieldLabel
          label='Email Address'
          type='email'
          value={email}
          error={showWarnings && !isValidEmail}
          onChange={(event) => { setEmail(event.target.value) }}
          placeholder='mail@email.com'
        />
        <Autocomplete
          label="Role"
          value={role}
          placeholder="Select Role"
          options={roleOptions}
          onChange={(value: any) => {
            setRole(value)
          }}
        />
      </DialogContent>
      <DialogContent className={classes.submitContainer}>
        <Button
          type='submit'
          variant='outlined'
          color='primary'
          className={classes.cancel}
          fullWidth
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          className={classes.submit}
          fullWidth
          onClick={handleSubmit}
        >
          Send
        </Button>
      </DialogContent>
      <DialogContent />
    </Dialog>
  )
}

export default DesktopShareModal
