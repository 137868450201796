import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { icons } from 'src/assets'
import TabNavigation from 'src/components/UI/CustomUI/organisms/TabNavigation'
import { getEstimate } from 'src/ducks/selectors'
import {
  Box,
  BoxContainer,
  Button,
  CircularProgress,
  Grid,
  LabeledText,
  SelectInput,
  TitleFirst,
  Typography,
} from '../../../../UI'
import Modal from 'src/components/UI/CustomUI/molecules/Modal'
import { estimatesActions } from 'src/ducks/actions'
import General from './Tabs/General'
import { EstimateItem } from 'src/ducks/types'
import Milestones from './Tabs/Milestones'
import Details from './Tabs/Details'
import { InsertLink } from '@mui/icons-material'
import Financial from './Tabs/Financial'
import { workOrderItemFilterOptions } from 'src/helpers'

const EstimateTopInfo: FC<{ loading: boolean, children: React.ReactNode }> = ({ loading, children }) => {
  const dispatch = useDispatch()

  const estimate = useSelector(getEstimate())

  const [status, setStatus] = useState(estimate?.status)
  const [confirmationModal, setConfirmationModal] = useState(false)
  const [toDeleteIdx, setToDeleteIdx] = useState<number>()

  const files = estimate?.properties?.files
    ?.filter((file) => !file.fileType?.includes('GOOGLE_SEARCH_IMAGE'))
    .map((file, idx) => {
      return {
        name: file.name,
        onDeleteClick: () => {
          setToDeleteIdx(idx)
          setConfirmationModal(true)
        },
        onViewClick: () => {
          window.open(file.fileUrl, '_blank')
        },
      }
    })

  useEffect(() => {
    setStatus(estimate?.status)
  }, [estimate])

  const ContractTabs = [
    {
      label: `Details`,
      content: <Details files={files || []} />,
    },
    { label: 'Financial', content: <Financial /> },
    { label: 'Milestones', content: <Milestones /> },
  ]

  const handleDelete = () => {
    if (estimate?.properties?.address)
      dispatch(
        estimatesActions.updateEstimate(
          {
            properties: {
              ...estimate?.properties,
              files: estimate?.properties?.files?.filter(
                (e, idx) => idx !== toDeleteIdx
              ),
            },
          },
          (succ: boolean) => {
            setConfirmationModal(false)
          }
        )
      )
  }

  return (
    <Box
      display="flex"
      flexDirection="row"
      gap={2}
      justifyContent="space-between"
      style={{ maxWidth: "97vw" }}
    >
      <Modal
        setOpen={setConfirmationModal}
        open={confirmationModal}
        title={
          <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 3, ml: 2 }}>
            Confirm Delete
          </Typography>
        }
      >
        <Box sx={{ p: 2 }}>
          <Typography variant="body1">
            Are you sure you want to delete this attachment?
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <Button
            variant="outlined"
            onClick={() => setConfirmationModal(false)}
          >
            Cancel
          </Button>
          <Button
            endIcon={
              loading ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                <icons.Delete />
              )
            }
            variant="containedError"
            color="error"
            onClick={handleDelete}
            sx={{ ml: 2, color: 'red' }}
          >
            Delete
          </Button>
        </Box>
      </Modal>
      <BoxContainer
        sx={{ width: '100%', height: '250px', justifyContent: 'flex-start' }}
        title={
          <Box display="flex" alignItems="center">
            <TitleFirst
              alignItems="center"
              black="Work Order"
              blackVariant="h5"
              lineHeight="24px"
            />
            <Box
              borderLeft="1px solid"
              width={0}
              height="15px"
              marginLeft={1}
              marginRight={1}
            />
            <LabeledText
              textVariant="h5"
              labelValue="#"
              textValue={'19987'}
              showCopyButton={true}
            />
          </Box>
        }
        loading={loading}
      >
        <General estimate={estimate as EstimateItem} />
      </BoxContainer>
      <BoxContainer
        sx={{ width: '100%', height: '250px', justifyContent: 'flex-start', padding: "8px 20px" }}
        title={
          <Box display="flex" alignItems="center">
            <TitleFirst
              alignItems="center"
              black="Contract"
              blackVariant="h5"
              lineHeight="24px"
            />
            <Box
              borderLeft="1px solid"
              width={0}
              height="15px"
              marginLeft={1}
              marginRight={1}
            />
            <Box
              display={'flex'}
              gap={'4px'}
              alignItems="center"
              color="#3A66FF"
              bgcolor="var(--gray100)"
              style={{ cursor: 'pointer' }}
              padding={"0px 4px"}
              borderRadius="4px"
            >
              <Typography
                fontSize={'14px'}
                lineHeight={'22px'}
                fontWeight={400}
              >
                Contract Link
              </Typography>
              <InsertLink style={{ width: '14px', height: '14px' }} />
            </Box>
          </Box>
        }
        loading={loading}
      >
        <Grid container spacing={1}>
          <TabNavigation
            tabs={ContractTabs}
            variant="contained"
            margin="12px 0 4px 0"
          />
        </Grid>
      </BoxContainer>
      {children}
    </Box>
  )
}

export default EstimateTopInfo
