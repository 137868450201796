import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Grid, Typography } from '@mui/material'

import Icon from '../Icon'
import { BackButtonProps } from './types'
import styles from './styles.module.scss'

const BackButton: FC<BackButtonProps> = ({
  to,
  large = false,
  float = false,
  top = '18px',
}) => {
  let navigate = useNavigate()
  const path = window.location.pathname.split('/')
  let back = to || path.slice(0, path.length - 1).join('/')

  if (typeof to === 'number' && to > 0) {
    path.splice(path.length - to, to)
    back = `/${path.filter((path) => path).join('/')}`
  }

  if (float) {
    return (
      <Box
        onClick={() => navigate(back)}
        className={`${styles.BackButton} ${styles.BackButton__Float} ${
          large && styles.BackButton__Large
        }`}
        sx={{ top: top }}
      >
        <Icon name="ArrowBack" />
        {large && <Typography variant="caption">Back</Typography>}
      </Box>
    )
  }

  return (
    <Grid
      container
      onClick={() => navigate(back)}
      className={styles.BackButton}
    >
      <Icon name="ArrowBackIos" color="var(--gray500)" />
      <Typography variant="h4" color="var(--gray500)">
        Back
      </Typography>
    </Grid>
  )
}

export default BackButton
