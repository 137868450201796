import { FC, useCallback, useEffect, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { PrivateLoader } from 'src/components/templates'
import { companyActions } from 'src/ducks/actions'
import { getCompany } from 'src/ducks/selectors'
import { Users } from './Tabs'
import TabNavigation from 'src/components/UI/CustomUI/organisms/TabNavigation'
import TabElement from './TabElement'

import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle'
import ImageIcon from '@mui/icons-material/Image'

import styles from './styles.module.scss'
import BackButton from 'src/components/UI/CustomUI/atoms/BackButton'

const TABS = [
  // {
  //   label: '',
  //   element: (
  //     <TabElement
  //       label="Overview"
  //       icon={<BuildIcon fontSize="small" color="inherit" />}
  //     />
  //   ),
  //   content: <Overview />,
  // },
  {
    label: '',
    element: (
      <TabElement
        label="Users"
        icon={<SupervisedUserCircleIcon fontSize="small" color="inherit" />}
      />
    ),
    content: <Users />,
  },
  // {
  //   label: '',
  //   element: (
  //     <TabElement
  //       label="Integrations"
  //       icon={<MediationIcon fontSize="small" color="inherit" />}
  //     />
  //   ),
  //   content: <p style={{ margin: 0 }}>1234</p>,
  // },
]

const CompanyView: FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()

  // Context

  const [pageLoading, setPageLoading] = useState<boolean>(false)

  const company = useSelector(getCompany())

  const fetchCompany = useCallback(() => {
    setPageLoading(true)

    if (id) {
      dispatch(
        companyActions.fetchCompany(id, (succ: boolean) => {
          setPageLoading(false)

          if (!succ) {
            setPageLoading(false)
            navigate('/not-found')
          }
        })
      )
    }
  }, [id, dispatch, navigate])

  useEffect(() => {
    fetchCompany()
  }, [fetchCompany])

  return (

    <PrivateLoader loading={pageLoading}>
      <Box display="flex" flexDirection="column" gap={3}>
        <BackButton />
        <Grid container justifyContent="space-between">
          <Box className={styles.Company__logoContainer}>
            {company?.logo?.fileUrl ? (
              <img
                src={`${company?.logo?.fileUrl}`}
                alt="Logo"
                className={styles.Company__logoContainer__logo}
              />
            ) : (
              <Box
                className={styles.Company__logoContainer__logo}
                style={{ backgroundColor: '#EBECEF' }}
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="5px"
              >
                <ImageIcon color="inherit" fontSize="small" />
              </Box>
            )}
            <Typography className={styles.Company__logoContainer__name}>
              {company?.name}
            </Typography>
          </Box>
        </Grid>

        <TabNavigation
          tabs={TABS}
          defaultActive={0}
          tabsStyles={{ width: 'fit-content !important', margin: 0 }}
        />
      </Box>
    </PrivateLoader>
  )
}

export default CompanyView
