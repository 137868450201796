import { useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Table from 'src/components/UI/CustomUI/organisms/Table'
import { notificationsActions } from 'src/ducks/actions'
import { getNotifications } from 'src/ducks/selectors'
import headers from './headers'

const Customer = () => {

  const [tableLoading, setTableLoading] = useState(false)
  const dispatch = useDispatch()
  const customers = useSelector(getNotifications())

  const fetchServices = useCallback((page?: number, size?: number) => {
    setTableLoading(true)
    dispatch(notificationsActions.fetchNotifications({ page, size, searchParams: { source: 'EXTERNAL' } }, (_succ: boolean) => {
      setTableLoading(false)
    }))
  }, [])

  useEffect(() => {
    fetchServices()
  }, [dispatch, fetchServices])


  return (
    <Table
      headers={headers}
      loading={tableLoading}
      callToApi={(start, limit) => { fetchServices(start, limit) }}
    >
      {customers.items ?? []}
    </Table>
  )
}

export default Customer
