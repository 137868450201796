import { FormControlLabel, Radio, RadioGroup as RadioGroupMUI } from "@mui/material";
import { FC } from "react";
import { RadioGroupProps } from "./types";

import styles from './styles.module.scss';
import Icon from "../Icon";

const RadioGroup: FC<RadioGroupProps> = ({
  onChange,
  value,
  options,
  labelPlacement = "start",
  variant = "text",
  spacing = 1,
  sx,
}) => {
  return (
    <RadioGroupMUI
      value={value}
      onChange={onChange}
      sx={{ flexDirection: "row", gap: spacing, ...sx }}
      className={`${styles.RadioGroup} ${styles[variant]}`}
    >
      {options.map((option, index) =>
        <FormControlLabel
          key={index}
          value={option.value}
          control={<Radio
            color="primary"
            icon={<Icon name="RadioButtonUnchecked" color="var(--gray500)" />}
            checkedIcon={<Icon name="RadioButtonChecked" />}
            sx={{
              '& .MuiSvgIcon-root': {
                fontSize: 18,
              },
            }}
          />}
          label={option.label}
          sx={{ height: "16px" }}
          labelPlacement={labelPlacement}
        />
      )}

    </RadioGroupMUI>
  )
}

export default RadioGroup;