import { FC, useState } from 'react'

import { Box, Button, CircularProgress, Grid, SvgIcon, Typography } from '@mui/material'
import { isEmpty } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { icons } from 'src/assets'
import { estimateItemsActions } from 'src/ducks/actions'
import { getEstimate, getItemsInCart, } from 'src/ducks/selectors'
import { Item, } from 'src/ducks/types'
import { ITEM_STATUS, history, } from 'src/helpers/index'
import EstimateItem from '../EstimateItem'
import PriceDetail from '../PriceDetail'
import EmptyCart from './components/EmptyState'
import PromotionalCode from './components/PromotionalCode'
import useStyles from './styles'
import WarningBar from 'src/components/UI/CustomUI/molecules/WarningBar'
import { ScrollWrapper } from 'src/components/templates'

export interface CartProps {
  // price: string
  showAlert?: () => void
  isPunchlistPlanner?: boolean
  serviceable?: boolean
  fetchEstimateView: (loadings: boolean) => void
  handleOpenContactUsModal: (showFAQ: boolean) => void
  setOpenCart?: (open: boolean) => void
  onApprove?: () => void
  showApproveButton?: boolean
  price?: string
  estimateNotApproved?: boolean
}

const Cart: FC<CartProps> = ({
  showAlert,
  isPunchlistPlanner = false,
  serviceable,
  fetchEstimateView,
  handleOpenContactUsModal,
  setOpenCart,
  onApprove,
  showApproveButton,
  price,
  estimateNotApproved,
}) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const cartItems = useSelector(getItemsInCart())
  const [showPromoInput, setShowPromoInput] = useState(false)
  const [loading, setLoading] = useState(false)
  const [itemIdLoading, setItemIdLoading] = useState('')
  const { id: estimateId } = history.getParamValues()
  // const recommendedItems = useSelector(getRecommendedItems())

  const estimate = useSelector(getEstimate())
  const { properties, promotionId } =
    estimate ?? {}
  const {
    nar,
    taxAmount,
    totalWithTax = 0,
    taxRate,
    discount
  } = properties ?? {}

  const promoCodeAlreadyInCart = estimate?.promotionId

  const handleRemoveItem = (item: Partial<Item>) => {
    setLoading(true)
    setItemIdLoading(item?.id || '')

    item.itemId && dispatch(
      estimateItemsActions.updateEstimateItem(
        {
          estimateId,
          itemId: item.itemId,
          partialItem: {
            status: ITEM_STATUS.REJECTED
          }
        },
        () => {
          setItemIdLoading('')
          setLoading(false)

          fetchEstimateView(false)
        }
      )
    )
  }

  return (
    <>
      <ScrollWrapper absoluteClass={classes.absoluteContainer} relativeClass={`${classes.relativeContainer}`}>
        <Box className={classes.cart}>
          <Box className={classes.promoCode}>
            {serviceable && !isEmpty(cartItems) ?
              <Box className={classes.header}>
                <Typography className={`${classes.gray} ${classes.bold}`}>{cartItems.length} Items</Typography>
                {promoCodeAlreadyInCart ?
                  <Grid container className={classes.successPromoCodeContainer}>
                    <icons.CheckCircle className={classes.promoSuccessIcon} />
                    <Typography className={classes.promoSuccessText}>Promo Code applied</Typography>
                  </Grid>
                  : <Typography className={classes.showPromoCode} onClick={() => setShowPromoInput(!showPromoInput)}>{showPromoInput ? 'Hide Promo Code' : 'Have a Promo Code?'}</Typography>}
              </Box> :
              <Box className={classes.header}>
                <Typography className={`${classes.gray} ${classes.bold}`}>{cartItems.length} Items</Typography>
              </Box>
            }
            {showPromoInput && !promoCodeAlreadyInCart && <PromotionalCode /* setPromoSuccess={setPromoSuccess} */ />}
          </Box>

          {(estimate?.properties?.minimum && !isEmpty(cartItems) && (!estimate?.properties?.totalWithTax || estimate?.properties?.totalWithTax < estimate?.properties?.minimum) && serviceable) && (
            <Box className={classes.row}>
              <WarningBar
                text={<Typography>BOSSCAT has a ${estimate?.properties?.minimum} minimum for Jobs. If you'd like to discuss this or learn how to move forward, please <Typography onClick={() => handleOpenContactUsModal(false)} className={classes.buttonContactUs}> contact us.</Typography></Typography>}
              />
            </Box>
          )}
          {isEmpty(cartItems) && <Box className={classes.row}>
            <EmptyCart
              onClick={() => setOpenCart && setOpenCart(false)}
            />
          </Box>}
          <Box className={`${classes.row} ${classes.itemsContainer}`}>
            {loading ?
              <Box className={classes.spinner}> <CircularProgress /></Box>
              : cartItems.map(item => (
                <EstimateItem
                  item={item}
                  // showAlert={showAlert}
                  isPunchlistPlanner={isPunchlistPlanner}
                  showPrice
                  showPriceRange={!serviceable}
                  className={classes.cartItem}
                  actionsInContent={
                    <Box className={classes.buttonsContainer}>
                      {/*   <Button
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                          event.stopPropagation()
                          // handleRemoveItem(item)
                          // handleClickConsultation(item)
                        }}
                        disabled={loading}
                        className={classes.buttonSaveForLater}
                      >
                        Save For Later
                      </Button> */}
                      <Button
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                          event.stopPropagation()
                          handleRemoveItem(item)
                          // handleClickConsultation(item)
                        }}
                        disabled={loading}
                        className={classes.buttonRemove}
                      >
                        Remove
                      </Button>
                    </Box>
                  }
                />
              ))}
          </Box>

          {/*  {isMobile && <Divider variant='fullWidth' style={{ width: '100%', marginTop: '.8rem' }} />} */}
          {/* 
          {serviceable && !isEmpty(recommendedItems) &&
            <Box className={`${classes.row}`}>
              <EstimateItemsContainer
                groupedItems={recommendedItems}
                canBeEmpty
                title={
                  <Box>
                    <Typography className={`${classes.dontForgetTitle} ${classes.title}`}>You might be interested <icons.Star /></Typography>
                    <Typography className={`${classes.subtitle} ${classes.subtitle}`}>See If you may need these commonly requested services.</Typography>
                  </Box>
                }
                className={classes.itemsContainer}
              >
                {recommendedItems.map(item => {
                  // cartItems.find(item)
                  const itemIsInCart = item.status === ITEM_STATUS.APPROVED

                  return (
                    <EstimateItem
                      item={item}
                      key={item.itemId}
                      // showAlert={showAlert}
                      isPunchlistPlanner={isPunchlistPlanner}
                      actionsClassName={classes.buttons}
                      showPrice
                      overrideTotalPrice={item.unitPrice}
                      showExpand={false}
                      showCounter={false}
                      image={houseInfrastructure}
                      subtitle={<Typography className={classes.itemSubtitle}>{item.disclaimer}</Typography>}
                      showImages={false}
                      actions=
                      {loading && item.id === itemIdLoading ?
                        <Box width={86} className={classes.spinnerContainer}>
                          <CircularProgress />
                        </Box>
                        :
                        <Button
                          startIcon={
                            !itemIsInCart && <icons.ShoppingCart />
                          }
                          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                            event.stopPropagation()
                            handleUpdateItem(item)
                            // handleClickConsultation(item)
                          }}
                          disabled={loading}
                          className={itemIsInCart ? classes.buttonRemove : classes.buttonAdd}>{itemIsInCart ? 'Remove' : 'Add'}</Button>
                      }
                    />
                  )
                })}
              </EstimateItemsContainer>
            </Box>} */}
        </Box>
      </ScrollWrapper>

      <Box className={`${classes.footer}`}>
        <Box className={classes.footerContent}>
          {estimateNotApproved && <Box className={classes.priceDetailContainer}>
            <PriceDetail
              discount={discount || 0}
              isNar={nar}
              taxAmount={taxAmount}
              totalValue={totalWithTax}
              taxRate={taxRate}
              priceRange={price}
              align="flex-start"
              style={{ padding: '0 .5rem' }}
              serviceable={serviceable}
              totalPrice={price}
              discountText={promotionId || ''}
            />
          </Box>}
        </Box>

        {serviceable &&
          <Button
            type="submit"
            variant="contained"
            size="small"
            className={classes.submitButton}
            fullWidth
            onClick={onApprove}
            startIcon={<SvgIcon component={icons.CalendarToday} />}
            endIcon={<SvgIcon component={icons.ArrowForward} />}
            disabled={!showApproveButton || isEmpty(cartItems)}
          >
            <Typography>Order & Schedule</Typography>
          </Button>
        }
      </Box>
    </>
  )
}

export default Cart
