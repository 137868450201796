import { Box, Typography } from 'src/components/UI'
import InfoIcon from '@mui/icons-material/Info'
import useStyles from '../styles'

const Alert = ({ text }: { text: string }) => {
  const styles = useStyles()

  return (
    <Box className={styles.alertContainer}>
      <InfoIcon style={{ width: '24px', height: '24px' }} />
      <Typography className={styles.alertText}>
        {text}
      </Typography>
    </Box>
  )
}

export default Alert
