/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import { toast } from 'react-toastify'
import type { SagaIterator } from 'redux-saga'
import { select, call, put, takeLatest } from 'redux-saga/effects'

import { Auth, Punchlist } from '../../api'
import { CreateEmployeeRequest } from '../../api/punchlist/employees/types'
import { UpdateRequest } from '../../api/types'
import { EMAIL_TYPE, errorTextTryingTo, isEmpty } from '../../helpers'
import { configActions, employeeActions } from '../actions'
import { getEmployeeId, getEmployee } from '../selectors'

import {
  employeeTypes,
  FetchCurrentEmployeeAction,
  CreateEmployeeAction,
  EmployeeType,
  UpdateEmployeeAction,
  UpdateEmployeeIdAction,
  UpdateEmployeeListAction
} from './types'

const parseEmployeeAttrs = (employee: Partial<EmployeeType>): Partial<EmployeeType> => {
  return {
    id: employee.id || '',
    email: employee.email || [],
    firstName: employee.firstName || '',
    middleName: employee.middleName || '',
    lastName: employee.lastName || '',
    phone: employee.phone || null,
    address: employee.address || { city: '', state: '', line_1: '', line_2: '', zipCode: '', latitude: 0, longitude: 0, fullAddress: '' },
    picture: employee.picture || '',
    registrationComplete: employee.registrationComplete || false,
    type: employee.type || '',
    roles: employee.roles || [],
    approved: employee.approved,
    admin: employee.admin
  }
}

export function* fetchCurrentEmployee({ callback }: FetchCurrentEmployeeAction): SagaIterator {
  let success = false
  try {
    const data = yield call(Punchlist.employees.getCurrentEmployee)

    if (!isEmpty(data) && !isEmpty(data.id)) {
      Auth.set(data.id, 'punchlist-employee-id', true)
      yield put(configActions.setConfigValue({ type: 'employeeType', value: data.clientType }))
      yield put(employeeActions.setEmployeeValues({ attrs: parseEmployeeAttrs(data) }))
      success = true
    }
    yield call(callback, success)
  } catch (error) {
    yield call(callback, false)
  }
}

export function* createEmployee({ callback }: CreateEmployeeAction): SagaIterator {
  let success = false

  try {
    const employeeInfo = Auth.getUserInfo()
    if (employeeInfo && employeeInfo.email) {
      const request = {
        email: [{
          emailType: EMAIL_TYPE.PRIMARY,
          email: employeeInfo.email
        }],
        type: 'Employee'
      } as CreateEmployeeRequest

      const data = yield call(Punchlist.employees.createEmployee, request)

      if (!isEmpty(data) && !isEmpty(data.id)) {
        Auth.set(data.id, 'punchlist-employee-id', true)
        yield put(configActions.setConfigValue({ type: 'employeeType', value: data.clientType }))
        yield put(employeeActions.setEmployeeValues({ attrs: parseEmployeeAttrs(data) }))
        success = true
      }
    }
    yield call(callback, success)
  } catch (error) {
    yield call(callback, false)
  }
}

export function* updateEmployee({ payload, callback }: UpdateEmployeeAction): SagaIterator {
  let success = false

  try {
    const employeeId = yield select(getEmployeeId)
    const request: UpdateRequest = []

    for (const attr in payload) {
      if (typeof payload[attr as keyof EmployeeType] === 'object') {

        for (const att in payload[attr as keyof EmployeeType] as any) {
          const value = payload[attr as keyof EmployeeType] as any;
          request.push({
            op: 'add',
            path: '/' + attr + '/' + att,
            value: value?.[att as any]
          })
        }
      } else {
        request.push({
          op: 'add',
          path: '/' + attr,
          value: payload[attr as keyof EmployeeType]
        })
      }
    }

    const data = yield call(Punchlist.employees.updateEmployee, employeeId, request)

    if (!isEmpty(data)) {
      yield put(configActions.setConfigValue({ type: 'employeeType', value: data.clientType }))
      yield put(employeeActions.setEmployeeValues({ attrs: parseEmployeeAttrs(data) }))
      success = true
    }
    yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('update employee info'))
    yield call(callback, false)
  }
}

export function* updateEmployeeId({ employeeId, payload, callback }: UpdateEmployeeIdAction): SagaIterator {
  let success = false

  try {
    const request: UpdateRequest = []

    for (const attr in payload) {
      request.push({
        op: 'add',
        path: '/' + attr,
        value: payload[attr as keyof EmployeeType]
      })
    }
    const data = yield call(Punchlist.employees.updateEmployee, employeeId, request)

    if (!isEmpty(data)) {
      success = true
    }
    yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('update employee info'))
    yield call(callback, false)
  }
}

export function* replaceEmployeeId({ employeeId, payload, op, callback }: UpdateEmployeeIdAction): SagaIterator {
  let success = false

  try {
    const request: UpdateRequest = []

    if (op === 'remove') {
      for (const attr in payload) {
        request.push({
          op: op,
          path: '/' + attr + '/0',
          value: payload[attr as keyof EmployeeType]
        })
      }

    } else {
      for (const attr in payload) {
        request.push({
          op: op,
          path: '/' + attr,
          value: payload[attr as keyof EmployeeType]
        })
      }
    }

    const data = yield call(Punchlist.employees.updateEmployee, employeeId, request)

    if (!isEmpty(data)) {
      success = true
    }
    yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('update employee info'))
    yield call(callback, false)
  }
}

export function* deleteEmployeeId({ employeeId, payload, callback }: UpdateEmployeeIdAction): SagaIterator {
  let success = false

  try {
    const data = yield call(Punchlist.employees.deleteEmployee, employeeId)

    if (!isEmpty(data)) {
      success = true
    }
    yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('delete employee info'))
    yield call(callback, false)
  }
}

export function* updateEmployeeList({ payload, callback }: UpdateEmployeeListAction): SagaIterator {
  let success = false
  let removeData = null
  let addData = null

  try {
    const employeeId = yield select(getEmployeeId)

    if (payload.opp === 'removeAll' || payload.opp === 'replace') {
      const requestRemove: UpdateRequest = []
      requestRemove.push({ op: 'remove', path: '/' + payload.attr })
      removeData = yield call(Punchlist.employees.updateEmployee, employeeId, requestRemove)
    }

    if (payload.opp === 'add' || (payload.opp === 'replace' && removeData)) {
      const requestAdd: UpdateRequest = []
      for (const index in payload.list) {
        requestAdd.push({
          op: 'add',
          path: '/' + payload.attr + '/-',
          value: payload.list[parseInt(index)]
        })
      }
      addData = yield call(Punchlist.employees.updateEmployee, employeeId, requestAdd)
    }

    if (payload.opp === 'removeAll' && removeData) {
      yield put(employeeActions.setEmployeeValue({
        attr: payload.attr, value: []
      }))
      success = true
    }

    if (payload.opp === 'replace' && addData) {
      yield put(employeeActions.setEmployeeValue({
        attr: payload.attr, value: payload.list
      }))
      success = true
    }

    if (payload.opp === 'add' && addData) {
      const employee = yield select(getEmployee)
      yield put(employeeActions.setEmployeeValue({
        attr: payload.attr, value: [...employee[payload.attr], ...payload.list ?? []]
      }))
      success = true
    }

    yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('update employee info'))
    yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(employeeTypes.FETCH_CURRENT_EMPLOYEE, fetchCurrentEmployee)
  yield takeLatest(employeeTypes.CREATE_EMPLOYEE, createEmployee)
  yield takeLatest(employeeTypes.UPDATE_EMPLOYEE, updateEmployee)
  yield takeLatest(employeeTypes.UPDATE_EMPLOYEE_ID, updateEmployeeId)
  yield takeLatest(employeeTypes.DELETE_EMPLOYEE_ID, deleteEmployeeId)
  yield takeLatest(employeeTypes.REPLACE_EMPLOYEE_ID, replaceEmployeeId)
  yield takeLatest(employeeTypes.UPDATE_EMPLOYEE_LIST, updateEmployeeList)
}
