import { Box, Button, Typography } from '@mui/material'
import {
  HeaderType,
  AlignType,
} from 'src/components/UI/CustomUI/organisms/Table/types'
import SettingsIcon from '@mui/icons-material/Settings';
import moment from 'moment'

import styles from './styles.module.scss'
import { getCompaniesForDropdown } from 'src/ducks/selectors';
import { useSelector } from 'react-redux';

export const removeProtocol = (url: string): string => {
  if (!url) {
    return ''
  }

  return url.replace(/(^\w+:|^)\/\//, '')
}

const headers: HeaderType[] = [
  {
    id: 'companyName',
    label: 'Company Name',
    custom: true,
    align: 'left' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Box padding="2px 10px">
          <Typography className={styles.Widgets__companyName}>
            {row?.name}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'partnerId',
    label: 'Partner ID',
    custom: true,
    align: 'left' as AlignType,
    Element: (row: any): JSX.Element => {
      const companies = useSelector(getCompaniesForDropdown)
      return (
        <Box padding="2px 10px">
          <Typography className={styles.Widgets__companyName}>
            {companies?.find(company => company?.key === row?.affiliate)?.label}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'createdOn',
    label: 'Created On',
    custom: true,
    align: 'left' as AlignType,
    width: 160,
    Element: (row: any): JSX.Element => {
      return (
        <Box padding="2px 10px">
          <Typography className={styles.Widgets__createdOn}>
            {`${moment(row.createdOn * 1000).format('MM/DD/YYYY - hh:mm A')}`}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'settings',
    label: 'Settings',
    custom: true,
    align: 'left' as AlignType,
    Element: (row: any): JSX.Element => {
      const { handleSettings, ...duckCompany } = row
      return (
        <Button
          onClick={() => { row.handleSettings(duckCompany) }}
          variant="containedLight"
          className={styles.Widgets__viewButton}
          color="primary"
          style={{
            width: '107px',
            height: '46px'
          }}
          startIcon={<SettingsIcon color='inherit' fontSize='small' />}
        >
          Settings
        </Button>
      )
    }
  },
]

export default headers
