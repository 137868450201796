import { FC } from 'react'
import { Link as RouteLink } from 'react-router-dom'
import { isEmpty } from '../../../../../helpers'
import { Typography, Link } from '../../../../UI'
import useStyles from './styles'
import { BottomLinkProps } from './types'

const BottomLink: FC<BottomLinkProps> = ({ question, linkText, linkTo, onClick, href, target }) => {
  const classes = useStyles()

  return (
    <div className={classes.center}>
      <Typography className={classes.question} variant='body2'>
        {question}
        {linkTo
          ? (
            <RouteLink
              className={classes.actionLink}
              to={linkTo}
            >
              {linkText}
            </RouteLink>)
          : (
            <Link
              className={classes.actionLink}
              href={href || ''}
              target={target || ''}
              onClick={(event) => {
                if (onClick) onClick()
                else if (isEmpty(href)) event.preventDefault()
              }}
            >
              {linkText}
            </Link>)}
      </Typography>
    </div>
  )
}

export default BottomLink
