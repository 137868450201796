import axios from '../axios'
import { ApiController } from './types'
import { formatParams } from 'src/helpers'

const base = '/employees'

const apiController: ApiController = {
  getEmployees: async ({ start, limit, searchParams }) => {
    const params = formatParams(searchParams)
    return await axios
      .get(`${base}?${params}`, { params: { start, limit } })
      .then(response => response.data)
  },

  getCurrentEmployee: async () =>
    await axios
      .get(`${base}/me`)
      .then(response => response.data),

  createEmployee: async (request) =>
    await axios
      .post(`${base}/`, request)
      .then(response => response.data),

  updateEmployee: async (id: string, request) =>
    await axios
      .patch(`${base}/${id}`, request)
      .then(response => response.data),

  deleteEmployee: async (id: string) =>
    await axios
      .delete(`${base}/${id}`)
      .then(response => response.data),

  getEmployeeId: async (id: string) =>
    await axios
      .get(`${base}/${id}`)
      .then(response => response.data)
}

export default apiController
