import { Button, CircularProgress, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { FC, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ActionsButton, TextFieldLabel } from 'src/components/UI'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import Modal from 'src/components/UI/CustomUI/molecules/Modal'
import { getJobsCSV, getSearches } from 'src/ducks/selectors'
import Folder from '../Folder'
import { jobsActions, searchesActions } from 'src/ducks/actions'
import { RESOURCE_TYPE } from 'src/helpers'
import { ActionButtonsProps } from './types'
import ExportCSV from 'src/components/UI/CustomUI/molecules/ExportCSV'
import { CSVLink } from 'react-csv'
import { icons } from 'src/assets'

const ActionButtons: FC<ActionButtonsProps> = ({ pageLoading, handleJobSearch, resetFilters, searchParams }) => {
  const [openModal, setOpenModal] = useState(false)
  const [folderSelected, setFolderSelected] = useState(0)
  const [buttonLoading, setButtonLoading] = useState(false)
  const [searchName, setSearchName] = useState('')
  const [loadingCSV, setLoadingCSV] = useState(false)
  const downloadRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);

  const searches = useSelector(getSearches())
  const jobsCSV = useSelector(getJobsCSV)
  const dispatch = useDispatch()

  const handleCreateSearch = () => {
    setButtonLoading(true)
    if (!searchName) return
    const request = {
      folderSelected,
      searchName
    }
    dispatch(
      searchesActions.createSearch(request, null, RESOURCE_TYPE.JOB, // if searchInfo is null or undefined, we will send search screen params by default
        (success) => {
          dispatch(searchesActions.cleanSearchParams())
          dispatch(searchesActions.fetchSearches(RESOURCE_TYPE.JOB))
          if (success) {
            setOpenModal(false)
            setButtonLoading(false)
            setSearchName('')
          }
        })
    )
  }

  const downloadCSV = (event: any) => {
    setLoadingCSV(true)
    dispatch(jobsActions.downloadCSV({
      searchParams: { ...searchParams, includeArchived: true }
    }, (succ: boolean) => {
      if (!succ)
        setLoadingCSV(false)
      downloadRef?.current?.link?.click()
    }))
  }

  return (
    <>
      {openModal &&
        <Modal
          setOpen={setOpenModal}
          open={openModal}
          title="Let's save your search!"
        >
          <Grid container spacing={2} width='fit-content'>
            <Grid item flex={1}>
              <TextFieldLabel
                labelVariant='h6'
                label='Name your search'
                placeholder='Saved Search name'
                value={searchName}
                onChange={(e) => setSearchName(e.target.value)}
              />
            </Grid>
            {searches.folders.length
              ? <Grid item container direction='column'>
                <Typography variant='h6'>Select a folder to save your Search</Typography>
                <RadioGroup
                  value={folderSelected}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFolderSelected(parseInt(event.target.value))}
                >
                  {searches.folders.map((folder, index) =>
                    <FormControlLabel
                      value={index}
                      control={<Radio />}
                      label={<Folder key={index} folder={folder} showSearches={false} />}
                    />
                  )}
                </RadioGroup>

              </Grid>
              : <Grid item>
                <Typography variant='h5' color={(theme) => theme.palette.gray[700]}>
                  No folder created.
                </Typography>
              </Grid>}
            <Grid container item spacing={1} direction='column'>
              <Grid item>
                <Button
                  variant='containedBig'
                  sx={{ width: '100%' }}
                  onClick={handleCreateSearch}
                  disabled={!searchName}
                >
                  {buttonLoading ? <CircularProgress color='info' size="1.4rem" /> : 'Save Changes'}
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={() => setOpenModal(false)} variant='outlinedBig' sx={{ width: '100%' }}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Modal>}
      <Grid container justifyContent='flex-end' spacing={2} width='fit-content'>
        <Grid item>
          <Button variant='containedBig' onClick={handleJobSearch} disabled={pageLoading}>
            Search
            <Icon name='Search' sx={{ margin: '5px' }} />
          </Button>
        </Grid>
        <Grid item>
          <Button variant='outlinedBig' onClick={() => setOpenModal(true)}>
            Save Search
            <Icon name='Save' sx={{ margin: '5px' }} />
          </Button>
        </Grid>
        <Grid item>
          <Button variant='containedLightBig' onClick={resetFilters}>
            Reset
            <Icon name='Refresh' sx={{ margin: '5px' }} />
          </Button>
        </Grid>
        <Grid item alignItems='center' justifyContent="center">
          <Box display='flex' alignItems='center' justifyContent="center">
            <ActionsButton
              loading={loadingCSV}
              icon={<icons.CloudDownload color='disabled' />}
              iconPosition='end'
              onClick={downloadCSV}
              sx={{
                minWidth: '45px!important',
                minHeight: '45px!important',
                '& .MuiBox-root': {
                  height: '45px!important'
                },
              }}
            />
            <ExportCSV
              innerRef={downloadRef}
              headers={jobsCSV?.slice(0, jobsCSV?.search('\n'))?.split(',')?.map((h: any) => h)}
              data={jobsCSV.slice(jobsCSV.search('\n') + 1)}
              fileName={'jobs.csv'}
              label=''
            />
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default ActionButtons
