/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo } from '../../helpers'
import { Punchlist } from '../../api'
import { repairItemActions, repairListActions } from '../actions'
import {
  repairItemTypes,
  FetchRepairItemAction,
  PatchRepairItemAction,
  PatchPriceItemAction,
  DeleteRepairItemAction,
  FetchPriceItemAction,
  PatchAllPriceItemsAction,
  PatchPriceItemsAction,
  CreateRepairItemAction,
} from './types'
import { getRepairItemId } from './selectors'
import { UpdateRequest } from '~api/types'
import { PriceItemType, RepairListItem } from '../types'

export function* fetchRepairItem({
  payload,
  callback,
}: FetchRepairItemAction): SagaIterator {
  let success = false
  try {
    const id = payload

    const item = yield call(Punchlist.repairList.getRepairItem, { id })

    if (item) {
      yield put(repairItemActions.setRepairItem(item))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the repair item'))
    if (callback) yield call(callback, false)
  }
}

export function* fetchPriceList({
  payload,
  callback,
}: FetchRepairItemAction): SagaIterator {
  let success = false
  try {
    const id = payload

    const priceItems = yield call(Punchlist.repairList.getPriceList, { id })

    if (priceItems) {
      yield put(repairItemActions.setPriceList(priceItems))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the price items'))
    if (callback) yield call(callback, false)
  }
}

export function* fetchPriceItem({
  payload,
  callback,
}: FetchPriceItemAction): SagaIterator {
  let success = false
  try {
    const { repairItemId, territoryId } = payload

    const priceItem = yield call(Punchlist.repairList.getTerritoryPriceItem, { repairItemId, territoryId })

    if (priceItem) {
      yield put(repairItemActions.setPriceItem(priceItem))
    }
    success = true
    if (callback) yield call(callback, success, priceItem)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the price item'))
    if (callback) yield call(callback, false)
  }
}

export function* createRepairItem({
  payload,
  callback
}: CreateRepairItemAction): SagaIterator {
  let succ = false
  try {
    const newRepairItem = yield call(Punchlist.repairList.createRepairItem, payload)

    if (newRepairItem) {
      yield put(repairItemActions.setRepairItem(newRepairItem))
    }

    succ = true
    if (callback) yield call(callback, succ, newRepairItem)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('create a repair item'))
    if (callback) yield call(callback, false)
  }
}

export function* patchRepairItem({
  payload,
  callback,
}: PatchRepairItemAction): SagaIterator {
  let success = false
  const id = yield select(getRepairItemId)
  const request: UpdateRequest = []

  for (const attr in payload) {
    request.push({
      op: 'add',
      path: '/' + attr,
      value: payload[attr as keyof RepairListItem],
    })
  }

  try {
    const item = yield call(Punchlist.repairList.updateRepairItem, {
      id: id || '',
      request,
    })

    if (item) {
      yield put(repairItemActions.setRepairItem(item))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('update the repair item'))
    if (callback) yield call(callback, false)
  }
}

export function* patchPriceItem({
  payload,
  callback,
}: PatchPriceItemAction): SagaIterator {
  let success = false
  const id = yield select(getRepairItemId)
  const request: UpdateRequest = []

  for (const attr in payload.request) {
    request.push({
      op: 'add',
      path: '/' + attr,
      value: payload.request[attr as keyof PriceItemType],
    })
  }

  try {
    const item = yield call(Punchlist.repairList.updatePriceItem, {
      repairItemId: id || '',
      territoryId: payload.territoryId,
      request,
    })

    if (item) {
      const priceItems = yield call(Punchlist.repairList.getPriceList, { id })

      if (priceItems) {
        yield put(repairItemActions.setPriceList(priceItems))
      }
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('update the price item'))
    if (callback) yield call(callback, false)
  }
}

export function* patchAllPriceItems({
  payload,
  callback
}: PatchAllPriceItemsAction): SagaIterator {
  let success = false
  const id = yield select(getRepairItemId)
  const request: UpdateRequest = []

  for (const attr in payload) {
    request.push({
      op: 'add',
      path: '/' + attr,
      value: payload[attr as keyof PriceItemType],
    })
  }

  try {
    const item = yield call(Punchlist.repairList.updateAllPriceItems, {
      repairItemId: id || '',
      request,
    })

    if (item) {
      const priceItems = yield call(Punchlist.repairList.getPriceList, { id })

      if (priceItems) {
        yield put(repairItemActions.setPriceList(priceItems))
      }
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('update all price items'))
    if (callback) yield call(callback, false)
  }
}

export function* patchPriceItems({
  payload,
  callback
}: PatchPriceItemsAction): SagaIterator {
  let success = false
  const id = yield select(getRepairItemId)
  const request: UpdateRequest = []

  for (const attr in payload.request) {
    request.push({
      op: 'add',
      path: '/' + attr,
      value: payload.request[attr as keyof PriceItemType],
    })
  }

  try {
    const item = yield call(Punchlist.repairList.updatedSelectedPriceItems, {
      repairItemId: id || '',
      ids: payload.ids,
      request,
    })

    if (item) {
      const priceItems = yield call(Punchlist.repairList.getPriceList, { id })

      if (priceItems) {
        yield put(repairItemActions.setPriceList(priceItems))
      }
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('update selected price items'))
    if (callback) yield call(callback, false)
  }
}

export function* deleteRepairItem({
  payload,
  callback,
}: DeleteRepairItemAction): SagaIterator {
  let success = false
  try {
    const id = payload

    yield call(Punchlist.repairList.deleteRepairItem, { id })

    success = true

    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('delete the repair item'))
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(repairItemTypes.FETCH_REPAIRITEM, fetchRepairItem)
  yield takeLatest(repairItemTypes.FETCH_PRICELIST, fetchPriceList)
  yield takeLatest(repairItemTypes.FETCH_PRICEITEM, fetchPriceItem)
  yield takeLatest(repairItemTypes.CREATE_REPAIRITEM, createRepairItem)
  yield takeLatest(repairItemTypes.PATCH_REPAIRITEM, patchRepairItem)
  yield takeLatest(repairItemTypes.PATCH_PRICEITEM, patchPriceItem)
  yield takeLatest(repairItemTypes.PATCH_ALL_PRICEITEMS, patchAllPriceItems)
  yield takeLatest(repairItemTypes.PATCH_PRICEITEMS, patchPriceItems)
  yield takeLatest(repairItemTypes.DELETE_REPAIRITEM, deleteRepairItem)
}
