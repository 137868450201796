import { ActionsButton } from "src/components/UI"
import { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { WorkOrdersContext } from '../../context'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import { Item } from "src/ducks/types"
import { Action } from "src/components/UI/CustomUI/molecules/ActionsButton/types"
import { employeeIsAdmin, getEstimate, getEstimateId, getItemsSortedByCategory, getJobId, getOrderById, getOrders } from "src/ducks/selectors"
import { estimateActions, itemsActions, jobActions } from "src/ducks/actions"
import { ITEM_STATUS, ITEM_CATEGORY, ORDER_STATUS, ESTIMATE_STATUS } from "src/helpers"
import { UpdateBatchPayload } from "src/ducks/items/types"
import { OrderType } from "src/ducks/orders/types"
import { MODAL_TYPE } from "../../context/types"
import { useParams } from "react-router-dom"

// const ORDER_STATUS_TO_COMPLETE = [
//   ORDER_STATUS.DISPATCHED, ORDER_STATUS.REVIEW, ORDER_STATUS.COMPLETE,
//   ORDER_STATUS.INVOICED, ORDER_STATUS.PAID, ORDER_STATUS.IN_PROGRESS,
//   ORDER_STATUS.APPROVED
// ]

const BatchActions = ({ items, defaultActions, defaultTitle, defaultIcon, setLoading, tabName }: {
  items: Item[],
  defaultActions?: Action[],
  defaultTitle?: string,
  defaultIcon?: any,
  setLoading?: any,
  tabName?: string
}) => {
  const { dispatch: dispatchContext, state: { selectedOrderId, selectedTab } } = useContext(WorkOrdersContext)
  const dispatch = useDispatch()
  const estimateId = useSelector(getEstimateId())
  const estimate = useSelector(getEstimate())
  const { id } = useParams()
  const actions: Action[] = []
  const itemsNotCompleted = items
  const itemsCount = itemsNotCompleted.length
  const itemsText = `${itemsCount} item${itemsCount === 1 ? '' : 's'}`
  const itemsIds = itemsNotCompleted.map((item: Item) => item.itemId)
  const withSiteVisit = itemsNotCompleted.some((item: Item) => item.siteVisitId)

  const approvedItems = itemsNotCompleted.filter((item: Item) => item.status === ITEM_STATUS.APPROVED)
  const approvedItemsCount = approvedItems.length
  const approvedItemsText = `${approvedItemsCount} item${approvedItemsCount === 1 ? '' : 's'}`
  const approvedItemsIds = approvedItems.map((item: Item) => item.itemId)

  const newItems = itemsNotCompleted.filter((item: Item) => item.status === ITEM_STATUS.NEW)
  const newItemsCount = newItems.length
  const newItemsText = `${newItemsCount} item${newItemsCount === 1 ? '' : 's'}`
  const newItemsIds = newItems.map((item: Item) => item.itemId)

  const itemsToApprove = itemsNotCompleted.filter((item: Item) => item.status === ITEM_STATUS.COMPLETE)
  const itemsToReopen = itemsToApprove.length
  const itemsToReopenText = `${itemsToReopen} item${itemsToReopen === 1 ? '' : 's'}`
  const itemsToReopenIds = itemsToApprove.map((item: Item) => item.itemId)

  const rejectedItems = itemsNotCompleted.filter((item: Item) => item.status === ITEM_STATUS.REJECTED)
  const rejectedItemsCount = rejectedItems.length
  const rejectedItemsText = `${rejectedItemsCount} item${rejectedItemsCount === 1 ? '' : 's'}`
  const rejectedItemsIds = rejectedItems.map((item: Item) => item.itemId)

  const toCompleteItems = itemsNotCompleted.filter((item: Item) => {
    return item.status === ITEM_STATUS.APPROVED
  })

  const toCompleteItemsCount = toCompleteItems.length
  // const toCompleteItemsText = `${toCompleteItemsCount} item${toCompleteItemsCount === 1 ? '' : 's'}`
  // const toCompleteItemsIds = toCompleteItems.map((item: Item) => item.itemId)

  const completeItems = itemsNotCompleted.filter((item: Item) => item.status === ITEM_STATUS.COMPLETE)
  const completeItemsCount = completeItems.length
  const completeItemsText = `${completeItemsCount} item${completeItemsCount === 1 ? '' : 's'}`
  const completeItemsIds = completeItems.map((item: Item) => item.itemId)

  const acceptedItems = itemsNotCompleted.filter((item: Item) => item.status === ITEM_STATUS.ACCEPTED)
  const acceptedItemsCount = acceptedItems.length
  const acceptedItemsText = `${acceptedItemsCount} item${acceptedItemsCount === 1 ? '' : 's'}`
  const acceptedReopenItemsText = `${acceptedItemsCount} work order item${acceptedItemsCount === 1 ? '' : 's'}`
  const acceptedItemsIds = acceptedItems.map((item: Item) => item.itemId)
  const itemsByCategory = useSelector(getItemsSortedByCategory())

  const isAdmin = employeeIsAdmin()



  const UncheckAllWorkItems = () => {

    dispatch({
      type: 'SET_WORK_ITEMS', payload: itemsByCategory.map((item: any) => {
        item.items.forEach((subitem: any) => {
          subitem.checked = false
          return subitem
        })
        return item
      })
    })

    dispatchContext({ type: 'TOGGLE_CHECK_ALL', payload: false })

  }

  const UpdateBatch = (payload: UpdateBatchPayload) => {
    if (selectedOrderId) {
      payload['selectedOrderId'] = selectedOrderId
    }

    dispatchContext({ type: 'SET_ITEM_VALUES_LOADING', payload: true })

    dispatch(itemsActions.updateBatch(payload, (succ) => {
      dispatchContext({ type: 'SET_ITEM_VALUES_LOADING', payload: false })
      if (succ) {
        UncheckAllWorkItems()

        dispatch(itemsActions.fetchItemsByCategory({
          params: { jobId: id, orderId: payload.selectedOrderId }
        }, (succ) => {
        }))
      }
    }))
  }

  const separateIntoNewJob = () => {
    setLoading(true)
    dispatch(
      estimateActions.cloneEstimate({ id: estimateId || '', shallow: true }, (succ, newEstimate) => {
        if (succ) {
          dispatch(
            itemsActions.moveItemtoEstimateBatch({
              itemsIds: items.map((item) => item.itemId),
              partialItem: { jobId: newEstimate.id || '' },
              selectedOrderId: selectedOrderId || '',
            }, (succ) => {
              if (succ) window.open(newEstimate.id || '')
              setLoading(false)
            })
          )
        } else {
          setLoading(false)
        }
      })
    )
  }

  const MoveBatch = (payload: UpdateBatchPayload) => {
    if (selectedOrderId) {
      payload['selectedOrderId'] = selectedOrderId
    }

    dispatchContext({ type: 'SET_ITEM_VALUES_LOADING', payload: true })

    dispatch(itemsActions.moveItemBatch(payload, (succ) => {
      dispatchContext({ type: 'SET_ITEM_VALUES_LOADING', payload: false })
      if (succ) { UncheckAllWorkItems() }
    }))
  }

  if (newItemsCount > 0) {
    actions.push({
      label: `Reject ${newItemsText}`,
      icon: <Icon color='lightGray' name='NotInterested' />,
      onClick: () => { UpdateBatch({ itemsIds: newItemsIds, partialItem: { status: ITEM_STATUS.REJECTED }, id: estimateId }) }
    })
  }


  if (rejectedItemsCount > 0) {
    actions.push({
      label: `Re-add ${rejectedItemsText}`,
      icon: <Icon color='lightGray' name='Add' />,
      onClick: () => { UpdateBatch({ itemsIds: rejectedItemsIds, partialItem: { status: ITEM_STATUS.NEW }, id: estimateId }) }
    })
  }

  if (itemsCount > 0) {
    actions.push({
      label: `Exclude ${itemsText}`,
      icon: <Icon color='lightGray' name='BrowserNotSupported' />,
      onClick: () => { UpdateBatch({ itemsIds, partialItem: { category: ITEM_CATEGORY.EXCLUDED }, id: estimateId }) }
    })
  }

  if (approvedItemsCount > 0 || newItemsCount > 0 || toCompleteItemsCount > 0 ||
    completeItemsCount > 0 || acceptedItemsCount > 0) {
    actions.push({ isDivider: true })
  }

  if (approvedItemsCount > 0) {
    actions.push({
      label: `Unapprove ${approvedItemsText}`,
      icon: <Icon color='lightGray' name='NotInterested' />,
      onClick: () => { UpdateBatch({ itemsIds: approvedItemsIds, partialItem: { status: ITEM_STATUS.NEW }, id: estimateId }) }
    })
  }

  if (newItemsCount > 0) {
    actions.push({
      label: `Approve ${newItemsText} for Client`,
      icon: <Icon color='lightGray' name='Done' />,
      onClick: () => { UpdateBatch({ itemsIds: newItemsIds, partialItem: { status: ITEM_STATUS.APPROVED }, id: estimateId }) }
    })
    actions.push({
      label: `Delete ${newItemsText}`,
      icon: <Icon color='lightGray' name='DeleteOutlined' />,
      onClick: () => {
        dispatchContext({ type: 'SET_SELECTED_ITEMS_IDS', payload: newItemsIds })
        dispatchContext({ type: 'SET_SELECTED_SITE_VISIT_ID', payload: null })
        dispatchContext({ type: 'SET_MODAL_OPEN', payload: true })
        dispatchContext({ type: 'SET_MODAL_TYPE', payload: 'DELETE_MANY' })
      }
    })
  }

  if (itemsNotCompleted.length >= 2 && ["NEW", "IN_REVIEW", "COMPOSING"].includes(estimate?.status || '')) {
    actions.push({
      label: `Combine ${itemsNotCompleted.length} Item(s)`,
      icon: <Icon color='lightGray' name='JoinFull' />,
      onClick: () => {
        dispatchContext({ type: 'SET_SELECTED_ITEMS_IDS', payload: itemsIds })
        dispatchContext({ type: 'SET_SELECTED_SITE_VISIT_ID', payload: null })
        dispatchContext({ type: 'SET_MODAL_OPEN', payload: true })
        dispatchContext({ type: 'SET_MODAL_TYPE', payload: MODAL_TYPE.COMBINE_ITEMS })
      }
    })
  }

  // if (toCompleteItemsCount > 0) {
  //   actions.push({
  //     label: `Mark ${toCompleteItemsText} Complete`,
  //     icon: <Icon color='lightGray' name='DoneAll' />,
  //     onClick: () => { UpdateBatch({ itemsIds: toCompleteItemsIds, partialItem: { status: ITEM_STATUS.COMPLETE }, id: estimateId }) }
  //   })
  // }

  // if (itemsToReopen > 0) {
  //   actions.push({
  //     label: `Re-open ${itemsToReopenText}`,
  //     icon: <Icon color='lightGray' name='Folder' />,
  //     onClick: () => {
  //       UpdateBatch({ itemsIds: itemsToReopenIds, partialItem: { status: ITEM_STATUS.APPROVED }, id: estimateId })
  //     }
  //   })
  // }

  // if (completeItemsCount > 0) {
  //   actions.push({
  //     label: `Accept ${completeItemsText}`,
  //     icon: <Icon color='lightGray' name='ThumbUpOutlined' />,
  //     onClick: () => { UpdateBatch({ itemsIds: completeItemsIds, partialItem: { status: ITEM_STATUS.ACCEPTED }, id: estimateId }) }
  //   })
  // }

  // if (acceptedItemsCount > 0) {
  //   actions.push({
  //     label: `Unaccept ${acceptedItemsText}`,
  //     icon: <Icon color='lightGray' name='ThumbDownOutlined' />,
  //     onClick: () => { UpdateBatch({ itemsIds: acceptedItemsIds, partialItem: { status: ITEM_STATUS.COMPLETE }, id: estimateId }) }
  //   })
  //   actions.push({
  //     label: `Re-open ${acceptedReopenItemsText}`,
  //     icon: <Icon color='lightGray' name='FolderOpen' />,
  //     onClick: () => {
  //       UpdateBatch({ itemsIds: acceptedItemsIds, partialItem: { status: ITEM_STATUS.APPROVED }, id: estimateId })
  //     }
  //   })
  // }



  if (itemsCount > 0 && !withSiteVisit) {
    actions.push({ isDivider: true })
    actions.push({
      label: 'Dispatch Site Visit',
      icon: <Icon color='lightGray' name='CalendarToday' />,
      onClick: () => {
        dispatchContext({ type: 'SET_SELECTED_ITEMS_IDS', payload: itemsIds })
        dispatchContext({ type: 'SET_SELECTED_VENDOR', payload: null })
        dispatchContext({ type: 'SET_SELECTED_FIELD_MANAGER', payload: null })
        dispatchContext({ type: 'SET_SELECTED_SITE_VISIT_ID', payload: null })
        dispatchContext({ type: 'SET_MODAL_OPEN', payload: true })
        dispatchContext({ type: 'SET_MODAL_TYPE', payload: 'SITE_VISIT' })
      }
    })
    if (tabName !== 'All Orders' && isAdmin) {
      actions.push({
        label: 'Separate into new Job',
        icon: <Icon color='lightGray' name='ViewAgenda' />,
        onClick: separateIntoNewJob
      })
    }
  }

  return (
    itemsNotCompleted.length > 0 || selectedTab !== 0 ? <ActionsButton
      actions={defaultActions ?? actions}
      variant='outlined'
      text={defaultTitle ? defaultTitle : 'Actions'}
      icon={defaultIcon ? defaultIcon : <Icon name='FormatListBulleted' />}
    /> : null
  )
}

export default BatchActions
