import { Box, Grid, Typography } from 'src/components/UI'
import ShowMore from 'src/components/UI/CustomUI/atoms/ShowMore'
import Tag from 'src/components/UI/CustomUI/atoms/Tag'
import {
  AlignType,
  HeaderType,
} from 'src/components/UI/CustomUI/organisms/Table/types'
import { formatTimestamp } from 'src/helpers'
import { initLicenseType } from './FilterHeader/constants'
import { reviewStatusColors } from './constants'

const applyRegExp = (phone: string): string => {
  if (!phone) return ''

  return String(phone).replace(/^(\d{3})(\d{3})(\d{4})$/g, '+1 $1-$2-$3')
}

const licenseHeaders: HeaderType[] = [
  {
    id: 'companyName',
    label: 'Company',
    custom: true,
    align: 'left' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Box>
          <Typography fontWeight={600}>{row?.companyName}</Typography>
        </Box>
      )
    },
  },
  {
    id: 'pro',
    label: 'Pro',
    custom: true,
    align: 'left' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Box>
          <Typography fontWeight={600}>{row?.contactName}</Typography>
        </Box>
      )
    },
  },
  {
    id: 'phone',
    label: 'Phone',
    custom: true,
    align: 'left' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Box>
          <Typography>{applyRegExp(row?.phone)}</Typography>
        </Box>
      )
    },
  },
  { id: 'email', label: 'Email' },
  {
    id: 'territoryList',
    label: 'Territories',
    custom: true,
    maxWidth: 140,
    Element: (row: any): JSX.Element => {
      const territories = row?.territories?.filter((territory: any) => territory.title)?.reduce(
        (acc: any, curr: any) =>
          acc.concat(`${curr.title}`),
        []
      )

      return (
        <Box display="flex" flexDirection="column" gap={1}>
          <ShowMore
            limit={3}
            buttonSx={{ width: '100%' }}
            tooltipContent={
              <Box gap={1}>
                {territories?.map((territory: any) => (
                  <Typography key={territory}>{territory}</Typography>
                ))}
              </Box>
            }
          >
            {territories?.map((territory: any) => (
              <Tag width="100%" center>
                {territory}
              </Tag>
            ))}
          </ShowMore>
        </Box>
      )
    },
  },
  {
    id: 'uploadedOn',
    label: 'Uploaded Date',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Box>
          <Typography>
            {formatTimestamp(row?.license?.uploadedOn, 'MM/DD/YYYY')}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'licenseType',
    label: 'License Type',
    custom: true,
    maxWidth: 120,
    Element: (row: any): JSX.Element => {
      return (
        <Box display="flex" flexDirection="column" gap={1} padding="15px 20px">
          <Tag
            width="100%"
            center
            sx={{ lineHeight: '18px', minWidth: '80px !important' }}
            fontWeight={600}
          >
            {
              initLicenseType?.find(
                (filter) => filter.value === row?.license?.licenseType
              )?.label
            }
          </Tag>
        </Box>
      )
    },
  },
  {
    id: 'validFromDate',
    label: 'Valid From Date',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Grid>
          <Typography>
            {formatTimestamp(row?.license?.validFromDate, 'MM/DD/YYYY')}
          </Typography>
        </Grid>
      )
    },
  },
  {
    id: 'validToDate',
    label: 'Valid To Date',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Grid>
          <Typography>
            {formatTimestamp(row?.license?.validToDate, 'MM/DD/YYYY')}
          </Typography>
        </Grid>
      )
    },
  },
  {
    id: 'reviewStatus',
    label: 'Review Status',
    custom: true,
    maxWidth: 120,
    Element: (row: any): JSX.Element => {
      const { status, ...customStyle } =
        reviewStatusColors?.find((item) => item.status === row?.reviewStatus) ||
        {}

      return (
        <Box display="flex" flexDirection="column" gap={1} padding="15px 20px">
          <Tag
            width="100%"
            center
            {...customStyle}
            hasShadow={true}
            sx={{ lineHeight: '18px', minWidth: '80px !important' }}
            fontWeight={600}
          >
            {row?.license?.reviewStatus}
          </Tag>
        </Box>
      )
    },
  },
]

export default licenseHeaders
