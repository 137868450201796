import axios from '../axios'
import { ApiController } from './types'

const base = '/history'

const apiController: ApiController = {
  getHistoryJob: async (jobId: string) =>
    await axios
      .get(`${base}/jobs/${jobId}`)
      .then(response => response.data),
  getHistoryJobStatus: async (jobId: string) =>
    await axios
      .get(`${base}/jobs/${jobId}/status`)
      .then(response => response.data),
  getHistoryJobOrders: async (jobId: string) =>
    await axios
      .get(`${base}/orders/all?jobId=${jobId}`)
      .then(response => response.data),
  getHistoryOrder: async (orderId: string) =>
    await axios
      .get(`${base}/orders/${orderId}`)
      .then(response => response.data),
  getCheckInHistory: async (jobId: string, orderId: string) =>
    await axios
      .get(`${base}/jobs/${jobId}/checkin?orderId=${orderId}`)
      .then(response => response.data),
  getHistoryJobTask: async (jobId: string) =>
    await axios
      .get(`${base}/jobs/${jobId}/task`)
      .then(response => response.data),
  getHistoryRemovedItems: async (jobId: string) =>
    await axios
      .get(`${base}/jobs/${jobId}/removed-items`)
      .then(response => response.data),
  getHistoryEstimates: async (estimateId: string, itemTitle, event) =>
    await axios
      .get(`${base}/estimates/${estimateId}?itemTitle=${itemTitle}&event=${event}`)
      .then(response => response.data),
}

export default apiController
