/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useCallback, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { PrivateLoader } from 'src/components/templates'
import BackButton from 'src/components/UI/CustomUI/atoms/BackButton'
import TabNavigation from 'src/components/UI/CustomUI/organisms/TabNavigation'
import { vendorActions } from 'src/ducks/actions'
import { getVendor, getVendorData, getVendorStatics } from 'src/ducks/selectors'
import { ActionsButton, Box, Grid, Typography } from '../../../UI'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import Contact from '../Pros/Tabs/Contact'
import Documents from '../Pros/Tabs/Documents'
import Payments from '../Pros/Tabs/Payments'
import Trades from '../Pros/Tabs/Trades'
import Territories from '../Pros/Tabs/Territories'
import Jobs from '../Pros/Tabs/Jobs'
import Admin from '../Pros/Tabs/Admin'
import { VendorContext } from '../Pros/context'
import ContactInfo from './ContactInfo'
import Organization from '../Pros/Tabs/Organization'

const ProsView: FC = () => {
  const dispatch = useDispatch()
  const [pageLoading, setPageLoading] = useState(true)
  const vendor = useSelector(getVendor())
  const { hasChanges, handleSave, loading } = useContext(VendorContext)
  const { id } = useParams()

  const userData = useSelector(getVendorData())
  const userStatsPros = useSelector(getVendorStatics())

  const fetchVendor = useCallback(() => {
    if (id) {
      dispatch(
        vendorActions.fetchVendor(id, (_succ: boolean) => {
          setPageLoading(false)
        })
      )
    }
  }, [])

  useEffect(() => {
    fetchVendor()
  }, [])

  const TABS = [
    {
      label: 'Contact',
      content: <Contact />
    },
    {
      label: 'Documents',
      content: <Documents />
    },
    {
      label: 'Payments',
      content: <Payments />
    },
    {
      label: 'Trades',
      content: <Trades />
    },
    {
      label: 'Territories',
      content: <Territories />
    },
    {
      label: 'Jobs',
      content: <Jobs />
    },
    {
      label: 'Admin',
      content: <Admin />
    },
    {
      label: 'Organization',
      content: <Organization />
    }
  ]

  return (
    <PrivateLoader loading={pageLoading}>
      <Box display='flex' flexDirection='column' gap={2}>
        <Box display='flex' justifyContent='space-between'>
          <BackButton />
          <Typography variant='h4' width='180px'>Total Paid: ${vendor?.earnedTotal}</Typography>
        </Box>
        <Grid container spacing={2} marginBottom={3}>
          <Grid item xs={3}>
            <ContactInfo userData={userData} userStatsPros={userStatsPros} />
          </Grid>
          <Grid item xs={9}>
            <TabNavigation tabs={TABS} />
          </Grid>
        </Grid>
        <ActionsButton
          variant='contained'
          disabled={!hasChanges}
          sx={{
            position: 'fixed',
            height: '40px !important',
            width: '180px !important',
            bottom: '40px',
            right: '40px'
          }}
          onClick={handleSave}
          loading={loading}
          icon={<Icon name='Save' />}
          iconPosition='end'
          text='Save changes'
        />
      </Box>
    </PrivateLoader>
  )
}

export default ProsView
