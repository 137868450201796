/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo, isEmpty } from '../../helpers'
import { Punchlist } from '../../api'
import { subscriptionTypes, FetchSubscriptionAction, CreateJobFromSubscriptionAction, UpdateSubscriptionByPathAction, DeleteSubscriptionAction } from './types'
import { subscriptionActions } from '../actions'
import { getSubscription } from './selectors'

export function* fetchSubscription({ payload, callback }: FetchSubscriptionAction): SagaIterator {
  let success = false
  try {
    const subscription = yield call(Punchlist.subscriptions.getSubscriptionById, payload)
    if (subscription) {
      yield put(subscriptionActions.setSubscription(subscription))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the subscription'))
    if (callback) yield call(callback, false)
  }
}

export function* updateSubscriptionByPath({ payload, callback }: UpdateSubscriptionByPathAction): SagaIterator {
  let success = false
  try {
    const { id } = yield select(getSubscription())
    const subscription = yield call(Punchlist.subscriptions.updateSubscription, id, payload)

    if (!isEmpty(subscription)) {
      yield put(subscriptionActions.setSubscription(subscription))
      success = true
    }
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('update the subscription'))
    if (callback) yield call(callback, false)
  }
}

export function* createJobFromSubscription({ payload, callback }: CreateJobFromSubscriptionAction): SagaIterator {
  let success = false
  try {
    const { subscriptionId, title } = payload
    const data = yield call(Punchlist.subscriptions.createJobFromSubscription, subscriptionId, { title })

    if (data) {
      success = true
    } else {
      yield call(toast.error, errorTextTryingTo('create the job'))
    }
    if (callback) yield call(callback, success, data)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('create the job'))
    if (callback) yield call(callback, false, null)
  }
}


export function* deleteSubscription({ payload, callback }: DeleteSubscriptionAction): SagaIterator {
  let success = false
  try {
    const { subscriptionId } = payload
    const data = yield call(Punchlist.subscriptions.deleteSubscription, subscriptionId)
    if (data.status === 204 || data.status === 200) {
      success = true
    } else {
      yield call(toast.error, errorTextTryingTo('delete the sub'))
    }
    if (callback) yield call(callback, success)
  } catch (error) {

    yield call(toast.error, errorTextTryingTo('delete the sub'))
    if (callback) yield call(callback, false)
  }
}
export default function* saga(): SagaIterator {
  yield takeLatest(subscriptionTypes.FETCH_SUBSCRIPTION, fetchSubscription)
  yield takeLatest(subscriptionTypes.DELETE_SUBSCRIPTION, deleteSubscription)
  yield takeLatest(subscriptionTypes.CREATE_JOB_FROM_SUBSCRIPTION, createJobFromSubscription)
  yield takeLatest(subscriptionTypes.UPDATE_SUBSCRIPTION, updateSubscriptionByPath)
}
