import { FC } from 'react'

import { Box, Button, DialogContent, Typography } from '@mui/material'
import useStyles from './styles'
import { OPTIONS } from '../..'
import { contact, faq, icons, questions } from 'src/assets'

const Initial: FC<{ showFAQ: boolean, handleClick: (option: OPTIONS) => void }> = ({ showFAQ, handleClick }) => {
  const styles = useStyles()
  return (
    <Box>
      <Typography variant="h5" className={`${styles.text} ${styles.header}`}>
        Contact us
      </Typography>
      <DialogContent className={styles.boxes}>
        {showFAQ && <Box className={styles.box} onClick={() => handleClick(OPTIONS.faq)}>
          <Button className={styles.radioButton}>
            <icons.RadioButtonUnchecked htmlColor='#F5F6F7' />
          </Button>
          <img src={faq} alt="FAQ" />
          <Typography className={styles.title}>Check out our FAQs</Typography>
          <Typography className={styles.subtitle}>See if our FAQs have answers to your questions.</Typography>
        </Box>}
        <Box className={styles.box} onClick={() => handleClick(OPTIONS.expert)}>
          <Button className={styles.radioButton}>
            <icons.RadioButtonUnchecked htmlColor='#F5F6F7' />
          </Button>
          <img src={contact} alt="FAQ" />
          <Typography className={styles.title}>Speak with an Expert</Typography>
          <Typography className={styles.subtitle}>Connect with our team to discuss your questions.</Typography>
        </Box>
        <Box className={styles.box} onClick={() => handleClick(OPTIONS.questions)}>
          <Button className={styles.radioButton}>
            <icons.RadioButtonUnchecked htmlColor='#F5F6F7' />
          </Button>
          <img src={questions} alt="FAQ" />
          <Typography className={styles.title}>Ask us a Question</Typography>
          <Typography className={styles.subtitle}>Submit your questions, and our team will reply via email, text, or a call.</Typography>
        </Box>
      </DialogContent>
    </Box>

  )
}

export default Initial