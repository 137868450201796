import { UpdateRequest } from "~api/types";
import { Contact, EstimateItem, EstimateProperty, Item } from "../types";
import { EstimateActions, ShareEstimateRequest, estimateTypes } from "./types";
import { ESTIMATE_STATUS } from "src/helpers";

const actions = {
    fetchEstimate: (payload: string, callback?: (succ: boolean) => void): EstimateActions => ({
        type: estimateTypes.FETCH_ESTIMATE,
        payload,
        callback
    }),
    createEstimateContact: (payload: Contact, callback?: (succ: boolean) => void): EstimateActions => ({
        type: estimateTypes.CREATE_ESTIMATE_CONTACT,
        payload,
        callback
    }),
    createEstimate: (payload: Partial<EstimateItem>, callback?: (succ: boolean, estimateId: string | null) => void): EstimateActions => ({
        type: estimateTypes.CREATE_ESTIMATE,
        payload,
        callback
    }),
    submitEstimate: (payload: { estimatorId: string, additionalInfo?: string, newStatus: ESTIMATE_STATUS }, callback?: (succ: boolean) => void): EstimateActions => ({
        type: estimateTypes.SUBMIT_ESTIMATE,
        payload,
        callback
    }),
    cloneEstimate: (payload: { id: string, shallow?: boolean }, callback?: (succ: boolean, newEstimate: Partial<EstimateItem>) => void): EstimateActions => ({
        type: estimateTypes.CLONE_ESTIMATE,
        payload,
        callback
    }),
    updateEstimateProperty: (payload: Partial<EstimateProperty>, callback?: (succ: boolean, newProperties?: Partial<EstimateProperty>) => void): EstimateActions => ({
        type: estimateTypes.UPDATE_ESTIMATE_PROPERTY,
        payload,
        callback
    }),
    setEstimate: (payload: EstimateItem | null): EstimateActions => ({
        type: estimateTypes.SET_ESTIMATE,
        payload
    }),
    updateEstimateByPath: (payload: UpdateRequest, callback: (succ: boolean, estimateId?: string) => void): EstimateActions => ({
        type: estimateTypes.UPDATE_ESTIMATE_BY_PATH,
        payload,
        callback
    }),
    shareEstimate: (payload: Partial<ShareEstimateRequest>, callback?: (succ: boolean) => void): EstimateActions => ({
        type: estimateTypes.SHARE_ESTIMATE,
        payload,
        callback
    }),
    updateEstimateContact: (payload: { contact: Partial<Contact>, idx: number }, callback: (succ: boolean) => void): EstimateActions => ({
        type: estimateTypes.UPDATE_ESTIMATE_CONTACT,
        payload,
        callback
    }),
    ocrReprocessEstimate: (payload: string, callback?: (succ: boolean) => void): EstimateActions => ({
        type: estimateTypes.OCR_REPROCESS,
        payload,
        callback
    }),
    addItems: (payload: Item[], callback?: (succ: boolean) => void): EstimateActions => ({
        type: estimateTypes.ADD_ITEMS,
        payload,
        callback
    }),
    removeItems: (payload: Item[], callback?: (succ: boolean) => void): EstimateActions => ({
        type: estimateTypes.REMOVE_ITEMS,
        payload,
        callback
    }),
}

export default actions