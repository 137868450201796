import React from 'react'
import { Button, Menu, MenuItem, Typography } from '@mui/material'

import styles from './styles.module.scss'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import { FolderProps } from './types'
import { useDispatch } from 'react-redux'
import { searchesActions, jobsActions } from 'src/ducks/actions'
import { SearchParams } from 'src/ducks/searches/types'

const Folder = ({ folder, showSearches = true, setPageLoading }: FolderProps) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const openSearches = Boolean(anchorEl)
    const dispatch = useDispatch()

    const fetchJobs = (searchParams: SearchParams) => {
        const params = { ...searchParams, includeArchived: true }

        const paramsWithValues = Object.entries(params).reduce((acc: any, [key, value]) => {
            if (value) {
                acc[key] = value
            }
            return acc
        }, {})

        setPageLoading && setPageLoading(true)
        dispatch(
            jobsActions.fetchJobs({ searchParams: paramsWithValues }, (_succ: boolean) => {
                setPageLoading && setPageLoading(false)
            })
        )
    }

    return (
      <>
          <Button
              className={`${styles.Folder} ${!showSearches && styles.Folder__noClickable}`}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                    setAnchorEl(event.currentTarget)
                }}
              variant='text'
              aria-controls={openSearches ? 'basic-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={openSearches ? 'true' : undefined}
              sx={{ justifyContent: 'flex-start' }}
            >
              <Icon name='Folder' sx={{ margin: '5px' }} />
              <Typography className={styles.Folders__Folder__name}>{folder.name}</Typography>
            </Button>
          {showSearches && <Menu
              id='basic-menu'
              anchorEl={anchorEl}
              open={openSearches}
              onClose={() => setAnchorEl(null)}
              MenuListProps={{
                    'aria-labelledby': 'basic-button'
                }}
              className={styles.Folder__Menu}
                             >
              {folder.searches.length
                    ? folder.searches.map((search, index) => (
                      <MenuItem
                          key={index}
                          className={styles.Folder__MenuItem}
                          onClick={() => {
                                dispatch(searchesActions.setSearchParamValues(search.properties))
                                if (setPageLoading) {
                                    fetchJobs(search.properties)
                                }
                            }}
                        >
                          {search.name}
                        </MenuItem>
                    ))
                    : <MenuItem className={`${styles.Folder__MenuItem} ${styles['Folder__MenuItem--empty']}`}>Empty Folder</MenuItem>}
                             </Menu>}
        </>
    )
}

export default Folder
