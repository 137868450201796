/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import DatePicker from 'src/components/UI/CustomUI/atoms/DatePicker'
import { useDispatch, useSelector } from 'react-redux'
import {
  ActionsButton,
  Box,
  Button,
  FieldsFilter,
  Grid,
  IconButton,
  InputSearch,
  MenuItem,
  Select,
} from 'src/components/UI'
import { filtersActions } from 'src/ducks/actions'
import { getInsurancesOptions, getProsFilter } from 'src/ducks/filters/selector'
import { ProsFilterType } from 'src/ducks/filters/types'
import { FilterHeaderProps } from './types'
import {
  dateFormatString,
  formatTimestamp,
  headerCSVParser,
  PROS_PAGE_STATUS,
} from 'src/helpers'
import ExportCSV from 'src/components/UI/CustomUI/molecules/ExportCSV'
import { getCsvInsurances } from 'src/ducks/selectors'

import RefreshIcon from '@mui/icons-material/Refresh'
import { TableInsurance } from 'src/ducks/insurances/types'
import { icons } from 'src/assets'
import { CSVLink } from 'react-csv'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import { debounce } from 'lodash'

const FilterHeader: FC<FilterHeaderProps> = ({ headers, setHeaders, fetchLicenses, fetchCsvInsurances, loadingCsv }) => {
  const dispatch = useDispatch()
  const [searchValue, setSearchValue] = useState<string | null>(null)

  const prosFilter = useSelector(getProsFilter())
  const csvInsurances = useSelector(getCsvInsurances())
  const insurancesOptions = useSelector(getInsurancesOptions())
  const downloadRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null)

  const { search, downloadCSVCount, uploadedOnStart, uploadedOnEnd } = prosFilter

  const [refresh, setRefresh] = useState<boolean>(false)
  const [dates, setDates] = useState<{
    uploadedOnStart: string | null
    uploadedOnEnd: string | null
  }>({
    uploadedOnStart: prosFilter.uploadedOnStart || null,
    uploadedOnEnd: prosFilter.uploadedOnEnd || null,
  })

  const headersCSV: any = headerCSVParser(headers)
  const dataCSV = csvInsurances.reduce((acc: any, curr: TableInsurance) => {
    const vendor = {
      ...curr,
      companyName: curr.companyName || '',
      pro: curr.vendorName || '',
      phone: curr.phone || '',
      territories: curr?.insurance?.territories
        ?.filter((territory: any) => territory.title)
        ?.reduce(
          (acc: any, curr: any) =>
            acc.concat(`${curr.title} : ${curr.capacity || '0'}`),
          []
        )
        ?.join(' - ')
        ?.replace(/,/g, ' '),
      uploadedOn: curr?.insurance?.uploadedOn
        ? formatTimestamp(curr?.insurance.uploadedOn, 'MM/DD/YYYY hh:mm a')
        : '',
      licenseType: curr?.insurance?.insuranceType || '',
      validToDate: curr?.insurance?.validToDate
        ? formatTimestamp(curr?.insurance?.validToDate, 'MM/DD/YYYY hh:mm a')
        : '',
      reviewStatus: curr?.insurance?.reviewStatus || '',
    }
    acc = [...acc, vendor]
    return acc
  }, [])

  useEffect(() => {
    if (refresh === true) {
      fetchLicenses()
    }
    setRefresh(false)
  }, [refresh])

  const updateProsFilter = (newFilter: Partial<ProsFilterType>) => {
    dispatch(
      filtersActions.setFilters({
        pros: {
          ...prosFilter,
          ...newFilter,
        },
      })
    )
  }

  const changeSearchPro = useCallback(
    (newValue) => {
      dispatch(filtersActions.setFilters({
        pros: {
          ...prosFilter,
          search: newValue
        }
      }))
    },
    []
  )

  const debouncedSearch = useCallback(
    debounce(changeSearchPro, 500),
    []
  )

  const onTableFilterChange = (index: number): void => {
    setHeaders(headers =>
      headers.map((item, indx) => {
        if (indx === index) {
          return { ...item, hide: !item.hide }
        }
        return item
      })
    )
  }

  useEffect(() => {
    if (dates.uploadedOnEnd !== uploadedOnEnd && dates.uploadedOnStart !== uploadedOnStart)
      setDates({
        uploadedOnStart: uploadedOnStart || null,
        uploadedOnEnd: uploadedOnEnd || null,
      })
  }, [uploadedOnStart, uploadedOnEnd])

  return (
    <Box>
      <Grid
        container
        spacing={2}
        sx={{ flexWrap: '!important' }}
        justifyContent="space-between"
      >
        <Grid item flex={1}>
          <InputSearch
            label="Search for Pro:"
            placeholder="Search for Pro's email"
            onChange={(value) => {
              debouncedSearch(value)
              setSearchValue(value)
            }}
            value={searchValue ?? ''}
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item flex={1}>
          <FieldsFilter
            flexColumn
            handleChange={(option) => {
              switch (option) {
                case 0:
                  dispatch(filtersActions.setFilters({
                    pros: {
                      ...prosFilter,
                      draft: !prosFilter?.draft
                    }
                  }))
                  break
                case 1:
                  dispatch(filtersActions.setFilters({
                    pros: {
                      ...prosFilter,
                      approved: !prosFilter?.approved
                    }
                  }))
                  break
                case 2:
                  dispatch(filtersActions.setFilters({
                    pros: {
                      ...prosFilter,
                      denied: !prosFilter?.denied
                    }
                  }))
                  break
                case 3:
                  // separator 
                  break
                case 4:
                  if (prosFilter.insuranceTypes?.some(cert => cert === "CERTIFICATE_OF_INSURANCE")) {
                    dispatch(filtersActions.setFilters({
                      pros: {
                        ...prosFilter,
                        insuranceTypes: prosFilter?.insuranceTypes.filter(cert => cert !== "CERTIFICATE_OF_INSURANCE")
                      }
                    }))
                  } else {
                    dispatch(filtersActions.setFilters({
                      pros: {
                        ...prosFilter,
                        insuranceTypes: prosFilter?.insuranceTypes ? [...prosFilter.insuranceTypes, "CERTIFICATE_OF_INSURANCE"] : ["CERTIFICATE_OF_INSURANCE"]
                      }
                    }))
                  }
                  break
                case 5:
                  if (prosFilter.insuranceTypes?.some(cert => cert === "WORKERS_COMPENSATION")) {
                    dispatch(filtersActions.setFilters({
                      pros: {
                        ...prosFilter,
                        insuranceTypes: prosFilter?.insuranceTypes.filter(cert => cert !== "WORKERS_COMPENSATION")
                      }
                    }))
                  } else {
                    dispatch(filtersActions.setFilters({
                      pros: {
                        ...prosFilter,
                        insuranceTypes: prosFilter?.insuranceTypes ? [...prosFilter.insuranceTypes, "WORKERS_COMPENSATION"] : ["WORKERS_COMPENSATION"]
                      }
                    }))
                  }
                  break
                default:
                  if (prosFilter.insuranceTypes?.some(cert => cert === "OTHER")) {
                    dispatch(filtersActions.setFilters({
                      pros: {
                        ...prosFilter,
                        insuranceTypes: prosFilter?.insuranceTypes.filter(cert => cert !== "OTHER")
                      }
                    }))
                  } else {
                    dispatch(filtersActions.setFilters({
                      pros: {
                        ...prosFilter,
                        insuranceTypes: prosFilter?.insuranceTypes ? [...prosFilter.insuranceTypes, "OTHER"] : ["OTHER"]
                      }
                    }))
                  }

              }

            }}
            inputText='Insurances'
            options={insurancesOptions}
            renderAsInput
            label='Insurances:'
            popupLabel='Select Insurances to view'
            variant='body1' />

        </Grid>
        <Grid
          item
          container
          justifyContent="space-between"
          alignItems="flex-end"
          width="fit-content"
          spacing={2}
          paddingRight={2}
        >
          <Grid item>
            <Button
              variant="containedBig"
              color="primary"
              sx={{
                height: '36px',
                alignSelf: 'flex-end',
              }}
              onClick={() => {
                updateProsFilter({ pageStatus: PROS_PAGE_STATUS.PROS })
              }}
            >
              Pros
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="containedBig"
              color="primary"
              sx={{
                height: '36px',
                alignSelf: 'flex-end',
              }}
              onClick={() => {
                updateProsFilter({ pageStatus: PROS_PAGE_STATUS.LICENSES })
              }}
            >
              Licences
            </Button>
          </Grid>
          <Grid item>
            <FieldsFilter
              maxWidth="400px"
              // options={tableHeaders}
              options={headers}
              handleChange={onTableFilterChange}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="space-between"
        alignItems="flex-end"
        marginTop={2}
      >
        <Grid container item xs={7} spacing={0.5}>
          <Grid item gap={2} display="flex" alignItems="flex-end">
            <DatePicker
              placeholder="mm/dd/yyyy"
              onChange={(dateSelected: Date) => {
                setDates({
                  ...dates,
                  uploadedOnStart: dateFormatString(dateSelected),
                })
                updateProsFilter({
                  uploadedOnStart: dateFormatString(dateSelected)
                })
              }}
              value={
                dates?.uploadedOnStart ? new Date(dates?.uploadedOnStart) : null
              }
              style={{ height: '38px', padding: '0px', width: '100%' }}
              size="small"
              label="Created date from:"
            />
            <DatePicker
              placeholder="mm/dd/yyyy"
              onChange={(dateSelected: Date) => {
                setDates({
                  ...dates,
                  uploadedOnEnd: dateFormatString(dateSelected),
                })
                updateProsFilter({
                  uploadedOnEnd: dateFormatString(dateSelected)
                })
              }}
              value={
                dates?.uploadedOnEnd ? new Date(dates?.uploadedOnEnd) : null
              }
              style={{ height: '38px', padding: '0px', width: '100%' }}
              size="small"
              label="Created date to:"
            />
            <Button
              variant="contained"
              sx={{ minWidth: '100px !important' }}
              onClick={() => {
                updateProsFilter({
                  uploadedOnEnd: dates.uploadedOnEnd,
                  uploadedOnStart: dates.uploadedOnStart,
                })
              }}
            >
              Apply
            </Button>
            <Button
              variant="outlined"
              sx={{ minWidth: '100px !important' }}
              onClick={() => {
                setDates({
                  uploadedOnStart: null,
                  uploadedOnEnd: null,
                })
              }}
            >
              Reset dates
            </Button>
            <IconButton
              style={{
                marginLeft: '10px',
                marginTop: '5px',
              }}
              onClick={() => setRefresh(true)}
            >
              <RefreshIcon />
            </IconButton>
            <Button
              endIcon={<Icon name='Clear' />}
              variant='outlined' onClick={() => dispatch(filtersActions.clearFilters())}>
              Clear Filters
            </Button>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={5}
          spacing={1}
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          {/* <Grid item>
            <TablePagination
              callToApi={(start, limit) => {
                fetchVendors(start, limit)
              }}
              totalItems={totalVendors}
              // totalItems={filteredVendors.length}
              sx={{
                '.MuiTablePagination-toolbar': {
                  minHeight: 'fit-content !important',
                },
                '.MuiButtonBase-root': {
                  padding: '0px inherit !important',
                },
              }}
            />
          </Grid> */}
          <Grid container item spacing={2} justifyContent="flex-end">
            <Grid item>
              <ActionsButton
                loading={loadingCsv}
                icon={<icons.CloudDownload color='disabled' />}
                iconPosition='end'
                onClick={() => fetchCsvInsurances(() => downloadRef?.current?.link?.click())}
                sx={{
                  minWidth: '56px!important',
                }}
              />
              <ExportCSV
                innerRef={downloadRef}
                headers={headersCSV}
                data={dataCSV}
                fileName={'insurances.csv'}
                label=''
              />
            </Grid>
            <Grid item>
              <Select
                value={downloadCSVCount || 400}
                label={`${downloadCSVCount || 400}`}
                style={{ width: '105px' }}
                onChange={(e) =>
                  updateProsFilter({ downloadCSVCount: Number(e.target.value) })
                }
              >
                <MenuItem value={400}> 400 </MenuItem>
                <MenuItem value={200}> 200 </MenuItem>
                <MenuItem value={100}> 100 </MenuItem>
                <MenuItem value={50}> 50 </MenuItem>
              </Select>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default FilterHeader
