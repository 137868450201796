import { Grid } from "@mui/material"
import Images from "../../../Images"

const ImageGallery = () => {
  return (
    <Grid container sx={{
      flex: '1 1 auto',
      overflowY: 'auto',
      display: 'grid',
      padding: '0 1rem'
    }}>
      <Images
        downloadable
        initialTab={1}
        size="large"
        height="14rem"
        deletable
        // showCopyToItem
      />
    </Grid >
  )
}

export default ImageGallery