import { SearchParams } from '../searches/types'
import { Vendor } from '../types'
import { vendorsTypes, VendorsActions, VendorsType } from './types'

const actions = {
  fetchVendors: (
    payload: {
      page?: number
      size?: number
      searchParams?: Partial<SearchParams>
    },
    callback?: (succ: boolean) => void
  ): VendorsActions => ({
    type: vendorsTypes.FETCH_VENDORS,
    payload,
    callback,
  }),
  requestCSVVendors: (
    payload: {
      count?: number
      searchParams?: Partial<SearchParams>
    },
    callback: (succ: boolean, vendors: string) => void
  ): VendorsActions => ({
    type: vendorsTypes.REQUEST_CSV_VENDORS,
    payload,
    callback,
  }),
  getCSV: (
    payload: string,
    callback: (succ: boolean, vendors: string) => void): VendorsActions => ({
      type: vendorsTypes.GET_VENDORS_CSV,
      payload,
      callback,
    }),
  fetchPunchlistVendors: (
    payload: {
      page?: number
      size?: number
      searchParams?: Partial<SearchParams>
    },
    callback?: (succ: boolean) => void
  ): VendorsActions => ({
    type: vendorsTypes.FETCH_PUNCHLIST_VENDORS,
    payload,
    callback,
  }),
  fetchRecommendedVendors: (
    payload: {
      territory: string
      trades: string
      workStartDate: string
      workEndDate: string
    },
    callback?: (succ: boolean, total: number) => void
  ): VendorsActions => ({
    type: vendorsTypes.FETCH_RECOMMENDED_VENDORS,
    payload,
    callback,
  }),
  setPunchlistVendors: (payload: Vendor[]): VendorsActions => ({
    type: vendorsTypes.SET_PUNCHLIST_VENDORS,
    payload,
  }),
  setVendors: (payload: Partial<VendorsType>): VendorsActions => ({
    type: vendorsTypes.SET_VENDORS,
    payload,
  }),
  setVendorsList: (payload: Vendor[]): VendorsActions => ({
    type: vendorsTypes.SET_VENDORS_LIST,
    payload,
  }),
  setVendorById: (payload: Vendor): VendorsActions => ({
    type: vendorsTypes.SET_VENDOR_BY_ID,
    payload,
  }),
  fetchCounts: (
    payload: {
      searchParams?: Partial<SearchParams>
    },
    callback?: (succ: boolean) => void
  ): VendorsActions => ({
    type: vendorsTypes.FETCH_COUNTS,
    payload,
    callback,
  }),
  setCounts: (payload: VendorsType): VendorsActions => ({
    type: vendorsTypes.SET_VENDORS_COUNTS,
    payload,
  }),
}

export default actions
