import { Box, Button, Typography } from '@mui/material'
import { Link } from 'src/components/UI'
import {
  HeaderType,
  AlignType,
} from 'src/components/UI/CustomUI/organisms/Table/types'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import SettingsIcon from '@mui/icons-material/Settings';
import moment from 'moment'

import styles from './styles.module.scss'

export const removeProtocol = (url: string): string => {
  if (!url) {
    return ''
  }

  return url.replace(/(^\w+:|^)\/\//, '')
}

const applyRegExp = (phone: string): string => {
  if (!phone) return ''

  return String(phone).replace(/^(\d{3})(\d{3})(\d{4})$/g, '+1 $1-$2-$3')
}

const headers: HeaderType[] = [
  {
    id: 'affiliateId',
    label: 'Affiliate ID',
    custom: true,
    align: 'left' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Box padding="2px 10px">
          <Typography className={styles.Companies__companyName}>
            {row?.affiliateId}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'companyName',
    label: 'Company Name',
    custom: true,
    align: 'left' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Box padding="2px 10px">
          <Typography className={styles.Companies__companyName}>
            {row?.name}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'createdOn',
    label: 'Created On',
    custom: true,
    align: 'left' as AlignType,
    width: 160,
    Element: (row: any): JSX.Element => {
      return (
        <Box padding="2px 10px">
          <Typography className={styles.Companies__createdOn}>
            {`${moment(row.createdOn * 1000).format('MM/DD/YYYY - hh:mm A')}`}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'link',
    label: 'Link',
    custom: true,
    align: 'left' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Box padding="2px 10px">
          <Link
            href={row.landingPageUrl}
            target="_blank"
            style={{ textDecoration: 'none' }}
          >
            <Box
              gap="4px"
              display="flex"
              borderBottom="1px solid var(--blue700)"
              width="fit-content"
            >
              <Typography
                className={styles.Companies__companyName}
                style={{ color: 'var(--blue700)' }}
              >
                {removeProtocol(row.landingPageUrl)}
              </Typography>
              <Icon
                sx={{ color: '#3A66FF', width: 'fit-content' }}
                name="NorthEast"
                height="14px"
              />
            </Box>
          </Link>
        </Box>
      )
    },
  },

  {
    id: 'phone',
    label: 'Phone',
    custom: true,
    align: 'left' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Box padding="2px 10px">
          <Typography className={styles.Companies__companyName}>
            {applyRegExp(row.phone)}
          </Typography>
        </Box>
      )
    },
  },

  {
    id: 'email',
    label: 'Email',
    custom: true,
    align: 'left' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Box padding="2px 10px">
          <Typography className={styles.Companies__companyName}>
            {row.email}
          </Typography>
          <Typography className={styles.Companies__createdOn}>
            {row.adminName}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'institutional',
    label: 'Institutional',
    custom: true,
    maxWidth: 80,
    Element: (row: any): JSX.Element => {
      return row?.institutional ? <Icon name='CheckCircle' color="var(--success-color)" /> : <Icon color="var(--error-color)" name='Cancel' />
    }
  },
  {
    id: 'settings',
    label: 'Settings',
    custom: true,
    align: 'left' as AlignType,
    Element: (row: any): JSX.Element => {
      const { handleSettings, ...duckCompany } = row
      return (
        <Button
          onClick={() => {row.handleSettings(duckCompany)}}
          variant="containedLight"
          className={styles.Companies__viewButton}
          color="primary"
          style={{
            width: '107px',
            height: '46px'
          }}
          startIcon={<SettingsIcon color='inherit' fontSize='small' />}
        >
          Settings
        </Button>
      )
    }
  },
  {
    id: 'action',
    label: 'Action',
    custom: true,
    align: 'left' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Link href={`/companies/${row.id}`} style={{ textDecoration: 'none' }}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Button
              variant="contained"
              className={styles.Companies__viewButton}
              endIcon={
                <Icon
                  color="inherit"
                  name="NorthEast"
                  width="1rem"
                  height="12px"
                />
              }
            >
              View
            </Button>
          </Box>
        </Link>
      )
    },
  },
]

export default headers
