import { Typography } from '@mui/material'
import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Modal from 'src/components/UI/CustomUI/molecules/Modal'
import { ModalActionType } from 'src/components/UI/CustomUI/molecules/Modal/types'
import { jobActions } from 'src/ducks/actions'
import { STATUS } from 'src/helpers'
import { JobModalTypes, ModalsProps } from './types'
import ModalMaterialInvoices from './ModalMaterialInvoices'
import ModalRequestChangeOrderApproval from './ModalRequestChangeOrderApproval'
import { getJob } from 'src/ducks/selectors'
import ModalConfirm from '../../../EstimatesView/ControlButtons/Modals/ModalConfirm'

const Modals: FC<ModalsProps> = ({ open, setOpen, modalType, setLoading }) => {
  const dispatch = useDispatch()
  const job = useSelector(getJob())
  const { jobId } = useParams()
  const navigate = useNavigate()
  const title = () => {
    switch (modalType) {
      case JobModalTypes.CLEAR_JOB:
        return 'Confirm Clear Approval'
      case JobModalTypes.COMPLETE_JOB:
        return 'Confirm Complete Job'
      case JobModalTypes.REOPEN_JOB:
        return 'Reopen Job'
      default:
        return undefined
    }
  }

  const content = () => {
    switch (modalType) {
      case JobModalTypes.CLEAR_JOB:
        return (
          <Typography>
            Are you sure you want to clear approval on the job? This cannot be
            undone.
          </Typography>
        )
      case JobModalTypes.COMPLETE_JOB:
        return (
          <Typography>
            Are you sure you want to mark the Job complete. This cannot be
            undone.
          </Typography>
        )
      case JobModalTypes.REOPEN_JOB:
        return <Typography>Are you sure you want to reopen the Job?</Typography>
      default:
        return <>No Content</>
    }
  }

  const onClearWorkOrder = (): void => {
    setOpen(false)
    setLoading && setLoading(true)
    if (job?.id) {
      dispatch(
        jobActions.deleteJob(job?.id, () => {
          toast.success.call('success', 'Job Deleted')
          setOpen(false)
          navigate(`/estimates/${job?.id}`)
        })
      )
    }
    setLoading && setLoading(false)
  }

  const onEditJob = (status: STATUS) => {
    let messageText = `Job status updated to In ${status} `

    dispatch(
      jobActions.updateJob(
        {
          status: status,
        },
        () => {
          toast.success.call('success', messageText)
          setOpen(false)
        }
      )
    )
  }

  const onArchive = (): void => {
    const nextStatus = !job?.properties.archived
    dispatch(
      jobActions.updateJobProperty(
        {
          archived: nextStatus,
        },
        (succ) => {
          if (succ) {
            toast.success.call(
              'success',
              nextStatus ? 'Job Archived' : 'Unarchive Successful'
            )
          }
          setOpen(false)
        }
      )
    )
  }

  const onExcludeFromReports = (): void => {
    const nextStatus = !job?.properties.excludedFromReports
    dispatch(jobActions.updateJobProperty({
      excludedFromReports: nextStatus
    }, (succ) => {
      if (succ) {
        toast.success.call('success', nextStatus ? 'Job excluded from reports' : 'Job included in reports')
      }
      setOpen(false)
    }))
  }

  const modalActions: ModalActionType[] = [
    {
      textButton: 'Cancel',
      variant: 'containedError',
      onClick: () => {
        setOpen(false)
      },
    },
    {
      textButton: 'Yes',
      variant: 'containedGreen',
      onClick: () => {
        switch (modalType) {
          case JobModalTypes.CLEAR_JOB:
            onClearWorkOrder()
            break
          case JobModalTypes.COMPLETE_JOB:
            onEditJob(STATUS.COMPLETE)
            break
          case JobModalTypes.REOPEN_JOB:
            if (
              job?.status === STATUS.COMPLETE ||
              job?.status === STATUS.CLOSED
            ) {
              onEditJob(STATUS.REVIEW)
            }

            break

          default:
            break
        }
      },
    },
  ]

  const selectModal = () => {
    switch (modalType) {
      case JobModalTypes.MATERIAL_INVOICES:
        return <ModalMaterialInvoices setOpen={setOpen} open={open} />
      case JobModalTypes.REQUEST_CHANGE_ORDER_APPROVAL:
        return <ModalRequestChangeOrderApproval setOpen={setOpen} open={open} />
      case JobModalTypes.ARCHIVE_JOB:
        return (
          <ModalConfirm
            open={open}
            setOpen={setOpen}
            action={onArchive}
            contentText={`Are you sure to ${
              !job?.properties?.archived ? 'archive' : 'unarchive'
            } this job?`}
          />
        )
      case JobModalTypes.EXCLUDE_FROM_REPORTS:
        return (
          <ModalConfirm
            open={open}
            setOpen={setOpen}
            action={onExcludeFromReports}
            contentText={`Are you sure to ${
              !job?.properties?.excludedFromReports ? 'exclude from reports' : 'include in reports'
            }?`}
          />
        )
      default:
        return (
          <Modal
            setOpen={setOpen}
            open={open}
            title={title()}
            actions={modalActions}
          >
            {content()}
          </Modal>
        )
    }
  }

  return selectModal()
}

export default Modals
