import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { LabeledText } from 'src/components/UI'
import { getJob } from 'src/ducks/selectors'
import { JobItem } from 'src/ducks/types'
import { round } from 'src/helpers'
import { useIsLgScreen, useIsXlScreen } from 'src/hooks'
import { ContentBox, Divider, TabBox } from '../../Templates'

const Totals = () => {
  const job = useSelector(getJob())
  const {
    materialCost,
    customerPaid,
    customerInvoicedTotal,
    taxesTotal,
    pendingInvoiceTotal,
    vendorPaid,
    net,
    vendorOutstanding,
    margin,
    totalProfit,
    properties,
  } = job || ({} as JobItem)

  const { totalValue, totalEstimated, discount } = properties || {}
  const [xlScreen] = useIsXlScreen()
  const [lgScreen] = useIsLgScreen()

  return (
    <TabBox>
      <ContentBox
        gridTemplateColumns={xlScreen || lgScreen ? '1fr 1fr' : '1fr'}
      >
        <LabeledText
          labelValue="Approved Total:"
          textValue={`$${round(totalValue, 2)}`}
        />
        <LabeledText
          labelValue="Estimated Total:"
          textValue={`$${round(totalEstimated, 2)}`}
        />
        <LabeledText
          labelValue="Vendor Cost:"
          textValue={`$${round(vendorPaid + vendorOutstanding, 2)}`}
        />
        <LabeledText
          labelValue="Material Cost:"
          textValue={`$${round(materialCost, 2)}`}
        />
      </ContentBox>
      <Divider />
      <ContentBox
        gridTemplateColumns={xlScreen || lgScreen ? '1fr 1fr' : '1fr'}
      >
        <LabeledText
          labelValue="Customer Paid:"
          textValue={`$${round(customerPaid, 2)}`}
        />
        <LabeledText
          labelValue="Customer Invoiced:"
          textValue={`$${round(customerInvoicedTotal, 2)}`}
        />
        <LabeledText
          labelValue="Taxes:"
          textValue={`$${round(taxesTotal, 2)}`}
        />
        <LabeledText
          labelValue="Pending Invoiced:"
          textValue={`$${round(pendingInvoiceTotal, 2)}`}
        />
      </ContentBox>
      <Divider />
      <ContentBox
        gridTemplateColumns={xlScreen || lgScreen ? '1fr 1fr' : '1fr'}
      >
        <LabeledText
          labelValue="Vendor Paid:"
          textValue={`$${round(vendorPaid, 2)}`}
        />
        <LabeledText labelValue="Net:" textValue={`$${round(net, 2)}`} />
        <LabeledText
          labelValue="Vendor Outstanding:"
          textValue={`$${round(vendorOutstanding, 2)}`}
        />
        <LabeledText labelValue="Margin:" textValue={`${round(margin, 2)}%`} />
      </ContentBox>
      <Divider />
      <ContentBox
        gridTemplateColumns={xlScreen || lgScreen ? '1fr 1fr' : '1fr'}
      >
        <LabeledText
          labelValue="Total Profit:"
          textValue={`$${round(totalProfit, 2)}`}
        />
        <LabeledText labelValue="Discount:" textValue={`${discount}%`} />
      </ContentBox>
    </TabBox>
  )
}

export default Totals
