import { FC, useState } from 'react'
import { Box, Button, IconButton } from '@mui/material'
import { icons } from 'src/assets'
import ConfirmReleaseModal from '../ConfirmReleaseModal'
import { useSelector } from 'react-redux'
import { getEstimate } from 'src/ducks/selectors'
import { useNavigate } from 'react-router-dom'

interface ReleaseHeaderProps {
    onDownload: () => void
}

const ReleaseHeader: FC<ReleaseHeaderProps> = ({ onDownload }) => {
    const estimate = useSelector(getEstimate())
    const navigate = useNavigate()

    const [open, setOpen] = useState(false)

    const onCancel = () => {
        navigate(`/estimates/${estimate?.id}`)
    }

    const onSend = () => {
        setOpen(true)
    }

    return (
        <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            marginBottom={2}
            height="fit-content"
            borderBottom={1}
            paddingBottom={2}
        >
            <IconButton onClick={onDownload} color="primary" style={{ marginRight: '12px' }}>
                <icons.Download />
            </IconButton>
            <Button onClick={onCancel} variant="outlined" color="secondary" style={{ marginRight: '12px' }}>
                Cancel
            </Button>
            <Button onClick={onSend} variant="contained" color="success" endIcon={<icons.Send />}>
                Send Estimate
            </Button>
            <ConfirmReleaseModal isOpen={open} setOpen={setOpen} />
        </Box>
    )
}

export default ReleaseHeader

