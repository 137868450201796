import { Typography } from "@mui/material"
import { FC } from "react"
import { ToastMessageProps } from "./types"

const ToastMessage: FC<ToastMessageProps> = ({ title, subtitle }) => {
  if (subtitle) {
    return (
      <>
        <Typography variant="h5Bold" fontFamily="MorangaRg" >
          {title}
        </Typography>
        <Typography variant="h6">
          {subtitle}
        </Typography>
      </>
    )
  }
  return (
    <>
      <Typography variant="h6">
        {title}
      </Typography>
    </>
  )
}
export default ToastMessage