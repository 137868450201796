import React, { useReducer } from 'react'
import { PreviewInvoiceReducer } from './reducer'
import { DepositInvoiceContextType } from './types'

const initialState = {
  ccEmails: '',
  subject: '',
  jobId: '',
  total: 0,
  isValid: false,
  pageLoading: true,
  payerEmail:'',
  depositAmount:0,
  depositPercentaje:0,
  depositTotal:0,
  depositSubTotal:0,
  tax:0,
  paid:0,
  depositOutstandingBalance: 0,
  typeAmount:"USD",
}

export const DepositInvoiceContext = React.createContext<{
  state: DepositInvoiceContextType;
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null
})

export const DepositInvoiceProvider: React.FunctionComponent = ({ children }: any) => {
  const [state, dispatch] = useReducer(PreviewInvoiceReducer, initialState)

  return (
    <DepositInvoiceContext.Provider value={{ state, dispatch }}>
      {children}
    </DepositInvoiceContext.Provider>
  )
}
