import { FC } from 'react';
import { Box } from '@mui/material';
import * as IconMUI from '@mui/icons-material';

import { IconProps } from './types';
import styles from './styles.module.scss';

const Icon: FC<IconProps> = ({ name, color = "primary", height = "1rem", width = "1rem", onClick, background, className = "", sx }) => {
    const Icons = IconMUI as any;
    const Component = Icons[name];
    return (
        <Box className={`${styles.Icon} ${className}`} onClick={onClick} sx={[{
            cursor: onClick ? 'pointer' : 'inherit', background: background || 'none',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
        ]} >
            {Component && <Component style={{ height: height, width: width, color: color }} />}
        </Box>
    )
}

export default Icon