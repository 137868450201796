/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import { toast } from 'react-toastify'
import type { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import { errorTextTryingTo } from '../../helpers'
import { Punchlist } from '../../api'
import { tradeTypesActions } from '../actions'

import {
  tradeTypes,
  FetchTradeTypesAction
} from './types'

export function* fetchTradeTypes({ callback }: FetchTradeTypesAction): SagaIterator {
  let success = false
  try {
    const start = 0
    const limit = 999
    const data = yield call(Punchlist.tradeTypes.getTradeTypes, { start, limit })
    if (data) {
      yield put(tradeTypesActions.setTradeTypes(data))
      success = true
    }
    if (callback) yield call(callback, success)
  } catch (error) {
    if (callback) yield call(callback, false)
    yield call(toast.error, errorTextTryingTo('get the TradeTypes'))
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(tradeTypes.FETCH_TRADE_TYPES, fetchTradeTypes)
}
