import { FC, useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { format } from 'date-fns'
import { Range, TextFieldProps } from './types'
import useStyles from './styles'
import { SingleDatePicker } from './SingleDatePicker'
import { MultiDatePicker } from './MultiDatePicker'
import { isEmpty } from '../../../../../helpers'
import { Button, TextField, Typography, InputAdornment } from '@mui/material'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import Icon from '../Icon'
import TimePicker from '../TimePicker'
import { Box } from '@mui/system'

/**
 *
 * @param value `Date || Range` to show as default date
 * @param onChange `function` to execute on calendar's change
 * @param allowRange `boolean` to allow or not range's selection. `(default) -> false`
 * @param minDate `Date` min date to pick dates after it
 * @returns
 */
const DatePicker: FC<TextFieldProps> = ({
  value = null,
  onChange,
  allowRange = false,
  disabled = false,
  minDate,
  style = {},
  styleAlert = {},
  size = "medium",
  placeholder,
  error = false,
  allowTimePicker = false,
  centerOnScreen = false,
  label,
  align = "left",
  labelVariant = "h6",
  quickOptions = ['All History', 'Last Month', 'This Month', 'This Week'],
  defaultOpenCalendar = false
}) => {
  const classes = useStyles()
  const formatDate = allowTimePicker ? 'MM/dd/yyyy HH:mm' : 'MM/dd/yyyy'
  const [show, setShow] = useState(defaultOpenCalendar || false)
  const [reload, setReload] = useState(false)
  // const [showAllHistory, setShowAllHistory] = useState(isEmpty((value as Range)?.startDate))
  const [showAllHistory, setShowAllHistory] = useState(false)
  const [firstDatePicked, setFirstDatePicker] = useState<boolean>(false)
  const [time, setTime] = useState<any>()
  const wref = useRef<HTMLDivElement>(null);

  const allHistory = (): void => {
    setShowAllHistory(true)
    onChange({})
    setReload(!reload)
    setShow(!show)
  }

  const thisMonth = (): void => {
    setShowAllHistory(false)
    const newValue: Range = {
      startDate: moment()
        .startOf('month')
        .toDate(),
      endDate: moment()
        .endOf('month')
        .toDate()
    }
    onChange(newValue)
    setReload(!reload)
    setShow(!show)
  }

  const lastMonth = (): void => {
    setShowAllHistory(false)
    const newValue: Range = {
      startDate: moment()
        .subtract(1, 'month')
        .startOf('month')
        .startOf('day')
        .toDate(),
      endDate: moment()
        .subtract(1, 'month')
        .endOf('month')
        .startOf('day')
        .toDate()
    }

    onChange(newValue)
    setReload(!reload)
    setShow(!show)
  }

  const thisWeek = (): void => {
    setShowAllHistory(false)
    const newValue: Range = {
      startDate: moment()
        .startOf('week')
        .toDate(),
      endDate: moment()
        .endOf('week')
        .toDate()
    }
    onChange(newValue)
    setReload(!reload)
    setShow(!show)
  }

  const thisYear = (): void => {
    setShowAllHistory(false)
    const newValue: Range = {
      startDate: moment()
        .startOf('year')
        .startOf('day')
        .toDate(),
      endDate: moment()
        .endOf('year')
        .startOf('day')
        .toDate()
    }
    onChange(newValue)
    setReload(!reload)
    setShow(!show)
  }

  const lastQuarter = (): void => {
    setShowAllHistory(false)
    const newValue: Range = {
      startDate: moment()
        .startOf('quarter')
        .subtract(1, 'quarter')
        .startOf('day')
        .toDate(),
      endDate: moment()
        .endOf('quarter')
        .subtract(1, 'quarter')
        .startOf('day')
        .toDate()
    }
    onChange(newValue)
    setReload(!reload)
    setShow(!show)
  }

  const lastYear = (): void => {
    setShowAllHistory(false)
    const newValue: Range = {
      startDate: moment()
        .startOf('year')
        .subtract(1, 'year')
        .startOf('day')
        .toDate(),
      endDate: moment()
        .endOf('year')
        .subtract(1, 'year')
        .startOf('day')
        .toDate()
    }
    onChange(newValue)
    setReload(!reload)
    setShow(!show)
  }

  const getHour = (time: any) => {
    const hour = Number(time.hour)
    if (time.period === "PM") return hour === 12 ? 12 : hour + 12
    return hour === 12 ? 0 : hour
  }


  const handleChangeTime = (time: any) => {
    const newDate = value as Date
    newDate?.setHours(getHour(time), Number(time.minute), 0)
    onChange(newDate)
    setShowAllHistory(false)
    setTime(time)
  }

  const handleOnChange = (event: any) => {
    if ((allowRange && !firstDatePicked)) {
      setFirstDatePicker(true)
    } else {
      setShow(!show)
      setFirstDatePicker(false)
    }
    setShowAllHistory(false)
    if (allowTimePicker) {
      event.setHours(time.period === "PM" ? Number(time.hour) + 12 : Number(time.hour), Number(time.minute), 0)
    }
    onChange(event)
  }
  const handleClickOutside = (event: Event) => {
    if (wref) {
      if (wref.current && !wref.current.contains(event.target as Node)) {
        setShow(false)
      }
    }
  };

  useEffect(() => {

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wref]);

  const renderCalendarPicker = () => {
    if ((value instanceof Date && !allowRange) || !value) {
      return (
        <SingleDatePicker
          onChange={handleOnChange}
          value={value}
          minDate={minDate}
        />
      )
    } else {
      return (
        <MultiDatePicker onChange={handleOnChange} range={value as Range} />
      )
    }
  }

  const formatDateToShow = () => {
    if (showAllHistory && allowRange) {
      return 'All History'
    }
    if (allowRange) {
      const range = value as Range
      return range && range.startDate && range.endDate
        ? format(range.startDate, formatDate) +
        '  -  ' +
        format(range.endDate, formatDate)
        : ''
    }
    return value instanceof Date ? format(value, formatDate) : ''
  }

  const getQuickOptions = () => {
    return (
      <div className={classes.bar}>
        {quickOptions.map((option, index) => (
          <Button
            key={index}
            variant='text'
            size='small'
            onClick={() => {
              switch (option) {
                case 'All History':
                  allHistory()
                  break
                case 'Last Month':
                  lastMonth()
                  break
                case 'This Month':
                  thisMonth()
                  break
                case 'This Week':
                  thisWeek()
                  break
                case 'This Year':
                  thisYear()
                  break
                case 'Last Quarter':
                  lastQuarter()
                  break
                case 'Last Year':
                  lastYear()
                  break
                default:
                  break
              }
            }}
          >
            <Typography className={classes.title}>{option}</Typography>
          </Button>
        ))}
        {/* <Button variant='text' size='small' onClick={allHistory}>
          <Typography className={classes.title}> All History </Typography>
        </Button>
        <Button variant='text' size='small' onClick={lastMonth}>
          <Typography className={classes.title}> Last Month </Typography>
        </Button>
        <Button variant='text' size='small' onClick={thisMonth}>
          <Typography className={classes.title}> This Month </Typography>
        </Button>
        <Button variant='text' size='small' onClick={thisWeek}>
          <Typography className={classes.title}> This Week </Typography>
        </Button> */}
      </div>
    )
  }

  return (
    <div style={{ position: centerOnScreen ? 'inherit' : 'relative' }}>
      {label && <Typography variant={labelVariant}>{label}</Typography>}
      <TextField
        variant='outlined'
        disabled={disabled}
        label=''
        className={classes.comboParent}
        value={formatDateToShow()}
        size={size}
        placeholder={placeholder}
        error={error}
        InputProps={{
          className: error ? classes.error : '',
          style,
          endAdornment: (
            <InputAdornment position='end'>
              <Button disabled={disabled} variant='text' size='small' onClick={() => setShow(!show)} sx={{ minWidth: 'fit-content' }} style={{ padding: '4px !important' }}>
                <Icon name="CalendarToday" color='var(--gray400)' />
              </Button>
            </InputAdornment>
          )
        }}
      />
      {show && (
        <>
          <div ref={wref} className={`${classes.root} ${allowTimePicker && classes.timePicker} ${centerOnScreen ? classes.centerOnScreen : ''} ${classes[align]}`} style={styleAlert}>
            {allowRange && (
              getQuickOptions()
            )}
            {renderCalendarPicker()}
            {allowTimePicker && (
              <Box display="flex" flexDirection="column" alignItems="flex-end">
                <TimePicker onChange={handleChangeTime} value={value} />
                <Button variant='text' color='infoText' onClick={() => setShow(!show)}>CLOSE</Button>
              </Box>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default DatePicker
