import storeInfo from '../store'
import { WorkOrdersType } from './types'

export const getWorkOrders = () => (): WorkOrdersType => {
  const { workOrders } = storeInfo.store.getState()
  return workOrders
}

export const getWorkOrderGeneralSection = () => {
  const { workOrders: { generalSection } } = storeInfo.store.getState()
  return generalSection
}

export const getWorkOrderFinancialSection = () => {
  const { workOrders: { financialSection } } = storeInfo.store.getState()
  return financialSection
}

export const getWorkOrderContractDetails = () => {
  const { workOrders: { contractDetails } } = storeInfo.store.getState()
  return contractDetails
}

export const getWorkOrderContractMilestones = () => {
  const { workOrders: { contractMilestones } } = storeInfo.store.getState()
  return contractMilestones
}

export const getWorkOrderItems = () => {
  const { workOrders: { items } } = storeInfo.store.getState()
  return items
}
