import { FC, Suspense, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { getConfigValue, isAuthorized, isApproved, isRequiredData } from '../ducks/selectors'
import { AuthPage, PunchlistLoader } from '../components/templates'
import { configActions } from 'src/ducks/actions'

const AuthRoutes: FC = () => {
  const location = useLocation()
  const authorized = useSelector(isAuthorized)
  const requiredData = useSelector(isRequiredData)
  const redirectPath = useSelector(getConfigValue('redirectPath'))
  const approved = useSelector(isApproved)
  const pathname = location.pathname
  const isUnauthorized = pathname === '/unauthorized'
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(configActions.setConfigValue({ type: 'overlayLoading', value: false }))
  }, [dispatch])

  if (authorized && !approved && !isUnauthorized) {
    return <Navigate to='/unauthorized' state={{ from: location }} replace />
  }

  if (authorized && !isUnauthorized) {
    if (!requiredData) {
      return <Navigate to='/account' state={{ from: location }} replace />
    } else {
      return <Navigate to={redirectPath || '/home'} state={{ from: location }} replace />
    }
  }


  return (
    <Suspense fallback={<PunchlistLoader />}>
      <AuthPage>
        <Outlet />
      </AuthPage>
    </Suspense>
  )
}

export default AuthRoutes
