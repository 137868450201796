import { EmailEvents, Job, Message } from '../types'

/** TYPES **/
export const communicationsTypes = {
  GET_MESSAGES: 'GET_MESSAGES',
  GET_AMOUNTS: 'GET_AMOUNTS',
  GET_EMAIL_EVENTS: 'GET_EMAIL_EVENTS',
  SET_MESSAGES: 'SET_MESSAGES',
  SET_MESSAGE: 'SET_MESSAGE',
  SET_MESSAGES_BY_THREAD: 'SET_MESSAGES_BY_THREAD',
  SET_MESSAGE_BY_THREAD: 'SET_MESSAGE_BY_THREAD',
  SEND_MESSAGE: 'SEND_MESSAGE',
  REPLY_THREAD: 'REPLY_THREAD',
  SET_AMOUNT_VALUE: 'SET_AMOUNT_VALUE',
  SET_EMAIL_EVENTS: 'SET_EMAIL_EVENTS',
  RESET_AMOUNTS: 'RESET_AMOUNTS'
}

/** ACTIONS **/
export interface GetMessagesAction {
  type: typeof communicationsTypes.GET_MESSAGES
  payload: {
    params?: Partial<GetMessagesParams>
  }
  callback: (succ: boolean, messages?: Message[] | null) => void
}

export interface GetEmailEventsAction {
  type: typeof communicationsTypes.GET_EMAIL_EVENTS
  payload: {
    id: string,
    start?: number,
    limit?: number,
  }
  callback: (succ: boolean) => void
}

export interface SendMessageAction {
  type: typeof communicationsTypes.SEND_MESSAGE
  payload: Partial<Message>
  callback?: (succ: boolean, message?: Message | null) => void
}

export interface ReplyThreadAction {
  type: typeof communicationsTypes.REPLY_THREAD
  payload: Partial<Message>
  callback?: (succ: boolean, messages?: Message[] | null) => void
}

export interface SetMessagesAction {
  type: typeof communicationsTypes.SET_MESSAGES
  payload: Message[]
}

export interface SetEmailEventsAction {
  type: typeof communicationsTypes.SET_EMAIL_EVENTS
  payload: EmailEvents
}

export interface SetMessageAction {
  type: typeof communicationsTypes.SET_MESSAGE
  payload: Message
}

export interface ReplyThreadAction {
  type: typeof communicationsTypes.REPLY_THREAD
  payload: Partial<Message>
  callback?: (succ: boolean, messages?: Message[] | null) => void
}

export interface ResetAmountsActions {
  type: typeof communicationsTypes.RESET_AMOUNTS
  payload: null
}

export interface SetAmountsValueAction {
  type: typeof communicationsTypes.SET_AMOUNT_VALUE
  payload: AmountValues
}

export interface VendorValue {
  attr: keyof Job
  value: any
}

export type MessagesActions =
  | GetMessagesAction
  | GetEmailEventsAction
  | SetMessagesAction
  | SetMessageAction
  | SetEmailEventsAction
  | SendMessageAction
  | ReplyThreadAction
  | SetAmountsValueAction
  | ResetAmountsActions

/** REDUCER **/
export type CommunicationsType = {
  messages: Message[]
  messagesByThread: Message[]
  amounts: AmountsTypes
  emailEvents: EmailEvents | undefined
}

export interface GetMessagesParams {
  jobId: string,
  threadId?: string,
  limit?: number,
  start?: number,
  bucket?: BUCKET_TYPE,
}

export enum BUCKET_TYPE {
  ALL = 'ALL',
  UNREAD = 'UNREAD',
  SENT = 'SENT',
}

export interface AmountsTypes {
  all: number,
  unread: number,
  sent: number,
}

export interface AmountValues {
  attr: string
  value: number
}
