import { Button, Grid, Typography } from '@mui/material'
import useStyles from './styles'
import PartnerModal from './Modals/PartnerModal'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getHomemart } from 'src/ducks/selectors'
import Table from 'src/components/UI/CustomUI/organisms/Table'
import headers from './headers'
import { homemartActions } from 'src/ducks/actions'
import { Partner } from 'src/ducks/types'
import DeletePartnerModal from './Modals/DeletePartnerModal'

const Homemart = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [tableLoading, setTableLoading] = useState<boolean>(false)
  const [isAddModal, setIsAddModal] = useState<boolean>(true)
  const [selectedPartner, setSelectedPartner] = useState<Partner>()
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)

  const dispatch = useDispatch()

  const styles = useStyles()

  const homemarts = useSelector(getHomemart())

  const handleAddNewClick = () => {
    setIsAddModal(true)
    setModalOpen(true)
  }

  useEffect(() => {
    fetchHomemart()
  }, [])

  const fetchHomemart = () => {
    setTableLoading(true)
    dispatch(
      homemartActions.fetchPartners({ active: true }, (succ) => {
        setTableLoading(false)
      })
    )
    dispatch(
      homemartActions.fetchPartnerCategories(null)
    )
    dispatch(
      homemartActions.fetchPartnerTags(null)
    )
  }

  const handleEditPartner = (selected: Partner) => {
    setIsAddModal(false)
    setModalOpen(true)
    setSelectedPartner({ ...selected })
  }

  const handleDeletePartner = (selected: Partner) => {
    setDeleteModalOpen(true)
    setSelectedPartner(selected)
  }

  return (
    <Grid container flexDirection="column" spacing={2}>
      <Grid item container spacing={2} alignItems="center">
        <Grid item>
          <Typography className={styles.lgFont}>HomeMart Partners</Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            className={styles.primaryButton}
            onClick={handleAddNewClick}
          >
            Add New
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        <Table headers={headers} loading={tableLoading}>
          {homemarts.partners?.map((partner) => ({
            ...partner,
            onEdit: () => handleEditPartner(partner),
            onDelete: () => handleDeletePartner(partner),
          }))}
        </Table>
      </Grid>
      <PartnerModal open={modalOpen} addModal={isAddModal} setOpen={setModalOpen} selectedPartner={selectedPartner} />
      <DeletePartnerModal open={deleteModalOpen} setOpen={setDeleteModalOpen} selectedPartner={selectedPartner} />
    </Grid>
  )
}

export default Homemart
