import storeInfo from "../store"
import { SubscriptionItem } from "../types"

export const getSubscriptions = (): SubscriptionItem[] => {
  const { subscriptions: { items } } = storeInfo.store.getState()
  return items || []
}

export const getSubscriptionsTotalCount = (): number => {
  const { subscriptions: { total } } = storeInfo.store.getState()
  return total || 0
}

export const getCsvSubscriptions = (): SubscriptionItem[] => {
  const { subscriptions: { csvItems } } = storeInfo.store.getState()
  return csvItems || []
}