import { Theme } from '../../../../../UI'
import { makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexFlow: 'column',
    gap: '16px',
    padding: '16px',
    borderRadius: '16px',
    width: 'fit-content'
  },
  column: {
    display: 'flex',
    flexFlow: 'column',
    gap: '16px',
    overflow: 'overlay',
    height: '600px'
  }
}))
