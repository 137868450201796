import { authTypes } from '../auth/types'
import { MatchesType } from './types'
import { MatchesActions, matchesTypes } from './types'

const initialState: MatchesType = {
  estimateId: '',
  id: '',
  results: [],
  runDuration: 0,
  runTimestamp: 0,
  type: ''
}

const reducer = (
  state = initialState,
  action: MatchesActions
): MatchesType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: {
      return initialState
    }
    case authTypes.LOGOUT_REQUEST: {
      return initialState
    }

    case matchesTypes.SET_MATCHES: {
      return {
        ...state,
        ...payload as MatchesType
      }
    }

    default:
      return state
  }
}

export default reducer
