import { FC, useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, CircularProgress, Grid, Typography } from 'src/components/UI'
import { orderActions, ordersActions } from 'src/ducks/actions'
import { WorkOrdersContext } from '../../../context'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import { getOrderById } from 'src/ducks/selectors'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

const DeleteWorkOrder: FC<{ many?: boolean, category?: boolean }> = ({ many = false, category = false }) => {
	const dispatch = useDispatch()
	const { dispatch: dispatchContext, state: { selectedOrderId } } = useContext(WorkOrdersContext)
	const navigate = useNavigate()
	const { id } = useParams()
	const order = useSelector(getOrderById(selectedOrderId as string))
	const [searchParams, setSearchParams] = useSearchParams()

	const [buttonLoading, setButtonLoading] = useState(false)

	const handleClose = () => {
		dispatchContext({ type: 'SET_MODAL_OPEN', payload: false })
	}

	const handleDelete = () => {
		setButtonLoading(true)
		if (!selectedOrderId) {
			setButtonLoading(false)
			return
		}
		dispatch(
			orderActions.deleteOrder({ orderId: selectedOrderId },
				(success) => {
					setButtonLoading(false)
					if (success) {
						handleClose()
						id && navigate(`jobs/${id}`)
						dispatchContext({ type: 'SET_SELECTED_TAB', payload: 0 })
						setSearchParams({
							...searchParams,
							tab: '1',
						}, { replace: true })
						dispatch(ordersActions.fetchOrders({
							params: { jobId: id }
						}, (succ) => {
							if (succ) {
								dispatchContext({ type: 'SET_PAGE_LOADING', payload: false })
							}
						}))
					}
				})
		)
	}

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Box sx={{ padding: '0 3vw' }}>
					<Typography>Permanently remove order <Typography variant='body1Bold' color="var(--red600)">{order.orderName}</Typography></Typography>
				</Box>
			</Grid>
			<Grid item container xs={12} spacing={2}>
				<Grid item xs={6}>
					<Button variant='outlined' onClick={handleClose} fullWidth>
						Cancel
					</Button>
				</Grid>
				<Grid item xs={6}>
					<Button variant='containedError' onClick={handleDelete} fullWidth
						endIcon={!buttonLoading && <Icon name='DeleteOutline' />}
					>
						{buttonLoading ? <CircularProgress color='info' size={'1.4rem'} /> : 'Permanently Delete'}
					</Button>
				</Grid>
			</Grid>
		</Grid >
	)
}

export default DeleteWorkOrder