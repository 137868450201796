import { FC } from 'react'
import { Button } from 'src/components/UI/MaterialUI'
import { Box, Typography } from '../../../'
import { Props } from './types'

const Level: FC<Props> = ({
  value,
  onChange
}) => {

  return (
    <Box
      border='1px solid'
      borderRadius='0.375rem'
      color='primary'
      display='flex'
      justifyContent='center'
      position='relative'
      width='fit-content'
      sx={{ width: '240px', height: '40px', borderColor: 'primary' }}
    >
      {[...Array(5)].map(function (object, i) {
        const bg = i === value ? '#1A2A55' : 'transparent'
        const color = i === value ? 'white' : 'primary'
        const radius = (i === 4 || i === 0) ? '0.375rem' : '0rem'
        return (<Box key={i} justifyContent="center" alignItems="center" borderRadius={radius}
          sx={{ width: '48px', height: '38px', borderColor: 'primary', backgroundColor: bg }}>
          <Button sx={{
            height: '40px',
            paddingRight: '8px',
            paddingLeft: '8px'
          }} onClick={() => onChange(i)}>
            <Typography textAlign='center' fontSize='16px' paddingX='10px' paddingY='10px' color={color}>{i}</Typography>
          </Button>
        </Box>)
      })}
    </Box>
  )
}

export default Level
