import { Box, Button, CircularProgress, DialogContent, Grid, Typography } from "@mui/material"
import { FC, useState } from "react"
import { email, icons, phone, text } from 'src/assets'
import { COMMUNICATION_METHODS } from "src/ducks/types"
import { SUB_OPTIONS } from "../.."
import useStyles from './styles'

interface ContactInformationProps {
  setSubActiveOption: (option: SUB_OPTIONS) => void
  setSelectedContactMethod: (method: COMMUNICATION_METHODS) => void
  selectedContactMethod?: COMMUNICATION_METHODS
  onSubmit: () => void
}


const ContactInformation: FC<ContactInformationProps> = ({ setSubActiveOption, setSelectedContactMethod, selectedContactMethod, onSubmit }) => {
  const styles = useStyles()
  const [loading, setLoading] = useState(false)

  interface Option {
    title: string
    id: COMMUNICATION_METHODS
    icon: string
    setSelected: (method: COMMUNICATION_METHODS) => void
  }

  const options = [
    {
      title: 'Email',
      id: COMMUNICATION_METHODS.EMAIL,
      icon: email
    },
    {
      title: 'Phone',
      id: COMMUNICATION_METHODS.PHONE,
      icon: phone
    },
    {
      title: 'Text',
      id: COMMUNICATION_METHODS.TEXT,
      icon: text
    }
  ]

  const ContactBox: FC<Option> = ({ title, id, icon, setSelected }) => {
    const currentMethodIsSelected = selectedContactMethod === id

    return (
      <Box
        onClick={() => setSelected(id)}
        className={`${currentMethodIsSelected ? styles.boxSelected : ''} ${styles.box}`}>
        <img src={icon} alt={title} className={styles.img} />
        <Typography className={styles.title}>{title}</Typography>
        <Button className={styles.radioButton}>
          {currentMethodIsSelected ? <icons.RadioButtonChecked htmlColor={currentMethodIsSelected ? 'var(--bosscat-blue-600)' : '#F5F6F7'} /> : <icons.RadioButtonUnchecked htmlColor='#F5F6F7' />}
        </Button>
      </Box>
    )
  }

  return (
    <Box width={"100%"} display="flex" flexDirection="column" className={styles.root}>
      <Typography variant="h5" className={`${styles.header} ${styles.text}`}>
        how would you like us to respond?
      </Typography>
      <DialogContent className={styles.boxes}>
        {options.map(option => <ContactBox setSelected={setSelectedContactMethod} key={option.id} {...option} />)}
      </DialogContent>
      <Grid container justifyContent="space-between">
        <Button
          onClick={() => setSubActiveOption(SUB_OPTIONS.initial)}
          className={styles.button}
          startIcon={<icons.ArrowBack />}
        >
          Back
        </Button>
        <Button
          onClick={() => {
            onSubmit()
          }}
          className={styles.buttonNext}
          disabled={!selectedContactMethod}
        >
          {loading ? <CircularProgress size={24} className={styles.spinner} /> : 'Submit'}
        </Button>
      </Grid>
    </Box>
  )
}

export default ContactInformation