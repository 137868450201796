import { Box } from 'src/components/UI'

const ContentBox = ({
  children,
  gap = '2px',
  gridAutoFlow = 'row',
  gridTemplateColumns,
  gridTemplateRows,
  className = ""
}: {
  children: JSX.Element | JSX.Element[],
  gap?: string,
  gridAutoFlow?: 'column' | 'row',
  gridTemplateColumns?: string,
  gridTemplateRows?: string,
  className?: string
}) => {

  return (
    <Box
      display='grid'
      gap={gap}
      gridAutoFlow={gridAutoFlow}
      gridTemplateColumns={gridTemplateColumns}
      gridTemplateRows={gridTemplateRows}
      className={className}
    >
      {children}
    </Box>
  )
}

export default ContentBox
