import { formatParams } from 'src/helpers/index';
import axios from '../axios'
import { ApiController } from './types'

const base = '/campaigns'

const apiController: ApiController = {
  getCampaigns: async (params) => {
    const parameters = formatParams(params);
    return await axios.get(`${base}/current?${parameters}`).then((response) => response.data)
  },
}

export default apiController
