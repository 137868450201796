import { CompanyItem } from 'src/ducks/types'
import { formatParams } from 'src/helpers'
import { UpdateRequest } from '~api/types'
import axios from '../axios'
import { ApiController } from './types'

const base = '/inspection-companies'

const apiController: ApiController = {
  getInspectionCompanies: async ({ start, limit, searchParams }) => {
    const params = formatParams(searchParams)

    return await axios
      .get(`${base}?${params}`, {
        params: {
          start,
          limit,
        },
      })
      .then((response) => response.data)
  },

  createInspectionCompany: async (request: Partial<CompanyItem>) =>
    await axios.post(`${base}`, request).then((response) => response.data),

  updateInspectionCompany: async (id: string, request: UpdateRequest) =>
    await axios
      .patch(`${base}/${id}`, request)
      .then((response) => response.data),

  deleteInspectionCompany: async (id: string) =>
    await axios.delete(`${base}/${id}`).then((response) => response.data),

  getInspectionCompanyById: async (id: string) =>
    await axios.get(`${base}/${id}`).then((response) => response.data),

  getInspectionCompanyInspectors: async (id: string) =>
    await axios
      .get(`${base}/${id}/inspectors`)
      .then((response) => response.data),
}

export default apiController
