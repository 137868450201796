import { authTypes } from '../auth/types'
import { configTypes, ConfigType, ConfigActionTypes, ConfigValue } from './types'

const initialState: ConfigType = {
  redirectPath: '/home'
}

const reducer = (state = initialState, action: ConfigActionTypes): ConfigType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: {
       return {
        ...initialState,
        redirectPath: state.redirectPath
      } 
    }
    
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case configTypes.SET_CONFIG_VALUE: {
      return {
        ...state,
        [(payload as ConfigValue).type]: (payload as ConfigValue).value
      }
    }
    default:
      return state
  }
}

export default reducer
