import { HeaderType } from 'src/components/UI/CustomUI/organisms/Table/types'

const headers: HeaderType[] = [
    {
        id: 'createdOn',
        label: 'Date',
        type: 'datetime'
    },
    {
        id: 'createdBy',
        label: 'Updated'
    },
    {
        id: 'text',
        label: 'Description',
        maxWidth: 520

    }
]

export default headers
