/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState, useContext, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  ActionsButton,
  Button,
  Grid,
  InputSearch,
  Radio,
  Checkbox,
  CircularProgress,
  Link as MaterialLink,
  TextFieldLabel,
  Typography,
  Box,
  SelectedVendor,
  RadioGroup,
  FormControlLabel,
} from 'src/components/UI'
import Table from 'src/components/UI/CustomUI/organisms/Table'
import DatePicker from 'src/components/UI/CustomUI/atoms/DatePicker'
import {
  siteVisitActions,
  territoryActions,
  vendorsActions,
} from 'src/ducks/actions'
import {
  getEstimate,
  getSiteVisit,
  getTerritoryManagers,
  getTotalVendors,
  getVendorsForDispatch,
} from 'src/ducks/selectors'
import headersVendors from './headersVendors'
import headersManagers from './headersManagers'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import { WorkOrdersContext } from '../../../context'
import { Link } from 'react-router-dom'
import { colors } from 'src/components/UI/MaterialUI/themeExtension'
import { PrivateLoader, ScrollWrapper } from 'src/components/templates'
import {
  EMPLOYEE_ROLE_TYPE,
  formatDateTimestamp,
  formatTimestampDate,
  isEmpty,
  ORDER_STATUS,
  ROWS_PER_PAGE_EXTENDED,
  ROWS_PER_PAGE_SIMPLE,
} from 'src/helpers'
import { SearchParams } from 'src/ducks/searches/types'
import Tag from 'src/components/UI/CustomUI/atoms/Tag'
import TablePagination from 'src/components/UI/CustomUI/organisms/Table/TablePagination'
import { debounce } from 'lodash'

const PAGE_SIZE = 5
const COMPANY_FIELD_MANAGER = 'PunchlistUSA'
type TABLE_OPTION = 'vendor' | 'fieldManager'

const SiteVisit: FC = () => {
  const {
    dispatch: dispatchContext,
    state: {
      selectedVendor,
      selectedSiteVisitId,
      selectedItemsIds,
      selectedFieldManager,
    },
  } = useContext(WorkOrdersContext)
  const dispatch = useDispatch()

  const estimate = useSelector(getEstimate())
  const totalVendors = useSelector(getTotalVendors)
  const territoryId = estimate?.properties?.territory?.id || ''
  const siteVisit = useSelector(getSiteVisit())

  const [tableVendorsLoading, setTableVendorsLoading] = useState(false)
  const [tableManagersLoading, setTableManagersLoading] = useState(false)
  const [pageLoading, setPageLoading] = useState(true)
  const [buttonLoading, setButtonLoading] = useState(false)
  const [searchVendor, setSearchVendor] = useState('')
  const [date, setDate] = useState<Date | null>(null)
  const [bidPrice, setBidPrice] = useState('')
  const [siteVisitNotes, setSiteVisitNotes] = useState('')
  const [estimateFiles, setPunchlistFile] = useState<any[]>([])
  const [showWarnings, setShowWarnings] = useState(false)
  const [assignTo, setAssignTo] = useState<TABLE_OPTION>('vendor')

  const vendors = useSelector(getVendorsForDispatch())
  const territoryManagers = useSelector(getTerritoryManagers)
  const [paginatedTerritoryManagers, setPaginatedTerritoryManagers] = useState(
    territoryManagers?.slice(0, 5)
  )


  const changeSearchPro = useCallback(
    (newValue) => {
      fetchVendors(0, PAGE_SIZE, newValue)
    },
    []
  )

  const debouncedSearch = useCallback(
    debounce(changeSearchPro, 500),
    []
  )

  const validate =
    date !== null &&
    !isEmpty(bidPrice) &&
    ((assignTo === 'vendor' && selectedVendor !== null) ||
      selectedFieldManager !== null)

  const getParams = () => {
    const searchParams = {} as Partial<SearchParams>
    if (!isEmpty(searchVendor)) searchParams.search = searchVendor
    if (territoryId) searchParams.territory = territoryId
    searchParams.approved = true
    return searchParams
  }

  const fetchVendors = (page?: number, size?: number, searchValue?: string) => {
    setTableVendorsLoading(true)
    const searchParams = getParams()
    if (searchValue) searchParams.search = searchValue
    dispatch(
      vendorsActions.fetchVendors(
        { page, size, searchParams },
        (_succ: boolean) => {
          setTableVendorsLoading(false)
        }
      )
    )
  }

  const fetchTerritoryManagers = () => {
    dispatch(
      territoryActions.fetchTerritoryManagers(
        EMPLOYEE_ROLE_TYPE.TERRITORY_MANAGER,
        (_succ: boolean) => {
          setTableManagersLoading(false)
        }
      )
    )
  }

  useEffect(() => {
    fetchTerritoryManagers()
  }, [])

  useEffect(() => {
    if (territoryManagers.length > 0)
      setPaginatedTerritoryManagers(territoryManagers.slice(0, 5))
  }, [territoryManagers])
  useEffect(() => {
    if (estimate) {
      const edit = selectedSiteVisitId !== null && siteVisit !== null
      const files = edit ? siteVisit.files ?? [] : []
      setPunchlistFile(
        (estimate?.properties?.files || []).map((file: any) => {
          return {
            ...file,
            checked: !edit
              ? true
              : files.some((f) => f.fileUrl === file.fileUrl),
          }
        })
      )
    }
  }, [siteVisit])

  useEffect(() => {
    if (selectedSiteVisitId) {
      dispatch(
        siteVisitActions.getSiteVisit(selectedSiteVisitId, () => {
          setPageLoading(false)
        })
      )
    } else setPageLoading(false)
  }, [selectedSiteVisitId])

  useEffect(() => {
    if (selectedSiteVisitId && siteVisit) {
      setDate(
        formatTimestampDate(
          siteVisit.scheduledStartTimestamp as number,
          estimate?.properties?.address?.timeZone
        )
      )
      setBidPrice(siteVisit.bidPrice.toString())
      setSiteVisitNotes(siteVisit.dispatchNotes)
      if (siteVisit.companyName === COMPANY_FIELD_MANAGER) {
        setAssignTo('fieldManager')
        dispatchContext({
          type: 'SET_SELECTED_FIELD_MANAGER',
          payload: { id: siteVisit.vendorId },
        })
      } else {
        setAssignTo('vendor')
        dispatchContext({
          type: 'SET_SELECTED_VENDOR',
          payload: siteVisit.selectedVendor,
        })
      }
    }
  }, [siteVisit])

  const toggleFileCheck = (fileId: string) => {
    setPunchlistFile([
      ...estimateFiles.map((file: any) => {
        if (file.id === fileId) file.checked = !file.checked
        return file
      }),
    ])
  }

  const handleClose = () => {
    dispatchContext({ type: 'SET_SELECTED_VENDOR', payload: null })
    dispatchContext({ type: 'SET_SELECTED_FIELD_MANAGER', payload: null })
    dispatchContext({ type: 'SET_SELECTED_SITE_VISIT_ID', payload: null })
    dispatchContext({ type: 'SET_MODAL_OPEN', payload: false })
  }

  const handleUpdate = () => {
    if (validate && selectedSiteVisitId) {
      setButtonLoading(true)
      setShowWarnings(false)

      const vendorId =
        assignTo === 'vendor' ? selectedVendor?.id : selectedFieldManager?.id
      const companyName = assignTo === 'vendor' ? '' : COMPANY_FIELD_MANAGER

      dispatch(
        siteVisitActions.updateSiteVisit(
          {
            siteVisitId: selectedSiteVisitId,
            siteVisit: {
              scheduledStartTimestamp: formatDateTimestamp(
                date,
                estimate?.properties?.address?.timeZone
              ),
              bidPrice: parseInt(bidPrice),
              dispatchNotes: siteVisitNotes,
              vendorId,
              companyName,
            },
            jobFiles: estimateFiles
              .filter((file) => file.checked)
              .map(({ checked, ...rest }) => rest),
          },
          (succ: boolean) => {
            setButtonLoading(false)
            if (succ) handleClose()
          }
        )
      )
    } else setShowWarnings(true)
  }

  const handleDispatch = (
    sendNotification: boolean = false,
    manualAssign: boolean = false
  ) => {
    if (validate) {
      setButtonLoading(true)
      setShowWarnings(false)

      const vendorId =
        assignTo === 'vendor' ? selectedVendor?.id : selectedFieldManager?.id
      // const statusType =
      //   assignTo === 'vendor' ? ORDER_STATUS.PENDING : ORDER_STATUS.DISPATCHED
      const statusType = manualAssign ? ORDER_STATUS.DISPATCHED : ORDER_STATUS.PENDING
      const companyName = assignTo === 'vendor' ? '' : COMPANY_FIELD_MANAGER

      dispatch(
        siteVisitActions.createSiteVisit(
          {
            siteVisit: {
              estimateId: estimate?.id,
              scheduledStartTimestamp: formatDateTimestamp(
                date,
                estimate?.properties?.address?.timeZone
              ),
              bidPrice: parseInt(bidPrice),
              dispatchNotes: siteVisitNotes,
              statusType,
              vendorId,
              companyName,
            },
            jobFiles: estimateFiles
              .filter((file) => file.checked)
              .map(({ checked, ...rest }) => rest),
            itemsIds: selectedItemsIds,
            jobId: estimate?.id || '',
            sendNotification,
          },
          (succ: boolean) => {
            dispatchContext({ type: 'REFRESH_SITE', payload: true })
            setButtonLoading(false)
            if (succ) handleClose()
          }
        )
      )
    } else setShowWarnings(true)
  }

  const handleDispatchAndNotify = () => {
    handleDispatch(true)
  }

  const handleManualAssign = () => {
    handleDispatch(false, true)
  }

  const isPLVendor = (): boolean => {
    return selectedVendor?.companyName.toLowerCase() === 'punchlistusa'
  }

  const isAssignedToVendor = (): boolean => {
    return assignTo === 'vendor'
  }

  const isAssignedToFieldManager = (): boolean => {
    return assignTo === 'fieldManager'
  }

  return (
    <PrivateLoader loading={pageLoading}>
      <Box
        sx={{
          display: 'flex',
          gap: '4px',
          flexDirection: 'column',
          position: 'absolute',
          bottom: '12px',
          left: '16px',
        }}
      >
        <Typography
          fontFamily="NouvelleNormal"
          fontWeight="bold"
          lineHeight="18px"
        >
          Status:
        </Typography>
        <Typography
          fontFamily="NouvelleNormal"
          fontWeight="regular"
          lineHeight="18px"
        >
          {selectedSiteVisitId ? (
            <Tag background="#FFFBF0" color="#CC9300" center>
              {' '}
              {siteVisit
                ? siteVisit.statusType?.replace('_', ' ')
                : 'PENDING'}{' '}
            </Tag>
          ) : (
            <Tag background="#EBECEF" color="#494A5A" center>
              {' '}
              NEW{' '}
            </Tag>
          )}
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid
          item
          container
          xs={12}
          lg={12}
          spacing={2}
          sx={{
            display: 'row',
            gridTemplateRows: 'min-content auto',
          }}
        >
          <Grid item xs={6}>
            <InputSearch
              label="Search for Pro:"
              placeholder="Search for Vendor"
              onChange={(value) => {
                setSearchVendor(value)
                debouncedSearch(value)
              }}
              value={!selectedVendor ? searchVendor : ''}
              disabled={!!selectedVendor}
              size="small"
              sx={{ width: '100%' }}
              error={showWarnings && assignTo === 'vendor' && !selectedVendor}
            />
            <Link
              to="/pros"
              target="_blank"
              style={{
                color: colors.blue[700],
                display: 'block',
                padding: '6px 8px',
                textDecoration: 'none',
              }}
            >
              Not seeing your vendor? Click here to make sure they're approved.
            </Link>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="h6"> Date: </Typography>
            <DatePicker
              allowTimePicker
              placeholder="mm/dd/yyyy --:--"
              onChange={(dateSelected: Date) => setDate(dateSelected)}
              value={date}
              style={{ height: '38px', padding: '0px', width: '100%' }}
              size="small"
              error={showWarnings && !date}
            />
            <Box padding="4px 8px 0">
              <Typography color="var(--gray500)">
                Time zone: {estimate?.properties?.address?.timeZone}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid
          item
          container
          xs={12}
          lg={8}
          spacing={2}
          sx={{
            display: 'grid',
            gridTemplateRows: 'min-content auto',
          }}
        >
          {selectedVendor ? (
            <Grid item xs={12}>
              <SelectedVendor
                selectedVendor={selectedVendor}
                unselectVendor={() => {
                  dispatchContext({
                    type: 'SET_SELECTED_VENDOR',
                    payload: null,
                  })
                }}
              />
            </Grid>
          ) : (
            <>
              <Grid item xs={12} spacing={1}>
                <Box display="flex" gap={2} sx={{ padding: '0 8px 12px' }}>
                  <Typography>Assign to:</Typography>
                  <RadioGroup
                    value={assignTo}
                    onChange={(e) =>
                      setAssignTo(e.target.value as TABLE_OPTION)
                    }
                    sx={{ flexDirection: 'row' }}
                  >
                    <FormControlLabel
                      key="vendor"
                      value="vendor"
                      control={<Radio color="primary" />}
                      label="Vendor"
                      sx={{ height: '16px' }}
                    />
                    <FormControlLabel
                      key="fieldManager"
                      value="fieldManager"
                      control={<Radio color="primary" />}
                      label="Field Manager"
                      sx={{ height: '16px' }}
                    />
                  </RadioGroup>
                </Box>
                {assignTo === 'vendor' ? (
                  <div>
                    <Table
                      headers={headersVendors(dispatchContext)}
                      defaultOrder="desc"
                      small
                      totalItems={totalVendors}
                      callToApi={(start, limit) => {
                        fetchVendors(start, limit)
                      }}
                      loading={tableVendorsLoading}
                      //rowsPerPageDefault={PAGE_SIZE}
                      //rowsPerPageOptions={[PAGE_SIZE]}
                      hasPagination={false}
                    >
                      {vendors}
                    </Table>
                    <TablePagination
                      callToApi={(start, limit) => {
                        fetchVendors(start, limit)
                      }}
                      totalItems={totalVendors}
                      rowsPerPageOptions={ROWS_PER_PAGE_EXTENDED}
                    />
                  </div>
                ) : (
                  <>
                    <Table
                      headers={headersManagers(dispatchContext)}
                      defaultOrder="desc"
                      small
                      totalItems={territoryManagers.length}
                      loading={tableManagersLoading}
                      callToApi={(start, limit) => {
                        setPaginatedTerritoryManagers(
                          territoryManagers.slice(start, start + limit)
                        )
                      }}
                      rowsPerPageDefault={5}
                      hasPagination={false}
                    >
                      {[
                        ...paginatedTerritoryManagers.map((manager) => {
                          return {
                            ...manager,
                            selected: selectedFieldManager?.id === manager.id,
                          }
                        }),
                      ]}
                    </Table>
                    <TablePagination
                      callToApi={(start, limit) => {
                        if (start * limit + limit < territoryManagers.length) {
                          setPaginatedTerritoryManagers(
                            territoryManagers.slice(
                              start * limit,
                              start * limit + limit
                            )
                          )
                        } else {
                          setPaginatedTerritoryManagers(
                            territoryManagers.slice(
                              start * limit,
                              territoryManagers.length
                            )
                          )
                        }
                      }}
                      totalItems={territoryManagers.length}
                      rowsPerPageDefault={5}
                      rowsPerPageOptions={ROWS_PER_PAGE_SIMPLE}
                    />
                  </>
                )}
              </Grid>
            </>
          )}
        </Grid>
        <Grid item container xs={12} lg={3.8} spacing={2}>
          <Grid item xs={12} spacing={2}></Grid>
          <Grid item xs={12}>
            <TextFieldLabel
              label="Bid Price:"
              placeholder="Enter Bid Price"
              value={bidPrice}
              type="number"
              size="small"
              onChange={(event) => setBidPrice(event.target.value)}
              labelVariant="h6"
              error={showWarnings && isEmpty(bidPrice)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldLabel
              label="Items description:"
              placeholder="Enter items description..."
              value={siteVisitNotes}
              size="small"
              onChange={(event) => setSiteVisitNotes(event.target.value)}
              multiline
              rows={8}
              labelVariant="h6"
            />
          </Grid>
          <Grid item xs={12} sx={{ paddingBottom: '12px' }}>
            <Typography variant="h6">
              {' '}
              Inspection Document & Attachments{' '}
            </Typography>
            <ScrollWrapper relativeStyle={{ minHeight: '75px' }}>
              <Grid container>
                {estimateFiles.map((file: any) => {
                  return (
                    <Grid item xs={6}>
                      <Box
                        sx={{
                          borderRadius: '4px',
                          padding: '0 4px',
                        }}
                      >
                        <FormControlLabel
                          sx={{ padding: '0px' }}
                          control={
                            <Checkbox
                              color="infoText"
                              onChange={() => {
                                toggleFileCheck(file.id)
                              }}
                            />
                          }
                          checked={file.checked}
                          label={
                            <Box display="flex" gap={1}>
                              <Icon name="Feed" />
                              <MaterialLink
                                href={file.fileUrl}
                                target="_blank"
                                sx={{
                                  maxWidth: '100px',
                                  overflow: 'hidden !important',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  textDecoration: 'none',
                                }}
                                onClick={(event) => {
                                  event.stopPropagation()
                                }}
                              >
                                {file.name}
                              </MaterialLink>
                            </Box>
                          }
                        />
                      </Box>
                    </Grid>
                  )
                })}
              </Grid>
            </ScrollWrapper>
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end" item xs={12}>
          <Grid item container xs={12} lg={10} spacing={2}>
            <Grid item xs={2} md={4} lg={6} />
            <Grid item xs={5} md={4} lg={3}>
              <Button variant="containedLight" onClick={handleClose} fullWidth>
                Cancel
              </Button>
            </Grid>
            <Grid item xs={5} md={4} lg={3}>
              {selectedSiteVisitId ? (
                <Button
                  variant="contained"
                  onClick={handleUpdate}
                  sx={{
                    minWidth: '100% !important',
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: 'var(--blue700)',
                    borderColor: 'var(--blue700)',
                    '&:hover': {
                      backgroundColor: 'var(--blue800)',
                      borderColor: 'var(--blue800)',
                    },
                  }}
                >
                  {buttonLoading ? (
                    <CircularProgress color="info" size="1.4rem" />
                  ) : (
                    'Save & Close'
                  )}
                </Button>
              ) : (
                <ActionsButton
                  icon={<Icon name="ArrowDropDown" />}
                  text="Dispatch & Notify"
                  onClick={handleDispatchAndNotify}
                  iconPosition="end"
                  loading={buttonLoading}
                  actions={[
                    {
                      label: isAssignedToVendor()
                        ? 'Dispatch'
                        : 'Manually Assign',
                      onClick: handleDispatch,
                    },
                    ...(isAssignedToFieldManager()
                      ? []
                      : [
                        { isDivider: true },
                        {
                          label: 'Manually Assign',
                          onClick: handleManualAssign,
                        },
                      ]),
                  ]}
                  variant="contained"
                  sx={{
                    minWidth: '100% !important',
                    display: 'flex',
                    justifyContent: buttonLoading ? 'center' : 'space-between',
                    backgroundColor: 'var(--blue700)',
                    borderColor: 'var(--blue700)',
                    '&:hover': {
                      backgroundColor: 'var(--blue800)',
                      borderColor: 'var(--blue800)',
                    },
                  }}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PrivateLoader>
  )
}

export default SiteVisit
