/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import { toast } from 'react-toastify'
import type { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'

import { Punchlist } from '../../api'
import { errorTextTryingTo } from '../../helpers'
import { employeesActions } from '../actions'
import { employeesTypes, FetchEmployeesAction } from './types'

export function* fetchEmployees({ payload, callback }: FetchEmployeesAction): SagaIterator {
  let success = false
  try {
    const page = payload.page ?? 0
    const size = payload.size ?? 50

    const start = page * size
    const limit = size
    const searchParams = payload.searchParams ?? ''
    const data = yield call(Punchlist.employees.getEmployees, { start, limit, searchParams })

    if (data) {
      yield put(employeesActions.setEmployees(data))
      success = true
    }
    yield call(callback, success)
  } catch (error) {
    yield call(callback, false)
    yield call(toast.error, errorTextTryingTo('get the Employees'))
  }
}

export function* fetchEmployeeById({ payload, callback }: FetchEmployeesAction): SagaIterator {
  let success = false
  try {
    const employeeId = payload.searchParams?.employeeId ?? ''
    const data = yield call(Punchlist.employees.getEmployeeId, employeeId)

    if (data) {
      success = true
    }
    yield call(callback, success, data)
  } catch (error) {
    yield call(callback, false, null)
    yield call(toast.error, errorTextTryingTo('get the employee id'))
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(employeesTypes.FETCH_EMPLOYEES, fetchEmployees)
  yield takeLatest(employeesTypes.FETCH_EMPLOYEE_ID, fetchEmployeeById)
}
