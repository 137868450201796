import { FC, useState } from 'react'

import { Box, Button, CircularProgress, Grid, ListSubheader, Typography } from '@mui/material'
import { isEmpty } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { icons } from 'src/assets'
import { estimateItemsActions } from 'src/ducks/actions'
import { getEstimate } from 'src/ducks/selectors'
import { Item } from 'src/ducks/types'
import { ESTIMATE_VIEW, ITEM_CATEGORY, ITEM_CATEGORY_FOR_FILTERS, ITEM_PRIORITY, ITEM_STATUS, history, sortItemsByCategories } from 'src/helpers/index'
import EstimateItem from '../EstimateItem'
import EstimateItemsContainer from '../EstimateItemsContainer'
import useStyles from './styles'
import { getEstimateItems, getEstimateItemsGroupBy } from 'src/ducks/estimateItems/selectors'


export interface NewItemsEstimatedProps {
  selectedType: ESTIMATE_VIEW
  hideRemoved: boolean
  selectedFilt?: ITEM_CATEGORY_FOR_FILTERS | ITEM_PRIORITY
  fetchEstimateView: (loadings: boolean) => void
  handleShowAlert: (Item: Item) => void
  punchlistPlanner: boolean
  estimateNotApproved: boolean
  getIcon: (item: string, color?: string) => JSX.Element
  serviceable?: boolean
}

const NewItemsEstimated: FC<NewItemsEstimatedProps> = ({ selectedType, hideRemoved, serviceable, selectedFilt, fetchEstimateView, handleShowAlert, punchlistPlanner, estimateNotApproved, getIcon }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { id: estimateId } = history.getParamValues()

  const [loading, setLoading] = useState(false)
  const [itemIdLoading, setItemIdLoading] = useState('')

  const estimate = useSelector(getEstimate())
  const estimateItems = useSelector(getEstimateItems(false)).filter((item: Item) => item.addedPostDelivery && item.category !== ITEM_CATEGORY.EVALUATE)

  const groupedItems = sortItemsByCategories(useSelector(
    getEstimateItemsGroupBy(selectedType, false, selectedFilt !== ITEM_CATEGORY_FOR_FILTERS.ALL ? selectedFilt : undefined, ITEM_STATUS.NEW, estimateItems)
  ))

  const handleUpdateItem = (item: Item) => {
    setLoading(true)
    setItemIdLoading(item.itemId)
    dispatch(
      estimateItemsActions.updateEstimateItem(
        {
          estimateId,
          itemId: item.itemId,
          partialItem: {
            status: item.status === ITEM_STATUS.APPROVED ? ITEM_STATUS.REJECTED : ITEM_STATUS.APPROVED
          }
        },
        () => {
          fetchEstimateView(false)
          setLoading(false)
          setItemIdLoading('')
        }
      )
    )
  }

  if (isEmpty(groupedItems)) return <></>

  return (
    <EstimateItemsContainer
      groupedItems={groupedItems}
      className={classes.root}
      showCollapse
      title={
        <Grid item>
          <Typography className={classes.title}>NEW ITEMS ESTIMATED</Typography>
        </Grid>
      }
    >
      {Object.keys(groupedItems).map((itemKey) => {
        const items = groupedItems[itemKey]
        const itemCategory =
          selectedType === ESTIMATE_VIEW.TYPE
            ? ITEM_CATEGORY[itemKey as keyof typeof ITEM_CATEGORY]
            : itemKey

        return (
          <Box className={classes.categoryContainer} key={itemKey}>
            <ListSubheader className={classes.subHeader}>
              <Box className={classes.categoryHeader}>
                {getIcon(itemKey, 'var(--bosscat-blue-600)')}
                <Typography className={classes.categoryTitle}>
                  {itemCategory}
                </Typography>
              </Box>
            </ListSubheader>

            {items.map((item: Item, index: number) => {
              const itemIsInCart = item.status === ITEM_STATUS.APPROVED
              return (
                <Box key={item.itemId} /* className={itemCategory === ITEM_CATEGORY.EVALUATE ? classes.evaluateContainer : ''} */>
                  <EstimateItem
                    item={item}
                    // showAlert={() => handleShowAlert && handleShowAlert(item)}
                    isPunchlistPlanner={punchlistPlanner}
                    showPrice={item.category !== ITEM_CATEGORY.EXCLUDED.toUpperCase()}
                    showPriceRange={!serviceable}
                    actions={
                      <>
                        {/*         <Typography className={classes.price}>
                          ${round(Number(item.totalPrice), 2)}
                        </Typography> */}
                        {loading && item.itemId === itemIdLoading ?
                          <Box width={86} className={classes.spinnerContainer}>
                            <CircularProgress />
                          </Box>
                          :
                          (item.category === ITEM_CATEGORY.EXCLUDED.toUpperCase()) ?
                            <></> :
                            <Button
                              startIcon={
                                !itemIsInCart && <icons.ShoppingCart />
                              }
                              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                event.stopPropagation()
                                handleUpdateItem(item)
                                // handleClickConsultation(item)
                              }}
                              disabled={loading}
                              className={itemIsInCart ? classes.buttonRemove : classes.buttonAdd}>{itemIsInCart ? 'Remove' : 'Add'}</Button>
                        }
                      </>
                    }
                  />
                </Box>
              )
            })}
          </Box>
        )
      })}
    </EstimateItemsContainer>
  )
}

export default NewItemsEstimated
