import { OrderType } from '../orders/types'

/** TYPES **/
export const siteVisitsTypes = {
  GET_SITE_VISITS: 'FETCH_SITE_VISITS',
  SET_SITE_VISITS: 'SET_SITE_VISITS',
}

export interface GetSiteVisitsParams {
  startDate: string
  endDate: string
  territory: string
  jobId: string
  status: string
  page?: number
  size?: number
  affiliate?: string
}

/** ACTIONS **/

export interface GetSiteVisitsAction {
  type: typeof siteVisitsTypes.GET_SITE_VISITS
  payload: Partial<GetSiteVisitsParams>
  start?: number,
  limit?: number,
  callback?: (succ: boolean) => void
}

export interface SetSiteVisitsAction {
  type: typeof siteVisitsTypes.SET_SITE_VISITS
  payload: SiteVisitsType
}


export type SiteVisitsActions =
  | GetSiteVisitsAction
  | SetSiteVisitsAction

/** REDUCER **/
export type SiteVisitsType = {
  start: number
  limit: number
  total: number
  items: OrderType[]
}
