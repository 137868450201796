import {
  Button, Typography, Box, Grid
} from '@mui/material'
import { ModalInvoiceProps, MaterialInvoiceModalTypes } from './types'

import useStyles from './styles'
import { useState, useEffect, useCallback } from "react"
import Remove from './Remove'
import Add from './Add'
import { useDispatch, useSelector } from "react-redux"
import { jobActions, materialInvoicesActions, ordersActions } from 'src/ducks/actions'
import Modal from 'src/components/UI/CustomUI/molecules/Modal'
import { icons } from '../../../../../../../assets'
import { InvoiceMaterialProvider } from "./context"
import { getJob } from 'src/ducks/selectors'
import MaterialInvoicesTab from '../../../Tabs/Attachments/Tabs/MaterialInvoices'
import { MaterialInvoices } from 'src/ducks/materialInvoice/types'

const ModalMaterialInvoices = ({
  open,
  setOpen,
  onClose = () => { },
}: ModalInvoiceProps) => {
  const classes = useStyles()
  // const materialInvoices = useSelector(getMaterialInvoices())
  const [loading, setLoading] = useState(true)
  const job = useSelector(getJob())
  const dispatch = useDispatch()


  const [contentMaterialInvoice, setContentMaterialInvoice] = useState(MaterialInvoiceModalTypes.MATERIAL_INVOICES_LIST)

  const showList = () => {
    setContentMaterialInvoice(MaterialInvoiceModalTypes.MATERIAL_INVOICES_LIST)
  }

  const handleAdd = () => {
    setContentMaterialInvoice(MaterialInvoiceModalTypes.MATERIAL_INVOICES_ADD)
  }

  const content = () => {
    switch (contentMaterialInvoice) {
      case MaterialInvoiceModalTypes.MATERIAL_INVOICES_LIST: return <MaterialInvoicesTab />
      case MaterialInvoiceModalTypes.MATERIAL_INVOICES_ADD: return <Add onClose={showList} />
      default: return <>No Content</>
    }
  }

  const title = () => {
    switch (contentMaterialInvoice) {
      case MaterialInvoiceModalTypes.MATERIAL_INVOICES_LIST: return (
        <Box display="flex" justifyContent="space-between" alignItems="center" margin="1rem">
          <Typography padding={0} variant='h5' className={classes.Modal__title}>Material Invoices</Typography>
          <Button
            variant='outlined'
            startIcon={<icons.Add style={{ width: '12px', height: '12px', padding: '0px !important' }} />}
            onClick={handleAdd}
          >
            Material Invoice
          </Button>
        </Box>
      )
      case MaterialInvoiceModalTypes.MATERIAL_INVOICES_ADD: return <Typography variant='h5' className={classes.Modal__title}>Add Invoice </Typography>
      default: return <>No Content</>
    }
  }

  const fetchOrders = useCallback(() => {
    if (job?.id) {
      dispatch(ordersActions.fetchOrders({
        params: { jobId: job.id }
      }, (_succ) => {
        setLoading(false)
      }))
    }
  }, [job?.id])

  useEffect(() => {
    fetchOrders()
  }, [job?.id])

  return (
    <InvoiceMaterialProvider>
      <Modal loading={loading} setOpen={setOpen} open={open} title={title()} size={contentMaterialInvoice === MaterialInvoiceModalTypes.MATERIAL_INVOICES_REMOVE ? 'xs' : 'lg'} >
        {content()}
      </Modal>
    </InvoiceMaterialProvider>
  )
}

export default ModalMaterialInvoices
