import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  center: {
    textAlign: 'center',
    paddingTop: '8px'
  },
  actionLink: {
    paddingLeft: '8px',
    textDecoration: 'underline',
    color: theme.palette.infoText.main
  },
  question: {
    color: theme.palette.grey[400],
  }
}))
