import { FC, useEffect, useRef, useState } from 'react'
import { ImagePickerProps } from './types'
import Icon from '../Icon'
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material'

import styles from './styles.module.scss'
import { useIsSmScreen, useIsXsScreen, useIsMdScreen } from 'src/hooks'

const ImagePicker: FC<ImagePickerProps> = ({
  handleDeleteFile = () => null,
  handleUploadFile = () => null,
  imageUrl = null,
  title = '',
  subtitle = '',
  disabled = false,
  multiple,
  height,
  maxWidth,
  hasImageLoader = true,
  component,
  acceptedFiles = ['image/*']
}) => {
  const inputRef = useRef<any>(null)
  const [loading, setLoading] = useState(true)
  const [attempts, setAttempts] = useState(5)
  const [error, setError] = useState(false)

  const [xsScreen] = useIsXsScreen()
  const [smScreen] = useIsSmScreen()
  const [mdScreen] = useIsMdScreen()


  useEffect(() => {
    setLoading(false)
  }, [])

  const handleClick = () => {
    if (!imageUrl && !disabled) {
      inputRef.current.click()
    }
  }

  const handleUpload = (event: any) => {
    if (event.target.files && event.target.files[0] && !disabled) {
      handleUploadFile(event.target.files)
    }
  }

  const handleDelete = (event: any) => {
    if (!disabled) {
      event.target.files = undefined
      handleDeleteFile()
    }
  }

  const handleError = () => {
    if (attempts > 0) {
      setAttempts(attempts - 1)
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
      }, 1000)
    } else {
      setError(true)
      setLoading(false)
    }
  }


  if (component) {
    return (
      <>
        <input
          type='file'
          accept={acceptedFiles.join(',')}
          onChange={handleUpload}
          ref={inputRef}
          disabled={disabled}
          className={styles.hiddenInput}
          multiple={multiple}
        />
        <Box
          className={`${styles.ImagePicker}`}
          sx={!imageUrl && !disabled ? { cursor: 'pointer' } : {}}
          onClick={() => inputRef.current.click()}
          maxWidth={maxWidth || 'inherit'}
        >
          {component}
        </Box>
      </>
    )
  }

  return (
    <>
      {!imageUrl ?
        hasImageLoader && <Box className={styles.ImagePicker__add} sx={{ position: 'relative' }}>
          <input
            type='file'
            accept={acceptedFiles.join(',')}
            onChange={handleUpload}
            ref={inputRef}
            disabled={disabled}
            className={styles.hiddenInput}
            multiple={multiple}
          />
          <Button
            variant="outlined"
            onClick={() => inputRef.current.click()}
            startIcon={<Icon name="Add" width=".8rem" />}
          >
            Add Images
          </Button>
        </Box>
        :
        <Box
          className={`${styles.ImagePicker}`}
          sx={!imageUrl && !disabled ? { cursor: 'pointer' } : {}}
          onClick={handleClick}
          maxWidth={maxWidth || 'inherit'}
        >
          <Box width="100%" height='100%'>
            {loading
              ? <CircularProgress color='primary' size={24} />
              : error
                ? imageUrl.includes('blob') ?
                  <Box display="flex" gap={1}>
                    <Icon name="Description" />
                    <Typography
                      variant='body1'
                    >
                      {imageUrl.split('/').pop()}
                    </Typography>
                  </Box>
                  : <img alt='' src={'./'} />
                : <img
                  src={imageUrl}
                  onError={handleError}
                  className={styles.ImagePicker__img}
                  alt=''
                  style={{
                    height: height ? height : (xsScreen ? '40vw' : smScreen ? '24vw' : mdScreen ? '16vw' : '6vw'),
                  }} />}
            {!disabled && (
              <Icon
                name="CloseRounded"
                onClick={handleDelete}
                className={styles.ImagePicker__deleteButton}
                width={14}
                background="var(--error-color)"
                color="white"
              />
            )}
            {title && title}
            {subtitle && subtitle}
          </Box>
        </Box>
      }
    </>
  )
}

export default ImagePicker
