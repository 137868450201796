import { format } from 'date-fns-tz'
import { formatTimestampDate } from '.'
import enUS from 'date-fns/locale/en-US'

export const formatDateTimeZone = (
  timeStamp: number,
  timeZone: string,
  formatDate: string
): string => {
  if (timeStamp !== null && timeStamp !== undefined) {
    // Get the default time zone if timeZone is not provided
    const defaultTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const tz = timeZone || defaultTimeZone;

    const date = formatTimestampDate(timeStamp, tz);
    const result = format(date, formatDate, {
      timeZone: tz,
      locale: enUS,
    });
    return result;
  }
  return '';
};

export const dateToUTC = (date: Date): Date => {
  var dateUTC = new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  )
  return dateUTC
}
