import { makeStyles } from '@mui/styles'
import { Theme } from '../../../../UI'

export default makeStyles((theme: Theme) => ({
  root: {
    display: 'grid !important',
    flexWrap: 'wrap',
    width: '100%',
    gap: '1rem'
  },
  rootButton: {
    flexDirection: 'column-reverse'
  },
  slider: {
    borderRadius: '8px',
    '@global': {
      '.swiper-slide': {
        height: 'auto',
        '@global': {
          'img': {
            height: '100%',
            objectFit: 'cover',
          }
        }
      }
    }
  }
}))
