import {
  AlignType,
  HeaderType,
} from 'src/components/UI/CustomUI/organisms/Table/types'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import { Grid, Typography } from '@mui/material'

const headers: HeaderType[] = [
  {
    id: 'name',
    label: 'Attachments',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <PictureAsPdfIcon style={{ width: '16px', height: '16px', marginTop: '4px' }} />
          </Grid>
          <Grid item>
            <Typography fontSize="14px" lineHeight="22px">
              {row.name}
            </Typography>
          </Grid>
        </Grid>
      )
    },
  },
]

export default headers
