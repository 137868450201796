/* eslint-disable react-hooks/exhaustive-deps */
import { useState, FC, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty, isEqual } from 'lodash'

import {
  Box,
  BoxContainer,
  Grid,
  SelectInput,
  TitleFirst,
  LabeledText,
  ActionsButton,
  TextFieldLabel,
} from 'src/components/UI'
import DatePicker from 'src/components/UI/CustomUI/atoms/DatePicker'

import {
  getEmployeesByRole,
  getJob,
  getTerritoriesSelector,
} from 'src/ducks/selectors'
import {
  companiesActions,
  employeesActions,
  jobActions,
} from 'src/ducks/actions'

import { UpdateRequest } from '~api/types'
import { InformationTabsProps } from '../../types'
import {
  AFFILIATE_TYPE_OPTIONS,
  EMPLOYEE_ROLE_TYPE,
  formatDateTimestamp,
  formatTimestampDate,
  getDifferenceBetweenObjects,
  STATUS,
  updateObjectByPath,
} from 'src/helpers'
import { getCompanies, getCompaniesForMultipleField, getCompaniesMultipleField } from 'src/ducks/companies/selectors'
import { PrivateLoader } from 'src/components/templates'

const Assignments: FC<InformationTabsProps> = ({ isChanged }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [pageLoading, setPageLoading] = useState(false)
  const [hasUpdatedData, setHasUpdatedData] = useState(false)

  const companies = useSelector(getCompanies)
  const territoriesOptions = useSelector(getTerritoriesSelector)
  const affiliatesStages = useSelector(getCompaniesMultipleField)
  const territoryManagers = useSelector(
    getEmployeesByRole(EMPLOYEE_ROLE_TYPE.TERRITORY_MANAGER)
  )
  const territorySuccessManagers = useSelector(
    getEmployeesByRole(EMPLOYEE_ROLE_TYPE.TERRITORY_SUCCESS_MANAGER)
  )
  const supervisors = useSelector(
    getEmployeesByRole(EMPLOYEE_ROLE_TYPE.TERRITORY_SUPERVISOR)
  )
  const clientPersons = useSelector(
    getEmployeesByRole(EMPLOYEE_ROLE_TYPE.TERRITORY_CUSTOMER_CARE)
  )
  const superIntendents = useSelector(
    getEmployeesByRole(EMPLOYEE_ROLE_TYPE.TERRITORY_SUPERINTENDENT)
  )
  const clientProjectManagers = useSelector(
    getEmployeesByRole(EMPLOYEE_ROLE_TYPE.MANAGER)
  )
  const bdManagers = useSelector(
    getEmployeesByRole(EMPLOYEE_ROLE_TYPE.TERRITORY_BUSINESS_DEV_MANAGER)
  )

  const job = useSelector(getJob())
  const [jobInfo, setJobInfo] = useState(job)

  const disableInputs =
    job?.status === STATUS.COMPLETE || job?.status === STATUS.CLOSED

  const fetchServices = useCallback(() => {
    dispatch(
      employeesActions.fetchEmployees(
        { page: 0, size: 150, searchParams: { approved: true } },
        (_succ: boolean) => {
          setLoading(false)
        }
      )
    )
  }, [dispatch])

  useEffect(() => {
    if (isEmpty(affiliatesStages)) {
      setPageLoading(true)
      dispatch(
        companiesActions.fetchCompanies({}, (_succ: boolean) =>
          setPageLoading(false)
        )
      )
    }
  }, [affiliatesStages])

  useEffect(() => {
    fetchServices()
    setJobInfo(job)
  }, [fetchServices, job])

  useEffect(() => {
    setHasUpdatedData(!isEqual(jobInfo, job))
  }, [jobInfo, job])

  const handleOnChangeValue = (path: string, newValue: string | number) => {
    setJobInfo(updateObjectByPath({ obj: jobInfo, path, value: newValue }))
  }

  const onSubmit = () => {
    setLoading(true)
    const jobDifferences: UpdateRequest = getDifferenceBetweenObjects(
      jobInfo,
      job,
      'replace'
    )
    dispatch(
      jobActions.updateJobByPath(jobDifferences, (succ: boolean) => {
        setLoading(false)
      })
    )
  }

  return (
    <PrivateLoader loading={pageLoading}>
      <>
        <Box
          display="flex"
          flexDirection="row"
          gap={2}
          justifyContent="space-between"
        >
          <BoxContainer
            borderColor="#FFFFFF"
            sx={{
              width: '100%',
              height: '340px',
              justifyContent: 'flex-start',
            }}
            title={
              <TitleFirst
                alignItems="center"
                black="Assigned"
                blackVariant="h5"
                lineHeight="24px"
              />
            }
          >
            <Grid container spacing={1} sx={{ paddingLeft: '0.25rem' }}>
              <Grid item xs={6}>
                <LabeledText labelValue="Territory:" />
                <SelectInput
                  value={territoriesOptions.find(
                    (territory) =>
                      territory.key === jobInfo?.properties?.territory?.id
                  )}
                  options={territoriesOptions}
                  size="small"
                  labelVariant="h6"
                  onChange={(newValue) =>
                    handleOnChangeValue('properties/territory/id', newValue.key)
                  }
                  disabled={disableInputs}
                />
              </Grid>
              <Grid item xs={6}>
                <LabeledText labelValue="Affiliate:" />
                <SelectInput
                  //label="Affiliate:"
                  value={
                    affiliatesStages.find(
                      (affiliate) => affiliate.key === jobInfo?.properties?.affiliate

                    )
                  }
                  options={affiliatesStages}
                  size="small"
                  labelVariant="h6"
                  onChange={(newValue) =>
                    handleOnChangeValue('properties/affiliate', newValue.key)
                  }
                  disabled={disableInputs}
                />
              </Grid>
              <Grid item xs={12}>
                <LabeledText labelValue="Channel:" />
                <SelectInput
                  value={AFFILIATE_TYPE_OPTIONS.find((affiliateType) => affiliateType.key === jobInfo?.properties.affiliateType)}
                  options={AFFILIATE_TYPE_OPTIONS}
                  size="small"
                  labelVariant="h6"
                  onChange={(newValue) => handleOnChangeValue('properties/affiliateType', newValue.key)}
                  disabled={disableInputs}
                />
              </Grid>
              <Grid item xs={12}>
                <LabeledText labelValue="Access Info:" />
                <TextFieldLabel
                  value={jobInfo?.properties.accessDetails}
                  label=""
                  multiline={true}
                  fullWidth={true}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleOnChangeValue(
                      'properties/accessDetails',
                      event.target.value
                    )
                  }
                  name="access-info-assignments"
                  type="text"
                  id="access-info-assignments"
                />
              </Grid>
              <Grid item xs={12}>
                <LabeledText labelValue="Lockbox Details:" />
                <TextFieldLabel
                  value={jobInfo?.properties.lockBoxDetails}
                  label=""
                  multiline={true}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleOnChangeValue(
                      'properties/lockBoxDetails',
                      event.target.value
                    )
                  }
                  name="lockbox-details-assignments"
                  type="text"
                  id="lockbox-details-assignments"
                />
              </Grid>
            </Grid>
          </BoxContainer>
          <BoxContainer
            borderColor="#FFFFFF"
            sx={{
              width: '100%',
              height: '340px',
              justifyContent: 'flex-start',
            }}
            title={
              <TitleFirst
                alignItems="center"
                black="Assign & Adjustments"
                blackVariant="h5"
                lineHeight="24px"
              />
            }
          >
            <Grid container spacing={1} sx={{ paddingLeft: '0.25rem' }}>
              <Grid item xs={6}>
                <LabeledText labelValue="Territory Manager:" />
                <SelectInput
                  //label="Territory Manager:"
                  value={territoryManagers.find(
                    (employee) => employee.key === jobInfo?.territoryManagerId
                  )}
                  options={territoryManagers}
                  size="small"
                  onChange={(newValue) =>
                    handleOnChangeValue('territoryManagerId', newValue.key)
                  }
                  labelVariant="h6"
                  disabled={disableInputs}
                />
              </Grid>
              <Grid item xs={6}>
                <LabeledText labelValue="Success Manager:" />
                <SelectInput
                  //label="Success Manager:"
                  value={
                    territorySuccessManagers.find(
                      (employee) => employee.key === jobInfo?.successManagerId
                    ) || null
                  }
                  options={territorySuccessManagers}
                  size="small"
                  labelVariant="h6"
                  onChange={(newValue) => {
                    handleOnChangeValue('successManagerId', newValue.key)
                  }}
                  disabled={disableInputs}
                />
              </Grid>
              <Grid item xs={6}>
                <LabeledText labelValue="Supervisor:" />
                <SelectInput
                  //label="Supervisor:"
                  value={supervisors.find(
                    (supervisor) => supervisor.key === jobInfo?.supervisorId
                  )}
                  options={supervisors}
                  size="small"
                  labelVariant="h6"
                  onChange={(newValue) =>
                    handleOnChangeValue('supervisorId', newValue.key)
                  }
                  disabled={disableInputs}
                />
              </Grid>
              <Grid item xs={6}>
                <LabeledText labelValue="Client person:" />
                <SelectInput
                  //label="Client person:"
                  value={clientPersons.find(
                    (client) => client.key === jobInfo?.customerCareEmployeeId
                  )}
                  options={clientPersons}
                  size="small"
                  labelVariant="h6"
                  onChange={(newValue) =>
                    handleOnChangeValue('customerCareEmployeeId', newValue.key)
                  }
                  disabled={disableInputs}
                />
              </Grid>
              <Grid item xs={6}>
                <LabeledText labelValue="Superintendent:" />
                <SelectInput
                  //label="Superintendent:"
                  value={
                    superIntendents.find(
                      (territory) => territory.key === jobInfo?.superintendentId
                    ) || null
                  }
                  options={superIntendents}
                  size="small"
                  labelVariant="h6"
                  onChange={(newValue) =>
                    handleOnChangeValue('superintendentId', newValue.key)
                  }
                  disabled={disableInputs}
                />
              </Grid>
              <Grid item xs={6}>
                <LabeledText labelValue="Client Project Manager:" />
                {/* <TextFieldLabel
                  value={jobInfo?.clientProjectManagerId}
                  label=""
                  fullWidth={true}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleOnChangeValue(
                      'clientProjectManagerId',
                      event.target.value
                    )
                  }
                  name="client-project-manager-assignments"
                  type="text"
                  id="client-project-manager-assignments"
                /> */}
                <SelectInput
                  //label="Client person:"
                  value={clientProjectManagers.find(
                    (client) => client.key === jobInfo?.clientProjectManagerId
                  )}
                  options={clientProjectManagers}
                  size="small"
                  labelVariant="h6"
                  onChange={(newValue) =>
                    handleOnChangeValue('clientProjectManagerId', newValue.key)
                  }
                  disabled={disableInputs}
                />
              </Grid>
              <Grid item xs={6}>
                <LabeledText labelValue="BD Manager:" />
                <SelectInput
                  //label="BD Manager:"
                  value={bdManagers.find(
                    (manager) =>
                      manager.key === jobInfo?.businessDevelopmentManagerId
                  )}
                  options={bdManagers}
                  size="small"
                  labelVariant="h6"
                  onChange={(newValue) =>
                    handleOnChangeValue(
                      'businessDevelopmentManagerId',
                      newValue.key
                    )
                  }
                  disabled={disableInputs}
                />
              </Grid>
            </Grid>
          </BoxContainer>

          <BoxContainer
            borderColor="#FFFFFF"
            sx={{
              width: '100%',
              height: '340px',
              justifyContent: 'flex-start',
            }}
            title={
              <TitleFirst
                alignItems="center"
                black="Dates"
                blackVariant="h5"
                lineHeight="24px"
              />
            }
          >
            <Grid
              container
              direction="column"
              justifyContent="space-between"
              spacing={2}
              sx={{ paddingLeft: '0.25rem' }}
            >
              <Grid item>
                <Grid
                  container
                  direction="column"
                  justifyContent="space-between"
                  spacing={2}
                >
                  <Grid item xs={6}>
                    <Grid container justifyContent="space-between" spacing={2}>
                      <Grid item xs={6}>
                        <LabeledText labelValue="Delivery Date:" />
                        <DatePicker
                          name="estimateDeliveredOn"
                          value={
                            jobInfo?.properties.estimateDeliveredOn
                              ? formatTimestampDate(
                                jobInfo?.properties.estimateDeliveredOn
                              )
                              : null
                          }
                          onChange={(selectedDate) =>
                            handleOnChangeValue(
                              'properties/estimateDeliveredOn',
                              formatDateTimestamp(selectedDate)
                            )
                          }
                          size="small"
                          style={{ height: '30px', padding: '0px' }}
                          disabled={disableInputs}
                          align="right"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <LabeledText labelValue="Closing Date:" />
                        <DatePicker
                          name="closingDate"
                          value={
                            jobInfo?.properties.closingDate
                              ? formatTimestampDate(
                                jobInfo?.properties.closingDate
                              )
                              : null
                          }
                          onChange={(selectedDate) =>
                            handleOnChangeValue(
                              'properties/closingDate',
                              new Date(selectedDate).setHours(12) / 1000
                            )
                          }
                          size="small"
                          style={{ height: '30px', padding: '0px' }}
                          disabled={disableInputs}
                          align="right"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container justifyContent="space-between" spacing={2}>
                      <Grid item xs={6}>
                        <LabeledText labelValue="Deadline:" />
                        <DatePicker
                          value={
                            jobInfo?.dueOn
                              ? formatTimestampDate(jobInfo?.dueOn)
                              : null
                          }
                          onChange={(selectedDate) => {
                            handleOnChangeValue(
                              'dueOn',
                              formatDateTimestamp(selectedDate)
                            )
                          }}
                          size="small"
                          style={{ height: '30px', padding: '0px' }}
                          disabled={disableInputs}
                          align="right"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <LabeledText labelValue="Completion ETA:" />
                        <DatePicker
                          value={
                            jobInfo?.expectedCompletedOn
                              ? formatTimestampDate(
                                jobInfo?.expectedCompletedOn
                              )
                              : null
                          }
                          onChange={(selectedDate) => {
                            handleOnChangeValue(
                              'expectedCompletedOn',
                              formatDateTimestamp(selectedDate)
                            )
                          }}
                          size="small"
                          style={{ height: '30px', padding: '0px' }}
                          disabled={disableInputs}
                          align="right"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Grid
                  container
                  direction="column"
                  justifyContent="space-between"
                  spacing={2}
                >
                  <Grid item xs={6}>
                    <Grid container justifyContent="space-between" spacing={2}>
                      <Grid item xs={6}>
                        <LabeledText labelValue="Scheduling Start:" />
                        <DatePicker
                          value={
                            jobInfo?.schedulingStartDate
                              ? formatTimestampDate(
                                jobInfo?.schedulingStartDate
                              )
                              : null
                          }
                          onChange={(selectedDate) => {
                            handleOnChangeValue(
                              'schedulingStartDate',
                              formatDateTimestamp(selectedDate)
                            )
                          }}
                          size="small"
                          style={{ height: '30px', padding: '0px' }}
                          disabled={disableInputs}
                          align="right"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <LabeledText labelValue="Scheduling End:" />
                        <DatePicker
                          value={
                            jobInfo?.schedulingEndDate
                              ? formatTimestampDate(jobInfo?.schedulingEndDate)
                              : null
                          }
                          onChange={(selectedDate) => {
                            handleOnChangeValue(
                              'schedulingEndDate',
                              formatDateTimestamp(selectedDate)
                            )
                          }}
                          size="small"
                          style={{ height: '30px', padding: '0px' }}
                          disabled={disableInputs}
                          align="right"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container justifyContent="space-between" spacing={2}>
                      <Grid item xs={6}>
                        <LabeledText labelValue="Pre-Con Inspection:" />
                        <DatePicker
                          value={
                            jobInfo?.preConInspectionDate
                              ? formatTimestampDate(
                                jobInfo?.preConInspectionDate
                              )
                              : null
                          }
                          onChange={(selectedDate) => {
                            handleOnChangeValue(
                              'preConInspectionDate',
                              formatDateTimestamp(selectedDate)
                            )
                          }}
                          size="small"
                          style={{ height: '30px', padding: '0px' }}
                          disabled={disableInputs}
                          align="right"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <LabeledText labelValue="Final Walktrough:" />
                        <DatePicker
                          value={
                            jobInfo?.finalWalkthroughDate
                              ? formatTimestampDate(
                                jobInfo?.finalWalkthroughDate
                              )
                              : null
                          }
                          onChange={(selectedDate) => {
                            handleOnChangeValue(
                              'finalWalkthroughDate',
                              formatDateTimestamp(selectedDate)
                            )
                          }}
                          size="small"
                          style={{ height: '30px', padding: '0px' }}
                          disabled={disableInputs}
                          align="right"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </BoxContainer>
        </Box>
        {hasUpdatedData && (
          <ActionsButton
            variant="contained"
            sx={{
              position: 'fixed',
              height: '40px !important',
              width: '100PX',
              bottom: '40px',
              left: '40px',
            }}
            loading={loading}
            text="Save changes"
            onClick={onSubmit}
          />
        )}
      </>
    </PrivateLoader>
  )
}

export default Assignments
