import { SelectInputOption } from 'src/components/UI/CustomUI/atoms/SelectInput/types'
import { getOptionsFromArray, getOptionsFromArrayMultiple } from 'src/helpers'
import storeInfo from '../store'
import { Territory } from '../types'

export const getTerritories = (): Territory[] => {
  const { territories: { territories } } = storeInfo.store.getState()
  return territories?.items || []
}

export const getOnlyTerritoriesForDropdown = (): SelectInputOption[] => {
  const { territories: { territories } } = storeInfo.store.getState()
  const flattenedData = getOptionsFromArray(territories?.items as unknown as Territory[] || [], { key: 'id', label: 'title' })
  return flattenedData
}

export const getTerritoriesForDropdown = (): SelectInputOption[] => {
  const { territories: { territories } } = storeInfo.store.getState()
  const flattenedData = getOptionsFromArray(territories?.items as unknown as Territory[] || [], { key: 'id', label: 'title' }, { key: 'all', label: 'All Territories' })
  return flattenedData
}

export const getTerritoriesSelector = (): SelectInputOption[] => {
  const { territories: { territories } } = storeInfo.store.getState()
  const flattenedData = getOptionsFromArray(territories?.items as unknown as Territory[] || [], { key: 'id', label: 'title' }, { key: 'all', label: 'All Territories' })
  return flattenedData
}

export const getTerritoriesMultipleField = (): SelectInputOption[] => {
  const { territories: { territories } } = storeInfo.store.getState()
  const flattenedData = getOptionsFromArrayMultiple(territories?.items as unknown as Territory[] || [], { key: 'id', label: 'title' }, { key: 'all', label: 'All Territories' })
  return flattenedData
}
