import { FC } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { EditablePanelProps } from './types'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import { useSelector } from 'react-redux'
import { employeeIsAdmin, getEmployeeRoles } from 'src/ducks/selectors'
import { EMPLOYEE_ROLE_TYPE } from 'src/helpers'

const EditablePanel: FC<EditablePanelProps> = ({ data, openModal }) => {
  const isAdmin = useSelector(employeeIsAdmin)
  const employeeRoles = useSelector(getEmployeeRoles)
  const employeeIsManager = 
    employeeRoles.some((role) => role.roleType === EMPLOYEE_ROLE_TYPE.MANAGER) ||
    employeeRoles.some((role) => role.roleType === EMPLOYEE_ROLE_TYPE.TERRITORY_CUSTOMER_CARE) ||
    employeeRoles.some((role) => role.roleType === EMPLOYEE_ROLE_TYPE.TERRITORY_BUSINESS_DEV_MANAGER)

  return (
    <Box>
      {
        Object.keys(data).map((key, index) => (
          <Box key={index} display='flex' flexDirection='row' justifyContent='space-between'>
            <Box display='flex'>
              <Typography color='GrayText' fontSize='1rem' paddingTop='0.5rem' marginRight='0.5rem'>
                {`${data[key].label}:`}
              </Typography>
              <Typography fontSize='1rem' paddingTop='0.5rem'>
                {`${data[key].value || '-'}`}
              </Typography>
            </Box>

            {index === 0 && <Button
              variant='containedLight'
              color='primary'
              sx={{ height: '30px' }}
              onClick={openModal}
              disabled={!isAdmin && !employeeIsManager}
            >
              Edit
              <Icon name="Edit" sx={{ marginLeft: '.5rem' }} />
            </Button>}
          </Box>
        ))
      }
    </Box >
  )
}

export default EditablePanel
