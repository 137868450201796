import { Notification, NotificationItem } from '../types'

/** TYPES **/
export const notifTypes = {
  FETCH_NOTIFICATIONS: 'FETCH_NOTIFICATIONS',
  SET_NOTIFICATIONS: 'SET_NOTIFICATIONS',
  UPDATE_NOTIFICATION:'UPDATE_NOTIFICATION'
}

/** ACTIONS **/
export interface FetchAction {
  type: typeof notifTypes.FETCH_NOTIFICATIONS
  payload: {
    page?: number,
    size?: number,
    searchParams?: Partial<SearchParams>
  }
  callback?: (succ: boolean) => void
}

export interface UpdateNotificationAction {
  type: typeof notifTypes.UPDATE_NOTIFICATION
  notificationId: string
  payload: Partial<NotificationItem>
  callback?: (succ: boolean) => void
}


export interface SetNotificationAction {
  type: typeof notifTypes.SET_NOTIFICATIONS
  payload: Notification
}

export interface SearchParams {
  source?: string,
  unread?: boolean,
  estimateId?: string,
}

export type NotifActions =
  | FetchAction
  | SetNotificationAction
  | UpdateNotificationAction

/** REDUCER **/
export type NotificationType = Notification