import { useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getStatistiscs } from 'src/ducks/selectors'
import { jobsActions } from 'src/ducks/actions'
import Table from 'src/components/UI/CustomUI/organisms/Table'
import headers from './headers'
import moment from 'moment'
import { format } from 'date-fns'

const Week = () => {
  const [tableLoading, setTableLoading] = useState(true)
  const dispatch = useDispatch()
  const statisticsList = useSelector(getStatistiscs())
  const formatDate = 'MM/dd/yyyy'

  const fetchServices = useCallback(() => {
    setTableLoading(true)
    const fistDayOfWeek = moment().startOf('week').add(1, 'days').toDate()
    const lastDayOfWeek = moment().endOf('week').add(2, 'days').toDate()

    dispatch(jobsActions.fetchJobsCounts({
      searchParams: {
        startDate: format(fistDayOfWeek, formatDate),
        endDate: format(lastDayOfWeek, formatDate)
      }
    }, (_succ: boolean) => {
      setTableLoading(false)
    }))
  }, [])

  useEffect(() => {
    fetchServices()
  }, [dispatch, fetchServices])

  return (
    <Table
      headers={headers}
      hasPagination={false}
      loading={tableLoading}
    >
      {statisticsList ?? []}
    </Table>
  )
}

export default Week
