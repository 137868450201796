/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Typography, Box, OutlinedInput } from 'src/components/UI'
import { Checkbox, FormControlLabel } from "@mui/material"
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import DatePicker from 'src/components/UI/CustomUI/atoms/DatePicker'
import { DispatchPriceItem, DispatchPricingModel, OrderType } from 'src/ducks/orders/types'
import { icons } from 'src/assets'
import { formatDateTimestamp, formatTimestampDate, round } from 'src/helpers'
import { getOrder } from 'src/ducks/selectors'
import { orderActions } from 'src/ducks/actions'

const Incentive: FC<{ position: number, item: DispatchPriceItem }> = ({ position, item }) => {
  const dispatch = useDispatch()
  const jobOrder = useSelector(getOrder())
  const orderId = jobOrder.orderId

  const { pricingModel } = jobOrder || {} as OrderType
  const { items } = pricingModel
  const { name, amount, description, deadline } = item
  const [open, setOpen] = useState(false)

  const calculatePricing = (attrs: Partial<DispatchPricingModel>) => {
    if (orderId) {
      dispatch(orderActions.getPricing({ orderId, pricingModel: { ...pricingModel, ...attrs } }))
    }
  }

  const setPricing = (attr: string, value: number) => {
    const newValue = value || 0
    setValue(attr, newValue)
    calculatePricing({
      ...pricingModel,
      items: items.map((item, index) => {
        if (index === position) {
          return { ...item, [attr]: newValue }
        }
        return item
      })
    })
  }

  const setValue = (attr: string, value: any) => {
    dispatch(orderActions.setOrder({
      ...jobOrder,
      pricingModel: {
        ...pricingModel,
        items: items.map((item, index) => {
          if (index === position) {
            return { ...item, [attr]: value }
          }
          return item
        })
      }
    }))
  }

  useEffect(() => {
    if (name === undefined) {
      setOpen(true)
      setValue('name', 'Incentive')
    }
  }, [name])

  const deleteItem = (event: React.SyntheticEvent<EventTarget>) => {
    event.stopPropagation()
    calculatePricing({ ...pricingModel, items: items.filter((_item, index) => index !== position) })
  }

  return (
    <>
      <Grid item xs={8}>
        <FormControlLabel
          control={
            <Checkbox
              checkedIcon={<icons.IndeterminateCheckBoxSharp />}
              icon={<icons.AddBoxSharp />}
              sx={{ color: 'var(--blue700)', '&.Mui-checked': { color: 'var(--blue700)', } }}
              onChange={() => setOpen(!open)}
            />
          }
          checked={open}
          label={(
            <Box display='flex' gap={2}>
              <Typography variant='body2'>{name}</Typography>
              <Box onClick={deleteItem}><Icon name='DeleteOutlined' width={16} color='var(--error-color)' background='var(--gray100)' /></Box>
            </Box>
          )}
        />
        {open && (
          <>
            <Grid container spacing={1} sx={{ paddingLeft: '24px' }}>
              <Grid item xs={8}>
                <OutlinedInput
                  size='small' fullWidth placeholder='Incentive Name'
                  value={name} onChange={(event) => setValue('name', event.target.value)}
                />
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  size='small' fullWidth placeholder='$0.00'
                  value={amount || ''} onChange={(event) => setPricing('amount', parseInt(event.target.value, 10))}
                />
              </Grid>
              <Grid item xs={12}>
                <DatePicker
                  placeholder='mm/dd/yyyy'
                  onChange={(dateSelected: Date) => setValue('deadline', formatDateTimestamp(dateSelected))}
                  value={formatTimestampDate(deadline)}
                  style={{ height: '38px', padding: '0px', width: '100%' }}
                  size="small"
                />
              </Grid>
              <Grid item xs={12}>
                <OutlinedInput
                  size='small' fullWidth placeholder='Provide Description'
                  multiline rows={2}
                  value={description} onChange={(event) => setValue('description', event.target.value)}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
      <Grid item xs={4} textAlign='end'>
        <Typography variant='body1' paddingTop='10px'>
          ${round(amount, 2)}
        </Typography>
      </Grid>
    </>
  )
}

export default Incentive
