import { Box, Typography } from "@mui/material"
import { FC, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import Modal from "src/components/UI/CustomUI/molecules/Modal"
import { ModalActionType } from "src/components/UI/CustomUI/molecules/Modal/types"
import { jobActions } from "src/ducks/actions"
import { getJob } from "src/ducks/selectors"
import { DeleteAttachmentModalProps } from "./types"

const DeleteAttachmentModal: FC<DeleteAttachmentModalProps> = ({ open, setOpen, attachment }) => {
  const dispatch = useDispatch()

  const job = useSelector(getJob())

  const [loading, setLoading] = useState(false)

  const fileIndex = job?.properties?.files.findIndex(file => file.id === attachment.id)

  const modalAction: ModalActionType[] = [
    {
      textButton: 'Cancel',
      variant: 'contained',
      onClick: () => { setOpen(false) }
    },
    {
      textButton: 'Delete',
      variant: 'containedError',
      onClick: () => { updateJob() },
      loading: loading
    }
  ]

  const updateJob = () => {
    setLoading(true)
    dispatch(jobActions.updateJobByPath([{ op: 'remove', path: `/properties/files/${fileIndex}` }],
      (succ: boolean) => {
        setOpen(false)
        setLoading(false)
        succ && toast.success('The file was deleted successfully')
      }))
  }

  return (
    <Modal
      setOpen={setOpen}
      open={open}
      title=''
      actions={modalAction}
      size="xs"
    >
      <Box display="flex" justifyContent="center">
        <Typography variant="h5" align="center" lineHeight="1.4">
          Are you sure you want <br /> to delete
          <Typography variant="h5Bold" marginLeft=".2rem" lineHeight="1.4">{attachment?.description}</Typography>
          ?</Typography>
      </Box>
    </Modal>
  )
}

export default DeleteAttachmentModal