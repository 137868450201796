import { formatParams } from 'src/helpers'
import axios from '../axios'
import { ApiController } from './types'

const base = '/subscriptions'

const apiController: ApiController = {
  getSubscriptions: async ({ start, limit, searchParams }) => {
    const params = formatParams(searchParams)
    return await axios
      .get(`${base}?${params}`, { params: { start, limit } })
      .then(response => response.data)
  },

  getSubscriptionById: async (subscriptionId) =>
    await axios
      .get(`${base}/${subscriptionId}`)
      .then(response => response.data),

  updateSubscription: async (subscriptionId, request) =>
    await axios
      .patch(`${base}/${subscriptionId}`, request)
      .then(response => response.data),

  createJobFromSubscription: async (subscriptionId, request) => {
    return await axios
      .post(`${base}/${subscriptionId}/service`, request)
      .then(response => response.data)
  },
  deleteSubscription: async (subscriptionId) => {
    return await axios
      .delete(`${base}/${subscriptionId}`)
      .then(response => response)
  },
}

export default apiController
