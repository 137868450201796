import { SelectInputOption } from "src/components/UI/CustomUI/atoms/SelectInput/types"

export interface WidgetsContextType {
    // MODAL
    modalType: MODAL_TYPE | null,
    modalOpen: boolean,
}

export enum MODAL_TYPE {
    CREATE = 'CREATE',
    EDIT = 'EDIT',
    DELETE = 'DELETE'
}

export interface DispatchValue {
    attr: keyof WidgetsContextType
    value: any
}

export const dispatchTypes = {
    SET_VALUE: 'SET_VALUE',
    CLOSE_MODAL: 'CLOSE_MODAL',
    OPEN_MODAL: 'OPEN_MODAL',
    DELETE: 'DELETE'
}