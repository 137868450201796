import { FC } from 'react'
import { isMobile } from 'react-device-detect'
import { Box } from '../../../../../UI'
import { InspectionsProps } from './types'
import useStyles from './styles'
import DocumentPreview from 'src/components/UI/CustomUI/molecules/DocumentPreview'

const Inspections: FC<InspectionsProps> = ({ children, modalTitle }) => {
  const count = (children && children.length) || 0
  const classes = useStyles()

  return (
    <Box className={`${classes.root} ${isMobile ? classes.hideScrollbar : ''}`}>
      {count > 0 && (
        <Box className={classes.list}>
          {children.map((file, index) => {
            return (
              <Box
                key={index}
                className={classes.image}
              >
                <DocumentPreview fileURL={file.fileUrl} imagesSlider={children} modalTitle={modalTitle} />
              </Box>
            )
          })}
        </Box>
      )}
    </Box>
  )
}

export default Inspections
