import { Avatar, Box, Button, Grid, Typography } from "@mui/material"
import { FC, useEffect, useState } from "react"
import { icons } from "src/assets"
import BackButton from "src/components/UI/CustomUI/atoms/BackButton"
import ActionsButton from "src/components/UI/CustomUI/molecules/ActionsButton"
import TabNavigation from "src/components/UI/CustomUI/organisms/TabNavigation"
import PrivateLoader from "src/components/templates/Loaders/PrivateLoader"
import Settings from "./Tabs/Settings"
import ZIPCodes from "./Tabs/ZIPCodes"
import VIPs from "./Tabs/VIPS"
import TradeRates from "./Tabs/TradeRates"
import ConfirmDeleteModal from "./Modals/ConfirmDeleteTerritory"
import { useDispatch, useSelector } from "react-redux"
import { employeesActions, territoryActions } from "src/ducks/actions"
import { useParams } from "react-router-dom"
import { getTerritory } from "src/ducks/territory/selectors"
import { getEmployee } from "src/ducks/selectors"
import { EMPLOYEE_ROLE_TYPE } from "src/helpers"

const TABS = [
    {
        label: 'Settings',
        content: <Settings />,
        icon: <icons.Settings />
    },
    {
        label: 'ZIP Codes',
        content: <ZIPCodes disabled={true} />,
        icon: <icons.Map />,
        disabled: true
    },
    {
        label: 'VIP\'s',
        content: <VIPs />,
        icon: <icons.PersonPin />
    },
    {
        label: 'Trade Rates',
        content: <TradeRates disabled={true} />,
        icon: <icons.BuildCircle />
    }
]

const TerritoryView: FC = () => {

    const dispatch = useDispatch()
    const { id } = useParams()
    const territory = useSelector(getTerritory)
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const currentUser = useSelector(getEmployee)
    const isSuperAdmin = currentUser.roles.find(role => role.roleType === EMPLOYEE_ROLE_TYPE.SUPER_ADMIN)


    useEffect(() => {
        setLoading(true)
        dispatch(territoryActions.getTerritory(id ?? '', (_succ: boolean) => {
            setLoading(false)
        }))
        setLoading(true)
        dispatch(employeesActions.fetchEmployees({ searchParams: { role: EMPLOYEE_ROLE_TYPE.TERRITORY_MANAGER, approved: true } }, () => {
            setLoading(false)
        }))

    }, [dispatch])

    return (
        <PrivateLoader loading={loading}>
            <ConfirmDeleteModal open={open} setOpen={setOpen} />
            <Box display='flex' flexDirection='column' gap={2}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box display="flex" justifyContent="flex-start" alignItems="center">
                        <BackButton />
                        <Box display="flex" justifyContent="flex-start" alignItems="center" marginTop={1} marginLeft={1}>
                            <Avatar sx={{ mr: 1 }}></Avatar><Typography variant="h4">{territory?.title ?? ''}</Typography>
                        </Box>
                    </Box>
                    <Button disabled={!isSuperAdmin} variant="containedError" startIcon={<icons.Delete />} onClick={() => setOpen(true)}>
                        Delete Territory
                    </Button>
                </Box>
                <Grid container spacing={2} marginBottom={3}>
                    <Grid item xs={12}>
                        <TabNavigation tabs={TABS} style={{ marginRight: '10px' }} />
                    </Grid>
                </Grid>
            </Box>
        </PrivateLoader>

    )
}

export default TerritoryView