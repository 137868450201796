import { Typography } from '@mui/material'
import { ProgressBar } from 'src/components/UI'
import { AlignType } from 'src/components/UI/CustomUI/organisms/Table/types'
import { formatTimestamp } from 'src/helpers'

const headers = [
  {
    id: 'publicId',
    label: 'Job ID',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <a href={`jobs/${row.id}`} target='_blank' rel='noreferrer'>
          <Typography> {row?.publicId} </Typography>
        </a>
      )
    }
  },
  {
    id: 'fullAddress',
    label: 'Address',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <a href={`jobs/${row.id}`} target='_blank' rel='noreferrer'>
          <Typography> {row?.fullAddress} </Typography>
        </a>
      )
    }
  },
  {
    id: 'status',
    label: 'Status',
    custom: true,
    Element: (row: any): JSX.Element => {
      return (
        <a href={`jobs/${row.id}`} target='_blank' rel='noreferrer'>
          <Typography sx={{ textTransform: 'capitalize' }}> {row?.status?.toLowerCase()} </Typography>
        </a>
      )
    }
  },
  {
    id: 'city', label: 'City'
  },
  {
    id: 'properties.address.state',
    label: 'State',
    custom: true,
    Element: (row: any): JSX.Element => {
      return (
        <a href={`jobs/${row.id}`} target='_blank' rel='noreferrer'>
          <Typography sx={{ textTransform: 'capitalize' }}> {row?.properties?.address?.state} </Typography>
        </a>
      )
    }
  },
  {
    id: 'properties.address.zipCode',
    label: 'Zip',
    custom: true,
    Element: (row: any): JSX.Element => {
      return (
        <a href={`jobs/${row.id}`} target='_blank' rel='noreferrer'>
          <Typography> {row?.properties?.address?.zipCode} </Typography>
        </a>
      )
    }
  },
  {
    id: 'properties.territory.territoryManager',
    label: 'Territory M.',
    custom: true,
    Element: (row: any): JSX.Element => {
      return (
        <a href={`jobs/${row.id}`} target='_blank' rel='noreferrer'>
          <Typography sx={{ textTransform: 'capitalize' }}> {row?.properties?.territory?.territoryManager}</Typography>
        </a>
      )
    }
  },
  {
    id: 'properties.territory.successManager',
    label: 'CSM Name',
    custom: true,
    Element: (row: any): JSX.Element => {
      return (
        <a href={`jobs/${row.id}`} target='_blank' rel='noreferrer'>
          <Typography sx={{ textTransform: 'capitalize' }}> {row?.properties?.territory?.successManager}</Typography>
        </a>
      )
    }
  },
  {
    id: 'createdOn',
    label: 'Approved on',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <a href={`jobs/${row.id}`} target='_blank' rel='noreferrer'>
          <Typography>
            {row?.createdOn ? formatTimestamp(row?.createdOn, 'MM/DD/YYYY') : '-'}
          </Typography>
        </a>
      )
    }
  },
  {
    id: 'dueOn',
    label: 'Due Date',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <a href={`jobs/${row.id}`} target='_blank' rel='noreferrer'>
          <Typography>
            {row?.dueOn ? formatTimestamp(row?.dueOn, 'MM/DD/YYYY') : '-'}
          </Typography>
        </a>
      )
    }
  },
  {
    id: 'percentItemsComplete',
    label: 'Progress',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <a href={`jobs/${row.id}`} target='_blank' rel='noreferrer'>
          <ProgressBar value={row.percentItemsComplete ?? 0} />
        </a>
      )
    }
  },
  {
    id: 'properties.affiliateType',
    label: 'Channel',
    custom: true,
    Element: (row: any): JSX.Element => {
      return (
        <a href={`jobs/${row.id}`} target='_blank' rel='noreferrer'>
          <Typography sx={{ textTransform: 'capitalize' }}> {row?.properties?.affiliateType?.toLowerCase()}</Typography>
        </a>
      )
    }
  },
  {
    id: 'properties.contacts[0].fullName',
    label: 'Client',
    custom: true,
    Element: (row: any): JSX.Element => {
      return (
        <a href={`jobs/${row.id}`} target='_blank' rel='noreferrer'>
          <Typography> {row?.properties?.contacts[0]?.fullName}</Typography>
        </a>

      )
    }
  }
]

export default headers
