import { FC } from 'react'
import { Button, Grid, Typography } from '@mui/material'
import styles from '../../styles.module.scss'
import { PartnerModalProps } from './types'
import Modal from 'src/components/UI/CustomUI/molecules/Modal'
import { useDispatch } from 'react-redux'
import { homemartActions } from 'src/ducks/actions'

const DeletePartnerModal: FC<PartnerModalProps> = ({ open, setOpen, selectedPartner }) => {
  const dispatch = useDispatch()
  
  const handleClose = () => {
    setOpen(false)
  }

  const handleDeletePartner = () => {
    dispatch(
      homemartActions.deletePartner(selectedPartner?.id || '', (succ) => {
        dispatch(homemartActions.fetchPartners({ active: true }))
        handleClose()
      })
    )
  }

  return (
    <Modal open={open} setOpen={setOpen} size="sm">
      <Grid
        container
        spacing={1}
        flexDirection="column"
        style={{ marginTop: '4px' }}
      >
        <Grid item>
          <Typography
            className={styles.Homemart__mFont}
            style={{ color: 'var(--navy-700)' }}
          >
            Delete Partner
          </Typography>
        </Grid>
        <Grid item>
          <Typography>Are you sure to delete this partner?</Typography>
        </Grid>
        <Grid container justifyContent="flex-end" item spacing={2}>
          <Grid item>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={handleDeletePartner}>
              Delete
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  )
}

export default DeletePartnerModal
