import React, { FC } from 'react'
import useStyles from './styles'
import { ContactItemProps } from './types'
import { Grid, Typography, Checkbox } from '../../../../../../../UI'
import { CONTACT_ROLE_TEXT } from 'src/helpers'

const ContactItem: FC<ContactItemProps> = ({
  contact,
  selected,
  setSelected,
}) => {
  const classes = useStyles()
  const { id, firstName, lastName, role } = contact

  const convertRole = (role: string): CONTACT_ROLE_TEXT =>
    CONTACT_ROLE_TEXT[role as keyof typeof CONTACT_ROLE_TEXT]

  return (
    <Grid
      container
      justifyContent="space-around"
      sx={{ flexFlow: 'row', padding: '8px 12px' }}
    >
      <Grid item container flexDirection="column">
        <Typography className={classes.fullName}>
          {`${firstName} ${lastName}`}
        </Typography>
        <Typography className={classes.role}>{convertRole(role || '')}</Typography>
      </Grid>
      <Grid item>
        <Checkbox
          checked={selected}
          onChange={() => setSelected(id as string)}
          color="infoText"
        />
      </Grid>
    </Grid>
  )
}

export default ContactItem
