import storeInfo from '../store'
import { WorkflowsType } from './types'
import { WorkflowItem } from '../types'
import { dayFromTimestamp } from 'src/helpers'

export const getWorkflows = () => (): WorkflowsType => {
  const { workflows } = storeInfo.store.getState()
  return workflows
}

export const getWorkflowsbyStatus = (): WorkflowItem[] => {
  const { workflows } = storeInfo.store.getState()
  const jobTask = workflows.items ? workflows.items?.filter(item => (item?.triggerDate && item.status === 'PENDING' && dayFromTimestamp(item?.triggerDate) === 'TOMORROW') || item.status === 'NEEDS_ATTENTION') : []
  return jobTask
}
