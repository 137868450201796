import { SearchParams } from '../searches/types'
import { ReportsActions, reportsTypes } from './types'

const actions = {
  requestReportsPipeline: (
    payload: {
      searchParams?: Partial<SearchParams>
    }, callback: (succ: boolean, csv: string) => void): ReportsActions => ({
      type: reportsTypes.REQUEST_PIPELINE,
      payload,
      callback,
    }),
  getReportsPipeline: (
    payload: string,
    callback: (succ: boolean, csv: string) => void): ReportsActions => ({
      type: reportsTypes.GET_PIPELINE,
      payload,
      callback,
    }),
}

export default actions
