import { authTypes } from '../auth/types'
import { Notification } from '../types'
import { NotifActions, notifTypes, NotificationType } from './types'

const initialState: NotificationType = {
  start: '',
  limit: '',
  total: '',
  items: []
}

const reducer = (state = initialState, action: NotifActions): NotificationType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case notifTypes.SET_NOTIFICATIONS: {
      return {
        ...state,
        start: (payload as Notification).start,
        limit: (payload as Notification).limit,
        items: (payload as Notification).items,
        total: (payload as Notification).total
      }
    }

    default:
      return state
  }
}

export default reducer
