import { SelectInputOption } from "src/components/UI/CustomUI/atoms/SelectInput/types"
import { OptionType } from "src/components/UI/CustomUI/molecules/AutocompleteSearch/types"
import { CONTACT_ROLE } from "./constants"

export const getOptionsFromArray = (
    data: any[],
    values: { key: string | number, label: string | string[] },
    firstOption?: { key?: string | number, label?: string | number },
    union?: string,
    extraAttributes?: { [key: string]: any }
): SelectInputOption[] => {
    const flattenedData = data?.reduce((acc: any, curr) => {
        return [...acc, (acc = {
            key: curr[values.key],
            label: Array.isArray(values.label) ? `${values.label.map((item) => curr[item]).join(union || ' ')}` : curr[values.label],
            ...extraAttributes
        })]
    }, firstOption ?
        [{ key: firstOption.key, label: firstOption.label }] : [])
    return flattenedData
}

export const getOptionsFromArrayMultiple = (
    data: any[],
    values: { key: string | number, label: string | string[] },
    firstOption?: { key?: string | number, label?: string | number, id?: string }
): SelectInputOption[] => {
    const flattenedData = data?.reduce((acc: any, curr) => {
        return [...acc, (acc = {
            id: curr.id,
            key: curr[values.key],
            label: Array.isArray(values.label) ? `${curr[values.label[0]]} ${curr[values.label[1]]}` : curr[values.label],
            hide: true
        })]
    }, firstOption && firstOption.key && firstOption.label ?
        [{ id: firstOption.id, key: firstOption.key, label: firstOption.label, hide: true, isDivider: true }] : [])
    return flattenedData
}

export const stringToObject = (str: string) => {
    return {
        key: str,
        label: str.slice(0, 1) + str.toLowerCase().slice(1).replaceAll('_', ' ')
    }
}

export const enumToArrayObject = (obj: any) => {
    return Object.keys(obj).map(key => stringToObject(obj[key]))
}


/** Enums to option lists ***************/
export const getContactRoleOpt = (): OptionType[] => {
    return [
        { key: CONTACT_ROLE.LISTING_AGENT, value: 'Selling/Listing Agent' },
        { key: CONTACT_ROLE.BUYERS_AGENT, value: 'Buyer’s Agent' },
        { key: CONTACT_ROLE.BUYERS_CLOSING_COORDINATOR, value: 'Closing Coordinator - Buyer’s Agent' },
        { key: CONTACT_ROLE.SELLERS_CLOSING_COORDINATOR, value: 'Closing Coordinator - Seller’s Agent' },
        { key: CONTACT_ROLE.HOME_BUYER, value: 'Home Buyer' },
        { key: CONTACT_ROLE.HOMEOWNER, value: 'Homeowner' },
        { key: CONTACT_ROLE.HOME_SELLER, value: 'Home Seller' },
        { key: CONTACT_ROLE.INSPECTOR, value: 'Inspector' },
        { key: CONTACT_ROLE.OTHER, value: 'Other' }
    ]
}

/** Enums to option lists ***************/
export const getContactRoleOptions = (): OptionType[] => {
    return [
        { key: CONTACT_ROLE.LISTING_AGENT, value: 'Selling/Listing Agent' },
        { key: CONTACT_ROLE.BUYERS_AGENT, value: 'Buyer’s Agent' },
        { key: CONTACT_ROLE.BUYERS_CLOSING_COORDINATOR, value: 'Closing Coordinator - Buyer’s Agent' },
        { key: CONTACT_ROLE.SELLERS_CLOSING_COORDINATOR, value: 'Closing Coordinator - Seller’s Agent' },
        { key: CONTACT_ROLE.HOMEOWNER, value: 'Homeowner' },
        { key: CONTACT_ROLE.HOME_BUYER, value: 'Home Buyer' },
        { key: CONTACT_ROLE.HOME_SELLER, value: 'Home Seller' },
        { key: CONTACT_ROLE.INSPECTOR, value: 'Inspector' },
        { key: CONTACT_ROLE.OTHER, value: 'Other' }
    ]
}