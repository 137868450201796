import { HeaderType, AlignType } from 'src/components/UI/CustomUI/organisms/Table/types'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import { Checkbox, Grid, Typography } from '../../../../../UI'
import Tag from 'src/components/UI/CustomUI/atoms/Tag'
import { statusTagColor, formatTimestamp, round } from 'src/helpers'
import Modals from '../../Modals'

const headers: HeaderType[] = [
    { id: 'territory', label: 'Territory' },
    {
        id: 'gl',
        label: 'GL',
        custom: true,
        align: 'center' as AlignType,
        Element: (row: any): JSX.Element => {
            return (
                row.gl && <Icon name='CheckCircle' color='var(--green800)' />
            )
            //return <Icon name='CheckCircle' color='var(--green800)' />
        }
    },
    { id: 'glExpiration', label: 'GL Expiration' },
    {
        id: 'wc',
        label: 'Worker’s Com',
        custom: true,
        align: 'center' as AlignType,
        Element: (row: any): JSX.Element => {
            return (
                row.wc && <Icon name='CheckCircle' color='var(--green800)' />
            )
        }
    },
    { id: 'wcExpiration', label: 'WC Expiration' },
    {
        id: 'wcExempt',
        label: 'WC Exempt',
        custom: true,
        align: 'center' as AlignType,
        Element: (row: any): JSX.Element => {
            return (
                <Checkbox
                    checked={row.wcExempt}
                    onChange={row.toogleWcExempt}
                    name='wcExempt'
                    color='infoText'
                />
            )
        }
    }
]

export default headers

export const headersLicenses: HeaderType[] = [
    {
        id: 'licenseType',
        label: 'License Type',
        custom: true,
        align: 'center' as AlignType,
        Element: (row: any): JSX.Element => {
            return (
                <Typography sx={{ fontSize: '12px', fontWeight: 400 }}> {row?.licenseType?.replaceAll('_', ' ')} </Typography>
            )
        }
    },
    {
        id: 'validFromDate',
        label: 'Valid from Date',
        custom: true,
        align: 'center' as AlignType,
        Element: (row: any): JSX.Element => {
            return (
                <Typography sx={{ fontSize: '12px', fontWeight: 400 }}>
                    {row?.validFromDate ? formatTimestamp(row?.validFromDate, 'MM/DD/YYYY') : '-'}
                </Typography>
            )
        }
    },
    {
        id: 'validToDate',
        label: 'Valid to Date',
        custom: true,
        align: 'center' as AlignType,
        Element: (row: any): JSX.Element => {
            return (
                <Typography sx={{ fontSize: '12px', fontWeight: 400 }}>
                    {row?.validToDate ? formatTimestamp(row?.validToDate, 'MM/DD/YYYY') : '-'}
                </Typography>
            )
        }
    },
    {
        id: 'reviewStatus',
        label: 'Review Status',
        custom: true,
        Element: (row: any): JSX.Element => {
            const { color, background } = statusTagColor(row?.reviewStatus)
            return (
                <Grid container alignItems='center' spacing={1} direction='column'>
                    <Grid item width='100%'>
                        <Tag center color={color} background={background}>{row?.reviewStatus?.length && row?.reviewStatus[0] + row?.reviewStatus?.toLowerCase().slice(1)} </Tag>
                    </Grid>
                </Grid>
            )
        },
        maxWidth: 80
    },
    {
        id: 'actions',
        label: 'Actions',
        custom: true,
        Element: (row: any): JSX.Element => {
            return (
                <>
                    {row && <Modals item={row} />}
                </>
            )
        },
        maxWidth: 65
    }
]

export const headersInsurance: HeaderType[] = [
    {
        id: 'insuranceType',
        label: 'Insurance Type',
        custom: true,
        align: 'center' as AlignType,
        Element: (row: any): JSX.Element => {
            return (
                <Typography sx={{ fontSize: '12px', fontWeight: 400 }}> {row?.insuranceType.replaceAll('_', ' ')} </Typography>
            )
        }
    },
    {
        id: 'insuaranceCompany',
        label: 'Insurance Company',
        custom: true,
        align: 'center' as AlignType,
        Element: (row: any): JSX.Element => {
            return (
                <Typography sx={{ fontSize: '12px', fontWeight: 400 }}> {row?.insuranceCompanyName?.replaceAll('_', ' ')} </Typography>
            )
        }
    },
    {
        id: 'amountOfCoverage',
        label: 'Amount of Coverage',
        custom: true,
        align: 'center' as AlignType,
        Element: (row: any): JSX.Element => {
            return (
                <Typography sx={{ fontSize: '12px', fontWeight: 400 }}> {round(row?.amountOfCoverage, 2)} </Typography>
            )
        }
    },
    {
        id: 'additionalInsured',
        label: 'Additional Insured',
        custom: true,
        align: 'left' as AlignType,
        Element: (row: any): JSX.Element => {
            return (
                row.additionalInsured && <Icon name='CheckCircle' color='var(--green800)' />
            )
        }
    },
    {
        id: 'validFromDate',
        label: 'Valid from Date',
        custom: true,
        align: 'center' as AlignType,
        Element: (row: any): JSX.Element => {
            return (
                <Typography sx={{ fontSize: '12px', fontWeight: 400 }}>
                    {row?.validFromDate ? formatTimestamp(row?.validFromDate, 'MM/DD/YYYY') : '-'}
                </Typography>
            )
        }
    },
    {
        id: 'validToDate',
        label: 'Valid to Date',
        custom: true,
        align: 'center' as AlignType,
        Element: (row: any): JSX.Element => {
            return (
                <Typography sx={{ fontSize: '12px', fontWeight: 400 }}>
                    {row?.validToDate ? formatTimestamp(row?.validToDate, 'MM/DD/YYYY') : '-'}
                </Typography>
            )
        }
    },
    {
        id: 'reviewStatus',
        label: 'Review Status',
        custom: true,
        Element: (row: any): JSX.Element => {
            const { color, background } = statusTagColor(row?.reviewStatus)
            return (
                <Grid container alignItems='center' spacing={1} direction='column'>
                    <Grid item width='100%'>
                        <Tag center color={color} background={background}>{row?.reviewStatus[0] + row?.reviewStatus?.toLowerCase().slice(1)} </Tag>
                    </Grid>
                </Grid>
            )
        },
        maxWidth: 80
    },
    {
        id: 'actions',
        label: 'Actions',
        custom: true,
        Element: (row: any): JSX.Element => {
            return (
                <>
                    {row && <Modals item={row} />}
                </>
            )
        },
        maxWidth: 65
    }
]
