import { authTypes } from '../auth/types'
import { SubscriptionActions, SubscriptionType, subscriptionTypes } from './types'

const initialState: SubscriptionType = null

const reducer = (state = initialState, action: SubscriptionActions): SubscriptionType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case subscriptionTypes.SET_SUBSCRIPTION: {
      return payload as SubscriptionType
    }

    default:
      return state
  }
}

export default reducer
