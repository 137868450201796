import { FC } from 'react'
import { Box, Checkbox, Typography } from 'src/components/UI/MaterialUI'
import useStyles from './styles'
import { CustomCheckboxProps } from './types'

const CustomCheckbox: FC<CustomCheckboxProps> = ({ checked, label, bgcolor, onClick }) => {
  const styles = useStyles()

  return (
    <Box className={styles.root} bgcolor={bgcolor}>
      <Checkbox checked={!!checked} onClick={onClick} />
      <Typography className={styles.optionDesc}>{label || ''}</Typography>
    </Box>
  )
}

export default CustomCheckbox
