import { FC, useEffect, useState, useRef, useCallback } from 'react'
import { ColumnLaneProps } from './types'
import { useDispatch, useSelector } from 'react-redux'
import { groupedOrdersActions } from 'src/ducks/actions'
import { GroupedOrder } from 'src/ducks/types'
import {
  getAllCompaniesForMultipleFieldWithoutAllOption,
  getEmployee,
  getGroupedOrders,
  getTerritoryManagersUsers,
  getTerritorySuccessManagersUsers,
} from 'src/ducks/selectors'
import { Box, CircularProgress, Grid } from 'src/components/UI'
import ColumnHeader from '../ColumnHeader'
import ColumnCard from '../ColumnCard'

import useStyles from './styles'
import { getJobsFilter } from 'src/ducks/filters/selector'
import { SearchParams } from 'src/ducks/searches/types'
import { debounce } from 'lodash'
import { EMPLOYEE_ROLE_TYPE } from 'src/helpers'

const SECTION_COUNT = 20

const ColumnLane: FC<ColumnLaneProps> = ({
  stage,
  searchParams,
  headerBackground,
  laneBackground,
}) => {
  const dispatch = useDispatch()

  const styles = useStyles()

  const groupedOrders = useSelector(getGroupedOrders(stage))
  const jobsFilter = useSelector(getJobsFilter())
  const territorySuccessManagersForDropdown = useSelector(
    getTerritorySuccessManagersUsers
  )
  const currentUser = useSelector(getEmployee)
  const territoryManagersForDropdown = useSelector(getTerritoryManagersUsers)
  const affiliatesStages = useSelector(
    getAllCompaniesForMultipleFieldWithoutAllOption
  )

  const [loading, setLoading] = useState<boolean>(false)

  const { csmUser, tmUser, orders, affiliates, territory } = jobsFilter
  const indexOfCSM = territorySuccessManagersForDropdown.findIndex(
    (user) => user.key === currentUser.id
  )
  const isManager = currentUser.roles.find(
    (role) => role.roleType === EMPLOYEE_ROLE_TYPE.TERRITORY_SUCCESS_MANAGER
  )
  const csmUsers =
    jobsFilter.csmUser ??
    (isManager
      ? territorySuccessManagersForDropdown[indexOfCSM]
      : territorySuccessManagersForDropdown[0])
  const tmUsers = jobsFilter.tmUser ?? territoryManagersForDropdown[0]
  const selectedAffiliates = jobsFilter.affiliates || affiliatesStages

  const getParams = () => {
    const params = {} as Partial<SearchParams>

    if (territory && territory?.label !== 'All Territories') {
      params.territory = territory.key
    }

    if (csmUsers?.key) {
      params.successManager = csmUsers?.key || ''
    }
    if (tmUsers?.key) {
      params.territoryManager = tmUsers?.key || ''
    }

    if (orders?.key !== 'all') {
      params.affiliateType = orders?.key.toUpperCase()
    }

    const resAffiliates = selectedAffiliates
      .filter((aff) => !aff.hide)
      .reduce((acc: any, curr: any) => acc.concat(curr.key), [])
    const queryAffiliates = resAffiliates.join(',')
    if (resAffiliates.length !== 0 && !resAffiliates.includes('all')) {
      params.affiliate = queryAffiliates
    }

    return params
  }

  const clearState = () => {
    dispatch(groupedOrdersActions.resetGroupedOrders({ stage }))
  }

  const fetchGroupedOrders = (
    params: Partial<SearchParams>,
  ) => {
    dispatch(
      groupedOrdersActions.fetchGroupedOrders(
        {
          stage,
          searchParams: {
            ...searchParams,
            ...params,
          },
        },
        (succ: boolean, limit?: number) => {
          setLoading(false)
        }
      )
    )
  }

  const debouncedFetchEstimatesByStage = useCallback(
    debounce(fetchGroupedOrders, 500),
    []
  )

  useEffect(() => {
    clearState()

    if (getParams().territory) {
      setLoading(true)

      debouncedFetchEstimatesByStage(getParams())
    }
  }, [jobsFilter])

  return (
    <Box className={styles.container} bgcolor={laneBackground || '#FAFDFF'}>
      <ColumnHeader
        count={Number(groupedOrders?.length || 0)}
        status={stage}
        backgroundColor={headerBackground || '#E6F7FF'}
        // price={Number(groupedOrders?.totalValue || 0) || undefined}
        // dueToday={Number(groupedOrders?.dueToday || 0) || undefined}
      />
      <Box className={styles.column}>
        {groupedOrders
          ?.map((groupedOrder: GroupedOrder) => (
            <ColumnCard groupedOrder={groupedOrder} key={groupedOrder.publicId} stage={stage} />
          ))}

        {loading && (
          <Grid container justifyContent="center">
            <CircularProgress />
          </Grid>
        )}
      </Box>
    </Box>
  )
}

export default ColumnLane
