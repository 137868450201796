import { GetInvitationAction, invitationTypes, SendInvitationAction } from './types';
/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'

import { Punchlist } from '../../api'
import { errorTextTryingTo } from 'src/helpers';
import actions from './actions';

export function* getInvitations({
    payload,
    callback,
}: GetInvitationAction): SagaIterator {
    try {
        const { networkIds } = payload || {}
        const invitations = yield call(Punchlist.invitations.getInvitations, { networkIds })
        if (invitations) {
            yield put(actions.setInvitations(invitations))
        }
        if (callback)
            yield call(
                callback,
                true)
    } catch (error) {
        yield call(toast.error, errorTextTryingTo('get the invitations'))
        if (callback) yield call(callback, false)
    }
}

export function* sendInvitation({
    payload,
    callback,
}: SendInvitationAction): SagaIterator {
    try {
        const invitations = yield call(Punchlist.invitations.createInvitation, payload)
        if (invitations) {
            yield put(actions.setInvitations(invitations))
        }
        if (callback)
            yield call(
                callback,
                true)
    } catch (error) {
        yield call(toast.error, errorTextTryingTo('get the invitations'))
        if (callback) yield call(callback, false)
    }
}

export default function* saga(): SagaIterator {
    yield takeLatest(invitationTypes.GET_INVITATIONS, getInvitations)
    yield takeLatest(invitationTypes.SEND_INVITATION, sendInvitation)
}
