import { Theme } from '@mui/material';

export const fab = {
  MuiFab: {
    styleOverrides: {
      root: ({ theme }: { theme: Theme }) => ({
        backgroundColor: theme['palette'].purple[500]
      }),
      sizeLarge: {
        width: 'fit-content',
        height: 'fit-content',
        padding: '0'
      },
    }
  },
}

