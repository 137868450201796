import { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Grid, TextFieldLabel, Typography } from 'src/components/UI'
import Autocomplete from 'src/components/UI/CustomUI/molecules/Autocomplete'
import Modal from 'src/components/UI/CustomUI/molecules/Modal'
import UploadDocuments from 'src/components/UI/CustomUI/molecules/UploadDocument'
import { estimateActions, filesActions, jobActions } from 'src/ducks/actions'
import { DOCUMENT_TYPE_OPTIONS, FILE_TYPE, ParseFilesUrl } from 'src/helpers'
import env from '@beam-australia/react-env'
import moment from 'moment'
import useStyles from '../Communications/'
import { getEmployeeId } from 'src/ducks/selectors'
import { Upload } from '@mui/icons-material'
import styles from './styles.module.scss'

interface UploadDocumentsModalProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleClose: () => void
}

const UploadDocumentsModal: FC<UploadDocumentsModalProps> = ({
  open,
  setOpen,
  handleClose,
}) => {
  const [docType, setDocType] = useState<any>(null)
  const [file, setFile] = useState<any>(null)
  const [uploadingFile, setUploadingFile] = useState(false)
  const [fileUrl, setFileUrl] = useState<string>('')

  const userId = useSelector(getEmployeeId)

  const FILES_URL = env('FILES_URL') ?? ''

  const dispatch = useDispatch()

  const handleUploadFile = (file: null | File, callback: () => void) => {
    if (file) {
      setUploadingFile(true)
      dispatch(
        filesActions.uploadFile(file, (uploadSucc, fileName) => {
          if (uploadSucc && fileName) {
            const fileToSave = {
              name: file.name,
              fileUrl: ParseFilesUrl({
                baseUrl: FILES_URL,
                avatar: fileName ?? '',
              }),
              description: file.name ?? '',
              uploadedOn: moment().unix(),
              uploadedBy: userId,
            }
            callback()
            setFileUrl(fileToSave.fileUrl)
            // setFiles([...files, fileToSave])
            setFile(fileToSave)
          }
          setUploadingFile(false)
        })
      )
    } else {
      setFile(null)
      setFileUrl('')
      setDocType(null)
      setUploadingFile(false)
    }
  }

  const handleUploadDocument = () => {
    dispatch(
      jobActions.updateJobByPath(
        [
          {
            op: 'add',
            path: '/properties/files/-',
            value: {
              ...file,
              fileType: docType?.key,
            },
          },
        ],
        (succ) => {
          setFile(null)
          setFileUrl('')
          setDocType(null)
          handleClose()
        }
      )
    )
  }

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Upload Document"
      onClose={() => {
        handleClose()
        setFile(null)
        setFileUrl('')
        setDocType(null)
      }}
    >
      <Grid container direction="column" spacing={2}>
        <Grid item>
          {/* <Typography fontSize='14px' lineHeight='22px' fontWeight={500}>Doc Type</Typography> */}
          <Autocomplete
            label="Doc Type"
            placeholder="Select Type"
            options={DOCUMENT_TYPE_OPTIONS}
            value={docType}
            onChange={(v) => setDocType(v)}
            className={styles.fit_height}
          />
        </Grid>
        <Grid item>
          <UploadDocuments
            error={false}
            file={file ?? null}
            dropzoneText="Click here to upload your file"
            onChange={!uploadingFile ? handleUploadFile : () => {}}
          />
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={6}>
            <Button
              variant="contained"
              sx={{
                height: '40px',
                backgroundColor: '#FAFBFF',
                color: '#3A66FF',
                border: 0,
                '&:hover': {
                  color: 'white',
                },
              }}
              fullWidth
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={handleUploadDocument}
              variant="contained"
              color="primary"
              sx={{
                height: '40px',
              }}
              fullWidth
              endIcon={
                <Upload
                  style={{
                    width: '12px',
                    height: '12px',
                    padding: '0px !important',
                  }}
                />
              }
              disabled={!fileUrl || uploadingFile || !docType}
            >
              Upload
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  )
}

export default UploadDocumentsModal
