import { InsertLink } from '@mui/icons-material'
import { Box, Grid, Typography } from '@mui/material'
import Table from 'src/components/UI/CustomUI/organisms/Table'

import headers from './headers'
import { useSelector } from 'react-redux'
import { LabeledText } from 'src/components/UI'
import { getPCPortfolioCount } from 'src/ducks/selectors'
import styles from './styles.module.scss'

const PortfolioCounts = () => {
  const portfolioCount = useSelector(getPCPortfolioCount())

  const mockCounts = [
    {
      status: 'Not Started',
      amount: portfolioCount?.notStarted,
    },
    {
      status: 'In Progress',
      amount: portfolioCount?.inProgress,
    },
    {
      status: 'Pending Pro Assignment',
      amount: portfolioCount?.pendingProAssignment,
    },
    {
      status: 'Pending QC',
      amount: portfolioCount?.pendingQualityControl,
    },
    {
      status: 'Pending Rework',
      amount: portfolioCount?.pendingRework,
    },
    // {
    //   status: 'Work Item Complete',
    //   amount: portfolioCount?.complete,
    // },
    // {
    //   status: 'Work Item Invoiced',
    //   amount: portfolioCount?.invoiced,
    // },
  ]

  return (
    <Grid container item spacing={1} direction="column" style={{ overflow: 'overlay', flexWrap: 'nowrap' }} height='215px'>
      <Grid item>
        <div className={styles.Table}>
          {mockCounts.map((count, index) => (
            <div key={`row-${index}`} className={styles.TableRow} style={index === mockCounts.length - 1 ? {} : { borderBottom: "2px solid var(--gray200)" }}>
              <div className={styles.TableRowLeft}>
                <Typography fontFamily="var(--font-bold)" fontSize={12}>
                  {count.status}
                </Typography>
              </div>
              <div className={styles.TableRowRight}>
                <Typography align='center'>
                  {count.amount}
                </Typography>
              </div>
            </div>
          ))}
        </div>
      </Grid>
    </Grid>
  )
}

export default PortfolioCounts
