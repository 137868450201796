import { ITEM_STATUS } from 'src/helpers'
import storeInfo from '../store'
import { EstimateItem, Item } from '../types'
import { EstimateType } from './types'

export const getEstimate = () => (): EstimateType => {
    const { estimate } = storeInfo.store.getState()
    return estimate
}

export const getItemsInCart = () => (): Item[] | [] => {
    const { estimateItems } = storeInfo.store.getState()
    return estimateItems.filter((item: Item) => item.status === ITEM_STATUS.APPROVED) || []
  }
  

export const getEstimateId = () => (): string => {
    const { estimate } = storeInfo.store.getState()    
    if (!estimate) return ''
    const { id } = estimate as EstimateItem
    return id
}

export const getEstimateFullAdress = () => (): string => {
    const { estimate } = storeInfo.store.getState()
    if (!estimate) return ''
    const { properties: { fullAddress } } = estimate as EstimateItem
    return fullAddress || ''
}
