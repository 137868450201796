import { Autocomplete, Box, Checkbox, Chip, FormControlLabel, Grid, IconButton, TextField } from "@mui/material";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { FC, useEffect, useState } from "react";
import { ActionsButton, LabeledText, SelectInput, TextFieldLabel } from "src/components/UI";
import { useDispatch, useSelector } from "react-redux";
import { getTerritory } from "src/ducks/territory/selectors";
import { getEmployee, getEmployeesByRole, getEmployeesForSelect } from "src/ducks/selectors";
import { EMPLOYEE_ROLE_TYPE, PHONE_MASK_INPUT } from "src/helpers";
import { territoryActions } from "src/ducks/actions";
import { SelectInputOption } from "src/components/UI/CustomUI/atoms/SelectInput/types";
import { icons } from "src/assets";
import { Territory } from "src/ducks/types";
import { toast } from "react-toastify";
import ExportCSV from "src/components/UI/CustomUI/molecules/ExportCSV";


const containerStyle = {
    width: '100%',
    height: '100%',
    minHeight: '175px',
    padding: '12px 0',
    borderRadius: '8px',
    '.gm-style-cc': { display: 'none' }
}

const Settings: FC = () => {

    const dispatch = useDispatch()
    const territory = useSelector(getTerritory)
    const employees = useSelector(getEmployeesForSelect())
    const currentUser = useSelector(getEmployee)
    const isSuperAdmin = currentUser.roles.find(role => role.roleType === EMPLOYEE_ROLE_TYPE.SUPER_ADMIN)

    const [loading, setLoading] = useState(false)
    const [zipFilter, setZipFilter] = useState('')
    const [inputValues, setInputValues] = useState({
        title: territory?.title ?? '',
        serviceable: territory?.serviceable ?? false,
        inspectionTerritory: territory?.inspectionTerritory ?? false,
        vipEmails: territory?.vipEmails ?? [],
        zipCodes: territory?.zipCodes ?? [],
        jobMinimum: territory?.jobMinimum ?? '',
        latitude: territory?.latitude ?? 0,
        longitude: territory?.longitude ?? 0,
        phone: territory?.phone ?? '',
        territoryManager: territory?.territoryManager ?? '',
        email: territory?.email ?? '',
        territoryManagerOption: employees.find((employee) => employee.key === territory?.territoryManager) ?? null
    })

    useEffect(() => {
        setInputValues({
            title: territory?.title ?? '',
            serviceable: territory?.serviceable ?? false,
            inspectionTerritory: territory?.inspectionTerritory ?? false,
            vipEmails: territory?.vipEmails ?? [],
            zipCodes: territory?.zipCodes ?? [],
            jobMinimum: territory?.jobMinimum ?? '',
            latitude: territory?.latitude ?? 0,
            longitude: territory?.longitude ?? 0,
            phone: territory?.phone ?? '',
            territoryManager: territory?.territoryManager ?? '',
            email: territory?.email ?? '',
            territoryManagerOption: employees.find((employee) => employee.key === territory?.territoryManager) ?? null
        })
    }, [territory])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setInputValues((prevTerritory) => ({
            ...prevTerritory,
            [name]: value,
        }));
    }

    const handleCheckboxChange = (event: React.ChangeEvent) => {
        const { name, checked } = event.target as HTMLInputElement;
        setInputValues((prevTerritory) => ({
            ...prevTerritory,
            [name]: checked,
        }));
    }

    const handleChangeSuccessManager = (newValue: SelectInputOption) => {
        setInputValues((prevTerritory) => ({
            ...prevTerritory,
            territoryManager: newValue.key,
            territoryManagerOption: newValue
        }));
    }

    const handleChangeVipEmails = (event: any, newValue: any) => {
        setInputValues((prevTerritory) => ({
            ...prevTerritory,
            vipEmails: newValue
        }));
    }

    const handleChangeZipCodes = (event: any, newValue: any) => {
        setInputValues((prevTerritory) => ({
            ...prevTerritory,
            zipCodes: newValue
        }));
    }

    function areArraysEqual(array1: string[], array2: string[]): boolean {
        return array1.length === array2.length && array1.every(value => array2.includes(value));
    }


    const isChanged = inputValues.title !== territory?.title ||
        inputValues.serviceable !== territory?.serviceable ||
        inputValues.inspectionTerritory !== territory?.inspectionTerritory ||
        !areArraysEqual(inputValues.vipEmails, territory?.vipEmails ?? []) ||
        !areArraysEqual(inputValues.zipCodes, territory?.zipCodes ?? []) ||
        Number(inputValues.jobMinimum) !== territory?.jobMinimum ||
        Number(inputValues.latitude) !== (territory?.latitude ?? 0) ||
        Number(inputValues.longitude) !== (territory?.longitude ?? 0) ||
        inputValues.phone !== territory?.phone ||
        inputValues.territoryManager !== (territory?.territoryManager ?? '') ||
        inputValues.email !== (territory?.email ?? '')

    const handleSaveChanges = () => {
        const updatedTerritory: Partial<Territory> = {}
        if (inputValues.title !== territory?.title)
            updatedTerritory.title = inputValues.title
        if (inputValues.serviceable !== territory?.serviceable)
            updatedTerritory.serviceable = inputValues.serviceable
        if (inputValues.inspectionTerritory !== territory?.inspectionTerritory)
            updatedTerritory.inspectionTerritory = inputValues.inspectionTerritory
        if (!areArraysEqual(inputValues.vipEmails, territory?.vipEmails ?? []))
            updatedTerritory.vipEmails = inputValues.vipEmails
        if (!areArraysEqual(inputValues.zipCodes, territory?.zipCodes ?? []))
            updatedTerritory.zipCodes = inputValues.zipCodes
        if (Number(inputValues.jobMinimum) !== territory?.jobMinimum)
            updatedTerritory.jobMinimum = Number(inputValues.jobMinimum)
        if (Number(inputValues.latitude) !== territory?.latitude)
            updatedTerritory.latitude = Number(inputValues.latitude)
        if (Number(inputValues.longitude) !== territory?.longitude)
            updatedTerritory.longitude = Number(inputValues.longitude)
        if (inputValues.phone !== territory?.phone)
            updatedTerritory.phone = inputValues.phone
        if (inputValues.territoryManager !== (territory?.territoryManager ?? ''))
            updatedTerritory.territoryManager = inputValues.territoryManager
        if (inputValues.email !== (territory?.email ?? ''))
            updatedTerritory.email = inputValues.email

        setLoading(true)
        dispatch(territoryActions.updateTerritory({
            id: territory?.id ?? '',
            territory: updatedTerritory
        }, (succ: boolean) => {
            setLoading(false)
            if (succ)
                toast.success('Territory updated successfully')
        }))
    }

    return (
        <Grid container style={{ height: '100%' }} sx={{ paddingLeft: '0.25rem' }} spacing={2}>
            <Grid item xs={4} style={{ height: '100%' }}>
                {GoogleMap && (
                    <GoogleMap
                        center={{
                            lat: territory?.latitude ?? 0,
                            lng: territory?.longitude ?? 0
                        }}
                        zoom={15}
                        mapContainerStyle={containerStyle}
                        options={{
                            clickableIcons: false,
                            fullscreenControl: false,
                            zoomControl: false
                        }}
                    >
                        <Marker position={{
                            lat: territory?.latitude ?? 0,
                            lng: territory?.longitude ?? 0
                        }} />
                    </GoogleMap>
                )}
            </Grid>
            <Grid item xs={8} container spacing={2}>
                <Grid item xs={12} container spacing={2} alignItems='center' alignContent='center'>
                    <Grid item xs={6}>
                        <LabeledText labelValue="Name" />
                        <TextFieldLabel label='' name='title'  disabled={!isSuperAdmin} value={inputValues.title} onChange={handleChange} />
                    </Grid>
                    <Grid item xs={6} container>
                        <Grid item xs={6} mt={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="serviceable"
                                        checked={inputValues.serviceable}
                                        disabled={!isSuperAdmin}
                                        onChange={handleCheckboxChange}
                                    />
                                }
                                label="Serviceable Territory"
                            />
                        </Grid>
                        <Grid item xs={6} mt={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="inspectionTerritory"
                                        disabled={!isSuperAdmin}
                                        checked={inputValues.inspectionTerritory}
                                        onChange={handleCheckboxChange}
                                    />
                                }
                                label="Inspection Territory"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <LabeledText labelValue="VIP Emails" />
                    <Autocomplete
                        multiple
                        freeSolo
                        value={inputValues.vipEmails}
                        onChange={handleChangeVipEmails}
                        readOnly={!isSuperAdmin}
                        options={[]}
                        renderInput={(params) => (
                            <TextField {...params} />
                        )}
                        renderTags={(value, getTagProps) => {
                            return value.map((option, index) => (
                                <Chip label={option} {...getTagProps({ index })} />))
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Box display='flex' alignItems='center' justifyContent='flex-end'>
                        <TextField sx={{mr:3}} disabled={!isSuperAdmin} value={zipFilter} onChange={(event)=> setZipFilter(event.target.value)} />
                        <LabeledText labelValue="Search:" sx={{mr:1}}/>
                        <ExportCSV
                            headers={[{ key: 'zipCode', label: 'zipCodes' }]}
                            data={inputValues.zipCodes.filter((zip) => zip.includes(zipFilter)).map(z => ({
                                zipCode: z
                            })) ?? ['']}
                            fileName='zipcodes.csv'
                            label={<IconButton>
                                <icons.Download />
                            </IconButton>}
                        />
                    </Box>
                    <LabeledText labelValue="Service ZIP Codes" />
                    <Autocomplete
                        multiple
                        freeSolo
                        readOnly={!isSuperAdmin}
                        onChange={handleChangeZipCodes}
                        value={inputValues.zipCodes.filter((zip) => zip.includes(zipFilter))}
                        options={[]}
                        renderInput={(params) => (
                            <TextField {...params} />
                        )}
                        renderTags={(value, getTagProps) => {
                            return value.map((option, index) => (
                                <Chip label={option} {...getTagProps({ index })} />))
                        }}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} container spacing={2} pb={2}>
                <Grid item xs={4}>
                    <SelectInput 
                    options={employees}
                     label='Territory Manager' 
                     disabled={!isSuperAdmin}
                     value={inputValues.territoryManagerOption ?? employees.find((employee) => employee.key === territory?.territoryManager)} 
                     onChange={handleChangeSuccessManager} />
                </Grid>
                <Grid item xs={4}>
                    <TextFieldLabel name='email' label='Bosscat From Email' value={inputValues.email ?? ''} disabled={!isSuperAdmin} onChange={handleChange} />
                </Grid>
                <Grid item xs={4}>
                    <TextFieldLabel mask={PHONE_MASK_INPUT} label='Primary Phone Number' name='phone' value={inputValues.phone} disabled={!isSuperAdmin} onChange={handleChange} />
                </Grid>
                <Grid item xs={4}>
                    <TextFieldLabel label='Job Minimum' name='jobMinimum' value={'' + inputValues.jobMinimum} disabled={!isSuperAdmin} onChange={handleChange} />
                </Grid>
                {/* <Grid item xs={3}>
                    <TextFieldLabel label='Territory Price Adjustment' value='' readOnly={true} inputProps={{
                        endAdornment: '%'
                    }} />
                </Grid> */}
                <Grid item xs={4}>
                    <TextFieldLabel label='Latitude' name='latitude' value={'' + inputValues.latitude} disabled={!isSuperAdmin} onChange={handleChange} />
                </Grid>
                <Grid item xs={4}>
                    <TextFieldLabel label='Longitude' name='longitude' value={'' + inputValues.longitude} disabled={!isSuperAdmin} onChange={handleChange} />
                </Grid>
            </Grid>
            <Grid item xs={12} container justifyContent='flex-end'>
                <ActionsButton
                    variant='contained'
                    disabled={!isChanged || !isSuperAdmin}
                    sx={{
                        position: 'fixed',
                        height: '40px !important',
                        width: '180px !important',
                        bottom: '40px',
                        right: '40px'
                    }}
                    onClick={handleSaveChanges}
                    loading={loading}
                    icon={<icons.Save />}
                    iconPosition='end'
                    text='Save changes'
                />
            </Grid>
        </Grid>
    )
}

export default Settings;