import {
  Grid,
  Typography,
  FormControlLabel,
  Divider,
  Button,
  Checkbox,
  CircularProgress,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { icons } from 'src/assets'
import { SelectInput, TextFieldLabel } from 'src/components/UI'
import DatePicker from 'src/components/UI/CustomUI/atoms/DatePicker'
import Modal from 'src/components/UI/CustomUI/molecules/Modal'
import CollapseContact from '../CollapseContact'
import UploadDocuments from 'src/components/UI/CustomUI/molecules/UploadDocument'
import {
  CONTACT_ROLE,
  CONTACT_ROLE_TEXT,
  ESTIMATE_STATUS,
  FILE_TYPE,
  PHONE_MASK_INPUT,
  ParseFilesUrl,
  formatDateToUTCTimestamp,
  formatUTCTimestampDate,
  validateEmail,
} from 'src/helpers'
import { useDispatch, useSelector } from 'react-redux'
import { getAllCompaniesForMultipleFieldWithoutAllOption } from 'src/ducks/selectors'
import { SelectInputOption } from 'src/components/UI/CustomUI/atoms/SelectInput/types'
import { estimateActions, filesActions } from 'src/ducks/actions'
import env from '@beam-australia/react-env'
import moment from 'moment'
import { Contact, EstimateItem } from 'src/ducks/types'
import NoContactsBox from '../NoContactsBox'
import SelectAddress from 'src/components/UI/CustomUI/organisms/SelectAddress'
import { Address } from 'src/components/UI/CustomUI/organisms/SelectAddress/types'
import useStyles from './styles'

const FILES_URL = env('FILES_URL') ?? ''

interface CreateEstimateModalProps {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export default function CreateEstimateModal({
  isOpen,
  setIsOpen,
}: CreateEstimateModalProps) {
  const classes = useStyles()

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.keyCode === 13) {
      event.preventDefault()
      event.stopPropagation()
    }
  }

  const dispatch = useDispatch()

  const today = new Date()
  const defaultDueDate = new Date(today)

  defaultDueDate.setDate(today.getDate() + 2)

  const [address, setAddress] = useState('')
  const [suite, setSuite] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [zipCode, setZipCode] = useState('')
  const [name, setName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [requester, setRequester] = useState(true)
  const [estimatedClosingDate, setEstimatedClosingDate] = useState<Date | null>(
    null
  )
  const [dueDate, setDueDate] = useState<Date | null>(defaultDueDate)
  const [affiliate, setAffiliate] = useState<SelectInputOption>()
  const [role, setRole] = useState<SelectInputOption>()
  const [importFromCSV, setImportFromCSV] = useState(false)
  const [contacts, setContacts] = useState<Contact[]>([])
  const [uploadingFile, setUploadingFile] = useState(false)
  const [file, setFile] = useState<any>(null)
  const [submitting, setSubmitting] = useState(false)
  const roleOptions = [
    { key: CONTACT_ROLE.LISTING_AGENT, label: CONTACT_ROLE_TEXT.LISTING_AGENT },
    { key: CONTACT_ROLE.BUYERS_AGENT, label: CONTACT_ROLE_TEXT.BUYERS_AGENT },
    {
      key: CONTACT_ROLE.BUYERS_CLOSING_COORDINATOR,
      label: CONTACT_ROLE_TEXT.BUYERS_CLOSING_COORDINATOR,
    },
    {
      key: CONTACT_ROLE.SELLERS_CLOSING_COORDINATOR,
      label: CONTACT_ROLE_TEXT.SELLERS_CLOSING_COORDINATOR,
    },
    { key: CONTACT_ROLE.HOMEOWNER, label: CONTACT_ROLE_TEXT.HOMEOWNER },
    { key: CONTACT_ROLE.INSPECTOR, label: CONTACT_ROLE_TEXT.INSPECTOR },
    { key: CONTACT_ROLE.OTHER, label: CONTACT_ROLE_TEXT.OTHER },
  ]

  const affiliatesStages = useSelector(
    getAllCompaniesForMultipleFieldWithoutAllOption
  )
  const validContact =
    validateEmail(email) &&
    name?.length > 0 &&
    lastName?.length > 0 &&
    phone !== '' &&
    role !== undefined
  const validAddress =
    address?.length > 0 &&
    city?.length > 0 &&
    state?.length > 0 &&
    zipCode?.length > 0

  const validate = validContact && validAddress

  useEffect(() => {
    if (!isOpen) {
      setAddress('')
      setSuite('')
      setCity('')
      setState('')
      setZipCode('')
      setName('')
      setLastName('')
      setEmail('')
      setPhone('')
      setRequester(true)
      setEstimatedClosingDate(null)
      setDueDate(null)
      setAffiliate(undefined)
      setRole(undefined)
      setImportFromCSV(false)
      setContacts([])
      setUploadingFile(false)
      setFile(null)
    } else {
      setDueDate(defaultDueDate)
    }
  }, [isOpen])

  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddress(event.target.value)
  }

  const handleSuiteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSuite(event.target.value)
  }

  const handleCityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCity(event.target.value)
  }

  const handleStateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState(event.target.value)
  }

  const handleZipCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setZipCode(event.target.value)
  }

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
  }

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value)
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(event.target.value)
  }

  const handleRequesterChange = () => {
    setRequester((requester) => !requester)
  }

  const handleEstimatedClosingDateChange = (dateSelected: Date) => {
    setEstimatedClosingDate(dateSelected)
  }

  const handleDueDateChange = (dateSelected: Date) => {
    setDueDate(dateSelected)
  }

  const handleAffiliateChange = (newValue: SelectInputOption) => {
    setAffiliate(newValue)
  }

  const handleRoleChange = (newValue: SelectInputOption) => {
    setRole(newValue)
  }

  const handleImportFromCSVChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setImportFromCSV(event.target.checked)
  }

  const handleAddContact = () => {
    setContacts((contacts: any) => [
      ...contacts,
      {
        firstName: name,
        lastName,
        email,
        phone,
        role: role?.key,
        isRequester: requester,
      },
    ])
    setName('')
    setLastName('')
    setEmail('')
    setPhone('')
    setRequester(false)
  }

  const handleUploadFile = (file: null | File, callback: () => void) => {
    if (file) {
      setUploadingFile(true)
      dispatch(
        filesActions.uploadFile(file, (uploadSucc, fileName) => {
          if (uploadSucc && fileName) {
            const fileToSave = {
              name: file.name,
              fileUrl: ParseFilesUrl({
                baseUrl: FILES_URL,
                avatar: fileName ?? '',
              }),
              description: file.name ?? '',
              fileType: FILE_TYPE.REPAIR_LIST,
              uploadedOn: moment().unix(),
            }
            callback()
            setFile(fileToSave)
          }
          setUploadingFile(false)
        })
      )
    } else {
      setFile(null)
    }
  }

  const handleCreateEstimate = () => {
    setSubmitting(true)

    const payloadContacts = [...contacts]

    if (validContact) {
      payloadContacts.push({
        firstName: name,
        lastName,
        email,
        phone,
        role: role?.key,
        isRequester: requester,
      } as Contact)
    }

    const newEstimate: Partial<EstimateItem> = {
      status: ESTIMATE_STATUS.NEW,
      properties: {
        contacts: payloadContacts,
        address: {
          zipCode: zipCode,
          city: city,
          state: state,
          line_1: `${address} ${suite}`,
          line_2: '',
          latitude: 0,
          longitude: 0,
          fullAddress: '',
        },
        files: file ? [file] : [],
        affiliate: affiliate?.key,
      },
      dueOn: dueDate ? formatDateToUTCTimestamp(dueDate, 'endOfDay') : 0,
    }
    dispatch(
      estimateActions.createEstimate(
        newEstimate,
        (success: boolean, estimateId) => {
          if (success && estimateId !== null) {
            window.location.href = `/estimates/${estimateId}`
          }
          setIsOpen(false)
          setSubmitting(false)
        }
      )
    )
  }

  const handleChange = (newAddress: Address) => {
    if (newAddress?.line_1) {
      setAddress(newAddress.line_1)
    }
    if (newAddress?.zipCode) setZipCode(newAddress.zipCode)
    if (newAddress?.city) setCity(newAddress.city)
    if (newAddress?.state) setState(newAddress.state)
  }
  return (
    <Modal
      onKeyDown={handleKeyDown}
      size="xl"
      open={isOpen}
      showClose={true}
      setOpen={setIsOpen}
      title={
        <Typography variant="h3" sx={{ p: 1, m: 1 }}>
          New Estimate
        </Typography>
      }
    >
      <Grid container spacing={2} onKeyDown={handleKeyDown}>
        <Grid
          item
          xs={12}
          md={6}
          container
          spacing={1}
          alignItems="flex-start"
          alignContent="flex-start"
        >
          <Grid item xs={12}>
            <Typography variant="h6">Address</Typography>
          </Grid>
          <Grid item xs={12}>
            <SelectAddress
              placeholder="Search"
              showWarnings={false}
              savedAddress={address}
              onChange={handleChange}
              showUnit={false}
              inputClassName={classes.selectAddress}
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldLabel
              label="Address"
              fullWidth
              value={address}
              onChange={handleAddressChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldLabel
              label="Suite/Apt/Unit"
              fullWidth
              value={suite}
              onChange={handleSuiteChange}
            />
          </Grid>
          <Grid item xs={4}>
            <TextFieldLabel
              label="City"
              fullWidth
              value={city}
              onChange={handleCityChange}
            />
          </Grid>
          <Grid item xs={4}>
            <TextFieldLabel
              label="State"
              fullWidth
              value={state}
              onChange={handleStateChange}
            />
          </Grid>
          <Grid item xs={4}>
            <TextFieldLabel
              label="Zip Code"
              fullWidth
              value={zipCode}
              onChange={handleZipCodeChange}
            />
          </Grid>
          <Grid item xs={12} container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h6">Dates and Attachments</Typography>
            </Grid>
            <Grid item xs={12}>
              <UploadDocuments file={file} onChange={handleUploadFile} />
            </Grid>
            <Grid item xs={12} md={4}>
              <DatePicker
                styleAlert={{
                  position: 'absolute',
                  top: -310,
                  height: '38px',
                  padding: '0px',
                  width: '100%',
                }}
                size="small"
                placeholder="mm/dd/yyyy"
                label="Estimated Closing Date"
                fullWidth
                value={estimatedClosingDate}
                onChange={handleEstimatedClosingDateChange}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <DatePicker
                placeholder="mm/dd/yyyy"
                label="Due Date"
                styleAlert={{
                  position: 'absolute',
                  top: -310,
                  height: '38px',
                  padding: '0px',
                  width: '100%',
                }}
                fullWidth
                value={dueDate}
                onChange={handleDueDateChange}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <SelectInput
                label="Affiliate"
                options={affiliatesStages}
                value={affiliate}
                onChange={handleAffiliateChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled
                    checked={importFromCSV}
                    onChange={handleImportFromCSVChange}
                    color="primary"
                  />
                }
                label="Import from CSV"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={6}
          container
          spacing={1}
          alignItems="flex-start"
          alignContent="flex-start"
        >
          <Grid item xs={12}>
            <Typography variant="h6">Contact Details</Typography>
          </Grid>
          <Grid item xs={12}>
            <SelectInput
              label="Role"
              options={roleOptions}
              value={role}
              onChange={handleRoleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldLabel
              fullWidth
              label="Name"
              placeholder="Enter name"
              variant="outlined"
              value={name}
              onChange={handleNameChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldLabel
              fullWidth
              label="Last Name"
              placeholder="Enter last name"
              variant="outlined"
              value={lastName}
              onChange={handleLastNameChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldLabel
              fullWidth
              label="Email"
              placeholder="Enter email"
              variant="outlined"
              value={email}
              onChange={handleEmailChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldLabel
              fullWidth
              label="Phone"
              placeholder="Enter phone number"
              variant="outlined"
              value={phone}
              onChange={handlePhoneChange}
              mask={PHONE_MASK_INPUT}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              value={requester}
              control={
                <Checkbox
                  checked={requester}
                  onChange={handleRequesterChange}
                />
              }
              label="Requester"
              labelPlacement="end"
            />
          </Grid>
          <Grid item xs={12} justifyContent="flex-end">
            <Button
              disabled={!validContact}
              variant="outlined"
              endIcon={<icons.Add />}
              onClick={handleAddContact}
            >
              Add Contact
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} container spacing={1}>
            {contacts.length === 0 && <NoContactsBox />}
            {contacts.map((contact, index) => (
              <Grid item xs={12}>
                <CollapseContact
                  contact={contact}
                  key={index}
                  onChangeRequester={() => {
                    setContacts((contacts) =>
                      contacts.map((c, i) => {
                        if (i === index) {
                          return {
                            ...c,
                            isRequester: !c.isRequester,
                          }
                        }
                        return c
                      })
                    )
                  }}
                  onDelete={() =>
                    setContacts((contacts) =>
                      contacts.filter((_, i) => i !== index)
                    )
                  }
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} container spacing={1} justifyContent="flex-end">
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </Button>
          <Button
            endIcon={submitting && <CircularProgress size={20} />}
            disabled={
              (contacts.length === 0 && !validContact) ||
              !validate ||
              submitting
            }
            variant="contained"
            color="primary"
            sx={{ ml: 1 }}
            onClick={handleCreateEstimate}
          >
            Create Estimate
          </Button>
        </Grid>
      </Grid>
    </Modal>
  )
}
