import Typography from '@mui/material/Typography'
import { Box } from 'src/components/UI'
import {
  AlignType,
  HeaderType,
} from 'src/components/UI/CustomUI/organisms/Table/types'
import { OrderType } from 'src/ducks/orders/types'
import { round } from 'src/helpers'
import classes from './styles.module.scss'
import moment from 'moment'

const headers: HeaderType[] = [
  {
    id: 'address',
    label: 'Address',
    custom: true,
    maxWidth: 200,
    Element: (row) => (
      <Box className={classes.Jobs__cell}>
        <Typography
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
          className={classes.Jobs__cell__content}
        >
          {row.address.fullAddress}
        </Typography>
      </Box>
    ),
  },
  {
    id: 'territory',
    label: 'Territory',
    custom: true,
    Element: ({ address }) => (
      <Box className={classes.Jobs__cell} width="fit-content">
        <Typography className={classes.Jobs__cell__content}>
          {`${address.city}, ${address.state}`}
        </Typography>
      </Box>
    ),
  },
  {
    id: 'value',
    label: 'Value',
    custom: true,
    Element: ({ totalValue }) => (
      <Box className={classes.Jobs__cell} width="fit-content">
        <Typography className={classes.Jobs__cell__content}>
          {`$${round(totalValue, 2)}`}
        </Typography>
      </Box>
    ),
  },
  //  { id: 'status', label: 'Status' },
  {
    id: 'items',
    label: '# of Items',
    custom: true,
    Element: ({ numberOfItems }) => (
      <Box className={classes.Jobs__cell} width="fit-content">
        <Typography className={classes.Jobs__cell__content}>
          {numberOfItems}
        </Typography>
      </Box>
    ),
  },
  {
    id: 'startDate',
    label: 'Start Date',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: OrderType) => (
      <Box className={classes.Jobs__cell} width="fit-content">
        <Typography className={classes.Jobs__cell__content} textAlign="center">
          {row.scheduledStartTimestamp
            ? `${moment(row.scheduledStartTimestamp * 1000).format(
                'MM/DD/YYYY'
              )}`
            : '-'}
        </Typography>
      </Box>
    ),
  },
  {
    id: 'endDate',
    label: 'End Date',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: OrderType) => (
      <Box className={classes.Jobs__cell} width="fit-content">
        <Typography className={classes.Jobs__cell__content} textAlign="center">
          {row.scheduledEndTimestamp
            ? `${moment(row.scheduledEndTimestamp * 1000).format('MM/DD/YYYY')}`
            : '-'}
        </Typography>
      </Box>
    ),
  },
  {
    id: 'completedOn',
    label: 'Completed on',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: OrderType) => (
      <Box className={classes.Jobs__cell} width="fit-content">
        <Typography className={classes.Jobs__cell__content} textAlign="center">
          {row.completedTimestamp
            ? `${moment(row.completedTimestamp * 1000).format('MM/DD/YYYY')}`
            : '-'}
        </Typography>
      </Box>
    ),
  },
  {
    id: 'duration',
    label: 'Duration',
    custom: true,
    align: 'center' as AlignType,
    Element: (row: OrderType) => (
      <Box className={classes.Jobs__cell} width="fit-content">
        <Typography className={classes.Jobs__cell__content}>
          {(row.completedTimestamp || row.scheduledEndTimestamp) && row.scheduledStartTimestamp
            ? `${
                moment((row.completedTimestamp || row.scheduledEndTimestamp || 0) * 1000).diff(
                  moment(row.scheduledStartTimestamp * 1000),
                  'days'
                ) + 1
              } days`
            : ''}
        </Typography>
      </Box>
    ),
  },
]

export default headers
