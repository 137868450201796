import { FC, useEffect, useState, useRef } from 'react'
import {
  Box,
  Button,
  Grid,
  SelectInput,
  TextField,
  Typography,
} from 'src/components/UI'
import DownloadIcon from '@mui/icons-material/Download'
import AddIcon from '@mui/icons-material/Add'
import Table from 'src/components/UI/CustomUI/organisms/Table'
import headers from './headers'
import useStyles from './styles'
import { useDispatch, useSelector } from 'react-redux'
import {
  filtersActions,
  jobsActions,
  repairItemActions,
  repairListActions,
  territoriesActions,
} from 'src/ducks/actions'
import {
  getCsvRepairList,
  getRepairList,
  getRepairListTotal,
  getTerritoriesForDropdown,
} from 'src/ducks/selectors'
import TablePagination from 'src/components/UI/CustomUI/organisms/Table/TablePagination'
import {
  ITEM_CATEGORY,
  ROWS_PER_PAGE_SIMPLE,
  formatTimestamp,
  headerCSVParser,
} from 'src/helpers'
import { RepairListItem } from 'src/ducks/types'
import { useNavigate } from 'react-router-dom'
import { SelectInputOption } from 'src/components/UI/CustomUI/atoms/SelectInput/types'
import { RepairListFilterType } from 'src/ducks/filters/types'
import { getRepairlistFilter } from 'src/ducks/filters/selector'
import ExportCSV from 'src/components/UI/CustomUI/molecules/ExportCSV'
import Modal from 'src/components/UI/CustomUI/molecules/Modal'
import AssessmentIcon from '@mui/icons-material/Assessment'
import { CSVLink } from 'react-csv'

const itemCategories = Object.values(ITEM_CATEGORY).map(
  (val: string): SelectInputOption => ({
    key: val,
    label: val,
  })
)

itemCategories.unshift({ key: 'all', label: 'All Categories' })

const RepairList: FC = () => {
  const dispatch = useDispatch()

  const styles = useStyles()
  const navigate = useNavigate()

  const repairList = useSelector(getRepairList)
  const csvRepairList = useSelector(getCsvRepairList)
  const total = useSelector(getRepairListTotal)
  const repairListFilter = useSelector(getRepairlistFilter())
  const territories = useSelector(getTerritoriesForDropdown)

  const [pagination, setPagination] = useState({ page: 0, size: 50 })
  const [loading, setLoading] = useState<boolean>(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
  const [deleteId, setDeleteId] = useState<string>('')
  const [statsToggle, setStatsToggle] = useState<boolean>(false)

  const { category, territory } = repairListFilter || {}

  // CSV
  const downloadRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null)
  const headersCSV: any = headerCSVParser(headers)
  const dataCSV = csvRepairList?.reduce((acc: any, curr: any) => {
    const vendor = {
      ...curr,
      name: curr?.title || '',
      lastUsedTimestamp: curr?.lastUsedTimestamp
        ? formatTimestamp(curr?.lastUsedTimestamp, 'MM/DD/YYYY hh:mm a')
        : '',
      win: curr?.wins || '',
      reject: curr?.losses || '',
      used: curr?.usageCount || '',
    }
    acc = [...acc, vendor]
    return acc
  }, [])

  useEffect(() => {
    dispatch(territoriesActions.fetchTerritories((succ) => {}))
  }, [])

  useEffect(() => {
    setLoading(true)

    dispatch(
      repairListActions.fetchRepairlist(
        {
          ...pagination,
          searchParams: {
            category:
              category?.key === 'all' || !category ? null : category?.key,
            territory:
              territory?.key === 'all' || !territory
                ? undefined
                : territory.key,
          },
        },
        (succ) => {
          setLoading(false)
        }
      )
    )
  }, [pagination, repairListFilter])

  const updateRepairListFilter = (newFilter: Partial<RepairListFilterType>) => {
    dispatch(
      filtersActions.setFilters({
        repairList: {
          ...repairListFilter,
          ...newFilter,
        },
      })
    )
  }

  const handleClickRow = (row: RepairListItem): void => {
    navigate(`/repairlist/${row.id}`)
  }

  const handleNewItem = (): void => {
    navigate('/repairlist/new-item')
  }

  const handleCategoryChange = (newValue: SelectInputOption) =>
    updateRepairListFilter({ category: newValue })

  const handleTerritoryChange = (newValue: SelectInputOption) =>
    updateRepairListFilter({ territory: newValue })

  const handleDeleteItem = (id: string): void => {
    setLoading(true)

    dispatch(
      repairItemActions.deleteRepairItem(id, (succ) => {
        if (succ) {
          dispatch(
            repairListActions.fetchRepairlist(
              {
                ...pagination,
                searchParams: {
                  category:
                    category?.key === 'all' || !category ? null : category?.key,
                  territory:
                    territory?.key === 'all' || !territory
                      ? undefined
                      : territory.key,
                },
              },
              (suc) => {
                setLoading(false)
              }
            )
          )
        } else {
          setLoading(false)
        }
      })
    )
    setDeleteModalOpen(false)
  }

  const handleStatsToggle = () => {
    setStatsToggle(!statsToggle)
  }

  const handleDownloadCsv = () => {
    const searchParams = {
      category:
        category?.key === 'all' || !category ? null : category?.key,
      territory:
        territory?.key === 'all' || !territory
          ? undefined
          : territory.key,
    }
    dispatch(
      repairListActions.fetchCsvRepairlist({ searchParams }, (succ: boolean) => {
        if (succ) {
          downloadRef.current?.link.click()
        }
      })
    )
  }

  return (
    <Grid container spacing={2} flexDirection="column">
      <Grid item container className={styles.header}>
        <Typography className={styles.title}>Estimate Price Items</Typography>
        <Box gap={2} display="flex">
          {/* <TextField placeholder="Search Item..." sx={{ width: '270px' }} /> */}
          <SelectInput
            options={territories}
            value={territory || territories[0]}
            sx={{ width: '270px' }}
            onChange={handleTerritoryChange}
          />
          <SelectInput
            options={itemCategories}
            value={category || itemCategories[0]}
            sx={{ width: '270px' }}
            onChange={handleCategoryChange}
          />
          {/* <Button
            className={
              statsToggle
                ? styles.statsPanelToggleSelected
                : styles.statsPanelToggleUnselected
            }
            onClick={handleStatsToggle}
          >
            {<AssessmentIcon style={{ width: '14px', height: '14px' }} />}
          </Button> */}
          <Button
            className={styles.submitBtn}
            endIcon={<DownloadIcon style={{ width: '16px', height: '16px' }} />}
            onClick={handleDownloadCsv}
          >
            Export CSV
          </Button>
          <ExportCSV
            innerRef={downloadRef}
            headers={headersCSV}
            data={dataCSV}
            fileName="repairlist.csv"
            label=""
          />
          <Button
            className={styles.addBtn}
            onClick={handleNewItem}
            endIcon={<AddIcon style={{ width: '16px', height: '16px' }} />}
          >
            Add New Item
          </Button>
        </Box>
      </Grid>
      <Grid item container spacing={2}>
        <Grid item xs={statsToggle ? 9 : 12}>
          <TablePagination
            callToApi={(start, limit) => {
              setPagination({
                page: start,
                size: limit,
              })
            }}
            totalItems={total}
            rowsPerPageDefault={50}
            rowsPerPageOptions={ROWS_PER_PAGE_SIMPLE}
          />
          <Table
            headers={headers}
            hasPagination={false}
            loading={loading}
            rowAction={handleClickRow}
          >
            {repairList.map((item) => ({
              ...item,
              handleDeleteItem: (id: string) => {
                setDeleteId(id)
                setDeleteModalOpen(true)
              },
            })) || []}
          </Table>
        </Grid>
        {statsToggle && (
          <Grid item xs={3}>
            stats panel
          </Grid>
        )}
      </Grid>
      <Modal
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        title="Delete Repair Item"
      >
        <Grid container flexDirection="column" style={{ gap: '12px' }}>
          <Typography variant="h5">
            Are you sure you wish to delete this price item? This will not
            remove this item in previous estimates.
          </Typography>
          <Grid
            container
            justifyContent="flex-end"
            style={{ gap: '8px', flexWrap: 'nowrap' }}
          >
            <Button
              variant="containedError"
              onClick={() => setDeleteModalOpen(false)}
              sx={{ width: '100%' }}
            >
              Cancel
            </Button>
            <Button
              variant="containedGreen"
              onClick={() => handleDeleteItem(deleteId)}
              sx={{ width: '100%' }}
            >
              Confirm
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </Grid>
  )
}

export default RepairList
