import { FC } from 'react'
import { EstimatorProps } from './types'
import { Button, Grid, Typography } from 'src/components/UI'
import CircleIconButton from 'src/components/UI/CustomUI/molecules/CircleIconButton'

const Estimator: FC<EstimatorProps> = ({ estimator, assignEstimator }) => {
  return (
    <Grid item container justifyContent="space-between" alignItems="center">
      <Grid item container spacing={2} alignItems="center" width="fit-content">
        <Grid item>
          <CircleIconButton
            width="36px"
            height="36px"
            bgcolor="#71B085"
            icon={
              <>
                {`${estimator.firstName} ${estimator.lastName}`
                  ?.split(' ')
                  .map((name) => name[0]?.toUpperCase())}
              </>
            }
            iconColor="white"
          />
        </Grid>
        <Grid item>
          <Typography variant="h6">{`${estimator.firstName} ${estimator.lastName}`}</Typography>
        </Grid>
      </Grid>
      <Grid item>
        <Button variant="text" onClick={() => assignEstimator(estimator.id)}>
          Assign
        </Button>
      </Grid>
    </Grid>
  )
}

export default Estimator
