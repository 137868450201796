import storeInfo from '../store'
import { CompanyType } from './types'

export const getCompany = () => (): CompanyType => {
  const { company } = storeInfo.store.getState()
  return company
}

export const getCompanyId = (): string => {
  const { company } = storeInfo.store.getState()
  return (company as CompanyType)?.id || ''
}
