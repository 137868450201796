import { Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { HeaderType } from 'src/components/UI/CustomUI/organisms/Table/types'
import { getEstimate, getJob } from 'src/ducks/selectors'
import { formatTimestampTimeZone } from 'src/helpers'

const headers: HeaderType[] = [
    {
        id: 'createdOn',
        label: 'Date',
        custom: true,
        Element: (row: any, dispatch): JSX.Element => {
            const estimate = useSelector(getEstimate())
            const timeZone = estimate?.properties?.address?.timeZone ? estimate.properties.address.timeZone : ""

            return (
                <Typography variant="body1">{formatTimestampTimeZone(row.createdOn, 'MM/DD/YYYY HH:mm', timeZone)}</Typography>
            )
        }
    },
    {
        id: 'createdByName',
        label: 'Updated'
    },
    {
        id: 'text',
        label: 'Description',
        maxWidth: 520

    }
]

export default headers
