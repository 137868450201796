import { Box, Button, Divider, Grid, Link } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { PrivateLoader, ScrollWrapper } from 'src/components/templates'
import Expand from 'src/components/UI/CustomUI/atoms/Expand'
import { employeesActions, itemsActions } from 'src/ducks/actions'
import Comments from './Comments'
import styles from './styles.module.scss'
import { getEstimate } from 'src/ducks/selectors'
import EstimateContact from './EstimateContact'
import ApprovalFlowTracker from './ApprovalFlowTracker'
import { ESTIMATE_STATUS } from 'src/helpers'
import InspectionData from './InspectionData'

const Overview = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const [pageLoading, setPageLoading] = useState(true)
  const estimate = useSelector(getEstimate())
  const showApprovalFlowTracker =
    ![ESTIMATE_STATUS.NEW, ESTIMATE_STATUS.COMPOSING].includes(
      estimate?.status as ESTIMATE_STATUS
    ) && estimate?.serviceable

  const fetchServices = () => {
    dispatch(
      itemsActions.fetchItemsByCategory(
        {
          params: { jobId: id },
        },
        (succ) => {
          if (succ) {
            dispatch(
              employeesActions.fetchEmployees(
                { page: 0, size: 150, searchParams: { approved: true } },
                (_succ: boolean) => {
                  setPageLoading(false)
                }
              )
            )
          }
        }
      )
    )
  }

  useEffect(() => {
    fetchServices()
  }, [id])

  return (
    <Box
      className={styles.Overview}
    >
      <PrivateLoader loading={pageLoading}>
        <Expand>

          <Grid
            item
            container
            className={styles.Overview__container}
            sx={{
              border: '2px solid var(--border-color)',
              borderRadius: '0.75rem 0 0.75rem 0.75rem',
              minHeight: '30vh',
              marginTop: '-3px'
            }}
            spacing={2}
          >
            <Grid container item xs={12} lg={7} className={styles.Overview__left}>
              {showApprovalFlowTracker && (
                <Grid
                  direction="column"
                  container
                  item
                  xs={12}
                  lg={7}
                  style={{
                    flexBasis: 'unset',
                    maxWidth: "unset"
                  }}
                // className={styles.Overview__left}
                >
                  <ApprovalFlowTracker />
                  <Divider style={{ paddingTop: '1.5rem' }} />
                </Grid>
              )}
              <EstimateContact />
              <InspectionData />
            </Grid>
            <Grid container item xs={12} lg={4.5} sx={{ height: 'fit-content' }}>
              <Comments />
            </Grid>
          </Grid>

        </Expand>
      </PrivateLoader>

    </Box>

  )
}
export default Overview
