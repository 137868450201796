import React from 'react'
import { ESTIMATE_STATUS } from 'src/helpers'
import { Box } from 'src/components/UI'

const statusLabels = {
  NEW: {
    label: 'NEW',
    color: '#0071A3',
    bgColor: '#FAFDFF',
  },
  COMPOSING: {
    label: 'COMPOSING',
    color: '#1A2A55',
    bgColor: '#F0F3FA',
  },
  OCR_REVIEW: {
    label: 'OCR REVIEW',
    color: '#494A5A',
    bgColor: '#F9FAFA',
  },
  IN_REVIEW: {
    label: 'REVIEW',
    color: '#4661A8',
    bgColor: '#F0F3FA',
  },
  PENDING_APPROVALS: {
    label: 'PENDING APPROVALS',
    color: '#0E905A',
    bgColor: '#EDFDF6',
  },
  DECLINED: {
    label: 'DECLINED',
    color: '#1A2A55',
    bgColor: '#F0F3FA',
  },
  NOT_SUBMITTED: {
    label: 'NOT SUBMITTED',
    color: '#1A2A55',
    bgColor: '#F0F3FA',
  },
  APPROVED: {
    label: 'APPROVED',
    color: '#1A2A55',
    bgColor: '#F0F3FA',
  },
  OCR_PROCESSING: {
    label: 'OCR PROCESSING',
    color: '#1A2A55',
    bgColor: '#F0F3FA',
  },
  EXPIRED: {
    label: 'EXPIRED',
    color: '#1A2A55',
    bgColor: '#F0F3FA',
  },
  READY_FOR_RELEASE: {
    label: 'READY_FOR_RELEASE',
    color: '#1A2A55',
    bgColor: '#F0F3FA',
  },
  RELEASED: {
    label: 'RELEASED',
    color: '#1A2A55',
    bgColor: '#F0F3FA',
  },
  ARCHIVED: {
    label: 'Archived',
    color: '#F0F3FA',
    bgColor: '#1A2A55',
  },
}

const StatusLabel = ({ status }: { status: string }) => {
  const labelInfo = statusLabels[status as keyof typeof statusLabels] || {
    label: 'RELEASED',
    color: '#1A2A55',
    bgColor: '#F0F3FA',
  }

  if (!status) return null

  return (
    <Box
      style={{
        color: labelInfo.color,
        backgroundColor: labelInfo.bgColor,
        padding: '2px 4px',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '150%',
        borderRadius: '4px',
      }}
    >
      {labelInfo.label}
    </Box>
  )
}

export default StatusLabel
