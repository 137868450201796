import { HeaderType, AlignType } from 'src/components/UI/CustomUI/organisms/Table/types'

import { Button, SelectInput } from 'src/components/UI'
import { USER_TYPE } from 'src/helpers'
import { Typography } from '@mui/material'
import moment from 'moment'
import { useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getJob, getMaterialInvoices, getOrdersSelector } from 'src/ducks/selectors'
import { jobActions, materialInvoicesActions } from 'src/ducks/actions'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import { InvoiceMaterialContext } from '../../../../ControlButtons/Modals/ModalMaterialInvoices/context'
import { invoiceMaterialModalTypes } from '../../../../ControlButtons/Modals/ModalMaterialInvoices/context/types'
import Remove from '../../../../ControlButtons/Modals/ModalMaterialInvoices/Remove'
import Modal from 'src/components/UI/CustomUI/molecules/Modal'
import { MaterialInvoices } from 'src/ducks/materialInvoice/types'
import { MaterialInvoice } from 'src/ducks/types'


const headers: HeaderType[] = [
    {
        id: 'invoiceNum',
        label: 'Invoice Number',
    },
    {
        id: 'amount',
        label: 'Amount',
        custom: true,
        Element: (row: any): JSX.Element => {
            return (
                <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                    {row?.amount > 0 ? `$${row?.amount}` : `-$${row?.amount * -1}`}
                </Typography>
            )
        },

    },
    {
        id: 'orderName',
        label: 'Work Order',
        maxWidth: 200,
    },
    {
        id: 'insertedOn',
        label: 'Added',
        maxWidth: 200,
        custom: true,
        align: 'center' as AlignType,
        Element: (row: any): JSX.Element => {
            return (
                <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                    {moment.unix(row?.insertedOn).format('MM/DD/YYYY')}
                </Typography>
            )
        },
    },
    {
        id: 'view',
        label: 'View',
        maxWidth: 200,
        custom: true,
        align: 'center' as AlignType,
        Element: (row: any): JSX.Element => {
            return (
                <Button
                    variant='contained'
                    color='primary'
                    sx={{ width: '75px', height: '26px' }}
                    href={row.fileUrl}
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    View
                </Button>
            )
        },
    },
]
export default headers
