import { Theme } from '../../../../../UI'
import { makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-between',
    padding: '8px',
    width: '300px',
    gap: '10px',
    minHeight: '100px',
    maxHeight: '101px',
    boxShadow:
      '0px 1px 1px rgba(26, 42, 85, 0.24), 0px 0px 1px rgba(26, 42, 85, 0.31)',
    borderRadius: '8px',
  },
  count: {
    fontSize: '24px !important',
    fontWeight: 500,
    lineHeight: '29px !important',
    color: '#1A2A55',
    width: 'fit-content'
  },
  price: {
    fontSize: '20px !important',
    fontWeight: 500,
    lineHeight: '24px !important',
    color: '#0E905A',
    width: 'fit-content'
  },
  status: {
    fontSize: '18px !important',
    fontWeight: 400,
    lineHeight: '28px !important',
    color: '#494A5A'
  },
  due: {
    lineHeight: '18px !important',
    color: '#494A5A'
  },
  headerContainer:{
    padding: '0 0.5rem',
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    height: '29px',
  }
}))
