import { FC } from 'react'
import { Box, Typography } from '../../../../../UI'
import { PriceDetailProps } from './types'
import useStyles from './styles'
import { round } from 'src/helpers'
import TotalPrice from '../Cart/components/TotalPrice'
import { isEmpty } from 'lodash'
import Price from 'src/components/UI/CustomUI/atoms/Price'

const PriceDetail: FC<PriceDetailProps> = ({
    discount,
    taxAmount,
    isNar,
    totalValue,
    taxRate,
    align = "flex-end",
    style,
    serviceable,
    totalPrice,
    ptp,
    showTotalPrice = true,
    discountText
}) => {
    const styles = useStyles()
    const discountTextIfNar = isNar && discount && (discount * -1) <= 5 ? 'NAR Exclusive Discount' : 'Discount'


    const discountExp = (discount || 0) * -1
    const discountExpText = `${discountExp}%`

    const subtotal = ((totalValue as number - (taxAmount || 0)) / ((100 - discountExp) / 100))
    const discountValue = subtotal * (discountExp / 100)

    return (
        <Box className={styles.root} style={{ alignItems: align, ...style }}>
            {(discount || taxAmount) && subtotal ?
                <Box className={styles.row} style={{ marginBottom: '.5rem' }}>
                    <Typography variant='body2' className={styles.body2}>Sub Total:</Typography>
                    <Price price={`$${round(subtotal, 2)}`} totalClassName={styles.price} decimalsClassName={styles.decimals} />
                    {/* <Typography variant='body2' className={styles.price}>${round(subtotal, 2)}</Typography> */}
                </Box>
                : null
            }

            {discount && discount !== 0 && discountValue ?
                <Box className={styles.row} style={{ marginBottom: '.5rem' }}>
                    <Typography variant='body2' className={styles.body2}>{!isEmpty(discountText) ? discountText : discountTextIfNar} ({discountExpText}):</Typography>
                    <Price price={`-$${round(discountValue, 2)}`} totalClassName={styles.price} decimalsClassName={styles.decimals} />
                    {/*  <Typography variant='body2' className={styles.price}>-${round(discountValue, 2)}</Typography> */}
                </Box>
                : null
            }

            {taxAmount && taxAmount !== 0 ?
                <Box className={styles.row} style={{ marginBottom: '.5rem' }}>
                    <Typography variant='body2' className={styles.body2}>Taxes ({round((taxRate || 0) * 100, 2)}%):</Typography>
                    <Price price={`$${round(taxAmount, 2)}`} totalClassName={styles.price} decimalsClassName={styles.decimals} />
                    {/*  <Typography variant='body2' className={styles.price}>${round(taxAmount, 2)}</Typography> */}
                </Box>
                : null
            }

            {showTotalPrice &&
                <TotalPrice
                    serviceable={serviceable}
                    price={totalPrice}
                    ptp={ptp}
                    style={{
                        alignItems: align,
                        justifyContent: align,
                    }}
                />
            }

        </Box>
    )
}

export default PriceDetail
