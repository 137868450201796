import { authTypes } from '../auth/types'
import { EmailEvents, Message } from '../types'
import { AmountValues, CommunicationsType, communicationsTypes, MessagesActions } from './types'

const initialState: CommunicationsType = {
  messages: [],
  messagesByThread: [],
  amounts: {
    all: 0,
    unread: 0,
    sent: 0,
  },
  emailEvents: undefined
}

const reducer = (state = initialState, action: MessagesActions): CommunicationsType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case communicationsTypes.SET_MESSAGES: {
      return {
        ...state,
        messages: payload as Message[]
      }
    }

    case communicationsTypes.SET_MESSAGE: {
      return {
        ...state,
        messages: [
          ...state.messages,
          payload as Message
        ]
      }
    }
    case communicationsTypes.SET_MESSAGES_BY_THREAD: {
      return {
        ...state,
        messagesByThread: payload as Message[]
      }
    }
    case communicationsTypes.SET_MESSAGE_BY_THREAD: {
      return {
        ...state,
        messagesByThread: [
          ...state.messagesByThread,
          payload as Message
        ]
      }
    }
    case communicationsTypes.SET_AMOUNT_VALUE: {
      const { attr, value } = payload as AmountValues
      return {
        ...state,
        amounts: {
          ...state.amounts,
          [attr]: value
        }
      }
    }
    case communicationsTypes.RESET_AMOUNTS: {
      return {
        ...state,
        amounts: {
          all: 0,
          unread: 0,
          sent: 0,
        }
      }
    }
    case communicationsTypes.SET_EMAIL_EVENTS: {
      return {
        ...state,
        emailEvents: payload as EmailEvents
      }
    }
    default:
      return state
  }
}

export default reducer
