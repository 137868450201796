import { HeaderType } from 'src/components/UI/CustomUI/organisms/Table/types'
import { Typography } from 'src/components/UI'

const headers: HeaderType[] = [

    // { id: 'address', label: 'Address' },
    {
        id: 'address',
        label: 'Address',
        custom: true,
        Element: (row: any, dispatch): JSX.Element => {
            return (
                <Typography variant="h6">{row.address?.line_1}</Typography>
            )
        }
    },
    { id: 'active', label: 'Active' },
    { id: 'status', label: 'Status' }
]

export default headers
