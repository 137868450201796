import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '100% !important',
      borderRadius: '16px !important',
      textAlign: 'center',
      paddingBottom: '1rem !important'
    },
    header: {
      marginBottom: '1.5rem !important',
      marginTop: '.5rem !important'
    },
    text: {
      padding: '0 !important',
      fontFamily: 'NextArtHeavy !important',
      textAlign: 'left',
      marginBottom: '2rem !important',
      [theme.breakpoints.down('sm')]: {
        fontSize: "18px !important",
        textAlign: 'center',
        marginBottom: '.5rem !important'
      }
    },
    boxes: {
      display: 'flex !important',
      // gridTemplateColumns: 'repeat(3, 1fr)',
      gap: '1rem !important',
      padding: '0 !important',
      paddingBottom: '1rem !important',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column'
      }
    },
    box: {
      display: 'flex !important',
      justifyContent: 'flex-start !important',
      flexDirection: 'column',
      alignItems: 'flex-start !important',
      width: '280px !important',
      textAlign: 'left',
      border: '2px solid #F5F6F7 !important',
      borderRadius: '8px !important',
      boxShadow: '0px 3px 5px rgba(26, 42, 85, 0.2), 0px 0px 1px rgba(26, 42, 85, 0.31) !important',
      padding: '8px !important',
      position: 'relative',
      cursor: 'pointer !important',
      '&:hover': {
        border: '2px solid var(--bosscat-blue-600) !important',
        transition: '300ms all !important',
        '@global': {
          '.MuiSvgIcon-root': {
            fill: 'var(--bosscat-blue-600) !important',
          }
        }
      },
      '@global': {
        'img': {
          [theme.breakpoints.down('sm')]: {
            gridArea: 'img !important',
            width: 55,
            marginRight: '.5rem'
          }
        }
      },
      [theme.breakpoints.down('sm')]: {
        width: '100% !important',
        display: 'grid !important',
        gridTemplateAreas: ' "img title button" "img subtitle button"'
      }
    },
    noAbsolute: {
      position: 'relative'
    },
    radioButton: {
      position: 'absolute',
      right: '8px !important',
      padding: '0 !important',
      width: 'fit-content !important',
      height: 'fit-content !important',
      minWidth: 'fit-content !important',
      '@global': {
        '.MuiButton-label': {
          width: 'fit-content'
        }
      },
      [theme.breakpoints.down('sm')]: {
        gridArea: 'button'
      }
    },
    title: {
      color: '#0B060F !important',
      fontSize: '18px !important',
      marginBottom: '.5rem !important',
      [theme.breakpoints.down('sm')]: {
        gridArea: 'title !important',
        marginBottom: '0 !important'
      }
    },
    subtitle: {
      color: '#6D6A6F !important',
      [theme.breakpoints.down('sm')]: {
        gridArea: 'subtitle'
      }
    },
    closeButton: {
      padding: 0,
      minWidth: '32px !important',
      height: '32px !important',
      '@global': {
        '.MuiSvgIcon-root': {
          width: '0.7em !important',
        }
      }
    }
  })
)
