/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo } from '../../helpers'
import { Punchlist } from '../../api'
import { repairListActions } from '../actions'
import {
  repairlistTypes,
  FetchRepairlistAction,
  FetchCsvRepairlistAction,
} from './types'

export function* fetchRepairlist({
  payload,
  callback,
}: FetchRepairlistAction): SagaIterator {
  let success = false
  try {
    const page = payload?.page ?? 0
    const size = payload?.size ?? 1000
    const searchParams = payload?.searchParams
    const start = page * size
    const limit = size

    const repairList = yield call(Punchlist.repairList.getRepairList, {
      start,
      limit,
      searchParams,
    })

    if (repairList) {
      yield put(repairListActions.setRepairlist(repairList))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the price list'))
    if (callback) yield call(callback, false)
  }
}

export function* fetchCsvRepairlist({
  payload,
  callback,
}: FetchCsvRepairlistAction): SagaIterator {
  let success = false
  try {
    const searchParams = payload?.searchParams
    const start = 0
    const limit = 2000

    const repairList = yield call(Punchlist.repairList.getRepairList, {
      start,
      limit,
      searchParams,
    })

    if (repairList) {
      yield put(repairListActions.setCsvRepairlist(repairList.items))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the csv price list'))
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(repairlistTypes.FETCH_REPAIRLIST, fetchRepairlist)
  yield takeLatest(repairlistTypes.FETCH_CSV_REPAIRLIST, fetchCsvRepairlist)
}
