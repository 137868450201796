import { PunchlistFile } from "src/ducks/types"

export interface DispatchContextType {
    jobFiles: PunchlistFile[],
    marginAmount: number,
    scheduledStartTimestamp: number | null,
    scheduledEndTimestamp: number | null,
    dispatchNotes: string,
    orderBids: any[] | null,
    showWarnings: boolean,
    openModal: boolean,
    modalType?: 'Trades' | 'ProConflict' | 'ExpiredLicenses'
    tradesSelected: string[],
    loadingPricing: boolean,
}

export interface DispatchValue {
    attr: keyof DispatchContextType
    value: any
}

export const dispatchTypes = {
    SET_VALUE: 'SET_VALUE'
}