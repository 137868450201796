/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { Punchlist } from '../../api'
import { errorTextTryingTo, FEEDBACK_TYPE, isEmpty } from '../../helpers'
import { ordersActions } from '../actions'
import {
  ordersTypes,
  FetchOrdersAction,
  CreateOrderAction,
  CreateOrderRequest,
  UpdateOrderAction,
  OrderType,
  UpdateOrderStateAction,
  CreateBillAction,
  GetOrderConflictAction,
} from './types'
import { UpdateRequest } from '~api/types'
import { Feedback } from '../types'

export function* fetchOrders({
  payload,
  callback,
}: FetchOrdersAction): SagaIterator {
  let success = false
  try {
    const params = payload.params ?? ''
    const data = yield call(Punchlist.orders.getOrders, {
      params,
    })
    if (data !== undefined || data !== null) {
      yield put(ordersActions.setOrders(data))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the orders'))
    if (callback) yield call(callback, false)
  }
}

export function* createOrder({
  payload,
  callback,
}: CreateOrderAction): SagaIterator {
  try {
    const request: CreateOrderRequest = payload
    const order = yield call(Punchlist.orders.createOrder, request)

    if (!isEmpty(order)) {
      const { estimateId } = order
      const params: any = { jobId: estimateId }
      const orders = yield call(Punchlist.orders.getOrders, {
        params,
      })
      if (orders) {
        yield put(ordersActions.setOrders(orders))
        yield call(callback, true, order.orderId)
      }
    }
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('create the order'))
    yield call(callback, false, null)
  }
}

export function* updateOrder({
  payload,
  callback,
}: UpdateOrderAction): SagaIterator {
  try {
    const request: UpdateRequest = []
    for (const attr in payload) {
      if (attr !== 'orderId')
        request.push({
          op: 'add',
          path: '/' + attr,
          value: payload[attr as keyof OrderType],
        })
    }
    const order = yield call(
      Punchlist.orders.updateOrder,
      payload.orderId as string,
      request
    )
    if (!isEmpty(order)) {
      const { estimateId } = order
      const params: any = { jobId: estimateId }
      const orders = yield call(Punchlist.orders.getOrders, {
        params,
      })
      if (orders) {
        yield put(ordersActions.setOrders(orders))
        yield call(callback, true)
      }
    }
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('update order'))
    yield call(callback, false)
  }
}

export function* updateOrderState({
  payload,
  callback,
}: UpdateOrderStateAction): SagaIterator {
  let success = false
  try {
    let data = null
    const { orderId, orderState } = payload

    if (orderState === 'checkin') {
      data = yield call(Punchlist.orders.setOrderCheckIn, orderId)
    }
    if (orderState === 'complete') {
      data = yield call(Punchlist.orders.setOrderComplete, orderId)
    }
    if (orderState === 'accepted') {
      data = yield call(Punchlist.orders.setOrderAccepted, orderId)
    }
    if (orderState === 'unaccepted') {
      data = yield call(Punchlist.orders.setOrderUnaccepted, orderId)
    }
    if (orderState === 'reopen') {
      data = yield call(Punchlist.orders.setOrderReopen, orderId)
    }
    if (data) {
      success = true
    }
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('update the job'))
    if (callback) yield call(callback, false)
  }
}

export function* createBill({
  payload,
  callback,
}: CreateBillAction): SagaIterator {
  let success = false
  const {
    jobId,
    orderId,
    orderType,
    pricingItemId,
    amount,
    rating,
    feedback,
    vendorId,
    paymentType,
  } = payload

  try {
    const data = yield call(Punchlist.bills.createBill, {
      jobId,
      orderId,
      orderType,
      pricingItemId,
      amount,
      paymentType,
    })
    if (data) {
      success = true
      if (rating && feedback && vendorId) {
        try {
          const request: Feedback = {
            resourceId: vendorId,
            resourceType: FEEDBACK_TYPE.VENDOR,
            ratings: [{ score: rating, description: orderId }],
            comments: feedback,
          }

          yield call(Punchlist.feedback.sendFeedback, request)
        } catch (_error) { }
      }
    }
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('create the bill'))
    if (callback) yield call(callback, false)
  }
}

export function* getOrderConflict({
  payload,
  callback,
}: GetOrderConflictAction): SagaIterator {
  let success = false
  try {
    const data = yield call(Punchlist.orders.getOrderConflict, payload)
    if (data !== undefined || data !== null) {
      yield put(ordersActions.setOrdersConflict(data))
    }
    success = true
    if (callback) yield call(callback, success, data)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the orders conflict'))
    if (callback) yield call(callback, false, null)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(ordersTypes.FETCH_ORDERS, fetchOrders)
  yield takeLatest(ordersTypes.CREATE_ORDER, createOrder)
  yield takeLatest(ordersTypes.UPDATE_ORDER, updateOrder)
  yield takeLatest(ordersTypes.UPDATE_ORDER_STATE, updateOrderState)
  yield takeLatest(ordersTypes.CREATE_BILL, createBill)
  yield takeLatest(ordersTypes.GET_ORDERS_CONFLICT, getOrderConflict)
}
