
import { CLIENT_TYPE, USER_TYPE } from 'src/helpers'
import { getClientPrimaryEmail } from '../selectors'
import storeInfo from '../store'
import { Client } from '../types'
import { ClientType } from './types'

export const getClient = () => (): ClientType => {
  const { client } = storeInfo.store.getState()
  return client
}

export const getClientData = () => (): Partial<Client> => {
  const { client }: { client: Client } = storeInfo.store.getState()
  const userData = {
    firstName: client.firstName,
    lastName: client.lastName,
    email: getClientPrimaryEmail(client),
    phone: client.phone,
    createdOn: client.registrationCompleteOn
  }
  return userData
}

export const getClientStatics = () => (): any => {
  const { client }: { client: Client } = storeInfo.store.getState()

  const userStats = {
    estimates: {
      label: 'Estimates',
      value: client.statistics?.find(x => x.status === "Estimates")?.count
    },
    jobs: {
      label: 'Jobs',
      value: client.statistics?.find(x => x.status === "Jobs")?.count
    },
    activeEstimates: {
      label: 'Active Estimates',
      value: client.statistics?.find(x => x.status === "Active Estimates")?.count
    },
    activeJobs: {
      label: 'Active Jobs',
      value: client.statistics?.find(x => x.status === "Active Jobs")?.count
    }
  }
  return userStats
}

export const getClientInfo = (narPrice: string, affiliatePrice: string) => (): any => {
  const { client }: { client: Client } = storeInfo.store.getState()
  const isNar = client.clientPriceModelId === narPrice
  const isAffiliate = client.clientPriceModelId === affiliatePrice
  const userStats = {
    nar: {
      label: 'NAR',
      value: client.nar ? 'Yes' : 'No'
    },
    discount: {
      label: 'Discount',
      value: isNar ? 'NAR' : isAffiliate ? 'AFFILIATE' : '-' ?? '-'
    },
    revShare: {
      label: 'Rev Share',
      value: client.revSharePriority ?? '-'
    },
    affiliate: {
      label: 'Affiliate',
      value: client.affiliate ?? '-'
    },
    clientType: {
      label: 'Client Type',
      value: client.clientType ?? '-'
    }
  }
  return userStats
}

export const getClientAdmin = () => {
  const { client }: { client: Client } = storeInfo.store.getState()
  return client.isAdmin
}

export const getClientIsHomeowner = () => {
  const { client } = storeInfo.store.getState()
  return [CLIENT_TYPE.HOMEOWNER, CLIENT_TYPE.HOME_BUYER, CLIENT_TYPE.INSTITUTIONAL].includes(client?.clientType)
}