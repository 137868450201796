import { Image, Page, Text, View } from '@react-pdf/renderer'
import styles from './style'
import { logoHouseWhite, needHelp, pdfBg2, videoTutorialQr } from 'src/assets/index'



const Page2 = ({ userInfo }) => {

  const listItems = [
    'Review your estimate by category',
    'Add project items to your cart',
  ]

  if (userInfo.serviceable) {
    listItems.push(
      `Place your order to begin the
       scheduling process`,
      `Have additional items needing an
      in-person assessment? Schedule a 
      free consultation with our in-home 
      consultation team`,
    )
  } else {
    listItems.push('View the total cost of your project')
  }

  return (
    <Page size="A4">
      <View style={styles.page2}>
        {/* <View style={styles.pageBg2}> */}
        <Image style={styles.imgBgPage2} src={pdfBg2} alt="bg" />
        {/* </View> */}
        <View style={styles.page2Right}>
          <View style={styles.page2Header}>
            <Text
              style={{
                ...styles.white,
                ...styles.estimateTitle,
              }}
            >
              PERSONALIZE
            </Text>
            <Text
              style={{
                ...styles.white,
                ...styles.estimateTitle,
              }}
            >
              YOUR
            </Text>
            <Text
              style={{
                ...styles.white,
                ...styles.estimateTitle,
              }}
            >
              ESTIMATE
            </Text>
            <View style={{ marginRight: '12px', marginTop: '16px' }}>
              {listItems.map((item, index) => (
                <View style={{ ...styles.row, ...styles.listItemBullet }}>
                  <View style={{ ...styles.bullet }}>
                    <Text style={{ ...styles.fontMedium, ...styles.latoBold }}>
                      {index + 1}
                    </Text>
                  </View>
                  <Text
                    style={{
                      ...styles.white,
                      ...styles.fontMedium,
                      ...styles.latoBold,
                      marginLeft: '4px'
                    }}
                  >
                    {item}
                  </Text>
                </View>
              ))}
            </View>
          </View>
          <View style={{
            ...styles.qrVideoContainer,
            ...styles.row,
            display: 'flex',
            gap: '16px',
            flexDirection: userInfo.serviceable ? 'row' : 'column',
          }}>
            <Image
              src={videoTutorialQr}
              alt="estimate qr code"
              style={{
                ...styles.mh2,
                ...styles.qrCode,
              }}
            />
            <View style={{
              marginLeft: '16px',
            }}>
              <Text style={{ ...styles.fontMedium, ...styles.latoBold }}>
                Watch a video on how to
              </Text>
              <Text style={{ ...styles.fontMedium, ...styles.latoBold }}>
                navigate to your estimate
              </Text>
            </View>
          </View>
          <View
            style={{
              ...styles.helpBanner,
            }}
          >
            <Image
              style={{
                position: 'absolute',
                left: -60,
                width: '120px',
                top: -15,
              }}
              src={needHelp}
              alt="need help"
            />
            <View>
              <Text
                style={{
                  ...styles.white,
                  ...styles.latoBold,
                  ...styles.fontLarge,
                }}
              >
                NEED HELP?
              </Text>
              <Text
                style={{
                  ...styles.white,
                  ...styles.latoNormal,
                  ...styles.fontSmall,
                }}
              >
                Our team is here to help.
              </Text>
              <Text
                style={{
                  ...styles.white,
                  ...styles.latoNormal,
                  ...styles.fontSmall,
                }}
              >
                CustomerCare@BosscatHome.com
              </Text>
              <Text
                style={{
                  ...styles.white,
                  ...styles.latoNormal,
                  ...styles.fontSmall,
                }}
              >
                1-877-4-BOSSCAT
              </Text>
            </View>
            <Image
              style={{
                ...styles.mh2,
                width: '41px',
                height: '49px'
              }}
              src={logoHouseWhite}
              alt="bosscat logo small"
            />
          </View>
        </View>
      </View>
    </Page>
  )
}

export default Page2
