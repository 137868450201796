export const dialog = {
  MuiDialog: {
    styleOverrides: {
      container: {
        maxWidth: '500px',
        margin: 'auto'
      },
      paper: {
        width: '100%',
        maxWidth: '100%',
        //minHeight: '30vh',
        borderRadius: '8px'
      }
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        padding: 0,
      }
    }
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        padding: '16px 12px',
      }
    }
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        padding: '16px 12px',
        justifyContent: 'space-between',
      }
    }
  }
};
