import { GroupedItem } from 'src/ducks/types'

export interface WorkOrdersContextType {
    // GENERAL
    refresh: boolean
    // MODAL
    modalType: MODAL_TYPE | null
    modalOpen: boolean
    tabSelected: number
    // ORDERS
    selectedOrderId: string | null
    selectedTab: number
    // ITEMS
    selectedItemId: string | null
    selectedItemsIds: string[]
    selectedWorkItem: GroupedItem | null
    checkAll: boolean
    expandAll: boolean
    workItems: GroupedItem[]
    searchFilter: string
    order: any
}

export const workOdersTypes = {
    SET_MODAL_TYPE: 'SET_MODAL_TYPE',
    SET_MODAL_OPEN: 'SET_MODAL_OPEN',
    REFRESH: 'REFRESH',
    SET_REVIEW_ITEMS: 'SET_REVIEW_ITEMS',
    TOGGLE_CHECK_ALL: 'TOGGLE_CHECK_ALL',
    TOGGLE_EXPAND_ALL: 'TOGGLE_EXPAND_ALL',
    RESET_ITEM: 'RESET_ITEM',
    SET_SELECTED_ITEM_ID: 'SET_SELECTED_ITEM_ID',
    SET_SELECTED_ITEMS_IDS: 'SET_SELECTED_ITEMS_IDS',
    SET_SELECTED_WORK_ITEM: 'SET_SELECTED_WORK_ITEM',
    SET_SEARCH: 'SET_SEARCH',
    SET_ORDER: 'SET_ORDER',
    SET_TAB_SELECTED: 'SET_TAB_SELECTED'
}

export enum MODAL_TYPE {
    ALL_IMAGES = 'ALL_IMAGES',
    DELETE_ONE = 'DELETE_ONE',
    DELETE_MANY = 'DELETE_MANY'
}
