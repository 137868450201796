/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState, useContext } from 'react'
import Table from 'src/components/UI/CustomUI/organisms/Table'
import Toolbar from './Toolbar'
import { useDispatch, useSelector } from 'react-redux'
import {
  companiesActions,
  filtersActions,
  widgetActions,
  widgetsActions,
} from 'src/ducks/actions'
import { Box } from '@mui/material'
import WidgetsModal from './modals'
import { Grid } from 'src/components/UI'
import styles from './styles.module.scss'
import { Widget } from 'src/ducks/types'
import { WidgetsContext } from './context'
import { SelectInputOption } from 'src/components/UI/CustomUI/atoms/SelectInput/types'
import { dispatchTypes, MODAL_TYPE } from './context/types'
import { getCompaniesFilter } from 'src/ducks/filters/selector'
import { CompaniesFilterType } from 'src/ducks/filters/types'
import headers from './headers'
import { getWidgets } from 'src/ducks/selectors'

const Widgets: FC = () => {
  const dispatch = useDispatch()
  const { dispatch: dispatchContext } = useContext(WidgetsContext)

  const widgets = useSelector(getWidgets())
  const [widgetsTable, setWidgetsTable] = useState(widgets)

  const [loading, setLoading] = useState<boolean>(false)
  const [search, setSearch] = useState('')

  const fetchWidgets = () => {
    setLoading(true)
    dispatch(
      widgetsActions.fetchWidgets(null, () => {
        setLoading(false)
      })
    )
  }

  const fetchCompanies = () => {
    setLoading(true)
    dispatch(companiesActions.fetchCompanies({}, (_succ: boolean) => { }))
  }

  useEffect(() => {
    fetchWidgets()
    fetchCompanies()
  }, [])

  useEffect(() => {
    setWidgetsTable(widgets.filter(widget => widget?.name?.toLowerCase()?.includes(search?.toLocaleLowerCase())))
  }, [search])

  useEffect(() => {
    setWidgetsTable(widgets)
  }, [widgets])

  const attachSettingHandler = (arr: Widget[]) => {
    return arr.map((widget) => ({ ...widget, handleSettings }))
  }

  const handleSettings = (row: any) => {
    dispatch(widgetActions.setWidget(row))
    dispatchContext({
      type: dispatchTypes.SET_VALUE,
      payload: {
        attr: 'modalType',
        value: MODAL_TYPE.EDIT,
      },
    })
    dispatchContext({
      type: dispatchTypes.SET_VALUE,
      payload: {
        attr: 'modalOpen',
        value: true,
      },
    })
  }

  return (
    <Box>
      <WidgetsModal />
      <Box display="flex" flexDirection="column" gap={1}>
        <Toolbar
          fetchData={fetchWidgets}
          setSearch={setSearch}
        />
        <Grid container justifyContent='flex-end' alignItems='center'>
        </Grid>
        <Box className={styles.Widgets__tableContainer}>
          <Table
            headers={headers}
            defaultOrder="desc"
            loading={loading}
            hasPagination={false}
          >
            {attachSettingHandler(widgetsTable || [])}
          </Table>
        </Box>
      </Box>
    </Box>
  )
}

export default Widgets
