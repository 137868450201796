import { authTypes } from '../auth/types'
import { Territory } from '../types'
import { TerritoryActions, TerritoryManagerType, TerritoryRepairItem, TerritoryType, territoryTypes } from './types'


const initialState: TerritoryType = {
  territoryManagers: [],
  territorySuccessManagers: [],
  territoryRepairItems: [],
  territory: null
}

const reducer = (state = initialState, action: TerritoryActions): TerritoryType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case territoryTypes.SET_TERRITORY_MANAGERS: {
      return {
        ...state,
        territoryManagers: payload as TerritoryManagerType[]
      }
    }
    case territoryTypes.SET_TERRITORY_SUCCESS_MANAGERS: {
      return {
        ...state,
        territorySuccessManagers: payload as TerritoryManagerType[]
      }
    }

    case territoryTypes.SET_TERRITORY_REPAIR_ITEMS: {
      return {
        ...state,
        territoryRepairItems: payload as unknown as TerritoryRepairItem[]
      }
    }

    case territoryTypes.SET_TERRITORY: {
      return {
        ...state,
        territory: payload as Territory
      }
    }

    default:
      return state
  }
}

export default reducer
