import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Table from 'src/components/UI/CustomUI/organisms/Table'
import { historyActions } from 'src/ducks/actions'
import { getJobHistory } from 'src/ducks/history/selectors'
import { getEstimate } from 'src/ducks/selectors'
import headers from './headers'

const Overall = ({ id }: { id?: string }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const estimateHistory = useSelector(getJobHistory)
  const estimate = useSelector(getEstimate())

  const fetchEstimateHIstory = useCallback(() => {
    if (id) {
      dispatch(
        historyActions.fetchHistoryJob(id, (_succ: boolean) => {
          setLoading(false)
        })
      )
    }
  }, [dispatch, id])

  useEffect(() => {
    fetchEstimateHIstory()
  }, [fetchEstimateHIstory, estimate])

  return (
    <Table
      headers={headers}
      title="OVERALL"
      loading={loading}
    >
      {estimateHistory}
    </Table>
  )
}

export default Overall;