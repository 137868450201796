import { makeStyles } from '@mui/styles'
import { Theme } from '../../../../UI'

export default makeStyles((theme: Theme) => ({
  root: {
    display: 'grid !important',
    flexWrap: 'wrap',
    width: '100%',
    gap: '1rem'
  },
  rootButton: {
    flexDirection: 'column-reverse'
  },
  slider: {
    borderRadius: '8px',
    '@global': {
      '.swiper-slide': {
        height: 'auto',
        '@global': {
          'img': {
            height: '100%',
            objectFit: 'cover',
          }
        }
      }
    }
  },
  addImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: 'white',
    border: '2px solid #EBECEF',
    borderRadius: '8px',
    color: '#72748D',
    width: '100%',
    height: '100%',
  }
}))
