import { Theme } from 'src/components/UI'
import { makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => ({
  container: {
    flexDirection: 'column',
    gap: '1rem',
    padding: '0 1rem',
    height: '100%'
  },
  buttonGroup: {
    gap: '12px'
  },
  ocrReview: {
    backgroundColor: '#1CCD83 !important',
    height: '40px',
    padding: 'auto !important',
    borderRadius: '100%',
    border: '0 !important'
  },
  addEstimate: {
    height: '40px',
    padding: 'auto !important',
    borderRadius: '100%',
  },
  midFont: {
    fontStyle: 'normal !important',
    fontWeight: 400,
    fontSize: '1rem !important',
    lineHeight: '24px !important',
  },
  smFont: {
    fontStyle: 'normal !important',
    fontWeight: 400,
    fontSize: '14px !important',
    lineHeight: '22px !important',
  },
  attachmentTable: {
    overflow: 'overlay',
    maxHeight: '200px'
  },
  itemsTable: {
    overflow: 'overlay',
    minHeight: '300px',
    maxHeight: 'calc(100vh - 400px)',
    flex: 1
  },
}))
