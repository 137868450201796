import { Grid } from '@mui/material'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { SelectInput, TextFieldLabel } from 'src/components/UI'
import { WorkOrdersContext } from '../../../../../context'
import { debounce } from 'lodash'
import { workOdersTypes } from '../../../../../context/types'
import { useSelector } from 'react-redux'
import { getOrderById } from 'src/ducks/selectors'
import { ORDER_STATUS } from 'src/helpers'


const Notes = () => {
  const { state: { item, selectedOrderId }, dispatch: dispatchContext } = useContext(WorkOrdersContext)
  const [newItem, setNewItem] = useState(item)
  const order = useSelector(getOrderById(selectedOrderId as string))

  const {
    completedTimestamp, vendorPaymentTimestamp,
    statusType, csmAcceptanceTimestamp,
  } = order

  const showPaid = statusType && (ORDER_STATUS.PAID === statusType || vendorPaymentTimestamp != null)
  const showAccepted = statusType && (ORDER_STATUS.COMPLETE === statusType || showPaid || csmAcceptanceTimestamp != null)
  const showComplete = statusType && (ORDER_STATUS.REVIEW === statusType || showAccepted || completedTimestamp != null)

  const vendorIsPaid = order.vendorPaymentTimestamp !== null ? true : (showAccepted && showComplete) ? true : false

  const changeItemValue = useCallback(
    (newValue, attr) => {
      dispatchContext({ type: 'SET_ITEM_VALUE', payload: { attr: attr, value: newValue } })
      dispatchContext({ type: workOdersTypes.SET_EDIT_ITEM_VALUE, payload: { attr: attr, value: newValue } })
    },
    []
  );

  const onItemValueChanged = useMemo(
    () => debounce(changeItemValue, 300),
    [changeItemValue]
  );

  useEffect(() => {
    setNewItem(item)
  }, [item])

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} marginBottom={2}>
        <TextFieldLabel
          isTextArea
          label='BossCat Notes for Technician:'
          placeholder='**Notes FOR Field Technician**'
          value={newItem.punchlistTechNote || ''}
          size="small"
          onChange={(e) => {
            setNewItem({ ...newItem, punchlistTechNote: e.target.value })
            onItemValueChanged(e.target.value, "punchlistTechNote");
          }}
          multiline
          rows={2}
          labelVariant='h6'
        // disabled={vendorIsPaid}
        />
      </Grid>
      <Grid item xs={6}>
        <TextFieldLabel
          isTextArea
          label='Field Tech Notes:'
          placeholder='**Notes FROM Field Technician**'
          value={newItem.fieldNote || ''}
          size="small"
          onChange={(e) => {
            setNewItem({ ...newItem, fieldNote: e.target.value })
            onItemValueChanged(e.target.value, "fieldNote");
          }}
          multiline
          rows={2}
          labelVariant='h6'
        // disabled={vendorIsPaid}
        />
      </Grid>
    </Grid>
  )
}
export default Notes