import { CompanyItem } from 'src/ducks/types'
import { formatParams } from 'src/helpers'
import { UpdateRequest } from '~api/types'
import axios from '../axios'
import { ApiController } from './types'

const base = '/companies'

const apiController: ApiController = {
  getCompanies: async ({ start, limit, institutional, searchParams }) => {
    const params = formatParams(searchParams)

    return await axios
      .get(`${base}?${params}`, {
        params: {
          start,
          limit,
          institutional,
        },
      })
      .then((response) => response.data)
  },

  createCompany: async (request: Partial<CompanyItem>) =>
    await axios.post(`${base}`, request).then((response) => response.data),

  updateCompany: async (id: string, request: UpdateRequest) =>
    await axios
      .patch(`${base}/${id}`, request)
      .then((response) => response.data),

  deleteCompany: async (id: string) =>
    await axios.delete(`${base}/${id}`).then((response) => response.data),

  getCompanyById: async (id: string) =>
    await axios.get(`${base}/${id}`).then((response) => response.data),
}

export default apiController
