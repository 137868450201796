import { Theme } from '../../../UI'
import { makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) =>
({
  text: {
    display: 'flex',
    gap: theme.spacing(1),
    justifyContent: 'center',
    alignItems: 'baseline',
    color: theme.palette.primary.dark
  },
  form: {
    width: '100%',
    maxWidth: '360px',
    marginTop: theme.spacing(4),
    display: 'flex',
    flexFlow: 'column',
    gap: theme.spacing(2)
  },
  submit: {
    margin: theme.spacing(3, 0, 4),
    textTransform: 'none',
    color: 'var(--white-color)',
    backgroundColor: 'var(--blue700)',
    borderRadius: 32
  },
  name: {
    '@global': {
      '.MuiOutlinedInput-input': {
        padding: '16px 14px !important'
      }
    }
  },
  center: {
    display: 'flex',
    justifyContent: 'center'
  },
  icon: {
    margin: '0 0 2px 0',
    width: '12px',
    height: '12px'
  },
  hiddenInput: {
    display: 'none'
  },
  imagePicker: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center'
  },
  profile: {
    width: '160px',
    height: '160px',
    borderRadius: '16px',
    alignSelf: 'center'
  },
  profileBtn: {
    alignSelf: 'center'
  }
})
)
