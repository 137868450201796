import { Grid, Typography } from "@mui/material";
import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Modal from "src/components/UI/CustomUI/molecules/Modal";
import { territoryActions } from "src/ducks/actions";
import { getTerritory } from "src/ducks/selectors";

interface ConfirmDeleteModalProps {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const ConfirmDeleteModal: FC<ConfirmDeleteModalProps> = ({ open, setOpen }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const  territory  = useSelector(getTerritory)
    const navigate = useNavigate();

    const handleDeleteTerritory = () => {
        setLoading(true)
        dispatch(territoryActions.deleteTerritory(territory?.id ?? '', (succ)=>{
            setLoading(false)
            setOpen(false)
            navigate('/territories')
        }))           
    }
    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title={'You are going to delete this Territory'}
            actions={[
                {
                    textButton: 'Cancel',
                    variant: 'outlined',
                    onClick: () => setOpen(false)
                },
                {
                    textButton: 'Delete',
                    variant: 'containedError',
                    loading: loading,
                    onClick: handleDeleteTerritory
                }
            ]}
        >
            <Grid container justifyContent='center' alignItems='center' spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='body1'>
                        This action can't be undone. All related information will be lost.
                        Are you sure to delete this territory?
                    </Typography>
                </Grid>
            </Grid>
        </Modal>

    )

}

export default ConfirmDeleteModal;