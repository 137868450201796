/* eslint-disable @typescript-eslint/no-unused-vars */
import { Auth } from '../../api'
import storeInfo from '../../ducks/store'
import { isEmpty } from '../../helpers'
import { tokenExpired } from '../../api/punchlist/axios'

export const isAuthorized = (): boolean => {
  const { employee } = storeInfo.store.getState()

  const hasId = !isEmpty(employee.id)
  const token = Auth.getToken()
  const hasToken = token !== null

  const authorized = hasId && hasToken && !tokenExpired(token as string)
  return authorized
}

export const isRegisterCompleted = (): boolean => {
  const { employee } = storeInfo.store.getState()
  return employee.registrationComplete
}

export const isApproved = (): boolean => {
  const { employee } = storeInfo.store.getState()
  return employee.approved
}

export const isRequiredData = (): boolean => {
  const { employee } = storeInfo.store.getState()
  return employee?.firstName?.length !== 0 && employee?.lastName?.length !== 0 && employee?.phone !== null
}
