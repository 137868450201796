import { authTypes } from '../auth/types'
import { WorkOrdersActions, workOrdersTypes, WorkOrdersType } from './types'

const initialState: WorkOrdersType = {}

const reducer = (state = initialState, action: WorkOrdersActions): WorkOrdersType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case workOrdersTypes.SET_WORK_ORDERS: {
      return { ...state, ...payload }
    }

    default:
      return state
  }
}

export default reducer
