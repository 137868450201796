import { FC, useCallback, useContext } from 'react'
import moment from 'moment'
// import FullCalendar from '@fullcalendar/react'
// import dayGridPlugin from '@fullcalendar/daygrid'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import { CalendarContext } from './../context.provider'

import 'react-big-calendar/lib/css/react-big-calendar.css'
import './../calendar-styles.css'
import { Box, Typography } from '@mui/material'


const localizer = momentLocalizer(moment)

const TimeGutterHeaderCustom = () => {
  return (
    <Typography variant="body1Bold">
      All Day
    </Typography >)
};

const ViewCalendar: FC = () => {
  const context = useContext(CalendarContext)
  const { selected, view, ordersEvents, siteVisitsEvents } = context

  const onSelectEvent = useCallback((event) => {
    event.resource && window.open(`/jobs/${event.resource}`, '_blank')
  }, [])

  return (
    <Box
      style={{
        overflow: 'scroll',
        minHeight: '500px',
        maxHeight: 'calc(100vh - 250px)',
        height: '100%',
      }}
    >
      <Calendar
        style={{
          minHeight: 500,
          maxHeight: 'calc(100vh - 210px)',
        }}
        defaultDate={new Date()}
        date={selected?.toDate()}
        localizer={localizer}
        step={60}
        events={[...ordersEvents, ...siteVisitsEvents]}
        view={view}
        // startAccessor="start"
        components={{
          timeGutterHeader: TimeGutterHeaderCustom,
        }}
        // showMultiDayTimes
        // showAllEvents
        toolbar={false}
        popup
        doShowMoreDrillDown={true}
        // tooltipAccessor={(event) => ''}
        // onShowMore={(events, date) => console.log('TEST')}
        /*    onNavigate={(date, view) => {
           }}
           onRangeChange={(start, end) => {
             console.log(start, ' // ', end)
           }}
           onView={(view) => {
             console.log(view)
           }} */
        onSelectEvent={onSelectEvent}
        eventPropGetter={(_event) => {
          const color = 'rgba(15, 16, 44, 1)'
          const backgroundColor = 'rgba(112, 144, 255, 1)'
          const border = view !== 'month' ? '1px solid #5e77d3' : 'none'
          return { style: { color, backgroundColor, border } }
        }}
      />
    </Box>
  )
}

export default ViewCalendar
