import {
  AlignType,
} from 'src/components/UI/CustomUI/organisms/Table/types'

import { Typography, Box } from 'src/components/UI'
import ModalActions from '../ModalActions'
import { WORKFLOW_TYPES } from 'src/ducks/types'
import { formatTimestamp, dayFromTimestamp } from 'src/helpers'
import { useSelector } from 'react-redux'
import { employeeIsAdmin, getJob } from 'src/ducks/selectors'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { Button } from '@mui/material'



const useHeaders = () => {

  const job = useSelector(getJob())
  const navigate = useNavigate()

  const [headers] = useState([
    {
      id: 'name',
      label: 'Task',
      custom: true,
      align: 'center' as AlignType,
      Element: (row: any): JSX.Element => {
        return (
          <Typography sx={{ fontSize: '12px' }}>
            {row?.name && row?.name.length > 0 ? row?.name : row?.taskName}
          </Typography>
        )
      },
      hide: false,
      minWidth: 150,
    },
    {
      id: 'triggerDate',
      label: 'Due Date',
      custom: true,
      align: 'center' as AlignType,
      Element: (row: any): JSX.Element => {
        return (
          <Box sx={{ justifyContent: 'center', alignItems: 'center' }}>
            {row?.name && (
              <Typography sx={{ fontSize: '12px', fontWeight: 'bold' }}>
                {dayFromTimestamp(row?.triggerDate)}
              </Typography>
            )}

            {!row?.name && (
              <Typography sx={{ fontSize: '12px', fontWeight: 'normal' }}>
                {row?.triggerDate
                  ? formatTimestamp(row?.triggerDate, 'MM/DD/YYYY')
                  : ' - '}
              </Typography>
            )}
          </Box>
        )
      },
      minWidth: 80,
      hide: false,
    },
    {
      id: 'startedOn',
      label: 'Open Date',
      align: 'center' as AlignType,
      type: 'date',
      minWidth: 80,
    },
    {
      id: 'completedOn',
      label: 'Closed Date',
      custom: true,
      align: 'center' as AlignType,
      Element: (row: any): JSX.Element => {
        return (
          <Typography sx={{ fontSize: '12px' }}>
            {row?.completedOn
              ? formatTimestamp(row?.completedOn, 'MM/DD/YYYY')
              : ' - '}
          </Typography>
        )
      },
      minWidth: 80,
      hide: false,
    },
    {
      id: 'orderName',
      label: 'WO Name',
      custom: true,
      align: 'center' as AlignType,
      Element: (row: any): JSX.Element => {
        return (
          <Typography sx={{ fontSize: '12px' }}>
            {row?.orderName && row?.orderId && row?.orderName.length > 0
              ? row?.orderName
              : ' - '}
          </Typography>
        )
      },
      maxWidth: 90,
      hide: false,
    },
    {
      id: 'status',
      label: 'Job Status',
      custom: true,
      Element: (row: any): JSX.Element => {
        const render = () => {
          const status = row?.status ?? 'COMPLETE'
          switch (status) {
            case 'NEEDS_ATTENTION': {
              return (
                <Typography sx={{ fontSize: '12px' }} color="var(--red600)">
                  NEEDS ATTENTION
                </Typography>
              )
            }
            case 'PENDING': {
              return (
                <Typography sx={{ fontSize: '12px' }} color="var(--blue700)">
                  PENDING
                </Typography>
              )
            }
            case 'AWAITING': {
              return (
                <Typography sx={{ fontSize: '12px' }} color="var(--orange700)">
                  AWAITING
                </Typography>
              )
            }
            case 'COMPLETE': {
              return (
                <Typography sx={{ fontSize: '12px' }} color="var(--green900)">
                  COMPLETE
                </Typography>
              )
            }
            default: {
              const text = status.replace('_', ' ')
              return (
                <Typography sx={{ fontSize: '12px' }}>
                  {' '}
                  {text?.length > 0 && `${text.toUpperCase()}`}{' '}
                </Typography>
              )
            }
          }
        }
        return render()
      },
      hide: false,
      maxWidth: 120,
    },
    {
      id: 'view',
      label: 'View',
      custom: true,
      align: 'center' as AlignType,
      Element: (row: any, index: number, isZero: boolean = false): JSX.Element => {
        const ENABLED_WORKFLOWS_MODAL = [
          WORKFLOW_TYPES.SETUP_JOB,
          WORKFLOW_TYPES.SCHEDULE_WORK_WITH_CLIENT,
          WORKFLOW_TYPES.REVIEW_ESTIMATED_COMPLETION_DATE,
          WORKFLOW_TYPES.CLIENT_FINAL_REVIEW,
          WORKFLOW_TYPES.SCHEDULE_PRECON_INSPECTION,
          WORKFLOW_TYPES.REVIEW_PRECON_INSPECTION,
          WORKFLOW_TYPES.SUBMIT_CHANGE_ORDER,
          WORKFLOW_TYPES.SCHEDULE_FINAL_WALKTHROUGH,
          WORKFLOW_TYPES.REVIEW_FINAL_WALKTHROUGH,
          WORKFLOW_TYPES.REVIEW_DISPATCH_STATUS,
          WORKFLOW_TYPES.REQUEST_CORRECTIONS,
          WORKFLOW_TYPES.RECEIVE_CHANGE_ORDER_APPROVAL,
          WORKFLOW_TYPES.MARK_JOB_COMPLETE,
          WORKFLOW_TYPES.SKIP,
        ]
        const isAdmin = useSelector(employeeIsAdmin)
        return (
          <>
            {row?.name && row.type === WORKFLOW_TYPES.SEND_FINAL_INVOICE && isZero === true && (
              <ModalActions item={row} isZero={isZero} />
            )}

            {row?.name && isAdmin && (
              <ModalActions item={row} isZero={isZero} isSkip={true} />
            )}

            {row?.name && ENABLED_WORKFLOWS_MODAL.includes(row.type) && (
              <ModalActions item={row} isZero={isZero} />
            )}

            {row?.type === WORKFLOW_TYPES.SEND_FINAL_INVOICE &&

              <Button onClick={() => {
                navigate(`/invoice/jobId/${job?.id}/preview`)
              }}

                variant='contained'
                color={'warning'}
                sx={{ marginRight: '3px' }}
              >
                View</Button>}
          </>
        )
      },
      hide: false,
    },
  ])

  return {
    headers
  }

}





export default useHeaders
