import { Avatar, Box, Button, Typography } from "@mui/material";
import { icons } from "src/assets";
import { HeaderType } from "src/components/UI/CustomUI/organisms/Table/types";
import { ParseFilesUrl } from "src/helpers";
import env from "@beam-australia/react-env"

const FILES_URL = env('FILES_URL') ?? ''
const headers: HeaderType[] = [
    {
        id: 'name',
        label: 'Name',
        custom: true,
        Element: (row: any, dispatch): JSX.Element => {
            return (

                <Typography variant='h6'> {row?.title} </Typography>

            )
        }
    },
    {
        id: 'manager',
        label: 'Manager',
        custom: true,
        Element: (row: any, dispatch): JSX.Element => {
            return (
                <>
                    {row?.employeeManager?.firstName && row?.employeeManager?.lastName && (
                        <Box display='flex' justifyItems='center' justifyContent='flex-start' alignItems='center'>
                            <Avatar src={ParseFilesUrl({ baseUrl: FILES_URL, avatar: row?.employeeManager?.picture })} sx={{ mr: 1 }} variant="rounded" />
                            <Typography variant='h6'> {`${row?.employeeManager?.firstName} ${row?.employeeManager?.lastName} `}</Typography>
                        </Box>
                    )}
                    {!row?.employeeManager?.firstName && !row?.employeeManager?.lastName && (
                        <Typography variant='h6'>No Manager Assigned</Typography>
                    )}
                </>
            )
        }
    },
    {
        id: 'phone',
        label: 'Phone',
        custom: true,
        Element: (row: any, dispatch): JSX.Element => {
            return (
                <Typography variant='h6'> {row?.phone} </Typography>

            )
        }
    },
    {
        id: 'email',
        label: 'Email',
        custom: true,
        Element: (row: any, dispatch): JSX.Element => {
            return (

                <Typography variant='h6'> {`${row.emailName} <${row?.email}>`} </Typography>

            )
        }
    },
    {
        id: 'action',
        label: 'Action',
        custom: true,
        Element: (row: any, dispatch): JSX.Element => {
            return (
                <Box justifyContent='center'>
                    <Button 
                    disabled={row?.disabled}
                    onClick={()=>row?.action(row)}
                    variant="contained" 
                    endIcon={<icons.NorthEast />}>
                        View
                    </Button>
                </Box>

                // <a href={`territories/${row.id}`} target='_blank' rel='noreferrer'>
                //     <Typography variant='h6'> {row?.action} </Typography>
                // </a>
            )
        }
    }
]
export default headers;