import { InspectorCompany } from 'src/ducks/types'
import axios from '../axios'
import { ApiController } from './types'
import { UpdateRequest } from '~api/types'

const base = '/inspectors'

const apiController: ApiController = {
  getInspectors: async () =>
    await axios.get(`${base}`).then((response) => response.data),

  createInspector: async (request: Partial<InspectorCompany>) =>
    await axios.post(`${base}`, request).then((response) => response.data),
  updateInspector: async (id: string, request: UpdateRequest) =>
    await axios
      .patch(`${base}/${id}`, request)
      .then((response) => response.data),
}

export default apiController
