import { Theme } from "@mui/material";

export const listItemButton = {
  MuiListItemButton: {
    styleOverrides: {
      root: ({ theme }: { theme: Theme }) => ({
        padding: '1.2rem'
       /*  border: `2px solid ${theme.palette.borderColor.main}`,
        borderRadius: '8px',
        boxShadow: '0px 1px 1px rgba(26, 42, 85, 0.24), 0px 0px 1px rgba(26, 42, 85, 0.31)',
        '&:hover': {
          borderColor: 'var(--border-hover)'
        } */
      }),
     
    },
  },
  
};
