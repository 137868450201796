import storeInfo from "../store";
import { LicenseType } from "../types";

export const getLicenses = () => (): LicenseType[] => {
    const { licenses: { items } } = storeInfo.store.getState()

    if (!items) return []

    return items
}

export const getLicenseTotalCount = () => (): number => {
    const { licenses: { total } } = storeInfo.store.getState()

    return total || 0
}

export const getCsvLicenses = () => (): LicenseType[] => {
    const { licenses: { csvItems } } = storeInfo.store.getState()

    if (!csvItems) return []

    return csvItems
}