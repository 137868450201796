import { useDispatch, useSelector } from 'react-redux'
import { getEstimate } from 'src/ducks/estimate/selectors'
import Slider from 'src/components/UI/CustomUI/molecules/Slider'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import useStyles from './styles'
import { Box, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { ScrollWrapper } from 'src/components/templates'
import { useState } from 'react'
import { estimateActions, jobActions } from 'src/ducks/actions'
import { getJob } from 'src/ducks/selectors'

const HouseImages = () => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const isEstimate = window.location.href.includes('estimates')
  const estimate = useSelector(getEstimate())
  const job = useSelector(getJob())

  const data = isEstimate ? estimate : job

  const [selectedImage, setSelectedImage] = useState<string | null>(data?.properties?.propertyImageUrl || null)

  const googleImages = data?.fileDetails?.filter((file) => file.fileType?.includes('GOOGLE_SEARCH_IMAGE'))

  const changeImage = (e: any) => {
    setSelectedImage(e.target.value || null)
    if (isEstimate) {
      dispatch(estimateActions.updateEstimateProperty({ propertyImageUrl: e.target.value || null }, (succ) => { }))
    } else {
      dispatch(jobActions.updateJobProperty({ propertyImageUrl: e.target.value || null }, (succ) => { }))
    }
  }
  if (!googleImages?.length) return null

  return (
    <ScrollWrapper relativeStyle={{ height: '175px' }}>
      <Box gap='6px'>
        <FormControl className={classes.formContainer}>
          <RadioGroup
            onChange={changeImage}
            value={selectedImage}
          >
            <FormControlLabel
              value={null}
              control={<Radio />}
              label="Select none"
              className={classes.selectNone}
            />
            <div className="swiper-button-prev-unique slider-arrow" ><Icon className={classes.arrow} color="white" name="ArrowBackIos" /></div>
            <Slider
              slidesPerView={1.5}
              spaceBetween={20}
              slideToClickedSlide
              centeredSlides
              className={classes.slider}
              navigation={{
                nextEl: '.swiper-button-next-unique',
                prevEl: '.swiper-button-prev-unique'
              }}
            >
              {googleImages?.map((image: any, index) => {
                return (
                  <Box className={classes.sliderSelector}>
                    <img key={index} src={image.fileUrl} alt="selector" style={{ borderRadius: 8, width: '100%'/* , maxWidth: "240px" */ }} />
                    <FormControlLabel
                      value={image?.fileUrl}
                      control={<Radio />}
                      label="Select"
                      key={image?.fileUrl}
                      className={classes.radioButton}
                    />
                  </Box>
                )
              })}
            </Slider>
            <div className="swiper-button-next-unique slider-arrow"><Icon className={classes.arrow} color="white" name="ArrowForwardIos" /></div>
          </RadioGroup>
        </FormControl>
      </Box>
    </ScrollWrapper>
  )
}

export default HouseImages
