import axios from '../axios'
import { ApiController } from './types'

const base = '/trades'

const apiController: ApiController = {
  getTradeTypes: async ({ start, limit }) => {
    return await axios
      .get(`${base}`, { params: { start, limit } })
      .then(response => response.data)
  },
}

export default apiController
