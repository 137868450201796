/* eslint-disable generator-star-spacing */
import type { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo } from '../../helpers'
import { Punchlist } from '../../api'
import {
  commentsTypes,
  CreateCommentAction,
  FetchCommentsAction,
} from './types'
import { commentsActions } from '../actions'
import { isEmpty } from 'lodash'

export function* fetchComments({
  payload,
  callback,
}: FetchCommentsAction): SagaIterator {
  let success = false

  try {
    const page = payload.page ?? 0
    const size = payload.size ?? 25
    const params = payload.params ?? ''

    const start = page * size
    const limit = size

    const comments = yield call(Punchlist.comments.getComments, {
      start,
      limit,
      params,
    })
    if (comments) {
      yield put(commentsActions.setComments(comments))
      success = true
    }
    if (callback) yield call(callback, success, comments)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the comments'))
    if (callback) yield call(callback, false)
  }
}

export function* createComment({
  payload,
  callback,
}: CreateCommentAction): SagaIterator {
  try {
    const comment = yield call(Punchlist.comments.createComment, payload)
    if (!isEmpty(comment)) {
      if (callback) yield call(callback, true, comment)
      yield put(commentsActions.setComment(comment))
    }
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('send the comment'))
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(commentsTypes.FETCH_COMMENTS, fetchComments)
  yield takeLatest(commentsTypes.CREATE_COMMENT, createComment)
}
