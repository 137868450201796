import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Mousewheel } from 'swiper';
import "swiper/css";
import "swiper/css/pagination";
import { FC } from "react";
import { HourPickerProps } from "./types";

SwiperCore.use([Mousewheel]);

const HourPicker: FC<HourPickerProps> = ({ loop, onSlideChange, direction = "horizontal", slidesPerView = 3, initialSlide, className, children }) => {
    return (
        <Swiper
            loop={loop}
            direction={direction}
            slidesPerView={slidesPerView}
            className={`${className}`}
            onSlideChange={onSlideChange}
            slideToClickedSlide
            grabCursor
            freeMode
            mousewheel={{ forceToAxis: true, sensitivity: 1 }}
            initialSlide={initialSlide}
        >
            {children && children.map((slide: any, index) => <SwiperSlide key={`${index}`}>{slide}</SwiperSlide>)}
        </Swiper>
    )
}

export default HourPicker;