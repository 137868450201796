/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid } from '@mui/material'
import { FC, useContext, useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { SelectInput, ActionsButton } from 'src/components/UI'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import BatchActions from '../BatchActions'
import { WorkOrdersContext } from '../../context'
import { GroupedItem, Item } from 'src/ducks/types'
import { useDispatch, useSelector } from 'react-redux'
import { getTerritoryRepairItemsForSelect, getOrderById, getItemsSortedByCategory, getOrders, getJob, getEmployee, getItems, getItemsByCategory } from 'src/ducks/selectors'
import { Action } from 'src/components/UI/CustomUI/molecules/ActionsButton/types'
import { historyActions, itemsActions, jobActions, orderActions, ordersActions } from 'src/ducks/actions'
import { STATUS, ORDER_STATUS, ORDER_TYPE, EMPLOYEE_ROLE_TYPE } from 'src/helpers'
import ShowNotes from '../ShowNotes'
import WorkOrderAction from '../WorkOrderAction'
import { MODAL_TYPE } from '../../context/types'
import WarrantyBox from 'src/components/UI/CustomUI/molecules/WarrantyBox'
import RecallBox from 'src/components/UI/CustomUI/molecules/RecallBox'
import { getRemovedItemsHistory } from 'src/ducks/history/selectors'
import _, { isEmpty } from 'lodash'
import { generatePdf } from '../WorkOrderPdf'

const Header: FC<{ hasItems?: boolean, fullHeader?: boolean, tabName?: string }> = ({ hasItems = false, fullHeader = false, tabName }) => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const currentUser = useSelector(getEmployee)
  const showDisableButton = currentUser?.roles?.some((r) => [EMPLOYEE_ROLE_TYPE.TERRITORY_CUSTOMER_CARE, EMPLOYEE_ROLE_TYPE.SUCCESS_MANAGER, EMPLOYEE_ROLE_TYPE.TERRITORY_SUCCESS_MANAGER, EMPLOYEE_ROLE_TYPE.ADMIN, EMPLOYEE_ROLE_TYPE.SUPER_ADMIN].includes(r.roleType))

  const {
    dispatch: dispatchContext,
    state: { checkAll, expandAll, item, selectedTab, selectedOrderId }
  } = useContext(WorkOrdersContext)

  const territoryRepairItems = useSelector(getTerritoryRepairItemsForSelect)
  const order = useSelector(getOrderById(selectedOrderId as string))
  const orders = useSelector(getOrders())
  const job = useSelector(getJob())
  const itemsByCategory = useSelector(getItemsSortedByCategory())
  const isAllOrdersTab = !order.orderId
  const removedItems = useSelector(getRemovedItemsHistory)

  const { dispatchedTimestamp, checkedInTimestamp, statusType } = order

  const itemsNotCompleted = itemsByCategory
    .reduce((acc: any, curr: any) => {
      return [...acc, ...curr.items];
    }, [])
    .filter((item) => {
      return (
        orders.find((order) => order.orderId === item.orderId)
          ?.vendorPaymentTimestamp === null
      );
    });

  const handleDownloadWorkOrderPdf = async () => {
    await generatePdf({
      workOrderInfo: {
        total: job?.totalCost
      },
      groupedItems: itemsByCategory
    })
  }

  const [actionsLoading, setActionsLoading] = useState(false)
  const [selectedItems, setSelectedItems] = useState<Item[]>([])

  useEffect(() => {
    setSelectedItems(itemsByCategory.reduce((acc: any, curr: any) => {
      return (acc = [
        ...acc,
        ...curr.items.filter((item: { checked: any }) => item.checked)
      ])
    }, []))
  }, [itemsByCategory])

  useEffect(() => {
    if (job?.id)
      dispatch(historyActions.fetchHistoryRemovedItems(job.id, (_succ: boolean) => {
      }))
  }, [job?.id])

  const handleSelectAll = () => {
    dispatchContext({ type: 'TOGGLE_CHECK_ALL', payload: !checkAll })
    const copyWorkItems: GroupedItem[] = [...itemsByCategory]
    copyWorkItems.map((item: any) => {
      item.items.forEach((subitem: any) => {
        subitem.checked = !checkAll
      })
      return item
    })
    dispatch(itemsActions.setItemsByCategory(copyWorkItems))
  }

  const handleSelectNewItem = (newSelected: any) => {
    const { key: newItem } = newSelected

    dispatchContext({ type: 'RESET_ITEM_VALUE', payload: true })
    dispatchContext({ type: 'SET_ITEM', payload: newItem })

    dispatchContext({ type: 'SET_ITEM_VALUE', payload: { attr: 'category', value: newItem.category } })
    dispatchContext({ type: 'SET_ITEM_VALUE', payload: { attr: 'totalPrice', value: newItem.unitPrice } })
    dispatchContext({ type: 'SET_ITEM_VALUE', payload: { attr: 'totalLaborCost', value: newItem.unitLaborHours * newItem.laborRate } })
    dispatchContext({ type: 'SET_ITEM_VALUE', payload: { attr: 'totalMaterialCost', value: newItem.unitMaterialCost } })
    dispatchContext({ type: 'SET_ITEM_VALUE', payload: { attr: 'totalLaborHours', value: newItem.unitLaborHours } })
    dispatchContext({ type: 'SET_ITEM_VALUE', payload: { attr: 'laborRate', value: newItem.laborRate } })
    dispatchContext({ type: 'SET_ITEM_VALUE', payload: { attr: 'severity', value: newItem.severity } })
    dispatchContext({ type: 'SET_ITEM_VALUE', payload: { attr: 'quantity', value: 1 } })
    dispatchContext({ type: 'SET_ITEM_VALUE', payload: { attr: 'title', value: newItem.title } })
    dispatchContext({ type: 'SET_ITEM_VALUE', payload: { attr: 'displayTitle', value: newSelected.label } })
    dispatchContext({ type: 'SET_ITEM_VALUE', payload: { attr: 'id', value: newItem.id } })
    dispatchContext({ type: 'SET_ITEM_VALUE', payload: { attr: 'hideFromClient', value: newItem?.hideFromClient } })
    dispatchContext({ type: 'SET_ITEM_VALUE', payload: { attr: 'disclaimer', value: newItem?.disclaimer } })
    dispatchContext({ type: 'SET_ITEM_VALUE', payload: { attr: 'unitLaborHours', value: newItem.unitLaborHours } })
    dispatchContext({ type: 'SET_ITEM_VALUE', payload: { attr: 'unitMaterialCost', value: newItem.unitMaterialCost } })
    dispatchContext({ type: 'SET_ITEM_VALUE', payload: { attr: 'margin', value: newItem.margin } })
    dispatchContext({ type: 'SET_MODAL_OPEN', payload: true })
    dispatchContext({ type: 'SET_MODAL_TYPE', payload: 'EDIT_ITEM' })
  }

  const actions: Action[] = []

  const showPaid = statusType && ORDER_STATUS.PAID === statusType
  const showAccepted = statusType && (ORDER_STATUS.COMPLETE === statusType || showPaid)
  const showComplete = statusType && (ORDER_STATUS.REVIEW === statusType || showAccepted)
  const showCheckedin = statusType && ([ORDER_STATUS.DISPATCHED, ORDER_STATUS.IN_PROGRESS].includes(statusType) || showComplete)

  const showResendAndRevoke = !showPaid
  const showUnacceptButton = showAccepted
  const showAcceptButton = showComplete && !showAccepted
  const showMarkCompleteButton = showCheckedin && checkedInTimestamp !== null && !showComplete
  const showCheckedinButton = showCheckedin && !checkedInTimestamp && !showComplete

  if (showCheckedinButton) {
    actions.push({
      label: 'Checkin Vendor',
      onClick: () => {
        setActionsLoading(true)
        dispatch(ordersActions.updateOrderState(
          { orderId: selectedOrderId ?? '', orderState: 'checkin' },
          (success) => {
            if (success) {
              dispatch(ordersActions.fetchOrders(
                { params: { jobId: id, page: 0, size: 9999 } },
                (_suc) => { setActionsLoading(false) }
              ))
            } else setActionsLoading(false)
          }
        ))
      }
    })
  }

  if (showMarkCompleteButton) {
    actions.push({
      label: 'Mark Complete',
      onClick: () => {
        setActionsLoading(true)
        dispatch(ordersActions.updateOrderState(
          { orderId: selectedOrderId ?? '', orderState: 'complete' },
          (success) => {
            if (success) {
              dispatch(ordersActions.fetchOrders(
                { params: { jobId: id, page: 0, size: 9999 } },
                (_suc) => {
                  dispatch(itemsActions.fetchItemsByCategory({
                    params: { jobId: id, orderId: selectedOrderId || '' }
                  }, (_succ) => {
                    setActionsLoading(false)
                  }))
                }
              ))
            } else setActionsLoading(false)
          }
        ))
      }
    })
  }

  if (showAcceptButton) {
    actions.push({
      label: 'Accept Work',
      onClick: () => {
        setActionsLoading(true)
        dispatch(ordersActions.updateOrderState(
          { orderId: selectedOrderId ?? '', orderState: 'accepted' },
          (success) => {
            if (success) {
              dispatch(ordersActions.fetchOrders(
                { params: { jobId: id, page: 0, size: 9999 } },
                (_suc) => { setActionsLoading(false) }
              ))
            } else setActionsLoading(false)
          }
        ))
      }
    })
  }

  if (showUnacceptButton) {
    actions.push({
      label: 'Unaccept Work',
      onClick: () => {
        setActionsLoading(true)
        dispatch(ordersActions.updateOrderState(
          { orderId: selectedOrderId ?? '', orderState: 'unaccepted' },
          (success) => {
            if (success) {
              dispatch(ordersActions.fetchOrders(
                { params: { jobId: id, page: 0, size: 9999 } },
                (_suc) => { setActionsLoading(false) }
              ))
            } else setActionsLoading(false)
          }
        ))
      }
    })
  }

  if (showResendAndRevoke) {
    actions.push({
      label: 'Revoke Dispatch',
      onClick: () => {
        setActionsLoading(true)
        dispatch(orderActions.revokeDispatchOrder(
          { orderId: selectedOrderId ?? '', sendNotification: true },
          (success) => {
            if (success) {
              dispatch(ordersActions.fetchOrders(
                { params: { jobId: id, page: 0, size: 9999 } },
                (_suc) => { setActionsLoading(false) }
              ))
            } else setActionsLoading(false)
          }
        ))
      }
    })

    actions.push({
      label: 'Resend Dispatch Notification',
      onClick: () => {
        setActionsLoading(true)
        dispatch(orderActions.dispatchOrder(
          {
            orderId: selectedOrderId ?? '',
            order: order
          },
          (success) => {
            if (success) {
              dispatch(ordersActions.fetchOrders(
                { params: { jobId: id, page: 0, size: 9999 } },
                (_suc) => { setActionsLoading(false) }
              ))
            } else setActionsLoading(false)
          }
        ))
      }
    })
  }

  const copyItems = selectedItems
  const copyItemsCount = copyItems.length
  const copyItemsText = `${copyItemsCount} item${copyItemsCount === 1 ? '' : 's'}`

  const recallItems = (orderId: string | null, jobId?: string | null) => {
    dispatchContext({ type: 'SET_PAGE_LOADING', payload: true })
    const request = selectedItems.map(item => {
      const { orderId: originalOrderId, checked, ...rest } = item
      const itemToPush = {
        orderId,
        ...rest
      }
      if (jobId) {
        itemToPush.estimateId = jobId
      }
      return itemToPush
    })
    dispatch(itemsActions.recallItems({ items: request, id: job?.id || '' }, (success, item) => {
      dispatchContext({ type: 'SET_PAGE_LOADING', payload: false })
      if (success) {
        if (jobId) {
          navigate(`/jobs/${jobId}`)
          searchParams.delete('order')
        }
      }
    }))
  }

  const copyItemsToClonedJob = () => {
    dispatchContext({ type: 'SET_PAGE_LOADING', payload: true })
    job?.id && dispatch(jobActions.cloneJob(job?.id, (success, jobId) => {
      recallItems(null, jobId)
    }))
  }

  const copyItemsToOrderAction: Action[] = orders.map(order => {
    return {
      label: order.orderName,
      icon: <Icon color='lightGray' name='ArrowForward' />,
      onClick: () => {
        recallItems(order.orderId as string)
      },
      disabled: order.vendorPaymentTimestamp !== null
    }
  })

  copyItemsToOrderAction.push({
    label: "New Work Order",
    icon: <Icon color='lightGray' name='Add' />,
    onClick: () => {
      dispatchContext({ type: 'SET_MODAL_TYPE', payload: MODAL_TYPE.ADD })
      dispatchContext({ type: 'SET_MODAL_OPEN', payload: true })
      dispatchContext({ type: 'SET_MODAL_ACTION_AFTER_CREATE', payload: true })
      dispatchContext({
        type: 'SET_MODAL_AFTER_CREATE',
        payload: (orderId: string) => {
          recallItems(orderId)
          dispatchContext({ type: 'SET_MODAL_ACTION_AFTER_CREATE', payload: false })
        }
      })
    }
  })

  const isJobClosedOrComplete = job?.status === STATUS.COMPLETE || job?.status === STATUS.CLOSED

  const vendorIsPaid = (order.vendorPaymentTimestamp !== null || (showAccepted && showComplete))
    && selectedTab !== 0
  return (
    <Grid container spacing={1} paddingX={1} paddingBottom='12px' justifyContent="space-between">
      <Grid item container spacing={1} lg={9}>
        {hasItems &&
          <>
            <Grid item>
              <Button
                onClick={() => dispatchContext({ type: 'TOGGLE_EXPAND_ALL', payload: !expandAll })}
                variant='outlined'
                sx={{ padding: 0, width: 41, height: 41 }}
              >
                <Icon name={expandAll ? 'Remove' : 'Add'} width='1rem' height='1rem' />
              </Button>
            </Grid>
            {!vendorIsPaid && itemsNotCompleted.length > 0 &&
              <Grid item>
                <Button
                  onClick={handleSelectAll}
                  variant='outlined'
                  sx={{ padding: 0, width: 41, height: 41 }}
                >
                  <Icon name={checkAll ? 'CheckBox' : 'CheckBoxOutlined'} />
                </Button>
              </Grid>
            }
          </>}
        <Grid item>
          {!vendorIsPaid && selectedItems && selectedItems.length > 0 &&
            <BatchActions items={selectedItems} setLoading={setActionsLoading} tabName={tabName} />
          }
        </Grid>
        {!vendorIsPaid && <Grid item sm={4.5} lg={6}>
          <SelectInput
            value={item.displayTitle || ''}
            onChange={handleSelectNewItem}
            placeholder='Search items to add them to this Order...'
            options={territoryRepairItems.map((option) => option)}
            renderOption={(props: any, item: any) => { return (<li {...props} key={item.id}>{`${item.key.publicTitle} - ${item.key.category} - ${item.key.unitLaborHours} hours`}</li>) }}
            size='small'
            disabled={!order.orderId}
          />
        </Grid>}
        {
          hasItems &&
          <Grid item>
            <Button
              variant='outlined'
              startIcon={<Icon name='Image' />}
              onClick={() => {
                dispatchContext({ type: 'SET_MODAL_OPEN', payload: true })
                dispatchContext({ type: 'SET_MODAL_TYPE', payload: 'ALL_IMAGES' })
              }}
            >

              All images
            </Button>
          </Grid>
        }
        {vendorIsPaid && selectedItems && selectedItems.length > 0 &&
          <Grid item>
            {job?.status === STATUS.COMPLETE || job?.status === STATUS.CLOSED ?
              <Button
                variant="outlined"
                startIcon={<Icon name="ArrowForward" width=".8rem" />}
                onClick={copyItemsToClonedJob}
              >
                Copy {copyItemsText} to a Cloned Job
              </Button>
              :
              <BatchActions
                items={selectedItems}
                defaultTitle={`Copy ${copyItemsText} to`}
                defaultIcon={<Icon name="ArrowForward" width=".8rem" />}
                defaultActions={copyItemsToOrderAction}
                setLoading={setActionsLoading}
              />
            }
          </Grid>

        }

        {!vendorIsPaid &&
          <Grid item>
            <Button
              variant='outlined'
              startIcon={<Icon name='Add' />}
              onClick={() => {
                dispatchContext({ type: 'SET_MODAL_OPEN', payload: true })
                dispatchContext({ type: 'SET_MODAL_TYPE', payload: 'EDIT_ITEM' })
              }}
              disabled={selectedTab === 0 || isJobClosedOrComplete}
            >
              Add Item
            </Button>
          </Grid>
        }
        {selectedItems && selectedItems.length === 0 && fullHeader && !actionsLoading &&
          <Grid item>
            <WorkOrderAction downloadPdf={handleDownloadWorkOrderPdf} />
          </Grid>}
        {selectedItems && selectedItems.length === 0 && fullHeader && order.type === ORDER_TYPE.WARRANTY &&
          <Grid item>
            <WarrantyBox />
          </Grid>}
        {selectedItems && selectedItems.length === 0 && fullHeader && order.type === ORDER_TYPE.RECALL &&
          <Grid item>
            <RecallBox />
          </Grid>}
        {selectedItems && selectedItems.length === 0 && fullHeader && order.holdNotes &&
          <Grid item>
            <ShowNotes notes={order.holdNotes} />
          </Grid>}
      </Grid>
      {fullHeader && !vendorIsPaid &&
        <Grid item container spacing={2} lg={3} justifyContent='flex-end'>
          <Grid item>
            <Button
              variant='outlined' startIcon={<Icon name='Edit' />} onClick={() => {
                dispatchContext({ type: 'SET_MODAL_OPEN', payload: true })
                dispatchContext({ type: 'SET_MODAL_TYPE', payload: 'EDIT' })
              }}
            >
              Rename
            </Button>
          </Grid>
          <Grid item>
            {dispatchedTimestamp && dispatchedTimestamp > 0
              ? (
                actions && actions.length
                  ? (
                    <ActionsButton
                      icon={<Icon name='ArrowDropDown' />}
                      text='Edit Dispatch'
                      onClick={() => navigate(`dispatch/${selectedOrderId}`)}
                      iconPosition='end'
                      loading={actionsLoading}
                      actions={actions}
                      variant='contained'
                      sx={{
                        width: '135px',
                        height: '42px !important',
                        display: 'flex',
                        justifyContent: actionsLoading ? 'center' : 'space-between',
                        backgroundColor: 'var(--blue700)',
                        borderColor: 'var(--blue700)',
                        '&:hover': {
                          backgroundColor: 'var(--blue800)',
                          borderColor: 'var(--blue800)'
                        }
                      }}
                    />
                  )
                  : (
                    <Button variant='contained' onClick={() => navigate(`dispatch/${selectedOrderId}`)}>
                      Edit Dispatch
                    </Button>)
              )
              : (
                showDisableButton && (<Button
                  variant='contained'
                  onClick={() => navigate(`dispatch/${selectedOrderId}`)}
                  startIcon={<Icon name='NearMe' />}
                  sx={{ backgroundColor: 'var(--orange500)', borderColor: 'var(--orange500)' }}
                >
                  Dispatch
                </Button>)
              )}
          </Grid>
        </Grid>}

      {vendorIsPaid &&
        <Grid display="flex" lg={3} justifyContent="flex-end">
          <Button
            variant='contained'
            onClick={() => {
              dispatchContext({ type: 'SET_MODAL_OPEN', payload: true })
              dispatchContext({ type: 'SET_MODAL_TYPE', payload: 'VIEW_DISPATCH' })
            }}
            startIcon={<Icon name='NearMe' />}
          >
            View Dispatch
          </Button>
        </Grid>
      }
      {isAllOrdersTab && !isEmpty(removedItems) &&
        <Grid item>
          <Button
            // endIcon={<Icon name='Add' />}
            onClick={() => {
              dispatchContext({ type: 'SET_MODAL_OPEN', payload: true })
              dispatchContext({ type: 'SET_MODAL_TYPE', payload: 'RECOVER_ITEMS' })
            }}
            // variant="containedLight"
            sx={{

            }}
            color="navbar"
          >
            Recover Deleted Items
          </Button>
        </Grid>
      }
    </Grid>
  )
}

export default Header
