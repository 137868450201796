import { FC, MouseEvent, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Grid,
  Typography,
  FormControlLabel,
  TitleFirst,
  Avatar,
  Checkbox,
  Box,
  Button,
  BottomLink,
  TextFieldLabel,
  PasswordLabel
} from '../../../UI'
import useStyles from './styles'
import { validateEmail, isEmpty } from '../../../../helpers'
import { HashWrapper, PunchlistLoader } from '../../../templates'
import { authActions } from '../../../../ducks/actions'
import { arrow } from '../../../../assets'
import { Link } from 'react-router-dom'
import theme from '../../../UI/MaterialUI/theme'

const Login: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [remember, setRemember] = useState(true)

  const [loading, setLoading] = useState(false)
  const [wrongPassword, setWrongPassword] = useState(false)
  const [loginError, setLoginError] = useState(false)
  const [submit, setSubmit] = useState(false)

  const handleSubmit = (event: MouseEvent): void => {
    event.preventDefault()
    setWrongPassword(false)
    setSubmit(true)
    setLoginError(false)
    if (validateEmail(email) && !isEmpty(password)) {
      setLoading(true)
      dispatch(
        authActions.loginRequest({
          email,
          password,
          remember,
          callback: success => {
            setLoading(false)
            setWrongPassword(!success)
          }
        })
      )
    }
  }

  return (
    <HashWrapper error={loginError} setError={setLoginError}>
      <PunchlistLoader loading={loading}>
        <TitleFirst value='Welcome' black='back!' />
        <Typography
          fontFamily='Nouvelle'
          fontSize='20px'
          fontWeight='normal'
          textAlign='center'
        >
          Let's Sign-in!
        </Typography>
        <Box className={classes.center}>
          <form className={classes.form}>
            <TextFieldLabel
              label='Email:'
              type='email'
              name="login-email"
              // autoComplete='email username'
              value={email}
              error={submit && (isEmpty(email) || !validateEmail(email))}
              helperText={
                submit &&
                (isEmpty(email) || !validateEmail(email)) &&
                'Write your e-mail to sign-in'
              }
              color='inputColors'
              onChange={event => setEmail(event.target.value)}
              placeholder='Enter your email here'
              sx={{
                marginBottom: '2.125rem',
                height: { sm: '54px' }
              }}
              className={classes.email}
            />

            <PasswordLabel
              label='Password:'
              value={password}
              error={submit && (isEmpty(password) || wrongPassword)}
              helperText={
                submit &&
                ((isEmpty(password) && 'Write your password to sign-in') ||
                  (wrongPassword &&
                    'Oops! Wrong email or password. Check it and type it again.'))
              }
              onChange={event => setPassword(event.target.value)}
              placeholder='Enter your password'
              className={classes.pass}
            />

            {loginError && (
              <Typography
                variant='body2'
                color='error'
                style={{ padding: '0 16px' }}
              >
                An error occurred while trying to sign-in, please try again
              </Typography>
            )}

            <Button
              type='submit'
              variant='contained'
              size='large'
              onClick={handleSubmit}
              color='primary'
              fullWidth
              endIcon={
                <Avatar
                  alt=''
                  src={arrow}
                  sx={{ height: '0.75rem', width: '0.75rem' }}
                />
              }
              sx={{
                margin: theme.spacing(4, 0, 2),
                textTransform: 'none',
                borderRadius: 32,
                height: '3rem'
              }}
            >
              Sign in
            </Button>

            <Grid container className={classes.grid}>
              <Grid item xs>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={remember}
                      onChange={() => setRemember(!remember)}
                      name='remember'
                      color='infoText'
                    />
                  }
                  label={<Typography variant='body2'>Remember me</Typography>}
                />
              </Grid>
              <Grid item>
                <Link className={classes.actionLink} to='/reset'>
                  {' '}
                  Forgot password?
                </Link>
              </Grid>
            </Grid>

            <BottomLink
              question='Already have an account?'
              linkText='Sign-up here'
              linkTo='/register'
            />
          </form>
        </Box>
      </PunchlistLoader>
    </HashWrapper>
  )
}

export default Login
