import { useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button } from 'src/components/UI'
import Table from 'src/components/UI/CustomUI/organisms/Table'
import { HeaderType } from 'src/components/UI/CustomUI/organisms/Table/types'
import { notificationsActions } from 'src/ducks/actions'
import { getNotifications } from 'src/ducks/selectors'
import { formatUTCTimestampDate, formatTimestamp, formatTimestampDate, isEmpty, ORDER_STATUS, ROWS_PER_PAGE_EXTENDED, dateToUTC } from 'src/helpers'
import moment from "moment";
//import headers from './headers'

const Internal = () => {

  const [tableLoading, setTableLoading] = useState(false)
  const dispatch = useDispatch()
  const internals = useSelector(getNotifications())

  const fetchServices = useCallback((page?: number, size?: number) => {
    setTableLoading(true)
    dispatch(notificationsActions.fetchNotifications({ page, size, searchParams: { source: 'INTERNAL' } }, (_succ: boolean) => {
      setTableLoading(false)
    }))
  }, [])

  const markAsRead = useCallback((notificationId: number) => {

    var time = dateToUTC(new Date())
    setTableLoading(true)
    dispatch(notificationsActions.updateNotificationAction(notificationId.toString(), {
      readTimestamp: time.getTime() / 1000,
    }, (_succ: boolean) => {
      setTableLoading(false)
      fetchServices()
    }))
  }, [])

  useEffect(() => {
    fetchServices()
  }, [dispatch, fetchServices])



  const headers: HeaderType[] = [

    {
      id: 'title',
      label: 'Title',
      custom: true,
      // width: 270,
      Element: (row: any): JSX.Element => {
        return (
          <a href={`jobs/${row.estimateId}/?tab=0`} target='_blank' rel='noreferrer'>
            {row?.title}
          </a>
        )
      }
    },
    {
      id: 'message',
      label: 'Description'
    },
    {
      id: 'insertedOn',
      label: 'Date',
      type: 'datetime',
      // maxWidth: 200,
    },
    {
      id: 'button',
      label: 'Mark as Read',
      align: 'center',
      width: 110,
      custom: true,
      Element: (row: any): JSX.Element => {
        return (
          <Button
            variant='contained'
            color='primary'
            sx={{
              lineHeight: 1,
              margin: '0.2rem',
              padding: '0.4rem .5rem'
            }}
            onClick={() => { markAsRead(row?.id) }} >
            Mark as Read
          </Button>


        )
      }
    }
  ]

  return (
    <Table
      headers={headers}
      loading={tableLoading}
      callToApi={(start, limit) => { fetchServices(start, limit) }}
    >
      {internals.items ?? []}
    </Table>
  )
}

export default Internal
