import { FC } from 'react'
import { Box, CircularProgress } from 'src/components/UI'
import theme from 'src/components/UI/MaterialUI/theme'
import { BoxContainerProps } from './types'

const BoxContainer: FC<BoxContainerProps> = ({
  borderColor = 'primary',
  hideTitle,
  title,
  children,
  className = "",
  sx,
  loading,
  secondTitle
}) => {
  const getBorderColor = () => {
    switch (borderColor) {
      case 'primary':
        return theme.palette.primary.main
      case 'secondary':
        return theme.palette.secondary.main
      case 'success':
        return theme.palette.success.main
      case 'info':
        return theme.palette.infoText.main
      default:
        return borderColor
    }
  }

  return (
    <Box
      alignItems='center'
      border='1px solid'
      borderRadius='0.75rem'
      color='primary'
      display='flex'
      flexDirection='column'
      justifyContent='center'
      paddingX='0.75rem'
      paddingY='1.125rem'
      position='relative'
      width='fit-content'
      sx={[{
        borderColor: getBorderColor(),
      },
      ...(Array.isArray(sx) ? sx : [sx]),]}
      className={`${className}`}
    >
      {loading ? <Box display="flex" justifyContent="center" alignItems="center" height="100%" width="100%"> <CircularProgress /> </Box> :
        <>
          {!hideTitle && (
            <Box
              left='0.75rem'
              paddingX='0.25rem'
              paddingY='0.125rem'
              position='absolute'
              sx={{
                backgroundColor: theme.palette.neutral.white
              }}
              top='-0.875rem'
            >
              {title}
              {!hideTitle && secondTitle && (
                <Box
                  left='6.5rem'
                  paddingX='0.25rem'
                  paddingY='0.125rem'
                  position='absolute'
                  sx={{
                    backgroundColor: theme.palette.neutral.white
                  }}
                  top='-0.2rem'
                >
                  {secondTitle}
                </Box>
              )}
            </Box>

          )}

          {children}
        </>
      }
    </Box>
  )
}

export default BoxContainer
