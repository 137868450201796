import { HistoryActions, historyTypes, HistoryValue, } from './types'

const actions = {
  fetchHistoryJobTask: (payload: string, callback?: (succ: boolean) => void): HistoryActions => ({
    type: historyTypes.FETCH_HISTORY_JOB_TASKS,
    payload,
    callback
  }),
  fetchHistoryJob: (payload: string, callback?: (succ: boolean) => void): HistoryActions => ({
    type: historyTypes.FETCH_HISTORY_JOB,
    payload,
    callback
  }),
  fetchHistoryStatus: (payload: string, callback?: (succ: boolean) => void): HistoryActions => ({
    type: historyTypes.FETCH_HISTORY_STATUS,
    payload,
    callback
  }),
  fetchOrderHistoryByJob: (payload: string, callback?: (succ: boolean) => void): HistoryActions => ({
    type: historyTypes.FETCH_JOB_ORDERS_HISTORY,
    payload,
    callback
  }),
  fetchHistoryRemovedItems: (payload: string, callback?: (succ: boolean) => void): HistoryActions => ({
    type: historyTypes.FETCH_REMOVED_ITEMS_HISTORY,
    payload,
    callback
  }),
  setHistoryValue: (payload: HistoryValue): HistoryActions => ({
    type: historyTypes.SET_HISTORY_VALUE,
    payload
  }),
  fetchEstimateHistory: (payload: {
    estimateId: string
    itemTitle: string
    event: string
  }, callback?: (succ: boolean) => void): HistoryActions => ({
    type: historyTypes.FETCH_HISTORY_ESTIMATES,
    payload,
    callback
  })
}

export default actions
