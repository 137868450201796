import moment from 'moment'
import { createContext } from 'react'
import { ICalendarContext } from './context'
import { Options } from 'src/components/UI/CustomUI/atoms/FieldsFilter/types'
import { VendorOptionProp } from './types'

const defaultSelected = moment()

export const initialCalendarState: ICalendarContext = {
  ordersEvents: [],
  siteVisitsEvents: [],

  selected: defaultSelected,
  setSelected: (_selected: moment.Moment | null) => { },

  view: 'month',
  setView: (_view: 'day' | 'week' | 'month') => { },

  showDispatch: false,
  setShowDispatch: (_showDispatch: boolean) => { },

  territoryOptions: [],
  setTerritoryOptions: (_territoryOptions: Options[]) => { },

  territoryParam: '',
  setTerritoryParam: (_territoryParam: string) => { },

  affiliatesOptions: [],
  setAffiliatesOptions: (_affiliatesOptions: Options[]) => { },

  affiliateParam: '',
  setAffiliateParam: (_affiliateParams: string) => { },

  vendorOptions: [],
  setVendorOptions: (_vendorOptions: VendorOptionProp[]) => { },

  showOrders: true,
  setShowOrders: (show: boolean) => { },

  showSiteVisits: true,
  setShowSiteVisits: (show: boolean) => { },

  showAssignedToMe: false,
  setShowAssignedToMe: (show: boolean) => { },
}

export const CalendarContext = createContext(initialCalendarState)