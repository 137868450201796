/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  Box, Grid, Typography, Checkbox,
  TextFieldLabel, FormControlLabel, Link
} from 'src/components/UI'
import DatePicker from 'src/components/UI/CustomUI/atoms/DatePicker'
import { ScrollWrapper } from 'src/components/templates'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import { getJob, getOrder } from 'src/ducks/selectors'
import { DispatchContext } from '../../context'
import { dispatchTypes } from '../../context/types'
import CostsTable from '../CostsTable'
import { formatDateTimestamp, formatTimestampDate } from 'src/helpers'
import { useIsXlScreen } from 'src/hooks'

const Schedule = ({ editable = true }: { editable?: boolean }) => {
  const {
    dispatch: dispatchContext,
    state: {
      jobFiles, showWarnings, dispatchNotes,
      scheduledStartTimestamp, scheduledEndTimestamp
    }
  } = useContext(DispatchContext)

  const job = useSelector(getJob())
  const jobOrder = useSelector(getOrder())
  const [xlScreen] = useIsXlScreen()

  const setValue = (attr: string, value: any) => {
    dispatchContext({
      type: dispatchTypes.SET_VALUE,
      payload: { attr, value }
    })
  }

  useEffect(() => {
    if (job && jobOrder && !jobFiles?.length) {
      const edit = jobOrder !== null && jobOrder.dispatchedTimestamp !== null
      const files = edit ? jobOrder?.files ?? [] : []
      setValue('jobFiles', job?.properties?.files?.map((file: any) => {
        file.checked = !edit ? false : files.some(f => f.fileUrl === file.fileUrl)
        return file
      }))
    }
  }, [jobOrder])

  const toggleFileCheck = (fileId: string) => {
    setValue('jobFiles',
      [...jobFiles.map((file: any) => {
        if (file.id === fileId) file.checked = !file.checked
        return file
      })])
  }

  return (
    <Grid
      container spacing={2}
      display='grid' height='100%'
      gridTemplateRows='min-content min-content min-content auto'
    >
      <Grid item xs={12}>
        <CostsTable />
      </Grid>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={12} lg={6}>
          <Typography variant='h6'> Job Starts: </Typography>
          <DatePicker
            placeholder='mm/dd/yyyy --:--'
            allowTimePicker
            onChange={(dateSelected: Date) => setValue('scheduledStartTimestamp', formatDateTimestamp(dateSelected, job?.properties?.address?.timeZone))}
            value={scheduledStartTimestamp ? formatTimestampDate(scheduledStartTimestamp, job?.properties?.address?.timeZone) : null}
            style={{ height: '38px', padding: '0px', width: '100%' }}
            size="small"
            minDate={new Date()}
            error={showWarnings && !scheduledStartTimestamp}
            align="right"
            disabled={!editable}
          />
          <Box padding='4px 8px 0'>
            <Typography color='var(--gray500)'>
              Time zone: {job?.properties?.address?.timeZone}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Typography variant='h6'> Job Ends: </Typography>
          <DatePicker
            placeholder='mm/dd/yyyy --:--'
            allowTimePicker
            onChange={(dateSelected: Date) => setValue('scheduledEndTimestamp', formatDateTimestamp(dateSelected, job?.properties?.address?.timeZone))}
            value={scheduledEndTimestamp ? formatTimestampDate(scheduledEndTimestamp, job?.properties?.address?.timeZone) : null}
            style={{ height: '38px', padding: '0px', width: '100%' }}
            size="small"
            minDate={new Date()}
            error={showWarnings && !scheduledEndTimestamp}
            styleAlert={{ right: 0 }}
            disabled={!editable}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TextFieldLabel
          label='Notes for Technician:'
          placeholder='Client Approval Notes...'
          value={dispatchNotes ?? ''}
          size="small"
          onChange={event => setValue('dispatchNotes', event.target.value)}
          multiline
          rows={8}
          labelVariant='h6'
          disabled={!editable}
        />
      </Grid>
      <Grid item xs={12} sx={{ paddingBottom: '12px' }}>
        <Typography variant='h6'> Inspection Document & Attachments </Typography>
        {xlScreen && <ScrollWrapper>
          <Grid container >
            {jobFiles?.map((file: any, index) => {
              return (
                <Grid key={index} item xs={4}>
                  <Box sx={{
                    borderRadius: '4px',
                    padding: '0 4px'
                  }}>
                    <FormControlLabel
                      sx={{ padding: '0px' }}
                      control={
                        editable ?
                          <Checkbox
                            color='infoText'
                            onChange={() => { toggleFileCheck(file.id) }} />
                          : <></>
                      }
                      checked={file.checked}
                      label={
                        <Box display='flex' gap={1}>
                          <Icon name='Feed' />
                          <Link
                            href={file.fileUrl}
                            target='_blank'
                            sx={{
                              maxWidth: '100px',
                              overflow: 'hidden !important',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              textDecoration: 'none',
                            }}
                            onClick={(event) => { event.stopPropagation() }}
                          >
                            {file.name}
                          </Link>
                        </Box>
                      }
                    />
                  </Box>
                </Grid>)
            })}
          </Grid>
        </ScrollWrapper>}
        {!xlScreen &&
          <Grid container>
            {jobFiles?.map((file: any, index) => {
              return (
                <Grid key={index} item xs={4}>
                  <Box sx={{
                    borderRadius: '4px',
                    padding: '0 4px'
                  }}>
                    <FormControlLabel
                      sx={{ padding: '0px' }}
                      control={
                        editable ?
                          <Checkbox
                            color='infoText'
                            onChange={() => { toggleFileCheck(file.id) }} />
                          : <></>
                      }
                      checked={file.checked}
                      label={
                        <Box display='flex' gap={1}>
                          <Icon name='Feed' />
                          <Link
                            href={file.fileUrl}
                            target='_blank'
                            sx={{
                              maxWidth: '100px',
                              overflow: 'hidden !important',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              textDecoration: 'none',
                            }}
                            onClick={(event) => { event.stopPropagation() }}
                          >
                            {file.name}
                          </Link>
                        </Box>
                      }
                    />
                  </Box>
                </Grid>)
            })}
          </Grid>}
      </Grid>
    </Grid>
  )
}

export default Schedule
