import { State } from '../reducers'
import storeInfo from '../store'
import { EmployeeRole, EmployeeType } from './types'
import { concatSeparator, EMAIL_TYPE, getNameFromAbb, EMPLOYEE_ROLE_TYPE } from '../../helpers'

export const getEmployee = (state: State): EmployeeType => state.employee

export const getEmployeeId = (): string => {
  const { employee } = storeInfo.store.getState()
  return employee.id
}

export const getEmployeePrimaryEmail = (): string => {
  const { employee } = storeInfo.store.getState()
  const primaryEmail = employee.email.find(e => e.emailType === EMAIL_TYPE.PRIMARY)?.email || ''
  return primaryEmail || 'No email registered'
}

export const getEmployeeFullName = (): string => {
  const { employee } = storeInfo.store.getState()
  const { firstName, lastName } = employee
  const fullName = concatSeparator(firstName, lastName, ' ')
  return fullName || 'No name registered'
}

export const getEmployeePhone = (): string => {
  const { employee: { phone } } = storeInfo.store.getState()
  return phone?.toString() || 'No phone'
}

export const getEmployeeAddress = (): string => {
  const { employee } = storeInfo.store.getState()
  const { city, line_1: street1 } = employee.address
  const address = concatSeparator(city, street1, ', ')
  return address || 'No address'
}

export const getEmployeeFullAddress = (): string => {
  const { employee } = storeInfo.store.getState()
  const { line_1: street1, line_2: street2, city, state, zipCode } = employee.address

  let fullAddress = concatSeparator(street1, street2, ' ')
  fullAddress = concatSeparator(fullAddress, city, ', ')
  fullAddress = concatSeparator(fullAddress, getNameFromAbb(state), ', ')
  fullAddress = concatSeparator(fullAddress, zipCode, ', ')

  return fullAddress || 'No address registered'
}

export const getEmployeeRoles = (): EmployeeRole[] => {
  const { employee } = storeInfo.store.getState()
  const { roles } = employee || []
  return roles
}

export const employeeIsAdmin = (): boolean => {
  const { employee } = storeInfo.store.getState()
  const { roles } = employee || []
  const isAdmin = employee.admin ||
    roles.some(role => role.roleType === EMPLOYEE_ROLE_TYPE.ADMIN) ||
    roles.some(role => role.roleType === EMPLOYEE_ROLE_TYPE.SUPER_ADMIN)
  return isAdmin
}

export const employeeIsCustomerCare = (): boolean => {
  const { employee } = storeInfo.store.getState()
  const { roles } = employee || []
  return roles.some(role => role.roleType === EMPLOYEE_ROLE_TYPE.TERRITORY_CUSTOMER_CARE)
}

export const employeeIsManager = (): boolean => {
  const { employee } = storeInfo.store.getState()
  const { roles } = employee || []
  const isManager = roles?.some(role => role?.roleType === EMPLOYEE_ROLE_TYPE.MANAGER || role?.roleType === EMPLOYEE_ROLE_TYPE.TERRITORY_MANAGER)
  return isManager || false
}

export const employeeIsSuperAdmin = (): boolean => {
  const { employee } = storeInfo.store.getState()
  const { roles } = employee || []
  const isSuperAdmin = roles?.some(role => role?.roleType === EMPLOYEE_ROLE_TYPE.SUPER_ADMIN)
  return isSuperAdmin || false
}