import { Box, Typography } from "@mui/material"
import { ESTIMATE_PUBLIC_STATUS } from "src/helpers/constants"
import { STATUS_MAP } from ".."

interface BadgeProps {
  status: ESTIMATE_PUBLIC_STATUS,
  styles?: any
}

const Badge = ({ status, styles }: BadgeProps) => (
  <Box
    style={{
      backgroundColor: STATUS_MAP[status]?.color,
      borderRadius: '16px',
      position: 'absolute',
      padding: '2px 4px',
      right: 16,
      top: 16,
      zIndex: 1,
      ...styles
    }}
  >
    <Typography
      style={{
        textTransform: 'uppercase',
        color: STATUS_MAP[status].textColor,
        fontFamily: 'Lato',
        fontSize: '12px',
      }}
    >
      {STATUS_MAP[status].text}
    </Typography>
  </Box>
)

export default Badge