import { HeaderType } from 'src/components/UI/CustomUI/organisms/Table/types'

const headers: HeaderType[] = [
    {
        id: 'createdOn',
        label: 'Date',
        type: 'datetime',
    },
    {
        id: 'createdByName',
        label: 'Added by',
    },
    {
        id: 'text',
        label: 'Notes',
    },
]

export default headers
