/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useEffect, useState } from 'react'
import { Box, Typography, Grid, TextFieldLabel } from '../../../../../UI'
import { useSelector } from 'react-redux'
import { getEmployee, getVendor } from 'src/ducks/selectors'
import { VendorContext } from '../../context'
import { EMPLOYEE_ROLE_TYPE } from 'src/helpers'

const availableRoles = [EMPLOYEE_ROLE_TYPE.TERRITORY_VENDOR_MANAGER, EMPLOYEE_ROLE_TYPE.MANAGER, EMPLOYEE_ROLE_TYPE.SUPER_ADMIN]

const Contact: FC = () => {
    const vendor = useSelector(getVendor())
    const { editedVendor, setEditedVendor } = useContext(VendorContext)
    const employee = useSelector(getEmployee)
    // const disabled = !employee.roles.some(role => role.roleType === EMPLOYEE_ROLE_TYPE.TERRITORY_VENDOR_MANAGER || role.roleType === EMPLOYEE_ROLE_TYPE.SUPER_ADMIN)
    const disabled = availableRoles.indexOf(employee.roles[0].roleType) === -1

    const [disabledSuperAdmin] = useState(!employee.roles.some(role => role.roleType === EMPLOYEE_ROLE_TYPE.SUPER_ADMIN))

    useEffect(() => {
        if (vendor?.id !== editedVendor?.id)
            setEditedVendor(vendor)

    }, [])

    return (

        <Box>
            <Typography variant='h4Bold' paddingY={2} display="block">Information</Typography>
            <Grid container spacing={2}>
                <Grid item xs={6} gap={2} display="flex" direction="column" container>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextFieldLabel
                                size='small'
                                value={editedVendor?.firstName}
                                variant='outlined'
                                label="Contact First Name:"
                                labelVariant='h5'
                                disabled={disabled}
                                onChange={(event) => {
                                    setEditedVendor({
                                        ...editedVendor,
                                        firstName: event.target.value
                                    })
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldLabel
                                size='small'
                                value={editedVendor?.lastName}
                                variant='outlined'
                                label="Contact Last Name:"
                                labelVariant='h5'
                                disabled={disabled}
                                onChange={(event) => {
                                    setEditedVendor({
                                        ...editedVendor,
                                        lastName: event.target.value
                                    })
                                }}
                            />
                        </Grid>
                    </Grid>



                    <TextFieldLabel
                        size='small'
                        value={editedVendor?.email && editedVendor?.email?.length > 0 ? editedVendor?.email[0].email : '-'}
                        variant='outlined'
                        label="Email:"
                        labelVariant='h5'
                        disabled={disabledSuperAdmin}
                        textFieldStyle={{ color: '#3A66FF' }}
                        onChange={(event) => {
                            setEditedVendor({
                              ...editedVendor,
                              email: [
                                {
                                  ...(vendor?.email[0] as any),
                                  id: null,
                                  email: event.target.value,
                                },
                              ],
                            })
                          }}
                    />
                </Grid>
                <Grid item xs={6} gap={2} display="flex" direction="column">
                    <TextFieldLabel
                        size='small'
                        value={editedVendor?.companyName}
                        variant='outlined'
                        label="Company Name:"
                        labelVariant='h5'
                        disabled={disabled}
                        onChange={(event) => {
                            setEditedVendor({
                                ...editedVendor,
                                companyName: event.target.value
                            })
                        }}
                    />
                    <TextFieldLabel
                        size='small'
                        value={vendor?.email && vendor?.email?.length > 1 ? vendor?.email[1].email : '-'}
                        variant='outlined'
                        label="CC Emails:"
                        labelVariant='h5'
                        disabled={employee.roles.find(role => role.roleType === EMPLOYEE_ROLE_TYPE.SUPER_ADMIN) === undefined}
                    />
                </Grid>
                <Grid item xs={6} gap={2} display="flex" direction="column">
                    <TextFieldLabel
                        size='small'
                        value={editedVendor?.phone ? editedVendor?.phone?.toString() : ''}
                        variant='outlined'
                        style={{ marginBottom: '8px' }}
                        label="Phone:"
                        labelVariant='h5'
                        disabled={disabled}
                        onChange={(event) => {
                            const num = event.target.value === '' ? undefined : Number(event.target.value)

                            setEditedVendor({
                                ...editedVendor,
                                phone: num
                            })
                        }}
                    />
                </Grid>
            </Grid>

            <Typography variant='h4Bold' paddingY={2} display="block">Address</Typography>

            <Grid container spacing={2}>
                <Grid item xs={6} gap={2} display="flex" direction="column">
                    <TextFieldLabel
                        size='small'
                        value={editedVendor?.address?.line_1}
                        variant='outlined'
                        label="Street Address (line 1):"
                        labelVariant='h5'
                        disabled={disabled}
                        onChange={(event) => {
                            if (editedVendor?.address?.city)
                                setEditedVendor({
                                    ...editedVendor,
                                    address: {
                                        ...editedVendor?.address,
                                        line_1: event.target.value
                                    }
                                })
                        }}
                    />
                    <TextFieldLabel
                        size='small'
                        value={editedVendor?.address?.city}
                        variant='outlined'
                        label="City:"
                        labelVariant='h5'
                        disabled={disabled}
                        onChange={(event) => {
                            if (editedVendor?.address?.city)
                                setEditedVendor({
                                    ...editedVendor,
                                    address: {
                                        ...editedVendor?.address,
                                        city: event.target.value
                                    }
                                })
                        }}
                    />
                </Grid>
                <Grid item xs={6} gap={2} display="flex" direction="column">
                    <TextFieldLabel
                        size='small'
                        value={editedVendor?.address?.line_2}
                        variant='outlined'
                        label="Street Address (line 2):"
                        labelVariant='h5'
                        disabled={disabled}
                        onChange={(event) => {
                            if (editedVendor?.address?.city)
                                setEditedVendor({
                                    ...editedVendor,
                                    address: {
                                        ...editedVendor?.address,
                                        line_2: event.target.value
                                    }
                                })
                        }}
                    />
                    <Grid container item spacing={2}>
                        <Grid item xs={8}>
                            <TextFieldLabel
                                size='small'
                                value={editedVendor?.address?.state}
                                variant='outlined'
                                label="State:"
                                labelVariant='h5'
                                disabled={disabled}
                                onChange={(event) => {
                                    if (editedVendor?.address?.city)
                                        setEditedVendor({
                                            ...editedVendor,
                                            address: {
                                                ...editedVendor?.address,
                                                state: event.target.value
                                            }
                                        })
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextFieldLabel
                                size='small'
                                value={editedVendor?.address?.zipCode}
                                variant='outlined'
                                label="ZIP Code:"
                                labelVariant='h5'
                                disabled={disabled}
                                onChange={(event) => {
                                    if (editedVendor?.address?.city)
                                        setEditedVendor({
                                            ...editedVendor,
                                            address: {
                                                ...editedVendor?.address,
                                                zipCode: event.target.value
                                            }
                                        })
                                }}
                            />
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        </Box >
    )
}

export default Contact
