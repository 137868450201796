import { Button, Grid, Typography } from '@mui/material'
import { isEmpty } from 'lodash'
import { FC, useState } from 'react'
import { PrivateLoader } from 'src/components/templates'
import { TextFieldLabel } from 'src/components/UI'
import Modal from 'src/components/UI/CustomUI/molecules/Modal'
import SelectAddress from 'src/components/UI/CustomUI/organisms/SelectAddress'
import { Address } from 'src/components/UI/CustomUI/organisms/SelectAddress/types'
import styles from './styles.module.scss'
import { useDispatch } from 'react-redux'
import { inspectionCompaniesActions } from 'src/ducks/actions'
import { InspectionCompany } from 'src/ducks/types'

interface CompanyModalProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  companySelected?: InspectionCompany
  fetchCompanies: () => void
  onClose?: () => void
}

export type ModalTypes = 'ADD' | 'EDIT'

const CompanyModal: FC<CompanyModalProps> = ({
  open,
  setOpen,
  companySelected,
  fetchCompanies,
  onClose,
}) => {
  const dispatch = useDispatch()
  const [companyName, setCompanyName] = useState(companySelected?.name)
  const [companyAddress, setCompanyAddress] = useState<Partial<Address>>(
    companySelected ? (companySelected?.address as Address) : {}
  )
  const [showWarnings, setShowWarnings] = useState(false)
  const [loading, setLoading] = useState(false)
  const isValid = !isEmpty(companyName) && !isEmpty(companyAddress?.line_1)

  const handleAddInspection = () => {
    if (isEmpty(companyName) || isEmpty(companyAddress?.line_1)) {
      setShowWarnings(true)
      return
    }
    setShowWarnings(false)
    setLoading(true)
    dispatch(
      inspectionCompaniesActions.createInspectionCompany(
        {
          name: companyName,
          address: companyAddress,
        },
        (succ) => {
          setLoading(false)
          if (succ) {
            //fetch companies
            fetchCompanies()
            setOpen(false)
          }
        }
      )
    )
  }

  const handleEditInspection = () => {
    if (isEmpty(companyName) || isEmpty(companyAddress?.line_1)) {
      setShowWarnings(true)
      return
    }
    setShowWarnings(false)
    setLoading(true)
    dispatch(
      inspectionCompaniesActions.updateInspectionCompany(
        {
          id: companySelected?.id as string,
          request: [
            {
              op: 'replace',
              path: '/name',
              value: companyName,
            },
            {
              op: 'replace',
              path: '/address',
              value: companyAddress,
            },
          ],
        },
        (succ) => {
          setLoading(false)
          if (succ) {
            //fetch companies
            fetchCompanies()
            setOpen(false)
          }
        }
      )
    )
  }

  return (
    <Modal open={open} setOpen={setOpen} onClose={onClose}>
      <PrivateLoader loading={loading}>
        <Grid
          container
          direction="column"
          spacing={2}
          style={{ padding: '.8rem 0' }}
        >
          <Grid item>
            <Typography variant="h5" style={{ fontFamily: 'Nouvelle' }}>
              Inspection Company
            </Typography>
          </Grid>
          <Grid item>
            <TextFieldLabel
              labelVariant="h6"
              label="Name"
              placeholder="Name"
              value={companyName}
              helperText={
                showWarnings && isEmpty(companyName)
                  ? 'Should add Company Name'
                  : ''
              }
              error={showWarnings && isEmpty(companyName)}
              onChange={(event) => setCompanyName(event.target.value)}
            />
          </Grid>
          <Grid item>
            <SelectAddress
              placeholder="Search"
              showWarnings={false}
              savedAddress={companyAddress}
              onChange={(newAddress: Address) => {
                setCompanyAddress(newAddress)
              }}
              showUnit={false}
              showMap={false}
              inputClassName={styles.selectAddress}
              title={<Typography variant="h6">Office Address</Typography>}
            />
          </Grid>
          <Grid item container spacing={1}>
            <Grid item>
              <Button
                style={{
                  color: 'var(--blue700)',
                  height: '100%',
                  fontSize: 'var(--font-S)',
                }}
                onClick={onClose}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item flex={1}>
              <Button
                variant="containedBig"
                fullWidth
                disabled={!isValid}
                onClick={
                  companySelected ? handleEditInspection : handleAddInspection
                }
              >
                {!companySelected
                  ? 'Add Inspection Company'
                  : 'Edit Inspection Company'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </PrivateLoader>
    </Modal>
  )
}

export default CompanyModal
