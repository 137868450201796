import { useSelector } from 'react-redux'

import storeInfo from '../store'
import { JobsType } from './types'
import { JobItem } from '../types'

import { getTerritorySuccessManagers } from 'src/ducks/territory/selectors'
import { getCompanies } from 'src/ducks/companies/selectors'

import { StatusBoxProps } from 'src/components/UI/CustomUI/molecules/StatusBox/types'
import { STATUS } from 'src/helpers/constants'

export const getJobs = () => (): JobsType => {
  const { jobs } = storeInfo.store.getState()
  return jobs
}

export const getJobsForTable = () => (): JobItem[] => {
  const { jobs } = storeInfo.store.getState()
  if (!jobs) return []

  const territorySuccessManagers = useSelector(getTerritorySuccessManagers)
  const affiliatesStages = useSelector(getCompanies)

  return jobs?.items?.map((item: JobItem) => {
    const csm = territorySuccessManagers.find(t => t.id === item.successManagerId)
    const affiliate = item.properties.affiliate
      ? affiliatesStages.find(a => a.affiliateId?.toLowerCase() === item.properties.affiliate?.toLowerCase())
      : null

    return {
      ...item,
      totalValue: item?.properties?.totalValue,
      fullAddress: item?.properties?.fullAddress,
      city: item?.properties?.address?.city,
      territory: item?.properties?.territory?.title,
      affiliate: affiliate ? affiliate?.name : '-',
      affiliateType: item?.properties?.affiliateType,
      territoryManager: item?.properties?.territory?.territoryManager,
      client: item?.properties?.contacts?.length > 0 ? item?.properties?.contacts[0]?.fullName : '',
      publicId: item?.properties?.publicId,
      csm: csm ? csm?.firstName + ' ' + csm?.lastName : ''
    }
  })
}

export const getTotalJobs = () => {
  const { jobs } = storeInfo.store.getState()
  return jobs ? Number(jobs.total) : 0
}

export const getJobsSearch = (jobs: JobItem[], search: string) => {
  if (search === '') return jobs
  return jobs.filter(
    vendor => vendor.status?.toLowerCase().includes(search?.toLowerCase())
  )
}

export const getJobsUser = (jobs: JobItem[], user: string) => {
  if (user === 'All users' || user === '') return jobs
  return jobs.filter(vendor => vendor.csm?.toLowerCase().includes(user?.toLowerCase()))
}

export const getJobsByFilter = (jobs: JobItem[], user: string) => (): JobItem[] => {
  const users = getJobsUser(jobs, user)
  return users
}

export const getJobsStatus = (jobs: JobItem[], status: string) => {
  return jobs.filter(job => job.properties.affiliateType?.toLowerCase().includes(status))
}

export const getJobsCSV = () => storeInfo.store.getState().jobs.jobsCSV

export const getJobsBoxes = () => (): StatusBoxProps[] => {
  const { jobs: { counts } } = storeInfo.store.getState()
  const depositPending = counts?.find((count: { status: string }) => count.status?.includes(STATUS.DEPOSIT_PENDING))
  const recalled = counts?.find((count: { status: string }) => count.status?.includes(STATUS.RECALLED))
  const scheduling = counts?.find((count: { status: string }) => count.status?.includes(STATUS.SCHEDULING))
  const pending = counts?.find((count: { status: string }) => count.status?.includes(STATUS.DISPATCH_PENDING))
  const dispatched = counts?.find((count: { status: string }) => count.status?.includes(STATUS.DISPATCHED))
  const review = counts?.find((count: { status: string }) => count.status?.includes(STATUS.REVIEW))
  const complete = counts?.find((count: { status: string }) => count.status?.includes(STATUS.COMPLETE))
  const closed = counts?.find((count: { status: string }) => count.status?.includes(STATUS.CLOSED))
  const notInvoiced = counts?.find((count: { status: string }) => count.status?.includes(STATUS.NOT_INVOICED))
  const invoicedSent = counts?.find((count: { status: string }) => count.status?.includes(STATUS.INVOICE_SENT))
  const partialPay = counts?.find((count: { status: string }) => count.status?.includes(STATUS.PARTIAL_PAYMENT))
  const collection = counts?.find((count: { status: string }) => count.status?.includes(STATUS.IN_COLLECTION))

  const data: StatusBoxProps[] = [
    {
      statName: 'Active',
      statValue: depositPending && recalled && scheduling && pending && dispatched
        ? depositPending.count + recalled.count + scheduling.count + pending.count + dispatched.count
        : 0,
      borderColor: 'var(--orange700)',
      minWidth: '310px',
      firstColumn: [depositPending ? depositPending.count.toString() : '-', 'Deposit Pending',
      recalled ? recalled.count.toString() : '-', 'Recalled'],
      sndColumn: [dispatched ? dispatched.count.toString() : '-', 'Dispatched',
      scheduling ? scheduling.count.toString() : '-', 'Scheduling'],
      layer: 0,
      thirdColumn: [pending ? pending.count.toString() : '-', 'Dispatch Pending',
        '', '']
    },
    { statName: 'Scheduling', statValue: scheduling ? scheduling.count : 0, borderColor: 'var(--lightBlue700)', layer: 1 },
    { statName: 'Dispatch Pending', statValue: pending ? pending.count : 0, borderColor: 'var(--lightBlue700)', layer: 1 },
    { statName: 'Dispatched', statValue: dispatched ? dispatched.count : 0, borderColor: 'var(--lightBlue700)', layer: 1 },
    { statName: 'Review', statValue: review ? review.count : 0, borderColor: 'info', layer: 1 },
    {
      statName: 'Complete',
      statValue: complete ? complete.count : 0,
      borderColor: 'success',
      minWidth: '310px',
      firstColumn: [notInvoiced ? notInvoiced.count.toString() : '-', 'Not invoiced',
      invoicedSent ? invoicedSent.count.toString() : '-', 'Invoice Sent'],
      sndColumn: [partialPay ? partialPay.count.toString() : '-', 'Partial Payments',
      collection ? collection.count.toString() : '-', 'Collections'],
      layer: 0,
    },
    { statName: 'Closed', statValue: closed ? closed.count : 0, layer: 1 }
  ]
  return data
}

export const getStatistiscs = () => (): any[] => {
  const { jobs: { counts } } = storeInfo.store.getState()
  const scheduling = counts?.find((count: { status: string }) => count.status?.includes(STATUS.SCHEDULING))
  const pending = counts?.find((count: { status: string }) => count.status?.includes(STATUS.DISPATCH_PENDING))
  const dispatched = counts?.find((count: { status: string }) => count.status?.includes(STATUS.DISPATCHED))
  const review = counts?.find((count: { status: string }) => count.status?.includes(STATUS.REVIEW))
  const complete = counts?.find((count: { status: string }) => count.status?.includes(STATUS.COMPLETE))
  const invoicedSent = counts?.find((count: { status: string }) => count.status?.includes(STATUS.INVOICE_SENT))
  const overdue = counts?.find((count: { status: string }) => count.status?.includes(STATUS.OVERDUE))

  const data = [
    { jobs: 'Scheduling', jobNumber: scheduling ? scheduling.count.toString() : '-', date: 'In Review', dateNumber: review ? review.count.toString() : '-' },
    { jobs: 'Pending', jobNumber: pending ? pending.count.toString() : '-', date: 'Needs Invoice', dateNumber: invoicedSent ? invoicedSent.count.toString() : '-' },
    { jobs: 'Dispatched', jobNumber: dispatched ? dispatched.count.toString() : '-', date: 'Overdue', dateNumber: overdue ? overdue.count.toString() : '-' },
    { jobs: 'Completed', jobNumber: complete ? complete.count.toString() : '-' }
  ]

  return data
}
