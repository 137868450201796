import { useState, FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Typography, Button, Box, TitleFirst } from '../../../UI'
import theme from '../../../UI/MaterialUI/theme'
import { authActions, employeeActions } from '../../../../ducks/actions'
import useStyles from './styles'
import { PunchlistLoader } from '../../../templates'
import { icons } from '../../../../assets'
import { getEmployeePrimaryEmail, isApproved } from '../../../../ducks/selectors'

const Unauthorized: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const approved = useSelector(isApproved)

  const email = useSelector(getEmployeePrimaryEmail)
  const [loading, setLoading] = useState(false)

  const handleLogout = () => {
    dispatch(
      authActions.logoutRequest({
        callback: success => {
          if (success) navigate('/login')
        }
      })
    )
  }

  useEffect(() => {
    setLoading(true)
    dispatch(employeeActions.fetchCurrentEmployee((fetchSuccess: any) => {
      setLoading(false)
      if (fetchSuccess && approved) {
        navigate('/home')
      }
    }))
  }, [dispatch, navigate, approved])


  const handleForgotPass = () => {
    navigate('/reset')
  }

  return (
    <PunchlistLoader loading={loading}>
      <TitleFirst value='Welcome' black='to BOSSCAT!' />
      <Box className={classes.center}>
        <form className={classes.form} autoComplete='on'>

          <Box display='flex' sx={{ width: 1 }} className={classes.center}>
            <Typography
              variant='body2'
              color='infoText'
              sx={{ width: 1, textAlign: 'right' }}
              style={{
                backgroundColor: theme.palette.blue[100],
                color: theme.palette.blue[700],
                padding: '0.5rem 0rem 0.5rem 1rem',
                textTransform: 'inherit',

                fontFamily: 'NouvelleNormal',
                fontSize: '18px'
              }}
            >
              {email}
            </Typography>

            <Typography
              variant='body2'
              color='infoText'
              sx={{ width: '280px' }}
              style={{
                backgroundColor: theme.palette.blue[100],
                padding: '0.5rem 1rem 0.5rem 0.5rem',
                textTransform: 'inherit',
                fontFamily: 'NouvelleNormal',
                fontSize: '18px'
              }}
            >
              is Not Authorized.
            </Typography>
          </Box>

          <Typography
            className={classes.center}
            variant='body2'
            color={theme.palette.grey[600]}
            style={{ padding: '0 16px', marginBottom: '32px', marginTop: '8px' }}
          >
            Please contact a system administrator for access
          </Typography>
          <Button
            type='submit'
            variant='containedBig'
            color='primary'
            size='large'
            className={classes.submit}
            onClick={handleLogout}
            sx={{ marginBottom: '16px', height: '56px', textTransform: 'none' }}
            startIcon={<icons.ArrowBack style={{ width: '12px', height: '12px' }} />}
            fullWidth
          >
            Log out
          </Button>

          <Button
            type='submit'
            variant='containedLightBig'
            color='primary'
            size='large'
            className={classes.reset}
            onClick={handleForgotPass}
            sx={{ height: '56px', textTransform: 'none' }}
            fullWidth
          >
            Reset Password
          </Button>

        </form>
      </Box>
    </PunchlistLoader >
  )
}

export default Unauthorized
