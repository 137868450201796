import { useEffect, FC, useState } from 'react'

import { Document, Page, pdfjs } from 'react-pdf'
import { Card, Box, IconButton, Button, CircularProgress, Grid } from '../../..'
import { icons, photoDefault } from '../../../../../assets'
import useStyles from './styles'
import { DocumentPreviewProps } from './types'
import { applicationMimeTypes, videoMimeTypes } from '../../../../../helpers/files'
import Modal from '../Modal'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const DocumentPreview: FC<DocumentPreviewProps> = ({
  file, fileURL, actions, expandable = true, previewActions, dialogActions, previewHeight, imagesSlider, modalTitle
}) => {
  const classes = useStyles()

  const tmppath = file ? URL.createObjectURL(file) : fileURL
  const fileExtension = (tmppath ? tmppath.slice((tmppath.lastIndexOf('.') - 2 >>> 0) + 2) : '').slice(1)

  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [attempts, setAttempts] = useState(5)
  const [error, setError] = useState(false)
  const [errorImg, setErrorImg] = useState('')
  const [filePath, setFilePath] = useState(tmppath)

  useEffect(() => {
    setLoading(false)
  }, [])

  const handleError = (fileUrl: string) => {
    if (attempts > 0) {
      setAttempts(attempts - 1)
      setLoading(true)
      setTimeout(() => {
        setFilePath(tmppath)
        setLoading(false)
      }, 1000)
    } else {
      setError(true)
      setErrorImg(fileUrl)
      setLoading(false)
    }
  }

  const getPreviewContent = (img?: string) => {
    const imageUrl = img || filePath
    if (loading) return <CircularProgress color='primary' size={44} />
    if (error) return <img alt='' src={photoDefault} className={classes.previewDefault} />
    if (videoMimeTypes.findIndex((extension) => extension.includes(fileExtension)) > -1) {
      return (
        <video className={classes.preview} preload='metadata'>
          <source src={tmppath} />
        </video>
      )
    }
    if (applicationMimeTypes.findIndex((extension) => extension.includes(fileExtension)) > -1) {
      return (
        <Document onLoadError={() => alert('Error loading pdf')} file={file}>
          <Page width={140} pageNumber={1} />
        </Document>
      )
    }
    return (<img alt='' src={imageUrl} onError={() => handleError(imageUrl || '')} className={classes.preview} />)
  }

  const getContent = () => {
    if (videoMimeTypes.findIndex((extension) => extension.includes(fileExtension)) > -1) {
      return (
        <video controls className={classes.video}>
          <source src={tmppath} />
        </video>
      )
    }
    if (applicationMimeTypes.findIndex((extension) => extension.includes(fileExtension)) > -1) {
      return (
        <Document onLoadError={() => alert('Error loading pdf')} file={file}>
          <Page width={250} pageNumber={1} />
        </Document>
      )
    }
    return (<img alt='' src={error ? photoDefault : filePath} className={classes.preview} />)
  }

  return (
    <>
      <Modal
        open={open}
        setOpen={() => setOpen(false)}
        className={classes.modal}
        title={modalTitle ?? ''}
      /*   PaperProps={{
          style: {
            width: '100%'
          }
        }} */
      >
        {getContent()}
        {imagesSlider ?
          <Grid container spacing={1} className={classes.slider}>
            <Grid container spacing={1} className={classes.imageSlider}>
              {imagesSlider.map((image, index) => {
                return (
                  <Grid
                    item
                    onClick={() => setFilePath(image.fileUrl)}
                    className={classes.imageSliderItem}
                  >
                    {/* {getPreviewContent(image.fileUrl)} */}
                    <img className={classes.imgPreview} src={image.fileUrl} alt={image.fileUrl} key={index} />
                  </Grid>
                )
              }
              )}
            </Grid>
          </Grid>
          : <Box className={classes.actions}>
            {actions && dialogActions && (
              dialogActions.map((enable, index) => {
                if (enable) {
                  return actions[index]
                }
                return null
              })
            )}
            <Button
              fullWidth
              size='small'
              color='inherit'
              variant='text'
              onClick={() => { setOpen(false) }}
            >
              Close
            </Button>
          </Box>}
      </Modal>
      <Card className={classes.card}>
        {expandable && !loading && (
          <IconButton
            className={classes.full}
            component='span'
            onClick={() => setOpen(true)}
          >
            <icons.Fullscreen />
          </IconButton>)}
        <Box
          onClick={() => { if (expandable && !loading) setOpen(true) }}
          style={{
            height: `${previewHeight ?? ''}`, // `${actions && previewActions ? '100px' : ''}`,
            cursor: expandable && !loading ? 'pointer' : 'default'
          }}
          className={classes.imageBox}
        >
          {getPreviewContent()}
        </Box>
        {actions && previewActions && (
          <Box className={classes.actions}>
            {previewActions.map((enable, index) => {
              if (enable) {
                return actions[index]
              }
              return null
            })}
          </Box>
        )}
      </Card>
    </>
  )
}

export default DocumentPreview
