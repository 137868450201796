import { ChangeOrderRequest, UpdateRequest } from '~api/types'
import { Contact, JobItem, JobProperties } from '../types'
import { JobActions, jobTypes, JobValue } from './types'

const actions = {
  fetchJob: (payload: string, callback?: (succ: boolean) => void): JobActions => ({
    type: jobTypes.FETCH_JOB,
    payload,
    callback
  }),
  createJob: (payload: Partial<JobItem>, callback?: (succ: boolean) => void): JobActions => ({
    type: jobTypes.CREATE_JOB,
    payload,
    callback
  }),
  deleteJob: (payload: string, callback?: (succ: boolean) => void): JobActions => ({
    type: jobTypes.DELETE_JOB,
    payload,
    callback
  }),
  setJob: (payload: JobItem | null): JobActions => ({
    type: jobTypes.SET_JOB,
    payload
  }),
  setJobValue: (payload: JobValue): JobActions => ({
    type: jobTypes.SET_JOB_VALUE,
    payload
  }),
  fetchOrderHistoryByJob: (payload: string, callback?: (succ: boolean) => void): JobActions => ({
    type: jobTypes.FETCH_JOB_ORDERS_HISTORY,
    payload,
    callback
  }),
  updateJob: (
    payload: Partial<JobItem>,
    callback?: (succ: boolean) => void
  ): JobActions => ({
    type: jobTypes.UPDATE_JOB,
    payload,
    callback
  }),
  updateJobByPath: (
    payload: UpdateRequest,
    callback?: (succ: boolean) => void
  ): JobActions => ({
    type: jobTypes.UPDATE_JOB_BY_PATH,
    payload,
    callback
  }),
  updateJobProperty: (
    payload: Partial<JobProperties>,
    callback?: (succ: boolean) => void
  ): JobActions => ({
    type: jobTypes.UPDATE_JOB_PROPERTY,
    payload,
    callback
  }),
  fetchOrderCheckInHistory: (payload: { id: string, orderId: string }, callback: (succ: boolean) => void): JobActions => ({
    type: jobTypes.FETCH_CHECK_IN_HISTORY,
    payload,
    callback
  }),
  updateJobContact: (payload: { contact: Partial<Contact>, idx: number }, callback: (succ: boolean) => void): JobActions => ({
    type: jobTypes.UPDATE_JOB_CONTACT,
    payload,
    callback
  }),
  createJobContact: (payload: Contact, callback: (succ: boolean) => void): JobActions => ({
    type: jobTypes.CREATE_JOB_CONTACT,
    payload,
    callback
  }),
  removeJobContact: (payload: { idx: number }, callback: (succ: boolean) => void): JobActions => ({
    type: jobTypes.REMOVE_JOB_CONTACT,
    payload,
    callback
  }),
  cloneJob: (payload: string, callback?: (succ: boolean, jobId: string | null) => void): JobActions => ({
    type: jobTypes.CLONE_JOB,
    payload,
    callback
  }),
  changeOrderRequestApproval: (payload: ChangeOrderRequest, callback: (succ: boolean) => void): JobActions => ({
    type: jobTypes.CHANGE_ORDER_REQUEST_APPROVAL,
    payload,
    callback
  }),
  cleanJob: (payload:  null): JobActions => ({
    type: jobTypes.CLEAN_JOB,
    payload
  }),
}

export default actions

