import {
  ConfigValue,
  configTypes,
  ConfigActionTypes
} from './types'

const actions = {
  setConfigValue: (payload: ConfigValue): ConfigActionTypes => ({
    type: configTypes.SET_CONFIG_VALUE,
    payload
  })
}

export default actions
