import { MatchItem } from '../types'

export const matchesTypes = {
  FETCH_MATCHES: 'FETCH_MATCHES',
  RUN_AUTOMATCH:  'RUN_AUTOMATCH',
  SET_MATCHES: 'SET_MATCHES',
}

export interface FetchMatchesAction {
  type: typeof matchesTypes.FETCH_MATCHES
  payload: string
  callback?: (succ: boolean) => void
}

export interface RunAutomatchAction {
  type: typeof matchesTypes.RUN_AUTOMATCH
  payload: string
  callback?: (succ: boolean) => void
}

export interface SetMatchesAction {
  type: typeof matchesTypes.SET_MATCHES
  payload: MatchesType
}

export type MatchesActions =
  | FetchMatchesAction
  | RunAutomatchAction
  | SetMatchesAction

export interface MatchesType {
  id: string
  estimateId: string
  type: string
  runTimestamp: number
  runDuration: number
  results: MatchItem[]
}
