import { useSelector } from 'react-redux'
import { Box, Button, LabeledText, ProgressBar, Typography } from 'src/components/UI'
import { getJob, getSiteVisits } from 'src/ducks/selectors'
import { JobItem } from 'src/ducks/types'
import { formatTimestamp } from 'src/helpers'
import { ContentBox, Divider, TabBox } from '../../Templates'
import { useIsLgScreen, useIsXlScreen } from 'src/hooks'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import SitevisitsModal from './SiteVisitsModal'
import Modal from 'src/components/UI/CustomUI/molecules/Modal'
import { useState } from 'react'

const JobInfo = () => {
  const job = useSelector(getJob())
  const siteVisits = useSelector(getSiteVisits())
  const [xlScreen] = useIsXlScreen()
  const [lgScreen] = useIsLgScreen()
  const [modalOpen, setModalOpen] = useState(false)

  const {
    properties, startedOn, createdOn, firstDispatchDate, lastSiteVisitDate,
    dueOn, expectedCompletedOn, percentItemsComplete, lastDispatchDate
  } = job || {} as JobItem

  const { address, territory } = properties || {}


  return (
    <TabBox>
      <Modal
        setOpen={setModalOpen}
        open={modalOpen}
        title='Site Visit History'
        showClose
        size='xs'
      >
        <SitevisitsModal />
      </Modal>
      <ContentBox gridTemplateColumns={'1fr'}>
        <LabeledText labelValue='Address:' textValue={job?.properties?.fullAddress} />
        <ContentBox gridTemplateColumns={xlScreen || lgScreen ? '1fr 1fr' : '1fr'}>
          <LabeledText labelValue='City:' textValue={address?.city} />
          <LabeledText labelValue='State:' textValue={address?.state} />
          <LabeledText labelValue='Territory:' textValue={territory?.title} />
          <LabeledText labelValue='ZIP Code:' textValue={address?.zipCode} />
        </ContentBox>
      </ContentBox>
      <Divider />
      <ContentBox gridTemplateColumns={xlScreen || lgScreen ? '1fr 1fr 1fr 1fr' : '1fr'}>
        <LabeledText
          labelValue='Approval:'
          textValue={formatTimestamp(createdOn, 'MM/DD/YYYY')}
          flexDirection={xlScreen || lgScreen ? 'column' : 'row'}
        />
        <LabeledText
          labelValue='Completion ETC:'
          flexDirection={xlScreen || lgScreen ? 'column' : 'row'}
          textValue={formatTimestamp(expectedCompletedOn, 'MM/DD/YYYY')}
        />
        <LabeledText
          labelValue='1st Dispatched:'
          flexDirection={xlScreen || lgScreen ? 'column' : 'row'}
          textValue={formatTimestamp(firstDispatchDate, 'MM/DD/YYYY')}
        />
        <Box display="flex" alignItems="flex-start">
          <LabeledText
            labelValue='Site Visit:'
            textValue={formatTimestamp(lastSiteVisitDate, 'MM/DD/YYYY')}
            flexDirection={xlScreen || lgScreen ? 'column' : 'row'}
            sx={{ width: '100%' }}
          />
          {siteVisits && siteVisits.length > 0 && <Button onClick={() => setModalOpen(true)}>
            <Icon name="ArrowRight" color="var(--navy-700)" />
          </Button>}
        </Box>
        <LabeledText
          flexDirection={xlScreen || lgScreen ? 'column' : 'row'}
          labelValue='Job Start:'
          textValue={formatTimestamp(startedOn, 'MM/DD/YYYY')}
        />
        <LabeledText
          flexDirection={xlScreen || lgScreen ? 'column' : 'row'}
          labelValue='Customer Deadline:'
          textValue={formatTimestamp(dueOn, 'MM/DD/YYYY')}
        />
        <LabeledText
          labelValue='Last Dispatched:'
          textValue={formatTimestamp(lastDispatchDate, 'MM/DD/YYYY')}
          flexDirection={xlScreen || lgScreen ? 'column' : 'row'}
        />
        <Box display='grid' gridAutoFlow='row' gridTemplateRows='1fr 1fr'>
          <LabeledText labelValue='Job Progress:' textValue={undefined} flexDirection='row' />
          <Box display='grid' gridAutoFlow='column' gap={1} gridTemplateColumns='60% max-content' alignItems='baseline'>
            <ProgressBar value={percentItemsComplete ?? 0} />
            <Typography>{percentItemsComplete}%</Typography>
          </Box>
        </Box>
      </ContentBox>
    </TabBox>
  )
}

export default JobInfo
