import { formatParams } from 'src/helpers'
import axios from '../axios'
import { ApiController } from './types'

const base = '/payments'

const apiController: ApiController = {
  getPayments: async ({
    params
  }) => {
    const parameters = formatParams(params);
    return await axios
      .get(`${base}?${parameters}`)
      .then(response => response.data)
  },
  createPayment: async (request) =>
    await axios
      .post(`${base}`, request)
      .then(response => response.data),
}

export default apiController
