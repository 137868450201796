import {
  GetNotesParams,
  Note, NotesActions, notesTypes
} from './types'

const actions = {
  createNote: (
    payload: Partial<Note>,
    callback?: (
      succ: boolean) => void
  ): NotesActions => ({
    type: notesTypes.CREATE_NOTE,
    payload,
    callback
  }),
  getNotes: (
    payload: {
      params: GetNotesParams,
    },
    callback?: (succ: boolean) => void
  ): NotesActions => ({
    type: notesTypes.GET_NOTES,
    payload,
    callback
  }),
  setNotes: (payload: Note[]): NotesActions => ({
    type: notesTypes.SET_NOTES,
    payload
  }),
  setNote: (payload: Note): NotesActions => ({
    type: notesTypes.SET_NOTE,
    payload
  }),
}

export default actions
