export const tooltip = {
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
       // width: '50%',
      },
      
    },
  },
};
