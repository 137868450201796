import { NOTE_TYPE, RESOURCE_TYPE } from "src/helpers"

/** TYPES **/
export const notesTypes = {
  CREATE_NOTE: 'CREATE_NOTE',
  GET_NOTES: 'GET_NOTES',
  SET_NOTES: 'SET_NOTES',
  SET_NOTE: 'SET_NOTE'
}

/** ACTIONS **/

export interface CreateNoteAction {
  type: typeof notesTypes.CREATE_NOTE
  payload: Partial<Note>
  callback?: (succ: boolean) => void
}

export interface GetNotesAction {
  type: typeof notesTypes.GET_NOTES
  payload: {
    params: GetNotesParams
  }
  callback?: (succ: boolean) => void
}

export interface SetNotesAction {
  type: typeof notesTypes.SET_NOTES
  payload: Note[]
}
export interface SetNoteAction {
  type: typeof notesTypes.SET_NOTE
  payload: Note
}

export interface GetNotesParams {
  resourceId: string,
  resourceType?: RESOURCE_TYPE,
  noteType?: NOTE_TYPE
  start?: number,
  limit?: number
}

export type NotesActions =
  | CreateNoteAction
  | GetNotesAction
  | SetNotesAction
  | SetNoteAction

/** REDUCER **/
export interface Note {
  id: string,
  text: string,
  createdOn: number,
  createdBy: string,
  noteType: string,
  resourceId: string,
  resourceType: string,
  createdByName: string
}

export type Notes = Note[] | null
