import { Box, Button, Grid, Typography } from "@mui/material";
import { Dispatch, useEffect } from "react";
import { SetStateAction } from "react";
import { TextFieldLabel } from "src/components/UI";
import Modal from "src/components/UI/CustomUI/molecules/Modal";
import ProfilePicture from "src/components/UI/CustomUI/molecules/ProfilePicture";
import { EmployeeType } from "src/ducks/employee/types";
import { useState } from 'react';
import { useDispatch } from "react-redux";
import { employeeActions, employeesActions, filesActions } from "src/ducks/actions";
import { PrivateLoader } from "src/components/templates";
import { isEmpty } from "src/helpers";


export default function UpdateUserModal({ open, setOpen, employee }: {
    open: boolean,
    setOpen: Dispatch<SetStateAction<boolean>>,
    employee: EmployeeType
}) {

    const dispatch = useDispatch()

    const [fullAddress, setFullAddress] = useState<string>(employee?.address?.fullAddress);
    const [name, setName] = useState<string>(employee.firstName);
    const [lastName, setLastName] = useState<string>(employee.lastName);
    const [phone, setPhone] = useState<string>('' + employee.phone ?? '');
    const [city, setCity] = useState<string>(employee.address?.city);
    const [state, setState] = useState<string>(employee.address?.state);
    const [line1, setLine1] = useState<string>(employee.address?.line_1);
    const [line2, setLine2] = useState<string>(employee.address?.line_2);
    const [zipCode, setZipCode] = useState<string>(employee.address?.zipCode);
    const [picture, setPicture] = useState(employee.picture);

    const [isLoading, setIsLoading] = useState(false);
    const [upLoading, setUploading] = useState(false)

    const [isDirty, setIsDirty] = useState(false);

    function checkDirty(picture: string, name: string, lastName: string, phone: string, fullAddress: string, city: string, state: string, line1: string, line2: string, zipCode: string, employee: EmployeeType): boolean {
        return (
            picture !== employee.picture ||
            name !== employee.firstName ||
            lastName !== employee.lastName ||
            (phone !== '' + employee.phone ?? '') ||
            fullAddress !== employee.address?.fullAddress ||
            city !== employee.address?.city ||
            state !== employee.address?.state ||
            line1 !== employee.address?.line_1 ||
            line2 !== employee.address?.line_2 ||
            zipCode !== employee.address?.zipCode
        );
    }

    useEffect(() => {
        setIsDirty(checkDirty(picture, name, lastName, phone, fullAddress, city, state, line1, line2, zipCode, employee))
    }, [picture, name, lastName, phone, fullAddress, city, state, line1, line2, zipCode])

    useEffect(() => {
        setPicture(employee.picture)
        setName(employee.firstName);
        setLastName(employee.lastName);
        setPhone('' + employee.phone ?? '');
        setFullAddress(employee?.address?.fullAddress);
        setCity(employee.address?.city ?? '');
        setState(employee.address?.state ?? '');
        setLine1(employee.address?.line_1 ?? '');
        setLine2(employee.address?.line_2 ?? '');
        setZipCode(employee.address?.zipCode ?? '');
        setIsDirty(false);
    }, [employee]);

    const handleUpload = (image: File | null): void => {
        if (image) {
            setUploading(true)
            // setPicture('')
            dispatch(
                filesActions.uploadFile(image, (uploadSucc, fileName) => {
                    if (uploadSucc && !isEmpty(fileName)) {
                        dispatch(employeesActions.updateEmployeeInListById({ updatedValues: { picture: fileName }, id: employee.id }))
                        dispatch(
                            employeeActions.updateEmployeeId(
                                employee.id,
                                {
                                    picture: fileName
                                },
                                success => {
                                    const newPicture = success
                                        ? (fileName as string)
                                        : employee.picture
                                    setPicture(newPicture)
                                    setTimeout(() => {
                                        setUploading(false)
                                        const customEvent = new CustomEvent(
                                            'profilePictureChange',
                                            {
                                                detail: { newPicture }
                                            }
                                        )
                                        document.dispatchEvent(customEvent)
                                    }, 5000)
                                }
                            )
                        )
                    } else {
                        setPicture(employee.picture)
                    }
                })
            )
        }
    }

    const handleSave = () => {
        setIsLoading(true)
        const updatedValues: Partial<EmployeeType> = {};

        if (name !== employee.firstName) {
            updatedValues.firstName = name;
        }

        if (lastName !== employee.lastName) {
            updatedValues.lastName = lastName;
        }

        if (phone !== '' + employee.phone ?? '') {
            updatedValues.phone = Number(phone);
        }

        if (
            city !== employee.address?.city ||
            state !== employee.address?.state ||
            line1 !== employee.address?.line_1 ||
            line2 !== employee.address?.line_2 ||
            zipCode !== employee.address?.zipCode
        ) {
            updatedValues.address = {
                ...employee.address,
                city: city ?? '',
                state: state ?? '',
                line_1: line1 ?? '',
                line_2: line2 ?? '',
                zipCode: zipCode ?? ''
            };
        }

        dispatch(employeeActions.updateEmployeeId(employee.id, updatedValues, (succ) => {
            setIsLoading(false)
            setOpen(false)
            dispatch(employeesActions.updateEmployeeInListById({ updatedValues: updatedValues, id: employee.id }))
        }))
    }

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title={<Grid container justifyContent='left' padding='1rem'>
                <Typography variant='h5Bold'>Edit User</Typography>
            </Grid>}
        >
            <PrivateLoader loading={isLoading}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <PrivateLoader loading={upLoading}>
                            <ProfilePicture avatar={picture} onUpload={handleUpload} uploading={upLoading} />
                        </PrivateLoader>

                        {upLoading && <Box sx={{
                            zIndex: 10,
                            position: 'absolute',
                            borderRadius: '32px',
                            top: '60px',
                            left: '25px',
                            width: '180px',
                            height: '180px',
                            backgroundColor: 'rgba(192, 192, 192, 0.3)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'

                        }}></Box>
                        }
                    </Grid>
                    <Grid item xs={6} container>
                        <Grid item xs={12}>
                            <TextFieldLabel
                                label="Name"
                                value={name}
                                onChange={(event) => setName(event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldLabel
                                label="Last Name"
                                value={lastName}
                                onChange={(event) => setLastName(event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldLabel
                                label="Phone"
                                value={phone}
                                onChange={(event) => setPhone(event.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <TextFieldLabel
                            label="Address"
                            value={fullAddress}
                            onChange={(event) => setFullAddress(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextFieldLabel
                            label="City"
                            value={city}
                            onChange={(event) => setCity(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextFieldLabel
                            label="State"
                            value={state}
                            onChange={(event) => setState(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextFieldLabel
                            label="Line 1"
                            value={line1}
                            onChange={(event) => setLine1(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextFieldLabel
                            label="Line 2"
                            value={line2}
                            onChange={(event) => setLine2(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextFieldLabel
                            label="ZIP Code"
                            value={zipCode}
                            onChange={(event) => setZipCode(event.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid container gap={1} display='flex' alignItems='flex-end' justifyContent='flex-end' marginTop='15px'>
                    <Button
                        sx={{ width: '200px' }}
                        variant='outlined'
                        onClick={() => setOpen(false)}
                    >
                        Close
                    </Button>
                    <Button
                        sx={{ width: '200px' }}
                        variant='contained'
                        onClick={handleSave}
                        disabled={!isDirty}
                    >
                        Save Changes
                    </Button>
                </Grid>
            </PrivateLoader>

        </Modal>
    )
}