import { HeaderType, AlignType } from 'src/components/UI/CustomUI/organisms/Table/types'

import { Button, SelectInput } from 'src/components/UI'
import { USER_TYPE } from 'src/helpers'
import { Typography } from '@mui/material'
import moment from 'moment'
import { useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getJob, getMaterialInvoices, getOrdersSelector } from 'src/ducks/selectors'
import { jobActions, materialInvoicesActions } from 'src/ducks/actions'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import { InvoiceMaterialContext } from '../../../../ControlButtons/Modals/ModalMaterialInvoices/context'
import { invoiceMaterialModalTypes } from '../../../../ControlButtons/Modals/ModalMaterialInvoices/context/types'
import Remove from '../../../../ControlButtons/Modals/ModalMaterialInvoices/Remove'
import Modal from 'src/components/UI/CustomUI/molecules/Modal'
import { MaterialInvoices } from 'src/ducks/materialInvoice/types'
import { MaterialInvoice } from 'src/ducks/types'


const headers: HeaderType[] = [
    {
        id: 'invoiceNum',
        label: 'Invoice Number',
    },
    {
        id: 'amount',
        label: 'Amount',
        custom: true,
        Element: (row: any): JSX.Element => {
            return (
                <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                    {row?.amount > 0 ? `$${row?.amount}` : `-$${row?.amount * -1}`}
                </Typography>
            )
        },

    },
    {
        id: 'orderName',
        label: 'Work Order',
        maxWidth: 200,
        custom: true,
        align: 'center' as AlignType,
        Element: (row: any, index): JSX.Element => {
            const dispatch = useDispatch()
            const materialInvoices = useSelector(getMaterialInvoices())
            const ordersOtions = useSelector(getOrdersSelector)
            const [loading, setLoading] = useState(false)

            const handleChangeOrder = (newValue: any) => {
                setLoading(true)
                dispatch(materialInvoicesActions.updateMaterialInvoiceByPath([{ op: 'replace', path: `/${index}/orderName`, value: newValue.label }, { op: 'replace', path: `/${index}/orderId`, value: newValue.key }],
                    (succ: boolean) => {
                        if (succ) {
                            setLoading(false)
                            const totalPrice = materialInvoices?.reduce((acc: number, curr: any) => acc + curr?.amount, 0) || 0
                            dispatch(jobActions.setJobValue({ attr: 'materialCost', value: totalPrice }))
                        }
                    }))
            }
            return (
                <SelectInput
                    onChange={handleChangeOrder}
                    value={row.orderName}
                    options={ordersOtions}
                    size='small'
                    disableClearable
                    disabled={false}
                    loadingValue={loading}
                />
            )
        },
    },
    {
        id: 'insertedOn',
        label: 'Added',
        maxWidth: 200,
        custom: true,
        align: 'center' as AlignType,
        Element: (row: any): JSX.Element => {
            return (
                <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                    {moment.unix(row?.insertedOn).format('MM/DD/YYYY')}
                </Typography>
            )
        },
    },
    {
        id: 'view',
        label: 'View',
        maxWidth: 200,
        custom: true,
        align: 'center' as AlignType,
        Element: (row: any): JSX.Element => {
            return (
                <Button
                    variant='contained'
                    color='primary'
                    sx={{ width: '75px', height: '26px' }}
                    href={row.fileUrl}
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    View
                </Button>
            )
        },
    },
    {
        id: 'delete',
        label: '',
        maxWidth: 200,
        custom: true,
        align: 'center' as AlignType,
        Element: (row: any, index: number): JSX.Element => {
            const { dispatch: dispatchContext } = useContext(InvoiceMaterialContext)
            const [invoiceDelete, setInvoiceDelete] = useState("")
            const [open, setOpen] = useState(false)

            const onDelete = (value: MaterialInvoice) => {
                dispatchContext({ type: invoiceMaterialModalTypes.MATERIAL_INVOICE_DELETE, payload: { value } })
                setOpen(true)
                setInvoiceDelete(value.invoiceNum)
                // onRemoveInvoice();
            }

            return (
                <>
                    <Modal
                        setOpen={setOpen}
                        open={open}
                        title={<Typography sx={{ padding: '12px' }} variant='h5'
                        >
                            Delete Invoice #{invoiceDelete}
                        </Typography>} size='xs'
                    >
                        <Remove index={index} onClose={() => setOpen(false)} />
                    </Modal>
                    <Button
                        variant='containedErrorLight'
                        style={{
                            padding: '.5rem !important'
                        }}
                        onClick={() => onDelete(row)}
                    >
                        <Icon name='Delete' color='var(--red600)' />
                    </Button>
                </>
            )
        },
    }
]
export default headers
