/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo } from '../../helpers'
import { Punchlist } from '../../api'
import { materialActions } from '../actions'
import {
  materialTypes,
  FetchMaterialBySku,
  CreateMaterial,
} from './types'

export function* fetchMaterialBySku({
  payload,
  callback,
}: FetchMaterialBySku): SagaIterator {
  let success = false
  try {
    const sku = payload

    const item = yield call(Punchlist.materials.getMaterialBySku, { sku })

    if (item) {
      yield put(materialActions.setMaterial(item))
    }
    success = true
    if (callback) yield call(callback, success, item)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the material by sku'))
    if (callback) yield call(callback, false)
  }
}

export function* createMaterial({
  payload,
  callback
}: CreateMaterial): SagaIterator {
  let success = false

  try {
    const item = yield call(Punchlist.materials.createMateral, payload)

    if (item) {
      yield put(materialActions.setMaterial(item))
    }
    success = true
    if (callback) yield call(callback, success, item)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('create a material'))
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(materialTypes.FETCH_MATERIAL_BY_SKU, fetchMaterialBySku)
  yield takeLatest(materialTypes.CREATE_MATERIAL, createMaterial)
}
