import React, { FC, useEffect, useState } from 'react'
import { Checkbox, FormControlLabel, Grid } from 'src/components/UI/MaterialUI'
import { VendorColorPickerProps } from './types'
import { ColorChangeHandler, ColorResult } from 'react-color'
import ColorPicker from '../../atoms/ColorPicker'
import { getEventTextColor } from 'src/components/pages/Private/Calendar/helper'
import { useDispatch } from 'react-redux'
import { vendorActions, vendorsActions } from 'src/ducks/actions'

const VendorColorPicker: FC<VendorColorPickerProps> = ({
  vendor,
  showColorPicker = true,
  vendorOptions,
  setVendorOptions,
  index,
}) => {
  const dispatch = useDispatch()
  const [color, setColor] = useState(vendor.color)

  useEffect(() => {
    setColor(vendor.color)
  }, [vendor.color])

  const pickerButton = (
    <div
      style={{
        backgroundColor: `${color}`,
        border: `1px solid ${getEventTextColor(vendor.color ?? 'black')}`,
        width: '32px',
        height: '16px',
        borderRadius: '4px',
      }}
    ></div>
  )

  const handleSelection = () => {
    setVendorOptions(
      vendorOptions.map((option) =>
        option.id === vendor.id
          ? { ...option, checked: !option.checked }
          : option
      )
    )
  }

  const handleColorChange: ColorChangeHandler = (newColor: ColorResult) => {
    setColor(newColor.hex)
  }

  const handleColorSave = () => {
    const newVendor = {
      color: color,
    }
    if (vendor.color !== color) {
      dispatch(vendorActions.setVendor(vendor))
      dispatch(
        vendorActions.updateVendor(newVendor, (succ) => {
          if (succ) {
            dispatch(
              vendorsActions.setVendorById({
                ...vendor,
                color: color,
              })
            )
          } else {
            setColor(vendor.color)
          }
        })
      )
    }
  }

  const isCheked = () => {

    const r = vendorOptions.find((v) => v.id === vendor.id)
    if (r) {
      return r.checked
    } else {
      return true
    }
  }

  return (
    <Grid container justifyContent="space-between" style={{ height: '30px' }}>
      <FormControlLabel
        control={
          <Checkbox
            //checked={vendorOptions[index]?.checked ?? true}
            checked={isCheked()}
            color="infoText"
            onChange={handleSelection}
            sx={{
              padding: '0px !important',
            }}
          />
        }
        label={`${vendor.firstName} ${vendor.lastName}`}
        style={{ gap: '8px' }}
      />
      {showColorPicker && (
        <ColorPicker
          picker={pickerButton}
          color={color}
          onChange={handleColorChange}
          onClose={handleColorSave}
        />
      )}
    </Grid>
  )
}

export default VendorColorPicker
