import React from 'react'
import { Grid } from '@mui/material'
import EditItems from './EditItemList'
import ItemView from '../ItemView'

const EditView = () => {
  return (
    <Grid container style={{ height: '100%' }}>
      <Grid item md={5}>
        <EditItems />
      </Grid>
      <Grid item md={7} flex={1} paddingRight={2}>
        <ItemView view='EDIT'/>
      </Grid>
    </Grid>
  )
}

export default EditView