import { createStyles, makeStyles } from "@mui/styles";

export default makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'center',
      width: '100%',
      alignItems: 'center',
      zIndex: 10,
      /* [theme.breakpoints.down('sm')]: {
        height: '112px'
      }, */
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      paddingBottom: '1rem'
    },
    image: {
      width: 240
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '1.5rem'
    },
    title: {
      fontSize: 20,
      color: '#2F7BF7',
      fontFamily: 'NextArtHeavy',
      lineHeight: 1
    },
    subtitle: {
      color: '#6D6A6F',
      fontSize: 18
    },
    button: {
      background: 'var(--bosscat-blue-600)',
      color: 'white',
      textTransform: 'inherit',
      fontWeight: 700,
      height: 'fit-content',
      padding: '0.8rem 1.2rem',
      fontSize: 18,
      borderRadius: 8,
      '&:hover': {
        background: 'var(--bosscat-blue-700)',
      },
      '@global': {
        '.MuiSvgIcon-root': {
          fontSize: '.8rem'
        }
      }
    }
  })
)
