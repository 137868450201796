import { Typography } from '@mui/material'
import { AlignType, HeaderType } from 'src/components/UI/CustomUI/organisms/Table/types'
import { round } from 'src/helpers'

const headers: HeaderType[] = [
    {
        id: 'createdOn',
        label: 'Date',
        type: 'datetime',
    },
    /* {
        id: 'type',
        label: 'Type',
        custom: true,
        Element: ({ type }: any): JSX.Element => {
            return <Typography variant='body2' color={type === "VENDOR PAY" ? 'var(--blue700)' : 'inherit'}>{type} </Typography>
        },
    }, */
    {
        id: 'createdByName',
        label: 'Added By',
    },
    {
        id: 'amount',
        label: 'Amount',
        custom: true,
        align: 'center' as AlignType,
        Element: (row: any): JSX.Element => <Typography>{row?.amount ? `$${round(row?.amount, 2)}` : '-'}</Typography>
    },
    {
        id: 'referenceNum',
        label: 'Check Number',
    },
    {
        id: 'memo',
        label: 'Note',
    },
    /*   {
          id: 'sent',
          label: 'Sent',
          type: 'datetime',
      }, */
    /* {
        id: 'status',
        label: 'Status',
        custom: true,
        Element: ({ status }: any): JSX.Element => {
            return <Typography variant='body2' color={status === "RECEIVED" ? 'var(--success-color)' : 'var(--orange700)'}>{status} </Typography>
        },
    }, */
]

export default headers
