import React, { useState } from 'react'
import { FieldsFilter, Grid, InputSearch, SelectInput, Typography } from 'src/components/UI'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import { SelectInputOption } from 'src/components/UI/CustomUI/atoms/SelectInput/types'
import Table from 'src/components/UI/CustomUI/organisms/Table'
import { useIsXlScreen } from 'src/hooks'
import headers from './headers'

const options = [{
    key: '0',
    label: 'Dropdown TBD'
},]

const Jobs = () => {
    // const userStatistics = useSelector(getClientStatics())
    const [xlScreen] = useIsXlScreen()
    const [searchFilter, setSearchFilter] = useState('')
    const [selectedTerritory, setSelectedTerritory] = useState(options[0])
    const [tableHeaders, setTableHeaders] = useState(headers)

    const handleSelectChange = (newSelected: SelectInputOption) => {
        setSelectedTerritory(newSelected)
    }

    const onTableFilterChange = (index: number): void => {
        setTableHeaders(tableHeaders.map((item, indx) => {
            if (indx === index) {
                return { ...item, hide: !item.hide }
            }
            return item
        }))
    }
    return (
        <Grid container>
            <Grid item flex={1}>
                <Grid>
                    <Grid item container spacing={2} marginBottom={3} >
                        <Grid item container spacing={2} lg={xlScreen ? 6 : 12} justifyContent={xlScreen ? "left" : "center"} >
                            <Grid item display="flex" justifyContent="center" alignItems="center">
                                <Icon color='var(--blue700)' name="Assignment" />
                                <Typography variant='h4' color='var(--blue700)' marginRight={1}> Estimates:</Typography>
                                {/* TODO: Replace data with userStatics */}
                                <Typography variant='h4Bold'>5</Typography>
                            </Grid>
                            <Grid item display="flex" justifyContent="center" alignItems="center">
                                <Icon color='var(--orange700)' name="AssignmentLate" />
                                <Typography variant='h4' color='var(--orange700)' marginRight={1}> Active Orders:</Typography>
                                <Typography variant='h4Bold'>5</Typography>
                            </Grid>
                            <Grid item display="flex" justifyContent="center" alignItems="center">
                                <Icon color='var(--green800)' name="Build" />
                                <Typography variant='h4' color='var(--green800)' marginRight={1}> Completed Jobs:</Typography>
                                <Typography variant='h4Bold'>5</Typography>
                            </Grid>
                        </Grid>
                        <Grid item container justifyContent="flex-end" spacing={2} lg={xlScreen ? 6 : 12}>
                            <Grid item>
                                <InputSearch
                                    placeholder='Search...'
                                    onChange={setSearchFilter}
                                    value={searchFilter}
                                />
                            </Grid>
                            <Grid item>
                                <SelectInput
                                    onChange={handleSelectChange}
                                    options={options}
                                    value={selectedTerritory}
                                    placeholder='Dropdown TBD'
                                    sx={{ minWidth: '200px' }}
                                />
                            </Grid>
                            <Grid item>
                                <FieldsFilter options={tableHeaders} handleChange={onTableFilterChange} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Table
                    headers={headers}
                >
                    {[]}
                </Table>
            </Grid>
        </Grid>
    )
}

export default Jobs