import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from 'react-toastify'

export default makeStyles((theme: Theme) =>
  createStyles({
    upload: {
      display: 'grid',
      alignItems: 'center',
      position: 'relative',
      border: '2px solid var(--border-color)',
      borderRadius: '8px',
      padding: '8px',
      '&:hover': {
        borderColor: 'var(--border-hover)',
        borderRadius: 8
      },
      '&.Mui-focused': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8
      }
    },
    dropzone: {
      display: 'flex',
      border: '2px dashed var(--border-color)',
      backgroundColor: 'rgba(245, 247, 255, 1)',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      width: 'auto',
      height: 'auto',
      minHeight: 'auto',
      margin: '24px',
      top: '-3px',
      bottom: '-3px',
      left: '-3px',
      right: '-3px',
      '& .MuiDropzoneArea-textContainer': {
        display: 'flex',
        alignItems: 'center',
        gap: '16px'
      }
    },
    buttonBox: {
      textAlign: 'end'
    },
    button: {
      width: '185px',
      height: '36px',
      margin: '12px',
      textTransform: 'none',
      alignItems: 'base',
      color: 'var(--blue700)',
      backgroundColor: 'var(--gray100)',
      borderRadius: 32,
      border: '0px solid',
    },
    image: {
      width: '16px',
      height: '16px',
      paddingBottom: '2px'
    },
    filesBox: {
      border: '1px dashed #1A2A55',
      minHeight: '64px',
      borderRadius: '4px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#F0F3FA',
      cursor: 'pointer'
    },
    file: {
      display: 'flex',
      padding: '12px',
      borderRadius: '8px',
      gap: '8px',
      alignItems: 'center',
      backgroundColor: '#F9FAFA',
      position: 'relative',
      justifyContent: 'space-between',
    },
    delete: {

    },
    loading: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%'
    },
    errorColor: { borderColor: 'var(--error-color)' }
  })
)
