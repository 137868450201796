import { FC } from 'react'
import { LinearProgress } from '../../../'
import { Props } from './types'

const ProgressBar: FC<Props> = ({
  value
}) => {
  const color = value >= 50 ? `rgb(23, 185, 117)` : `rgb(58, 102, 255)`
  return (
    <LinearProgress
      sx={{
        height: 8,
        borderRadius: 8,
        backgroundColor: `rgb(235, 236, 239)`,
        "& .MuiLinearProgress-bar": {
          backgroundColor: `${color}`
        }
      }}
      color='primary'
      variant='determinate'
      value={value}
    />
  )
}

export default ProgressBar
