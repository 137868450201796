import { useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Table from 'src/components/UI/CustomUI/organisms/Table'
import { getStatistiscs } from 'src/ducks/selectors'
import { jobsActions } from 'src/ducks/actions'
import headers from './headers'
import moment from 'moment'
import { format } from 'date-fns'

const Month = () => {

  const [tableLoading, setTableLoading] = useState(true)
  const dispatch = useDispatch()
  const statisticsList = useSelector(getStatistiscs())
  const formatDate = 'MM/dd/yyyy'

  const fetchServices = useCallback(() => {
    setTableLoading(true)
    const fistDayOfMonth = moment().startOf('month').toDate()
    const lastDayOfMonth = moment().endOf('month').toDate()

    dispatch(jobsActions.fetchJobsCounts({
      searchParams: {
        startDate: format(fistDayOfMonth, formatDate),
        endDate: format(lastDayOfMonth, formatDate)
      }
    }, (_succ: boolean) => {
      setTableLoading(false)
    }))
  }, [])

  useEffect(() => {
    fetchServices()
  }, [dispatch, fetchServices])

  return (
    <Table
      headers={headers}
      loading={tableLoading}
      hasPagination={false}
    >
      {statisticsList ?? []}
    </Table>
  )
}

export default Month
