import { ChangeOrderRequest, UpdateRequest } from '~api/types'
import { Contact, JobItem, JobProperties } from '../types'

/** TYPES **/
export const jobTypes = {
  FETCH_JOB: 'FETCH_JOB',
  DELETE_JOB: 'DELETE_JOB',
  SET_JOB: 'SET_JOB',
  CLEAN_JOB: 'CLEAN_JOB',
  SET_JOB_VALUE: 'SET_JOB_VALUE',
  FETCH_HISTORY: 'FETCH_HISTORY',
  FETCH_HISTORY_STATUS: 'FETCH_HISTORY_STATUS',
  FETCH_JOB_ORDERS_HISTORY: 'FETCH_JOB_ORDERS_HISTORY',
  FETCH_HISTORY_TASK: 'FETCH_HISTORY_TASK',
  FETCH_ORDER_HISTORY: 'FETCH_ORDER_HISTORY',
  UPDATE_JOB: 'UPDATE_JOB',
  UPDATE_JOB_BY_PATH: 'UPDATE_JOB_BY_PATH',
  UPDATE_JOB_PROPERTY: 'UPDATE_JOB_PROPERTY',
  FETCH_CHECK_IN_HISTORY: 'FETCH_CHECK_IN_HISTORY',
  UPDATE_JOB_CONTACT: 'UPDATE_JOB_CONTACT',
  CREATE_JOB_CONTACT: 'CREATE_JOB_CONTACT',
  REMOVE_JOB_CONTACT: 'REMOVE_JOB_CONTACT',
  CLONE_JOB: 'CLONE_JOB',
  CHANGE_ORDER_REQUEST_APPROVAL: 'CHANGE_ORDER_REQUEST_APPROVAL',
  CREATE_JOB: 'CREATE_JOB',
}

/** ACTIONS **/
export interface FetchJobAction {
  type: typeof jobTypes.FETCH_JOB
  payload: string
  callback?: (succ: boolean) => void
}

export interface CreateJobAction {
  type: typeof jobTypes.CREATE_JOB
  payload: Partial<JobItem>
  callback?: (succ: boolean) => void
}

export interface DeleteJobAction {
  type: typeof jobTypes.DELETE_JOB
  payload: string
  callback?: (succ: boolean) => void
}

export interface SetJobAction {
  type: typeof jobTypes.SET_JOB
  payload: JobItem | null
}

export interface SetJobAttb {
  type: typeof jobTypes.SET_JOB
  payload: JobValue
}

export interface FetchJobHistoryAction {
  type: typeof jobTypes.FETCH_HISTORY
  payload: string
  callback?: (succ: boolean) => void
}

export interface UpdateJobAction {
  type: typeof jobTypes.UPDATE_JOB
  payload: Partial<JobItem>
  callback?: (succ: boolean) => void
}

export interface UpdateJobByPathAction {
  type: typeof jobTypes.UPDATE_JOB
  payload: UpdateRequest
  callback?: (succ: boolean) => void
}

export interface UpdateJobPropertyAction {
  type: typeof jobTypes.UPDATE_JOB
  payload: Partial<JobProperties>
  callback?: (succ: boolean) => void
}

export interface UpdateJobByPath {
  type: typeof jobTypes.UPDATE_JOB
  payload: {
    path: string,
    value?: any
  }
  callback?: (succ: boolean) => void
}

export interface FetchOrderCheckInHistory {
  type: typeof jobTypes.UPDATE_JOB
  payload: { id: string, orderId: string }
  callback?: (succ: boolean) => void
}

export interface UpdateJobContact {
  type: typeof jobTypes.UPDATE_JOB_CONTACT
  payload: {
    contact: Partial<Contact>,
    idx: number
  }
  callback?: (succ: boolean) => void
}

export interface CreateJobContact {
  type: typeof jobTypes.CREATE_JOB_CONTACT
  payload: Contact
  callback?: (succ: boolean) => void
}

export interface CloneJobAction {
  type: typeof jobTypes.CLONE_JOB
  payload: string
  callback?: (succ: boolean, jobId: string | null) => void
}

export interface JobValue {
  attr: keyof JobItem
  value: any
}

export interface RemoveJobContact {
  type: typeof jobTypes.REMOVE_JOB_CONTACT
  payload: {
    //contact: Partial<Contact>,
    idx: number
  }
  callback?: (succ: boolean) => void
}

export interface ChangeOrderRequestApprovalAction {
  type: typeof jobTypes.CHANGE_ORDER_REQUEST_APPROVAL
  payload: ChangeOrderRequest
  callback?: (succ: boolean) => void
}

export type JobActions =
  | FetchJobAction
  | SetJobAction
  | SetJobAttb
  | UpdateJobAction
  | UpdateJobByPathAction
  | UpdateJobPropertyAction
  | DeleteJobAction
  | FetchOrderCheckInHistory
  | UpdateJobContact
  | CreateJobContact
  | CloneJobAction
  | RemoveJobContact
  | ChangeOrderRequestApprovalAction
  | CreateJobAction

/** REDUCER **/
export type JobType = JobItem | null
