import { FC, useState } from 'react'

import { Box, Button, Typography } from '@mui/material'
import { addDays } from 'date-fns'
import { SingleDatePicker } from 'src/components/UI/CustomUI/atoms/DatePicker/SingleDatePicker'
import { Item } from 'src/ducks/types'
import { calcWeekends, getHolidaysDate, getNextBusinessDayIfFriday, isEmpty } from 'src/helpers/index'
import useStyles from './styles'

export interface HomeConsultationModalProps {
  selectedItemsForConsultation?: Item[],
  onSuccess: () => void
}

const HomeConsultationModal: FC<HomeConsultationModalProps> = ({ selectedItemsForConsultation, onSuccess }) => {
  const classes = useStyles()
  const [date, setDate] = useState<Date>()
  const holidays = getHolidaysDate()
  const nextDay = addDays(new Date(), 1)
  const weekends = calcWeekends(new Date(), addDays(new Date(), 400))

  const nextBusinessDayIfFriday = getNextBusinessDayIfFriday()

  const disabledDates = [...weekends, ...holidays, new Date(), nextDay]

  if (nextBusinessDayIfFriday) {
    disabledDates.push(nextBusinessDayIfFriday)
  }

  const handleChangeDate = (value: Date) => {
    setDate(value)
  }

  return (
    <Box className={classes.root}>
      <Box style={{ margin: '1rem 0' }}>
        <Typography variant='h6' style={{ paddingLeft: 0, fontFamily: 'NextArtHeavy' }}>schedule in-home consultation ({selectedItemsForConsultation?.length}) items</Typography>
        <Typography style={{ color: '#6D6A6F' }}>Request the date that best suits your schedule.</Typography>
      </Box>
      <Box className={classes.datePickerContainer}>
        <SingleDatePicker
          onChange={handleChangeDate}
          value={date as Date}
          minDate={new Date()}
          maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 1))}
          disabledDates={disabledDates}
        />
      </Box>
      <Box className={classes.buttonContainer}>
        <Button
          className={classes.button}
          onClick={() => { }}
          disabled={isEmpty(date?.toString())}
        >Request Consultation</Button>
      </Box>

    </Box>
  )
}

export default HomeConsultationModal
