
/** TYPES **/
export const invitationTypes = {
    GET_INVITATIONS: 'GET_INVITATIONS',
    SEND_INVITATION: 'SEND_INVITATION',
    SET_INVITATIONS: 'SET_INVITATIONS'
}
export enum INVITATION_STATE {
    ACCEPTED = 'ACCEPTED',
    REVOKED = 'REVOKED',
    REJECTED = 'REJECTED'
}

export interface Invitation {
    id: string
    networkId: string
    recipientEmail: string
    sentOn: number
    status: INVITATION_STATE
}

export interface SendInvitationPayload {
    recipientEmail: string,
    networkId: string
}

/** ACTIONS **/
export interface GetInvitationAction {
    type: typeof invitationTypes.GET_INVITATIONS
    payload: {
        networkIds?: string[]
    }
    callback?: (succ: boolean) => void
}

export interface SendInvitationAction {
    type: typeof invitationTypes.SEND_INVITATION
    payload: SendInvitationPayload,
    callback?: (succ: boolean) => void
}

export interface SetInvitationsAction {
    type: typeof invitationTypes.SET_INVITATIONS
    payload: Invitation[],
    callback?: (succ: boolean) => void
}



export type InvitationActions =
    | GetInvitationAction
    | GetInvitationAction
    | SendInvitationAction
    | SetInvitationsAction

/** REDUCER **/

export interface InvitationReducer {
    invitations: Invitation[] | never[]
}

