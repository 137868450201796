import React, { FC } from 'react'
import { ClientNotesProps } from './types'
import styles from './styles.module.scss'
import { Grid, Typography, Divider, Box } from '@mui/material'
import { ESTIMATE_STATUS } from 'src/helpers'

const ClientNotes: FC<ClientNotesProps> = ({ estimate }) => {
  return (
    <Box overflow="scroll" maxHeight="200px" width='100%'>
      <Grid
        container
        spacing={0.5}
        flexDirection="column"
        className={styles.ClientNote}
      >
        <Grid item>
          <Typography className={styles.ClientNote__address}>
            {estimate?.properties?.fullAddress || '-'}
          </Typography>
        </Grid>
        {estimate.status === ESTIMATE_STATUS.APPROVED && (
          <>
            <Grid item>
              <Typography className={styles.ClientNote__noteTitle}>
                {'Client Notes (Approval)'}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className={styles.ClientNote__noteTitle}
                style={{ fontWeight: 400 }}
              >
                {estimate?.properties?.clientNotes || '-'}
              </Typography>
            </Grid>
            <Grid item>
              <Divider />
            </Grid>
          </>
        )}
        <Grid item>
          <Typography className={styles.ClientNote__noteTitle}>
            {'Client Notes (Request)'}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            className={styles.ClientNote__noteTitle}
            style={{ fontWeight: 400 }}
          >
            {estimate?.properties?.clientNotes || '-'}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ClientNotes
