import { useEffect, useState } from 'react'

import styles from './styles.module.scss'
import { Typography } from '@mui/material';
import HourPicker from '../../molecules/HourPicker';

const defaultTime = {
    hours: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
    ],
    minutes: [
        "0",
        "15",
        "30",
        "45",
    ],
    period: [
        "AM",
        "PM",
    ]
}

const TimePicker = ({ onChange, value }: { onChange: (time: any) => void, value?: any }) => {
    const [time, setTime] = useState({
        hour: value ? value?.getHours() > 12 ? (value?.getHours() - 12).toString() : value?.getHours().toString() : defaultTime.hours[9],
        minute: value?.getMinutes().toString() || "00",
        period: value?.getHours() >= 12 ? "PM" : "AM",
    })

    useEffect(() => {
        onChange(time)
    }, [time])

    const handleChangeTime = (event: any, type: string) => {
        switch (type) {
            case 'hour':
                setTime({ ...time, hour: event })
                break
            case 'minute':
                setTime({ ...time, minute: event })
                break
            case 'period':
                setTime({ ...time, period: event })
                break
        }
    }

    return (
        <div className={styles.TimePickers}>
            <HourPicker
                loop
                direction="vertical"
                slidesPerView={7}
                className={`${styles.TimePicker} ${styles.TimePicker__Hours}`}
                onSlideChange={(slide) => {
                    handleChangeTime(defaultTime.hours[slide.realIndex], "hour")
                }}
                slideToClickedSlide
                grabCursor
                freeMode
                mousewheel={{ forceToAxis: true, sensitivity: 1 }}
                initialSlide={defaultTime.hours.indexOf(time?.hour)}
            >
                {defaultTime.hours.map((hour, index) => <Typography key={index} className={styles.TimePicker__label}>{hour}</Typography>)}
            </HourPicker>
            <HourPicker
                loop
                direction="vertical"
                slidesPerView={4}
                className={`${styles.TimePicker} ${styles.TimePicker__Minutes}`}
                onSlideChange={(slide) => {
                    handleChangeTime(defaultTime.minutes[slide.realIndex], "minute")
                }}
                slideToClickedSlide
                grabCursor
                freeMode
                mousewheel={{ forceToAxis: true, sensitivity: 1 }}
                initialSlide={defaultTime.minutes.indexOf(time?.minute.toString())}
            >
                {defaultTime.minutes.map((minute, index) => <Typography key={index} className={styles.TimePicker__label}>{minute}</Typography>)}
            </HourPicker>
            <HourPicker
                direction="vertical"
                loop
                slidesPerView={2}
                className={`${styles.TimePicker} ${styles.TimePicker__Period}`}
                onSlideChange={(slide) => {
                    handleChangeTime(defaultTime.period[slide.realIndex], "period")
                }}
                slideToClickedSlide
                grabCursor
                freeMode
                initialSlide={defaultTime.period.indexOf(time?.period)}
                mousewheel={{ forceToAxis: true, sensitivity: 1 }}
            >
                {defaultTime.period.map((period, index) => <Typography key={index} className={styles.TimePicker__label}>{period}</Typography>)}
            </HourPicker>
        </div >
    )
}

export default TimePicker   