import { useState } from "react";
import { Box, Button, DialogContent, Grid, IconButton, Typography } from "src/components/UI";
import Icon from "src/components/UI/CustomUI/atoms/Icon";
import Modal from "src/components/UI/CustomUI/molecules/Modal";
import Table from "src/components/UI/CustomUI/organisms/Table";
import headers from "./headers"

const HELP_LEVELS = [
    {
        level: 0,
        description: "Not confirmed - User declared"
    },
    {
        level: 1,
        description: "Light"
    },
    {
        level: 2,
        description: "Competent"
    },
    {
        level: 3,
        description: "Skilled"
    },
    {
        level: 4,
        description: "Licensed"
    },
]

export default function TradesHeader() {
    const [open, setOpen] = useState(false)

    return (
        <Box display='flex' flexDirection='row' alignItems='center' alignContent='center'>
            <Typography>Trades</Typography>
            <IconButton onClick={() => setOpen(true)}>
                <Icon name='Info' />
            </IconButton>
            <Modal
                open={open}
                setOpen={setOpen}
                onClose={() => setOpen(false)}
                title={
                    <Grid container justifyContent='left' padding='1rem'>
                        <Typography variant='h5Bold'>Level reference</Typography>
                    </Grid>
                }
            ><>
                    <DialogContent>
                        <Table
                            headers={headers}
                            hasPagination={false}
                        >
                            {HELP_LEVELS}
                        </Table>
                    </DialogContent>
                    <Grid container justifyContent='flex-end'>
                        <Button variant='outlined' onClick={() => setOpen(false)}>Close</Button>
                    </Grid>
                </>
            </Modal>
        </Box>
    )
}