import { FC, useState } from 'react'
import {
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  InputSearch,
  SelectInput,
  FieldsFilter,
  Button,
} from '../../../../../UI'
import { useDispatch, useSelector } from 'react-redux'
import { getVendor } from 'src/ducks/selectors'
import headers from './headers'
import { colors } from 'src/components/UI/MaterialUI/themeExtension'
import { vendorActions } from 'src/ducks/actions'
import { PrivateLoader } from 'src/components/templates'

const Payments: FC = () => {
  const dispatch = useDispatch()

  const vendor = useSelector(getVendor())

  const [ach, setACH] = useState(true)
  const [w, setW] = useState(true)
  const [tax, setTax] = useState(true)
  const [searchFilter, setSearchFilter] = useState('')
  const [tableHeaders, setTableHeaders] = useState(headers)
  const [pageLoading, setPageLoading] = useState(false)

  const handleChange = (newSelected: any) => {
    // TODO: handleChange
  }

  const onTableFilterChange = (index: number): void => {
    setTableHeaders(
      tableHeaders.map((item, indx) => {
        if (indx === index) {
          return { ...item, hide: !item.hide }
        }
        return item
      })
    )
  }

  const generateQuickBooksId = (): void => {
    setPageLoading(true)
    dispatch(
      vendorActions.createQuickbooksId(vendor?.id || '', (succ) => {
        setPageLoading(false)
      })
    )
  }

  return (
    <PrivateLoader loading={pageLoading}>
      <Box display="flex" flexDirection="column" gap={1}>
        <Box display="flex" gap={1} width="100%">
          <FormControlLabel
            label="ACH"
            control={
              <Checkbox
                checked={ach}
                onChange={() => setACH(!ach)}
                color="infoText"
              />
            }
          />

          <FormControlLabel
            label="W-9"
            control={
              <Checkbox checked={w} onChange={() => setW(!w)} color="infoText" />
            }
          />

          <FormControlLabel
            label="Tax ID"
            control={
              <Checkbox
                checked={tax}
                onChange={() => setTax(!tax)}
                color="infoText"
              />
            }
          />

          <Box
            display="flex"
            gap={1}
            flex={1}
            style={{ justifyContent: 'flex-end' }}
          >
            <InputSearch
              placeholder="Search.."
              onChange={setSearchFilter}
              value={searchFilter}
              sx={{ minWidth: '220px' }}
            />

            <SelectInput
              options={[{ key: '0', label: 'Dropdown TBD' }]}
              value="Dropdown TBD"
              placeholder="Dropdown TBD"
              sx={{ minWidth: '200px' }}
              onChange={handleChange}
            />
            <FieldsFilter
              options={tableHeaders}
              handleChange={onTableFilterChange}
            />
          </Box>
        </Box>

        {vendor?.qbId ? (
          <Typography
            variant="h5"
            fontWeight={400}
            fontFamily="Nouvelle"
            style={{
              margin: '20px 0px',
              background: '#F9FAFA',
              padding: '8px',
              width: 'fit-content',
            }}
          >
            {`Quickbooks ID: ${vendor?.qbId}`}
          </Typography>
        ) : (
          <Button
            variant="containedBig"
            sx={{
              width: '200px',
              height: '40px',
              marginTop: '20px',
              backgroundColor: colors.green[700],
              border: `2px solid ${colors.green[700]}`,
              '&:hover': {
                backgroundColor: colors.green[700],
                border: `2px solid ${colors.green[700]}`,
              },
            }}

            onClick={generateQuickBooksId}
          >
            Generate QuickBooks ID
          </Button>
        )}

        {/*     <Table headers={headers}>
                {[]}
            </Table> */}
      </Box>
    </PrivateLoader>
  )
}

export default Payments
