import { Theme } from "@mui/material";

export const tabs = {
    MuiTabs: {
        styleOverrides: {
            indicator: {
                background: 'none '
            },
            root: {
                minHeight: 'fit-content'
            }
        },
    },
    MuiTab: {
        styleOverrides: {
            root: ({ theme }: { theme: Theme }) => ({
                textTransform: 'none',
                fontFamily: 'var(--font-medium)',
                fontSize: theme.typography.h3.fontSize,
                padding: '0 .5rem 0 0',
                minWidth: 'fit-content',
                minHeight: 'inherit'
            }),
            selected: ({ theme }: { theme: Theme }) => ({
                color: '#3A66FF'
            }),
        },
    }
};
