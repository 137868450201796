/** TYPES **/
export const authTypes = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  PARSE_HASH_REQUEST: 'PARSE_HASH_REQUEST',
  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  SIGNUP_REQUEST: 'SIGNUP_REQUEST',
  CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST'
}

/** ACTIONS **/
export interface LoginPayload {
  email: string
  password: string
  remember: boolean
  callback: (success: boolean) => void
}

export interface LoginAction {
  type: typeof authTypes.LOGIN_REQUEST
  payload: LoginPayload
}

export interface ParseHashPayload {
  hash: string
  callback: (success: boolean) => void
}

export interface ParseHashAction {
  type: typeof authTypes.PARSE_HASH_REQUEST
  payload: ParseHashPayload
}

export interface LogoutPayload {
  callback: (success: boolean) => void
}

export interface LogoutAction {
  type: typeof authTypes.LOGOUT_REQUEST
  payload: LogoutPayload | null
}

export interface Auth0Error {
  code: string
  policy: string
}

export interface SignupPayload {
  email: string
  password: string
  callback: (success: boolean, error?: Auth0Error) => void
}

export interface SignupAction {
  type: typeof authTypes.SIGNUP_REQUEST
  payload: SignupPayload
}

export interface ChangePasswordPayload {
  email: string
  callback: (success: boolean, error?: Auth0Error) => void
}

export interface ChangePasswordAction {
  type: typeof authTypes.CHANGE_PASSWORD_REQUEST
  payload: ChangePasswordPayload
}

export type AuthActions =
  | LoginAction
  | ParseHashAction
  | LogoutAction
  | SignupAction
  | ChangePasswordAction
