import { Button, CircularProgress, Grid, Typography } from 'src/components/UI'
import { FC, SyntheticEvent, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useSearchParams } from 'react-router-dom'
import { EmployeeType } from 'src/ducks/employee/types'
import { commentsActions } from 'src/ducks/actions'
import { getComments } from 'src/ducks/comments/selectors'
import { getEmployees, getEmployeesForDropdown, getOrderById, getTotalComments } from 'src/ducks/selectors'
import { isEmpty } from 'src/helpers'
import CommentItem from './Comment'

import { MentionsInput, Mention } from 'react-mentions'
import { Comment, CommentsParams } from 'src/ducks/comments/types'
import { Box } from '@mui/system'
import { computer } from 'src/assets'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'

const Comments: FC = () => {
  const [searchParams] = useSearchParams()
  const dispatch = useDispatch()
  const { id } = useParams()

  const orderId = searchParams?.get('order') ?? ''
  const order = useSelector(getOrderById(orderId))

  const employeesForDropDown = useSelector(getEmployeesForDropdown)
  const employees = useSelector(getEmployees)
  const totalComments = useSelector(getTotalComments)

  const [comment, setComment] = useState('')
  const [buttonLoading, setButtonLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [commentsArray, setCommentsArray] = useState<Comment[]>([])
  const [page, setPage] = useState(0)

  const [previousScrollHeight, setPreviousScrollHeight] = useState(0)
  const [currentScrollHeight, setCurrentScrollHeight] = useState(0)

  const messagesEnd = useRef<null | HTMLDivElement>(null);
  const messagesContainer = useRef<null | HTMLDivElement>(null);

  const isValid = !isEmpty(comment)

  const fetchComments = useCallback(() => {
    setLoading(true)
    if (id) {
      const params: CommentsParams = { jobId: id }
      if (order.orderId) params.orderId = order.orderId
      dispatch(
        commentsActions.fetchComments({ page, size: 10, params }, (_succ: boolean, comments) => {
          setLoading(false)
          setCommentsArray([...comments?.items as Comment[], ...commentsArray as Comment[]])
        })
      )
    }
  }, [page])


  const handleCreateComment = () => {
    setButtonLoading(true)
    const data: { recipientsList: any[], comment: string } = {
      recipientsList: [],
      comment: ''
    }
    comment?.split(/[{}]+/).forEach((item) => {
      if (item.includes('ID')) {
        const userId = item?.split('ID:')[1]
        const user = employees?.find((employee: EmployeeType) => employee?.id === userId)
        if (user?.id || userId) {
          data?.recipientsList?.push({
            id: user?.id || userId,
            userId: user?.id || userId,
            name: `${user?.firstName} ${user?.lastName}`
          })
        }
      } else {
        data.comment += item
      }
    })
    if (isValid) {
      const request: Partial<Comment> = {
        message: data?.comment,
        recipientsList: data?.recipientsList,
      }
      if (id) {
        request.estimateId = id
        request.jobId = id
      }
      if (order.orderId) request.orderId = order.orderId
      // if (data?.recipientsList.length > 0) {
        dispatch(
          commentsActions.createComment(request,
            (succ, comment) => {
              if (succ && comment) {
                setCommentsArray([...commentsArray as Comment[], comment as Comment])
                scrollToLastMessage()
              }
              setComment('')
              setButtonLoading(false)
            })
        )
      // }
    }
  }

  const handleScroll = (event: SyntheticEvent<HTMLDivElement>) => {
    const scrollTop = event?.currentTarget?.scrollTop
    setCurrentScrollHeight(event?.currentTarget?.scrollHeight)
    if (scrollTop <= 0 && commentsArray && commentsArray.length < totalComments) {
      setPage(page + 1)
      setTimeout(() => {
        messagesContainer.current?.scrollTo({ top: currentScrollHeight - previousScrollHeight, behavior: 'smooth' })
        setPreviousScrollHeight(currentScrollHeight)
      }, 1000)
    }
  }

  useEffect(() => {
    fetchComments()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  const scrollToLastMessage = () => setTimeout(() => messagesEnd.current?.scrollIntoView({ behavior: 'smooth' }), 120)

  useLayoutEffect(() => {
    page === 0 && scrollToLastMessage()
  }, [commentsArray])

  return (
    <>
      <Grid direction='column' spacing={2}>
        <div
          onScroll={handleScroll}
          style={{
            height: '70vh',
            overflowX: 'hidden',
            overflowY: 'scroll',
            paddingTop: '1rem'
          }}
          ref={messagesContainer}
        >
          {loading && <Box display="flex" justifyContent="center" margin="1rem"><CircularProgress /></Box>}
          {commentsArray && commentsArray?.length > 0 ?
            <>
              {commentsArray?.sort((a, b) => a.insertedOn > b.insertedOn ? 1 : -1).map((comment) => <CommentItem key={comment.id} comment={comment} />)}
              <div ref={messagesEnd} />
            </>
            : !loading &&
            <Grid display="flex" alignItems="center" height="100%" justifyContent="center" flexDirection="column">
              <img src={computer} alt='computer' style={{ height: '86px', width: '86px' }} />
              <Typography align='center' variant="h6Bold">
                No comments yet. <br />
                Leave your first comment.
              </Typography>
            </Grid>
          }
        </div>
        <Grid container item spacing={1} direction='column'>
          <Grid item sx={{ marginTop: '.8rem' }}>
            <Typography>Add comment</Typography>
            <MentionsInput
              placeholder="Add comment. Use '@' to tag a user."
              value={comment}
              onChange={(e: any, newValue) => setComment(e.target.value)}
              className='Comments__textarea'
              cols={4}
              rows={3}
              style={{
                input: {
                  border: '2px solid #EBECEF',
                  borderRadius: '8px',
                  height: 'auto',
                  position: 'relative',
                  padding: '.5rem'
                },
                suggestions: {
                  border: '2px solid #EBECEF',
                  borderRadius: '8px',
                  height: '30vh',
                  overflowY: 'scroll',
                  minWidth: '15vw',
                  padding: '0'
                },
                item: {
                  padding: '0 !important'
                },
                highlighter: {
                  display: 'none'
                },
                control: {
                }

              }}
            >
              <Mention
                markup='{{@__display__}} {{ID:__id__}}'
                trigger='@'
                data={employeesForDropDown}
                renderSuggestion={(suggestion: any) => {
                  return (
                    <Grid padding={1}>
                      <Typography variant='body1Bold'>{suggestion?.display}</Typography>
                      <Typography variant='body1'>{suggestion?.email}</Typography>
                    </Grid>
                  )
                }}
              />
            </MentionsInput>
          </Grid>
          <Grid item display="flex" justifyContent="flex-end" sx={{ paddingTop: '0 !important' }}>
            <Button
              variant='contained'
              onClick={handleCreateComment}
              disabled={!isValid}
              sx={{ padding: '0.8rem !important', minWidth: '180px !important', marginTop: '1rem' }}
              endIcon={!buttonLoading && <Icon name="Send" width="12px" />}
            >
              {buttonLoading ? <CircularProgress color='info' size='1.4rem' /> : <Typography variant="h6">Send Comment</Typography>}
            </Button>
          </Grid>
        </Grid>
      </Grid >
    </>
  )
}

export default Comments
