import { Theme } from '../../../../UI'
import { makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => ({
    headerContainer: {
        width: '100%',
        flexFlow: 'row !important'
    },
    title: {
        fontFamily: 'var(--font-medium) !important',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 'var(--font-L) !important',
        lineHeight: '30px !important',
        color: '#1A2A55',
    },
    addCompanyButton: {
        borderRadius: '32px',
        width: '123px',
        height: '38px !important',
    }
}))
