import { Box, Checkbox, FormControlLabel, Grid, Typography } from "@mui/material"
import { FC, useEffect, useState, useContext } from "react"
import Icon from "../../../../../../../UI/CustomUI/atoms/Icon"
import { WorkOrdersContext } from '../../context'
import { DropDownSelectProps } from "./types"
import styles from './styles.module.scss'
import DropDown from "src/components/UI/CustomUI/atoms/DropDown"
import { round } from "src/helpers"

const DropDownSelect: FC<DropDownSelectProps> = ({
	workItem, checkAll, index, initiallyExpanded, initiallyChecked, children
}) => {
	const { dispatch: dispatchContext } = useContext(WorkOrdersContext)

	const [checked, setChecked] = useState(false)

	useEffect(() => {
		setChecked(initiallyChecked || false)
	}, [initiallyChecked])

	// const totalItemsCost = workItem.items.reduce((acc, item) => acc + item.totalCost, 0)
	// const totalItemsTotal = workItem.items.reduce((acc, item) => acc + item.totalPrice, 0)
	const totalItemsCost = workItem?.totalCost || 0
	const totalItemsTotal = workItem?.totalPrice || 0

	return (
		<DropDown
			title={
				<Grid container sx={{ alignItems: 'center' }} justifyContent="space-between">
					<FormControlLabel
						aria-label="Enable"
						onClick={(event: React.SyntheticEvent<EventTarget>) => event.stopPropagation()}
						onFocus={(event: React.SyntheticEvent<EventTarget>) => event.stopPropagation()}
						control={
							<Checkbox
								color='checkbox'
								onChange={() => {
									setChecked(!checked)
									checkAll && checkAll(index, checked)
								}}
							/>}
						checked={checked}
						label={workItem.category}
						style={{ color: 'white', marginLeft: '0px' }}
					/>
					<Box display="flex" gap={3} px={2}>
						<Typography color="white">Cost: ${round(totalItemsCost, 2)}</Typography>
						<Typography color="white">Total: ${round(totalItemsTotal, 2)}</Typography>
						<Icon name="DeleteOutline" color='var(--error-color)' background="white" onClick={(event: any) => {
							event.stopPropagation()
							dispatchContext({ type: 'SET_SELECTED_WORK_ITEM', payload: workItem })
							dispatchContext({ type: 'SET_MODAL_OPEN', payload: true })
							dispatchContext({ type: 'SET_MODAL_TYPE', payload: 'DELETE_CATEGORY' })
						}} />
					</Box>
				</Grid>
			}
			iconOpen={<Icon name="AddBox" color="var(--blue700)" height="100%" />}
			iconClose={<Icon name="IndeterminateCheckBox" color="var(--blue700)" height="100%" />}
			initallyExpanded={initiallyExpanded}
			className={styles.DropDown}
		>
			{children}
		</DropDown>
	)
}

export default DropDownSelect