import React, { useReducer } from 'react'
import { PreviewInvoiceReducer } from './reducer'
import { PreviewInvoiceContextType } from './types'

const initialState = {
  ccEmails: '',
  subject: '',
  jobId: '',
  total: 0,
  isValid: false,
  pageLoading: true,
  payerEmail:'',
  optionSelectedList: [true, true, true],
}

export const PreviewInvoiceContext = React.createContext<{
  state: PreviewInvoiceContextType;
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null
})

export const PreviewInvoiceProvider: React.FunctionComponent = ({ children }: any) => {
  const [state, dispatch] = useReducer(PreviewInvoiceReducer, initialState)

  return (
    <PreviewInvoiceContext.Provider value={{ state, dispatch }}>
      {children}
    </PreviewInvoiceContext.Provider>
  )
}
