/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useCallback, useContext, useEffect, useState } from 'react'
import { PrivateLoader } from 'src/components/templates'
import {
  Box,
  Grid,
  InputSearch,
  LabeledText,
  Button,
  ActionsButton,
  TradesAndVendor
} from '../../../UI'
import styles from './styles.module.scss'
import { usePaddingWrapper, useIsXlScreen } from 'src/hooks'
import TabNavigation from 'src/components/UI/CustomUI/organisms/TabNavigation'
import Table from 'src/components/UI/CustomUI/organisms/Table'
import { Link, useParams, useNavigate, To } from 'react-router-dom'
import { colors } from 'src/components/UI/MaterialUI/themeExtension'
import { JobItem, License, Vendor } from 'src/ducks/types'

import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  getJob,
  getTotalVendors,
  getVendorsForDispatch,
  getVendor,
  getOrder,
  getJobFullAddress
} from 'src/ducks/selectors'
import { formatTimestamp, getNonValidLicenses, isEmpty, ORDER_STATUS, ROWS_PER_PAGE_EXTENDED } from 'src/helpers'
import { SearchParams } from 'src/ducks/searches/types'
import {
  jobActions,
  vendorsActions,
  vendorActions,
  orderActions,
  ordersActions
} from 'src/ducks/actions'
import headers from './headers'
import BackButton from 'src/components/UI/CustomUI/atoms/BackButton'
import { capitalize, debounce } from 'lodash'
import Schedule from './Tabs/Schedule'
import Pricing from './Tabs/Pricing'
import History from './Tabs/History'
import { DispatchContext } from './context'
import { OrderType } from 'src/ducks/orders/types'
import { dispatchTypes } from './context/types'
import moment from 'moment'
import TradesModal from './TradesModal'
import TablePagination from 'src/components/UI/CustomUI/organisms/Table/TablePagination'
import ProConflictModal from './ProConflictModal'
import LicenseExpireModal from './LicenseExpireModal'

const PAGE_SIZE = 10

const Dispatch: FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { setHasPadding } = usePaddingWrapper()

  const {
    dispatch: dispatchContext,
    state: {
      jobFiles,
      showWarnings,
      dispatchNotes,
      scheduledStartTimestamp,
      scheduledEndTimestamp,
      tradesSelected,
      openModal,
      modalType,
      marginAmount,
      loadingPricing
    }
  } = useContext(DispatchContext)

  const { jobId, orderId } = useParams()
  const job = useSelector(getJob())
  const jobFullAddress = useSelector(getJobFullAddress())
  const jobOrder = useSelector(getOrder())
  const totalVendors = useSelector(getTotalVendors)
  const vendors = useSelector(getVendorsForDispatch(), shallowEqual)
  const dispatchVendor = useSelector(getVendor())

  const { dispatchedTimestamp, bidPrice, pricingModel, statusType } =
    jobOrder || ({} as OrderType)
  const { dueOn, properties } = job || ({} as JobItem)
  const { affiliate, affiliateType, territory, closingDate, lockBoxDetails, accessDetails } = properties || {}
  const { id: territoryId } = territory || {}

  const showResendAndRevoke = statusType && ORDER_STATUS.PAID !== statusType

  const TABS = [
    {
      label: 'Schedule',
      content: <Schedule />,
      error:
        showWarnings && (!scheduledStartTimestamp || !scheduledEndTimestamp)
    },
    {
      label: 'Pricing',
      content: <Pricing />,
      error: showWarnings && !bidPrice
    },
    { label: 'Dispatch History', content: <History timezone={job?.properties.address.timeZone} /> }
  ]

  const [tableLoading, setTableLoading] = useState(false)
  const [pageLoading, setPageLoading] = useState(false)
  const [fetchingJob, setfetchingJob] = useState(true)
  const [fetchingOrder, setfetchingOrder] = useState(true)
  const [pageLoaded, setPageLoaded] = useState(false)

  const [buttonLoading, setButtonLoading] = useState(false)
  const [searchVendor, setSearchVendor] = useState('')
  const [selectedVendor, setSelectedVendor] = useState<Vendor | null>(null)
  const [warningVendor, setWarningVendor] = useState<Vendor | null>(null)
  const [expiringLicenses, setExpiringLicenses] = useState<License[] | undefined>(undefined)
  const [recommendedStart, setRecommendedStart] = useState(0)

  const dispatched = dispatchedTimestamp !== null && dispatchedTimestamp > 0
  const validate =
    scheduledStartTimestamp &&
    scheduledEndTimestamp &&
    selectedVendor &&
    (bidPrice > 0 || bidPrice === 0) &&
    marginAmount >= 0
    && !loadingPricing
  const [showRecommended, setShowRecommended] = useState(true)
  const fetchRecommended = isEmpty(searchVendor) && tradesSelected?.length > 0

  const [xlScreen] = useIsXlScreen()

  const getParams = () => {
    const searchParams = {} as Partial<SearchParams>
    if (!isEmpty(searchVendor)) searchParams.search = searchVendor
    if (territoryId) searchParams.territory = territoryId
    searchParams.approved = true
    return searchParams
  }

  const fetchJob = useCallback(() => {
    if (jobId) {
      setfetchingJob(true)
      dispatch(jobActions.setJob(null))
      dispatch(
        jobActions.fetchJob(jobId, (_succ: boolean) => {
          setfetchingJob(false)
        })
      )
    }
  }, [])

  const fetchOrder = useCallback(() => {
    if (orderId) {
      dispatchContext({
        type: dispatchTypes.SET_VALUE,
        payload: { attr: 'order', value: null }
      })
      dispatch(orderActions.setOrder(null))
      setfetchingOrder(true)
      dispatch(
        orderActions.getOrder(orderId, (_succ: boolean) => {
          setfetchingOrder(false)
        })
      )
    }
  }, [])

  const fetchOrderBids = useCallback(() => {
    if (orderId) {
      dispatch(
        orderActions.getOrderBids(orderId, (bids: any) => {
          dispatchContext({
            type: dispatchTypes.SET_VALUE,
            payload: { attr: 'orderBids', value: bids }
          })
        })
      )
    }
  }, [])

  const changeSearchPro = useCallback(
    (newValue) => {
      fetchVendors(0, PAGE_SIZE, newValue)
    },
    []
  )

  const debouncedSearch = useCallback(
    debounce(changeSearchPro, 500),
    []
  )


  useEffect(() => {
    dispatch(vendorActions.setVendor(null))
    fetchJob()
    fetchOrder()
    fetchOrderBids()
    setHasPadding(false)
  }, [])

  useEffect(() => {
    dispatchContext({
      type: dispatchTypes.SET_VALUE,
      payload: { attr: 'openModal', value: !dispatched }
    })
    dispatchContext({
      type: dispatchTypes.SET_VALUE,
      payload: { attr: 'modalType', value: 'Trades' }
    })
  }, [dispatched])

  const openModalLicenses = (val: boolean | null, vendor: Vendor | null) => {
    dispatchContext({
      type: dispatchTypes.SET_VALUE,
      payload: { attr: 'openModal', value: val }
    })
    dispatchContext({
      type: dispatchTypes.SET_VALUE,
      payload: { attr: 'modalType', value: 'ExpiredLicenses' }
    })
    setExpiringLicenses(getNonValidLicenses(vendor?.licenseList))
    setWarningVendor(vendor)
  }

  useEffect(() => {
    if (jobOrder && jobOrder.orderId === orderId && !pageLoaded) {
      setPageLoaded(true)
      dispatchContext({
        type: dispatchTypes.SET_VALUE,
        payload: {
          attr: 'scheduledStartTimestamp',
          value: jobOrder?.scheduledStartTimestamp
        }
      })
      dispatchContext({
        type: dispatchTypes.SET_VALUE,
        payload: {
          attr: 'scheduledEndTimestamp',
          value: jobOrder?.scheduledEndTimestamp
        }
      })
      const access = accessDetails ? 'Access Details: ' + accessDetails : ''
      const lockbox = lockBoxDetails ? '\nLockbox: ' + lockBoxDetails : ''

      dispatchContext({
        type: dispatchTypes.SET_VALUE,
        payload: {
          attr: 'dispatchNotes', value: jobOrder?.dispatchedTimestamp ?
            jobOrder?.dispatchNotes : access + ' ' + lockbox
        }
      })

      if (jobOrder?.vendorId) {
        setPageLoading(true)
        dispatch(
          vendorActions.fetchVendor(jobOrder.vendorId, (_succ: boolean) => {
            setPageLoading(false)
          })
        )
      } else dispatch(vendorActions.setVendor(null))
    }
  }, [jobOrder])

  useEffect(() => {
    setSelectedVendor(dispatchVendor)
  }, [dispatchVendor])

  const fetchVendors = (page?: number, size?: number, searchValue?: string) => {
    setTableLoading(true)
    setShowRecommended(false)
    const searchParams = getParams()
    if (searchValue) searchParams.search = searchValue
    dispatch(
      vendorsActions.fetchVendors(
        { page, size, searchParams },
        (_succ: boolean) => {
          setTableLoading(false)
        }
      )
    )
  }

  const fetchRecommendedVendors = () => {
    setTableLoading(true)
    setShowRecommended(true)
    dispatch(
      vendorsActions.fetchRecommendedVendors(
        {
          territory: territoryId,
          trades: tradesSelected.join(','),
          workStartDate: formatTimestamp(
            scheduledStartTimestamp ?? moment().unix(),
            'M/D/YYYY'
          ),
          workEndDate: formatTimestamp(
            scheduledEndTimestamp ?? closingDate ?? moment().unix(),
            'M/D/YYYY'
          )
        },
        (_succ: boolean, total: number) => {
          setTableLoading(false)
          if (!total) fetchVendors(0, PAGE_SIZE)
        }
      )
    )
  }

  useEffect(() => {
    if (fetchRecommended) {
      setRecommendedStart(0)
      fetchRecommendedVendors()
    } else {
      fetchVendors(0, PAGE_SIZE)
    }
  }, [debouncedSearch, tradesSelected])

  useEffect(() => {
    if (fetchRecommended) {
      setRecommendedStart(0)
      fetchRecommendedVendors()
    }
  }, [scheduledStartTimestamp, scheduledEndTimestamp])

  const handleClose = () => {
    navigate(`/jobs/${jobId}?tab=1&order=${orderId}`)
  }

  const updateOrder = () => {
    orderId && dispatch(
      orderActions.updateOrder(
        {
          orderId,
          order: {
            orderId,
            vendorId: selectedVendor?.id,
            scheduledStartTimestamp,
            scheduledEndTimestamp,
            bidPrice,
            pricingModel: {
              ...pricingModel,
              autoUpdate: true
            },
            dispatchNotes
          },
          jobFiles: jobFiles?.filter((file) => (file as any).checked) ?? null
        },
        (succ: boolean) => {
          setButtonLoading(false)
          if (succ) handleClose()
        }
      )
    )
  }

  const dispatchOrder = (sendNotification: boolean = false,
    manualAssign: boolean = false) => {
    orderId && dispatch(
      orderActions.dispatchOrder(
        {
          orderId,
          order: {
            orderId,
            vendorId: selectedVendor?.id,
            statusType: manualAssign
              ? ORDER_STATUS.DISPATCHED
              : ORDER_STATUS.PENDING,
            scheduledStartTimestamp,
            scheduledEndTimestamp,
            bidPrice,
            pricingModel,
            dispatchNotes
          },
          jobFiles: jobFiles?.filter((file) => (file as any).checked) ?? null,
          sendNotification: sendNotification ? true : undefined
        },
        (succ: boolean) => {
          setButtonLoading(false)
          if (succ) handleClose()
        }
      )
    )
  }

  const handleUpdate = () => {
    if (validate && orderId) {
      setButtonLoading(true)
      dispatchContext({
        type: dispatchTypes.SET_VALUE,
        payload: { attr: 'showWarnings', value: false }
      })
      dispatch(ordersActions.getOrdersConflict({
        vendorId: selectedVendor?.id,
        endDate: scheduledEndTimestamp,
        startDate: scheduledStartTimestamp,
        territory: job?.properties.territory?.id || ''
      }, (succ, ordersConflict) => {
        if (ordersConflict?.conflict) {
          dispatchContext({
            type: dispatchTypes.SET_VALUE,
            payload: { attr: 'openModal', value: true }
          })
          dispatchContext({
            type: dispatchTypes.SET_VALUE,
            payload: { attr: 'modalType', value: 'ProConflict' }
          })
          setButtonLoading(false)
        } else {
          updateOrder()
        }
      }))
    } else {
      dispatchContext({
        type: dispatchTypes.SET_VALUE,
        payload: { attr: 'showWarnings', value: true }
      })
    }
  }

  const handleDispatch = (
    sendNotification: boolean = false,
    manualAssign: boolean = false
  ) => {
    if (validate && orderId) {
      // setButtonLoading(true)
      dispatchContext({
        type: dispatchTypes.SET_VALUE,
        payload: { attr: 'showWarnings', value: false }
      })
      dispatch(ordersActions.getOrdersConflict({
        vendorId: selectedVendor?.id,
        endDate: scheduledEndTimestamp,
        startDate: scheduledStartTimestamp,
        territory: job?.properties.territory?.id || ''
      }, (succ, orderConflict) => {
        if (orderConflict?.conflict) {
          dispatchContext({
            type: dispatchTypes.SET_VALUE,
            payload: { attr: 'openModal', value: true }
          })
          dispatchContext({
            type: dispatchTypes.SET_VALUE,
            payload: { attr: 'modalType', value: 'ProConflict' }
          })
          setButtonLoading(false)
        } else {
          dispatchOrder(sendNotification, manualAssign)
        }
      }))
    } else {
      dispatchContext({
        type: dispatchTypes.SET_VALUE,
        payload: { attr: 'showWarnings', value: true }
      })
    }
  }

  const handleDispatchAndNotify = () => {
    handleDispatch(true)
  }

  const handleManualAssign = () => {
    handleDispatch(false, true)
  }

  const handleRevokeDispatch = (sendNotification: boolean = false) => {
    if (orderId) {
      setButtonLoading(true)
      dispatchContext({
        type: dispatchTypes.SET_VALUE,
        payload: { attr: 'showWarnings', value: false }
      })
      dispatch(
        orderActions.revokeDispatchOrder(
          { orderId, sendNotification },
          (success: boolean) => {
            if (success) setSelectedVendor(null)
            setButtonLoading(false)
          }
        )
      )
    }
  }

  const handleRevokeDispatchAndNotify = () => {
    handleRevokeDispatch(true)
  }

  const handlResendNotification = () => {
    if (validate && orderId) {
      setButtonLoading(true)
      dispatchContext({
        type: dispatchTypes.SET_VALUE,
        payload: { attr: 'showWarnings', value: false }
      })
      dispatch(
        orderActions.dispatchOrder({
          orderId: orderId,
          order: {
            ...jobOrder,
            orderId,
            vendorId: selectedVendor?.id ? selectedVendor?.id : jobOrder?.vendorId,
            scheduledStartTimestamp,
            scheduledEndTimestamp,
            bidPrice,
            pricingModel: {
              ...pricingModel,
              autoUpdate: true
            },
            dispatchNotes
          },
          jobFiles: jobFiles?.filter((file) => (file as any).checked) ?? null
        }, (succ: boolean) => {
          setButtonLoading(false)
        })
      )
    } else {
      dispatchContext({
        type: dispatchTypes.SET_VALUE,
        payload: { attr: 'showWarnings', value: true }
      })
    }
  }

  const actionsDispatch = [
    { label: 'Save & Close', onClick: handleUpdate },
    { label: 'Dispatch', onClick: handleDispatch },
    { isDivider: true },
    { label: 'Manually Assign', onClick: handleManualAssign }
  ]

  const actionsDispatched = showResendAndRevoke
    ? [
      { label: 'Revoke Dispatch', onClick: handleRevokeDispatchAndNotify },
      {
        label: 'Resend Dispatch Notification',
        onClick: handlResendNotification
      }
    ]
    : undefined

  const [windowDimention] = useState({
    winWidth: window.innerWidth - 32,
    winHeight: window.innerHeight
  })

  return (
    <PrivateLoader loading={pageLoading || fetchingJob || fetchingOrder}>
      <>
        <BackButton float large top='12px' to={`/jobs/${jobId}?tab=1&order=${orderId}`} />
        <Box
          sx={
            xlScreen
              ? {
                display: 'grid',
                gridAutoFlow: 'row',
                gridAutoRows: 'min-content auto min-content'
              }
              : {
                display: 'grid',
                gridAutoFlow: 'row',
                gridAutoRows: 'min-content min-content min-content'
              }
          }
        >
          <Box className={styles.Header}>
            <LabeledText
              labelValue='Due Date:'
              textValue={formatTimestamp(dueOn, 'MM/DD/YYYY')}
            />
            <LabeledText
              labelValue='Type:'
              textValue={capitalize(affiliateType)}
            />
            <LabeledText
              labelValue='Affiliate:'
              textValue={affiliate !== null ? capitalize(affiliate) : undefined}
            />
            <LabeledText labelValue='Address:' textValue={jobFullAddress} />
          </Box>
          <Grid container spacing={2} className={styles.Content}>
            <Grid
              item
              container
              xs={12}
              lg={8}
              spacing={1}
              sx={
                xlScreen
                  ? {
                    display: 'grid',
                    gridTemplateRows: 'min-content auto'
                  }
                  : {
                    display: 'grid',
                    gridTemplateRows: 'min-content min-content'
                  }
              }
            >
              <Grid container item spacing={2}>
                <Grid item xs={12} lg={6}>
                  <InputSearch
                    label='Search for Pro:'
                    placeholder='Search for Vendor'
                    onChange={(value) => {
                      setSearchVendor(value)
                      debouncedSearch(value)
                    }}
                    value={searchVendor}
                    size='small'
                    error={showWarnings && !selectedVendor}
                    sx={{ width: '100%' }}
                  />
                  <Link
                    to='/pros'
                    target='_blank'
                    style={{
                      color: colors.blue[700],
                      display: 'block',
                      padding: '6px 8px',
                      textDecoration: 'none'
                    }}
                  >
                    Not seeing your vendor? Click here to make sure they're
                    approved.
                  </Link>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TradesAndVendor
                    selectedVendor={selectedVendor}
                    unselectVendor={() => {
                      setSelectedVendor(null)
                    }}
                    selectTrades={() => {
                      dispatchContext({
                        type: dispatchTypes.SET_VALUE,
                        payload: { attr: 'openModal', value: true }
                      })
                      dispatchContext({
                        type: dispatchTypes.SET_VALUE,
                        payload: { attr: 'modalType', value: 'Trades' }
                      })
                    }}
                    selectedTrades={tradesSelected}
                    dispatched={dispatched}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                lg={12}
                sx={
                  xlScreen
                    ? { maxWidth: '100% !important' }
                    : { maxWidth: `${windowDimention.winWidth}px !important` }
                }
              >
                {openModal && modalType === "Trades" && <TradesModal onClick={fetchRecommendedVendors} />}
                {openModal && modalType === "ExpiredLicenses" && <LicenseExpireModal onClick={setSelectedVendor} licenses={expiringLicenses} vendor={warningVendor} />}
                {openModal && modalType === "ProConflict" && <ProConflictModal onClick={dispatched ? updateOrder : () => dispatchOrder(false, true)} />}
                {showRecommended
                  ? (
                    <div id='recommended-vendor-search-container'>
                      <Table
                        id='recommended-vendor-search'
                        headers={headers(setSelectedVendor, dispatched, true, openModalLicenses)}
                        defaultOrder='desc'
                        defaultOrderBy='rank'
                        totalItems={totalVendors}
                        small
                        loading={tableLoading}
                        hasPagination={totalVendors > PAGE_SIZE}
                        callToApi={(start, _limit) => {
                          setRecommendedStart(start as number)
                        }}
                        rowsPerPageDefault={PAGE_SIZE}
                        rowsPerPageOptions={[PAGE_SIZE]}
                        className={!xlScreen ? styles.Table : ''}
                      >
                        {!openModal
                          ? vendors
                            .splice(recommendedStart * PAGE_SIZE, PAGE_SIZE)
                            .map((vendor) => {
                              return {
                                ...vendor,
                                selected: selectedVendor?.id === vendor.id
                              }
                            })
                          : []}
                      </Table>
                    </div>
                  )
                  : (
                    <div>
                      <Table
                        id='vendor-search'
                        headers={headers(setSelectedVendor, dispatched, false, openModalLicenses)}
                        defaultOrder='desc'
                        totalItems={totalVendors}
                        small
                        loading={tableLoading}
                        callToApi={(start, limit) => {
                          fetchVendors(start, limit)
                        }}
                        //rowsPerPageDefault={PAGE_SIZE}
                        //rowsPerPageOptions={[PAGE_SIZE]}
                        className={!xlScreen ? styles.Table : ''}
                        hasPagination={false}
                      >
                        {!openModal
                          ? vendors.map((vendor) => {
                            return {
                              ...vendor,
                              selected: selectedVendor?.id === vendor.id
                            }
                          })
                          : []}
                      </Table>
                      <TablePagination
                        callToApi={(start, limit) => { fetchVendors(start, limit) }}
                        totalItems={totalVendors}
                        rowsPerPageDefault={10}
                        rowsPerPageOptions={ROWS_PER_PAGE_EXTENDED}
                      />
                    </div>
                  )}
              </Grid>

              <Grid>

              </Grid>
            </Grid>
            <Grid item xs={12} lg={3.8}>
              <TabNavigation
                tabs={TABS}
                defaultActive={0}
                variant='contained'
                margin='0 0 0.5rem'
              />
            </Grid>
          </Grid>
          <Box className={styles.Footer}>
            <Button
              variant='containedLight'
              onClick={handleClose}
              sx={{ width: '200px' }}
            >
              Cancel
            </Button>
            <ActionsButton
              icon={<Icon name='ArrowDropDown' />}
              text={dispatched ? 'Save & Close' : 'Dispatch and Notify'}
              onClick={dispatched ? handleUpdate : handleDispatchAndNotify}
              iconPosition='end'
              loading={buttonLoading}
              actions={dispatched ? actionsDispatched : actionsDispatch}
              variant='contained'
              sx={{
                width: '200px',
                display: 'flex',
                justifyContent: buttonLoading ? 'center' : 'space-between',
                backgroundColor: validate ? 'var(--blue700)' : '#E0E0E0',
                borderColor: validate ? 'var(--blue700)' : '#E0E0E0',
                '&:hover': {
                  backgroundColor: validate ? 'var(--blue800)' : '#E0E0E0',
                  borderColor: validate ? 'var(--blue800)' : '#E0E0E0'
                }
              }}
            />
          </Box>
        </Box>
      </>
    </PrivateLoader>
  )
}

export default Dispatch
